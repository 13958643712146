import type { UpdatesConfig } from "@urql/exchange-graphcache";

import type {
  CreateAskAutodorQueueInput,
  createAskAutodorQueueMutation,
  deleteAskAutodorQueueMutation,
  getAskAutodorQueueQuery,
  getAskAutodorQueuesQuery,
  QueryaskAutodorQueuesArgs,
  UpdateAskAutodorQueueInput,
  updateAskAutodorQueueMutation,
} from "../../remote/gql/graphql";
import { invalidateQuery } from "../../remote/graphCache";
import { createAskAutodorQueueMutationDoc } from "../../remote/mutations/createAskAutodorQueue.gql";
import { deleteAskAutodorQueueMutationDoc } from "../../remote/mutations/deleteAskAutodorQueue.gql";
import { updateAskAutodorQueueMutationDoc } from "../../remote/mutations/updateAskAutodorQueue.gql";
import { getAskAutodorQueueQueryDoc } from "../../remote/queries/getAskAutodorQueue.gql";
import { getAskAutodorQueuesQueryDoc } from "../../remote/queries/getAskAutodorQueues.gql";
import type { ClientReturnType } from "../alaffiaClient";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

type QueuesModuleArgs = AlaffiaClientModuleArgs;
export class QueuesModule extends AlaffiaClientModule {
  constructor(args: QueuesModuleArgs) {
    super(args);
  }

  static cacheOptions: UpdatesConfig = {
    Mutation: {
      createAskAutodorQueue(_result, _args, cache) {
        invalidateQuery(cache, "askAutodorQueues");
      },
      updateAskAutodorQueue(_result, _args, cache) {
        invalidateQuery(cache, "askAutodorQueues");
      },
      deleteAskAutodorQueue(_result, _args, cache) {
        invalidateQuery(cache, "askAutodorQueues");
      },
    },
  };

  getAllQueues = async (
    _input = {},
    args?: QueryaskAutodorQueuesArgs,
  ): ClientReturnType<getAskAutodorQueuesQuery["askAutodorQueues"]> => {
    const result = await this.runGqlQuery(
      getAskAutodorQueuesQueryDoc,
      { ...args },
      "getAllQueues",
    );

    return {
      data: result.data?.askAutodorQueues ?? null,
      error: result.error ?? null,
    };
  };

  getQueue = async (input: {
    id: string;
  }): ClientReturnType<getAskAutodorQueueQuery["askAutodorQueue"]> => {
    const result = await this.runGqlQuery(
      getAskAutodorQueueQueryDoc,
      { id: input.id },
      "getQueue",
    );
    return {
      data: result.data?.askAutodorQueue ?? null,
      error: result.error ?? null,
    };
  };

  createQueue = async (input: {
    input: CreateAskAutodorQueueInput;
  }): ClientReturnType<
    createAskAutodorQueueMutation["createAskAutodorQueue"]
  > => {
    const result = await this.runGqlMutation(
      createAskAutodorQueueMutationDoc,
      { input: input.input },
      "createQueue",
    );

    return {
      data: result.data?.createAskAutodorQueue ?? null,
      error: result.error ?? null,
    };
  };

  deleteQueue = async (input: {
    id: string;
  }): ClientReturnType<
    deleteAskAutodorQueueMutation["deleteAskAutodorQueue"]
  > => {
    const result = await this.runGqlMutation(
      deleteAskAutodorQueueMutationDoc,
      { id: input.id },
      "deleteQueue",
    );

    return {
      data: result.data?.deleteAskAutodorQueue ?? null,
      error: result.error ?? null,
    };
  };

  updateQueue = async ({
    id,
    queueData,
  }: {
    id: string;
    queueData: UpdateAskAutodorQueueInput;
  }): ClientReturnType<
    updateAskAutodorQueueMutation["updateAskAutodorQueue"]
  > => {
    const result = await this.runGqlMutation(
      updateAskAutodorQueueMutationDoc,
      {
        id,
        input: {
          name: queueData.name,
        },
      },
      "updateQueue",
    );

    return {
      data: result.data?.updateAskAutodorQueue ?? null,
      error: result.error ?? null,
    };
  };
}
