import { GraphQLError } from "@alaffia-technology-solutions/schema";

type Handler = (message: { title: string; description: string }) => void;

export const handleError = (error: unknown, handler: Handler) => {
  const parsedError = GraphQLError.safeParse(error);

  const description = parsedError.success
    ? parsedError.data.message
    : "Unknown error";

  handler({
    title: "Something went wrong",
    description,
  });
};
