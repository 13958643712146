import type { UrqlClient } from "../../remote/createUrqlClient";
import type { IAuthHandler } from "../authHandler";
import { AuthHandler } from "../authHandler";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

interface ApiModuleArgs extends AlaffiaClientModuleArgs {
  authHandler?: IAuthHandler;
}

export class ApiModule extends AlaffiaClientModule {
  private authHandler: IAuthHandler;

  constructor(args: ApiModuleArgs) {
    super(args);
    this.authHandler = args.authHandler ?? new AuthHandler(args.apiUrl);
  }

  fetchAuthenticatedPost: IAuthHandler["fetchAuthenticatedPost"] = (
    resourcePath,
    bodyObject,
  ) => this.authHandler.fetchAuthenticatedPost(resourcePath, bodyObject);

  getGraphqlClient = (): UrqlClient => {
    return this.gqlClient;
  };
}
