import { z } from "zod";

export const ThreadByIdInput = z.object({
  id: z.string(),
});
export type ThreadByIdInput = z.infer<typeof ThreadByIdInput>;

export const ThreadByCaseIdInput = z.object({
  caseId: z.string(),
});
export type ThreadByCaseIdInput = z.infer<typeof ThreadByCaseIdInput>;

export const ThreadCreateInput = z.object({
  caseId: z.string(),
  name: z.string(),
});
export type ThreadCreateInput = z.infer<typeof ThreadCreateInput>;
