/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  BookOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FileDoneOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";

import { parseGroupedAggregatesQueryResIntoProgessStatProps } from "../../claimStateUtil";

const workflow = {
  auditProgress: {
    stateTypeLabel: "Workflow",
    defaultLabel: "No Claims in workflow state",
    tooltipLabel: "Overall workflow status for all claims",
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: WORKFLOW_STATE) on
     *     the workflowClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: () => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `workflow: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
          ]
        }
      ) {
        groupedAggregates(groupBy: WORKFLOW_CLAIM_STATE) {
          keys
          distinctCount {
            id
          }
        }
      }`;
      return query;
    },

    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e TO_DO)): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims,
        workflow: { groupedAggregates },
      },
      permissions,
      userType,
      queryableStates,
      defaultStates,
      states,
    }: any) => {
      let sumOfAggregates = 0;
      const formattedQueryResults: any = {
        CLAIM_RECEIVED: 0,
        CLAIM_READY: 0,
        TO_DO: 0,
        IN_PROGRESS: 0,
        COMPLETED: 0,
        REPORTED_TO_PROVIDER: 0,
        REPORTED_TO_CLIENT: 0,
        CLOSED: 0,
      };
      groupedAggregates.forEach(
        ({ keys: [keyName, _], distinctCount }: any) => {
          sumOfAggregates += parseInt(distinctCount.id);
          formattedQueryResults[keyName] = parseInt(distinctCount.id);
        },
      );
      return parseGroupedAggregatesQueryResIntoProgessStatProps({
        totalClaims,
        sumOfAggregates,
        formattedQueryResults,
        permissions,
        userType,
        stateType: "workflow",
        queryableStates,
        defaultStates,
        states,
      });
    },
  },
  queryableStates: [
    "CLAIM_RECEIVED",
    "TO_DO",
    "CLAIM_READY",
    "IN_PROGRESS",
    "COMPLETED",
    "REPORTED_TO_PROVIDER",
    "REPORTED_TO_CLIENT",
    "CLOSED",
  ],
  dataType: "enum",
  defaultStates: ["CLAIM_RECEIVED"],
  onTableQuery: "batchClaims",
  states: {
    // Default State
    CLAIM_RECEIVED: {
      name: () => {
        return "Claim Received";
      },
      primaryColor: "#40a9ff",
      strokeColor: "white",
      tagColor: "blue",
      icon: <PlusOutlined style={{ color: "#40a9ff", fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "New Claim(s) that have been received";
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "CLAIM_RECEIVED",
      timelineEventRendering: {
        description: () => "Claim Received",
        renderingAllowed: () => false,
      },
    },
    TO_DO: {
      name: (userType: string | number) => {
        return {
          PROVIDER: "Set For Review",
          PAYER: "Set For Review",
          ADMINISTRATOR: "To Do",
          AUDITOR: "To Do",
        }[userType];
      },
      primaryColor: "lightgray",
      strokeColor: "white",
      tagColor: null, // makes the tag use the default grey in antd
      icon: (
        <PlayCircleOutlined style={{ color: "lightgray", fontSize: "120%" }} />
      ),
      description: (userType: string | number) => {
        // different userTypes don't make a difference so we just return
        return {
          PROVIDER: "Claim(s) set for review",
          PAYER: "Claim(s) set for review",
          ADMINISTRATOR: "Claim(s) set to To Do",
          AUDITOR: "Claim(s) set to To Do",
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "TO_DO",
      timelineEventRendering: {
        description: (userType: string | number) => {
          return {
            PROVIDER: "Claim is set for review",
            PAYER: "Claim is set for review",
            ADMINISTRATOR: "Claim is set to To Do",
            AUDITOR: "Claim is set to To Do",
          }[userType];
        },
        renderingAllowed: () => true,
      },
    },
    CLAIM_READY: {
      name: (userType: string | number) => {
        return {
          PROVIDER: "Under Review",
          PAYER: "Under Review",
          ADMINISTRATOR: "Claim Ready",
          AUDITOR: "Claim Ready",
        }[userType];
      },
      primaryColor: "#3c88fa",
      strokeColor: "white",
      tagColor: "blue",
      icon: <BookOutlined style={{ color: "#3c88fa", fontSize: "120%" }} />,
      description: (userType: string | number) => {
        return {
          PROVIDER: "Claim(s) is under review",
          PAYER: "Claim(s) is under review",
          ADMINISTRATOR: "Claim(s) is Ready",
          AUDITOR: "Claim(s) is Ready",
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "CLAIM_READY",
      timelineEventRendering: {
        description: (userType: string | number) => {
          return {
            PROVIDER: "Claim set to under review",
            PAYER: "Claim set to under review",
            ADMINISTRATOR: "Claim set to Ready",
            AUDITOR: "Claim set to Ready",
          }[userType];
        },
        renderingAllowed: () => true,
      },
    },
    IN_PROGRESS: {
      name: (userType: string | number) => {
        return {
          PROVIDER: "Under Review",
          PAYER: "Under Review",
          ADMINISTRATOR: "In Progress",
          AUDITOR: "In Progress",
        }[userType];
      },
      primaryColor: "#faad14",
      strokeColor: "white",
      tagColor: "gold",
      icon: <EditOutlined style={{ color: "#faad14", fontSize: "120%" }} />,
      description: (userType: string | number) => {
        return {
          PROVIDER: "Claim(s) is under review",
          PAYER: "Claim(s) is under review",
          ADMINISTRATOR: "Claim(s) is In Progress",
          AUDITOR: "Claim(s) is In Progress",
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "IN_PROGRESS",
      timelineEventRendering: {
        description: (userType: string | number) => {
          return {
            PROVIDER: "Claim set to under review",
            PAYER: "Claim set to under review",
            ADMINISTRATOR: "Claim set to In Progress",
            AUDITOR: "Claim set to In Progress",
          }[userType];
        },
        renderingAllowed: () => true,
      },
    },
    COMPLETED: {
      name: (userType: string | number) => {
        return {
          PROVIDER: "Reviewed",
          PAYER: "Reviewed",
          ADMINISTRATOR: "Completed",
          AUDITOR: "Completed",
        }[userType];
      },
      primaryColor: "#52c41a",
      strokeColor: "white",
      tagColor: "green",
      icon: <CheckOutlined style={{ color: "#52c41a", fontSize: "120%" }} />,
      description: (userType: string | number) => {
        return {
          PROVIDER: "Review Complete for Claim(s)",
          PAYER: "Review Complete for Claim(s)",
          ADMINISTRATOR: "Claim(s) is Complete",
          AUDITOR: "Claim(s) is Complete",
        }[userType];
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "COMPLETED",
      timelineEventRendering: {
        description: (userType: string | number) => {
          return {
            PROVIDER: "Review Complete for Claim",
            PAYER: "Review Complete for Claim",
            ADMINISTRATOR: "Claim Completed",
            AUDITOR: "Claim Completed",
          }[userType];
        },
        renderingAllowed: () => true,
      },
    },
    REPORTED_TO_PROVIDER: {
      name: () => {
        return "Reported To Provider";
      },
      primaryColor: "#15ad02",
      strokeColor: "white",
      tagColor: "green",
      icon: <FileDoneOutlined style={{ color: "#15ad02", fontSize: "120%" }} />,
      description: () => {
        return "Claim(s) Reported To Provider";
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "REPORTED_TO_PROVIDER",
      timelineEventRendering: {
        description: () => {
          return "Claim Reported To Provider";
        },
        renderingAllowed: () => true,
      },
    },
    REPORTED_TO_CLIENT: {
      name: () => {
        return "Reported To Client";
      },
      primaryColor: "#128a03",
      strokeColor: "white",
      tagColor: "green",
      icon: <FileDoneOutlined style={{ color: "#128a03", fontSize: "120%" }} />,
      description: () => {
        return "Claim(s) Reported To Client";
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "REPORTED_TO_CLIENT",
      timelineEventRendering: {
        description: () => {
          return "Claim Reported To Client";
        },
        renderingAllowed: () => true,
      },
    },
    CLOSED: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "Archived";
      },
      primaryColor: "#fa541c", //#fa541c
      strokeColor: "white",
      tagColor: "volcano",
      icon: <CloseOutlined style={{ color: "#fa541c", fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim(s) have been Archived";
      },
      displayCondition: ({ batchClaimState: { workflowClaimState } }: any) =>
        workflowClaimState === "CLOSED",
      timelineEventRendering: {
        description: () => "Claim Archived",
        renderingAllowed: () => true,
      },
    },
  },
  filterBuilder: (values: any[]) => {
    /* 
      POSSIBLE VALUES = [CLAIM_RECEIVED, TO_DO, IN_PROGRESS, COMPLETED, CLOSED]

      filter required for each value:
      CLAIM_RECEIVED
        - workflowClaimStatesExist = false ONLY

     TO_DO | IN_PROGRESS | COMPLETED | CLOSED 
      - workflowClaimStatesExist: true   
        -- and --
      - workflowClaimState = TO_DO | IN_PROGRESS | COMPLETED | CLOSED 
    */

    if (values.length === 1) {
      if (values.includes("CLAIM_RECEIVED"))
        return { workflowClaimStatesExist: false };
      else
        return {
          workflowClaimStatesExist: true,
          batchClaimStateById: {
            workflowClaimState: { equalTo: values[0] },
          },
        };
    } else if (values.includes("CLAIM_RECEIVED")) {
      return {
        or: [
          { workflowClaimStatesExist: false },
          {
            and: [
              { workflowClaimStatesExist: true },
              {
                batchClaimStateById: {
                  workflowClaimState: {
                    in: values.filter((v) => v !== "CLAIM_RECEIVED"),
                  },
                },
              },
            ],
          },
        ],
      };
    } else
      return {
        workflowClaimStatesExist: true,
        batchClaimStateById: {
          workflowClaimState: {
            in: values,
          },
        },
      };
  },
};

export { workflow };
