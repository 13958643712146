/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { searchableFields } from "./searchable/searchableFields";

class BatchClaimSearchFilter {
  filterMap: Map<any, any>;
  constructor(urlFilter = null) {
    this.filterMap = new Map();

    // if a search url was passed in then build the filter object from the url
    urlFilter && this.buildFromUrlFilter(urlFilter);
  }

  get gqlFilter() {
    const and: any[] = [];
    this.filterMap.forEach(({ filterFragment }) => {
      const frag = Object.assign({}, filterFragment);
      and.push(frag);
    });
    return { and };
  }

  get searchUrl() {
    const json = {};
    this.filterMap.forEach(({ value }, k) => {
      Object.assign(json, { [k]: value });
    });
    return JSON.stringify(json);
  }

  add(
    field: string,
    value: unknown,
    displayName: any,
    typeDef: {
      invalidValuesFilter: (arg0: any) => any;
      filterBuilder: (arg0: any) => any;
    },
    tag: any,
    renderComponent: any,
    options: any,
    query: any,
  ) {
    // If a `invalidValuesFilter()` function is defined inside a fields `typeDef` then this function will be run before adding values to the `filterMap`
    // Because this function may modify and remove items from the original values Object passed we check that `validatedValues` generated are not null or empty
    const validatedValues = typeDef.invalidValuesFilter
      ? typeDef.invalidValuesFilter(value)
      : value;
    if (
      validatedValues === null ||
      validatedValues == undefined ||
      (Array.isArray(validatedValues) && validatedValues.length === 0) ||
      (typeof validatedValues === "object" &&
        Object.keys(validatedValues).length == 0)
    ) {
      // If the values param passed OR the validatedValues after being ran through `invalidValuesFilter` is empty --> we remove from the filter map
      this.remove(field);
    } else {
      // we could filter on this validatedValues.replace(/\n|\r|\t|\u/g,'')
      // adds to the filter map
      this.filterMap.set(field, {
        filterFragment: typeDef.filterBuilder(validatedValues),
        displayName,
        value: validatedValues,
        typeDef,
        tag,
        renderComponent,
        options,
        query,
      });
    }
  }

  remove(field: any) {
    this.filterMap.delete(field);
  }

  buildFromUrlFilter(urlFilter: { [s: string]: unknown } | ArrayLike<unknown>) {
    Object.entries(urlFilter).forEach(([field, value]) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      searchableFields[field] &&
        this.add(
          field,
          value,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].displayName,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].typeDef,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].tag,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].renderComponent,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].options,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          searchableFields[field].query, // can be undefined
        );
    });
  }
}

export { BatchClaimSearchFilter };
