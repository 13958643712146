import { graphql } from "../../../../gql";

/* strip types
 : .*$
*/

export const claimQueryDoc = graphql(`
  query claim($id: UUID) {
    claim(id: $id) {
      __typename
      id
      icn
      findings {
        ...FindingFragment
      }
      autodor {
        claimId
        sinks {
          s3Key
          timestamp
        }
        autodorFindings {
          ...autodorFindingFragment
        }
      }
      itemizedBillLines {
        ...ItemizedBillLineFragment
      }
    }
  }
`);

export const providerClaimQueryDoc = graphql(`
  query providerClaim($id: UUID) {
    claim(id: $id) {
      __typename
      id
      icn
      itemizedBillLines {
        ...ProviderItemizedBillLineFragment
      }
    }
  }
`);

export const claimFindingsQueryDoc = graphql(`
  query claimFindings($id: UUID) {
    claim(id: $id) {
      __typename
      id
      icn
      findings {
        ...FindingFragment
      }
    }
  }
`);
