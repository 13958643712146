import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import type { ImperativePanelHandle } from "react-resizable-panels";

import { useResizeObserver } from "@alaffia-technology-solutions/hooks";

const EXPANDED_FILES_SIDEBAR_THRESHOLD = 688;
const COLLAPSED_SIZE_IN_PIXELS = 67;

interface UseExpandablePanelOptions {
  /**
   * The ref of the wrapper element.
   */
  wrapperRef: React.RefObject<Element>;
  /**
   * The threshold at which the files sidebar should be expanded.
   */
  expandedFilesSidebarThreshold?: number;
  /**
   * The size of a collapsed panel in pixels.
   */
  collapsedSizeInPixels?: number;
  /**
   * The selector for resize handle nodes of this panel.
   */
  resizeHandleSelector?: string;
  /**
   * Indicates whether the panel should be expanded by default.
   */
  defaultExpanded?: boolean;
}

interface GetExpandablePanelProps {
  /**
   * Specifies the default size of the panel.
   */
  defaultSize?: number;
}

/**
 * A hook that provides functionality to expand and collapse a panel.
 * @param {UseExpandablePanelOptions} options - The options to configure the hook.
 * @returns An object containing the expand and collapse functions, the panel's isExpanded state, and expandable panel prop getter function.
 */
export const useExpandablePanel = ({
  wrapperRef,
  expandedFilesSidebarThreshold = EXPANDED_FILES_SIDEBAR_THRESHOLD,
  collapsedSizeInPixels = COLLAPSED_SIZE_IN_PIXELS,
  resizeHandleSelector = "[data-panel-resize-handle-id]",
  defaultExpanded = false,
}: UseExpandablePanelOptions) => {
  const { width: wrapperWidth } = useResizeObserver({ ref: wrapperRef });

  const ref = useRef<ImperativePanelHandle>(null);
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  const collapsePanel = useCallback(() => {
    ref.current?.collapse();
  }, []);

  const expandPanel = useCallback(() => {
    ref.current?.expand();
  }, []);

  const onExpand = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const onCollapse = useCallback(() => {
    setIsExpanded(false);
  }, []);

  useEffect(() => {
    if (wrapperWidth && wrapperWidth <= expandedFilesSidebarThreshold) {
      collapsePanel();
    }
  }, [wrapperWidth, expandedFilesSidebarThreshold, collapsePanel]);

  const [collapsedSize, setCollapsedSize] = useState(10);

  useLayoutEffect(() => {
    const resizeHandles =
      document.querySelectorAll<HTMLDivElement>(resizeHandleSelector);

    let width = wrapperWidth;

    if (width !== 0) {
      resizeHandles.forEach((resizeHandle) => {
        width -= resizeHandle.offsetWidth;
      });

      setCollapsedSize((collapsedSizeInPixels / width) * 100);
    }
  }, [wrapperWidth, collapsedSizeInPixels, resizeHandleSelector]);

  const getExpandablePanelProps = useCallback(
    ({ defaultSize }: GetExpandablePanelProps = {}) => {
      return {
        onExpand,
        onCollapse,
        collapsedSize,
        defaultSize: defaultExpanded ? defaultSize : collapsedSize,
        ref,
        collapsible: true,
      };
    },
    [collapsedSize, defaultExpanded, onCollapse, onExpand],
  );

  return {
    expandPanel,
    collapsePanel,
    isExpanded,
    getExpandablePanelProps,
  };
};
