/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, message } from "antd";
import { gql, useMutation } from "urql";

import { batchClaimState } from "../../../fragments";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";

//mutation to update workflow state
const setDisputedFindingMutation = gql`
  mutation createDisputedFindings(
    $batchClaimIds: [UUID!]!
    $deleted: Boolean!
  ) {
    createDisputedFindings(
      input: { batchClaimIds: $batchClaimIds, deleted: $deleted }
    ) {
      disputedFindings {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

//disputedFinding
const typeActions = {
  setDispute: {
    title: "Set Dispute",
    value: "DISPUTED",
    actionCriteria: ({
      batchClaimState: {
        reported,
        disputedFindingClaimState,
        negotiationClaimState,
      },
    }: never) => {
      const satisfied =
        // must be reported
        reported &&
        // can't already be disputed
        disputedFindingClaimState === "NOT_DISPUTED" &&
        // claim isn't already being negotiated
        negotiationClaimState === "NOT_NEGOTIATING";

      return { satisfied };
    },
  },
  setNotDispute: {
    title: "Remove Dispute",
    value: "NOT_DISPUTED",
    actionCriteria: ({
      batchClaimState: { disputedFindingClaimState },
    }: any) => {
      const satisfied =
        // must be disputed in order to reomve the dispute
        disputedFindingClaimState === "DISPUTED";
      return { satisfied };
    },
  },
};

const actionSubMenuItem = ({
  action,
  value,
  batchClaims,
  actionCriteria,
  setDispute,
  title,
  setLoading,
}: any) => {
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setDispute({
          batchClaimIds: actionClaims.map(({ id }: any) => id),
          deleted: action === "setNotDispute",
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          void message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  const [, setDispute] = useMutation(setDisputedFindingMutation);

  return (
    <Menu.SubMenu
      key={`dispute-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Dispute"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions.dispute.includes(action),
        )
        .map(([action, { ...actionProps }]) =>
          actionSubMenuItem({
            action,
            batchClaims,
            setDispute,
            setLoading,
            ...actionProps,
          }),
        )}
    </Menu.SubMenu>
  );
};

const dispute = {
  title: "Dispute",
  subMenu: actionSubMenu,
};

export { dispute };
