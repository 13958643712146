/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Form, Input, message, Modal } from "antd";
import { gql, useMutation } from "urql";

import {
  adminAndAuditorWorkstationBatchClaimFragment,
  auditFindingWithReviewFragment,
  reviewedAuditFindingFragment,
} from "../../../../fragments";
import { UserContext } from "../../../context/user";
import { useClaimPostFindingRefresh } from "../../queries/claim/useClaimFindingsAndLine";

const declineAuditFindingMutation = gql`
  mutation declineAuditFinding(
    $auditFindingId: UUID!
    $authorId: UUID!
    $rationale: String!
  ) {
    createReviewedAuditFinding(
      input: {
        reviewedAuditFinding: {
          accepted: false
          auditFindingId: $auditFindingId
          authorId: $authorId
          rationale: $rationale
        }
      }
    ) {
      reviewedAuditFinding {
        ...reviewedAuditFindingFragment
        auditFinding {
          # because ONLY auditors & Admins can manipluate findings (Create, Decline, Review, etc) we don't select the return fragment based on users
          # and return the 'adminAndAuditorWorkstationBatchClaimFragment' by default
          ...auditFindingWithReviewFragment
          # update determined and potential amounts in the cache
          batchClaim {
            ...adminAndAuditorWorkstationBatchClaimFragment
            auditFindingCount: auditFindings(
              filter: { isActive: { equalTo: true } }
            ) {
              totalCount
              nodes {
                id
              }
            }
          }
        }
      }
    }
  }
  ${reviewedAuditFindingFragment}
  ${auditFindingWithReviewFragment}
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

const DeclineAuditFindingModal = (props: any) => {
  const { visible, auditFinding, setDecliningAuditFinding, afclFilter } = props;

  const { id: authorId } = React.useContext(UserContext);

  const [form] = Form.useForm();
  const [{ fetching }, declineAuditFinding] = useMutation(
    declineAuditFindingMutation,
  );
  const claimRefresher = useClaimPostFindingRefresh();

  const cancel = () => {
    console.debug("Cancel!");
    form.resetFields();
    setDecliningAuditFinding({ visible: false });
  };

  const ok = async () => {
    console.debug("check form...");
    return form
      .validateFields()
      .then(async ({ rationale }) => {
        console.debug("Form is valid. Declining audit");
        // send mutation to graphql
        const { data, error } = await declineAuditFinding({
          auditFindingId: auditFinding.id,
          authorId,
          rationale,
          afclFilter,
        });
        if (error) {
          console.log("Error declining finding", error);
          void message.error(error.message);
        } else {
          // todo dedupe this with the same code in claimWorkspaceAuditFinding 'accept' -> maybe a hook for any of these updates??
          const claimId =
            data.createReviewedAuditFinding?.reviewedAuditFinding?.auditFinding
              ?.batchClaimId;
          const ibLineId =
            data.createReviewedAuditFinding?.reviewedAuditFinding?.auditFinding
              ?.batchClaimLineItemAudit?.batchClaimLineItem?.ibLineId ??
            data.createReviewedAuditFinding?.reviewedAuditFinding?.auditFinding
              ?.batchClaimLineItemAudit?.batchClaimLineItem?.alaRowId;

          if (claimId && ibLineId) {
            await claimRefresher({
              claimId,
              ibLineIds: [ibLineId],
            }).catch((err) => {
              console.log(
                "Error refreshing claim itemizedBillLines and Findings",
                err,
              );
              return { data: null, fetching: false, error: err };
            });
          }
        }

        console.debug("declined audit finding:", data);

        form.submit();
        cancel();
      })
      .catch((error) => console.error("new audit has errors:", error));
  };

  return (
    <Modal
      title="Decline Claim Review Finding"
      open={visible}
      onOk={ok}
      confirmLoading={fetching}
      onCancel={cancel}
    >
      <Form form={form}>
        <Form.Item
          key="rationale"
          name="rationale"
          label="Rationale"
          rules={[
            {
              required: true,
              message: "Enter a rationale for declining this finding",
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export { DeclineAuditFindingModal };
