import { useQuery } from "@tanstack/react-query";

import { neverRefetchOptions, QUERY_LOADER_SCOPE } from "./constants";

export interface PreloadJrWorkerFunctionProps {
  workerPath: string;
  enginePath: string;
  fontPath: string;
  fontInfoPath: string;
  licenseSN: string;
  licenseKey: string;
}

type PreloadJrWorkerFunction = (
  options: PreloadJrWorkerFunctionProps,
) => Worker;

export const QUERY_KEY_FOXIT_JR_WORKER = [
  ...QUERY_LOADER_SCOPE,
  "preloadJrWorker",
];

export const getPreloadWorkerScript = async () => {
  if (typeof window === "undefined") {
    return null;
  }

  const importedModule: unknown = await import(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/preload-jr-worker"
  );

  if (
    importedModule &&
    typeof importedModule === "object" &&
    "default" in importedModule
  ) {
    return (importedModule as { default: PreloadJrWorkerFunction }).default;
  } else if (typeof importedModule === "function") {
    return importedModule as PreloadJrWorkerFunction;
  } else {
    console.error(
      "Unexpected '@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/preload-jr-worker' module value.\n" +
        "Expected: `{default: Function}` or `Function`.\n" +
        "Got: ",
      importedModule,
    );
    throw new Error("Unknown module format for preloadJrWorker");
  }
};

export interface UseCreateFoxitJrWorkerConfig
  extends PreloadJrWorkerFunctionProps {
  key?: string;
}

export const useSuspenseCreateFoxitJrWorker = ({
  key = "default",
  ...jrConfig
}: UseCreateFoxitJrWorkerConfig) => {
  return useQuery({
    queryKey: [...QUERY_KEY_FOXIT_JR_WORKER, { key, ...jrConfig }],
    queryFn: async () => {
      return createFoxitJrWorker(jrConfig);
    },
    suspense: true,
    ...neverRefetchOptions,
  });
};

export const createFoxitJrWorker = async (
  options: PreloadJrWorkerFunctionProps,
) => {
  const preloadJrWorker = await getPreloadWorkerScript();

  if (!preloadJrWorker) {
    return null;
  }

  return preloadJrWorker(options);
};
