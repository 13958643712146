import { clsx } from "clsx";
import type { ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";

export const createCn = (prefix?: string) => {
  const twMerge = extendTailwindMerge({
    prefix,
  });

  return (...inputs: ClassValue[]) => twMerge(clsx(inputs));
};

export const cn = createCn("af-");
