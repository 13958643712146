/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import accounting from "accounting-js";

const auditFindingMetaDataFromRuleTypes = (ruleType: any) => {
  // DOC: The switch statement drops through till it hits either a return or a 'break' statement
  // so it makes it easy to have two or more keys return the same value without duplication

  // DOC: (MV)  Per US184 these are kept for historical data but with the new denial
  // they won't have replacement codes in effort to standardize the form
  switch (ruleType) {
    case "UB_CLAIM_LINE_NCCI_MUE": // deprecated/historical - UB04 Claim Line NCCI MUE Violation
      return {
        metaDataNcciMueOhpMueValue: {
          label: "MUE Limit",
        },
        metaDataNcciMueAjudicationValue: {
          label: "MAI",
        },
      };
    case "UB_CLAIM_LINE_NCCI_PTP": // deprecated/historical - UB04 Claim Line NCCI PTP
      return {
        metaDataNcciPtpModifier: {
          label: "Modifier",
        },
        metaDataNcciPtpCode: {
          label: "Conflicting Proc. Code",
        },
      };

    case "UB_CLAIM_LINE_EM": // deprecated/historical - UB04 Claim Line EM Rule Violation
      return {
        metaDataEmCode: {
          label: "EM Code",
        },
        metaDataEmOriginalLevel: {
          label: "Original Level",
        },
        metaDataEmCorrectLevel: {
          label: "Correct Level",
        },
      };
    case "UB_CLAIM_LINE_AGE_MODIFIER": // deprecated/historical - Age Modifier Rule Violation
      return {};
    case "UB_CLAIM_LINE_GENDER_MODIFIER": // deprecated/historical - Gender Modifier Rule Violation
      return {};
    case "UB_CLAIM_LINE_ADD_ON_CODE": //deprecated - Add on Code Violation
      return {
        metaDataAocPrimaryCode: {
          label: "Primary Code(s)",
        },
      };
    case "UB_CLAIM_LINE_PRICING": //deprecated - Incorrect Pricing
      return {
        metaDataPricingCorrectPrice: {
          label: "Correct Price",
          formatter: accounting.formatMoney,
        },
      };
    case "UB_CLAIM_LINE_UNITS": //deprecated - Incorrect Units
      return {
        metaDataUnitsCorrectUnits: {
          label: "Correct Units",
        },
      };
    default:
      return {};
  }
};

export { auditFindingMetaDataFromRuleTypes };
