export interface FileEntity {
  id: string;
  archived: boolean;
  fileName: string;
  readyToSearch: boolean;
  createdAt: string;
}
export interface Message {
  id: string;
  content: string;
  createdAt: string;
  response: Response | null;
}

export interface ResponsePage {
  pageNumber: number;
  file: Pick<FileEntity, "id" | "fileName">;
  textMatched: string;
}

export enum PromptResponseRatingType {
  DOWN = "DOWN",
  UP = "UP",
}

export interface ResponseRating {
  currentUserRating?: PromptResponseRatingType | null;
  downRatings: number;
  upRatings: number;
}

export interface Response {
  id: string;
  responseText?: string;
  contextFiles?: Pick<FileEntity, "id" | "archived" | "fileName">[];
  pages?: ResponsePage[];
  createdAt: string;
  ratings?: ResponseRating | null;
}
