import type {
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedElementNode,
  SerializedLexicalNode,
  SerializedTextNode,
} from "lexical";
import { $applyNodeReplacement, ElementNode, TextNode } from "lexical";

export interface SerializedKeywordNode extends SerializedTextNode {
  type: ReturnType<(typeof KeywordNode)["getType"]>;
}

export class KeywordNode extends TextNode {
  static getType() {
    return "keyword" as const;
  }

  static clone(node: KeywordNode): KeywordNode {
    return new KeywordNode(node.__text, node.__key);
  }

  constructor(text: string, key?: NodeKey) {
    super(text, key);
  }

  createDOM(_config: EditorConfig): HTMLElement {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-keyword", "true");
    element.classList.add(
      "af-inline",
      "af-rounded-sm",
      "af-border",
      "af-border-border",
      "af-bg-gray-200",
      "af-p-0.5",
    );
    element.innerText = this.__text;

    return element;
  }

  static importJSON(serializedNode: SerializedKeywordNode): KeywordNode {
    return $createKeywordNode(serializedNode.text);
  }

  exportJSON(): SerializedKeywordNode {
    return {
      ...super.exportJSON(),
      type: KeywordNode.getType(),
    };
  }
}

export function $createKeywordNode(text: string): KeywordNode;
export function $createKeywordNode(textNode: TextNode): KeywordNode;
export function $createKeywordNode(
  textOrTextNode: string | TextNode,
): KeywordNode {
  const text =
    typeof textOrTextNode === "string" ? textOrTextNode : textOrTextNode.__text;
  const keywordNode = new KeywordNode(text);
  return $applyNodeReplacement(keywordNode);
}

export function $isKeywordNode(
  node: LexicalNode | null | undefined,
): node is KeywordNode {
  return node instanceof KeywordNode;
}

export type SerializedKeywordListNode = SerializedLexicalNode;

export class KeywordListNode extends ElementNode {
  static getType() {
    return "keyword-list" as const;
  }

  static clone(node: KeywordListNode): KeywordListNode {
    return new KeywordListNode(node.__key);
  }

  createDOM(): HTMLElement {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-keyword-list", "true");
    return element;
  }

  updateDOM(): boolean {
    return false;
  }

  exportJSON(): SerializedElementNode<SerializedKeywordListNode> {
    return {
      ...super.exportJSON(),
      type: KeywordListNode.getType(),
    };
  }

  static importJSON(): KeywordListNode {
    return $createKeywordListNode();
  }
}

export function $createKeywordListNode(): KeywordListNode {
  return new KeywordListNode();
}

export function $isKeywordListNode(
  node: LexicalNode | null | undefined,
): node is KeywordListNode {
  return node instanceof KeywordListNode;
}
