/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileTextOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { formatMoney } from "accounting-js";
import { Badge, Tag, Tooltip } from "antd";
import moment from "moment-timezone";

import { BatchClaimOverviewTriggerButton } from "../audit/batchClaimOverview/batchClaimOverviewTriggerButton";
import { ClaimActionMenu } from "../claimActionMenu/claimActionMenu";
import { EnumClaimStates } from "../claimState/enumClaimStates";
import { assignment, dueDate } from "../claimState/stateTypes";
import { usePdfViewerDialog } from "../documentViewer/PdfViewerProvider";
import {
  ColTitle,
  formatDateRange,
  numberOfWeekDaysBetweenTwoDates,
} from "./util";

const generateClaimListColumnList = ({
  permissions,
  allowedClaimListColumns,
  props: {
    setIsActionLoading,
    setClickedOnBatchClaim,
    setShowClaimDetails,
    setViewDocumentNamesModalVisible,
  },
  customColumnsList,
}: any) => {
  const possibleColumns = [
    {
      key: "claimNumber",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Identifying Claim Number"
          title="Claim Number"
          id="claim-number"
        />
      ),
      render: (batchClaim: any) => (
        <span
          id={`claim-list-claim-number-cell`}
          data-cy={`claim-list-claim-number-cell`}
          css={css`
            display: flex;
          `}
        >
          <BatchClaimOverviewTriggerButton
            batchClaim={batchClaim}
            onClickFunction={() => {
              setClickedOnBatchClaim(batchClaim);
              setShowClaimDetails(true);
            }}
          />
          {batchClaim.icn}
        </span>
      ),
      showSorterTooltip: {
        title: "Sort by Claim number",
      },
      sorter: {
        compare: () => {
          return ["ICN"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "daysOpen",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of Days since claim has been opened"
          title="Days Open"
          id="days-open"
        />
      ),
      render: ({
        createdAt,
        batchClaimState: { workflowClaimState, invoiceClaimState },
      }: any) => {
        // this is different than 'reviewed' this indicates that the claim
        // has officially been "resolved/closed" (whether that means it has been paid or archived) -- 'the process has come to the end'
        if (
          ["CLOSED"].includes(workflowClaimState) ||
          ["SENT", "PAID"].includes(invoiceClaimState)
        )
          return (
            <div
              id={`claim-list-days-open-cell-closed`}
              data-cy={`claim-list-days-open-cell-closed`}
            >
              Closed
            </div>
          );
        else {
          /*
          How we define `DaysOpen`:
            - take the createdAt date and the date NOW and convert both to EST
            - take the createdAt date and select the number of days between then and present date
              - If createdAt date is after 5pm EST on a work day or NOT on a workday we “start” the clock on the next valid date
              - Subtract all weekend days between those 2 dates
              - FIX ME (Not Implemented right now): Add code that subtracts the number of Alaffia company Holidays in between the 2 dates
          */
          // use moment to convert date is in EST beforre converting back to js dateTime and parsing
          const todayDateEST = moment(new Date(Date.now()).valueOf())
            .tz("America/New_York")
            .toDate();
          const createdAtDateEST = moment(new Date(createdAt))
            .tz("America/New_York")
            .toDate();
          const daysOpen = numberOfWeekDaysBetweenTwoDates(
            createdAtDateEST,
            todayDateEST,
          );

          return (
            <Tooltip
              title={`Number Of business days since receipt Date: ${moment(
                createdAt,
              )
                .utc()
                .format("MM/DD/YYYY")}`}
            >
              <div
                id={`claim-list-days-open-cell-open`}
                data-cy={`claim-list-days-open-cell-open`}
              >
                {daysOpen === null ? "-" : <Tag>{daysOpen}</Tag>}
              </div>
            </Tooltip>
          );
        }
      },
      showSorterTooltip: {
        title: "Sort by Days Open",
      },
      sorter: {
        compare: () => {
          return ["CREATED_AT"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "dueDate",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Due date for claim review completion (UTC)"
          title="Due Date"
          id="due-date"
        />
      ),
      render: (batchClaim: any) =>
        // due date has it's own render function, since there are a couple of ways to render a due date
        dueDate.render(batchClaim, { isCompact: false }),
      showSorterTooltip: {
        title: "Sort By Due Date",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_STATE_BY_ID__DUE_DATE"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "assignees",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Users assigned to review the claim"
          title="Assignees"
          id="assignees"
        />
      ),
      render: (batchClaim: any) =>
        // assignment has it's own render function
        assignment.render(batchClaim),
      showSorterTooltip: {
        title: "Sort By Assignee Names",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_NAME"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "dateOfService",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Date range for date of service (UTC)"
          title="Date of Serv."
          id="date-of-service"
        />
      ),
      render: (_: any, { id, dateOfServiceStart, dateOfServiceEnd }: any) => (
        <div
          key={`claim-list-date-of-service-cell-${id}`}
          id={`claim-list-date-of-service-cell-${id}`}
        >
          {formatDateRange(dateOfServiceStart, dateOfServiceEnd)}
        </div>
      ),
      showSorterTooltip: {
        title: "Sort by Date Of Service Start",
      },
      sorter: {
        compare: () => {
          return ["DATE_OF_SERVICE_START", "DATE_OF_SERVICE_END"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "dateAdmitDischarge",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Date range for Patient admission to discharge (UTC)"
          title="Date of Admit to Discharge."
          id="date-of-admit-to-discharge"
        />
      ),
      render: (_: any, { id, dateAdmit, dateDischarge }: any) => (
        <div
          key={`claim-list-date-of-admit-to-discharge-cell-${id}`}
          id={`claim-list-date-of-admit-to-discharge-cell-${id}`}
        >
          {formatDateRange(dateAdmit, dateDischarge)}
        </div>
      ),
      showSorterTooltip: {
        title: "Sort by Date Of Admission",
      },
      sorter: {
        compare: () => {
          return ["DATE_ADMIT", "DATE_DISCHARGE"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "provider",
      dataIndex: "provider",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Provider name, ID, & NPI number"
          title="Provider"
          id="provider-name"
        />
      ),
      render: ({ name, id, npi }: any) => (
        <Tooltip title={`ID: ${id}, NPI: ${npi}`}>
          <div
            key={`claim-list-provider-name-cell-${npi}`}
            id={`claim-list-provider-name-cell-${npi}`}
          >
            {name}
          </div>
        </Tooltip>
      ),
      showSorterTooltip: {
        title: "Sort by Provider Name",
      },
      sorter: {
        compare: () => {
          return ["PROVIDER_BY_PROVIDER_ID__NAME"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "billedAmount",
      dataIndex: "amountCharged", // database field
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Billed amount on Claim"
          title="Billed Amount"
          id="billed-amount"
        />
      ),
      render: (amountCharged: any) => formatMoney(amountCharged),
      showSorterTooltip: {
        title: "Sort by Billed Amount",
      },
      sorter: {
        compare: () => {
          return ["AMOUNT_CHARGED"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "allowedAmount",
      dataIndex: "amountReimbursed", // database field
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Allowed amount on Claim"
          title="Allowed Amount"
          id="allowed-amount"
        />
      ),
      render: (amountReimbursed: any) => formatMoney(amountReimbursed),
      showSorterTooltip: {
        title: "Sort by Allowed Amount",
      },
      sorter: {
        compare: () => {
          return ["AMOUNT_REIMBURSED"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "determinedDiscrepantAmount",
      dataIndex: "batchClaimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Determined Discrepant amount on claim"
          title="Discrepant Amount"
          id="discrepant-amount"
        />
      ),
      render: ({ determinedDiscrepantAmount }: any) => (
        <div>{formatMoney(determinedDiscrepantAmount)}</div>
      ),
      showSorterTooltip: {
        title: "Sort By Determined Discrepant amount",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_STATE_BY_ID__DETERMINED_DISCREPANT_AMOUNT"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "determinedAdjustedAllowedAmount",
      dataIndex: "batchClaimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Adjusted Allowed Amount on Claim"
          title="Adj Allowed Amount"
          id="determined-adjusted-allowed-amount"
        />
      ),
      render: ({ determinedAdjustedAllowedAmount }: any) =>
        formatMoney(determinedAdjustedAllowedAmount),
      showSorterTooltip: {
        title: "Sort By Determined Adjusted Allowed Amount",
      },
      sorter: {
        compare: () => {
          return [
            "BATCH_CLAIM_STATE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT",
          ];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "potentialAdjustedAllowedAmount",
      dataIndex: "batchClaimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Adjusted Allowed Amount on Claim"
          title="Potential Adj Allowed Amount"
          id="potential-adjusted-allowed-amount"
        />
      ),
      render: ({ potentialAdjustedAllowedAmount }: any) =>
        formatMoney(potentialAdjustedAllowedAmount),
      showSorterTooltip: {
        title: "Sort By Potential Adjusted Allowed Amount",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_STATE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "determinedClientSavings",
      dataIndex: "batchClaimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Determined savings on Claim"
          title="Determined Savings"
          id="determined-savings"
        />
      ),
      render: ({ determinedClientSavings }: any) => {
        return formatMoney(determinedClientSavings);
      },
      showSorterTooltip: {
        title: "Sort By Determined Client Savings",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },

    {
      key: "realizedClientSavings",
      dataIndex: "batchClaimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Realized savings on Claim"
          title="Realized Savings"
          id="realized-savings"
        />
      ),
      render: ({ determinedClientSavings, isRealized }: any) => {
        // `realized client savings` = sum of a claim's `determined client savings` for all claims that are workflow stat COMPLETED and invoice state SENT or PAID
        return isRealized ? (
          formatMoney(determinedClientSavings)
        ) : (
          <Tag>Savings not yet Realized</Tag>
        );
      },
      showSorterTooltip: {
        title: "Sort By Realized Client Savings",
      },
      sorter: {
        compare: () => {
          return [
            "BATCH_CLAIM_STATE_BY_ID__IS_REALIZED",
            "BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS",
          ];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "documents",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Uploaded supporting Documentation for this claim"
          title="Docs"
          id="documents"
        />
      ),
      render: (batchClaim: any) => {
        return (
          <DocumentsCell
            batchClaim={batchClaim}
            permissions={permissions}
            setViewDocumentNamesModalVisible={setViewDocumentNamesModalVisible}
            setClickedOnBatchClaim={setClickedOnBatchClaim}
          />
        );
      },
      showSorterTooltip: {
        title: "Sort by Document Count",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_VALID_UPLOADED_AUDIT_DOCUMENTATION_COUNT"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "providerActive",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Is the claim active and visible for Providers"
          title="Prov. Active"
          id="provider-active"
        />
      ),
      render: (batchClaim: any) => {
        return (
          <EnumClaimStates
            stateTypesToShow={["providerActive"]}
            id={`claim-list-claim-state-cell-provider-active-icons`}
            data-cy={`claim-list-claim-state-cell-provider-active-icons`}
            batchClaim={batchClaim}
            useIcons={true}
            showNAText={true}
          />
        );
      },
      showSorterTooltip: {
        title: "Sort by is claim visible for Providers",
      },
      sorter: {
        compare: () => {
          return ["BATCH_CLAIM_STATE_BY_ID__IS_PROVIDER_ACTIVE"];
        },
        multiple: 1, // this field should always exist but with a value of 1
      },
    },
    {
      key: "claimState",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Current State of the Claim"
          title="State"
          id="claim-state"
        />
      ),
      render: (batchClaim: any) => {
        return (
          <EnumClaimStates
            stateTypesToShow={[
              "workflow",
              "documentation",
              "signOff",
              "invoice",
              "negotiation",
              "onHold",
              "report",
              "dispute",
            ]} // everything but findings
            id={`claim-list-claim-state-cell-state-icons`}
            data-cy={`claim-list-claim-state-cell-state-icons`}
            batchClaim={batchClaim}
            useIcons={true}
            showNAText={true}
          />
        );
      },
    },
    {
      key: "claimActions",
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Set of Actions that can be taken on the Claim"
          title="Actions"
          id="claim-actions"
        />
      ),
      render: (_: any, batchClaim: any) => (
        <div
          id={`claim-list-claim-actions-cell-action-menu`}
          data-cy={`claim-list-claim-actions-cell-action-menu`}
        >
          <ClaimActionMenu
            setIsActionLoading={setIsActionLoading}
            batchClaims={[batchClaim]}
          />
        </div>
      ),
    },
  ];
  return [
    ...possibleColumns.filter(
      ({ key }) =>
        allowedClaimListColumns.has(key) &&
        (!customColumnsList || customColumnsList?.has(key)),
    ),
  ];
};

interface DocumentsCellProps {
  permissions: { viewDocs?: boolean; viewDocList?: boolean };
  batchClaim: any;
  setClickedOnBatchClaim: (batchClaim: any) => void;
  setViewDocumentNamesModalVisible: (visible: boolean) => void;
}

const DocumentsCell = ({
  permissions,
  batchClaim,
  setClickedOnBatchClaim,
  setViewDocumentNamesModalVisible,
}: DocumentsCellProps) => {
  const {
    s3AuditDocumentations: { totalCount },
    id,
  } = batchClaim;

  const { open } = usePdfViewerDialog();

  return totalCount > 0 ? (
    <Tooltip title="View Uploaded Documentation">
      <Badge
        css={{
          cursor: "pointer",
        }}
        data-cy={`claim-list-documents-cell-document-count`}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        id={`claim-list-documents-cell-document-count`}
        count={totalCount}
        size="small"
        onClick={() => {
          if (
            totalCount > 0 &&
            permissions.viewDocs &&
            permissions.viewDocList
          ) {
            open({ claimId: id });
          } else if (totalCount > 0 && permissions.viewDocList) {
            setClickedOnBatchClaim(batchClaim);

            setViewDocumentNamesModalVisible(true);
          }
        }}
      >
        <FileTextOutlined />
      </Badge>
    </Tooltip>
  ) : (
    <i>0</i>
  );
};

export { generateClaimListColumnList };
