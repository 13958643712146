import * as React from "react";
import { IconEye, IconEyeOff } from "@tabler/icons-react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const inputVariants = cva("af-relative", {
  variants: {
    iconPosition: {
      left: "af-absolute af-left-3 af-top-1/2 -af-translate-y-1/2 af-transform af-text-muted-foreground",
      right:
        "af-absolute af-left-auto af-right-3 af-top-1/2 -af-translate-y-1/2 af-transform af-text-muted-foreground",
    },
  },
  defaultVariants: {
    iconPosition: "left",
  },
});

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement>,
    VariantProps<typeof inputVariants> {
  icon?: React.ReactNode;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      icon,
      iconPosition = type === "password" ? "right" : "left",
      ...props
    },
    ref,
  ) => {
    const isPassword = type === "password";
    const hasIcon = isPassword || icon;
    const [isPasswordVisible, setIsPasswordVisible] = React.useState(false);
    const togglePasswordVisibility = () =>
      setIsPasswordVisible((wasPasswordVisible) => !wasPasswordVisible);

    const renderIcon = () => {
      return isPassword ? (
        <button
          className={cn(
            inputVariants({ iconPosition }),
            "af-rounded hover:af-text-gray-900 focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2",
            className,
          )}
          onClick={togglePasswordVisibility}
          type="button"
        >
          {isPasswordVisible ? (
            <IconEyeOff className="af-h-5 af-w-5" />
          ) : (
            <IconEye className="af-h-5 af-w-5" />
          )}
        </button>
      ) : (
        <span className={cn(inputVariants({ iconPosition }))}>{icon}</span>
      );
    };

    return (
      <>
        {hasIcon ? (
          <div className="af-relative af-h-10">
            {iconPosition !== "right" && renderIcon()}
            <input
              type={
                isPassword ? (isPasswordVisible ? "text" : "password") : type
              }
              className={cn(
                "af-flex af-h-full af-w-full af-rounded-md af-border af-border-input af-bg-white af-py-1 af-text-sm af-ring-offset-background file:af-border-0 file:af-bg-transparent file:af-text-sm file:af-font-medium placeholder:af-text-gray-500 focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2 disabled:af-cursor-not-allowed disabled:af-opacity-50",
                className,
                iconPosition !== "right"
                  ? "af-pl-10 af-pr-4"
                  : "af-pl-4 af-pr-10",
              )}
              ref={ref}
              {...props}
            />
            {iconPosition === "right" && renderIcon()}
          </div>
        ) : (
          <input
            type={type}
            className={cn(
              "af-flex af-h-10 af-w-full af-rounded-md af-border af-border-input af-bg-white af-px-3 af-py-2 af-text-sm af-ring-offset-background file:af-border-0 file:af-bg-transparent file:af-text-sm file:af-font-medium placeholder:af-text-gray-500 focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2 disabled:af-cursor-not-allowed disabled:af-opacity-50",
              className,
            )}
            ref={ref}
            {...props}
          />
        )}
      </>
    );
  },
);
Input.displayName = "Input";

export { Input };
