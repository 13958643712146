import React from "react";
import { Checkbox, Form, Tooltip } from "antd";

import { isSet } from "../../../../util/isSet";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

export type RepriceCheckboxProps = CommonFieldProps & {
  hoverText: string;
  disabled: boolean;
};

export const RepriceCheckbox: React.FC<RepriceCheckboxProps> = ({
  dataKey,
  label = "Reprice?",
  hoverText,
  disabled,
  initialValues,
  formLayout,
  className,
}) => {
  const { defaultValue } = useInitialValues<boolean>(dataKey, initialValues);
  return (
    <Tooltip title={hoverText}>
      <Form.Item
        {...formKey(dataKey)}
        {...formLayout}
        className={className}
        valuePropName="checked"
        initialValue={isSet(defaultValue) && defaultValue}
        tooltip={<div>{hoverText}</div>}
      >
        <Checkbox disabled={disabled}>{label}</Checkbox>
      </Form.Item>
    </Tooltip>
  );
};
