/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
const createAuditFindingError = (error: any) => {
  console.error(error);
  const err = `Error creating audit finding${
    error.graphQLErrors
      ? error.graphQLErrors.map(({ message }: any) =>
          //display all the erros in the errors array
          //TODO: update to audit_finding_pkey
          //check if the error is a primary key error - which indicates
          //that this finding is a duplicate
          message.match(/audit_finding_claim_line_pkey/g)
            ? `: This finding already exists, please review the existing finding`
            : `: Please contact support`,
        )
      : //if all else fails, log to the console with error details
        console.error("Create Claim Review Finding Error", error.message)
  }
  `;

  console.error("Create Claim Review Finding Error", error.message);
  return err;
};

const reportServiceErr = (error: any) => {
  const err = error.message.replace(/\[GraphQL\] /, "");

  let responseError;
  try {
    responseError = JSON.parse(err).errors[0].message.replace(
      /^Internal.*:/,
      ":",
    );
  } catch (e) {
    console.log(e);
  }

  if (responseError) {
    responseError = responseError.replace(
      "no claim data for filter",
      "all findings already reported",
    );
  } else {
    responseError = ": " + err;
  }

  return responseError;
};

export { reportServiceErr, createAuditFindingError };
