/* eslint-disable no-constant-condition */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { css } from "@emotion/react";
import { Select, Typography } from "antd";

const RangeSelectFilterComponent = ({
  displayName,
  query, // queryData, isQueryFetching are unused in 'RangeSelectFilterComponent'
  filter,
  field,
  typeDef,
  renderComponent,
  options,
  tag,
}: any) => {
  const getDefaultValue = (keywords: any[]) => {
    const obj: any = {};
    keywords.forEach((keyword) => {
      obj[keyword] =
        !filter.filterMap.has(field) ||
        [undefined, null].includes(filter.filterMap.get(field).value)
          ? null
          : typeDef.defaultValueGenerator
          ? typeDef.defaultValueGenerator(filter.filterMap.get(field).value)
          : filter.filterMap.get(field).value[keyword];
    });
    return obj;
  };

  const [values, setValues] = React.useState(
    getDefaultValue(typeDef.filterType.map(({ keyword }: any) => keyword)),
  ); // always returns an object with keywords that map to values

  return (
    <div>
      <Typography.Title level={5}>{displayName}:</Typography.Title>
      <div
        css={css`
          margin-top: 5px;
        `}
      >
        {typeDef.filterType.map(({ keyword, label }: any) => (
          <span
            key={`${displayName
              .toLowerCase()
              .replaceAll(" ", "-")}-${keyword}-search-outer-span`}
            id={`${displayName
              .toLowerCase()
              .replaceAll(" ", "-")}-${keyword}-search-outer-span`}
            css={css`
              padding-right: 15px;
            `}
          >
            <span
              key={`${displayName
                .toLowerCase()
                .replaceAll(" ", "-")}-${keyword}-search-label-span`}
              id={`${displayName
                .toLowerCase()
                .replaceAll(" ", "-")}-${keyword}-search-label-span`}
              css={css`
                padding-right: 4px;
              `}
            >
              {label}:
            </span>
            {typeDef.rangeSelectComponent({
              key: `${displayName
                .toLowerCase()
                .replaceAll(" ", "-")}-${keyword}-search-item`,
              id: `${displayName
                .toLowerCase()
                .replaceAll(" ", "-")}-${keyword}-search-item`,
              onChange: (value: any) => {
                const newValues = { ...values };
                if (typeDef.isValueValid(value)) {
                  newValues[keyword] = value; // updates the value
                } else {
                  delete newValues[keyword];
                }
                setValues(newValues);
                filter.add(
                  field,
                  newValues,
                  displayName,
                  typeDef,
                  tag,
                  renderComponent,
                  options,
                  query, // likely undefined/null
                );
              },
              defaultValue: getDefaultValue([keyword])[keyword],
            })}
            <br />
            <br />
          </span>
        ))}
      </div>
    </div>
  );
};

const SelectFilterComponent = ({
  displayName,
  query,
  queryData,
  isQueryFetching,
  filter, //batchClaimSearchFilter Class instance
  field, // searchableField name
  typeDef, // Static information & functions required unique to this searchable Field types [filterBuilder, selectType]
  options, // The function for calculating the options of the select
  renderComponent,
  tag,
  userContext, // passed from the user.js context
}: any) => {
  const { userType, permissions } = userContext;
  const additionalProps: any = {};

  // When we define a searchableField to get it's selectable values list from a query (instead of statically set)
  // and the query Object has the `autoComplete` field added to it.
  // Allowing the user to manage which values are returned based on partial search
  if (query?.autoComplete) {
    const {
      getResults,
      autoCompleteState: { input, setInput },
    } = query.autoComplete;

    additionalProps.dropdownRender = (node: any) => {
      const meetsAutoCompleteReq =
        query.autoComplete.meetsAutoCompleteRequirement({
          input,
          userType: userContext.userType,
        });

      const results = !meetsAutoCompleteReq.result
        ? null
        : getResults({
            query,
            isQueryFetching,
            queryData,
            typeDef,
          });

      const msg = isQueryFetching
        ? "Searching..."
        : !meetsAutoCompleteReq.result
        ? meetsAutoCompleteReq.reason
        : !results
        ? `No results for '${input}'`
        : undefined;

      return (
        <div
          css={css`
            ${!results || !meetsAutoCompleteReq.result || true
              ? "padding-left: 5px;"
              : ""}
          `}
        >
          {msg}
          {results && node}
        </div>
      );
    };
    additionalProps.onSearch = (v: any) => {
      setInput(v);
    };
    // re-add the option filtering input value if they click away and back,
    // since otherwise the list is still filtered (potentially by the control,
    // e.g. post-query, like searching 1111 then filtering results to 111122
    // without retriggering the query), so seeing the filter string clarifies
    // that the field is still filtered:
    additionalProps.searchValue = input;
  }

  additionalProps.autoClearSearchValue = false;

  return (
    <div data-cy={`search-filter-add-container-${field}`}>
      <Typography.Title level={5}>{displayName}:</Typography.Title>
      <Select
        data-cy={`search-filter-add-select-${field}`}
        id={`search-filter-add-select-id-${field}`}
        css={css`
          width: 100%;
          margin-top: 5px;
        `}
        // split the input on paste and uses these as delimiters (NOTE: order matters)
        //  - For some reason I can't explain, stand-alone \r in input won't parse correctly unless it's arg 0 here (mv)
        tokenSeparators={["\r", "\r\n", "\n", ", ", ","]}
        placeholder={`Select ${displayName}`}
        loading={isQueryFetching || false}
        mode={typeDef.selectType}
        optionFilterProp="label" // always search label
        showSearch={true} // allow search for single-selects too
        defaultValue={
          filter.filterMap.has(field) ? filter.filterMap.get(field).value : []
        }
        /*
          If the searchableField doesnt use a query then the field's options function will
          only use the 'typeDefs' param passed
        */
        options={options({
          field,
          query,
          isQueryFetching,
          queryData,
          typeDef,
          permissions,
          userType,
        })}
        onChange={(value) => {
          filter.add(
            field,
            value,
            displayName,
            typeDef,
            tag,
            renderComponent,
            options,
            query,
          );
        }}
        {...additionalProps}
      />
    </div>
  );
};

export { RangeSelectFilterComponent, SelectFilterComponent };
