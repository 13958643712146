import type { FC } from "react";
import { Form, Input } from "antd";

import { FormExtra } from "../../../../shared/components/form/formExtra";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

export type RationaleProps = CommonFieldProps & object;

export const Rationale: FC<RationaleProps> = ({
  dataKey,
  label = "Rationale",
  initialValues,
  formLayout = {},
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<string>(
    dataKey,
    initialValues,
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      rules={[
        {
          required: true,
          message: "Enter a rationale for this finding",
        },
      ]}
      initialValue={defaultValue}
      extra={
        <FormExtra visible={!!existingFindingValue}>
          <div tw="af-whitespace-pre-line af-max-h-12 af-overflow-y-auto">
            {existingFindingValue}
          </div>
        </FormExtra>
      }
      {...formLayout}
    >
      <Input.TextArea rows={4} />
    </Form.Item>
  );
};
