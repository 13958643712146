/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext, useMemo } from "react";
import type { CSSInterpolation } from "@emotion/serialize";
import { css } from "twin.macro";

import { DesignProvider } from "../../../components/app/design/designProvider";

export interface TableStyleProviderProps {
  width: number;
  height: number;
  style?: CSSInterpolation[] | CSSInterpolation;
  children: React.ReactElement;
}

const tableStyleProvider: React.FC<TableStyleProviderProps> = ({
  width,
  height,
  style,
  children,
}) => {
  const {
    options: { darkMode },
  } = useContext(DesignProvider);

  const cls = useMemo(() => {
    return css(style);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [darkMode, style]);

  return (
    <div
      className={`ag-theme-alpine${darkMode ? "-dark" : ""}`}
      css={cls}
      style={{ width, height }}
    >
      {children}
    </div>
  );
};

export const TableStyleProvider = React.memo(tableStyleProvider);
