// todo temp types till we have iblines root resolver

import { gql } from "urql";

export const FindingItemizedBillDataFragment = gql`
  fragment FindingItemizedBillDataFragment on FindingItemizedBillData {
    __typename
    alaRowId
    authorId
    batchClaimLineId
    createdAt
    dateOfServiceEnd
    dateOfServiceStart
    description
    ibLineId
    id
    itemNumber
    lineNumber
    procedureCode
    procedureCodeModifier
    revCode
    s3InputSource
    stale
    unitCharge
    units
    updatedAt
    versionFingerprint
    ibLineVersionFingerprint
    originalBilledAmount
  }
`;

export const FindingFragment = gql`
  fragment FindingFragment on Finding {
    __typename
    autodorFindingId
    autodorFindingS3Key
    findingItemizedBillData {
      ...FindingItemizedBillDataFragment
    }
    accepted
    author {
      firstName
      id
      lastName
      userType
    }
    createdAt
    confidence
    id
    improperPaymentCost
    improperPaymentReason
    improperPaymentUnitsCharged
    isOriginal
    metaDataAocPrimaryCode
    metaDataEmCode
    metaDataEmCorrectLevel
    metaDataEmOriginalLevel
    metaDataNcciMueAdjudicationValue
    metaDataNcciMueOhpMueValue
    metaDataNcciPtpCode
    metaDataNcciPtpModifier
    metaDataPricingCorrectPrice
    metaDataUnitsCorrectUnits
    needsReview
    ruleType
    seedType
    superseded
    updatedAt
    latestReview {
      authorId
      createdAt
      rationale
    }
  }
  ${FindingItemizedBillDataFragment}
`;

export const AutodorFindingFragment = gql`
  fragment autodorFindingFragment on AutodorFinding {
    __typename
    auditType
    claimId
    createdAt
    s3Key
    denialCode
    description
    ibLineId
    ibLineVersionFingerprint
    id
    improperCharge
    improperUnits
    seedType
    stale
    ubLineId
    autodorMetadata {
      auditType
      billType
      dateOfServiceStart
      lineNumber
      procedureCode
      procedureCodeModifier
      quarter
      unitCharge
      units
      versionId
      year
      rule {
        _type
        __typename
        genericRule
        ptp {
          colOneCode
          colTwoCode
          extendedDescription
          rationale
          __typename
        }
        mue {
          description
          code
          num
          rationale {
            value
            enumeration
            __typename
          }
          editType
          mai
          __typename
        }
        nonBillable {
          code
          description
          __typename
        }
      }
    }
  }
`;

export const ItemizedBillLineFragment = gql`
  fragment ItemizedBillLineFragment on ItemizedBillLine {
    __typename
    alaRowId
    id
    dateOfService
    itemNumber
    revCode
    description
    procedureCode
    procedureCodeModifier
    units
    totalCharge
    lineNumber
    findings
    s3InputSourceId
    versionFingerprint
    groupCode
  }
`;
