import React from "react";
import { css } from "twin.macro";

export interface FormExtraProps {
  children?: React.ReactNode;
  minHeight?: React.CSSProperties["height"];
  className?: string;
  visible: boolean;
}

export const FormExtra = ({
  children,
  visible,
  className,
  minHeight = "1.5em",
}: FormExtraProps) => (
  <div
    tw="af-text-[0.75em]"
    css={css({
      minHeight,
    })}
    className={className}
  >
    {visible ? children : <></>}
  </div>
);
