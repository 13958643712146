import { useQuery } from "@tanstack/react-query";

import { neverRefetchOptions, QUERY_LOADER_SCOPE } from "./constants";

interface FoxitPDFViewCtrlLoaderFunctionOptions<
  TReturn = Awaited<ReturnType<typeof foxitPDFViewCtrlLoader>>,
> {
  select: (data: Awaited<ReturnType<typeof foxitPDFViewCtrlLoader>>) => TReturn;
}

export const QUERY_KEY_FOXIT_PDF_VIEW_CTRL = [
  ...QUERY_LOADER_SCOPE,
  "foxit-pdf-view-ctrl",
];

export const useSuspenseFoxitPDFViewCtrlLoader = <
  TReturn = Awaited<ReturnType<typeof foxitPDFViewCtrlLoader>>,
>(
  options?: FoxitPDFViewCtrlLoaderFunctionOptions<TReturn>,
) => {
  return useQuery({
    select: options?.select,
    queryKey: QUERY_KEY_FOXIT_PDF_VIEW_CTRL,
    queryFn: foxitPDFViewCtrlLoader,
    suspense: true,
    ...neverRefetchOptions,
  });
};

export const foxitPDFViewCtrlLoader = async () => {
  if (typeof window === "undefined") {
    return null;
  }

  return await import(
    "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/PDFViewCtrl.full"
  );
};

export type * as PDFViewCtrl from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/PDFViewCtrl.full";
