import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

type HashValue = string | null;
type HashParams = Record<string, HashValue>;

type ToggleMap = Record<string, string[]>;

type UpdateParamsFn = (
  query: URLSearchParams,
  toggleMap: ToggleMap,
  key: string,
  value?: string | boolean | null,
) => void;

export const _queryToMap = (query: URLSearchParams) =>
  [...query.entries()].reduce<Record<string, HashValue>>(
    (acc, [key, value]) => {
      acc[key] = value === "" ? null : value;
      return acc;
    },

    {},
  );

export const _getParams = (query: URLSearchParams) =>
  // remove empty params for stuff like UB&, IB&
  query.toString().replace("=&", "&").replace(/=$/, "");

export const _updateParams: UpdateParamsFn = (
  query,
  toggleMap,
  key,
  value?,
) => {
  if (
    value === undefined ||
    value === null ||
    (typeof value === "boolean" && value === false)
  ) {
    query.delete(key);
  } else {
    if (toggleMap[key]) {
      for (const k of toggleMap[key]) {
        query.delete(k);
      }
    }
    query.set(key, typeof value === "boolean" && value === true ? "" : value);
  }
};

export const _createToggleMap = (toggleSets: string[][]): ToggleMap =>
  toggleSets.reduce<Record<string, string[]>>((acc, v) => {
    for (const k of v) {
      acc[k] = v.filter((y) => y !== k);
    }
    return acc;
  }, {});

export const _createBuilder = (
  query: URLSearchParams,
  toggleMap: ToggleMap,
  commit: () => void,
) => {
  const _builder = {
    reset: () => {
      const keys = [...query.keys()];
      keys.forEach((key) => query.delete(key));
      return _builder;
    },
    add: (key: string, value?: string | boolean | null) => {
      _updateParams(query, toggleMap, key, value);
      return _builder;
    },
    commit: () => {
      commit();
    },
  };
  return _builder;
};

type BuilderType = ReturnType<typeof _createBuilder>;

/**
 * primative util for managing hash params
 * TODO find a better one or see what we can use in react-router
 * for now, this requires removing params not in the toggleSets that are no longer needed
 * if we keep this, it could implement a chained builder, e.g.
 * .build('key', 'value').add('key2', 'value2').add('key3, 'value3').commit()
 * @param toggleSets
 * @returns params, setParams
 */
export const useHashParams = (
  toggleSets: string[][] = [],
): [HashParams, BuilderType] => {
  const location = useLocation();
  const history = useHistory();

  const toggleMap = useMemo(() => _createToggleMap(toggleSets), [toggleSets]);

  const query = useMemo(
    () => new URLSearchParams(location.hash.replace("#", "?")),
    [location],
  );

  const params = _queryToMap(query);

  const getParams = useCallback(() => _getParams(query), [query]);

  const _commit = useCallback(
    () => history.replace({ hash: `#${getParams()}` }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [query],
  );

  const builder = useMemo(() => {
    const _builder = _createBuilder(query, toggleMap, _commit);
    return _builder;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  return [params, builder]; //, { info, location, history, query, rejoined }];
};
