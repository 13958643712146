import type { __internal__ } from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib";
import type { StateCreator } from "zustand";

import type {
  PDFAnnot,
  PDFBookmark,
  PDFHighlightCoords,
  PDFPoint,
} from "../../helper-types";
import { useSuspenseFoxitPDFViewCtrlLoader } from "../../loaders";
import type { PDFViewCtrl } from "../../loaders/foxit-pdf-view-ctrl";
import { mapHighlightCoordsToFoxitRect } from "../../utils";
import type { CombinedPDFStore } from "../pdf-store-provider";
import {
  DEFAULT_SCALE,
  MAX_SCALE,
  MIN_SCALE,
  ROTATE_0,
  ROTATE_90,
  ROTATE_180,
  ROTATE_270,
} from "./const";
import type { PDFViewerSlice } from "./pdf-viewer-slice";

type EnumValues<T> = T[keyof T];

type PDFDoc = NonNullable<
  ReturnType<PDFViewCtrl.PDFViewer["getCurrentPDFDoc"]>
>;

export interface PDFControllerSlice {
  pdfUrl: null | string;
  pageCount: number | null;
  currentPageIndex: number | null;
  state: "initialized" | "destroyed" | "initial";
  scale: number | "fitWidth" | "fitHeight";
  error: string | null;
  bookmarks: PDFBookmark[] | null;
  id: string | null;
  open: (
    url: string,
    id: string,
    pageIndex?: number,
    highlights?: string[],
  ) => void | Promise<void>;
  close: () => void | Promise<void>;
  nextPage: () => Promise<void>;
  prevPage: () => Promise<void>;
  jumpToPage: (pageIndex: number, offset?: PDFPoint) => void | Promise<void>;
  setCurrentPageIndex: (pageIndex: number) => void;
  getPageThumbnail: (pageIndex: number) => Promise<HTMLImageElement>;
  loadBookmarks: () => Promise<PDFBookmark[] | null>;
  rotate: (direction: "left" | "right") => Promise<void>;
  zoom: (step: Parameters<PDFViewCtrl.PDFViewer["zoomTo"]>[0]) => Promise<void>;
  addEventListener: <
    T extends EnumValues<typeof PDFViewCtrl.constants.ViewerEvents> | undefined,
  >(
    eventName: T,
    listener: Parameters<
      ReturnType<PDFViewCtrl.PDFViewer["getEventEmitter"]>["addListener"]
    >[1],
  ) => void;
  removeEventListener: <
    T extends EnumValues<typeof PDFViewCtrl.constants.ViewerEvents> | undefined,
  >(
    eventName: T,
    listener: Parameters<
      ReturnType<PDFViewCtrl.PDFViewer["getEventEmitter"]>["removeListener"]
    >[1],
  ) => void;
  highlightTextOnPage: (
    pageIndex: number,
    stringsToHighlight: string[],
  ) => __internal__.Annot[] | Promise<__internal__.Annot[]>;
  removeHighlights: (highlights: __internal__.Annot[]) => void | Promise<void>;
  removeHighlightsOnPage: (pageIndex: number) => void | Promise<void>;
  addHighlight: (
    pageIndex: number,
    highlightCoords: PDFHighlightCoords[],
  ) => Promise<PDFAnnot[]>;
  getPageDimensions: (
    pageIndex: number,
  ) => Promise<{ width: number; height: number }>;
}

export const usePDFControllerSlice = () => {
  const { data: PDFViewCtrlClass } = useSuspenseFoxitPDFViewCtrlLoader();

  const safeGet = (get: () => CombinedPDFStore) => {
    const { pdfViewer, ...rest } = get();

    if (!pdfViewer) {
      throw new Error("PDF Viewer is not initialized yet");
    }
    return { pdfViewer, ...rest };
  };

  const getPDFDoc = (pdfViewer: PDFViewCtrl.PDFViewer) => {
    const pdfDoc = pdfViewer.getCurrentPDFDoc();
    if (!pdfDoc) {
      throw new Error("PDF Doc in not initialized yet");
    }
    return pdfDoc;
  };

  const getPDFDocRender = (pdfViewer: PDFViewCtrl.PDFViewer) => {
    const pdfDocRender = pdfViewer.getPDFDocRender();
    if (!pdfDocRender) {
      throw new Error("PDF DocRender is not initialized yet");
    }
    return pdfDocRender;
  };

  function assertPDFViewCtrlAvailable(
    pdfViewCtrl: typeof PDFViewCtrlClass,
  ): asserts pdfViewCtrl {
    if (!pdfViewCtrl) {
      throw new Error("PDFViewCtrl or worker is not available yet");
    }
  }

  const highlightTextOnPage = async (
    pdfDoc: PDFDoc,
    pageIndex: number,
    stringsToHighlight: string[],
  ) => {
    assertPDFViewCtrlAvailable(PDFViewCtrlClass);

    const { Annot_Flags, Annot_Type } = PDFViewCtrlClass.PDF.annots.constant;
    const { Search_Flag } = PDFViewCtrlClass.PDF.constant;
    type TextSearchMatch = PDFViewCtrl.PDF.search.TextSearchMatch;

    const page = await pdfDoc.getPageByIndex(pageIndex);

    for (const text of stringsToHighlight) {
      const searchResult = await page.getTextSearch(
        text,
        Search_Flag.consecutively,
      );

      const annotations = [];

      let match: TextSearchMatch | null;
      while ((match = await searchResult.findNext())) {
        const rects = match.getRects();
        for (const rect of rects) {
          const annotation = await page.addAnnot({
            flags: Annot_Flags.readOnly,
            type: Annot_Type.highlight,
            rect,
            coords: [rect],
            opacity: 0.5,
            date: new Date().getTime(),
            color: "eeff00",
          });

          annotations.push(...annotation);
        }
      }
      await searchResult.destroy();

      return annotations;
    }
  };

  const loadBookmarks = async (pdfDoc: PDFDoc) => {
    const rootBookmark = await pdfDoc.getRootBookmark();
    if (!rootBookmark) {
      return null;
    }

    const bookmarks = rootBookmark.children as PDFBookmark[];

    return bookmarks;
  };

  const createPDFControlsSlice: StateCreator<
    PDFControllerSlice & PDFViewerSlice,
    [],
    [],
    PDFControllerSlice
  > = (set, get) => ({
    state: "initial",
    pdfUrl: null,
    fileName: null,
    currentPageIndex: null,
    scale: DEFAULT_SCALE,
    pageCount: null,
    error: null,
    bookmarks: null,
    id: null,
    addEventListener: (eventName, listener) => {
      const { pdfViewer } = safeGet(get);
      if (!eventName) throw new Error("Event name is not defined");

      pdfViewer.getEventEmitter().addListener(eventName, listener);
    },
    removeEventListener: (eventName, listener) => {
      const { pdfViewer } = safeGet(get);
      if (!eventName) throw new Error("Event name is not defined");

      pdfViewer.getEventEmitter().removeListener(eventName, listener);
    },
    open: async (url, id, pageIndex = 0, highlights = []) => {
      const { pdfViewer, id: currentId, currentPageIndex } = safeGet(get);

      const pageIndexToOpen =
        id === currentId ? currentPageIndex ?? pageIndex : pageIndex;

      try {
        const pdfDoc = await pdfViewer.openPDFByHttpRangeRequest(
          {
            range: { url },
          },
          { isRenderOnDocLoaded: true },
        );

        // Foxit internals somehow changes view mode from defaultViewMode ("continuous-view-mode") to "single-page-view-mode" so we need to set it manually here
        pdfViewer.getViewModeManager().switchTo("continuous-view-mode");

        await pdfViewer.getPDFDocRender()?.goToPage(pageIndexToOpen);
        const pageCount = pdfDoc.getPageCount();

        const [bookmarks] = await Promise.all([
          loadBookmarks(pdfDoc),
          highlights.length
            ? highlightTextOnPage(pdfDoc, pageIndexToOpen, highlights)
            : null,
        ]);

        set({
          pdfUrl: url,
          pageCount,
          currentPageIndex: pageIndexToOpen,
          bookmarks,
          error: null,
          id,
        });
      } catch (e) {
        console.error(e);

        set({
          error: "Could not open PDF",
          pdfUrl: null,
          pageCount: null,
          bookmarks: null,
          id: null,
        });
      }
    },
    close: async () => {
      const { pdfViewer } = safeGet(get);
      await pdfViewer.close();
      set({
        pageCount: null,
        error: null,
        bookmarks: null,
      });
    },

    nextPage: async () => {
      const { pageCount, pdfViewer } = safeGet(get);

      if (!pageCount) throw new Error("Page count is not defined");

      const pdfDocRender = getPDFDocRender(pdfViewer);

      const currentPageIndex = pdfDocRender?.getCurrentPageIndex();
      const nextPageIndex = Math.min(currentPageIndex + 1, pageCount - 1);
      if (nextPageIndex <= (pageCount ?? 0))
        await pdfDocRender?.goToPage(nextPageIndex);

      set({ currentPageIndex: nextPageIndex });
    },
    prevPage: async () => {
      const { pdfViewer } = safeGet(get);
      const pdfDocRender = getPDFDocRender(pdfViewer);

      const currentPageIndex = pdfDocRender?.getCurrentPageIndex();
      const prevPageIndex = Math.max(currentPageIndex - 1, 0);
      if (prevPageIndex >= 0) await pdfDocRender?.goToPage(prevPageIndex);

      set({ currentPageIndex: prevPageIndex });
    },
    jumpToPage: async (pageIndex: number, offset) => {
      const { pdfViewer, pageCount } = safeGet(get);
      const pdfDocRender = getPDFDocRender(pdfViewer);

      if (pageIndex >= 0 && pageIndex <= (pageCount ?? 0))
        await pdfDocRender?.goToPage(pageIndex, offset, true);

      set({ currentPageIndex: pageIndex });
    },
    setCurrentPageIndex: (pageIndex: number) => {
      set({ currentPageIndex: pageIndex });
    },
    getPageThumbnail: async (pageIndex: number): Promise<HTMLImageElement> => {
      const { pdfViewer } = safeGet(get);
      const pdfDoc = getPDFDoc(pdfViewer);

      return (await pdfDoc.loadThumbnail({
        pageIndex,
        type: "image",
      })) as HTMLImageElement;
    },
    loadBookmarks: () => {
      const { pdfViewer } = safeGet(get);
      const pdfDoc = getPDFDoc(pdfViewer);

      return loadBookmarks(pdfDoc);
    },
    rotate: async (direction) => {
      const { pdfViewer, currentPageIndex } = safeGet(get);

      const pdfDoc = getPDFDoc(pdfViewer);

      if (currentPageIndex !== null && currentPageIndex >= 0) {
        const page = await pdfDoc.getPageByIndex(currentPageIndex);

        const currentPageRotation = page.getRotation();

        let newRotation = -1;

        if (direction === "left") {
          switch (currentPageRotation) {
            case ROTATE_0:
              newRotation = ROTATE_270;
              break;
            case ROTATE_90:
              newRotation = ROTATE_0;
              break;
            case ROTATE_180:
              newRotation = ROTATE_90;
              break;
            case ROTATE_270:
              newRotation = ROTATE_180;
              break;
            default:
              newRotation = currentPageRotation;
              break;
          }
        } else if (direction === "right") {
          switch (currentPageRotation) {
            case ROTATE_0:
              newRotation = ROTATE_90;
              break;
            case ROTATE_90:
              newRotation = ROTATE_180;
              break;
            case ROTATE_180:
              newRotation = ROTATE_270;
              break;
            case ROTATE_270:
              newRotation = ROTATE_0;
              break;
            default:
              newRotation = currentPageIndex;
              break;
          }
        }

        page.setRotation(newRotation);
      }
    },
    zoom: async (step) => {
      const { pdfViewer } = safeGet(get);
      const pdfDocRender = getPDFDocRender(pdfViewer);

      const currentScale = await pdfDocRender.getScale();

      const isRectWithScale = (rect: object): rect is { scale: number } =>
        rect && Object.hasOwn(rect, "scale");

      const roundOneDecimal = (num: number) => Math.round(num * 10) / 10;

      switch (currentScale) {
        case "fitWidth":
        case "fitHeight": {
          const rect = pdfDocRender.getBoundingClientRects()?.[0];

          if (rect && isRectWithScale(rect)) {
            const newScale =
              typeof step === "number"
                ? roundOneDecimal(rect.scale + step)
                : step;

            if (
              (typeof newScale === "number" && newScale < MIN_SCALE) ||
              (typeof newScale === "number" && newScale > MAX_SCALE)
            )
              return;

            await pdfViewer.zoomTo(newScale);

            set({ scale: newScale });
            return;
          }
          break;
        }
        default: {
          const newScale =
            typeof step === "number" ? currentScale + step : step;

          if (
            (typeof newScale === "number" && newScale < MIN_SCALE) ||
            (typeof newScale === "number" && newScale > MAX_SCALE)
          )
            return;

          await pdfViewer.zoomTo(newScale);

          set({ scale: newScale });
        }
      }
    },
    highlightTextOnPage: async (
      pageIndex: number,
      stringsToHighlight: string[],
    ) => {
      const { pdfViewer } = safeGet(get);

      const pdfDoc = getPDFDoc(pdfViewer);

      const annotations = await highlightTextOnPage(
        pdfDoc,
        pageIndex,
        stringsToHighlight,
      );

      return annotations ?? [];
    },
    removeHighlights: async (highlights) => {
      for (const highlight of highlights) {
        const page = highlight.getPage();
        await page.removeAnnotById(highlight.getUniqueID());
      }
    },
    removeHighlightsOnPage: async (pageIndex: number) => {
      const { pdfViewer } = safeGet(get);
      const pdfDoc = getPDFDoc(pdfViewer);
      const page = await pdfDoc.getPageByIndex(pageIndex);
      await page.removeAllAnnot();
      return;
    },
    addHighlight: async (pageIndex, highlightCoords) => {
      const { pdfViewer } = safeGet(get);

      const pdfDoc = getPDFDoc(pdfViewer);

      assertPDFViewCtrlAvailable(PDFViewCtrlClass);

      const { Annot_Type } = PDFViewCtrlClass.PDF.annots.constant;

      const firstCoords = highlightCoords[0];
      if (!firstCoords) {
        throw new Error(
          "highlightCoords array must contain at least one element",
        );
      }

      const rect = mapHighlightCoordsToFoxitRect(firstCoords);

      const page = await pdfDoc.getPageByIndex(pageIndex);

      return page.addAnnot({
        type: Annot_Type.highlight,
        rect: rect,
        coords: highlightCoords.map(mapHighlightCoordsToFoxitRect),
      });
    },

    getPageDimensions: async (pageIndex) => {
      const { pdfViewer } = safeGet(get);
      const pdfDoc = getPDFDoc(pdfViewer);

      const page = await pdfDoc.getPageByIndex(pageIndex);
      if (!page) {
        throw new Error(`Page with index ${pageIndex} is not available`);
      }
      const pageWidth = page.getWidth();
      const pageHeight = page.getHeight();
      const rotation = page.getRotation();

      const isPageRotated = rotation === ROTATE_90 || rotation === ROTATE_270;
      if (isPageRotated) {
        return {
          width: pageHeight,
          height: pageWidth,
        };
      }
      return {
        width: pageWidth,
        height: pageHeight,
      };
    },
  });

  return createPDFControlsSlice;
};
