import { graphql } from "../gql";

export const getAskAutodorQueuesQueryDoc = graphql(`
  query getAskAutodorQueues($args: AskAutodorQueuesArgs) {
    askAutodorQueues(args: $args) {
      __typename
      edges {
        __typename
        node {
          ...AskAutodorQueueFragment

          summary {
            ...AskAutodorQueueSummaryFragment
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`);
