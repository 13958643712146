import type { Components } from "react-markdown";

import { A, Code, H1, H2, H3, H4, H5, H6, Li, Ol, P, Ul } from "./renderers";

const components: Partial<Components> = {
  a: A,
  code: Code,
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  li: Li,
  ol: Ol,
  p: P,
  ul: Ul,
};

export default components;
