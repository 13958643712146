/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { Comment } from "@ant-design/compatible";
import { ClockCircleOutlined, RobotOutlined } from "@ant-design/icons";
import { Avatar, Descriptions, Space, Tooltip } from "antd";
import moment from "moment-timezone";

import type { AutodorFinding } from "../../../../../gql/graphql";
import { UserContext } from "../../../../context/user";
import { RuleDetails } from "./autodorDetails/ruleDetails";
import { getRuleLabelForFinding } from "./autodorFindingUtils";

const autodorFindingComment = ({
  autodorFinding,
}: {
  autodorFinding: AutodorFinding;
}) => {
  const {
    improperUnits,
    createdAt,
    autodorMetadata: { procedureCode, procedureCodeModifier },
  } = autodorFinding;

  const {
    workstation: { hides },
  } = React.useContext(UserContext);

  const commentProps = {
    ...(hides.auditFindingComment.author
      ? {}
      : {
          author: "Autodor",
          avatar: <Avatar icon={<RobotOutlined tw="af-pt-1.5" />} />,
        }),
    content: (
      <div tw="af-flex af-mr-8 af-pt-2 af-h-full">
        <div tw="af-basis-5/12 af-min-w-[300px] af-pl-4">
          <Space direction="vertical" size="small">
            <Descriptions title="" size="small" column={1}>
              <Descriptions.Item
                label="Type"
                key="autodorFindingType"
                data-cy="audit-finding-meta-data-description-autodorFindingType"
                contentStyle={{ fontWeight: "extrabold" }}
              >
                <div
                  tw="af-line-clamp-2  af-leading-5 af-align-baseline af-my-auto
      af-p-0 af-whitespace-normal af-break-words"
                >
                  {getRuleLabelForFinding(autodorFinding)}
                </div>
              </Descriptions.Item>

              <Descriptions.Item
                label="Discrepant Quantity"
                key="improperPaymentUnitsCharged"
                data-cy="audit-finding-meta-data-description-improperPaymentUnitsCharged"
                contentStyle={{ fontWeight: "extrabold" }}
              >
                {improperUnits}
              </Descriptions.Item>

              <Descriptions.Item
                label="Procedure Code"
                key="procedureCode"
                data-cy="audit-finding-meta-data-description-procedureCode"
                contentStyle={{ fontWeight: "extrabold" }}
              >
                {procedureCode}
              </Descriptions.Item>

              {procedureCodeModifier && (
                <Descriptions.Item
                  label="Procedure Code Modifier"
                  key="procedureCodeModifier"
                  data-cy="audit-finding-meta-data-description-procedureCodeModifier"
                  contentStyle={{ fontWeight: "extrabold" }}
                >
                  {procedureCodeModifier}
                </Descriptions.Item>
              )}
            </Descriptions>
          </Space>
        </div>

        <div tw="af-basis-7/12  af-min-w-[300px] af-pl-4">
          <RuleDetails
            autodorMetadata={autodorFinding.autodorMetadata}
          ></RuleDetails>
        </div>
      </div>
    ),
    datetime: (
      <Tooltip title={moment.utc(createdAt).format("MM-DD-YY HH:mm:ss")}>
        <Space>
          <ClockCircleOutlined />
          <span data-cy="audit-finding-comment-created">
            {moment.utc(createdAt).format("MM-DD-YY")}
          </span>
        </Space>
      </Tooltip>
    ),
  };

  return <Comment {...commentProps} />;
};

export const AutodorFindingComment = React.memo(autodorFindingComment);
