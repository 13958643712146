import { graphql } from "../gql";

export const updateAskAutodorQueueMutationDoc = graphql(`
  mutation updateAskAutodorQueue(
    $id: ID!
    $input: UpdateAskAutodorQueueInput!
  ) {
    updateAskAutodorQueue(id: $id, input: $input) {
      ...AskAutodorQueueFragment
    }
  }
`);
