import { useCallback, useContext, useEffect, useState } from "react";

import { useLog } from "../../common/log/useLog";
import type { SignedUrlUpdater } from "../../sw/swIpc/client";
import { SwClientContext } from "../../sw/swProvider";
import * as util from "./documentViewerUtil";
import { useDocuments } from "./queries/useDocuments";
import type { UiAuditDocumentation } from "./types";

export const useClaimFiles = ({ claimId }: { claimId: string }) => {
  const log = useLog("useClaimFiles");
  const [fetchDocuments, fetching] = useDocuments(claimId);
  const [files, setFiles] = useState<UiAuditDocumentation[]>([]);
  const fetchFiles = useCallback(
    () =>
      fetchDocuments(true)
        .then((_files) => {
          return util.mapFilesToUiFiles(_files, claimId);
        })
        .then((_files) => {
          setFiles(_files);
          return _files;
        }),
    [fetchDocuments, claimId],
  );
  useEffect(() => {
    void fetchFiles().then((_files) => {
      log.debug("%cUpdated files", log.css, _files);
    });
  }, [fetchFiles, claimId, log]);

  // todo this could all be a hook that wraps e.g. useSwDocsClient and wires updater...
  const signedUrlUpdater: SignedUrlUpdater = useCallback<SignedUrlUpdater>(
    async (documentId: string) => {
      return fetchFiles().then((_files) => {
        log.log(`Search ${documentId} in updated files`, _files);
        const f = _files.find((f1) => f1.id === documentId);
        if (!f) {
          throw new Error(
            `Document id for document ${documentId} not found in updated file collection!`,
          );
        }
        return {
          documentId,
          url: f.signedUrl,
          expires: f.expiresMs,
        };
      });
    },
    [fetchFiles, log],
  );
  const swClient = useContext(SwClientContext);
  useEffect(() => {
    if (swClient) {
      swClient.registerSignedUrlUpdater(signedUrlUpdater);
    }
    return () => {
      if (swClient) {
        swClient.removeSignedUrlUpdater();
      }
    };
  }, [swClient, files, signedUrlUpdater]);

  return { files, fetching };
};
