import { useContext, useMemo, useState } from "react";
import { MessageOutlined } from "@ant-design/icons";

import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import {
  Chatbox,
  ChatboxHeaderLayout,
  CloseButton,
  DefaultChatboxSubmitButton,
  FullscreenButton,
  HelpBox,
  ImportFilesButton,
  InvalidFileContextChatboxSubmitButton,
  MessageInputTopContent,
  MessageList,
  SelectableFilesPanel,
  useChatboxFilesSelection,
} from "@alaffia-technology-solutions/components";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
  useExpandablePanel,
} from "@alaffia-technology-solutions/ui";

import type { AuditDocumentation } from "../../../gql/graphql";
import { SupportingDocumentUploadModal } from "../../claimActionMenu/actionTypes/upload/supportingDocumentUploadModal";
import { UserContext } from "../../context/user";
import { usePdfViewerSplit } from "../../documentViewer/usePdfViewerSplit";
import { AskAutodorPromptTemplateSelector } from "./AskAutodorPromptTemplateSelector";
import { useAskAutodorState } from "./useAskAutodorState";

export interface AskAutodorProps {
  claimId: string;
  documents: AuditDocumentation[];
  onClose: () => void;
  onFullscreenToggle: () => void;
  showImportButton?: boolean;
}

export const AskAutodor = ({
  claimId,
  documents,
  onClose,
  onFullscreenToggle,
  showImportButton = false,
}: AskAutodorProps) => {
  const files = useMemo(
    () =>
      documents.map((document) => ({
        ...document,
        readyToSearch: true,
        createdAt: new Date().toISOString(),
        modifiedAt: new Date().toISOString(),
        archived: false,
      })),
    [documents],
  );

  const claimCases = apiClient.cases.getClaimCases.useQuery({ claimId });
  const firstCase = claimCases?.data?.[0];

  const caseId = firstCase?.id ?? "";

  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const {
    claimList: { dataFragment },
  } = useContext(UserContext);

  const caseQuery = apiClient.cases.getCase.useQuery(
    { id: caseId },
    { enabled: Boolean(caseId) },
  );

  const {
    selectedFiles,
    selectedFileIds,
    handleFileSelection,
    clearFileSelection,
    isFileSelectionValid,
  } = useChatboxFilesSelection({ files, caseId });

  const {
    wrapperRef,
    messagesBottomRef,
    currentThread,
    threads,
    handlePromptSubmit,
  } = useAskAutodorState({
    caseId,
    fileIds: selectedFileIds,
  });

  const { open: openPdfPreview } = usePdfViewerSplit();

  const { expandPanel, collapsePanel, isExpanded, getExpandablePanelProps } =
    useExpandablePanel({
      wrapperRef,
    });

  const contextFileNames = selectedFiles.map((f) => f.fileName);

  const isLoadingMessages = claimCases.isLoading || threads.isLoading;

  return (
    <>
      <SupportingDocumentUploadModal
        batchClaimId={claimId}
        visible={uploadModalVisible}
        setVisible={setUploadModalVisible}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        claimListDataFragment={dataFragment}
      />
      <Chatbox ref={wrapperRef} className="autodor-reset af-h-full">
        <Chatbox.Header>
          <ChatboxHeaderLayout>
            <ChatboxHeaderLayout>
              <MessageOutlined className="af-h-5 af-w-5 af-flex-shrink-0 af-text-gray-600" />
              {caseQuery.data?.name}
            </ChatboxHeaderLayout>
          </ChatboxHeaderLayout>
          <ChatboxHeaderLayout>
            <div className="af-flex af-flex-nowrap">
              <FullscreenButton onClick={onFullscreenToggle} />
              <CloseButton onClick={onClose} />
            </div>
          </ChatboxHeaderLayout>
        </Chatbox.Header>

        <Chatbox.Body>
          <ResizablePanelGroup
            direction="horizontal"
            className="af-h-full"
            id="group"
          >
            <ResizablePanel
              minSize={30}
              maxSize={50}
              {...getExpandablePanelProps()}
            >
              <SelectableFilesPanel
                className="af-h-full af-w-full af-flex-shrink-0"
                files={files}
                selectedFileIds={selectedFileIds}
                onFileSelection={handleFileSelection}
                onExpand={expandPanel}
                onCollapse={collapsePanel}
                onFilePreview={(file) =>
                  openPdfPreview({
                    fileId: file.id,
                    fileName: file.fileName,
                    searchEnabled: true,
                  })
                }
                isExpanded={isExpanded}
                position="left"
                actions={
                  showImportButton && (
                    <ImportFilesButton
                      onClick={() => setUploadModalVisible(true)}
                    >
                      Import Files
                    </ImportFilesButton>
                  )
                }
              />
            </ResizablePanel>
            <ResizableHandle withHandle={true} />
            <ResizablePanel
              minSize={50}
              defaultSize={70}
              className="af-flex af-min-h-0 af-flex-1 af-flex-col af-justify-between af-overflow-hidden af-p-6"
            >
              <MessageList
                isLoading={isLoadingMessages}
                bottomRef={messagesBottomRef}
                threads={threads.data ?? []}
                onFilePreview={(promptResponse, file) =>
                  openPdfPreview({
                    fileId: file.id,
                    fileName: file.name,
                    page: file.page,
                    highlights: promptResponse?.searchKeywords ?? undefined,
                    searchEnabled: true,
                  })
                }
              />
              <Chatbox.MessageInput
                filesMentions={files.map((file) => ({
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  id: file.id,
                  name: file.fileName,
                }))}
                mentionsMenuAnchor={wrapperRef}
                onMentionAdded={(mention) =>
                  handleFileSelection(mention.id, true)
                }
                onSubmit={handlePromptSubmit}
                disabled={
                  isLoadingMessages ||
                  !isFileSelectionValid ||
                  !currentThread ||
                  undefined
                }
                topContent={
                  <MessageInputTopContent
                    contextFileNames={contextFileNames}
                    expandPanel={expandPanel}
                    clearFileSelection={clearFileSelection}
                  />
                }
                actions={
                  <>
                    <HelpBox>
                      Put all search terms in 1 set of quotation marks. Use
                      commas to separate each individual search term. To call
                      out a file, use the “@“ sign followed by the file name.
                    </HelpBox>
                    <AskAutodorPromptTemplateSelector />
                  </>
                }
                submitButton={
                  isFileSelectionValid ? (
                    <DefaultChatboxSubmitButton />
                  ) : (
                    <InvalidFileContextChatboxSubmitButton />
                  )
                }
              />
            </ResizablePanel>
          </ResizablePanelGroup>
        </Chatbox.Body>
      </Chatbox>
    </>
  );
};
