/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useMemo } from "react";
import { get } from "lodash";

import type { InitialEditFindingValues } from "../viewController/types";

export const useInitialValues = <T>(
  dataKey: string,
  initialValues: InitialEditFindingValues,
) => {
  const values: { defaultValue: T; existingFindingValue: T } = useMemo(() => {
    const { defaultValues, existingFindingValues } = initialValues;
    const defaultValue: T = get(defaultValues, dataKey);
    const existingFindingValue: T = get(existingFindingValues ?? {}, dataKey);
    return { defaultValue, existingFindingValue };
  }, [dataKey, initialValues]);
  return values;
};
