/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { isEmpty } from "lodash";
import omitDeep from "omit-deep-lodash";
import tw, { css } from "twin.macro";

import { objectPropToTitle } from "./autodorFindingUtils";

const getIndent = (indent: number): string =>
  indent > 0 ? `indent-${Math.min(indent, 6)}` : "";

const clsDtable = css({
  ...tw`af-flex af-flex-row af-gap-2 af-mt-2`,
  "& .label": tw`af-text-left af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary`,
  "&>.header": tw`af-font-bold`,
  //  af-line-clamp-2
  "& .value": tw`af-text-left af-leading-5 af-align-baseline af-my-auto 
      af-p-0 af-whitespace-normal af-break-words`,
  "& .html-value": tw`af-w-full af-whitespace-pre-wrap`,
  "& .indent-1": tw`af-pl-3`,
  "& .indent-2": tw`af-pl-6`,
  "& .indent-3": tw`af-pl-9`,
  "& .indent-4": tw`af-pl-12`,
  "& .indent-5": tw`af-pl-16`,
  "& .indent-6": tw`af-pl-20`,
});

const stripSystemKeys = (object: Record<string, any>) =>
  omitDeep(object, ["__typename"]);

export const hasDisplayableData = (object: Record<string, any>) => {
  const stripped: Record<string, any> = stripSystemKeys(object ?? {});
  const keys = Object.keys(stripped);
  return (
    keys.length > 0 && keys.some((k) => !!stripped[k] && !isEmpty(stripped[k]))
  );
};

const mapObject = (
  title: string | null,
  object: object,
  parents: string[] = [],
  indent = 1,
): any => {
  // improvement: could also accept an 'order' param containing an array of
  // top-level (root) prop names in the desired display order
  const _object = stripSystemKeys(object);
  if (!hasDisplayableData(_object)) {
    return null;
  }
  return (
    <>
      {title && (
        <div css={clsDtable}>
          <div className={`label header${getIndent(indent)}`}>{title} </div>
        </div>
      )}
      {Object.entries(_object).map(([key, value]) => {
        if (typeof value === "object") {
          if (hasDisplayableData(value)) {
            return mapObject(
              objectPropToTitle(key),
              value,
              [...parents, key],
              indent + 1,
            );
          } else {
            return null;
          }
        }
        const _value = "" + value;
        return (
          <div css={clsDtable} key={key}>
            <div className={`label ${getIndent(indent + 1)}`}>
              {objectPropToTitle(key)}{" "}
            </div>
            <div className="value">{_value}</div>{" "}
          </div>
        );
      })}
    </>
  );
};

export const AutodorDynamicDetails: FC<{
  type: string;
  title: string | null;
  object?: object | null | undefined;
}> = ({ type, title, object }) => {
  if (!object) {
    return <></>;
  }
  return (
    <div>
      <div>{mapObject(title, object, [type])}</div>
    </div>
  );
};
