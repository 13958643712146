/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UsergroupDeleteOutlined, UserSwitchOutlined } from "@ant-design/icons";

const negotiation = {
  auditProgress: {
    stateTypeLabel: "Negotiations",
    defaultLabel: "No Claim in Negotiation",
    tooltipLabel: "Claims that are in Negotiation",
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: NEGOTIATION_STATE) on
     *     the negotiationClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: () => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `negotiation: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
            { negotiationClaimState: { equalTo: NEGOTIATING } }
          ]
        }
      ) {
        totalCount
      }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e NEGOTIATING): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims: { totalCount },
        negotiation,
      },
      permissions,
      userType,
      queryableStates,
      states,
    }: any) => {
      const progressStatProps: any = {};
      const formattedQueryResults: any = {
        NEGOTIATING: negotiation.totalCOunt || 0,
        NOT_NEGOTIATING: totalCount - negotiation.totalCount || 0,
      };
      queryableStates.forEach((stateName: string | number) => {
        if (permissions.claimStatesToShow.negotiation.has(stateName)) {
          progressStatProps[stateName] = {
            totalCount: formattedQueryResults[stateName],
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            tagColor: states[stateName].tagColor,
            icon: states[stateName].icon,
            description: states[stateName].description(userType),
          };
        }
      });
      return progressStatProps;
    },
  },
  queryableStates: ["NOT_NEGOTIATING", "NEGOTIATING"],
  defaultStates: ["NOT_NEGOTIATING"],
  dataType: "enum",
  onTableQuery: "batchClaims",
  states: {
    NOT_NEGOTIATING: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "Not in Negotiation";
      },
      primaryColor: "lightgray",
      strokeColor: "white",
      tagColor: "volcano",
      icon: <UsergroupDeleteOutlined style={{ fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim(s) not in Negotiation";
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        description: () => "Negotiation ended",
        renderingAllowed: () => true,
      },
    },
    NEGOTIATING: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "In Negotiation";
      },
      primaryColor: "red",
      strokeColor: "white",
      tagColor: "volcano",
      icon: (
        <UserSwitchOutlined style={{ color: "purple", fontSize: "120%" }} />
      ),
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim(s) in Negotiation";
      },
      displayCondition: ({
        batchClaimState: { reported, negotiationClaimState },
      }: any) => reported && negotiationClaimState === "NEGOTIATING",
      timelineEventRendering: {
        description: () => "Claim in Negotiation",
        renderingAllowed: () => true,
      },
    },
  },
  // since a binary selection this will alway come as an array of values of size 1
  filterBuilder: (values: string | string[]) => {
    /* 
      POSSIBLE VALUES = NOT_NEGOTIATING OR NEGOTIATING -- only ONE Value accepted
      filter required for each values:
      NOT_NEGOTIATING
        - negotiationClaimStatesExist = false
          -- or --
        - negotiationClaimState = NOT_NEGOTIATING

      NEGOTIATING
      - negotiationClaimStatesExist: true   
        -- and --
      - negotiationClaimState = NEGOTIATING
    */

    if (values.includes("NOT_NEGOTIATING"))
      /*
        when NOT_NEGOTIATING in values we add the 'OR'
        because for the case when negotiationClaimStatesExist is false that handles the 'NOT_NEGOTIATING' scenerio
        and the pg function 'negotiationClaimState' handles the remaining scenerios
      */
      return {
        or: [
          { negotiationClaimStatesExist: false },
          { batchClaimStateById: { negotiationClaimState: { in: values } } },
        ],
      };
    else
      return {
        negotiationClaimStatesExist: true,
        batchClaimStateById: { negotiationClaimState: { in: values } },
      };
  },
};

export { negotiation };
