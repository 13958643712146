import type { AlaffiaClient } from "@alaffia-technology-solutions/client-sdk";
import { createSafeContext } from "@alaffia-technology-solutions/react-utils";

interface AlaffiaClientContextValue {
  alaffiaClient: AlaffiaClient;
}

export const [AlaffiaClientContext, useAlaffiaClientContext] =
  createSafeContext<AlaffiaClientContextValue>(
    "useAlaffiaClientContext must be used within a AlaffiaClientProvider",
  );
