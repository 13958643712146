/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React from "react";
import { css } from "@emotion/react";
import { message, Spin } from "antd";

import { CopyHover } from "../../../common/ux/copyHover";
import { ClaimActionMenu } from "../../claimActionMenu/claimActionMenu";
import { EnumClaimStates } from "../../claimState/enumClaimStates";
import { AskAutodorButton } from "../askAutodor/askAutodorButton";
import { BatchClaimOverviewDrawer } from "../batchClaimOverview/batchClaimOverviewDrawer";
import { BatchClaimOverviewTriggerButton } from "../batchClaimOverview/batchClaimOverviewTriggerButton";

const ClaimWorkspaceHeader = (props: any) => {
  const {
    batchClaim,
    batchClaim: { id: batchClaimId, icn },
  } = props;
  const [isActionLoading, setIsActionLoading] = React.useState(false); // triggers subsequent refresh, so initially false

  const [batchClaimOverviewVisible, setBatchClaimOverviewVisible] =
    React.useState(false);
  const showBatchClaimOverviewDrawer = () => {
    setBatchClaimOverviewVisible(true);
  };
  const closeBatchClaimOverviewDrawer = () => {
    setBatchClaimOverviewVisible(false);
  };

  const grid = css({
    display: "grid",
    gridTemplateColumns: `minmax(${
      icn.length + 10 // ICN-len-ch + 10-ch buffer for icon, copy, colon, space, etc.
    }ch, 1fr) 6fr minmax(50px, 1fr)`,
  });

  return !batchClaim ? (
    <Spin />
  ) : (
    <>
      <div
        css={grid}
        tw="af-gap-1 af-py-0 af-m-0  af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer"
        id="auditor-workspace-card-title"
      >
        <div tw="af-flex-none af-h-9 af-flex af-items-center">
          <BatchClaimOverviewTriggerButton
            batchClaim={batchClaim}
            onClickFunction={showBatchClaimOverviewDrawer}
            tw="af-mr-2"
          />
          <div className="af-group" tw="af-flex af-flex-row af-text-sm">
            <div tw="af-pr-1 af-text-antd-lt-colorTextSecondary dark:af-text-antd-dk-colorTextSecondary">
              Claim:
            </div>
            <CopyHover
              textToCopy={icn}
              onCopyComplete={(txt) => message.success(`Copied '${txt}'`)}
              tw="af-font-semibold"
            >
              {icn}
            </CopyHover>
          </div>
        </div>
        <div tw="af-flex-auto af-flex af-items-center af-justify-center">
          <EnumClaimStates
            stateTypesToShow={[
              "onHold",
              "workflow",
              "documentation",
              "providerActive",
              "signOff",
              "invoice",
              "negotiation",
              "report",
              "dispute",
            ]}
            batchClaim={batchClaim}
            useIcons={false}
            showNAText={false}
            key={`claim-workspace-header-state-events-row-1-${batchClaim.icn}`}
            id={`claim-workspace-header-state-events-row-1-${batchClaim.icn}`}
          />
        </div>
        <div tw=" af-h-9 af-justify-self-end">
          <div tw="af-flex">
            <div>
              <AskAutodorButton
                documents={batchClaim.auditDocumentation ?? []}
                claimId={batchClaimId}
              ></AskAutodorButton>
            </div>
            <div>
              <ClaimActionMenu
                batchClaims={[batchClaim]}
                setIsActionLoading={setIsActionLoading}
              />
            </div>
          </div>
        </div>
      </div>

      <BatchClaimOverviewDrawer
        id="claim-workspace-header-batch-claim-overview-drawer"
        key="claim-workspace-header-batch-claim-overview-drawer"
        onClose={closeBatchClaimOverviewDrawer}
        isVisible={batchClaimOverviewVisible}
        batchClaimId={batchClaim ? batchClaim.id : null}
        setClaimActionMenuIsActionLoading={setIsActionLoading}
        isActionLoading={isActionLoading}
      />
    </>
  );
};

export { ClaimWorkspaceHeader };
