import { graphql } from "../gql";

export const getCasesQueryDoc = graphql(`
  query getCases($input: CasesInput!) {
    cases(input: $input) {
      ...CaseFragment
      queue {
        ...AskAutodorQueueFragment
      }
    }
  }
`);
