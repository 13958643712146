/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import type { FC } from "react";
import { useMemo } from "react";
import accounting from "accounting-js";
import { Divider, Form, Select, Space, Typography } from "antd";
import { css } from "twin.macro";

import type { UUID } from "../../../../../../../types/ids";
import { FormExtra } from "../../../../shared/components/form/formExtra";
import type { RevCodeBclInfo } from "../../types";
import type { RevCodesForSelectedIbLines } from "../../viewController/revCodeUtils";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

const { Option } = Select;
const { Text } = Typography;

const revCodeInfoToOption = (infos: RevCodeBclInfo[]) =>
  infos.map(({ id, revCode, revDescription, unitCharge }: RevCodeBclInfo) => {
    return (
      <Option
        key={id}
        value={id}
        title={revDescription}
        disabled={unitCharge <= 0}
      >
        <Space
          split={<Divider type="vertical" />}
          css={css`
            min-width: 100%;
          `}
        >
          <div>{revCode}</div>
          <div>{accounting.formatMoney(unitCharge)}</div>
          <div
            css={css`
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              min-width: 8em;
              max-width: 8em;
            `}
          >
            {revDescription}
          </div>
        </Space>
      </Option>
    );
  });

export type RevCodeProps = CommonFieldProps & {
  revCodes: RevCodesForSelectedIbLines;
};

export const RevCode: FC<RevCodeProps> = ({
  dataKey,
  label = "Rev. Code",
  revCodes,
  initialValues,
  formLayout,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<UUID>(
    dataKey,
    initialValues,
  );

  const revCodeFieldValue = Form.useWatch(formKey(dataKey).name);

  const revCodeOptions = useMemo(() => {
    return revCodes.recommended.length > 0 ? (
      <>
        <Select.OptGroup
          key="recommended-rev-code-options"
          label="UB codes matching selected IB lines"
        >
          {revCodeInfoToOption(revCodes.recommended)}
        </Select.OptGroup>
        <Select.OptGroup
          key="other-rev-code-options"
          label="Other codes available on UB"
        >
          {revCodeInfoToOption(revCodes.other)}
        </Select.OptGroup>
      </>
    ) : (
      revCodeInfoToOption(revCodes.other)
    );
  }, [revCodes]);

  const existingRevCodeValue = useMemo(
    () =>
      existingFindingValue && revCodes
        ? revCodes.byId[existingFindingValue]
        : undefined,
    [existingFindingValue, revCodes],
  );

  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      rules={[{ required: true, message: "Select a rev. code" }]}
      initialValue={defaultValue}
      extra={
        <FormExtra visible={!!existingFindingValue}>
          {revCodeFieldValue !== existingFindingValue ? (
            <Text type="danger" tw="af-text-xs">
              {`${existingRevCodeValue?.revCode} - Warning: Changing Rev. Code will decline the exiting finding and create a new finding!`}
            </Text>
          ) : (
            existingRevCodeValue?.revCode
          )}
        </FormExtra>
      }
    >
      <Select
        placeholder="Select Rev Code"
        virtual={false}
        showSearch={true}
        optionFilterProp={"title"}
      >
        {revCodeOptions}
      </Select>
    </Form.Item>
  );
};
