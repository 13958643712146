import { gql } from "urql";

import { providerFragment } from "../../../fragments";

const providerQuery = gql`
  query providerListQuery($providerFilter: ProviderFilter!) {
    providers(filter: { and: [$providerFilter] }, orderBy: NAME_ASC) {
      nodes {
        ...providerFragment
      }
    }
  }
  ${providerFragment}
`;

const insurerQuery = gql`
  query insurerListQuery {
    insurers(orderBy: NAME_ASC) {
      nodes {
        name
        id
      }
    }
  }
`;

const uniqueProcedureCodesQuery = gql`
  query uniqueProcedureCodesQuery($includes: String!) {
    uniqueProcedureCodes(filter: { includes: $includes }) {
      nodes
    }
  }
`;

const uniqueRevCodeQuery = gql`
  query uniqueRevCodeQuery($includes: String!) {
    uniqueRevCodes(filter: { includes: $includes }) {
      nodes
    }
  }
`;

const icnQuery = gql`
  query icnQuery($includes: String!, $userBasedFilter: BatchClaimFilter!) {
    batchClaims(
      filter: {
        and: [$userBasedFilter, { icn: { includesInsensitive: $includes } }]
      }
    ) {
      nodes {
        id
        icn
      }
    }
  }
`;

const reportsQuery = gql`
  query reportsQuery($reportFilter: AuditFindingReportFilter!) {
    auditFindingReports(
      filter: $reportFilter #  we only want reports that actually have claims associated with them
    ) {
      nodes {
        id
        title
        auditFindingReportType
        auditFindingReportSubtype
      }
    }
  }
`;

export {
  providerQuery,
  uniqueProcedureCodesQuery,
  uniqueRevCodeQuery,
  insurerQuery,
  icnQuery,
  reportsQuery,
};
