import logo from "../../resources/alaffia-logo.png";

interface LogoProps {
  className: string;
}

export function Logo({ className }: LogoProps) {
  return (
    <div tw="dark:af-brightness-150" className={className}>
      <img alt="" src={logo} />
    </div>
  );
}
