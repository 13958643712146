import { auditorUsers } from "./auditorUsers";
import { auth } from "./auth";
import { caseKeys, cases, threadKeys } from "./cases";
import { directoryKeys, fileKeys, files } from "./files";
import { queueKeys, queues } from "./queues";
import { users } from "./users";

export const apiClient = {
  auth,
  cases,
  files,
  queues,
  users,
  auditorUsers,
};

export { caseKeys, threadKeys, fileKeys, directoryKeys, queueKeys };
