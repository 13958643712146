import * as React from "react";
import { IconArrowsMaximize } from "@tabler/icons-react";

import { Button } from "@alaffia-technology-solutions/ui";

export const FullscreenButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>(
  (
    { children = <IconArrowsMaximize className="af-size-5" />, ...props },
    ref,
  ) => (
    <Button ref={ref} variant="ghost" size="icon" {...props}>
      {children}
    </Button>
  ),
);
FullscreenButton.displayName = "FullscreenButton";
