import { useCallback } from "react";

import { useEventCallback } from "@alaffia-technology-solutions/hooks";

import { usePDFStore } from "../../../providers";

export const useInitializePDFCtrl = () => {
  const { data: pdfViewerCtrl, isServer } = usePDFStore((state) => ({
    init: state.init,
    destroy: state.destroy,
    pdfViewerState: state.pdfViewerState,
    pdfViewer: state.pdfViewer,
    setCurrentPageIndex: state.setCurrentPageIndex,
    events: state.events,
  }));

  const init = useEventCallback(pdfViewerCtrl?.init);

  const initialize = useCallback(
    (node: HTMLElement | null) => {
      if (isServer) {
        return;
      }

      if (node) {
        return init(node);
      }
    },
    [init, isServer],
  );

  return { pdfViewerCtrl, isServer, initialize };
};
