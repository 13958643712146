import type { SideBarDef } from "ag-grid-community";

import { DevToolbar } from "../../../../../common/table/ag/toolbar/devToolbar";

export const getIbTableSideBar: (showDevToolbar: boolean) => SideBarDef = (
  showDevToolbar,
) => ({
  toolPanels: [
    {
      id: "columns",
      labelDefault: "Columns",
      labelKey: "columns",
      iconKey: "columns",
      toolPanel: "agColumnsToolPanel",
    },
    {
      id: "filters",
      labelDefault: "Filters",
      labelKey: "filters",
      iconKey: "filter",
      toolPanel: "agFiltersToolPanel",
    },
    ...(!showDevToolbar
      ? []
      : [
          {
            id: "idsToolbar",
            labelDefault: "IDs",
            labelKey: "devToolbar",
            iconKey: "linked",
            toolPanel: DevToolbar,
          },
        ]),
  ],
});
