import { useEffect, useState } from "react";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, message, Table, Tag, Tooltip } from "antd";
import moment from "moment-timezone";
import { useQuery } from "urql";

import type { AuditorUser } from "../../gql/graphql";
import { CreateUserModal } from "./createUserModal";
import { EditUserModal } from "./editUserModal";
import { OnboardUserModal } from "./onboardUserModal";
import { usersQuery } from "./queries";

const Users = () => {
  const [{ data, fetching }, reexecuteQuery] = useQuery<{
    auditorUsers: {
      nodes: AuditorUser[];
    };
  }>({
    query: usersQuery,
    requestPolicy: "network-only",
  });

  const [createUserModalVisible, setCreateUserModalVisible] = useState(false);
  const [onboardModalVisible, setOnboardModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<AuditorUser | null>(null);
  const [refresh, setRefresh] = useState(false);

  const successMessage = (text: string) => message.success(text, 15);
  const errorMessage = (text: string) => message.error(text, 15);

  useEffect(() => {
    console.log("refreshing....");
    reexecuteQuery({ requestPolicy: "network-only" });
  }, [refresh, reexecuteQuery]);

  const userColumns = [
    {
      title: "Name",
      render: ({ firstName, lastName }: AuditorUser) =>
        `${firstName} ${lastName}`,
    },
    {
      title: "Email",
      dataIndex: "email",
    },
    {
      title: "Type",
      dataIndex: "userType",
    },
    {
      title: "Facilities",
      render: (user: AuditorUser) => {
        if (user.providerUserFacilities?.nodes.length) {
          return (
            <div className="af-flex af-flex-wrap af-gap-y-2">
              {user.providerUserFacilities.nodes.map((facility) => (
                <Tag key={facility?.provider?.name}>
                  {facility?.provider?.name}
                </Tag>
              ))}
            </div>
          );
        }

        return "-";
      },
    },
    {
      title: "Created",
      render: ({ createdAt }: { createdAt: string }) =>
        moment.utc(createdAt).format("MM/DD/YY"),
    },
    {
      title: "Onboarded",
      render: ({ messageSentsByRecipientUserId }: AuditorUser) =>
        messageSentsByRecipientUserId.nodes.length !== 0
          ? moment
              .utc(messageSentsByRecipientUserId?.nodes[0]?.createdAt as string)
              .format("MM/DD/YY")
          : "-",
    },
    {
      title: "Actions",
      render: (user: AuditorUser) => {
        const items: MenuProps["items"] = [
          {
            label: (
              <Button
                type="ghost"
                block={true}
                className="af-text-left"
                onClick={() => {
                  setSelectedUser(user);
                  setOnboardModalVisible(true);
                }}
              >
                Onboard
              </Button>
            ),
            key: "onboard",
          },
          {
            label: (
              <Button
                type="ghost"
                block={true}
                className="af-text-left"
                onClick={() => {
                  setSelectedUser(user);
                  setEditModalVisible(true);
                }}
              >
                Edit
              </Button>
            ),
            key: "edit",
          },
        ];

        return (
          <Dropdown trigger={["click"]} menu={{ items }}>
            <Button>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  return (
    <div>
      <Tooltip title="Create New User">
        <Button
          type="primary"
          shape="circle"
          icon={<PlusOutlined />}
          onClick={() => setCreateUserModalVisible(true)}
        />
      </Tooltip>
      <Table
        dataSource={data ? data.auditorUsers.nodes : []}
        columns={userColumns}
        loading={fetching}
        rowKey={"id"}
      />
      <CreateUserModal
        visible={createUserModalVisible}
        close={() => setCreateUserModalVisible(false)}
        refresh={() => setRefresh((r) => !r)}
      />
      <OnboardUserModal
        user={selectedUser}
        visible={onboardModalVisible}
        close={() => setOnboardModalVisible(false)}
        refresh={() => setRefresh((r) => !r)}
        success={successMessage}
        error={errorMessage}
      />
      {editModalVisible && selectedUser ? (
        <EditUserModal
          user={selectedUser}
          refresh={() => setRefresh((r) => !r)}
          onClose={() => setEditModalVisible(false)}
        />
      ) : null}
    </div>
  );
};

export { Users };
