import { z } from "zod";

import { Queue } from "./queue";

export const Case = z.object({
  id: z.string(),
  name: z.string(),
  latestQuestion: z.string().nullable().optional(),
  completed: z.boolean(),
  createdAt: z.string(),
  queue: Queue.optional().nullable(),
});
export type Case = z.infer<typeof Case>;

export const CaseCreateInput = z.object({
  name: z.string().trim().min(1),
  queueId: z.string().nullable(),
});

export type CaseCreateInput = z.infer<typeof CaseCreateInput>;

export const CaseCreateWithFilesInput = CaseCreateInput.extend({
  files: z.array(z.string()),
});
export type CaseCreateWithFilesInput = z.infer<typeof CaseCreateWithFilesInput>;

export const CaseByIdInput = z.object({ id: z.string() });
export type CaseByIdInput = z.infer<typeof CaseByIdInput>;

export const CaseByQueueIdInput = z.object({ queueId: z.string().nullable() });
export type CaseByQueueIdInput = z.infer<typeof CaseByQueueIdInput>;

export const CaseUpdateInput = z.object({
  id: z.string(),
  completed: z.boolean().optional(),
  name: z.string().trim().min(1).optional(),
  queueId: z.string().nullable().optional(),
  fileIds: z.array(z.string()).optional(),
});

export type CaseUpdateInput = z.infer<typeof CaseUpdateInput>;
