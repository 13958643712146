import React from "react";
import { IconClockHour2 } from "@tabler/icons-react";

interface DateTimeProps {
  date: Date | string;
  responsive?: boolean;
  icon?: React.ReactNode;
}

const locale = "en-US";

export function DateTime({
  date: dateProp,
  responsive = false,
  icon = <IconClockHour2 className="af-h-4 af-w-4" />,
}: DateTimeProps) {
  const date = new Date(dateProp);
  const localizedDate = date.toLocaleDateString(locale);
  const localizedTime = date.toLocaleTimeString(locale, {
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <div
      title={date.toString()}
      className="af-flex af-flex-shrink-0 af-items-center af-justify-between af-gap-2 af-text-xs af-text-gray-500"
    >
      {icon} {localizedDate}{" "}
      <span className={responsive ? "af-hidden md:af-inline" : undefined}>
        {localizedTime}
      </span>
    </div>
  );
}
