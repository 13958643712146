/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "urql";

// Wrap a React component with a graphql fragment
/*
const myOtherFrag = gql``;

const MyComponent = withFragment({
    displayName: 'MyComponent',
    fragment: gql`
        fragment myFrag on MyType {
            ...myOtherFrag,
            id,
            someFields
        }
        ${myOtherFrag}
    `,
    queryBuilder: (fragment) => gql`
        query myQuery($myInputId:UUID) {
            myType(id:$myInputId) {
                ...fragment
            }
        }
        ${fragment}
    `,
    Component(props) {
        const {
            query: [{ fetching, data, error }, refreshQuery] 
        } = props;

        return (
            <div>{data.myType.id}</div>
        );
    }
});
*/
const withFragment = ({
  fragment,
  queryBuilder,
  queryOptions,
  displayName,
  Component,
}: any) => {
  const query = queryBuilder(fragment);

  const QueryComponent = (props: any) => {
    const data = useQuery({
      query,
      variables: props.variables,
      ...(props.queryOptions || queryOptions || {}),
    });

    if (displayName) Component.displayName = displayName;
    return (
      <Component
        {...{
          ...(queryOptions || {}),
          ...props,
          query: data,
          gqlFragment: fragment,
          gqlQuery: query,
        }}
      />
    );
  };

  // FIXME move fragment and query into Component as props
  // since React seems to erase these fields
  QueryComponent.fragment = fragment;
  QueryComponent.query = query;
  return QueryComponent;
};

const getFragmentName = (componentOrFragment: any) =>
  (componentOrFragment.fragment || componentOrFragment).definitions[0].name
    .value;

export { withFragment, getFragmentName };
