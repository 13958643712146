"use client";

import type { ReactNode } from "react";

import { StoreProvider } from "./pdf-store-provider";
import type { JrWorkerConfig } from "./worker-provider";
import { WorkerProvider } from "./worker-provider";

interface PDFViewerProps {
  children: ReactNode;
  jrConfig?: JrWorkerConfig;
  libPath?: string;
  workerKey: string;
}

/**
 *
 * PDFViewerProvider allows for creating instances of Foxit pdf viewers in application, with necessary configuration for worker and state management.
 *
 * Must be a child of top level {@link [PDFViewerConfig](../providers/pdf-config-provider.tsx)}.
 *
 * @example
 * ```tsx
 * import { PDFViewerProvider, PDFViewerCanvas } from "@alaffia-technology-solutions/pdf-viewer";
 *
 * const MyViewer = () => {
 *   return (
 *     <PDFViewerProvider>
 *       <PDFViewerCanvas />
 *     </PDFViewerProvider>
 *   );
 * };
 * ```
 */

export const PDFViewerProvider = ({
  children,
  jrConfig,
  libPath,
  workerKey,
}: PDFViewerProps) => {
  return (
    <WorkerProvider jrConfig={jrConfig} libPath={libPath} workerKey={workerKey}>
      <StoreProvider>{children}</StoreProvider>
    </WorkerProvider>
  );
};
