/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Menu, message } from "antd";
import { gql, useMutation } from "urql";

import { batchClaimState } from "../../../fragments";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";

// mutation to update workflow state
const setInvoiceMutation = gql`
  mutation setInvoiceClaimStates(
    $batchClaimIds: [UUID!]!
    $state: InvoiceClaimStateEnum!
  ) {
    setInvoiceClaimStates(
      input: { invoiceState: $state, batchClaimIds: $batchClaimIds }
    ) {
      invoiceClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const typeActions = {
  setInvoiceSent: {
    title: "Mark Invoice Sent",
    value: "SENT",
    actionCriteria: ({
      batchClaimState: { reported, invoiceClaimState, clientSignOffClaimState },
    }: any) => {
      const satisfied =
        // must be reported
        reported &&
        // claim is not already paid
        invoiceClaimState === "NOT_PAID" &&
        // claim is signed off by the the client
        clientSignOffClaimState === "CLIENT_SIGN_OFF";
      return { satisfied };
    },
  },
  setInvoicePaid: {
    title: "Invoice Paid",
    value: "PAID",
    actionCriteria: ({
      batchClaimState: { invoiceClaimState, clientSignOffClaimState },
    }: any) => {
      const satisfied =
        // claim is not already paid
        invoiceClaimState === "SENT" &&
        // claim is signed off by the the client
        clientSignOffClaimState === "CLIENT_SIGN_OFF";
      return { satisfied };
    },
  },
  setInvoiceRemoved: {
    title: "Remove/Refund Invoice",
    value: "NOT_PAID",
    actionCriteria: ({ batchClaimState: { invoiceClaimState } }: any) => {
      const satisfied =
        /* 
          criteria is dependent on othe states -->
            cannot set to NOT_PAID unless it is not NOT_PAID
            however the default state of a claim is NOT_PAID and in order to exit NOT_PAID
            the criteria of SENT and PAID must be made which is more stringent
        */
        invoiceClaimState !== "NOT_PAID";
      return { satisfied };
    },
  },
};

const actionSubMenuItem = ({
  batchClaims,
  action,
  value,
  actionCriteria,
  title,
  setInvoice,
  setLoading,
}: any) => {
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setInvoice({
          state: value,
          batchClaimIds: actionClaims.map(({ id }: any) => id),
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          void message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setInvoice] = useMutation(setInvoiceMutation);
  return (
    <Menu.SubMenu
      key={`invoice-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Invoice"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions.invoice.includes(action),
        )
        .map(([action, { ...actionProps }]) => {
          return actionSubMenuItem({
            action,
            batchClaims,
            setInvoice,
            setLoading,
            ...actionProps,
          });
        })}
    </Menu.SubMenu>
  );
};

const invoice = {
  title: "Invoice",
  subMenu: actionSubMenu,
};

export { invoice };
