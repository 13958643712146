/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CalendarTwoTone } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import moment from "moment-timezone";

import { formatDateUTC } from "../../../audit/batchClaimOverview/eventHistoryTimeline";
import { DueDateButton } from "../../../claimActionMenu/actionTypes/dueDate";

const dueDate = {
  render: (batchClaim: any, { isCompact }: any) => {
    return <DueDateButton batchClaim={batchClaim} compact={isCompact} />;
  },
  dataType: "date",
  onTableQuery: "batchClaims",
  states: {
    DUE_DATE_SET: {
      name: "Due Date Set",
      icon: <CalendarTwoTone twoToneColor="red" style={{ fontSize: "120%" }} />,
      timelineEventRendering: {
        description: () => "Due Date set",
        customLabel: ({ createdAt }: any) => {
          return (
            <Tooltip title={formatDateUTC(createdAt)}>
              <strong>Due Date set</strong>
            </Tooltip>
          );
        },
        customRendering: ({ metaData: dueDateStr }: any) => {
          // time is always written in UTC but on recieving is converted to LOCAL
          return (
            <Tag>{moment(dueDateStr).utc().format("ddd MMM Do YYYY")}</Tag>
          );
        },
        renderingAllowed: ({ metaData: dueDateStr }: any) =>
          dueDateStr ? true : false,
      },
    },
    NO_DUE_DATE: {
      name: "No Due Date",
      icon: <CalendarTwoTone twoToneColor="red" style={{ fontSize: "120%" }} />,
      timelineEventRendering: {
        description: () => "Due Date removed",
        customRendering: ({ metaData: dueDateStr }: any) => {
          // time is always written in UTC but on recieving is converted to LOCAL
          return (
            <Tag>{moment(dueDateStr).utc().format("ddd MMM Do YYYY")}</Tag>
          );
        },
        customLabel: ({ createdAt }: any) => {
          return (
            <Tooltip title={formatDateUTC(createdAt)}>
              <strong>Due Date removed</strong>
            </Tooltip>
          );
        },
        renderingAllowed: ({ metaData: dueDateStr }: any) =>
          dueDateStr ? true : false,
      },
    },
  },
  // Note: Dont destructure date to ({dueDate}) as that clashes with the
  // name of the parent object which is also dueDate -- creating bugs
  filterBuilder: (date: { dueDate: moment.MomentInput[] }) => {
    // startDate, endDate are moment Objects or dateStrs -- in both cases it sets to Moment Obj
    let formattedStartDate = moment(date.dueDate[0]).utc().endOf("day").utc();
    let formattedEndDate = moment(date.dueDate[1]).utc().endOf("day").utc();

    // when range is the same date and user just wants one Date
    // we format the query as range between the start and end of that day
    if (formattedStartDate.diff(formattedEndDate) === 0) {
      formattedStartDate = moment(date.dueDate[0]).utc().startOf("day").utc();
      formattedEndDate = moment(date.dueDate[1]).utc().endOf("day").utc();
    }
    return {
      and: [
        { batchClaimDueDatesExist: true },
        {
          batchClaimStateById: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            dueDate: { greaterThanOrEqualTo: formattedStartDate._d },
          },
        },
        {
          batchClaimStateById: {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            dueDate: { lessThanOrEqualTo: formattedEndDate._d },
          },
        },
      ],
    };
  },
};

export { dueDate };
