import type { ExtraProps } from "react-markdown";
import URLParse from "url-parse";
import { z, ZodIssueCode } from "zod";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const filePreviewSchema = z.object({
  data: z.record(
    z.string(),
    z.object({
      annotations: z
        .array(
          z.object({
            height: z.number().min(0).max(1),
            left: z.number().min(0).max(1),
            top: z.number().min(0).max(1),
            width: z.number().min(0).max(1),
          }),
        )
        .optional(),
      fileId: z.string().min(1),
      page: z.number().min(1).optional().default(1),
    }),
  ),
  host: z.literal("file-preview"),
  paths: z.tuple([z.string().min(1)]),
});

const annotationsSchema = z.array(
  z.object({
    height: z.number().min(0),
    left: z.number().min(0),
    top: z.number().min(0),
    width: z.number().min(0),
  }),
);

const jsonParseEncodedUriSchema = z
  .string()
  .min(1)
  .transform((arg, ctx) => {
    try {
      const decodedArg = decodeURIComponent(arg);
      return JSON.parse(decodedArg) as unknown;
    } catch (e) {
      ctx.addIssue({
        code: ZodIssueCode.custom,
        message: (e as Error).message,
      });
      return z.NEVER;
    }
  });

const filePreviewAnnotationsSchema =
  jsonParseEncodedUriSchema.pipe(annotationsSchema);

// Append here new deep link schemas (use zod discriminatedUnion or union)
const deepLinkSchema = filePreviewSchema;

type DeepLinkSchema = z.infer<typeof deepLinkSchema>;

export type Host = DeepLinkSchema["host"];

export type DeepLinkDataShape = Partial<Record<Host, unknown>>;

type DeepLink<THost extends Host> = Extract<DeepLinkSchema, { host: THost }>;

type DeepLinkMapper<THost extends Host> = (
  deepLink: DeepLink<THost>,
) => URLSearchParams;

type DeepLinkMappers = {
  [THost in Host]: DeepLinkMapper<THost>;
};

export const urlParamsFilePreviewSchema = z.object({
  "file-preview-annotations": filePreviewAnnotationsSchema.optional(),
  "file-preview-file-id": z.string().min(1),
  "file-preview-page": z.string().min(1).transform(Number),
});

type UrlParamsFilePreviewSchemaInput = z.input<
  typeof urlParamsFilePreviewSchema
>;

const deepLinkMappers: DeepLinkMappers = {
  "file-preview": ({ data, paths }) => {
    const deepLinkData = data[paths[0]];

    if (!deepLinkData) {
      throw new Error("Invalid deep link");
    }

    const filePreviewAnnotations = deepLinkData.annotations
      ? encodeURIComponent(JSON.stringify(deepLinkData.annotations))
      : undefined;

    return new URLSearchParams({
      ...(filePreviewAnnotations && {
        "file-preview-annotations": filePreviewAnnotations,
      }),
      "file-preview-file-id": deepLinkData.fileId,
      "file-preview-page": deepLinkData.page.toString(),
    } satisfies UrlParamsFilePreviewSchemaInput);
  },
};

const isWhitelistHost = (host: string): host is Host => {
  const whitelistHosts = Object.keys(deepLinkMappers);

  if (whitelistHosts.includes(host)) {
    return true;
  }

  return false;
};

type IsWhitelistHost = ReturnType<typeof isWhitelistHost>;

type AssertIsWhitelistHost = (
  isWhitelistHost: IsWhitelistHost,
) => asserts isWhitelistHost;

const assertIsWhitelistHost: AssertIsWhitelistHost = (isWhitelistHost) => {
  if (!isWhitelistHost) {
    throw new Error("Invalid host in deep link");
  }
};

const parseDeepLink = <TDeepLinkData extends DeepLinkDataShape>(
  href: string,
  deepLinkData?: TDeepLinkData,
) => {
  try {
    // url-parse library here is used because it is browser agnostic.
    // The new URL() constructor provided by the browser is handled differently in different browsers.
    // Firefox does not support unknown protocols/schemes (like alaffia://).
    // https://developer.mozilla.org/en-US/docs/Web/API/URL/URL#browser_compatibility
    const url = new URLParse(href);
    const { host, pathname, query } = url;
    const paths = pathname.split("/").filter(Boolean);

    const searchParams = new URLSearchParams(query);

    const searchParamsObject = Object.fromEntries(searchParams.entries());

    assertIsWhitelistHost(isWhitelistHost(host));

    const parsedDeepLink = deepLinkSchema.safeParse({
      data: deepLinkData?.[host],
      host,
      paths,
      searchParamsObject,
    });

    if (!parsedDeepLink.success) {
      throw new Error("Invalid deep link");
    }

    const data = parsedDeepLink.data;

    const deepLinkMapper = deepLinkMappers[data.host];

    // Use this instead if therea are multiple deep link mappers
    // const deepLinkMapper = deepLinkMappers[data.host] as DeepLinkMapper<
    //   typeof data.host
    // >;

    const mappedDeepLink = deepLinkMapper(data);

    return mappedDeepLink;
  } catch (error) {
    console.error(error);

    return undefined;
  }
};

type AComponentProps = JSX.IntrinsicElements["a"] & ExtraProps;

const Anchor = ({ children, className, ...rest }: AComponentProps) => (
  <a className={cn("af-pt-4 af-text-blue-500", className)} {...rest}>
    {children}
  </a>
);

type RenderDeepLink = (
  props: AComponentProps,
  searchParams: URLSearchParams,
) => JSX.Element;

type RenderLink = (props: AComponentProps) => JSX.Element;

type AComponentPropsWithRenderProps<TDeepLinkData extends DeepLinkDataShape> =
  AComponentProps & {
    deepLinkData?: TDeepLinkData;
    renderDeepLink?: RenderDeepLink;
    renderLink?: RenderLink;
  };

export const A = <TDeepLinkData extends DeepLinkDataShape>({
  deepLinkData,
  renderDeepLink,
  renderLink,
  ...props
}: AComponentPropsWithRenderProps<TDeepLinkData>) => {
  const { href } = props;

  // href is sanitized
  if (href?.startsWith("alaffia")) {
    const searchParams = parseDeepLink(href, deepLinkData);

    if (searchParams) {
      if (renderDeepLink) {
        return renderDeepLink(props, searchParams);
      }

      if (renderLink) {
        return renderLink(props);
      }

      return <Anchor {...props} />;
    }
  }

  if (renderLink) {
    return renderLink(props);
  }

  return <Anchor {...props} />;
};
