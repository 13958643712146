/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { SWClient } from "./swIpc/client";

export const registerSw = async () => {
  const result: {
    registration: ServiceWorkerRegistration | null;
    swClient: SWClient;
  } = {
    registration: null,
    swClient: new SWClient(),
  };

  result.registration = await navigator.serviceWorker
    .register(new URL("../sw", import.meta.url), {
      type: "module",
      scope: "/",
    })
    .then((reg: any) => {
      console.debug("sw scope", reg.scope);
      return reg;
    });

  await navigator.serviceWorker.ready;

  await result.swClient
    .getReadyPromise()
    .then(() => console.debug("sw client ready"));

  return result;
};
