import type { FC } from "react";
import { useContext } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import type { ItemizedBillLine } from "../../../../../gql/graphql";
import { AuditFindingSeedType } from "../../../claimWorkspace/createAuditFinding/auditFindingUtil";
import { FindingContext } from "../../../claimWorkspace/findingEdit/findingProvider";
import type {
  Finding,
  ReviewFindingOp,
} from "../../../claimWorkspace/findingEdit/types";

interface LineReviewFindingButtonProps {
  fetching: boolean;
  readOnly: boolean;
  finding: Finding;
  ibData: ItemizedBillLine[];
}

export const LineReviewFindingButton: FC<LineReviewFindingButtonProps> = ({
  fetching,
  readOnly,
  finding,
  ibData,
}) => {
  const { findingEditor } = useContext(FindingContext);
  const operation: ReviewFindingOp = {
    finding,
    operation: "update",
    seedType: AuditFindingSeedType.IB_CLAIM_LINE,
    ibData,
  };
  return (
    <Tooltip title="Review finding" key="tt_convert-autodor">
      <Button
        data-cy={"claim-line-finding-btn-convert-autodor"}
        loading={fetching}
        type={"primary"}
        size="small"
        onClick={(event) => {
          event.stopPropagation();
          findingEditor.showModal({ operation });
        }}
        disabled={readOnly}
        icon={<EditOutlined />}
      />
    </Tooltip>
  );
};
