import { gql } from "urql";

export const facilitiesQuery = gql`
  query createUser_getFacilities {
    providers(orderBy: NAME_ASC) {
      nodes {
        name
        id
      }
    }
  }
`;

export const insurersQuery = gql`
  query createUser_getInsurers {
    insurers(orderBy: NAME_ASC) {
      nodes {
        name
        id
      }
    }
  }
`;

export const usersQuery = gql`
  query getUsers {
    auditorUsers(orderBy: CREATED_AT_DESC) {
      nodes {
        id
        createdAt
        email
        userType
        firstName
        lastName
        provider {
          name
          id
        }
        insurerUsers {
          nodes {
            id
            auditorUserId
            insurerId
          }
        }
        providerUserFacilities {
          nodes {
            id
            provider {
              id
              name
            }
          }
        }
        userInviteCreateds(orderBy: CREATED_AT_DESC) {
          nodes {
            id
            messageId
            auditorUserId
          }
        }
        messageSentsByRecipientUserId(orderBy: CREATED_AT_DESC, first: 1) {
          nodes {
            id
            createdAt
          }
        }
      }
    }
  }
`;
