import type { ComponentPropsWithoutRef } from "react";

import { Files } from "@alaffia-technology-solutions/components";
import { Separator, Skeleton } from "@alaffia-technology-solutions/ui";

import { fileNameProcessPrefix } from "./documentViewerUtil";
import type { UiAuditDocumentation } from "./types";

interface PdfViewerFilesMenuProps
  extends Pick<
    ComponentPropsWithoutRef<typeof Files>,
    "isExpanded" | "expand" | "collapse"
  > {
  files: UiAuditDocumentation[];
  isLoading?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  selectedFileId?: UiAuditDocumentation["id"] | null;
  onFileSelected: (selectedFile: UiAuditDocumentation) => void;
}

export const PdfViewerFilesMenu = ({
  files,
  isLoading = false,
  selectedFileId = null,
  onFileSelected,
  isExpanded,
  expand,
  collapse,
}: PdfViewerFilesMenuProps) => {
  return (
    <Files
      position="left"
      isExpanded={isExpanded}
      expand={expand}
      collapse={collapse}
    >
      <Files.Header position="left" />
      <Files.Body>
        {isLoading ? (
          <PdfViewerFileMenuSkeleton />
        ) : (
          <Files.List>
            {files.map((f) => (
              <PdfViewerFilesMenuItem
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                key={f.id}
                file={f}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                selected={selectedFileId && f.id === selectedFileId}
                onSelected={onFileSelected}
              />
            ))}
          </Files.List>
        )}
      </Files.Body>
    </Files>
  );
};

interface PdfViewerFilesMenuItemProps {
  file: UiAuditDocumentation;
  selected?: boolean;
  onSelected: (file: UiAuditDocumentation) => void;
}

const PdfViewerFilesMenuItem = ({
  file,
  selected = false,
  onSelected,
}: PdfViewerFilesMenuItemProps) => {
  return (
    <li
      className={
        "af-flex af-cursor-pointer af-items-center af-border-b af-border-gray-300 af-pb-2"
      }
    >
      <button
        className={`af-flex-1 af-cursor-pointer af-overflow-hidden af-rounded-lg af-border af-px-2 af-py-3 af-text-left hover:af-border-gray-400 hover:af-bg-gray-200 ${
          selected
            ? "af-border-gray-300 af-bg-gray-200"
            : "af-border-transparent"
        }`}
        onClick={() => onSelected(file)}
      >
        <div className="af-truncate af-text-xs af-font-medium af-text-neutral-900">
          {file.fileName}
        </div>
        <div className="af-text-xs af-text-neutral-600">
          {fileNameProcessPrefix[file.process]}
        </div>
      </button>
    </li>
  );
};

function PdfViewerFileMenuItemSkeleton() {
  return (
    <li className="af-flex af-flex-col af-gap-2 af-border-b af-border-gray-300 af-px-2 af-py-5">
      <Skeleton className="af-h-4 af-w-1/2" />
      <div className="af-flex af-h-3 af-items-center af-gap-2">
        <Skeleton className="af-h-4 af-w-6 af-border-r af-border-border af-pr-2" />
        <Separator orientation="vertical" />
        <Skeleton className="af-h-4 af-w-4/6" />
      </div>
    </li>
  );
}

function PdfViewerFileMenuSkeleton() {
  return (
    <ul className="af-list-none af-p-0">
      <PdfViewerFileMenuItemSkeleton />
      <PdfViewerFileMenuItemSkeleton />
      <PdfViewerFileMenuItemSkeleton />
    </ul>
  );
}
