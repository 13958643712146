import { userTenantNameQueryDoc } from "../../remote/queries/getUserTenantName.gql";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

type UsersModuleArgs = AlaffiaClientModuleArgs;

export class UsersModule extends AlaffiaClientModule {
  constructor(args: UsersModuleArgs) {
    super(args);
  }

  getUserTenantName = async () => {
    const result = await this.runGqlQuery(
      userTenantNameQueryDoc,
      {},
      "getUserTenantName",
    );

    return {
      data: result.data?.userTenantName ?? null,
      error: result.error ?? null,
    };
  };
}
