/* eslint-disable @typescript-eslint/no-unsafe-return */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { IDetailCellRendererParams } from "ag-grid-community";

import type { Finding } from "../../../../../gql/graphql";
import { isAutodorFinding } from "../../../util/findingType";
import { AutodorFindingComment } from "./autodorFindingComment";
import { FindingComment } from "./findingComment";

const commentDetailCellRenderer = (props: any) => {
  const { findings } = props.getDetailRowData(props);
  const finding = findings[0];
  return finding ? (
    <div tw="af-pl-2 af-w-full af-h-[275px] af-overflow-auto">
      <div tw="af-h-fit">
        {isAutodorFinding(finding as unknown as Finding) ? (
          <AutodorFindingComment
            autodorFinding={finding}
          ></AutodorFindingComment>
        ) : (
          <FindingComment auditFinding={finding} />
        )}
      </div>
    </div>
  ) : (
    <div>No finding data</div>
  );
};
export const CommentDetailCellRenderer = commentDetailCellRenderer; // React.memo(commentDetailCellRenderer);

export const commentTableDetailRendererParams: Partial<
  IDetailCellRendererParams<Finding, Finding>
> = {
  refreshStrategy: "rows",
  detailGridOptions: {
    getRowId: (params: any) => params.data?.id,
  },
  getDetailRowData: (params) => {
    return {
      findings: [params.data],
    };
  },
};
