import { PromptFeedbackLevel } from "@alaffia-technology-solutions/client-sdk";
import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import type { Case } from "@alaffia-technology-solutions/schema";

interface UseAskAutodorPromptOptions {
  caseId: Case["id"];
  onMutate?: () => void;
  onSettled?: () => void;
}

export function useAskAutodorPrompt({
  caseId,
  onMutate,
  onSettled,
}: UseAskAutodorPromptOptions) {
  const getThreadsCtx = apiClient.cases.getThreads.useQueryContext();

  return apiClient.cases.prompt.useMutation({
    onSuccess: async (result, { input: { threadId } }) => {
      if (!result) return;

      await getThreadsCtx.cancel({
        input: {
          caseId,
        },
      });

      const previousThreads =
        getThreadsCtx.getData({
          input: {
            caseId,
          },
        }) ?? [];
      const threads = previousThreads.map((thread) => {
        if (thread.id !== threadId) return thread;
        if (!thread.messages.length) return thread;

        thread.messages[thread.messages.length - 1] = result;

        return thread;
      });

      getThreadsCtx.setData(
        {
          input: {
            caseId,
          },
        },
        threads,
      );
    },
    onMutate: async ({ input: { promptText, inputState, threadId } }) => {
      await getThreadsCtx.cancel({
        input: {
          caseId,
        },
      });

      const previousThreads =
        getThreadsCtx.getData({
          input: {
            caseId,
          },
        }) ?? [];

      const utcDate = new Date().toUTCString();
      const optimisticMessage = {
        __typename: "Message" as const,
        id: `${utcDate}-${previousThreads.length}`,
        promptText,
        promptInputState: inputState,
        threadId,
        createdAt: utcDate,
        response: null,
      };

      const newThreads = previousThreads.map((thread) => {
        if (thread.id === threadId) {
          return {
            ...thread,
            messages: [...thread.messages, optimisticMessage],
          };
        }
        return thread;
      });

      getThreadsCtx.setData(
        {
          input: {
            caseId,
          },
        },
        newThreads,
      );

      onMutate?.();

      return { previousThreads };
    },
    onError: async (_error, { input: { threadId } }, mutationContext) => {
      await getThreadsCtx.cancel({
        input: {
          caseId,
        },
      });

      const previousThreads = mutationContext?.previousThreads ?? [];
      const currentThreads =
        getThreadsCtx.getData({
          input: {
            caseId,
          },
        }) ?? [];
      let threads = currentThreads;

      for (const thread of currentThreads) {
        if (thread.id !== threadId) continue;

        const lastMessage = thread.messages.at(-1);
        if (!lastMessage || lastMessage.response) {
          threads = previousThreads;
          break;
        }

        lastMessage.feedback = [
          {
            __typename: "PromptFeedback",
            message: "Something went wrong, please try your request again",
            level: PromptFeedbackLevel.ERROR,
          },
        ];
        thread.messages[thread.messages.length - 1] = lastMessage;
        break;
      }

      getThreadsCtx.setData(
        {
          input: {
            caseId,
          },
        },
        threads,
      );
    },
    onSettled,
  });
}
