import type { UpdatesConfig } from "@urql/exchange-graphcache";

import type {
  archiveDirectoryMutation,
  archiveFileMutation,
  ConfirmFileUploadSuccessInput,
  confirmFileUploadSuccessMutation,
  CreateDirectoryInput,
  createDirectoryMutation,
  DirectoryItemsInput,
  FilesInput,
  getDirectoryQuery,
  getFileDownloadUrlQuery,
  getFileQuery,
  getFilesQuery,
  PrepareFileForUploadInput,
  prepareFileForUploadMutation,
  searchInFileQuery,
  UpdateDirectoryInput,
  updateDirectoryMutation,
  UpdateFileInput,
  updateFileMutation,
} from "../../remote/gql/graphql";
import { invalidateQuery } from "../../remote/graphCache";
import { archiveDirectoryMutationDoc } from "../../remote/mutations/archiveDirectory.gql";
import { archiveFileMutationDoc } from "../../remote/mutations/archiveFile.gql";
import { confirmFileUploadSuccessMutationDoc } from "../../remote/mutations/confirmFileUploadSuccess.gql";
import { createDirectoryMutationDoc } from "../../remote/mutations/createDirectory.gql";
import { prepareFileForUploadMutationDoc } from "../../remote/mutations/prepareFileForUpload.gql";
import { updateDirectoryMutationDoc } from "../../remote/mutations/updateDirectory.gql";
import { updateFileMutationDoc } from "../../remote/mutations/updateFile.gql";
import { getDirectoryQueryDoc } from "../../remote/queries/getDirectory.gql";
import { getFileQueryDoc } from "../../remote/queries/getFile.gql";
import { getFileDownloadUrlQueryDoc } from "../../remote/queries/getFileDownloadUrl.gql";
import { getFilesQueryDoc } from "../../remote/queries/getFiles.gql";
import { searchInFileQueryDoc } from "../../remote/queries/searchInFileQuery.gql";
import type { ClientReturnType } from "../alaffiaClient";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

type DataModuleArgs = AlaffiaClientModuleArgs;

export class FilesModule extends AlaffiaClientModule {
  constructor(args: DataModuleArgs) {
    super(args);
  }

  static cacheOptions: UpdatesConfig = {
    Mutation: {
      prepareFileForUpload(_result, _args, cache) {
        invalidateQuery(cache, "files");
      },
      archiveFile(_result, _args, cache) {
        invalidateQuery(cache, "files");
        invalidateQuery(cache, "directory");
        invalidateQuery(cache, "threads");
      },
      updateFile(_result, _args, cache) {
        invalidateQuery(cache, "files");
        invalidateQuery(cache, "directory");
      },
      createDirectory(_result, _args, cache) {
        invalidateQuery(cache, "directory");
      },
      updateDirectory(_result, _args, cache) {
        invalidateQuery(cache, "directory");
      },
      archiveDirectory(_result, _args, cache) {
        invalidateQuery(cache, "files");
        invalidateQuery(cache, "directory");
        invalidateQuery(cache, "threads");
      },
      confirmFileUploadSuccess(_result, _args, cache) {
        invalidateQuery(cache, "files");
        invalidateQuery(cache, "directory");
      },
    },
  };

  getAllFiles = async (input: {
    input?: FilesInput;
  }): ClientReturnType<getFilesQuery["files"]> => {
    const result = await this.runGqlQuery(
      getFilesQueryDoc,
      { input: input.input ?? {} },
      "getFiles",
    );

    return {
      data: result.data?.files ?? null,
      error: result.error ?? null,
    };
  };

  getFile = async (input: {
    id: string;
  }): ClientReturnType<getFileQuery["file"]> => {
    const result = await this.runGqlQuery(
      getFileQueryDoc,
      { id: input.id ?? {} },
      "getFile",
    );
    return {
      data: result.data?.file ?? null,
      error: result.error ?? null,
    };
  };

  getFileDownloadUrl = async (input: {
    id: string;
  }): ClientReturnType<getFileDownloadUrlQuery["fileDownloadUrl"]> => {
    const result = await this.runGqlQuery(
      getFileDownloadUrlQueryDoc,
      { id: input.id },
      "getFileDownloadUrl",
      { requestPolicy: "network-only" },
    );

    return {
      data: result.data?.fileDownloadUrl ?? null,
      error: result.error ?? null,
    };
  };

  getDirectory = async (input: {
    id: string | null;
    itemsInput?: DirectoryItemsInput;
  }): ClientReturnType<getDirectoryQuery["directory"]> => {
    const result = await this.runGqlQuery(
      getDirectoryQueryDoc,
      { id: input.id, itemsInput: input.itemsInput ?? {} },
      "getDirectory",
    );

    return {
      data: result.data?.directory ?? null,
      error: result.error ?? null,
    };
  };

  archiveFile = async (input: {
    id: string;
  }): ClientReturnType<archiveFileMutation["archiveFile"]> => {
    const result = await this.runGqlMutation(
      archiveFileMutationDoc,
      { id: input.id },
      "archiveFile",
    );

    return {
      data: result.data?.archiveFile ?? null,
      error: result.error ?? null,
    };
  };

  updateFile = async (input: {
    id: string;
    input: UpdateFileInput;
  }): ClientReturnType<updateFileMutation["updateFile"]> => {
    const result = await this.runGqlMutation(
      updateFileMutationDoc,
      { id: input.id, input: input.input },
      "updateFile",
    );

    return {
      data: result.data?.updateFile ?? null,
      error: result.error ?? null,
    };
  };

  createDirectory = async (input: {
    input: CreateDirectoryInput;
  }): ClientReturnType<createDirectoryMutation["createDirectory"]> => {
    const result = await this.runGqlMutation(
      createDirectoryMutationDoc,
      { input: input.input },
      "createDirectory",
    );

    return {
      data: result.data?.createDirectory ?? null,
      error: result.error ?? null,
    };
  };

  archiveDirectory = async (input: {
    id: string;
  }): ClientReturnType<archiveDirectoryMutation["archiveDirectory"]> => {
    const result = await this.runGqlMutation(
      archiveDirectoryMutationDoc,
      { id: input.id },
      "archiveDirectory",
    );

    return {
      data: result.data?.archiveDirectory ?? null,
      error: result.error ?? null,
    };
  };

  updateDirectory = async (input: {
    id: string;
    input: UpdateDirectoryInput;
  }): ClientReturnType<updateDirectoryMutation["updateDirectory"]> => {
    const result = await this.runGqlMutation(
      updateDirectoryMutationDoc,
      { id: input.id, input: input.input },
      "updateDirectory",
    );

    return {
      data: result.data?.updateDirectory ?? null,
      error: result.error ?? null,
    };
  };

  prepareFileForUpload = async (input: {
    input: PrepareFileForUploadInput;
  }): ClientReturnType<
    prepareFileForUploadMutation["prepareFileForUpload"]
  > => {
    const result = await this.runGqlMutation(
      prepareFileForUploadMutationDoc,
      { input: input.input },
      "prepareFileForUpload",
    );

    return {
      data: result.data?.prepareFileForUpload ?? null,
      error: result.error ?? null,
    };
  };

  confirmFileUploadSuccess = async (input: {
    input: ConfirmFileUploadSuccessInput;
  }): ClientReturnType<
    confirmFileUploadSuccessMutation["confirmFileUploadSuccess"]
  > => {
    const result = await this.runGqlMutation(
      confirmFileUploadSuccessMutationDoc,
      {
        input: {
          fileId: input.input.fileId,
          fileUploadId: input.input.fileUploadId,
        },
      },
      "confirmFileUploadSuccess",
    );

    return {
      data: result.data?.confirmFileUploadSuccess ?? null,
      error: result.error ?? null,
    };
  };

  searchInFile = async (input: {
    fileId: string;
    query: string;
  }): ClientReturnType<searchInFileQuery["searchInFile"]> => {
    const result = await this.runGqlQuery(
      searchInFileQueryDoc,
      { fileId: input.fileId, query: input.query },
      "searchInFile",
    );

    return {
      data: result.data?.searchInFile ?? null,
      error: result.error ?? null,
    };
  };
}
