/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useMemo, useRef, useState } from "react";
import type { Observable } from "rxjs";
import { Subject } from "rxjs";

/**
 * useSubject Hook - creates an rxjs Subject and returns
 * the Subject's next() command along with the Observable
 * (from subject.asObservable() for listeners to .subscribe() to.
 * Intended for use in
 * ContextProviders to allow (potentially multiple) children to
 * subscribe to the event stream and receive multiple events
 * WITHOUT getting an initial value (whereas props !== events,
 * and it's hard to disambiguate null from no event ever occurring
 * which is more like the 'lastError' concept).
 * This could obviously all be done in the component providing the
 * observable, just by creating Subject and sharing .asObservable()
 * but component lifecycle would require the useRefs/useEffects anyway
 * A static lib could also provide a 'service' like method for creation,
 * but then we're looking like Angular not React
 * @Returns [next(value: any) => void, observable: Observable]
 */
export const useSubject = <T = unknown>(): [
  next: (nextVal: T) => void,
  obs: Observable<T>,
  complete: () => void,
] => {
  const subj = useRef(new Subject<T>());
  const obs = useMemo(() => subj.current.asObservable(), [subj]);
  const next = (value: T) => subj.current.next(value);
  const complete = () => subj.current.complete();

  return [next, obs, complete];
};

export const useObservableSubscription = <T = unknown>(
  observable: Observable<T> | null | undefined,
  defaultValue: T | null | undefined = undefined,
) => {
  const [val, setVal] = defaultValue
    ? useState<T>(defaultValue)
    : useState<T>();

  useEffect(() => {
    const sub = !observable
      ? null
      : observable.subscribe((v: T) => {
          setVal(v);
        });

    return () => {
      if (sub) {
        sub.unsubscribe();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [observable]);

  return val;
};
