import {
  AuditOutlined,
  DollarCircleOutlined,
  TeamOutlined,
} from "@ant-design/icons";

const activeStatistics = {
  claimsUnderReview: {
    title: "Claims Under Review",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  potentialClientSavings: {
    title: "Potential Client Savings",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  determinedClientSavings: {
    title: "Determined Savings",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  allowedAmount: {
    title: "Allowed Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  billedAmount: {
    title: "Billed Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  potentialDiscrepantAmount: {
    title: "Potential Discrepant Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  determinedDiscrepantAmount: {
    title: "Determined Discrepant Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  numberOfLives: {
    title: "Lives",
    prefix: <TeamOutlined />,
    showTooltip: true,
  },
  savingsPerLife: {
    title: "Determined Savings Per Life",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  claimsAssigned: {
    title: "Claims Assigned",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsInProgress: {
    title: "Claims In Progress",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsCompleted: {
    title: "Claims Completed",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsAwaitingDocumentation: {
    title: "Claims Awaiting Documentation",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsWithProviderSignOff: {
    title: "Claims With Provider Sign Off",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
};

const historicStatistics = {
  claimsReceived: {
    title: "Claims Received",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsReviewed: {
    title: "Claims Reviewed",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  clientRealizedSavings: {
    title: "Realized Savings",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  allowedAmount: {
    title: "Reviewed Allowed Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  billedAmount: {
    title: "Reviewed Billed Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  determinedDiscrepantAmount: {
    title: "Reviewed Discrepant Amount",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  numberOfLives: {
    title: "Lives",
    prefix: <TeamOutlined />,
    showTooltip: true,
  },
  numberOfProviders: {
    title: "Number of Providers",
    prefix: <TeamOutlined />,
    showTooltip: true,
  },
  savingsPerLife: {
    title: "Savings Per Life",
    prefix: <DollarCircleOutlined />,
    showTooltip: true,
  },
  claimsAssigned: {
    title: "All Time Assigned Claims",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsInProgress: {
    title: "All Time Claims in Progress",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
  claimsCompleted: {
    title: "All Time Claims Completed",
    prefix: <AuditOutlined />,
    showTooltip: true,
  },
};

export { activeStatistics, historicStatistics };
