import * as React from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

function Skeleton({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("af-animate-pulse af-rounded-md af-bg-gray-400", className)}
      {...props}
    />
  );
}

export { Skeleton };
