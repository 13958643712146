/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { useContext, useMemo } from "react";
import { Button, Tooltip } from "antd";

import type { AuditDocumentation } from "../../../gql/graphql";
import { UserContext } from "../../context/user";
import { AskAutodorIcon } from "./askAutodorIcon";
import { useAskAutodorFileVectorsExist } from "./useAskAutodorFileVectorsExist";
import { useAskAutodorSplit } from "./useAskAutodorSplit";

export const AskAutodorButton: FC<{
  documents: AuditDocumentation[] | null | undefined;
  claimId: string;
}> = ({ documents, claimId }) => {
  const { userType } = useContext<any>(UserContext);

  const documentIds = useMemo(
    () => documents?.map((d) => d.id) ?? [],
    [documents],
  );
  const [{ data, error }] = useAskAutodorFileVectorsExist(documentIds);
  const availableDocuments: AuditDocumentation[] = useMemo(
    () =>
      (documents ?? []).filter((d) =>
        (data?.askAutodorFileVectorsExist?.documentIds ?? []).includes(d.id),
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data],
  );

  const { open: openAskAutodor } = useAskAutodorSplit();

  return userType === "PROVIDER" ? (
    <></>
  ) : (
    <>
      <Tooltip
        title={
          userType === "PAYER"
            ? "Ask your Account Manager about 'Ask Autodor,' Alaffia's AI enabled assistant for claim review"
            : error
            ? `Failed to load Ask Autodor document list: ${error.message}`
            : availableDocuments?.length > 0
            ? `${availableDocuments.length} documents available`
            : "No documents available to search"
        }
      >
        <Button
          tw="af-w-11 af-p-1 af-mx-3"
          onClick={() =>
            openAskAutodor({
              claimId,
              documents: availableDocuments,
            })
          }
          disabled={!(availableDocuments.length > 0)}
        >
          <AskAutodorIcon />
        </Button>
      </Tooltip>
    </>
  );
};
