/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, Dropdown, Menu } from "antd";

import { UserContext } from "../context/user";
import * as actionTypes from "./actions";

/**
 * A claim action menu rendered based on the permissions
 * of the logged in user
 * @param batchClaims <BatchClaim>[]
 * @param setIsActionLoading () -> Boolean
 * */
const ClaimActionMenu = ({
  batchClaims,
  setIsActionLoading,
  isVerbose,
}: any) => {
  const { id: userId, permissions } = React.useContext(UserContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (setIsActionLoading) {
      setIsActionLoading(loading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  // check that this is a permitted type of action type (e.g. workflow)
  const permittedActionTypes = Object.entries(actionTypes).filter(
    ([type, _]) => permissions.claimActions[type].length > 0,
  );
  return (
    <Dropdown
      css={css`
        ${permittedActionTypes.length > 0
          ? "display: inline-block;"
          : "display: none;"}
      `}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      trigger={"click"}
      disabled={loading || (batchClaims && batchClaims.length == 0)}
      overlay={
        <Menu data-cy={"claim-action-dropdown-menu"}>
          {permittedActionTypes.map(([, { subMenu }]) =>
            subMenu({ batchClaims, permissions, userId, setLoading }),
          )}
        </Menu>
      }
    >
      <Button
        data-cy={`claim-action-dropdown-button`}
        loading={loading}
        type="primary"
      >
        {isVerbose && "Actions"}
        <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export { ClaimActionMenu };
