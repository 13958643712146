export const PACKAGE_SCOPE = "@alaffia-technology-solutions/pdf-viewer";
export const LOADERS_MODULE_NAME = "loaders";
export const QUERY_LOADER_SCOPE = [PACKAGE_SCOPE, LOADERS_MODULE_NAME];

export const neverRefetchOptions = {
  staleTime: Infinity,
  refetchInterval: false as const,
  refetchOnReconnect: false as const,
  refetchOnWindowFocus: false as const,
  refetchOnMount: false as const,
  refetchIntervalInBackground: false as const,
};
