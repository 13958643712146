/* eslint-disable @typescript-eslint/no-explicit-any */
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

const providerActive = {
  queryableStates: [],
  defaultStates: [" NOT_PROVIDER_ACTIVE"],
  dataType: "enum",
  onTableQuery: "batchClaims",
  states: {
    // Default State
    PROVIDER_ACTIVE: {
      name: () => {
        return "Provider Active";
      },
      primaryColor: "#9254de",
      strokeColor: "white",
      tagColor: "purple",
      icon: <EyeOutlined style={{ color: "#9254de", fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim is active for Providers";
      },
      displayCondition: ({ batchClaimState: { isProviderActive } }: any) =>
        isProviderActive === true,
      timelineEventRendering: {
        renderingAllowed: () => false,
      },
    },
    NOT_PROVIDER_ACTIVE: {
      name: () => {
        return "Not Provider Active";
      },
      primaryColor: "#9254de",
      strokeColor: "white",
      tagColor: "purple",
      icon: (
        <EyeInvisibleOutlined style={{ color: "#d4c8e2", fontSize: "120%" }} />
      ),
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim is not active for Providers";
      },
      displayCondition: ({ batchClaimState: { isProviderActive } }: any) =>
        !isProviderActive,
      timelineEventRendering: {
        renderingAllowed: () => false,
      },
    },
  },
  filterBuilder: (values: string | string[]) => {
    /* 
      POSSIBLE VALUE = PROVIDER_ACTIVE or NOT_PROVIDER_ACTIVE -- only ONE Values accepted

      filter required for each values:
      NOT_PROVIDER_ACTIVE
        - isProviderActive: { equalTo: false }

      PROVIDER_ACTIVE
        - isProviderActive: { equalTo: true }
    */
    if (values.includes("NOT_PROVIDER_ACTIVE")) {
      return {
        or: [
          { invoiceClaimStatesExist: true },
          {
            batchClaimStateById: {
              invoiceClaimState: { equalTo: "NOT_PAID" },
            },
          },
        ],
        batchClaimStateById: { isProviderActive: { equalTo: true } },
      };
    } else {
      return {
        documentRequestCreatedsExist: true,
        invoiceClaimStatesExist: false,
        batchClaimStateById: { isProviderActive: { equalTo: true } },
      };
    }
  },
};

export { providerActive };
