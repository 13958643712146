import { graphql } from "../gql";

export const prepareFileForUploadMutationDoc = graphql(`
  mutation prepareFileForUpload($input: PrepareFileForUploadInput!) {
    prepareFileForUpload(input: $input) {
      __typename
      fileId
      fileUploadId
      signedUploadUrl {
        url
        fields
      }
    }
  }
`);
