import * as React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { COMMAND_PRIORITY_HIGH, KEY_ENTER_COMMAND } from "lexical";
import type { LexicalEditor } from "lexical";

interface SubmitPluginProps {
  onSubmit: (editor: LexicalEditor) => void;
}

export function SubmitPlugin({ onSubmit }: SubmitPluginProps) {
  const [editor] = useLexicalComposerContext();

  const onEnter = React.useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey) {
        return false;
      }

      event.preventDefault();

      onSubmit(editor);

      return true;
    },
    [editor, onSubmit],
  );

  React.useEffect(
    () =>
      editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_HIGH),
    [editor, onEnter],
  );

  return null;
}
