/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useEffect, useState } from "react";
import type { IToolPanelParams, RowClickedEvent } from "ag-grid-community";
import { message } from "antd";
import { Item, Menu, useContextMenu } from "react-contexify";

import type { IBGridLine } from "../../../../components/audit/claimWorkspace/itemizedBillTab/ibTable/types";
import { fromSlugOrId, toSlug } from "../../../navigation/utils";
import { ObjectViewer } from "../../../util/devUtil/objectViewer";

export const DevToolbar = (props: IToolPanelParams) => {
  const [numFindings, setNumFindings] = useState(0);

  const [clickedRow, setClickedRow] = useState<IBGridLine>();

  const updateTotals = () => {
    let findingCount = 0;

    props.api.forEachNode(function (rowNode) {
      const data = rowNode.data;
      if (data?.findings?.length > 0) {
        findingCount += data.findings.length;
      }
    });

    setNumFindings(findingCount);
  };

  const rowSelected = (rowClickedEvent: RowClickedEvent) => {
    setClickedRow(() => rowClickedEvent?.node?.data ?? {});
  };

  useEffect(() => {
    props.api.addEventListener("modelUpdated", updateTotals);
    props.api.addEventListener("rowClicked", rowSelected);
    return () => {
      props.api.removeEventListener("modelUpdated", updateTotals);
      props.api.removeEventListener("rowClicked", rowSelected);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const MENU_ID = "menu-id";
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  function handleItemClick({ props }: any) {
    return navigator.clipboard
      .writeText(JSON.stringify(props.data, null, 3))
      .catch((err) => {
        void message.error(`Failed to copy text to clipboard`);
        console.error(err);
      });
  }

  return (
    <div tw="af-p-2 af-h-full af-min-h-screen af-w-full af-min-w-[400px] af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer">
      <h3 tw="af-pt-8 af-p-4 af-text-antd-lt-colorPrimary dark:af-text-antd-dk-colorPrimary">
        Row Info
      </h3>
      <div tw="af-p-1">
        <span tw="af-font-bold">ID:</span>
        <span tw="af-pl-4 af-cursor-text af-select-text">
          {fromSlugOrId(toSlug(clickedRow?.id))}
        </span>
      </div>
      <div tw="af-p-1">
        <span tw="af-font-bold">Slug:</span>
        <span tw="af-pl-4 af-cursor-text af-select-text">
          {toSlug(clickedRow?.id ?? "")}
        </span>
      </div>
      <div tw="af-p-4">Total Findings (all rows): {numFindings}</div>
      <h3 tw="af-pt-8 af-p-4 af-text-antd-lt-colorPrimary dark:af-text-antd-dk-colorPrimary">
        Row Data
      </h3>
      <div tw="af-h-fit af-w-fit">
        {!clickedRow ? (
          <div tw="af-p-4 af-italic">Click a row to show data</div>
        ) : (
          <div
            onContextMenu={(event) => {
              show({ event, props: { data: clickedRow } });
            }}
          >
            <ObjectViewer objectToView={clickedRow ?? {}}></ObjectViewer>
          </div>
        )}
      </div>
      <Menu id={MENU_ID}>
        <Item onClick={handleItemClick}>Copy as JSON</Item>
      </Menu>
    </div>
  );
};
