import React, { useContext } from "react";
import { Switch } from "@headlessui/react";

import { DesignProvider } from "./designProvider";

export function DarkModeButton() {
  const {
    setDarkMode,
    options: { darkMode },
  } = useContext(DesignProvider);

  return (
    <Switch
      checked={!!darkMode}
      onChange={() => setDarkMode(!darkMode)}
      tw="af-m-1 af-p-[1px] af-border-0 af-relative af-inline-flex af-h-[16px] af-w-[16px] af-shrink-0 af-cursor-pointer af-bg-transparent af-rounded-full af-transition-colors af-duration-1000 af-ease-in-out"
    >
      <span tw="af-m-0 af-p-0 af-pointer-events-none af-h-[16px] af-w-[16px] af-transform af-rounded-full af-transition af-duration-300 af-ease-in-out">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          tw="af-w-[14px] af-h-[14px] af-stroke-antd-lt-colorPrimaryText dark:af-stroke-antd-dk-colorPrimaryText"
        >
          {darkMode ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
            />
          )}
        </svg>
      </span>
    </Switch>
  );
}
