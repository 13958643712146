import { useState } from "react";

import {
  SavedPromptsPopover,
  useChatbox,
} from "@alaffia-technology-solutions/components";

interface TextNode {
  type: "text";
  detail: 0;
  format: 0;
  mode: "normal";
  style: "";
  text: string[];
  version: 1;
}

const prompts = [
  `Summarize the patient's hospital course in detail`,
  `What is the patient's principal diagnosis based on their hospital course?`,
  `What conditions was the patient diagnosed with during their hospitalization?`,
  `What procedure took place on "DATE"?`,
  `What was the patient's indication for "PROCEDURE"?`,
  `As an insurance company, draft a denial letter for [INSERT DETAILS]. Include details from the medical record to support your denial`,
  `What "level of care" has been ordered for the patient?`,
  `Does the documentation support the "level of care" that was ordered for the patient? If so, list the rationale`,
];

function generateState(nodes: TextNode[]) {
  return JSON.stringify({
    root: {
      children: [
        {
          children: nodes,
          direction: "ltr",
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: "ltr",
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  });
}

function textNode(content: string): TextNode {
  return {
    type: "text",
    detail: 0,
    format: 0,
    mode: "normal",
    style: "",
    text: [content],
    version: 1,
  };
}

const promptTemplates = prompts.map((prompt) => ({
  name: prompt,
  promptInputState: generateState([textNode(prompt)]),
}));

export const AskAutodorPromptTemplateSelector = () => {
  const { setEditorInput } = useChatbox();
  const [phrase, setPhrase] = useState("");

  const filteredPrompts = promptTemplates.filter(({ name }) =>
    name.toLowerCase().includes(phrase.toLowerCase()),
  );

  return (
    <SavedPromptsPopover
      searchPhrase={phrase}
      onSearchPhraseChange={setPhrase}
      filteredPrompts={filteredPrompts}
      onPromptSelected={({ promptInputState }) =>
        setEditorInput(promptInputState)
      }
    />
  );
};
