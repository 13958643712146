import logo from "../../resources/alaffia-icon.png";

interface IconProps {
  className: string;
}

export function Icon({ className }: IconProps) {
  return (
    <div className={className}>
      <img alt="" src={logo} />
    </div>
  );
}
