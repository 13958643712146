/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
// import { graphqlClient } from '../../../app/app';
import { gql } from "urql";

import { getAlaffiaClient } from "../../../../alaffiaClient/alaffiaClientProvider";
import { batchClaimState } from "../../../../fragments";

const confirmFileUploadSuccess = async (
  fileId: string,
  fileUploadId: string,
): Promise<boolean> => {
  const graphqlClient = getAlaffiaClient()?.api.getGraphqlClient();
  if (!graphqlClient) {
    throw new Error("No graphql client found");
  }
  const confirmFileUploadSuccessMutation = gql`
    mutation ConfirmFileUploadSuccessAlaffiaApp(
      $confirmFileUploadSuccessInput: ConfirmFileUploadSuccessInput!
    ) {
      confirmFileUploadSuccessAlaffiaApp(input: $confirmFileUploadSuccessInput)
    }
  `;

  const confirmFileUploadSuccessResult = await graphqlClient
    .mutation(confirmFileUploadSuccessMutation, {
      confirmFileUploadSuccessInput: { fileId, fileUploadId },
    })
    .toPromise();
  if (confirmFileUploadSuccessResult.error) {
    console.error(
      { error: confirmFileUploadSuccessResult.error },
      "Error confirming file upload success",
    );
    throw new Error("Error confirming file upload success");
  }
  return confirmFileUploadSuccessResult.data; // Should be a boolean representing success (and should be True by default as no Error was thrown)
};

// convert "CAPITAL_SNAKE_CASE" to "Title Case"
const toFirstLetterUpper = (str: string) =>
  str
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b[a-z](?=[a-z]{2})/g, (l) => l.toUpperCase());

const uploadDocumentToS3 = async ({
  fileId,
  fileUploadId,
  uploadUrl,
  file,
  fields,
}: {
  fileId: string;
  fileUploadId: string;
  uploadUrl: string;
  file: any;
  fields: Record<string, unknown>;
}) => {
  // NOTE: This is the OLD implementation of uploadDocumentToS3 for reference
  // const uploadDocumentToS3 = async ({ uploadUrl, file, tags }) => {
  //   try {
  //     return fetch(uploadUrl, {
  //       method: 'POST',
  //       body: file,
  //       headers: {
  //         'x-amz-tagging': tags,
  //       },
  //     }).then(async res => Promise.resolve(file));
  //   } catch (err) {
  //     console.error('error uploading documents', err);
  //     // TODO pipe error to console
  //     return Promise.reject({
  //       file,
  //       error: new Error('Error uploading document'),
  //     });
  //   }
  // };
  try {
    console.log("Got signed upload URL and fields, now uploading file...");
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]: any) => {
      formData.append(key, value);
    });
    formData.append("file", file);
    const uploadResult = await fetch(uploadUrl, {
      body: formData,
      method: "POST",
    });

    if (!uploadResult.ok) {
      console.error("File upload failed via fetch request: ", uploadResult);
      throw new Error("File upload failed via fetch request");
    }
    console.log("File upload successful, now confirming upload success...");
    await confirmFileUploadSuccess(fileId, fileUploadId);
    return Promise.resolve(file);
  } catch (err) {
    console.error("error uploading documents", err);
    // TODO pipe error to console
    return Promise.reject({
      file,
      fileId,
      fileUploadId,
      error: new Error("Error uploading document"),
    });
  }
};

// Gets upload URL and creates tags for s3 upload
// Ouput matches input of uploadDocumentToS3()
const getDocumentUploadParams = async ({
  file,
  batchClaimId,
}: {
  file: any;
  batchClaimId: string;
}) => {
  try {
    const fileName = file.name;
    const documentUploadKind = file.documentUploadKind;

    const supportingDocumentationUploadInput = {
      documentUploadKind,
      prepareFileForUploadInput: {
        fileName, // NOTE: fileName includes the file extension (e.g. "file.pdf") we don't need to add it here
        size: typeof file.size === "number" ? file.size : parseInt(file.size),
      },
    };

    const graphqlClient = getAlaffiaClient()?.api.getGraphqlClient();
    if (!graphqlClient) {
      throw new Error("No graphql client found");
    }
    const { data: batchClaimUploadData, error } = await graphqlClient
      .query(
        gql`
          query getSignedDocumentUploadUrl(
            $batchClaimId: UUID!
            $input: SupportingDocumentationUploadInput!
          ) {
            batchClaim(id: $batchClaimId) {
              id
              supportingDocumentation {
                signedUpload(input: $input) {
                  fileId
                  fileUploadId
                  signedUploadUrl {
                    url
                    fields
                  }
                }
              }
              ...batchClaimState
            }
          }
          ${batchClaimState}
        `,
        { batchClaimId, input: supportingDocumentationUploadInput },
        {
          // IMPORTANT !!!
          // It is critical that this is kept as a network only call
          // to prevent cached upload URLs from being re-used
          requestPolicy: "network-only",
        },
      )
      .toPromise();

    if (error) {
      console.error(error);
      // TODO pipe error to console
      return Promise.reject({
        file,
        error:
          error.graphQLErrors.length &&
          error.graphQLErrors[0]?.message === "Document already exists"
            ? new Error(
                `Document with file name ${fileName} already exists. File names must be unique.`,
              )
            : new Error("Network error.\nPlease try again."),
      });
    }
    const {
      batchClaim: {
        supportingDocumentation: {
          signedUpload: {
            fileId,
            fileUploadId,
            signedUploadUrl: { url: uploadUrl, fields },
          },
        },
      },
    } = batchClaimUploadData;
    return {
      fileId,
      fileUploadId,
      uploadUrl,
      file,
      fields,
    };
  } catch (e) {
    console.error(e);
    return Promise.reject({
      file,
      error: e,
    });
  }
};

// File upload handler
// Returns a rejected promise in the event of errors
// Returns a resolved promise if upload is successful
const uploadFile = async ({ file, batchClaimId }: any) => {
  if (!file.documentUploadKind) {
    console.log("Must select document kind when uploading a file");
    return Promise.reject({
      file,
      error: new Error("Document Kind Must Be Selected"),
    });
  }

  return getDocumentUploadParams({
    file,
    batchClaimId,
  }).then(uploadDocumentToS3);
};

export { toFirstLetterUpper, uploadFile };
