import type { RefObject } from "react";
import { useCallback, useRef } from "react";

interface UseScrollIntoViewOptions {
  ref: RefObject<Element>;
  scrollOptions?: ScrollIntoViewOptions;
}

export function useScrollIntoView({
  ref,
  scrollOptions = { behavior: "smooth" },
}: UseScrollIntoViewOptions) {
  const scrollOptionsRef = useRef(scrollOptions);

  return useCallback(() => {
    if (!ref.current) {
      return;
    }
    requestAnimationFrame(() => {
      ref.current?.scrollIntoView(scrollOptionsRef.current);
    });
  }, [ref]);
}
