/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */

import { uniq } from "lodash";
import tw, { css } from "twin.macro";

import type { ItemizedBillLine } from "../../../../../gql/graphql";
import { toFormattedDate } from "../../../util/date";

const toJoined = (
  key: keyof ItemizedBillLine,
  ibLines: ItemizedBillLine[],
  formatter?: (v: any) => string,
) =>
  uniq(
    ibLines
      .filter((en: ItemizedBillLine) => !!en)
      .map((en: ItemizedBillLine) =>
        formatter ? formatter(en[key]) : en[key],
      ),
  ).join(", ");

export interface IbLineInfoProps {
  ibLines?: ItemizedBillLine[];
}

export const IbLineInfo: React.FC<IbLineInfoProps> = ({ ibLines }) => {
  return ibLines ? (
    <div
      css={css({
        ...tw`af-grid af-grid-cols-8 af-text-xs af-py-2`,
        "& > div": tw`af-px-2 af-py-0.5`,
        "& > div.val": tw`af-col-span-6 af-h-4 af-line-clamp-1 af-break-all`,
        "& > div.lbl": tw`af-col-span-2 af-text-antd-lt-colorTextSecondary dark:af-text-antd-dk-colorTextSecondary`,
      })}
    >
      <div className="lbl">Date of Service</div>
      <div className="val">
        {toJoined("dateOfService", ibLines, toFormattedDate)}
      </div>

      <div className="lbl">CPT/HCPCS Code</div>
      <div className="val">{toJoined("procedureCode", ibLines)}</div>

      <div className="lbl">IB Item Number</div>
      <div className="val">{toJoined("itemNumber", ibLines)}</div>

      <div className="lbl">Service Name</div>
      <div className="val">{toJoined("description", ibLines)}</div>

      <div className="lbl">Billed Quantity</div>
      <div className="val">{toJoined("units", ibLines)}</div>

      <div className="lbl">Billed Amount</div>
      <div className="val">{toJoined("totalCharge", ibLines)}</div>
    </div>
  ) : (
    <> </>
  );
};
