/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { CalendarTwoTone } from "@ant-design/icons";
import accounting from "accounting-js";
import { Descriptions, Empty, Space, Typography } from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

import type { BatchClaimLineItem } from "../../../../../gql/graphql";
import { auditFindingMetaDataFromRuleTypes } from "../../../claimWorkspace/auditFinding/util";
import type {
  Finding,
  FindingItemizedBillData,
} from "../../../claimWorkspace/findingEdit/types";

dayjs.extend(utc);
const { Text } = Typography;

const renderMetaDataPanel = ({
  metaData,
  finding,
  style,
  bcli,
  bcliData,
}: {
  metaData: any;
  finding: Finding;
  style: any;
  bcli?: FindingItemizedBillData | null;
  bcliData: any;
}) => (
  <>
    {metaData.length || bcli ? (
      <Space direction="vertical" style={style}>
        {bcli ? (
          <Space direction="vertical" style={{ width: "100%" }}>
            <Space
              style={{ justifyContent: "space-between", width: "100%" }}
              align="start"
            >
              <Descriptions title="" size="small" column={1}>
                <Descriptions.Item
                  label="Billed Amount"
                  key="bcli_unitCharge"
                  data-cy="audit-finding-meta-data-description-unitCharged"
                  contentStyle={{ fontWeight: "extrabold" }}
                >
                  {accounting.formatMoney(bcli.unitCharge)}
                </Descriptions.Item>
              </Descriptions>
              <Space>
                <CalendarTwoTone twoToneColor="red" />
                <div data-cy="audit-finding-meta-data-description-dateOfService">
                  {bcli.dateOfServiceStart
                    ? dayjs(bcli.dateOfServiceStart).format("MM/DD/YYYY")
                    : ""}
                </div>
              </Space>
            </Space>
            <Descriptions title="" size="small" column={1}>
              <Descriptions.Item label="Quantity" key="bcli_units">
                {bcli.units}
              </Descriptions.Item>

              {bcliData
                .filter(
                  ([bcliDataKey]: [
                    bcliDataKey: keyof FindingItemizedBillData,
                  ]) => bcli[bcliDataKey] !== null,
                )
                .map(
                  ([
                    bcliDataKey,
                    { label, formatter = (value: any) => value },
                  ]: [
                    keyof BatchClaimLineItem,
                    { label: string; formatter: (value: any) => any },
                  ]) => (
                    <Descriptions.Item label={label} key={bcliDataKey}>
                      {formatter(
                        bcli[bcliDataKey as keyof FindingItemizedBillData],
                      )}
                    </Descriptions.Item>
                  ),
                )}
            </Descriptions>
            <Text type="secondary">Description:</Text>
            <span
              tw="af-whitespace-normal"
              data-cy="audit-finding-meta-data-description-unitCharged"
            >
              {bcli.description}
            </span>
          </Space>
        ) : (
          <div />
        )}
        {metaData.length > 0 && (
          <>
            <div className="audit_finding_comment_title">Finding Details</div>
            <Descriptions title="" size="small" column={1}>
              {metaData.map(
                ([metaDataKey, { label, formatter = (value: any) => value }]: [
                  keyof Finding,
                  { label: string; formatter: (value: any) => any },
                ]) => (
                  <Descriptions.Item label={label} key={metaDataKey}>
                    {formatter(finding[metaDataKey])}
                  </Descriptions.Item>
                ),
              )}
            </Descriptions>
          </>
        )}
      </Space>
    ) : (
      <Empty description="no additional information" />
    )}
  </>
);

// DOC - (MV) a/o US184 (8/2022) we're moving to standard form for most denial codes
// so removed the error handler/effect on missing meta-data fields.  The error
// handling was a great example of managing missing UI config for data responses
// and was removed after commit de102719 if needed.

const metaDataDescription = (props: { auditFinding: Finding; style?: any }) => {
  const { auditFinding, style } = props;

  const { ruleType: auditFindingRuleType } = auditFinding;
  const { findingItemizedBillData: bcli } = auditFinding;

  const bcliData = Object.entries({
    procedureCode: { label: "CPT/HCPCS Code" },
    itemNumber: { label: "Item Number" },
  });

  // DOC - (MV) we store the configuration for rendering some meta-data fields in
  // auditFindingMetaDataFromRuleTypes, but most types listed there support
  // historical data; if there's no metadata for a type it's just a standard version
  // so there's no longer a need for error handling if the UI doesn't have a type
  return (
    <div data-cy="audit-finding-meta-data-description-content">
      {
        //  If it's the first load and the metaData object exists, then we render the metaDataPanel
        renderMetaDataPanel({
          metaData: Object.entries(
            auditFindingMetaDataFromRuleTypes(auditFindingRuleType) || {},
          ),
          finding: auditFinding,
          style,
          bcli,
          bcliData,
        })
      }
    </div>
  );
};

export const MetaDataDescription = React.memo(metaDataDescription);
