/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/react";
import { message, Space, Statistic } from "antd";
import { gql } from "urql";

import { withFragment } from "../../util/urelay";
import { dollarStatProps } from "../audit/util";

const PureReportPreview = (props: any) => {
  const { query } = props;

  const [{ fetching, data, error }] = query;

  if (error) {
    console.error("Report preview  error:", error);
    void message.error("Reload page! Error:", error);
  }

  const numFindings = fetching || error ? null : data.auditFindings.totalCount;

  const numClaims = fetching || error ? null : data.batchClaims.totalCount;

  const determined =
    fetching || error
      ? null
      : data.batchClaimStates.aggregates.sum.determinedClientSavings;

  return (
    <div css={css({ paddingTop: "2.5em", paddingBottom: "2.5em" })}>
      <Space
        direction="vertical"
        style={{ width: "100%", justifyContent: "center" }}
      >
        <Statistic
          {...{
            loading: fetching,
            title: "Determined Savings",
            value: determined,
            ...dollarStatProps,
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            },
          }}
        />
        <Statistic
          {...{
            loading: fetching,
            title: "Findings",
            value: numFindings,
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            },
          }}
        />
        <Statistic
          {...{
            loading: fetching,
            title: "Claims",
            value: numClaims,
            style: {
              display: "flex",
              flexDirection: "row",
              alignItems: "baseline",
              justifyContent: "space-between",
            },
          }}
        />
      </Space>
    </div>
  );
};

const CreateReportPreview = withFragment({
  displayName: "ReportPreview",
  // (AT) Improvement: It would be great if we could query a custom graphql resolver
  // to get how many claims are reportable for a given list of IDs. That way
  // We can remove for this client query to contain any business logic
  queryBuilder: () => gql`
    query CreateReportPreview(
      $batchClaimFilter: BatchClaimFilter!
      $auditFindingFilter: AuditFindingFilter!
    ) {
      batchClaims(filter: $batchClaimFilter) {
        totalCount
      }
      batchClaimStates(
        filter: {
          batchClaimById: $batchClaimFilter
          determinedClientSavings: { greaterThan: 0 } # performance booster
        }
      ) {
        aggregates {
          sum {
            determinedClientSavings
          }
        }
      }
      auditFindings(
        filter: {
          and: [{ batchClaim: $batchClaimFilter }, $auditFindingFilter]
        }
      ) {
        totalCount
      }
    }
  `,

  // report previews are shown on-demand and should be as consistent as possible
  queryOptions: {
    requestPolicy: "network-only",
  },
  Component: PureReportPreview,
});

export { PureReportPreview, CreateReportPreview };
