/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import accounting from "accounting-js";
import type {
  ColDef,
  GetQuickFilterTextParams,
  ICellRendererParams,
  KeyCreatorParams,
  ValueFormatterFunc,
  ValueFormatterParams,
} from "ag-grid-community";
import dayjs from "dayjs";

import { LineClampedCell } from "../../../../../common/table/ag/cell/lineClampedCell";
import { lineClampedCellRenderer } from "../../../../../common/table/ag/cell/lineClampedCellRenderer";
import { IBGridLineDefaultColumn } from "../../../../../common/table/ag/cols/defaultColumn";
import type { Finding } from "../../../../../gql/graphql";
import { DenialCodeTags } from "../../../shared/components/claimLine/denialCodeTags";
import type { FindingInfo, IBGridLine } from "./types";

// TODO: timezones and utc?
const dateFormatter: ValueFormatterFunc<IBGridLine> = <T,>(
  params: ValueFormatterParams<T>,
) => params?.value && dayjs(params.value).toDate().toLocaleDateString();

const currencyFormatter: ValueFormatterFunc<IBGridLine> = <T,>(
  params: ValueFormatterParams<T>,
) => params?.value && accounting.formatMoney(params.value);

const aggImproperPaymentCost = (findings: Finding[]) =>
  (!!findings && Array.isArray(findings) ? findings : [])
    .filter((f: Finding) => !!f?.accepted)
    .map((f: Finding) => f?.improperPaymentCost ?? 0)
    .reduce((prev: number, curr: number) => {
      return prev + (curr ?? 0);
    }, 0);

const aggFindingLabels = (findingInfos: FindingInfo[]) =>
  Array.from(
    (!!findingInfos && Array.isArray(findingInfos) ? findingInfos : [])
      .filter((f) => !!f)
      .reduce((acc: Set<string>, f: FindingInfo) => {
        if (!acc.has(f.label)) {
          acc.add(f.label);
        }
        return acc;
      }, new Set<string>()),
  )
    .sort()
    .join(" ");

const aggFindingSearchable = (findingInfos: FindingInfo[]) =>
  (!!findingInfos && Array.isArray(findingInfos) ? findingInfos : [])
    .filter((f) => !!f)
    .reduce((acc: string[], f: FindingInfo) => {
      acc.push(f.searchText);
      return acc;
    }, [])
    // the search seems to tokenize words and search AND-ed terms
    // across all search input so using the † or some other unlikely
    // (or unprintable) character here (as a sep btwn findings) is
    // currently moot from the search perspective, but useful in debugging...
    .join(String.fromCharCode(0x2020)); // '†'

export const ibTableDefaultColumnDef: ColDef<IBGridLine> = {
  ...IBGridLineDefaultColumn,
  cellRenderer: lineClampedCellRenderer,
};

export const getIbTableColumns: (
  showFindings: boolean,
  readOnly: boolean,
) => ColDef<IBGridLine>[] = (showFindings, readOnly) => [
  {
    cellRenderer: "agGroupCellRenderer",
    cellRendererParams: {
      cellStyle: {
        textAlign: "left",
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    width: 40,
    minWidth: 34,
    headerName: "Expand/Collapse",
    headerTooltip: "Expand/Collapse",
    suppressMenu: true,
    filter: false,
    enableRowGroup: false,
    showDisabledCheckboxes: false,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation"></div>',
    },
  },
  {
    headerName: "",
    headerTooltip: "Line Number",
    field: "lineNumber",
    valueFormatter: ({ value }: { value: any }) => value ?? "",
    width: 35,
    cellRenderer: undefined,
    cellStyle: {
      textAlign: "left",
      paddingLeft: "4px",
      paddingRight: "4px",
      lineHeight: "1.25rem",
      fontSize: "0.65rem",
      color: "gray",
      display: "flex",
      alignItems: "center",
    },
    suppressHeaderMenuButton: true,
    enableRowGroup: false,
    showDisabledCheckboxes: false,
    suppressHeaderFilterButton: true,
  },
  {
    headerCheckboxSelection: showFindings && !readOnly,
    checkboxSelection: true,
    showDisabledCheckboxes: false,
    headerCheckboxSelectionFilteredOnly: true,
    headerName: "Checkbox Selection",
    headerTooltip: "Checkbox Selection",
    enableRowGroup: false,
    filter: false,
    width: 42,
    headerComponentParams: {
      template:
        '<div class="ag-cell-label-container" role="presentation"></div>',
    },
  },
  {
    headerName: "Item",
    headerTooltip: "Item Number",
    field: "itemNumber",
    width: 110,
  },
  {
    headerName: "Rev",
    headerTooltip: "Rev Code",
    field: "revCode",
    width: 100,
  },
  {
    headerName: "Description",
    headerTooltip: "Description (Service Name)",
    field: "description",
    minWidth: 150,
    flex: 3,
    suppressSizeToFit: true,
  },
  {
    headerName: "DoS",
    headerTooltip: "Date of Service (start date)",
    valueFormatter: dateFormatter,
    field: "dateOfService",
    width: 120,
    aggFunc: undefined,
    getQuickFilterText: dateFormatter,
    filterParams: {
      treeList: false,
    },
  },
  {
    headerName: "Units",
    headerTooltip: "Units Administered",
    type: "rightAligned",
    field: "units",
    width: 90,
    aggFunc: "sum",
  },
  {
    headerName: "Total",
    headerTooltip: "Total Charge for Units Administered",
    valueFormatter: currencyFormatter,
    type: "rightAligned",
    field: "totalCharge",
    width: 120,
    aggFunc: "sum",
    getQuickFilterText: currencyFormatter,
  },
  {
    headerName: "Proc",
    headerTooltip: "Procedure Code",
    field: "procedureCode",
    width: 100,
  },
  {
    headerName: "Proc Mod",
    headerTooltip: "Procedure Code Modifier",
    field: "procedureCodeModifier",
    width: 90,
  },
  ...(showFindings ? getShowFindingColumns() : []),
  {
    headerName: "Group Code",
    headerTooltip: "Group Code",
    field: "groupCode",
    width: 120,
  },
];

const getShowFindingColumns = (): ColDef<IBGridLine>[] => [
  {
    headerName: "Adj. Amount",
    headerTooltip: "Adjustment Amount",
    type: "rightAligned",
    field: "findings",
    width: 120,
    getQuickFilterText: (
      params: GetQuickFilterTextParams<IBGridLine, Finding[]>,
    ) =>
      accounting.formatMoney(
        aggImproperPaymentCost(params?.data?.findings ?? []),
      ),
    comparator: (a: Finding[], b: Finding[]) => {
      return aggImproperPaymentCost(a ?? []) > aggImproperPaymentCost(b ?? [])
        ? 1
        : -1;
    },
    suppressMenu: true,
    filter: false,
    cellRenderer: (props: ICellRendererParams<any, any>) => {
      return (props?.data?.findings?.length ?? 0) > 0 ? (
        <LineClampedCell>
          {accounting.formatMoney(
            aggImproperPaymentCost(props?.data?.findings ?? []),
          )}
        </LineClampedCell>
      ) : (
        ""
      );
    },
  },
  {
    headerName: "Denial Code",
    headerTooltip: "Denial Code",
    field: "findingInfo",
    minWidth: 120,
    flex: 2,
    suppressSizeToFit: true,
    getQuickFilterText: (
      params: GetQuickFilterTextParams<IBGridLine, FindingInfo[]>,
    ) => aggFindingSearchable(params?.value ?? []),
    comparator: (a: FindingInfo[], b: FindingInfo[]) =>
      aggFindingLabels(a ?? []) > aggFindingLabels(b ?? []) ? 1 : -1,
    filter: "agSetColumnFilter",
    filterParams: {
      keyCreator: (params: KeyCreatorParams<FindingInfo>) =>
        params.value?.label ?? null,
      valueFormatter: (params: ValueFormatterParams<FindingInfo>) =>
        params.value?.label ?? null,
    },
    cellRenderer: (props: ICellRendererParams<IBGridLine, FindingInfo[]>) => {
      return (
        <LineClampedCell>
          <DenialCodeTags findingInfos={props.value ?? []} />
        </LineClampedCell>
      );
    },
  },
];
