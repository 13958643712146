/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/alt-text */
export const AutodorIcon = ({ width }: { width?: number }) => {
  return (
    <img
      src={require("../../resources/favicon-32x32.png")}
      style={{
        width: width ?? 14 + "px",
      }}
    ></img>
  );
};
