/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Drawer } from "antd";
import { gql, useQuery } from "urql";

import { batchClaimState, providerFragment } from "../../../fragments";
import { ClaimActionMenu } from "../../claimActionMenu/claimActionMenu";
import { UserContext } from "../../context/user";
import { BatchClaimMetaData } from "./batchClaimMetaData";
import { EventHistoryTimeline } from "./eventHistoryTimeline";

const BatchClaimOverviewDrawer = ({
  batchClaimId,
  isVisible,
  onClose,
  width,
  placement,
  setClaimActionMenuIsActionLoading,
  isActionLoading,
}: any) => {
  const { userType, permissions } = React.useContext(UserContext);

  const [{ fetching, data }] = useQuery({
    query: gql`
    query batchClaimAssigneeAuthorAndProviderInfoQuery($batchClaimId: UUID!) {
      batchClaim(id: $batchClaimId) {
        id
        computationStrategy
        paymentRate
        provider {
          ...providerFragment
        }
        ...batchClaimState
        ${
          !["PAYER", "PROVIDER"].includes(userType)
            ? `
                activeDueDate: batchClaimStateById {
                  id
                  dueDate
                }
                activeAssignees {
                  nodes {
                    id
                    firstName
                    lastName
                  }
                }
            `
            : ""
        }
      }
    }
  
    ${batchClaimState}
    ${providerFragment}
  `,
    variables: {
      batchClaimId,
    },
    pause: !batchClaimId,
  });

  return (
    <Drawer
      data-cy=" claim-list-table-batch-claim-overview-drawer"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="claim-list-table-batch-claim-overview-drawer"
      key="claim-list-table-batch-claim-overview-drawer"
      width={width ? width : "550"}
      title={
        <span>
          <span css={{ paddingRight: "50%" }}>Batch Claim Overview</span>
          {!fetching && data && (
            <span
              id={"batch-claim-overview-claim-action-menu"}
              key={"batch-claim-overview-claim-action-menu"}
            >
              <ClaimActionMenu
                setIsActionLoading={setClaimActionMenuIsActionLoading}
                batchClaims={[data.batchClaim]}
              />
            </span>
          )}
        </span>
      }
      placement={placement ? placement : "right"}
      onClose={onClose}
      open={isVisible}
    >
      {!fetching && data && (
        <div>
          <BatchClaimMetaData
            batchClaim={data.batchClaim}
            permissions={permissions}
          />
          <EventHistoryTimeline
            batchClaim={data.batchClaim}
            refreshTrigger={!isActionLoading}
          />
        </div>
      )}
    </Drawer>
  );
};

export { BatchClaimOverviewDrawer };
