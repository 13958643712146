/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { Menu, message } from "antd";
import { gql, useMutation, useQuery } from "urql";

import { batchClaimState } from "../../../fragments";
import { UserContext } from "../../context/user";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";
import { SupportingDocumentUploadModal } from "./upload/supportingDocumentUploadModal";

const batchClaimDocumentationStateQuery = gql`
  query batchClaimDocumentationStateQuery($batchClaimIds: [UUID!]) {
    batchClaims(filter: { id: { in: $batchClaimIds } }) {
      nodes {
        ...batchClaimState
      }
    }
  }
  ${batchClaimState}
`;

const deleteDocumentationRequestsMutation = gql`
  mutation deleteDocumentationRequests($batchClaimIds: [UUID!]!) {
    deleteDocumentationRequests(input: { batchClaimIds: $batchClaimIds }) {
      documentRequestCreateds {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const createDocumentationRequestsMutation = gql`
  mutation createDocumentationRequests($batchClaimIds: [UUID!]!) {
    createDocumentationRequests(input: { batchClaimIds: $batchClaimIds }) {
      documentRequestCreateds {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const createDocumentationRequestMenuItem = ({
  batchClaims,
  actionCriteria,
  title,
  setLoading,
}: any) => {
  const [, createDocumentationRequests] = useMutation(
    createDocumentationRequestsMutation,
  );

  // find the claims for which the action criteria is satisfied
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );

  const [, refreshDocumentationState] = useQuery({
    query: batchClaimDocumentationStateQuery,
    variables: {
      batchClaimIds: actionClaims.map(({ id }: any) => id),
    },
    pause: true, // only will use the refresh function
  });

  return (
    <Menu.Item
      key={`send-documentation-request-menuitem-${batchClaimsNodeKey(
        batchClaims,
      )}`}
      data-cy={`send-documentation-request-menuitem-${batchClaimsNodeKey(
        batchClaims,
      )}`}
      disabled={
        // disabled if no claims satisfy the action criteria
        !actionClaims.length
      }
      onClick={async () => {
        setLoading(true);
        const { error } = await createDocumentationRequests({
          batchClaimIds: actionClaims.map(({ id }: any) => id),
        });
        setLoading(false);
        if (error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          refreshDocumentationState({ requestPolicy: "network-only" });
          void message.success(`${title} success`);
        }
      }}
    >
      Send Request {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const deleteDocumentationRequestMenuItem = ({
  batchClaims,
  actionCriteria,
  title,
  setLoading,
}: any) => {
  const [_deleteResponse, deleteDocumentationRequests] = useMutation(
    deleteDocumentationRequestsMutation,
  );

  // find the claims for which the action criteria is satisfied
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );

  const [_refreshResponse, refreshDocumentationState] = useQuery({
    query: batchClaimDocumentationStateQuery,
    variables: {
      batchClaimIds: actionClaims.map(({ id }: any) => id),
    },
    pause: true, // only will use the refresh function
  });

  return (
    <Menu.Item
      key={`delete-documentation-request-menuitem-${batchClaimsNodeKey(
        batchClaims,
      )}`}
      data-cy={`delete-documentation-request-menuitem-${batchClaimsNodeKey(
        batchClaims,
      )}`}
      disabled={
        // disabled if no claims satisfy the action criteria
        !actionClaims.length
      }
      onClick={async () => {
        setLoading(true);
        const { error } = await deleteDocumentationRequests({
          batchClaimIds: actionClaims.map(({ id }: any) => id),
        });
        setLoading(false);
        if (error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          refreshDocumentationState({ requestPolicy: "network-only" });
          void message.success(`${title} success`);
        }
      }}
    >
      Remove Request {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const uploadDocumentsMenuItem = ({
  batchClaimId,
  disabled,
  uploadModalVisible,
  setUploadModalVisible,
  claimListDataFragment,
}: any) => {
  return (
    <Menu.Item
      key={`documents-submenu-item-upload-documents-${batchClaimId}`}
      data-cy={`documents-submenu-item-upload-documents-${batchClaimId}`}
      disabled={disabled}
    >
      <div onClick={() => setUploadModalVisible(true)}>Upload Documents</div>

      <SupportingDocumentUploadModal
        batchClaimId={batchClaimId}
        visible={uploadModalVisible}
        setVisible={setUploadModalVisible}
        claimListDataFragment={claimListDataFragment}
      />
    </Menu.Item>
  );
};

const typeActions = {
  request: {
    title: "Documentation Request",
    actionCriteria: ({
      batchClaimState: { documentationClaimState, workflowClaimState },
    }: any) => {
      // A claim that is in progress with no current doc request
      const satisfied =
        documentationClaimState !== "AWAITING_UPLOAD" &&
        workflowClaimState === "IN_PROGRESS";
      return { satisfied };
    },
    renderMenuItem: createDocumentationRequestMenuItem,
  },
  deleteRequest: {
    title: "Remove Request",
    actionCriteria: ({ batchClaimState: { documentationClaimState } }: any) => {
      // can only remove a request if AWAITING_UPLOAD
      const satisfied = documentationClaimState === "AWAITING_UPLOAD";
      return { satisfied };
    },
    renderMenuItem: deleteDocumentationRequestMenuItem,
  },
  upload: {
    title: "Upload Documents",
    renderMenuItem: ({ batchClaims }: any) => {
      const {
        claimList: { dataFragment },
      } = useContext(UserContext);

      // if there is 1 claim in the array, read it off as the claim to
      // upload for, if not then disable the button and set uploadForBatchClaim
      // to null
      const [uploadModalVisible, setUploadModalVisible] = useState(false);
      const uploadForBatchClaim =
        batchClaims.length === 1 ? batchClaims[0] : null;
      const disabled = batchClaims.length !== 1;
      return uploadDocumentsMenuItem({
        batchClaimId: uploadForBatchClaim?.id,
        disabled,
        uploadModalVisible,
        setUploadModalVisible,
        claimListDataFragment: dataFragment,
      });
    },
  },
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  return (
    <Menu.SubMenu
      key={`action-submenu-documents-${batchClaimsNodeKey(batchClaims)}`}
      data-cy={`action-submenu-documents-${batchClaimsNodeKey(batchClaims)}`}
      title={"Documents"}
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          // check permissions for the document action category
          permissions.claimActions.documentation.includes(action),
        )
        .map(([_action, { renderMenuItem, ...actionProps }]) =>
          renderMenuItem({
            batchClaims,
            setLoading,
            ...actionProps,
          }),
        )}
    </Menu.SubMenu>
  );
};

const documentation = {
  title: "Documentation",
  subMenu: actionSubMenu,
};

export { documentation };
