/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { JsonView } from "react-json-view-lite";
import tw, { css } from "twin.macro";

/*
    cursor: default;
    -webkit-user-select: none;
    user-select: none;
*/
const clsJv = css({
  ...tw`af-p-0 af-m-0`,
  "& .jv-container": tw`af-p-2`,
  "& .jv-basicChildStyle": tw`af-ml-3`,
  "& .jv-label": tw`af-mr-1 af-text-xs af-text-gray-800 dark:af-text-gray-200`,
  "& .jv-nullValue": tw`af-text-orange-600  dark:af-text-orange-200`,
  "& .jv-undefinedValue": tw`af-text-red-800  dark:af-text-red-200`,
  "& .jv-stringValue": tw`af-cursor-text af-select-text af-text-amber-800  dark:af-text-amber-300`,
  "& .jv-booleanValue": tw`af-text-blue-700  dark:af-text-blue-200`,
  "& .jv-numberValue": tw`af-cursor-text af-select-text af-text-green-800  dark:af-text-green-400`,
  "& .jv-otherValue": tw`af-text-amber-400  dark:af-text-amber-200`,
  "& .jv-expander": tw`af-mr-1 af-text-lg af-font-bold af-text-gray-700  dark:af-text-gray-400`,
  "& .jv-punctuation": tw`af-font-bold af-text-blue-500  dark:af-text-blue-400`,
  "& .jv-pointer": tw`af-cursor-pointer `,
  // '& .jv-pointer': {},
});

const _styleMap = {
  container: "jv-container",
  basicChildStyle: "jv-basicChildStyle",
  label: "jv-label",
  nullValue: "jv-nullValue",
  undefinedValue: "jv-undefinedValue",
  stringValue: "jv-stringValue",
  booleanValue: "jv-booleanValue",
  numberValue: "jv-numberValue",
  otherValue: "jv-otherValue",
  expander: "jv-expander",
  punctuation: "jv-punctuation",
  pointer: "jv-pointer",
};

export interface ObjectViewerProps {
  objectToView: Record<string, any>;
  styleMap?: typeof _styleMap;
  initialExpandLevel?: number;
}

export const ObjectViewer: FC<ObjectViewerProps> = ({
  objectToView,
  styleMap,
  initialExpandLevel = 1,
}) =>
  styleMap ? (
    <div>
      <JsonView
        data={objectToView}
        shouldInitiallyExpand={(level) => level < initialExpandLevel}
        style={styleMap}
      />
    </div>
  ) : (
    <div css={clsJv}>
      <JsonView
        data={objectToView}
        shouldInitiallyExpand={(level) => level < initialExpandLevel}
        style={_styleMap}
      />
    </div>
  );
