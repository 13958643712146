import type { Components } from "react-markdown";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

export const Ol: Components["ol"] = ({ children, className, ...rest }) => (
  <ol
    className={cn(
      "af-text-md af-ml-4 af-mt-4 af-flex af-list-inside af-list-decimal af-flex-col af-gap-3",
      className,
    )}
    {...rest}
  >
    {children}
  </ol>
);
