/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import type { FC } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AutoComplete, Form, Input } from "antd";
import { intersection, pick } from "lodash";

import type { AutodorMetadata } from "../../../components/audit/claimWorkspace/findingEdit/types";
import { RuleDetails } from "../../../components/audit/shared/components/finding/autodorDetails/ruleDetails";
import type { TemplateDefinitions } from "../../../components/audit/shared/components/finding/autodorDetails/ruleDisplayTypes";
import { defaultHbsTemplates } from "../../../components/audit/shared/components/finding/autodorDetails/ruleTypeTemplates";
import { AutodorBillType } from "../../../gql/graphql";
import { VerticalSplitter } from "../splitter/verticalSplitter";

const { TextArea } = Input;

interface JsonParseResult {
  json: Record<string, any> | null;
  syntaxError: SyntaxError | undefined;
  error: undefined | any;
}

const parseJson = (val: string) => {
  const result = getEmptyJsonResult();
  try {
    result.json = JSON.parse(val);
  } catch (error) {
    result.error = error;
    if (error instanceof SyntaxError) {
      result.syntaxError = error;
    } else {
      result.error = error;
    }
  }
  return result;
};

const DataPane: FC<{
  jsonResult: JsonParseResult;
  setJson: (jsonResult: JsonParseResult) => JsonParseResult;
  templates: TemplateDefinitions;
  setTemplates: (templates: TemplateDefinitions) => void;
  displayedTemplateName: string;
  setDisplayedTemplateName: (displayedTemplateName: string) => void;
}> = ({
  jsonResult,
  setJson,
  templates,
  setTemplates,
  displayedTemplateName,
  setDisplayedTemplateName,
}) => {
  // TODO implement the 'showExtraProps' -- override disabled for now so it picks up rule def...
  const [form] = Form.useForm();

  const templateOptions = useMemo(
    () => [
      ...Object.keys(templates).map((k) => ({
        label: k,
        value: k,
      })),
    ],
    [templates],
  );

  const onChangeTemplate = (newTemplateName: string) => {
    if (!newTemplateName) {
      form.setFieldValue("template", "Select template to view");
    } else if (templates[newTemplateName]) {
      form.setFieldValue("template", templates[newTemplateName].template);
    }
  };

  const templateName = Form.useWatch("templateName", form);
  useEffect(() => {
    setDisplayedTemplateName(templateName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateName]);

  const template = Form.useWatch("template", form);
  useEffect(() => {
    if (templateName) {
      setTemplates({
        ...templates,
        [templateName]: {
          template,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template]);

  const data = Form.useWatch("data", form);
  useEffect(() => {
    const jsonResult = setJson(parseJson(data));
    if (jsonResult.error) {
      console.log("json error", jsonResult);
    } else {
      // console.log('JSON', jsonResult.json);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div>
      <Form form={form} layout="vertical">
        <Form.Item
          label="template name"
          name="templateName"
          initialValue={displayedTemplateName}
        >
          <AutoComplete
            options={templateOptions}
            style={{ width: "100%" }}
            onSelect={onChangeTemplate}
            // onSearch={text => setOptions(text)}
            placeholder="Choose a template to edit"
          />
        </Form.Item>
        <Form.Item
          label="template"
          name="template"
          initialValue="Choose or add a template name to edit."
        >
          <TextArea disabled={templateName === ""} rows={15} />
        </Form.Item>
        <Form.Item
          label="data"
          name="data"
          initialValue={JSON.stringify(jsonResult.json, null, 3)}
        >
          <TextArea rows={10} />
        </Form.Item>
      </Form>
      <div>{jsonResult?.syntaxError?.message ?? jsonResult.error?.message}</div>
    </div>
  );
};

const PreviewPane = ({
  autodorMetadata,
  templates,
}: {
  autodorMetadata: AutodorMetadata;
  templates: TemplateDefinitions;
}) => {
  const keysInTemplates = Object.keys(templates);
  const keysInData = Object.keys(autodorMetadata.rule);
  const matches = intersection(keysInData, keysInTemplates);

  return matches.length < 1 ? (
    <div>
      <div tw="af-text-antd-lt-colorError dark:af-text-antd-dk-colorError">
        Select or add a template and ensure the data pane has a matching prop
      </div>
      <div>Template: {keysInTemplates.join(", ")}</div>
      <div>Data keys in AutodorMetadata.rule: {keysInData.join(", ")}</div>
    </div>
  ) : (
    <RuleDetails
      autodorMetadata={autodorMetadata}
      templates={templates}
      divider={
        <>
          <br />
          <hr />
          <br />
        </>
      }
      // showExtraPropsOverride={true}
    ></RuleDetails>
  );
};

export const HbsPreview: FC<object> = () => {
  const [displayedTemplateName, setDisplayedTemplateName] = useState("");

  const [templates, setTemplates] =
    useState<TemplateDefinitions>(defaultHbsTemplates);

  const [jsonResult, _setJson] = useState<JsonParseResult>(
    getDefaultJsonResult(),
  );

  const displayedTemplates = useMemo(() => {
    if (!displayedTemplateName) {
      return {};
    }
    return pick(templates, displayedTemplateName);
  }, [templates, displayedTemplateName]);

  const setJson = useCallback(
    (_jsonResult: JsonParseResult) => {
      _setJson(() => _jsonResult);
      return _jsonResult;
    },
    [_setJson],
  );

  return (
    <div tw="af-h-full af-min-h-full">
      <VerticalSplitter
        left={
          <DataPane
            jsonResult={jsonResult}
            setJson={setJson}
            templates={templates}
            setTemplates={setTemplates}
            displayedTemplateName={displayedTemplateName}
            setDisplayedTemplateName={setDisplayedTemplateName}
          />
        }
        right={
          <PreviewPane
            tw="af-overflow-auto af-w-full af-h-full af-min-h-full"
            autodorMetadata={getAsMetadata(displayedTemplateName, jsonResult)}
            templates={displayedTemplates}
          />
        }
      ></VerticalSplitter>
    </div>
  );
};

function getEmptyJsonResult() {
  const result: JsonParseResult = {
    json: {},
    syntaxError: undefined,
    error: undefined,
  };
  return result;
}

function getAsMetadata(
  key: string,
  jsonResult: JsonParseResult,
): AutodorMetadata {
  return {
    auditType: "NCCI_PTP",
    billType: AutodorBillType.IB,
    dateOfServiceStart: new Date("2023-01-01"),
    lineNumber: 2,
    procedureCode: "0002A",
    procedureCodeModifier: "bb",
    quarter: "q1",
    unitCharge: 10,
    units: 1,
    versionId: "84757258-1dbd-4655-b218-746cc8520aae",
    year: "2023",
    rule: {
      _type: key,
      __typename: "AuditRule",
      [key]: jsonResult?.json?.[
        key as keyof typeof jsonResult
      ] as AutodorMetadata["rule"],
    },
    __typename: "AutodorMetadata",
  } as unknown as AutodorMetadata;
}

function getDefaultJsonResult() {
  const result = getEmptyJsonResult();
  result.json = {
    mue: {
      description:
        '[i] MUE MAI "3" indicates a date of service (DOS) edit based on clinical benchmarks...',
      code: "0238T",
      num: 2,
      rationale: {
        value: "Clinical: Data",
        enumeration: "Clinical Data",
        __typename: "MUEAuditRuleRationale",
      },
      editType: "Outpatient",
      mai: "DOS_PER_DAY_EDIT_ON_CLINICAL_BENCHMARK",
      __typename: "MUEAuditRule",
    },
    ptp: {
      colOneCode: "0082A",
      colTwoCode: "0002A",
      extendedDescription:
        "[i] Edit Description: Code 82565 is a column 2 code for 80053, but you may use a NCCI-associated modifier to override the edit under appropriate circumstances. The current NCCI-associated modifiers are: E1, E2, E3, E4, FA, F1, F2, F3, F4, F5, F6, F7, F8, F9, LC, LD, LM, RC, RI, LT, RT, TA, T1, T2, T3, T4, T5, T6, T7, T8, T9, XE, XP, XS, XU,24, 25,",
      rationale: "Mutually exclusive procedures",
      __typename: "PTPAuditRule",
    },
    nonBillable: {
      code: "code nonbill",
      description:
        "Dolorem ipsum dolor sit amet, consectetur adipiscing elit, sed",
      __typename: "NonBillableAuditRule",
    },
    genericRule: {
      rule_type: "PTP_RULE",
      rule: {
        col_one_code: "0082A",
        col_two_code: "0002A",
        extended_description:
          "[i] Edit Description: Code 82565 is a column 2 code for 80053, but you may use a NCCI-associated modifier to override the edit under appropriate circumstances. The current NCCI-associated modifiers are: E1, E2, E3, E4, FA, F1, F2, F3, F4, F5, F6, F7, F8, F9, LC, LD, LM, RC, RI, LT, RT, TA, T1, T2, T3, T4, T5, T6, T7, T8, T9, XE, XP, XS, XU,24, 25,",
        rationale: "Mutually exclusive procedures",
      },
    },
  };
  return result;
}
