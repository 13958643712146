"use client";

import { createContext, useContext } from "react";

import { WithExistingQueryProvider } from "./with-existing-query-provider";
import type { RequiredJrWorkerConfig } from "./worker-provider";

interface PDFContextConfig {
  jrConfig: RequiredJrWorkerConfig;
  strategy?: "preload" | "lazy";
  libPath: string;
}

interface PDFViewerProviderProps extends PDFContextConfig {
  children?: React.ReactNode;
}

const PDFConfigContext = createContext<PDFContextConfig | undefined>(undefined);

/**
 * Top level config provider for the PDFViewer. This provider should wrap the whole
 * scope where all PDF Viewers will be used. This regularly means the whole
 * application.
 *
 * Example:
 * ```tsx
 * import { PDFConfigProvider } from "@alaffia-technology-solutions/pdf-viewer";
 *
 * const App = () => {
 *  return (
 *    <PDFConfigProvider jrConfig={jrConfig}>
 *      <AppContent />
 *    </PDFConfigProvider>
 *  );
 * };
 * ```
 */

export const PDFConfigProvider = ({
  children,
  ...config
}: PDFViewerProviderProps) => {
  return (
    <PDFConfigContext.Provider value={config}>
      <WithExistingQueryProvider>{children}</WithExistingQueryProvider>
    </PDFConfigContext.Provider>
  );
};

export const usePDFConfig = () => {
  const config = useContext(PDFConfigContext);

  if (config === undefined) {
    throw new Error("usePDFConfig must be used within a PDFConfigProvider");
  }
  return config;
};
