import { z } from "zod";

import { File } from "./file";

export const MessageCreateInput = z.object({
  threadId: z.string(),
  fileIds: z.array(z.string()),
  promptText: z.string(),
  inputState: z.string(),
  searchKeywords: z.array(z.string()).nullable(),
});
export type MessageCreateInput = z.infer<typeof MessageCreateInput>;

export const MessageByIdInput = z.object({ id: z.number() });
export type MessageByIdInput = z.infer<typeof MessageByIdInput>;

export const MessageByCaseIdInput = z.object({ caseId: z.number() });
export type MessageByCaseIdInput = z.infer<typeof MessageByCaseIdInput>;

export const PromptResponsePage = z.object({
  file: File.pick({ id: true, fileName: true }),
  textMatched: z.string(),
  pageNumber: z.number(),
  confidence: z.number().optional().nullable(),
});
export type PromptResponsePage = z.infer<typeof PromptResponsePage>;

export const PromptResponse = z.object({
  id: z.string(),
  promptText: z.string(),
  responseText: z.string(),
  pages: z.array(PromptResponsePage),
  createdAt: z.string(),
  searchKeywords: z.array(z.string()).nullable().optional(),
});
export type PromptResponse = z.infer<typeof PromptResponse>;

export const PromptFeedbackLevel = z.enum(["INFO", "WARNING", "ERROR"]);
export type PromptFeedbackLevel = z.infer<typeof PromptFeedbackLevel>;

export const PromptFeedback = z.object({
  level: PromptFeedbackLevel,
  message: z.string(),
});
export type PromptFeedback = z.infer<typeof PromptFeedback>;

export const Message = z.object({
  id: z.string(),
  promptText: z.string(),
  promptInputState: z.string(),
  response: PromptResponse.optional().nullable(),
  createdAt: z.string(),
  feedback: z.array(PromptFeedback).optional().nullable(),
});
export type Message = z.infer<typeof Message>;

export const Thread = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string(),
  messages: z.array(Message),
});
export type Thread = z.infer<typeof Thread>;
