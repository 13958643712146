/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo } from "react";
import { message } from "antd";
import { isFunction } from "lodash";

import { useDenialCodes } from "../../../queries/denialCodes/useDenialCodes";
import { showFindingSaveError } from "../form/findingSaveError";
import { useDenialCodeChanges } from "../form/useDenialCodeChanges";
import type { FormChangeHandler } from "../form/useFormChanges";
import { useFormChanges } from "../form/useFormChanges";
import { useIbFindingRepricing } from "../form/useIbFindingRepricing";
import type { FindingEditor } from "../useFindingEditor";
import type {
  EditFindingValues,
  FormAdapter,
  InitialEditFindingValues,
} from "../viewController/types";
import { useFindingViewController } from "../viewController/useFindingViewController";

export const useFindingModalVm = (
  findingEditor: FindingEditor,
  formAdapter: FormAdapter<EditFindingValues>,
  closeModalFn: () => void,
) => {
  const { state, controller } = useFindingViewController(
    findingEditor.viewControllerConfig,
  );

  const denialCodes = useDenialCodes();
  const onDenialCodeChange = useDenialCodeChanges(
    denialCodes,
    state,
    formAdapter,
  );

  // improvement: wrap this logic in a hook and ensure that reasonable defaults
  // are provided so that form change / event handling is zero config, but
  // a VM can opt to provide fns to tap into the process
  const currentHandler: FormChangeHandler<EditFindingValues> = useMemo(
    () => ({
      onValuesChange: ({ changedValues, allValues }) => {
        if (changedValues.auditFindingValues?.auditFindingRuleType) {
          onDenialCodeChange(
            changedValues.auditFindingValues.auditFindingRuleType,
            allValues.auditFindingValues?.improperPaymentReason,
          );
        }
        return {
          changedValues,
          allValues,
        };
      },
      onFieldsChange: (fieldData) => {
        return fieldData;
      },
      onControllerEvent: (evt: any): void => {
        // improvement: per note above, ensure controller events are typed
        //  and defaults work as expected if unhandled/un-configured events are sent
        //  by controller code customizations.
        if (isFunction(formAdapter?.setValues)) {
          formAdapter.setValues(evt.fields);
        }
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { setFields } = useFormChanges<EditFindingValues>({
    currentHandler,
    nextHandler: controller,
    formAdapter,
  });

  const {
    ready,
    type,
    defaultValues,
    existingFindingValues,
    revCodesForSelectedLines,
    operation,
    adjustments,
  } = state;

  const initialValues: InitialEditFindingValues = {
    defaultValues,
    existingFindingValues,
  };

  const ibLines = operation?.ibData;

  useIbFindingRepricing({ adjustments, defaultValues, setFields });

  const onFinish = useCallback((values: any) => {
    return controller
      .save(values)
      .then((result: any) => {
        console.debug(
          "%cFinding save result",
          "background-color: yellow",
          result,
        );
        void message.success(
          `${
            result.type === "IB_CLAIM_LINE" ? "Itemized" : ""
          } Claim Review Finding${
            result.isVersion ? " Version " : " "
          }Created!`,
        );
        closeModalFn();
        return result;
      })
      .catch((err: any) => {
        console.error("Error saving finding!", err);
        showFindingSaveError(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.debug("Form viewModel update", {
    info: { ready, state, controller },
  });

  return {
    ready,
    type,
    initialValues,
    revCodesForSelectedLines,
    adjustments,
    ibLines,
    denialCodes,
    save: controller.save,
    onFinish,
    setFields,
  };
};
