export const DEFAULT_SCALE = "fitWidth";
export const MIN_SCALE = 0.1;
export const MAX_SCALE = 2;

/**
 * Foxit rotatePages api says Rotation is a enum, but it's not reflected in the type definition:
 * https://webviewer-demo.foxit.com/docs/API_Reference/html/group___consts_p_d_f.html#ga4940d1dc528122726d2c8c475657e1a9
 * where in type definition rotation param is just a number.
 */

export const ROTATE_0 = 0;
export const ROTATE_90 = 1;
export const ROTATE_180 = 2;
export const ROTATE_270 = 3;
