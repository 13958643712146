import { graphql } from "../gql";

export const editAuditorUserMutationDoc = graphql(`
  mutation editAuditorUser($userId: ID!, $input: EditAuditorUserInput!) {
    editAuditorUser(userId: $userId, input: $input) {
      ...AuditorUserFragment
      providerUserFacilities {
        nodes {
          ...ProviderUserFacilityFragment
        }
      }
    }
  }
`);
