/* eslint-disable no-constant-binary-expression */

/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-base-to-string */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { css } from "@emotion/react";
import { Form, message, Modal } from "antd";
import Draggable from "react-draggable";
import { gql, useMutation, useQuery } from "urql";

import {
  adminAndAuditorWorkstationBatchClaimFragment,
  auditFindingWithReviewFragment,
  versionAuditFindingFragment,
} from "../../../../fragments";
import { UserContext } from "../../../context/user";
import { useClaimPostFindingRefresh } from "../../queries/claim/useClaimFindingsAndLine";
import { denormalize } from "../../util";
import { createAuditFindingError } from "./../../../../util/errors";
import {
  AuditFindingSeedType,
  createIbLineFindingInput,
  createUbClaimFindingInput,
  createUbClaimLineFindingInput,
  versionFindingInput,
  versionIbFindingInput,
} from "./auditFindingUtil";
import { CreateClaimLineAuditFindingForm } from "./createClaimLineAuditFindingForm";

// FIXME
// auditFindingCount: auditFindings(filter: $afclFilter) {
// does not work!
// GraphQL fails with           "message": "Variable \"$afclFilter\" is not defined by operation \"createAuditFinding\".",
const refreshBatchClaimQuery = gql`
  query refreshBatchClaim($batchClaimId: UUID!) {
    # update determined and potential amounts in the cache
    batchClaim(id: $batchClaimId) {
      # because ONLY auditors & Admins can manipluate findings (Create, Decline, Review, etc) we don't select the return fragment based on users
      # and return the 'adminAndAuditorWorkstationBatchClaimFragment' by default
      ...adminAndAuditorWorkstationBatchClaimFragment
      auditFindingCount: auditFindings(
        filter: { isActive: { equalTo: true } }
      ) {
        totalCount
        nodes {
          id
        }
      }
    }
  }
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

const createIbLineFindingsMutation = gql`
  mutation createIbLineFindings(
    $input: CreateIbLineFindingsInput! # $afclFilter: AuditFindingFilter!
  ) {
    createAuditFindings: createIbLineFindings(input: $input) {
      auditFindings {
        ...auditFindingWithReviewFragment
      }
    }
  }
  ${auditFindingWithReviewFragment}
`;

const createUbClaimLineFindingsMutation = gql`
  mutation createUbClaimLineFindings(
    $input: CreateUbClaimLineFindingsInput! # $afclFilter: AuditFindingFilter!
  ) {
    createAuditFindings: createUbClaimLineFindings(input: $input) {
      auditFindings {
        ...auditFindingWithReviewFragment
      }
    }
  }
  ${auditFindingWithReviewFragment}
`;

const createUbClaimFindingsMutation = gql`
  mutation createUbClaimFindings(
    $input: CreateUbClaimFindingsInput! # $afclFilter: AuditFindingFilter!
  ) {
    createAuditFindings: createUbClaimFindings(input: $input) {
      auditFindings {
        ...auditFindingWithReviewFragment
      }
    }
  }
  ${auditFindingWithReviewFragment}
`;

// n.b. versionAuditFindingFragment is intentionally light-weight as called in nested resolver -- see note at graphql_api aliasFields.js:
const versionAuditFindingsMutation = gql`
  mutation versionAuditFindings(
    $input: VersionAuditFindingsInput! # $afclFilter: AuditFindingFilter! # $claimId: UUID!
  ) {
    createAuditFindings: versionAuditFindings(input: $input) {
      auditFindings {
        ...versionAuditFindingFragment
      }
    }
  }
  ${versionAuditFindingFragment}
`;

const CreateAuditFindingModal = (props: any) => {
  const {
    setCreatingAuditFinding,
    updateAuditFindingErrorHandler = (e: any) =>
      message.error(createAuditFindingError(e)),
    visible,
    batchClaim,
    batchClaimLine,
    auditFinding: existingAuditFinding, // the latest audit finding in the version history
    afclFilter,
    nnIbLineFingerprints = [],
    s3Key,
    auditFindingSeedType: initialAuditFindingSeedType,
    ibData,
  } = props;

  if (existingAuditFinding) {
    console.debug(`reviewing audit finding:`, existingAuditFinding);
  }

  // improvement: pass an enum type or separate elements of this component to
  // concrete types that implement the peculiarities of UB_CLAIM_LINE, IB_CLAIM_LINE
  // (pass control props (ioc) as {displayComponent: ['formsetA', 'formsetB'], onFinish: () => ...} ?)
  const isUbTab =
    initialAuditFindingSeedType === AuditFindingSeedType.UB_CLAIM_LINE;

  const isIbexTab =
    (initialAuditFindingSeedType === AuditFindingSeedType.IB_CLAIM_LINE &&
      ibData?.length > 0) ||
    !!existingAuditFinding?.findingItemizedBillData; // ?.ibLine;  // TODO!!!
  // console.log('isIbexTab', isIbexTab, existingAuditFinding);

  // friendly display names for the
  // modal title
  const auditFindingTypeDisplayName = {
    UB_CLAIM: "Entire Claim",
    UB_CLAIM_LINE: "Rev. Code",
    IB_CLAIM_LINE: "Itemized",
  };

  const { id: authorId } = React.useContext(UserContext);

  // allows modal to be dragged to a new position
  const draggableRef = React.useRef(null);

  // forms
  const [form] = Form.useForm();

  // If there is an original audit finding,
  // then this audit finding is being created as a version
  // of the original audit finding
  const originalAuditFinding = existingAuditFinding?.original
    ? existingAuditFinding.original.auditFinding
    : existingAuditFinding;

  // if reviewing, use existing AF since non-editable - prevents needing to pass the
  // bcl on screens where only review/decline is available
  const getUbTabBatchClaimLineId = () =>
    existingAuditFinding?.batchClaimLineId || batchClaimLine?.id;
  const getUbTabRevCode = () =>
    existingAuditFinding?.revCode || batchClaimLine?.revCode;

  const isReviewing =
    originalAuditFinding &&
    originalAuditFinding.id !== null &&
    originalAuditFinding.id !== undefined;

  // mutations
  const [{ fetching: createIbLineFetching }, createIbLineFinding] = useMutation(
    createIbLineFindingsMutation,
  );
  const [{ fetching: createUbClaimLineFetching }, createUbClaimLineFinding] =
    useMutation(createUbClaimLineFindingsMutation);
  const [{ fetching: createUbClaimFetching }, createUbClaimFinding] =
    useMutation(createUbClaimFindingsMutation);
  const [{ fetching: fetchingVersion }, versionAuditFinding] = useMutation(
    versionAuditFindingsMutation,
  );

  // queries
  const [
    { fetching: refreshingBatchClaim, error: refreshBatchClaimError },
    refreshBatchClaim,
  ] = useQuery({
    query: refreshBatchClaimQuery,
    variables: { batchClaimId: batchClaim.id },
    requestPolicy: "network-only",
    pause: true,
  });

  const [refreshingIbLines, setRefreshingIbLines] = React.useState(false);

  const claimRefresher = useClaimPostFindingRefresh();

  const [ibLineAdjustments, setIbLineAdjustments] = React.useState([]);

  // create function
  const onFinish = async () =>
    Promise.resolve()
      .then(async () => {
        const formValues: any = await form
          .validateFields()
          .then((oo) => {
            console.debug("--> Form Values:", oo);
            return denormalize(oo);
          })
          .catch((error) => {
            console.error("Audit finding has errors:", error);
          });

        if (!formValues) {
          console.log("no form values");
          return;
        }
        const { batchClaimLineItemFormValues, ...auditFindingFormValues } =
          formValues;

        const updatedAuditFindingSeedType =
          auditFindingFormValues.auditFindingSeedType;

        if (
          batchClaimLineItemFormValues &&
          updatedAuditFindingSeedType !== AuditFindingSeedType.IB_CLAIM_LINE
        ) {
          console.warn(
            "Batch claim line item values are set, but seed type is %s; values %O",
            updatedAuditFindingSeedType,
            batchClaimLineItemFormValues,
          );
          void message.warning(
            `Finding type is not IB Line but line item values present, they will be ignored...`,
          );
        }

        const getCreateAuditFindingMutation = (createPayloadInput: any) => {
          switch (updatedAuditFindingSeedType) {
            case AuditFindingSeedType.UB_CLAIM:
              return () =>
                createUbClaimFinding({
                  input: createUbClaimFindingInput(createPayloadInput),
                  afclFilter,
                });
            case AuditFindingSeedType.UB_CLAIM_LINE:
              return () =>
                createUbClaimLineFinding({
                  input: createUbClaimLineFindingInput(createPayloadInput),
                  afclFilter,
                });
            case AuditFindingSeedType.IB_CLAIM_LINE:
              return () =>
                createIbLineFinding({
                  input: createIbLineFindingInput(createPayloadInput),
                  afclFilter,
                });
            default:
              throw new Error(
                `Create Audit Finding - Unknown AuditFindingSeedType: ${AuditFindingSeedType}`,
              );
          }
        };

        const getVersionAuditFindingMutation = (createPayloadInput: any) => {
          switch (updatedAuditFindingSeedType) {
            case AuditFindingSeedType.UB_CLAIM:
            case AuditFindingSeedType.UB_CLAIM_LINE:
              return () =>
                versionAuditFinding({
                  input: versionFindingInput(createPayloadInput),
                  // claimId: batchClaim.id,
                  afclFilter,
                });
            case AuditFindingSeedType.IB_CLAIM_LINE:
              return () =>
                versionAuditFinding({
                  input: versionIbFindingInput(createPayloadInput),
                  // claimId: batchClaim.id,
                  afclFilter,
                });
            default:
              throw new Error(
                `Version Audit Finding - Unknown AuditFindingSeedType: ${updatedAuditFindingSeedType}`,
              );
          }
        };

        const createMutationInput = {
          authorId,
          batchClaim,
          ubTabBatchClaimLineId: getUbTabBatchClaimLineId(),
          auditFindingBeingVersioned: existingAuditFinding,
          auditFindingFormValues: {
            ...auditFindingFormValues,
            auditFindingSeedType: isReviewing
              ? originalAuditFinding.auditFindingSeedType
              : updatedAuditFindingSeedType,
          },
          batchClaimLineItemFormValues: {
            ...batchClaimLineItemFormValues,
            // If the revCodeValue is undefined populate it automatically
            // with batchClaimLine information for UB_CLAIM_LINE seed type
            revCodeValue:
              batchClaimLineItemFormValues?.revCodeValue ||
              (isUbTab &&
                [getUbTabBatchClaimLineId(), getUbTabRevCode()].join(":")),
          },
          propValues: {
            originalAuditFinding,
            nnIbLineFingerprints,
            ibData,
            ibLineAdjustments,
            s3Key,
          },
        };

        const mutation = isReviewing
          ? getVersionAuditFindingMutation(createMutationInput)
          : getCreateAuditFindingMutation(createMutationInput);

        // console.log('mutation', mutation);

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        const { data, fetching, error } = await mutation().catch((error) => {
          console.log("error", error);
          return { data: null, fetching: false, error };
        });

        if (!fetching && error) {
          // on error
          updateAuditFindingErrorHandler(error);
          return; // leave the dialog open in case they'd like to retry or copy their work
        } else if (!fetching && data.createAuditFindings) {
          // on success
          void message.success(
            `${
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              auditFindingTypeDisplayName[updatedAuditFindingSeedType]
            } Claim Review Finding${isReviewing ? " Version " : " "}Created!`,
          );

          // todo dedupe this with the same code in declineAuditFindingModal & claimWorkspaceAuditFinding
          //  -> maybe a hook for all types of these?  the other two have common paths to finding/claim ids
          // so we'd need some logic, perhaps pass a path and use _.get or _.result if it can handle array idx

          // const newIbLineId =
          //   data?.createAuditFindings?.auditFindings[0]?.batchClaimLineItemAudit
          //     ?.batchClaimLineItem?.ibLineId;
          // const newAfId = data?.createAuditFindings?.auditFindings[0]?.id;

          const ibLineIds = data?.createAuditFindings?.auditFindings
            ?.filter(
              (af: any) =>
                !!af.batchClaimLineItemAudit?.batchClaimLineItem?.ibLineId ??
                data.createReviewedAuditFinding?.reviewedAuditFinding
                  ?.auditFinding?.batchClaimLineItemAudit?.batchClaimLineItem
                  ?.alaRowId,
            )
            .map(
              (af: any) =>
                af.batchClaimLineItemAudit.batchClaimLineItem.ibLineId ??
                data.createReviewedAuditFinding?.reviewedAuditFinding
                  ?.auditFinding?.batchClaimLineItemAudit?.batchClaimLineItem
                  ?.alaRowId,
            );

          refreshBatchClaim();

          if (ibLineIds && ibLineIds?.length > 0) {
            setRefreshingIbLines(true);
            await claimRefresher({
              claimId: batchClaim.id,
              ibLineIds,
            })
              .then(() => setRefreshingIbLines(false))
              .catch((err) => {
                console.log(
                  "Error refreshing claim itemizedBillLines and Findings",
                  err,
                );
                setRefreshingIbLines(false);
                return { data: null, fetching: false, error: err };
              });
          }
        } else {
          void message.error("Something went wrong, please try again");
        }

        form.resetFields();
        setCreatingAuditFinding({ visible: false });
      })
      .catch((err) => {
        // outer catch, relay unexpected error
        console.log("Unhandled error creating finding", err);
        void message.error(
          "An error occurred creating the finding; please contact support",
        );
      });

  React.useEffect(() => {
    if (refreshBatchClaimError) {
      console.error(refreshBatchClaimError);
      void message.error(
        "Failed to refresh Batch Claim information. Please refresh manually!",
      );
    }
  }, [refreshBatchClaimError]);

  const createAuditFindingFormProps = {
    form,
    batchClaim,
    batchClaimLine,
    originalAuditFinding: existingAuditFinding,
    onFinish,
    auditFindingSeedType: initialAuditFindingSeedType,
    numFindings: nnIbLineFingerprints?.length || 1,
    isIbexTab,
    ibData,
    ibLineAdjustments,
    setIbLineAdjustments,
  };

  const numberOfFindings = nnIbLineFingerprints?.length || ibData?.length || 1;

  const modalProps = {
    title: `${existingAuditFinding ? "Review" : "Create"} Finding${
      !originalAuditFinding && numberOfFindings > 1 ? "s" : ""
    }`,
    css: css`
      width: 100%;
      cursor: move;
    `,
    open: visible,
    onOk: onFinish,
    okText:
      numberOfFindings > 1
        ? `Create (${numberOfFindings} ${
            numberOfFindings > 1 ? "findings" : "finding"
          })`
        : `Create`,
    maskClosable: false,
    mask: false,
    onCancel: () => {
      form.resetFields();
      setCreatingAuditFinding({ visible: false });
    },
    confirmLoading:
      createIbLineFetching ||
      createUbClaimLineFetching ||
      createUbClaimFetching ||
      fetchingVersion ||
      refreshingBatchClaim ||
      refreshingIbLines,
    destroyOnClose: true,
    // used to implement react-draggable
    // fix eslintjsx-a11y/mouse-events-have-key-events
    // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
    onFocus: () => {},
    onBlur: () => {},
    // The badly-named 'cancel' prop accepts a selector for elements that shouldn't start drag
    // https://www.npmjs.com/package/react-draggable - search 'cancel'
    modalRender: (modal: any) => (
      <Draggable
        nodeRef={draggableRef}
        cancel={
          "input, textarea, button, .rc-virtual-list, .ant-form-item-extra"
        }
      >
        <div ref={draggableRef}>{modal}</div>
      </Draggable>
    ),
  };

  // modal is destroyOnClose to clear state when cancelling and opening another type
  return (
    <Modal {...modalProps}>
      <CreateClaimLineAuditFindingForm {...createAuditFindingFormProps} />
    </Modal>
  );
};

export { CreateAuditFindingModal };
