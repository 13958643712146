/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-argument */

import { useContext, useState } from "react";
import { Button, Form, Input, message, Modal } from "antd";
import { useLocation, useParams } from "react-router-dom";
import { gql, useMutation } from "urql";

import { queueFragment } from "../../graphql/fragments";
import { UserContext } from "../context/user";
import { ClaimListTable } from "../queues/claimListTable";
import { BatchClaimSearchFilter } from "./batchClaimSearchFilter";

const createQueueMutation = gql`
  mutation createQueue($queue: QueueInput!) {
    createQueue(input: { queue: $queue }) {
      queue {
        ...queueFragment
      }
    }
  }
  ${queueFragment}
`;

const SearchResults = () => {
  const params = useParams();

  const batchClaimSearchFilter = new BatchClaimSearchFilter(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    JSON.parse(params.searchFilter),
  );
  const { id: userId, permissions } = useContext(UserContext);

  const [, createQueue] = useMutation(createQueueMutation);
  const [creatingQueue, setCreatingQueue] = useState(false);
  const [createQueueForm] = Form.useForm();
  const [confirmLoading, setConfirmLoading] = useState(false);

  // The claimListTable component is used for 3 different purposes
  // To show dynamic user queues, canned client/provider queries and
  // Search results, this function decides what to name the table based
  // on the url that led to the page
  const tableName = () => {
    if (useLocation().pathname.includes("claims")) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      return useParams().tableName;
    } else if (useLocation().pathname.includes("queue")) {
      return "Queue";
    } else {
      return "Search Results";
    }
  };
  const buttons = permissions.queues.canCreateOrEditQueue
    ? [<Button onClick={() => setCreatingQueue(true)}>Create Queue</Button>]
    : [];

  return (
    <div>
      <ClaimListTable
        batchClaimFilter={batchClaimSearchFilter.gqlFilter}
        tableName={tableName()}
        buttons={buttons}
      />
      <Modal
        onOk={async () => {
          const createQueueInput = {
            name: createQueueForm.getFieldsValue(["name"]).name,
            description: createQueueForm.getFieldsValue(["description"])
              .description,
            filter: { batchClaimFilter: batchClaimSearchFilter.gqlFilter },
            indexedFilterTags: JSON.parse(batchClaimSearchFilter.searchUrl),
            authorId: userId,
          };

          if (createQueueInput.name === undefined)
            void message.error("Please input the name of Queue being created");
          else {
            console.log("Creating Queue:", createQueueInput);
            setConfirmLoading(true);
            return await createQueue({
              queue: createQueueInput,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
            }).then((data, error) => {
              console.log(data, error);
              setConfirmLoading(false);
              setCreatingQueue(false);
            });
          }
        }}
        okText={"Create"}
        confirmLoading={confirmLoading}
        onCancel={() => setCreatingQueue(false)}
        open={creatingQueue}
      >
        <div css={{ marginTop: "10%" }}>
          <Form form={createQueueForm}>
            <Form.Item
              name="name"
              label="Queue Title"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <Input placeholder={"My Queue..."} />
            </Form.Item>
            <Form.Item name="description" label="Queue Description">
              <Input placeholder={"My Queue Description..."} />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};

export { SearchResults };
