/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button, List, Modal } from "antd";
import { gql, useQuery } from "urql";

import { batchClaimDocumentationFragment } from "../../../fragments";

const ViewDocumententationModal = ({
  batchClaimId,
  viewDocumentNamesModalVisible,
  setViewDocumentNamesModalVisible,
}: any) => {
  const [{ fetching, data }] = useQuery({
    query: gql`
      query GetUploadedBatchClaimFilenames($batchClaimId: UUID!) {
        batchClaim(id: $batchClaimId) {
          ...batchClaimDocumentationFragment
        }
      }
      ${batchClaimDocumentationFragment}
    `,
    variables: { batchClaimId },
    pause: batchClaimId === null,
  });

  return (
    <>
      <Modal
        title="Uploaded Claim Documents"
        open={viewDocumentNamesModalVisible}
        onCancel={() => setViewDocumentNamesModalVisible(false)}
        footer={[
          <Button
            key="submit"
            type="primary"
            onClick={() => setViewDocumentNamesModalVisible(false)}
          >
            OK
          </Button>,
        ]}
      >
        <List
          size="large"
          loading={fetching && !data}
          dataSource={
            data
              ? data.batchClaim.auditDocumentation.map(
                  ({ fileName }: any) => fileName,
                )
              : []
          }
          renderItem={(item: any) => <List.Item>{item}</List.Item>}
        />
      </Modal>
    </>
  );
};

export { ViewDocumententationModal };
