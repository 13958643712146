import { useCallback } from "react";

import { useSplitView } from "@alaffia-technology-solutions/components";

import type { AskAutodorProps } from "./AskAutodor";
import { AskAutodor } from "./AskAutodor";

export const useAskAutodorSplit = () => {
  const { show, closeRight, toggleFullscreenRight, slotsState } =
    useSplitView();

  const open = useCallback(
    (props: Omit<AskAutodorProps, "onClose" | "onFullscreenToggle">) => {
      show({
        right: (
          <AskAutodor
            {...props}
            onClose={closeRight}
            onFullscreenToggle={toggleFullscreenRight}
          />
        ),
      });
    },
    [closeRight, show, toggleFullscreenRight],
  );

  return {
    open,
    close: closeRight,
    isOpen: slotsState.right.state === "open",
  };
};
