"use client";

import { useEffect, useState } from "react";
import type { FC } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

import { Button, Separator } from "@alaffia-technology-solutions/ui";

import { SearchResult } from "./search-result";

interface SearchResultsProps {
  query: string;
  results: {
    id: string;
    confidence: number;
    fileId: string;
    pageNumber: number;
    textMatched: string;
  }[];
  onResultPick?: (id?: string) => void;
}
export const SearchResults: FC<SearchResultsProps> = ({
  query,
  results,
  onResultPick,
}) => {
  const [index, setIndex] = useState<number | null>(null);

  const changeIndex = (newIndex: number) => {
    setIndex(newIndex);
    onResultPick?.(results?.[newIndex]?.id);
  };

  const decrementIndex = () => {
    if (!index) {
      changeIndex(results.length - 1);
    } else {
      changeIndex(index - 1);
    }
  };

  const incrementIndex = () => {
    if (index === null || index === results.length - 1) {
      changeIndex(0);
    } else {
      changeIndex(index + 1);
    }
  };

  useEffect(() => {
    setIndex(null);
  }, [query]);

  const wrapperClassName =
    "af-h-full af-w-full af-border-r af-border-border af-bg-gray-100 af-p-4";

  if (query.length === 0)
    return (
      <div className={`${wrapperClassName} af-py-2 af-text-xs`}>
        <p className="af-px-1 af-py-px">No Search Results</p>
      </div>
    );

  return (
    <div className={wrapperClassName}>
      <div className="af-flex af-items-center af-justify-between">
        <span className="af-py-2 af-text-xs af-text-gray-700">
          Search results for: &quot;{query}&quot;
        </span>
        <div className="af-flex">
          <Button size="icon" variant="ghost" onClick={incrementIndex}>
            <ChevronDown size={16} />
          </Button>
          <Button size="icon" variant="ghost" onClick={decrementIndex}>
            <ChevronUp size={16} />
          </Button>
        </div>
      </div>

      <Separator />

      <div className="af-h-full af-overflow-auto af-pb-3">
        {results.map((result, idx) => (
          <SearchResult
            key={result.id}
            {...result}
            query={query}
            active={idx === index}
            onClick={() => changeIndex(idx)}
          />
        ))}
      </div>
    </div>
  );
};
