import { Thumbnail } from "./thumbnail";
import type { GetPageThumbnail } from "./use-pdf-thumbnails";
import { usePDFThumbnails } from "./use-pdf-thumbnails";

interface PDFThumbnailsProps {
  currentPage: number;
  fileId: string;
  getPageThumbnail: GetPageThumbnail;
  jumpToPage: (pageIndex: number) => void;
  pagesCount: number;
}

export const PDFThumbnails = ({
  currentPage,
  fileId,
  getPageThumbnail,
  jumpToPage,
  pagesCount,
}: PDFThumbnailsProps) => {
  const { isThumbnailHighlighted, ref, rowVirtualizer, useThumbnailImage } =
    usePDFThumbnails({
      currentPage,
      fileId,
      getPageThumbnail,
      pagesCount,
    });

  return (
    <div
      ref={ref}
      className="af-h-full af-w-full af-overflow-y-auto af-overflow-x-hidden"
    >
      <div
        className="af-relative af-w-full"
        style={{
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        {rowVirtualizer.getVirtualItems().map((virtualItem) => (
          <div
            className="af-absolute af-left-1/2 af-top-0 af-mx-auto"
            key={virtualItem.key}
            style={{
              height: `${virtualItem.size}px`,
              transform: `translate(-50%, ${virtualItem.start}px)`,
            }}
          >
            <Thumbnail
              isHighlighted={isThumbnailHighlighted(virtualItem.index)}
              onClick={() => jumpToPage(virtualItem.index)}
              pageNumber={virtualItem.index + 1}
              useThumbnailImage={useThumbnailImage}
              virtualItemIndex={virtualItem.index}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
