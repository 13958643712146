import type { UpdatesConfig } from "@urql/exchange-graphcache";
import type { CombinedError } from "urql";

import type { AuthDataStrategy } from "../auth/types";
import type {
  CreateUrqlClientArgs,
  UrqlClient,
} from "../remote/createUrqlClient";
import { createUrqlClient } from "../remote/createUrqlClient";
import { graphCache, mergeUpdateCacheOptions } from "../remote/graphCache";
import type { IAuthHandler } from "./authHandler";
import { AuthHandler } from "./authHandler";
import { ClientError } from "./error";
import type { LoggerLevels, LoggerType } from "./log";
import { getLogger } from "./log";
import { ApiModule } from "./modules/apiModule";
import { AuditorUsersModule } from "./modules/auditorUsersModule";
import { AuthModule } from "./modules/authModule";
import {
  CasesModule,
  CasesModuleWithPromptResponseRatings,
} from "./modules/casesModule";
import { FilesModule } from "./modules/filesModule";
import type {
  AlaffiaClientModule,
  AlaffiaClientModuleArgs,
} from "./modules/modules";
import { QueuesModule } from "./modules/queuesModule";
import { UsersModule } from "./modules/usersModule";

export type ClientReturnType<T> = Promise<{
  data: T | null;
  error: CombinedError | null;
}>;

export type AlaffiaClientExtraModules = Record<string, AlaffiaClientModule>;

/**
 * Constructor args for Alaffia Client
 */
export interface AlaffiaClientArgs<
  TExtraModules extends AlaffiaClientExtraModules = Record<string, never>,
  TFeatures extends AlaffiaClientFeatures = AlaffiaClientFeatures,
> {
  apiUrl: string;
  graphqlOptions?: Omit<CreateUrqlClientArgs, "apiUrl" | "auth">;
  logLevel?: LoggerLevels;
  authData?: AuthDataStrategy;
  extraModules?: {
    [key in keyof TExtraModules]: new (
      args: AlaffiaClientModuleArgs,
    ) => TExtraModules[key];
  };
  features?: TFeatures;
}

export interface AlaffiaClientFeatures {
  promptResponseRatings: boolean;
}

const defaultsCtorArgs = {
  logLevel: "WARN",
} satisfies Partial<AlaffiaClientArgs>;

/**
 * The Alaffia client sdk for remote (http) access
 */
export class AlaffiaClient<
  TExtraModules extends AlaffiaClientExtraModules = Record<string, never>,
  TFeatures extends AlaffiaClientFeatures = AlaffiaClientFeatures,
> {
  private apiUrl: string;
  private gqlClient: UrqlClient;
  private logger: LoggerType;
  private authData?: AuthDataStrategy;
  private authHandler: IAuthHandler;

  public readonly auth: AuthModule;
  public readonly files: FilesModule;
  public readonly cases: TFeatures["promptResponseRatings"] extends true
    ? CasesModuleWithPromptResponseRatings
    : CasesModule;
  public readonly queues: QueuesModule;
  public readonly api: ApiModule;
  public readonly extraModules: TExtraModules;
  public readonly auditorUsers: AuditorUsersModule;
  public readonly users: UsersModule;
  public readonly features: AlaffiaClientFeatures;

  constructor(args: AlaffiaClientArgs<TExtraModules, TFeatures>) {
    const processedArgs = this.processCtorArgs(args);
    this.apiUrl = processedArgs.apiUrl;
    this.authData = processedArgs.authData;
    this.logger = getLogger("AlaffiaClient", processedArgs.logLevel);
    this.authHandler = new AuthHandler(this.apiUrl, this.authData);
    this.features = args.features ?? { promptResponseRatings: false };

    const ResolvedCasesModule = this.features.promptResponseRatings
      ? CasesModuleWithPromptResponseRatings
      : CasesModule;

    const defaultGqlCacheOptions = this.createGqlCacheOptions([
      AuthModule,
      FilesModule,
      ResolvedCasesModule,
      QueuesModule,
      ApiModule,
    ]);

    this.gqlClient = createUrqlClient({
      cacheExchangeOptions: defaultGqlCacheOptions,
      ...processedArgs.graphqlOptions,
      apiUrl: this.apiUrl + "/graphql",
      auth: this.authHandler,
    });

    const moduleArgs = {
      apiUrl: this.apiUrl,
      gqlClient: this.gqlClient,
      logger: this.logger,
    };

    this.auth = new AuthModule({
      ...moduleArgs,
      authHandler: this.authHandler,
    });
    this.files = new FilesModule(moduleArgs);
    this.cases = new ResolvedCasesModule(
      moduleArgs,
    ) as TFeatures["promptResponseRatings"] extends true
      ? CasesModuleWithPromptResponseRatings
      : CasesModule;
    this.queues = new QueuesModule(moduleArgs);
    this.api = new ApiModule({
      ...moduleArgs,
      authHandler: this.authHandler,
    });
    this.auditorUsers = new AuditorUsersModule(moduleArgs);
    this.users = new UsersModule(moduleArgs);

    const extraModules = {} as TExtraModules;
    for (const key in processedArgs.extraModules) {
      const module = processedArgs.extraModules[key];
      extraModules[key] = new module(moduleArgs);
    }
    this.extraModules = extraModules;
  }

  // Example of an internal method to process constructor arguments
  private processCtorArgs(
    args: AlaffiaClientArgs<TExtraModules, TFeatures>,
  ): AlaffiaClientArgs<TExtraModules, TFeatures> {
    const argsWithDefaults = {
      ...defaultsCtorArgs,
      ...args,
    };
    if (!argsWithDefaults.apiUrl) {
      throw new Error("API Url is required to create Alaffia client");
    }
    if (argsWithDefaults.apiUrl.endsWith("/")) {
      argsWithDefaults.apiUrl = argsWithDefaults.apiUrl.slice(0, -1);
    }
    return argsWithDefaults;
  }

  private clientError = (contextMessage: string, error: any) => {
    const originalMessage = error?.message ?? error.toString(); // todo include cause?
    const msg = `${contextMessage}: ${originalMessage}`;
    this.logger.error(msg, { error });
    return new ClientError(msg, { error });
  };

  private createGqlCacheOptions = (
    modules: { cacheOptions?: UpdatesConfig }[],
  ) => {
    const updates = modules.reduce<UpdatesConfig>(
      (acc, module) =>
        module.cacheOptions
          ? mergeUpdateCacheOptions(acc, module.cacheOptions)
          : acc,
      {},
    );

    return {
      ...graphCache,
      updates,
    };
  };

  getClientError = () => {
    return this.clientError;
  };
}
