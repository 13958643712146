/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext } from "react";
import { ClockCircleOutlined, UserOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import accounting from "accounting-js";
import { Empty, Table, Tag, Tooltip } from "antd";
import moment from "moment-timezone";
import { useRouteMatch } from "react-router-dom";
import { gql } from "urql";

import { auditFindingFragment } from "../../../../fragments";
import { withFragment } from "../../../../util/urelay";
import { UserContext } from "../../../context/user";
import { AuditFindingComment } from "../auditFinding/auditFindingComment";

const findingsTableQuery = gql`
  query FindingsTable($batchClaimId: UUID!, $afclFilter: AuditFindingFilter!) {
    batchClaim(id: $batchClaimId) {
      id
      auditFindings(filter: $afclFilter) {
        totalCount
        nodes {
          ...auditFindingFragment
        }
      }
      itemizedBill {
        id
        csv {
          id
          s3Key
          header {
            values
          }
          rows
        }
      }
    }
  }

  ${auditFindingFragment}
`;

const PureFindingsTable = (props: any) => {
  const { query } = props;

  const [{ fetching, data }] = query;

  const {
    userType,
    workstation: {
      tabs: {
        Findings: { columns: userColumns },
      },
    },
  } = useContext(UserContext);

  const viewingReport =
    useRouteMatch("/reports/:auditFindingReportId/workspace") !== null;
  const showFindings = userType !== "PROVIDER" || viewingReport;

  const auditFindings = !data ? [] : data.batchClaim.auditFindings.nodes;

  const dataAccess = (auditFinding: any, dataIndex: any) =>
    Array.isArray(dataAccess)
      ? dataIndex.reduce((value: any, index: any) => value[index], auditFinding)
      : auditFinding[dataIndex];

  const addFiltering = (column: any) => ({
    filters: [
      ...auditFindings.reduce(
        (values: any, auditFinding: any) =>
          values.add(dataAccess(auditFinding, column.dataIndex)),
        new Set(),
      ),
    ]
      .sort()
      .map((value) => ({ text: value || "<EMPTY>", value })),
    onFilter: (value: any, auditFinding: any) =>
      auditFinding &&
      (value
        ? dataAccess(auditFinding, column.dataIndex).includes(value)
        : dataAccess(auditFinding, column.dataIndex) == value),
    ...column,
  });

  const columns = [
    {
      title: "Source",
      key: "source",
      dataIndex: "auditFindingSeedType",
      align: "center",
      render: (auditFindingSeedType: any) => (
        <Tag color="lightgray" css={css({ minWidth: "3em" })}>
          {auditFindingSeedType.includes("UB") ? "UB" : "IB"}
        </Tag>
      ),
    },
    {
      title: "Denial Code",
      key: "denialCode",
      dataIndex: ["auditFindingRuleTypeByAuditFindingRuleType", "displayName"],
      render: (
        auditFindingRuleTypeName: any,
        { improperPaymentReason, auditorComment, auditFindingSeedType }: any,
      ) => {
        const rationale = improperPaymentReason || auditorComment;
        return (
          <Tooltip title={rationale} placement="top">
            <Tag
              color={
                auditFindingSeedType === "UB_CLAIM_LINE"
                  ? "geekblue"
                  : "magenta"
              }
            >
              {auditFindingRuleTypeName}
            </Tag>
          </Tooltip>
        );
      },
    },
    {
      title: "Qty",
      key: "quantity",
      dataIndex: "improperPaymentUnitsCharged",
      align: "center",
      sorter: (a: any, b: any) =>
        a.improperPaymentUnitsCharged - b.improperPaymentUnitsCharged,
    },
    {
      title: "Amount",
      key: "amount",
      dataIndex: "improperPaymentCost",
      render: (improperPaymentCost: any) =>
        accounting.formatMoney(improperPaymentCost),
      sorter: (a: any, b: any) => a.improperPaymentCost - b.improperPaymentCost,
    },
    {
      title: "Status",
      key: "status",
      render: ({ reviewedAuditFinding, reportedFindings, superseded }: any) => {
        return (
          <>
            {reviewedAuditFinding ? (
              reviewedAuditFinding.accepted ? (
                superseded ? (
                  <Tag color="lightgray">Superseded</Tag>
                ) : (
                  <Tag color="green">Accepted</Tag>
                )
              ) : (
                <Tag color="lightgray">Declined</Tag>
              )
            ) : (
              <Tag color="red">Needs Review</Tag>
            )}
            {reportedFindings.totalCount > 0 && (
              <Tag color="warning">Reported</Tag>
            )}
          </>
        );
      },
      filters: [
        "Accepted",
        "Superseded",
        "Declined",
        "Needs Review",
        "Reported",
      ].map((value) => ({ text: value, value })),
      onFilter: (
        value: string,
        { reviewedAuditFinding, reportedFindings, superseded }: any,
      ) =>
        Object.entries({
          Accepted: reviewedAuditFinding?.accepted && !superseded,
          Superseded: superseded,
          Declined: reviewedAuditFinding && !reviewedAuditFinding.accepted,
          "Needs Review": !reviewedAuditFinding,
          Reported: reportedFindings.totalCount > 0,
        }).filter(([key, condition]) => value === key && condition === true)
          .length > 0,
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      title: <UserOutlined test-id="author-icon" size="small" />,
      key: "author",
      dataIndex: ["author"],
      render: ({ firstName, lastName }: any) => (
        <div>{`${firstName} ${lastName}`}</div>
      ),
      filters: [
        ...auditFindings.reduce(
          (values: any, { author: { firstName, lastName } }: any) =>
            values.add(`${firstName} ${lastName}`),
          new Set(),
        ),
      ]
        .sort()
        .map((value) => ({ text: value, value })),
      onFilter: (value: any, { author: { firstName, lastName } }: any) =>
        value
          ? `${firstName} ${lastName}`.includes(value)
          : `${firstName} ${lastName}` == value,
    },
    {
      title: <ClockCircleOutlined test-id="created-at-icon" />,
      key: "createdAt",
      dataIndex: "createdAt",
      render: (createdAt: moment.MomentInput) =>
        moment(createdAt).format("MM-DD-YY HH:mm:ss"),
      sorter: (
        a: { createdAt: moment.MomentInput },
        b: { createdAt: moment.MomentInput },
      ) => moment(a.createdAt).diff(moment(b.createdAt)),
    },
  ].map(addFiltering);

  return !fetching && auditFindings.length === 0 ? (
    <Empty />
  ) : (
    <Table
      id="findings_table"
      loading={fetching}
      bordered
      size="small"
      sticky={true}
      columns={columns.filter(
        (c) => userColumns.includes(c.key) || userColumns.includes("*"),
      )}
      dataSource={auditFindings}
      rowKey={(auditFinding) => auditFinding.id}
      pagination={false}
      scroll={{
        y: "calc(100vh - 55px - 160px - 32px - 35px)",
      }}
      expandable={{
        columnWidth: 36,
        expandedRowRender: (auditFinding) => (
          <AuditFindingComment auditFinding={auditFinding} />
        ),
        rowExpandable: () => showFindings,
      }}
    />
  );
};

const FindingsTable = withFragment({
  displayName: "FindingsTable",
  queryBuilder: () => findingsTableQuery,
  Component: PureFindingsTable,
});

export { FindingsTable, findingsTableQuery };
