/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { gql, useQuery } from "urql";

const afclVersionFilter = (autodorVersionUserId: any) => ({
  or: [
    { authorId: { equalTo: autodorVersionUserId } },
    { author: { userType: { notEqualTo: "SYS" } } },
  ],
});

const AutodorVersionContext = createContext<any>(null);

const AutodorVersion = ({ children, loggedIn }: any) => {
  const [{ data }] = useQuery({
    query: gql`
      query latestAutodorVersionUserId {
        latestAutodorVersionUserId
      }
    `,
    pause: !loggedIn,
  });

  return !loggedIn ? (
    // do nothing if not logged in
    <>{children}</>
  ) : !data ? (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      style={{ width: "100vw", height: "100vh" }}
      delay={1000}
    />
  ) : (
    <AutodorVersionContext.Provider
      value={{
        afclFilter: afclVersionFilter(data.latestAutodorVersionUserId),
        latestAutodorVersionUserId: data.latestAutodorVersionUserId,
      }}
    >
      {children}
    </AutodorVersionContext.Provider>
  );
};

export { AutodorVersion, AutodorVersionContext };
