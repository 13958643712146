import type { Ref } from "react";
import { forwardRef, Fragment } from "react";

import type {
  PromptResponse,
  PromptResponsePage,
  Thread,
} from "@alaffia-technology-solutions/schema";
import { Button, Skeleton } from "@alaffia-technology-solutions/ui";

import { useChatbox } from "./Chatbox";
import type { PromptResponseRatingType } from "./Chatbox/types";
import { Messages } from "./Messages";
import type { OnRate } from "./Messages/AnswerItem";

interface MessageListProps {
  threads: Thread[];
  onDownRate?: OnRate;
  onFilePreview: MessageListQuestionItemProps["onFilePreview"];
  onUpRate?: OnRate;
  isLoading?: boolean;
  bottomRef?: Ref<HTMLDivElement>;
}

export const MessageList = forwardRef<HTMLDivElement, MessageListProps>(
  (
    { threads, onDownRate, onFilePreview, onUpRate, isLoading, bottomRef },
    ref,
  ) => {
    return (
      <Messages ref={ref}>
        <div className="af-flex af-flex-col-reverse af-overflow-y-auto af-pb-6">
          <div ref={bottomRef} />
          <Messages.List>
            {isLoading ? (
              <MessageListSkeleton />
            ) : (
              threads.map((thread) => (
                <Fragment key={thread.id}>
                  {thread.messages.map((message) => (
                    <MessageListQuestionItem
                      key={message.id}
                      message={message}
                      onDownRate={onDownRate}
                      onFilePreview={onFilePreview}
                      onUpRate={onUpRate}
                    />
                  ))}
                </Fragment>
              ))
            )}
          </Messages.List>
        </div>
      </Messages>
    );
  },
);
MessageList.displayName = "MessageList";

export function MessageListSkeleton() {
  return (
    <>
      <MessageListSkeletonItem />
      <MessageListSkeletonItem />
      <MessageListSkeletonItem />
    </>
  );
}

export function MessageListSkeletonItem() {
  return (
    <li className="af-gap-w af-flex af-flex-col af-gap-2">
      <Skeleton className="af-h-4 af-w-40 af-max-w-full" />
      <Skeleton className="af-h-5 af-w-full" />
      <Skeleton className="af-h-40 af-w-full" />
    </li>
  );
}

interface MessageListQuestionItemProps {
  message: Thread["messages"][number];
  onDownRate?: (
    currentUserRating: PromptResponseRatingType | null,
    messageId: string,
  ) => void;
  onFilePreview: (
    promptResponse: PromptResponse | null,
    file: {
      id: PromptResponsePage["file"]["id"];
      name: PromptResponsePage["file"]["fileName"];
      page: PromptResponsePage["pageNumber"];
    },
  ) => void;
  onUpRate?: (
    currentUserRating: PromptResponseRatingType | null,
    messageId: string,
  ) => void;
}

export function MessageListQuestionItem({
  message,
  onDownRate,
  onFilePreview,
  onUpRate,
}: MessageListQuestionItemProps) {
  const { setEditorInput, editorRef } = useChatbox();

  const hasErrorFeedback =
    message.feedback?.find((feedback) => feedback.level === "ERROR") !==
    undefined;

  return (
    <Messages.QuestionItem message={message}>
      {message.feedback?.map((feedback, feedbackIndex) => (
        <Messages.FeedbackItem
          key={`${message.id}-${feedbackIndex}`}
          feedback={feedback}
        >
          {feedback.level === "ERROR" && (
            <Button
              size="sm"
              variant="neutralOutline"
              onClick={() => {
                setEditorInput(message.promptInputState);
                editorRef.current?.focus();
              }}
            >
              Retry
            </Button>
          )}
        </Messages.FeedbackItem>
      ))}
      {(message.response ?? !hasErrorFeedback) && (
        <Messages.AnswerItem
          message={message.response ?? null}
          onDownRate={onDownRate}
          onFilePreview={({ id, name, page }) =>
            onFilePreview(message.response ?? null, {
              id,
              name,
              page,
            })
          }
          onUpRate={onUpRate}
        />
      )}
    </Messages.QuestionItem>
  );
}
