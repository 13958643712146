const MS_IN_SECOND = 1000;

/**
 * Function that extracts epoch from provided signed URL in form of the milliseconds.
 *
 * @param signedUrl - URL that you want to extract epoch from. The `signedUrl` should contain `X-Amz-Expires` or `Expires` parameter to get a reasonable output.
 *
 * @returns Epoch time from provided `singedUrl` parameter.
 *
 * @example
 * ```
 * const signedUrl = "http://localhost:4000/uploads/test.pdf?Expires=1721819828&Signature=12345";
 *
 * const epochMillisecondsFromSignedUrl = extractEpochMillisecondsFromSignedUrl(signedUrl);
 *
 * console.log(epochMillisecondsFromSignedUrl); // 1721819828000
 * ```
 */
export function extractEpochMillisecondsFromSignedUrl(
  signedUrl: string,
): number {
  let url;
  try {
    url = new URL(signedUrl);
  } catch (err) {
    return 0;
  }

  const expiresSearchParam =
    url.searchParams.get("X-Amz-Expires") ?? url.searchParams.get("Expires");

  const expiresInSeconds = Number(expiresSearchParam);
  if (Number.isNaN(expiresInSeconds) || expiresInSeconds < 0) {
    return 0;
  }

  return expiresInSeconds * MS_IN_SECOND;
}
