/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserOutlined } from "@ant-design/icons";
import { Descriptions } from "antd";

import { assignment, dueDate } from "../../claimState/stateTypes";
import { usePaymentRate } from "../util/usePaymentRate";

const BatchClaimMetaData = ({
  permissions,
  batchClaim,
  batchClaim: { provider },
}: any) => {
  const paymentRateUtility = usePaymentRate(batchClaim);
  return (
    <div style={{ marginBottom: "10px" }}>
      {provider && (
        <>
          <Descriptions>
            <Descriptions.Item
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              id={"batch-claim-overview-provider-item"}
              key={"batch-claim-overview-provider-item"}
            >
              {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment  */}
              {/* @ts-expect-error */}
              <UserOutlined size="small" style={{ marginRight: 10 }} />
              {`${provider.name} (${provider.id})`}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions>
            <Descriptions.Item
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              id={"batch-claim-overview-npi-item"}
              key={"batch-claim-overview-npi-item"}
              label="NPI"
            >
              {provider.npi}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      {paymentRateUtility.showStrategy && (
        <>
          <Descriptions>
            <Descriptions.Item
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              id={"batch-claim-overview-py-rate-strat-item"}
              key={"batch-claim-overview-py-rate-strat-item"}
            >
              {`Rate Method: ${
                paymentRateUtility.strategyLabel
              } (claim level is ${paymentRateUtility.getClaimRatePercent(2)}%)`}
            </Descriptions.Item>
          </Descriptions>
        </>
      )}
      {(permissions.claimStatesToShow.assignment.has("USER_ASSIGNED") ||
        permissions.claimStatesToShow.assignment.has("USER_UNASSIGNED")) && (
        <Descriptions>
          <Descriptions.Item
            label="Assignees"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-expect-error
            id={"batch-claim-overview-assignment-item"}
            key={"batch-claim-overview-assignment-item"}
          >
            {assignment.render(batchClaim)}
          </Descriptions.Item>
        </Descriptions>
      )}
      {(permissions.claimStatesToShow.dueDate.has("DUE_DATE_SET") ||
        permissions.claimStatesToShow.dueDate.has("NO_DUE_DATE")) && (
        <Descriptions>
          <Descriptions.Item
            label="Due Date"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-expect-error
            id={"batch-claim-overview-due-date-item"}
            key={"batch-claim-overview-due-date-item"}
          >
            {dueDate.render(batchClaim, { isCompact: false })}
          </Descriptions.Item>
        </Descriptions>
      )}
      {/* 
      
          READ ME:

          These stats below seem redundant
          
          We already show all these stats in the table with all these details. 
          Adding them here really just increases clutter and more development work/issues
          
          I originally added these stats way back before we had fully fleshed out the claim list table and 
          I was just retrofitting the old <AuditInfo> component into this. At this point <AuditInfo> doesn't event exist

          I think the whole point of this drawer is the timeline history and that is really the primary focus.
          some of these additional stats are superflous

          therefore for the time being I have commented this code out.
      */}

      {/* 
      {permissions.findingsActions.includes('view') && (
        <Space align="center">
          <div>
            <Row>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
                title={
                  <span
                    id={`audit-info-amount-charged-dollar-value`}
                    key={`audit-info-amount-charged-dollar-value`}
                    style={{ marginRight: 3, fontSize: 12 }}
                  >
                    Billed
                  </span>
                }
                prefix="$"
                value={amountCharged}
                precision={2}
                valueStyle={{ fontSize: 14 }}
              />
            </Row>
            <Row>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
                title={
                  <span
                    id={`audit-info-amount-reimbursed-dollar-value`}
                    key={`audit-info-amount-reimbursed-dollar-value`}
                    style={{ marginRight: 3, fontSize: 12 }}
                  >
                    Allowed
                  </span>
                }
                prefix="$"
                value={amountReimbursed}
                precision={2}
                valueStyle={{ fontSize: 14 }}
              />
            </Row>
          </div>

          <div style={{ marginLeft: '40px' }}>
            <Row>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
                title={
                  <span
                    id={`audit-info-potential-adj-allowed-amount-dollar-value`}
                    key={`audit-info-potential-adj-allowed-amount-dollar-value`}
                    style={{ marginRight: 3, fontSize: 12 }}
                  >
                    Pot Adj Allowed Amount
                  </span>
                }
                prefix="$"
                value={potentialAdjustedAllowedAmount}
                precision={2}
                valueStyle={{ fontSize: 14 }}
              />
            </Row>
            <Row>
              <Statistic
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                }}
                title={
                  <span
                    id={`audit-info-potential-adj-allowed-amount-dollar-value`}
                    key={`audit-info-potential-adj-allowed-amount-dollar-value`}
                    style={{ marginRight: 3, fontSize: 12 }}
                  >
                    Det Adj Allowed Amount
                  </span>
                }
                prefix="$"
                value={determinedAdjustedAllowedAmount}
                precision={2}
                valueStyle={{ fontSize: 14 }}
              />
            </Row>
          </div>
        </Space>
      )} */}
    </div>
  );
};

export { BatchClaimMetaData };
