import tailwindScrollbar from "tailwind-scrollbar";
import type { Config } from "tailwindcss";

import baseConfig from "@alaffia-technology-solutions/tailwind-config/base";

import { antdTokens } from "./tailwind-antd-tokens";

export default {
  presets: [baseConfig],
  content: [
    "../../packages/ui/**/*.{ts,tsx}",
    "../../packages/auth/**/*.{ts,tsx}",
    "../../packages/components/**/*.{ts,tsx}",
    "../../packages/pdf-viewer/**/*.{ts,tsx}",
    "./src/**/*.{html,js,ts,jsx,tsx}",
  ],
  theme: {
    extend: {
      colors: {
        ...antdTokens.colors,
      },
      antd: {
        dark: {
          ...antdTokens.tokens.dark.colors,
          ...antdTokens.tokens.dark.tokens,
        },
        light: {
          ...antdTokens.tokens.light.colors,
          ...antdTokens.tokens.light.tokens,
        },
      },
      gridTemplateColumns: {
        // Simple 16 column grid
        24: "repeat(24, minmax(0, 1fr))",
      },
      gridColumnStart: {
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
      },
      gridColumnEnd: {
        13: "13",
        14: "14",
        15: "15",
        16: "16",
        17: "17",
        18: "18",
        19: "19",
        20: "20",
        21: "21",
        22: "22",
        23: "23",
        24: "24",
      },
    },
  },
  plugins: [tailwindScrollbar({ nocompatible: true })],
} satisfies Config;
