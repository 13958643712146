import { Separator, Skeleton } from "@alaffia-technology-solutions/ui";

function FileSkeleton() {
  return (
    <li className="af-flex af-flex-col af-gap-2 af-border-b af-border-border af-px-2 af-py-5">
      <Skeleton className="af-h-4 af-w-1/2" />
      <div className="af-flex af-h-3 af-items-center af-gap-2">
        <Skeleton className="af-h-4 af-w-6 af-border-r af-border-border af-pr-2" />
        <Separator orientation="vertical" />
        <Skeleton className="af-h-4 af-w-4/6" />
      </div>
    </li>
  );
}

export function FilesListSkeleton() {
  return (
    <ul className="af-list-none af-p-0">
      <FileSkeleton />
      <FileSkeleton />
      <FileSkeleton />
    </ul>
  );
}
