import * as React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import type { ElementNode } from "lexical";
import { $createTextNode, $isTextNode, TextNode } from "lexical";

import {
  $createKeywordListNode,
  $createKeywordNode,
  $isKeywordListNode,
  $isKeywordNode,
  KeywordListNode,
  KeywordNode,
} from "./KeywordNode";

const KEYWORD_LIST_START = '"';
const KEYWORD_LIST_END = '"';
const KEYWORD_LIST_SEPARATOR = ",";

interface KeywordTextMatch {
  startIndex: number;
  endIndex: number;
  offsets: KeywordMatchOffset[];
}
interface KeywordMatchOffset {
  keyword: string;
  startIndex: number;
  endIndex: number;
}

function getKeywordsTextMatch(text: string): KeywordTextMatch | null {
  const keywordListStartIndex = text.indexOf(KEYWORD_LIST_START);
  if (keywordListStartIndex === -1) {
    return null;
  }
  const keywordListEndIndex = text.indexOf(
    KEYWORD_LIST_END,
    keywordListStartIndex + 1,
  );
  if (keywordListEndIndex === -1) {
    return null;
  }

  const keywordListText = text.substring(
    keywordListStartIndex,
    keywordListEndIndex + 1,
  );
  const keywordsText = keywordListText.slice(
    KEYWORD_LIST_START.length,
    -KEYWORD_LIST_END.length,
  );

  const keywords = [];
  const trimResult = trimStart(keywordsText);
  let currentKeywordsText = trimResult[0];
  const initiallyTrimmedChars = trimResult[1];
  currentKeywordsText = currentKeywordsText.trimEnd();
  let currentOffset = KEYWORD_LIST_START.length + initiallyTrimmedChars;

  let separatorIndex = currentKeywordsText.indexOf(KEYWORD_LIST_SEPARATOR);
  while (separatorIndex !== -1) {
    let keyword = currentKeywordsText.slice(0, separatorIndex);
    if (!keyword) {
      return null;
    }

    const [trimmedStartKeyword, trimmedStartChars] = trimStart(keyword);
    const [trimmedKeyword, trimmedEndChars] = trimEnd(trimmedStartKeyword);
    keyword = trimmedKeyword;
    currentOffset += trimmedStartChars;

    keywords.push({
      keyword,
      startIndex: currentOffset,
      endIndex: currentOffset + keyword.length,
    });
    currentOffset += keyword.length + trimmedEndChars + 1;
    currentKeywordsText = currentKeywordsText.slice(separatorIndex + 1);
    separatorIndex = currentKeywordsText.indexOf(KEYWORD_LIST_SEPARATOR);
  }

  let lastKeyword = currentKeywordsText;
  if (!lastKeyword) {
    return null;
  }

  const [trimmedStartKeyword, trimmedStartChars] = trimStart(lastKeyword);
  const [trimmedKeyword] = trimEnd(trimmedStartKeyword);
  lastKeyword = trimmedKeyword;
  currentOffset += trimmedStartChars;
  keywords.push({
    keyword: lastKeyword,
    startIndex: currentOffset,
    endIndex: currentOffset + lastKeyword.length,
  });

  return {
    startIndex: keywordListStartIndex,
    endIndex: keywordListEndIndex + 1,
    offsets: keywords,
  };
}

function trimStart(str: string): [string, number] {
  const trimmed = str.trimStart();
  return [trimmed, str.length - trimmed.length];
}

function trimEnd(str: string): [string, number] {
  const trimmed = str.trimEnd();
  return [trimmed, str.length - trimmed.length];
}

function $createKeywordNodesFromTextNode(
  textNode: TextNode,
  keywordMatch: KeywordTextMatch,
) {
  let keywordListTextNode;
  let remainingTextNode;
  if (keywordMatch.startIndex === 0) {
    [keywordListTextNode, remainingTextNode] = textNode.splitText(
      keywordMatch.endIndex,
    );
  } else {
    [, keywordListTextNode, remainingTextNode] = textNode.splitText(
      keywordMatch.startIndex,
      keywordMatch.endIndex,
    );
  }

  if (!keywordListTextNode) {
    throw new Error("Invalid keyword match indicies provided");
  }

  if (!remainingTextNode) {
    remainingTextNode = $createTextNode();
  }

  const offsets = keywordMatch.offsets;
  const splitOffsets = offsets.flatMap(
    ({ startIndex, endIndex }, tableIndex) => [
      {
        type: "text",
        startIndex: offsets[tableIndex - 1]?.endIndex ?? 0,
        endIndex: startIndex,
      },
      { type: "keyword", startIndex, endIndex },
    ],
  );

  const textContent = keywordListTextNode.getTextContent();
  splitOffsets.push({
    type: "text",
    startIndex: offsets[offsets.length - 1]?.endIndex ?? 0,
    endIndex: textContent.length,
  });

  const nodesToAppend = splitOffsets.map(({ startIndex, endIndex, type }) => {
    const text = textContent.slice(startIndex, endIndex);
    const node =
      type === "text" ? $createTextNode(text) : $createKeywordNode(text);
    return node;
  });

  const keywordListNode = $createKeywordListNode();

  keywordListNode.append(...nodesToAppend);
  keywordListTextNode.replace(keywordListNode);

  const parent = keywordListNode.getParentOrThrow<ElementNode>();
  parent.append(remainingTextNode);
  remainingTextNode.select();
}

function $appendTextNodeSibling(
  keywordListNode: KeywordListNode,
  lastTextNode: TextNode,
) {
  const parent = keywordListNode.getParentOrThrow<ElementNode>();
  const lastTextNodeTextContent = lastTextNode.getTextContent();
  const indexOfKeywordListEnd =
    lastTextNodeTextContent.indexOf(KEYWORD_LIST_END);
  if (indexOfKeywordListEnd === -1) {
    throw new Error("Invalid call to the $appendTextNodeSibling function");
  }

  const textContentInsideKeywordList = lastTextNodeTextContent.slice(
    0,
    indexOfKeywordListEnd + 1,
  );
  const textContentOutsideKeywordList = lastTextNodeTextContent.slice(
    indexOfKeywordListEnd + 1,
  );

  lastTextNode.setTextContent(textContentInsideKeywordList);

  const textNodeSibling = $createTextNode(textContentOutsideKeywordList);
  parent.append(textNodeSibling);
  textNodeSibling.select();
}

type SelectionPosition = "start" | "end";

function handleKeywordListRemoval(
  keywordListNode: KeywordListNode,
  selectionPosition: SelectionPosition = "end",
) {
  const textNode = $createTextNode(keywordListNode.getTextContent());
  keywordListNode.replace(textNode);
  switch (selectionPosition) {
    case "start":
      textNode.selectStart();
      break;
    case "end":
      textNode.selectEnd();
      break;
    default:
      selectionPosition satisfies never;
  }
}

function handleKeywordUpdate(textNode: TextNode) {
  const text = textNode.getTextContent();

  let startIndex = 0;
  let startChar = text[startIndex]!;
  while (
    startIndex < text.length &&
    [KEYWORD_LIST_START, KEYWORD_LIST_SEPARATOR, " "].includes(startChar)
  ) {
    startIndex++;
    startChar = text[startIndex]!;
  }

  let endIndex = text.length - 1;
  let endChar = text[endIndex]!;
  while (
    endIndex >= 0 &&
    [KEYWORD_LIST_END, KEYWORD_LIST_SEPARATOR, " "].includes(endChar)
  ) {
    endIndex--;
    endChar = text[endIndex]!;
  }

  if (startIndex > endIndex) {
    return;
  }

  const keywordText = text.slice(startIndex, endIndex + 1);

  if (!keywordText) {
    return;
  }

  const [_, keywordTextNode] = textNode.splitText(startIndex, endIndex + 1);

  if (!keywordTextNode) {
    throw new Error("Unexpected missing keyword text node");
  }

  const keywordNode = $createKeywordNode(keywordTextNode.getTextContent());
  keywordTextNode.replace(keywordNode);
}

function handleKeywordSplit(keywordNode: KeywordNode) {
  const text = keywordNode.getTextContent();
  const separatorIndex = text.indexOf(KEYWORD_LIST_SEPARATOR);
  if (separatorIndex === -1) {
    return;
  }

  let newKeywordTextNode;
  if (separatorIndex === 0) {
    [, newKeywordTextNode] = keywordNode.splitText(separatorIndex);
  } else {
    [newKeywordTextNode] = keywordNode.splitText(separatorIndex);
  }

  if (!newKeywordTextNode) {
    throw new Error("Unexpected missing keyword text node");
  }
  const newKeywordNode = $createKeywordNode(
    newKeywordTextNode.getTextContent(),
  );
  newKeywordTextNode.replace(newKeywordNode);
}

function handleKeywordsMerge(keywordListNode: KeywordListNode) {
  const children = keywordListNode.getChildren();

  for (const child of children) {
    const previousSibling = child.getPreviousSibling();
    if (
      previousSibling &&
      $isKeywordNode(child) &&
      $isKeywordNode(previousSibling)
    ) {
      child.mergeWithSibling(previousSibling);
    }
  }
}

export function KeywordsPlugin() {
  const [editor] = useLexicalComposerContext();

  React.useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(TextNode, (textNode) => {
        const text = textNode.getTextContent();
        const parent = textNode.getParentOrThrow<ElementNode>();

        if ($isKeywordListNode(parent)) {
          const nextSibling = textNode.getNextSibling();
          if (
            !nextSibling &&
            text.startsWith(KEYWORD_LIST_END) &&
            textNode.isSelected()
          ) {
            $appendTextNodeSibling(parent, textNode);
            return;
          }

          handleKeywordUpdate(textNode);
        } else {
          const match = getKeywordsTextMatch(text);
          if (match) {
            $createKeywordNodesFromTextNode(textNode, match);
          }
        }
      }),
      editor.registerNodeTransform(KeywordNode, (keywordNode) => {
        const textContent = keywordNode.getTextContent();
        if (textContent.includes(KEYWORD_LIST_SEPARATOR)) {
          handleKeywordSplit(keywordNode);
        }
      }),
      editor.registerNodeTransform(KeywordListNode, (keywordListNode) => {
        const firstChild = keywordListNode.getFirstChild();
        const isFirstChildKeywordListStartChar =
          $isTextNode(firstChild) &&
          firstChild.getTextContent().startsWith(KEYWORD_LIST_START);

        if (!firstChild || !isFirstChildKeywordListStartChar) {
          handleKeywordListRemoval(keywordListNode, "start");
          return;
        }

        const lastChild = keywordListNode.getLastChild();
        const isLastChildKeywordListEndChar =
          $isTextNode(lastChild) &&
          lastChild.getTextContent().endsWith(KEYWORD_LIST_END);

        if (!lastChild || !isLastChildKeywordListEndChar) {
          handleKeywordListRemoval(keywordListNode, "end");
          return;
        }

        const children = keywordListNode.getChildren();
        const hasKeywordNodes = children.some((node) => $isKeywordNode(node));
        if (!hasKeywordNodes) {
          handleKeywordListRemoval(keywordListNode, "end");
          return;
        }

        handleKeywordsMerge(keywordListNode);
      }),
    );
  }, [editor]);

  return null;
}
