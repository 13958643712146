/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import React, { useMemo } from "react";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/react";
import { Popover, Select, Table, Typography } from "antd";
import type { ColumnsType } from "antd/es/table";

import { formatBytesAsDisplayString } from "../../common/util/fn/bytes";
import type { ClaimIntakeUpload } from "./util";
import { toFirstLetterUpper } from "./util";

const { Text } = Typography;

const clsFontSm = css({ fontSize: ".85em" });
const clsIcon = css({ fontSize: "1.1em" });
// antd colors...
const clsDanger = css({ color: "#ff4d4f" });
const clsSuccess = css({ color: "#52c41a" });
const clsPrimary = css({ color: "#1890ff" });

const clsRedOutlineInputWrapper = css({
  "&  div.ant-select-selector:not(:hover):not(:focus-within)": {
    border: "solid #ff4d4f 1px !important",
  },
});

const UploadClaimsTable = ({
  uploadFiles,
  isShowingHistory,
  documentUploadKindOptions,
  setUploadKind,
  removeUpload,
}: {
  uploadFiles: ClaimIntakeUpload[];
  isShowingHistory?: boolean;
  documentUploadKindOptions: { label: string; value: string }[];
  setUploadKind: (upload: ClaimIntakeUpload, kind: string) => void;
  removeUpload?: (upload: ClaimIntakeUpload) => void;
}): JSX.Element => {
  const getKindLabelForOption = (documentUploadKindOption?: string) =>
    !documentUploadKindOption
      ? ""
      : documentUploadKindOptions.find(
          (ukOpt) => ukOpt.value === documentUploadKindOption,
        )?.label ?? documentUploadKindOption;

  const columns: ColumnsType<ClaimIntakeUpload> = useMemo(
    () => [
      {
        title: "File",
        dataIndex: ["uploadFile", "name"],
        width: "33%",
        ellipsis: true,
        render: (name, record) => (
          <Popover
            content={
              <>
                <div>
                  <Text type="secondary">File:</Text> {name}
                </div>
                <div>
                  <Text type="secondary">Claim Number:</Text>{" "}
                  {record.metadata.claimNumber}
                </div>
                <div>
                  <Text type="secondary">Insurer:</Text>{" "}
                  {record.metadata.insurerName}
                </div>
              </>
            }
            title="File Info"
          >
            <div>
              <Text>{name}</Text>
            </div>
            <div>
              <Text type="secondary" css={clsFontSm}>
                Claim: {record.metadata.claimNumber}
              </Text>
            </div>
            <div>
              <Text type="secondary" css={clsFontSm}>
                Insurer: {record.metadata.insurerName}
              </Text>
            </div>
          </Popover>
        ),
      },
      {
        title: "Size",
        width: "10%",
        dataIndex: ["uploadFile", "size"],
        responsive: ["xxl", "xl", "lg"],
        render: (size) => (
          <Popover content={size}>
            <Text css={clsFontSm}>{formatBytesAsDisplayString(size, 1)}</Text>
          </Popover>
        ),
      },
      {
        title: "Uploaded",
        dataIndex: ["metadata", "uploadedAt"],
        width: "12%",
        responsive: ["xxl", "xl", "lg"],
        render: (ua, record) => (
          <Popover
            content={record.metadata.uploadedAt?.toLocaleString() ?? undefined}
          >
            <Text css={clsFontSm}>
              {record.metadata.uploadedAt?.toLocaleTimeString()}
            </Text>
          </Popover>
        ),
      },
      {
        title: "Status",
        dataIndex: ["metadata", "status"],
        width: "16%",
        ellipsis: true,
        render: (status, record) => (
          <Popover
            title={toFirstLetterUpper(status)}
            content={
              <div>
                <div>
                  <Text>{record.metadata.statusMessage}</Text>
                </div>
                {record.metadata.status === "success" && (
                  <div>
                    <Text type="secondary" css={clsFontSm}>
                      Uploaded:{" "}
                      {record.metadata.uploadedAt?.toLocaleTimeString()}
                    </Text>
                  </div>
                )}
                <div>
                  <Text type="secondary" css={clsFontSm}>
                    Size: {record.uploadFile.size}
                  </Text>
                </div>
              </div>
            }
          >
            {status === "success" ? (
              <CheckCircleOutlined css={css([clsIcon, clsSuccess])} />
            ) : status === "ready" ? (
              <UploadOutlined css={css([clsIcon, { color: "gray" }])} />
            ) : status === "pending" ? (
              <SyncOutlined spin css={css([clsIcon, clsPrimary])} />
            ) : status === "error" ? (
              <ExclamationCircleOutlined css={css([clsIcon, clsDanger])} />
            ) : undefined}
            <Text
              type={
                status === "success"
                  ? "success"
                  : status === "error"
                  ? "danger"
                  : undefined
              }
            >
              {" " + toFirstLetterUpper(status)}
            </Text>
            {record.metadata.statusMessage && (
              <div>
                <Text ellipsis={true} type="secondary" css={clsFontSm}>
                  {record.metadata.statusMessage}
                </Text>
              </div>
            )}
          </Popover>
        ),
      },
      {
        title: "Kind",
        dataIndex: ["metadata", "documentUploadKind"],
        width: "25%",
        render: (documentUploadKind, upload) => (
          <>
            {isShowingHistory ?? upload.metadata.status === "success" ? (
              getKindLabelForOption(documentUploadKind)
            ) : (
              <div
                css={
                  !documentUploadKind ||
                  documentUploadKind.length < 1 ||
                  upload.metadata.duplicateUB ||
                  upload.metadata.duplicateNameAndKind
                    ? clsRedOutlineInputWrapper
                    : undefined
                }
              >
                <Select
                  data-id="document-type-select"
                  placeholder="Select document type"
                  size="small"
                  listItemHeight={22}
                  dropdownMatchSelectWidth={false}
                  options={documentUploadKindOptions}
                  virtual={false}
                  value={documentUploadKind}
                  onSelect={(newDocumentUploadKind) => {
                    // Set the documentUploadKind for this file in the list
                    setUploadKind(upload, newDocumentUploadKind);
                  }}
                />
              </div>
            )}
          </>
        ),
      },
      {
        ...(removeUpload && {
          title: "",
          width: "4%",
          dataIndex: ["uploadFile", "uid"],
          render: (uid, record) => {
            const disabled = record.metadata.status === "pending";
            return (
              <Popover content="Remove entry from active list">
                <DeleteOutlined
                  style={{
                    padding: 0,
                    margin: 0,
                    color: disabled ? "lightgrey" : undefined,
                  }}
                  onClick={() => !disabled && removeUpload(record)}
                />
              </Popover>
            );
          },
        }),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <div
      css={css({
        height: "100%",
        width: "100%",
        padding: 0,
        margin: 0,
        display: "flex",
        flexFlow: "column",
        // border: 'solid blue 1px',
      })}
    >
      <div
        css={css({
          // border: 'solid red 1px',
          flex: "1 1 auto",
          padding: 0,
          margin: 0,
          minWidth: "600px",
          "& .ant-table-thead > tr > th": {
            padding: "8px",
            fontSize: ".95em",
            backgroundColor: "unset",
          },
        })}
      >
        {isShowingHistory && (
          <Text type="secondary" css={clsFontSm}>
            ** History is only available for the current browser session,
            reloading the browser will clear history.
          </Text>
        )}
        <Table
          rowKey={(row) => row.uploadFile.uid}
          columns={columns}
          dataSource={uploadFiles}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default UploadClaimsTable;
