/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unnecessary-type-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Dispatch, Reducer } from "react";
import { useCallback, useEffect, useReducer } from "react";
import { isFunction } from "lodash";

import type { FieldPath, FieldPathValue } from "../typing/propPath";
import type { createReducer, GenericAction } from "./genericReducer";
import { Action, ActionFunction } from "./genericReducer";

export interface ClassWithReducer<StateType extends object> {
  getInitialState: () => StateType;
  setDispatcher: (dispatchers: ClassWithReducerDispatchers<StateType>) => void;
  reducer: ReturnType<typeof createReducer<StateType>>;
}

type SetStateFnValueType<
  StateType extends object,
  N extends FieldPath<StateType>,
> = FieldPathValue<StateType, N>;
type SetStateFnFunctionType<
  StateType extends object,
  N extends FieldPath<StateType>,
> = (slice: N, fullState: StateType) => FieldPathValue<StateType, N>;
export type SetStateFn<StateType extends object> = <
  N extends FieldPath<StateType>,
>(
  path: N,
  value:
    | SetStateFnValueType<StateType, N>
    | SetStateFnFunctionType<StateType, N>,
) => void;

export interface ClassWithReducerDispatchers<StateType extends object> {
  dispatch: Dispatch<GenericAction<StateType, any>>;
  setState: SetStateFn<StateType>;
  replaceState: (state: StateType) => void;
}

export const useClassWithReducer = <StateType extends object>(
  classInstance: ClassWithReducer<StateType>,
): StateType => {
  const [state, dispatch] = useReducer<
    Reducer<StateType, GenericAction<StateType, any>>,
    any
  >(
    classInstance.reducer,
    {} as StateType,
    () => (classInstance.getInitialState() as StateType) ?? ({} as StateType),
  );

  const setState = useCallback(
    function setState<N extends FieldPath<StateType>>(
      path: N,
      value:
        | FieldPathValue<StateType, N>
        | ((slice: N, fullState: StateType) => FieldPathValue<StateType, N>),
    ) {
      if (isFunction(value)) {
        dispatch(new ActionFunction<StateType, N>(path, value));
      } else {
        dispatch(new Action<StateType>(path, value));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classInstance, dispatch],
  );

  const replaceState = useCallback(
    function replaceState<StateType extends object>(state: StateType) {
      dispatch(
        new ActionFunction("", (prev) => {
          // this is probably unnecessary but getting typescript to accept
          // the new state T as StateType in genericReducer when returning it
          // as the result of immer .produce() proved tricky... rather than jump
          // out of immer and just return a plain object, the shortcut for now is
          // this bit of excess computation:
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          Object.keys(prev).forEach((k) => {
            delete prev[k];
          });
          Object.keys(state).forEach(
            (k) => (prev[k] = state[k as keyof StateType]),
          );
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [classInstance, dispatch],
  );

  useEffect(() => {
    classInstance.setDispatcher({
      dispatch,
      setState,
      replaceState,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classInstance, dispatch]);

  return state;
};
