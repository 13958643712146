"use client";

import type { StateCreator } from "zustand";

import type { PDFControllerSlice } from ".";
import { useSuspenseFoxitPDFViewCtrlLoader } from "../../loaders";
import type { PDFViewCtrl } from "../../loaders/foxit-pdf-view-ctrl";
import { useSuspenseWorker } from "../worker-provider";
import { DEFAULT_SCALE, MAX_SCALE, MIN_SCALE } from "./const";

const createPdfViewer = (
  pdfViewCtrl: typeof PDFViewCtrl,
  worker: Worker,
  libPath?: string,
) => {
  return new pdfViewCtrl.PDFViewer({
    defaultScale: DEFAULT_SCALE,
    minScale: MIN_SCALE,
    maxScale: MAX_SCALE,
    libPath,
    jr: {
      readyWorker: worker,
    } as unknown as ConstructorParameters<
      typeof PDFViewCtrl.PDFViewer
    >["0"]["jr"],
    StateHandlers: [],
    viewerUI: new (class ViewerUI extends pdfViewCtrl.viewerui.TinyViewerUI {
      constructor() {
        super();
      }

      createContextMenu(): PDFViewCtrl.viewerui.IContextMenu | undefined {
        return undefined;
      }
    })(),
    customs: {
      ScrollWrap: pdfViewCtrl.DivScrollWrap,

      getDocPermissions: function () {
        const userPermissions = pdfViewCtrl.PDF.constant.User_Permissions;

        return (
          // rotating pages
          userPermissions.assemble |
          // adding highlight annotations
          userPermissions.annotForm |
          // modifying annotations
          userPermissions.fillForm |
          // copying text
          userPermissions.extract
        );
      },
    },
  });
};

export interface PDFViewerSlice {
  pdfViewer: PDFViewCtrl.PDFViewer | null;
  pdfViewerState: "pending" | "ready" | "destroyed";
  events: typeof PDFViewCtrl.constants.ViewerEvents | null;
  init: (el: HTMLElement) => void;
  destroy: () => void;
}

export const usePDFViewerSlice = () => {
  const { data: PDFViewCtrl } = useSuspenseFoxitPDFViewCtrlLoader();
  const worker = useSuspenseWorker();

  const createSlice: StateCreator<
    PDFViewerSlice & PDFControllerSlice,
    [],
    [],
    PDFViewerSlice
  > = (set) => ({
    pdfViewer: null,
    events: null,
    pdfViewerState: "pending",
    init: (el: HTMLElement) => {
      if (window === undefined) {
        throw new Error("PDF Viewer is not available on the server.");
      }

      if (!PDFViewCtrl || !worker) {
        throw new Error("PDFViewCtrl or worker is not available yet");
      }

      const pdfViewer = createPdfViewer(PDFViewCtrl, worker);
      pdfViewer.init(el);

      set({ pdfViewer, pdfViewerState: "ready" });

      const events = PDFViewCtrl.constants.ViewerEvents;

      set({ state: "initialized", events });
    },
    destroy: () => {
      set({ pdfViewerState: "destroyed", pdfViewer: null });
    },
  });

  return createSlice;
};
