import { useEffect, useState } from "react";

import type {
  LoginSuccessResult,
  LogoutEvent,
} from "@alaffia-technology-solutions/client-sdk";

import { useLog } from "../common/log/useLog";
import { useObservableSubscription } from "../util/Observable";
import { useAlaffiaClient } from "./useAlaffiaClient";

const isLogoutEvent = (
  data: LogoutEvent | LoginSuccessResult | undefined,
): data is LogoutEvent => {
  return data?.type === "SessionExpiredEvent" || data?.type === "LogoutEvent";
};

export const useLoginStatus = () => {
  const log = useLog("useLoginStatus");
  // log.setLevel('debug', false);

  const alaffiaClient = useAlaffiaClient();

  const [loggedIn, setLoggedIn] = useState<boolean | null>(null);
  const [hasLoggedIn, setHasLoggedIn] = useState<boolean | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);

  const latest = useObservableSubscription(
    alaffiaClient?.auth.loginStatus,
    null,
  );

  log.debug("%c useLoginStatus", "color:blue", { latest });

  useEffect(() => {
    if (latest?.loggedIn && latest?.data && !isLogoutEvent(latest.data)) {
      if (userId && userId !== latest.data.userId) {
        setHasLoggedIn(false);
      } else {
        setHasLoggedIn(true);
      }
      setLoggedIn(latest.loggedIn);
      setUserId(latest.data.userId);
      setUserRole(latest.data.userRole);
    } else {
      setLoggedIn(false);
      setUserId(null);
      setUserRole(null);
    }
    return () => {
      log.debug(
        "%c useLoginStatus effect cleanup",
        "color: orange; background-color: blue",
        {
          loggedIn,
          hasLoggedIn,
          userId,
        },
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [latest]);

  log.debug("%c useLoginStatus render", "color:blue", {
    loggedIn,
    hasLoggedIn,
    userId,
  });
  return {
    loggedIn,
    hasLoggedIn,
    userId,
    userRole,
  };
};
