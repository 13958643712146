import { graphql } from "../../../../../gql";

/* strip types
 : .*$
*/

export const batchClaimLineItemFragment = graphql(`
  fragment batchClaimLineItemFragment on BatchClaimLineItem {
    id
    dateOfServiceStart
    dateOfServiceEnd
    revCode
    procedureCode
    itemNumber
    description
    units
    unitCharge
    batchClaimLineId
    authorId
    createdAt
    updatedAt
    alaRowId
    ibLineId
    batchClaimLineItemNnIbLines {
      nodes {
        nnIbLineId
        nnIbLine {
          fingerprint
        }
      }
    }
  }
`);

export const reviewedAuditFindingFragment = graphql(`
  fragment reviewedAuditFindingFragment on ReviewedAuditFinding {
    id
    accepted
    deleted
    createdAt
    updatedAt
    rationale
  }
`);

export const auditFindingFragment = graphql(`
  fragment auditFindingFragment on AuditFinding {
    id
    improperPaymentUnitsCharged
    improperPaymentCost
    improperPaymentReason
    needsReview
    auditorReviewed
    auditorComment
    auditType
    superseded
    auditFindingRuleTypeByAuditFindingRuleType {
      type
      description
      displayName
    }
    auditFindingRuleType
    auditFindingSeedType
    confidence
    isOriginal
    isActive
    accepted
    createdAt
    metaDataNcciMueOhpMueValue
    metaDataNcciMueAjudicationValue
    metaDataNcciPtpModifier
    metaDataNcciPtpCode
    metaDataEmCode
    metaDataEmOriginalLevel
    metaDataEmCorrectLevel
    metaDataAocPrimaryCode
    metaDataPricingCorrectPrice
    metaDataUnitsCorrectUnits
    discrepantAmount # At the auditFindings level there is no determined vs potential because this calculates the number on that finding
    author {
      id
      firstName
      lastName
      # userType
      email
    }
    reviewedAuditFinding: latestReview {
      ...reviewedAuditFindingFragment
    }
    batchClaimLineItemAudit {
      id
      batchClaimLineItem {
        ...batchClaimLineItemFragment
      }
    }
    reportedFindings {
      totalCount
      nodes {
        id
        auditFindingReportId
      }
    }
    # needed for updating cache
    batchClaimLineId
    batchClaimId
  }
`);

export const auditFindingReviewFragment = graphql(`
  fragment auditFindingReviewFragment on AuditFindingReview {
    id
    createdAt
    # needed for update cache
    reviewId
    originalId
  }
`);

export const auditFindingReviewsFragment = graphql(`
  fragment auditFindingReviewsFragment on AuditFinding {
    id
    reviews: auditFindingReviewsByOriginalId(orderBy: CREATED_AT_DESC) {
      totalCount
      nodes {
        ...auditFindingReviewFragment
        auditFinding: review {
          ...auditFindingFragment
        }
      }
    }
  }
`);

export const auditFindingWithReviewFragment = graphql(`
  fragment auditFindingWithReviewFragment on AuditFinding {
    ...auditFindingFragment
    ...auditFindingReviewsFragment
    original: auditFindingReviewByReviewId {
      ...auditFindingReviewFragment
      auditFinding: original {
        ...auditFindingFragment
        ...auditFindingReviewsFragment
      }
    }
  }
`);

export const versionAuditFindingFragment = graphql(`
  fragment versionAuditFindingFragment on AuditFinding {
    id
    improperPaymentUnitsCharged
    improperPaymentCost
    improperPaymentReason
    needsReview
    auditorReviewed
    auditorComment
    auditType
    superseded
    auditFindingRuleType
    auditFindingSeedType
    confidence
    isOriginal
    isActive
    accepted
    createdAt
    # needed for updating cache
    batchClaimLineId
    batchClaimId
    batchClaimLineItemAudit {
      id
      batchClaimLineItem {
        ...batchClaimLineItemFragment
      }
    }
  }
`);

export const createIbLineFindingsMutationDoc = graphql(`
  mutation createIbLineFindings(
    $input: CreateIbLineFindingsInput! # $afclFilter: AuditFindingFilter!
  ) {
    createAuditFindings: createIbLineFindings(input: $input) {
      auditFindings {
        ...auditFindingWithReviewFragment
      }
    }
  }
`);

export const versionFindingsMutationDoc = graphql(`
  mutation versionAuditFindings(
    $input: VersionAuditFindingsInput! # $afclFilter: AuditFindingFilter!
  ) {
    createAuditFindings: versionAuditFindings(input: $input) {
      auditFindings {
        ...versionAuditFindingFragment
      }
    }
  }
`);
