import { graphql } from "../gql";

export const updateCaseMutationDoc = graphql(`
  mutation updateCase($id: ID!, $input: UpdateCaseInput!) {
    updateCase(id: $id, input: $input) {
      ...CaseFragment
      queue {
        ...AskAutodorQueueFragment
      }
    }
  }
`);
