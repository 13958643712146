/**
 * anything CLOSED or SENT/PAID is NOT active unless it is IN_PROGRESS
 * Externally and Under Review Claim is Active (A claim that is In progress is an internal metric)
 */
const claimIsActiveGeneralFilter = {
  workflowClaimStatesExist: true,
  or: [
    { batchClaimStateById: { workflowClaimState: { equalTo: "IN_PROGRESS" } } },
    {
      batchClaimStateById: {
        workflowClaimState: { equalTo: "COMPLETED" },
        invoiceClaimState: { notIn: ["SENT", "PAID"] },
      },
    },
  ],
};

/**
 *
 *  USER BASED FILTERS
 *
 */

/* isProviderActive uses cache.batch_claim_is_provider_active() in DB 
   the other filters are only meant for query performance
   isProviderActive ensures:
    documentationClaimState: in 'AWAITING_UPLOAD', 'DOCUMENTS_RECEIVED'
    invoiceClaimState: 'NOT_PAID'
    workflowClaimState: in 'TO_DO', 'IN_PROGRESS', 'COMPLETED'
*/
const claimIsProviderActiveFilter = {
  documentRequestCreatedsExist: true,
  batchClaimStateById: {
    isProviderActive: { equalTo: true },
  },
};

const claimIsAuditorActiveFilter = {
  workflowClaimStatesExist: true,
  batchClaimStateById: {
    workflowClaimState: {
      in: ["CLAIM_RECEIVED", "TO_DO", "IN_PROGRESS"],
    },
  },
};

const claimIsAdminActiveFilter = {
  ...claimIsActiveGeneralFilter,
};

const claimIsPayerActiveFilter = {
  ...claimIsActiveGeneralFilter,
};

const claimIsAssignedToUserFilter = {
  batchClaimAssigneesExist: true, // dramatically improves performance
  isAssignedToQueryingUser: { equalTo: true },
};

/**
 *
 *  STATUS BASED FILTERS
 *  IS ACTIVE, OPEN, REVIEWED, AWAITING DOCS, ETC....
 *
 */

const isRealized = {
  invoiceClaimStatesExist: true,
  batchClaimStateById: {
    isRealized: { equalTo: true },
  },
};

const auditFindingIsActiveFilter = {
  isActive: { equalTo: true },
};

const claimsAwaitingDocumentationFilter = {
  documentRequestCreatedsExist: true,
  batchClaimStateById: {
    documentationClaimState: {
      equalTo: "AWAITING_UPLOAD",
    },
  },
};

const claimsDocumentationReceivedFilter = {
  documentRequestCreatedsExist: true,
  batchClaimStateById: {
    documentationClaimState: {
      equalTo: "DOCUMENTS_RECEIVED",
    },
  },
};

/**
    A claim that is completed had to be 'in-progress' before thus it has been reviewed
    However not all "closed" claims have necessarily been reviewed:
      - Some claim could be pre-selected to be 'archived' without ANY review
    Therefore if a claim is "closed" AND "paid" we know that is was closed after a completed review

    NOTE: Overall still unsure about this filter's correctness - i consulted Oyinade for this
  */
const claimIsReviewed = {
  workflowClaimStatesExist: true,
  or: [
    { batchClaimStateById: { workflowClaimState: { equalTo: "CLOSED" } } },
    {
      invoiceClaimStatesExist: true,
      batchClaimStateById: {
        workflowClaimState: { equalTo: "COMPLETED" },
        invoiceClaimState: { in: ["SENT", "PAID"] },
      },
    },
  ],
};

const claimsOnHoldFilter = {
  and: [
    { onHoldClaimStatesExist: true },
    { batchClaimStateById: { onHoldClaimState: { equalTo: "ON_HOLD" } } },
  ],
};

const claimsInvoicePendingFilter = {
  and: [
    { invoiceClaimStatesExist: true },
    { batchClaimStateById: { invoiceClaimState: { equalTo: "SENT" } } },
  ],
};

const claimsInvoicePaidFilter = {
  and: [
    { invoiceClaimStatesExist: true },
    { batchClaimStateById: { invoiceClaimState: { equalTo: "PAID" } } },
  ],
};

const claimsToDoFilter = {
  workflowClaimStatesExist: true,
  batchClaimStateById: { workflowClaimState: { equalTo: "TO_DO" } },
};

const claimsInProgressFilter = {
  workflowClaimStatesExist: true,
  batchClaimStateById: { workflowClaimState: { equalTo: "IN_PROGRESS" } },
};

const claimsCompletedFilter = {
  workflowClaimStatesExist: true,
  batchClaimStateById: { workflowClaimState: { equalTo: "COMPLETED" } },
};

// gets us all claims witha provider sign off
const claimsSignedOffProviderFilter = {
  providerSignOffClaimStatesExist: true,
  batchClaimStateById: {
    providerSignOffClaimState: { equalTo: "PROVIDER_SIGN_OFF" },
  },
};

/**
 *
 *  REPORT FILTERS
 *
 */
const reportableBatchClaimFilter = {
  auditFindingsExist: true,
  auditFindings: {
    some: {
      and: [
        { improperPaymentCost: { greaterThan: 0 } },
        { accepted: { equalTo: true } },
        { isActive: { equalTo: true } },
      ],
    },
  },
  batchClaimStateById: {
    workflowClaimState: {
      equalTo: "COMPLETED",
    },
  },
};

export {
  claimIsActiveGeneralFilter,
  claimIsProviderActiveFilter,
  claimIsPayerActiveFilter,
  claimIsAdminActiveFilter,
  claimIsAuditorActiveFilter,
  claimIsAssignedToUserFilter,
  auditFindingIsActiveFilter,
  claimsAwaitingDocumentationFilter,
  claimsOnHoldFilter,
  claimsInvoicePendingFilter,
  claimsInvoicePaidFilter,
  claimsDocumentationReceivedFilter,
  claimsSignedOffProviderFilter,
  claimsCompletedFilter,
  claimsInProgressFilter,
  claimsToDoFilter,
  claimIsReviewed,
  isRealized,
  reportableBatchClaimFilter,
};
