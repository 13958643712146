/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { css } from "@emotion/react";
import { Button, message, Modal, Spin, Table, Typography } from "antd";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "urql";

import { useLog } from "../../common/log/useLog";
//Mutation
import { softDeleteQueueMutation } from "../../graphql/mutations";
// queries
import { claimCountQuery, queuesQuery } from "../../graphql/queries";
// context
import { UserContext } from "../context/user";
// components
import { BatchClaimSearchFilter } from "../search/batchClaimSearchFilter";
import { FilterTags } from "../search/search";

const { Title } = Typography;

/**
 * A component for displaying the claim count in a queue
 * @param BatchClaimFilter!
 * @returns <div>Int</div>
 */
const ClaimCount = ({ batchClaimFilter }: any) => {
  const {
    permissions: { baseQueryType },
  } = useContext(UserContext);

  const [{ data }] = useQuery({
    query: claimCountQuery({
      queryTitle: "claimsInQueue",
      queryType: baseQueryType.batchClaims,
    }),
    variables: { batchClaimFilter },
  });

  return (
    <div>{data ? data[baseQueryType.batchClaims].totalCount : <Spin />}</div>
  );
};

/**
 * The columns for a
 * user created queue
 */
const queueColumns = (setQueueToDelete: any, log: any) => [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Filter",
    render: ({ id, indexedFilterTags }: any) => {
      const searchFilter = new BatchClaimSearchFilter(indexedFilterTags);
      return (
        <FilterTags
          id={`queue-filter-tags-${id}`}
          key={`queue-filter-tagsn-${id}`}
          filter={searchFilter}
        />
      );
    },
  },
  {
    title: "Description",
    render: ({ id, description }: any) => {
      return (
        <div
          id={`queue-description-col-${id}`}
          key={`queue-description-col-${id}`}
        >
          {description ? description : "No Description"}
        </div>
      );
    },
  },
  {
    title: "Creation Date",
    render: ({ id, createdAt }: any) => {
      return (
        <div id={`queue-createdAt-col-${id}`} key={`queue-createdAt-col-${id}`}>
          {moment(createdAt).utc().format("MM/DD/YYYY")}
        </div>
      );
    },
  },
  {
    title: "Claim Count",
    render: ({ id, indexedFilterTags }: any) => {
      const searchFilter = new BatchClaimSearchFilter(indexedFilterTags);
      log?.debug(searchFilter.gqlFilter);
      return (
        <ClaimCount
          id={`claim-Count-column-${id}`}
          key={`claim-Count-column-${id}`}
          batchClaimFilter={searchFilter.gqlFilter}
        />
      );
    },
  },
  {
    title: "Actions",
    render: ({ id }: any) => {
      return (
        <Button
          id={`delete-button-${id}`}
          key={`delete-button-${id}`}
          onClick={() => {
            setQueueToDelete(id);
          }}
        >
          Delete
        </Button>
      );
    },
  },
];

/**
 * Formatted Queue title
 * Defaults to "My Queues"
 */
const QueueTitle = ({ title }: any) => (
  <Title
    level={4}
    css={css`
      margintop: 10px;
      marginbottom: 10px;
      float: left;
    `}
  >
    {title || "My Queues"}
  </Title>
);

/**
 * The "My Queues" component, queries for the queues
 * made by the logged in user
 * @returns <Table<Queues>>
 */
const Queues = () => {
  const log = useLog("Queues");
  const history = useHistory();
  const [{ fetching, data }, refreshQuery] = useQuery({
    query: queuesQuery,
    requestPolicy: "network-only", // to ensure it refreshes
  });

  const [queueToDelete, setQueueToDelete] = useState(null);

  const [{ fetching: deletingQueueLoading }, softDeleteQueue] = useMutation(
    softDeleteQueueMutation,
  );

  return (
    <div data-cy="queues">
      <Modal
        open={queueToDelete !== null}
        confirmLoading={deletingQueueLoading}
        onCancel={() => {
          setQueueToDelete(null);
        }}
        onOk={async () => {
          await softDeleteQueue({
            queueId: queueToDelete,
          }).then(({ data, error }) => {
            if (!data && error) {
              const errorMessage = `Queue Deletion failed`;
              log.error(`${errorMessage}: ${error.message}`);
              void message.error(errorMessage);
            } else {
              refreshQuery({ requestPolicy: "network-only" });
              void message.success(`Queue Deletion success`);
            }
            setQueueToDelete(null);
          });
        }}
      >
        Are you Sure you want to delete this Queue?
      </Modal>
      {!fetching ? (
        <Table
          title={() => <QueueTitle />}
          columns={queueColumns(setQueueToDelete, log)}
          dataSource={
            data?.queues.nodes.map((node: { key: string }, i: any) => {
              node.key = `queues-row-${i}`; // each row needs a unique key for react to work best
              return node;
            }) || [] // when no queues are returned
          }
          onRow={(record, _i) => {
            return {
              onDoubleClick: (_) => {
                history.push(`/queue/${record.id}`);
              },
            };
          }}
        />
      ) : (
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Spin size="large" />
        </div>
      )}
      <Modal
        open={queueToDelete !== null}
        confirmLoading={deletingQueueLoading}
        onCancel={() => {
          setQueueToDelete(null);
        }}
        onOk={async () => {
          await softDeleteQueue({
            queueId: queueToDelete,
          }).then(({ data, error }) => {
            if (!data && error) {
              const errorMessage = `Queue Deletion failed`;
              log.error(`${errorMessage}: ${error.message}`);
              void message.error(errorMessage);
            } else {
              refreshQuery();
              void message.success(`Queue Deletion success`);
            }
            setQueueToDelete(null);
          });
        }}
      >
        Are you Sure you want to delete this Queue?
      </Modal>
    </div>
  );
};

export { Queues, QueueTitle };
