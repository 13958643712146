/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { message } from "antd";

export interface CopyHoverProps {
  textToCopy: string;
  onCopyComplete: null | ((textCopied: string) => void);
  className?: string;
  children: React.ReactNode;
}

export const CopyHover: React.FC<CopyHoverProps> = ({
  textToCopy,
  onCopyComplete,
  className,
  children,
}) => {
  const doCopy = async () => {
    return navigator.clipboard
      .writeText(textToCopy)
      .then(() => onCopyComplete && onCopyComplete(textToCopy))
      .catch((err) => {
        void message.error(`Failed to copy text to clipboard: '${textToCopy}'`);
        console.error(err);
      });
  };

  return (
    <div className={className}>
      <div
        className="af-group"
        tw="af-flex af-flex-row af-items-center af-justify-center af-w-fit af-min-w-fit af-h-fit af-min-h-fit"
      >
        <div>{children}</div>
        <div
          tw="af-flex af-flex-row af-w-5 af-h-5 af-px-0.5 af-invisible group-hover:af-visible"
          onClick={doCopy}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            tw="af-w-[1.15rem] af-h-[1.15rem] af-stroke-antd-dk-colorBgSpotlight dark:af-stroke-antd-lt-colorBgSpotlight hover:af-stroke-antd-lt-colorPrimaryHover hover:dark:(af-stroke-antd-dk-colorPrimaryHover)"
          >
            <path
              tw="af-inline"
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 01-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 011.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 00-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 01-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5a3.375 3.375 0 00-3.375-3.375H9.75"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};
