import { extractEpochMillisecondsFromSignedUrl } from "@alaffia-technology-solutions/client-sdk";
import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import type { File } from "@alaffia-technology-solutions/schema";

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;

interface UseFileDownloadUrlOptions {
  fileId: File["id"];
  enabled?: boolean;
}

export const useFileDownloadUrl = ({
  fileId,
  enabled,
}: UseFileDownloadUrlOptions) => {
  return apiClient.files.getFileDownloadUrl.useQuery(
    { id: fileId },
    {
      enabled,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
      refetchInterval: (data) => {
        if (!data?.url) {
          return false;
        }

        const minExpiryInterval = 30 * MS_IN_SECOND;
        const refreshBeforeExpiryThreshold = 5 * MS_IN_MINUTE;

        const expiresTimestampMilliseconds =
          extractEpochMillisecondsFromSignedUrl(data.url);

        const refreshInterval =
          expiresTimestampMilliseconds - refreshBeforeExpiryThreshold;

        return Math.max(refreshInterval, minExpiryInterval);
      },
    },
  );
};
