import { useCallback, useEffect, useRef } from "react";

import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import { useScrollIntoView } from "@alaffia-technology-solutions/hooks";
import type { Case, File } from "@alaffia-technology-solutions/schema";

import { useAskAutodorPrompt } from "./useAskAutodorPrompt";

interface UseAskAutodorStateOptions {
  caseId: Case["id"];
  fileIds?: File["id"][];
}

export function useAskAutodorState({
  caseId,
  fileIds = [],
}: UseAskAutodorStateOptions) {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const messagesBottomRef = useRef<HTMLDivElement>(null);
  const scrollToMessagesBottom = useScrollIntoView({ ref: messagesBottomRef });
  const prompt = useAskAutodorPrompt({
    caseId,
    onMutate: scrollToMessagesBottom,
    onSettled: scrollToMessagesBottom,
  });

  // We use useIsMutating here instead of `createMessage.isLoading`
  // because useIsMutating reads the mutation status from the global mutation cache,
  // so it will preserve the status when the Chatbox is closed and reopened.
  const isPromptMutating = apiClient.cases.prompt.useIsMutating() > 0;

  const threads = apiClient.cases.getThreads.useQuery(
    { input: { caseId } },
    {
      // This prevents the threads refetching (on window re-focusing
      // or upon closing and reopening the Chatbox window)
      // and overwriting the optimistic updates made in the `prompt` mutation
      // before the prompt mutation is completed.
      enabled: Boolean(caseId) && !isPromptMutating,
      staleTime: Infinity,
    },
  );
  const currentThread = threads.data?.[0];

  const fileIdsRef = useRef(fileIds);

  useEffect(() => {
    fileIdsRef.current = fileIds;
  }, [fileIds]);

  const handlePromptSubmit = useCallback(
    (textContent: string, editorState: string, searchKeywords: string[]) => {
      if (!currentThread?.id) {
        // This should never happen because every case should have at least one thread
        throw new Error("No thread found");
      }

      return prompt.mutate({
        input: {
          threadId: currentThread.id,
          fileIds: fileIdsRef.current,
          promptText: textContent,
          inputState: editorState,
          searchKeywords,
        },
      });
    },
    [prompt, currentThread?.id],
  );

  return {
    wrapperRef,
    messagesBottomRef,
    threads,
    currentThread,
    handlePromptSubmit,
  };
}
