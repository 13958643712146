import { createMutationHook, createQueryHook } from "../factory";

export const queueKeys = {
  all: ["queues"] as const,
  lists: () => [...queueKeys.all, "list"] as const,
  list: <T>(filters: T) => [...queueKeys.lists(), filters] as const,
  details: () => [...queueKeys.all, "detail"] as const,
  detail: ({ id }: { id: string }) => [...queueKeys.details(), id] as const,
};

export const queues = {
  getQueue: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.queues.getQueue,
    queryKeyFn: queueKeys.detail,
  }),
  getAllQueues: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.queues.getAllQueues,
    queryKeyFn: queueKeys.list,
  }),
  createQueue: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.queues.createQueue,
    mutationKey: ["queues", "createQueue"],
  }),
  updateQueue: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.queues.updateQueue,
    mutationKey: ["queues", "updateQueue"],
  }),
  deleteQueue: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.queues.deleteQueue,
    mutationKey: ["queues", "deleteQueue"],
  }),
};
