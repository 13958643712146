/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Component } from "react";
import {
  AuditOutlined,
  CalendarOutlined,
  CarryOutOutlined,
  CloseSquareOutlined,
  ContainerOutlined,
  CreditCardOutlined,
  DollarCircleOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  ExperimentOutlined,
  FieldTimeOutlined,
  FileDoneOutlined,
  FileUnknownOutlined,
  FormOutlined,
  InfoOutlined,
  MedicineBoxOutlined,
  PauseOutlined,
  PercentageOutlined,
  PlusCircleOutlined,
  RiseOutlined,
  ScheduleOutlined,
  ShopOutlined,
  SnippetsOutlined,
  TrademarkOutlined,
  UserAddOutlined,
  UserSwitchOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { css } from "@emotion/react";
import type { JSX } from "@emotion/react/jsx-runtime";
import { DatePicker, InputNumber } from "antd";
import type { RangePickerProps } from "antd/es/date-picker/generatePicker";
import type {
  CommonPickerMethods,
  PickerComponentClass,
} from "antd/es/date-picker/generatePicker/interface";
import type { Dayjs } from "dayjs";
import moment from "moment-timezone";

import { assignableAuditorUsersQuery } from "../../../fragments";
import {
  claimIsProviderActiveFilter,
  isRealized,
} from "../../../graphql/filters";
import { inputPropsCurrencyFormatter } from "../../audit/util";
import * as stateTypes from "../../claimState/stateTypes";
import {
  RangeSelectFilterComponent,
  SelectFilterComponent,
} from "./searchModalComponents";
import {
  icnQuery,
  insurerQuery,
  providerQuery,
  uniqueProcedureCodesQuery,
  uniqueRevCodeQuery,
} from "./searchQueries";
import {
  arrayOfLabelsFromLabelValueMap,
  filterClaimStatesSearchOptionsByPermission,
  generateFormattedMoneyRangeString,
  generateOptionsArrForEnumClaimState,
  getDisplayNameOfDateRange,
  getDisplayNamesOfStatesArray,
  getUTCDateObj,
  isUuid,
  isValidInteger,
  stringReplace,
} from "./util";

const searchableFields = {
  /*
    --- SEARCH BY Claim Number ---
  */
  claimNumber: {
    displayName: "Claim Number",
    icon: <AuditOutlined />,
    options: ({ query, queryData }: any) => {
      const ClaimNumbers: any[] = [];
      (queryData ? queryData[query.name].nodes : []).forEach(({ icn }: any) => {
        ClaimNumbers.push({
          label: icn,
          value: icn,
        });
      });
      return ClaimNumbers;
    },
    typeDef: {
      filterBuilder: (values: any) => ({ icn: { in: values } }),
      selectType: "tags",
    },
    tag: {
      tagTitle: "claimNumber",
      tagText: (v: any) =>
        v.length > 1 ? `Claim Numbers: ${v.length}` : `Claim Number: ${v}`,
      tagTooltipTitle: (v: any) => `Claim Numbers: ${v.join(", ")}`,
    },
    query: {
      name: "batchClaims",
      gql: icnQuery,
      queryFilter: ({ input, search, userType }: any) => {
        return {
          includes: input,
          userBasedFilter: {
            ...(userType === "PROVIDER" ? claimIsProviderActiveFilter : {}),
            ...search.isValidInformationForFormFilter.batchClaim,
          },
        };
      },
      autoComplete: {
        getResults: ({ queryData }: any) => {
          return (
            queryData &&
            queryData.batchClaims.nodes.length > 0 &&
            queryData.batchClaims.nodes
          );
        },
        meetsAutoCompleteRequirement: ({ input, userType }: any) => {
          const acceptableChars = /^[a-zA-Z0-9_-]*$/; // alphanumeric, dash, underscore
          if (input?.length > 0 && ["_", "-"].includes(input?.charAt(0))) {
            return {
              result: false,
              reason:
                "Invalid characters; claim number must start with a letter or number",
            };
          }
          const queryThreshold = ["ADMINISTRATOR", "PAYER"].includes(userType)
            ? 3 // minimum for Admin/Payer is 4+ characters
            : 1; // else 2+, because there is a smaller world of claims for Providers, Auditors
          const meetsLengthReq = input?.length > queryThreshold;
          const meetsAcceptableCharsReq = acceptableChars.test(input);
          return {
            result: meetsLengthReq && meetsAcceptableCharsReq,
            reason: !meetsAcceptableCharsReq
              ? `Invalid characters; claim number can only contain letters, numbers, underscore and dash`
              : !meetsLengthReq
              ? `Please enter at least ${queryThreshold + 1} characters`
              : "",
          };
        },
        isCurrentAutoCompleteResultValid: ({
          input,
          lastQueryVariables,
        }: any) => {
          const last = lastQueryVariables?.includes || "";
          return (
            last.length > 0 &&
            input.length >= last.length &&
            input.toLowerCase().startsWith(last.toLowerCase())
          );
        },
      },
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- ENUM CLAIM STATE SEARCHABLE FIELDS ---
  */
  workflow: {
    displayName: "Workflow State",
    icon: <EditOutlined />,
    options: ({ typeDef, permissions, field, userType }: any) => {
      return generateOptionsArrForEnumClaimState(
        filterClaimStatesSearchOptionsByPermission(
          typeDef.states,
          field,
          permissions,
        ),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.workflow,
      selectType: "multiple",
    },
    tag: {
      tagKey: "workflow",
      tagText: (v: string | any[], userType: any) =>
        `Workflow: ${
          v.length === 1
            ? getDisplayNamesOfStatesArray(
                stateTypes.workflow.states,
                v,
                userType,
              )
            : `${v.length} States`
        }`,
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.workflow.states, v, userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  documentation: {
    displayName: "Documentation Request State",
    icon: <ContainerOutlined />,
    options: ({ typeDef, permissions, field, userType }: any) => {
      return generateOptionsArrForEnumClaimState(
        filterClaimStatesSearchOptionsByPermission(
          typeDef.states,
          field,
          permissions,
        ),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.documentation,
      selectType: "multiple",
    },
    tag: {
      tagKey: "documentation",
      tagText: (v: string | any[], userType: any) =>
        `Documentation: ${
          v.length === 1
            ? getDisplayNamesOfStatesArray(
                stateTypes.documentation.states,
                v,
                userType,
              )
            : `${v.length} States`
        }`,
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(
          stateTypes.documentation.states,
          v,
          userType,
        ),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
      --- SEARCH BY DOCUMENT TYPE ---
  */
  documentType: {
    displayName: "Documentation",
    icon: <FileUnknownOutlined />,
    options: () => {
      // a query can be added when this is expanded
      return [
        // hard coding this also makes hiding the spelling error easier
        {
          label: "Medical Record",
          value: "MEDICAL_RECORD",
        },
        {
          label: "Billing Record",
          value: "BILLING_RECORD",
        },
        {
          label: "Other",
          value: "OTHER",
        },
        {
          label: "No Documentation Uploaded",
          value: "NO_DOCUMENTATION_UPLOADED",
        },
      ];
    },
    typeDef: {
      filterBuilder: (values: string | string[]) => {
        if (values.includes("NO_DOCUMENTATION_UPLOADED")) {
          return {
            // TODO
            documentUploadCreatedsExist: false,
          };
        } else {
          return {
            // TODO
            documentUploadCreatedsExist: true,
            // TODO
            documentUploadCreateds: { some: { kind: { in: values } } },
          };
        }
      },
      selectType: "multiple",
    },
    tag: {
      tagTitle: "documentType",
      tagText: (v: any) => {
        return v.length > 1
          ? `Doc Types: ${v.length}`
          : `Doc Type: ${
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              {
                BILLING_RECORD: "Billing Record",
                MEDICAL_RECORD: "Medical Record",
                OTHER: "Other",
                NO_DOCUMENTATION_UPLOADED: "No Documentation",
              }[v]
            }`;
      },
      tagTooltipTitle: (v: any) =>
        `Doc Types: ${arrayOfLabelsFromLabelValueMap(
          {
            BILLING_RECORD: "Billing Record",
            MEDICAL_RECORD: "Medical Record",
            OTHER: "Other",
            NO_DOCUMENTATION_UPLOADED: "No Documentation",
          },
          v,
        )}`,
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  reported: {
    displayName: "Reports State",
    icon: <FileDoneOutlined />,
    options: ({ typeDef, userType }: any) => {
      // When searching a claim state with binary values/selections we dont need to filter options
      return generateOptionsArrForEnumClaimState(
        Object.entries(typeDef.states),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.report,
      selectType: null,
    },
    tag: {
      tagKey: "reported",
      tagText: (v: any, userType: any) => {
        return getDisplayNamesOfStatesArray(
          stateTypes.report.states,
          [v],
          userType,
        );
      },
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.report.states, [v], userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY REPORT ID ---
    EN-381: Removed Oct 2022 - after commit d2298b1aa23b93244a8b148cb9464bf2451356ef
    Report Service v2 introduces new types that would require overhaul of component which was deemed unnecessary
    at the time - source for MultipleSelectFilterComponent is still available in searchModalComponent.js
  */

  /*
    --- MORE ENUM CLAIM STATE SEARCHABLE FIELDS ---
  */
  dispute: {
    displayName: "Disputes State",
    icon: <CloseSquareOutlined />,
    options: ({ typeDef, userType }: any) => {
      // When searching a claim state with binary values/selections we dont need to filter options
      return generateOptionsArrForEnumClaimState(
        Object.entries(typeDef.states),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.dispute,
      selectType: null,
    },
    tag: {
      tagKey: "disputed",
      tagText: (v: any, userType: any) => {
        return getDisplayNamesOfStatesArray(
          stateTypes.dispute.states,
          [v],
          userType,
        );
      },
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.dispute.states, [v], userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  negotiation: {
    displayName: "Negotiation State",
    icon: <UserSwitchOutlined />,
    options: ({ typeDef, userType }: any) => {
      // When searching a claim state with binary values/selections we dont need to filter options
      return generateOptionsArrForEnumClaimState(
        Object.entries(typeDef.states),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.negotiation,
      selectType: null,
    },
    tag: {
      tagKey: "negotiation",
      tagText: (v: any, userType: any) => {
        return getDisplayNamesOfStatesArray(
          stateTypes.negotiation.states,
          [v],
          userType,
        );
      },
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(
          stateTypes.negotiation.states,
          [v],
          userType,
        ),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  signOff: {
    displayName: "Provider/Client Sign Off State",
    icon: <FormOutlined />,
    options: ({ typeDef, permissions, field, userType }: any) => {
      return generateOptionsArrForEnumClaimState(
        filterClaimStatesSearchOptionsByPermission(
          typeDef.states,
          field,
          permissions,
        ).filter(([state, _]) => typeDef.queryableStates.includes(state)),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.signOff,
      selectType: "multiple",
    },
    tag: {
      tagKey: "signOff",
      tagText: (v: string | any[], userType: any) =>
        v.length === 1
          ? getDisplayNamesOfStatesArray(stateTypes.signOff.states, v, userType)
          : `Client/Provider SO: ${v.length} States`,
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.signOff.states, v, userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  onHold: {
    displayName: "On Hold State",
    icon: <PauseOutlined />,
    options: ({ typeDef, userType }: any) => {
      // When searching a claim state with binary values/selections we dont need to filter options
      return generateOptionsArrForEnumClaimState(
        Object.entries(typeDef.states),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.onHold,
      selectType: null,
    },
    tag: {
      tagKey: "onHold",
      tagText: (v: any, userType: any) => {
        return getDisplayNamesOfStatesArray(
          stateTypes.onHold.states,
          [v],
          userType,
        );
      },
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.onHold.states, [v], userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  invoice: {
    displayName: "Invoice State",
    icon: <CreditCardOutlined />,
    options: ({ typeDef, permissions, field, userType }: any) => {
      return generateOptionsArrForEnumClaimState(
        filterClaimStatesSearchOptionsByPermission(
          typeDef.states,
          field,
          permissions,
        ),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.invoice,
      selectType: "multiple",
    },
    tag: {
      tagKey: "invoice",
      tagText: (v: string | any[], userType: any) =>
        v.length === 1
          ? getDisplayNamesOfStatesArray(stateTypes.invoice.states, v, userType)
          : `Invoice: ${v.length} States`,
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(stateTypes.invoice.states, v, userType),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  assignment: {
    displayName: "Assignees",
    icon: <UserAddOutlined />,
    options: ({ queryData, query }: any) => {
      const assigneeOptions = [
        { label: "No Assignees", value: "NO_ASSIGNEES" },
      ];

      (queryData ? queryData[query.name].nodes : []).forEach((node: any) => {
        const { firstName, lastName } = node;
        assigneeOptions.push({
          label: `${firstName} ${lastName}`,
          value: `${firstName} ${lastName}`,
        });
      });
      return assigneeOptions;
    },
    typeDef: {
      ...stateTypes.assignment,
      selectType: "multiple",
    },
    tag: {
      tagKey: "assignees",
      tagText: (v: string | string[]) => {
        return v.includes("NO_ASSIGNEES")
          ? "No Assignees"
          : v.length === 1
          ? `Assignee: ${v}`
          : `${v.length} Assignees`;
      },
      tagTooltipTitle: (v: string[]) => {
        return v.includes("NO_ASSIGNEES")
          ? "Filter for claims with No Assignees"
          : `Assignees: ${v.join(", ")}`;
      },
    },
    query: {
      name: "auditorUsers",
      gql: assignableAuditorUsersQuery,
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },
  dueDate: {
    displayName: "Due Date",
    icon: <CalendarOutlined />,
    typeDef: {
      rangeSelectComponent: (
        props: JSX.IntrinsicAttributes &
          (JSX.IntrinsicClassAttributes<
            Component<
              RangePickerProps<Dayjs> & {
                dropdownClassName?: string | undefined;
                popupClassName?: string | undefined;
              },
              unknown,
              any
            > &
              CommonPickerMethods
          > &
            JSX.LibraryManagedAttributes<
              PickerComponentClass<
                RangePickerProps<Dayjs> & {
                  dropdownClassName?: string | undefined;
                  popupClassName?: string | undefined;
                },
                unknown
              >,
              Readonly<
                RangePickerProps<Dayjs> & {
                  dropdownClassName?: string | undefined;
                  popupClassName?: string | undefined;
                }
              >
            >),
      ) => {
        const { RangePicker } = DatePicker;
        return <RangePicker {...props} />;
      },
      isValueValid: (v: any) => {
        return v !== null;
      },
      defaultValueGenerator: ({ dueDate }: any) => {
        return [getUTCDateObj(dueDate[0]), getUTCDateObj(dueDate[1])];
      },
      filterType: [{ keyword: "dueDate", label: "Due Date Range (UTC)" }],
      ...stateTypes.dueDate, // filterBuilder lives here
    },
    tag: {
      tagKey: "dueDate",
      tagText: ({ dueDate: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Due Date Range",
          startDate,
          endDate,
          "MMM D YY",
        );
      },
      tagTooltipTitle: ({ dueDate: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Due Date Range",
          startDate,
          endDate,
          "ddd MMM Do YYYY",
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },

  providerActive: {
    displayName: "Provider Visible",
    icon: <FileDoneOutlined />,
    options: ({ typeDef, userType }: any) => {
      // When searching a claim state with binary values/selections we dont need to filter options
      return generateOptionsArrForEnumClaimState(
        Object.entries(typeDef.states),
        userType,
      );
    },
    typeDef: {
      ...stateTypes.providerActive,
      selectType: null,
    },
    tag: {
      tagKey: "providerActive",
      tagText: (v: any, userType: any) => {
        return getDisplayNamesOfStatesArray(
          stateTypes.providerActive.states,
          [v],
          userType,
        );
      },
      tagTooltipTitle: (v: any, userType: any) =>
        getDisplayNamesOfStatesArray(
          stateTypes.providerActive.states,
          [v],
          userType,
        ),
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
      --- SEARCH BY NPI ---
  */
  npi: {
    displayName: "NPI",
    icon: <InfoOutlined />,
    options: ({ query, queryData }: any) => {
      const NPIs: any[] = [];
      (queryData ? queryData[query.name].nodes : []).forEach(
        ({ id, npi }: any) => {
          NPIs.push({
            label: stringReplace(
              "Provider: %id% (NPI: %npi%)",
              { "%id%": "id", "%npi%": "npi" },
              { id, npi },
            ),
            value: npi,
          });
        },
      );
      return NPIs;
    },
    typeDef: {
      filterBuilder: (values: any) => ({ provider: { npi: { in: values } } }),
      selectType: "tags",
    },
    tag: {
      tagKey: "npi",
      tagText: (v: string | any[]) =>
        v.length > 1 ? `NPIs: ${v.length}` : `NPI: ${v}`,
      tagTooltipTitle: (v: any[]) => `NPIs: ${v.join(", ")}`,
    },
    query: {
      name: "providers",
      gql: providerQuery, // since NPI is on provider
      queryFilter: ({ search }: any) => {
        if (
          Object.keys(search.isValidInformationForFormFilter.batchClaim)
            .length === 0
        )
          return { providerFilter: {} };
        return {
          providerFilter: {
            batchClaims: {
              some: { ...search.isValidInformationForFormFilter.batchClaim },
            },
          },
        };
      },
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY PROVIDER ID ---
  */
  providerId: {
    displayName: "Providers",
    icon: <MedicineBoxOutlined />,
    options: ({ query, queryData }: any) => {
      return (queryData ? queryData[query.name].nodes : []).map(
        ({ id, name }: any) => ({
          label: stringReplace(
            "%name% (%id%)",
            {
              "%name%": "name",
              "%id%": "id",
            },
            {
              id,
              name,
            },
          ),
          value: id,
        }),
      );
    },
    typeDef: {
      filterBuilder: (values: any) => ({
        providerId: { in: values },
      }),
      selectType: "tags",
    },
    tag: {
      tagKey: "providers",
      tagText: (v: string | any[]) =>
        v.length > 1 ? `Provider ID: ${v.length} IDs` : `Provider ID: ${v}`,
      tagTooltipTitle: (v: any[]) => `Provider Ids: ${v.join(", ")}`,
    },
    query: {
      name: "providers",
      queryFilter: ({ search }: any) => {
        if (
          Object.keys(search.isValidInformationForFormFilter.batchClaim)
            .length === 0
        )
          return { providerFilter: {} };
        return {
          providerFilter: {
            batchClaims: {
              some: { ...search.isValidInformationForFormFilter.batchClaim },
            },
          },
        };
      },
      gql: providerQuery,
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY INSURER ID ---
  */
  insurerId: {
    displayName: "Insurers",
    icon: <ShopOutlined />,
    options: ({ query, queryData }: any) => {
      return (queryData ? queryData[query.name].nodes : []).map(
        ({ id, name }: any) => ({
          label: stringReplace(
            "%name% (%id%)",
            {
              "%name%": "name",
              "%id%": "id",
            },
            {
              id,
              name,
            },
          ),
          value: id,
        }),
      );
    },
    typeDef: {
      filterBuilder: (values: any) => {
        return {
          insurerId: { in: values },
        };
      },
      invalidValuesFilter: (values: any[]) => {
        return values.filter((value) => isUuid(value));
      },
      selectType: "tags",
    },
    tag: {
      tagKey: "insurers",
      tagText: (v: string | any[]) =>
        v.length > 1 ? `Insurer ID: ${v.length} IDs` : `Insurer ID: ${v}`,
      tagTooltipTitle: (v: any[]) => `Insurer Ids: ${v.join(", ")}`,
    },
    query: {
      name: "insurers",
      gql: insurerQuery,
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  amountCharged: {
    displayName: "Billed Amount",
    icon: <DollarCircleOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => (
        <InputNumber
          min={0}
          css={css`
            width: 125px;
          `}
          {...inputPropsCurrencyFormatter}
          {...props}
        />
      ),
      filterType: [
        {
          keyword: "lessThanOrEqualTo",
          label: "Less Than Or Equal To",
        },
        {
          keyword: "greaterThanOrEqualTo",
          label: "Greater Than Or Equal To",
        },
      ],
      filterBuilder: (v: any) => {
        const filter: any = { amountCharged: {} };
        if (v.lessThanOrEqualTo)
          filter.amountCharged.lessThanOrEqualTo = v.lessThanOrEqualTo;
        if (v.greaterThanOrEqualTo)
          filter.amountCharged.greaterThanOrEqualTo = v.greaterThanOrEqualTo;
        return filter;
      },
      isValueValid: (v: any) => {
        return isValidInteger(v, { min: 0 });
      },
    },
    tag: {
      tagKey: "amountCharged",
      tagText: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Billed Amount",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: true, min: 0 },
        );
      },
      tagTooltipTitle: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Billed Amount",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: false, min: 0 },
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },
  amountReimbursed: {
    displayName: "Allowed Amount",
    icon: <PercentageOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => (
        <InputNumber
          min={0}
          css={css`
            width: 125px;
          `}
          {...inputPropsCurrencyFormatter}
          {...props}
        />
      ),
      filterType: [
        { keyword: "lessThanOrEqualTo", label: "Less Than Or Equal To" },
        {
          keyword: "greaterThanOrEqualTo",
          label: "Greater Than Or Equal To",
        },
      ],
      filterBuilder: (v: any) => {
        const filter: any = { amountReimbursed: {} };
        if (v.lessThanOrEqualTo)
          filter.amountReimbursed.lessThanOrEqualTo = v.lessThanOrEqualTo;
        if (v.greaterThanOrEqualTo)
          filter.amountReimbursed.greaterThanOrEqualTo = v.greaterThanOrEqualTo;
        return filter;
      },
      isValueValid: (v: any) => {
        return isValidInteger(v, { min: 0 });
      },
    },
    tag: {
      tagKey: "amountReimbursed",
      tagText: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Allowed Amount",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: true, min: 0 },
        );
      },
      tagTooltipTitle: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Allowed Amount",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: false, min: 0 },
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY POTENTIAL, DETERMINED, OR DETERMINED REALIZED SAVINGS ---
  */

  potentialClientSavings: {
    displayName: "Potential Client Savings",
    icon: <ExclamationCircleOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => (
        <InputNumber
          min={0}
          css={css`
            width: 125px;
          `}
          {...inputPropsCurrencyFormatter}
          {...props}
        />
      ),
      filterType: [
        { keyword: "lessThanOrEqualTo", label: "Less Than Or Equal To" },
        { keyword: "greaterThanOrEqualTo", label: "Greater Than Or Equal To" },
      ],
      filterBuilder: (v: any) => {
        if (!v || (!v.lessThanOrEqualTo && !v.greaterThanOrEqualTo)) {
          return {};
        }
        const getFilter = (prop: string, value: any) => ({
          auditFindings: {
            // this is not using the batchClaimState cache as this is more efficient
            aggregates: {
              sum: {
                potentialClientSavings: {
                  [prop]: value,
                },
              },
            },
          },
        });
        const filter: any = { and: [{ auditFindingsExist: true }] };
        if (v.lessThanOrEqualTo) {
          filter.and.push(getFilter("lessThanOrEqualTo", v.lessThanOrEqualTo));
        }
        if (v.greaterThanOrEqualTo) {
          filter.and.push(
            getFilter("greaterThanOrEqualTo", v.greaterThanOrEqualTo),
          );
        }
        return filter;
      },

      isValueValid: (v: any) => {
        return isValidInteger(v, { min: 0 });
      },
    },
    tag: {
      tagTitle: "potentialClientSavings",
      tagText: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Potential Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: true, min: 0 },
        );
      },
      tagTooltipTitle: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Potential Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: false, min: 0 },
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },
  determinedClientSavings: {
    displayName: "Determined Client Savings",
    icon: <PlusCircleOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => (
        <InputNumber
          min={0}
          css={css`
            width: 125px;
          `}
          {...inputPropsCurrencyFormatter}
          {...props}
        />
      ),
      filterType: [
        { keyword: "lessThanOrEqualTo", label: "Less Than Or Equal To" },
        { keyword: "greaterThanOrEqualTo", label: "Greater Than Or Equal To" },
      ],
      filterBuilder: (v: any) => {
        if (!v || (!v.lessThanOrEqualTo && !v.greaterThanOrEqualTo)) {
          return {};
        }
        const getFilter = (prop: string, value: any) => ({
          auditFindings: {
            // this is not using the batchClaimState cache as this is more efficient
            aggregates: {
              sum: {
                determinedClientSavings: {
                  [prop]: value,
                },
              },
            },
          },
        });
        const filter: any = { and: [{ auditFindingsExist: true }] };
        if (v.lessThanOrEqualTo) {
          filter.and.push(getFilter("lessThanOrEqualTo", v.lessThanOrEqualTo));
        }
        if (v.greaterThanOrEqualTo) {
          filter.and.push(
            getFilter("greaterThanOrEqualTo", v.greaterThanOrEqualTo),
          );
        }
        return filter;
      },
      isValueValid: (v: any) => {
        return isValidInteger(v, { min: 0 });
      },
    },
    tag: {
      tagTitle: "determinedClientSavings",
      tagText: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Determined Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: true, min: 0 },
        );
      },
      tagTooltipTitle: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Determined Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: false, min: 0 },
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },
  realizedClientSavings: {
    displayName: "Realized Client Savings",
    icon: <RiseOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => (
        <InputNumber
          min={0}
          css={css`
            width: 125px;
          `}
          {...inputPropsCurrencyFormatter}
          {...props}
        />
      ),
      filterType: [
        { keyword: "lessThanOrEqualTo", label: "Less Than Or Equal To" },
        { keyword: "greaterThanOrEqualTo", label: "Greater Than Or Equal To" },
      ],
      filterBuilder: (v: any) => {
        if (!v || (!v.lessThanOrEqualTo && !v.greaterThanOrEqualTo)) {
          return {};
        }
        const getFilter = (prop: string, value: any) => ({
          auditFindings: {
            // this is not using the batchClaimState cache as this is more efficient
            aggregates: {
              sum: {
                determinedClientSavings: {
                  [prop]: value,
                },
              },
            },
          },
        });
        const filter: any = {
          and: [{ ...isRealized }],
        };
        if (v.lessThanOrEqualTo) {
          filter.and.push(getFilter("lessThanOrEqualTo", v.lessThanOrEqualTo));
        }
        if (v.greaterThanOrEqualTo) {
          filter.and.push(
            getFilter("greaterThanOrEqualTo", v.greaterThanOrEqualTo),
          );
        }
        return filter;
      },
      isValueValid: (v: any) => {
        return isValidInteger(v, { min: 0 });
      },
    },
    tag: {
      tagTitle: "realizedClientSavings",
      tagText: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Realized Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: true, min: 0 },
        );
      },
      tagTooltipTitle: (v: any) => {
        return generateFormattedMoneyRangeString(
          v,
          "Realized Client Savings",
          "greaterThanOrEqualTo",
          "lessThanOrEqualTo",
          { useSymbols: false, min: 0 },
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },

  /*
    --- AUDIT FINDINGS SEARCHABLE FIELDS ---
  */
  auditFindingsExist: {
    displayName: "Claim Review Findings Exist",
    icon: <WarningOutlined />,
    options: () => {
      return [
        {
          label: "True",
          value: true,
        },
        {
          label: "False",
          value: false,
        },
      ];
    },
    typeDef: {
      filterBuilder: (value: any) => ({
        auditFindingsExist: value,
      }),
      selectType: null,
    },
    tag: {
      tagKey: "auditFindingsExist",
      tagText: (v: string | number) => {
        return {
          true: "Has Claim Review Findings",
          false: "No Claim Review Findings",
        }[v];
      },
      tagTooltipTitle: (v: string | number) => {
        return {
          true: "Claims with Claim Review Findings",
          false: "Claims with no Claim Review Findings",
        }[v];
      },
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY PROCEDURE CODES ---
  */
  procedureCode: {
    displayName: "Procedure Codes",
    icon: <ExperimentOutlined />,
    options: ({ query, queryData }: any) => {
      // this query can return null values
      return (queryData ? queryData[query.name].nodes : []).reduce(
        (resultArr: any, currentValue: any) => {
          if (currentValue !== null) {
            resultArr.push({
              label: currentValue,
              value: currentValue,
            });
          }
          return resultArr;
        },
        [],
      );
    },
    typeDef: {
      filterBuilder: (values: any) => ({
        batchClaimLines: { some: { procedureCode: { in: values } } },
      }),
      selectType: "tags",
    },
    tag: {
      tagKey: "procedureCodes",
      tagText: (v: string | any[]) =>
        v.length > 1 ? `Proc Code: ${v.length} Codes` : `Proc Code: ${v}`,
      tagTooltipTitle: (v: any[]) => `Proc Codes: ${v.join(", ")}`,
    },
    query: {
      name: "uniqueProcedureCodes",
      gql: uniqueProcedureCodesQuery,
      queryFilter: ({ input }: any) => {
        return {
          includes: input,
        };
      },
      autoComplete: {
        getResults: ({ queryData }: any) => {
          return (
            queryData &&
            queryData.uniqueProcedureCodes.nodes.length > 0 &&
            queryData.uniqueProcedureCodes.nodes
          );
        },
        meetsAutoCompleteRequirement: ({ input }: any) => {
          const acceptableChars = /^[a-zA-Z0-9]*$/; // alphanumeric
          const queryThreshold = 1;
          const meetsLengthReq = input?.length > queryThreshold;
          const meetsAcceptableCharsReq = acceptableChars.test(input);
          return {
            result: meetsLengthReq && meetsAcceptableCharsReq,
            reason: !meetsAcceptableCharsReq
              ? `Invalid characters; only numbers & letters allowed`
              : !meetsLengthReq
              ? `Please enter at least ${queryThreshold + 1} characters`
              : "",
          };
        },
        isCurrentAutoCompleteResultValid: ({
          input,
          lastQueryVariables,
        }: any) => {
          const last = lastQueryVariables?.includes || "";
          return (
            last.length > 0 &&
            input.length >= last.length &&
            input.startsWith(last)
          );
        },
      },
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY REV CODES ---
  */
  revCode: {
    displayName: "Rev Codes",
    icon: <TrademarkOutlined />,
    options: ({ query, queryData }: any) => {
      return (queryData ? queryData[query.name].nodes : []).map((i: any) => ({
        label: i,
        value: i,
      }));
    },
    typeDef: {
      filterBuilder: (values: any) => ({
        batchClaimLines: { some: { revCode: { in: values } } },
      }),
      selectType: "tags",
    },
    tag: {
      tagKey: "revCodes",
      tagText: (v: string | any[]) =>
        v.length > 1 ? `Rev Code: ${v.length} Codes` : `Rev Code: ${v}`,
      tagTooltipTitle: (v: any[]) => `Rev Codes: ${v.join(", ")}`,
    },
    query: {
      name: "uniqueRevCodes",
      gql: uniqueRevCodeQuery,
      queryFilter: ({ input }: any) => {
        return {
          includes: input,
        };
      },
      autoComplete: {
        getResults: ({ queryData }: any) => {
          return (
            queryData &&
            queryData.uniqueRevCodes.nodes.length > 0 &&
            queryData.uniqueRevCodes.nodes
          );
        },
        meetsAutoCompleteRequirement: ({ input }: any) => {
          const acceptableChars = /^[0-9]*$/; // numeric
          const queryThreshold = 0;
          const meetsLengthReq = input?.length >= queryThreshold;
          const meetsAcceptableCharsReq = acceptableChars.test(input);
          return {
            result: meetsLengthReq && meetsAcceptableCharsReq,
            reason: !meetsAcceptableCharsReq
              ? `Invalid characters; only numbers allowed`
              : !meetsLengthReq
              ? `Please enter at least ${queryThreshold + 1} characters`
              : "",
          };
        },
        isCurrentAutoCompleteResultValid: ({
          input,
          lastQueryVariables,
        }: any) => {
          const last = lastQueryVariables?.includes || "";
          return (
            last.length > 0 &&
            input.length >= last.length &&
            input.startsWith(last)
          );
        },
      },
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY CLAIM TYPE ---
  */
  claimType: {
    displayName: "Claim Type",
    icon: <SnippetsOutlined />,
    options: () => {
      // a query can be added when this is expanded
      return [
        // hard coding this also makes hiding the spelling error easier
        {
          label: "Inpatient Hospital Claim",
          value: "Inpatient Hopital Claim",
        },
        {
          label: "Outpatient Hospital Claim",
          value: "Outpatient Hospital Claim",
        },
      ];
    },
    typeDef: {
      filterBuilder: (value: any) => ({ claimType: { equalTo: value } }),
      selectType: null,
    },
    tag: {
      tagKey: "claimType",
      tagText: (
        v: string | string[], // the value passes in the spelling error as well
      ) =>
        `Claim Type: ${v.includes("Outpatient") ? "Outpatient" : "Inpatient"}`,
      tagTooltipTitle: (v: string | string[]) =>
        `Claim Type: ${v.includes("Outpatient") ? "Outpatient" : "Inpatient"}`,
    },
    renderComponent: (props: any) => <SelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY CREATED AT ---
  */
  createdAtDate: {
    displayName: "Alaffia Receipt Date",
    icon: <FieldTimeOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => {
        const { RangePicker } = DatePicker;
        return <RangePicker {...props} />;
      },
      isValueValid: (v: any) => {
        return v !== null;
      },
      defaultValueGenerator: ({ createdAtDate }: any) => {
        return [
          getUTCDateObj(createdAtDate[0]),
          getUTCDateObj(createdAtDate[1]),
        ];
      },
      filterType: [
        { keyword: "createdAtDate", label: "Creation Date Range (UTC)" },
      ],
      /**
       * NOTE: if any issues with dates refer to
       * EN-1029 https://alaffiahealth.atlassian.net/browse/EN-1029?atlOrigin=eyJpIjoiZjA0NDA2NGExOTg5NDgwZWIxODViYTYzYjI0ZWY4MjkiLCJwIjoiaiJ9
       * @param {Dayjs[]} createdAtDate --> startDate, endDate date produced by DayJs via RangePicker that are consumed by Moment
       * @returns {string} GQL query filter on BatchClaim as a JS Object
       */
      filterBuilder: ({ createdAtDate: [startDate, endDate] }: any) => {
        // startDate, endDate are moment Objects or dateStrs -- in both cases it sets to Moment Obj
        let formattedStartDate = moment(startDate).utc().endOf("day").utc();
        let formattedEndDate = moment(endDate).utc().endOf("day").utc();

        // when range is the same date and user just wants one Date
        // we format the query as range between the start and end of that day
        if (formattedStartDate.diff(formattedEndDate) === 0) {
          formattedStartDate = moment(startDate).utc().startOf("day").utc();
          formattedEndDate = moment(endDate).utc().endOf("day").utc();
        }
        return {
          and: [
            {
              createdAt: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                greaterThanOrEqualTo: formattedStartDate._d,
              },
            },
            {
              createdAt: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                lessThanOrEqualTo: formattedEndDate._d,
              },
            },
          ],
        };
      },
    },
    tag: {
      tagKey: "createdAtDate",
      tagText: ({ createdAtDate: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Created At Date Range",
          startDate,
          endDate,
          "MMM D YY",
        );
      },
      tagTooltipTitle: ({ createdAtDate: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Created At Date Range",
          startDate,
          endDate,
          "ddd MMM Do YYYY",
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },

  /*
    --- SEARCH BY DATE OF SERVICE ---
  */
  dateOfService: {
    displayName: "Claim Date Of Service",
    icon: <CarryOutOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => {
        const { RangePicker } = DatePicker;
        return <RangePicker {...props} />;
      },
      isValueValid: (v: any) => {
        return v !== null;
      },
      defaultValueGenerator: ({ dateOfService }: any) => {
        return [
          getUTCDateObj(dateOfService[0]),
          getUTCDateObj(dateOfService[1]),
        ];
      },
      filterType: [
        { keyword: "dateOfService", label: "Date Of Service Range (UTC)" },
      ],
      /**
       * NOTE: if any issues with dates refer to
       * EN-1029 https://alaffiahealth.atlassian.net/browse/EN-1029?atlOrigin=eyJpIjoiZjA0NDA2NGExOTg5NDgwZWIxODViYTYzYjI0ZWY4MjkiLCJwIjoiaiJ9
       * @param {Dayjs[]} dateOfService --> startDate, endDate date produced by DayJs via RangePicker that are consumed by Moment
       * @returns {string} GQL query filter on BatchClaim as a JS Object
       */
      filterBuilder: ({ dateOfService: [startDate, endDate] }: any) => {
        // startDate, endDate are moment Objects or dateStrs -- in both cases it sets to Moment Obj
        let formattedStartDate = moment(startDate).utc().endOf("day").utc();
        let formattedEndDate = moment(endDate).utc().endOf("day").utc();

        // when range is the same date and user just wants one Date
        // we format the query as range between the start and end of that day
        if (formattedStartDate.diff(formattedEndDate) === 0) {
          formattedStartDate = moment(startDate).utc().startOf("day").utc();
          formattedEndDate = moment(endDate).utc().endOf("day").utc();
        }
        return {
          and: [
            {
              dateOfServiceStart: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                greaterThanOrEqualTo: formattedStartDate._d,
              },
            },
            {
              dateOfServiceEnd: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                lessThanOrEqualTo: formattedEndDate._d,
              },
            },
          ],
        };
      },
    },
    tag: {
      tagKey: "dateOfService",
      tagText: ({ dateOfService: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Date Of Service Range",
          startDate,
          endDate,
          "MMM D YY",
        );
      },
      tagTooltipTitle: ({ dateOfService: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Date Of Service Range",
          startDate,
          endDate,
          "ddd MMM Do YYYY",
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },
  /*
    --- SEARCH BY DATE OF ADMIT/DISCHARGE---
  */
  dateAdmitDischarge: {
    displayName: "Claim Date of Admission to Discharge",
    icon: <ScheduleOutlined />,
    typeDef: {
      rangeSelectComponent: (props: any) => {
        const { RangePicker } = DatePicker;
        return <RangePicker {...props} />;
      },
      isValueValid: (v: any) => {
        return v !== null;
      },
      defaultValueGenerator: ({ dateAdmitDischarge }: any) => {
        return [
          getUTCDateObj(dateAdmitDischarge[0]),
          getUTCDateObj(dateAdmitDischarge[1]),
        ];
      },
      filterType: [
        {
          keyword: "dateAdmitDischarge",
          label: "Date of Admission to Discharge (UTC)",
        },
      ],
      /**
       * NOTE: if any issues with dates refer to
       * EN-1029 https://alaffiahealth.atlassian.net/browse/EN-1029?atlOrigin=eyJpIjoiZjA0NDA2NGExOTg5NDgwZWIxODViYTYzYjI0ZWY4MjkiLCJwIjoiaiJ9
       * @param {Dayjs[]} dateAdmitDischarge --> startDate, endDate date produced by DayJs via RangePicker that are consumed by Moment
       * @returns {string} GQL query filter on BatchClaim as a JS Object
       */
      filterBuilder: ({ dateAdmitDischarge: [startDate, endDate] }: any) => {
        // startDate, endDate are moment Objects or dateStrs -- in both cases it sets to Moment Obj
        let formattedStartDate = moment(startDate).utc().endOf("day").utc();
        let formattedEndDate = moment(endDate).utc().endOf("day").utc();

        // when range is the same date and user just wants one Date
        // we format the query as range between the start and end of that day
        if (formattedStartDate.diff(formattedEndDate) === 0) {
          formattedStartDate = moment(startDate).utc().startOf("day").utc();
          formattedEndDate = moment(endDate).utc().endOf("day").utc();
        }
        return {
          and: [
            {
              dateAdmit: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                greaterThanOrEqualTo: formattedStartDate._d,
              },
            },
            {
              dateDischarge: {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                lessThanOrEqualTo: formattedEndDate._d,
              },
            },
          ],
        };
      },
    },
    tag: {
      tagKey: "dateAdmitDischarge",
      tagText: ({ dateAdmitDischarge: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Date of Admit to Discharge Range",
          startDate,
          endDate,
          "MMM D YY",
        );
      },
      tagTooltipTitle: ({ dateAdmitDischarge: [startDate, endDate] }: any) => {
        return getDisplayNameOfDateRange(
          "Date of Admit to Discharge Range",
          startDate,
          endDate,
          "ddd MMM Do YYYY",
        );
      },
    },
    renderComponent: (props: any) => <RangeSelectFilterComponent {...props} />,
  },
};

export { searchableFields };
