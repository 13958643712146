import { useContext } from "react";
import type { Logger } from "loglevel";

import { LogContext } from "./logProvider";

type LoggerEx = Logger & {
  css?: string;
};

export const useLog = (logName: string): LoggerEx => {
  const log: LoggerEx = useContext(LogContext).getLog(logName);
  return log;
};
