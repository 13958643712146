/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import type { FC } from "react";
import { css } from "@emotion/react";
import { Tag, Tooltip } from "antd";
import tw from "twin.macro";

import { AutodorIcon } from "../../../../misc/autodorIcon";
import { AuditFindingSeedType } from "../../../claimWorkspace/createAuditFinding/auditFindingUtil";
import type { FindingInfo } from "../../../claimWorkspace/itemizedBillTab/ibTable/types";
import { arrayHasLen } from "../../../util/array";

export interface DenialCodeTagsProps {
  findingInfos: FindingInfo[];
}

type UniqueInfo = FindingInfo & {
  count: number;
};

export const DenialCodeTags: FC<DenialCodeTagsProps> = ({ findingInfos }) => {
  if (!arrayHasLen(findingInfos)) {
    return null;
  }
  const uniqueInfos = findingInfos
    .filter((fi) => fi.accepted || fi.type === "suggestion")
    .reduce<Record<string, UniqueInfo>>((acc, fi) => {
      if (acc[fi.denialCode]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        acc[fi.denialCode].count++;
      } else {
        acc[fi.denialCode] = { count: 1, ...fi };
      }
      return acc;
    }, {});

  return (
    <>
      {Object.values(uniqueInfos).map(({ type, seedType, label, count }) => {
        const isUB = seedType === AuditFindingSeedType.UB_CLAIM_LINE;
        const c = isUB ? "geekblue" : "magenta";
        return (
          <Tooltip
            color={c}
            key={isUB ? "UB" + label : "IB" + label}
            title={`${isUB ? "UB" : "IB"} - ${label} ${
              count > 1 ? `(${count})` : ""
            }`}
          >
            <Tag
              color={c}
              css={css({
                ...tw`af-my-[1px] af-mx-[2px] af-text-xs`,
                ...(type === "suggestion"
                  ? tw`af-bg-antd-lt-gold-2! dark:af-bg-antd-dk-gold-6!`
                  : {}),
              })}
              icon={
                type === "suggestion" ? (
                  <span tw=" af-rounded-full ">
                    <AutodorIcon width={12}></AutodorIcon>
                  </span>
                ) : undefined
              }
            >
              <span tw="af-pl-0.5 af-align-middle">{label}</span>
            </Tag>
          </Tooltip>
        );
      })}
    </>
  );
};
