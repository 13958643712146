import type { ElementType } from "react";
import React from "react";
import { css } from "@emotion/react";
import type { SwitchProps } from "@headlessui/react";
import { Switch as HuiSwitch } from "@headlessui/react";
import tw from "twin.macro";

import { tailwindPreflight } from "../../components/app/design/tw-preflight";

/**
 * Wraps a headless-ui switch control to theme it, as an illustration (antd already has a 'switch')
 * however this pattern is one we may adopt in places (e.g. the search filter's server-based typeahead that's
 * been difficult to control for our needs like allowing paste while still limiting the selected options)
 * @param props - same as headless-ui doc
 */
export const Switch = (props: SwitchProps<ElementType>) => {
  return (
    <div css={css(tailwindPreflight)}>
      <HuiSwitch
        {...props}
        css={css([
          tailwindPreflight,
          props.checked
            ? tw`!af-bg-antd-lt-colorPrimaryActive dark:!af-bg-antd-dk-colorPrimaryActive`
            : tw`!af-bg-gray-400 dark:!af-bg-gray-200`,
          tw`af-relative af-inline-flex af-h-6 af-w-11 af-items-center af-rounded-full`,
        ])}
      >
        <span tw="af-sr-only af-translate-x-9">{props.name}</span>
        <span
          css={css([
            tailwindPreflight,
            props.checked ? tw`af-translate-x-6` : tw`af-translate-x-1`,
            tw`af-inline-block af-h-4 af-w-4 af-transform af-rounded-full af-bg-white af-transition`,
          ])}
        />
      </HuiSwitch>
    </div>
  );
};
