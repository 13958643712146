import { useEffect } from "react";
import type { PropsWithChildren } from "react";
import type { QueryClient } from "@tanstack/react-query";
import { QueryClientProvider } from "@tanstack/react-query";

import type {
  AlaffiaClient,
  AlaffiaClientExtraModules,
  AlaffiaClientFeatures,
} from "@alaffia-technology-solutions/client-sdk";

import { AlaffiaClientContext } from "./AlaffiaClient.context";

interface ClientSDKReactQueryProviderProps<
  TExtraModules extends AlaffiaClientExtraModules,
  TFeatures extends AlaffiaClientFeatures,
> extends PropsWithChildren {
  alaffiaClient: AlaffiaClient<TExtraModules, TFeatures>;
  queryClient: QueryClient;
}

export const ClientSDKReactQueryProvider = <
  TExtraModules extends AlaffiaClientExtraModules,
  TFeatures extends AlaffiaClientFeatures,
>({
  alaffiaClient,
  queryClient,
  children,
}: ClientSDKReactQueryProviderProps<TExtraModules, TFeatures>) => {
  useEffect(() => {
    const subscription = alaffiaClient.auth.loginStatus.subscribe(
      (loginStatus) => {
        const eventType = loginStatus.data?.type;
        if (
          eventType === "LogoutEvent" ||
          eventType === "SessionExpiredEvent"
        ) {
          queryClient.clear();
        } else if (eventType === "LoginSuccessResult") {
          void queryClient.invalidateQueries();
        }
      },
    );

    return () => subscription.unsubscribe();
  }, [alaffiaClient, queryClient]);

  return (
    <QueryClientProvider client={queryClient}>
      <AlaffiaClientContext
        value={{ alaffiaClient: alaffiaClient as unknown as AlaffiaClient }}
      >
        {children}
      </AlaffiaClientContext>
    </QueryClientProvider>
  );
};
