import { LoaderCircle } from "lucide-react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const Loader = ({ className }: { className?: string }) => {
  return (
    <LoaderCircle
      className={cn("af-animate-spin af-text-primary/60", className)}
    />
  );
};

export { Loader };
