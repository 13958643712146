export function dateToUtcLocaleDateString(
  date: Date | undefined | null,
  locale?: string,
): string {
  if (!date) {
    return "";
  }
  if (isNaN(date.getTime())) {
    return "";
  }
  return date.toLocaleDateString(locale, { timeZone: "UTC" });
}
