import type { ReactNode } from "react";
import { css } from "@emotion/react";
import { message, Tag, Tooltip, Typography } from "antd";

import { createAuditFindingError } from "../../../util/errors";
import type { DenialCodeTagMap, FindingUpdateErrorInfo } from "./util";
import { extractIbLineFindingError } from "./util";

const { Text } = Typography;

export const createDenialCodeRowTags = (tagMapData: DenialCodeTagMap) =>
  Object.values(tagMapData).map(
    ({ accepted, isUB, title, displayName, count }) => {
      const c = !accepted ? "lightgray" : isUB ? "geekblue" : "magenta";
      return (
        <Tooltip
          color={c}
          key={isUB ? "UB" : "IB" + displayName}
          title={`${isUB ? "UB" : "IB"} - ${title} ${
            count > 1 ? `(${count})` : ""
          }`}
        >
          <Tag color={c} css={css({ margin: "1px 2px" })}>
            {displayName}
          </Tag>
        </Tooltip>
      );
    },
  );

// indirection for antd message that swallows the promise - `void` vs `Promise returned from error is ignored`
const displayError = (msg: string | ReactNode, showForSeconds: number) =>
  void message.error(msg, showForSeconds);

/**
 * Create an error handling function for 'line' finding actions that return errors with the
 * `auditFinding.id` that's blocking the accept/create action, passing fn to interpret the line info for display
 * @param getErrorMessagesForFindingInfo
 */
export const createUpdateAuditFindingErrorHandler = (
  getErrorMessagesForFindingInfo: (
    findingErrorInfo: FindingUpdateErrorInfo,
  ) => (string | ReactNode)[],
) => {
  return (error: Error) => {
    const info = extractIbLineFindingError(error);
    if (info) {
      const findingMessages = getErrorMessagesForFindingInfo(info.findingInfo);
      if (findingMessages && findingMessages.length > 0) {
        displayError(
          <>
            <Text italic={true}>Failed to save findings - {info.message}:</Text>
            {findingMessages.map((findingMessage: string | ReactNode) => (
              <div>{findingMessage}</div>
            ))}
          </>,
          6,
        );
      }
    } else {
      displayError(createAuditFindingError(error), 4);
    }
  };
};
