/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RefObject } from "react";
import { useCallback, useMemo, useRef, useState } from "react";

import type { BatchClaim, Claim } from "../../../../gql/graphql";
import type { UUID } from "../createAuditFinding/auditFindingUtil";
import type { FindingEditorOp } from "./types";
import type { UseFindingViewModelProps } from "./viewController/useFindingViewController";

export interface FindingEditor {
  title: string;
  showModal: ({ operation }: { operation: FindingEditorOp }) => void;
  hideModal: () => void;
  isModalOpen: boolean;
  isReady: () => boolean;
  showInline: ({
    rowId,
    operation,
  }: {
    rowId: string;
    operation: FindingEditorOp;
  }) => void;
  hideInline: () => void;
  viewControllerConfig: {
    config: UseFindingViewModelProps;
    currentOperationRef: RefObject<FindingEditorOp | null>;
  };
}

interface UseFindingProps {
  claimId: UUID;
  batchClaim?: BatchClaim;
  claim?: Claim;
}

export const useFindingEditor = ({
  claimId,
  batchClaim,
  claim,
}: UseFindingProps): [FindingEditor, boolean, string | null] => {
  const currentValuesRef = useRef<any>();
  const currentOperationRef = useRef<FindingEditorOp | null>(null);

  // states
  const [isModalOpen, setModalOpen] = useState(false);
  const [inlineOpen, setInlineOpen] = useState<string | null>(null);
  const [_title, setTitle] = useState("Create Findings");

  const viewControllerConfig = useMemo(() => {
    return {
      config: {
        claimId,
        batchClaim,
        claim,
        currentValuesRef,
      },
      currentOperationRef,
    };
  }, [claimId, batchClaim, claim]);

  const showModal = useCallback<FindingEditor["showModal"]>(({ operation }) => {
    currentOperationRef.current = operation;
    setModalOpen(true);
  }, []);

  const hideModal = useCallback<FindingEditor["hideInline"]>(() => {
    setModalOpen(false);
  }, []);

  const showInline = useCallback<FindingEditor["showInline"]>(
    ({ rowId, operation }) => {
      currentOperationRef.current = operation;
      setInlineOpen(rowId);
    },
    [],
  );

  const hideInline = useCallback<FindingEditor["hideInline"]>(() => {
    // console.log('clearing vm config ', findingViewModel.clear());
    setInlineOpen(null);
  }, []);

  const isReady = useCallback<FindingEditor["isReady"]>(
    () => !!currentOperationRef.current && !!batchClaim && !!claim,
    [claim, batchClaim, claimId],
  );

  const findingEditor: FindingEditor = useMemo<FindingEditor>(
    () => ({
      get title() {
        return _title;
      },
      set title(title: string) {
        setTitle(title);
      },
      isModalOpen,
      showModal,
      hideModal,
      showInline,
      hideInline,
      isReady,
      viewControllerConfig,
    }),
    [viewControllerConfig],
  );
  return [findingEditor, isModalOpen, inlineOpen];
};
