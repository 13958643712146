import { z } from "zod";

export const File = z.object({
  id: z.string(),
  archived: z.boolean(),
  fileName: z.string(),
  readyToSearch: z.boolean(),
  createdAt: z.string(),
  modifiedAt: z.string(),
  size: z.number().nullable().optional(),
});
export type File = z.infer<typeof File>;

export const Directory = z.object({
  id: z.string().nullable(),
  name: z.string(),
  createdAt: z.string(),
  modifiedAt: z.string(),
});
export type Directory = z.infer<typeof Directory>;

export const DirectoryItem = z.union([Directory, File]);
export type DirectoryItem = z.infer<typeof DirectoryItem>;

export const FilePathItem = z.object({
  id: z.string().nullable(),
  name: z.string(),
});
export type FilePathItem = z.infer<typeof FilePathItem>;

const TargetTypeSchema = z.union([z.literal("case"), z.literal("directory")]);

export const FilePrepareInput = z.object({
  id: z.string().nullable(),
  name: z.string(),
  size: z.number(),
  type: TargetTypeSchema,
});
export type FilePrepareInput = z.infer<typeof FilePrepareInput>;

export const FileByIdInput = z.object({ id: z.string() });
export type FileByIdInput = z.infer<typeof FileByIdInput>;

export const SearchInFileInput = z.object({
  fileId: z.string(),
  query: z.string(),
});
export type SearchInFileInput = z.infer<typeof SearchInFileInput>;

export const FilePathInput = z.object({
  id: z.string().nullable(),
  type: TargetTypeSchema,
});
export type FilePathInput = z.infer<typeof FilePathInput>;

export const FilesByCaseIdInput = z.object({ caseId: z.string() });
export type FilesByCaseIdInput = z.infer<typeof FilesByCaseIdInput>;

export const GetDirectoryInput = z.object({ id: z.string().nullable() });
export type GetDirectoryInput = z.infer<typeof GetDirectoryInput>;

export const DirectoryCreateInput = z.object({
  name: z.string().trim().min(1),
  parentDirectoryId: z.string().nullable(),
});
export type DirectoryCreateInput = z.infer<typeof DirectoryCreateInput>;

export const DirectoryRemoveInput = z.object({ id: z.string() });
export type DirectoryRemoveInput = z.infer<typeof DirectoryRemoveInput>;

export const FileUpdateInput = z.object({
  id: z.string(),
  fileName: z.string().trim().min(1).optional(),
  parentDirectoryId: z.string().nullable().optional(),
  signedUrl: z.string().nullable().optional(),
  size: z.number().nullable().optional(),
});
export type FileUpdateInput = z.infer<typeof FileUpdateInput>;

export const DirectoryUpdateInput = z.object({
  id: z.string(),
  name: z.string().trim().min(1).optional(),
  parentDirectoryId: z.string().nullable().optional(),
});
export type DirectoryUpdateInput = z.infer<typeof DirectoryUpdateInput>;

export const ConfirmFileUploadSuccessInput = z.object({
  fileId: z.string(),
  fileUploadId: z.string(),
});
export type ConfirmFileUploadSuccessInput = z.infer<
  typeof ConfirmFileUploadSuccessInput
>;
