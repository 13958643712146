/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import accounting from "accounting-js";
import moment from "moment-timezone";

const confidences = {
  Auto: 80,
  "Semi-Auto": 50,
  "Complex Review": 20,
};

const confidenceToString = (confidenceString: string) => {
  const confidence = parseFloat(confidenceString);

  switch (true) {
    case confidence >= confidences.Auto:
      return "Auto";
    case confidence >= confidences["Semi-Auto"]:
      return "Semi-Auto";
    case confidence >= confidences["Complex Review"]:
      return "Complex Review";
    default:
      return "Complex Review";
  }
};

const auditTypeLabels = {
  EB1: "EB1",
  MUE: "MUE",
  NCCI_MUE: "NCCI MUE",
  NCCI_PTP: "NCCI PTP",
  AGE_MODIFIER: "Age to CPT/HCPCS violation",
  GENDER_MODIFIER: "Gender to CPT/HCPCS violation",
  ADD_ON_CODE: "Add on Code",
  EM: "E/M Leveling",
  PRICING: "Pricing Discrepancy",
  UNITS: "Units Discrepancy",
  OTHER: "Other",
  CPT_ICD10: "CPT to ICD10",
  CPT_MOD: "CPT Modifier",
};

const inputPropsCurrencyFormatter = {
  formatter: (num: string) => {
    if (num === "Infinity") {
      return "";
    }
    // DOC: this is a possible (interim) fix for
    // by default, accounting.formatMoney assumes 2 precision, which in practice means
    // entering 1 will return 1.00 -- while this is correct for currency, it means that
    // while the user is typing say 1100, the value changes from 1 to 1.00 and the cursor
    // moves to the end so the user ends up entering 1001.00... not great ux...
    // there's also issues with the precision handling in that it rounds rather than floors
    // so entering 1.446 results in 1.45 -- which isn't resolved by this tentative fix, at least
    // in all cases (sometimes some input gets through presumably due to timing and re-renders)
    // there are also scenarios where it leaves more numbers than precision in the field and rounds onblur
    const maxPrecision = 2;
    const precision = Math.min(
      maxPrecision,
      String(num).split(".")[1]?.length ?? 0,
    );
    return accounting.formatMoney(num, { precision });
  },
  parser: accounting.unformat,
  precision: 2,
};

// Takes an object with normalized keys
// {
//    key1.field1: value1,
//    key1.field2.subField1: value2,
//    key1.field3.subField1: value3,
//    key1.field3.subField2: value4,
//    key2.field1: value5,
//    key2.field2: value6,
// }
// and converts it to a nested object
// {
//   key1: {
//     field1: value1,
//     field2: {
//       subField1: value2
//     },
//     field3: {
//       subField1: value3,
//       subField1: value4
//     },
//   }
//   key2: {
//     field1: value5,
//     field2: value6
//   }
// }
const denormalize = (obj: any) => {
  const denormalized = {};

  for (const [normalizedKey, value] of Object.entries(obj)) {
    // split the normalized key into a path, e.g. key1, field1, subField1
    const keys = normalizedKey.split(".");
    // traverse the path
    keys.reduce((nested: any, key, i) => {
      if (!(key in nested)) {
        nested[key] =
          i === keys.length - 1
            ? value // assign the value at the end of the key path
            : {}; // assign an empty object if no existing object exists
      }
      // continue traversing the key path
      return nested[key];
    }, denormalized);
  }

  return denormalized;
};

const dollarStatProps = {
  prefix: "$",
  precision: 2,
};

/**
 *
 * @param {string} start Date string
 * @param {string} end Date string
 * @returns {string} Date String
 */
const dateString = (start: string, end: string): string =>
  `${moment.utc(start).format("MM/DD/YY")}${
    end && start !== end ? ` - ${moment.utc(end).format("MM/DD/YY")}` : ""
  }`;

export {
  auditTypeLabels,
  confidenceToString,
  dateString,
  denormalize,
  dollarStatProps,
  inputPropsCurrencyFormatter,
};
