import * as React from "react";

import { useLocalStorage } from "@alaffia-technology-solutions/hooks";

import type { FileEntity } from "./types";

interface UseFilesSelectionProps {
  files: FileEntity[] | undefined;
  caseId: string;
}

export function useChatboxFilesSelection({
  files,
  caseId,
}: UseFilesSelectionProps) {
  const [fileSelectionState, setFileSelectionState] = useLocalStorage<
    Record<string, boolean>
  >(`chatbox-file-selection-state-${caseId}`, {});

  const selectedFileIds = React.useMemo(() => {
    return files
      ? files
          .filter((file) => fileSelectionState[file.id] === true)
          .map((file) => file.id)
      : [];
  }, [files, fileSelectionState]);

  const selectedFiles = React.useMemo(
    () => files?.filter((file) => selectedFileIds.includes(file.id)) ?? [],
    [files, selectedFileIds],
  );

  const handleFileSelection = React.useCallback(
    (fileId: string, selected: boolean) => {
      setFileSelectionState((prev) => ({
        ...prev,
        [fileId]: selected,
      }));
    },
    [setFileSelectionState],
  );

  const isFileSelectionValid = selectedFileIds.length > 0;

  const callOutFile = React.useCallback(
    (fileId: string) => {
      setFileSelectionState((prev) => ({
        ...prev,
        [fileId]: true,
      }));
    },
    [setFileSelectionState],
  );

  const clearFileSelection = React.useCallback(() => {
    if (files) {
      const clearedState = files.reduce(
        (acc, file) => {
          acc[file.id] = false;
          return acc;
        },
        {} as Record<string, boolean>,
      );
      setFileSelectionState(clearedState);
    }
  }, [files, setFileSelectionState]);

  React.useEffect(() => {
    if (files) {
      const currentFileIds = files.map((file) => file.id);
      setFileSelectionState((prev) => {
        const newState = { ...prev };
        Object.keys(newState).forEach((id) => {
          if (!currentFileIds.includes(id)) {
            delete newState[id];
          }
        });
        return newState;
      });
    }
  }, [files, setFileSelectionState]);

  return {
    selectedFiles,
    selectedFileIds,
    handleFileSelection,
    clearFileSelection,
    callOutFile,
    isFileSelectionValid,
  };
}
