import { IconExclamationMark, IconSend } from "@tabler/icons-react";

import {
  Button,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@alaffia-technology-solutions/ui";

import { useChatbox } from "./ChatboxProvider";

export type DefaultChatboxSubmitButtonProps = React.ComponentPropsWithoutRef<
  typeof Button
>;

export function DefaultChatboxSubmitButton({
  disabled,
  children,
  ...props
}: DefaultChatboxSubmitButtonProps) {
  const { isEditorEmpty } = useChatbox();

  return (
    <Button
      aria-label="Send"
      type="submit"
      variant="secondary"
      size="icon"
      className="disabled:af-bg-gray-300 disabled:af-opacity-100"
      disabled={disabled ?? isEditorEmpty}
      {...props}
    >
      {children ?? <IconSend className="af-size-4" />}
    </Button>
  );
}

export function InvalidFileContextChatboxSubmitButton() {
  return (
    <HoverCard openDelay={0}>
      <HoverCardTrigger className="af-block">
        <DefaultChatboxSubmitButton
          className="disabled:af-bg-red-800 disabled:af-text-white"
          disabled
        >
          <IconExclamationMark className="af-size-4" />
        </DefaultChatboxSubmitButton>
      </HoverCardTrigger>
      <HoverCardContent>
        <p>At least one file must be selected to send a message.</p>
      </HoverCardContent>
    </HoverCard>
  );
}
