/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import type { Logger } from "loglevel";
import _log from "loglevel";
import type { Observable } from "rxjs";
import { BehaviorSubject, filter, Subject, take, timeout } from "rxjs";
import { v4 } from "uuid";

import type { IPCDataType } from "./common";
import {
  getClientReceiverBroadcastChannelName,
  SW_RECEIVER_CHANNEL_NAME,
} from "./common";

export const log: Logger & {
  cCli: string;
} = _log.getLogger("swIpc") as Logger & {
  cCli: string;
};
log.cCli = "color: blue;";

export interface SignedUrlUpdaterResponse {
  documentId: string;
  url: string;
  expires: number;
}
export type SignedUrlUpdater = (
  documentId: string,
) => Promise<SignedUrlUpdaterResponse>;

/**
 * Stub exposing methods the client page might call (via IPC) in the service worker
 */
export class SWClient {
  private _broadcastChannel: BroadcastChannel | null = null;
  private _swBroadcastChannel = new BroadcastChannel(SW_RECEIVER_CHANNEL_NAME);
  private _readySubject = new BehaviorSubject(false);
  private _ready = false;
  private _clientId: string | null = null;
  private _instanceId: string = v4();

  private readonly _subject: Subject<IPCDataType> = new Subject<IPCDataType>();
  private readonly _observable: Observable<IPCDataType> =
    this._subject.asObservable();

  // TODO TODO TODO IMPLEMENT READY CHECKS....

  // external:
  private _signedUrlUpdater: SignedUrlUpdater | null = null;

  constructor() {
    log.trace("%cswClient constructor", log.cCli);
    this.registerListeners(); //.then(r => this.ready = r);
  }

  registerSignedUrlUpdater = (signedUrlUpdater: SignedUrlUpdater) => {
    this._signedUrlUpdater = signedUrlUpdater;
  };

  removeSignedUrlUpdater = () => (this._signedUrlUpdater = null);

  getReadyPromise = () => {
    log.trace("%cswClient call getReadyPromise", log.cCli);
    return new Promise((resolve) =>
      this._readySubject
        .pipe(
          filter((b) => b),
          take(1),
        )
        .subscribe((r) => resolve(r)),
    );
  };

  get messageObservable() {
    return this._observable;
  }

  private isForInstance = (recd: IPCDataType) => {
    if (recd.tx.clientId !== this._clientId) {
      return false;
    }
    if (recd.tx.instanceId && recd.tx.instanceId !== this._instanceId) {
      return false;
    }
    return true;
  };

  /**
   * indicates an inbound request (for this instance) that isn't a response to
   * an outbound request to the sw -- i.e. the service worker invoking us
   */
  private isInbound = (recd: IPCDataType) => {
    return this.isForInstance(recd) && !recd.tx.operationId;
  };

  clearUserData = async () =>
    fetch("/ala-sw/___clearUserData")
      .then((res) => {
        if (res.ok) {
          log.debug("%c cleared sw user data", log.cCli);
          return true;
        } else {
          const msg = "Failed to clear user data";
          log.error(msg, res);
          return false;
        }
      })
      .catch((e) => {
        const msg = "Failed to clear user data - request rejected...";
        console.error(msg, e);
        return false;
      });

  private getClientId = async () => {
    const errMsg = `The Document Service worker failed to respond, PDF session timeouts may not be handled correctly.
Reloading with 'shift+reload' may temporarily suspend the worker.  
A message 'Document Service worker loaded' may follow indicating the functionality was restored.`;

    return fetch("/ala-sw/___getClientId")
      .then((res) => {
        if (res.ok) {
          const _clientId = res.headers.get("clientId");
          if (!_clientId) {
            log.warn(errMsg, res);
            // throw new Error(errMsg);
          }
          log.debug("%c service worker loaded: ", log.cCli, _clientId);
          return _clientId;
        } else {
          log.warn(errMsg, res);
          // throw new Error(errMsg);
        }
      })
      .catch((e) => {
        log.warn(errMsg, e);
        return null;
      });
  };

  private prepareClient = async () => {
    const _clientId = await this.getClientId();
    log.trace("%c client: got client id", log.cCli);

    if (_clientId) {
      if (_clientId !== this._clientId) {
        this._clientId = _clientId;
        this._broadcastChannel = new BroadcastChannel(
          getClientReceiverBroadcastChannelName(_clientId),
        );
        this._broadcastChannel.addEventListener("message", this.onMessage);
        this._ready = true;
        log.trace("%c client: call ready true", log.cCli);
        return true;
      }
    } else {
      log.trace("%c no client id, clearing broadcast channel", log.cCli);
      this._broadcastChannel = null;
      return false;
    }
    return false;
  };

  private registerListeners = () => {
    void navigator.serviceWorker.ready.then(() =>
      this.prepareClient().then((r) => this._readySubject.next(r)),
    );

    navigator.serviceWorker.addEventListener(
      "controllerchange",
      (event: Event) => {
        log.debug("%c client: sw changed", log.cCli, event);
        void this.prepareClient().then((r) => this._readySubject.next(r));
      },
    );
    this._observable
      .pipe(filter((e) => this.isInbound(e)))
      .subscribe((data: IPCDataType) => {
        switch (data.type) {
          case "getUpdatedSignedUrl":
            if (this._signedUrlUpdater) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              this._signedUrlUpdater(data.payload.documentId)
                .then((inf) => {
                  if (inf) {
                    this.sendMessage(data.type + "Response", {
                      ...data.payload,
                      status: "success",
                      ...inf,
                    });
                  }
                })
                .catch((e) => {
                  console.log("Signed URL update failed", e);
                  this.sendMessage(data.type + "Response", {
                    ...data.payload,
                    status: "failed",
                    reason: e,
                  });
                });
              break;
            }
            this.sendMessage(data.type + "Response", {
              ...data.payload,
              status: "failed",
              reason: "No getUpdatedSignedUrl handler registered",
            });

            break;
          default:
            log.info(`%cUnhandled inbound request: ${data.type}`, data);
        }
      });
  };

  private onMessage = (event: MessageEvent) => {
    log.debug(`%ccli got message (${event.data.version})`, log.cCli, event);
    this._subject.next(event.data);
  };

  getTxIds = (operationId?: string) => {
    const clientId = this._clientId;
    if (!clientId) {
      throw new Error("Service Worker - ClientId handshake has not completed;");
    }
    return {
      clientId,
      instanceId: this._instanceId,
      ...(operationId ? { operationId } : {}),
    };
  };

  private createFnFilter = (type: string, operationId: string | undefined) => {
    return (recd: IPCDataType) => {
      if (!this.isForInstance) {
        return false;
      }
      if (type !== recd.type) {
        return false;
      }
      if (!!operationId && operationId !== recd.tx.operationId) {
        return false;
      }
      return true;
    };
  };

  private sendMessage = (type: string, payload: Record<string, unknown>) => {
    const tx = this.getTxIds(v4());
    const message: IPCDataType = { type, tx, payload };
    this._swBroadcastChannel.postMessage(message);
    return message;
  };

  getVersion = async () => {
    return new Promise((resolve, reject) => {
      const sent = this.sendMessage("getVersion", {});
      this._observable
        .pipe(
          filter(this.createFnFilter(sent.type, sent.tx.operationId)),
          timeout(15000),
          take(1),
        )
        .subscribe({
          next: (answer) => resolve(answer),
          error: (error) => reject(error),
        });
    });
  };

  isSignedUrlRewriteEnabled = async () => {
    return new Promise((resolve, reject) => {
      const sent = this.sendMessage("isSignedUrlRewriteEnabled", {});
      this._observable
        .pipe(
          filter(this.createFnFilter(sent.type, sent.tx.operationId)),
          timeout(15000),
          take(1),
        )
        .subscribe({
          next: (answer) => resolve(answer?.payload?.isSignedUrlRewriteEnabled),
          error: (error) => reject(error),
        });
    });
  };

  setSignedUrlRewriteEnabled = async (allowRewrite: boolean) => {
    return new Promise((resolve, reject) => {
      const sent = this.sendMessage("setSignedUrlRewriteEnabled", {
        allowRewrite,
      });
      this._observable
        .pipe(
          filter(this.createFnFilter(sent.type, sent.tx.operationId)),
          timeout(15000),
          take(1),
        )
        .subscribe({
          next: (answer) =>
            resolve(answer?.payload?.setSignedUrlRewriteEnabled),
          error: (error) => reject(error),
        });
    });
  };
}
