/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-empty-function */
import { get, isNaN, isNil, min, omitBy, pick } from "lodash";
import type { OperationResult } from "urql";

import { applyTemplate } from "../../../../../../common/templates/handlebarsTemplateUtil";
import type { ItemizedBillLine } from "../../../../../../gql/graphql";
import type { DenialCode } from "../../../../queries/denialCodes/useDenialCodes";
import { autodorToFindingConfig } from "../../../../shared/components/finding/autodorDetails/autodorToFindingConfig";
import {
  getGenericRuleFromAutodorMetadata,
  getRuleFromAutodorMetadata,
  getRuleTypeFromAutodorMetadata,
} from "../../../../shared/components/finding/autodorFindingUtils";
import { arrayHasLen } from "../../../../util/array";
import type { FindingAdjustments } from "../../../../util/findingAdjustments";
import {
  areIbLinesEquivalent,
  calculateAdjustmentAmount,
  calculateDiscrepantAmount,
  findingAdjustmentsFromIbLines,
} from "../../../../util/findingAdjustments";
import { isSet } from "../../../../util/isSet";
import { orZero } from "../../../../util/orZero";
import {
  AuditFindingSeedType,
  getValidatedIbLineAdjustments,
} from "../../../createAuditFinding/auditFindingUtil";
import type { FindingModel } from "../../model/finding";
import type {
  AutodorFinding,
  CreateAuditFindingValuesInput,
  CreateIbLineFindingsInput,
  CreateIbLineFindingsInputValue,
  Finding,
  VersionAuditFindingsInput,
} from "../../types";
import { isConvertAutodorFindingOp, isReviewFindingOp } from "../../types";
import type {
  EditFindingAdjustments,
  EditFindingState,
  EditFindingStrategy,
  EditFindingStrategyControllerCommands,
  EditFindingValues,
  FindingInput,
} from "../types";
import { ibLineToBcliInput } from "../viewControllerUtils";

export class IbEditFindingStrategy implements EditFindingStrategy {
  constructor() {}

  /**
   * generates default field values
   */
  getFieldDefaults = () => ({
    metadata: {
      type: AuditFindingSeedType.IB_CLAIM_LINE,
    },
    auditFindingValues: {
      improperPaymentUnitsCharged: 1,
      improperPaymentCost: 0,
      improperPaymentReason: undefined,
    },
  });

  /**
   * Determines if a finding was in 'repricing' mode based on finding data
   * @param finding
   * @param paymentRate
   */
  getRepricingInfo = (finding: Finding, paymentRate: number) => {
    // improvement: this seems frail... repricing IPC is only a UI feature
    //  but it's worth some thought whether we only want to know in the UI
    //  because the IPC doesn't match the calculation based on the charge,
    //  units, and payment rate...
    const isRepricedBilledAmt =
      isSet(finding?.findingItemizedBillData?.originalBilledAmount) &&
      finding?.findingItemizedBillData?.originalBilledAmount !==
        finding?.findingItemizedBillData?.unitCharge;

    const calcDiscrepant = calculateAdjustmentAmount(
      calculateDiscrepantAmount({
        improperPaymentUnitsCharged: finding?.improperPaymentUnitsCharged ?? 1,
        amountCharged: finding?.findingItemizedBillData?.unitCharge ?? 0,
        unitsBilled: finding?.findingItemizedBillData?.units ?? 1, // correct?
      }) ?? 0,
      paymentRate,
    );

    const isRepricedIpc = isSet(paymentRate)
      ? finding?.improperPaymentCost !== calcDiscrepant
      : false;
    const isRepricing = isRepricedBilledAmt || isRepricedIpc;
    return { isRepricing, isRepricedBilledAmt, isRepricedIpc };
  };

  /**
   * Extracts existing/initial form values from a finding
   * @param finding
   * @param defaultValues
   * @param state
   */
  getFieldInitialValuesFromExistingFinding = (
    finding: Finding,
    defaultValues: Partial<EditFindingValues>,
    state: Partial<EditFindingState>,
  ) => {
    const pyRate = state.paymentRate!;
    const { isRepricing, isRepricedBilledAmt, isRepricedIpc } =
      this.getRepricingInfo(finding, pyRate);

    return {
      ...defaultValues,
      metadata: {
        ...defaultValues.metadata,
        batchClaimLineId: finding.findingItemizedBillData?.batchClaimLineId,
        isRepricing,
        ...(isRepricedBilledAmt || isRepricedIpc
          ? {
              amountChargedOverride:
                finding?.findingItemizedBillData?.unitCharge,
            }
          : {}),
        improperPaymentCostOverride: finding?.improperPaymentCost,
      },
      auditFindingValues: {
        ...defaultValues.auditFindingValues,
        auditFindingRuleType: finding.ruleType,
        improperPaymentUnitsCharged: finding.improperPaymentUnitsCharged,
        improperPaymentReason: finding.improperPaymentReason,
        improperPaymentCost: finding.improperPaymentCost,
      },
    };
  };

  /**
   * Extracts existing/initial form values from an Autodor suggestion
   * @param finding
   * @param defaultValues
   * @param state
   */
  getFieldInitialValuesFromAutodorSuggestion = (
    finding: AutodorFinding,
    defaultValues: Partial<EditFindingValues>,
    state: Partial<EditFindingState>,
  ) => {
    const denialCode: DenialCode | undefined =
      state.denialCodes?.byKey[finding.denialCode];

    const metadata = finding.autodorMetadata;

    const strTemplate: string =
      get(
        autodorToFindingConfig,
        `${getRuleTypeFromAutodorMetadata(metadata)}.rationale`,
      ) ?? autodorToFindingConfig.genericRule.rationale;

    const rule =
      getRuleFromAutodorMetadata(metadata) ??
      getGenericRuleFromAutodorMetadata(metadata);

    const rationale = applyTemplate(strTemplate, { finding, rule, denialCode });

    return {
      ...defaultValues,
      auditFindingValues: {
        ...defaultValues.auditFindingValues,
        auditFindingRuleType: finding.denialCode,
        improperPaymentUnitsCharged: finding.improperUnits,

        improperPaymentReason: rationale,
      },
    };
  };

  /**
   * Updates the per-IB-line array of adjustment values (e.g. IPC, units, billed amt)
   * @param changedValues
   * @param allValues
   * @param previousAdjustmentState
   * @param fullState
   */
  private updateAdjustmentInfo = ({
    changedValues,
    allValues,
    previousAdjustmentState,
    fullState,
  }: {
    changedValues: Partial<EditFindingValues>;
    allValues: Partial<EditFindingValues>;
    previousAdjustmentState: EditFindingAdjustments;
    fullState: EditFindingState;
  }): EditFindingAdjustments => {
    const { metadata, auditFindingValues } = allValues;

    const ibLines = fullState.operation?.ibData ?? [];
    const hasIbLines = arrayHasLen(ibLines);
    const maxUnits = orZero(min(ibLines?.map((x) => x.units)));
    const ibLinesAreEquivalent = hasIbLines
      ? areIbLinesEquivalent(ibLines)
      : true;

    const isRepricing = metadata?.isRepricing ?? false;
    const paymentRate = fullState.paymentRate ?? 0;

    const improperPaymentUnitsCharged = orZero(
      auditFindingValues?.improperPaymentUnitsCharged,
    );

    const { amountChargedOverride } = isRepricing ? metadata : ({} as any); // un-define overrides if no longer repricing

    const prevIpcOverride = arrayHasLen(
      previousAdjustmentState?.ibLineAdjustments,
    )
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        previousAdjustmentState.ibLineAdjustments[0].overrides
          ?.improperPaymentCost
      : undefined;
    const ipcOverride = !isRepricing
      ? undefined // if we're not repricing, don't override, it defeats the attempt to clear in the ui code
      : changedValues?.metadata?.improperPaymentCostOverride ?? prevIpcOverride;

    const adjustments: EditFindingAdjustments = {
      isRepricing,
      canReprice: ibLinesAreEquivalent,
      cappedUnits: true,
      hasIbLines,
      maxUnits,
      ibLinesAreEquivalent,
      improperPaymentUnitsCharged,
      paymentRate,
      ibLineAdjustments: findingAdjustmentsFromIbLines({
        paymentRate,
        ibLines,
        improperPaymentUnitsCharged,
        improperPaymentCostOverride: ipcOverride,
        amountChargedOverride,
      }),
    };
    return adjustments;
  };

  /**
   * Triggers update of the IB line adjustment values when certain form values change
   * @param controllerCommands
   * @param changedValues
   * @param allValues
   */
  updateAdjustmentInfoIfNeeded = (
    controllerCommands: EditFindingStrategyControllerCommands,
    changedValues: any,
    allValues: any,
  ) => {
    const TRIGGERS = [
      "metadata.batchClaimLineId",
      "metadata.isRepricing",
      "auditFindingValues.improperPaymentUnitsCharged",
      "metadata.amountChargedOverride",
      "metadata.improperPaymentCostOverride",
    ];
    if (Object.keys(pick(changedValues, TRIGGERS)).length > 0) {
      controllerCommands.setState(
        "adjustments",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        (prev: EditFindingAdjustments, fullState: EditFindingState) => {
          const adjustments = this.updateAdjustmentInfo({
            changedValues,
            allValues,
            previousAdjustmentState: prev,
            fullState,
          });
          return adjustments;
        },
      );
    }
  };

  /**
   * Observer called by controller when form values change, allows IB-strategy-specific updates
   * @param controllerCommands
   * @param changedValues
   * @param allValues
   */
  onValuesChange: EditFindingStrategy["onValuesChange"] = (
    controllerCommands,
    changedValues,
    allValues,
  ): void => {
    this.updateAdjustmentInfoIfNeeded(
      controllerCommands,
      changedValues,
      allValues,
    );
  };

  /**
   * Builds 'create' mutation input from intermediate save input
   * @param state
   * @param editFindingValues
   * @param inputValues
   */
  private prepareCreateMutation = (
    state: EditFindingState,
    editFindingValues: EditFindingValues,
    inputValues: CreateIbLineFindingsInputValue[],
  ): CreateIbLineFindingsInput | null => {
    const { authorId, batchClaimId, batchClaimLineId } = state;
    if (!authorId || !batchClaimId || !batchClaimLineId) {
      console.error("Missing IDs", {
        authorId,
        batchClaimId,
        batchClaimLineId,
      });
      throw new Error("Missing required ids to create audit finding ");
    }
    // Create new finding
    return inputValues.length < 1
      ? null
      : {
          authorId,
          batchClaimId,
          batchClaimLineId,
          values: inputValues,
        };
  };

  /**
   * Builds 'version' mutation input from intermediate save input
   * @param state
   * @param editFindingValues
   * @param inputValues
   */
  private prepareVersionMutation = (
    state: EditFindingState,
    editFindingValues: EditFindingValues,
    inputValues: CreateIbLineFindingsInputValue[],
  ): VersionAuditFindingsInput | null => {
    const reviewFindingOp = isReviewFindingOp(state.operation)
      ? state.operation
      : null;
    if (!reviewFindingOp) {
      throw new Error(
        "Internal error: Attempt to review finding but no existing finding version found in state",
      );
    }
    const auditFindingId = reviewFindingOp.finding.id ?? null;
    const batchClaimLineId = state.batchClaimLineId;
    if (!auditFindingId || !batchClaimLineId) {
      throw new Error(
        "IbEditFindingStrategy::save: cannot version finding without finding & ub line IDs",
      );
    }
    const inputKeys = [
      "auditFindingRuleType",
      "autodorFindingId",
      "autodorFindingS3Key",
      "confidence",
      "improperPaymentCost",
      "improperPaymentReason",
      "improperPaymentUnitsCharged",
      "metaDataAocPrimaryCode",
      "metaDataEmCorrectLevel",
      "metaDataEmOriginalLevel",
      "metaDataNcciMueAjudicationValue",
      "metaDataNcciMueOhpMueValue",
      "metaDataNcciPtpCode",
      "metaDataNcciPtpModifier",
      "metaDataPricingCorrectPrice",
      "metaDataUnitsCorrectUnits",
    ];
    const existingFindingValues = omitBy(
      pick(reviewFindingOp.finding, inputKeys),
      isNil,
    );
    return inputValues.length < 1
      ? null
      : {
          authorId: state.authorId!,
          auditFindingReviewValues: inputValues.map(
            ({ auditFindingValues, batchClaimLineItemValues }) => ({
              auditFindingId,
              batchClaimLineId,
              auditFindingValues: {
                ...(existingFindingValues as any), // TODO maybe/null handling / type conversion
                ...auditFindingValues,
              },
              batchClaimLineItemValues,
            }),
          ),
        };
  };

  /**
   * Builds the common, intermediate input for 'create finding' and
   * 'version finding' mutation from the form values & current state
   * @param editFindingValues
   * @param state
   */
  createMutationInput = (
    editFindingValues: EditFindingValues,
    state: EditFindingState,
  ): FindingInput | null => {
    // generate the mutation for the operation... separate from calling the mutation
    // so that tests may call the main controller's create input function and get the
    // intended payload without handling mocking URQL client which gets complex
    // with TS + renderHooks tests...

    // 1. do all the common stuff for both Create and Review,
    // 2. do specific stuff for create, review, including correct query input variable structure

    //  ---- #1 common ----
    const isReview = isReviewFindingOp(state.operation);

    // Validate key required values

    // Rev Code / BCL id
    const revCode = state?.revCode;
    if (!revCode) {
      console.error("No revCode/batchClaimLine found for batchClaimLineId", {
        bcliFromRevCodeField: editFindingValues?.metadata?.batchClaimLineId,
      });
      throw new Error("No revCode found for batchClaimLineId");
    }

    // Denial Code (auditFindingRuleType)
    const auditFindingRuleType =
      editFindingValues.auditFindingValues?.auditFindingRuleType;
    if (!auditFindingRuleType) {
      throw new Error("Invalid Rule Type");
    }

    // Improper Units
    const improperPaymentUnitsChargedStr =
      editFindingValues.auditFindingValues?.improperPaymentUnitsCharged;
    if (
      !improperPaymentUnitsChargedStr ||
      isNaN(Number(improperPaymentUnitsChargedStr))
    ) {
      throw new Error("Invalid Improper Payment Units");
    }
    const improperPaymentUnitsCharged = Number(improperPaymentUnitsChargedStr);

    // Rationale (improperPaymentReason
    const improperPaymentReason =
      editFindingValues.auditFindingValues?.improperPaymentReason;
    if (!improperPaymentReason) {
      throw new Error("Rationale must be set");
    }

    // IPC - not validated, for IB it comes from adjustment, just default 0
    const improperPaymentCostStr =
      editFindingValues.auditFindingValues?.improperPaymentCost;
    // IPC will be zero for IB -- overridden with adjustment amount below
    const improperPaymentCost = orZero(improperPaymentCostStr);

    const auditFindingValues: CreateAuditFindingValuesInput = {
      ...editFindingValues.auditFindingValues,
      auditFindingRuleType,
      improperPaymentUnitsCharged,
      improperPaymentCost,
      improperPaymentReason,
      ...(isConvertAutodorFindingOp(state.operation)
        ? {
            autodorFindingId: state.operation.autodorFinding.id,
            autodorFindingS3Key:
              state.operation.autodorFinding?.s3Key ?? undefined,
            confidence: 25,
          }
        : { confidence: 50 }),
    };

    // Additional data for IB findings
    const ibLines = state.operation?.ibData;
    const adjustments = state?.adjustments;
    const adjustmentLines = adjustments?.ibLineAdjustments;

    // Loop over input lines and create appropriate finding mutation inputs for each
    if (
      arrayHasLen<ItemizedBillLine>(ibLines) &&
      arrayHasLen<FindingAdjustments>(adjustmentLines) &&
      revCode
    ) {
      const inputValues: CreateIbLineFindingsInputValue[] = ibLines.map(
        (ibLine) => {
          const {
            improperPaymentUnitsCharged,
            improperPaymentCost,
            amountCharged,
            originalBilledAmount,
          } = getValidatedIbLineAdjustments(ibLine, adjustmentLines);

          const result: CreateIbLineFindingsInputValue = {
            auditFindingValues: {
              ...auditFindingValues,
              improperPaymentUnitsCharged,
              improperPaymentCost,
            },
            batchClaimLineItemValues: ibLineToBcliInput({
              revCode,
              ibLine,
              originalBilledAmount,
              amountCharged,
            }),
          };

          return result;
        },
      );

      //  ---- #2 Create / Review specifics ----
      // get the specific mutation for review vs create:
      if (isReview) {
        const input = this.prepareVersionMutation(
          state,
          editFindingValues,
          inputValues,
        );
        return !input
          ? null
          : {
              type: "IbLineVersionFindingInput",
              isReview: true,
              input,
            };
      }

      if (!isReview) {
        const input = this.prepareCreateMutation(
          state,
          editFindingValues,
          inputValues,
        );
        return !input
          ? null
          : {
              type: "IbLineCreateFindingInput",
              isReview: false,
              input,
            };
      }
    }

    return null;
  };

  /**
   * Calls the finding model to create new findings (executes Create mutation);
   * this function is separate from building the mutation for easy testing and to
   * allow the Controller act on the final mutation prior to commit
   * @param findingModel
   * @param input
   */
  commitCreateMutation = async (
    findingModel: FindingModel,
    input: FindingInput,
  ): Promise<OperationResult<any, { input: CreateIbLineFindingsInput }>> => {
    if (input.type === "IbLineCreateFindingInput") {
      return findingModel.createIbFinding(input.input).then((result) => {
        return result as OperationResult<
          any,
          { input: CreateIbLineFindingsInput }
        >;
      });
    } else {
      return Promise.reject(
        new Error(
          `commitCreateMutation: unexpected input type '${input.type}'`,
        ),
      );
    }
  };

  /**
   * Calls the finding model to commit updates to existing findings (executes Version mutation);
   * this function is separate from building the mutation for easy testing and to
   * allow the Controller act on the final mutation prior to commit
   * @param findingModel
   * @param input
   */
  commitVersionMutation = async (
    findingModel: FindingModel,
    input: FindingInput,
  ): Promise<OperationResult<any, { input: VersionAuditFindingsInput }>> => {
    if (input.type === "IbLineVersionFindingInput") {
      return findingModel.versionIbFinding(input.input).then((result) => {
        return result as OperationResult<
          any,
          { input: VersionAuditFindingsInput }
        >;
      });
    } else {
      return Promise.reject(
        new Error(
          `commitVersionMutation: unexpected input type '${input.type}'`,
        ),
      );
    }
  };

  /**
   * Processes the result of mutations to create/version findings, packaging the
   * response for VM/Form consumption; separate from commit operations to allow
   * Controller to act on response if desired
   * @param findingInput
   * @param result
   * @param state
   * @param claimRefresher
   */
  packageMutationResponse = async (
    findingInput: FindingInput,
    result: any,
    state: EditFindingState,
    claimRefresher: any,
  ): Promise<any> => {
    const ibLines = state.operation?.ibData ?? [];

    console.log("findingController::versionIbFinding: result", result);
    console.log("findingController::versionIbFinding: CALLING CLAIM REFRESHER");
    if (result.error) {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject(result.error);
    }
    return claimRefresher({
      claimId: state.batchClaimId,
      ibLineIds: ibLines.map((ibLine) => ibLine.id),
    })
      .catch((err: any) => {
        console.error("error refreshing claim", err);
      })
      .then((refreshResult: any) => {
        console.log(
          "findingController::versionIbFinding: ** Claim Refresher Result",
          refreshResult,
        );
        const packagedResult = {
          type: state.type,
          review: findingInput.isReview,
          result,
          refreshResult,
        };

        console.log("result", packagedResult);
        return packagedResult;
      });
  };
}
