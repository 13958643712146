"use client";

import { useEffect, useRef } from "react";
import type { UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useVirtualizer } from "@tanstack/react-virtual";

import { useUnmount } from "@alaffia-technology-solutions/hooks";

type GetPageThumbnailUseBaseQueryOptions<TError> = UseQueryOptions<
  HTMLImageElement | null,
  TError,
  HTMLImageElement | null,
  (string | number)[]
>;

type GetPageThumbnailUseQueryOptions<TError> = Omit<
  GetPageThumbnailUseBaseQueryOptions<TError>,
  "queryKey" | "queryFn"
>;

interface UseThumbnailImageProps<TError> {
  fileId: string;
  getPageThumbnail: GetPageThumbnail;
  virtualItemIndex: number;
  options?: GetPageThumbnailUseQueryOptions<TError>;
}

const useThumbnailImage = <TError>({
  fileId,
  getPageThumbnail,
  virtualItemIndex,
  options,
}: UseThumbnailImageProps<TError>) => {
  const query = useQuery({
    queryFn: () => getPageThumbnail(virtualItemIndex),
    queryKey: ["pdf-viewer-thumbnails", fileId, virtualItemIndex],
    ...options,
  });

  return query;
};

export type GetPageThumbnail = (
  pageIndex: number,
) => Promise<HTMLImageElement | null>;

export type UseThumbnailImage = <TError>(
  virtualItemIndex: number,
  options?: GetPageThumbnailUseQueryOptions<TError>,
) => UseQueryResult<HTMLImageElement | null, unknown>;

interface UsePDFThumbnailsProps {
  currentPage: number;
  fileId: string;
  getPageThumbnail: GetPageThumbnail;
  pagesCount: number;
}

export const usePDFThumbnails = ({
  currentPage,
  fileId,
  getPageThumbnail,
  pagesCount,
}: UsePDFThumbnailsProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    count: pagesCount,
    estimateSize: () => 160,
    gap: 4,
    getScrollElement: () => ref.current,
    overscan: 5,
  });

  const isThumbnailHighlighted = (virtualItemIndex: number) =>
    virtualItemIndex === currentPage;

  const useThumbnailImageWithCallback: UseThumbnailImage = (
    virtualItemIndex,
    options,
  ) =>
    useThumbnailImage({
      fileId,
      getPageThumbnail,
      virtualItemIndex,
      options,
    });

  useEffect(() => {
    rowVirtualizer.scrollToIndex(currentPage, {
      align: "auto",
      behavior: "smooth",
    });
  }, [currentPage, rowVirtualizer]);

  const queryClient = useQueryClient();

  useUnmount(() =>
    queryClient.removeQueries({
      queryKey: ["pdf-viewer-thumbnails", fileId],
    }),
  );

  return {
    isThumbnailHighlighted,
    ref,
    rowVirtualizer,
    useThumbnailImage: useThumbnailImageWithCallback,
  };
};
