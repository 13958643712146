/* eslint-disable @typescript-eslint/consistent-type-definitions */
import type { FC } from "react";

import { IbinIbLineAdjustments } from "../../createAuditFinding/ibinIbLineAdjustments";
import type { EditFindingAdjustments } from "../viewController/types";

type IbLineAdjustmentInfoProps = {
  adjustments: EditFindingAdjustments | undefined;
};

export const IbLineAdjustmentsInfo: FC<IbLineAdjustmentInfoProps> = ({
  adjustments,
}) => {
  return (
    <div>
      <div tw="af-italic">Findings to create:</div>
      <div tw="af-h-24 af-max-h-24 af-text-xs af-py-2 af-pl-3 af-text-antd-lt-colorTextSecondary dark:af-text-antd-dk-colorTextSecondary">
        {adjustments?.paymentRate &&
        adjustments?.improperPaymentUnitsCharged > 0 ? (
          <div tw="af-h-20 af-max-h-20 af-overflow-y-auto">
            {
              <IbinIbLineAdjustments
                paymentRate={adjustments?.paymentRate}
                ibLineAdjustments={adjustments?.ibLineAdjustments ?? []}
              ></IbinIbLineAdjustments>
            }
          </div>
        ) : (
          <div tw="af-pl-2 af-py-2 af-italic af-text-xs af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary">
            Select Rev Code and Discrepant Quantity to display finding
            calculations
          </div>
        )}
      </div>
    </div>
  );
};
