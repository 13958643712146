import * as React from "react";
import { useState } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { IconMessageChatbot } from "@tabler/icons-react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import {
  useEventListener,
  useOnClickOutside,
} from "@alaffia-technology-solutions/hooks";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  Skeleton,
} from "@alaffia-technology-solutions/ui";

import { CloseButton } from "../CloseButton";
import { FullscreenButton } from "../FullscreenButton";

interface DefaultChatboxHeaderProps {
  caseName: React.ReactNode | undefined;
  actions?: React.ReactNode;
  onClose: () => void;
  onFullscreenToggle: () => void;
  onCaseNameUpdate: (caseName: string) => void;
}

export function DefaultChatboxHeader({
  caseName,
  actions,
  onClose,
  onFullscreenToggle,
  onCaseNameUpdate,
}: DefaultChatboxHeaderProps) {
  return (
    <>
      <ChatboxHeaderLayout>
        <IconMessageChatbot className="af-h-5 af-w-5 af-flex-shrink-0 af-text-gray-600" />
        <EditableCaseName
          caseName={caseName}
          onCaseNameUpdate={onCaseNameUpdate}
        />
      </ChatboxHeaderLayout>
      <ChatboxHeaderLayout className="af-flex-wrap">
        <ChatboxHeaderLayout className="af-flex-wrap">
          {actions}
        </ChatboxHeaderLayout>
        <div className="af-flex af-flex-nowrap">
          <FullscreenButton onClick={onFullscreenToggle} />
          <CloseButton onClick={onClose} />
        </div>
      </ChatboxHeaderLayout>
    </>
  );
}

export const ChatboxHeaderLayout = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<"div">
>(({ className, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={cn(
        "af-flex af-max-w-full af-items-center af-justify-between af-gap-2",
        className,
      )}
      {...props}
    />
  );
});
ChatboxHeaderLayout.displayName = "ChatboxHeaderLayout";

const caseNameSchema = z.object({
  caseName: z.string().trim().min(1, {
    message: "Case name is required",
  }),
});

function EditableCaseName({
  caseName,
  onCaseNameUpdate,
}: Pick<DefaultChatboxHeaderProps, "caseName" | "onCaseNameUpdate">) {
  const [isSelected, setSelected] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const form = useForm<z.infer<typeof caseNameSchema>>({
    resolver: zodResolver(caseNameSchema),
    values: {
      caseName: typeof caseName === "string" ? caseName : "",
    },
  });

  const handleSubmit = form.handleSubmit((data) => {
    const isDifferent = caseName !== data.caseName;
    isDifferent && onCaseNameUpdate(data.caseName);
    setSelected(false);
  });

  useOnClickOutside(inputRef, () => void handleSubmit());

  useEventListener("keydown", (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      void handleSubmit();
    }
  });

  if (caseName === undefined) {
    return <Skeleton className="af-h-6 af-w-[150px]" />;
  }

  return (
    <div>
      {isSelected && caseName ? (
        <Form {...form}>
          <FormField
            name={"caseName"}
            control={form.control}
            render={({ field }) => (
              <FormItem>
                <FormControl>
                  <Input
                    {...field}
                    ref={inputRef}
                    className="af-w-unset af-h-6 af-w-fit af-px-2 af-py-1 af-text-base"
                  />
                </FormControl>
              </FormItem>
            )}
          />
        </Form>
      ) : (
        // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/click-events-have-key-events
        <h3
          onClick={() => setSelected(true)}
          className={cn(
            "af-rounded-sm af-px-2 af-transition-all hover:af-bg-gray-200",
            isSelected && "af-invisible af-opacity-0",
          )}
        >
          {form.getValues("caseName") ?? caseName}
        </h3>
      )}
    </div>
  );
}
