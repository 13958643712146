/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";

// WE ARE KEEPING FINDINGS EXEMPT FROM EVENT HISTORY TIMELINE (this may change one day...)
const findings = {
  auditProgress: {
    stateTypeLabel: "Findings",
    defaultLabel: "No Findings yet",
    tooltipLabel: "Status of audit findings within claim(s)",

    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that queries the auditFindng table filtered on a $batchClaimFilter and counts
     *     the number of findings for each queryable state definition below
     */
    buildProgressSubQuery: () => {
      const query = `
        findings_FINDINGS_TO_REVIEW: auditFindings(
          filter: {
            and: [{ batchClaim: $batchClaimFilter }, { isActive: { equalTo: true } }]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_SUPERSEDED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { superseded: { equalTo: true } }
            ]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_REVIEWED_DECLINED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { accepted: { equalTo: false } }
              {
                or: [
                  { reviewedAuditFindingsExist: true }
                  { auditFindingReviewsByOriginalIdExist: true }
                ]
              }
            ]
          }
        ) {
          totalCount
        }
        findings_FINDINGS_REVIEWED_ACCEPTED: auditFindings(
          filter: {
            and: [
              { batchClaim: $batchClaimFilter }
              { isActive: { equalTo: true } }
              { accepted: { equalTo: true } }
              {
                or: [
                  { reviewedAuditFindingsExist: true }
                  { auditFindingReviewsByOriginalIdExist: true }
                ]
              }
            ]
          }
        ) {
          totalCount
        }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e FINDINGS_TO_REVIEW): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: { totalClaims, ...restOfData },
      permissions,
      userType,
      queryableStates,
      states,
    }: any) => {
      const progressStats: any = {};
      queryableStates.forEach((stateName: any) => {
        // allowed to show this state for this user
        if (permissions.claimStatesToShow.findings.has(stateName)) {
          progressStats[stateName] = {
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            description: states[stateName].description(userType),
          };
          progressStats[stateName].totalCount =
            restOfData[`findings_${stateName}`].totalCount;
        }
      });
      return progressStats;
    },
  },
  queryableStates: [
    "FINDINGS_TO_REVIEW",
    "FINDINGS_SUPERSEDED",
    "FINDINGS_REVIEWED_DECLINED",
    "FINDINGS_REVIEWED_ACCEPTED",
  ],
  defaultStates: ["FINDINGS_TO_REVIEW"],
  dataType: "enum",
  onTableQuery: "auditFindings",
  states: {
    FINDINGS_TO_REVIEW: {
      name: () => {
        return "To Review";
      },
      primaryColor: "lightgray",
      strokeColor: "white",
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Findings that require review";
      },
      timelineEventRendering: {
        renderingAllowed: () => false,
      },
    },
    FINDINGS_SUPERSEDED: {
      name: () => {
        return "Superseded";
      },
      primaryColor: "#faad14",
      strokeColor: "white",
      icon: (
        <MinusCircleOutlined style={{ color: "#faad14", fontSize: "120%" }} />
      ),
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Findings that have been superseded";
      },
      timelineEventRendering: {
        description: () => "Finding was Superseded",
        renderingAllowed: () => false,
      },
    },
    FINDINGS_REVIEWED_DECLINED: {
      name: () => {
        return "Reviewed & Declined";
      },
      primaryColor: "#fa541c",
      strokeColor: "white",
      icon: (
        <CloseCircleOutlined style={{ color: "#fa541c", fontSize: "120%" }} />
      ),
      description: () => {
        return "Findings that have been Reviewed & Declined";
      },
      timelineEventRendering: {
        description: () => "Finding was Reviewed & Declined",
        renderingAllowed: () => false,
      },
    },
    FINDINGS_REVIEWED_ACCEPTED: {
      name: () => {
        return "Reviewed & Accepted";
      },
      primaryColor: "#52c41a",
      strokeColor: "white",
      icon: (
        <CheckCircleOutlined style={{ color: "#52c41a", fontSize: "120%" }} />
      ),
      description: () => {
        return "Findings that have been Reviewed & Accepted";
      },
      timelineEventRendering: {
        description: () => "Finding was Reviewed & Accepted",
        renderingAllowed: () => false,
      },
    },
  },
  filterBuilder: (values: any) => {
    /* 
      POSSIBLE VALUES = [FINDINGS_TO_REVIEW, FINDINGS_SUPERSEDED,  FINDINGS_REVIEWED_DECLINED,  FINDINGS_REVIEWED_ACCEPTED]

      filter required for each value:
      FINDINGS_TO_REVIEW
        - isActive: { equalTo: true }
          -- and --
        - reviewedAuditFindingsExist: false
          -- and --
        - auditFindingReviewsByOriginalIdExist: false
          -- and --
        - superseded: { equalTo: false }

      FINDINGS_SUPERSEDED
        - isActive: { equalTo: true }
          -- and --
        - superseded: { equalTo: true }

      FINDINGS_REVIEWED_DECLINED
        - isActive: { equalTo: true }
          -- and --
        - accepted: { equalTo: false },          
          -- and --
        - superseded: { equalTo: false }
          -- and --
        - or: [
            { reviewedAuditFindingsExist: true },
            { auditFindingReviewsByOriginalIdExist: true },
          ],
      
      FINDINGS_REVIEWED_ACCEPTED
        - isActive: { equalTo: true }
          -- and --
        - accepted: { equalTo: true },          
          -- and --
        - superseded: { equalTo: false }
          -- and --
        - or: [
            { reviewedAuditFindingsExist: true },
            { auditFindingReviewsByOriginalIdExist: true },
          ],
    */

    const filter = {
      isActive: { equalTo: true }, // all findings need this
    };

    /* 
      if 'FINDINGS_SUPERSEDED' is the only value, then we need to filter on 'superseded'. Otherwise we dont have to
      this is because all values require 'superseded': false except for 'FINDINGS_SUPERSEDED'
      thus if values includes 'FINDINGS_SUPERSEDED' and something else, that means we want ANY claim with 'superseded': true OR false
    */
    if (values.includes("FINDINGS_SUPERSEDED") && values.length === 1)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      filter.superseded = { equalTo: true };

    /**
      if 'FINDINGS_REVIEWED_ACCEPTED' or 'FINDINGS_REVIEWED_DECLINED' are in values that means we want claims that have been reviewed
      However if values has bothe 'ACCEPTED' & 'DECLINED' that means we don't filter on accpetance

     */
    if (
      values.includes("FINDINGS_REVIEWED_ACCEPTED") ||
      values.includes("FINDINGS_REVIEWED_DECLINED")
    ) {
      if (
        !(
          values.includes("FINDINGS_REVIEWED_ACCEPTED") &&
          values.includes("FINDINGS_REVIEWED_DECLINED")
        ) // if both 'ACCEPTED' and 'DECLINED' are NOT in value
      ) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        filter.accepted = {
          equalTo: values.includes("FINDINGS_REVIEWED_ACCEPTED"),
        };
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        filter.or = [
          { reviewedAuditFindingsExist: true },
          { auditFindingReviewsByOriginalIdExist: true },
        ];
      } else {
        /* 
          because 'FINDINGS_TO_REVIEW' requires auditFindingReviewsByOriginalIdExist: false && reviewedAuditFindingsExist: false 
          and 'FINDINGS_REVIEWED' allows auditFindingReviewsByOriginalIdExist: trye || reviewedAuditFindingsExist: true
          that means if both a 'FINDINGS_REVIEWED' is in values and 'FINDINGS_TO_REVIEW' is in value we return 
            auditFindingReviewsByOriginalIdExist: false || reviewedAuditFindingsExist: false
          but otherwise return 
            auditFindingReviewsByOriginalIdExist: false && reviewedAuditFindingsExist: false 
        */
        if (values.includes("FINDINGS_TO_REVIEW")) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          filter.reviewedAuditFindingsExist = false;
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          filter.auditFindingReviewsByOriginalIdExist = false;
        }
      }
    }
    return filter;
  },
};

export { findings };
