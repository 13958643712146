import type { AuthConfig, AuthUtilities } from "@urql/exchange-auth";

import type { LogoutEvent } from "../auth/types";
import { getLogger } from "../client/log";

type GetTokenResult = { token: string; expires: number } | undefined;

export interface AuthExchangeHandlerHelpers {
  getToken: () => GetTokenResult;
  refreshToken: () => Promise<boolean>;
  logout: (event?: LogoutEvent) => void;
}

/**
 *  urql exchange to handle auth including triggering token refresh
 *  ** we can pass callbacks to allow Client to update auth **
 *  https://www.npmjs.com/package/@urql/exchange-auth
 */
export const authExchangeHandler =
  ({ getToken, refreshToken, logout }: AuthExchangeHandlerHelpers) =>
  // eslint-disable-next-line @typescript-eslint/require-await
  async (utils: AuthUtilities): Promise<AuthConfig> => {
    const logger = getLogger("AuthExchangeHandler");
    // called on initial launch,
    // fetch the auth state from storage (local storage, async storage etc)
    // let token = localStorage.getItem('token');
    // let refreshToken = localStorage.getItem('refreshToken');

    logger.log("Auth exchange utils", utils);

    // TODO we could return a class with 'expires' to proactively refresh
    return {
      addAuthToOperation(operation) {
        const { token } = getToken() ?? {};
        if (token) {
          return utils.appendHeaders(operation, {
            Authorization: `Bearer ${token}`,
          });
        }
        return operation;
      },

      willAuthError(_operation) {
        // e.g. check for expiration, existence of auth etc

        const { token, expires } = getToken() ?? {};

        if (token && expires) {
          // todo expires
          // return expires > Date.now();
        }

        return !token;
      },

      didAuthError(error, operation) {
        // check if the error was an auth error
        // this can be implemented in various ways, e.g. 401 or a special error code
        // return error.graphQLErrors.some(
        //   (e) => e.extensions?.code === 'FORBIDDEN',
        // );

        const msg = error.message.toLowerCase();
        const isAuthError =
          msg.includes("jwt expired") || msg.includes("auth error:");
        logger.debug(`didAuthError: ${isAuthError}`, { error, operation });
        return isAuthError;
      },

      async refreshAuth() {
        // called when auth error has occurred
        // we should refresh the token with a GraphQL mutation or a fetch call,
        // depending on what the API supports

        let refreshed = false;
        let refreshError: any = null;

        try {
          refreshed = await refreshToken();
        } catch (e) {
          logger.info("refreshAuth error", e);
          refreshError = e;
        }

        if (refreshed) {
          // ok
        } else {
          logger.info("refreshAuth failed", { refreshError });
          logout({
            type: "SessionExpiredEvent",
            trigger: "fetchFailure",
            payload: { refreshError },
          });
        }
      },
    };
  };
