import _loglevel from "loglevel";
import type { Logger, LogLevelDesc } from "loglevel";

const LEVELS = _loglevel.levels;

export type LoggerType = Logger;
export type LoggerLevels = LogLevelDesc;
let initialLogLevel: LogLevelDesc | null = null;

const DEFAULT_LOG_LEVEL = LEVELS.WARN;

export const setBaseLogLevel = (level: LoggerLevels) => {
  initialLogLevel = level;
  _loglevel.setLevel(level);
};

export function getLogger(
  name: string,
  level: LoggerLevels | null = null,
): Logger {
  if (!initialLogLevel) {
    // let the first log instantiated set the 'default' level for add'l loggers
    initialLogLevel = level ?? DEFAULT_LOG_LEVEL;
    _loglevel.setLevel(initialLogLevel);
  }
  const logger: Logger = _loglevel.getLogger(name);
  if (level !== null && level !== initialLogLevel) {
    logger.setLevel(level);
  }
  return logger;
}
