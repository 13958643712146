import type { PropsWithChildren } from "react";
import { IconX } from "@tabler/icons-react";

import { Button } from "@alaffia-technology-solutions/ui";

import { FullscreenButton } from "../FullscreenButton";

interface PaneHeaderSplitViewProps {
  onClose: () => void;
  onFullscreenToggle: () => void;
}

export function PaneHeaderSplitView({
  children,
  onClose,
  onFullscreenToggle,
}: PropsWithChildren<PaneHeaderSplitViewProps>) {
  return (
    <div className="af-flex af-items-center af-justify-between af-border-b af-border-border af-bg-gray-100 af-px-6 af-py-4 af-text-foreground">
      {children}
      <div className="af-flex af-items-center af-gap-3">
        <FullscreenButton onClick={onFullscreenToggle} />
        <Button
          variant="link"
          className="af-h-fit af-w-fit af-rounded-sm af-p-1.5 af-text-gray-600 af-ring-offset-background af-transition-colors hover:af-text-gray-900 focus:af-outline-none focus:af-ring-2 focus:af-ring-ring focus:af-ring-offset-2 disabled:af-pointer-events-none data-[state=open]:af-bg-secondary"
          onClick={onClose}
          aria-label="Close"
        >
          <IconX className="af-h-5 af-w-5" />
        </Button>
      </div>
    </div>
  );
}
