import { useCallback } from "react";
import { useShallow } from "zustand/react/shallow";

import type { CombinedPDFStore } from "./providers/pdf-store-provider";
import { usePDFStore } from "./providers/pdf-store-provider";

export function usePDF() {
  const state = usePDFStore((s) => s.state);

  const selector = useShallow((s: CombinedPDFStore) => ({
    addEventListener: s.addEventListener,
    addHighlight: s.addHighlight,
    bookmarks: s.bookmarks,
    close: s.close,
    currentPageIndex: s.currentPageIndex,
    error: s.error,
    events: s.events,
    getPageDimensions: s.getPageDimensions,
    getPageThumbnail: s.getPageThumbnail,
    highlightTextOnPage: s.highlightTextOnPage,
    jumpToPage: s.jumpToPage,
    loadBookmarks: s.loadBookmarks,
    nextPage: s.nextPage,
    open: s.open,
    pageCount: s.pageCount,
    pdfUrl: s.pdfUrl,
    prevPage: s.prevPage,
    readOnlyScale: s.scale,
    removeEventListener: s.removeEventListener,
    removeHighlights: s.removeHighlights,
    removeHighlightsOnPage: s.removeHighlightsOnPage,
    rotate: s.rotate,
    zoom: s.zoom,
  }));

  const { data: controller } = usePDFStore(selector);

  const close = useControllerCallback(controller?.close);
  const getPageDimensions = useControllerCallback(
    controller?.getPageDimensions,
  );
  const getPageThumbnail = useControllerCallback(controller?.getPageThumbnail);

  const highlightTextOnPage = useControllerCallback(
    controller?.highlightTextOnPage,
  );

  const jumpToPage = useControllerCallback(controller?.jumpToPage);
  const loadBookmarks = useControllerCallback(controller?.loadBookmarks);
  const nextPage = useControllerCallback(controller?.nextPage);
  const open = useControllerCallback(controller?.open);
  const prevPage = useControllerCallback(controller?.prevPage);

  const removeHighlights = useControllerCallback(controller?.removeHighlights);
  const removeHighlightsOnPage = useControllerCallback(
    controller?.removeHighlightsOnPage,
  );

  const rotate = useControllerCallback(controller?.rotate);
  const zoom = useControllerCallback(controller?.zoom);

  return {
    addEventListener: controller?.addEventListener,
    addHighlight: controller?.addHighlight,
    bookmarks: controller?.bookmarks ?? null,
    close,
    currentPage: controller?.currentPageIndex ?? 0,
    error: controller?.error,
    events: controller?.events,
    getPageDimensions,
    getPageThumbnail,
    highlightTextOnPage,
    jumpToPage,
    loadBookmarks,
    nextPage,
    open,
    pagesCount: controller?.pageCount ?? 0,
    prevPage,
    readOnlyScale: controller?.readOnlyScale ?? "fitWidth",
    removeEventListener: controller?.removeEventListener,
    removeHighlights,
    removeHighlightsOnPage,
    rotate,
    state,
    zoom,
  };
}

function useControllerCallback<TArgs extends unknown[], TReturn>(
  callback: ((...args: TArgs) => TReturn | Promise<TReturn>) | undefined,
) {
  return useCallback(
    async (...args: TArgs) => {
      const result = await callback?.(...args);
      return result ?? null;
    },
    [callback],
  );
}
