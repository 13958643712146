/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { useContext, useMemo } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import { ErrorBoundary } from "../../common/error/errorBoundary";
import { Playground } from "../../common/playground/playground";
import { AuditWorkspace } from "../audit/auditWorkspace";
import { UserContext } from "../context/user";
import { Dashboard } from "../dashboard/dashboard";
import { Queue } from "../queues/queue";
import { Queues } from "../queues/queues";
import { Reports } from "../reports/reports";
import { Search } from "../search/search";
import { SearchResults } from "../search/searchResults";
import { Profile } from "../user/profile";
import { Users } from "../user/users";
import { Colors } from "./design/colors";

const Routes = ({ loggedIn, searchBarVisible }: any) => {
  const { permissions, search } = useContext(UserContext) || {
    permissions: {
      routes: ["/"],
    },
  };

  // add dev routes if they exist
  // TODO: lazy load dev routes to split bundle or restructure build so that dev routes are not included in prod build
  const devRoutes = useMemo(() => {
    const dr = (process.env.REACT_APP_DEV_ROUTES ?? "")?.split(",");
    dr.length > 0 && console.log("Dev routes enabled", dr);
    return dr;
  }, []);
  const permittedRoutes = permissions.routes.concat(devRoutes);

  // assign the logged in user's allowed routes
  const routes = {
    "/users": <Users />,
    // '/auth/login': <Login />,
    "/user/profile": <Profile />,
    "/workspace/:batchClaimId": <AuditWorkspace />,
    "/claims/:tableName/:searchFilter": <SearchResults />,
    "/search/:searchFilter": <SearchResults />,

    "/queues": <Queues />,
    "/queue/:queueId": <Queue />,
    "/reports": <Reports />,
    "/home": <Dashboard />,

    // routes available in dev mode only (per env check above)
    "/colors": <Colors />,
    "/playground": <Playground />,

    // catch-all
    "/": <Redirect to={permissions.homeRoute} />,

    // FIXME: (AT) - Assess the following routes / components
    // Notifications are unimplemented today, but coming!
    // '/user/notifications': <Notifications />,
  };

  return (
    <Switch>
      {Object.entries(routes)
        .filter(([route]) => permittedRoutes.includes(route))
        .map(([path, component]) => (
          <Route key={path} path={path}>
            <div
              data-id="routes-wrapper"
              tw="af-w-full af-h-full af-min-h-full af-flex af-flex-col"
            >
              <ErrorBoundary boundaryDescription={`Route [${path}]`}>
                <div>
                  {loggedIn && search.canSearchClaims ? (
                    // this condition prevents app from crashing as Search uses the UserContext
                    <Search searchBarVisible={searchBarVisible} />
                  ) : null}
                </div>
                <div tw="af-grow">{component}</div>
              </ErrorBoundary>
            </div>
          </Route>
        ))}
    </Switch>
  );
};

export { Routes };
