/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext, useState } from "react";
import { CalendarOutlined, PlusOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Button, DatePicker, Menu, message, Popover, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
//dayjs is the drop-in immutable replacement for moment, used by antd5,
//    see https://alaffiahealth.atlassian.net/browse/EN-1031
// import moment from 'moment-timezone';
import utc from "dayjs/plugin/utc"; //dayjs requires enabling this plugin to use the .utc() calls
import { gql, useMutation, useQuery } from "urql";

import {
  batchClaimEventHistoryTimeLineQuery,
  batchClaimState,
} from "../../../fragments";
import { UserContext } from "../../context/user";
import { batchClaimsNodeKey } from "../util";

dayjs.extend(utc);

const createBatchClaimDueDateMutation = gql`
  mutation setDueDates(
    $batchClaimIds: [UUID!]!
    $dueDates: [Datetime!]!
    $deleted: Boolean!
  ) {
    setDueDates(
      input: {
        batchClaimIds: $batchClaimIds
        dueDates: $dueDates
        deleted: $deleted
      }
    ) {
      batchClaimDueDates {
        batchClaim {
          ...batchClaimState
          # not included in batchClaimState by default
          activeDueDate: batchClaimStateById {
            id
            dueDate
          }
        }
      }
    }
  }
  ${batchClaimState}
`;

const typeActions = {
  setDueDate: {
    title: "Set/Change Due Date",
    value: "DUE_DATE_SET",
    actionCriteria: () => {
      const satisfied = true;
      return { satisfied };
    },
  },
  setRemoveDueDate: {
    title: "Remove the Due Date",
    value: "NO_DUE_DATE",
    actionCriteria: ({ activeDueDate: { dueDate } }: any) => {
      const satisfied = dueDate !== null;
      return { satisfied };
    },
  },
};

/**
 * helper function to extract the dueDate of a set of claims and conevert them to UTC
 * @param {*} batchClaims
 * @returns array of DayJs dueDates Object (UTC)
 */
const getCurrentUTCDueDateValuesFromClaims = (batchClaims: any) => {
  return batchClaims.map((batchClaim: any) => {
    return (
      batchClaim.activeDueDate.dueDate &&
      dayjs(batchClaim.activeDueDate.dueDate).utc()
    );
  });
};

const setDateMutation = async (
  dueDates: any,
  batchClaimIds: any,
  deleted: any,
  setLoading: any,
  createBatchClaimDueDateGqlMutation: any,
  refreshHistoryTimeLine: any,
) => {
  setLoading(true);
  const { data, error } = await createBatchClaimDueDateGqlMutation({
    batchClaimIds,
    dueDates,
    deleted,
  });
  setLoading(false);
  if (!data && error) {
    const errorMessage = "Date set/change failed";
    console.error(`${errorMessage}: ${error.message}`);
    void message.error(errorMessage);
  } else {
    if (batchClaimIds.length === 1 && refreshHistoryTimeLine)
      await refreshHistoryTimeLine({ requestPolicy: "network-only" });
    if (batchClaimIds.length > 1)
      void message.success(
        `Due Dates Successfully ${deleted ? "Removed" : "Set"}`,
      );
    // only 1 dueDate
    else
      void message.success(
        `Successful Due Date ${deleted ? "Removal from" : "Set to"} ${dayjs(
          dueDates[0], // if only 1 its uniform if many its uniform
        ).format("MM/DD/YYYY")}`,
      );
  }
};

const DatePickerPopover = ({
  currentDueDateValues,
  batchClaims,
  setLoading,
  permissions,
  internalRenderJsx,
  createBatchClaimDueDateGqlMutation,
}: any) => {
  const batchClaimIds = batchClaims.map(({ id }: any) => id);
  const [, refreshHistoryTimeLine] = useQuery({
    query: batchClaimEventHistoryTimeLineQuery,
    variables: {
      batchClaimIds,
    },
    pause: true,
  });
  return (
    <Popover
      trigger="hover"
      content={
        <DatePicker
          data-cy="date-picker"
          onChange={(newDate) => {
            /* 
               if the date coming from the antd popOver (newDate) is Null that means the user
               pressed the 'x', indicating we have "removed" this date and so we mark the
               isDeleted as true
            */
            const isDeleted = newDate === null;
            /* 
               if the action coming from the antd popOver is a delete action then we want to set 
               `dueDateValueToSet` as the old date that is now being DELETED (currentDueDateValues) -- so the db knows which row to indicate as deleted
               Otherwise if isDeleted===false tht means we are setting
            */
            const dueDateValuesToSet = isDeleted
              ? currentDueDateValues
              : Array(batchClaimIds.length).fill(newDate);
            void setDateMutation(
              dueDateValuesToSet,
              batchClaimIds,
              isDeleted,
              setLoading,
              createBatchClaimDueDateGqlMutation,
              refreshHistoryTimeLine,
            );
          }}
          value={
            currentDueDateValues.reduce((d1: any, d2: any) => {
              return d1 <= d2 ? d1 : d2;
            }) || null
          } // we select the min date from the list as the date the popOver shows
          disabled={!permissions.claimActions.dueDate.includes("setDueDate")}
          disabledDate={(current) =>
            current && current < dayjs().utc().subtract(1, "day").endOf("day")
          }
        />
      }
    >
      {internalRenderJsx}
    </Popover>
  );
};

const actionSubMenuItem = ({
  action,
  value,
  actionCriteria,
  batchClaims,
  title,
  createBatchClaimDueDateGqlMutation,
  permissions,
  setLoading,
}: any) => {
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );

  const menuItemContentRenderJsx = (
    <span>
      {`${title}${actionClaims.length > 1 ? ` (${actionClaims.length})` : ""}`}
    </span>
  );
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(actionClaims)}`}
      disabled={actionClaims.length === 0}
      onClick={async () => {
        // we only want an onClick action when we click on the 'Remove The Due Date' Menu Item
        // Otherwise we use the set date Popover which is rendered within the 'Set/Change Due Date' Menu Item
        if (action === "setRemoveDueDate") {
          return await setDateMutation(
            getCurrentUTCDueDateValuesFromClaims(actionClaims),
            actionClaims.map((actionClaim: any) => actionClaim.id),
            true, // is deleted === true
            setLoading,
            createBatchClaimDueDateGqlMutation,
            null, // the refreshHistoryTimeLine query would normally be passed here
          );
        } else return;
      }}
    >
      {action === "setDueDate" ? (
        <DatePickerPopover
          {...{
            currentDueDateValues:
              getCurrentUTCDueDateValuesFromClaims(actionClaims),
            createBatchClaimDueDateGqlMutation,
            permissions,
            setLoading,
            batchClaims: actionClaims,
            internalRenderJsx: menuItemContentRenderJsx,
          }}
        />
      ) : (
        menuItemContentRenderJsx
      )}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  const [, createBatchClaimDueDateGqlMutation] = useMutation(
    createBatchClaimDueDateMutation,
  );
  return (
    <Menu.SubMenu
      key={`dueDate-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Due Date"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions.dueDate.includes(action),
        )
        .map(([action, { ...actionProps }]) => {
          return actionSubMenuItem({
            action,
            batchClaims,
            createBatchClaimDueDateGqlMutation,
            permissions,
            setLoading,
            ...actionProps,
          });
        })}
    </Menu.SubMenu>
  );
};

/**
 * Shows the due date and the ability to change it if the user has the proper permissions
 * can either show as compact (icon only) or extended with description and full date print out
 * @param {*} batchClaim: Object
 * @param {*} compact: Boolean, whether or not to show label and description
 * @returns renders JSX
 */
const DueDateButton = ({ batchClaim, compact }: any) => {
  const { activeDueDate } = batchClaim;
  const [, createBatchClaimDueDateGqlMutation] = useMutation(
    createBatchClaimDueDateMutation,
  );
  const [dueDateChangeLoading, setDueDateChangeLoading] = useState(false);
  const { permissions } = useContext(UserContext);
  return (
    <div id="due-date-button">
      {permissions.claimActions.dueDate.includes("setDueDate") ? (
        /* USERS ALLOWED TO SET DUE DATES (i.e admin) */
        <DatePickerPopover
          {...{
            createBatchClaimDueDateGqlMutation,
            permissions,
            currentDueDateValues: getCurrentUTCDueDateValuesFromClaims([
              batchClaim,
            ]),
            setLoading: setDueDateChangeLoading,
            batchClaims: [batchClaim],
            internalRenderJsx: (
              <span>
                {activeDueDate.dueDate ? (
                  <div
                    id="dueDateDescription"
                    css={css`
                      display: inline;
                    `}
                  >
                    <Button
                      loading={dueDateChangeLoading}
                      size="small"
                      type="ghost"
                      icon={<CalendarOutlined />}
                    >
                      {!compact &&
                        dayjs(activeDueDate.dueDate).utc().format("MM/DD/YYYY")}
                    </Button>
                  </div>
                ) : (
                  <Button
                    loading={dueDateChangeLoading}
                    type="dashed"
                    shape="circle"
                    size="small"
                    icon={
                      // we may possibly keep the style attribute instead of css beause its an antd componnet
                      <PlusOutlined
                        style={{ color: "rgb(192 192 203 / 88%)" }}
                      />
                    }
                  />
                )}
              </span>
            ),
          }}
        />
      ) : (
        /* USERS NOT ALLOWED TO SET DUE DATES (i.e auditor) */
        <>
          {activeDueDate.dueDate ? (
            <div
              id="dueDateDescription"
              css={css`
              'display: inline;
            `}
            >
              {compact ? (
                <Tooltip
                  title={dayjs(activeDueDate.dueDate)
                    .utc()
                    .format("MM/DD/YYYY")}
                >
                  <CalendarOutlined />
                </Tooltip>
              ) : (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                <Tag loading={dueDateChangeLoading} icon={<CalendarOutlined />}>
                  {dayjs(activeDueDate.dueDate).utc().format("MM/DD/YYYY")}
                </Tag>
              )}
            </div>
          ) : (
            <span>-</span>
          )}
        </>
      )}
    </div>
  );
};

const dueDate = {
  title: "Due Date",
  subMenu: actionSubMenu,
};

export { DueDateButton, dueDate };
