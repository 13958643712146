/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React from "react";
import { InfoCircleOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Col, Empty, Row, Spin, Tooltip } from "antd";
import { gql, useQuery } from "urql";

import { ProgressBar } from "../../charts/progressBar";
import * as stateTypes from "../../claimState/stateTypes";
import { UserContext } from "../../context/user";
import { AuditProgressSingleStatistic } from "./auditProgressSingleStatistic";
import { generateAuditProgressQuery } from "./util/buildAuditProgressQueryUtil";
import { parseAuditProgressQueryResult } from "./util/parseAuditProgressQueryRes";

const AuditProgress = ({
  // NOTE: It is assumed that this combinedBatchClaimFilter is the combined filter with the userDefaultFilter defined in it
  combinedBatchClaimFilter,
  refreshTrigger,
}: any) => {
  const {
    userType,
    permissions,
    auditProgress: { showAsStats, progressBarsStateTypesToShow },
  } = React.useContext(UserContext);

  const [progressStatesObj, setProgressStatesObj] = React.useState<any>({});

  // some stats are shown in addition to the progress bars as rows above the progress bar inside tha panel
  // We define which of these stats are shown in the showAsStats config object
  const showAsStatsArr = showAsStats.row1.concat(showAsStats.row2);
  const auditProgressQuery = gql`
    ${generateAuditProgressQuery(permissions, showAsStatsArr)}
  `;

  const [{ fetching, data: auditProgressData }, refreshQuery] = useQuery({
    query: auditProgressQuery,
    variables: { batchClaimFilter: combinedBatchClaimFilter },
  });

  React.useEffect(
    () => {
      if (auditProgressData && refreshTrigger) {
        return refreshQuery({ requestPolicy: "network-only" });
      }
    },
    // auditProgressData is intentionally left out of this array as it leads to an infinite loop of refreshQuery being trggered
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [refreshTrigger],
  );

  React.useEffect(() => {
    // n.b. workflow.js (called further down the stack) needs groupedAggregates to be defined
    // since we added the schema to graphCache, it seems that sometimes the query object returns
    // an object with nulls including groupedAggregates, which fails if we only check for auditProgressData
    if (
      auditProgressData?.totalClaims &&
      auditProgressData?.workflow?.groupedAggregates
    ) {
      setProgressStatesObj(
        parseAuditProgressQueryResult(
          auditProgressData,
          permissions,
          userType,
          showAsStatsArr,
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditProgressData]);

  // maxStatColumnCount is used to determine the spacing (span) applied for each stat per row in the stats rendered above progress bars
  // Of all rows in 'showAsStats' this gives us the maximum number of columns any particular row has
  // however we set the default value to 3 (therefore maxStatColumnCount is always >= 3)
  // i.e if row1 has 4 columns and row2 has 5 columns, then maxStatColumnCount = 5
  //     or if row1 has 1 columns and row2 has 2 column, then maxStatColumnCount = 3
  const maxStatColumnCount = Object.values(showAsStats).reduce(
    (max: number, row: any) => Math.max(max, row.length),
    3,
  );

  return !auditProgressData || fetching ? ( // adding the 'fetching' makes it so that when we refresh the panel the loading spinner renders
    <div tw="af-min-h-[212px] af-flex af-justify-center af-items-center">
      <Spin size="large" />
    </div>
  ) : Object.keys(progressStatesObj).length === 0 ||
    auditProgressData.totalClaims.totalCount === 0 ? (
    // Sometimes there is lag between the request resolving and progressStatesObj getting loaded with data thats why we handle this case
    // Furthermore in the edge case that we load progressStatObj and even still it resolves as an empty object (this should technically never happen) -- we protect against a hard crash
    <Empty
      description={`No Claims returned by this search and therefore no Progress Statistics are shown...`}
    />
  ) : (
    <div tw="af-min-h-[212px]">
      <div
        id={`audit-progress-stats-parent-div`}
        key={`audit-progress-stats-parent-div`}
      >
        {/* for each row in showAsStats we render the stats of that row */}
        {Object.entries(showAsStats).map(([rowName, rowColumns]: any) => {
          return (
            <Row
              key={`audit-progress-antd-${rowName}`}
              id={`audit-progress-antd-${rowName}`}
            >
              {rowColumns.map((statKey: string, idx: number) => {
                return (
                  <Col
                    key={`audit-progress-antd-row-${rowName}-column-${statKey.toLowerCase()}`}
                    id={`audit-progress-antd-row-${rowName}-column-${statKey.toLowerCase()}`}
                    // FIXME: (MV) - alignment needs improvement on this screen, for now improve
                    // spacing a bit and make room for a 4th entry for Admins to show total claims
                    // (KY) stats have these custom (somewhat arbitrary) span values as stats are not entirley uniform in their rendering
                    // and so with our current statistic rendering config this results in most aesthetic render for now
                    // To improve this we would have to re-imagine the css for rendering these stats to be strcutured more intelligently
                    span={
                      maxStatColumnCount > 3 // if more than 3 columns we make the spans smaller
                        ? idx === 0 // 1st column gets more space
                          ? 9
                          : 5
                        : idx === 0 // else we make the spans bigger
                        ? 10 // again 1st column gets more space
                        : 7
                    }
                  >
                    <AuditProgressSingleStatistic
                      statKey={statKey}
                      progressStatesObj={progressStatesObj}
                    />
                  </Col>
                );
              })}
            </Row>
          );
        })}
      </div>
      <div
        id={`audit-progress-progress-bars-parent-div`}
        key={`audit-progress-progress-bars-parent-div`}
        css={css`
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: column;
          margin-top: 0.5%;
        `}
      >
        {progressBarsStateTypesToShow.map((stateType: any) => {
          const { tooltipLabel, defaultLabel, stateTypeLabel } =
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            stateTypes[stateType].auditProgress;
          /*
            aggregates the total count of all states in a stateType
            if that number resolves to zero then we show the
            defaut label instead of an empty progress bar
          */
          const stateTypeTotalCount = Object.values(
            progressStatesObj[stateType],
          ).reduce(
            // we get the totalCount of all fields of a stateType
            // i.e for a particular search we find 10 claims in IN_PROGRESS and 100 in CLAIMS_RECEIVED and the rest of the states are 0 then we return 110
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            (accumulator, { totalCount }) => accumulator + totalCount,
            0,
          );

          return (
            <div
              key={stateType}
              css={css`
                height: 21px;
                display: flex;
                flex-direction: row;
                width: inherit;
                margin-top: 5px;
              `}
            >
              <div
                id={`${stateType}-label`}
                css={css`
                  display: flex;
                  flex-direction: row;
                  justify-content: left;
                  width: 30%;
                `}
              >
                <Tooltip
                  id={`${stateType}-toolltip`}
                  key={`${stateType}-toolip`}
                  title={tooltipLabel}
                >
                  <InfoCircleOutlined
                    // css doesnt behave well possibly because this is an 'antd' component
                    style={{
                      marginTop: "7px",
                      fontSize: "65%",
                      paddingRight: "2px",
                    }}
                  />
                </Tooltip>
                {stateTypeLabel}
              </div>
              {stateTypeTotalCount === 0 ? (
                <i>{defaultLabel}</i>
              ) : (
                <ProgressBar
                  key={`${stateType}-progress-bar`}
                  id={`${stateType}-progress-bar`}
                  max={stateTypeTotalCount}
                  stateType={stateType}
                  statuses={Object.entries(progressStatesObj[stateType])}
                  minWidth={9}
                />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { AuditProgress };
