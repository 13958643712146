import { useCallback, useState } from "react";

import { useEventCallback } from "@alaffia-technology-solutions/hooks";

interface UseFilesPanelControlsProps {
  onCollapse?: () => void;
  onExpand?: () => void;
  isExpanded?: boolean;
}

export const useFilesPanelControls = ({
  onCollapse,
  onExpand,
  isExpanded: controlledIsExpanded,
}: UseFilesPanelControlsProps = {}) => {
  const isControlled = controlledIsExpanded !== undefined;

  const [uncontrolledIsExpanded, setUncontrolledIsExpanded] = useState(true);

  const onExpandEventCallback = useEventCallback(onExpand);
  const onCollapseEventCallback = useEventCallback(onCollapse);

  const expand = useCallback(() => {
    if (!isControlled) {
      setUncontrolledIsExpanded(true);
    }
    onExpandEventCallback();
  }, [isControlled, onExpandEventCallback]);

  const collapse = useCallback(() => {
    if (!isControlled) {
      setUncontrolledIsExpanded(false);
    }
    onCollapseEventCallback();
  }, [isControlled, onCollapseEventCallback]);

  const isExpanded = isControlled
    ? controlledIsExpanded
    : uncontrolledIsExpanded;

  return {
    expand,
    collapse,
    isExpanded,
  };
};
