/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type React from "react";

import { AuditFindingSeedType } from "../../../gql/graphql";
import type { AuditFinding } from "../../../gql/graphql";

enum DefaultFindingsRowColors {
  NEEDS_REVIEW = "#ffa39e", // antd red-3
  ACCEPTED = "#b7eb8f", // antd green-3
}

export enum AuditFindingStatus {
  ACCEPTED = "ACCEPTED",
  DECLINED = "DECLINED",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  SUPERSEDED = "SUPERSEDED",
  DELETED = "DELETED",
  UNKNOWN = "UNKNOWN",
}
interface AuditFindingStatusDefiner {
  accepted: boolean;
  active: boolean;
  needsReview: boolean;
  superseded: boolean;
  deleted: boolean;
  reviewedAuditFinding: {
    accepted: boolean;
    declined: boolean;
  };
}

export const getFindingStatus = (
  finding: AuditFindingStatusDefiner,
): AuditFindingStatus => {
  if (!finding.reviewedAuditFinding) {
    // TODO: resolve inconsistency between af.needs_review and use of reviewedAuditFinding
    return AuditFindingStatus.NEEDS_REVIEW;
  }
  if (finding.superseded) {
    return AuditFindingStatus.SUPERSEDED;
  }
  if (finding.deleted) {
    return AuditFindingStatus.DELETED;
  }
  if (finding.accepted) {
    return AuditFindingStatus.ACCEPTED;
  }
  if (finding.reviewedAuditFinding) {
    if (finding.reviewedAuditFinding.accepted) {
      return AuditFindingStatus.ACCEPTED;
    } else {
      return AuditFindingStatus.DECLINED;
    }
  }
  console.error("Undetermined finding type: ", finding);
  return AuditFindingStatus.UNKNOWN;
};

export const getTableRowStyle = ({
  showFindings = true,
  findings,
  findingsRowColors = DefaultFindingsRowColors,
}: {
  showFindings: boolean;
  findings: AuditFindingStatusDefiner[];
  findingsRowColors: { ACCEPTED: string; NEEDS_REVIEW: string };
}): React.HTMLAttributes<any> | React.TdHTMLAttributes<any> => {
  if (!showFindings || !findings || findings.length < 1) {
    return { style: {} };
  }
  const createStyle = (bgColor: string) => ({
    style: {
      backgroundColor: bgColor,
    },
  });
  const statuses = findings.map((f) => getFindingStatus(f));
  if (statuses.some((s) => s === AuditFindingStatus.NEEDS_REVIEW)) {
    return createStyle(findingsRowColors.NEEDS_REVIEW);
  }
  if (statuses.some((s) => s === AuditFindingStatus.ACCEPTED)) {
    return createStyle(findingsRowColors.ACCEPTED);
  }
  // We only color rows with the above; declined, superseded, deleted should not highlight the row...
  return { style: {} };
};

export interface DenialCodeTagMappableFinding {
  auditFindingSeedType: string;
  auditFindingRuleTypeByAuditFindingRuleType: {
    displayName: string;
    description: string;
    type: string;
  };
  accepted: boolean;
  needsReview: boolean;
}

// improvement: refactor to use AuditFindingStatus
export type DenialCodeTagMap = Record<
  string,
  {
    accepted: boolean;
    isUB: boolean;
    title: string;
    displayName: string;
    count: number;
  }
>;

export const toDenialCodesTagMap = (
  findings: AuditFinding[],
): DenialCodeTagMap =>
  findings
    .filter((f) => f.accepted ?? f.needsReview)
    .reduce((_map, finding) => {
      const {
        accepted,
        auditFindingSeedType,
        auditFindingRuleTypeByAuditFindingRuleType,
      } = finding;
      const { displayName, description, type } =
        auditFindingRuleTypeByAuditFindingRuleType ?? {
          displayName: "",
          description: "",
          type: "UB_CLAIM_LINE",
        };
      const isUB = auditFindingSeedType === AuditFindingSeedType.UB_CLAIM_LINE;
      _map[type] = _map[type]
        ? { ..._map[type], count: ++_map[type].count }
        : {
            accepted: accepted ?? false,
            isUB,
            title: `${displayName}: ${description}`,
            displayName,
            count: 1,
          };
      return _map;
    }, {} as DenialCodeTagMap);

export const removeGraphQLErrorTag = (errorMessage: string) =>
  errorMessage.replace("[GraphQL]", "");

export interface IbCsvErrorInfo {
  fingerprint: string;
  auditFindingId: string;
}
export interface IbinErrorInfo {
  ibLineId: string;
  alaRowId: string;
  auditFindingId: string;
}

export type FindingUpdateErrorInfo = IbinErrorInfo[]; // | IbCsvErrorInfo[];

export interface ExtractIbLineFindingErrorResult {
  message: string;
  findingInfo: FindingUpdateErrorInfo;
}

/**
 * Returns the error message and fingerprint/finding info for lines with active/accepted findings
 * that block the creation/accepting/reviewing of findings on the same line...
 * @param error
 */
export const extractIbLineFindingError = (error: {
  message: string;
  graphQLErrors?: any;
}): ExtractIbLineFindingErrorResult | undefined => {
  const message = removeGraphQLErrorTag(error?.message);

  //IBIN finding error handling
  const acceptedFindingsExistErrorPayload =
    error?.graphQLErrors?.[0]?.extensions?.payload?.existingActiveFindings;
  if (acceptedFindingsExistErrorPayload) {
    const findingInfo = acceptedFindingsExistErrorPayload.map(
      ({
        ibLineId,
        alaRowId,
        auditFindingId,
      }: {
        ibLineId: string;
        alaRowId: string;
        auditFindingId: string;
      }) => ({ alaRowId, ibLineId, auditFindingId }),
    );
    return { message, findingInfo };
  }
  // Old CSV / NNIBL error handling, leaving in for now... can be removed once csv ib table is fully disabled
  const oldAcceptedFindingsExistErrorPayload =
    error?.graphQLErrors?.[0]?.extensions?.payload?.exisingActiveFindings;
  if (!oldAcceptedFindingsExistErrorPayload) {
    return;
  }
  const oldFindingInfo = oldAcceptedFindingsExistErrorPayload.map(
    ({
      fingerprint,
      auditFindingId,
    }: {
      fingerprint: string;
      auditFindingId: string;
    }) => ({ fingerprint, auditFindingId }),
  );
  return { message, findingInfo: oldFindingInfo };
};
