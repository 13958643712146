import { PromptResponseRatingType } from "../../remote/gql/graphql";

/**
 * Updates the ratings of a prompt response based on the current user's rating and the new rating.
 * @param responseCurrentUserRating The current user's rating for the response.
 * @param rating The new rating for the response.
 * @param responseDownRatings The number of down ratings for the response.
 * @param responseUpRatings The number of up ratings for the response.
 * @returns An object containing the updated down ratings and up ratings.
 */
export const updatePromptResponseRatings = (
  responseCurrentUserRating: PromptResponseRatingType | null,
  rating: PromptResponseRatingType | null,
  responseDownRatings: number,
  responseUpRatings: number,
) => {
  let downRatings = responseDownRatings;
  let upRatings = responseUpRatings;

  if (responseCurrentUserRating === rating) {
    return { downRatings, upRatings };
  }

  if (rating === null) {
    if (responseCurrentUserRating === PromptResponseRatingType.UP) {
      upRatings -= 1;
    } else if (responseCurrentUserRating === PromptResponseRatingType.DOWN) {
      downRatings -= 1;
    }
    return { downRatings, upRatings };
  }

  if (responseCurrentUserRating === null) {
    if (rating === PromptResponseRatingType.UP) {
      upRatings += 1;
    } else if (rating === PromptResponseRatingType.DOWN) {
      downRatings += 1;
    }
    return { downRatings, upRatings };
  }

  if (
    responseCurrentUserRating === PromptResponseRatingType.DOWN &&
    rating === PromptResponseRatingType.UP
  ) {
    downRatings -= 1;
    upRatings += 1;
  } else if (
    responseCurrentUserRating === PromptResponseRatingType.UP &&
    rating === PromptResponseRatingType.DOWN
  ) {
    upRatings -= 1;
    downRatings += 1;
  }

  return { downRatings, upRatings };
};
