import { gql } from "urql";

// Note: UB batch claim line sort orders need to remain consistent with ReportingService:
const BATCH_CLAIM_LINE_SORT =
  "orderBy: [LINE_NUMBER_ASC, REV_CODE_ASC, SERVICE_DATE_ASC, ID_ASC]";

const providerFragment = gql`
  fragment providerFragment on Provider {
    id
    name
    npi
    practiceType
    spec
  }
`;

const auditorUserFragment = gql`
  fragment auditorUserFragment on AuditorUser {
    id
    firstName
    lastName
    userType
    email
  }
`;

// Can admins even be assigned??
const assignableAuditorUsersQuery = gql`
  query auditors {
    auditorUsers(filter: { userType: { in: [AUDITOR, ADMINISTRATOR] } }) {
      nodes {
        id
        firstName
        lastName
      }
    }
  }
`;

const batchClaimLineItemFragment = gql`
  fragment batchClaimLineItemFragment on BatchClaimLineItem {
    id
    dateOfServiceStart
    dateOfServiceEnd
    procedureCode
    revCode
    itemNumber
    description
    units
    unitCharge
    batchClaimLineId
    authorId
    createdAt
    updatedAt
    alaRowId
    ibLineId
    lineNumber
    computedValues: batchClaimLineItemComputedValueById {
      id
      determinedAdjustedAllowedAmount
      determinedDiscrepantAmount
      determinedAdjustedBilledAmount
    }
    batchClaimLineItemNnIbLines {
      nodes {
        id
        nnIbLineId
        nnIbLine {
          id
          fingerprint
        }
      }
    }
  }
`;

const reviewedAuditFindingFragment = gql`
  fragment reviewedAuditFindingFragment on ReviewedAuditFinding {
    id
    accepted
    deleted
    createdAt
    updatedAt
    rationale
  }
`;

const auditFindingReviewFragment = gql`
  fragment auditFindingReviewFragment on AuditFindingReview {
    id
    createdAt
    # needed for update cache
    reviewId
    originalId
  }
`;

// n.b. this is intentionally light-weight as called in nested resolver -- see note at graphql_api aliasFields.js
const versionAuditFindingFragment = gql`
  fragment versionAuditFindingFragment on AuditFinding {
    id
    improperPaymentUnitsCharged
    improperPaymentCost
    improperPaymentReason
    needsReview
    auditorReviewed
    auditorComment
    auditType
    superseded
    auditFindingRuleType
    auditFindingSeedType
    confidence
    isOriginal
    isActive
    accepted
    createdAt
    # needed for updating cache
    batchClaimLineId
    batchClaimId
    batchClaimLineItemAudit {
      id
      batchClaimLineItem {
        ...batchClaimLineItemFragment
      }
    }
  }
  ${batchClaimLineItemFragment}
`;

const auditFindingFragment = gql`
  fragment auditFindingFragment on AuditFinding {
    id
    improperPaymentUnitsCharged
    improperPaymentCost
    improperPaymentReason
    needsReview
    auditorReviewed
    auditorComment
    auditType
    superseded
    auditFindingRuleTypeByAuditFindingRuleType {
      type
      description
      displayName
    }
    auditFindingRuleType
    auditFindingSeedType
    confidence
    isOriginal
    isActive
    accepted
    createdAt
    metaDataNcciMueOhpMueValue
    metaDataNcciMueAjudicationValue
    metaDataNcciPtpModifier
    metaDataNcciPtpCode
    metaDataEmCode
    metaDataEmOriginalLevel
    metaDataEmCorrectLevel
    metaDataAocPrimaryCode
    metaDataPricingCorrectPrice
    metaDataUnitsCorrectUnits
    discrepantAmount # At the auditFindings level there is no determined vs potential because this calculates the number on that finding
    author {
      id
      firstName
      lastName
      # userType
      email
    }
    reviewedAuditFinding: latestReview {
      ...reviewedAuditFindingFragment
    }
    batchClaimLineItemAudit {
      id
      batchClaimLineItem {
        ...batchClaimLineItemFragment
      }
    }
    reportedFindings {
      totalCount
      nodes {
        id
        auditFindingReportId
      }
    }
    # needed for updating cache
    batchClaimLineId
    batchClaimId
  }
  ${batchClaimLineItemFragment}
  ${reviewedAuditFindingFragment}
`;

const batchClaimDocumentationFragment = gql`
  fragment batchClaimDocumentationFragment on BatchClaim {
    id
    auditDocumentation {
      id
      subFile
      fileName
      process
      size
      signedUrl
      __typename
    }
  }
`;

const auditFindingReviewsFragment = gql`
  fragment auditFindingReviewsFragment on AuditFinding {
    id
    reviews: auditFindingReviewsByOriginalId(orderBy: CREATED_AT_DESC) {
      totalCount
      nodes {
        ...auditFindingReviewFragment
        auditFinding: review {
          ...auditFindingFragment
        }
      }
    }
  }
  ${auditFindingReviewFragment}
  ${auditFindingFragment}
`;

const auditFindingWithReviewFragment = gql`
  fragment auditFindingWithReviewFragment on AuditFinding {
    ...auditFindingFragment
    ...auditFindingReviewsFragment
    original: auditFindingReviewByReviewId {
      ...auditFindingReviewFragment
      auditFinding: original {
        ...auditFindingFragment
        ...auditFindingReviewsFragment
      }
    }
  }
  ${auditFindingReviewsFragment}
  ${auditFindingFragment}
  ${auditFindingReviewFragment}
`;

const batchClaimLineItemWithAuditFragment = gql`
  fragment batchClaimLineItemWithAuditFragment on BatchClaimLineItem {
    ...batchClaimLineItemFragment
    batchClaimLineItemAudit {
      id
      auditFinding {
        ...auditFindingWithReviewFragment
      }
    }
  }
  ${batchClaimLineItemFragment}
  ${auditFindingWithReviewFragment}
`;

const batchClaimLineDataFragment = gql`
  fragment batchClaimLineDataFragment on BatchClaimLine {
    id
    lineNumber
    procedureCode
    procedureDescription
    revCode
    revDescription
    unitAllowableCharge
    unitCharge
    units
    paymentRate
    computedValues: batchClaimLineComputedValueById {
      id
      determinedDiscrepantAmount
      determinedAdjustedAllowedAmount
      determinedAdjustedBilledAmount
    }
    diag1
    diag1Description
    diag2
    diag2Description
    diag3
    diag3Description
    diag4
    diag4Description
  }
`;

const batchClaimLineFragment = gql`
  fragment batchClaimLineFragment on BatchClaimLine {
    ...batchClaimLineDataFragment
    auditFindings(filter: { and: [{ isActive: { equalTo: true } }] }) {
      totalCount
      nodes {
        id
        ...auditFindingWithReviewFragment
      }
    }
    batchClaimLineItems {
      nodes {
        ...batchClaimLineItemFragment
      }
    }
  }
  ${batchClaimLineItemFragment}
  ${batchClaimLineDataFragment}
  ${auditFindingWithReviewFragment}
`;

/*
  Alternative determined filter for auditFinding
  this filter will compute the determined findings as those are accepted 
  AND
  do not have any future versions that have been accepted
  filter: {
    accepted: { equalTo: true },
    # future versions
    auditFindingsByPreviousVersionId: {
      none: {
        or: [
          { accepted: { equalTo: true } },
          # future versions can also be interpreted as reviews
          { 
            auditFindingReviewsByOriginalId: { 
              some: { review: { accepted: { equalTo: true } } }
            } 
          }
        ]
      }
    }
  }
  Much less computationally intensive, and still highly specific (but not quite as strict)
  determined findings are defined by findings that are:
   accepted 
   and of the latest version
    ** or **
  from an old version, but are: 
    reported
    do not have any finding reviews
    do not have any **future** versions
*/
// DEPRECATED AND CURRENTLY UNUSED
const _batchClaimDeterminedAndPotentialFragment = gql`
  fragment batchClaimDeterminedAndPotentialFragment on BatchClaim {
    id
    determined: auditFindings(
      filter: {
        and: [{ accepted: { equalTo: true } }, { isActive: { equalTo: true } }]
      }
    ) {
      aggregates {
        sum {
          improperPaymentCost
        }
      }
    }
    potential: auditFindings(
      filter: {
        and: [
          { superseded: { equalTo: false } }
          { reviewedAuditFindingsExist: false }
          { isValid: { equalTo: true } }
          { isActive: { equalTo: true } }
        ]
      }
    ) {
      aggregates {
        sum {
          improperPaymentCost
        }
      }
    }
  }
`;

const eventHistoryTimelineEventsFragment = gql`
  fragment eventHistoryTimelineEventsFragment on HistoryTimelineEvents {
    events {
      id
      eventType
      action
      metaData
      createdAt: created_at
    }
  }
`;

// used for custom requests
const eventHistoryTimelineQuery = gql`
  query eventHistoryTimelineQuery(
    $batchClaimId: UUID!
    $limit: Int!
    $offset: Int!
  ) {
    batchClaim(id: $batchClaimId) {
      id
      eventHistoryTimeline(limit: $limit, offset: $offset) {
        ...eventHistoryTimelineEventsFragment
      }
    }
  }
  ${eventHistoryTimelineEventsFragment}
`;

const batchClaimState = gql`
  fragment batchClaimState on BatchClaim {
    id
    batchClaimState: batchClaimStateById {
      id
      onHoldClaimState
      documentationClaimState
      reported
      disputedFindingClaimState
      workflowClaimState
      negotiationClaimState
      providerSignOffClaimState
      clientSignOffClaimState
      invoiceClaimState
      isProviderActive
      isRealized
      determinedDiscrepantAmount
      potentialDiscrepantAmount
      potentialClientSavings
      determinedClientSavings
      potentialAdjustedAllowedAmount
      determinedAdjustedAllowedAmount
    }
  }
`;

const batchClaimDocumentationMetaDataFragment = gql`
  fragment batchClaimDocumentationMetaDataFragment on BatchClaim {
    id
    batchClaimState: batchClaimStateById {
      id
      documentationClaimState
      workflowClaimState
      isProviderActive
      documentationClaimState
      workflowClaimState
      isProviderActive
    }
  }
`;

const batchClaimEventHistoryTimeLineQuery = gql`
  query batchClaimEventHistoryTimeLineQuery($batchClaimIds: [UUID!]) {
    batchClaims(filter: { id: { in: $batchClaimIds } }) {
      nodes {
        id
        eventHistoryTimeline(limit: 30, offset: 0) {
          ...eventHistoryTimelineEventsFragment
        }
      }
    }
  }
  ${eventHistoryTimelineEventsFragment}
`;

// defaultclaimListQueryData is the return values from claimList that is shared and same for all user types
//  - leaves out:
//    - duedates
//    - assignees
const defaultclaimListQueryData = gql`
  fragment defaultclaimListQueryData on BatchClaim {
    id
    icn
    ...batchClaimState # stats like enumclaimsStates, reported, det/pot clientSavings, discrepantAmount, & adjustedAllowedAmount live in batchClaimState
    dateOfServiceStart
    dateOfServiceEnd
    dateAdmit
    paymentRate
    dateDischarge
    amountCharged
    amountReimbursed
    claimType
    createdAt
    provider {
      ...providerFragment
    }
  }
  ${providerFragment}
  ${batchClaimState}
`;

// Extends the defaultclaimListQueryData to match Admin/Auditor users:
//  - leaves out nothing
//  - adds in:
//    - duedates
//    - assignees
//    - s3AuditDocumentations with correct filter for Admin/Auditor
const adminAndAuditorClaimListQueryData = gql`
  fragment claimListDataFragment on BatchClaim {
    id
    ...defaultclaimListQueryData
    activeAssignees {
      nodes {
        id
        firstName
        lastName
      }
    }
    activeDueDate: batchClaimStateById {
      id
      dueDate
    }

    # As of EN-73 & EN-74 this 's3AuditDocumentations' code should NOT be removed for the custom resolver 'auditDocumentation' due to performance concerns
    # For more information read this: https://www.notion.so/BatchClaim-Performance-c6a3d1615fb341ca82e45af029932f27?pvs=4#b327751738244574b5c341c8e6ce641c
    # TODO: there is future work to be done here to enable these filters to be defined in the backend without the performanvce hit that comes from a custom resolver
    s3AuditDocumentations(
      filter: {
        and: [
          { isUploaded: { equalTo: true } }
          {
            or: [
              { process: { isNull: true } }
              {
                and: [
                  { process: { in: ["ocr", "annotated"] } }
                  { subFile: { likeInsensitive: "%.pdf" } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
    }
  }
  ${defaultclaimListQueryData}
`;

// Extends the defaultclaimListQueryData to match Payer/Provider users:
//  - adds in:
//    - s3AuditDocumentations with correct filter for Payer/Provider
const payerAndProviderClaimListQueryData = gql`
  fragment claimListDataFragment on BatchClaim {
    id
    ...defaultclaimListQueryData

    # As of EN-73 & EN-74 this 's3AuditDocumentations' code should NOT be removed for the custom resolver 'auditDocumentation' due to performance concerns
    # For more information read this: https://www.notion.so/BatchClaim-Performance-c6a3d1615fb341ca82e45af029932f27?pvs=4#b327751738244574b5c341c8e6ce641c
    # TODO: there is future work to be done here to enable these filters to be defined in the backend without the performanvce hit that comes from a custom resolver
    s3AuditDocumentations(
      filter: {
        and: [
          { isUploaded: { equalTo: true } }
          {
            or: [
              { process: { isNull: true } }
              {
                and: [
                  { process: { in: ["ocr"] } }
                  { subFile: { likeInsensitive: "%.pdf" } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      totalCount
    }
  }
  ${defaultclaimListQueryData}
`;

/**
 * README (KY Feb, 2023 EN-73):
 *  This fragment is designed to be called within workstation queries NOT the claimList search queries (for those use the defaultclaimListQueryData fragment)
 *  This is because this has the 'batchClaimDocumentationFragment' which uses a cuxstom resolver that returns signedUrls for uploaded documents
 *  when 'batchClaimDocumentationFragment' is called many times for multiple claims within the claimList search queries we see huge performance hits.
 *
 *  --> Therefore it is advised to use this fragment only when we explicitly need the results of the 'auditDocumentation' query such as signedUrl
 *      which is needed in the workstation for example
 */
const batchClaimDataFragment = gql`
  fragment batchClaimDataFragment on BatchClaim {
    id
    icn
    ...batchClaimState # stats like enumclaimsStates, reported, det/pot clientSavings, discrepantAmount, & adjustedAllowedAmount live in batchClaimState
    ...batchClaimDocumentationFragment # uses the custom gql resolver 'auditDocumentation' enabling the return of the signedUrl -- (this is a relativley slow query)
    dateOfServiceStart
    dateOfServiceEnd
    dateAdmit
    dateDischarge
    amountCharged
    amountReimbursed
    computationStrategy
    paymentRate
    claimType
    createdAt
    provider {
      ...providerFragment
    }
  }
  ${batchClaimDocumentationFragment}
  ${providerFragment}
  ${batchClaimState}
`;

const claimLevelAuditTypesQuery = gql`
  query auditFindingRuleTypes {
    auditFindingRuleTypes(
      filter: {
        type: { startsWith: "UB_CLAIM", notStartsWith: "UB_CLAIM_LINE" }
      }
    ) {
      nodes {
        type
        displayName
        description
        rationaleTemplate
      }
    }
  }
`;

const claimLineLevelAuditTypesFragment = gql`
  query auditFindingRuleTypes {
    auditFindingRuleTypes(
      filter: {
        type: {
          startsWith: "UB_CLAIM_LINE"
          notStartsWith: "UB_CLAIM_LINE_ITEM"
        }
      }
    ) {
      nodes {
        type
        displayName
        description
      }
    }
  }
`;

const auditFindingsClaimListFragment = gql`
  query auditFindingsClaimList {
    auditFindings(
      filter: {
        and: [
          { isActive: { equalTo: true } }
          {
            or: [
              {
                reviewedAuditFindings: { some: { deleted: { equalTo: false } } }
              }
              { reviewedAuditFindingsExist: false }
            ]
          }
        ]
      }
    ) {
      totalCount
    }
  }
`;

const adminAndAuditorWorkstationBatchClaimFragment = gql`
  fragment adminAndAuditorWorkstationBatchClaimFragment on BatchClaim {
    id
    ...batchClaimDataFragment
    activeDueDate: batchClaimStateById {
      id
      dueDate
    }
    activeAssignees {
      nodes {
        id
        firstName
        lastName
      }
    }
    provider {
      ...providerFragment
      auditorUsers {
        nodes {
          id
        }
      }
    }
    batchClaimLines(${BATCH_CLAIM_LINE_SORT}) {
      nodes {
        ...batchClaimLineFragment
      }
    }
    ubClaimFindings: auditFindings(
      filter: {
        and: [
          { isOriginal: { equalTo: true } }
          { auditFindingSeedType: { equalTo: UB_CLAIM } }
        ]
      }
    ) {
      nodes {
        ...auditFindingWithReviewFragment
        __typename
      }
      __typename
    }
    ## all audit findings for the claim
    auditFindings {
      nodes {
        ...auditFindingFragment
      }
      totalCount
    }
  }
  ${batchClaimDataFragment}
  ${batchClaimLineFragment}
  ${providerFragment}
  ${auditFindingWithReviewFragment}
  ${auditFindingFragment}
`;

// The provider workstation Query only returns UB_CLAIM findings while Payer returns all findings
const providerBatchClaimWorkstationFragment = gql`
  fragment providerBatchClaimWorkstationFragment on BatchClaim {
    id
    ...batchClaimDataFragment
    batchClaimLines(${BATCH_CLAIM_LINE_SORT}) {
      nodes {
        ...batchClaimLineDataFragment
        auditFindings(
          filter: {
            and: [
              { isActive: { equalTo: true } }
              { auditFindingSeedType: { equalTo: UB_CLAIM } }
            ]
          }
        ) {
          totalCount
          nodes {
            id
            ...auditFindingWithReviewFragment
          }
        }
      }
    }
    ubClaimFindings: auditFindings(
      filter: {
        and: [
          { isOriginal: { equalTo: true } }
          { auditFindingSeedType: { equalTo: UB_CLAIM } }
        ]
      }
    ) {
      nodes {
        ...auditFindingWithReviewFragment
        __typename
      }
      __typename
    }
    # ONLY UB_CLAIM audit findings for the claim
    auditFindings(filter: { auditFindingSeedType: { equalTo: UB_CLAIM } }) {
      nodes {
        ...auditFindingFragment
      }
      totalCount
    }
  }
  ${batchClaimDataFragment}
  ${batchClaimLineDataFragment}
  ${auditFindingFragment}
  ${auditFindingWithReviewFragment}
`;

const payerBatchClaimWorkstationFragment = gql`
  fragment payerBatchClaimWorkstationFragment on BatchClaim {
    ...batchClaimDataFragment
    provider {
      ...providerFragment
    }
    batchClaimLines(${BATCH_CLAIM_LINE_SORT}) {
      nodes {
        ...batchClaimLineFragment
      }
    }
    ubClaimFindings: auditFindings(
      filter: {
        and: [
          { isOriginal: { equalTo: true } }
          { auditFindingSeedType: { equalTo: UB_CLAIM } }
        ]
      }
    ) {
      nodes {
        ...auditFindingWithReviewFragment
        __typename
      }
      __typename
    }
    ## all audit findings for the claim
    auditFindings {
      nodes {
        ...auditFindingFragment
      }
      totalCount
    }
  }
  ${batchClaimDataFragment}
  ${batchClaimLineFragment}
  ${providerFragment}
  ${auditFindingWithReviewFragment}
  ${auditFindingFragment}
  ${reviewedAuditFindingFragment}
`;

export {
  assignableAuditorUsersQuery,
  batchClaimDocumentationMetaDataFragment,
  providerFragment,
  auditorUserFragment,
  batchClaimLineItemFragment,
  reviewedAuditFindingFragment,
  auditFindingReviewFragment,
  auditFindingFragment,
  auditFindingReviewsFragment,
  auditFindingWithReviewFragment,
  batchClaimLineItemWithAuditFragment,
  batchClaimLineDataFragment,
  batchClaimLineFragment,
  batchClaimDocumentationFragment,
  batchClaimDataFragment,
  batchClaimEventHistoryTimeLineQuery,
  providerBatchClaimWorkstationFragment,
  adminAndAuditorWorkstationBatchClaimFragment,
  defaultclaimListQueryData,
  adminAndAuditorClaimListQueryData,
  payerAndProviderClaimListQueryData,
  claimLevelAuditTypesQuery,
  claimLineLevelAuditTypesFragment,
  auditFindingsClaimListFragment,
  batchClaimState,
  eventHistoryTimelineEventsFragment,
  eventHistoryTimelineQuery,
  payerBatchClaimWorkstationFragment,
  versionAuditFindingFragment,
};
