"use client";

import {
  Alert,
  AlertCircle,
  AlertDescription,
  AlertTitle,
} from "@alaffia-technology-solutions/ui";

interface ErrorMessageProps {
  error: string | null;
}

export const PDFErrorMessage = ({ error }: ErrorMessageProps) => {
  return (
    <div className="af-flex af-h-full af-w-full af-items-center af-justify-center">
      <Alert
        variant="destructive"
        showCloseButton={false}
        className="af-mx-auto af-w-max"
      >
        <AlertCircle className="af-h-4 af-w-4" />
        <AlertTitle>Error</AlertTitle>
        <AlertDescription>{error}</AlertDescription>
      </Alert>
    </div>
  );
};
