/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { createContext } from "react";

import { useLog } from "../../common/log/useLog";
import { useSubject } from "../../util/Observable";

export const UnhandledRejectionObservable = createContext<any>(null);

export const UnhandledRejectionsCatch = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const log = useLog("UnhandledRejectionsCatch");
  const [next, observable] = useSubject();
  window.onunhandledrejection = (err) => {
    const reason =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      err?.reason?.message || err?.reason?.reason || err?.message || null;
    const msg = `Unhandled promise rejection${reason ? ": " + reason : ""}`;
    // DOC: enable more or less visibility based on env
    if (process.env.REACT_APP_DD_ENV === "development") {
      log.warn(msg, err);
    } else {
      // in prod, always debug log, so we have for troubleshooting
      console.debug(msg, err);
    }
    err.preventDefault();
    next(err);
  };

  return (
    <UnhandledRejectionObservable.Provider value={observable}>
      {children}
    </UnhandledRejectionObservable.Provider>
  );
};
