import { graphql } from "../gql";

export const ratePromptResponseMutationDoc = graphql(`
  mutation ratePromptResponse(
    $promptResponseId: ID!
    $rating: PromptResponseRatingType
  ) {
    ratePromptResponse(promptResponseId: $promptResponseId, rating: $rating) {
      ...PromptResponseWithRatingsFragment
    }
  }
`);
