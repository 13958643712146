import { Subject } from "rxjs";

import type { LoggerType } from "../client/log";
import { getLogger } from "../client/log";
import type { AuthDataLoginFields, AuthDataStrategy } from "./types";

export class MemoryAuthDataStrategy implements AuthDataStrategy {
  protected _userId: string | null = null;
  protected _username: string | null = null;
  protected _userRole: string | null = null;
  protected _token: string | null = null;
  protected _expires = 0;
  protected _refreshToken: string | null = null;
  protected _raw: Record<string, unknown> | null = null;
  protected _url: string | null = null;

  constructor({ url }: { url: string; initialValues?: AuthDataLoginFields }) {
    this._url = url;
  }

  get userId() {
    return this._userId;
  }
  get username() {
    return this._username;
  }
  get userRole() {
    return this._userRole;
  }
  get token() {
    return this._token;
  }
  get expires() {
    return this._expires;
  }
  get refreshToken() {
    return this._refreshToken;
  }
  get raw() {
    return this._raw;
  }
  get url() {
    return this._url;
  }

  setLoginFields({
    url,
    userId,
    username,
    userRole,
    token,
    expires,
    refreshToken,
    raw,
  }: AuthDataLoginFields) {
    if (this.url !== url) {
      throw new Error(
        "cannot set login fields, url provided url does not match initial url",
      );
    }
    this._userId = userId;
    this._username = username;
    this._userRole = userRole;
    this._token = token;
    this._expires = expires;
    this._refreshToken = refreshToken;
    this._raw = raw;
  }

  clearLoginFields() {
    if (
      this._userId !== null ||
      this._username !== null ||
      this._userRole !== null ||
      this._token !== null ||
      this._expires > 0 ||
      this._refreshToken !== null ||
      this._raw !== null
    ) {
      this._userId = null;
      this._username = null;
      this._userRole = null;
      this._token = null;
      this._expires = 0;
      this._refreshToken = null;
      this._raw = null;
      return true;
    }
    return false;
  }
}

export class LocalStorageAuthDataStrategy
  extends MemoryAuthDataStrategy
  implements AuthDataStrategy
{
  private logger: LoggerType;
  private readonly DELIM = String.fromCharCode(7); //'^';
  private dataChangeSubject = new Subject<boolean>();
  readonly dataChangeObservable = this.dataChangeSubject.asObservable();

  constructor(args: { url: string }) {
    super(args);
    this.logger = getLogger("LSAuthDataStrategy");
    // this.logger.setLevel(this.logger.levels.DEBUG, false);
    if (this._url) {
      this.logger.trace("checking for restorable auth");
      const stored = localStorage.getItem(this._url);
      if (stored) {
        this.logger.debug("restoring auth info");
        this.restoreStoredAuth(stored);
      }
      if (window && window.addEventListener) {
        window.addEventListener("storage", (storageEvent) => {
          this.logger.debug("storage event", { storageEvent });
          if (storageEvent?.key === this._url) {
            if (storageEvent.newValue) {
              // if new auth set, restore and notify
              this.logger.debug("restoring auth info on storage event");
              this.restoreStoredAuth(storageEvent.newValue);
              this.dataChangeSubject.next(true);
            } else {
              // if cleared, leave alone, since updating on other tabs may keep state on current tab;
              // at some point, we may decide we need to enforce immediate logout on all tabs when triggered on one
              //      this.restoreStoredAuth(storageEvent.newValue);
              //      this.dataChangeSubject.next(false);
            }
          }
        });
      }
    }
  }

  private restoreStoredAuth(storedAuth: string) {
    const arr = decodeURIComponent(atob(storedAuth)).split(this.DELIM);
    if (arr.length < 7) {
      this.logger.error(
        "Could not extract login info from storage... clearing storage.",
      );
      this.clearLoginFields();
    } else if (arr[5] !== this.url) {
      this.logger.error("Stored login info does not match current url...");
    } else {
      this.logger.trace("writing auth info");
      super.setLoginFields({
        userId: arr[0] ?? null,
        userRole: arr[1] ?? null,
        token: arr[2] ?? null,
        expires: parseInt(String(arr[3])),
        refreshToken: arr[4] ?? null,
        raw: null,
        url: arr[5],
        username: arr[6] ?? null,
      });
    }
  }

  setLoginFields(args: AuthDataLoginFields) {
    if (!this._url) {
      throw new Error("URL is not set");
    }
    const toStore = [
      args.userId,
      args.userRole,
      args.token,
      args.expires,
      args.refreshToken,
      // no raw...
      this._url,
      args.username,
    ];

    localStorage.setItem(
      this._url,
      btoa(encodeURIComponent(toStore.join(this.DELIM))),
    );
    this.logger.debug("persisted auth ", args);
    super.setLoginFields(args);
  }

  clearLoginFields() {
    if (!this._url) {
      throw new Error("URL is not set");
    }
    this.logger.debug("clearing persisted auth");
    localStorage.removeItem(this._url);
    return super.clearLoginFields();
  }
}
