import DOMPurify from "dompurify";
import type { HTMLReactParserOptions } from "html-react-parser";
import parse from "html-react-parser";

export default function htmlToReact(
  html: string | string[],
  opts: HTMLReactParserOptions | undefined = undefined,
) {
  const _html = Array.isArray(html) ? html.join("") : html;
  return parse(DOMPurify.sanitize(_html), opts);
}
