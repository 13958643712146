import * as React from "react";
import { GripVertical } from "lucide-react";
import * as ResizablePrimitive from "react-resizable-panels";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const ResizablePanelGroup = ({
  className,
  ...props
}: React.ComponentProps<typeof ResizablePrimitive.PanelGroup>) => (
  <ResizablePrimitive.PanelGroup
    className={cn(
      "af-flex af-h-full af-w-full data-[panel-group-direction=vertical]:af-flex-col",
      className,
    )}
    {...props}
  />
);

const ResizablePanel = ResizablePrimitive.Panel;

interface ResizableHandleProps
  extends React.ComponentProps<typeof ResizablePrimitive.PanelResizeHandle> {
  withHandle?: boolean;
}

const ResizableHandle: React.FC<ResizableHandleProps> = ({
  withHandle,
  className,
  ...props
}) => (
  <ResizablePrimitive.PanelResizeHandle
    className={cn(
      "data-[panel-group-direction=vertical]:af-after data-[panel-group-direction=vertical]:af-after data-[panel-group-direction=vertical]:af-after data-[panel-group-direction=vertical]:af-after data-[panel-group-direction=vertical]:af-after af-relative af-flex af-w-px af-items-center af-justify-center af-bg-border after:af-absolute after:af-inset-y-0 after:af-left-1/2 after:af-w-1 after:-af-translate-x-1/2 focus-visible:af-outline-none focus-visible:af-ring-1 focus-visible:af-ring-ring focus-visible:af-ring-offset-1 data-[panel-group-direction=vertical]:af-h-px data-[panel-group-direction=vertical]:af-w-full [&[data-panel-group-direction=vertical]>div]:af-rotate-90",
      className,
    )}
    {...props}
  >
    {withHandle && (
      <div className="af-z-10 af-flex af-h-4 af-w-3 af-items-center af-justify-center af-rounded-sm af-border af-border-border af-bg-border">
        <GripVertical className="af-h-2.5 af-w-2.5 af-text-foreground" />
      </div>
    )}
  </ResizablePrimitive.PanelResizeHandle>
);

export { ResizablePanelGroup, ResizablePanel, ResizableHandle };
