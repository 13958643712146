import * as React from "react";
import { LexicalComposer as OriginalLexicalComposer } from "@lexical/react/LexicalComposer";

import { KeywordListNode, KeywordNode } from "./plugins/KeywordsPlugin";
import { MentionNode } from "./plugins/MentionsPlugin";

type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

type OriginalLexicalComposerProps = React.ComponentPropsWithoutRef<
  typeof OriginalLexicalComposer
>;
type LexicalComposerProps = Omit<
  OriginalLexicalComposerProps,
  "initialConfig"
> & {
  initialConfig: PartialBy<
    OriginalLexicalComposerProps["initialConfig"],
    keyof typeof defaultConfig
  >;
};

export const defaultConfig = {
  onError: console.error,
  nodes: [MentionNode, KeywordNode, KeywordListNode],
};

export function LexicalComposer(props: LexicalComposerProps) {
  const initialConfig = {
    ...defaultConfig,
    ...props.initialConfig,
  };

  return <OriginalLexicalComposer {...props} initialConfig={initialConfig} />;
}
