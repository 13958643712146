/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import * as types from "./graphql";

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
  "\n  query askAutodorFileVectorsExistQuery(\n    $input: AskAutodorFileVectorsExistInput\n  ) {\n    askAutodorFileVectorsExist(input: $input) {\n      documentIds\n    }\n  }\n":
    types.askAutodorFileVectorsExistQueryDocument,
  "\n  fragment batchClaimLineItemFragment on BatchClaimLineItem {\n    id\n    dateOfServiceStart\n    dateOfServiceEnd\n    revCode\n    procedureCode\n    itemNumber\n    description\n    units\n    unitCharge\n    batchClaimLineId\n    authorId\n    createdAt\n    updatedAt\n    alaRowId\n    ibLineId\n    batchClaimLineItemNnIbLines {\n      nodes {\n        nnIbLineId\n        nnIbLine {\n          fingerprint\n        }\n      }\n    }\n  }\n":
    types.batchClaimLineItemFragmentFragmentDoc,
  "\n  fragment reviewedAuditFindingFragment on ReviewedAuditFinding {\n    id\n    accepted\n    deleted\n    createdAt\n    updatedAt\n    rationale\n  }\n":
    types.reviewedAuditFindingFragmentFragmentDoc,
  "\n  fragment auditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleTypeByAuditFindingRuleType {\n      type\n      description\n      displayName\n    }\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    metaDataNcciMueOhpMueValue\n    metaDataNcciMueAjudicationValue\n    metaDataNcciPtpModifier\n    metaDataNcciPtpCode\n    metaDataEmCode\n    metaDataEmOriginalLevel\n    metaDataEmCorrectLevel\n    metaDataAocPrimaryCode\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    discrepantAmount # At the auditFindings level there is no determined vs potential because this calculates the number on that finding\n    author {\n      id\n      firstName\n      lastName\n      # userType\n      email\n    }\n    reviewedAuditFinding: latestReview {\n      ...reviewedAuditFindingFragment\n    }\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n    reportedFindings {\n      totalCount\n      nodes {\n        id\n        auditFindingReportId\n      }\n    }\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n  }\n":
    types.auditFindingFragmentFragmentDoc,
  "\n  fragment auditFindingReviewFragment on AuditFindingReview {\n    id\n    createdAt\n    # needed for update cache\n    reviewId\n    originalId\n  }\n":
    types.auditFindingReviewFragmentFragmentDoc,
  "\n  fragment auditFindingReviewsFragment on AuditFinding {\n    id\n    reviews: auditFindingReviewsByOriginalId(orderBy: CREATED_AT_DESC) {\n      totalCount\n      nodes {\n        ...auditFindingReviewFragment\n        auditFinding: review {\n          ...auditFindingFragment\n        }\n      }\n    }\n  }\n":
    types.auditFindingReviewsFragmentFragmentDoc,
  "\n  fragment auditFindingWithReviewFragment on AuditFinding {\n    ...auditFindingFragment\n    ...auditFindingReviewsFragment\n    original: auditFindingReviewByReviewId {\n      ...auditFindingReviewFragment\n      auditFinding: original {\n        ...auditFindingFragment\n        ...auditFindingReviewsFragment\n      }\n    }\n  }\n":
    types.auditFindingWithReviewFragmentFragmentDoc,
  "\n  fragment versionAuditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n  }\n":
    types.versionAuditFindingFragmentFragmentDoc,
  "\n  mutation createIbLineFindings(\n    $input: CreateIbLineFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: createIbLineFindings(input: $input) {\n      auditFindings {\n        ...auditFindingWithReviewFragment\n      }\n    }\n  }\n":
    types.createIbLineFindingsDocument,
  "\n  mutation versionAuditFindings(\n    $input: VersionAuditFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: versionAuditFindings(input: $input) {\n      auditFindings {\n        ...versionAuditFindingFragment\n      }\n    }\n  }\n":
    types.versionAuditFindingsDocument,
  "\n  fragment autodorFindingFragment on AutodorFinding {\n    __typename\n    auditType\n    claimId\n    createdAt\n    s3Key\n    denialCode\n    description\n    ibLineId\n    ibLineVersionFingerprint\n    id\n    improperCharge\n    improperUnits\n    seedType\n    stale\n    ubLineId\n    autodorMetadata {\n      auditType\n      billType\n      dateOfServiceStart\n      lineNumber\n      procedureCode\n      procedureCodeModifier\n      quarter\n      unitCharge\n      units\n      versionId\n      year\n      rule {\n        _type\n        __typename\n        genericRule\n        ptp {\n          colOneCode\n          colTwoCode\n          extendedDescription\n          rationale\n          __typename\n        }\n        mue {\n          description\n          code\n          num\n          rationale {\n            value\n            enumeration\n            __typename\n          }\n          editType\n          mai\n          __typename\n        }\n        nonBillable {\n          code\n          description\n          __typename\n        }\n      }\n    }\n  }\n":
    types.autodorFindingFragmentFragmentDoc,
  "\n  query claim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n      autodor {\n        claimId\n        sinks {\n          s3Key\n          timestamp\n        }\n        autodorFindings {\n          ...autodorFindingFragment\n        }\n      }\n      itemizedBillLines {\n        ...ItemizedBillLineFragment\n      }\n    }\n  }\n":
    types.claimDocument,
  "\n  query providerClaim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      itemizedBillLines {\n        ...ProviderItemizedBillLineFragment\n      }\n    }\n  }\n":
    types.providerClaimDocument,
  "\n  query claimFindings($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n    }\n  }\n":
    types.claimFindingsDocument,
  "\n  fragment FindingItemizedBillDataFragment on FindingItemizedBillData {\n    __typename\n    alaRowId\n    authorId\n    batchClaimLineId\n    createdAt\n    dateOfServiceEnd\n    dateOfServiceStart\n    description\n    ibLineId\n    id\n    itemNumber\n    lineNumber\n    procedureCode\n    procedureCodeModifier\n    revCode\n    s3InputSource\n    stale\n    unitCharge\n    units\n    updatedAt\n    versionFingerprint\n    ibLineVersionFingerprint\n    originalBilledAmount\n  }\n":
    types.FindingItemizedBillDataFragmentFragmentDoc,
  "\n  fragment FindingFragment on Finding {\n    __typename\n    autodorFindingId\n    autodorFindingS3Key\n    findingItemizedBillData {\n      ...FindingItemizedBillDataFragment\n    }\n    accepted\n    author {\n      firstName\n      id\n      lastName\n      userType\n    }\n    createdAt\n    confidence\n    id\n    improperPaymentCost\n    improperPaymentReason\n    improperPaymentUnitsCharged\n    isOriginal\n    metaDataAocPrimaryCode\n    metaDataEmCode\n    metaDataEmCorrectLevel\n    metaDataEmOriginalLevel\n    metaDataNcciMueAdjudicationValue\n    metaDataNcciMueOhpMueValue\n    metaDataNcciPtpCode\n    metaDataNcciPtpModifier\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    needsReview\n    ruleType\n    seedType\n    superseded\n    updatedAt\n    latestReview {\n      authorId\n      createdAt\n      rationale\n    }\n  }\n":
    types.FindingFragmentFragmentDoc,
  "\n  query Finding($id: UUID) {\n    finding(id: $id) {\n      ...FindingFragment\n    }\n  }\n":
    types.FindingDocument,
  "\n  fragment ItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    findings\n    s3InputSourceId\n    versionFingerprint\n    groupCode\n  }\n":
    types.ItemizedBillLineFragmentFragmentDoc,
  "\n  fragment ProviderItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    s3InputSourceId\n    versionFingerprint\n  }\n":
    types.ProviderItemizedBillLineFragmentFragmentDoc,
  "\n  query ItemizedBillLines($ids: [UUID!]!) {\n    itemizedBillLines(ids: $ids) {\n      ...ItemizedBillLineFragment\n    }\n  }\n":
    types.ItemizedBillLinesDocument,
  "\n  query auditFindingRuleTypes {\n    auditFindingRuleTypes {\n      nodes {\n        type\n        claimDataType\n        deprecated\n        displayName\n        description\n        rationaleTemplate\n      }\n    }\n  }\n":
    types.auditFindingRuleTypesDocument,
  "\n  fragment batchClaimDocumentationFragment on BatchClaim {\n    id\n    auditDocumentation {\n      id\n      subFile\n      fileName\n      process\n      size\n      signedUrl\n      __typename\n    }\n  }\n":
    types.batchClaimDocumentationFragmentFragmentDoc,
  "\n  query documentationViewer($claimId: UUID!) {\n    batchClaim(id: $claimId) {\n      ...batchClaimDocumentationFragment\n    }\n  }\n":
    types.documentationViewerDocument,
  "\n  query reportDownloadQuery($reportId: UUID!) {\n    auditFindingReport(id: $reportId) {\n      id\n      title\n      downloadUrl\n    }\n  }\n":
    types.reportDownloadQueryDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query askAutodorFileVectorsExistQuery(\n    $input: AskAutodorFileVectorsExistInput\n  ) {\n    askAutodorFileVectorsExist(input: $input) {\n      documentIds\n    }\n  }\n",
): (typeof documents)["\n  query askAutodorFileVectorsExistQuery(\n    $input: AskAutodorFileVectorsExistInput\n  ) {\n    askAutodorFileVectorsExist(input: $input) {\n      documentIds\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment batchClaimLineItemFragment on BatchClaimLineItem {\n    id\n    dateOfServiceStart\n    dateOfServiceEnd\n    revCode\n    procedureCode\n    itemNumber\n    description\n    units\n    unitCharge\n    batchClaimLineId\n    authorId\n    createdAt\n    updatedAt\n    alaRowId\n    ibLineId\n    batchClaimLineItemNnIbLines {\n      nodes {\n        nnIbLineId\n        nnIbLine {\n          fingerprint\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment batchClaimLineItemFragment on BatchClaimLineItem {\n    id\n    dateOfServiceStart\n    dateOfServiceEnd\n    revCode\n    procedureCode\n    itemNumber\n    description\n    units\n    unitCharge\n    batchClaimLineId\n    authorId\n    createdAt\n    updatedAt\n    alaRowId\n    ibLineId\n    batchClaimLineItemNnIbLines {\n      nodes {\n        nnIbLineId\n        nnIbLine {\n          fingerprint\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment reviewedAuditFindingFragment on ReviewedAuditFinding {\n    id\n    accepted\n    deleted\n    createdAt\n    updatedAt\n    rationale\n  }\n",
): (typeof documents)["\n  fragment reviewedAuditFindingFragment on ReviewedAuditFinding {\n    id\n    accepted\n    deleted\n    createdAt\n    updatedAt\n    rationale\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment auditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleTypeByAuditFindingRuleType {\n      type\n      description\n      displayName\n    }\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    metaDataNcciMueOhpMueValue\n    metaDataNcciMueAjudicationValue\n    metaDataNcciPtpModifier\n    metaDataNcciPtpCode\n    metaDataEmCode\n    metaDataEmOriginalLevel\n    metaDataEmCorrectLevel\n    metaDataAocPrimaryCode\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    discrepantAmount # At the auditFindings level there is no determined vs potential because this calculates the number on that finding\n    author {\n      id\n      firstName\n      lastName\n      # userType\n      email\n    }\n    reviewedAuditFinding: latestReview {\n      ...reviewedAuditFindingFragment\n    }\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n    reportedFindings {\n      totalCount\n      nodes {\n        id\n        auditFindingReportId\n      }\n    }\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n  }\n",
): (typeof documents)["\n  fragment auditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleTypeByAuditFindingRuleType {\n      type\n      description\n      displayName\n    }\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    metaDataNcciMueOhpMueValue\n    metaDataNcciMueAjudicationValue\n    metaDataNcciPtpModifier\n    metaDataNcciPtpCode\n    metaDataEmCode\n    metaDataEmOriginalLevel\n    metaDataEmCorrectLevel\n    metaDataAocPrimaryCode\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    discrepantAmount # At the auditFindings level there is no determined vs potential because this calculates the number on that finding\n    author {\n      id\n      firstName\n      lastName\n      # userType\n      email\n    }\n    reviewedAuditFinding: latestReview {\n      ...reviewedAuditFindingFragment\n    }\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n    reportedFindings {\n      totalCount\n      nodes {\n        id\n        auditFindingReportId\n      }\n    }\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment auditFindingReviewFragment on AuditFindingReview {\n    id\n    createdAt\n    # needed for update cache\n    reviewId\n    originalId\n  }\n",
): (typeof documents)["\n  fragment auditFindingReviewFragment on AuditFindingReview {\n    id\n    createdAt\n    # needed for update cache\n    reviewId\n    originalId\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment auditFindingReviewsFragment on AuditFinding {\n    id\n    reviews: auditFindingReviewsByOriginalId(orderBy: CREATED_AT_DESC) {\n      totalCount\n      nodes {\n        ...auditFindingReviewFragment\n        auditFinding: review {\n          ...auditFindingFragment\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment auditFindingReviewsFragment on AuditFinding {\n    id\n    reviews: auditFindingReviewsByOriginalId(orderBy: CREATED_AT_DESC) {\n      totalCount\n      nodes {\n        ...auditFindingReviewFragment\n        auditFinding: review {\n          ...auditFindingFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment auditFindingWithReviewFragment on AuditFinding {\n    ...auditFindingFragment\n    ...auditFindingReviewsFragment\n    original: auditFindingReviewByReviewId {\n      ...auditFindingReviewFragment\n      auditFinding: original {\n        ...auditFindingFragment\n        ...auditFindingReviewsFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment auditFindingWithReviewFragment on AuditFinding {\n    ...auditFindingFragment\n    ...auditFindingReviewsFragment\n    original: auditFindingReviewByReviewId {\n      ...auditFindingReviewFragment\n      auditFinding: original {\n        ...auditFindingFragment\n        ...auditFindingReviewsFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment versionAuditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment versionAuditFindingFragment on AuditFinding {\n    id\n    improperPaymentUnitsCharged\n    improperPaymentCost\n    improperPaymentReason\n    needsReview\n    auditorReviewed\n    auditorComment\n    auditType\n    superseded\n    auditFindingRuleType\n    auditFindingSeedType\n    confidence\n    isOriginal\n    isActive\n    accepted\n    createdAt\n    # needed for updating cache\n    batchClaimLineId\n    batchClaimId\n    batchClaimLineItemAudit {\n      id\n      batchClaimLineItem {\n        ...batchClaimLineItemFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation createIbLineFindings(\n    $input: CreateIbLineFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: createIbLineFindings(input: $input) {\n      auditFindings {\n        ...auditFindingWithReviewFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  mutation createIbLineFindings(\n    $input: CreateIbLineFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: createIbLineFindings(input: $input) {\n      auditFindings {\n        ...auditFindingWithReviewFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  mutation versionAuditFindings(\n    $input: VersionAuditFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: versionAuditFindings(input: $input) {\n      auditFindings {\n        ...versionAuditFindingFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  mutation versionAuditFindings(\n    $input: VersionAuditFindingsInput! # $afclFilter: AuditFindingFilter!\n  ) {\n    createAuditFindings: versionAuditFindings(input: $input) {\n      auditFindings {\n        ...versionAuditFindingFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment autodorFindingFragment on AutodorFinding {\n    __typename\n    auditType\n    claimId\n    createdAt\n    s3Key\n    denialCode\n    description\n    ibLineId\n    ibLineVersionFingerprint\n    id\n    improperCharge\n    improperUnits\n    seedType\n    stale\n    ubLineId\n    autodorMetadata {\n      auditType\n      billType\n      dateOfServiceStart\n      lineNumber\n      procedureCode\n      procedureCodeModifier\n      quarter\n      unitCharge\n      units\n      versionId\n      year\n      rule {\n        _type\n        __typename\n        genericRule\n        ptp {\n          colOneCode\n          colTwoCode\n          extendedDescription\n          rationale\n          __typename\n        }\n        mue {\n          description\n          code\n          num\n          rationale {\n            value\n            enumeration\n            __typename\n          }\n          editType\n          mai\n          __typename\n        }\n        nonBillable {\n          code\n          description\n          __typename\n        }\n      }\n    }\n  }\n",
): (typeof documents)["\n  fragment autodorFindingFragment on AutodorFinding {\n    __typename\n    auditType\n    claimId\n    createdAt\n    s3Key\n    denialCode\n    description\n    ibLineId\n    ibLineVersionFingerprint\n    id\n    improperCharge\n    improperUnits\n    seedType\n    stale\n    ubLineId\n    autodorMetadata {\n      auditType\n      billType\n      dateOfServiceStart\n      lineNumber\n      procedureCode\n      procedureCodeModifier\n      quarter\n      unitCharge\n      units\n      versionId\n      year\n      rule {\n        _type\n        __typename\n        genericRule\n        ptp {\n          colOneCode\n          colTwoCode\n          extendedDescription\n          rationale\n          __typename\n        }\n        mue {\n          description\n          code\n          num\n          rationale {\n            value\n            enumeration\n            __typename\n          }\n          editType\n          mai\n          __typename\n        }\n        nonBillable {\n          code\n          description\n          __typename\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query claim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n      autodor {\n        claimId\n        sinks {\n          s3Key\n          timestamp\n        }\n        autodorFindings {\n          ...autodorFindingFragment\n        }\n      }\n      itemizedBillLines {\n        ...ItemizedBillLineFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query claim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n      autodor {\n        claimId\n        sinks {\n          s3Key\n          timestamp\n        }\n        autodorFindings {\n          ...autodorFindingFragment\n        }\n      }\n      itemizedBillLines {\n        ...ItemizedBillLineFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query providerClaim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      itemizedBillLines {\n        ...ProviderItemizedBillLineFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query providerClaim($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      itemizedBillLines {\n        ...ProviderItemizedBillLineFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query claimFindings($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n    }\n  }\n",
): (typeof documents)["\n  query claimFindings($id: UUID) {\n    claim(id: $id) {\n      __typename\n      id\n      icn\n      findings {\n        ...FindingFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FindingItemizedBillDataFragment on FindingItemizedBillData {\n    __typename\n    alaRowId\n    authorId\n    batchClaimLineId\n    createdAt\n    dateOfServiceEnd\n    dateOfServiceStart\n    description\n    ibLineId\n    id\n    itemNumber\n    lineNumber\n    procedureCode\n    procedureCodeModifier\n    revCode\n    s3InputSource\n    stale\n    unitCharge\n    units\n    updatedAt\n    versionFingerprint\n    ibLineVersionFingerprint\n    originalBilledAmount\n  }\n",
): (typeof documents)["\n  fragment FindingItemizedBillDataFragment on FindingItemizedBillData {\n    __typename\n    alaRowId\n    authorId\n    batchClaimLineId\n    createdAt\n    dateOfServiceEnd\n    dateOfServiceStart\n    description\n    ibLineId\n    id\n    itemNumber\n    lineNumber\n    procedureCode\n    procedureCodeModifier\n    revCode\n    s3InputSource\n    stale\n    unitCharge\n    units\n    updatedAt\n    versionFingerprint\n    ibLineVersionFingerprint\n    originalBilledAmount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment FindingFragment on Finding {\n    __typename\n    autodorFindingId\n    autodorFindingS3Key\n    findingItemizedBillData {\n      ...FindingItemizedBillDataFragment\n    }\n    accepted\n    author {\n      firstName\n      id\n      lastName\n      userType\n    }\n    createdAt\n    confidence\n    id\n    improperPaymentCost\n    improperPaymentReason\n    improperPaymentUnitsCharged\n    isOriginal\n    metaDataAocPrimaryCode\n    metaDataEmCode\n    metaDataEmCorrectLevel\n    metaDataEmOriginalLevel\n    metaDataNcciMueAdjudicationValue\n    metaDataNcciMueOhpMueValue\n    metaDataNcciPtpCode\n    metaDataNcciPtpModifier\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    needsReview\n    ruleType\n    seedType\n    superseded\n    updatedAt\n    latestReview {\n      authorId\n      createdAt\n      rationale\n    }\n  }\n",
): (typeof documents)["\n  fragment FindingFragment on Finding {\n    __typename\n    autodorFindingId\n    autodorFindingS3Key\n    findingItemizedBillData {\n      ...FindingItemizedBillDataFragment\n    }\n    accepted\n    author {\n      firstName\n      id\n      lastName\n      userType\n    }\n    createdAt\n    confidence\n    id\n    improperPaymentCost\n    improperPaymentReason\n    improperPaymentUnitsCharged\n    isOriginal\n    metaDataAocPrimaryCode\n    metaDataEmCode\n    metaDataEmCorrectLevel\n    metaDataEmOriginalLevel\n    metaDataNcciMueAdjudicationValue\n    metaDataNcciMueOhpMueValue\n    metaDataNcciPtpCode\n    metaDataNcciPtpModifier\n    metaDataPricingCorrectPrice\n    metaDataUnitsCorrectUnits\n    needsReview\n    ruleType\n    seedType\n    superseded\n    updatedAt\n    latestReview {\n      authorId\n      createdAt\n      rationale\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query Finding($id: UUID) {\n    finding(id: $id) {\n      ...FindingFragment\n    }\n  }\n",
): (typeof documents)["\n  query Finding($id: UUID) {\n    finding(id: $id) {\n      ...FindingFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    findings\n    s3InputSourceId\n    versionFingerprint\n    groupCode\n  }\n",
): (typeof documents)["\n  fragment ItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    findings\n    s3InputSourceId\n    versionFingerprint\n    groupCode\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment ProviderItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    s3InputSourceId\n    versionFingerprint\n  }\n",
): (typeof documents)["\n  fragment ProviderItemizedBillLineFragment on ItemizedBillLine {\n    __typename\n    alaRowId\n    id\n    dateOfService\n    itemNumber\n    revCode\n    description\n    procedureCode\n    procedureCodeModifier\n    units\n    totalCharge\n    lineNumber\n    s3InputSourceId\n    versionFingerprint\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query ItemizedBillLines($ids: [UUID!]!) {\n    itemizedBillLines(ids: $ids) {\n      ...ItemizedBillLineFragment\n    }\n  }\n",
): (typeof documents)["\n  query ItemizedBillLines($ids: [UUID!]!) {\n    itemizedBillLines(ids: $ids) {\n      ...ItemizedBillLineFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query auditFindingRuleTypes {\n    auditFindingRuleTypes {\n      nodes {\n        type\n        claimDataType\n        deprecated\n        displayName\n        description\n        rationaleTemplate\n      }\n    }\n  }\n",
): (typeof documents)["\n  query auditFindingRuleTypes {\n    auditFindingRuleTypes {\n      nodes {\n        type\n        claimDataType\n        deprecated\n        displayName\n        description\n        rationaleTemplate\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  fragment batchClaimDocumentationFragment on BatchClaim {\n    id\n    auditDocumentation {\n      id\n      subFile\n      fileName\n      process\n      size\n      signedUrl\n      __typename\n    }\n  }\n",
): (typeof documents)["\n  fragment batchClaimDocumentationFragment on BatchClaim {\n    id\n    auditDocumentation {\n      id\n      subFile\n      fileName\n      process\n      size\n      signedUrl\n      __typename\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query documentationViewer($claimId: UUID!) {\n    batchClaim(id: $claimId) {\n      ...batchClaimDocumentationFragment\n    }\n  }\n",
): (typeof documents)["\n  query documentationViewer($claimId: UUID!) {\n    batchClaim(id: $claimId) {\n      ...batchClaimDocumentationFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(
  source: "\n  query reportDownloadQuery($reportId: UUID!) {\n    auditFindingReport(id: $reportId) {\n      id\n      title\n      downloadUrl\n    }\n  }\n",
): (typeof documents)["\n  query reportDownloadQuery($reportId: UUID!) {\n    auditFindingReport(id: $reportId) {\n      id\n      title\n      downloadUrl\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> =
  TDocumentNode extends DocumentNode<infer TType, any> ? TType : never;
