import * as React from "react";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@alaffia-technology-solutions/ui";

import { SavedPrompts, SavedPromptsButton } from "./SavedPrompts";

export interface SavedPrompt {
  name: string;
  promptInputState: string;
}

export interface SavedPromptsPopoverProps {
  filteredPrompts: SavedPrompt[];
  onPromptSelected?: (prompt: SavedPrompt) => void;
  searchPhrase?: string;
  onSearchPhraseChange?: (phrase: string) => void;
}

export function SavedPromptsPopover({
  filteredPrompts,
  onPromptSelected,
  searchPhrase,
  onSearchPhraseChange,
}: SavedPromptsPopoverProps) {
  const [isOpen, setIsOpen] = React.useState(false);

  const onSelect = (prompt: SavedPrompt) => {
    onPromptSelected?.(prompt);
    setIsOpen(false);
  };

  return (
    <Popover open={isOpen} onOpenChange={setIsOpen}>
      <PopoverTrigger asChild>
        <SavedPromptsButton />
      </PopoverTrigger>
      <PopoverContent className="af-w-auto af-max-w-[100vw]">
        <SavedPrompts>
          <SavedPrompts.Header onClose={() => setIsOpen(false)} />
          <SavedPrompts.Search
            searchPhrase={searchPhrase}
            onSearchPhraseChange={onSearchPhraseChange}
          />
          <SavedPrompts.List>
            {filteredPrompts.map((prompt) => (
              <SavedPrompts.ListItem
                key={prompt.name}
                onClick={() => onSelect(prompt)}
              >
                {prompt.name}
              </SavedPrompts.ListItem>
            ))}
          </SavedPrompts.List>
        </SavedPrompts>
      </PopoverContent>
    </Popover>
  );
}
