/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Tag, Tooltip } from "antd";

import { UserContext } from "../context/user";
import { getPermittedStatesAsArray } from "./claimStateUtil";

/**
 * Renders all valid enum states for the claim as icons or as tags (default is tags)
 * @param {*} batchClaim --> Object BatchClaim
 * @param {*} useIcons --> Boolean
 * @param {*} showNAText --> Boolean
 * @param {*} stateTypesToShow --> EnumStateTypeField[]
 * @returns jsx component of icons or Tags
 */

const EnumClaimStates = ({
  batchClaim,
  useIcons,
  stateTypesToShow,
  showNAText,
}: any) => {
  const { userType, permissions } = React.useContext(UserContext);

  /* 
    gets the states array based on:
      user permissions, batchClaim context, & states that are not filtered by stateTypesToShow
  */
  const states = getPermittedStatesAsArray({
    permissions,
    stateTypesToShow: stateTypesToShow ? stateTypesToShow : null, // default stateTypesToShow is all
    dataTypes: ["enum"],
  }).filter(({ state }) => state.displayCondition(batchClaim));

  return states.length > 0 ? (
    <div tw="af-flex af-flex-wrap">
      {states.map(({ stateType, stateName, state }) => {
        const formattedStateName = stateName.toLowerCase().replaceAll("_", "-");
        return (
          <div key={`${stateType}-${formattedStateName}-span`} tw="af-pb-1.5">
            <Tooltip
              style={{ margin: "auto" }}
              title={state.description(userType)}
              color={state.tagColor}
              key={`${stateType}-${formattedStateName}-state-tooltip`}
              id={`${stateType}-${formattedStateName}-state-tooltip`}
            >
              {!useIcons ? (
                <Tag
                  color={state.tagColor}
                  key={`${stateType}-${formattedStateName}-tag`}
                  id={`${stateType}-${formattedStateName}-tag`}
                  data-cy={`${stateType}-${formattedStateName}-tag`}
                >
                  <div tw="af-flex af-items-center af-justify-center">
                    <div tw="af-pt-0.5 af-pr-1">{state.icon}</div>
                    <div tw="">{state.name(userType)}</div>
                  </div>
                </Tag>
              ) : (
                <span
                  data-cy={`${stateType}-${formattedStateName}-icon`}
                  key={`${stateType}-${formattedStateName}-icon`}
                  id={`${stateType}-${formattedStateName}-icon`}
                >
                  {state.icon}
                </span>
              )}
            </Tooltip>
          </div>
        );
      })}
    </div>
  ) : (
    <div>{showNAText && "-"}</div>
  );
};

export { EnumClaimStates };
