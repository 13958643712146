import { graphql } from "../gql";

export const getClaimCasesQueryDoc = graphql(`
  query getClaimCases($claimId: UUID!) {
    claimCases(claimId: $claimId) {
      __typename
      id
      name
      createdAt
      completed
    }
  }
`);
