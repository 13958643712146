"use client";

import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import { cva } from "class-variance-authority";

import { createSafeContext } from "@alaffia-technology-solutions/react-utils";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";

type TabsVariant = "filled" | "minimal";

interface TabsContextValue {
  variant: TabsVariant;
}

const [TabsContextProvider, useTabsContext] =
  createSafeContext<TabsContextValue>(
    "useTabsContext must be used within a TabsContextProvider",
  );

interface TabsProps {
  variant?: TabsVariant;
}

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root> & TabsProps
>(({ variant = "filled", ...props }, ref) => (
  <TabsContextProvider
    value={{
      variant,
    }}
  >
    <TabsPrimitive.Root ref={ref} {...props} />
  </TabsContextProvider>
));
Tabs.displayName = TabsPrimitive.Root.displayName;

const tabsListVariants = cva(
  "af-flex af-w-min af-justify-center af-rounded-lg af-bg-muted af-text-muted-foreground data-[orientation=horizontal]:af-items-center data-[orientation=vertical]:af-flex-col data-[orientation=vertical]:af-items-start data-[orientation=vertical]:af-justify-start",
  {
    variants: {
      variant: {
        filled: "data-[orientation=horizontal]:af-h-9 af-p-1",
        minimal: "",
      },
    },
  },
);

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => {
  const { variant } = useTabsContext();

  return (
    <TabsPrimitive.List
      ref={ref}
      className={cn(tabsListVariants({ variant }), className)}
      {...props}
    />
  );
});
TabsList.displayName = TabsPrimitive.List.displayName;

const tabsTriggerVariants = cva(
  "af-inline-flex af-items-center af-justify-center af-whitespace-nowrap af-text-sm af-font-medium af-ring-offset-background af-transition-all focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2 disabled:af-pointer-events-none disabled:af-opacity-50",
  {
    variants: {
      variant: {
        filled:
          "af-rounded-md data-[state=active]:af-shadow data-[orientation=horizontal]:af-px-3 data-[orientation=horizontal]:af-py-1 data-[orientation=vertical]:af-px-1 data-[orientation=vertical]:af-py-3 data-[state=active]:af-bg-background",
        minimal:
          "af-gap-1 af-text-sm af-font-semibold data-[state=active]:af-text-black data-[state=inactive]:af-text-[#396BEE] data-[state=active]:af-underline [&>svg]:data-[state=active]:af-text-[#6F6E77]",
      },
    },
  },
);

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => {
  const { variant } = useTabsContext();

  return (
    <TabsPrimitive.Trigger
      ref={ref}
      className={cn(tabsTriggerVariants({ variant }), className)}
      {...props}
    />
  );
});
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "af-bg-background af-text-foreground af-ring-offset-background focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2",
      "data-[orientation=horizontal]:af-mt-2",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
