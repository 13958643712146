import React from "react";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";

import type { Message } from "@alaffia-technology-solutions/schema";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import { LexicalComposer } from "./../Chatbox/LexicalComposer";
import { DateTime } from "./../DateTime";

interface QuestionLexicalComposerProps
  extends React.HTMLAttributes<HTMLDivElement> {
  state: string | null;
}

export const QuestionLexicalComposer = React.memo(
  ({ state, className, ...props }: QuestionLexicalComposerProps) => {
    const initialConfig = {
      namespace: "message",
      editable: false,
      editorState: state,
    };
    // NOTE: use key to enforce re-rendering when state changes
    return (
      <LexicalComposer initialConfig={initialConfig} key={state}>
        <PlainTextPlugin
          contentEditable={<ContentEditable className={className} {...props} />}
          placeholder={null}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </LexicalComposer>
    );
  },
);

type QuestionItemProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLLIElement> & {
    message: Pick<Message, "promptInputState" | "createdAt">;
  }
>;

export const QuestionItem = React.forwardRef<HTMLLIElement, QuestionItemProps>(
  ({ message, className, children, ...props }, ref) => {
    return (
      <li
        className={cn("af-flex af-flex-col af-items-start af-gap-3", className)}
        ref={ref}
        {...props}
      >
        <div className="af-flex af-items-center af-gap-2 af-text-xs af-text-gray-500">
          Question <span>|</span>{" "}
          <DateTime date={message.createdAt} icon={null} />
        </div>
        <QuestionLexicalComposer
          state={message.promptInputState}
          className="af-text-sm"
        />
        <ul className="af-flex af-w-full af-flex-col af-gap-4 af-pt-1">
          {children}
        </ul>
      </li>
    );
  },
);
