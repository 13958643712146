/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, message } from "antd";
import { gql, useMutation } from "urql";

import { batchClaimState } from "../../../fragments";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";

// mutation to update workflow workflowState
const setWorkflowStateMutation = gql`
  mutation workflowStateMutation(
    $batchClaimIds: [UUID!]!
    $workflowState: WorkflowClaimStateEnum!
  ) {
    setWorkflowClaimStates(
      input: { workflowState: $workflowState, batchClaimIds: $batchClaimIds }
    ) {
      workflowClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

// workflow actions
const typeActions = {
  setToDo: {
    title: "To Do",
    state: "TO_DO",
    actionCriteria: ({
      batchClaimState: {
        workflowClaimState: _workflowClaimState,
        reported: _reported,
      },
    }: any) => {
      return { satisfied: true };
    },
  },
  setClaimReady: {
    title: "Ready",
    state: "CLAIM_READY",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
  setInProgress: {
    title: "In Progress",
    state: "IN_PROGRESS",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
  setComplete: {
    title: "Complete",
    state: "COMPLETED",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
  setReportedToProvider: {
    title: "Reported to Provider",
    state: "REPORTED_TO_PROVIDER",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
  setReportedToClient: {
    title: "Reported to Client",
    state: "REPORTED_TO_CLIENT",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
  setClosed: {
    title: "Closed",
    state: "CLOSED",
    actionCriteria: () => {
      return { satisfied: true };
    },
  },
};

const workflowMenuItem = ({
  action,
  title,
  state,
  batchClaims,
  actionCriteria,
  setWorkflowState,
  setLoading,
}: any) => {
  // find the claims for which the action criteria is satisfied
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );
  return (
    <Menu.Item
      key={`workflow-menuitem-${action}-${batchClaimsNodeKey(batchClaims)}`}
      disabled={
        // disabled if no claims satisfy the action criteria
        !actionClaims.length
      }
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setWorkflowState({
          workflowState: state,
          batchClaimIds: actionClaims.map(({ id }: any) => id),
        });
        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          void message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  const [_, setWorkflowState] = useMutation(setWorkflowStateMutation);

  return (
    <Menu.SubMenu
      key={`workflow-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="Workflow"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions.workflow.includes(action),
        )
        .map(([action, { ...actionProps }]) => {
          return workflowMenuItem({
            action,
            batchClaims,
            setWorkflowState,
            setLoading,
            ...actionProps,
          });
        })}
    </Menu.SubMenu>
  );
};

const workflow = {
  title: "Workflow State",
  subMenu: actionSubMenu,
};

export { workflow, setWorkflowStateMutation };
