import type { ICellRendererParams } from "ag-grid-community";
import { Empty } from "antd";

export const NoDataOverlay = (
  props: ICellRendererParams & { noDataMessage: string },
) => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span tw="af-text-antd-lt-colorTextSecondary dark:af-text-antd-dk-colorTextSecondary">
          {props.noDataMessage}
        </span>
      }
    />
  );
};
