import type { ColDef } from "ag-grid-community";

import type {
  FindingDisplayAugmented,
  IBGridLine,
} from "~/components/audit/claimWorkspace/itemizedBillTab/ibTable/types";

export const IBGridLineDefaultColumn: ColDef<IBGridLine> = {
  enableRowGroup: true,
  sortable: true,
  resizable: true,
  filter: true,
  wrapText: true,
  wrapHeaderText: true,
  menuTabs: ["filterMenuTab", "generalMenuTab"],
};

export const FindingDisplayAugmentedDefaultColumn: ColDef<FindingDisplayAugmented> =
  {
    enableRowGroup: true,
    sortable: true,
    resizable: true,
    filter: true,
    wrapText: true,
    wrapHeaderText: true,
    menuTabs: ["filterMenuTab", "generalMenuTab"],
  };
