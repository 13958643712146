/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";

import { idbGetValue, idbSetValue, idbTryUnwrapErrorEvent } from "./indexedDB";

export function useUserPreference(name: any) {
  const [pref, setPref] = useState(undefined);
  const [error, setError] = useState(null);
  const [needsUpdate, setNeedsUpdate] = useState(null);

  useEffect(() => {
    loadPreference(
      name,
      (result: any) => setPref(result),
      (err: any) => setError(err),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsUpdate]);

  function savePref(update: any) {
    savePreference(
      name,
      update,
      () => setNeedsUpdate(update),
      (err: any) => setError(err),
    );
  }

  return [pref, savePref, error];
}

// DOC: (MV) these exported functions are primarily for tests but can be used if needed in non-effect code.
// if changed (or call semantics in above hook change) the test should be updated.

export function loadPreference(key: any, onSuccess: any, onError: any) {
  idbGetValue(key)
    .then((result) => onSuccess(result))
    .catch((err) => onError(idbTryUnwrapErrorEvent(err)));
}

export function savePreference(
  key: any,
  value: any,
  onSuccess: any,
  onError: any,
) {
  idbSetValue(key, value)
    .then((result) => onSuccess(result))
    .catch((err) => onError(idbTryUnwrapErrorEvent(err)));
}
