/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { FileSearchOutlined } from "@ant-design/icons";
import { Button } from "antd";

import { UserContext } from "../../context/user";
import { usePdfViewerDialog } from "../../documentViewer/PdfViewerProvider";

export const ViewDocumentsButton: React.FC<{ claimId: string }> = ({
  claimId,
}) => {
  const user = React.useContext(UserContext) as unknown as Record<string, any>;
  const { open } = usePdfViewerDialog();

  return (
    <>
      <Button
        id="view-documents-button"
        icon={<FileSearchOutlined />}
        onClick={() => {
          const { viewDocs, viewDocList } = user.permissions;
          if (viewDocs && viewDocList) {
            open({ claimId });
          } else if (viewDocList) {
            // TODO
            throw new Error("TODO");
          }
        }}
        css={{
          padding: "0 7px",
          fontSize: "12px",
          lineHeight: "20px",
          height: "auto",
        }}
      >
        View Documents
      </Button>
    </>
  );
};
