import { graphql } from "../gql";

export const getDirectoryQueryDoc = graphql(`
  query getDirectory($id: ID, $itemsInput: DirectoryItemsInput) {
    directory(id: $id) {
      ...DirectoryFragment
      path {
        ...DirectoryPathNodeFragment
      }
      items(input: $itemsInput) {
        ... on Directory {
          ...DirectoryFragment
        }
        ... on File {
          ...FileFragment
        }
      }
    }
  }
`);
