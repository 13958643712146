"use client";

import type { ComponentProps, ReactNode } from "react";
import { forwardRef, useEffect, useRef, useState } from "react";
import {
  CloudDownload,
  Minus,
  Plus,
  RotateCcw,
  RotateCw,
  Search,
  X,
} from "lucide-react";

import { useResizeObserver } from "@alaffia-technology-solutions/hooks";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import {
  Button,
  Input,
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationNext,
  PaginationPrevious,
  Separator,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@alaffia-technology-solutions/ui";

import type { RotateDirection, ZoomStep } from "../helper-types";

interface ToolbarProps {
  zoomScale: number | "fitWidth" | "fitHeight";
  currentPage: number | null;
  pagesCount: number | null;
  disabled?: boolean;
  onJumpToPage: (pageIndex: number) => void;
  onNextPage: () => void;
  onPrevPage: () => void;
  onZoom: (step: ZoomStep) => void;
  onRotate: (direction: RotateDirection) => void;
  onSearch: (query: string) => void;
  url?: string;
  searchEnabled?: boolean;
  searchTooltip?: ReactNode;
}

export const PDFToolbar = ({
  zoomScale,
  pagesCount,
  currentPage,
  disabled,
  onJumpToPage,
  onNextPage,
  onPrevPage,
  onRotate,
  onZoom,
  onSearch,
  url,
  searchEnabled = false,
  searchTooltip,
}: ToolbarProps) => {
  const pageValue = currentPage === 0 || currentPage ? currentPage + 1 : "";
  const [searchInputValue, setSearchInputValue] = useState("");
  const [isSearchBarExpanded, setIsSearchBarExpanded] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { width: wrapperWidth } = useResizeObserver({ ref: wrapperRef });
  const clearButtonRef = useRef<HTMLButtonElement>(null);
  const searchButtonRef = useRef<HTMLButtonElement>(null);

  const formattedZoomScale =
    typeof zoomScale === "number"
      ? `${Math.round(zoomScale * 100)}%`
      : zoomScale === "fitWidth"
      ? "Fit Width"
      : "Fit Height";

  const isMobile = Boolean(wrapperWidth && wrapperWidth < 768);

  const inputRef = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (isSearchBarExpanded) inputRef.current?.focus();
  }, [isSearchBarExpanded]);

  const handleInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget !== clearButtonRef.current &&
      e.relatedTarget !== searchButtonRef.current
    ) {
      setIsSearchBarExpanded(false);
    }
  };

  return (
    <div
      className="af-grid af-w-full af-auto-cols-auto af-grid-flow-col af-justify-between af-gap-y-2 af-overflow-hidden af-overflow-x-auto af-border-b af-border-b-border af-px-4 af-py-2 md:af-items-center"
      ref={wrapperRef}
    >
      <PDFToolbarSearchInput
        value={searchInputValue}
        onValueChange={setSearchInputValue}
        isMobile={isMobile}
        isExpanded={isSearchBarExpanded}
        onIsExpandedChange={setIsSearchBarExpanded}
        onSearch={onSearch}
        onBlur={handleInputBlur}
        disabled={!searchEnabled || disabled}
        icon={
          !searchEnabled && searchTooltip ? (
            searchTooltip
          ) : (
            <div className="af-flex">
              <PDFToolbarSearchIconButton
                title="Clear input"
                ref={clearButtonRef}
                disabled={disabled}
                onClick={() => {
                  onSearch("");
                  setSearchInputValue("");
                  setIsSearchBarExpanded(false);
                }}
                className={cn({
                  "af-hidden": searchInputValue === "",
                })}
              >
                <X size={14} />
              </PDFToolbarSearchIconButton>
              <PDFToolbarSearchIconButton
                title="Search"
                ref={searchButtonRef}
                disabled={disabled}
                onClick={() => {
                  onSearch(searchInputValue);
                }}
              >
                <Search size={20} />
              </PDFToolbarSearchIconButton>
            </div>
          )
        }
      />

      {!isSearchBarExpanded && (
        <Pagination className="af-m-0 af-w-min af-justify-self-center af-text-foreground">
          <PaginationContent className="af-gap-2">
            <PaginationItem>
              <PaginationPrevious
                onClick={onPrevPage}
                aria-disabled={disabled}
                className={cn(
                  "af-h-8 af-p-1 md:af-p-2",
                  disabled && "af-pointer-events-none af-opacity-50",
                )}
              />
            </PaginationItem>
            <div className="af-flex af-flex-nowrap af-items-center af-gap-2 af-text-nowrap af-text-xs md:af-justify-center md:af-text-sm">
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                <span className="af-sr-only">Page number</span>
                <Input
                  min={1}
                  max={pagesCount ?? 1}
                  type="number"
                  className="af-hide-arrow-controls af-h-full af-w-11 af-p-1 af-text-center af-text-xs md:af-text-sm"
                  value={pageValue}
                  onChange={(e) => {
                    const value = parseInt(e.target.value);
                    onJumpToPage(value - 1);
                  }}
                  disabled={disabled}
                />
              </label>
              <div className={cn("af-min-w-max", disabled && "af-opacity-50")}>
                {" "}
                / {pagesCount}
              </div>
            </div>
            <PaginationItem>
              <PaginationNext
                onClick={onNextPage}
                aria-disabled={disabled}
                className={cn(
                  "af-h-8 af-p-1 md:af-p-2",
                  disabled && "af-pointer-events-none af-opacity-50",
                )}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      )}

      <div className="af-flex af-h-full af-w-full af-flex-nowrap af-items-center af-justify-center af-gap-4 af-self-center af-text-xs af-text-foreground md:af-justify-end md:af-text-sm">
        <Button
          size="icon"
          variant="ghost"
          onClick={() => onRotate("left")}
          disabled={disabled}
        >
          <RotateCcw size={16} />
        </Button>
        <Button
          size="icon"
          variant="ghost"
          onClick={() => onRotate("right")}
          disabled={disabled}
        >
          <RotateCw size={16} />
        </Button>
        {url && (
          <Button size="icon" variant="ghost" disabled={disabled} asChild>
            <a
              href={url}
              target="_blank"
              rel="noreferrer"
              aria-label="Download PDF"
            >
              <CloudDownload size={16} />
            </a>
          </Button>
        )}
        <Separator orientation="vertical" className="h-2/3" />
        <div className="af-flex-no-wrap af-flex af-items-center af-gap-2">
          <Button
            variant="ghost"
            size="icon"
            onClick={() => onZoom(-0.1)}
            disabled={disabled}
          >
            <Minus size={16} />
          </Button>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  variant="ghost"
                  size="sm"
                  className="af-text-nowrap"
                  onClick={() => onZoom("fitWidth")}
                  aria-label={`Current zoom scale: ${formattedZoomScale}. Click to fit width`}
                >
                  {formattedZoomScale}
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <div className="af-text-xs af-text-popover-foreground">
                  Click to fit width
                </div>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>

          <Button
            variant="ghost"
            size="icon"
            onClick={() => onZoom(0.1)}
            disabled={disabled}
          >
            <Plus size={16} />
          </Button>
        </div>
      </div>
    </div>
  );
};

interface PDFToolbarSearchInputProps extends ComponentProps<typeof Input> {
  isMobile: boolean;
  isExpanded: boolean;
  onIsExpandedChange: (expanded: boolean) => void;
  onSearch: (query: string) => void;
  value: string;
  onValueChange: (value: string) => void;
}

const PDFToolbarSearchInput = forwardRef<
  HTMLInputElement,
  PDFToolbarSearchInputProps
>(
  (
    {
      isMobile,
      isExpanded,
      onIsExpandedChange,
      onSearch,
      value,
      onValueChange,
      ...props
    },
    ref,
  ) => {
    if (isMobile && !isExpanded) {
      return (
        <Button
          variant="ghost"
          size="icon"
          type="button"
          onClick={() => {
            onIsExpandedChange(!isExpanded);
          }}
        >
          <span className="af-sr-only">Search</span>
          <Search size={20} />
        </Button>
      );
    }

    return (
      <Input
        className="af-w-full af-min-w-36"
        placeholder="Search"
        ref={ref}
        value={value}
        onChange={(e) => onValueChange(e.target.value)}
        onKeyUp={(e) => {
          if (value === "") {
            onSearch("");
          }
          if (e.key === "Enter") {
            onSearch(value);
          }
        }}
        iconPosition="right"
        {...props}
      />
    );
  },
);

interface PDFToolbarSearchIconButtonProps
  extends ComponentProps<typeof Button> {
  title: string;
}

const PDFToolbarSearchIconButton = forwardRef<
  HTMLButtonElement,
  PDFToolbarSearchIconButtonProps
>(({ children, title, ...props }, ref) => {
  return (
    <Button variant="ghost" size="icon" type="button" ref={ref} {...props}>
      <span className="af-sr-only">{title}</span>
      {children}
    </Button>
  );
});
