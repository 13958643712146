/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from "urql";

// FIXME
import {
  adminAndAuditorClaimListQueryData,
  adminAndAuditorWorkstationBatchClaimFragment,
  auditorUserFragment,
  payerAndProviderClaimListQueryData,
  payerBatchClaimWorkstationFragment,
  providerBatchClaimWorkstationFragment,
} from "../fragments";
// fragments
import { queueFragment } from "./fragments";

// ------ Queues ------
// Queries that return Queue or Queues

/**
 * Returns a queue
 * @params
 *    queueId: UUID!
 */
const queueQuery = gql`
  query queue($queueId: UUID!) {
    queue(id: $queueId) {
      ...queueFragment
    }
  }
  ${queueFragment}
`;

/**
 * A query for returning a user's queues
 * restricted to only queues created by
 * the requesting user. Returns all
 * fields on <Queue>
 */
const queuesQuery = gql`
  query queuesQuery {
    queues(orderBy: CREATED_AT_DESC, filter: { deleted: { equalTo: false } }) {
      nodes {
        ...queueFragment
      }
    }
  }
  ${queueFragment}
`;

// ------ AuditorUsers ------
// Queries that return AuditorUser or AuditorUsers

/**
 * A query for returning a list of the PIA users
 * @returns [<AuditorUser>]
 */
const piaListQuery = gql`
  query piaList {
    auditorUsers(filter: { userType: { equalTo: AUDITOR } }) {
      nodes {
        ...auditorUserFragment
      }
    }
  }
  ${auditorUserFragment}
`;

// ------ BatchClaims ------
// Queries that return BatchClaim or BatchClaims

/**
 * Function that generates a query that returns a batch claim count.
 * Takes as input a queryTitle so it can be identified by name
 *  And a queryType (e.g. 'batchClaims') as the base type to query for
 * Returns the count of claims in the batch claim filter. Will be
 * impacted by the security policy of the user
 * @params BatchClaimFilter!
 */
const claimCountQuery = ({ queryTitle, queryType }: any) => gql`
  query ${queryTitle}($batchClaimFilter: BatchClaimFilter) {
    ${queryType}(filter: $batchClaimFilter) {
      totalCount
    }
  }
`;

/**
 * Uses the provider claim fragment to query
 * fields for the workstation
 * @params BatchClaimId!
 */
const providerWorkstationQuery = gql`
  query providerWorkstationQuery($batchClaimId: UUID!) {
    batchClaim(id: $batchClaimId) {
      id
      ...providerBatchClaimWorkstationFragment
    }
  }
  ${providerBatchClaimWorkstationFragment}
`;

/**
 * Uses the full claim fragment to query
 * fields for the workstation
 * @params BatchClaimId!
 */
const adminAndAuditorWorkstationQuery = gql`
  query adminAuditWorkspace($batchClaimId: UUID!) {
    batchClaim(id: $batchClaimId) {
      id
      ...adminAndAuditorWorkstationBatchClaimFragment
    }
  }
  ${adminAndAuditorWorkstationBatchClaimFragment}
`;

/**
 * Uses the full claim fragment to query
 * fields for the workstation
 * @params BatchClaimId!
 */
const payerWorkstationQuery = gql`
  query payerWorkstationQuery($batchClaimId: UUID!) {
    batchClaim(id: $batchClaimId) {
      id
      ...payerBatchClaimWorkstationFragment
    }
  }
  ${payerBatchClaimWorkstationFragment}
`;

/**
 * Queries claims for a claim list (i.e. in a search)
 * returing only the fields needed for an admin user
 * @params BatchClaimFilter!
 */
const adminClaimListQuery = gql`
  query adminClaimListQuery(
    $batchClaimFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
    $orderBy: [BatchClaimsOrderBy!]
  ) {
    batchClaims(
      filter: $batchClaimFilter
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        ...claimListDataFragment
        computationStrategy
        paymentRate
      }
    }
  }
  ${adminAndAuditorClaimListQueryData}
`;

/**
 * Queries claims for a claim list (i.e. in a search)
 * returing only the fields needed for an admin user
 * @params BatchClaimFilter!
 */
const auditorClaimListQuery = gql`
  query auditorClaimListQuery(
    $batchClaimFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
    $orderBy: [BatchClaimsOrderBy!]
  ) {
    batchClaimsAssignedToAuditor(
      filter: $batchClaimFilter
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        ...claimListDataFragment
        computationStrategy
        paymentRate
      }
    }
  }
  ${adminAndAuditorClaimListQueryData}
`;

/**
 * Queries claims for a claim list returing only
 * the fields needed for a provider user
 * @params (required) BatchClaimFilter!
 */
const providerClaimListQuery = gql`
  query providerClaimListQuery(
    $batchClaimFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
    $orderBy: [BatchClaimsOrderBy!]
  ) {
    batchClaims(
      filter: $batchClaimFilter
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        ...claimListDataFragment
      }
    }
  }
  ${payerAndProviderClaimListQueryData}
`;

/**
 * Queries claims for a claim list returing only
 * the fields needed for a payer user
 * @params (required) BatchClaimFilter!
 */
const payerClaimListQuery = gql`
  query payerClaimListQuery(
    $batchClaimFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
    $orderBy: [BatchClaimsOrderBy!]
  ) {
    batchClaims(
      filter: $batchClaimFilter
      first: $first
      offset: $offset
      orderBy: $orderBy
    ) {
      totalCount
      nodes {
        ...claimListDataFragment
      }
    }
  }
  ${payerAndProviderClaimListQueryData}
`;

// ------ AuditFindings ------
// Queries that return AuditFinding or AuditFindings

// ------ Aggregates ------
// Queries that return a type AuditFindingAggregates

//.... soon to come

export {
  // auditor user
  piaListQuery,
  // queues
  queueQuery,
  queuesQuery,
  // statistics
  claimCountQuery,
  // workstation
  adminAndAuditorWorkstationQuery,
  providerWorkstationQuery,
  payerWorkstationQuery,
  // claim list
  adminClaimListQuery,
  auditorClaimListQuery,
  providerClaimListQuery,
  payerClaimListQuery,
};
