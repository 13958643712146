import * as React from "react";
import { IconAlertCircleFilled } from "@tabler/icons-react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@alaffia-technology-solutions/ui";

type TooltipTriggerProps = React.ComponentPropsWithoutRef<
  typeof TooltipTrigger
>;

export interface SearchTooltipProps extends TooltipTriggerProps {
  message: string;
}

export const SearchTooltip = (props: SearchTooltipProps) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger aria-label="File not ready" {...props}>
          <div className="af-flex af-items-center af-rounded-full af-px-1 af-py-0.5 af-text-error-500">
            <IconAlertCircleFilled stroke={3} className="af-size-4" />
          </div>
        </TooltipTrigger>
        <TooltipContent collisionPadding={4} className="[&_p]:af-mb-0">
          <p>{props.message}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};
