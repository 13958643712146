/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { useMemo } from "react";

import { useUserPreference } from "../../util/preferences";
import { AA } from "./aa";
import { FindingEdits } from "./findingEdits";
import { HbsPreview } from "./hbs/hbsPreview";
import MainMenu from "./mainMenu";

interface ComponentOption {
  label: string;
  component: React.FC;
}

export const Playground = () => {
  const [chosenComponent, setChosenComponent] = useUserPreference(
    `playground.chosenComponent.label`,
  ); // persist

  const components = [
    {
      label: "ask autodor",
      component: AA,
    },
    {
      label: "finding modal",
      component: FindingEdits,
    },
    { label: "hbs preview", component: HbsPreview },
    {
      label: "default",
      component: () => (
        <div
          style={{
            width: "100%",
            minHeight: "100%",
            height: "100%",
            backgroundColor: "red",
          }}
        >
          this is 2
        </div>
      ),
    },
  ];

  const CurrentComponent = useMemo(
    () =>
      components.find((c) => c.label === "" + chosenComponent)?.component ??
      (() => <div>Choose a component</div>),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chosenComponent],
  );

  const setComponent = (component: ComponentOption) => {
    if (setChosenComponent) {
      setChosenComponent(component.label);
    }
  };

  return (
    <div
      data-id="pg-1"
      tw="af-p-1 af-flex af-h-full af-max-h-full af-min-h-full af-flex-col af-bg-antd-lt-colorBgContainer dark:af-bg-antd-lt-colorBgContainer"
    >
      <div data-id="pg-2">
        <MainMenu
          components={components}
          setComponent={setComponent}
        ></MainMenu>
      </div>
      <div data-id="pg-component-wrapper" tw="af-flex-1">
        <CurrentComponent></CurrentComponent>
      </div>
    </div>
  );
};
