/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Form, InputNumber } from "antd";

import { FormExtra } from "../../../../shared/components/form/formExtra";
import { inputPropsCurrencyFormatter } from "../../../../util";
import { asMoney } from "../../../../util/format";
import { isSet } from "../../../../util/isSet";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

export type BilledAmountProps = CommonFieldProps & {
  flagged?: boolean;
  addlExtraJsx?: JSX.Element;
  formLayout?: any;
};

export const BilledAmount: React.FC<BilledAmountProps> = ({
  dataKey,
  label = "Billed Amount",
  initialValues,
  formLayout,
  addlExtraJsx = undefined,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<number>(
    dataKey,
    initialValues,
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      {...formLayout}
      label={label}
      rules={[
        {
          required: true,
          message: "Enter the charge amount per date of service",
        },
      ]}
      extra={
        <FormExtra visible={isSet(existingFindingValue) || !!addlExtraJsx}>
          <div tw="af-flex">
            <div tw="af-min-w-[3em]">
              {isSet(existingFindingValue) && asMoney(existingFindingValue)}
            </div>
            <div tw="af-pl-0.5">{addlExtraJsx}</div>
          </div>
        </FormExtra>
      }
      initialValue={isSet(defaultValue) && defaultValue}
    >
      <InputNumber
        style={{ width: "50%" }}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        min={1}
        {...inputPropsCurrencyFormatter}
      />
    </Form.Item>
  );
};
