import React, { useContext, useEffect } from "react";
import { theme } from "antd";

import { DesignProvider } from "./designProvider";

export const BodyBgColorUpdater = () => {
  const { token } = theme.useToken();
  const {
    options: { darkMode },
  } = useContext(DesignProvider);
  useEffect(() => {
    try {
      if (theme && token) {
        console.debug(`Setting html body bg color: ${token.colorBgContainer}`);
        document.body.className = darkMode ? "af-dark" : "";
        document.body.style.backgroundColor = token.colorBgContainer;
      }
    } catch (e) {
      console.info(
        `Failed to set html body bg color: ${token.colorBgContainer}, error: `,
        e,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theme, token]);
  return <></>;
};
