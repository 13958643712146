import lodash from "lodash";

/**
 * a base class for extending Error that can communicate
 * more detail through the GraphQLError specs' `extensions` property
 */
class ClientErrorBase extends Error {
  public readonly extensions;
  constructor(message: string, extensions: Record<string, any> | null = null) {
    super(message);
    this.extensions = extensions;
    this.name = "ClientErrorBase"; // set name after super
  }
}
/**
 * An Error subclass for communicating additional metadata via 'payload'
 * helpful for sending details about the error to the frontend
 * a limited serialization (message, stack, name) will be sent as `GraphQLError.extensions.cause`
 */
export class ClientError<T = any> extends ClientErrorBase {
  constructor(
    message: string,
    payload: T | Record<string, any> | null = null,
    cause: unknown = null,
  ) {
    const extensions = {
      errorType: "ClientError",
      payload,
      cause, //: cloneObjectFlattened(cause),
    };
    super(message, extensions);
    this.name = extensions.errorType; // set name after super
  }
}

// util
function getObjProps(obj: object) {
  let o = obj;
  const names = new Set<string>();
  do {
    Object.getOwnPropertyNames(o).forEach((n) => names.add(n));
    o = Object.getPrototypeOf(o);
  } while (o);
  return [...names];
}

export function cloneObjectFlattened(obj: object) {
  return lodash.pick(obj, getObjProps(obj));
}

export function graphqlErrorToClientError(error: any) {
  const netCause = lodash.get(error, "networkError.cause.message");
  const netErr = lodash.get(error, "networkError.message");
  const gqlErr = error?.graphQLErrors;
  const errObj = { netCause, netErr, gqlErr };
  const newError = new ClientError<typeof errObj>(
    netCause ?? netErr ?? error.message,
    errObj,
    error,
  );
  return newError;
}
