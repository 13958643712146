/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useMemo } from "react";

import { UserContext } from "../../context/user";

export enum PAYMENT_RATE_STRATEGY {
  CLAIM_LEVEL_PAYMENT_RATE = "CLAIM_LEVEL_PAYMENT_RATE",
  CLAIM_LINE_LEVEL_PAYMENT_RATE = "CLAIM_LINE_LEVEL_PAYMENT_RATE",
}
const COMP_STRATEGY_LABELS = {
  CLAIM_LEVEL_PAYMENT_RATE: "claim level",
  CLAIM_LINE_LEVEL_PAYMENT_RATE: "claim line level",
};

// contract for bc/bcl with payment rate definition; can be replaced by full types when implemented
interface PaymentRateDefiner {
  paymentRate: number;
}

// contract for bc with strategy definition; can be replaced by full types when implemented
interface PaymentRateStrategyDefiner extends PaymentRateDefiner {
  computationStrategy: PAYMENT_RATE_STRATEGY | string | null;
}

/**
 * utility class encapsulating common logic for UI payment rate display; see usePaymentRate hook, below,
 * for a convenient way to construct; see the getActiveXXX methods for retrieving the rate that should apply
 * to a line per the current strategy setting.
 */
export class PaymentRateUtility {
  readonly isPaymentRateLineLevel: boolean;
  private readonly _strategy: PAYMENT_RATE_STRATEGY | null;
  private readonly _showStrategy: boolean;
  private readonly _claimRate: number | null;

  // improvement: specifying the constructor parameter props as readonly (vs. needing decl + getters) works locally
  // in parcel in our 'start' script, but throws a syntax error in building for release...  worth investigating!
  constructor(
    strategy: PAYMENT_RATE_STRATEGY | null,
    showStrategy: boolean,
    claimRate: number | null,
  ) {
    this._strategy = strategy;
    this._showStrategy = showStrategy;
    this._claimRate = this.numberOrNull(claimRate);

    this.isPaymentRateLineLevel =
      (this._strategy &&
        this._strategy ===
          PAYMENT_RATE_STRATEGY.CLAIM_LINE_LEVEL_PAYMENT_RATE) ||
      false;
  }

  /**
   * returns the current strategy ENUM value, or null if the user shouldn't see the value, per
   * ctor arg showStrategy (set per UserContext / permissions if instantiated via usePaymentRate hook)
   */
  get strategy() {
    return (this._showStrategy && this._strategy) || null;
  }

  /**
   * indicates whether the current user should be able to see the strategy, per ctor arg
   * showStrategy (set per UserContext / permissions if instantiated via usePaymentRate hook)
   */
  get showStrategy() {
    return this._showStrategy;
  }

  /**
   * returns the current strategy display-friendly value, or null if the user shouldn't see the value, per
   * ctor arg 'showStrategy' (set per UserContext / permissions if instantiated via usePaymentRate hook)
   */
  get strategyLabel() {
    return (
      (this._showStrategy &&
        this._strategy &&
        COMP_STRATEGY_LABELS[this._strategy]) ||
      null
    );
  }

  /**
   * returns the claim-level payment rate, regardless of the strategy defined
   */
  get claimRate() {
    return this._claimRate;
  }

  /**
   * Ignoring the set payment rate strategy, returns the rate as ratio (fraction) explicitly specified in the batchClaimLine
   * @param batchClaimLine
   */
  getExplicitLineRate(batchClaimLine: PaymentRateDefiner) {
    return this.numberOrNull(batchClaimLine?.paymentRate);
  }

  /**
   * returns the correct rate (claim-level or line-level) that applies to a given batchClaimLine, as ratio (fraction), per
   * strategy defined in ctor arg 'strategy' (set per batchClaim input if instantiated via usePaymentRate hook)
   * @param batchClaimLine
   */
  getActiveLineRate(batchClaimLine: PaymentRateDefiner) {
    return this.isPaymentRateLineLevel
      ? this.getExplicitLineRate(batchClaimLine)
      : this._claimRate;
  }

  /**
   * Returns the claim level rate, as a percentage, regardless of strategy
   * @param batchClaimLine
   * @param fractionDigits the number places after the decimal point to keep
   */
  getClaimRatePercent(fractionDigits = 2) {
    return this.formatRate(this._claimRate, fractionDigits);
  }

  /**
   * Ignoring the set payment rate strategy, returns the rate explicitly specified in the batchClaimLine, as a percentage, per
   * strategy defined in ctor arg 'strategy' (set per batchClaim input if instantiated via usePaymentRate hook)
   * @param batchClaimLine
   * @param fractionDigits the number places after the decimal point to keep
   */
  getExplicitLineRatePercent(
    batchClaimLine: PaymentRateDefiner,
    fractionDigits = 2,
  ) {
    return this.formatRate(
      this.getExplicitLineRate(batchClaimLine),
      fractionDigits,
    );
  }

  /**
   * returns the active rate (claim-level or line-level) that applies to a given batchClaimLine, as a percentage, per
   * strategy defined in ctor arg 'strategy' (set per batchClaim input if instantiated via usePaymentRate hook)
   * @param batchClaimLine
   * @param fractionDigits the number places after the decimal point to keep
   */
  getActiveLineRatePercent(
    batchClaimLine: PaymentRateDefiner,
    fractionDigits = 2,
  ) {
    return this.formatRate(
      this.getActiveLineRate(batchClaimLine),
      fractionDigits,
    );
  }

  // convert to fixed number of decimal points string
  private formatRate = (
    rate: number | null | undefined,
    fractionDigits: number,
  ) =>
    rate === null || rate === undefined
      ? null
      : (rate * 100).toFixed(fractionDigits);

  // ensure numeric (including 0) or null
  private numberOrNull = (n: number | null | undefined) =>
    n === null || n === undefined || isNaN(n) ? null : n;
}

/**
 * hook to get a PaymentRateUtility based on the current batchClaim; manages getting user context permissions internally
 * @param batchClaim
 * @returns PaymentRateUtility
 */
export const usePaymentRate = (
  batchClaim: PaymentRateStrategyDefiner | null,
): PaymentRateUtility => {
  const { permissions } = React.useContext<any>(UserContext);
  const paymentRateUtility = useMemo(() => {
    const pru = new PaymentRateUtility(
      PAYMENT_RATE_STRATEGY[
        batchClaim?.computationStrategy as keyof typeof PAYMENT_RATE_STRATEGY
      ],
      permissions.canViewPaymentRateStrategy,
      batchClaim?.paymentRate ?? null,
    );
    return pru;
  }, [permissions, batchClaim]); // permissions is static, but if we are able one day to switch roles in dev mode...
  return paymentRateUtility;
};
