import type { Options } from "react-markdown";
import ReactMarkdown from "react-markdown";
import rehypeSlug from "rehype-slug";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import defaultComponents from "./components";

// https://github.com/remarkjs/react-markdown/blob/main/lib/index.js
const safeProtocol = /^(alaffia?|https?|ircs?|mailto|xmpp)$/i;

/**
 * Make a URL safe.
 *
 * @satisfies {UrlTransform}
 * @param {string} value
 *   URL.
 * @returns {string}
 *   Safe URL.
 */
function defaultUrlTransform(value: string): string {
  // Same as:
  // <https://github.com/micromark/micromark/blob/929275e/packages/micromark-util-sanitize-uri/dev/index.js#L34>
  // But without the `encode` part.
  const colon = value.indexOf(":");
  const questionMark = value.indexOf("?");
  const numberSign = value.indexOf("#");
  const slash = value.indexOf("/");

  if (
    // If there is no protocol, it’s relative.
    colon < 0 ||
    // If the first colon is after a `?`, `#`, or `/`, it’s not a protocol.
    (slash > -1 && colon > slash) ||
    (questionMark > -1 && colon > questionMark) ||
    (numberSign > -1 && colon > numberSign) ||
    // It is a protocol, it should be allowed.
    safeProtocol.test(value.slice(0, colon))
  ) {
    return value;
  }

  return "";
}

export type MarkdownProps = {
  withDefaultComponents?: boolean;
  withDefaultRehypePlugins?: boolean;
} & Options;

export const Markdown = ({
  className,
  components: passedComponents,
  rehypePlugins: passedRehypePlugins,
  withDefaultComponents = true,
  withDefaultRehypePlugins = true,
  ...rest
}: MarkdownProps) => {
  const components = withDefaultComponents
    ? { ...defaultComponents, ...passedComponents }
    : passedComponents;

  const rehypePlugins = withDefaultRehypePlugins
    ? [rehypeSlug, ...(passedRehypePlugins ?? [])]
    : passedRehypePlugins;

  return (
    <div
      // This class is a hack to remove the top margin and padding from the first child
      className={cn(
        "[&>*:first-child]:af-mt-0 [&>*:first-child]:af-pt-0",
        className,
      )}
    >
      <ReactMarkdown
        components={components}
        rehypePlugins={rehypePlugins}
        urlTransform={defaultUrlTransform}
        {...rest}
      />
    </div>
  );
};
