import type { PropsWithChildren } from "react";
import { useState } from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import type { PDFBookmark } from "../../helper-types";

interface PDFBookmarksProps {
  bookmarks: PDFBookmark[];
  onBookmarkClick: (bookmark: PDFBookmark) => void;
  className?: string;
}

export const PDFBookmarks = ({
  bookmarks,
  onBookmarkClick,
  className,
}: PDFBookmarksProps) => {
  return (
    <div
      className={cn(
        "af-h-full af-w-full af-overflow-y-auto af-border-r af-border-border af-text-xs",
        className,
      )}
    >
      <PDFBookmarksList className="af-px-1 af-py-2">
        {bookmarks.map((bookmark) => (
          <PDFBookmarksListItem
            key={bookmark.id}
            bookmark={bookmark}
            onClick={onBookmarkClick}
          />
        ))}
      </PDFBookmarksList>
    </div>
  );
};

interface PDFBookmarksListProps extends PropsWithChildren {
  className?: string;
}

const PDFBookmarksList = ({ className, children }: PDFBookmarksListProps) => {
  return <ul className={className}>{children}</ul>;
};

interface PDFBookmarksListItemProps {
  bookmark: PDFBookmark;
  onClick: (bookmark: PDFBookmark) => void;
}

const PDFBookmarksListItem = ({
  bookmark,
  onClick,
}: PDFBookmarksListItemProps) => {
  const [childrenExpanded, setChildrenExpanded] = useState(false);

  const hasChildren = bookmark.children.length > 0;

  return (
    <li>
      <div
        className={
          "af-grid af-grid-cols-[0.75rem_1fr] af-gap-1 af-rounded af-py-px hover:af-bg-primary-100 hover:af-text-gray-800"
        }
      >
        {hasChildren ? (
          <button onClick={() => setChildrenExpanded(!childrenExpanded)}>
            {childrenExpanded ? "▼" : "▶"}
          </button>
        ) : (
          <div />
        )}
        <button
          className="af-min-w-0 af-break-words af-text-left"
          onClick={() => onClick(bookmark)}
        >
          {bookmark.title}
        </button>
      </div>
      {childrenExpanded && hasChildren && (
        <PDFBookmarksList className="af-mt-0.5 af-pl-3">
          {bookmark.children.map((child) => (
            <PDFBookmarksListItem
              key={child.id}
              bookmark={child}
              onClick={onClick}
            />
          ))}
        </PDFBookmarksList>
      )}
    </li>
  );
};
