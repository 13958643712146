// https://github.com/mantinedev/mantine/blob/master/packages/%40mantine/core/src/core/utils/create-safe-context/create-safe-context.tsx

import type { ReactNode } from "react";
import { createContext, useContext } from "react";

export const createSafeContext = <TContextValue,>(errorMessage: string) => {
  const Context = createContext<TContextValue | null>(null);

  const Provider = ({
    children,
    value,
  }: {
    value: TContextValue;
    children: ReactNode;
  }) => <Context.Provider value={value}>{children}</Context.Provider>;

  const useSafeContext = () => {
    const context = useContext(Context);

    if (context === null) {
      throw new Error(errorMessage);
    }

    return context;
  };

  return [Provider, useSafeContext] as const;
};
