/**
 * THIS COMPONENT OUT OF DATE - JUST A PLACEHOLDER FOR ALT TYPE OF EDIT CONTROL
 */

import type { FC, ReactNode } from "react";
import { useContext, useMemo } from "react";
import { Popover } from "antd";

import type {
  AuditFindingSeedType,
  ItemizedBillLine,
} from "../../../../../gql/graphql";
import type { UUID } from "../../createAuditFinding/auditFindingUtil";
import { FindingContext } from "../findingProvider";

interface FindingInlineProps {
  seedType: AuditFindingSeedType;
  children: ReactNode;
  rowId: string;
  ibData?: ItemizedBillLine[];
  claimId: UUID;
}

export const FindingInline: FC<FindingInlineProps> = ({
  seedType,
  rowId,
  ibData,
  children,
}) => {
  const { findingEditor, inlineOpenId } = useContext(FindingContext);
  console.log("findingInline render", inlineOpenId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const isOpen = useMemo(() => inlineOpenId === rowId, [inlineOpenId]);
  return (
    <Popover
      onOpenChange={() => {
        console.log("open");
        findingEditor.showInline({
          rowId,
          operation: { operation: "create", seedType, ibData },
        });
      }}
      open={isOpen}
      title={findingEditor.title + " Inline"}
      trigger="click"
      key={rowId}
      // content={
      //   isOpen ? (
      //     <FindingForm
      //       onCancel={() => findingEditor.hideInline()}
      //       onSave={(values: any) => alert('todo convert to current code!')}
      //       findingEditor={findingEditor}
      //     ></FindingForm>
      //   ) : (
      //     <div>Is open flag is stale</div>
      //   )
      // }
    >
      {children}
    </Popover>
  );
};
