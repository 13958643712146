import { useEffect } from "react";
import type PDFViewCtrl from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib/PDFViewCtrl.full";

import { useEventCallback } from "@alaffia-technology-solutions/hooks";

import { usePDFStore } from "./providers";

type ViewerEvents = PDFViewCtrl.constants.ViewerEvents;

export function usePDFViewerEvent<T extends unknown[]>(
  eventName: ViewerEvents | null | undefined,
  listener: (...args: T) => void,
) {
  const { data: pdfViewerCtrl } = usePDFStore((state) => ({
    pdfViewer: state.pdfViewer,
  }));
  const pdfViewer = pdfViewerCtrl?.pdfViewer ?? null;
  const listenerCallback = useEventCallback(listener);

  useEffect(() => {
    if (!pdfViewer || !eventName) {
      return;
    }
    const eventEmitter = pdfViewer.getEventEmitter();

    eventEmitter.on(eventName, listenerCallback);

    return () => {
      eventEmitter.off(eventName, listenerCallback);
    };
  }, [eventName, listenerCallback, pdfViewer]);
}
