import { WarningTwoTone } from "@ant-design/icons";
import { css } from "@emotion/react";
import { Col, Result, Row, theme } from "antd";

const clsContainer = css({
  height: "100%",
});

const clsRow = css({
  height: "80%",
});

const clsCol = css({ height: "80%" });

const clsResult = css({ maxWidth: "70%" });

export const Maintenance = ({ message }: { message: string }) => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  return (
    <div css={css([clsContainer, { backgroundColor: colorBgContainer }])}>
      <Row css={clsRow} justify={"center"} align={"middle"}>
        <Col css={clsCol} span={4}></Col>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <Col css={clsCol} justify={"center"} align={"middle"} span={16}>
          <Result
            css={clsResult}
            icon={
              <WarningTwoTone
                twoToneColor={"#e5706a"}
                style={{ fontSize: "6em" }}
              />
            }
            status="warning"
            title="Site Undergoing Maintenance"
            subTitle={message}
          />
        </Col>
        <Col css={clsCol} span={4}></Col>
      </Row>
    </div>
  );
};
