/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import dayjs from "dayjs";
//dayjs is the drop-in immutable replacement for moment, used by antd5,
//    see https://alaffiahealth.atlassian.net/browse/EN-1031
// import moment from 'moment-timezone';
import utc from "dayjs/plugin/utc"; //dayjs requires enabling this plugin to use the .utc() calls
import { validate as uuidValidate } from "uuid";

import { inputPropsCurrencyFormatter } from "../../audit/util";

dayjs.extend(utc);

const generateFormattedMoneyRangeString = (
  v: any,
  searchFieldName: any,
  lowerBoundKeyword: any,
  upperBoundKeyword: any,
  { useSymbols, min, max }: any,
) => {
  const lowerBound = v[lowerBoundKeyword]
    ? inputPropsCurrencyFormatter.formatter(v[lowerBoundKeyword])
    : min;

  const upperBound = v[upperBoundKeyword]
    ? inputPropsCurrencyFormatter.formatter(v[upperBoundKeyword])
    : max;

  if (lowerBound && upperBound) {
    return useSymbols === true
      ? `${lowerBound} < ${searchFieldName} < ${upperBound}`
      : `${searchFieldName}: greater than ${lowerBound} and less than ${upperBound}`;
  } else if (upperBound)
    return useSymbols === true
      ? `${searchFieldName} < ${upperBound}`
      : `${searchFieldName}: greater than 0 and less than ${upperBound}`;
  else if (lowerBound)
    return useSymbols === true
      ? `${searchFieldName} > ${lowerBound}`
      : `${searchFieldName}: greater than ${lowerBound}`;
  return `${searchFieldName}: No Bounds`;
};

const stringReplace = (str: any, replaceKeys: any, replacements: any) => {
  return str.replace(/%\w+%/g, (i: string | number) => {
    return replacements[replaceKeys[i]];
  });
};

/**
 *
 * @param {string} uuid Expected type for \"UUID\" -- 32 hexadecimal characters, with 4 hyphens
 * @returns boolean value determined using 'validate' function from 'uuid' library
 */
const isUuid = (uuid: string) => {
  try {
    return uuidValidate(uuid);
  } catch (error) {
    return false;
  }
};

const getDisplayNamesOfStatesArray = (
  states: any,
  values: any,
  userType: any,
) => {
  return Object.entries(states)
    .filter(([stateName]) => values.includes(stateName))
    .map(([, { name }]: any) => name(userType))
    .join(", ");
};

const arrayOfLabelsFromLabelValueMap = (map: any, values: any) => {
  return Object.entries(map)
    .filter(([value]) => values.includes(value))
    .map(([, label]) => label)
    .join(", ");
};

const isValidInteger = (v: any, { min, max }: any) => {
  let isValid = v !== null && typeof v === "number";
  if (isValid && min) isValid = v > min;
  if (isValid && max) isValid = v < max;
  return isValid;
};

const getUTCDateObj = (date: any) => {
  return dayjs(date).utc().endOf("day").utc();
};

const getDisplayNameOfDateRange = (
  dateTypeName: any,
  startDate: any,
  endDate: any,
  momentFormat: any,
) => {
  const formattedStartDate = getUTCDateObj(startDate);
  const formattedEndDate = getUTCDateObj(endDate);

  // when range is the same date
  if (formattedStartDate.diff(formattedEndDate) === 0) {
    return `${dateTypeName}: ${formattedStartDate.format(momentFormat)}`;
  } else
    return `${dateTypeName}: ${formattedStartDate.format(
      momentFormat,
    )} - ${formattedEndDate.format(momentFormat)}`;
};

const generateOptionsArrForEnumClaimState = (statesArr: any, userType: any) => {
  return statesArr.map(([state, { name }]: any) => ({
    label: name(userType),
    value: state,
  }));
};

const filterClaimStatesSearchOptionsByPermission = (
  states: any,
  field: any,
  { claimStatesToShow }: any, // the permissions object destructured
) => {
  return Object.entries(states).filter(([state, _]) => {
    return claimStatesToShow[field].has(state);
  });
};

const generateDefaultValue = (filter: any, field: any, typeDef: any) => {
  if (filter.filterMap.has(field)) {
    return filter.filterMap.get(field).value;
  } else {
    const value: any = {};
    typeDef.selects.forEach(({ key, defaultValue }: any) => {
      if (defaultValue) {
        value[key] = defaultValue;
      }
    });
    return value;
  }
};

export {
  stringReplace,
  getDisplayNamesOfStatesArray,
  isValidInteger,
  generateFormattedMoneyRangeString,
  getUTCDateObj,
  getDisplayNameOfDateRange,
  arrayOfLabelsFromLabelValueMap,
  generateOptionsArrForEnumClaimState,
  filterClaimStatesSearchOptionsByPermission,
  generateDefaultValue,
  isUuid,
};
