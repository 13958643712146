/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import tw, { css } from "twin.macro";

import { tailwindPreflight } from "../../components/app/design/tw-preflight";

export default function MainMenu({ components, setComponent }: any) {
  return (
    <div css={tailwindPreflight}>
      <div tw="af-top-[48px] af-w-2/4 af-text-right">
        <Menu as="div" tw="af-relative af-inline-block af-text-left">
          <div>
            <Menu.Button tw="af-inline-flex af-w-full af-justify-center af-rounded-md af-bg-blue-600 af-bg-opacity-60 af-px-4 af-py-2 af-text-sm af-font-medium af-text-white hover:af-bg-opacity-80 focus:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-white focus-visible:af-ring-opacity-75">
              Options
              <ChevronDownIcon
                tw="af-ml-2 af--mr-1 af-h-5 af-w-5 af-text-violet-200 hover:af-text-violet-100"
                aria-hidden="true"
              />
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="af-transition af-ease-out af-duration-100"
            enterFrom="af-transform af-opacity-0 af-scale-95"
            enterTo="af-transform af-opacity-100 af-scale-100"
            leave="af-transition af-ease-in af-duration-75"
            leaveFrom="af-transform af-opacity-100 af-scale-100"
            leaveTo="af-transform af-opacity-0 af-scale-95"
          >
            <Menu.Items tw="af-absolute af-z-[1001] af-right-0 af-mt-2 af-w-56 af-origin-top-right af-divide-y af-divide-purple-100 af-rounded-md af-bg-white af-shadow-lg af-ring-1 af-ring-black af-ring-opacity-5 focus:af-outline-none">
              <div tw=" af-px-1 af-py-1 ">
                {components.map((component: any) => (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setComponent(component)}
                        css={css([
                          active
                            ? tw`af-bg-violet-500 af-text-white`
                            : tw`af-bg-transparent af-text-yellow-900`,
                        ])}
                        className="group"
                        tw="af-flex af-w-full af-items-center af-rounded-md af-px-2 af-py-2 af-text-sm"
                      >
                        {active ? (
                          <EditActiveIcon
                            tw="af-mr-2 af-h-5 af-w-5"
                            aria-hidden="true"
                          />
                        ) : (
                          <EditInactiveIcon
                            tw="af-mr-2 af-h-5 af-w-5"
                            aria-hidden="true"
                          />
                        )}
                        {component.label}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
}

function EditInactiveIcon(props: any) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#EDE9FE"
        stroke="#A78BFA"
        strokeWidth="2"
      />
    </svg>
  );
}

function EditActiveIcon(props: any) {
  return (
    <svg
      {...props}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 13V16H7L16 7L13 4L4 13Z"
        fill="#8B5CF6"
        stroke="#C4B5FD"
        strokeWidth="2"
      />
    </svg>
  );
}
