/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { RowClassParams, RowClassRules } from "ag-grid-community";

import type { AutodorFinding, Finding } from "../../../../gql/graphql";
import type { IBGridLine } from "../../claimWorkspace/itemizedBillTab/ibTable/types";

const isGroupHeader = (params: any) => params?.node?.group;

const isNotDetailRow = (params: any) => !params?.node?.detail;

const hasNeedsReview = (findings: Finding[]) =>
  findings?.length > 0 && findings?.some((f: Finding) => f?.needsReview);

const hasStale = (findings: Finding[]) =>
  findings?.length > 0 &&
  findings?.some(
    (f: Finding) => f.accepted && f?.findingItemizedBillData?.stale,
  );

const hasAcceptedButNoNeedsReview = (findings: Finding[]) =>
  findings?.length > 0 &&
  findings?.some((f: Finding) => f?.accepted) &&
  !hasNeedsReview(findings);

const hasAutodor = (findings: Finding[], autodorFindings: AutodorFinding[]) => {
  return autodorFindings.length > 0 && !hasAcceptedButNoNeedsReview(findings);
};

export const getClaimLineTableRowClassRules: (
  showFindings: boolean,
) => RowClassRules<IBGridLine> = (showFindings) => {
  return {
    // rules for applying row classes for highlighted rows based on findings + finding status
    // row style function

    "ala-ag-row-focused": (params: RowClassParams) => {
      return params.context?.highlightRowId === params.node.id ? true : false;
    },

    "ala-ag-row-style-is-group-header": (params: RowClassParams) =>
      isNotDetailRow(params) && isGroupHeader(params),

    ...(!showFindings
      ? {}
      : {
          "ala-ag-row-style-accepted-finding": (params: RowClassParams) =>
            isNotDetailRow(params) &&
            hasAcceptedButNoNeedsReview(params?.data?.findings ?? []),

          "ala-ag-row-style-needs-review-finding": (params: RowClassParams) =>
            isNotDetailRow(params) &&
            hasNeedsReview(params?.data?.findings ?? []) &&
            !hasStale(params?.data?.findings ?? []),

          "ala-ag-row-style-stale-finding": (params: RowClassParams) =>
            isNotDetailRow(params) && hasStale(params?.data?.findings ?? []),

          "ala-ag-row-style-autodor-finding": (params: RowClassParams) =>
            isNotDetailRow(params) &&
            hasAutodor(
              params?.data?.findings ?? [],
              params?.data?.autodorFindings ?? [],
            ),
        }),
  };
};
