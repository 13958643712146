import type { ICellRendererParams } from "ag-grid-community";
import { Spin } from "antd";

export const LoadingOverlay = (
  props: ICellRendererParams & { loadingMessage: string },
) => {
  return (
    <Spin tip={props.loadingMessage} size="large">
      <div
        className="content"
        tw="af-p-40 af-w-2/3 af-text-antd-lt-colorTextSecondary dark:af-text-antd-dk-colorTextSecondary"
      />
    </Spin>
  );
};
