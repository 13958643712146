/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { css } from "@emotion/react";
import { Col, Row, Switch, theme, Tooltip } from "antd";
import { gql, useQuery } from "urql";

import { UserContext } from "../../context/user";
import LoadingSpinner from "../../misc/loadingSpinner";
import { Stat } from "./stat";
import { activeStatistics, historicStatistics } from "./statistics";

const isFetching = (
  isHistoric: any,
  isFetchingActive: boolean,
  isFetchingHistoric: boolean,
) => {
  return isHistoric ? isFetchingHistoric : isFetchingActive;
};

const getStatList = (isHistoric: any, activeList: any, historicList: any) => {
  return isHistoric ? historicList : activeList;
};

/**
 * Generates a historic stat query given selection fields for the user
 * @param fields: string[]
 * @returns gql
 */
const historicStatisticsQuery = (fields: any[]) => gql`
 query dashboardHistoricStatistics {
   statistics: historicStatistics {
     ${fields
       .map((stat) => `${stat} { id description value units operationType } `)
       .join("\n")}
   }
 }
`;

/**
 * Generates an active stat query given selection fields for the user
 * @param fields: string[]
 * @returns gql
 */
const activeStatisticsQuery = (fields: any[]) => gql`
 query dashboardActiveStatistics {
   statistics: activeStatistics  {
     ${fields
       .map((stat) => `${stat} { id description value units operationType } `)
       .join("\n")}
   }
 }
`;

/**
 *
 * @param statList { activeList: string[],  historicList: string[] }  - permitted list of active AND historic statistics
 * @param historic boolean - whether or not this is a historic or active view
 * @param statConfigs - { activeConfig, historicConfig } active AND historic statistic configuration object
 * @returns A gridview of statistics components
 */
const Stats = ({
  statList: { activeList, historicList },
  historic,
  statConfigs: { activeConfig, historicConfig },
}: any) => {
  const [{ data: activeStatsData, fetching: fetchingActiveStats }] = useQuery({
    query: activeStatisticsQuery(activeList),
  });

  const [{ data: historicStatsData, fetching: fetchingHistoricStats }] =
    useQuery({
      query:
        // this ternary code is needed because provider users do not get to make historic stats requests
        // yet useQuery needs a valid query string and so somehow we needed to add a valid but dummy query into that section for
        // when historicList is empty in the case of provider users
        // NOTE: the pause field is what asserts that no query is ever triggered for providers but the useQuery handler still needed some valid query string
        historicList.length > 0
          ? historicStatisticsQuery(historicList)
          : activeStatisticsQuery(activeList),
      pause: historicList.length === 0,
    });

  return (
    <div>
      {isFetching(historic, fetchingActiveStats, fetchingHistoricStats) && (
        <LoadingSpinner />
      )}

      {!isFetching(historic, fetchingActiveStats, fetchingHistoricStats) && (
        <Row>
          {getStatList(historic, activeList, historicList).map(
            (stat: string | number) => {
              const { value, description, units } = historic
                ? historicStatsData.statistics[stat]
                : activeStatsData.statistics[stat];
              const config = historic
                ? historicConfig[stat]
                : activeConfig[stat];
              return (
                <Col
                  id={`stat-panel-${
                    historic ? "historic" : "active"
                  }-stat-${stat}`}
                  key={`stat-panel-${
                    historic ? "historic" : "active"
                  }-stat-${stat}`}
                  data-cy={`stat-panel-${
                    historic ? "historic" : "active"
                  }-stat-${stat}`}
                  span={8}
                >
                  <Stat
                    {...{
                      ...config,
                      value,
                      description,
                      units,
                    }}
                  />
                </Col>
              );
            },
          )}
        </Row>
      )}
    </div>
  );
};
/**
 * Renders a statistic panel based on the user type
 * of the logged in user. Takes no arguments
 */
const StatsPanel = () => {
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // styling
  const statPanelStyle = css({
    backgroundColor: colorBgContainer,
  });

  const {
    dashboard: {
      stats: { active: userActiveStats, historic: userHistoricStats },
    },
  } = React.useContext(UserContext);

  // show toggle if there are stats of both categories
  const showToggle =
    userActiveStats.length && userHistoricStats.length ? true : false;

  // default to active stats
  const [historic, setHistoric] = React.useState(false);

  return (
    <div id="stats-panel" data-cy="stats-panel" css={statPanelStyle}>
      <Row>
        <Col span={24}>
          <h1
            css={css`
              margin-left: 1%;
              font-size: 150%;
            `}
          >
            <span>Overview</span>
            {showToggle && (
              <span>
                <Tooltip
                  title={"Switch between active and historic statistics"}
                >
                  <Switch
                    data-cy="active-vs-historic-stats-switch"
                    css={css`
                      margin-left: 1%;
                    `}
                    checkedChildren="Active"
                    unCheckedChildren="Historic"
                    defaultChecked
                    onChange={() => {
                      setHistoric(historic ? false : true);
                    }}
                  />
                </Tooltip>
              </span>
            )}
          </h1>
        </Col>
      </Row>
      <Stats
        statList={{
          activeList: userActiveStats,
          historicList: userHistoricStats,
        }}
        statConfigs={{
          historicConfig: historicStatistics,
          activeConfig: activeStatistics,
        }}
        historic={historic}
      />
    </div>
  );
};

export { StatsPanel };
