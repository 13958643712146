import type { PDFFoxitRect, PDFHighlightCoords } from "./helper-types";

interface RatioCoords {
  height: number;
  left: number;
  top: number;
  width: number;
}

export function mapRatioToHighlightCoords(
  ratioCoords: RatioCoords,
  pageDimensions: { width: number; height: number },
): PDFHighlightCoords {
  const r = ratioCoords;
  const { width: pageWidth, height: pageHeight } = pageDimensions;
  return {
    lowerLeft: {
      x: r.left * pageWidth,
      y: pageHeight - r.top * pageHeight,
    },
    upperRight: {
      x: (r.left + r.width) * pageWidth,
      y: pageHeight - (r.top + r.height) * pageHeight,
    },
  };
}

export function mapHighlightCoordsToFoxitRect(
  highlighCoords: PDFHighlightCoords,
): PDFFoxitRect {
  const { lowerLeft, upperRight } = highlighCoords;

  return {
    left: lowerLeft.x,
    bottom: lowerLeft.y,
    right: upperRight.x,
    top: upperRight.y,
  };
}

export function mapFoxitRectToHighlightCoords(
  foxitRect: PDFFoxitRect,
): PDFHighlightCoords {
  return {
    lowerLeft: {
      x: foxitRect.left,
      y: foxitRect.bottom,
    },
    upperRight: {
      x: foxitRect.right,
      y: foxitRect.top,
    },
  };
}
