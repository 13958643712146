/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UploadFile as AntdUploadFile } from "antd/lib/upload/interface";
import type { Client as UrqlClient } from "urql";
import { gql } from "urql";

export const CLAIM_NUMBER_VALIDATION_REGEX =
  /^[a-zA-Z0-9][a-zA-Z0-9-_]{4,48}[a-zA-Z0-9]$/;

export const DOCUMENT_UPLOAD_KINDS = {
  UB04_CLAIM_FORM: {
    label: "Uniform Bill (UB)",
    contains: {
      UB04_CLAIM_FORM: "Standardized UB-04 Claim Form",
    },
  },
  ITEMIZED_BILL: {
    label: "Itemized Bill (IB)",
    contains: {
      ITEMIZED_BILL: "Itemized Bill",
    },
  },
  BILLING_RECORD: {
    label: "Mixed Bill (UB/IB/Other)",
    contains: {
      BILLING_RECORD: "Any combination of UB, IB, etc.",
    },
  },
  MEDICAL_RECORD: {
    label: "Medical Record",
    contains: {
      FACE_SHEET: "Face Sheet",
      THERAPY_TREATMENT_PLAN_WITH_NOTES: "Therapy Treatment Plan And Notes",
      MEDICATION_ADMINISTRATION_RECORDS: "Medication Administration Records",
      DISCHARGE_SUMMARY: "Discharge Summary",
      PHYSICIAN_PROGRESS_NOTES: "Physician Progress Notes ",
      HISTORY_AND_PHYSICAL: "History & Physical ",
      LABORATORY_REPORTS: "Laboratory Reports",
      EMERGENCY_ROOM_RECORDS: "Emergency Room Records",
      RADIOLOGY_REPORTS: "Radiology Reports & Images",
      NURSING_NOTES: "All Nursing Notes",
      OPERATIVE_REPORTS: "Operative Reports",
      CONSULTATIONS: "Consultations (RT/PT/OT/ST, etc)",
      PATHOLOGY_REPORTS: "Pathology Reports",
    },
  },
  OTHER: {
    label: "Other Documentation",
    contains: {
      OTHER: "Additional supporting documentation",
    },
  },
};

interface UploadFileEx<T> {
  uploadFile: AntdUploadFile;
  metadata: T;
}

interface UploadClaimMetadata {
  uploadUrl?: string;
  tags?: string;
  documentUploadKind?: string;
  status?: string;
  statusMessage?: string;
  claimNumber?: string;
  insurerName?: string;
  insurerId?: string;
  uploadedAt?: Date;
  errorMessage?: string;
  error?: any;
  duplicateUB?: boolean;
  duplicateNameAndKind?: boolean;
}

export type ClaimIntakeUpload = UploadFileEx<UploadClaimMetadata>;

interface ClaimIntakeResponse {
  claimIntake: {
    url: string;
    tags: string;
  };
}

const toFirstLetterUpper = (str: string) =>
  str
    ?.replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b[a-z](?=[a-z]{2})/g, (l) => l.toUpperCase());

const rejectWithError = (
  upload: ClaimIntakeUpload,
  errorMessage: string,
  error: any = null,
) => {
  console.log("Claim upload: error received in promise chain", {
    upload,
    errorMessage,
    error,
  });
  const errMsg =
    typeof errorMessage === "string"
      ? errorMessage
      : (errorMessage as any)?.message ?? JSON.stringify(errorMessage);

  upload.metadata.status = "error";
  upload.metadata.statusMessage = errMsg;
  upload.metadata.errorMessage = (error && error?.cause) || errMsg;
  upload.metadata.error =
    typeof errorMessage === "object" && !error
      ? errorMessage
      : error ?? new Error(errorMessage);
  return Promise.reject(upload);
};

const uploadDocument = async (upload: ClaimIntakeUpload) => {
  try {
    const url = upload.metadata?.uploadUrl;
    if (!url) {
      throw new Error(
        "Upload URL not set for file " +
          (upload.uploadFile.fileName ?? upload.uploadFile.name),
      );
    }
    return fetch(url, {
      method: "PUT",
      body: upload.uploadFile as any,
      headers: {
        "x-amz-tagging": upload.metadata.tags ?? "",
      },
    })
      .then(async (res) => {
        if (res.ok) {
          upload.metadata = {
            ...upload.metadata,
            status: "success",
            statusMessage: "File uploaded",
            uploadedAt: new Date(),
            error: undefined,
            errorMessage: undefined,
          };
          return upload;
        }

        const serverError = await res.text();
        throw new Error(" " + res.status + ": " + serverError);
      })
      .catch((error) => {
        return rejectWithError(upload, error);
      });
  } catch (err: any) {
    return rejectWithError(upload, err?.message, err);
  }
};

// Gets upload URL and creates tags for upload
const getDocumentUploadParams = async (
  upload: ClaimIntakeUpload,
  graphqlClient: UrqlClient,
): Promise<ClaimIntakeUpload> => {
  const input = {
    fileName: upload.uploadFile.name,
    fileType: upload.uploadFile.type,
    insurerId: upload.metadata.insurerId,
    claimNumber: upload.metadata.claimNumber,
    documentUploadKind: upload.metadata.documentUploadKind,
  };

  const { data, error } = await graphqlClient
    .query(
      gql`
        query claimIntakeQuery($claimIntakeInput: ClaimIntakeInput!) {
          claimIntake(input: $claimIntakeInput) {
            url
            tags
          }
        }
      `,
      { claimIntakeInput: input },
      {
        // IMPORTANT !!!
        // It is critical that this is kept as a network only call
        // to prevent cached upload URLs from being re-used
        requestPolicy: "network-only",
      },
    )
    .toPromise()
    .then((r) => {
      return r;
    });

  if (error) {
    const reason =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      (error.graphQLErrors.length && error.graphQLErrors[0].message) ??
      "Unknown";
    const errorMessage =
      reason === "File already exists"
        ? `Document with file name ${upload.uploadFile.name} already exists. File names must be unique.`
        : `Network error, please try again. (reason: ${reason})`;
    return rejectWithError(upload, errorMessage);
  }

  const {
    claimIntake: { url: uploadUrl, tags },
  }: ClaimIntakeResponse = data;

  upload.metadata = { ...upload.metadata, uploadUrl, tags };
  return upload;
};

// File upload handler
// Returns a rejected promise in the event of errors
// Returns a resolved promise if upload is successful
const uploadFile = async (
  upload: ClaimIntakeUpload,
  graphqlClient: UrqlClient,
) => {
  // make a new obj for immutability for the calling react component (prob not relevant here but good practice)
  return getDocumentUploadParams(upload, graphqlClient)
    .then((upp) => {
      return upp;
    })
    .then(uploadDocument);
};

export { toFirstLetterUpper, uploadFile };
