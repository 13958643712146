import * as React from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import { ChatboxProvider } from "./ChatboxProvider";
import { MessageInput } from "./MessageInput";

type ChatboxProps = React.ComponentPropsWithoutRef<"div">;

const ChatboxBase = React.forwardRef<HTMLDivElement, ChatboxProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <ChatboxProvider>
        <div
          ref={ref}
          className={cn("af-flex af-flex-col", className)}
          {...props}
        >
          {children}
        </div>
      </ChatboxProvider>
    );
  },
);

type ChatboxHeaderProps = React.ComponentPropsWithoutRef<"div">;

const Header = React.forwardRef<HTMLDivElement, ChatboxHeaderProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "af-flex af-flex-wrap af-items-center af-justify-between af-gap-1 af-border-b af-border-border af-bg-gray-100 af-px-6 af-py-4 af-text-foreground",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

type ChatboxSubheaderProps = React.ComponentPropsWithoutRef<"div">;

const Subheader = React.forwardRef<HTMLDivElement, ChatboxSubheaderProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "af-bg-[#DCDCDC] af-bg-opacity-30 af-px-6 af-py-4 af-backdrop-blur-[6px]",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

type ChatboxBodyProps = React.ComponentPropsWithoutRef<"div">;

const Body = React.forwardRef<HTMLDivElement, ChatboxBodyProps>(
  ({ children }, ref) => {
    return (
      <div ref={ref} className="af-flex af-min-h-0 af-flex-1">
        {children}
      </div>
    );
  },
);

type ChatboxAsideProps = React.ComponentPropsWithoutRef<"div">;

const Aside = React.forwardRef<HTMLDivElement, ChatboxAsideProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div ref={ref} className={cn("af-flex-shrink-0", className)} {...props}>
        {children}
      </div>
    );
  },
);

type ChatboxContentsProps = React.ComponentPropsWithoutRef<"div">;

const Contents = React.forwardRef<HTMLDivElement, ChatboxContentsProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          "af-flex af-min-h-0 af-flex-1 af-flex-col af-justify-between af-overflow-hidden",
          className,
        )}
        {...props}
      >
        {children}
      </div>
    );
  },
);

const Chatbox = Object.assign(ChatboxBase, {
  Header,
  Subheader,
  Body,
  Aside,
  Contents,
  MessageInput,
});

export { Chatbox };
