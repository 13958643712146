/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useLayoutEffect, useState } from "react";
import { CaretDownOutlined, CaretUpOutlined } from "@ant-design/icons";
import { css, keyframes } from "@emotion/react";
import { theme } from "antd";
import { useLocation } from "react-router-dom";
import tw from "twin.macro";

import { useUserPreference } from "../../util/preferences";
import { UserContext } from "../context/user";
import { DarkModeButton } from "./design/darkModeButton";
import { DesignProvider } from "./design/designProvider";
import { UiOptionsDialog } from "./design/UiOptionsDialog";
import { Icon } from "./icon";
import { Logo } from "./logo";

const clsContainer = css({
  display: "flex",
  flexDirection: "column",
  "& .header-height-toggle": {
    display: "flex",
    alignContent: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginBottom: "2px",
  },
});
const clsContent = css({
  flex: "auto",
  display: "flex",
  justifyContent: "flex-start",
  transitionDuration: "200ms",
  transitionProperty: "height",
});
const clsUser = css([
  tw`af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText`,
  {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    flexDirection: "column",
    paddingRight: "48px",
    "& div": {
      flex: "0 0 auto",
      marginBottom: "0.25em",
      marginTop: "0.25em",
      lineHeight: "1em",
      "&.header-user-role": {
        fontSize: "0.85em",
      },
    },
  },
]);
const clsUserCompact = [
  clsUser,
  css({
    alignItems: "center",
    justifyContent: "flex-end",
    flexDirection: "row",
  }),
];

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;
const fadeInSecs = 0.5;
const iconCss = css({
  height: "100%",
  paddingLeft: "14px",
  paddingTop: "16px",
  display: "flex",
  alignItems: "center",
  "& img": {
    animation: `${fadeIn} ${fadeInSecs}s ease`,
    flex: "0 0 auto",
    width: "48px",
    height: "auto",
  },
});
const logoCss = css({
  height: "100%",
  paddingLeft: "32px",
  display: "flex",
  alignItems: "center",
  "& img": {
    flex: "0 0 auto",
    height: "58px",
    width: "auto",
    animation: `${fadeIn} ${fadeInSecs}s ease`,
  },
});

const getPathSegments = (location: any) => {
  const segments = location.pathname.split("/");
  return segments.length === 1 && segments[0] === "" ? [] : segments;
};
const isInteriorPage = (location: any) =>
  !getPathSegments(location).some((pp: string) =>
    ["home", "auth"].includes(pp),
  );

const isAuthScreen = (location: any) =>
  getPathSegments(location).includes("auth");

export const Header = () => {
  const {
    token: {
      colorBgContainer,
      colorBorder: activeIcon,
      colorBorderSecondary: border,
      colorFillTertiary: borderLight,
    },
  } = theme.useToken();
  const { options: uiOptions } = useContext(DesignProvider);

  const user = useContext(UserContext);
  const [collapsed, setCollapsed] = useState(false);
  const location = useLocation();

  // expand/collapse header handling
  const [headerPrefs, setHeaderPrefs] =
    useUserPreference(`header.home.expanded`); // persist
  const isInterior = isInteriorPage(location);
  const toggleCollapsed = () =>
    // override
    isInterior
      ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setHeaderPrefs({
          ...headerPrefs,
          expandInteriorScreens: collapsed ? true : undefined,
        })
      : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setHeaderPrefs({
          ...headerPrefs,
          collapseHomeScreens: !collapsed ? true : undefined,
        });
  useLayoutEffect(() => {
    // update on location/pref change
    setCollapsed(
      isInterior
        ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          !headerPrefs?.expandInteriorScreens
        : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          headerPrefs?.collapseHomeScreens,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, headerPrefs]);

  // computed styles
  const clsHeight = css({
    height: collapsed ? "40px" : "90px",
  });
  const clsFont = css({
    fontSize: collapsed ? ".85em" : ".90em",
  });
  const clsSeparator = css({
    borderBottom: collapsed
      ? `1px solid ${borderLight}`
      : `1px solid ${border}`,
  });
  const clsExpandIcon = css([
    {
      border: `1px solid ${border}`,
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      marginBottom: "2px",
      borderTop: 0,
      "& svg": {
        padding: "0px",
        margin: "0px",
        width: "32px",
        height: "8px",
      },
    },
  ]);
  const clsExpandIconColor = css({
    color:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      (!isInterior && headerPrefs?.collapseHomeScreens) ||
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      (isInterior && headerPrefs?.expandInteriorScreens)
        ? activeIcon
        : borderLight,
  });

  const getUserDiv = () => {
    if (!user) {
      return "";
    }
    const userName = `${user.firstName} ${user.lastName} ${
      user.title ? ", " + user.title : ""
    }`;
    return (
      <div
        css={css([clsHeight, clsFont, collapsed ? clsUserCompact : clsUser])}
      >
        <div
          css={css`
            flex: 1 0 auto;
            margin-left: auto;
          `}
        >
          <div data-cy="header-user-name">{userName}</div>
        </div>

        {!collapsed && (
          <div data-cy="header-user-role" className="header-user-role">
            {user.userType}
          </div>
        )}
      </div>
    );
  };

  return (
    <div
      data-cy="header-container"
      css={css([clsContainer, { backgroundColor: colorBgContainer }])}
    >
      <div css={css([clsContent, clsHeight, clsSeparator])}>
        <div
          css={css`
            flex: none;
            padding-bottom: 16px;
          `}
        >
          {collapsed ? (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Icon data-cy="header-icon" css={iconCss}></Icon>
          ) : (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            <Logo data-cy="header-logo" css={logoCss}></Logo>
          )}
        </div>

        <div
          css={css`
            flex: 1 0 auto;
            margin-left: auto;
          `}
        >
          <div tw="af-flex af-justify-end">
            {uiOptions?.showMenu && (
              <div
                css={css(
                  collapsed
                    ? tw`af-flex af-content-center af-align-middle af-self-center af-gap-2`
                    : tw`af-self-center`,
                )}
              >
                <div
                  css={css(
                    tw`af-flex af-content-center af-align-middle af-self-center`,
                  )}
                >
                  <DarkModeButton />
                </div>
                <div
                  css={css(
                    tw`af-flex af-content-center af-align-middle af-self-center`,
                  )}
                >
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <UiOptionsDialog showButton={true}></UiOptionsDialog>
                </div>
              </div>
            )}

            {getUserDiv()}
          </div>
        </div>
      </div>
      {!isAuthScreen(location) && (
        <div className="header-height-toggle" onClick={() => toggleCollapsed()}>
          {collapsed ? (
            <CaretDownOutlined css={css([clsExpandIcon, clsExpandIconColor])} />
          ) : (
            <CaretUpOutlined css={css([clsExpandIcon, clsExpandIconColor])} />
          )}
        </div>
      )}
    </div>
  );
};
