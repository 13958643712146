/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type {
  ColDef,
  GetDetailRowData,
  ICellRendererParams,
  IDetailCellRendererParams,
} from "ag-grid-community";

import { LineClampedCell } from "../../../../../common/table/ag/cell/lineClampedCell";
import { lineClampedCellRenderer } from "../../../../../common/table/ag/cell/lineClampedCellRenderer";
import { FindingDisplayAugmentedDefaultColumn } from "../../../../../common/table/ag/cols/defaultColumn";
import {
  // AuditFindingActions,
  AuditFindingTags,
} from "../../../claimWorkspace/auditFinding/claimWorkspaceAuditFinding";
import type {
  FindingDisplayAugmented,
  IBGridLine,
} from "../../../claimWorkspace/itemizedBillTab/ibTable/types";
import { findingToAF } from "../../../queries/claim/interimUtils";
import { AuditFindingActions } from "./AuditFindingActions";
import {
  CommentDetailCellRenderer,
  commentTableDetailRendererParams,
} from "./commentTableDetailRenderer";

const detailsDefaultCol: ColDef<FindingDisplayAugmented> = {
  ...FindingDisplayAugmentedDefaultColumn,
  cellRenderer: lineClampedCellRenderer,
};

export const createAuditFindingDetailsTableRendererParams: (params: {
  getDetailRowData: GetDetailRowData<IBGridLine, FindingDisplayAugmented>;
  context: any;
}) => Partial<
  IDetailCellRendererParams<IBGridLine, FindingDisplayAugmented>
> = ({ getDetailRowData, context }) =>
  ({
    refreshStrategy: "rows",
    detailGridOptions: {
      context,
      masterDetail: true,
      getRowId: (params) => params.data.id,
      enableCellTextSelection: true,
      ensureDomOrder: true,
      detailCellRenderer: CommentDetailCellRenderer,
      detailCellRendererParams: commentTableDetailRendererParams,
      columnDefs: [
        { cellRenderer: "agGroupCellRenderer", width: 50 },
        {
          headerName: "",
          headerTooltip: "Denial Code",
          field: "auditFindingRuleType",
          minWidth: 190,
          flex: 2,
          suppressMenu: true,
          cellRenderer: (props: ICellRendererParams<any, any>) =>
            !props.data ? (
              <div></div>
            ) : (
              <AuditFindingTags
                auditFinding={{
                  ...findingToAF(
                    // TODO we need a new component here..
                    props.data.denialCode
                      ? { ...props.data, ruleType: props.data.denialCode }
                      : props.data,
                    props.context?.current?.denialCodes ?? {},
                  ),
                }}
              ></AuditFindingTags>
            ),
        },
        {
          headerName: "",
          headerTooltip: "Summary",
          field: "detailRowSummary",
          flex: 5,
          cellRenderer: (props: ICellRendererParams<any, any>) => (
            <LineClampedCell>{props?.value}</LineClampedCell>
          ),
        },
        {
          headerName: "",
          headerTooltip: "Actions",
          flex: 3,
          field: "improperPaymentCost",
          type: "rightAligned",
          cellRenderer: (
            props: ICellRendererParams<FindingDisplayAugmented, any, any>,
          ) => {
            return !props.data ? (
              <div></div>
            ) : (
              <AuditFindingActions
                auditFinding={props.data}
                {...props.context?.current?.createFindingFunctions}
                ibData={[props.data.ibLine]}
              ></AuditFindingActions>
            );
          },
        },
      ],
      defaultColDef: detailsDefaultCol,
      headerHeight: 8,
      detailRowHeight: 275,
    },
    getDetailRowData,
    // the as IDetailCellRenderParams<...> here is per their doc... looks like they didn't work it out either...:
  }) as Partial<IDetailCellRendererParams<IBGridLine, FindingDisplayAugmented>>;
