/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { AuditOutlined } from "@ant-design/icons";
import { css, Global } from "@emotion/react";
import accounting from "accounting-js";
import { Button, Table, Tag, theme, Tooltip, Typography } from "antd";
import { useRouteMatch } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";

import { UserContext } from "../../context/user";
import { ColTitle } from "../../queues/util";
import { usePaymentRate } from "../util/usePaymentRate";
import { ClaimWorkspaceAuditFindingList } from "./auditFinding/claimWorkspaceAuditFinding";
import {
  createDenialCodeRowTags,
  createUpdateAuditFindingErrorHandler,
} from "./shared";
import { getTableRowStyle, toDenialCodesTagMap } from "./util";

const { Text } = Typography;

const PureClaimWorkspaceTable = (props: any) => {
  const {
    setCreatingAuditFinding,
    setDecliningAuditFinding,
    navigateToIbinRow,
    readOnly,
    batchClaim,
    variables: { afclFilter },
  } = props;

  const {
    token: {
      colorPrimaryText,
      colorTextDisabled,
      red3: NEEDS_REVIEW,
      green3: ACCEPTED,
    },
  } = theme.useToken();
  const findingsRowColors = {
    NEEDS_REVIEW,
    ACCEPTED,
  };

  /*
    * FIXME:
    * the query being passed to this component was not hitting the cache for batchClaims from the Audit/ReportWorkspace
    * which led to slow (or sometime no) loading of the batchClaimLines in the ClaimTable,
    * so now we just pass the data through props ('batchClaim.batchClaimLines')
    * until we figure out how to manage the urql graphcache to handle the filters properly for cache hits

    * NOTE: If any such query is added ensure that the query is the correct user specific workstation query defined in user.js
  */

  //const [{ fetching, data, error }] = query;

  const viewingReport =
    useRouteMatch("/reports/:auditFindingReportId/workspace") !== null;
  const { permissions } = React.useContext(UserContext);
  const showFindings =
    permissions.findingsActions.includes("view") || viewingReport;

  const paymentRateUtility = usePaymentRate(batchClaim);

  // FIXME use data when cache works for query
  //const batchClaimLines = !data ? [] : data.batchClaim.batchClaimLines.nodes;
  const batchClaimLines = batchClaim.batchClaimLines
    ? batchClaim.batchClaimLines.nodes
    : [];
  const summedColumnValues = { billedAmount: 0, allowedAmount: 0 };
  batchClaimLines.forEach((batchClaimLine: any) => {
    summedColumnValues.allowedAmount += batchClaimLine.unitAllowableCharge;
    summedColumnValues.billedAmount += batchClaimLine.unitCharge;
  });

  const revCodes = batchClaimLines.map(({ revCode }: any) => ({
    text: revCode,
    value: revCode,
  }));

  revCodes.sort((a: any, b: any) => a.value - b.value);

  const updateAuditFindingErrorHandler = createUpdateAuditFindingErrorHandler(
    (findingInfoArr) =>
      findingInfoArr.map((fi) => {
        const finding = batchClaim.auditFindings.nodes.find(
          (af: any) => af.id === fi.auditFindingId,
        );
        const ubLineIndex = batchClaimLines.findIndex(
          (bcl: any) => bcl.id === finding.batchClaimLineId,
        );
        const revCode =
          ubLineIndex === -1 ? "" : batchClaimLines[ubLineIndex].revCode;
        return (
          <>
            Rev Code {revCode} (UB line {ubLineIndex + 1}) -{" "}
            <Text strong={true}>
              {finding?.auditFindingRuleTypeByAuditFindingRuleType.displayName}
            </Text>{" "}
            {accounting.formatMoney(finding?.improperPaymentCost)}
          </>
        );
      }),
  );

  const columns = [
    {
      dataIndex: "lineNumber",
      key: "lineNumber",
      width: 18,
      render: (ln: any) => ln || "",
      className: "claim_workspace_table_meta",
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Rev Code For UB Line"
          title="Rev. Code"
          id="rev-code"
        />
      ),
      dataIndex: "revCode",
      key: "revCode",
      width: 60,
      filters: revCodes,
      filterMode: "tree",
      onFilter: (value: any, record: any) =>
        record && record.revCode.includes(value),
      filterSearch: true,
      render: (revCode: any) => {
        return <Tag>{revCode}</Tag>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Rev Code Description For UB Line"
          title="Rev. Description"
          id="rev-code-description"
        />
      ),
      dataIndex: "revDescription",
      key: "revDescription",
      width: 150,
      filters: batchClaimLines.map(({ revDescription }: any) => ({
        text: revDescription,
        value: revDescription,
      })),
      onFilter: (value: any, record: any) =>
        record && record.revDescription.includes(value),
      filterSearch: true,
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Procedure Code For UB Line"
          title="Proc. Code"
          id="procedure-code"
        />
      ),
      dataIndex: "procedureCode",
      key: "procedureCode",
      width: 60,
      filters: batchClaimLines.map(({ procedureCode }: any) => ({
        text: procedureCode,
        value: procedureCode,
      })),
      onFilter: (value: any, record: any) =>
        record && record.procedureCode.includes(value),
      filterSearch: true,
      render: (procedureCode: any) => {
        return procedureCode ? procedureCode : "-";
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Service Name for UB Line"
          title="Service Name" //Service Name
          id="service-name"
        />
      ),
      dataIndex: "procedureDescription",
      key: "procedureDescription",
      width: 90,
      render: (procedureDescription: any) => {
        return procedureDescription ? procedureDescription : "-";
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle tooltipTitle="UB Line Quantity" title="Qty" id="units" />
      ),
      dataIndex: "units",
      key: "units",
      width: 60,
      align: "center",
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle={`Payment Rate${
            paymentRateUtility.showStrategy
              ? ` - ${paymentRateUtility.strategyLabel}`
              : ""
          }`}
          title="Payment Rate"
          id="payment-rate"
        />
      ),
      key: "paymentRate",
      render: (_: any, batchClaimLine: any) => {
        return (
          <Tooltip
            title={`${paymentRateUtility.getActiveLineRatePercent(
              batchClaimLine,
              5,
            )}%`}
          >
            <div>{`~${paymentRateUtility.getActiveLineRatePercent(
              batchClaimLine,
              0,
            )}%`}</div>
          </Tooltip>
        );
      },
      width: 90,
      align: "center",
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Billed Amount on UB Line"
          title="Billed Amount"
          id="billed-amount"
        />
      ),
      dataIndex: "unitCharge",
      key: "unitCharge",
      width: 90,
      align: "right",
      sorter: (a: any, b: any) => a.unitCharge - b.unitCharge,
      render: (value: any) => accounting.formatMoney(value),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Allowed Amount on UB Line"
          title="Allowed Amount"
          id="allowed-amount"
        />
      ),
      dataIndex: "unitAllowableCharge",
      key: "unitAllowableCharge",
      width: 90,
      align: "right",
      sorter: (a: any, b: any) => a.unitAllowableCharge - b.unitAllowableCharge,
      render: (value: any) => accounting.formatMoney(value),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Determined Discrepant Amount on UB Line"
          title="Det. Discrepant Amount"
          id="discrepant-amount"
        />
      ),
      key: "determinedDiscrepantAmount",
      dataIndex: "computedValues",
      width: 90,
      align: "right",
      render: ({ determinedDiscrepantAmount }: any) =>
        accounting.formatMoney(determinedDiscrepantAmount),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Adjusted Billed Amount on UB Line"
          title="Adj. Billed Amount"
          id="adjusted-billed-amount"
        />
      ),
      key: "determinedAdjustedBilledAmount",
      dataIndex: "computedValues",
      width: 90,
      align: "right",
      render: ({ determinedAdjustedBilledAmount }: any) =>
        accounting.formatMoney(determinedAdjustedBilledAmount),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Adjusted Allowed Amount on UB Line"
          title="Adj. Allowed Amount"
          id="adjusted-allowed-amount"
        />
      ),
      key: "determinedAdjustedAllowedAmount",
      dataIndex: "computedValues",
      width: 90,
      align: "right",
      render: ({ determinedAdjustedAllowedAmount }: any) =>
        accounting.formatMoney(determinedAdjustedAllowedAmount),
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      title: <ColTitle title="Denial Code" id="denial-code" />,
      key: "denialCode",
      width: 90,
      align: "left",
      render: ({ auditFindings: { nodes } }: any) => {
        if (nodes.length === 0) {
          return "-";
        }

        const findingsTagsMap = toDenialCodesTagMap(nodes);

        return createDenialCodeRowTags(findingsTagsMap);
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "createAuditFinding",
      width: 30,
      render: (_label: any, batchClaimLine: any) => (
        <Button
          aria-label={"create-audit-finding-btn"}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          type="secondary"
          size="small"
          icon={
            <AuditOutlined
              css={css({
                color: readOnly ? colorTextDisabled : colorPrimaryText,
              })}
            />
          }
          disabled={readOnly}
          onClick={() => {
            console.debug("click on new finding!", batchClaimLine);
            setCreatingAuditFinding({
              visible: true,
              title: "New Claim Review Finding",
              batchClaimLine,
              auditFindingSeedType: "UB_CLAIM_LINE",
            });
          }}
        />
      ),
    },
  ];

  const table = (w: number, h: number) => {
    return (
      <div style={{ width: w, maxWidth: w }}>
        <Global
          styles={css`
            .claim_workspace_table_meta {
              font-size: 0.7em;
              color: #bfbfbf;
              border-right-style: none !important;
              padding-left: 2px !important;
              padding-right: 0 !important;
            }
          `}
        />

        <Table
          id="claim_workspace_table"
          bordered
          size="small"
          sticky={true}
          loading={batchClaimLines.length === 0}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          columns={columns}
          dataSource={batchClaimLines}
          rowKey={(batchClaimLine) => batchClaimLine.id}
          onRow={(batchClaimLine) =>
            getTableRowStyle({
              showFindings,
              findings: batchClaimLine.auditFindings.nodes,
              findingsRowColors,
            })
          }
          pagination={false}
          scroll={{
            y: h, // 'calc(100vh - 55px - 435px - 32px - 8px - 35px)',
          }}
          expandable={{
            columnWidth: 24,
            expandedRowRender: (batchClaimLine) => (
              <ClaimWorkspaceAuditFindingList
                key={batchClaimLine.id}
                auditFindings={batchClaimLine.auditFindings.nodes || []}
                {...{
                  setCreatingAuditFinding,
                  setDecliningAuditFinding,
                  navigateToIbinRow,
                  updateAuditFindingErrorHandler,
                  batchClaimLine,
                  readOnly,
                  afclFilter,
                }}
              />
            ),
            rowExpandable: (batchClaimLine) =>
              showFindings && batchClaimLine.auditFindings.nodes.length > 0,
          }}
          summary={() => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row tw="af-font-bold af-bg-antd-lt-colorBgElevated af-text-antd-lt-colorPrimary dark:(af-bg-antd-dk-colorBgElevated af-text-antd-dk-colorPrimary)">
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell align="right">
                    {/* Sum of the values in the billed amount columns */}
                    {accounting.formatMoney(summedColumnValues.billedAmount)}
                  </Table.Summary.Cell>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell align="right">
                    {/* Sum of the values in the Allowed amount columns */}
                    {accounting.formatMoney(summedColumnValues.allowedAmount)}
                  </Table.Summary.Cell>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-expect-error */}
                  <Table.Summary.Cell />
                </Table.Summary.Row>
              </Table.Summary>
            );
          }}
        />
      </div>
    );
  };

  return (
    <div
      data-foo="claim-ws-table-div"
      tw="af-w-full af-h-full af-min-h-full af-max-h-full"
    >
      <AutoSizer defaultHeight={900} defaultWidth={1200}>
        {({ height, width }: any) => {
          const h = height || 900;
          const w = width || 1200;
          return (
            <div
              data-foo="claim-ws-sized-div"
              style={{
                minHeight: h,
                height: h,
                width: w,
              }}
            >
              {/* todo account for table header/footer rather than hardcode */}
              {table(w - 16, h - (56 + 87))}
            </div>
          );
        }}
      </AutoSizer>
    </div>
  );
};

// FIXME: (AT) Maybe a better way to do this?
const ClaimWorkspaceTable = PureClaimWorkspaceTable;

export { ClaimWorkspaceTable };
