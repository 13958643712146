import React from "react";
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

import type { PromptFeedback } from "@alaffia-technology-solutions/schema";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import { Markdown } from "../Markdown";
import truncateMarkdown from "./truncateMarkdown";

type FeedbackItemProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLLIElement> & {
    feedback: PromptFeedback;
  }
>;

const FEEDBACK_CHAR_LIMIT = 80;

export const FeedbackItem = React.forwardRef<HTMLLIElement, FeedbackItemProps>(
  ({ feedback, className, children, ...props }, ref) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    const isExceedingCharLimit = feedback.message.length > FEEDBACK_CHAR_LIMIT;

    const displayText = React.useMemo(() => {
      if (isExceedingCharLimit && !isExpanded) {
        return truncateMarkdown(feedback.message, {
          limit: FEEDBACK_CHAR_LIMIT,
          ellipsis: true,
        });
      }

      return feedback.message;
    }, [feedback.message, isExceedingCharLimit, isExpanded]);

    return (
      <li
        className={cn(
          "af-flex af-items-center af-gap-2 af-rounded-lg af-p-4",
          {
            INFO: "",
            WARNING: "af-bg-warning-100 af-text-warning-600",
            ERROR: "af-bg-error-100 af-text-error-600",
          }[feedback.level],
          className,
        )}
        ref={ref}
        {...props}
      >
        <div className="af-flex af-w-full af-grow af-gap-2">
          <span className="af-sr-only">Feedback: </span>
          <Markdown className="af-w-full">{displayText}</Markdown>
          {isExceedingCharLimit && (
            <button
              className="af-flex af-w-12 af-shrink-0 af-self-end af-pt-2 af-text-xs af-text-gray-700"
              onClick={() => setIsExpanded((prev) => !prev)}
              aria-expanded={isExpanded}
              aria-label={isExpanded ? "Show less" : "Show more"}
            >
              {isExpanded ? (
                <span className="af-flex af-items-center">
                  <span>less</span>
                  <IconChevronUp className="af-ml-1 af-h-4 af-w-4" />
                </span>
              ) : (
                <span className="af-flex af-items-center ">
                  <span>more</span>
                  <IconChevronDown className="af-ml-1 af-h-4 af-w-4" />
                </span>
              )}
            </button>
          )}
        </div>

        {children}
      </li>
    );
  },
);
