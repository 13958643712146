/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type { ErrorInfo, FC, ReactNode } from "react";
import React, { useCallback } from "react";
import { Alert } from "antd";
import type { FallbackProps } from "react-error-boundary";
import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { useHistory } from "react-router-dom";

const Fallback = (props: FallbackProps) => {
  const history = useHistory();

  const { error } = props;

  return (
    <div tw="af-m-8 af-p-16">
      <Alert
        message={
          <span tw="af-inline-block af-align-top">
            Error loading requested resource
          </span>
        }
        showIcon
        description={
          <div tw="af-m-4">
            <div tw="af-p-2">
              Oops! Something went wrong...{" "}
              <button onClick={() => history.go(0)}>Reloading the page</button>{" "}
              may resolve the issue.
            </div>
            <div tw="af-p-2">
              If you received a link to this page, you may not have access to
              the resource. Contact the sender for more information.
            </div>
            <div tw="af-p-2">
              If you continue to experience issues accessing this resource,
              <br /> please let us know at:
              <a
                tw="af-p-2"
                href="mailto:support@alaffiahealth.com"
                target="_top"
              >
                support@alaffiahealth.com
              </a>
            </div>
            <div tw="af-p-2">Message: {error?.message}</div>
          </div>
        }
        type="error"
      />
    </div>
  );
};

export const ErrorBoundary: FC<{
  boundaryDescription: string;
  children: ReactNode;
}> = ({ boundaryDescription, children }) => {
  const logError = useCallback(
    (error: Error, info: ErrorInfo) => {
      console.log(`ErrorBoundary Error (@ ${boundaryDescription}:`, {
        boundaryDescription,
        error,
        info,
      });
      console.error(error);
    },
    [boundaryDescription],
  );
  return (
    <ReactErrorBoundary FallbackComponent={Fallback} onError={logError}>
      {children}
    </ReactErrorBoundary>
  );
};
