/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useCallback, useContext } from "react";
import { gql, useClient } from "urql";

import { UserContext } from "../../../context/user";
import {
  AutodorFindingFragment,
  FindingFragment,
  ItemizedBillLineFragment,
} from "./stdFrags.stdgql";

export const useClaimPostFindingRefresh = () => {
  const { userType }: any = useContext(UserContext);
  const client = useClient();
  const doRefresh = useCallback(
    async ({
      claimId,
      ibLineIds,
    }: {
      claimId: string;
      ibLineIds: string[];
    }) => {
      if (userType === "PROVIDER") {
        throw new Error(
          "Unexpected permissions error, please contact support.",
        );
      }
      const q = gql`
        query claimFindingsAndLine($claimId: UUID, $ibLineIds: [UUID!]!) {
          claim(id: $claimId) {
            __typename
            id
            icn
            findings {
              ...FindingFragment
            }
            autodor {
              claimId
              sinks {
                s3Key
                timestamp
              }
              autodorFindings {
                ...autodorFindingFragment
              }
            }
          }
          itemizedBillLines(ids: $ibLineIds) {
            ...ItemizedBillLineFragment
          }
        }
        ${FindingFragment}
        ${ItemizedBillLineFragment}
        ${AutodorFindingFragment}
      `;

      return client
        .query(
          q,
          {
            claimId,
            ibLineIds,
          },
          { requestPolicy: "network-only" },
        )
        .toPromise();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  );

  return doRefresh;
};
