/* eslint-disable @typescript-eslint/no-unsafe-argument */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { css } from "@emotion/react";
import { Disclosure } from "@headlessui/react";
import { theme } from "antd";
import resolveConfig from "tailwindcss/resolveConfig";

import twConfig from "../../../../tailwind.config";

const tailwindConfig = resolveConfig(twConfig as any);

const getColorCss = (c: string) =>
  css({
    backgroundColor: c,
    ".text": {
      color: "rgb(255, 255, 255)",
      mixBlendMode: "difference",
    },
  });

const objectToKvColorArray = (prefix: string, object: object) =>
  Object.entries<any>(object).reduce<{ name: string; color: string }[]>(
    (acc, [k, v]) => {
      if (v!.startsWith && (v.startsWith("#") || v.startsWith("rgb"))) {
        acc.push({
          name: `${(prefix && prefix + "-") ?? ""}${k}`,
          color: v as string,
        });
      }
      return acc;
    },
    [],
  );

const nestedObjectToColorArray = (object: object) =>
  Object.entries<any>(object).reduce<{ name: string; color: string }[]>(
    (acc, [k, v]) => {
      console.log(k, v);
      if (typeof v === "object") {
        const subcolors = objectToKvColorArray(k, v);
        if (subcolors.length > 0) {
          acc.push(...subcolors);
        }
      } else if (v!.startsWith?.("#")) {
        acc.push({ name: k, color: v as string });
      }
      return acc;
    },
    [],
  );

export const Colors = () => {
  const { token } = theme.useToken();
  return (
    <>
      <Disclosure>
        <Disclosure.Button tw="af-py-2 af-w-full">
          tailwind theme colors
        </Disclosure.Button>
        <Disclosure.Panel tw="af-text-gray-500">
          <div tw="af-grid af-grid-cols-3 af-gap-y-4">
            <>
              {nestedObjectToColorArray(
                tailwindConfig?.theme?.colors ?? {},
              ).map((cObj) => (
                <>
                  <div tw="af-p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.name}</span>
                  </div>
                  <div tw="af-col-span-2 af-p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.color}</span>
                  </div>
                </>
              ))}
            </>
          </div>
        </Disclosure.Panel>
      </Disclosure>

      <Disclosure>
        <Disclosure.Button tw="af-py-2 af-w-full">
          tailwind theme tokens object
        </Disclosure.Button>
        <Disclosure.Panel tw="af-text-gray-500">
          {/*<pre>{JSON.stringify(tailwindColors, null, 3)}</pre>*/}
          <pre>{JSON.stringify(tailwindConfig, null, 3)}</pre>
        </Disclosure.Panel>
      </Disclosure>

      <Disclosure>
        <Disclosure.Button tw="af-py-2 af-w-full">
          antd theme colors
        </Disclosure.Button>
        <Disclosure.Panel tw="af-text-gray-500">
          <div tw="af-grid af-grid-cols-3 af-gap-y-4">
            <>
              {objectToKvColorArray("", token ?? {}).map((cObj) => (
                <>
                  <div tw="af-p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.name}</span>
                  </div>
                  <div tw="af-col-span-2 af-p-1" css={getColorCss(cObj.color)}>
                    <span className="text">{cObj.color}</span>
                  </div>
                </>
              ))}
            </>
          </div>
        </Disclosure.Panel>
      </Disclosure>
      <Disclosure>
        <Disclosure.Button tw="af-py-2 af-w-full">
          antd theme tokens object
        </Disclosure.Button>
        <Disclosure.Panel tw="af-text-gray-500">
          <pre>{JSON.stringify(token, null, 3)}</pre>
        </Disclosure.Panel>
      </Disclosure>
    </>
  );
};
