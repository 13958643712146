import * as React from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { LexicalEditor } from "lexical";

export const EditorCapturePlugin = React.forwardRef<LexicalEditor, unknown>(
  (_, ref) => {
    const [editor] = useLexicalComposerContext();
    React.useImperativeHandle(ref, () => editor, [editor]);

    return null;
  },
);
EditorCapturePlugin.displayName = "EditorCapturePlugin";
