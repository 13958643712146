import type { HTMLAttributes } from "react";
import type { Components } from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { coy } from "react-syntax-highlighter/dist/cjs/styles/prism";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

interface CodeBlockProps extends HTMLAttributes<HTMLDivElement> {
  children: string;
  language: string;
}

const CodeBlock = ({
  children,
  className,
  language,
  ...rest
}: CodeBlockProps) => (
  <div className={cn("af-mt-6 af-border", className)} {...rest}>
    <SyntaxHighlighter language={language} PreTag="div" style={coy}>
      {children}
    </SyntaxHighlighter>
  </div>
);

const lanuageRegex = /language-(\w+)/;

const matchLanguage = (className: string) => lanuageRegex.exec(className)?.[1];

const transformCodeBlockChildren = (children: string) =>
  children.replace(/\n$/, "");

export const Code: Components["code"] = ({ children, className, ...rest }) => {
  const language = className ? matchLanguage(className) : null;

  const isCodeBlock = typeof children === "string" && language;

  if (isCodeBlock) {
    return (
      <CodeBlock className={className} language={language}>
        {transformCodeBlockChildren(children)}
      </CodeBlock>
    );
  }

  return (
    <code className={cn("af-mt-6", className)} {...rest}>
      {children}
    </code>
  );
};
