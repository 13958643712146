/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { keyBy, pick } from "lodash";

import type {
  BatchClaimLine,
  ItemizedBillLine,
} from "../../../../../gql/graphql";
import type { RevCodeBclInfo } from "../types";

export const extractRevCodes = (batchClaimLines: (BatchClaimLine | null)[]) =>
  (batchClaimLines ?? [])
    .filter((line) => !!line)
    .map(
      (line) =>
        pick(
          line,
          "id",
          "revCode",
          "revDescription",
          "unitCharge",
        ) as RevCodeBclInfo,
    );

export const revCodesForSelectedIbLines = (
  revCodeBclInfos: RevCodeBclInfo[],
  ibLines: ItemizedBillLine[],
) => {
  const onSelectedIbLines: string[] = (ibLines ?? [])
    .filter((ibLine: any) => ibLine?.revCode ?? false)
    .map((ibLine: any) => ibLine.revCode)
    .reduce((acc: string[], curr: any) => {
      if (!acc.includes(curr)) {
        acc.push(curr);
      }
      return acc;
    }, []);

  const recommended = revCodeBclInfos.filter((bcl) =>
    onSelectedIbLines.includes(bcl.revCode),
  );

  const other = revCodeBclInfos.filter(
    (bcl) => !onSelectedIbLines.includes(bcl.revCode),
  );

  const byId = keyBy(revCodeBclInfos, "id");

  return { recommended, other, revCodeBclInfos, onSelectedIbLines, byId };
};

export type RevCodesForSelectedIbLines = ReturnType<
  typeof revCodesForSelectedIbLines
>;
