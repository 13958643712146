/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import type { FC, ReactElement } from "react";
import { useCallback, useState } from "react";
import { Button, Skeleton } from "antd";
import Split from "react-split-grid";
import AutoSizer from "react-virtualized-auto-sizer";
import tw, { css } from "twin.macro";

const splitterLayout = css({
  display: "grid",
  gridTemplateColumns: "1fr 10px 1fr",
  ...tw`af-h-full af-max-h-full af-min-h-full af-w-full`,

  "& .gutter-col": {
    gridRow: "1/-1",
    cursor: "col-resize",
    ...tw`af-bg-antd-lt-colorFill
     af-w-1 af-h-full af-max-h-full af-min-h-full af-rounded-md af-shadow-md
     af-border af-border-solid af-border-antd-lt-colorBorderSecondary`,
  },

  "& .gutter-col-1": {
    gridColumn: 2,
  },
});

export interface VerticalSplitterProps {
  left: ReactElement;
  right: ReactElement;
}

export const VerticalSplitter: FC<VerticalSplitterProps> = ({
  left,
  right,
}) => {
  const [gridTemplateColumns, setGridTemplateColumns] = useState<string[]>([
    "1fr",
    "10px",
    "1fr",
  ]);

  const [isSplit, setIsSplit] = useState<boolean>(true);

  const onDrag = useCallback(
    (direction: string, track: number, gridTemplateString: string) => {
      if (!isSplit) {
        return;
      }
      console.log("drag", direction, track, gridTemplateString);
      setGridTemplateColumns(gridTemplateString.split(" "));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  return (
    <div tw="af-h-full af-w-full af-max-h-full af-min-h-full af-flex af-flex-col">
      <div>
        <Button onClick={() => setIsSplit(false)}>single</Button>
        <Button onClick={() => setIsSplit(true)}>split</Button>
      </div>
      <div tw="af-flex-1">
        <div tw="af-h-full af-max-h-full af-min-h-full">
          {/* <Split> does not for some reason define children as a
          prop despite this is from the sample usage, so ignore for now: */}
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          <Split
            minSize={100}
            snapOffset={20}
            dragInterval={20}
            gridTemplateColumns={
              isSplit ? gridTemplateColumns.join(" ") : "1fr"
            }
            onDrag={onDrag}
          >
            {({
              getGridProps,
              getGutterProps,
            }: {
              getGridProps: any;
              getGutterProps: any;
            }) => (
              <div css={splitterLayout} {...getGridProps()}>
                <div tw="af-p-1 af-h-full af-min-h-full af-max-h-full">
                  <AutoSizer defaultWidth={25} defaultHeight={25}>
                    {(props: any) => {
                      const { height: h, width: w } = props;
                      return !h || h <= 25 ? (
                        <Skeleton active={true} />
                      ) : (
                        <div
                          data-mv={`w:${w},h:${h}`}
                          style={{
                            minHeight: h,
                            height: h,
                            width: w,
                          }}
                        >
                          {left}
                        </div>
                      );
                    }}
                  </AutoSizer>
                </div>
                {isSplit && (
                  <>
                    <div
                      className="gutter-col gutter-col-1"
                      {...getGutterProps("column", 1)}
                    />
                    <div tw="af-p-1 af-h-full af-max-h-full af-min-h-full">
                      <AutoSizer defaultWidth={25} defaultHeight={25}>
                        {({
                          height: h,
                          width: w,
                        }: {
                          height: number;
                          width: number;
                        }) => {
                          return !h || h <= 25 ? (
                            <Skeleton active={true} />
                          ) : (
                            <div
                              data-mv={`w:${w},h:${h}`}
                              style={{
                                minHeight: h,
                                height: h,
                                width: w,
                              }}
                            >
                              {right}
                            </div>
                          );
                        }}
                      </AutoSizer>
                    </div>
                  </>
                )}
              </div>
            )}
          </Split>
        </div>
      </div>
    </div>
  );
};
