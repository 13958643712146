/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Suspense, useRef } from "react";
import { IconPdf } from "@tabler/icons-react";

import { PDFViewerProvider } from "@alaffia-technology-solutions/pdf-viewer";
import {
  ResizableHandle,
  ResizablePanel,
  ResizablePanelGroup,
  useExpandablePanel,
} from "@alaffia-technology-solutions/ui";

import { useAskAutodorFileVectorsExist } from "../audit/askAutodor/useAskAutodorFileVectorsExist";
import { PdfViewer } from "./PdfViewer";
import { PdfViewerFilesMenu } from "./PdfViewerFilesMenu";
import type { UiAuditDocumentation } from "./types";
import { useClaimFiles } from "./useClaimFiles";

interface PDFViewerDialogContentProps {
  claimId: string;
  selectedFile: UiAuditDocumentation | null;
  onFileSelected: (file: UiAuditDocumentation) => void;
  initialPage?: number;
}

export const PDFViewerDialogContent = ({
  claimId,
  selectedFile,
  onFileSelected,
  initialPage,
}: PDFViewerDialogContentProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { files, fetching } = useClaimFiles({ claimId });
  const [{ data: fileVectors }] = useAskAutodorFileVectorsExist(
    files?.map((f) => f.id as string),
  );
  const vectorizedFileIds =
    (fileVectors?.askAutodorFileVectorsExist?.documentIds as string[]) ?? [];

  const { expandPanel, collapsePanel, isExpanded, getExpandablePanelProps } =
    useExpandablePanel({
      wrapperRef,
      defaultExpanded: true,
    });

  return (
    <div
      ref={wrapperRef}
      className="autodor-reset af-flex af-w-full af-flex-col af-overflow-hidden af-p-0"
    >
      <h2 className="af-flex af-flex-grow-0 af-items-center af-border-b af-border-b-gray-300 af-p-4 af-text-base af-font-semibold af-text-gray-900">
        <IconPdf className="af-mr-2 af-h-5 af-w-5 af-text-gray-600" />
        Documents
      </h2>
      <ResizablePanelGroup
        id="group"
        direction="horizontal"
        className="af-h-full"
      >
        <ResizablePanel
          minSize={10}
          maxSize={40}
          {...getExpandablePanelProps({ defaultSize: 20 })}
        >
          <PdfViewerFilesMenu
            isLoading={fetching}
            files={files}
            onFileSelected={onFileSelected}
            selectedFileId={selectedFile?.id}
            isExpanded={isExpanded}
            expand={expandPanel}
            collapse={collapsePanel}
          />
        </ResizablePanel>
        <ResizableHandle withHandle={true} />
        <ResizablePanel
          minSize={60}
          defaultSize={70}
          className="af-flex af-min-h-0 af-flex-1 af-flex-col af-justify-between af-overflow-hidden"
        >
          <Suspense fallback={null}>
            <PDFViewerProvider workerKey="pdf-viewer">
              {selectedFile && (
                <PdfViewer
                  key={selectedFile.id}
                  fileId={selectedFile.id}
                  url={selectedFile.signedUrl}
                  page={initialPage}
                  searchEnabled={vectorizedFileIds.includes(selectedFile.id)}
                />
              )}
            </PDFViewerProvider>
          </Suspense>
        </ResizablePanel>
      </ResizablePanelGroup>
    </div>
  );
};
