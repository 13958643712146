/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Form, Input } from "antd";

export enum DRG_ITEM {
  DIAG = "diag",
  DESC = "desc",
  POA = "poa",
  PROC = "proc",
  NOTES = "notes",
}

export enum DRG_MODE {
  ORIGINAL = "Original",
  NEW = "New",
  REVIEW = "Review",
}

const typeToClassNames = (type: DRG_ITEM, changed: boolean) => {
  const base = changed ? "formItem changed " : "formItem ";
  switch (type) {
    case DRG_ITEM.DIAG:
      return base + "diagnosis";
    case DRG_ITEM.DESC:
      return base + "description";
    case DRG_ITEM.PROC:
      return base + "procedure";
    case DRG_ITEM.POA:
      return base + "poa";
    case DRG_ITEM.NOTES:
      return base + "poa";
    default:
      return "";
  }
};

export type DrgFormItemMode = keyof DRG_MODE;

export interface DrgFormItemProps {
  type: DRG_ITEM;
  dataKey?: string | string[];
  currentValue: any;
  previousValue?: any;
  isSplitScreen: boolean;
  editable: boolean;
  mode: DRG_MODE;
}

// TODO do we want to enable diffing?
// const isChanged = (current: any, prev: any) => current !== prev;
const isChanged = (_current: any, _prev: any) => false;

export const DrgFormItem: React.FC<DrgFormItemProps> = ({
  type,
  dataKey,
  currentValue,
  previousValue,
  isSplitScreen,
  editable,
  mode,
}) => {
  return (
    <Form.Item
      className={typeToClassNames(type, isChanged(currentValue, previousValue))}
      name={dataKey}
      initialValue={currentValue}
      {...(mode !== DRG_MODE.REVIEW || isSplitScreen
        ? {}
        : {
            extra: (
              <span
                style={{
                  paddingLeft: "12px",
                  paddingTop: "6px",
                  fontSize: "0.60rem",
                  lineHeight: "0.75rem",
                  ...(previousValue !== currentValue
                    ? { textDecoration: "line-through" }
                    : {}),
                }}
              >
                {previousValue}
              </span>
            ),
          })}
    >
      {type === DRG_ITEM.NOTES ? (
        <Input.TextArea
          autoSize={{ minRows: 1, maxRows: 4 }}
          disabled={mode === DRG_MODE.ORIGINAL || !editable}
        />
      ) : (
        <Input disabled={mode === DRG_MODE.ORIGINAL || !editable} />
      )}
    </Form.Item>
  );
};
