import { devtoolsExchange } from "@urql/devtools";
import { authExchange } from "@urql/exchange-auth";
import type { CacheExchangeOpts } from "@urql/exchange-graphcache";
import { cacheExchange } from "@urql/exchange-graphcache";
import type { Client } from "urql";
import { createClient, fetchExchange } from "urql";

import type { AuthExchangeHandlerHelpers } from "./authExchange";
import { authExchangeHandler } from "./authExchange";
import { graphCache } from "./graphCache";

export type UrqlClient = Client;

export interface CreateUrqlClientArgs {
  apiUrl: string;
  auth: AuthExchangeHandlerHelpers;
  // TODO we probably won't expose urqlCreateClient for sec reasons?
  //  but it might be useful in complex configs...
  //  in any case, peer-dependency of urql is prob something we should do
  urqlCreateClient?: typeof createClient;
  cacheExchangeOptions?: Partial<CacheExchangeOpts>;
  includeDevtoolsExchange?: boolean;
}

export const createUrqlClient = (args: CreateUrqlClientArgs): UrqlClient => {
  let createFn: typeof createClient | null = null;
  if (args.urqlCreateClient) {
    console.log(
      "** Using provided urqlCreateClient() function to bootstrap urql **",
      args.urqlCreateClient,
    );
    createFn = args.urqlCreateClient;
  } else {
    // console.log('USING **SDK-PKG** CREATE FN', args.urqlCreateClient);
    createFn = createClient;
  }
  return createFn({
    url: args.apiUrl,
    requestPolicy: "cache-first",
    exchanges: [
      ...(args.includeDevtoolsExchange ? [devtoolsExchange] : []),
      // dedupExchange, // now built into client
      cacheExchange(args.cacheExchangeOptions ?? graphCache),
      authExchange(authExchangeHandler(args.auth)),
      fetchExchange,
    ],
  });
};
