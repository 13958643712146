/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, message } from "antd";
import { gql, useMutation } from "urql";

import { batchClaimState } from "../../../fragments";
import { batchClaimsNodeKey, claimMenuItemDetails } from "../util";

// mutation to update workflow state
const setOnHoldMutation = gql`
  mutation setOnHoldMutation(
    $batchClaimIds: [UUID!]!
    $state: OnHoldClaimStateEnum!
  ) {
    setOnHoldClaimStates(
      input: { onHoldState: $state, batchClaimIds: $batchClaimIds }
    ) {
      onHoldClaimStates {
        batchClaim {
          ...batchClaimState
        }
      }
    }
  }
  ${batchClaimState}
`;

const typeActions = {
  setOnHold: {
    title: "Set Claim to On Hold",
    value: "ON_HOLD",
    actionCriteria: ({
      batchClaimState: { onHoldClaimState, workflowClaimState },
    }: any) => {
      // any Claim that isn't CLAIM_RECEIVED, CLOSED or NOT_ON_HOLD is valid
      const satisfied =
        ["NOT_ON_HOLD"].includes(onHoldClaimState) &&
        ["TO_DO", "IN_PROGRESS", "COMPLETED"].includes(workflowClaimState);
      return { satisfied };
    },
  },
  setRemoveHold: {
    title: "Remove Hold on Claim",
    value: "NOT_ON_HOLD",
    actionCriteria: ({ batchClaimState: { onHoldClaimState } }: any) => {
      /* 
          criteria is dependent on othe states -->
            cannot set to NOT_ON_HOLD unless it is not NOT_ON_HOLD
            however the default state of a claim is NOT_ON_HOLD and in order to exit NOT_ON_HOLD
            the criteria of SENT and ON_HOLD must be made which is more stringent
      */
      const satisfied = ["ON_HOLD"].includes(onHoldClaimState);
      return { satisfied };
    },
  },
};

const actionSubMenuItem = ({
  action,
  value,
  batchClaims,
  actionCriteria,
  setOnHold,
  title,
  setLoading,
}: any) => {
  const actionClaims = batchClaims.filter(
    (i: any) => actionCriteria(i).satisfied,
  );
  return (
    <Menu.Item
      key={`${action}-${value}-menuitem-${batchClaimsNodeKey(batchClaims)}`}
      disabled={!actionClaims.length}
      onClick={async () => {
        setLoading(true);
        const { data, error } = await setOnHold({
          state: value,
          batchClaimIds: actionClaims.map(({ id }: any) => id),
        });

        setLoading(false);
        if (!data && error) {
          const errorMessage = `${title} failed`;
          console.error(`${errorMessage}: ${error.message}`);
          void message.error(errorMessage);
        } else {
          void message.success(`${title} success`);
        }
      }}
    >
      {title} {claimMenuItemDetails({ batchClaims, actionClaims })}
    </Menu.Item>
  );
};

const actionSubMenu = ({ batchClaims, permissions, setLoading }: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [, setOnHold] = useMutation(setOnHoldMutation);
  return (
    <Menu.SubMenu
      key={`onHold-submenu-${batchClaimsNodeKey(batchClaims)}`}
      title="On Hold"
    >
      {Object.entries(typeActions)
        .filter(([action, _]) =>
          permissions.claimActions.onHold.includes(action),
        )
        .map(([action, { ...actionProps }]) =>
          actionSubMenuItem({
            action,
            batchClaims,
            setOnHold,
            setLoading,
            ...actionProps,
          }),
        )}
    </Menu.SubMenu>
  );
};

const onHold = {
  title: "On Hold",
  subMenu: actionSubMenu,
};

export { onHold };
