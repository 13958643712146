import * as React from "react";
import { IconX } from "@tabler/icons-react";

import { Button } from "@alaffia-technology-solutions/ui";

export const CloseButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>(({ children = <IconX className="af-h-5 af-w-5" />, ...props }, ref) => (
  <Button ref={ref} aria-label="Close" variant="ghost" size="icon" {...props}>
    {children}
  </Button>
));
CloseButton.displayName = "CloseButton";
