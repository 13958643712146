import { useEffect, useRef } from "react";

import { useEventCallback } from "@alaffia-technology-solutions/hooks";

import { usePDF } from "./use-pdf";

interface UsePDFViewerSyncOptions {
  url: string;
  fileId: string;
  pageIndex: number;
  highlights?: string[];
  openDelay?: number;
  onOpen?: () => Promise<void> | void;
}

export function usePDFViewerSync({
  url,
  fileId,
  pageIndex,
  highlights,
  openDelay,
  onOpen,
}: UsePDFViewerSyncOptions) {
  const { close, jumpToPage, open } = usePDF();

  const highlightsRef = useRef(highlights);
  const pageIndexRef = useRef(pageIndex);
  const openDelayRef = useRef(openDelay);

  useEffect(() => {
    highlightsRef.current = highlights;
    pageIndexRef.current = pageIndex;
    openDelayRef.current = openDelay;
  });

  const isOpened = useRef(false);
  const onOpenCallback = useEventCallback(onOpen);

  useEffect(() => {
    isOpened.current = false;

    const openTimeout = setTimeout(
      () =>
        void open(url, fileId, pageIndexRef.current, highlightsRef.current)
          .catch(console.error)
          .then(() => {
            isOpened.current = true;
            return onOpenCallback?.();
          }),
      openDelayRef.current,
    );
    return () => {
      isOpened.current = false;
      clearTimeout(openTimeout);
      void close();
    };
  }, [url, fileId, open, close, onOpenCallback]);

  useEffect(() => {
    if (!isOpened.current) {
      return;
    }

    void jumpToPage(pageIndex).catch(console.error);
  }, [pageIndex, jumpToPage]);
}
