import * as React from "react";
import { IconX } from "@tabler/icons-react";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { AlertCircle } from "lucide-react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import { Button } from "./Button";

const alertVariants = cva(
  "af-relative af-w-full af-flex af-gap-2 af-rounded-lg af-border af-p-4 [&>svg~*]:af-pl-7 [&>svg+div]:af-translate-y-[-3px] [&>svg]:af-absolute [&>svg]:af-left-5 [&>svg]:af-top-5 [&>svg]:af-text-foreground",
  {
    variants: {
      variant: {
        default: "af-bg-background af-text-foreground",
        destructive:
          "af-border-error-500 af-bg-error-100 af-text-error-700 [&_svg]:af-text-error-500 [&_.title]:af-text-error-900",
        informative:
          "af-border-info-500 af-bg-info-100 af-text-info-700 [&_svg]:af-text-info-500 [&_.title]:af-text-info-900",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
);

export interface AlertProps extends React.HTMLAttributes<HTMLDivElement> {
  showCloseButton?: boolean;
}

const Alert = React.forwardRef<
  HTMLDivElement,
  AlertProps & VariantProps<typeof alertVariants>
>(({ className, variant, showCloseButton = true, children, ...props }, ref) => {
  const [visible, setVisible] = React.useState(true);

  if (!visible) {
    return null;
  }

  return (
    <div
      ref={ref}
      role="alert"
      className={cn(alertVariants({ variant }), className)}
      {...props}
    >
      <div className="af-w-full [&>svg+div]:af-translate-y-[-3px] [&>svg]:af-absolute [&>svg]:af-left-5 [&>svg]:af-top-5 [&>svg]:af-text-foreground [&>svg~*]:af-pl-7">
        {children}
      </div>
      {showCloseButton && (
        <Button
          aria-label="Close alert"
          onClick={() => setVisible(false)}
          className="hover:af-bg-error-100 hover:af-text-error-900"
          variant="ghost"
          size="icon"
        >
          <IconX className="af-h-4 af-w-4" />
        </Button>
      )}
    </div>
  );
});
Alert.displayName = "Alert";

const AlertTitle = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLHeadingElement>
>(({ className, children, ...props }, ref) => (
  <h5
    ref={ref}
    className={cn(
      "title af-mb-1 af-align-middle af-text-sm af-font-semibold af-leading-6 af-tracking-tight",
      className,
    )}
    {...props}
  >
    {children}
  </h5>
));
AlertTitle.displayName = "AlertTitle";

const AlertDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(
      "af-text-xs af-font-medium [&_p]:af-leading-relaxed",
      className,
    )}
    {...props}
  />
));
AlertDescription.displayName = "AlertDescription";

export { Alert, AlertCircle, AlertDescription, AlertTitle };
