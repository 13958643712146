"use client";

import { createContext, useContext, useMemo } from "react";

import { useSuspenseCreateFoxitJrWorker } from "../loaders";
import { usePDFConfig } from "./pdf-config-provider";

const WorkerConfigContext = createContext<
  WorkerConfig | { jrConfig: null; libPath: null; workerKey: null }
>({ jrConfig: null, libPath: null, workerKey: null });

export interface JrWorkerConfig {
  workerPath?: string;
  enginePath?: string;
  fontPath?: string;
  fontInfoPath?: string;
  licenseSN?: string;
  licenseKey?: string;
}

export type RequiredJrWorkerConfig = Required<JrWorkerConfig>;

export interface WorkerConfig {
  jrConfig: RequiredJrWorkerConfig;
  libPath: string;
  workerKey?: string;
}

interface WorkerProviderProps {
  children?: React.ReactNode;
  jrConfig?: JrWorkerConfig;
  libPath?: string;
  workerKey: string;
}

/**
 * WorkerProvider is a provider that should be used to provide the Foxit PDF
 * Worker. It's used internally by the PDFViewerProvider. If you want multiple
 * providers in your application, you can use this provider directly. It will
 * load default jr settings from PDFViewerProvider if not provided.
 *
 * If you dont't want to use root level worker you can disable it with setting
 * strategy prop to `lazy`.
 *
 * Example:
 * ```ts
 * import { PDFViewerProvider, WorkerProvider } from "@alaffia-technology-solutions/pfg-viewer";
 *
 * const App = () => {
 *   return (
 *     <PDFViewerProvider jrConfig={jrConfig} strategy="lazy">
 *       <WorkerProvider><FirstPdfViewer/></WorkerProvider>
 *       <WorkerProvider><SecondPdfViewer/></WorkerProvider>
 *     </PDFViewerProvider>
 *   );
 * }
 * ```
 */
export const WorkerProvider = ({
  children,
  libPath,
  jrConfig,
  workerKey,
}: WorkerProviderProps) => {
  const { jrConfig: existingJrConfig, libPath: existingLibPath } =
    usePDFConfig();

  const newJrConfig: Partial<JrWorkerConfig> = jrConfig ? jrConfig : {};

  const finalConfig = useMemo(() => {
    const finalLibPath = libPath ?? existingLibPath;

    const licenseKey = existingJrConfig?.licenseKey;
    const licenseSN = existingJrConfig?.licenseSN;

    if (!licenseKey) throw new Error("licenseKey is required");
    if (!licenseSN) throw new Error("licenseSN is required");

    return {
      jrConfig: {
        workerPath: newJrConfig.workerPath ?? existingJrConfig?.workerPath,

        enginePath: newJrConfig.enginePath ?? existingJrConfig?.enginePath,

        fontPath: newJrConfig.fontPath ?? existingJrConfig?.fontPath,
        fontInfoPath:
          newJrConfig.fontInfoPath ?? existingJrConfig?.fontInfoPath,
        licenseSN,
        licenseKey,
      },
      libPath: finalLibPath,
      workerKey,
    };
  }, [
    libPath,
    existingLibPath,
    existingJrConfig?.licenseKey,
    existingJrConfig?.licenseSN,
    existingJrConfig?.workerPath,
    existingJrConfig?.enginePath,
    existingJrConfig?.fontPath,
    existingJrConfig?.fontInfoPath,
    newJrConfig.workerPath,
    newJrConfig.enginePath,
    newJrConfig.fontPath,
    newJrConfig.fontInfoPath,
    workerKey,
  ]);

  return (
    <WorkerConfigContext.Provider value={finalConfig}>
      {children}
    </WorkerConfigContext.Provider>
  );
};

export const useWorkerConfig = () => {
  const jrConfig = useContext(WorkerConfigContext);

  if (!jrConfig.workerKey) {
    throw new Error("WorkerConfig is not initialzed");
  }

  return jrConfig;
};

export const useSuspenseWorker = () => {
  const config = useContext(WorkerConfigContext);

  if (!config.workerKey) {
    throw new Error("WorkerConfig is not initialized");
  }

  const { data: worker } = useSuspenseCreateFoxitJrWorker({
    key: config.workerKey,
    ...config.jrConfig,
  });

  if (!worker) {
    throw new Error("Worker is not available yet");
  }

  return worker;
};
