import React from "react";
import tw, { css } from "twin.macro";

const clsCellStyle = css({
  ...tw`af-p-0 af-m-0 af-w-full af-h-full af-overflow-hidden af-flex`,
  "&>div": tw`af-line-clamp-2  af-leading-5 af-align-baseline af-my-auto 
      af-p-0 af-whitespace-normal af-break-words af-text-xs`,
  "div.ag-right-aligned-cell &>div": tw`af-ml-auto`,
});
interface LineClampedCellProps {
  children: React.ReactNode;
  className?: string;
}
const _LineClampedCell: React.FC<LineClampedCellProps> = ({
  children,
  className,
}) => (
  <div css={clsCellStyle} {...(className ? { className } : {})}>
    <div>{children}</div>
  </div>
);
export const LineClampedCell = React.memo(_LineClampedCell);
