import type { Dispatch } from "react";
import { useEffect, useReducer, useRef } from "react";

import { useUserPreference } from "../../../util/preferences";

//  This hook wraps React.useReducer() to make available an UI options object (e.g. dark mode, theme, font-size, etc) that:
//   - provides a set of type-safe actions that update the state of the selected UI options
//   - defines the reducer logic to merge the existing state with the updates made via those actions
//   - also writes those updates to IndexedDb (~ local storage) so they persist
//
//  The patterns here are basically the 'older' Redux/rxjs-store patterns
//  (new versions have convenience fns to reduce boilerplate, e.g. https://redux-toolkit.js.org/api/createreducer)

// interfaces:
export interface UiOptions {
  showMenu?: boolean;
  darkMode?: boolean;
  borderRadius?: number | null;
  fontSize?: number | null;
}

const enum UiOptionActionTypes {
  SetShowMenu = "[UIOPTS] SET_SHOW_MENU",
  SetDarkMode = "[UIOPTS] SET_DARK_MODE",
  SetBorderRadius = "[UIOPTS] SET_BORDER_RADIUS",
  SetFontSize = "[UIOPTS] SET_FONT_SIZE",
  SetAll = "[UIOPTS] SET_ALL",
  Reset = "[UIOPTS] RESET",
}

interface IUiOptionsAction {
  type: UiOptionActionTypes;
}

// actions
export class SetShowMenu implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.SetShowMenu;

  constructor(public showMenu: boolean) {}
}
export class SetDarkMode implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.SetDarkMode;

  constructor(public darkMode: boolean) {}
}

export class SetBorderRadius implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.SetBorderRadius;

  constructor(public borderRadius: number) {}
}

export class SetFontSize implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.SetFontSize;

  constructor(public fontSize: number) {}
}

export class SetAll implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.SetAll;

  constructor(public options: UiOptions) {}
}

export class Reset implements IUiOptionsAction {
  readonly type = UiOptionActionTypes.Reset;
}

// union of available actions/action types
export type UiOptionActions =
  | SetShowMenu
  | SetDarkMode
  | SetBorderRadius
  | SetFontSize
  | SetAll
  | Reset;

type UiOptionsReducer = (
  state: UiOptions,
  action: UiOptionActions,
) => UiOptions;

// reducer - updates the values of uiOptions by merging the existing state and the requested changes
const uiOptionReducer: UiOptionsReducer = (
  state: UiOptions,
  action: UiOptionActions,
): UiOptions => {
  switch (action.type) {
    case UiOptionActionTypes.SetAll: {
      return state !== action.options ? action.options : state;
    }
    case UiOptionActionTypes.Reset: {
      return UiOptionsDefaultState;
    }
    case UiOptionActionTypes.SetShowMenu: {
      return {
        ...state,
        showMenu: action.showMenu,
      };
    }
    case UiOptionActionTypes.SetDarkMode: {
      return {
        ...state,
        darkMode: action.darkMode,
      };
    }
    case UiOptionActionTypes.SetBorderRadius: {
      return {
        ...state,
        borderRadius: action.borderRadius,
      };
    }
    case UiOptionActionTypes.SetFontSize: {
      return {
        ...state,
        fontSize: action.fontSize,
      };
    }
    default: {
      // because the typing above is strong, ts has narrowed action to
      // the types defined in the UiOptionActions -- so here the action
      // is inferred as typeof 'never' which doesn't have a 'type' prop
      // we'll use the string-accessor to get around this in case we pass
      // a bad value from a js file that doesn't enforce the type:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      console.error("Unknown action: " + action.type);
      return state;
    }
  }
};

// wraps the above reducer with a call to update the indexeddb version at the same time
export const uiOptionsReducerWithUserPrefsUpdate =
  (setUserSettings: (settings: UiOptions) => void): UiOptionsReducer =>
  (state: UiOptions, action: UiOptionActions): UiOptions => {
    const updatedPrefs = uiOptionReducer(state, action);
    if (updatedPrefs !== state) {
      setUserSettings(updatedPrefs);
    }
    return updatedPrefs;
  };

export const UiOptionsDefaultState: UiOptions = {
  showMenu: false,
  darkMode: false,
  borderRadius: null,
  fontSize: null,
};

export type UiOptionUpdateDispatch = Dispatch<UiOptionActions>;

/**
 * wraps useReducer and returns strong-typed fn for updating UIOptions
 * @param initialState
 */
export const useUiOptions = (
  initialState?: UiOptions,
): [uiOptions: UiOptions, updateUiOptions: UiOptionUpdateDispatch] => {
  // on first load we will update again when indexedDb (useUserPrefs returns):
  const isFirstLoad = useRef(true);
  const [userSettings, setUserSettings] = useUserPreference(`app.ux.prefs`);

  // merge any partial state passed with the default to make sure we have the full structure
  const initState = initialState
    ? {
        ...UiOptionsDefaultState,
        ...initialState,
      }
    : UiOptionsDefaultState;

  // create the reducer fn, using wrapper that updates indexedDb
  const reducer: UiOptionsReducer = uiOptionsReducerWithUserPrefsUpdate(
    // assert type here for typings, until we convert userPrefs to use generic <T>:
    setUserSettings as (settings: UiOptions) => void,
  );

  // create the React reducer to hold the state (like useState but calls the reducer to merge updates)
  const [uiOptions, updateUiOption] = useReducer(reducer, initState);

  // on the initial load delay the update until we've got the settings....
  useEffect(() => {
    if (isFirstLoad.current && userSettings) {
      isFirstLoad.current = false;
      updateUiOption(new SetAll(userSettings as unknown as UiOptions));
    }
  }, [userSettings]);

  return [uiOptions, updateUiOption];
};
