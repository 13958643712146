/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import type {
  AuditFinding,
  AuditFindingRuleType,
  AuditFindingSeedType,
  BatchClaimLineItem,
  BatchClaimLineItemAudit,
  Finding,
} from "../../../../gql/graphql";
import type { DenialCodes } from "../denialCodes/useDenialCodes";

export const findingToAF = (
  f: Finding,
  denialCodes: DenialCodes | null,
): AuditFinding => {
  if (!f) {
    throw new Error("findingToAF: no finding");
  }

  const ruleType = (f as any)?.denialCode ?? f.ruleType!;
  const dc = denialCodes?.byKey[ruleType];
  const auditFindingRuleTypeByAuditFindingRuleType = dc
    ? {
        ...dc,
      }
    : ({
        displayName: ruleType.substring(f.seedType!.length + 1),
        description: f.ruleType!,
        type: f.seedType!,
      } as AuditFindingRuleType);

  const af: AuditFinding = {
    ...f,
    accepted: f.accepted!,
    auditFindingRuleType: ruleType,
    auditFindingSeedType: f.seedType! as AuditFindingSeedType,

    auditorComment: null, // important ??? // TODO
    auditType: null,

    batchClaimLineId: f.findingItemizedBillData?.batchClaimLineId,

    // NEEDS ? ? : discrepantAmount

    isActive: true, // all findings on claim are...

    metaDataNcciMueAjudicationValue: f.metaDataNcciMueAdjudicationValue!, // YEAH this one was mispelled in AuditFinding

    needsReview: f.needsReview,

    reviewedAuditFinding: {
      nodeId: "",
      id: "no-id-converted-from-claim-finding",
      accepted: f.accepted,
      deleted: (f as any)?.deleted ?? false,
      createdAt: f.latestReview?.createdAt,
      updatedAt: f.latestReview?.createdAt,
      rationale: f.latestReview?.rationale,
    },

    auditFindingRuleTypeByAuditFindingRuleType,

    batchClaimLineItemAudit: {
      batchClaimLineItem: {
        ...f.findingItemizedBillData,
      } as BatchClaimLineItem,
    } as BatchClaimLineItemAudit,
  } as unknown as AuditFinding;

  return af;
};

// const bcliToFindingIbData = (bcli: BatchClaimLineItem, stale: boolean) => {
//   const {
//     alaRowId,
//     authorId,
//     batchClaimLineId,
//     createdAt,
//     dateOfServiceEnd,
//     dateOfServiceStart,
//     description,
//     ibLineId,
//     id,
//     itemNumber,
//     lineNumber,
//     procedureCode,
//     revCode,
//     s3InputSource,
//     // stale?: Maybe<Scalars['Boolean']>;
//     unitCharge,
//     units,
//     updatedAt,
//     versionFingerprint,
//   } = bcli;
//   return {
//     __typename: 'FindingItemizedBillData',
//     stale,
//     // extracted above:
//     alaRowId,
//     authorId,
//     batchClaimLineId,
//     createdAt,
//     dateOfServiceEnd,
//     dateOfServiceStart,
//     description,
//     ibLineId,
//     id,
//     itemNumber,
//     lineNumber,
//     procedureCode,
//     revCode,
//     s3InputSource,
//     unitCharge,
//     units,
//     updatedAt,
//     versionFingerprint,
//   };
// };

// const afFieldsToFindingFields = (af: AuditFinding) => {
//   const bcli = af.batchClaimLineItemAudit?.batchClaimLineItem;
//   const findingItemizedBillData = bcli
//     ? bcliToFindingIbData(bcli, false)
//     : undefined;
//   const author = {
//     __typename: 'FindingAuthor',
//     id: af.author!.id,
//     firstName: af.author!.firstName,
//     lastName: af.author!.lastName,
//     userType: af.author!.userType,
//   };
//   const confidence = '' + af.confidence;
//   const {
//     accepted,
//     // author: FindingAuthor;
//     // confidence?: Maybe<Scalars['String']>;
//     createdAt,
//     // findingItemizedBillData?: Maybe<FindingItemizedBillData>;
//     id,
//     improperPaymentCost,
//     improperPaymentReason,
//     improperPaymentUnitsCharged,
//     isOriginal,
//     metaDataAocPrimaryCode,
//     metaDataEmCode,
//     metaDataEmCorrectLevel,
//     metaDataEmOriginalLevel,
//     metaDataNcciMueAjudicationValue: metaDataNcciMueAdjudicationValue,
//     metaDataNcciMueOhpMueValue,
//     metaDataNcciPtpCode,
//     metaDataNcciPtpModifier,
//     metaDataPricingCorrectPrice,
//     metaDataUnitsCorrectUnits,
//     needsReview,
//     auditFindingRuleType: ruleType,
//     auditFindingSeedType: seedType,
//     superseded,
//     updatedAt,
//   } = af;
//   return {
//     __typename: 'Finding',
//     findingItemizedBillData,
//     author,
//     confidence,
//     // extracted above:
//     accepted,
//     createdAt,
//     id,
//     improperPaymentCost,
//     improperPaymentReason,
//     improperPaymentUnitsCharged,
//     isOriginal,
//     metaDataAocPrimaryCode,
//     metaDataEmCode,
//     metaDataEmCorrectLevel,
//     metaDataEmOriginalLevel,
//     metaDataNcciMueAdjudicationValue,
//     metaDataNcciMueOhpMueValue,
//     metaDataNcciPtpCode,
//     metaDataNcciPtpModifier,
//     metaDataPricingCorrectPrice,
//     metaDataUnitsCorrectUnits,
//     needsReview,
//     ruleType,
//     seedType,
//     superseded,
//     updatedAt,
//   };
// };
