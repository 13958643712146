import type { ComponentPropsWithoutRef } from "react";
import { forwardRef } from "react";

import { Files } from "../Files";
import { FilesListSkeleton } from "./FilesListSkeleton";

export interface BaseFilesPanelProps
  extends ComponentPropsWithoutRef<typeof Files> {
  loading?: boolean;
  actions?: React.ReactNode;
  expandedClassName?: string;
}

export const BaseFilesPanel = forwardRef<HTMLDivElement, BaseFilesPanelProps>(
  ({ children, actions, loading, position, ...props }, ref) => {
    return (
      <Files ref={ref} position={position} {...props}>
        <Files.Header position={position} />
        <Files.Body>
          {!loading ? children : <FilesListSkeleton />}
          {actions}
        </Files.Body>
      </Files>
    );
  },
);
BaseFilesPanel.displayName = "BaseFilesPanel";
