import type { ReactNode } from "react";
import React, { useState } from "react";

import type { SlotsState, SlotState, SlotType } from "./types";

interface Slots extends Record<SlotType, ReactNode> {
  left: React.ReactNode;
  right: React.ReactNode;
}

interface SplitViewContextProps {
  slotsState: SlotsState;
  show: (slots: Partial<Slots>) => void;
  closeAll: () => void;
  closeLeft: () => void;
  closeRight: () => void;
  enterFullscreenLeft: () => void;
  enterFullscreenRight: () => void;
  exitFullscreenLeft: () => void;
  exitFullscreenRight: () => void;
  removeNodeLeft: () => void;
  removeNodeRight: () => void;
  toggleFullscreenLeft: () => void;
  toggleFullscreenRight: () => void;
}

export const SPLIT_VIEW_ANIMATION_DURATION = 300;

const SplitViewContext = React.createContext<SplitViewContextProps | null>(
  null,
);

type SplitViewProviderProps = React.PropsWithChildren;

const slotStateReducer = (
  state: SlotState,
  otherState: SlotState,
  node: React.ReactNode,
): SlotState => {
  if (node) {
    return {
      state: otherState.state === "fullscreen" ? "fullscreen" : "open",
      node,
      prevState: state.prevState,
    };
  }
  if (state.state === "open" || state.state === "fullscreen") return state;

  return { state: "closed", prevState: state.prevState, node: null };
};

export function SplitViewProvider({ children }: SplitViewProviderProps) {
  const [slotsState, setSlotsState] = useState<SlotsState>({
    left: { state: "closed", node: null, prevState: null },
    right: { state: "closed", node: null, prevState: null },
  });

  const contextValue: SplitViewContextProps = {
    slotsState,
    show: (slots) => {
      const { left, right } = slots;
      setSlotsState((v) => {
        return {
          ...v,
          left: slotStateReducer(
            {
              ...v.left,
              prevState: v.left.state,
            },
            v.right,
            left,
          ),
          right: slotStateReducer(
            {
              ...v.right,
              prevState: v.right.state,
            },
            v.left,
            right,
          ),
        };
      });
    },
    closeAll: () => {
      setSlotsState((v) => ({
        left: { ...v.left, state: "closed", prevState: v.left.state },
        right: { ...v.right, state: "closed", prevState: v.right.state },
      }));
    },
    closeLeft: () => {
      setSlotsState((v) => ({
        ...v,
        left: { ...v.left, state: "closed", prevState: v.left.state },
      }));
    },
    closeRight: () => {
      setSlotsState((v) => ({
        ...v,
        right: { ...v.right, state: "closed", prevState: v.right.state },
      }));
    },
    enterFullscreenLeft: () => {
      setSlotsState((v) => ({
        ...v,
        left: { ...v.left, state: "fullscreen", prevState: v.left.state },
      }));
    },
    enterFullscreenRight: () => {
      setSlotsState((v) => ({
        ...v,
        right: { ...v.right, state: "fullscreen", prevState: v.right.state },
      }));
    },
    exitFullscreenLeft: () => {
      setSlotsState((v) => ({
        ...v,
        left: { ...v.right, state: "open", prevState: v.left.state },
      }));
    },
    exitFullscreenRight: () => {
      setSlotsState((v) => ({
        ...v,
        right: { ...v.right, state: "open", prevState: v.right.state },
      }));
    },
    removeNodeLeft: () => {
      setSlotsState((v) => ({
        ...v,
        left: { ...v.left, node: null },
      }));
    },
    removeNodeRight: () => {
      setSlotsState((v) => ({
        ...v,
        right: { ...v.right, node: null },
      }));
    },
    toggleFullscreenLeft: () => {
      setSlotsState((v) => ({
        ...v,
        left: {
          ...v.left,
          state: v.left.state === "open" ? "fullscreen" : "open",
          prevState: v.left.state,
        },
      }));
    },
    toggleFullscreenRight: () => {
      setSlotsState((v) => ({
        ...v,
        right: {
          ...v.right,
          state: v.right.state === "open" ? "fullscreen" : "open",
          prevState: v.right.state,
        },
      }));
    },
  };

  return (
    <SplitViewContext.Provider value={contextValue}>
      {children}
    </SplitViewContext.Provider>
  );
}

export function useSplitView() {
  const context = React.useContext(SplitViewContext);
  if (!context) {
    throw new Error("useSplitView must be used within a SplitViewProvider");
  }
  return context;
}
