import type {
  Cache,
  CacheExchangeOpts,
  FieldInfo,
  UpdatesConfig,
} from "@urql/exchange-graphcache";

import * as schema from "./schema/introspection.min.json";

export const graphCache: Partial<CacheExchangeOpts> = {
  schema: schema as CacheExchangeOpts["schema"],
  keys: {
    AskAutodorQueueSummary: () => null,
    PrepareFileForUploadResult: () => null,
    PromptResponsePage: () => null,
    PromptFeedback: () => null,
    PromptResponseRating: () => null,
    SignedDownloadUrl: () => null,
  },
  resolvers: {
    Query: {},
  },
  updates: {
    // Mutation: {},
  },
};

export const invalidateQuery = (
  cache: Cache,
  queryName: string,
  predicate: (field: FieldInfo) => boolean = () => true,
) => {
  const key = "Query";

  cache
    .inspectFields(key)
    .filter((field) => field.fieldName === queryName && predicate(field))
    .forEach((field) => {
      cache.invalidate(key, field.fieldName, field.arguments);
    });
};

export const mergeUpdateCacheOptions = (
  source: UpdatesConfig,
  target: UpdatesConfig,
): UpdatesConfig => {
  const merged = {
    ...source,
  };

  for (const key in target) {
    if (merged[key]) {
      merged[key] = {
        ...merged[key],
        ...target[key],
      };
    } else {
      merged[key] = target[key];
    }
  }

  return merged;
};
