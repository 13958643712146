/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import accounting from "accounting-js";

import type { ItemizedBillLine } from "../../../gql/graphql";

export interface DiscrepantAmountArgs {
  improperPaymentUnitsCharged: number;
  unitsBilled: number;
  amountCharged: number;
}

export type FindingAdjustments = DiscrepantAmountArgs & {
  ibLineId?: string;
  ibLineNumber: number;
  discrepantAmount: number;
  improperPaymentCost: number;
  overrides: {
    amountCharged?: number;
    improperPaymentCost?: number;
    isIPCOverride?: boolean;
  };
};

type CalculateDiscrepantAmountFn = (
  args: DiscrepantAmountArgs,
) => number | null;

export const calculateDiscrepantAmount: CalculateDiscrepantAmountFn = ({
  improperPaymentUnitsCharged,
  amountCharged,
  unitsBilled,
}) => {
  if ([improperPaymentUnitsCharged, amountCharged, unitsBilled].some(isNaN)) {
    return null;
  } else {
    const pricePerUnit = amountCharged / unitsBilled;
    return improperPaymentUnitsCharged * pricePerUnit;
  }
};

export const calculateAdjustmentAmount: (
  discrepantAmount: number,
  paymentRate: number,
) => number | null = (discrepantAmount, paymentRate) =>
  isNaN(discrepantAmount) ? null : paymentRate * discrepantAmount;

export interface FindingAdjustmentsFromIbLinesArgs {
  ibLines: ItemizedBillLine[];
  improperPaymentUnitsCharged: number;
  paymentRate: number;
  amountChargedOverride?: number;
  improperPaymentCostOverride?: number;
}

type FindingAdjustmentFromIbLinesFn = (
  args: FindingAdjustmentsFromIbLinesArgs,
) => FindingAdjustments[] | null;

export const findingAdjustmentsFromIbLines: FindingAdjustmentFromIbLinesFn = ({
  ibLines,
  improperPaymentUnitsCharged,
  paymentRate,
  amountChargedOverride,
  improperPaymentCostOverride,
}) =>
  (ibLines?.length ?? 0) < 1 ||
  isNaN(improperPaymentUnitsCharged) ||
  isNaN(paymentRate)
    ? null
    : ibLines
        .map((ibl) => {
          const discrepantAmount = calculateDiscrepantAmount({
            improperPaymentUnitsCharged,
            amountCharged: amountChargedOverride ?? ibl.totalCharge ?? 0,
            unitsBilled: ibl.units ?? 0,
          });
          const improperPaymentCost = calculateAdjustmentAmount(
            discrepantAmount ?? 0,
            paymentRate,
          );

          const ipcOverride =
            accounting.formatMoney(improperPaymentCostOverride) ===
            accounting.formatMoney(improperPaymentCost)
              ? undefined
              : improperPaymentCostOverride ?? undefined;

          return {
            ibLineId: ibl.id,
            ibLineNumber: ibl.lineNumber ?? 0,
            unitsBilled: ibl.units ?? 0,
            amountCharged: ibl.totalCharge ?? 0,
            discrepantAmount: discrepantAmount ?? 0,
            improperPaymentCost: improperPaymentCost ?? 0,
            improperPaymentUnitsCharged,
            overrides: {
              amountCharged: amountChargedOverride,
              improperPaymentCost: ipcOverride,
              isIPCOverride: !!ipcOverride,
            },
          };
        })
        .sort(
          (a: FindingAdjustments, b: FindingAdjustments) =>
            (a.ibLineNumber ?? 0) - (b.ibLineNumber ?? 0),
        );

export interface FindingAdjustmentsFromCsvLinesArgs {
  ibLines: ItemizedBillLine[];
  improperPaymentUnitsCharged: number;
  paymentRate: number;
  amountCharged: number;
  unitsBilled: number;
}

type FindingAdjustmentsFromCsvLinesFn = (
  args: FindingAdjustmentsFromCsvLinesArgs,
) => FindingAdjustments[] | null;

export const findingAdjustmentsFromCsvLines: FindingAdjustmentsFromCsvLinesFn =
  ({
    amountCharged,
    unitsBilled,
    improperPaymentUnitsCharged,
    paymentRate,
  }) => {
    const discrepantAmount = calculateDiscrepantAmount({
      improperPaymentUnitsCharged,
      amountCharged,
      unitsBilled,
    });
    const improperPaymentCost = calculateAdjustmentAmount(
      discrepantAmount ?? 0,
      paymentRate,
    );
    return [
      {
        ibLineId: undefined,
        ibLineNumber: 0,
        unitsBilled,
        amountCharged,
        discrepantAmount: discrepantAmount ?? 0,
        improperPaymentCost: improperPaymentCost ?? 0,
        improperPaymentUnitsCharged,
        overrides: {},
      },
    ];
  };

export const areIbLinesEquivalent = (ibLines: ItemizedBillLine[]) => {
  if (!ibLines || ibLines.length < 2) {
    return true;
  }

  const fields = [
    "itemNumber",
    "description",
    "procedureCode",
    "procedureCodeModifier",
    "units",
    "totalCharge",
  ];

  const firstIbLine: any = ibLines[0];
  return ibLines.every((ibLine) => {
    return fields.every((field) => {
      if (
        ibLine[field as keyof ItemizedBillLine] ===
        firstIbLine[field as keyof ItemizedBillLine]
      ) {
        return true;
      }
      return false;
    });
  });
};
