/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { useContext } from "react";
import { useQuery } from "urql";

import { UserContext } from "../../../context/user";
import { claimQueryDoc, providerClaimQueryDoc } from "./claim.gql";

export const useClaim = (id: string) => {
  const { userType }: any = useContext(UserContext);
  const useQueryResult = useQuery({
    query: userType === "PROVIDER" ? providerClaimQueryDoc : claimQueryDoc,
    variables: {
      id,
    },
    // requestPolicy: 'network-only',
  });

  return useQueryResult;
};
