"use client";

import { useCallback, useEffect, useState } from "react";
import type { PropsWithChildren } from "react";

import { useDebounceCallback } from "@alaffia-technology-solutions/hooks";
import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import type { UseThumbnailImage } from "./use-pdf-thumbnails";

interface ThumbnailBoxProps {
  isHighlighted: boolean;
  onClick: () => void;
  pageNumber: number;
}

const ThumbnailBox = ({
  children,
  isHighlighted,
  onClick,
  pageNumber,
}: PropsWithChildren<ThumbnailBoxProps>) => (
  <button
    onClick={onClick}
    className={cn(
      "af-relative af-h-[160px] af-w-[120px] af-cursor-pointer af-border af-bg-none",
      isHighlighted ? " af-border-blue-500" : "af-border-gray-500",
    )}
    type="button"
  >
    {children}
    <span
      className={cn(
        "af-absolute af-bottom-1 af-right-1 af-rounded af-bg-black af-bg-opacity-50 af-px-1 af-text-xs af-text-white",
      )}
    >
      {pageNumber}
    </span>
  </button>
);

interface ThumbnailProps extends ThumbnailBoxProps {
  useThumbnailImage: UseThumbnailImage;
  virtualItemIndex: number;
}

export const Thumbnail = ({
  useThumbnailImage,
  virtualItemIndex,
  ...props
}: ThumbnailProps) => {
  const [enabled, setEnabled] = useState(false);
  const { data, error } = useThumbnailImage(virtualItemIndex, {
    enabled,
  });

  const enableThumbnailFetch = useCallback(() => setEnabled(true), []);
  const loadThumbnail = useDebounceCallback(enableThumbnailFetch, 300);

  useEffect(() => {
    void loadThumbnail();
  }, [loadThumbnail]);

  const Children = () => {
    if (error) {
      return <span>Could not load the thumbnail</span>;
    }

    if (!data) {
      return null;
    }

    return (
      <img
        alt={`Page ${props.pageNumber}`}
        src={data.src}
        className="af-mx-auto af-h-auto af-max-h-full af-max-w-full"
      />
    );
  };

  return (
    <ThumbnailBox {...props}>
      <Children />
    </ThumbnailBox>
  );
};
