/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// external
import { useContext } from "react";
// component library
import { Col, Row } from "antd";

// internal
// user context
import { UserContext } from "../context/user";
// dashboard components
import { Queues } from "../queues/queues";
import { AuditorClientQueues } from "./defaultQueues/auditorClientQueues";
import { FacilityQueues } from "./defaultQueues/facilityQueues";
import { ProviderFacilityQueues } from "./defaultQueues/providerFacilityQueues";
import { StatsPanel } from "./stats/statsPanel";

const availableComponents = {
  statsPanel: (
    <StatsPanel
      key="stats-panel-dashboard-component"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="stats-panel-dashboard-component"
    />
  ),
  facilityQueues: (
    <FacilityQueues
      key="facility-queues-dashboard-component"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="facility-queues-dashboard-component"
    />
  ),
  providerFacilityQueues: (
    <ProviderFacilityQueues
      key="provider-facility-queues-dashboard-component"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="provider-facility-queues-dashboard-component"
    />
  ),
  auditorClientQueues: (
    <AuditorClientQueues
      key="auditor-client-queues-dashboard-component"
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="auditor-client-queues-dashboard-component"
    />
  ),
  queues: (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Queues key="queues-dashboard-component" id="queues-dashboard-component" />
  ),
};

const Dashboard = () => {
  const {
    dashboard: { components },
  } = useContext(UserContext);

  // NOTE: Ordering of dashboard components comes from user context components array
  return (
    <div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
          {/* @ts-expect-error */}
          {components.map((c: any) => availableComponents[c])}
        </Col>
      </Row>
    </div>
  );
};

export { Dashboard };
