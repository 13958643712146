/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useEffect, useMemo } from "react";
import { isFunction, set } from "lodash";
import type { FieldData } from "rc-field-form/lib/interface";
import type { Observable } from "rxjs";

import type {
  FieldChangeHandler,
  FormAdapter,
  FormSetFieldFn,
  FormSetFieldsFn,
  ValueChangeHandler,
  ValueChangeHandlerArgs,
} from "../viewController/types";

export interface FormChangeHandler<T> {
  onValuesChange: ValueChangeHandler<T>;
  onFieldsChange?: FieldChangeHandler;
  onControllerEvent?: (evt: any) => void;
}

interface useFormOnChangeProps<T> {
  currentHandler?: Partial<FormChangeHandler<T>>;
  nextHandler?: FormChangeHandler<T> & {
    events?: Observable<any> | null | undefined;
  };
  formAdapter: FormAdapter<T>;
}

const getInvalidChainMsg = (fnName: string) =>
  `useFormOnChange: 'current' handler '${fnName}' must return both '{changedValues, allValues}', or return null to skip 'next' handler '${fnName}'`;

const createValuesChangeFunction = <T>(
  currentHandler?: Partial<FormChangeHandler<T>>,
  nextHandler?: FormChangeHandler<T>,
): ValueChangeHandler<T> => {
  return (vals: ValueChangeHandlerArgs<T>) => {
    if (isFunction(currentHandler?.onValuesChange)) {
      const currentResult = currentHandler.onValuesChange(vals);
      if (!!currentResult && isFunction(nextHandler?.onValuesChange)) {
        if (!!currentResult.changedValues && !!currentResult.allValues) {
          return nextHandler.onValuesChange(currentResult);
        } else {
          throw new Error(getInvalidChainMsg("onValuesChange"));
        }
      } else {
        return currentResult;
      }
    } else {
      if (isFunction(nextHandler?.onValuesChange)) {
        return nextHandler.onValuesChange(vals);
      }
    }
  };
};

const createFieldsChangeFunction = <T>(
  currentHandler?: Partial<FormChangeHandler<T>>,
  nextHandler?: FormChangeHandler<T>,
): FieldChangeHandler => {
  return (fields: FieldData[]) => {
    if (isFunction(currentHandler?.onFieldsChange)) {
      const currentResult = currentHandler.onFieldsChange(fields);
      if (!!currentResult && isFunction(nextHandler?.onFieldsChange)) {
        return nextHandler.onFieldsChange(currentResult);
      } else {
        return currentResult;
      }
    } else {
      if (isFunction(nextHandler?.onFieldsChange)) {
        return nextHandler.onFieldsChange(fields);
      }
    }
  };
};

export interface UseFormChangesResult<T extends object> {
  setField: FormSetFieldFn<T>;
  setFields: FormSetFieldsFn<T>;
}

export const useFormChanges = <T extends object>({
  currentHandler,
  nextHandler,
  formAdapter,
}: useFormOnChangeProps<T>): UseFormChangesResult<T> => {
  const deps = [currentHandler, nextHandler, formAdapter];

  const onValuesChange: ValueChangeHandler<T> = useCallback<
    ValueChangeHandler<T>
  >(createValuesChangeFunction(currentHandler, nextHandler), deps);

  const onFieldsChange: FieldChangeHandler = useCallback<FieldChangeHandler>(
    createFieldsChangeFunction(currentHandler, nextHandler),
    deps,
  );

  useEffect(() => {
    formAdapter.setValueChangeHandler(onValuesChange);
    formAdapter.setFieldChangeHandler(onFieldsChange);
    if (!!nextHandler?.events && currentHandler?.onControllerEvent) {
      nextHandler.events.subscribe(currentHandler.onControllerEvent);
    }
  }, deps);

  const result = useMemo<UseFormChangesResult<T>>(() => {
    const res: UseFormChangesResult<T> = {
      setField: (path, value, simulateOnChange = false) => {
        const update: Partial<T> = set({}, path, value);
        formAdapter.setValues(update);
        const allValues: Partial<T> = formAdapter.getAllValues();
        if (simulateOnChange) {
          onValuesChange({ changedValues: update, allValues });
        }
      },
      setFields: (changedValues, simulateOnChange = false) => {
        formAdapter.setValues(changedValues);
        const allValues: Partial<T> = formAdapter.getAllValues();
        if (simulateOnChange) {
          onValuesChange({ changedValues, allValues });
        }
      },
    };
    return res;
  }, deps);

  return result;
};
