/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useLayoutEffect, useMemo, useState } from "react";
import { css } from "@emotion/react";
import { Button, Card, Col, Collapse, Empty, Row, Spin, Table } from "antd";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";

import { useLog } from "../../common/log/useLog";
import { filterMapByValue } from "../../util/collectionUtils";
import { useUserPreference } from "../../util/preferences";
import { AuditProgress } from "../audit/auditProgress/auditProgress";
import { BatchClaimOverviewDrawer } from "../audit/batchClaimOverview/batchClaimOverviewDrawer";
import { ReportGenerationModal } from "../claimActionMenu/actionTypes/reports/report";
import { ClaimActionMenu } from "../claimActionMenu/claimActionMenu";
import { UserContext } from "../context/user";
import TableColumnSelectorCollapsible from "../misc/tableColumnSelectorCollapsible";
import TitleWithDescription from "../misc/titleWithDescription";
import { generateClaimListColumnList } from "./claimListTableColumns";
import { flattenOrderByArgsObj, generateSortEnumOnSort } from "./util";

const clsFlex = css({ flex: "initial", paddingLeft: "1%" });
const clsFlexRight = css({ marginLeft: "auto" });
const clsFlexContainer = css({ display: "flex" });

const ClaimListTable = ({
  tableName,
  tableDescription,
  buttons,
  batchClaimFilter,
}: any) => {
  const log = useLog("ClaimListTable");
  const history = useHistory();
  const { Panel } = Collapse;
  const {
    permissions,
    permissions: { baseQueryType, userDefaultFilter },
    userType,
    claimList: { query: claimListQuery, columns: allowedClaimListColumns },
  } = React.useContext(UserContext);

  const [reportModalVisible, setReportModalVisible] = useState(false);

  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [orderByArgs, setOrderByArgs] = useState<any>(
    [], // TYPE: [ {columnKey: <str>, sortEnums: [sortEnum:<GQL orderByArgs>] }, ...]
  );

  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });
  const [, setViewDocumentNamesModalVisible] = React.useState(false);

  const [clickedOnBatchClaim, setClickedOnBatchClaim] = useState<any>(null);
  const [showClaimDetails, setShowClaimDetails] = useState(false);
  const [selectedBatchClaims, setSelectedBatchClaims] = useState<any>(null);

  // state for the selected columns - filter columns by 'default' from user permissions
  // to determine whether this column should show when the page loads.
  const [customColumnSelection, _setCustomColumnSelection] = useState(
    new Set(
      filterMapByValue(
        allowedClaimListColumns,
        (col: any) => col.default,
      ).keys(),
    ),
  );
  const setCustomColumnSelection = (cols: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    setsavedUserColPrefs(cols);
    _setCustomColumnSelection(new Set(cols));
  };
  const disabledColumns = useMemo(
    () =>
      Array.from(
        filterMapByValue(
          allowedClaimListColumns,
          (col: { disabled: any }) => col.disabled,
        ).keys(),
      ),
    [allowedClaimListColumns],
  );

  // column selection persistence -- this can be componentized as we implement it in other places
  const [savedUserColPrefs, setsavedUserColPrefs, savedUserColPrefsErrors] =
    useUserPreference(`colpref.claimsList.${userType}.${encodeURI(tableName)}`);
  useLayoutEffect(() => {
    if (savedUserColPrefs) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      _setCustomColumnSelection(new Set(savedUserColPrefs));
    }
  }, [savedUserColPrefs]);
  useEffect(() => {
    if (savedUserColPrefsErrors) {
      log.warn(
        "Failed to persist column selection in local storage",
        savedUserColPrefsErrors,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedUserColPrefsErrors]);

  const [isActionLoading, setIsActionLoading] = React.useState(false); // to remove

  const rowSelection = permissions.allowMultiSelect
    ? {
        type: "checkbox",
        onChange: (_: any, selectedRows: any) => {
          // We store references to the batchClaim instead of the actual batchClaim so that
          // the cache can update the batchClaim obj associated with id when actions occur
          setSelectedBatchClaims(
            selectedRows.map((selectedRow: any) => selectedRow.id),
          );
        },
      }
    : null;

  // combine the search filter with the default filters from the
  // from the user configuration
  const combinedFilter = {
    and: [
      ...batchClaimFilter.and, // all batchClaimFilters from search are placed in an and array by default
    ],
  };
  // placing the userDefaultFilter into the and array ensures no key collisions
  if (
    userDefaultFilter.batchClaimFilter &&
    Object.keys(userDefaultFilter.batchClaimFilter).length > 0
  )
    combinedFilter.and.push(userDefaultFilter.batchClaimFilter);

  log.debug("filter --> ", {
    batchClaimFilter: combinedFilter,
    ...paginationArgs,
    orderBy: flattenOrderByArgsObj(orderByArgs),
  });

  const [{ fetching, error, data: batchClaimsData }, claimsRefreshQuery] =
    useQuery({
      query: claimListQuery,
      variables: {
        //TODO: change class name
        batchClaimFilter: combinedFilter,
        ...paginationArgs,
        orderBy:
          Object.values(orderByArgs).length > 0
            ? flattenOrderByArgsObj(orderByArgs)
            : null,
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      paused: !batchClaimFilter,
      fetchPolicy: "network-only",
      nextFetchPolicy: "cache-and-network", // Used for subsequent executions
    });

  const columns = batchClaimsData
    ? generateClaimListColumnList({
        batchClaimsData,
        permissions,
        baseQueryType,
        allowedClaimListColumns,
        props: {
          isActionLoading,
          setIsActionLoading,
          setClickedOnBatchClaim,
          setShowClaimDetails,
          setViewDocumentNamesModalVisible,
        },
        customColumnSelection,
      })
    : [];

  if (error) {
    log.error(
      "Error processing claimListQuery with following error:",
      error,
      " --> with variables passed",
      combinedFilter,
    );
  }

  return (
    <div>
      {(fetching || batchClaimsData) && (
        <div>
          <Collapse defaultActiveKey={["auditProgressStatsSubMenu"]}>
            <Panel header="Progress Statistics" key="auditProgressStatsSubMenu">
              <Row>
                <Col span={4}></Col>
                <Col span={14}>
                  <Card size={"small"} bordered={false}>
                    <AuditProgress
                      // when loading ends it triggers a refresh
                      refreshTrigger={!isActionLoading}
                      // It's called 'combined' as its assumed that this filter hsa been combined with any userDefaultFilter defined
                      combinedBatchClaimFilter={combinedFilter}
                    />
                  </Card>
                </Col>
                <Col span={6}></Col>
              </Row>
            </Panel>
          </Collapse>
        </div>
      )}
      {batchClaimsData && !error ? (
        <div
          css={css`
            padding-right: 5px;
            overflow: auto;
          `}
          id="claim-list-table-antd-table-div"
          data-cy="claim-list-table-antd-table-div"
        >
          <Table
            id="claim-list-table-antd-table"
            data-cy="claim-list-table-antd-table"
            loading={fetching}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            rowSelection={rowSelection}
            title={() => (
              <div css={clsFlexContainer}>
                <TitleWithDescription
                  title={tableName}
                  description={tableDescription}
                ></TitleWithDescription>
                {permissions.allowMultiSelect && (
                  <div css={[clsFlex, clsFlexRight]}>
                    {
                      // Since We store references to the batchClaim instead of the actual batchClaim
                      // we must calculate which claims to pass using the ids stored in selectedBatchClaims
                      // This way the cache can update the batchClaim obj associated with id when actions occur
                      <ClaimActionMenu
                        setIsActionLoading={setIsActionLoading}
                        batchClaims={
                          (selectedBatchClaims &&
                            batchClaimsData[
                              baseQueryType.batchClaims
                            ].nodes.filter(({ id }: any) =>
                              selectedBatchClaims.includes(id),
                            )) ??
                          []
                        }
                        isVerbose={true}
                      />
                    }
                  </div>
                )}
                {buttons?.map(
                  (
                    button:
                      | string
                      | number
                      | boolean
                      | React.ReactElement<
                          any,
                          string | React.JSXElementConstructor<any>
                        >
                      | Iterable<React.ReactNode>
                      | React.ReactPortal
                      | null
                      | undefined,
                    i: React.Key | null | undefined,
                  ) => (
                    <div key={i} css={[clsFlex, clsFlexRight]}>
                      {button}
                    </div>
                  ),
                )}
                <div css={[clsFlex, clsFlexRight]}>
                  {permissions.claimActions.report.includes("createReport") && (
                    <span>
                      <Button
                        data-cy="claim-list-report-all-button"
                        onClick={() => setReportModalVisible(true)}
                      >
                        Report All
                      </Button>
                    </span>
                  )}
                </div>
                <div css={[clsFlex, clsFlexRight]}>
                  <TableColumnSelectorCollapsible
                    columns={columns}
                    selectedColumns={Array.from(customColumnSelection.keys())}
                    disabledColumns={disabledColumns}
                    setSelectedColumns={setCustomColumnSelection}
                  />
                </div>
              </div>
            )}
            onRow={(batchClaim) => {
              return {
                onDoubleClick: () => {
                  history.push(`/workspace/${batchClaim.id}`);
                },
              };
            }}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            columns={columns.filter((c) => customColumnSelection.has(c.key))}
            dataSource={batchClaimsData[baseQueryType.batchClaims].nodes.map(
              (node: { key: string }, i: any) => {
                node.key = `claim-list-table-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
                return node;
              },
            )}
            pagination={{
              ...paginationOptions,
              current: currentPageNumber,
              // only bottom left because when the table width
              // begins to exceed the length of page the top right bar looks off
              position: ["bottomLeft"],
              total: batchClaimsData?.[baseQueryType.batchClaims].totalCount,
              pageSizeOptions:
                userType === "ADMINISTRATOR"
                  ? ["10", "20", "30", "40", "50", "100", "150", "200"]
                  : ["10", "20", "30", "40", "50"],
              showSizeChanger: true,
              showTotal: (total) => <div>{total} Total Claims</div>,
              onChange: (nextPage, pageSize) => {
                setPaginationOptions({ pageSize });
                setPaginationArgs({
                  first: pageSize,
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  offset:
                    nextPage > 1
                      ? (nextPage - 1) * paginationOptions.pageSize
                      : null,
                });
                setCurrentPageNumber(nextPage);
                claimsRefreshQuery();
              },
            }}
            onChange={(_pagination, _filters, sorterObj, _extra) => {
              // On every new change to sorter we are either removing a sort, changing a sort, or adding a new one
              // We need to reflect this addition, removal, or change in the orderBy state while MAINTAINING order of the enums as they were clicked
              if (sorterObj && Object.keys(sorterObj).length > 0) {
                log.log("ON SORT CHANGE:", sorterObj);
                const newOrderByArgs = generateSortEnumOnSort(
                  Array.isArray(sorterObj) ? sorterObj : [sorterObj],
                  orderByArgs,
                );
                setOrderByArgs(newOrderByArgs);
                claimsRefreshQuery();
              }
            }}
          />
          {/* renders the drawer */}
          <BatchClaimOverviewDrawer
            batchClaimId={clickedOnBatchClaim ? clickedOnBatchClaim.id : null}
            onClose={() => setShowClaimDetails(false)}
            isVisible={showClaimDetails && clickedOnBatchClaim}
            setClaimActionMenuIsActionLoading={setIsActionLoading}
            isActionLoading={isActionLoading}
          />

          {reportModalVisible && (
            <ReportGenerationModal
              {...{
                batchClaimFilter,
                reportModalVisible,
                setReportModalVisible,
              }}
            />
          )}
        </div>
      ) : (
        <div css={{ textAlign: "center", marginTop: "5%" }}>
          {error && !fetching ? (
            <Empty
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              size="large"
              description={
                <span>
                  There was an error processing this search. Please review
                  search criteria and values passed - If error persists contact{" "}
                  <a href="mailto:support@alaffiahealth.com" target="_top">
                    support@alaffiahealth.com
                  </a>
                </span>
              }
            />
          ) : (
            <Spin size="large" />
          )}
        </div>
      )}
    </div>
  );
};

export { ClaimListTable };
