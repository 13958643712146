/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useRef } from "react";

import { UserContext } from "../../../context/user";
import { DrgContent } from "./drgContent";
import type { DrgDataType } from "./drgForm";
import type { DrgHeaderData } from "./drgHeader";
import { DrgHeader } from "./drgHeader";

const originalData: DrgDataType = {
  drgDxs: [
    {
      code: "A419",
      description: "SEPSIS, UNSPECIFIED ORGANISM",
      presentOnAdmission: "N",
    },
    {
      code: "U071",
      description: "COVID-19",
      presentOnAdmission: "Y",
    },
    {
      code: "J80",
      description: "ACUTE RESPIRATORY DISTRESS SYNDROM",
      presentOnAdmission: "Y",
    },
  ],

  procedures: [
    {
      code: "5A1955Z",
      description: "RESPIRATORY VENTILATION, GREATER THAN 96 CONSECUTIVE HOURS",
    },
  ],
  overallDrg: {
    code: "870",
    description: "SEPTICEMIA OR SEVERE SEPSIS WITH MV >96 HOURS",
  },

  notes: {},
};

export const DrgReviewDataDefault: DrgDataType = {
  drgDxs: [
    {
      code: "U071",
      description: "COVID-19",
      presentOnAdmission: "Y",
    },
    {
      code: "A419",
      description: "SEPSIS, UNSPECIFIED ORGANISM",
      presentOnAdmission: "N",
    },
    {
      code: "J80",
      description: "ACUTE RESPIRATORY DISTRESS SYNDROM",
      presentOnAdmission: "Y",
    },
  ],

  procedures: [
    {
      code: "5A1955Z",
      description: "RESPIRATORY VENTILATION, GREATER THAN 96 CONSECUTIVE HOURS",
    },
  ],
  overallDrg: {
    code: "207",
    description:
      "RESPIRATORY SYSTEM DIAGNOSIS WITH VENTILATOR SUPPORT >96 HOURS",
  },

  notes: {
    // drgPrimaryNotes:
    //   'this is a better primary dx since there is a ton of justification for doing it this way see all this documentation',
  },
};

export interface DrgTabProps {
  batchClaim: any;
  renderViewDocumentsButton: () => React.ReactElement;
  drgReviewData: DrgDataType;
  setDrgReviewData: (drgData: DrgDataType) => void;
}
export const DrgTab: React.FC<DrgTabProps> = ({
  batchClaim,
  renderViewDocumentsButton,
  drgReviewData: reviewData,
  setDrgReviewData: setReviewData,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  // const {
  //   claimType,
  //   dateOfServiceStart,
  //   dateOfServiceEnd,
  //   provider,
  //   amountCharged,
  //   amountReimbursed,
  // } = batchClaim;

  const headerData: DrgHeaderData = {
    claimType: "Inpatient",
    dateOfServiceStart: "2021-01-18",
    dateOfServiceEnd: "2021-03-05",
    providerName: "Alaffia Hospital",
    providerLocation: "NY, NY",
    amountCharged: 211_603.93,
    amountReimbursed: 90_850.16,
    patientAge: 54,
    patientGender: "Male",
    adjustedAllowedAmount: 80_974.41,
    savings: 9875.75,
  };

  // const dataSrc = batchClaim.batchClaimLines.nodes[0];
  // const [reviewData, setReviewData] = useState<DrgDataType>(DrgReviewDataDefault);

  const saveReviewData = useCallback(
    (dataToSave: DrgDataType) => {
      setReviewData({ ...dataToSave });
      return true;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reviewData],
  );
  const user: any = React.useContext(UserContext);

  const { auditDocumentation: files } = batchClaim || {
    auditDocumentation: [],
  };

  const renderDocs = () => {
    // claim has files
    return (files.length > 0 &&
      // user can view docs or doc names
      user.permissions.viewDocs) ||
      user.permissions.viewDocList ? (
      renderViewDocumentsButton()
    ) : (
      <></>
    );
  };

  return (
    <div
      ref={ref}
      tw="af-pr-2 af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer af-overflow-auto af-h-full"
    >
      <DrgHeader
        headerData={headerData}
        renderViewDocumentsButton={renderDocs}
      ></DrgHeader>
      <DrgContent
        drgData={reviewData}
        drgPriorVersionData={originalData}
        saveReviewData={saveReviewData}
      ></DrgContent>
    </div>
  );
};
