"use client";

import type { HTMLAttributes } from "react";
import { forwardRef, memo, useImperativeHandle, useRef } from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import { Loader } from "@alaffia-technology-solutions/ui";

import { usePDFStore } from "../../providers";
import { usePDFViewerEvent } from "../../use-pdf-viewer-event";
import { PDFErrorMessage } from "./components/pdf-error-message";
import { useInitializePDFCtrl } from "./hooks/useInitializePDFCtrl";

type PDFViewerCanvasProps = HTMLAttributes<HTMLDivElement>;

export const PDFViewerCanvas = memo(
  forwardRef<HTMLDivElement, PDFViewerCanvasProps>(({ className }, ref) => {
    const elementRef = useRef<HTMLDivElement | null>(null);
    const { pdfViewerCtrl, initialize } = useInitializePDFCtrl();
    const { data: error } = usePDFStore((state) => state.error);

    useImperativeHandle(
      ref,
      () => {
        if (!elementRef.current) {
          throw new Error("elementRef is not set");
        }

        return elementRef.current;
      },
      [elementRef],
    );

    const pdfViewer = pdfViewerCtrl?.pdfViewer ?? null;

    usePDFViewerEvent(
      pdfViewerCtrl?.events?.pageNumberChange,
      (pageNumber: number) => {
        pdfViewerCtrl?.setCurrentPageIndex(pageNumber - 1);
      },
    );

    const showLoader =
      !pdfViewer || pdfViewerCtrl?.pdfViewerState === "pending";

    return (
      <>
        {error ? (
          <PDFErrorMessage error={error} />
        ) : (
          <div
            className={cn(
              "af-flex af-h-full af-w-full af-min-w-0 af-overflow-auto",
              {
                "af-items-center af-justify-center af-bg-primary-100":
                  showLoader,
              },
              className,
            )}
          >
            {showLoader && <Loader className="af-h-16 af-w-16" />}
            <div ref={initialize} className={"af-relative"} />
          </div>
        )}
      </>
    );
  }),
);

PDFViewerCanvas.displayName = "PDFViewerCanvas";
