import * as React from "react";
import { IconClock } from "@tabler/icons-react";

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@alaffia-technology-solutions/ui";

type TooltipTriggerProps = React.ComponentPropsWithoutRef<
  typeof TooltipTrigger
>;

export type FileNotReadyTooltipProps = TooltipTriggerProps;

export const FileNotReadyTooltip = (props: FileNotReadyTooltipProps) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger aria-label="File not ready" {...props}>
        <div className="af-flex af-items-center af-gap-1 af-rounded-sm af-bg-warning-200 af-px-1 af-py-0.5 af-text-xs af-text-warning-700">
          <IconClock stroke={3} className="af-size-3" />
          Processing
        </div>
      </TooltipTrigger>
      <TooltipContent collisionPadding={4} className="[&_p]:af-mb-0">
        <p>This file is not ready to search yet.</p>
        <p>Please wait for the file processing to complete.</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);
