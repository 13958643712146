import { useState } from "react";

import { useAskAutodorSplit } from "../../askAutodor/useAskAutodorSplit";
import type { DrgDataType } from "./drgForm";
import { DrgForm } from "./drgForm";
import { DRG_MODE } from "./drgFormItem";

export interface DrgContentProps {
  drgData?: DrgDataType;
  drgPriorVersionData?: DrgDataType;
  saveReviewData: (dataToSave: DrgDataType) => boolean;
}

export const DrgContent: React.FC<DrgContentProps> = ({
  drgData,
  drgPriorVersionData,
  saveReviewData,
}) => {
  const [editable, setEditable] = useState(false);
  const { isOpen: isAskAutodorOpen } = useAskAutodorSplit();
  const isSplitScreen = isAskAutodorOpen ? false : true;

  return (
    <div tw="af-pr-0.5">
      <DrgForm
        isSplitScreen={isSplitScreen}
        mode={DRG_MODE.REVIEW}
        editable={editable}
        setEditable={setEditable}
        drgData={drgData}
        drgPriorVersionData={drgPriorVersionData}
        saveReviewData={saveReviewData}
      ></DrgForm>
    </div>
  );
};
