import type { PropsWithChildren } from "react";
import { useCallback, useReducer } from "react";

import { PDFConfigProvider } from "@alaffia-technology-solutions/pdf-viewer";
import { createSafeContext } from "@alaffia-technology-solutions/react-utils";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogOverlay,
  DialogPortal,
} from "@alaffia-technology-solutions/ui";

import { PDFViewerDialogContent } from "./PdfViewerDialogContent";
import type { UiAuditDocumentation } from "./types";

interface PdfViewerDialogContextValue {
  claimId: string | null;
  open: (payload: PdfViewerOpenedAction["payload"]) => void;
  close: () => void;
}

const [PdfViewerDialogContextProvider, usePdfViewerDialogContext] =
  createSafeContext<PdfViewerDialogContextValue>(
    "usePdfViewerDialogContext must be used within PdfViewerProvider",
  );

interface PdfViewerProviderProps extends PropsWithChildren {
  baseUrl: string;
  foxitLicenseKey: string;
  foxitLicenseSN: string;
}

export const PdfViewerProvider = ({
  baseUrl,
  foxitLicenseKey,
  foxitLicenseSN,
  children,
}: PdfViewerProviderProps) => {
  const [{ status, claimId, selectedFile, initialPage }, dispatch] = useReducer(
    pdfViewerStateReducer,
    {
      status: "closed",
      claimId: null,
      selectedFile: null,
      initialPage: null,
    },
  );

  const open = useCallback(
    (payload: PdfViewerOpenedAction["payload"]) =>
      dispatch({ type: "opened", payload }),
    [dispatch],
  );
  const close = useCallback(() => dispatch({ type: "closed" }), [dispatch]);
  const selectFile = useCallback(
    (file: UiAuditDocumentation) =>
      dispatch({ type: "file-selected", payload: { selectedFile: file } }),
    [dispatch],
  );

  return (
    <PDFConfigProvider
      jrConfig={{
        enginePath: "./jr-engine/gsdk",
        fontPath: `${baseUrl}/public/foxit/external/brotli`,
        fontInfoPath: `${baseUrl}/public/foxit/external/brotli/fontInfo.csv`,
        licenseKey: foxitLicenseKey,
        licenseSN: foxitLicenseSN,
        workerPath: `${baseUrl}/public/foxit/lib/`,
      }}
      libPath="/public/foxit/lib"
    >
      <PdfViewerDialogContextProvider value={{ claimId, open, close }}>
        {children}
      </PdfViewerDialogContextProvider>
      <Dialog open={status === "open"} onOpenChange={close} modal={false}>
        <DialogPortal>
          <DialogOverlay />
          {status === "open" && (
            <DialogContent
              className="af-h-[calc(100vh-2rem)] af-max-w-[calc(100vw-2rem)] af-p-0"
              onInteractOutside={(e) => {
                e.preventDefault();
              }}
            >
              <PDFViewerDialogContent
                claimId={claimId}
                selectedFile={selectedFile}
                onFileSelected={selectFile}
                initialPage={initialPage}
              />
              <DialogClose />
            </DialogContent>
          )}
        </DialogPortal>
      </Dialog>
    </PDFConfigProvider>
  );
};

interface PdfViewerOpenState {
  status: "open";
  claimId: string;
  selectedFile: UiAuditDocumentation | null;
  initialPage?: number;
}

interface PdfViewerClosedState {
  status: "closed";
  claimId: null;
  selectedFile: null;
  initialPage: null;
}

type PdfViewerState = PdfViewerOpenState | PdfViewerClosedState;

interface PdfViewerOpenedAction {
  type: "opened";
  payload: {
    claimId: string;
    initiallySelectedFile?: UiAuditDocumentation | null;
    initialPage?: number;
  };
}

interface PdfViewerFileSelectedAction {
  type: "file-selected";
  payload: {
    selectedFile: UiAuditDocumentation;
  };
}

interface PdfViewerClosedAction {
  type: "closed";
}

type PdfViewerStateAction =
  | PdfViewerOpenedAction
  | PdfViewerFileSelectedAction
  | PdfViewerClosedAction;

const pdfViewerStateReducer = (
  state: PdfViewerState,
  action: PdfViewerStateAction,
): PdfViewerState => {
  switch (action.type) {
    case "opened": {
      return {
        status: "open",
        claimId: action.payload.claimId,
        selectedFile: action.payload.initiallySelectedFile ?? null,
        initialPage: action.payload.initialPage,
      };
    }
    case "file-selected": {
      if (state.status !== "open") {
        throw new Error("Cannot change selected file when PDFViewer not open");
      }
      return {
        ...state,
        selectedFile: action.payload.selectedFile,
        initialPage: 1,
      };
    }
    case "closed": {
      return {
        status: "closed",
        claimId: null,
        selectedFile: null,
        initialPage: null,
      };
    }
  }
};

export const usePdfViewerDialog = () => {
  const { claimId, open, close } = usePdfViewerDialogContext();

  return { claimId, isOpen: Boolean(claimId), open, close };
};
