import { graphql } from "../gql";

export const getCaseQueryDoc = graphql(`
  query getCase($id: ID!) {
    case(id: $id) {
      ...CaseFragment
      queue {
        ...AskAutodorQueueFragment
      }
    }
  }
`);
