import { useCallback, useEffect } from "react";

import {
  usePDF,
  usePDFViewerSync,
} from "@alaffia-technology-solutions/pdf-viewer";

interface UsePDFViewerProps {
  highlights: string[] | undefined;
  openDelay: number;
  page?: number;
  url: string;
  onSearch?: (query: string) => void | Promise<void>;
  searchQuery: string;
  fileId: string;
}

export const usePDFViewer = ({
  highlights,
  openDelay,
  page = 1,
  url,
  onSearch: extOnSearch,
  searchQuery,
  fileId,
}: UsePDFViewerProps) => {
  const {
    currentPage,
    state,
    pagesCount,
    readOnlyScale,
    error,
    jumpToPage,
    nextPage,
    zoom,
    prevPage,
    rotate,
    getPageThumbnail,
    highlightTextOnPage,
    removeHighlightsOnPage,
    bookmarks,
  } = usePDF();

  const isDisabled = !!(state.isServer || state.data === "initial" || error);

  usePDFViewerSync({
    url,
    fileId,
    pageIndex: page - 1,
    openDelay,
    highlights,
  });

  const onSearch = useCallback(
    async (query: string) => {
      await extOnSearch?.(query);
      await removeHighlightsOnPage(currentPage);
      await highlightTextOnPage(currentPage, [query]);
    },
    [currentPage, extOnSearch, highlightTextOnPage, removeHighlightsOnPage],
  );

  useEffect(() => {
    if (searchQuery) {
      onSearch(searchQuery).catch((error) => console.error(error));
    }
  }, [onSearch, searchQuery]);

  return {
    currentPage,
    isDisabled,
    getPageThumbnail,
    jumpToPage,
    nextPage,
    pagesCount,
    prevPage,
    readOnlyScale,
    rotate,
    zoom,
    highlightTextOnPage,
    removeHighlightsOnPage,
    onSearch,
    bookmarks,
  };
};
