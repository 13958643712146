/* eslint-disable @typescript-eslint/no-explicit-any */
import type { UUID } from "../../../../../types/ids";
import type {
  AuditFinding,
  AuditFindingSeedType,
  AutodorBillType,
  ItemizedBillLine,
  SeedType,
} from "../../../../gql/graphql";

export type UserType = "ADMINISTRATOR" | "AUDITOR" | "PAYER" | "PROVIDER";

interface BaseFindingProps {
  seedType: AuditFindingSeedType;
  ibData?: ItemizedBillLine[];
}

export interface CreateFindingOp extends BaseFindingProps {
  operation: "create";
}

export interface ReviewFindingOp extends BaseFindingProps {
  operation: "update";
  finding: Finding;
}

export interface ConvertAutodorFindingOp extends BaseFindingProps {
  operation: "convert";
  autodorFinding: AutodorFinding;
}

export type FindingEditorOp =
  | CreateFindingOp
  | ReviewFindingOp
  | ConvertAutodorFindingOp;

const isSet = (value: any) => value !== undefined && value !== null;

export const isCreateFindingOp = (
  op?: FindingEditorOp | null,
): op is ReviewFindingOp => isSet(op) && op?.operation === "create";

export const isReviewFindingOp = (
  op?: FindingEditorOp | null,
): op is ReviewFindingOp => isSet(op) && op?.operation === "update";

export const isConvertAutodorFindingOp = (
  op?: FindingEditorOp | null,
): op is ConvertAutodorFindingOp => isSet(op) && op?.operation === "convert";

export interface RevCodeBclInfo {
  id: UUID;
  revCode: string;
  revDescription: string;
  unitCharge: number;
}

// types that suck from codegen, handwritten here

// TODO this type is for the field... need to figure out what to do vs codegen types
export type AuditFindingRuleType = string | null | undefined;

export interface FindingItemizedBillData {
  alaRowId?: UUID;
  authorId?: UUID;
  batchClaimLineId?: UUID;
  createdAt?: Date;
  dateOfServiceEnd?: Date;
  dateOfServiceStart?: Date;
  description?: string;
  ibLineId?: UUID;
  ibLineVersionFingerprint?: UUID;
  id?: UUID;
  itemNumber?: string;
  lineNumber?: number;
  originalBilledAmount?: number;
  procedureCode?: string;
  procedureCodeModifier?: string;
  revCode?: string;
  s3InputSource?: string;
  stale?: boolean | null;
  unitCharge?: number;
  units?: number;
  updatedAt?: Date;
  versionFingerprint?: UUID;
}

export interface MUEAuditRuleRationale {
  __typename?: "MUEAuditRuleRationale" | null;
  enumeration: string;
  value: string;
}

export interface MUEAuditRule {
  __typename?: "MUEAuditRule" | null;
  code: string;
  description: string;
  editType: string;
  mai: string;
  num: number;
  rationale: MUEAuditRuleRationale;
}

export interface PTPAuditRule {
  __typename?: "PTPAuditRule" | null;
  colOneCode: string;
  colTwoCode: string;
  extendedDescription: string;
  rationale: string;
}

export interface NonBillableAuditRule {
  __typename?: "NonBillableAuditRule" | null;
  code: string;
  description: string;
}

export interface AuditRule {
  __typename?: "AuditRule" | null;
  _type: string;
  genericRule: Record<any, any>; // TODO IS THIS OR-NULL?
  mue?: MUEAuditRule | null;
  nonBillable?: NonBillableAuditRule | null;
  ptp?: PTPAuditRule | null;
}

export interface AutodorMetadata {
  __typename?: "AutodorMetadata" | null;
  auditType: string;
  billType: AutodorBillType;
  dateOfServiceStart: Date;
  lineNumber?: number | null;
  procedureCode: string;
  procedureCodeModifier?: string | null;
  quarter: string;
  rule: AuditRule;
  unitCharge: number;
  units: number;
  versionId: UUID;
  year: string;
}

export interface AutodorFinding {
  __typename?: "AutodorFinding" | null;
  auditType: string;
  autodorMetadata: AutodorMetadata;
  claimId: UUID;
  createdAt: string;
  denialCode: string;
  description: string;
  ibLineId?: UUID | null;
  ibLineVersionFingerprint?: UUID | null;
  id: UUID;
  improperCharge: number;
  improperUnits: number;
  s3Key?: string | null;
  seedType: SeedType;
  stale: boolean;
  ubLineId?: UUID | null;
}

export interface CreateIbLineValueInput {
  fingerprint: UUID;
  s3Key: string;
}

export interface CreateBatchClaimLineItemValuesInput {
  alaRowId?: UUID | null;
  dateOfServiceEnd?: Date;
  dateOfServiceStart: Date;
  description: string;
  ibLineId?: UUID | null;
  ibLineVersionFingerprint?: UUID | null;
  id?: UUID | null;
  itemNumber?: string | null;
  lineNumber?: number | null;
  nnIbLineValues?: CreateIbLineValueInput[] | null;
  originalBilledAmount?: number | null;
  procedureCode?: string | null;
  procedureCodeModifier?: string | null;
  revCode?: string | null;
  unitCharge: number;
  units: number;
}

export interface CreateAuditFindingValuesInput {
  auditFindingRuleType: string;
  autodorFindingId?: string;
  autodorFindingS3Key?: string;
  confidence: number; // string; //BigFloat;
  improperPaymentCost: number;
  improperPaymentReason: string;
  improperPaymentUnitsCharged: number;
  metaDataAocPrimaryCode?: string;
  metaDataEmCorrectLevel?: number;
  metaDataEmOriginalLevel?: number;
  metaDataNcciMueAjudicationValue?: number;
  metaDataNcciMueOhpMueValue?: number;
  metaDataNcciPtpCode?: string;
  metaDataNcciPtpModifier?: number;
  metaDataPricingCorrectPrice?: string; //BigFloat;
  metaDataUnitsCorrectUnits?: number;
}

export interface CreateUbClaimFindingsInput {
  auditFindingValues: CreateAuditFindingValuesInput;
  authorId: UUID;
  batchClaimIds: UUID[];
}

export interface CreateAuditFindingsPayload {
  auditFindings: AuditFinding[];
}

export interface CreateUbClaimLineFindingsInput {
  auditFindingValues: CreateAuditFindingValuesInput;
  authorId: UUID;
  batchClaimId: UUID;
  batchClaimLineIds: UUID[];
}

export interface CreateIbLineFindingsInput {
  authorId: UUID;
  batchClaimId: UUID;
  batchClaimLineId: UUID;
  values: CreateIbLineFindingsInputValue[];
}

export interface CreateIbLineFindingsInputValue {
  auditFindingValues: CreateAuditFindingValuesInput;
  batchClaimLineItemValues: CreateBatchClaimLineItemValuesInput;
}

export interface VersionAuditFindingsInput {
  auditFindingReviewValues: AuditFindingReviewValuesInput[];
  authorId: UUID;
}

export interface AuditFindingReviewValuesInput {
  auditFindingId: UUID;
  auditFindingValues: CreateAuditFindingValuesInput;
  batchClaimLineId?: UUID;
  batchClaimLineItemValues?: CreateBatchClaimLineItemValuesInput;
}

export interface FindingAuthor {
  firstName: string;
  id: UUID;
  lastName: string;
  userType: string;
}

export interface LatestReview {
  authorId: string;
  createdAt: Date;
  rationale?: string | null;
}

export interface Finding {
  __typename?: "Finding";
  accepted?: boolean;
  author: FindingAuthor;
  confidence?: string;
  createdAt?: Date;
  findingItemizedBillData?: FindingItemizedBillData | null;
  id: UUID;
  improperPaymentCost?: number;
  improperPaymentReason?: string;
  improperPaymentUnitsCharged?: number;
  isActive?: boolean;
  isOriginal?: boolean;
  latestReview?: LatestReview;
  metaDataAocPrimaryCode?: string | null;
  metaDataEmCode?: string | null;
  metaDataEmCorrectLevel?: number | null;
  metaDataEmOriginalLevel?: number | null;
  metaDataNcciMueAdjudicationValue?: number | null;
  metaDataNcciMueOhpMueValue?: number | null;
  metaDataNcciPtpCode?: string | null;
  metaDataNcciPtpModifier?: number | null;
  metaDataPricingCorrectPrice?: number | null;
  metaDataUnitsCorrectUnits?: number | null;
  needsReview: boolean;
  deleted?: boolean | null; // TODO how are we handling in new resolvers???
  ruleType: string;
  seedType: string;
  superseded?: boolean | null;
  updatedAt?: Date | null;
}
