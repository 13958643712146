import { graphql } from "../gql";

export const getThreadsQueryDoc = graphql(`
  query getThreads($input: ThreadsInput!) {
    threads(input: $input) {
      ...ThreadFragment
      messages {
        ...MessageFragment
        response {
          ...PromptResponseFragment
        }
        feedback {
          ...PromptFeedbackFragment
        }
      }
    }
  }
`);

export const getThreadsWithPromptResponseRatingsQueryDoc = graphql(`
  query getThreadsWithPromptResponseRatings($input: ThreadsInput!) {
    threads(input: $input) {
      ...ThreadFragment
      messages {
        ...MessageFragment
        response {
          ...PromptResponseWithRatingsFragment
        }
        feedback {
          ...PromptFeedbackFragment
        }
      }
    }
  }
`);
