import type { JwtPayload } from "jwt-decode";
import { jwtDecode } from "jwt-decode";
import lodash from "lodash";
import { toDataURL } from "qrcode";
import type { Observable } from "rxjs";
import { BehaviorSubject } from "rxjs";

import { AuthData } from "../auth/authData";
import type {
  AuthDataLoginFields,
  AuthDataStrategy,
  ConfigureMfaResult,
  CredentialsChangeSuccessResult,
  ForceChangePasswordResult,
  ForgotPasswordResult,
  LoginChallengeResult,
  LoginFailResult,
  LoginResult,
  LoginStatusEvent,
  LoginSuccessResult,
  LogoutEvent,
} from "../auth/types";
import type { AuthExchangeHandlerHelpers } from "../remote/authExchange";
import type {
  AuthenticationResult,
  AuthUserConfigureMfa,
  AuthUserConfirmForgotPassword,
  AuthUserCredentialsChangeSuccess,
  AuthUserForceChangePassword,
  AuthUserLoginChallenge,
  AuthUserLoginToken,
  ConfigureMfaInput,
  ConfirmForgotPasswordInput,
  ForceChangePasswordInput,
  ForgotPasswordInput,
  LoginChallengeParameters,
  LoginParameters,
} from "../remote/restTypes";
import { ClientError } from "./error";
import type { LoggerType } from "./log";
import { getLogger } from "./log";

export interface IAuthHandler extends AuthExchangeHandlerHelpers {
  login: (username: string, password: string) => Promise<LoginResult>;
  getUserId: () => string;
  getUsername: () => string;
  isLoggedIn: () => boolean;
  loginStatus: Observable<LoginStatusEvent>;
  configureMfa: (
    configureMfa: ConfigureMfaResult,
    code: string,
  ) => Promise<LoginResult>;
  forceChangePassword: (
    forceChangePassword: ForceChangePasswordResult,
    oldPassword: string,
    newPassword: string,
  ) => Promise<LoginResult>;
  loginChallenge: (
    challengeResponse: LoginChallengeResult,
    code: string,
  ) => Promise<LoginResult>;
  // TODO fetchAuthenticatedPost may be removed in future
  //  or moved to another interface
  fetchAuthenticatedPost: (
    resourcePath: string,
    bodyObject: Record<string, any>,
  ) => Promise<Response>;
  forgotPassword: (username: string) => Promise<LoginResult>;
  confirmForgotPassword: (
    userName: string,
    newPassword: string,
    code: string,
  ) => Promise<LoginResult>;
}

const LOGIN_INITIAL_ENDPOINT = "/login";
const LOGIN_CHALLENGE_ENDPOINT = "/loginChallenge";
const CONFIGURE_MFA_ENDPOINT = "/configureMfa";
const CHANGE_PASSWORD_ENDPOINT = "/forceChangePassword";
const FORGOT_PASSWORD_ENDPOINT = "/forgotPassword";
const CONFIRM_FORGOT_PASSWORD_ENDPOINT = "/confirmForgotPassword";

interface ErrorResponseJson {
  error: string;
  [key: string]: any;
}

type AuthenticationResultOrError =
  | AuthenticationResult
  | ErrorResponseJson
  | string
  | null;

export class AuthHandler implements IAuthHandler {
  private logger: LoggerType;
  private url: string;
  private authData: AuthDataStrategy;
  // tmpusr - needs a better pattern; some ops need to prohibit
  //   switching user, so we need a way to keep the username entered
  //   but only when it's confirmed to have logged in successfully
  private tmpusr: string | undefined;
  private loginStatusSubject = new BehaviorSubject<LoginStatusEvent>({
    loggedIn: false,
  });

  constructor(url: string, authData?: AuthDataStrategy) {
    this.logger = getLogger("AuthHandler");
    this.url = url;
    this.authData =
      authData ?? new AuthData({ url: this.url, allowLocalStorage: true });
    if (this.isLoggedIn()) {
      this.loginStatusSubject.next({
        loggedIn: true,
        data: this.createSuccessResponse(this.getLoginStatusFields()),
      });
    }
    if (this.authData.dataChangeObservable) {
      this.authData.dataChangeObservable.subscribe(
        this.onDataStrategyDetectedChange,
      );
    }
  }

  fetchAuthenticatedPost = async (
    resourcePath: string,
    bodyObject: Record<string, any>,
  ): Promise<Response> => {
    if (!this.isLoggedIn()) {
      throw this.notAuthErr("fetchAuthenticatedPost");
    }
    if (!resourcePath.startsWith("/")) {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject("resourcePath must start with /");
    }
    if (resourcePath.includes("://")) {
      // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
      return Promise.reject("resourcePath cannot contain protocol");
    }
    return fetch(this.url + resourcePath, {
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${this.getToken()?.token}`,
      },
      method: "POST",
      body: JSON.stringify(bodyObject),
    });
  };

  loginStatus: Observable<LoginStatusEvent> =
    this.loginStatusSubject.asObservable();

  login: IAuthHandler["login"] = async (username, password) => {
    //
    // initiate login:  (user, pwd) => token response || mfa challenge || error
    const res = await fetch(
      this.url + LOGIN_INITIAL_ENDPOINT,
      fetchInit<LoginParameters>({
        authParams: {
          username,
          password,
        },
      }),
    );

    this.tmpusr = username;

    return this.parseAndReturnLoginResult({ res, caller: "login" });
  };

  loginChallenge: IAuthHandler["loginChallenge"] = async (
    challengeResponse,
    code,
  ) => {
    if (!isLoginChallenge(challengeResponse)) {
      const msg = "Invalid challenge response";
      this.logger.error(msg, challengeResponse);
      throw new ClientError(msg, challengeResponse);
    }

    const {
      session,
      challengeKind,
      authParams: { username },
    } = challengeResponse;

    if (!session || !challengeKind || !username) {
      const msg = "Challenge response is missing required fields";
      this.logger.error(msg, challengeResponse);
      throw new ClientError(msg, challengeResponse);
    }

    const res = await fetch(
      this.url + LOGIN_CHALLENGE_ENDPOINT,
      fetchInit<LoginChallengeParameters>({
        session,
        challengeKind,
        authParams: {
          username,
          softwareTokenMfaCode: code,
        },
      }),
    );

    return this.parseAndReturnLoginResult({ res, caller: "loginChallenge" });
  };

  forceChangePassword: IAuthHandler["forceChangePassword"] = async (
    forceChangePasswordResult,
    oldPassword,
    newPassword,
  ) => {
    if (!isForceChangePassword(forceChangePasswordResult)) {
      const msg = "Invalid challenge response";
      this.logger.error(msg, forceChangePasswordResult);
      throw new ClientError(msg, forceChangePasswordResult);
    }

    const {
      session,
      idpType,
      authParams: { username },
    } = forceChangePasswordResult;

    if (!session || !username) {
      const msg = "Force change password response is missing required fields";
      this.logger.error(msg, forceChangePasswordResult);
      throw new ClientError(msg, forceChangePasswordResult);
    }

    const res = await fetch(
      this.url + CHANGE_PASSWORD_ENDPOINT,
      fetchInit<ForceChangePasswordInput>({
        session,
        authParams: {
          username,
        },
        ...(idpType !== "keycloak-dev-only" ? { oldPassword } : {}),
        newPassword,
      }),
    );

    return this.parseAndReturnLoginResult({ res, caller: "configureMfa" });
  };

  configureMfa: IAuthHandler["configureMfa"] = async (
    configureMfaResult,
    code,
  ) => {
    if (!isConfigureMfa(configureMfaResult)) {
      const msg = "Invalid challenge response";
      this.logger.error(msg, configureMfaResult);
      throw new ClientError(msg, configureMfaResult);
    }

    const {
      session,
      authParams: { username },
    } = configureMfaResult;

    if (!session || !username) {
      const msg = "Challenge response is missing required fields";
      this.logger.error(msg, configureMfaResult);
      throw new ClientError(msg, configureMfaResult);
    }

    const res = await fetch(
      this.url + CONFIGURE_MFA_ENDPOINT,
      fetchInit<ConfigureMfaInput>({
        session,
        totpSecret: configureMfaResult.totpSecret,
        authParams: {
          username,
          softwareTokenMfaCode: code,
        },
      }),
    );

    return this.parseAndReturnLoginResult({ res, caller: "configureMfa" });
  };

  forgotPassword: IAuthHandler["forgotPassword"] = async (username) => {
    //
    // forgotPassword:  (user) => ForgotPasswordResponse || error
    const res = await fetch(
      this.url + FORGOT_PASSWORD_ENDPOINT,
      fetchInit<ForgotPasswordInput>({
        username,
      }),
    );

    const body:
      | AuthUserConfirmForgotPassword
      | AuthUserCredentialsChangeSuccess = await this.getJson(res);

    if (body.__typename === "AuthUserConfirmForgotPassword") {
      // if we do use cognito to reset, we need the user to enter a code sent
      // by cognito -- this is not currently in use due to some cognito restrictions
      const result: ForgotPasswordResult = {
        type: "ForgotPasswordResult",
        username: body.username,
        codeDeliveryDetails: body.$metadata.codeDeliveryDetails,
        predicates,
      };
      return result;
    } else if (body.__typename === "AuthUserCredentialsChangeSuccess") {
      // if we use a reset to temp password, they don't need to enter a code,
      // the server will just email them a new temp password; the response field
      // `$metadata.codeDeliveryDetails.Destination` will contain the (masked)
      // email address where the temp password was sent, may be worth displaying:
      const result: CredentialsChangeSuccessResult = {
        type: "CredentialsChangeSuccessResult",
        authParams: body.authParams,
        deliveryDestination: body?.$metadata?.codeDeliveryDetails?.Destination,
        predicates,
      };
      return result;
    } else {
      throw new Error("Unexpected response type for operation forgotPassword");
    }
  };

  confirmForgotPassword = async (
    username: string,
    newPassword: string,
    code: string,
  ): Promise<LoginResult> => {
    const res = await fetch(
      this.url + CONFIRM_FORGOT_PASSWORD_ENDPOINT,
      fetchInit<ConfirmForgotPasswordInput>({
        newPassword,
        code,
        username,
      }),
    );

    return this.parseAndReturnLoginResult({
      res,
      caller: "confirmForgotPassword",
    });
  };

  isLoggedIn = () => !!this.authData.userId && !!this.authData.token;

  getUserId = () => {
    if (!this.isLoggedIn() || !this.authData.userId) {
      throw this.notAuthErr("getUserId");
    }
    return this.authData.userId;
  };

  getUsername = () => {
    if (!this.isLoggedIn() || !this.authData.username) {
      throw this.notAuthErr("getUsername");
    }
    return this.authData.username;
  };

  getToken = () => {
    if (!this.isLoggedIn() || !this.authData.token) {
      // throw this.notAuthErr('getToken');
      return undefined;
    }
    return { token: this.authData.token, expires: this.authData.expires };
  };

  // eslint-disable-next-line @typescript-eslint/require-await
  refreshToken = async () => {
    if (!this.isLoggedIn()) {
      throw this.notAuthErr("refreshToken");
    }
    // todo
    throw new Error("Not implemented");
  };

  logout = (event?: LogoutEvent) => {
    this.authData.clearLoginFields();
    this.loginStatusSubject.next({
      loggedIn: false,
      data: event ?? undefined,
    });
  };

  protected getTokenResponse = () => this.authData.raw;

  private parseAndReturnLoginResult = async ({
    res,
    caller,
  }: {
    res: Response;
    caller: string;
  }) => {
    const body: AuthenticationResult = await this.getJson(res);
    this.logger.trace(`${caller}: init login response`, { body, res });

    if (res.status >= 400 && res.status <= 403) {
      this.tmpusr = undefined;
      return this.createLoginFailResult(res.status, body);
    } else if (res.status < 200 || res.status >= 300) {
      this.tmpusr = undefined;
      return getErrorRejection(res, body);
    }

    if (isConfigureMfaResponse(body)) {
      return Object.freeze(await this.createConfigureMfaResponse(body));
    }

    if (isForceChangePasswordResponse(body)) {
      return Object.freeze(this.createForceChangePasswordResponse(body));
    }

    if (isChallengeResponse(body)) {
      // freeze the challenge response to prevent accidental mod of 'next' data
      return Object.freeze(this.createChallengeResponse(body));
    }

    if (isCredentialsChangeSuccessResponse(body)) {
      return Object.freeze(this.createCredentialsChangeSuccessResponse(body));
    }

    const loginFields = this.extractLoginFields(body);

    this.authData.setLoginFields(loginFields);

    const successResult = this.createSuccessResponse(loginFields);
    this.loginStatusSubject.next({ loggedIn: true, data: successResult });
    return successResult;
  };

  private createSuccessResponse = (
    loginFields: Pick<AuthDataLoginFields, "userId" | "userRole" | "expires">,
  ): LoginSuccessResult => {
    this.logger.debug("createSuccessResponse", { loginFields });
    const loginSuccessResult = {
      ...lodash.pick(loginFields, ["userId", "userRole", "expires"]),
      type: "LoginSuccessResult",
      predicates,
    };

    if (!isLoginSuccess(loginSuccessResult)) {
      throw new Error("Bad login response: required fields not found in token");
    }

    return loginSuccessResult;
  };

  onDataStrategyDetectedChange = () => {
    const statusResult: LoginSuccessResult | LogoutEvent = this.isLoggedIn()
      ? this.createSuccessResponse(this.getLoginStatusFields())
      : { type: "SessionExpiredEvent", trigger: "expiry", payload: {} };
    this.loginStatusSubject.next({
      loggedIn: this.isLoggedIn(),
      data: statusResult,
    });
  };

  private getTotpQrLink = (username: string, totpSecret: string) => {
    const thisUrl = "integration.fake.local";
    // todo consider moving otpauth url generation to server
    return `otpauth://totp/${thisUrl}:${encodeURI(username)}?secret=${encodeURI(
      totpSecret.replaceAll(" ", ""),
    )}&issuer=alaffia`;
  };

  private createConfigureMfaResponse = async (
    challengePayload: AuthUserConfigureMfa,
  ): Promise<ConfigureMfaResult> => {
    let totpQrCode = challengePayload?.totpQrCode;

    if (!totpQrCode && !!challengePayload?.totpSecret) {
      try {
        totpQrCode = await toDataURL(
          this.getTotpQrLink(
            challengePayload.authParams.username,
            challengePayload.totpSecret,
          ),
          {
            errorCorrectionLevel: "H",
          },
        );
      } catch (e: any) {
        this.logger.error("Failed to generate QR code", { error: e });
      }
    }

    return {
      type: "ConfigureMfaResult",
      session: challengePayload.session,
      totpSecret: challengePayload.totpSecret,
      totpQrCode, // either sent by IDP or built above
      authParams: challengePayload.authParams,
      predicates,
    };
  };

  private createForceChangePasswordResponse = (
    challengePayload: AuthUserForceChangePassword,
  ): ForceChangePasswordResult => {
    return {
      type: "ForceChangePasswordResult",
      session: challengePayload.session,
      idpType: challengePayload.idpType,
      authParams: challengePayload.authParams,
      predicates,
    };
  };

  private createChallengeResponse = (
    challengePayload: AuthUserLoginChallenge,
  ): LoginChallengeResult => {
    return {
      type: "LoginChallengeResult",
      challengeKind: challengePayload.challengeKind,
      session: challengePayload.session,
      authParams: challengePayload.authParams,
      predicates,
    };
  };

  private createCredentialsChangeSuccessResponse = (
    credentialsChangeSuccessResponse: AuthUserCredentialsChangeSuccess,
  ): CredentialsChangeSuccessResult => {
    return {
      type: "CredentialsChangeSuccessResult",
      authParams: credentialsChangeSuccessResponse.authParams,
      predicates,
    };
  };

  private createLoginFailResult = (
    httpStatus: number,
    responseBody: any,
  ): LoginFailResult => {
    return {
      type: "LoginFailResult",
      responseBody,
      httpStatus,
      predicates,
    };
  };

  private extractLoginFields = (
    tokenResponse: AuthenticationResultOrError,
  ): AuthDataLoginFields => {
    if (!isTokenResponse(tokenResponse)) {
      const msg = "Unexpected error, no token response";
      this.logger.error(msg, tokenResponse);
      throw new ClientError(msg, tokenResponse);
    }

    const { accessToken } = tokenResponse;
    if (!accessToken) {
      const msg = "Unexpected error, no access token returned";
      this.logger.error(msg, tokenResponse);
      throw new ClientError(msg, tokenResponse);
    }
    const jwt: JwtPayload & Record<string, never> = jwtDecode(accessToken);

    // todo: we could vet this more and potentially adjust (sync) expires to clients
    //  clock if based on iat... ~e.g. jwt.iat ? Date.now() - jwt.iat * 1000 : <???>,
    const expires = jwt.exp
      ? jwt.exp * 1000
      : Date.now() + (tokenResponse.expiresIn ?? 0) * 1000;

    if (!jwt["cognito:groups"]?.[1]) {
      this.logger.info("Using 'jwt.sub', no groups provided in jwt...");
    }

    const userId = jwt["cognito:groups"]?.[1] ?? jwt.sub;
    const userRole = jwt["cognito:groups"]?.[0] ?? null;

    if (!userId) {
      const msg = "Unexpected error, no userId in token response";
      this.logger.error(msg, tokenResponse);
      throw new ClientError(msg, tokenResponse);
    }

    const username = this.tmpusr ?? null;
    this.tmpusr = undefined;

    return {
      url: this.url,
      userId,
      username,
      userRole,
      token: tokenResponse.accessToken ?? null,
      expires,
      refreshToken: tokenResponse.refreshToken ?? null,
      raw: tokenResponse,
    };
  };

  private getJson = async (res: Response) =>
    Promise.resolve()
      .then(() => res.json())
      .catch((error) => {
        this.logger.error("Failed to retrieve response body", { error, res });
        // eslint-disable-next-line @typescript-eslint/prefer-promise-reject-errors
        return Promise.reject(error);
      });

  private notAuthErr = (contextMessage: string) => {
    const msg = `${contextMessage}: must login first!`;
    this.logger.error(msg);
    return new ClientError(msg);
  };

  private getLoginStatusFields = () => {
    return {
      userId: this.authData.userId,
      userRole: this.authData.userRole,
      expires: this.authData.expires,
    };
  };
}

// exported convenience functions

export function isLoginSuccess(
  loginResult: unknown,
): loginResult is LoginSuccessResult {
  return (
    hasType(loginResult) &&
    loginResult.type === "LoginSuccessResult" &&
    hasProperty(loginResult, "userId") &&
    !!loginResult.userId &&
    hasProperty(loginResult, "expires") &&
    !!loginResult.expires
  );
}

export function isForceChangePassword(
  loginResult: unknown,
): loginResult is ForceChangePasswordResult {
  return (
    hasType(loginResult) && loginResult.type === "ForceChangePasswordResult"
  );
}

export function isForgotPassword(
  loginResult: unknown,
): loginResult is ForgotPasswordResult {
  return hasType(loginResult) && loginResult.type === "ForgotPasswordResult";
}

export function isConfigureMfa(
  loginResult: unknown,
): loginResult is ConfigureMfaResult {
  return (
    hasType(loginResult) &&
    loginResult.type === "ConfigureMfaResult" &&
    hasProperty(loginResult, "totpSecret") &&
    !!loginResult.totpSecret
  );
}

export function isLoginChallenge(
  loginResult: unknown,
): loginResult is LoginChallengeResult {
  return (
    hasType(loginResult) &&
    loginResult.type === "LoginChallengeResult" &&
    hasProperty(loginResult, "session") &&
    !!loginResult.session &&
    hasProperty(loginResult, "challengeKind") &&
    !!loginResult.challengeKind &&
    hasProperty(loginResult, "authParams") &&
    !!loginResult.authParams
  );
}

export function isLoginFail(
  loginResult: unknown,
): loginResult is LoginFailResult {
  return (
    hasType(loginResult) &&
    loginResult.type === "LoginFailResult" &&
    hasProperty(loginResult, "httpStatus") &&
    typeof loginResult.httpStatus === "number" &&
    loginResult.httpStatus >= 400 &&
    loginResult.httpStatus < 500
  );
}

export function isCredentialsChangeSuccess(
  loginResult: unknown,
): loginResult is CredentialsChangeSuccessResult {
  return (
    hasType(loginResult) &&
    loginResult.type === "CredentialsChangeSuccessResult"
  );
}

function hasType(value: unknown) {
  return hasProperty(value, "type");
}

const predicates = {
  isLoginSuccess,
  isConfigureMfa,
  isForceChangePassword,
  isForgotPassword,
  isLoginChallenge,
  isLoginFail,
  isCredentialsChangeSuccess,
};

// helpers

function isErrorResponseJsonBody(
  authResult: string | AuthenticationResultOrError,
): authResult is ErrorResponseJson {
  return hasProperty(authResult, "error");
}

function isConfigureMfaResponse(
  authResult: AuthenticationResultOrError,
): authResult is AuthUserConfigureMfa {
  return (
    hasTypename(authResult) &&
    authResult?.__typename === "AuthUserConfigureMfa" && // TODO  AuthUserConfigureMfa ??
    !!authResult?.session &&
    !!authResult?.totpSecret
  );
}

function isChallengeResponse(
  authResult: AuthenticationResultOrError,
): authResult is AuthUserLoginChallenge {
  return (
    hasTypename(authResult) &&
    authResult?.__typename === "AuthUserLoginChallenge" &&
    !!authResult?.session &&
    !!authResult?.challengeKind
  );
}

function isForceChangePasswordResponse(
  authResult: AuthenticationResultOrError,
): authResult is AuthUserForceChangePassword {
  return (
    hasTypename(authResult) &&
    authResult.__typename === "AuthUserForceChangePassword"
  );
}

function isTokenResponse(
  authResult: AuthenticationResultOrError,
): authResult is AuthUserLoginToken {
  return (
    hasTypename(authResult) && authResult.__typename === "AuthUserLoginToken"
  );
}

function isCredentialsChangeSuccessResponse(
  authResult: AuthenticationResultOrError,
): authResult is AuthUserCredentialsChangeSuccess {
  return (
    hasTypename(authResult) &&
    authResult.__typename === "AuthUserCredentialsChangeSuccess"
  );
}

function hasTypename(value: unknown) {
  return hasProperty(value, "__typename");
}

function hasProperty<TProp extends string>(
  value: unknown,
  prop: TProp,
): value is Record<TProp, unknown> {
  return Boolean(value && typeof value === "object" && prop in value);
}

function getErrorRejection(
  res: Response,
  body: string | AuthenticationResultOrError,
) {
  const msg = isErrorResponseJsonBody(body)
    ? `Login Failed: [${res.status}]: ${body.error}`
    : `Login Failed: [${res.status}]: ${String(body)}`;
  return Promise.reject(
    new ClientError(msg, {
      response: res,
      body: body,
    }),
  );
}

function fetchInit<T>(bodyObj: T) {
  return {
    method: "POST",
    body: JSON.stringify(bodyObj),
    headers: {
      "Content-Type": "application/json",
    },
  };
}
