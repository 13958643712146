import { graphql } from "../../../../gql";

export const denialCodesQueryDoc = graphql(`
  query auditFindingRuleTypes {
    auditFindingRuleTypes {
      nodes {
        type
        claimDataType
        deprecated
        displayName
        description
        rationaleTemplate
      }
    }
  }
`);
