/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useMemo, useRef } from "react";
import { Alert } from "antd";
import AutoSizer from "react-virtualized-auto-sizer";

import { useHashParams } from "../../../../common/navigation/useHashParam";
import { fromSlugOrId } from "../../../../common/navigation/utils";
import { useWaitForRef } from "../../../../common/util/hooks/useWaitForRef";
import type { AuditFinding, BatchClaim, Claim } from "../../../../gql/graphql";
import { useClaim } from "../../queries/claim/useClaim";
import { FindingContextProvider } from "../findingEdit/findingProvider";
import type { IbTableFunctions } from "./ibTable/ibTable";
import { IbTable } from "./ibTable/ibTable";

interface IbTabProps {
  setCreatingAuditFinding: any;
  setDecliningAuditFinding: any;
  navigateToIbinRow: ({
    rowId,
    findingId,
  }: {
    rowId: string;
    findingId: string;
  }) => void;
  readOnly: boolean;
  variables: {
    batchClaimId: string;
    batchClaim: BatchClaim;
    auditFindings: AuditFinding[];
  };
  TableComponent: React.FC<any>;
  canCreateFindings: boolean;
  tabToolbarRef: any;
  showRow?: { rowId: string; findingId?: string };
}

export const IbTab = ({
  setCreatingAuditFinding,
  setDecliningAuditFinding,
  navigateToIbinRow,
  readOnly,
  variables: { batchClaimId, batchClaim },
  canCreateFindings,
  tabToolbarRef,
}: IbTabProps) => {
  const [{ fetching, data, error }] = useClaim(batchClaimId);

  const claim = useMemo(() => data?.claim, [data]);

  const tableRef = useRef<IbTableFunctions>(null);

  const [hashParams] = useHashParams();

  const isTableRefLoaded = useWaitForRef(tableRef);

  // if we're loading tab, update the table based on hash params
  // n.b. this could be done in the table itself, but the original
  // design exposed an api on the table which is likley to be a useful pattern going forward
  useEffect(
    () => {
      if (isTableRefLoaded && (hashParams.rowId ?? hashParams.findingId)) {
        if (tableRef.current!.isGridReady()) {
          tableRef.current!.scrollToRowId(
            fromSlugOrId(hashParams.rowId),
            fromSlugOrId(hashParams.findingId),
          );
        } else {
          const unsub = tableRef?.current?.onReady(() => {
            tableRef.current!.scrollToRowId(
              fromSlugOrId(hashParams.rowId),
              fromSlugOrId(hashParams.findingId),
            );
          });
          return unsub;
        }
      }
    },
    // only on table ready -- don't update on hash params since that will interfere with user scrolling
    // e.g. timing of hash changes can cause this to reissue scroll command and cause the row to jump:
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isTableRefLoaded],
  );

  if (error) {
    console.error("Error loading claim", error);
  }

  return error && !data ? (
    <div tw="af-m-8">
      <Alert
        message="Error retrieving claim data"
        showIcon
        description={
          <div tw="af-m-4">
            <div tw="af-p-2">
              An unexpected error occurred loading this claim. Please contact
              support at
              <a
                tw="af-p-2"
                href="mailto:support@alaffiahealth.com"
                target="_top"
              >
                support@alaffiahealth.com
              </a>
            </div>
            <div tw="af-p-2">Message: {error?.message}</div>
          </div>
        }
        type="error"
      />
    </div>
  ) : (
    <FindingContextProvider
      claimId={claim?.id}
      claim={claim! as Claim}
      batchClaim={batchClaim}
    >
      {error && (
        <Alert
          message="Parts of this claim failed to load"
          showIcon
          closable={true}
          description={
            <>
              <div>
                Reloading the browser after a few moments may resolve the issue.
                If this error persists, please contact support at:
                <a
                  tw="af-p-2"
                  href="mailto:support@alaffiahealth.com"
                  target="_top"
                >
                  support@alaffiahealth.com
                </a>
              </div>
              <div tw="af-p-2">
                Message: {error?.message?.replaceAll("[GraphQL]", "")}
              </div>
            </>
          }
          type="error"
        />
      )}
      <div tw="af-w-full af-h-full af-min-h-full af-max-h-full">
        <AutoSizer defaultHeight={900} defaultWidth={1200}>
          {({
            height = 900,
            width = 1200,
          }: {
            height: number;
            width: number;
          }) => {
            const h = height || 900;
            const w = width || 1200;
            return (
              <div
                style={{
                  width: w,
                  height: h,
                  minHeight: h,
                }}
              >
                <IbTable
                  width={w - 8}
                  height={h - 8}
                  loading={fetching}
                  claim={claim! as Claim}
                  batchClaim={batchClaim}
                  setCreatingAuditFinding={setCreatingAuditFinding}
                  setDecliningAuditFinding={setDecliningAuditFinding}
                  navigateToIbinRow={navigateToIbinRow}
                  readOnly={readOnly}
                  canCreateFindings={canCreateFindings}
                  tabToolbarRef={tabToolbarRef}
                  ref={tableRef}
                ></IbTable>
              </div>
            );
          }}
        </AutoSizer>
      </div>
    </FindingContextProvider>
  );
};
