import * as React from "react";

import { useIsomorphicEffect } from "./useIsomorphicEffect";

export function useEventCallback<TArgs extends unknown[], TReturn>(
  callback: (...args: TArgs) => TReturn,
): (...args: TArgs) => TReturn;
export function useEventCallback<TArgs extends unknown[], TReturn>(
  callback: ((...args: TArgs) => TReturn) | undefined,
): (...args: TArgs) => TReturn | undefined;
export function useEventCallback<TArgs extends unknown[], TReturn>(
  callback: ((...args: TArgs) => TReturn) | undefined,
): (...args: TArgs) => TReturn | undefined {
  const callbackRef = React.useRef(callback);

  useIsomorphicEffect(() => {
    callbackRef.current = callback;
  });

  return React.useCallback((...args) => callbackRef.current?.(...args), []);
}
