import type { FC } from "react";
import { useContext } from "react";
import { EditOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import type { ItemizedBillLine } from "../../../../../gql/graphql";
import { AuditFindingSeedType } from "../../../claimWorkspace/createAuditFinding/auditFindingUtil";
import { FindingContext } from "../../../claimWorkspace/findingEdit/findingProvider";
import type {
  AutodorFinding,
  ConvertAutodorFindingOp,
} from "../../../claimWorkspace/findingEdit/types";

interface LineConvertAutodorFindingButtonProps {
  fetching: boolean;
  readOnly: boolean;
  autodorFinding: AutodorFinding;
  ibData: ItemizedBillLine[];
}

export const LineConvertAudotorFindingButton: FC<
  LineConvertAutodorFindingButtonProps
> = ({ fetching, readOnly, autodorFinding, ibData }) => {
  const { findingEditor } = useContext(FindingContext);
  const operation: ConvertAutodorFindingOp = {
    autodorFinding,
    operation: "convert",
    seedType: AuditFindingSeedType.IB_CLAIM_LINE,
    ibData,
  };
  return readOnly ? null : (
    <Tooltip title="Review and convert to finding" key="tt_convert-autodor">
      <Button
        data-cy={"claim-line-finding-btn-convert-autodor"}
        loading={fetching}
        type={"primary"}
        size="small"
        onClick={() => {
          findingEditor.showModal({ operation });
        }}
        disabled={readOnly}
        icon={<EditOutlined />}
      />
    </Tooltip>
  );
};
