/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { gql } from "@urql/core";
// component library
import { Divider, Form, Input, message, Modal } from "antd";
import QRCode from "qrcode.react";
import { useMutation } from "urql";

const updateUserMfaPreferenceMutation = gql`
  mutation profile_setUserMfaPreference(
    $userMfaPreferenceInput: UserMfaPreferenceInput!
  ) {
    setUserMfaPreference(input: $userMfaPreferenceInput) {
      success
      message
    }
  }
`;

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

const SetupTOTP = (props: any) => {
  const [form] = Form.useForm();
  const { visible, data, refreshProfileQuery, closeModal } = props;

  const [, updateUserMfaPreference] = useMutation(
    updateUserMfaPreferenceMutation,
  );

  const cancelTotpSetup = () => {
    form.resetFields();
    props.closeModal();
  };

  const okTotpSetup = () => {
    void form.validateFields().then(async (values) => {
      const userMfaPreferenceInput = { userCode: values.totpPassword };
      const { data } = await updateUserMfaPreference({
        userMfaPreferenceInput,
      });

      if (!data.setUserMfaPreference.success) {
        void message.error(data.setUserMfaPreference.message);
      } else {
        void message.success("Successfully Enabled MFA Token!");
        refreshProfileQuery({ requestPolicy: "network-only" });
        closeModal();
      }
    });
  };

  return (
    <div>
      <Modal
        title="Set up virtual MFA device"
        open={visible}
        destroyOnClose={true}
        closable={true}
        okText="Assign MFA"
        onCancel={cancelTotpSetup}
        onOk={okTotpSetup}
      >
        <Form
          {...layout}
          form={form}
          name="basic"
          initialValues={{
            remember: true,
          }}
        >
          <div style={{ paddingTop: "10px" }}>
            1. Install a compatible app on your mobile device or computer
          </div>
          <Divider />
          <div style={{ paddingTop: "10px" }}>
            2. Use your virtual MFA app and your devices camera to scan the QR
            code
          </div>
          <div style={{ paddingTop: "10px" }}>
            <QRCode value={data ? data.link : ""} />
          </div>
          <div style={{ paddingTop: "5px" }}>
            Alternatively you can type in the secret key:{" "}
            {data ? data.seed : ""}
          </div>

          <Divider />
          <div style={{ paddingTop: "10px" }}>
            3. Type the next MFA code below from your virtual MFA app
          </div>
          <Form.Item
            name={["totpPassword"]}
            label="Enter OneTime Password "
            rules={[
              {
                pattern: new RegExp("^[0-9]{6,6}$"),
                message:
                  "Please enter 6 digits for OneTime password from authentication app",
              },
            ]}
          >
            <Input
              style={{
                width: "40%",
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export { SetupTOTP };
