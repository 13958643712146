/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import type { ICellRendererParams } from "ag-grid-community";

import { LineClampedCell } from "./lineClampedCell";

const _lineClampedCellRenderer = <T,>(props: ICellRendererParams<T, any>) => (
  <LineClampedCell>
    {props.valueFormatted ?? false
      ? props.valueFormatted
      : props.value
      ? props.value
      : props.getValue
      ? props.getValue()
      : ""}
  </LineClampedCell>
);

export const lineClampedCellRenderer = React.memo(_lineClampedCellRenderer);
