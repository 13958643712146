import { isNodeProcess } from "is-node-process";
import type { Observable } from "rxjs";

import type { LoggerType } from "../client/log";
import { getLogger } from "../client/log";
import {
  LocalStorageAuthDataStrategy,
  MemoryAuthDataStrategy,
} from "./authDataStrategies";
import type { AuthDataStrategy } from "./types";

export interface AuthDataArgs {
  url: string;
  allowLocalStorage: boolean;
}

export class AuthData implements AuthDataStrategy {
  private logger: LoggerType;
  private hasLocalStorage = false;
  private allowLocalStorage = false;
  private data: AuthDataStrategy;
  readonly dataChangeObservable: Observable<boolean> | undefined;

  private get useLocalStorage() {
    return this.hasLocalStorage && this.allowLocalStorage;
  }

  constructor({ url, allowLocalStorage }: AuthDataArgs) {
    this.logger = getLogger("AuthHandler");
    // this.logger.setLevel(this.logger.levels.TRACE, false);
    this.allowLocalStorage = allowLocalStorage;
    this.configureStorage();
    const ul = this.useLocalStorage;
    this.data = ul // this.useLocalStorage
      ? new LocalStorageAuthDataStrategy({ url })
      : new MemoryAuthDataStrategy({ url });

    if (this.data.dataChangeObservable) {
      this.dataChangeObservable = this.data.dataChangeObservable;
    }
  }

  private configureStorage = () => {
    // see if we have local storage
    this.hasLocalStorage =
      !isNodeProcess() && checkStorageAvailable("localStorage");

    this.logger.trace("Storage?", {
      use: this.useLocalStorage,
      has: this.hasLocalStorage,
      allow: this.allowLocalStorage,
    });
  };

  get url() {
    return this.data.url;
  }
  get userId() {
    return this.data.userId;
  }
  get username() {
    return this.data.username;
  }
  get userRole() {
    return this.data.userRole;
  }
  get token() {
    return this.data.token;
  }
  get expires() {
    return this.data.expires;
  }
  get refreshToken() {
    return this.data.refreshToken;
  }
  get raw() {
    return this.data.raw;
  }

  clearLoginFields = () => {
    this.data.clearLoginFields();
  };

  setLoginFields: AuthDataStrategy["setLoginFields"] = (args) => {
    this.data.setLoginFields(args);
  };
}

//
function checkStorageAvailable(type: string) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
  let storage;
  try {
    storage = window[type as keyof typeof window];
    const x = "__storage_test__";
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (e: any) {
    const code = e.code ? e.code : 0;
    return (
      e instanceof DOMException &&
      // everything except Firefox
      (code === 22 ||
        // Firefox
        code === 1014 ||
        // test name field too, because code might not be present
        // everything except Firefox
        e.name === "QuotaExceededError" ||
        // Firefox
        e.name === "NS_ERROR_DOM_QUOTA_REACHED") &&
      // acknowledge QuotaExceededError only if there's something already stored
      storage &&
      storage.length !== 0
    );
  }
}
