import type { AlaffiaClient } from "@alaffia-technology-solutions/client-sdk";

import { createMutationHook, createQueryHook } from "../factory";

export const caseKeys = {
  all: ["cases"] as const,
  lists: () => [...caseKeys.all, "list"] as const,
  list: <T>(filters: T) => [...caseKeys.lists(), filters] as const,
  details: () => [...caseKeys.all, "detail"] as const,
  detail: ({ id }: { id: string }) => [...caseKeys.details(), id] as const,
};

export const threadKeys = {
  all: ["threads"] as const,
  lists: () => [...threadKeys.all, "list"] as const,
  list: <T>(filters: T) => [...threadKeys.lists(), filters] as const,
};

export const cases = {
  getAllCases: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.getAllCases,
    queryKeyFn: caseKeys.list,
  }),
  getClaimCases: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.getClaimCases,
    queryKeyFn: caseKeys.list,
  }),
  getCase: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.getCase,
    queryKeyFn: caseKeys.detail,
  }),
  getThreads: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.getThreads,
    queryKeyFn: threadKeys.list,
  }),
  createCase: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.createCase,
    mutationKey: ["cases", "createCase"],
  }),
  updateCase: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.updateCase,
    mutationKey: ["cases", "updateCase"],
  }),
  deleteCase: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.deleteCase,
    mutationKey: ["cases", "deleteCase"],
  }),
  prompt: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.prompt,
    mutationKey: ["cases", "prompt"],
  }),
  createThread: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.cases.createThread,
    mutationKey: ["cases", "createThread"],
  }),
  ratePromptResponse: createMutationHook({
    methodSelector: (alaffiaClient) => {
      if (!alaffiaClient.features.promptResponseRatings) {
        throw new Error(
          "ratePromptResponse is not enabled in this AlaffiaClient instance",
        );
      }

      return (
        alaffiaClient as AlaffiaClient<
          Record<string, never>,
          { promptResponseRatings: true }
        >
      ).cases.ratePromptResponse;
    },
    mutationKey: ["cases", "ratePromptResponse"],
  }),
};
