import * as React from "react";
import { cva } from "class-variance-authority";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

export const textareaVariants = cva(
  "af-border-input af-ring-offset-background focus-visible:af-ring-ring af-flex af-min-h-[80px] af-w-full af-resize-none af-rounded-lg af-border af-bg-white af-p-4 af-text-sm af-text-gray-800 placeholder:af-text-gray-500 focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-offset-2 disabled:af-cursor-not-allowed disabled:af-opacity-50",
);

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(textareaVariants(), className)}
        ref={ref}
        {...props}
      />
    );
  },
);
Textarea.displayName = "Textarea";

export { Textarea };
