/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { v5 as uuidv5 } from "uuid";

// takes a <BatchClaim>[]
// and returns a uuid that can be used as a unique
// React node key
const batchClaimsNodeKey = (batchClaims: any) =>
  uuidv5(
    batchClaims.map(({ id }: any) => id).join("."),
    "b4ed6580-0d07-45be-b8c0-34e47fcd3ed3",
  );

const claimMenuItemDetails = ({ batchClaims, actionClaims }: any) =>
  (actionClaims.length > 1 && `(${actionClaims.length})`) ||
  (actionClaims.length === 1 && batchClaims.length > 1 && "(1)");

export { batchClaimsNodeKey, claimMenuItemDetails };
