/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { PauseOutlined, PlaySquareOutlined } from "@ant-design/icons";

const onHold = {
  auditProgress: {
    stateTypeLabel: "On Hold",
    defaultLabel: "No Claim are on hold",
    tooltipLabel: "Claims that are on hold, pending some further action",
    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that runs a groupedAggregate (groupedBy: ON_HOLD_STATE) on
     *     the onHoldClaimState table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: () => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `onHold: batchClaimStatesWithActiveStates(
        filter: {
          and: [
            { batchClaimById: $batchClaimFilter }
            { onHoldClaimState: { equalTo: ON_HOLD } }
          ]
        }
      ) {
        totalCount
      }`;
      return query;
    },
    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e ON_HOLD): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims: { totalCount },
        onHold,
      },
      permissions,
      userType,
      queryableStates,
      states,
    }: any) => {
      const progressStatProps: any = {};
      const formattedQueryResults: any = {
        ON_HOLD: onHold.totalCount,
        NOT_ON_HOLD: totalCount - onHold.totalCount || 0,
      };
      queryableStates.forEach((stateName: string | number) => {
        if (permissions.claimStatesToShow.onHold.has(stateName)) {
          progressStatProps[stateName] = {
            totalCount: formattedQueryResults[stateName],
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            tagColor: states[stateName].tagColor,
            icon: states[stateName].icon,
            description: states[stateName].description(userType),
          };
        }
      });
      return progressStatProps;
    },
  },
  queryableStates: ["NOT_ON_HOLD", "ON_HOLD"],
  defaultStates: ["NOT_ON_HOLD"],
  dataType: "enum",
  onTableQuery: "batchClaims",
  states: {
    //defaultState: 'NOT_ON_HOLD'
    NOT_ON_HOLD: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "Not On Hold";
      },
      primaryColor: "lightgray",
      strokeColor: "white",
      tagColor: null,
      icon: <PlaySquareOutlined style={{ fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "No hold on claim(s)";
      },
      displayCondition: () => false,
      timelineEventRendering: {
        description: () => "Removed hold on claim(s)",
        renderingAllowed: () => true,
      },
    },
    ON_HOLD: {
      name: (userType: string | number) => {
        return {
          PROVIDER: "Awaiting Payer Response",
          PAYER: "Awaiting Client Response",
          ADMINISTRATOR: "On Hold",
          AUDITOR: "On Hold",
        }[userType];
      },
      primaryColor: "#0c0b0bd6",
      strokeColor: "white",
      tagColor: "#0c0b0bd6",
      icon: <PauseOutlined style={{ color: "#0c0b0bd6", fontSize: "120%" }} />,
      description: (userType: string | number) => {
        return {
          PROVIDER:
            "Claim(s) Awaiting Payer response, please contact your account manager",
          PAYER:
            "Claim(s) Awaiting Client response, please contact your account manager",
          ADMINISTRATOR: "Claim(s) On Hold",
          AUDITOR: "Claim(s) On Hold",
        }[userType];
      },
      displayCondition: ({ batchClaimState: { onHoldClaimState } }: any) =>
        onHoldClaimState === "ON_HOLD",
      timelineEventRendering: {
        description: (userType: string | number) => {
          return {
            PROVIDER: "Claim set to Awaiting Payer Response",
            PAYER: "Claim set to Awaiting Payer Response",
            ADMINISTRATOR: "Claim Set to On Hold",
            AUDITOR: "Claim Set to On Hold",
          }[userType];
        },
        renderingAllowed: () => true,
      },
    },
  },
  // since a binary selection this will alway come as an array of values of size 1
  filterBuilder: (values: string | string[]) => {
    /* 
      POSSIBLE VALUES = NOT_ON_HOLD OR ON_HOLD -- only ONE Value accepted

      filter required for each values:
      NOT_ON_HOLD
        - onHoldClaimStatesExist = false
          -- or --
        - onHoldClaimState = NOT_ON_HOLD

      ON_HOLD
      - onHoldClaimStatesExist: true   
        -- and --
      - onHoldClaimState = ON_HOLD
    */
    if (values.includes("NOT_ON_HOLD"))
      /*
        when NOT_ON_HOLD in values we add the 'OR'
        because for the case when onHoldClaimStatesExist is false that handles the 'NOT_ON_HOLD' scenerio
        and the pg function 'onHoldClaimState' handles the remaining scenerios
      */
      return {
        or: [
          { onHoldClaimStatesExist: false },
          { batchClaimStateById: { onHoldClaimState: { in: values } } },
        ],
      };
    else
      return {
        onHoldClaimStatesExist: true,
        batchClaimStateById: { onHoldClaimState: { in: values } },
      };
  },
};

export { onHold };
