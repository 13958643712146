/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-enum-comparison */
import {
  AuditFindingReportSubtype,
  AuditFindingReportType,
} from "../../../../../src/gql/graphql";
import type { BatchClaim } from "../../../../gql/graphql";

const allReportTypes = {
  MDH: {
    subtypes: {
      PRELIMINARY: { label: "Preliminary", value: "PRELIMINARY" },
      DRAFT: { label: "Draft", value: "DRAFT" },
      // FINAL: { label: 'Final', value: 'FINAL' }, // spec requires a prelim first, can only create final via version report
    },
    disabled: false,
  },
  EOA: {
    subtypes: {
      PROCESSED: { label: "Processed", value: "PROCESSED" },
    },
    disabled: false,
  },
  IFR: {
    subtypes: {
      PROCESSED: { label: "Not applicable", value: "PROCESSED" },
    },
    disabled: false,
  },
};

export type UiEnabledReportTypes = Record<
  string,
  {
    disabled?: boolean;
    existingClaimsWithReportType?: string[];
    subtypes: Record<
      string,
      {
        disabled?: boolean;
        label: string;
        value: string;
      }
    >;
  }
>;

export type ClaimWithReportInfo = Omit<
  Partial<BatchClaim>,
  "reportVersions"
> & {
  claimNumber: string;
  reportVersions: {
    nodes: {
      auditFindingReportType: string;
      auditFindingReportSubtype: string;
    }[];
  };
};

const isReportMdhDraft = (report: {
  auditFindingReportType: string;
  auditFindingReportSubtype: string;
}) =>
  report.auditFindingReportType === AuditFindingReportType.MDH &&
  report.auditFindingReportSubtype === AuditFindingReportSubtype.DRAFT;

export function getUiEnabledReportTypes(
  claims: ClaimWithReportInfo[],
): UiEnabledReportTypes {
  const existingTypes = claims.reduce((acc, claim) => {
    for (const report of claim.reportVersions.nodes) {
      if (!isReportMdhDraft(report)) {
        if (!acc[report.auditFindingReportType]) {
          acc[report.auditFindingReportType] = new Set();
        }
        acc[report.auditFindingReportType].add(claim.icn);
      }
    }
    return acc;
  }, {} as any);

  if (existingTypes.size === 0) {
    return allReportTypes;
  } else {
    const reportTypes = Object.fromEntries(
      Object.entries(allReportTypes).map((type) => {
        // console.log('type', type);
        const [k, v] = type;

        // MDH: if any claim in the list has membership in an MDH report that is
        // NOT DRAFT (ignored above), it can't be add another one so prohibit:
        if (k === "MDH" && (existingTypes[k]?.size ?? 0 > 0)) {
          return [
            k,
            {
              ...v,
              disabled: true,
              existingClaimsWithReportType: Array.from(existingTypes[k]),
            },
          ];
        }

        // IFR: if any claim in the list has membership in an IFR report,
        // that IFR should be versioned instead of creating a new one -- since
        // IFRs are individual-claim-level reports it doesn't make sense to
        // create a new one when one already exists
        if (k === "IFR" && (existingTypes[k]?.size ?? 0 > 0)) {
          return [
            k,
            {
              ...v,
              disabled: true,
              existingClaimsWithReportType: Array.from(existingTypes[k]),
            },
          ];
        }

        // EOA: Since EOA could be run both on individual claims and as a member
        // of a group report (and is allowed to have both), we don't currently disable
        // it even if there are existing EOAs... If we wanted to prevent multiple EOAs
        // with the same membership on the front end, we could do so by validating the claim
        // membership of the report being created with all existing reports of the same type
        // but for now we'll trust the back end to enforce it's rules, and the user will get
        // a back-end error message if the report they're trying to create violates RS constraints

        return [k, { ...v }];
      }),
    );

    return reportTypes;
  }
}
