import React from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

import { AnswerItem } from "./AnswerItem";
import { FeedbackItem } from "./FeedbackItem";
import { QuestionItem } from "./QuestionItem";

type MessagesBaseProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLDivElement>
>;

const MessagesBase = React.forwardRef<HTMLDivElement, MessagesBaseProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <div
        className={cn(
          "af-relative af-flex af-min-h-0 af-flex-1 af-flex-col",
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);

type ListProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLUListElement>
>;

const List = React.forwardRef<HTMLUListElement, ListProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <ul
        className={cn(
          "af-flex af-list-none af-flex-col af-gap-10 af-p-0",
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </ul>
    );
  },
);

export const Messages = Object.assign(MessagesBase, {
  List,
  QuestionItem,
  AnswerItem,
  FeedbackItem,
});
