import type { UpdatesConfig, Variables } from "@urql/exchange-graphcache";

import type {
  AskAutodorQueue,
  CasesInput,
  CreateCaseInput,
  createCaseMutation,
  CreateSavedPromptInput,
  createSavedPromptMutation,
  CreateThreadInput,
  createThreadMutation,
  deleteCaseMutation,
  getCaseQuery,
  getCasesQuery,
  getClaimCasesQuery,
  getSavedPromptsQuery,
  getThreadsQuery,
  getThreadsWithPromptResponseRatingsQuery,
  PromptInput,
  promptMutation,
  PromptResponseRatingType,
  promptWithPromptResponseRatingsMutation,
  ratePromptResponseMutation,
  ThreadsInput,
  UpdateCaseInput,
  updateCaseMutation,
  updateCaseMutationVariables,
} from "../../remote/gql/graphql";
import { invalidateQuery } from "../../remote/graphCache";
import { createCaseMutationDoc } from "../../remote/mutations/createCase.gql";
import { createSavedPromptMutationDoc } from "../../remote/mutations/createSavedPrompt.gql";
import { createThreadMutationDoc } from "../../remote/mutations/createThread.gql";
import { deleteCaseMutationDoc } from "../../remote/mutations/deleteCase.gql";
import {
  promptMutationDoc,
  promptWithPromptResponseRatingsMutationDoc,
} from "../../remote/mutations/prompt.gql";
import { ratePromptResponseMutationDoc } from "../../remote/mutations/ratePromptResponse.gql";
import { updateCaseMutationDoc } from "../../remote/mutations/updateCase.gql";
import { getCaseQueryDoc } from "../../remote/queries/getCase.gql";
import { getCasesQueryDoc } from "../../remote/queries/getCases.gql";
import { getClaimCasesQueryDoc } from "../../remote/queries/getClaimCases.gql";
import { getSavedPromptsQueryDoc } from "../../remote/queries/getSavedPrompts.gql";
import {
  getThreadsQueryDoc,
  getThreadsWithPromptResponseRatingsQueryDoc,
} from "../../remote/queries/getThreads.gql";
import type { ClientReturnType } from "../alaffiaClient";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

type CasesModuleArgs = AlaffiaClientModuleArgs;

export class CasesModule extends AlaffiaClientModule {
  constructor(args: CasesModuleArgs) {
    super(args);
  }

  static cacheOptions: UpdatesConfig = {
    Mutation: {
      createCase(_result, args, cache) {
        const queueId = CasesModule.getQueueIdFromVariables(args);

        invalidateQuery(cache, "claimCases");
        invalidateQuery(cache, "cases", (field) => {
          return (
            (!field.arguments && !queueId) ||
            (!!field.arguments &&
              CasesModule.getQueueIdFromVariables(field.arguments) === queueId)
          );
        });
      },
      updateCase(
        _result: updateCaseMutation,
        args: updateCaseMutationVariables,
        cache,
      ) {
        invalidateQuery(cache, "cases");
        invalidateQuery(cache, "claimCases");
        if (args.input.fileIds) {
          invalidateQuery(cache, "files");
        }
      },
      deleteCase(_result, _args, cache) {
        invalidateQuery(cache, "cases");
        invalidateQuery(cache, "claimCases");
        invalidateQuery(cache, "files");
      },
      createSavedPrompt(_result, _args, cache) {
        invalidateQuery(cache, "savedPrompts");
      },
      prompt(_result, _args, cache) {
        invalidateQuery(cache, "cases");
        invalidateQuery(cache, "threads");
      },
    },
  };

  private static getQueueIdFromVariables = (
    args: Variables,
  ): AskAutodorQueue["id"] | null => {
    if (typeof args.input !== "object" || !args.input) {
      return null;
    }

    return "queueId" in args.input ? args.input.queueId : null;
  };

  getAllCases = async (input: {
    input?: CasesInput;
  }): ClientReturnType<getCasesQuery["cases"]> => {
    const result = await this.runGqlQuery(
      getCasesQueryDoc,
      { input: input?.input ?? {} },
      "getAllCases",
    );

    return {
      data: result.data?.cases ?? null,
      error: result.error ?? null,
    };
  };

  getClaimCases = async (input: {
    claimId: string;
  }): ClientReturnType<getClaimCasesQuery["claimCases"]> => {
    const result = await this.runGqlQuery(
      getClaimCasesQueryDoc,
      { claimId: input.claimId },
      "getClaimCases",
    );

    return {
      data: result.data?.claimCases ?? null,
      error: result.error ?? null,
    };
  };

  getCase = async (input: {
    id: string;
  }): ClientReturnType<getCaseQuery["case"]> => {
    const result = await this.runGqlQuery(
      getCaseQueryDoc,
      { id: input.id },
      "getCase",
    );
    return {
      data: result.data?.case ?? null,
      error: result.error ?? null,
    };
  };

  createCase = async (input: {
    input: CreateCaseInput;
  }): ClientReturnType<createCaseMutation["createCase"]> => {
    const result = await this.runGqlMutation(
      createCaseMutationDoc,
      { input: input.input },
      "createCase",
    );

    return {
      data: result.data?.createCase ?? null,
      error: result.error ?? null,
    };
  };

  deleteCase = async (input: {
    id: string;
  }): ClientReturnType<deleteCaseMutation["deleteCase"]> => {
    const result = await this.runGqlMutation(
      deleteCaseMutationDoc,
      { id: input.id },
      "deleteCase",
    );

    return {
      data: result.data?.deleteCase ?? null,
      error: result.error ?? null,
    };
  };

  updateCase = async ({
    id,
    updateInput,
  }: {
    id: string;
    updateInput: UpdateCaseInput;
  }): ClientReturnType<updateCaseMutation["updateCase"]> => {
    const result = await this.runGqlMutation(
      updateCaseMutationDoc,
      {
        id,
        input: {
          name: updateInput.name,
          fileIds: updateInput.fileIds,
          queueId: updateInput.queueId,
          completed: updateInput.completed,
        },
      },
      "updateCase",
    );

    return {
      data: result.data?.updateCase ?? null,
      error: result.error ?? null,
    };
  };

  createThread = async (input: {
    caseId: string;
    input: CreateThreadInput;
  }): ClientReturnType<createThreadMutation["createThread"]> => {
    const result = await this.runGqlMutation(
      createThreadMutationDoc,
      { caseId: input.caseId, input: input.input },
      "createThread",
    );

    return {
      data: result.data?.createThread ?? null,
      error: result.error ?? null,
    };
  };

  getAllSavedPrompts = async (): ClientReturnType<
    getSavedPromptsQuery["savedPrompts"]
  > => {
    const result = await this.runGqlQuery(
      getSavedPromptsQueryDoc,
      {},
      "getAllSavedPrompts",
    );

    return {
      data: result.data?.savedPrompts ?? null,
      error: result.error ?? null,
    };
  };

  createSavedPrompt = async (
    input: CreateSavedPromptInput,
  ): ClientReturnType<createSavedPromptMutation["createSavedPrompt"]> => {
    const result = await this.runGqlMutation(
      createSavedPromptMutationDoc,
      { input },
      "createSavedPrompt",
    );

    return {
      data: result.data?.createSavedPrompt ?? null,
      error: result.error ?? null,
    };
  };

  getThreads = async (input: {
    input: ThreadsInput;
  }): ClientReturnType<getThreadsQuery["threads"]> => {
    const result = await this.runGqlQuery(
      getThreadsQueryDoc,
      { input: input.input },
      "getThreads",
    );

    return {
      data: result.data?.threads ?? null,
      error: result.error ?? null,
    };
  };

  prompt = async (input: {
    input: PromptInput;
  }): ClientReturnType<promptMutation["prompt"]> => {
    const result = await this.runGqlMutation(
      promptMutationDoc,
      { input: input.input },
      "prompt",
    );

    return {
      data: result.data?.prompt ?? null,
      error: result.error ?? null,
    };
  };
}

export class CasesModuleWithPromptResponseRatings extends CasesModule {
  constructor(args: CasesModuleArgs) {
    super(args);
  }

  static cacheOptions: UpdatesConfig = {
    ...CasesModule.cacheOptions,
    Mutation: {
      ...CasesModule.cacheOptions.Mutation,
      ratePromptResponse(_result, _args, cache) {
        invalidateQuery(cache, "threads");
      },
    },
  };

  getThreads = async (input: {
    input: ThreadsInput;
  }): ClientReturnType<getThreadsWithPromptResponseRatingsQuery["threads"]> => {
    const result = await this.runGqlQuery(
      getThreadsWithPromptResponseRatingsQueryDoc,
      { input: input.input },
      "getThreads",
    );

    return {
      data: result.data?.threads ?? null,
      error: result.error ?? null,
    };
  };

  prompt = async (input: {
    input: PromptInput;
  }): ClientReturnType<promptWithPromptResponseRatingsMutation["prompt"]> => {
    const result = await this.runGqlMutation(
      promptWithPromptResponseRatingsMutationDoc,
      { input: input.input },
      "prompt",
    );

    return {
      data: result.data?.prompt ?? null,
      error: result.error ?? null,
    };
  };

  ratePromptResponse = async (input: {
    promptResponseId: string;
    rating: PromptResponseRatingType | null;
  }): ClientReturnType<ratePromptResponseMutation["ratePromptResponse"]> => {
    const result = await this.runGqlMutation(
      ratePromptResponseMutationDoc,
      { promptResponseId: input.promptResponseId, rating: input.rating },
      "ratePromptResponse",
    );

    return {
      data: result.data?.ratePromptResponse ?? null,
      error: result.error ?? null,
    };
  };
}
