// Purpose: a definition of mappings between autodor suggestion metadata (e.g. the
// properties that aren't common to findings) and the default value for the finding
// rationale field or potentially other metadata fields that can be created/converted
// from them. Defined as a json-style config for the potential of storing it in the
// db when autodor rule types expand/become configurable.

/**
 * autodorToFindingConfig - provides default settings and hbs templates for
 * converting autodor suggestions to audit findings.  For example, which fields
 * should be copied from the suggestion into a new finding's rationale.
 * n.b. hbs templates can use {{{}}} (non-escaped replacement vars) since
 * this will preserve things like " without escaping -- inputs to these templates
 * are pre-sanitized and html-stripped using dompurify
 */
export const autodorToFindingConfig = {
  ptp: {
    label: "PTP",
    rationale: `{{{denialCode.rationaleTemplate}}}

Code ({{{rule.colTwoCode}}}) is unbundled from Code ({{{rule.colOneCode}}}) and not payable separately
`,
  },
  mue: {
    label: "MUE",
    rationale: `{{{denialCode.rationaleTemplate}}}

MUE Limit: {{rule.num}}
`,
  },
  nonBillable: {
    label: "Non-Billable",
    rationale: `{{{denialCode.rationaleTemplate}}}

{{{finding.description}}}
`,
  },
  genericRule: {
    rationale:
      "{{{denialCode.rationaleTemplate}}}\n\n{{{finding.description}}}",
  },
};
