/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { Collapse } from "antd";
import { omit, pick } from "lodash";
import tw, { css } from "twin.macro";

import { isNonNullStringArray } from "../../../../util/array";
import { isSet } from "../../../../util/isSet";
import {
  AutodorDynamicDetails,
  hasDisplayableData,
} from "../autodorDynamicDetails";
import { getRuleLabelForRuleType } from "../autodorFindingUtils";
import { handlebarsUtil } from "./handlebarsUtil";
import type { TemplateDefinition } from "./ruleDisplayTypes";

const defaultStyles = css({
  ...tw`af-whitespace-normal af-break-words`,
  "& .label": tw`af-text-left af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary`,
  "& .header": tw`af-font-bold af-text-left af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary`,
  "& .value": tw`af-text-left af-leading-5 af-align-baseline af-my-auto 
      af-p-0 af-whitespace-normal af-break-words`,
  "& .html-value": tw`af-w-full af-whitespace-pre-wrap`,
  "& .indent-1": tw`af-pl-3`,
  "& .indent-2": tw`af-pl-6`,
  "& .indent-3": tw`af-pl-9`,
  "& .indent-4": tw`af-pl-12`,
  "& .indent-5": tw`af-pl-16`,
  "& .indent-6": tw`af-pl-20`,
  "& .bold": tw`af-font-bold`,
  "& .flex": tw`af-flex af-flex-row af-gap-2`,
  "& .pad-x": tw`af-px-2`,
  "& .pad-l": tw`af-pl-2`,
  "& .pad-r": tw`af-pr-2`,
  "& .pad-y": tw`af-py-2`,
  "& .clamp-1": tw`af-line-clamp-1`,
  "& .clamp-2": tw`af-line-clamp-2`,
  "& .clamp-3": tw`af-line-clamp-3`,
  "& .clamp-4": tw`af-line-clamp-4`,
  "& .clamp-5": tw`af-line-clamp-5`,
  "& .scroll": tw`af-overflow-auto af-w-full af-h-24`,
  "& .italic": tw`af-italic`,
  "& .primary": tw`af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText`,
  "& .error": tw`af-text-antd-lt-colorError dark:af-text-antd-dk-colorError`,
});

export interface RuleTypeDisplayProps {
  ruleType?: string | null;
  rule?: Record<string, any> | null;
  template: TemplateDefinition["template"];
  showExtraProps?: TemplateDefinition["showExtraProps"];
}

export const RuleTypeDisplay: FC<RuleTypeDisplayProps> = ({
  ruleType,
  rule,
  template,
  showExtraProps = false,
}) => {
  if (!isSet(rule) || !isSet(ruleType) || !isSet(template)) {
    return (
      <div tw="af-text-antd-lt-colorError dark:af-text-antd-dk-colorError">
        Template config is incorrect for {ruleType}
      </div>
    );
  } else {
    const extraPropsToShow = isNonNullStringArray(showExtraProps)
      ? pick(rule ?? {}, showExtraProps)
      : showExtraProps
      ? omit(rule ?? {}, handlebarsUtil.getHandlebarsVariables(template))
      : {};

    const components = handlebarsUtil.getAsComponents(template, rule!);

    // n.b. the getHandlebarsVariables function above doesn't filter props used only as input to
    //  helpers, e.g. if it's only used in an #if but not {{ref'd}} -- to hide props in templates
    //  that are used in calculations but not displayed, assign them to an attr e.g.
    //  <... title={{myPropNotToShowInExtra}} />
    if (showExtraProps && hasDisplayableData(extraPropsToShow)) {
      return (
        <>
          <div css={defaultStyles}>{components}</div>
          <div tw="af-pt-2">
            <Collapse
              defaultActiveKey={[""]}
              ghost
              css={css({
                "& .ant-collapse-header": tw`af-p-0! af-m-0! af-font-bold! af-text-antd-lt-colorTextTertiary! dark:af-text-antd-dk-colorTextTertiary!`,
              })}
            >
              <Collapse.Panel
                header={`Additional ${getRuleLabelForRuleType(ruleType)} info`}
                key="addl-rule-fields"
              >
                <AutodorDynamicDetails
                  type={ruleType ?? "Unknown"}
                  title={""}
                  object={extraPropsToShow}
                ></AutodorDynamicDetails>
              </Collapse.Panel>
            </Collapse>
          </div>
        </>
      );
    }
    return <div css={defaultStyles}>{components}</div>;
  }
};
