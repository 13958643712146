/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\n  fragment AskAutodorQueueFragment on AskAutodorQueue {\n    __typename\n    id\n    name\n    createdAt\n  }\n": types.AskAutodorQueueFragmentFragmentDoc,
    "\n  fragment AskAutodorQueueSummaryFragment on AskAutodorQueueSummary {\n    __typename\n    caseCount\n    completedCaseCount\n    openCaseCount\n  }\n": types.AskAutodorQueueSummaryFragmentFragmentDoc,
    "\n  fragment AuditorUserFragment on AuditorUser {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    createdAt\n  }\n": types.AuditorUserFragmentFragmentDoc,
    "\n  fragment CaseFragment on Case {\n    __typename\n    id\n    name\n    completed\n    latestQuestion\n    createdAt\n  }\n": types.CaseFragmentFragmentDoc,
    "\n  fragment DirectoryFragment on Directory {\n    __typename\n    id\n    archived\n    name\n    createdAt\n    modifiedAt\n  }\n": types.DirectoryFragmentFragmentDoc,
    "\n  fragment DirectoryPathNodeFragment on DirectoryPathNode {\n    __typename\n    id\n    name\n  }\n": types.DirectoryPathNodeFragmentFragmentDoc,
    "\n  fragment FileFragment on File {\n    __typename\n    id\n    archived\n    fileName\n    readyToSearch\n    createdAt\n    modifiedAt\n    size\n  }\n": types.FileFragmentFragmentDoc,
    "\n  fragment SignedDownloadUrlFragment on SignedDownloadUrl {\n    __typename\n    fileId\n    url\n  }\n": types.SignedDownloadUrlFragmentFragmentDoc,
    "\n  fragment MessageFragment on Message {\n    __typename\n    id\n    promptText\n    promptInputState\n    createdAt\n  }\n": types.MessageFragmentFragmentDoc,
    "\n  fragment PromptFeedbackFragment on PromptFeedback {\n    __typename\n    level\n    message\n  }\n": types.PromptFeedbackFragmentFragmentDoc,
    "\n  fragment PromptResponseFragment on PromptResponse {\n    __typename\n    id\n    promptText\n    responseText\n    searchKeywords\n    createdAt\n    pages {\n      ...PromptResponsePageFragment\n    }\n    contextFiles {\n      id\n      archived\n      fileName\n    }\n  }\n": types.PromptResponseFragmentFragmentDoc,
    "\n  fragment PromptResponseWithRatingsFragment on PromptResponse {\n    ...PromptResponseFragment\n    ratings {\n      ...PromptResponseRatingFragment\n    }\n  }\n": types.PromptResponseWithRatingsFragmentFragmentDoc,
    "\n  fragment PromptResponsePageFragment on PromptResponsePage {\n    __typename\n    file {\n      id\n      fileName\n    }\n    textMatched\n    pageNumber\n    confidence\n  }\n": types.PromptResponsePageFragmentFragmentDoc,
    "\n  fragment PromptResponseRatingFragment on PromptResponseRating {\n    __typename\n    currentUserRating\n    downRatings\n    upRatings\n  }\n": types.PromptResponseRatingFragmentFragmentDoc,
    "\n  fragment ProviderUserFacilityFragment on ProviderUserFacility {\n    __typename\n    id\n  }\n": types.ProviderUserFacilityFragmentFragmentDoc,
    "\n  fragment SavedPromptFragment on SavedPrompt {\n    __typename\n    id\n    content\n    createdAt\n  }\n": types.SavedPromptFragmentFragmentDoc,
    "\n  fragment SearchResultFragment on SearchResult {\n    __typename\n    id\n    confidence\n    fileId\n    pageNumber\n    textMatched\n    results\n  }\n": types.SearchResultFragmentFragmentDoc,
    "\n  fragment ThreadFragment on Thread {\n    __typename\n    id\n    name\n    createdAt\n  }\n": types.ThreadFragmentFragmentDoc,
    "\n  fragment UserFragment on User {\n    __typename\n    id\n    firstName\n    lastName\n    title\n    email\n    userType\n  }\n": types.UserFragmentFragmentDoc,
    "\n  mutation archiveDirectory($id: ID!) {\n    archiveDirectory(id: $id) {\n      ...DirectoryFragment\n    }\n  }\n": types.archiveDirectoryDocument,
    "\n  mutation archiveFile($id: ID!) {\n    archiveFile(id: $id) {\n      ...FileFragment\n    }\n  }\n": types.archiveFileDocument,
    "\n  mutation confirmFileUploadSuccess($input: ConfirmFileUploadSuccessInput!) {\n    confirmFileUploadSuccess(input: $input) {\n      ...FileFragment\n      signedDownloadUrl {\n        ...SignedDownloadUrlFragment\n      }\n    }\n  }\n": types.confirmFileUploadSuccessDocument,
    "\n  mutation createAskAutodorQueue($input: CreateAskAutodorQueueInput!) {\n    createAskAutodorQueue(input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n": types.createAskAutodorQueueDocument,
    "\n  mutation createCase($input: CreateCaseInput!) {\n    createCase(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n": types.createCaseDocument,
    "\n  mutation createDirectory($input: CreateDirectoryInput!) {\n    createDirectory(input: $input) {\n      ...DirectoryFragment\n    }\n  }\n": types.createDirectoryDocument,
    "\n  mutation createSavedPrompt($input: CreateSavedPromptInput!) {\n    createSavedPrompt(input: $input) {\n      ...SavedPromptFragment\n    }\n  }\n": types.createSavedPromptDocument,
    "\n  mutation createThread($caseId: ID!, $input: CreateThreadInput!) {\n    createThread(caseId: $caseId, input: $input) {\n      ...ThreadFragment\n    }\n  }\n": types.createThreadDocument,
    "\n  mutation deleteAskAutodorQueue($id: ID!) {\n    deleteAskAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n": types.deleteAskAutodorQueueDocument,
    "\n  mutation deleteCase($id: ID!) {\n    deleteCase(id: $id) {\n      ...CaseFragment\n    }\n  }\n": types.deleteCaseDocument,
    "\n  mutation editAuditorUser($userId: ID!, $input: EditAuditorUserInput!) {\n    editAuditorUser(userId: $userId, input: $input) {\n      ...AuditorUserFragment\n      providerUserFacilities {\n        nodes {\n          ...ProviderUserFacilityFragment\n        }\n      }\n    }\n  }\n": types.editAuditorUserDocument,
    "\n  mutation prepareFileForUpload($input: PrepareFileForUploadInput!) {\n    prepareFileForUpload(input: $input) {\n      __typename\n      fileId\n      fileUploadId\n      signedUploadUrl {\n        url\n        fields\n      }\n    }\n  }\n": types.prepareFileForUploadDocument,
    "\n  mutation prompt($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n": types.promptDocument,
    "\n  mutation promptWithPromptResponseRatings($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseWithRatingsFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n": types.promptWithPromptResponseRatingsDocument,
    "\n  mutation ratePromptResponse(\n    $promptResponseId: ID!\n    $rating: PromptResponseRatingType\n  ) {\n    ratePromptResponse(promptResponseId: $promptResponseId, rating: $rating) {\n      ...PromptResponseWithRatingsFragment\n    }\n  }\n": types.ratePromptResponseDocument,
    "\n  mutation updateAskAutodorQueue(\n    $id: ID!\n    $input: UpdateAskAutodorQueueInput!\n  ) {\n    updateAskAutodorQueue(id: $id, input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n": types.updateAskAutodorQueueDocument,
    "\n  mutation updateCase($id: ID!, $input: UpdateCaseInput!) {\n    updateCase(id: $id, input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n": types.updateCaseDocument,
    "\n  mutation updateDirectory($id: ID!, $input: UpdateDirectoryInput!) {\n    updateDirectory(id: $id, input: $input) {\n      ...DirectoryFragment\n    }\n  }\n": types.updateDirectoryDocument,
    "\n  mutation updateFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      ...FileFragment\n    }\n  }\n": types.updateFileDocument,
    "\n  query getAskAutodorQueue($id: ID!) {\n    askAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n": types.getAskAutodorQueueDocument,
    "\n  query getAskAutodorQueues($args: AskAutodorQueuesArgs) {\n    askAutodorQueues(args: $args) {\n      __typename\n      edges {\n        __typename\n        node {\n          ...AskAutodorQueueFragment\n\n          summary {\n            ...AskAutodorQueueSummaryFragment\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      totalCount\n    }\n  }\n": types.getAskAutodorQueuesDocument,
    "\n  query getCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n": types.getCaseDocument,
    "\n  query getCases($input: CasesInput!) {\n    cases(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n": types.getCasesDocument,
    "\n  query getClaimCases($claimId: UUID!) {\n    claimCases(claimId: $claimId) {\n      __typename\n      id\n      name\n      createdAt\n      completed\n    }\n  }\n": types.getClaimCasesDocument,
    "\n  query getDirectory($id: ID, $itemsInput: DirectoryItemsInput) {\n    directory(id: $id) {\n      ...DirectoryFragment\n      path {\n        ...DirectoryPathNodeFragment\n      }\n      items(input: $itemsInput) {\n        ... on Directory {\n          ...DirectoryFragment\n        }\n        ... on File {\n          ...FileFragment\n        }\n      }\n    }\n  }\n": types.getDirectoryDocument,
    "\n  query getFile($id: ID!) {\n    file(id: $id) {\n      ...FileFragment\n      parentDirectory {\n        path {\n          id\n          name\n        }\n      }\n    }\n  }\n": types.getFileDocument,
    "\n  query getFileDownloadUrl($id: ID!) {\n    fileDownloadUrl(id: $id) {\n      ...SignedDownloadUrlFragment\n    }\n  }\n": types.getFileDownloadUrlDocument,
    "\n  query getFiles($input: FilesInput) {\n    files(input: $input) {\n      ...FileFragment\n    }\n  }\n": types.getFilesDocument,
    "\n  query getSavedPrompts {\n    savedPrompts {\n      ...SavedPromptFragment\n    }\n  }\n": types.getSavedPromptsDocument,
    "\n  query getThreads($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n": types.getThreadsDocument,
    "\n  query getThreadsWithPromptResponseRatings($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseWithRatingsFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n": types.getThreadsWithPromptResponseRatingsDocument,
    "\n  query getUser($id: ID!) {\n    user(id: $id) {\n      ...UserFragment\n    }\n  }\n": types.getUserDocument,
    "\n  query getUserTenantName {\n    userTenantName\n  }\n": types.getUserTenantNameDocument,
    "\n  query searchInFile($fileId: ID!, $query: String!) {\n    searchInFile(fileId: $fileId, query: $query) {\n      ...SearchResultFragment\n    }\n  }\n": types.searchInFileDocument,
};

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = graphql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function graphql(source: string): unknown;

/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AskAutodorQueueFragment on AskAutodorQueue {\n    __typename\n    id\n    name\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AskAutodorQueueFragment on AskAutodorQueue {\n    __typename\n    id\n    name\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AskAutodorQueueSummaryFragment on AskAutodorQueueSummary {\n    __typename\n    caseCount\n    completedCaseCount\n    openCaseCount\n  }\n"): (typeof documents)["\n  fragment AskAutodorQueueSummaryFragment on AskAutodorQueueSummary {\n    __typename\n    caseCount\n    completedCaseCount\n    openCaseCount\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment AuditorUserFragment on AuditorUser {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    createdAt\n  }\n"): (typeof documents)["\n  fragment AuditorUserFragment on AuditorUser {\n    __typename\n    id\n    firstName\n    lastName\n    email\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment CaseFragment on Case {\n    __typename\n    id\n    name\n    completed\n    latestQuestion\n    createdAt\n  }\n"): (typeof documents)["\n  fragment CaseFragment on Case {\n    __typename\n    id\n    name\n    completed\n    latestQuestion\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DirectoryFragment on Directory {\n    __typename\n    id\n    archived\n    name\n    createdAt\n    modifiedAt\n  }\n"): (typeof documents)["\n  fragment DirectoryFragment on Directory {\n    __typename\n    id\n    archived\n    name\n    createdAt\n    modifiedAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment DirectoryPathNodeFragment on DirectoryPathNode {\n    __typename\n    id\n    name\n  }\n"): (typeof documents)["\n  fragment DirectoryPathNodeFragment on DirectoryPathNode {\n    __typename\n    id\n    name\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment FileFragment on File {\n    __typename\n    id\n    archived\n    fileName\n    readyToSearch\n    createdAt\n    modifiedAt\n    size\n  }\n"): (typeof documents)["\n  fragment FileFragment on File {\n    __typename\n    id\n    archived\n    fileName\n    readyToSearch\n    createdAt\n    modifiedAt\n    size\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SignedDownloadUrlFragment on SignedDownloadUrl {\n    __typename\n    fileId\n    url\n  }\n"): (typeof documents)["\n  fragment SignedDownloadUrlFragment on SignedDownloadUrl {\n    __typename\n    fileId\n    url\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment MessageFragment on Message {\n    __typename\n    id\n    promptText\n    promptInputState\n    createdAt\n  }\n"): (typeof documents)["\n  fragment MessageFragment on Message {\n    __typename\n    id\n    promptText\n    promptInputState\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromptFeedbackFragment on PromptFeedback {\n    __typename\n    level\n    message\n  }\n"): (typeof documents)["\n  fragment PromptFeedbackFragment on PromptFeedback {\n    __typename\n    level\n    message\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromptResponseFragment on PromptResponse {\n    __typename\n    id\n    promptText\n    responseText\n    searchKeywords\n    createdAt\n    pages {\n      ...PromptResponsePageFragment\n    }\n    contextFiles {\n      id\n      archived\n      fileName\n    }\n  }\n"): (typeof documents)["\n  fragment PromptResponseFragment on PromptResponse {\n    __typename\n    id\n    promptText\n    responseText\n    searchKeywords\n    createdAt\n    pages {\n      ...PromptResponsePageFragment\n    }\n    contextFiles {\n      id\n      archived\n      fileName\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromptResponseWithRatingsFragment on PromptResponse {\n    ...PromptResponseFragment\n    ratings {\n      ...PromptResponseRatingFragment\n    }\n  }\n"): (typeof documents)["\n  fragment PromptResponseWithRatingsFragment on PromptResponse {\n    ...PromptResponseFragment\n    ratings {\n      ...PromptResponseRatingFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromptResponsePageFragment on PromptResponsePage {\n    __typename\n    file {\n      id\n      fileName\n    }\n    textMatched\n    pageNumber\n    confidence\n  }\n"): (typeof documents)["\n  fragment PromptResponsePageFragment on PromptResponsePage {\n    __typename\n    file {\n      id\n      fileName\n    }\n    textMatched\n    pageNumber\n    confidence\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment PromptResponseRatingFragment on PromptResponseRating {\n    __typename\n    currentUserRating\n    downRatings\n    upRatings\n  }\n"): (typeof documents)["\n  fragment PromptResponseRatingFragment on PromptResponseRating {\n    __typename\n    currentUserRating\n    downRatings\n    upRatings\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ProviderUserFacilityFragment on ProviderUserFacility {\n    __typename\n    id\n  }\n"): (typeof documents)["\n  fragment ProviderUserFacilityFragment on ProviderUserFacility {\n    __typename\n    id\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SavedPromptFragment on SavedPrompt {\n    __typename\n    id\n    content\n    createdAt\n  }\n"): (typeof documents)["\n  fragment SavedPromptFragment on SavedPrompt {\n    __typename\n    id\n    content\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment SearchResultFragment on SearchResult {\n    __typename\n    id\n    confidence\n    fileId\n    pageNumber\n    textMatched\n    results\n  }\n"): (typeof documents)["\n  fragment SearchResultFragment on SearchResult {\n    __typename\n    id\n    confidence\n    fileId\n    pageNumber\n    textMatched\n    results\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment ThreadFragment on Thread {\n    __typename\n    id\n    name\n    createdAt\n  }\n"): (typeof documents)["\n  fragment ThreadFragment on Thread {\n    __typename\n    id\n    name\n    createdAt\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  fragment UserFragment on User {\n    __typename\n    id\n    firstName\n    lastName\n    title\n    email\n    userType\n  }\n"): (typeof documents)["\n  fragment UserFragment on User {\n    __typename\n    id\n    firstName\n    lastName\n    title\n    email\n    userType\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation archiveDirectory($id: ID!) {\n    archiveDirectory(id: $id) {\n      ...DirectoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation archiveDirectory($id: ID!) {\n    archiveDirectory(id: $id) {\n      ...DirectoryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation archiveFile($id: ID!) {\n    archiveFile(id: $id) {\n      ...FileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation archiveFile($id: ID!) {\n    archiveFile(id: $id) {\n      ...FileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation confirmFileUploadSuccess($input: ConfirmFileUploadSuccessInput!) {\n    confirmFileUploadSuccess(input: $input) {\n      ...FileFragment\n      signedDownloadUrl {\n        ...SignedDownloadUrlFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation confirmFileUploadSuccess($input: ConfirmFileUploadSuccessInput!) {\n    confirmFileUploadSuccess(input: $input) {\n      ...FileFragment\n      signedDownloadUrl {\n        ...SignedDownloadUrlFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createAskAutodorQueue($input: CreateAskAutodorQueueInput!) {\n    createAskAutodorQueue(input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"): (typeof documents)["\n  mutation createAskAutodorQueue($input: CreateAskAutodorQueueInput!) {\n    createAskAutodorQueue(input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createCase($input: CreateCaseInput!) {\n    createCase(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation createCase($input: CreateCaseInput!) {\n    createCase(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createDirectory($input: CreateDirectoryInput!) {\n    createDirectory(input: $input) {\n      ...DirectoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation createDirectory($input: CreateDirectoryInput!) {\n    createDirectory(input: $input) {\n      ...DirectoryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createSavedPrompt($input: CreateSavedPromptInput!) {\n    createSavedPrompt(input: $input) {\n      ...SavedPromptFragment\n    }\n  }\n"): (typeof documents)["\n  mutation createSavedPrompt($input: CreateSavedPromptInput!) {\n    createSavedPrompt(input: $input) {\n      ...SavedPromptFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation createThread($caseId: ID!, $input: CreateThreadInput!) {\n    createThread(caseId: $caseId, input: $input) {\n      ...ThreadFragment\n    }\n  }\n"): (typeof documents)["\n  mutation createThread($caseId: ID!, $input: CreateThreadInput!) {\n    createThread(caseId: $caseId, input: $input) {\n      ...ThreadFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteAskAutodorQueue($id: ID!) {\n    deleteAskAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"): (typeof documents)["\n  mutation deleteAskAutodorQueue($id: ID!) {\n    deleteAskAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation deleteCase($id: ID!) {\n    deleteCase(id: $id) {\n      ...CaseFragment\n    }\n  }\n"): (typeof documents)["\n  mutation deleteCase($id: ID!) {\n    deleteCase(id: $id) {\n      ...CaseFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation editAuditorUser($userId: ID!, $input: EditAuditorUserInput!) {\n    editAuditorUser(userId: $userId, input: $input) {\n      ...AuditorUserFragment\n      providerUserFacilities {\n        nodes {\n          ...ProviderUserFacilityFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation editAuditorUser($userId: ID!, $input: EditAuditorUserInput!) {\n    editAuditorUser(userId: $userId, input: $input) {\n      ...AuditorUserFragment\n      providerUserFacilities {\n        nodes {\n          ...ProviderUserFacilityFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation prepareFileForUpload($input: PrepareFileForUploadInput!) {\n    prepareFileForUpload(input: $input) {\n      __typename\n      fileId\n      fileUploadId\n      signedUploadUrl {\n        url\n        fields\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation prepareFileForUpload($input: PrepareFileForUploadInput!) {\n    prepareFileForUpload(input: $input) {\n      __typename\n      fileId\n      fileUploadId\n      signedUploadUrl {\n        url\n        fields\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation prompt($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation prompt($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation promptWithPromptResponseRatings($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseWithRatingsFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation promptWithPromptResponseRatings($input: PromptInput!) {\n    prompt(input: $input) {\n      ...MessageFragment\n      response {\n        ...PromptResponseWithRatingsFragment\n      }\n      feedback {\n        ...PromptFeedbackFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation ratePromptResponse(\n    $promptResponseId: ID!\n    $rating: PromptResponseRatingType\n  ) {\n    ratePromptResponse(promptResponseId: $promptResponseId, rating: $rating) {\n      ...PromptResponseWithRatingsFragment\n    }\n  }\n"): (typeof documents)["\n  mutation ratePromptResponse(\n    $promptResponseId: ID!\n    $rating: PromptResponseRatingType\n  ) {\n    ratePromptResponse(promptResponseId: $promptResponseId, rating: $rating) {\n      ...PromptResponseWithRatingsFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateAskAutodorQueue(\n    $id: ID!\n    $input: UpdateAskAutodorQueueInput!\n  ) {\n    updateAskAutodorQueue(id: $id, input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"): (typeof documents)["\n  mutation updateAskAutodorQueue(\n    $id: ID!\n    $input: UpdateAskAutodorQueueInput!\n  ) {\n    updateAskAutodorQueue(id: $id, input: $input) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateCase($id: ID!, $input: UpdateCaseInput!) {\n    updateCase(id: $id, input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  mutation updateCase($id: ID!, $input: UpdateCaseInput!) {\n    updateCase(id: $id, input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateDirectory($id: ID!, $input: UpdateDirectoryInput!) {\n    updateDirectory(id: $id, input: $input) {\n      ...DirectoryFragment\n    }\n  }\n"): (typeof documents)["\n  mutation updateDirectory($id: ID!, $input: UpdateDirectoryInput!) {\n    updateDirectory(id: $id, input: $input) {\n      ...DirectoryFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  mutation updateFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      ...FileFragment\n    }\n  }\n"): (typeof documents)["\n  mutation updateFile($id: ID!, $input: UpdateFileInput!) {\n    updateFile(id: $id, input: $input) {\n      ...FileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAskAutodorQueue($id: ID!) {\n    askAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"): (typeof documents)["\n  query getAskAutodorQueue($id: ID!) {\n    askAutodorQueue(id: $id) {\n      ...AskAutodorQueueFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getAskAutodorQueues($args: AskAutodorQueuesArgs) {\n    askAutodorQueues(args: $args) {\n      __typename\n      edges {\n        __typename\n        node {\n          ...AskAutodorQueueFragment\n\n          summary {\n            ...AskAutodorQueueSummaryFragment\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      totalCount\n    }\n  }\n"): (typeof documents)["\n  query getAskAutodorQueues($args: AskAutodorQueuesArgs) {\n    askAutodorQueues(args: $args) {\n      __typename\n      edges {\n        __typename\n        node {\n          ...AskAutodorQueueFragment\n\n          summary {\n            ...AskAutodorQueueSummaryFragment\n          }\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      totalCount\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCase($id: ID!) {\n    case(id: $id) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getCases($input: CasesInput!) {\n    cases(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"): (typeof documents)["\n  query getCases($input: CasesInput!) {\n    cases(input: $input) {\n      ...CaseFragment\n      queue {\n        ...AskAutodorQueueFragment\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getClaimCases($claimId: UUID!) {\n    claimCases(claimId: $claimId) {\n      __typename\n      id\n      name\n      createdAt\n      completed\n    }\n  }\n"): (typeof documents)["\n  query getClaimCases($claimId: UUID!) {\n    claimCases(claimId: $claimId) {\n      __typename\n      id\n      name\n      createdAt\n      completed\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getDirectory($id: ID, $itemsInput: DirectoryItemsInput) {\n    directory(id: $id) {\n      ...DirectoryFragment\n      path {\n        ...DirectoryPathNodeFragment\n      }\n      items(input: $itemsInput) {\n        ... on Directory {\n          ...DirectoryFragment\n        }\n        ... on File {\n          ...FileFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getDirectory($id: ID, $itemsInput: DirectoryItemsInput) {\n    directory(id: $id) {\n      ...DirectoryFragment\n      path {\n        ...DirectoryPathNodeFragment\n      }\n      items(input: $itemsInput) {\n        ... on Directory {\n          ...DirectoryFragment\n        }\n        ... on File {\n          ...FileFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFile($id: ID!) {\n    file(id: $id) {\n      ...FileFragment\n      parentDirectory {\n        path {\n          id\n          name\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getFile($id: ID!) {\n    file(id: $id) {\n      ...FileFragment\n      parentDirectory {\n        path {\n          id\n          name\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFileDownloadUrl($id: ID!) {\n    fileDownloadUrl(id: $id) {\n      ...SignedDownloadUrlFragment\n    }\n  }\n"): (typeof documents)["\n  query getFileDownloadUrl($id: ID!) {\n    fileDownloadUrl(id: $id) {\n      ...SignedDownloadUrlFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getFiles($input: FilesInput) {\n    files(input: $input) {\n      ...FileFragment\n    }\n  }\n"): (typeof documents)["\n  query getFiles($input: FilesInput) {\n    files(input: $input) {\n      ...FileFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getSavedPrompts {\n    savedPrompts {\n      ...SavedPromptFragment\n    }\n  }\n"): (typeof documents)["\n  query getSavedPrompts {\n    savedPrompts {\n      ...SavedPromptFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getThreads($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getThreads($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getThreadsWithPromptResponseRatings($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseWithRatingsFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n"): (typeof documents)["\n  query getThreadsWithPromptResponseRatings($input: ThreadsInput!) {\n    threads(input: $input) {\n      ...ThreadFragment\n      messages {\n        ...MessageFragment\n        response {\n          ...PromptResponseWithRatingsFragment\n        }\n        feedback {\n          ...PromptFeedbackFragment\n        }\n      }\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUser($id: ID!) {\n    user(id: $id) {\n      ...UserFragment\n    }\n  }\n"): (typeof documents)["\n  query getUser($id: ID!) {\n    user(id: $id) {\n      ...UserFragment\n    }\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query getUserTenantName {\n    userTenantName\n  }\n"): (typeof documents)["\n  query getUserTenantName {\n    userTenantName\n  }\n"];
/**
 * The graphql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function graphql(source: "\n  query searchInFile($fileId: ID!, $query: String!) {\n    searchInFile(fileId: $fileId, query: $query) {\n      ...SearchResultFragment\n    }\n  }\n"): (typeof documents)["\n  query searchInFile($fileId: ID!, $query: String!) {\n    searchInFile(fileId: $fileId, query: $query) {\n      ...SearchResultFragment\n    }\n  }\n"];

export function graphql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;