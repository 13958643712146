import type { FC } from "react";
import React from "react";

import type { AutodorFinding } from "../../../../claimWorkspace/findingEdit/types";
import { AutodorDynamicDetails } from "../autodorDynamicDetails";
import {
  getGenericRuleFromAutodorMetadata,
  getRuleLabelForRuleType,
  getRuleTypeFromAutodorMetadata,
} from "../autodorFindingUtils";
import type {
  TemplateDefinition,
  TemplateDefinitions,
} from "./ruleDisplayTypes";
import { RuleTypeDisplay } from "./ruleTypeDisplay";
import { defaultHbsTemplates } from "./ruleTypeTemplates";

export interface RuleDetailsProps {
  autodorMetadata: AutodorFinding["autodorMetadata"];
  templates?: TemplateDefinitions;
  showExtraPropsOverride?: TemplateDefinition["showExtraProps"];
  divider?: React.ReactElement | null;
}

export const RuleDetails: FC<RuleDetailsProps> = ({
  autodorMetadata,
  templates = defaultHbsTemplates,
  showExtraPropsOverride = false,
}) => {
  const ruleContainer = autodorMetadata.rule;

  const ruleType = getRuleTypeFromAutodorMetadata(autodorMetadata);

  const hasTemplate =
    !!templates[ruleType as keyof typeof templates] ||
    !!ruleContainer[ruleType];

  const template = hasTemplate
    ? templates[ruleType as keyof typeof templates]
    : null;
  const rule = hasTemplate
    ? ruleContainer[ruleType]
    : getGenericRuleFromAutodorMetadata(autodorMetadata);

  return template ? (
    <div key={`rule-dets-${ruleType}`}>
      <RuleTypeDisplay
        ruleType={ruleType}
        rule={typeof rule === "string" ? { rule } : rule}
        template={template.template}
        showExtraProps={showExtraPropsOverride || template.showExtraProps}
      ></RuleTypeDisplay>
    </div>
  ) : (
    <AutodorDynamicDetails
      type={ruleType}
      title={getRuleLabelForRuleType(ruleType)}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      object={rule}
    ></AutodorDynamicDetails>
  );
};
