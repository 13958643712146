/* eslint-disable @typescript-eslint/no-unsafe-return */
import type {
  AutodorFinding,
  Finding,
  FindingItemizedBillData,
} from "../../../gql/graphql";

export const isAutodorFinding = (
  finding: AutodorFinding | Finding,
): finding is AutodorFinding => finding?.__typename === "AutodorFinding"; // todo alts? metadata type, etc?

// todo test
export type AutodorIbFinding = AutodorFinding & {
  findingItemizedBillData: FindingItemizedBillData & { ibLineId: string };
};

export const isAutodorIbFinding = (
  finding: AutodorFinding | Finding,
): finding is AutodorIbFinding =>
  finding?.__typename === "AutodorFinding" && finding?.ibLineId;
