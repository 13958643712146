/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import accounting from "accounting-js";
import { Divider } from "antd";
import tw, { css } from "twin.macro";

import type { ItemizedBillLine } from "../../../../../../gql/graphql";
import { IbLineInfo } from "../../modal/ibLineInfo";
import type {
  EditFindingAdjustments,
  EditFindingValues,
  FormSetFieldsFn,
  InitialEditFindingValues,
} from "../../viewController/types";
import { BilledAmount } from "../fields/billedAmount";
import { ImproperPaymentCost } from "../fields/improperPaymentCost";
import { ImproperPaymentUnits } from "../fields/improperPaymentUnits";
import { RepriceCheckbox } from "../fields/repriceCheckbox";
import { IbLineAdjustmentsInfo } from "../ibLineAdjustmentInfo";

export interface IbLineAdjustmentsProps {
  initialValues: InitialEditFindingValues;
  adjustments?: EditFindingAdjustments;
  ibLines: ItemizedBillLine[];
  setFields: FormSetFieldsFn<EditFindingValues>;
  formLayout?: any;
}

export const IbLineAdjustments: FC<IbLineAdjustmentsProps> = (props) => {
  const { initialValues, adjustments, ibLines, formLayout, setFields } = props;
  const { paymentRate, ibLinesAreEquivalent, canReprice, isRepricing } =
    adjustments ?? ({} as EditFindingAdjustments);

  const repriceCheckboxHoverText =
    !adjustments || !paymentRate
      ? "Select Rev Code to enable repricing"
      : !ibLinesAreEquivalent
      ? "Repricing requires all selected lines to have the same values"
      : "Select to adjust billed/adjustment amount";

  return (
    <div css={css({ "& .ant-form-item": tw`af-mb-4` })}>
      <IbLineInfo ibLines={ibLines}></IbLineInfo>

      <Divider tw="!af-mb-0 af-pb-0">Adjustment</Divider>

      <div tw="af-flex">
        <RepriceCheckbox
          tw="af-ml-auto !af-text-xs"
          dataKey="metadata.isRepricing"
          initialValues={initialValues}
          hoverText={repriceCheckboxHoverText}
          disabled={!canReprice}
        ></RepriceCheckbox>
      </div>

      <ImproperPaymentUnits
        dataKey="auditFindingValues.improperPaymentUnitsCharged"
        initialValues={initialValues}
        maxUnits={adjustments?.maxUnits}
        formLayout={formLayout}
      ></ImproperPaymentUnits>

      {ibLinesAreEquivalent && isRepricing && (
        <>
          <BilledAmount
            dataKey="metadata.amountChargedOverride"
            initialValues={initialValues}
            label="Adj. Billed Amount"
            formLayout={formLayout}
          ></BilledAmount>

          <ImproperPaymentCost
            dataKey={"metadata.improperPaymentCostOverride"}
            label={"Adjustment Amount"}
            initialValues={initialValues}
            formLayout={formLayout}
            addlExtraJsx={
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-expect-error
              adjustments?.ibLineAdjustments![0].overrides?.isIPCOverride ? (
                <div>
                  <span tw="af-text-antd-lt-colorWarningText dark:af-text-antd-dk-colorWarningText">
                    {`${adjustments?.improperPaymentUnitsCharged} @ ${accounting.toFixed(
                      paymentRate * 100,
                      1,
                    )}% = ${accounting.formatMoney(
                      adjustments?.ibLineAdjustments[0].improperPaymentCost,
                    )}`}
                  </span>
                  <span
                    tw="af-pl-1 af-text-antd-lt-colorLink dark:af-text-antd-dk-colorLink"
                    onClick={() => {
                      setFields(
                        {
                          metadata: {
                            improperPaymentCostOverride:
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              adjustments?.ibLineAdjustments![0]
                                .improperPaymentCost,
                          },
                        },
                        true,
                      );
                    }}
                  >
                    [use]
                  </span>
                </div>
              ) : undefined
            }
          ></ImproperPaymentCost>
        </>
      )}

      <IbLineAdjustmentsInfo adjustments={adjustments}></IbLineAdjustmentsInfo>
    </div>
  );
};
