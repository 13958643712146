/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from "moment-timezone";

export const toDate = (date: any) =>
  date !== null && date !== undefined ? moment.utc(date) : "";

export const toFormattedDate = (date: any) => {
  const d = toDate(date);
  return d ? d.format("MM/DD/YY") : date;
};
