import { graphql } from "../../../gql";

export const askAutodorFileVectorsExistQuery = graphql(/* GraphQL */ `
  query askAutodorFileVectorsExistQuery(
    $input: AskAutodorFileVectorsExistInput
  ) {
    askAutodorFileVectorsExist(input: $input) {
      documentIds
    }
  }
`);
