/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: { input: any; output: any };
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: { input: any; output: any };
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any };
  /** The day, does not include a time. */
  Date: { input: any; output: any };
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: { input: any; output: any };
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any };
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: { input: any; output: any };
};

export type ActiveIbLinesView = {
  __typename?: "ActiveIbLinesView";
  alaRowId?: Maybe<Scalars["UUID"]["output"]>;
  batchClaimId?: Maybe<Scalars["UUID"]["output"]>;
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  dateOfService?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  groupCode?: Maybe<Scalars["String"]["output"]>;
  ibId?: Maybe<Scalars["UUID"]["output"]>;
  ibLineId?: Maybe<Scalars["UUID"]["output"]>;
  itemNumber?: Maybe<Scalars["String"]["output"]>;
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  procedureCode?: Maybe<Scalars["String"]["output"]>;
  procedureCodeModifier?: Maybe<Scalars["String"]["output"]>;
  revCode?: Maybe<Scalars["String"]["output"]>;
  s3InputSourceId?: Maybe<Scalars["UUID"]["output"]>;
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  units?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["Datetime"]["output"]>;
  versionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
};

export type ActiveIbLinesViewAggregates = {
  __typename?: "ActiveIbLinesViewAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ActiveIbLinesViewAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ActiveIbLinesViewDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ActiveIbLinesViewMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ActiveIbLinesViewMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ActiveIbLinesViewStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ActiveIbLinesViewStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ActiveIbLinesViewSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ActiveIbLinesViewVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ActiveIbLinesViewVarianceSampleAggregates>;
};

export type ActiveIbLinesViewAverageAggregates = {
  __typename?: "ActiveIbLinesViewAverageAggregates";
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `ActiveIbLinesView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ActiveIbLinesViewCondition = {
  /** Checks for equality with the object’s `alaRowId` field. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfService` field. */
  dateOfService?: InputMaybe<Scalars["Date"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `groupCode` field. */
  groupCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ibId` field. */
  ibId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `ibLineId` field. */
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `itemNumber` field. */
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `procedureCodeModifier` field. */
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revCode` field. */
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3InputSourceId` field. */
  s3InputSourceId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `tableNumber` field. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `totalCharge` field. */
  totalCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `units` field. */
  units?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `versionFingerprint` field. */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type ActiveIbLinesViewDistinctCountAggregates = {
  __typename?: "ActiveIbLinesViewDistinctCountAggregates";
  /** Distinct count of alaRowId across the matching connection */
  alaRowId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateOfService across the matching connection */
  dateOfService?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of groupCode across the matching connection */
  groupCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ibId across the matching connection */
  ibId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ibLineId across the matching connection */
  ibLineId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of itemNumber across the matching connection */
  itemNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureCode across the matching connection */
  procedureCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureCodeModifier across the matching connection */
  procedureCodeModifier?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revCode across the matching connection */
  revCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3InputSourceId across the matching connection */
  s3InputSourceId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of units across the matching connection */
  units?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of versionFingerprint across the matching connection */
  versionFingerprint?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ActiveIbLinesView` object types. All fields are combined with a logical ‘and.’ */
export type ActiveIbLinesViewFilter = {
  /** Filter by the object’s `alaRowId` field. */
  alaRowId?: InputMaybe<UUIDFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ActiveIbLinesViewFilter>>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfService` field. */
  dateOfService?: InputMaybe<DateFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `groupCode` field. */
  groupCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `ibId` field. */
  ibId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `ibLineId` field. */
  ibLineId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `itemNumber` field. */
  itemNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ActiveIbLinesViewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ActiveIbLinesViewFilter>>;
  /** Filter by the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `procedureCodeModifier` field. */
  procedureCodeModifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revCode` field. */
  revCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3InputSourceId` field. */
  s3InputSourceId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `tableNumber` field. */
  tableNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `totalCharge` field. */
  totalCharge?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `units` field. */
  units?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `versionFingerprint` field. */
  versionFingerprint?: InputMaybe<UUIDFilter>;
};

/** Grouping methods for `ActiveIbLinesView` for usage during aggregation. */
export enum ActiveIbLinesViewGroupBy {
  ALA_ROW_ID = "ALA_ROW_ID",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DATE_OF_SERVICE = "DATE_OF_SERVICE",
  DESCRIPTION = "DESCRIPTION",
  GROUP_CODE = "GROUP_CODE",
  IB_ID = "IB_ID",
  ITEM_NUMBER = "ITEM_NUMBER",
  LINE_NUMBER = "LINE_NUMBER",
  PAGE_NUMBER = "PAGE_NUMBER",
  PROCEDURE_CODE = "PROCEDURE_CODE",
  PROCEDURE_CODE_MODIFIER = "PROCEDURE_CODE_MODIFIER",
  REV_CODE = "REV_CODE",
  S3_INPUT_SOURCE_ID = "S3_INPUT_SOURCE_ID",
  TABLE_NUMBER = "TABLE_NUMBER",
  TOTAL_CHARGE = "TOTAL_CHARGE",
  UNITS = "UNITS",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
  VERSION_FINGERPRINT = "VERSION_FINGERPRINT",
}

export type ActiveIbLinesViewHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ActiveIbLinesView` aggregates. */
export type ActiveIbLinesViewHavingInput = {
  AND?: InputMaybe<Array<ActiveIbLinesViewHavingInput>>;
  OR?: InputMaybe<Array<ActiveIbLinesViewHavingInput>>;
  average?: InputMaybe<ActiveIbLinesViewHavingAverageInput>;
  distinctCount?: InputMaybe<ActiveIbLinesViewHavingDistinctCountInput>;
  max?: InputMaybe<ActiveIbLinesViewHavingMaxInput>;
  min?: InputMaybe<ActiveIbLinesViewHavingMinInput>;
  stddevPopulation?: InputMaybe<ActiveIbLinesViewHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ActiveIbLinesViewHavingStddevSampleInput>;
  sum?: InputMaybe<ActiveIbLinesViewHavingSumInput>;
  variancePopulation?: InputMaybe<ActiveIbLinesViewHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ActiveIbLinesViewHavingVarianceSampleInput>;
};

export type ActiveIbLinesViewHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfService?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  totalCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ActiveIbLinesViewMaxAggregates = {
  __typename?: "ActiveIbLinesViewMaxAggregates";
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

export type ActiveIbLinesViewMinAggregates = {
  __typename?: "ActiveIbLinesViewMinAggregates";
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

export type ActiveIbLinesViewStddevPopulationAggregates = {
  __typename?: "ActiveIbLinesViewStddevPopulationAggregates";
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type ActiveIbLinesViewStddevSampleAggregates = {
  __typename?: "ActiveIbLinesViewStddevSampleAggregates";
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type ActiveIbLinesViewSumAggregates = {
  __typename?: "ActiveIbLinesViewSumAggregates";
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars["BigInt"]["output"];
  /** Sum of pageNumber across the matching connection */
  pageNumber: Scalars["BigInt"]["output"];
  /** Sum of tableNumber across the matching connection */
  tableNumber: Scalars["BigInt"]["output"];
  /** Sum of totalCharge across the matching connection */
  totalCharge: Scalars["BigFloat"]["output"];
  /** Sum of units across the matching connection */
  units: Scalars["BigInt"]["output"];
};

export type ActiveIbLinesViewVariancePopulationAggregates = {
  __typename?: "ActiveIbLinesViewVariancePopulationAggregates";
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type ActiveIbLinesViewVarianceSampleAggregates = {
  __typename?: "ActiveIbLinesViewVarianceSampleAggregates";
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of totalCharge across the matching connection */
  totalCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `ActiveIbLinesView` values. */
export type ActiveIbLinesViewsConnection = {
  __typename?: "ActiveIbLinesViewsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ActiveIbLinesViewAggregates>;
  /** A list of edges which contains the `ActiveIbLinesView` and cursor to aid in pagination. */
  edges: Array<ActiveIbLinesViewsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ActiveIbLinesViewAggregates>>;
  /** A list of `ActiveIbLinesView` objects. */
  nodes: Array<Maybe<ActiveIbLinesView>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ActiveIbLinesView` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ActiveIbLinesView` values. */
export type ActiveIbLinesViewsConnectiongroupedAggregatesArgs = {
  groupBy: Array<ActiveIbLinesViewGroupBy>;
  having?: InputMaybe<ActiveIbLinesViewHavingInput>;
};

/** A `ActiveIbLinesView` edge in the connection. */
export type ActiveIbLinesViewsEdge = {
  __typename?: "ActiveIbLinesViewsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ActiveIbLinesView` at the end of the edge. */
  node?: Maybe<ActiveIbLinesView>;
};

/** Methods to use when ordering `ActiveIbLinesView`. */
export enum ActiveIbLinesViewsOrderBy {
  ALA_ROW_ID_ASC = "ALA_ROW_ID_ASC",
  ALA_ROW_ID_DESC = "ALA_ROW_ID_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DATE_OF_SERVICE_ASC = "DATE_OF_SERVICE_ASC",
  DATE_OF_SERVICE_DESC = "DATE_OF_SERVICE_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  GROUP_CODE_ASC = "GROUP_CODE_ASC",
  GROUP_CODE_DESC = "GROUP_CODE_DESC",
  IB_ID_ASC = "IB_ID_ASC",
  IB_ID_DESC = "IB_ID_DESC",
  IB_LINE_ID_ASC = "IB_LINE_ID_ASC",
  IB_LINE_ID_DESC = "IB_LINE_ID_DESC",
  ITEM_NUMBER_ASC = "ITEM_NUMBER_ASC",
  ITEM_NUMBER_DESC = "ITEM_NUMBER_DESC",
  LINE_NUMBER_ASC = "LINE_NUMBER_ASC",
  LINE_NUMBER_DESC = "LINE_NUMBER_DESC",
  NATURAL = "NATURAL",
  PAGE_NUMBER_ASC = "PAGE_NUMBER_ASC",
  PAGE_NUMBER_DESC = "PAGE_NUMBER_DESC",
  PROCEDURE_CODE_ASC = "PROCEDURE_CODE_ASC",
  PROCEDURE_CODE_DESC = "PROCEDURE_CODE_DESC",
  PROCEDURE_CODE_MODIFIER_ASC = "PROCEDURE_CODE_MODIFIER_ASC",
  PROCEDURE_CODE_MODIFIER_DESC = "PROCEDURE_CODE_MODIFIER_DESC",
  REV_CODE_ASC = "REV_CODE_ASC",
  REV_CODE_DESC = "REV_CODE_DESC",
  S3_INPUT_SOURCE_ID_ASC = "S3_INPUT_SOURCE_ID_ASC",
  S3_INPUT_SOURCE_ID_DESC = "S3_INPUT_SOURCE_ID_DESC",
  TABLE_NUMBER_ASC = "TABLE_NUMBER_ASC",
  TABLE_NUMBER_DESC = "TABLE_NUMBER_DESC",
  TOTAL_CHARGE_ASC = "TOTAL_CHARGE_ASC",
  TOTAL_CHARGE_DESC = "TOTAL_CHARGE_DESC",
  UNITS_ASC = "UNITS_ASC",
  UNITS_DESC = "UNITS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  VERSION_FINGERPRINT_ASC = "VERSION_FINGERPRINT_ASC",
  VERSION_FINGERPRINT_DESC = "VERSION_FINGERPRINT_DESC",
}

export type ActiveStatistics = {
  __typename?: "ActiveStatistics";
  allowedAmount: FloatStat;
  billedAmount: FloatStat;
  claimsAssigned: IntStat;
  claimsAwaitingDocumentation: IntStat;
  claimsCompleted: IntStat;
  claimsInProgress: IntStat;
  claimsUnderReview: IntStat;
  claimsWithProviderSignOff: IntStat;
  determinedClientSavings: FloatStat;
  determinedDiscrepantAmount: FloatStat;
  numberOfLives: IntStat;
  potentialClientSavings: FloatStat;
  potentialDiscrepantAmount: FloatStat;
  savingsPerLife: FloatStat;
};

export type Aggregate = {
  __typename?: "Aggregate";
  count?: Maybe<Scalars["Int"]["output"]>;
  sum?: Maybe<Scalars["Float"]["output"]>;
};

export type AskAutodorCreateSavedPromptInput = {
  prompt: Scalars["String"]["input"];
  tags: Array<Scalars["String"]["input"]>;
};

export type AskAutodorCreateSavedPromptResponse = {
  __typename?: "AskAutodorCreateSavedPromptResponse";
  savedPromptId: Scalars["UUID"]["output"];
};

export type AskAutodorDeleteSavedPromptInput = {
  promptId: Scalars["UUID"]["input"];
};

export type AskAutodorFileInput = {
  documentId: Scalars["UUID"]["input"];
  prompt: Scalars["String"]["input"];
};

export type AskAutodorFileResponse = {
  __typename?: "AskAutodorFileResponse";
  pages?: Maybe<Array<Maybe<AskAutodorMatchedText>>>;
  prompt: Scalars["String"]["output"];
  response?: Maybe<Scalars["String"]["output"]>;
  responseId: Scalars["UUID"]["output"];
};

export type AskAutodorFileVectorsExistInput = {
  documentIds: Array<Scalars["UUID"]["input"]>;
};

export type AskAutodorFileVectorsExistResponse = {
  __typename?: "AskAutodorFileVectorsExistResponse";
  documentIds: Array<Maybe<Scalars["UUID"]["output"]>>;
};

export type AskAutodorGetSavedPromptsInput = {
  tags: Array<Scalars["String"]["input"]>;
};

export type AskAutodorMatchedText = {
  __typename?: "AskAutodorMatchedText";
  confidence?: Maybe<Scalars["Float"]["output"]>;
  documentId: Scalars["UUID"]["output"];
  documentPageId?: Maybe<Scalars["UUID"]["output"]>;
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  textMatched?: Maybe<Scalars["String"]["output"]>;
};

export type AskAutodorQueue = {
  __typename?: "AskAutodorQueue";
  cases: Array<Case>;
  createdAt: Scalars["String"]["output"];
  files: Array<File>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  summary: AskAutodorQueueSummary;
};

export type AskAutodorQueueSummary = {
  __typename?: "AskAutodorQueueSummary";
  caseCount: Scalars["Int"]["output"];
  completedCaseCount: Scalars["Int"]["output"];
  fileCount: Scalars["Int"]["output"];
  openCaseCount: Scalars["Int"]["output"];
};

export type AskAutodorQueuesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  cursor?: InputMaybe<Scalars["Cursor"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AskAutodorQueuesOrdering>>;
};

export type AskAutodorQueuesConnection = {
  __typename?: "AskAutodorQueuesConnection";
  edges: Array<AskAutodorQueuesEdge>;
  nodes: Array<AskAutodorQueue>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AskAutodorQueuesEdge = {
  __typename?: "AskAutodorQueuesEdge";
  cursor: Scalars["Cursor"]["output"];
  node: AskAutodorQueue;
};

export enum AskAutodorQueuesOrderField {
  CREATED_AT = "CREATED_AT",
  ID = "ID",
  NAME = "NAME",
}

export type AskAutodorQueuesOrdering = {
  direction?: SortDirection;
  field: AskAutodorQueuesOrderField;
};

export type AskAutodorRateResponseInput = {
  rating: Scalars["Int"]["input"];
  responseId: Scalars["UUID"]["input"];
};

export type AskAutodorRateResponseResponse = {
  __typename?: "AskAutodorRateResponseResponse";
  responseRatingId: Scalars["UUID"]["output"];
};

export type AskAutodorSavedPrompt = {
  __typename?: "AskAutodorSavedPrompt";
  authorId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  isDeleted: Scalars["Boolean"]["output"];
  prompt: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AskAutodorSavedPromptsNewTagsInput = {
  promptId: Scalars["UUID"]["input"];
  tags: Array<Scalars["String"]["input"]>;
};

export type AuditDocumentation = {
  __typename?: "AuditDocumentation";
  fileName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  process: AuditDocumentationProcessTypes;
  signedUrl: Scalars["String"]["output"];
  size: Scalars["Int"]["output"];
  subFile?: Maybe<Scalars["String"]["output"]>;
};

export enum AuditDocumentationProcessTypes {
  ANNOTATED = "ANNOTATED",
  OCR = "OCR",
  ORIGINAL = "ORIGINAL",
}

export type AuditFinding = Node & {
  __typename?: "AuditFinding";
  accepted?: Maybe<Scalars["Boolean"]["output"]>;
  /** Reads a single `AuditFindingJob` that is related to this `AuditFinding`. */
  auditFindingJob?: Maybe<AuditFindingJob>;
  auditFindingJobId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `AuditFindingReasonCode`. */
  auditFindingReasonCodes: AuditFindingReasonCodesConnection;
  /** Reads a single `AuditFindingReview` that is related to this `AuditFinding`. */
  auditFindingReviewByReviewId?: Maybe<AuditFindingReview>;
  /** Reads and enables pagination through a set of `AuditFindingReview`. */
  auditFindingReviewsByOriginalId: AuditFindingReviewsConnection;
  /**
   * Reads and enables pagination through a set of `AuditFindingReview`.
   * @deprecated Please use auditFindingReviewByReviewId instead
   */
  auditFindingReviewsByReviewId: AuditFindingReviewsConnection;
  auditFindingRuleType: Scalars["String"]["output"];
  /** Reads a single `AuditFindingRuleType` that is related to this `AuditFinding`. */
  auditFindingRuleTypeByAuditFindingRuleType?: Maybe<AuditFindingRuleType>;
  auditFindingSeedType: AuditFindingSeedType;
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindingsByPreviousVersionId: AuditFindingsConnection;
  auditType?: Maybe<AuditTypeEnum>;
  auditorComment?: Maybe<Scalars["String"]["output"]>;
  auditorReviewed: Scalars["Boolean"]["output"];
  /** Reads a single `AuditorUser` that is related to this `AuditFinding`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: Maybe<Scalars["UUID"]["output"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `BatchClaim` that is related to this `AuditFinding`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaimLine` that is related to this `AuditFinding`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  batchClaimLineId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchClaimLineItemAudit` that is related to this `AuditFinding`. */
  batchClaimLineItemAudit?: Maybe<BatchClaimLineItemAudit>;
  /**
   * Reads and enables pagination through a set of `BatchClaimLineItemAudit`.
   * @deprecated Please use batchClaimLineItemAudit instead
   */
  batchClaimLineItemAudits: BatchClaimLineItemAuditsConnection;
  confidence: Scalars["BigFloat"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  /**
   * ### Overview
   * Determines whether a finding has been explicitly declined. If a finding has not been reviewed, this will return false.
   */
  declined?: Maybe<Scalars["Boolean"]["output"]>;
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["UUID"]["output"];
  improperPaymentCost: Scalars["Float"]["output"];
  improperPaymentReason: Scalars["String"]["output"];
  improperPaymentUnitsCharged: Scalars["Int"]["output"];
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isOriginal?: Maybe<Scalars["Boolean"]["output"]>;
  isValid?: Maybe<Scalars["Boolean"]["output"]>;
  latestReview?: Maybe<ReviewedAuditFinding>;
  metaDataAocPrimaryCode?: Maybe<Scalars["String"]["output"]>;
  metaDataEmCode?: Maybe<Scalars["String"]["output"]>;
  metaDataEmCorrectLevel?: Maybe<Scalars["Int"]["output"]>;
  metaDataEmOriginalLevel?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciPtpCode?: Maybe<Scalars["String"]["output"]>;
  metaDataNcciPtpModifier?: Maybe<Scalars["Int"]["output"]>;
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  metaDataUnitsCorrectUnits?: Maybe<Scalars["Int"]["output"]>;
  needsReview: Scalars["Boolean"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `AuditFinding` that is related to this `AuditFinding`. */
  previousVersion?: Maybe<AuditFinding>;
  previousVersionId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `ReportedFinding`. */
  reportedFindings: ReportedFindingsConnection;
  /** Reads and enables pagination through a set of `ReviewedAuditFinding`. */
  reviewedAuditFindings: ReviewedAuditFindingsConnection;
  superseded?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditFindingauditFindingReasonCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReasonCodeCondition>;
  filter?: InputMaybe<AuditFindingReasonCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
};

export type AuditFindingauditFindingReviewsByOriginalIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReviewCondition>;
  filter?: InputMaybe<AuditFindingReviewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReviewsOrderBy>>;
};

export type AuditFindingauditFindingReviewsByReviewIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReviewCondition>;
  filter?: InputMaybe<AuditFindingReviewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReviewsOrderBy>>;
};

export type AuditFindingauditFindingsByPreviousVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

export type AuditFindingbatchClaimLineItemAuditsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemAuditCondition>;
  filter?: InputMaybe<BatchClaimLineItemAuditFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemAuditsOrderBy>>;
};

export type AuditFindingreportedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedFindingCondition>;
  filter?: InputMaybe<ReportedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedFindingsOrderBy>>;
};

export type AuditFindingreviewedAuditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReviewedAuditFindingCondition>;
  filter?: InputMaybe<ReviewedAuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReviewedAuditFindingsOrderBy>>;
};

export type AuditFindingAggregates = {
  __typename?: "AuditFindingAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AuditFindingAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AuditFindingMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AuditFindingMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AuditFindingStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AuditFindingStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AuditFindingSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AuditFindingVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AuditFindingVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `AuditFinding` object types. */
export type AuditFindingAggregatesFilter = {
  /** Mean average aggregate over matching `AuditFinding` objects. */
  average?: InputMaybe<AuditFindingAverageAggregateFilter>;
  /** Distinct count aggregate over matching `AuditFinding` objects. */
  distinctCount?: InputMaybe<AuditFindingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditFinding` object to be included within the aggregate. */
  filter?: InputMaybe<AuditFindingFilter>;
  /** Maximum aggregate over matching `AuditFinding` objects. */
  max?: InputMaybe<AuditFindingMaxAggregateFilter>;
  /** Minimum aggregate over matching `AuditFinding` objects. */
  min?: InputMaybe<AuditFindingMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `AuditFinding` objects. */
  stddevPopulation?: InputMaybe<AuditFindingStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `AuditFinding` objects. */
  stddevSample?: InputMaybe<AuditFindingStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `AuditFinding` objects. */
  sum?: InputMaybe<AuditFindingSumAggregateFilter>;
  /** Population variance aggregate over matching `AuditFinding` objects. */
  variancePopulation?: InputMaybe<AuditFindingVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `AuditFinding` objects. */
  varianceSample?: InputMaybe<AuditFindingVarianceSampleAggregateFilter>;
};

/** The fields on `auditFinding` to look up the row to connect. */
export type AuditFindingAuditFindingClaimLinePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingAuditFindingRuleTypeFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditFindingRuleType` in the `AuditFindingInput` mutation. */
export type AuditFindingAuditFindingRuleTypeFkeyInput = {
  /** The primary key(s) for `auditFindingRuleType` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingRuleTypeNodeIdConnect>;
  /** The primary key(s) for `auditFindingRuleType` for the far side of the relationship. */
  connectByType?: InputMaybe<AuditFindingRuleTypeAuditFindingRuleTypePkeyConnect>;
  /** The primary key(s) and patch data for `auditFindingRuleType` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `auditFindingRuleType` for the far side of the relationship. */
  updateByType?: InputMaybe<AuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyUsingAuditFindingRuleTypePkeyUpdate>;
};

/** Input for the nested mutation of `auditFinding` in the `AuditFindingRuleTypeInput` mutation. */
export type AuditFindingAuditFindingRuleTypeFkeyInverseInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingAuditFindingClaimLinePkeyConnect>>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingNodeIdConnect>>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingAuditFindingRuleTypeFkeyAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyUsingAuditFindingClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyNodeIdUpdate>
  >;
};

export type AuditFindingAverageAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigFloatFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigFloatFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigFloatFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigFloatFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingAverageAggregates = {
  __typename?: "AuditFindingAverageAggregates";
  /** Mean average of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingClaimLineAuditFindingJobIdFkeyAuditFindingCreateInput =
  {
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType: AuditFindingSeedType;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    improperPaymentCost: Scalars["Float"]["input"];
    improperPaymentReason: Scalars["String"]["input"];
    improperPaymentUnitsCharged: Scalars["Int"]["input"];
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview: Scalars["Boolean"]["input"];
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditFindingJob` in the `AuditFindingInput` mutation. */
export type AuditFindingClaimLineAuditFindingJobIdFkeyInput = {
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingJobAuditFindingJobPkeyConnect>;
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingJobNodeIdConnect>;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyUsingAuditFindingJobPkeyUpdate>;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFinding` in the `AuditFindingJobInput` mutation. */
export type AuditFindingClaimLineAuditFindingJobIdFkeyInverseInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingAuditFindingClaimLinePkeyConnect>>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingNodeIdConnect>>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingClaimLineAuditFindingJobIdFkeyAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyUsingAuditFindingClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyNodeIdUpdate>
  >;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingClaimLineAuthorIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditorUser` to be created by this mutation. */
export type AuditFindingClaimLineAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingInput` mutation. */
export type AuditFindingClaimLineAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFinding` in the `AuditorUserInput` mutation. */
export type AuditFindingClaimLineAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingAuditFindingClaimLinePkeyConnect>>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingNodeIdConnect>>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingClaimLineAuthorIdFkeyAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditFindingClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingClaimLineBatchClaimIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `AuditFindingInput` mutation. */
export type AuditFindingClaimLineBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFinding` in the `BatchClaimInput` mutation. */
export type AuditFindingClaimLineBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingAuditFindingClaimLinePkeyConnect>>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingNodeIdConnect>>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingClaimLineBatchClaimIdFkeyAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingAuditFindingClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyNodeIdUpdate>
  >;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingClaimLineBatchClaimLineIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `batchClaimLine` in the `AuditFindingInput` mutation. */
export type AuditFindingClaimLineBatchClaimLineIdFkeyInput = {
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineBatchClaimLinePkeyConnect>;
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyUsingBatchClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFinding` in the `BatchClaimLineInput` mutation. */
export type AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingAuditFindingClaimLinePkeyConnect>>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingNodeIdConnect>>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingClaimLineBatchClaimLineIdFkeyAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyUsingAuditFindingClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyNodeIdUpdate>
  >;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingClaimLinePreviousVersionIdFkeyAuditFindingCreateInput =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType: AuditFindingSeedType;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    improperPaymentCost: Scalars["Float"]["input"];
    improperPaymentReason: Scalars["String"]["input"];
    improperPaymentUnitsCharged: Scalars["Int"]["input"];
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview: Scalars["Boolean"]["input"];
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditFinding` in the `AuditFindingInput` mutation. */
export type AuditFindingClaimLinePreviousVersionIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyNodeIdUpdate>;
};

export enum AuditFindingClaimStatus {
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
  CREATED = "CREATED",
  DOCUMENTATION_REQUESTED = "DOCUMENTATION_REQUESTED",
  DOCUMENTATION_REQUEST_COMPLETE = "DOCUMENTATION_REQUEST_COMPLETE",
  DOCUMENTATION_TO_REQUEST = "DOCUMENTATION_TO_REQUEST",
  IN_PROGRESS = "IN_PROGRESS",
}

/** A filter to be used against AuditFindingClaimStatus fields. All fields are combined with a logical ‘and.’ */
export type AuditFindingClaimStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AuditFindingClaimStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AuditFindingClaimStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AuditFindingClaimStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AuditFindingClaimStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AuditFindingClaimStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AuditFindingClaimStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AuditFindingClaimStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AuditFindingClaimStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AuditFindingClaimStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AuditFindingClaimStatus>>;
};

/**
 * A condition to be used against `AuditFinding` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingCondition = {
  /** Checks for equality with the object’s `auditFindingJobId` field. */
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `auditFindingRuleType` field. */
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `auditFindingSeedType` field. */
  auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
  /** Checks for equality with the object’s `auditType` field. */
  auditType?: InputMaybe<AuditTypeEnum>;
  /** Checks for equality with the object’s `auditorComment` field. */
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `auditorReviewed` field. */
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `autodorFindingId` field. */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `autodorFindingS3Key` field. */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimLineId` field. */
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `confidence` field. */
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `improperPaymentCost` field. */
  improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `improperPaymentReason` field. */
  improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `improperPaymentUnitsCharged` field. */
  improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataAocPrimaryCode` field. */
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `metaDataEmCode` field. */
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `metaDataEmCorrectLevel` field. */
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataEmOriginalLevel` field. */
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataNcciMueAjudicationValue` field. */
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataNcciMueOhpMueValue` field. */
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataNcciPtpCode` field. */
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `metaDataNcciPtpModifier` field. */
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `metaDataPricingCorrectPrice` field. */
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `metaDataUnitsCorrectUnits` field. */
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `needsReview` field. */
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `previousVersionId` field. */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingDistinctCountAggregateFilter = {
  accepted?: InputMaybe<BooleanFilter>;
  auditFindingJobId?: InputMaybe<BigIntFilter>;
  auditFindingRuleType?: InputMaybe<BigIntFilter>;
  auditFindingSeedType?: InputMaybe<BigIntFilter>;
  auditType?: InputMaybe<BigIntFilter>;
  auditorComment?: InputMaybe<BigIntFilter>;
  auditorReviewed?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  autodorFindingId?: InputMaybe<BigIntFilter>;
  autodorFindingS3Key?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  batchClaimLineId?: InputMaybe<BigIntFilter>;
  confidence?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  declined?: InputMaybe<BooleanFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  id?: InputMaybe<BigIntFilter>;
  improperPaymentCost?: InputMaybe<BigIntFilter>;
  improperPaymentReason?: InputMaybe<BigIntFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigIntFilter>;
  isActive?: InputMaybe<BooleanFilter>;
  isOriginal?: InputMaybe<BooleanFilter>;
  isValid?: InputMaybe<BooleanFilter>;
  metaDataAocPrimaryCode?: InputMaybe<BigIntFilter>;
  metaDataEmCode?: InputMaybe<BigIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigIntFilter>;
  metaDataNcciPtpCode?: InputMaybe<BigIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigIntFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigIntFilter>;
  needsReview?: InputMaybe<BigIntFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
  previousVersionId?: InputMaybe<BigIntFilter>;
  superseded?: InputMaybe<BooleanFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditFindingDistinctCountAggregates = {
  __typename?: "AuditFindingDistinctCountAggregates";
  /** Distinct count of this field across the matching connection. */
  accepted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingJobId across the matching connection */
  auditFindingJobId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingRuleType across the matching connection */
  auditFindingRuleType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingSeedType across the matching connection */
  auditFindingSeedType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditType across the matching connection */
  auditType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditorComment across the matching connection */
  auditorComment?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditorReviewed across the matching connection */
  auditorReviewed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of autodorFindingId across the matching connection */
  autodorFindingId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of autodorFindingS3Key across the matching connection */
  autodorFindingS3Key?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimLineId across the matching connection */
  batchClaimLineId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Determines whether a finding has been explicitly declined. If a finding has not been reviewed, this will return false.
   */
  declined?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of improperPaymentReason across the matching connection */
  improperPaymentReason?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  isActive?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  isOriginal?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  isValid?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  latestReview?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataAocPrimaryCode across the matching connection */
  metaDataAocPrimaryCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataEmCode across the matching connection */
  metaDataEmCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataNcciPtpCode across the matching connection */
  metaDataNcciPtpCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of needsReview across the matching connection */
  needsReview?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of previousVersionId across the matching connection */
  previousVersionId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  superseded?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingFilter = {
  /** Filter by the object’s `accepted` field. */
  accepted?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingFilter>>;
  /** Filter by the object’s `auditFindingJob` relation. */
  auditFindingJob?: InputMaybe<AuditFindingJobFilter>;
  /** A related `auditFindingJob` exists. */
  auditFindingJobExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditFindingJobId` field. */
  auditFindingJobId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `auditFindingReasonCodes` relation. */
  auditFindingReasonCodes?: InputMaybe<AuditFindingToManyAuditFindingReasonCodeFilter>;
  /** Some related `auditFindingReasonCodes` exist. */
  auditFindingReasonCodesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditFindingReviewByReviewId` relation. */
  auditFindingReviewByReviewId?: InputMaybe<AuditFindingReviewFilter>;
  /** A related `auditFindingReviewByReviewId` exists. */
  auditFindingReviewByReviewIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditFindingReviewsByOriginalId` relation. */
  auditFindingReviewsByOriginalId?: InputMaybe<AuditFindingToManyAuditFindingReviewFilter>;
  /** Some related `auditFindingReviewsByOriginalId` exist. */
  auditFindingReviewsByOriginalIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `auditFindingRuleType` field. */
  auditFindingRuleType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `auditFindingRuleTypeByAuditFindingRuleType` relation. */
  auditFindingRuleTypeByAuditFindingRuleType?: InputMaybe<AuditFindingRuleTypeFilter>;
  /** Filter by the object’s `auditFindingSeedType` field. */
  auditFindingSeedType?: InputMaybe<AuditFindingSeedTypeFilter>;
  /** Filter by the object’s `auditFindingsByPreviousVersionId` relation. */
  auditFindingsByPreviousVersionId?: InputMaybe<AuditFindingToManyAuditFindingFilter>;
  /** Some related `auditFindingsByPreviousVersionId` exist. */
  auditFindingsByPreviousVersionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `auditType` field. */
  auditType?: InputMaybe<AuditTypeEnumFilter>;
  /** Filter by the object’s `auditorComment` field. */
  auditorComment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `auditorReviewed` field. */
  auditorReviewed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `autodorFindingId` field. */
  autodorFindingId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `autodorFindingS3Key` field. */
  autodorFindingS3Key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLine` relation. */
  batchClaimLine?: InputMaybe<BatchClaimLineFilter>;
  /** A related `batchClaimLine` exists. */
  batchClaimLineExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimLineId` field. */
  batchClaimLineId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineItemAudit` relation. */
  batchClaimLineItemAudit?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** A related `batchClaimLineItemAudit` exists. */
  batchClaimLineItemAuditExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `confidence` field. */
  confidence?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `declined` field. */
  declined?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `determinedClientSavings` field. */
  determinedClientSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `discrepantAmount` field. */
  discrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `improperPaymentCost` field. */
  improperPaymentCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `improperPaymentReason` field. */
  improperPaymentReason?: InputMaybe<StringFilter>;
  /** Filter by the object’s `improperPaymentUnitsCharged` field. */
  improperPaymentUnitsCharged?: InputMaybe<IntFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOriginal` field. */
  isOriginal?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isValid` field. */
  isValid?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `metaDataAocPrimaryCode` field. */
  metaDataAocPrimaryCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaDataEmCode` field. */
  metaDataEmCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaDataEmCorrectLevel` field. */
  metaDataEmCorrectLevel?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metaDataEmOriginalLevel` field. */
  metaDataEmOriginalLevel?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metaDataNcciMueAjudicationValue` field. */
  metaDataNcciMueAjudicationValue?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metaDataNcciMueOhpMueValue` field. */
  metaDataNcciMueOhpMueValue?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metaDataNcciPtpCode` field. */
  metaDataNcciPtpCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `metaDataNcciPtpModifier` field. */
  metaDataNcciPtpModifier?: InputMaybe<IntFilter>;
  /** Filter by the object’s `metaDataPricingCorrectPrice` field. */
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `metaDataUnitsCorrectUnits` field. */
  metaDataUnitsCorrectUnits?: InputMaybe<IntFilter>;
  /** Filter by the object’s `needsReview` field. */
  needsReview?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingFilter>>;
  /** Filter by the object’s `potentialClientSavings` field. */
  potentialClientSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `previousVersion` relation. */
  previousVersion?: InputMaybe<AuditFindingFilter>;
  /** A related `previousVersion` exists. */
  previousVersionExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `previousVersionId` field. */
  previousVersionId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `reportedFindings` relation. */
  reportedFindings?: InputMaybe<AuditFindingToManyReportedFindingFilter>;
  /** Some related `reportedFindings` exist. */
  reportedFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `reviewedAuditFindings` relation. */
  reviewedAuditFindings?: InputMaybe<AuditFindingToManyReviewedAuditFindingFilter>;
  /** Some related `reviewedAuditFindings` exist. */
  reviewedAuditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `superseded` field. */
  superseded?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditFinding` for usage during aggregation. */
export enum AuditFindingGroupBy {
  AUDITOR_COMMENT = "AUDITOR_COMMENT",
  AUDITOR_REVIEWED = "AUDITOR_REVIEWED",
  AUDIT_FINDING_JOB_ID = "AUDIT_FINDING_JOB_ID",
  AUDIT_FINDING_RULE_TYPE = "AUDIT_FINDING_RULE_TYPE",
  AUDIT_FINDING_SEED_TYPE = "AUDIT_FINDING_SEED_TYPE",
  AUDIT_TYPE = "AUDIT_TYPE",
  AUTHOR_ID = "AUTHOR_ID",
  AUTODOR_FINDING_ID = "AUTODOR_FINDING_ID",
  AUTODOR_FINDING_S3_KEY = "AUTODOR_FINDING_S3_KEY",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  BATCH_CLAIM_LINE_ID = "BATCH_CLAIM_LINE_ID",
  CONFIDENCE = "CONFIDENCE",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  IMPROPER_PAYMENT_COST = "IMPROPER_PAYMENT_COST",
  IMPROPER_PAYMENT_REASON = "IMPROPER_PAYMENT_REASON",
  IMPROPER_PAYMENT_UNITS_CHARGED = "IMPROPER_PAYMENT_UNITS_CHARGED",
  META_DATA_AOC_PRIMARY_CODE = "META_DATA_AOC_PRIMARY_CODE",
  META_DATA_EM_CODE = "META_DATA_EM_CODE",
  META_DATA_EM_CORRECT_LEVEL = "META_DATA_EM_CORRECT_LEVEL",
  META_DATA_EM_ORIGINAL_LEVEL = "META_DATA_EM_ORIGINAL_LEVEL",
  META_DATA_NCCI_MUE_AJUDICATION_VALUE = "META_DATA_NCCI_MUE_AJUDICATION_VALUE",
  META_DATA_NCCI_MUE_OHP_MUE_VALUE = "META_DATA_NCCI_MUE_OHP_MUE_VALUE",
  META_DATA_NCCI_PTP_CODE = "META_DATA_NCCI_PTP_CODE",
  META_DATA_NCCI_PTP_MODIFIER = "META_DATA_NCCI_PTP_MODIFIER",
  META_DATA_PRICING_CORRECT_PRICE = "META_DATA_PRICING_CORRECT_PRICE",
  META_DATA_UNITS_CORRECT_UNITS = "META_DATA_UNITS_CORRECT_UNITS",
  NEEDS_REVIEW = "NEEDS_REVIEW",
  PREVIOUS_VERSION_ID = "PREVIOUS_VERSION_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditFindingHavingAverageAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingAverageAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingAverageAuditFindingPotentialClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingAverageInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingAverageAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingAverageAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingAverageAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingDistinctCountAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingDistinctCountAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingDistinctCountAuditFindingPotentialClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingDistinctCountInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingDistinctCountAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingDistinctCountAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingDistinctCountAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditFinding` aggregates. */
export type AuditFindingHavingInput = {
  AND?: InputMaybe<Array<AuditFindingHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingHavingInput>>;
  average?: InputMaybe<AuditFindingHavingAverageInput>;
  distinctCount?: InputMaybe<AuditFindingHavingDistinctCountInput>;
  max?: InputMaybe<AuditFindingHavingMaxInput>;
  min?: InputMaybe<AuditFindingHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditFindingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditFindingHavingStddevSampleInput>;
  sum?: InputMaybe<AuditFindingHavingSumInput>;
  variancePopulation?: InputMaybe<AuditFindingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditFindingHavingVarianceSampleInput>;
};

export type AuditFindingHavingMaxAuditFindingDeterminedClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMaxAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMaxAuditFindingPotentialClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMaxInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingMaxAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingMaxAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingMaxAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingMinAuditFindingDeterminedClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMinAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMinAuditFindingPotentialClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingMinInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingMinAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingMinAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingMinAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingStddevPopulationAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingStddevPopulationAuditFindingDiscrepantAmountInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingStddevPopulationAuditFindingPotentialClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingStddevPopulationInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingStddevPopulationAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingStddevPopulationAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingStddevPopulationAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingStddevSampleAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingStddevSampleAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingStddevSampleAuditFindingPotentialClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingStddevSampleInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingStddevSampleAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingStddevSampleAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingStddevSampleAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingSumAuditFindingDeterminedClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingSumAuditFindingDiscrepantAmountInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingSumAuditFindingPotentialClientSavingsInput = {
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditFindingHavingSumInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingSumAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingSumAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingSumAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingVariancePopulationAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVariancePopulationAuditFindingDiscrepantAmountInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVariancePopulationAuditFindingPotentialClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVariancePopulationInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingVariancePopulationAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingVariancePopulationAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingVariancePopulationAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingHavingVarianceSampleAuditFindingDeterminedClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVarianceSampleAuditFindingDiscrepantAmountInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVarianceSampleAuditFindingPotentialClientSavingsInput =
  {
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditFindingHavingVarianceSampleInput = {
  confidence?: InputMaybe<HavingBigfloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  determinedClientSavings?: InputMaybe<AuditFindingHavingVarianceSampleAuditFindingDeterminedClientSavingsInput>;
  discrepantAmount?: InputMaybe<AuditFindingHavingVarianceSampleAuditFindingDiscrepantAmountInput>;
  improperPaymentCost?: InputMaybe<HavingFloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<HavingIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<HavingIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<HavingIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<HavingIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<HavingBigfloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<HavingIntFilter>;
  potentialClientSavings?: InputMaybe<AuditFindingHavingVarianceSampleAuditFindingPotentialClientSavingsInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AuditFinding` */
export type AuditFindingInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingJob = Node & {
  __typename?: "AuditFindingJob";
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindings: AuditFindingsConnection;
  /** Reads a single `AuditorUser` that is related to this `AuditFindingJob`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchFile` that is related to this `AuditFindingJob`. */
  batchFile?: Maybe<BatchFile>;
  batchFileId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditFindingJobauditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

export type AuditFindingJobAggregates = {
  __typename?: "AuditFindingJobAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingJobDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditFindingJob` object types. */
export type AuditFindingJobAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditFindingJob` objects. */
  distinctCount?: InputMaybe<AuditFindingJobDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditFindingJob` object to be included within the aggregate. */
  filter?: InputMaybe<AuditFindingJobFilter>;
};

/** The fields on `auditFindingJob` to look up the row to connect. */
export type AuditFindingJobAuditFindingJobPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingJobInput` mutation. */
export type AuditFindingJobAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingJob` in the `AuditorUserInput` mutation. */
export type AuditFindingJobAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingJobAuditFindingJobPkeyConnect>>;
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingJobNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditFindingJobPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyNodeIdUpdate>
  >;
};

/** Input for the nested mutation of `batchFile` in the `AuditFindingJobInput` mutation. */
export type AuditFindingJobBatchFileIdFkeyInput = {
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectById?: InputMaybe<BatchFileBatchFilePkeyConnect>;
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchFileNodeIdConnect>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateById?: InputMaybe<BatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyUsingBatchFilePkeyUpdate>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingJob` in the `BatchFileInput` mutation. */
export type AuditFindingJobBatchFileIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditFindingJobAuditFindingJobPkeyConnect>>;
  /** The primary key(s) for `auditFindingJob` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingJobNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyUsingAuditFindingJobPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingJob` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `AuditFindingJob` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingJobCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchFileId` field. */
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingJobDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  batchFileId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditFindingJobDistinctCountAggregates = {
  __typename?: "AuditFindingJobDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchFileId across the matching connection */
  batchFileId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFindingJob` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingJobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingJobFilter>>;
  /** Filter by the object’s `auditFindings` relation. */
  auditFindings?: InputMaybe<AuditFindingJobToManyAuditFindingFilter>;
  /** Some related `auditFindings` exist. */
  auditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchFile` relation. */
  batchFile?: InputMaybe<BatchFileFilter>;
  /** Filter by the object’s `batchFileId` field. */
  batchFileId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingJobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingJobFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditFindingJob` for usage during aggregation. */
export enum AuditFindingJobGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_FILE_ID = "BATCH_FILE_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditFindingJobHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditFindingJob` aggregates. */
export type AuditFindingJobHavingInput = {
  AND?: InputMaybe<Array<AuditFindingJobHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingJobHavingInput>>;
  average?: InputMaybe<AuditFindingJobHavingAverageInput>;
  distinctCount?: InputMaybe<AuditFindingJobHavingDistinctCountInput>;
  max?: InputMaybe<AuditFindingJobHavingMaxInput>;
  min?: InputMaybe<AuditFindingJobHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditFindingJobHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditFindingJobHavingStddevSampleInput>;
  sum?: InputMaybe<AuditFindingJobHavingSumInput>;
  variancePopulation?: InputMaybe<AuditFindingJobHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditFindingJobHavingVarianceSampleInput>;
};

export type AuditFindingJobHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingJobHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingJobNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFindingJob` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFindingJob` to look up the row to update. */
export type AuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyUsingAuditFindingJobPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: updateAuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFindingJob` to look up the row to update. */
export type AuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditFindingJobPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: updateAuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchFile` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchFile` being updated. */
    patch: BatchFilePatch;
  };

/** The fields on `auditFindingJob` to look up the row to update. */
export type AuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyUsingAuditFindingJobPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: updateAuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyPatch;
  };

/** Represents an update to a `AuditFindingJob`. Fields that are set will be updated. */
export type AuditFindingJobPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingJobAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInput>;
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingJobToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A connection to a list of `AuditFindingJob` values. */
export type AuditFindingJobsConnection = {
  __typename?: "AuditFindingJobsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingJobAggregates>;
  /** A list of edges which contains the `AuditFindingJob` and cursor to aid in pagination. */
  edges: Array<AuditFindingJobsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingJobAggregates>>;
  /** A list of `AuditFindingJob` objects. */
  nodes: Array<Maybe<AuditFindingJob>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFindingJob` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFindingJob` values. */
export type AuditFindingJobsConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingJobGroupBy>;
  having?: InputMaybe<AuditFindingJobHavingInput>;
};

/** A `AuditFindingJob` edge in the connection. */
export type AuditFindingJobsEdge = {
  __typename?: "AuditFindingJobsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFindingJob` at the end of the edge. */
  node?: Maybe<AuditFindingJob>;
};

/** Methods to use when ordering `AuditFindingJob`. */
export enum AuditFindingJobsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDINGS_AVERAGE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_JOB_ID__COUNT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_JOB_ID__COUNT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_JOB_ID__COUNT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_JOB_ID__COUNT_DESC",
  AUDIT_FINDINGS_COUNT_ASC = "AUDIT_FINDINGS_COUNT_ASC",
  AUDIT_FINDINGS_COUNT_DESC = "AUDIT_FINDINGS_COUNT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_ASC = "AUDIT_FINDINGS_MAX_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_DESC = "AUDIT_FINDINGS_MAX_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  AUDIT_FINDINGS_MAX_ID_ASC = "AUDIT_FINDINGS_MAX_ID_ASC",
  AUDIT_FINDINGS_MAX_ID_DESC = "AUDIT_FINDINGS_MAX_ID_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_ASC = "AUDIT_FINDINGS_MIN_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_DESC = "AUDIT_FINDINGS_MIN_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  AUDIT_FINDINGS_MIN_ID_ASC = "AUDIT_FINDINGS_MIN_ID_ASC",
  AUDIT_FINDINGS_MIN_ID_DESC = "AUDIT_FINDINGS_MIN_ID_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_ASC = "AUDIT_FINDINGS_SUM_CONFIDENCE_ASC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_DESC = "AUDIT_FINDINGS_SUM_CONFIDENCE_DESC",
  AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  AUDIT_FINDINGS_SUM_ID_ASC = "AUDIT_FINDINGS_SUM_ID_ASC",
  AUDIT_FINDINGS_SUM_ID_DESC = "AUDIT_FINDINGS_SUM_ID_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_DESC",
  BATCH_FILE_ID_ASC = "BATCH_FILE_ID_ASC",
  BATCH_FILE_ID_DESC = "BATCH_FILE_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type AuditFindingMaxAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<IntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<IntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<IntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<IntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<IntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<IntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<IntFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingMaxAggregates = {
  __typename?: "AuditFindingMaxAggregates";
  /** Maximum of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditFindingMinAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<IntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<IntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<IntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<IntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<IntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<IntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<IntFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingMinAggregates = {
  __typename?: "AuditFindingMinAggregates";
  /** Minimum of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingRuleType` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingRuleType` being updated. */
    patch: AuditFindingRuleTypePatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingJob` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: AuditFindingJobPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: BatchClaimLinePatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReasonCode` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: AuditFindingReasonCodePatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReview` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: AuditFindingReviewPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReview` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: AuditFindingReviewPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemAudit` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: BatchClaimLineItemAuditPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reportedFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reportedFinding` being updated. */
    patch: ReportedFindingPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reviewedAuditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
    patch: ReviewedAuditFindingPatch;
  };

/** The fields on `auditFinding` to look up the row to update. */
export type AuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: updateAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyPatch;
  };

/** Represents an update to a `AuditFinding`. Fields that are set will be updated. */
export type AuditFindingPatch = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
  improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
  improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingReasonCode = Node & {
  __typename?: "AuditFindingReasonCode";
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReasonCode`. */
  auditFinding?: Maybe<AuditFinding>;
  auditFindingId: Scalars["UUID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReasonCode`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `ReasonCode` that is related to this `AuditFindingReasonCode`. */
  reasonCode?: Maybe<ReasonCode>;
  reasonCodeId: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditFindingReasonCodeAggregates = {
  __typename?: "AuditFindingReasonCodeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingReasonCodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditFindingReasonCode` object types. */
export type AuditFindingReasonCodeAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditFindingReasonCode` objects. */
  distinctCount?: InputMaybe<AuditFindingReasonCodeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditFindingReasonCode` object to be included within the aggregate. */
  filter?: InputMaybe<AuditFindingReasonCodeFilter>;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingReasonCodeAuditFindingIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditFindingReasonCode` to be created by this mutation. */
export type AuditFindingReasonCodeAuditFindingIdFkeyAuditFindingReasonCodeCreateInput =
  {
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditFinding` in the `AuditFindingReasonCodeInput` mutation. */
export type AuditFindingReasonCodeAuditFindingIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReasonCode` in the `AuditFindingInput` mutation. */
export type AuditFindingReasonCodeAuditFindingIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReasonCodeAuditFindingReasonCodePkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReasonCodeNodeIdConnect>>;
  /** A `AuditFindingReasonCodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReasonCodeAuditFindingIdFkeyAuditFindingReasonCodeCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyUsingAuditFindingReasonCodePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `auditFindingReasonCode` to look up the row to connect. */
export type AuditFindingReasonCodeAuditFindingReasonCodePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `auditFindingReasonCode` to be created by this mutation. */
export type AuditFindingReasonCodeAuthorIdFkeyAuditFindingReasonCodeCreateInput =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `auditorUser` to be created by this mutation. */
export type AuditFindingReasonCodeAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingReasonCodeInput` mutation. */
export type AuditFindingReasonCodeAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReasonCode` in the `AuditorUserInput` mutation. */
export type AuditFindingReasonCodeAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReasonCodeAuditFindingReasonCodePkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReasonCodeNodeIdConnect>>;
  /** A `AuditFindingReasonCodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReasonCodeAuthorIdFkeyAuditFindingReasonCodeCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditFindingReasonCodePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `AuditFindingReasonCode` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingReasonCodeCondition = {
  /** Checks for equality with the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `reasonCodeId` field. */
  reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingReasonCodeDistinctCountAggregateFilter = {
  auditFindingId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  reasonCodeId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditFindingReasonCodeDistinctCountAggregates = {
  __typename?: "AuditFindingReasonCodeDistinctCountAggregates";
  /** Distinct count of auditFindingId across the matching connection */
  auditFindingId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of reasonCodeId across the matching connection */
  reasonCodeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFindingReasonCode` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReasonCodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingReasonCodeFilter>>;
  /** Filter by the object’s `auditFinding` relation. */
  auditFinding?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingReasonCodeFilter>>;
  /** Filter by the object’s `reasonCode` relation. */
  reasonCode?: InputMaybe<ReasonCodeFilter>;
  /** Filter by the object’s `reasonCodeId` field. */
  reasonCodeId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditFindingReasonCode` for usage during aggregation. */
export enum AuditFindingReasonCodeGroupBy {
  AUDIT_FINDING_ID = "AUDIT_FINDING_ID",
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  REASON_CODE_ID = "REASON_CODE_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditFindingReasonCodeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditFindingReasonCode` aggregates. */
export type AuditFindingReasonCodeHavingInput = {
  AND?: InputMaybe<Array<AuditFindingReasonCodeHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingReasonCodeHavingInput>>;
  average?: InputMaybe<AuditFindingReasonCodeHavingAverageInput>;
  distinctCount?: InputMaybe<AuditFindingReasonCodeHavingDistinctCountInput>;
  max?: InputMaybe<AuditFindingReasonCodeHavingMaxInput>;
  min?: InputMaybe<AuditFindingReasonCodeHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditFindingReasonCodeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditFindingReasonCodeHavingStddevSampleInput>;
  sum?: InputMaybe<AuditFindingReasonCodeHavingSumInput>;
  variancePopulation?: InputMaybe<AuditFindingReasonCodeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditFindingReasonCodeHavingVarianceSampleInput>;
};

export type AuditFindingReasonCodeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReasonCodeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AuditFindingReasonCode` */
export type AuditFindingReasonCodeInput = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
  reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingReasonCodeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFindingReasonCode` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFindingReasonCode` to look up the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyUsingAuditFindingReasonCodePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFindingReasonCode` to look up the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditFindingReasonCodePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reasonCode` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reasonCode` being updated. */
    patch: ReasonCodePatch;
  };

/** The fields on `auditFindingReasonCode` to look up the row to update. */
export type AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyUsingAuditFindingReasonCodePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyPatch;
  };

/** Represents an update to a `AuditFindingReasonCode`. Fields that are set will be updated. */
export type AuditFindingReasonCodePatch = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
  reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditFindingReasonCode` to be created by this mutation. */
export type AuditFindingReasonCodeReasonCodeIdFkeyAuditFindingReasonCodeCreateInput =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `reasonCode` in the `AuditFindingReasonCodeInput` mutation. */
export type AuditFindingReasonCodeReasonCodeIdFkeyInput = {
  /** The primary key(s) for `reasonCode` for the far side of the relationship. */
  connectById?: InputMaybe<ReasonCodeReasonCodePkeyConnect>;
  /** The primary key(s) for `reasonCode` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ReasonCodeNodeIdConnect>;
  /** The primary key(s) and patch data for `reasonCode` for the far side of the relationship. */
  updateById?: InputMaybe<ReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyUsingReasonCodePkeyUpdate>;
  /** The primary key(s) and patch data for `reasonCode` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReasonCode` in the `ReasonCodeInput` mutation. */
export type AuditFindingReasonCodeReasonCodeIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReasonCodeAuditFindingReasonCodePkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReasonCode` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReasonCodeNodeIdConnect>>;
  /** A `AuditFindingReasonCodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReasonCodeReasonCodeIdFkeyAuditFindingReasonCodeCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyUsingAuditFindingReasonCodePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReasonCode` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyNodeIdUpdate>
  >;
};

/** A connection to a list of `AuditFindingReasonCode` values. */
export type AuditFindingReasonCodesConnection = {
  __typename?: "AuditFindingReasonCodesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingReasonCodeAggregates>;
  /** A list of edges which contains the `AuditFindingReasonCode` and cursor to aid in pagination. */
  edges: Array<AuditFindingReasonCodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingReasonCodeAggregates>>;
  /** A list of `AuditFindingReasonCode` objects. */
  nodes: Array<Maybe<AuditFindingReasonCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFindingReasonCode` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFindingReasonCode` values. */
export type AuditFindingReasonCodesConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingReasonCodeGroupBy>;
  having?: InputMaybe<AuditFindingReasonCodeHavingInput>;
};

/** A `AuditFindingReasonCode` edge in the connection. */
export type AuditFindingReasonCodesEdge = {
  __typename?: "AuditFindingReasonCodesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFindingReasonCode` at the end of the edge. */
  node?: Maybe<AuditFindingReasonCode>;
};

/** Methods to use when ordering `AuditFindingReasonCode`. */
export enum AuditFindingReasonCodesOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_ID_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REASON_CODE_BY_REASON_CODE_ID__CREATED_AT_ASC = "REASON_CODE_BY_REASON_CODE_ID__CREATED_AT_ASC",
  REASON_CODE_BY_REASON_CODE_ID__CREATED_AT_DESC = "REASON_CODE_BY_REASON_CODE_ID__CREATED_AT_DESC",
  REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_END_ASC = "REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_END_ASC",
  REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_END_DESC = "REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_END_DESC",
  REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_START_ASC = "REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_START_ASC",
  REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_START_DESC = "REASON_CODE_BY_REASON_CODE_ID__DATE_EFFECTIVE_START_DESC",
  REASON_CODE_BY_REASON_CODE_ID__DESCRIPTION_ASC = "REASON_CODE_BY_REASON_CODE_ID__DESCRIPTION_ASC",
  REASON_CODE_BY_REASON_CODE_ID__DESCRIPTION_DESC = "REASON_CODE_BY_REASON_CODE_ID__DESCRIPTION_DESC",
  REASON_CODE_BY_REASON_CODE_ID__ID_ASC = "REASON_CODE_BY_REASON_CODE_ID__ID_ASC",
  REASON_CODE_BY_REASON_CODE_ID__ID_DESC = "REASON_CODE_BY_REASON_CODE_ID__ID_DESC",
  REASON_CODE_BY_REASON_CODE_ID__LAST_UPDATED_ASC = "REASON_CODE_BY_REASON_CODE_ID__LAST_UPDATED_ASC",
  REASON_CODE_BY_REASON_CODE_ID__LAST_UPDATED_DESC = "REASON_CODE_BY_REASON_CODE_ID__LAST_UPDATED_DESC",
  REASON_CODE_BY_REASON_CODE_ID__NOTES_ASC = "REASON_CODE_BY_REASON_CODE_ID__NOTES_ASC",
  REASON_CODE_BY_REASON_CODE_ID__NOTES_DESC = "REASON_CODE_BY_REASON_CODE_ID__NOTES_DESC",
  REASON_CODE_BY_REASON_CODE_ID__UPDATED_AT_ASC = "REASON_CODE_BY_REASON_CODE_ID__UPDATED_AT_ASC",
  REASON_CODE_BY_REASON_CODE_ID__UPDATED_AT_DESC = "REASON_CODE_BY_REASON_CODE_ID__UPDATED_AT_DESC",
  REASON_CODE_ID_ASC = "REASON_CODE_ID_ASC",
  REASON_CODE_ID_DESC = "REASON_CODE_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type AuditFindingReport = Node & {
  __typename?: "AuditFindingReport";
  auditFindingReportSubtype: AuditFindingReportSubtype;
  auditFindingReportType: AuditFindingReportType;
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  auditFindingReportsByOriginalVersionId: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  auditorBuckets: AuditorBucketsConnection;
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReport`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims: BatchClaimsConnection;
  claimFilter?: Maybe<Scalars["JSON"]["output"]>;
  createdAt: Scalars["Datetime"]["output"];
  determinedImproperPayments?: Maybe<ReportAggregate>;
  downloadUrl: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Insurer` that is related to this `AuditFindingReport`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  isLatestVersion?: Maybe<Scalars["Boolean"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `AuditFindingReport` that is related to this `AuditFindingReport`. */
  originalVersion?: Maybe<AuditFindingReport>;
  originalVersionId: Scalars["UUID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReport`. */
  primaryPia?: Maybe<AuditorUser>;
  primaryPiaId: Scalars["UUID"]["output"];
  /** Reads a single `Provider` that is related to this `AuditFindingReport`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ReportedClaim`. */
  reportedClaims: ReportedClaimsConnection;
  /** Reads and enables pagination through a set of `ReportedFinding`. */
  reportedFindings: ReportedFindingsConnection;
  s3Url?: Maybe<Scalars["String"]["output"]>;
  s3Version?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
  viewId: Scalars["UUID"]["output"];
};

export type AuditFindingReportauditFindingReportsByOriginalVersionIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

export type AuditFindingReportauditorBucketsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AuditFindingReportbatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AuditFindingReportreportedClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedClaimCondition>;
  filter?: InputMaybe<ReportedClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedClaimsOrderBy>>;
};

export type AuditFindingReportreportedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedFindingCondition>;
  filter?: InputMaybe<ReportedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedFindingsOrderBy>>;
};

export type AuditFindingReportAggregates = {
  __typename?: "AuditFindingReportAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingReportDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditFindingReport` object types. */
export type AuditFindingReportAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditFindingReport` objects. */
  distinctCount?: InputMaybe<AuditFindingReportDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditFindingReport` object to be included within the aggregate. */
  filter?: InputMaybe<AuditFindingReportFilter>;
};

/**
 * A condition to be used against `AuditFindingReport` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingReportCondition = {
  /** Checks for equality with the object’s `auditFindingReportSubtype` field. */
  auditFindingReportSubtype?: InputMaybe<AuditFindingReportSubtype>;
  /** Checks for equality with the object’s `auditFindingReportType` field. */
  auditFindingReportType?: InputMaybe<AuditFindingReportType>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `claimFilter` field. */
  claimFilter?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `originalVersionId` field. */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `primaryPiaId` field. */
  primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3Url` field. */
  s3Url?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3Version` field. */
  s3Version?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `viewId` field. */
  viewId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type AuditFindingReportDistinctCountAggregateFilter = {
  auditFindingReportSubtype?: InputMaybe<BigIntFilter>;
  auditFindingReportType?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  claimFilter?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  insurerId?: InputMaybe<BigIntFilter>;
  isLatestVersion?: InputMaybe<BooleanFilter>;
  originalVersionId?: InputMaybe<BigIntFilter>;
  primaryPiaId?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  s3Url?: InputMaybe<BigIntFilter>;
  s3Version?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  viewId?: InputMaybe<BigIntFilter>;
};

export type AuditFindingReportDistinctCountAggregates = {
  __typename?: "AuditFindingReportDistinctCountAggregates";
  /** Distinct count of auditFindingReportSubtype across the matching connection */
  auditFindingReportSubtype?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingReportType across the matching connection */
  auditFindingReportType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of claimFilter across the matching connection */
  claimFilter?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  determinedImproperPayments?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  isLatestVersion?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of originalVersionId across the matching connection */
  originalVersionId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of primaryPiaId across the matching connection */
  primaryPiaId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3Url across the matching connection */
  s3Url?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3Version across the matching connection */
  s3Version?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of viewId across the matching connection */
  viewId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFindingReport` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingReportFilter>>;
  /** Filter by the object’s `auditFindingReportSubtype` field. */
  auditFindingReportSubtype?: InputMaybe<AuditFindingReportSubtypeFilter>;
  /** Filter by the object’s `auditFindingReportType` field. */
  auditFindingReportType?: InputMaybe<AuditFindingReportTypeFilter>;
  /** Filter by the object’s `auditFindingReportsByOriginalVersionId` relation. */
  auditFindingReportsByOriginalVersionId?: InputMaybe<AuditFindingReportToManyAuditFindingReportFilter>;
  /** Some related `auditFindingReportsByOriginalVersionId` exist. */
  auditFindingReportsByOriginalVersionIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `claimFilter` field. */
  claimFilter?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `isLatestVersion` field. */
  isLatestVersion?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingReportFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingReportFilter>>;
  /** Filter by the object’s `originalVersion` relation. */
  originalVersion?: InputMaybe<AuditFindingReportFilter>;
  /** Filter by the object’s `originalVersionId` field. */
  originalVersionId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `primaryPia` relation. */
  primaryPia?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `primaryPiaId` field. */
  primaryPiaId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `reportedClaims` relation. */
  reportedClaims?: InputMaybe<AuditFindingReportToManyReportedClaimFilter>;
  /** Some related `reportedClaims` exist. */
  reportedClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `reportedFindings` relation. */
  reportedFindings?: InputMaybe<AuditFindingReportToManyReportedFindingFilter>;
  /** Some related `reportedFindings` exist. */
  reportedFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `s3Url` field. */
  s3Url?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3Version` field. */
  s3Version?: InputMaybe<StringFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `viewId` field. */
  viewId?: InputMaybe<UUIDFilter>;
};

/** Grouping methods for `AuditFindingReport` for usage during aggregation. */
export enum AuditFindingReportGroupBy {
  AUDIT_FINDING_REPORT_SUBTYPE = "AUDIT_FINDING_REPORT_SUBTYPE",
  AUDIT_FINDING_REPORT_TYPE = "AUDIT_FINDING_REPORT_TYPE",
  AUTHOR_ID = "AUTHOR_ID",
  CLAIM_FILTER = "CLAIM_FILTER",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  INSURER_ID = "INSURER_ID",
  ORIGINAL_VERSION_ID = "ORIGINAL_VERSION_ID",
  PRIMARY_PIA_ID = "PRIMARY_PIA_ID",
  PROVIDER_ID = "PROVIDER_ID",
  S3_URL = "S3_URL",
  S3_VERSION = "S3_VERSION",
  TITLE = "TITLE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
  VIEW_ID = "VIEW_ID",
}

export type AuditFindingReportHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditFindingReport` aggregates. */
export type AuditFindingReportHavingInput = {
  AND?: InputMaybe<Array<AuditFindingReportHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingReportHavingInput>>;
  average?: InputMaybe<AuditFindingReportHavingAverageInput>;
  distinctCount?: InputMaybe<AuditFindingReportHavingDistinctCountInput>;
  max?: InputMaybe<AuditFindingReportHavingMaxInput>;
  min?: InputMaybe<AuditFindingReportHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditFindingReportHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditFindingReportHavingStddevSampleInput>;
  sum?: InputMaybe<AuditFindingReportHavingSumInput>;
  variancePopulation?: InputMaybe<AuditFindingReportHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditFindingReportHavingVarianceSampleInput>;
};

export type AuditFindingReportHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReportHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `insurer` in the `AuditFindingReportInput` mutation. */
export type AuditFindingReportInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReport` in the `InsurerInput` mutation. */
export type AuditFindingReportInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReportNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyNodeIdUpdate>
  >;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingReportNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reportedClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reportedClaim` being updated. */
    patch: ReportedClaimPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reportedFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reportedFinding` being updated. */
    patch: ReportedFindingPatch;
  };

/** The fields on `auditFindingReport` to look up the row to update. */
export type AuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: updateAuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyPatch;
  };

/** Input for the nested mutation of `auditFindingReport` in the `AuditFindingReportInput` mutation. */
export type AuditFindingReportOriginalVersionIdFkeyInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingReportNodeIdConnect>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyNodeIdUpdate>;
};

/** Represents an update to a `AuditFindingReport`. Fields that are set will be updated. */
export type AuditFindingReportPatch = {
  auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
  auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
};

/** The fields on `auditFindingReport` to look up the row to connect. */
export type AuditFindingReportPreliminaryAuditFindingReportPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingReportInput` mutation. */
export type AuditFindingReportPrimaryPiaIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReport` in the `AuditorUserInput` mutation. */
export type AuditFindingReportPrimaryPiaIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReportNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyNodeIdUpdate>
  >;
};

export enum AuditFindingReportSubtype {
  /** MDH - Creates an audit finding report file but does not save results to database */
  DRAFT = "DRAFT",
  /** MDH - The last audit finding report subtype sent to MDH, any challenge to these findings are an appeal. Note you can have multiple final reports, so this does NOT imply a single FINAL report exists for a given report family. */
  FINAL = "FINAL",
  /** MDH - Creates an audit finding report file but does not save results to database, is not currently called from the frontend but is a backend convenience handled in the report service */
  MANUAL = "MANUAL",
  /** MDH - The initial audit finding report sent to a provider to respond to, any challenge to these findings are a dispute */
  PRELIMINARY = "PRELIMINARY",
  /** Creates an audit finding report file based on our EOA format */
  PROCESSED = "PROCESSED",
}

/** A filter to be used against AuditFindingReportSubtype fields. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportSubtypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AuditFindingReportSubtype>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AuditFindingReportSubtype>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AuditFindingReportSubtype>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AuditFindingReportSubtype>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AuditFindingReportSubtype>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AuditFindingReportSubtype>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AuditFindingReportSubtype>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AuditFindingReportSubtype>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AuditFindingReportSubtype>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AuditFindingReportSubtype>>;
};

/** A filter to be used against many `AuditFindingReport` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportToManyAuditFindingReportFilter = {
  /** Aggregates across related `AuditFindingReport` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReportAggregatesFilter>;
  /** Every related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReportFilter>;
  /** No related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReportFilter>;
  /** Some related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReportFilter>;
};

/** A filter to be used against many `ReportedClaim` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportToManyReportedClaimFilter = {
  /** Aggregates across related `ReportedClaim` match the filter criteria. */
  aggregates?: InputMaybe<ReportedClaimAggregatesFilter>;
  /** Every related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReportedClaimFilter>;
  /** No related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReportedClaimFilter>;
  /** Some related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReportedClaimFilter>;
};

/** A filter to be used against many `ReportedFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportToManyReportedFindingFilter = {
  /** Aggregates across related `ReportedFinding` match the filter criteria. */
  aggregates?: InputMaybe<ReportedFindingAggregatesFilter>;
  /** Every related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReportedFindingFilter>;
  /** No related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReportedFindingFilter>;
  /** Some related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReportedFindingFilter>;
};

export enum AuditFindingReportType {
  /** Standard explanation of adjustment report */
  EOA = "EOA",
  /** Itemized finding report is a csv dump of findings on IBs */
  IFR = "IFR",
  /** Maryland Department of Health formatted post-pay report */
  MDH = "MDH",
}

/** A filter to be used against AuditFindingReportType fields. All fields are combined with a logical ‘and.’ */
export type AuditFindingReportTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AuditFindingReportType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AuditFindingReportType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AuditFindingReportType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AuditFindingReportType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AuditFindingReportType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AuditFindingReportType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AuditFindingReportType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AuditFindingReportType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AuditFindingReportType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AuditFindingReportType>>;
};

/** A connection to a list of `AuditFindingReport` values. */
export type AuditFindingReportsConnection = {
  __typename?: "AuditFindingReportsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingReportAggregates>;
  /** A list of edges which contains the `AuditFindingReport` and cursor to aid in pagination. */
  edges: Array<AuditFindingReportsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingReportAggregates>>;
  /** A list of `AuditFindingReport` objects. */
  nodes: Array<Maybe<AuditFindingReport>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFindingReport` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFindingReport` values. */
export type AuditFindingReportsConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingReportGroupBy>;
  having?: InputMaybe<AuditFindingReportHavingInput>;
};

/** A `AuditFindingReport` edge in the connection. */
export type AuditFindingReportsEdge = {
  __typename?: "AuditFindingReportsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFindingReport` at the end of the edge. */
  node?: Maybe<AuditFindingReport>;
};

/** Methods to use when ordering `AuditFindingReport`. */
export enum AuditFindingReportsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__EMAIL_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__EMAIL_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__EMAIL_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__EMAIL_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__ID_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__ID_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__ID_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__ID_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__TITLE_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__TITLE_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__TITLE_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__TITLE_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_PRIMARY_PIA_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_PRIMARY_PIA_ID__USER_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_AVERAGE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_COUNT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_DISTINCT_COUNT_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MAX_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_MIN_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_STDDEV_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_SUM_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID_VARIANCE_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID__COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID__COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID__COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_ORIGINAL_VERSION_ID__COUNT_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CREATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CREATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CREATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__CREATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__INSURER_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__INSURER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__INSURER_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__INSURER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PROVIDER_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PROVIDER_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_URL_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_URL_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_URL_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_URL_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_VERSION_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_VERSION_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_VERSION_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__S3_VERSION_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__TITLE_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__TITLE_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__TITLE_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__TITLE_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__UPDATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__UPDATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__VIEW_ID_ASC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__VIEW_ID_ASC",
  AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__VIEW_ID_DESC = "AUDIT_FINDING_REPORT_BY_ORIGINAL_VERSION_ID__VIEW_ID_DESC",
  AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  CLAIM_FILTER_ASC = "CLAIM_FILTER_ASC",
  CLAIM_FILTER_DESC = "CLAIM_FILTER_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NATURAL = "NATURAL",
  ORIGINAL_VERSION_ID_ASC = "ORIGINAL_VERSION_ID_ASC",
  ORIGINAL_VERSION_ID_DESC = "ORIGINAL_VERSION_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PRIMARY_PIA_ID_ASC = "PRIMARY_PIA_ID_ASC",
  PRIMARY_PIA_ID_DESC = "PRIMARY_PIA_ID_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  REPORTED_CLAIMS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_AVERAGE_CLAIM_ID_ASC = "REPORTED_CLAIMS_AVERAGE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_AVERAGE_CLAIM_ID_DESC = "REPORTED_CLAIMS_AVERAGE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_AVERAGE_ID_ASC = "REPORTED_CLAIMS_AVERAGE_ID_ASC",
  REPORTED_CLAIMS_AVERAGE_ID_DESC = "REPORTED_CLAIMS_AVERAGE_ID_DESC",
  REPORTED_CLAIMS_AVERAGE_STRATEGY_ASC = "REPORTED_CLAIMS_AVERAGE_STRATEGY_ASC",
  REPORTED_CLAIMS_AVERAGE_STRATEGY_DESC = "REPORTED_CLAIMS_AVERAGE_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_AUDIT_FINDING_REPORT_ID__COUNT_ASC = "REPORTED_CLAIMS_BY_AUDIT_FINDING_REPORT_ID__COUNT_ASC",
  REPORTED_CLAIMS_BY_AUDIT_FINDING_REPORT_ID__COUNT_DESC = "REPORTED_CLAIMS_BY_AUDIT_FINDING_REPORT_ID__COUNT_DESC",
  REPORTED_CLAIMS_COUNT_ASC = "REPORTED_CLAIMS_COUNT_ASC",
  REPORTED_CLAIMS_COUNT_DESC = "REPORTED_CLAIMS_COUNT_DESC",
  REPORTED_CLAIMS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_DISTINCT_COUNT_CLAIM_ID_ASC = "REPORTED_CLAIMS_DISTINCT_COUNT_CLAIM_ID_ASC",
  REPORTED_CLAIMS_DISTINCT_COUNT_CLAIM_ID_DESC = "REPORTED_CLAIMS_DISTINCT_COUNT_CLAIM_ID_DESC",
  REPORTED_CLAIMS_DISTINCT_COUNT_ID_ASC = "REPORTED_CLAIMS_DISTINCT_COUNT_ID_ASC",
  REPORTED_CLAIMS_DISTINCT_COUNT_ID_DESC = "REPORTED_CLAIMS_DISTINCT_COUNT_ID_DESC",
  REPORTED_CLAIMS_DISTINCT_COUNT_STRATEGY_ASC = "REPORTED_CLAIMS_DISTINCT_COUNT_STRATEGY_ASC",
  REPORTED_CLAIMS_DISTINCT_COUNT_STRATEGY_DESC = "REPORTED_CLAIMS_DISTINCT_COUNT_STRATEGY_DESC",
  REPORTED_CLAIMS_MAX_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_MAX_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_MAX_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_MAX_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_MAX_CLAIM_ID_ASC = "REPORTED_CLAIMS_MAX_CLAIM_ID_ASC",
  REPORTED_CLAIMS_MAX_CLAIM_ID_DESC = "REPORTED_CLAIMS_MAX_CLAIM_ID_DESC",
  REPORTED_CLAIMS_MAX_ID_ASC = "REPORTED_CLAIMS_MAX_ID_ASC",
  REPORTED_CLAIMS_MAX_ID_DESC = "REPORTED_CLAIMS_MAX_ID_DESC",
  REPORTED_CLAIMS_MAX_STRATEGY_ASC = "REPORTED_CLAIMS_MAX_STRATEGY_ASC",
  REPORTED_CLAIMS_MAX_STRATEGY_DESC = "REPORTED_CLAIMS_MAX_STRATEGY_DESC",
  REPORTED_CLAIMS_MIN_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_MIN_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_MIN_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_MIN_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_MIN_CLAIM_ID_ASC = "REPORTED_CLAIMS_MIN_CLAIM_ID_ASC",
  REPORTED_CLAIMS_MIN_CLAIM_ID_DESC = "REPORTED_CLAIMS_MIN_CLAIM_ID_DESC",
  REPORTED_CLAIMS_MIN_ID_ASC = "REPORTED_CLAIMS_MIN_ID_ASC",
  REPORTED_CLAIMS_MIN_ID_DESC = "REPORTED_CLAIMS_MIN_ID_DESC",
  REPORTED_CLAIMS_MIN_STRATEGY_ASC = "REPORTED_CLAIMS_MIN_STRATEGY_ASC",
  REPORTED_CLAIMS_MIN_STRATEGY_DESC = "REPORTED_CLAIMS_MIN_STRATEGY_DESC",
  REPORTED_CLAIMS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_STDDEV_POPULATION_CLAIM_ID_ASC = "REPORTED_CLAIMS_STDDEV_POPULATION_CLAIM_ID_ASC",
  REPORTED_CLAIMS_STDDEV_POPULATION_CLAIM_ID_DESC = "REPORTED_CLAIMS_STDDEV_POPULATION_CLAIM_ID_DESC",
  REPORTED_CLAIMS_STDDEV_POPULATION_ID_ASC = "REPORTED_CLAIMS_STDDEV_POPULATION_ID_ASC",
  REPORTED_CLAIMS_STDDEV_POPULATION_ID_DESC = "REPORTED_CLAIMS_STDDEV_POPULATION_ID_DESC",
  REPORTED_CLAIMS_STDDEV_POPULATION_STRATEGY_ASC = "REPORTED_CLAIMS_STDDEV_POPULATION_STRATEGY_ASC",
  REPORTED_CLAIMS_STDDEV_POPULATION_STRATEGY_DESC = "REPORTED_CLAIMS_STDDEV_POPULATION_STRATEGY_DESC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_CLAIM_ID_ASC = "REPORTED_CLAIMS_STDDEV_SAMPLE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_CLAIM_ID_DESC = "REPORTED_CLAIMS_STDDEV_SAMPLE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_ID_ASC = "REPORTED_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_ID_DESC = "REPORTED_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_STRATEGY_ASC = "REPORTED_CLAIMS_STDDEV_SAMPLE_STRATEGY_ASC",
  REPORTED_CLAIMS_STDDEV_SAMPLE_STRATEGY_DESC = "REPORTED_CLAIMS_STDDEV_SAMPLE_STRATEGY_DESC",
  REPORTED_CLAIMS_SUM_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_SUM_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_SUM_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_SUM_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_SUM_CLAIM_ID_ASC = "REPORTED_CLAIMS_SUM_CLAIM_ID_ASC",
  REPORTED_CLAIMS_SUM_CLAIM_ID_DESC = "REPORTED_CLAIMS_SUM_CLAIM_ID_DESC",
  REPORTED_CLAIMS_SUM_ID_ASC = "REPORTED_CLAIMS_SUM_ID_ASC",
  REPORTED_CLAIMS_SUM_ID_DESC = "REPORTED_CLAIMS_SUM_ID_DESC",
  REPORTED_CLAIMS_SUM_STRATEGY_ASC = "REPORTED_CLAIMS_SUM_STRATEGY_ASC",
  REPORTED_CLAIMS_SUM_STRATEGY_DESC = "REPORTED_CLAIMS_SUM_STRATEGY_DESC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_CLAIM_ID_ASC = "REPORTED_CLAIMS_VARIANCE_POPULATION_CLAIM_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_CLAIM_ID_DESC = "REPORTED_CLAIMS_VARIANCE_POPULATION_CLAIM_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_ID_ASC = "REPORTED_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_ID_DESC = "REPORTED_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_STRATEGY_ASC = "REPORTED_CLAIMS_VARIANCE_POPULATION_STRATEGY_ASC",
  REPORTED_CLAIMS_VARIANCE_POPULATION_STRATEGY_DESC = "REPORTED_CLAIMS_VARIANCE_POPULATION_STRATEGY_DESC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_CLAIM_ID_ASC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_CLAIM_ID_DESC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_STRATEGY_ASC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_STRATEGY_ASC",
  REPORTED_CLAIMS_VARIANCE_SAMPLE_STRATEGY_DESC = "REPORTED_CLAIMS_VARIANCE_SAMPLE_STRATEGY_DESC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_CREATED_AT_ASC = "REPORTED_FINDINGS_AVERAGE_CREATED_AT_ASC",
  REPORTED_FINDINGS_AVERAGE_CREATED_AT_DESC = "REPORTED_FINDINGS_AVERAGE_CREATED_AT_DESC",
  REPORTED_FINDINGS_AVERAGE_ID_ASC = "REPORTED_FINDINGS_AVERAGE_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_ID_DESC = "REPORTED_FINDINGS_AVERAGE_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_UPDATED_AT_ASC = "REPORTED_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_AVERAGE_UPDATED_AT_DESC = "REPORTED_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  REPORTED_FINDINGS_BY_AUDIT_FINDING_REPORT_ID__COUNT_ASC = "REPORTED_FINDINGS_BY_AUDIT_FINDING_REPORT_ID__COUNT_ASC",
  REPORTED_FINDINGS_BY_AUDIT_FINDING_REPORT_ID__COUNT_DESC = "REPORTED_FINDINGS_BY_AUDIT_FINDING_REPORT_ID__COUNT_DESC",
  REPORTED_FINDINGS_COUNT_ASC = "REPORTED_FINDINGS_COUNT_ASC",
  REPORTED_FINDINGS_COUNT_DESC = "REPORTED_FINDINGS_COUNT_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_MAX_CREATED_AT_ASC = "REPORTED_FINDINGS_MAX_CREATED_AT_ASC",
  REPORTED_FINDINGS_MAX_CREATED_AT_DESC = "REPORTED_FINDINGS_MAX_CREATED_AT_DESC",
  REPORTED_FINDINGS_MAX_ID_ASC = "REPORTED_FINDINGS_MAX_ID_ASC",
  REPORTED_FINDINGS_MAX_ID_DESC = "REPORTED_FINDINGS_MAX_ID_DESC",
  REPORTED_FINDINGS_MAX_UPDATED_AT_ASC = "REPORTED_FINDINGS_MAX_UPDATED_AT_ASC",
  REPORTED_FINDINGS_MAX_UPDATED_AT_DESC = "REPORTED_FINDINGS_MAX_UPDATED_AT_DESC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_MIN_CREATED_AT_ASC = "REPORTED_FINDINGS_MIN_CREATED_AT_ASC",
  REPORTED_FINDINGS_MIN_CREATED_AT_DESC = "REPORTED_FINDINGS_MIN_CREATED_AT_DESC",
  REPORTED_FINDINGS_MIN_ID_ASC = "REPORTED_FINDINGS_MIN_ID_ASC",
  REPORTED_FINDINGS_MIN_ID_DESC = "REPORTED_FINDINGS_MIN_ID_DESC",
  REPORTED_FINDINGS_MIN_UPDATED_AT_ASC = "REPORTED_FINDINGS_MIN_UPDATED_AT_ASC",
  REPORTED_FINDINGS_MIN_UPDATED_AT_DESC = "REPORTED_FINDINGS_MIN_UPDATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_SUM_CREATED_AT_ASC = "REPORTED_FINDINGS_SUM_CREATED_AT_ASC",
  REPORTED_FINDINGS_SUM_CREATED_AT_DESC = "REPORTED_FINDINGS_SUM_CREATED_AT_DESC",
  REPORTED_FINDINGS_SUM_ID_ASC = "REPORTED_FINDINGS_SUM_ID_ASC",
  REPORTED_FINDINGS_SUM_ID_DESC = "REPORTED_FINDINGS_SUM_ID_DESC",
  REPORTED_FINDINGS_SUM_UPDATED_AT_ASC = "REPORTED_FINDINGS_SUM_UPDATED_AT_ASC",
  REPORTED_FINDINGS_SUM_UPDATED_AT_DESC = "REPORTED_FINDINGS_SUM_UPDATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  S3_URL_ASC = "S3_URL_ASC",
  S3_URL_DESC = "S3_URL_DESC",
  S3_VERSION_ASC = "S3_VERSION_ASC",
  S3_VERSION_DESC = "S3_VERSION_DESC",
  TITLE_ASC = "TITLE_ASC",
  TITLE_DESC = "TITLE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  VIEW_ID_ASC = "VIEW_ID_ASC",
  VIEW_ID_DESC = "VIEW_ID_DESC",
}

export type AuditFindingReview = Node & {
  __typename?: "AuditFindingReview";
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReview`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReview`. */
  original?: Maybe<AuditFinding>;
  originalId: Scalars["UUID"]["output"];
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReview`. */
  review?: Maybe<AuditFinding>;
  reviewId: Scalars["UUID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditFindingReviewAggregates = {
  __typename?: "AuditFindingReviewAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingReviewDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditFindingReview` object types. */
export type AuditFindingReviewAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditFindingReview` objects. */
  distinctCount?: InputMaybe<AuditFindingReviewDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditFindingReview` object to be included within the aggregate. */
  filter?: InputMaybe<AuditFindingReviewFilter>;
};

/** The fields on `auditFindingReview` to look up the row to connect. */
export type AuditFindingReviewAuditFindingReviewPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `auditFindingReview` to look up the row to connect. */
export type AuditFindingReviewAuditFindingReviewReviewIdOriginalIdKeyConnect = {
  originalId: Scalars["UUID"]["input"];
  reviewId: Scalars["UUID"]["input"];
};

/** The fields on `auditFindingReview` to look up the row to connect. */
export type AuditFindingReviewAuditFindingReviewUniqueReviewIdConnect = {
  reviewId: Scalars["UUID"]["input"];
};

/** The `auditFindingReview` to be created by this mutation. */
export type AuditFindingReviewAuthorIdFkeyAuditFindingReviewCreateInput = {
  auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
  auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditorUser` to be created by this mutation. */
export type AuditFindingReviewAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingReviewInput` mutation. */
export type AuditFindingReviewAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingReviewAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReview` in the `AuditorUserInput` mutation. */
export type AuditFindingReviewAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReviewNodeIdConnect>>;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewId?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewUniqueReviewIdConnect>
  >;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewIdAndOriginalId?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewReviewIdOriginalIdKeyConnect>
  >;
  /** A `AuditFindingReviewInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReviewAuthorIdFkeyAuditFindingReviewCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewId?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewIdAndOriginalId?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate>
  >;
};

/**
 * A condition to be used against `AuditFindingReview` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingReviewCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `originalId` field. */
  originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `reviewId` field. */
  reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditFindingReviewDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  originalId?: InputMaybe<BigIntFilter>;
  reviewId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditFindingReviewDistinctCountAggregates = {
  __typename?: "AuditFindingReviewDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of originalId across the matching connection */
  originalId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of reviewId across the matching connection */
  reviewId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFindingReview` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingReviewFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingReviewFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingReviewFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingReviewFilter>>;
  /** Filter by the object’s `original` relation. */
  original?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `originalId` field. */
  originalId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `review` relation. */
  review?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `reviewId` field. */
  reviewId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditFindingReview` for usage during aggregation. */
export enum AuditFindingReviewGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  ORIGINAL_ID = "ORIGINAL_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditFindingReviewHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditFindingReview` aggregates. */
export type AuditFindingReviewHavingInput = {
  AND?: InputMaybe<Array<AuditFindingReviewHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingReviewHavingInput>>;
  average?: InputMaybe<AuditFindingReviewHavingAverageInput>;
  distinctCount?: InputMaybe<AuditFindingReviewHavingDistinctCountInput>;
  max?: InputMaybe<AuditFindingReviewHavingMaxInput>;
  min?: InputMaybe<AuditFindingReviewHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditFindingReviewHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditFindingReviewHavingStddevSampleInput>;
  sum?: InputMaybe<AuditFindingReviewHavingSumInput>;
  variancePopulation?: InputMaybe<AuditFindingReviewHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditFindingReviewHavingVarianceSampleInput>;
};

export type AuditFindingReviewHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditFindingReviewHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AuditFindingReview` */
export type AuditFindingReviewInput = {
  auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
  auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingReviewNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFindingReview` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate =
  {
    originalId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate =
  {
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate =
  {
    originalId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate =
  {
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch;
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate =
  {
    originalId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The fields on `auditFindingReview` to look up the row to update. */
export type AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate =
  {
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch;
    reviewId: Scalars["UUID"]["input"];
  };

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingReviewOriginalIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditFindingReview` to be created by this mutation. */
export type AuditFindingReviewOriginalIdFkeyAuditFindingReviewCreateInput = {
  auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
  auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditFinding` in the `AuditFindingReviewInput` mutation. */
export type AuditFindingReviewOriginalIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingReviewOriginalIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReview` in the `AuditFindingInput` mutation. */
export type AuditFindingReviewOriginalIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReviewNodeIdConnect>>;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewId?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewUniqueReviewIdConnect>
  >;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewIdAndOriginalId?: InputMaybe<
    Array<AuditFindingReviewAuditFindingReviewReviewIdOriginalIdKeyConnect>
  >;
  /** A `AuditFindingReviewInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReviewOriginalIdFkeyAuditFindingReviewCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewId?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewIdAndOriginalId?: InputMaybe<
    Array<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate>
  >;
};

/** Represents an update to a `AuditFindingReview`. Fields that are set will be updated. */
export type AuditFindingReviewPatch = {
  auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
  auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `auditFinding` to be created by this mutation. */
export type AuditFindingReviewReviewIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditFindingReview` to be created by this mutation. */
export type AuditFindingReviewReviewIdFkeyAuditFindingReviewCreateInput = {
  auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
  auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditFinding` in the `AuditFindingReviewInput` mutation. */
export type AuditFindingReviewReviewIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditFindingReviewReviewIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReview` in the `AuditFindingInput` mutation. */
export type AuditFindingReviewReviewIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingReviewAuditFindingReviewPkeyConnect>;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingReviewNodeIdConnect>;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewId?: InputMaybe<AuditFindingReviewAuditFindingReviewUniqueReviewIdConnect>;
  /** The primary key(s) for `auditFindingReview` for the far side of the relationship. */
  connectByReviewIdAndOriginalId?: InputMaybe<AuditFindingReviewAuditFindingReviewReviewIdOriginalIdKeyConnect>;
  /** A `AuditFindingReviewInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditFindingReviewReviewIdFkeyAuditFindingReviewCreateInput>
  >;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewPkeyUpdate>;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewId?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewUniqueReviewIdUpdate>;
  /** The primary key(s) and patch data for `auditFindingReview` for the far side of the relationship. */
  updateByReviewIdAndOriginalId?: InputMaybe<AuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyUsingAuditFindingReviewReviewIdOriginalIdKeyUpdate>;
};

export type AuditFindingReviewValuesInput = {
  auditFindingId: Scalars["UUID"]["input"];
  auditFindingValues: CreateAuditFindingValuesInput;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemValues?: InputMaybe<CreateBatchClaimLineItemValuesInput>;
};

/** A connection to a list of `AuditFindingReview` values. */
export type AuditFindingReviewsConnection = {
  __typename?: "AuditFindingReviewsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingReviewAggregates>;
  /** A list of edges which contains the `AuditFindingReview` and cursor to aid in pagination. */
  edges: Array<AuditFindingReviewsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingReviewAggregates>>;
  /** A list of `AuditFindingReview` objects. */
  nodes: Array<Maybe<AuditFindingReview>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFindingReview` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFindingReview` values. */
export type AuditFindingReviewsConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingReviewGroupBy>;
  having?: InputMaybe<AuditFindingReviewHavingInput>;
};

/** A `AuditFindingReview` edge in the connection. */
export type AuditFindingReviewsEdge = {
  __typename?: "AuditFindingReviewsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFindingReview` at the end of the edge. */
  node?: Maybe<AuditFindingReview>;
};

/** Methods to use when ordering `AuditFindingReview`. */
export enum AuditFindingReviewsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_ORIGINAL_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_ORIGINAL_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_ORIGINAL_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_ORIGINAL_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_REVIEW_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_REVIEW_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_REVIEW_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_REVIEW_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_REVIEW_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_REVIEW_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_REVIEW_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_REVIEW_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_REVIEW_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_REVIEW_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_REVIEW_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_REVIEW_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_REVIEW_ID__UPDATED_AT_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  ORIGINAL_ID_ASC = "ORIGINAL_ID_ASC",
  ORIGINAL_ID_DESC = "ORIGINAL_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REVIEW_ID_ASC = "REVIEW_ID_ASC",
  REVIEW_ID_DESC = "REVIEW_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type AuditFindingRuleType = Node & {
  __typename?: "AuditFindingRuleType";
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindingsByAuditFindingRuleType: AuditFindingsConnection;
  author?: Maybe<Scalars["String"]["output"]>;
  claimDataType: AuditFindingSeedType;
  deprecated?: Maybe<Scalars["Boolean"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  displayName: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  rationaleTemplate?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
};

export type AuditFindingRuleTypeauditFindingsByAuditFindingRuleTypeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

export type AuditFindingRuleTypeAggregates = {
  __typename?: "AuditFindingRuleTypeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditFindingRuleTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** The fields on `auditFindingRuleType` to look up the row to connect. */
export type AuditFindingRuleTypeAuditFindingRuleTypePkeyConnect = {
  type: Scalars["String"]["input"];
};

/**
 * A condition to be used against `AuditFindingRuleType` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AuditFindingRuleTypeCondition = {
  /** Checks for equality with the object’s `author` field. */
  author?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `claimDataType` field. */
  claimDataType?: InputMaybe<AuditFindingSeedType>;
  /** Checks for equality with the object’s `deprecated` field. */
  deprecated?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `displayName` field. */
  displayName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `rationaleTemplate` field. */
  rationaleTemplate?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuditFindingRuleTypeDistinctCountAggregates = {
  __typename?: "AuditFindingRuleTypeDistinctCountAggregates";
  /** Distinct count of author across the matching connection */
  author?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of claimDataType across the matching connection */
  claimDataType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deprecated across the matching connection */
  deprecated?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of displayName across the matching connection */
  displayName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of rationaleTemplate across the matching connection */
  rationaleTemplate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditFindingRuleType` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingRuleTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditFindingRuleTypeFilter>>;
  /** Filter by the object’s `auditFindingsByAuditFindingRuleType` relation. */
  auditFindingsByAuditFindingRuleType?: InputMaybe<AuditFindingRuleTypeToManyAuditFindingFilter>;
  /** Some related `auditFindingsByAuditFindingRuleType` exist. */
  auditFindingsByAuditFindingRuleTypeExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `author` field. */
  author?: InputMaybe<StringFilter>;
  /** Filter by the object’s `claimDataType` field. */
  claimDataType?: InputMaybe<AuditFindingSeedTypeFilter>;
  /** Filter by the object’s `deprecated` field. */
  deprecated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayName` field. */
  displayName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditFindingRuleTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditFindingRuleTypeFilter>>;
  /** Filter by the object’s `rationaleTemplate` field. */
  rationaleTemplate?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** Grouping methods for `AuditFindingRuleType` for usage during aggregation. */
export enum AuditFindingRuleTypeGroupBy {
  AUTHOR = "AUTHOR",
  CLAIM_DATA_TYPE = "CLAIM_DATA_TYPE",
  DEPRECATED = "DEPRECATED",
  DESCRIPTION = "DESCRIPTION",
  DISPLAY_NAME = "DISPLAY_NAME",
  RATIONALE_TEMPLATE = "RATIONALE_TEMPLATE",
}

/** Conditions for `AuditFindingRuleType` aggregates. */
export type AuditFindingRuleTypeHavingInput = {
  AND?: InputMaybe<Array<AuditFindingRuleTypeHavingInput>>;
  OR?: InputMaybe<Array<AuditFindingRuleTypeHavingInput>>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditFindingRuleTypeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditFindingRuleType` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditFindingRuleType` to look up the row to update. */
export type AuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyUsingAuditFindingRuleTypePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `auditFindingRuleType` being updated. */
    patch: updateAuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyPatch;
    type: Scalars["String"]["input"];
  };

/** Represents an update to a `AuditFindingRuleType`. Fields that are set will be updated. */
export type AuditFindingRuleTypePatch = {
  auditFindingsUsingType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInverseInput>;
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingRuleTypeToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A connection to a list of `AuditFindingRuleType` values. */
export type AuditFindingRuleTypesConnection = {
  __typename?: "AuditFindingRuleTypesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingRuleTypeAggregates>;
  /** A list of edges which contains the `AuditFindingRuleType` and cursor to aid in pagination. */
  edges: Array<AuditFindingRuleTypesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingRuleTypeAggregates>>;
  /** A list of `AuditFindingRuleType` objects. */
  nodes: Array<Maybe<AuditFindingRuleType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFindingRuleType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFindingRuleType` values. */
export type AuditFindingRuleTypesConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingRuleTypeGroupBy>;
  having?: InputMaybe<AuditFindingRuleTypeHavingInput>;
};

/** A `AuditFindingRuleType` edge in the connection. */
export type AuditFindingRuleTypesEdge = {
  __typename?: "AuditFindingRuleTypesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFindingRuleType` at the end of the edge. */
  node?: Maybe<AuditFindingRuleType>;
};

/** Methods to use when ordering `AuditFindingRuleType`. */
export enum AuditFindingRuleTypesOrderBy {
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_COUNT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_COUNT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_COUNT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_COUNT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MAX_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_MIN_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_SUM_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE__COUNT_ASC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE__COUNT_ASC",
  AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE__COUNT_DESC = "AUDIT_FINDINGS_BY_AUDIT_FINDING_RULE_TYPE__COUNT_DESC",
  AUTHOR_ASC = "AUTHOR_ASC",
  AUTHOR_DESC = "AUTHOR_DESC",
  CLAIM_DATA_TYPE_ASC = "CLAIM_DATA_TYPE_ASC",
  CLAIM_DATA_TYPE_DESC = "CLAIM_DATA_TYPE_DESC",
  DEPRECATED_ASC = "DEPRECATED_ASC",
  DEPRECATED_DESC = "DEPRECATED_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  DISPLAY_NAME_ASC = "DISPLAY_NAME_ASC",
  DISPLAY_NAME_DESC = "DISPLAY_NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  RATIONALE_TEMPLATE_ASC = "RATIONALE_TEMPLATE_ASC",
  RATIONALE_TEMPLATE_DESC = "RATIONALE_TEMPLATE_DESC",
  TYPE_ASC = "TYPE_ASC",
  TYPE_DESC = "TYPE_DESC",
}

export enum AuditFindingSeedType {
  /** Audit finding for an Itemlized Bill line item */
  IB_CLAIM_LINE = "IB_CLAIM_LINE",
  /** Audit finding for a UB04 claim */
  UB_CLAIM = "UB_CLAIM",
  /** Audit finding for a UB04 claim line */
  UB_CLAIM_LINE = "UB_CLAIM_LINE",
}

/** A filter to be used against AuditFindingSeedType fields. All fields are combined with a logical ‘and.’ */
export type AuditFindingSeedTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AuditFindingSeedType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AuditFindingSeedType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AuditFindingSeedType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AuditFindingSeedType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AuditFindingSeedType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AuditFindingSeedType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AuditFindingSeedType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AuditFindingSeedType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AuditFindingSeedType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AuditFindingSeedType>>;
};

export type AuditFindingStddevPopulationAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigFloatFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigFloatFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigFloatFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigFloatFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingStddevPopulationAggregates = {
  __typename?: "AuditFindingStddevPopulationAggregates";
  /** Population standard deviation of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditFindingStddevSampleAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigFloatFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigFloatFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigFloatFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigFloatFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingStddevSampleAggregates = {
  __typename?: "AuditFindingStddevSampleAggregates";
  /** Sample standard deviation of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditFindingSumAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigIntFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigIntFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigIntFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigIntFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigIntFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigIntFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigIntFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingSumAggregates = {
  __typename?: "AuditFindingSumAggregates";
  /** Sum of confidence across the matching connection */
  confidence: Scalars["BigFloat"]["output"];
  /** Sum of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sum of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sum of improperPaymentCost across the matching connection */
  improperPaymentCost: Scalars["Float"]["output"];
  /** Sum of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged: Scalars["BigInt"]["output"];
  /** Sum of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel: Scalars["BigInt"]["output"];
  /** Sum of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel: Scalars["BigInt"]["output"];
  /** Sum of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue: Scalars["BigInt"]["output"];
  /** Sum of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue: Scalars["BigInt"]["output"];
  /** Sum of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier: Scalars["BigInt"]["output"];
  /** Sum of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice: Scalars["BigFloat"]["output"];
  /** Sum of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits: Scalars["BigInt"]["output"];
  /** Sum of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A filter to be used against many `AuditFindingReasonCode` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingToManyAuditFindingReasonCodeFilter = {
  /** Aggregates across related `AuditFindingReasonCode` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReasonCodeAggregatesFilter>;
  /** Every related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** No related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** Some related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReasonCodeFilter>;
};

/** A filter to be used against many `AuditFindingReview` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingToManyAuditFindingReviewFilter = {
  /** Aggregates across related `AuditFindingReview` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReviewAggregatesFilter>;
  /** Every related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReviewFilter>;
  /** No related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReviewFilter>;
  /** Some related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReviewFilter>;
};

/** A filter to be used against many `ReportedFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingToManyReportedFindingFilter = {
  /** Aggregates across related `ReportedFinding` match the filter criteria. */
  aggregates?: InputMaybe<ReportedFindingAggregatesFilter>;
  /** Every related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReportedFindingFilter>;
  /** No related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReportedFindingFilter>;
  /** Some related `ReportedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReportedFindingFilter>;
};

/** A filter to be used against many `ReviewedAuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditFindingToManyReviewedAuditFindingFilter = {
  /** Aggregates across related `ReviewedAuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<ReviewedAuditFindingAggregatesFilter>;
  /** Every related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReviewedAuditFindingFilter>;
  /** No related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReviewedAuditFindingFilter>;
  /** Some related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReviewedAuditFindingFilter>;
};

export type AuditFindingVariancePopulationAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigFloatFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigFloatFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigFloatFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigFloatFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingVariancePopulationAggregates = {
  __typename?: "AuditFindingVariancePopulationAggregates";
  /** Population variance of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditFindingVarianceSampleAggregateFilter = {
  confidence?: InputMaybe<BigFloatFilter>;
  determinedClientSavings?: InputMaybe<FloatFilter>;
  discrepantAmount?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  improperPaymentUnitsCharged?: InputMaybe<BigFloatFilter>;
  metaDataEmCorrectLevel?: InputMaybe<BigFloatFilter>;
  metaDataEmOriginalLevel?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueAjudicationValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciMueOhpMueValue?: InputMaybe<BigFloatFilter>;
  metaDataNcciPtpModifier?: InputMaybe<BigFloatFilter>;
  metaDataPricingCorrectPrice?: InputMaybe<BigFloatFilter>;
  metaDataUnitsCorrectUnits?: InputMaybe<BigFloatFilter>;
  potentialClientSavings?: InputMaybe<FloatFilter>;
};

export type AuditFindingVarianceSampleAggregates = {
  __typename?: "AuditFindingVarianceSampleAggregates";
  /** Sample variance of confidence across the matching connection */
  confidence?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of this field across the matching connection. */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of this field across the matching connection. */
  discrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of improperPaymentCost across the matching connection */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of improperPaymentUnitsCharged across the matching connection */
  improperPaymentUnitsCharged?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataEmCorrectLevel across the matching connection */
  metaDataEmCorrectLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataEmOriginalLevel across the matching connection */
  metaDataEmOriginalLevel?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataNcciMueAjudicationValue across the matching connection */
  metaDataNcciMueAjudicationValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataNcciMueOhpMueValue across the matching connection */
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataNcciPtpModifier across the matching connection */
  metaDataNcciPtpModifier?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataPricingCorrectPrice across the matching connection */
  metaDataPricingCorrectPrice?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of metaDataUnitsCorrectUnits across the matching connection */
  metaDataUnitsCorrectUnits?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of this field across the matching connection. */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `AuditFinding` values. */
export type AuditFindingsConnection = {
  __typename?: "AuditFindingsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditFindingAggregates>;
  /** A list of edges which contains the `AuditFinding` and cursor to aid in pagination. */
  edges: Array<AuditFindingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditFindingAggregates>>;
  /** A list of `AuditFinding` objects. */
  nodes: Array<Maybe<AuditFinding>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditFinding` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditFinding` values. */
export type AuditFindingsConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditFindingGroupBy>;
  having?: InputMaybe<AuditFindingHavingInput>;
};

/** A `AuditFinding` edge in the connection. */
export type AuditFindingsEdge = {
  __typename?: "AuditFindingsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditFinding` at the end of the edge. */
  node?: Maybe<AuditFinding>;
};

/** Methods to use when ordering `AuditFinding`. */
export enum AuditFindingsOrderBy {
  AUDITOR_COMMENT_ASC = "AUDITOR_COMMENT_ASC",
  AUDITOR_COMMENT_DESC = "AUDITOR_COMMENT_DESC",
  AUDITOR_REVIEWED_ASC = "AUDITOR_REVIEWED_ASC",
  AUDITOR_REVIEWED_DESC = "AUDITOR_REVIEWED_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_COUNT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_COUNT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_COUNT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_COUNT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MAX_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_MIN_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_SUM_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID__COUNT_ASC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID__COUNT_ASC",
  AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID__COUNT_DESC = "AUDIT_FINDINGS_BY_PREVIOUS_VERSION_ID__COUNT_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_PREVIOUS_VERSION_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__CREATED_AT_ASC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__CREATED_AT_ASC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__CREATED_AT_DESC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__CREATED_AT_DESC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__ID_ASC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__ID_ASC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__ID_DESC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__ID_DESC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__UPDATED_AT_ASC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__UPDATED_AT_DESC = "AUDIT_FINDING_JOB_BY_AUDIT_FINDING_JOB_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_BY_AUDIT_FINDING_ID__COUNT_ASC = "AUDIT_FINDING_REASON_CODES_BY_AUDIT_FINDING_ID__COUNT_ASC",
  AUDIT_FINDING_REASON_CODES_BY_AUDIT_FINDING_ID__COUNT_DESC = "AUDIT_FINDING_REASON_CODES_BY_AUDIT_FINDING_ID__COUNT_DESC",
  AUDIT_FINDING_REASON_CODES_COUNT_ASC = "AUDIT_FINDING_REASON_CODES_COUNT_ASC",
  AUDIT_FINDING_REASON_CODES_COUNT_DESC = "AUDIT_FINDING_REASON_CODES_COUNT_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_COUNT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_COUNT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_COUNT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_COUNT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_REVIEW_ID_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_REVIEW_ID_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID__COUNT_ASC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID__COUNT_ASC",
  AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID__COUNT_DESC = "AUDIT_FINDING_REVIEWS_BY_ORIGINAL_ID__COUNT_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__CREATED_AT_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__CREATED_AT_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__CREATED_AT_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__CREATED_AT_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ID_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ID_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ID_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ID_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ORIGINAL_ID_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ORIGINAL_ID_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ORIGINAL_ID_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__ORIGINAL_ID_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__REVIEW_ID_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__REVIEW_ID_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__REVIEW_ID_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__REVIEW_ID_DESC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__UPDATED_AT_ASC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_REVIEW_BY_REVIEW_ID__UPDATED_AT_DESC = "AUDIT_FINDING_REVIEW_BY_REVIEW_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__AUTHOR_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__AUTHOR_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__AUTHOR_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__AUTHOR_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__CLAIM_DATA_TYPE_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__CLAIM_DATA_TYPE_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__CLAIM_DATA_TYPE_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__CLAIM_DATA_TYPE_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DEPRECATED_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DEPRECATED_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DEPRECATED_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DEPRECATED_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DESCRIPTION_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DESCRIPTION_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DESCRIPTION_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DESCRIPTION_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DISPLAY_NAME_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DISPLAY_NAME_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DISPLAY_NAME_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__DISPLAY_NAME_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__RATIONALE_TEMPLATE_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__RATIONALE_TEMPLATE_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__RATIONALE_TEMPLATE_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__RATIONALE_TEMPLATE_DESC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__TYPE_ASC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__TYPE_ASC",
  AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__TYPE_DESC = "AUDIT_FINDING_RULE_TYPE_BY_AUDIT_FINDING_RULE_TYPE__TYPE_DESC",
  AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_TYPE_ASC = "AUDIT_TYPE_ASC",
  AUDIT_TYPE_DESC = "AUDIT_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  AUTODOR_FINDING_ID_ASC = "AUTODOR_FINDING_ID_ASC",
  AUTODOR_FINDING_ID_DESC = "AUTODOR_FINDING_ID_DESC",
  AUTODOR_FINDING_S3_KEY_ASC = "AUTODOR_FINDING_S3_KEY_ASC",
  AUTODOR_FINDING_S3_KEY_DESC = "AUTODOR_FINDING_S3_KEY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDIT_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_COUNT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_COUNT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_COUNT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_COUNT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_AUDIT_FINDING_CLAIM_LINE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CONFIDENCE_ASC = "CONFIDENCE_ASC",
  CONFIDENCE_DESC = "CONFIDENCE_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IMPROPER_PAYMENT_COST_ASC = "IMPROPER_PAYMENT_COST_ASC",
  IMPROPER_PAYMENT_COST_DESC = "IMPROPER_PAYMENT_COST_DESC",
  IMPROPER_PAYMENT_REASON_ASC = "IMPROPER_PAYMENT_REASON_ASC",
  IMPROPER_PAYMENT_REASON_DESC = "IMPROPER_PAYMENT_REASON_DESC",
  IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  META_DATA_AOC_PRIMARY_CODE_ASC = "META_DATA_AOC_PRIMARY_CODE_ASC",
  META_DATA_AOC_PRIMARY_CODE_DESC = "META_DATA_AOC_PRIMARY_CODE_DESC",
  META_DATA_EM_CODE_ASC = "META_DATA_EM_CODE_ASC",
  META_DATA_EM_CODE_DESC = "META_DATA_EM_CODE_DESC",
  META_DATA_EM_CORRECT_LEVEL_ASC = "META_DATA_EM_CORRECT_LEVEL_ASC",
  META_DATA_EM_CORRECT_LEVEL_DESC = "META_DATA_EM_CORRECT_LEVEL_DESC",
  META_DATA_EM_ORIGINAL_LEVEL_ASC = "META_DATA_EM_ORIGINAL_LEVEL_ASC",
  META_DATA_EM_ORIGINAL_LEVEL_DESC = "META_DATA_EM_ORIGINAL_LEVEL_DESC",
  META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  META_DATA_NCCI_PTP_CODE_ASC = "META_DATA_NCCI_PTP_CODE_ASC",
  META_DATA_NCCI_PTP_CODE_DESC = "META_DATA_NCCI_PTP_CODE_DESC",
  META_DATA_NCCI_PTP_MODIFIER_ASC = "META_DATA_NCCI_PTP_MODIFIER_ASC",
  META_DATA_NCCI_PTP_MODIFIER_DESC = "META_DATA_NCCI_PTP_MODIFIER_DESC",
  META_DATA_PRICING_CORRECT_PRICE_ASC = "META_DATA_PRICING_CORRECT_PRICE_ASC",
  META_DATA_PRICING_CORRECT_PRICE_DESC = "META_DATA_PRICING_CORRECT_PRICE_DESC",
  META_DATA_UNITS_CORRECT_UNITS_ASC = "META_DATA_UNITS_CORRECT_UNITS_ASC",
  META_DATA_UNITS_CORRECT_UNITS_DESC = "META_DATA_UNITS_CORRECT_UNITS_DESC",
  NATURAL = "NATURAL",
  NEEDS_REVIEW_ASC = "NEEDS_REVIEW_ASC",
  NEEDS_REVIEW_DESC = "NEEDS_REVIEW_DESC",
  PREVIOUS_VERSION_ID_ASC = "PREVIOUS_VERSION_ID_ASC",
  PREVIOUS_VERSION_ID_DESC = "PREVIOUS_VERSION_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_CREATED_AT_ASC = "REPORTED_FINDINGS_AVERAGE_CREATED_AT_ASC",
  REPORTED_FINDINGS_AVERAGE_CREATED_AT_DESC = "REPORTED_FINDINGS_AVERAGE_CREATED_AT_DESC",
  REPORTED_FINDINGS_AVERAGE_ID_ASC = "REPORTED_FINDINGS_AVERAGE_ID_ASC",
  REPORTED_FINDINGS_AVERAGE_ID_DESC = "REPORTED_FINDINGS_AVERAGE_ID_DESC",
  REPORTED_FINDINGS_AVERAGE_UPDATED_AT_ASC = "REPORTED_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_AVERAGE_UPDATED_AT_DESC = "REPORTED_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  REPORTED_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_ASC = "REPORTED_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_ASC",
  REPORTED_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_DESC = "REPORTED_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_DESC",
  REPORTED_FINDINGS_COUNT_ASC = "REPORTED_FINDINGS_COUNT_ASC",
  REPORTED_FINDINGS_COUNT_DESC = "REPORTED_FINDINGS_COUNT_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_ID_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_ID_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_ID_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_ID_DESC",
  REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "REPORTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_MAX_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_MAX_CREATED_AT_ASC = "REPORTED_FINDINGS_MAX_CREATED_AT_ASC",
  REPORTED_FINDINGS_MAX_CREATED_AT_DESC = "REPORTED_FINDINGS_MAX_CREATED_AT_DESC",
  REPORTED_FINDINGS_MAX_ID_ASC = "REPORTED_FINDINGS_MAX_ID_ASC",
  REPORTED_FINDINGS_MAX_ID_DESC = "REPORTED_FINDINGS_MAX_ID_DESC",
  REPORTED_FINDINGS_MAX_UPDATED_AT_ASC = "REPORTED_FINDINGS_MAX_UPDATED_AT_ASC",
  REPORTED_FINDINGS_MAX_UPDATED_AT_DESC = "REPORTED_FINDINGS_MAX_UPDATED_AT_DESC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_MIN_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_MIN_CREATED_AT_ASC = "REPORTED_FINDINGS_MIN_CREATED_AT_ASC",
  REPORTED_FINDINGS_MIN_CREATED_AT_DESC = "REPORTED_FINDINGS_MIN_CREATED_AT_DESC",
  REPORTED_FINDINGS_MIN_ID_ASC = "REPORTED_FINDINGS_MIN_ID_ASC",
  REPORTED_FINDINGS_MIN_ID_DESC = "REPORTED_FINDINGS_MIN_ID_DESC",
  REPORTED_FINDINGS_MIN_UPDATED_AT_ASC = "REPORTED_FINDINGS_MIN_UPDATED_AT_ASC",
  REPORTED_FINDINGS_MIN_UPDATED_AT_DESC = "REPORTED_FINDINGS_MIN_UPDATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_ID_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_ID_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_ID_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_ID_DESC",
  REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_ID_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_ID_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "REPORTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_SUM_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_SUM_CREATED_AT_ASC = "REPORTED_FINDINGS_SUM_CREATED_AT_ASC",
  REPORTED_FINDINGS_SUM_CREATED_AT_DESC = "REPORTED_FINDINGS_SUM_CREATED_AT_DESC",
  REPORTED_FINDINGS_SUM_ID_ASC = "REPORTED_FINDINGS_SUM_ID_ASC",
  REPORTED_FINDINGS_SUM_ID_DESC = "REPORTED_FINDINGS_SUM_ID_DESC",
  REPORTED_FINDINGS_SUM_UPDATED_AT_ASC = "REPORTED_FINDINGS_SUM_UPDATED_AT_ASC",
  REPORTED_FINDINGS_SUM_UPDATED_AT_DESC = "REPORTED_FINDINGS_SUM_UPDATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_ID_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_ID_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "REPORTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_ASC = "REVIEWED_AUDIT_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_ASC",
  REVIEWED_AUDIT_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_DESC = "REVIEWED_AUDIT_FINDINGS_BY_AUDIT_FINDING_ID__COUNT_DESC",
  REVIEWED_AUDIT_FINDINGS_COUNT_ASC = "REVIEWED_AUDIT_FINDINGS_COUNT_ASC",
  REVIEWED_AUDIT_FINDINGS_COUNT_DESC = "REVIEWED_AUDIT_FINDINGS_COUNT_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_ID_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_ID_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_ID_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_ID_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_DESC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type AuditRule = {
  __typename?: "AuditRule";
  _type: Scalars["String"]["output"];
  genericRule: Scalars["JSON"]["output"];
  mue?: Maybe<MUEAuditRule>;
  nonBillable?: Maybe<NonBillableAuditRule>;
  ptp?: Maybe<PTPAuditRule>;
};

export enum AuditTypeEnum {
  ADD_ON_CODE = "ADD_ON_CODE",
  AGE_MODIFIER = "AGE_MODIFIER",
  CPT_ICD10 = "CPT_ICD10",
  CPT_MOD = "CPT_MOD",
  EM = "EM",
  GENDER_MODIFIER = "GENDER_MODIFIER",
  NCCI_MUE = "NCCI_MUE",
  NCCI_PTP = "NCCI_PTP",
  OTHER = "OTHER",
  PRICING = "PRICING",
  UNITS = "UNITS",
}

/** A filter to be used against AuditTypeEnum fields. All fields are combined with a logical ‘and.’ */
export type AuditTypeEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<AuditTypeEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<AuditTypeEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<AuditTypeEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<AuditTypeEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<AuditTypeEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<AuditTypeEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<AuditTypeEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<AuditTypeEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<AuditTypeEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<AuditTypeEnum>>;
};

export type AuditorBucket = Node & {
  __typename?: "AuditorBucket";
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  assignee?: Maybe<AuditorUser>;
  assigneeId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `AuditorUser`. */
  assignees: AuditorUsersConnection;
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindings: AuditFindingsConnection;
  /** Reads and enables pagination through a set of `AuditorBucketAssignee`. */
  auditorBucketAssignees: AuditorBucketAssigneesConnection;
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims: BatchClaimsConnection;
  /** Reads and enables pagination through a set of `BucketClaim`. */
  bucketClaims: BucketClaimsConnection;
  createdAt: Scalars["Datetime"]["output"];
  dueDate?: Maybe<Scalars["Datetime"]["output"]>;
  filter?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `Insurer` that is related to this `AuditorBucket`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `AuditorBucket`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditorBucketassigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<AuditorUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AuditorBucketauditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AuditorBucketauditorBucketAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketAssigneeCondition>;
  filter?: InputMaybe<AuditorBucketAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketAssigneesOrderBy>>;
};

export type AuditorBucketbatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AuditorBucketbucketClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BucketClaimCondition>;
  filter?: InputMaybe<BucketClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BucketClaimsOrderBy>>;
};

export type AuditorBucketimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketAggregates = {
  __typename?: "AuditorBucketAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<AuditorBucketAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditorBucketDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<AuditorBucketMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<AuditorBucketMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<AuditorBucketStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<AuditorBucketStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<AuditorBucketSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<AuditorBucketVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<AuditorBucketVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `AuditorBucket` object types. */
export type AuditorBucketAggregatesFilter = {
  /** Mean average aggregate over matching `AuditorBucket` objects. */
  average?: InputMaybe<AuditorBucketAverageAggregateFilter>;
  /** Distinct count aggregate over matching `AuditorBucket` objects. */
  distinctCount?: InputMaybe<AuditorBucketDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditorBucket` object to be included within the aggregate. */
  filter?: InputMaybe<AuditorBucketFilter>;
  /** Maximum aggregate over matching `AuditorBucket` objects. */
  max?: InputMaybe<AuditorBucketMaxAggregateFilter>;
  /** Minimum aggregate over matching `AuditorBucket` objects. */
  min?: InputMaybe<AuditorBucketMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `AuditorBucket` objects. */
  stddevPopulation?: InputMaybe<AuditorBucketStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `AuditorBucket` objects. */
  stddevSample?: InputMaybe<AuditorBucketStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `AuditorBucket` objects. */
  sum?: InputMaybe<AuditorBucketSumAggregateFilter>;
  /** Population variance aggregate over matching `AuditorBucket` objects. */
  variancePopulation?: InputMaybe<AuditorBucketVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `AuditorBucket` objects. */
  varianceSample?: InputMaybe<AuditorBucketVarianceSampleAggregateFilter>;
};

export type AuditorBucketAssignee = Node & {
  __typename?: "AuditorBucketAssignee";
  /** Reads a single `AuditorBucket` that is related to this `AuditorBucketAssignee`. */
  auditorBucket?: Maybe<AuditorBucket>;
  auditorBucketId: Scalars["UUID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `AuditorBucketAssignee`. */
  auditorUser?: Maybe<AuditorUser>;
  auditorUserId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  unassigned: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type AuditorBucketAssigneeAggregates = {
  __typename?: "AuditorBucketAssigneeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditorBucketAssigneeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditorBucketAssignee` object types. */
export type AuditorBucketAssigneeAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditorBucketAssignee` objects. */
  distinctCount?: InputMaybe<AuditorBucketAssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditorBucketAssignee` object to be included within the aggregate. */
  filter?: InputMaybe<AuditorBucketAssigneeFilter>;
};

/** The fields on `auditorBucketAssignee` to look up the row to connect. */
export type AuditorBucketAssigneeAuditorBucketAssigneePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `auditorBucketAssignee` to be created by this mutation. */
export type AuditorBucketAssigneeAuditorBucketIdFkeyAuditorBucketAssigneeCreateInput =
  {
    auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `auditorBucket` to be created by this mutation. */
export type AuditorBucketAssigneeAuditorBucketIdFkeyAuditorBucketCreateInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditorBucket` in the `AuditorBucketAssigneeInput` mutation. */
export type AuditorBucketAssigneeAuditorBucketIdFkeyInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorBucketAuditorBucketPkeyConnect>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorBucketNodeIdConnect>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyAuditorBucketCreateInput>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<AuditorBucketAuditorBucketPkeyDelete>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AuditorBucketNodeIdDelete>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyUsingAuditorBucketPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucketAssignee` in the `AuditorBucketInput` mutation. */
export type AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput = {
  /** The primary key(s) for `auditorBucketAssignee` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditorBucketAssigneeAuditorBucketAssigneePkeyConnect>
  >;
  /** The primary key(s) for `auditorBucketAssignee` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketAssigneeNodeIdConnect>>;
  /** A `AuditorBucketAssigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditorBucketAssigneeAuditorBucketIdFkeyAuditorBucketAssigneeCreateInput>
  >;
  /** The primary key(s) and patch data for `auditorBucketAssignee` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyUsingAuditorBucketAssigneePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucketAssignee` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyNodeIdUpdate>
  >;
};

/** The `auditorBucketAssignee` to be created by this mutation. */
export type AuditorBucketAssigneeAuditorUserIdFkeyAuditorBucketAssigneeCreateInput =
  {
    auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
    auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `auditorUser` to be created by this mutation. */
export type AuditorBucketAssigneeAuditorUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditorBucketAssigneeInput` mutation. */
export type AuditorBucketAssigneeAuditorUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucketAssignee` in the `AuditorUserInput` mutation. */
export type AuditorBucketAssigneeAuditorUserIdFkeyInverseInput = {
  /** The primary key(s) for `auditorBucketAssignee` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditorBucketAssigneeAuditorBucketAssigneePkeyConnect>
  >;
  /** The primary key(s) for `auditorBucketAssignee` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketAssigneeNodeIdConnect>>;
  /** A `AuditorBucketAssigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditorBucketAssigneeAuditorUserIdFkeyAuditorBucketAssigneeCreateInput>
  >;
  /** The primary key(s) and patch data for `auditorBucketAssignee` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorBucketAssigneePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucketAssignee` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `AuditorBucketAssignee` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AuditorBucketAssigneeCondition = {
  /** Checks for equality with the object’s `auditorBucketId` field. */
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `unassigned` field. */
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditorBucketAssigneeDistinctCountAggregateFilter = {
  auditorBucketId?: InputMaybe<BigIntFilter>;
  auditorUserId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  unassigned?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditorBucketAssigneeDistinctCountAggregates = {
  __typename?: "AuditorBucketAssigneeDistinctCountAggregates";
  /** Distinct count of auditorBucketId across the matching connection */
  auditorBucketId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditorUserId across the matching connection */
  auditorUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unassigned across the matching connection */
  unassigned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditorBucketAssignee` object types. All fields are combined with a logical ‘and.’ */
export type AuditorBucketAssigneeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditorBucketAssigneeFilter>>;
  /** Filter by the object’s `auditorBucket` relation. */
  auditorBucket?: InputMaybe<AuditorBucketFilter>;
  /** Filter by the object’s `auditorBucketId` field. */
  auditorBucketId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `auditorUser` relation. */
  auditorUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditorBucketAssigneeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditorBucketAssigneeFilter>>;
  /** Filter by the object’s `unassigned` field. */
  unassigned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditorBucketAssignee` for usage during aggregation. */
export enum AuditorBucketAssigneeGroupBy {
  AUDITOR_BUCKET_ID = "AUDITOR_BUCKET_ID",
  AUDITOR_USER_ID = "AUDITOR_USER_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UNASSIGNED = "UNASSIGNED",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditorBucketAssigneeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditorBucketAssignee` aggregates. */
export type AuditorBucketAssigneeHavingInput = {
  AND?: InputMaybe<Array<AuditorBucketAssigneeHavingInput>>;
  OR?: InputMaybe<Array<AuditorBucketAssigneeHavingInput>>;
  average?: InputMaybe<AuditorBucketAssigneeHavingAverageInput>;
  distinctCount?: InputMaybe<AuditorBucketAssigneeHavingDistinctCountInput>;
  max?: InputMaybe<AuditorBucketAssigneeHavingMaxInput>;
  min?: InputMaybe<AuditorBucketAssigneeHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditorBucketAssigneeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditorBucketAssigneeHavingStddevSampleInput>;
  sum?: InputMaybe<AuditorBucketAssigneeHavingSumInput>;
  variancePopulation?: InputMaybe<AuditorBucketAssigneeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditorBucketAssigneeHavingVarianceSampleInput>;
};

export type AuditorBucketAssigneeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketAssigneeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The `auditorBucket` to be created by this mutation. */
export type AuditorBucketAssigneeIdFkeyAuditorBucketCreateInput = {
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditorUser` to be created by this mutation. */
export type AuditorBucketAssigneeIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditorBucketInput` mutation. */
export type AuditorBucketAssigneeIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditorBucketAssigneeIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucket` in the `AuditorUserInput` mutation. */
export type AuditorBucketAssigneeIdFkeyInverseInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyConnect>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketNodeIdConnect>>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditorBucketAssigneeIdFkeyAuditorBucketCreateInput>
  >;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyDelete>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<AuditorBucketNodeIdDelete>>;
  /** Flag indicating whether all other `auditorBucket` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorBucketPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyNodeIdUpdate>
  >;
};

/** An input for mutations affecting `AuditorBucketAssignee` */
export type AuditorBucketAssigneeInput = {
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditorBucketAssigneeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditorBucketAssignee` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: AuditorBucketPatch;
  };

/** The fields on `auditorBucketAssignee` to look up the row to update. */
export type AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyUsingAuditorBucketAssigneePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
    patch: updateAuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditorBucketAssignee` to look up the row to update. */
export type AuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorBucketAssigneePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
    patch: updateAuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyPatch;
  };

/** Represents an update to a `AuditorBucketAssignee`. Fields that are set will be updated. */
export type AuditorBucketAssigneePatch = {
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
};

/** A connection to a list of `AuditorBucketAssignee` values. */
export type AuditorBucketAssigneesConnection = {
  __typename?: "AuditorBucketAssigneesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditorBucketAssigneeAggregates>;
  /** A list of edges which contains the `AuditorBucketAssignee` and cursor to aid in pagination. */
  edges: Array<AuditorBucketAssigneesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditorBucketAssigneeAggregates>>;
  /** A list of `AuditorBucketAssignee` objects. */
  nodes: Array<Maybe<AuditorBucketAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditorBucketAssignee` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditorBucketAssignee` values. */
export type AuditorBucketAssigneesConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditorBucketAssigneeGroupBy>;
  having?: InputMaybe<AuditorBucketAssigneeHavingInput>;
};

/** A `AuditorBucketAssignee` edge in the connection. */
export type AuditorBucketAssigneesEdge = {
  __typename?: "AuditorBucketAssigneesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditorBucketAssignee` at the end of the edge. */
  node?: Maybe<AuditorBucketAssignee>;
};

/** Methods to use when ordering `AuditorBucketAssignee`. */
export enum AuditorBucketAssigneesOrderBy {
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_DESC",
  AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC",
  AUDITOR_USER_ID_ASC = "AUDITOR_USER_ID_ASC",
  AUDITOR_USER_ID_DESC = "AUDITOR_USER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UNASSIGNED_ASC = "UNASSIGNED_ASC",
  UNASSIGNED_DESC = "UNASSIGNED_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** The fields on `auditorBucket` to look up the row to connect. */
export type AuditorBucketAuditorBucketPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `auditorBucket` to look up the row to delete. */
export type AuditorBucketAuditorBucketPkeyDelete = {
  id: Scalars["UUID"]["input"];
};

/** The `auditorBucket` to be created by this mutation. */
export type AuditorBucketAuthorIdFkeyAuditorBucketCreateInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditorUser` to be created by this mutation. */
export type AuditorBucketAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `AuditorBucketInput` mutation. */
export type AuditorBucketAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuditorBucketAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucket` in the `AuditorUserInput` mutation. */
export type AuditorBucketAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyConnect>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketNodeIdConnect>>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<AuditorBucketAuthorIdFkeyAuditorBucketCreateInput>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyDelete>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<AuditorBucketNodeIdDelete>>;
  /** Flag indicating whether all other `auditorBucket` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorBucketPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyNodeIdUpdate>
  >;
};

export type AuditorBucketAverageAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketAverageAggregates = {
  __typename?: "AuditorBucketAverageAggregates";
  /** Mean average of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketAverageAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * A condition to be used against `AuditorBucket` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuditorBucketCondition = {
  /** Checks for equality with the object’s `assigneeId` field. */
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `filter` field. */
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditorBucketDistinctCountAggregateFilter = {
  assigneeId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  dueDate?: InputMaybe<BigIntFilter>;
  filter?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  insurerId?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type AuditorBucketDistinctCountAggregates = {
  __typename?: "AuditorBucketDistinctCountAggregates";
  /** Distinct count of assigneeId across the matching connection */
  assigneeId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dueDate across the matching connection */
  dueDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of filter across the matching connection */
  filter?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

export type AuditorBucketDistinctCountAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against `AuditorBucket` object types. All fields are combined with a logical ‘and.’ */
export type AuditorBucketFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditorBucketFilter>>;
  /** Filter by the object’s `assignee` relation. */
  assignee?: InputMaybe<AuditorUserFilter>;
  /** A related `assignee` exists. */
  assigneeExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `assigneeId` field. */
  assigneeId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `auditorBucketAssignees` relation. */
  auditorBucketAssignees?: InputMaybe<AuditorBucketToManyAuditorBucketAssigneeFilter>;
  /** Some related `auditorBucketAssignees` exist. */
  auditorBucketAssigneesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `bucketClaims` relation. */
  bucketClaims?: InputMaybe<AuditorBucketToManyBucketClaimFilter>;
  /** Some related `bucketClaims` exist. */
  bucketClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `filter` field. */
  filter?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `improperPaymentCost` field. */
  improperPaymentCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuditorBucketFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditorBucketFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `AuditorBucket` for usage during aggregation. */
export enum AuditorBucketGroupBy {
  ASSIGNEE_ID = "ASSIGNEE_ID",
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DUE_DATE = "DUE_DATE",
  DUE_DATE_TRUNCATED_TO_DAY = "DUE_DATE_TRUNCATED_TO_DAY",
  DUE_DATE_TRUNCATED_TO_HOUR = "DUE_DATE_TRUNCATED_TO_HOUR",
  FILTER = "FILTER",
  INSURER_ID = "INSURER_ID",
  NAME = "NAME",
  PROVIDER_ID = "PROVIDER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type AuditorBucketHavingAverageAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingAverageAuditorBucketImproperPaymentCostInput = {
  args?: InputMaybe<AuditorBucketHavingAverageAuditorBucketImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditorBucketHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingAverageAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingDistinctCountAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingDistinctCountAuditorBucketImproperPaymentCostInput =
  {
    args?: InputMaybe<AuditorBucketHavingDistinctCountAuditorBucketImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditorBucketHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingDistinctCountAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditorBucket` aggregates. */
export type AuditorBucketHavingInput = {
  AND?: InputMaybe<Array<AuditorBucketHavingInput>>;
  OR?: InputMaybe<Array<AuditorBucketHavingInput>>;
  average?: InputMaybe<AuditorBucketHavingAverageInput>;
  distinctCount?: InputMaybe<AuditorBucketHavingDistinctCountInput>;
  max?: InputMaybe<AuditorBucketHavingMaxInput>;
  min?: InputMaybe<AuditorBucketHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditorBucketHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditorBucketHavingStddevSampleInput>;
  sum?: InputMaybe<AuditorBucketHavingSumInput>;
  variancePopulation?: InputMaybe<AuditorBucketHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditorBucketHavingVarianceSampleInput>;
};

export type AuditorBucketHavingMaxAuditorBucketImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketHavingMaxAuditorBucketImproperPaymentCostInput = {
  args?: InputMaybe<AuditorBucketHavingMaxAuditorBucketImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditorBucketHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingMaxAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingMinAuditorBucketImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketHavingMinAuditorBucketImproperPaymentCostInput = {
  args?: InputMaybe<AuditorBucketHavingMinAuditorBucketImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditorBucketHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingMinAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingStddevPopulationAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingStddevPopulationAuditorBucketImproperPaymentCostInput =
  {
    args?: InputMaybe<AuditorBucketHavingStddevPopulationAuditorBucketImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditorBucketHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingStddevPopulationAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingStddevSampleAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingStddevSampleAuditorBucketImproperPaymentCostInput =
  {
    args?: InputMaybe<AuditorBucketHavingStddevSampleAuditorBucketImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditorBucketHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingStddevSampleAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingSumAuditorBucketImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketHavingSumAuditorBucketImproperPaymentCostInput = {
  args?: InputMaybe<AuditorBucketHavingSumAuditorBucketImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type AuditorBucketHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingSumAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingVariancePopulationAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingVariancePopulationAuditorBucketImproperPaymentCostInput =
  {
    args?: InputMaybe<AuditorBucketHavingVariancePopulationAuditorBucketImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditorBucketHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingVariancePopulationAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorBucketHavingVarianceSampleAuditorBucketImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type AuditorBucketHavingVarianceSampleAuditorBucketImproperPaymentCostInput =
  {
    args?: InputMaybe<AuditorBucketHavingVarianceSampleAuditorBucketImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type AuditorBucketHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<AuditorBucketHavingVarianceSampleAuditorBucketImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AuditorBucket` */
export type AuditorBucketInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `auditorBucket` to be created by this mutation. */
export type AuditorBucketInsurerIdFkeyAuditorBucketCreateInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `insurer` in the `AuditorBucketInput` mutation. */
export type AuditorBucketInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucket` in the `InsurerInput` mutation. */
export type AuditorBucketInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyConnect>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketNodeIdConnect>>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuditorBucketInsurerIdFkeyAuditorBucketCreateInput>
  >;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyDelete>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<AuditorBucketNodeIdDelete>>;
  /** Flag indicating whether all other `auditorBucket` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingAuditorBucketPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyNodeIdUpdate>
  >;
};

export type AuditorBucketMaxAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketMaxAggregates = {
  __typename?: "AuditorBucketMaxAggregates";
  /** Maximum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketMaxAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketMinAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketMinAggregates = {
  __typename?: "AuditorBucketMinAggregates";
  /** Minimum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketMinAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditorBucketNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to delete. */
export type AuditorBucketNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `auditorBucket` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucketAssignee` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
    patch: AuditorBucketAssigneePatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `bucketClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: BucketClaimPatch;
  };

/** The fields on `auditorBucket` to look up the row to update. */
export type AuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingAuditorBucketPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: updateAuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch;
  };

/** Represents an update to a `AuditorBucket`. Fields that are set will be updated. */
export type AuditorBucketPatch = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type AuditorBucketStddevPopulationAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketStddevPopulationAggregates = {
  __typename?: "AuditorBucketStddevPopulationAggregates";
  /** Population standard deviation of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketStddevPopulationAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketStddevSampleAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketStddevSampleAggregates = {
  __typename?: "AuditorBucketStddevSampleAggregates";
  /** Sample standard deviation of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketStddevSampleAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketSumAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketSumAggregates = {
  __typename?: "AuditorBucketSumAggregates";
  /** Sum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketSumAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against many `AuditorBucketAssignee` object types. All fields are combined with a logical ‘and.’ */
export type AuditorBucketToManyAuditorBucketAssigneeFilter = {
  /** Aggregates across related `AuditorBucketAssignee` match the filter criteria. */
  aggregates?: InputMaybe<AuditorBucketAssigneeAggregatesFilter>;
  /** Every related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorBucketAssigneeFilter>;
  /** No related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorBucketAssigneeFilter>;
  /** Some related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorBucketAssigneeFilter>;
};

/** A filter to be used against many `BucketClaim` object types. All fields are combined with a logical ‘and.’ */
export type AuditorBucketToManyBucketClaimFilter = {
  /** Aggregates across related `BucketClaim` match the filter criteria. */
  aggregates?: InputMaybe<BucketClaimAggregatesFilter>;
  /** Every related `BucketClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BucketClaimFilter>;
  /** No related `BucketClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BucketClaimFilter>;
  /** Some related `BucketClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BucketClaimFilter>;
};

export type AuditorBucketVariancePopulationAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketVariancePopulationAggregates = {
  __typename?: "AuditorBucketVariancePopulationAggregates";
  /** Population variance of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketVariancePopulationAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AuditorBucketVarianceSampleAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type AuditorBucketVarianceSampleAggregates = {
  __typename?: "AuditorBucketVarianceSampleAggregates";
  /** Sample variance of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type AuditorBucketVarianceSampleAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `AuditorBucket` values. */
export type AuditorBucketsConnection = {
  __typename?: "AuditorBucketsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditorBucketAggregates>;
  /** A list of edges which contains the `AuditorBucket` and cursor to aid in pagination. */
  edges: Array<AuditorBucketsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditorBucketAggregates>>;
  /** A list of `AuditorBucket` objects. */
  nodes: Array<Maybe<AuditorBucket>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditorBucket` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditorBucket` values. */
export type AuditorBucketsConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditorBucketGroupBy>;
  having?: InputMaybe<AuditorBucketHavingInput>;
};

/** A `AuditorBucket` edge in the connection. */
export type AuditorBucketsEdge = {
  __typename?: "AuditorBucketsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditorBucket` at the end of the edge. */
  node?: Maybe<AuditorBucket>;
};

/** Methods to use when ordering `AuditorBucket`. */
export enum AuditorBucketsOrderBy {
  ASSIGNEE_ID_ASC = "ASSIGNEE_ID_ASC",
  ASSIGNEE_ID_DESC = "ASSIGNEE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_BUCKET_ID__COUNT_ASC = "AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_BUCKET_ID__COUNT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_BUCKET_ID__COUNT_DESC = "AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_BUCKET_ID__COUNT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_COUNT_ASC = "AUDITOR_BUCKET_ASSIGNEES_COUNT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_COUNT_DESC = "AUDITOR_BUCKET_ASSIGNEES_COUNT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__EMAIL_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__EMAIL_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__EMAIL_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__EMAIL_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__ID_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__ID_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__ID_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__ID_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__TITLE_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__TITLE_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__TITLE_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__TITLE_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_ASSIGNEE_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_ASSIGNEE_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_ASSIGNEE_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_ASSIGNEE_ID__USER_TYPE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BUCKET_CLAIMS_AVERAGE_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_AVERAGE_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_AVERAGE_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_AVERAGE_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_AVERAGE_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_AVERAGE_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_AVERAGE_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_AVERAGE_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_AVERAGE_CREATED_AT_ASC = "BUCKET_CLAIMS_AVERAGE_CREATED_AT_ASC",
  BUCKET_CLAIMS_AVERAGE_CREATED_AT_DESC = "BUCKET_CLAIMS_AVERAGE_CREATED_AT_DESC",
  BUCKET_CLAIMS_AVERAGE_ID_ASC = "BUCKET_CLAIMS_AVERAGE_ID_ASC",
  BUCKET_CLAIMS_AVERAGE_ID_DESC = "BUCKET_CLAIMS_AVERAGE_ID_DESC",
  BUCKET_CLAIMS_AVERAGE_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_AVERAGE_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_AVERAGE_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_AVERAGE_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_AVERAGE_STATUS_ASC = "BUCKET_CLAIMS_AVERAGE_STATUS_ASC",
  BUCKET_CLAIMS_AVERAGE_STATUS_DESC = "BUCKET_CLAIMS_AVERAGE_STATUS_DESC",
  BUCKET_CLAIMS_AVERAGE_UPDATED_AT_ASC = "BUCKET_CLAIMS_AVERAGE_UPDATED_AT_ASC",
  BUCKET_CLAIMS_AVERAGE_UPDATED_AT_DESC = "BUCKET_CLAIMS_AVERAGE_UPDATED_AT_DESC",
  BUCKET_CLAIMS_BY_AUDITOR_BUCKET_ID__COUNT_ASC = "BUCKET_CLAIMS_BY_AUDITOR_BUCKET_ID__COUNT_ASC",
  BUCKET_CLAIMS_BY_AUDITOR_BUCKET_ID__COUNT_DESC = "BUCKET_CLAIMS_BY_AUDITOR_BUCKET_ID__COUNT_DESC",
  BUCKET_CLAIMS_COUNT_ASC = "BUCKET_CLAIMS_COUNT_ASC",
  BUCKET_CLAIMS_COUNT_DESC = "BUCKET_CLAIMS_COUNT_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_ID_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_ID_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_ID_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_ID_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_STATUS_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_STATUS_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_STATUS_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_STATUS_DESC",
  BUCKET_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BUCKET_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BUCKET_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BUCKET_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BUCKET_CLAIMS_MAX_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_MAX_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_MAX_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_MAX_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_MAX_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_MAX_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_MAX_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_MAX_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_MAX_CREATED_AT_ASC = "BUCKET_CLAIMS_MAX_CREATED_AT_ASC",
  BUCKET_CLAIMS_MAX_CREATED_AT_DESC = "BUCKET_CLAIMS_MAX_CREATED_AT_DESC",
  BUCKET_CLAIMS_MAX_ID_ASC = "BUCKET_CLAIMS_MAX_ID_ASC",
  BUCKET_CLAIMS_MAX_ID_DESC = "BUCKET_CLAIMS_MAX_ID_DESC",
  BUCKET_CLAIMS_MAX_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_MAX_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_MAX_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_MAX_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_MAX_STATUS_ASC = "BUCKET_CLAIMS_MAX_STATUS_ASC",
  BUCKET_CLAIMS_MAX_STATUS_DESC = "BUCKET_CLAIMS_MAX_STATUS_DESC",
  BUCKET_CLAIMS_MAX_UPDATED_AT_ASC = "BUCKET_CLAIMS_MAX_UPDATED_AT_ASC",
  BUCKET_CLAIMS_MAX_UPDATED_AT_DESC = "BUCKET_CLAIMS_MAX_UPDATED_AT_DESC",
  BUCKET_CLAIMS_MIN_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_MIN_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_MIN_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_MIN_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_MIN_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_MIN_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_MIN_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_MIN_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_MIN_CREATED_AT_ASC = "BUCKET_CLAIMS_MIN_CREATED_AT_ASC",
  BUCKET_CLAIMS_MIN_CREATED_AT_DESC = "BUCKET_CLAIMS_MIN_CREATED_AT_DESC",
  BUCKET_CLAIMS_MIN_ID_ASC = "BUCKET_CLAIMS_MIN_ID_ASC",
  BUCKET_CLAIMS_MIN_ID_DESC = "BUCKET_CLAIMS_MIN_ID_DESC",
  BUCKET_CLAIMS_MIN_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_MIN_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_MIN_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_MIN_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_MIN_STATUS_ASC = "BUCKET_CLAIMS_MIN_STATUS_ASC",
  BUCKET_CLAIMS_MIN_STATUS_DESC = "BUCKET_CLAIMS_MIN_STATUS_DESC",
  BUCKET_CLAIMS_MIN_UPDATED_AT_ASC = "BUCKET_CLAIMS_MIN_UPDATED_AT_ASC",
  BUCKET_CLAIMS_MIN_UPDATED_AT_DESC = "BUCKET_CLAIMS_MIN_UPDATED_AT_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_ID_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_ID_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_ID_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_ID_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_STATUS_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_STATUS_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_STATUS_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_STATUS_DESC",
  BUCKET_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BUCKET_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BUCKET_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BUCKET_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_ID_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_ID_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_STATUS_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_STATUS_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_STATUS_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_STATUS_DESC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BUCKET_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BUCKET_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BUCKET_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BUCKET_CLAIMS_SUM_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_SUM_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_SUM_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_SUM_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_SUM_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_SUM_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_SUM_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_SUM_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_SUM_CREATED_AT_ASC = "BUCKET_CLAIMS_SUM_CREATED_AT_ASC",
  BUCKET_CLAIMS_SUM_CREATED_AT_DESC = "BUCKET_CLAIMS_SUM_CREATED_AT_DESC",
  BUCKET_CLAIMS_SUM_ID_ASC = "BUCKET_CLAIMS_SUM_ID_ASC",
  BUCKET_CLAIMS_SUM_ID_DESC = "BUCKET_CLAIMS_SUM_ID_DESC",
  BUCKET_CLAIMS_SUM_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_SUM_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_SUM_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_SUM_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_SUM_STATUS_ASC = "BUCKET_CLAIMS_SUM_STATUS_ASC",
  BUCKET_CLAIMS_SUM_STATUS_DESC = "BUCKET_CLAIMS_SUM_STATUS_DESC",
  BUCKET_CLAIMS_SUM_UPDATED_AT_ASC = "BUCKET_CLAIMS_SUM_UPDATED_AT_ASC",
  BUCKET_CLAIMS_SUM_UPDATED_AT_DESC = "BUCKET_CLAIMS_SUM_UPDATED_AT_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_ID_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_ID_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_STATUS_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_STATUS_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_STATUS_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_STATUS_DESC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BUCKET_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BUCKET_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BUCKET_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_SELECTION_METHOD_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_SELECTION_METHOD_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_SELECTION_METHOD_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_SELECTION_METHOD_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_STATUS_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_STATUS_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_STATUS_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_STATUS_DESC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BUCKET_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BUCKET_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DUE_DATE_ASC = "DUE_DATE_ASC",
  DUE_DATE_DESC = "DUE_DATE_DESC",
  FILTER_ASC = "FILTER_ASC",
  FILTER_DESC = "FILTER_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type AuditorUser = Node & {
  __typename?: "AuditorUser";
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  auditFindingReportsByPrimaryPiaId: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `AuditorBucketAssignee`. */
  auditorBucketAssignees: AuditorBucketAssigneesConnection;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  auditorBucketsByAssigneeId: AuditorBucketsConnection;
  /** Reads and enables pagination through a set of `AuditFindingJob`. */
  authoredAuditFindingJobs: AuditFindingJobsConnection;
  /** Reads and enables pagination through a set of `AuditFindingReasonCode`. */
  authoredAuditFindingReasonCodes: AuditFindingReasonCodesConnection;
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  authoredAuditFindingReports: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `AuditFindingReview`. */
  authoredAuditFindingReviews: AuditFindingReviewsConnection;
  /** Reads and enables pagination through a set of `AuditFinding`. */
  authoredAuditFindings: AuditFindingsConnection;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  authoredAuditorBuckets: AuditorBucketsConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItemAudit`. */
  authoredBatchClaimLineItemAudits: BatchClaimLineItemAuditsConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItemNnIbLine`. */
  authoredBatchClaimLineItemNnIbLines: BatchClaimLineItemNnIbLinesConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItem`. */
  authoredBatchClaimLineItems: BatchClaimLineItemsConnection;
  /** Reads and enables pagination through a set of `BatchClaim`. */
  authoredBatchClaims: BatchClaimsConnection;
  /** Reads and enables pagination through a set of `BatchFile`. */
  authoredBatchFiles: BatchFilesConnection;
  /** Reads and enables pagination through a set of `DisputedFinding`. */
  authoredDisputedFindings: DisputedFindingsConnection;
  /** Reads and enables pagination through a set of `DocumentUploadCreated`. */
  authoredDocumentUploadCreateds: DocumentUploadCreatedsConnection;
  /** Reads and enables pagination through a set of `NnIbLine`. */
  authoredNnIbLines: NnIbLinesConnection;
  /** Reads and enables pagination through a set of `Queue`. */
  authoredQueues: QueuesConnection;
  /** Reads and enables pagination through a set of `ReviewedAuditFinding`. */
  authoredReviewedAuditFindings: ReviewedAuditFindingsConnection;
  /** Reads and enables pagination through a set of `BatchClaimAssignee`. */
  batchClaimAssignees: BatchClaimAssigneesConnection;
  claimIntakeEnabled: Scalars["Boolean"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `InsurerUser`. */
  insurerUsers: InsurerUsersConnection;
  internalOnly?: Maybe<Scalars["Boolean"]["output"]>;
  lastName: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `MessageCreated`. */
  messageCreatedsBySenderUserId: MessageCreatedsConnection;
  /** Reads and enables pagination through a set of `MessageSent`. */
  messageSentsByRecipientUserId: MessageSentsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `AuditorUser`. */
  provider?: Maybe<Provider>;
  providerId?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `ProviderUserFacility`. */
  providerUserFacilities: ProviderUserFacilitiesConnection;
  readOnly?: Maybe<Scalars["Boolean"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
  userCognitoSettings: UserCognitoSettings;
  /** Reads and enables pagination through a set of `UserInviteCreated`. */
  userInviteCreateds: UserInviteCreatedsConnection;
  userType: UserTypeEnum;
};

export type AuditorUserauditFindingReportsByPrimaryPiaIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

export type AuditorUserauditorBucketAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketAssigneeCondition>;
  filter?: InputMaybe<AuditorBucketAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketAssigneesOrderBy>>;
};

export type AuditorUserauditorBucketsByAssigneeIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketCondition>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

export type AuditorUserauthoredAuditFindingJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingJobCondition>;
  filter?: InputMaybe<AuditFindingJobFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingJobsOrderBy>>;
};

export type AuditorUserauthoredAuditFindingReasonCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReasonCodeCondition>;
  filter?: InputMaybe<AuditFindingReasonCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
};

export type AuditorUserauthoredAuditFindingReportsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

export type AuditorUserauthoredAuditFindingReviewsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReviewCondition>;
  filter?: InputMaybe<AuditFindingReviewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReviewsOrderBy>>;
};

export type AuditorUserauthoredAuditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

export type AuditorUserauthoredAuditorBucketsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketCondition>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

export type AuditorUserauthoredBatchClaimLineItemAuditsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemAuditCondition>;
  filter?: InputMaybe<BatchClaimLineItemAuditFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemAuditsOrderBy>>;
};

export type AuditorUserauthoredBatchClaimLineItemNnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemNnIbLineCondition>;
  filter?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
};

export type AuditorUserauthoredBatchClaimLineItemsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemCondition>;
  filter?: InputMaybe<BatchClaimLineItemFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

export type AuditorUserauthoredBatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimCondition>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

export type AuditorUserauthoredBatchFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchFileCondition>;
  filter?: InputMaybe<BatchFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchFilesOrderBy>>;
};

export type AuditorUserauthoredDisputedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DisputedFindingCondition>;
  filter?: InputMaybe<DisputedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DisputedFindingsOrderBy>>;
};

export type AuditorUserauthoredDocumentUploadCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentUploadCreatedCondition>;
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

export type AuditorUserauthoredNnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NnIbLineCondition>;
  filter?: InputMaybe<NnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NnIbLinesOrderBy>>;
};

export type AuditorUserauthoredQueuesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<QueueCondition>;
  filter?: InputMaybe<QueueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<QueuesOrderBy>>;
};

export type AuditorUserauthoredReviewedAuditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReviewedAuditFindingCondition>;
  filter?: InputMaybe<ReviewedAuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReviewedAuditFindingsOrderBy>>;
};

export type AuditorUserbatchClaimAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimAssigneeCondition>;
  filter?: InputMaybe<BatchClaimAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimAssigneesOrderBy>>;
};

export type AuditorUserinsurerUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerUserCondition>;
  filter?: InputMaybe<InsurerUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerUsersOrderBy>>;
};

export type AuditorUsermessageCreatedsBySenderUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCreatedCondition>;
  filter?: InputMaybe<MessageCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageCreatedsOrderBy>>;
};

export type AuditorUsermessageSentsByRecipientUserIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageSentCondition>;
  filter?: InputMaybe<MessageSentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

export type AuditorUserproviderUserFacilitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderUserFacilityCondition>;
  filter?: InputMaybe<ProviderUserFacilityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderUserFacilitiesOrderBy>>;
};

export type AuditorUseruserInviteCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserInviteCreatedCondition>;
  filter?: InputMaybe<UserInviteCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserInviteCreatedsOrderBy>>;
};

export type AuditorUserAggregates = {
  __typename?: "AuditorUserAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuditorUserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `AuditorUser` object types. */
export type AuditorUserAggregatesFilter = {
  /** Distinct count aggregate over matching `AuditorUser` objects. */
  distinctCount?: InputMaybe<AuditorUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `AuditorUser` object to be included within the aggregate. */
  filter?: InputMaybe<AuditorUserFilter>;
};

/** The fields on `auditorUser` to look up the row to connect. */
export type AuditorUserAuditorUserEmailUniqueKeyConnect = {
  email: Scalars["String"]["input"];
};

/** The fields on `auditorUser` to look up the row to connect. */
export type AuditorUserAuditorUserPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `AuditorUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuditorUserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `internalOnly` field. */
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `readOnly` field. */
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `title` field. */
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `userType` field. */
  userType?: InputMaybe<UserTypeEnum>;
};

export type AuditorUserDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  email?: InputMaybe<BigIntFilter>;
  firstName?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  internalOnly?: InputMaybe<BigIntFilter>;
  lastName?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  readOnly?: InputMaybe<BigIntFilter>;
  title?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  userType?: InputMaybe<BigIntFilter>;
};

export type AuditorUserDistinctCountAggregates = {
  __typename?: "AuditorUserDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of firstName across the matching connection */
  firstName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of internalOnly across the matching connection */
  internalOnly?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastName across the matching connection */
  lastName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of readOnly across the matching connection */
  readOnly?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of title across the matching connection */
  title?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of userType across the matching connection */
  userType?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `AuditorUser` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuditorUserFilter>>;
  /** Filter by the object’s `auditFindingReportsByPrimaryPiaId` relation. */
  auditFindingReportsByPrimaryPiaId?: InputMaybe<AuditorUserToManyAuditFindingReportFilter>;
  /** Some related `auditFindingReportsByPrimaryPiaId` exist. */
  auditFindingReportsByPrimaryPiaIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `auditorBucketAssignees` relation. */
  auditorBucketAssignees?: InputMaybe<AuditorUserToManyAuditorBucketAssigneeFilter>;
  /** Some related `auditorBucketAssignees` exist. */
  auditorBucketAssigneesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditorBucketsByAssigneeId` relation. */
  auditorBucketsByAssigneeId?: InputMaybe<AuditorUserToManyAuditorBucketFilter>;
  /** Some related `auditorBucketsByAssigneeId` exist. */
  auditorBucketsByAssigneeIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredAuditFindingJobs` relation. */
  authoredAuditFindingJobs?: InputMaybe<AuditorUserToManyAuditFindingJobFilter>;
  /** Some related `authoredAuditFindingJobs` exist. */
  authoredAuditFindingJobsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredAuditFindingReasonCodes` relation. */
  authoredAuditFindingReasonCodes?: InputMaybe<AuditorUserToManyAuditFindingReasonCodeFilter>;
  /** Some related `authoredAuditFindingReasonCodes` exist. */
  authoredAuditFindingReasonCodesExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `authoredAuditFindingReports` relation. */
  authoredAuditFindingReports?: InputMaybe<AuditorUserToManyAuditFindingReportFilter>;
  /** Some related `authoredAuditFindingReports` exist. */
  authoredAuditFindingReportsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredAuditFindingReviews` relation. */
  authoredAuditFindingReviews?: InputMaybe<AuditorUserToManyAuditFindingReviewFilter>;
  /** Some related `authoredAuditFindingReviews` exist. */
  authoredAuditFindingReviewsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredAuditFindings` relation. */
  authoredAuditFindings?: InputMaybe<AuditorUserToManyAuditFindingFilter>;
  /** Some related `authoredAuditFindings` exist. */
  authoredAuditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredAuditorBuckets` relation. */
  authoredAuditorBuckets?: InputMaybe<AuditorUserToManyAuditorBucketFilter>;
  /** Some related `authoredAuditorBuckets` exist. */
  authoredAuditorBucketsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredBatchClaimLineItemAudits` relation. */
  authoredBatchClaimLineItemAudits?: InputMaybe<AuditorUserToManyBatchClaimLineItemAuditFilter>;
  /** Some related `authoredBatchClaimLineItemAudits` exist. */
  authoredBatchClaimLineItemAuditsExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `authoredBatchClaimLineItemNnIbLines` relation. */
  authoredBatchClaimLineItemNnIbLines?: InputMaybe<AuditorUserToManyBatchClaimLineItemNnIbLineFilter>;
  /** Some related `authoredBatchClaimLineItemNnIbLines` exist. */
  authoredBatchClaimLineItemNnIbLinesExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `authoredBatchClaimLineItems` relation. */
  authoredBatchClaimLineItems?: InputMaybe<AuditorUserToManyBatchClaimLineItemFilter>;
  /** Some related `authoredBatchClaimLineItems` exist. */
  authoredBatchClaimLineItemsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredBatchClaims` relation. */
  authoredBatchClaims?: InputMaybe<AuditorUserToManyBatchClaimFilter>;
  /** Some related `authoredBatchClaims` exist. */
  authoredBatchClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredBatchFiles` relation. */
  authoredBatchFiles?: InputMaybe<AuditorUserToManyBatchFileFilter>;
  /** Some related `authoredBatchFiles` exist. */
  authoredBatchFilesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredDisputedFindings` relation. */
  authoredDisputedFindings?: InputMaybe<AuditorUserToManyDisputedFindingFilter>;
  /** Some related `authoredDisputedFindings` exist. */
  authoredDisputedFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredDocumentUploadCreateds` relation. */
  authoredDocumentUploadCreateds?: InputMaybe<AuditorUserToManyDocumentUploadCreatedFilter>;
  /** Some related `authoredDocumentUploadCreateds` exist. */
  authoredDocumentUploadCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredNnIbLines` relation. */
  authoredNnIbLines?: InputMaybe<AuditorUserToManyNnIbLineFilter>;
  /** Some related `authoredNnIbLines` exist. */
  authoredNnIbLinesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredQueues` relation. */
  authoredQueues?: InputMaybe<AuditorUserToManyQueueFilter>;
  /** Some related `authoredQueues` exist. */
  authoredQueuesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authoredReviewedAuditFindings` relation. */
  authoredReviewedAuditFindings?: InputMaybe<AuditorUserToManyReviewedAuditFindingFilter>;
  /** Some related `authoredReviewedAuditFindings` exist. */
  authoredReviewedAuditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimAssignees` relation. */
  batchClaimAssignees?: InputMaybe<AuditorUserToManyBatchClaimAssigneeFilter>;
  /** Some related `batchClaimAssignees` exist. */
  batchClaimAssigneesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurerUsers` relation. */
  insurerUsers?: InputMaybe<AuditorUserToManyInsurerUserFilter>;
  /** Some related `insurerUsers` exist. */
  insurerUsersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `internalOnly` field. */
  internalOnly?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageCreatedsBySenderUserId` relation. */
  messageCreatedsBySenderUserId?: InputMaybe<AuditorUserToManyMessageCreatedFilter>;
  /** Some related `messageCreatedsBySenderUserId` exist. */
  messageCreatedsBySenderUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `messageSentsByRecipientUserId` relation. */
  messageSentsByRecipientUserId?: InputMaybe<AuditorUserToManyMessageSentFilter>;
  /** Some related `messageSentsByRecipientUserId` exist. */
  messageSentsByRecipientUserIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<AuditorUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuditorUserFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** A related `provider` exists. */
  providerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerUserFacilities` relation. */
  providerUserFacilities?: InputMaybe<AuditorUserToManyProviderUserFacilityFilter>;
  /** Some related `providerUserFacilities` exist. */
  providerUserFacilitiesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `readOnly` field. */
  readOnly?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `title` field. */
  title?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userInviteCreateds` relation. */
  userInviteCreateds?: InputMaybe<AuditorUserToManyUserInviteCreatedFilter>;
  /** Some related `userInviteCreateds` exist. */
  userInviteCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `userType` field. */
  userType?: InputMaybe<UserTypeEnumFilter>;
};

/** Grouping methods for `AuditorUser` for usage during aggregation. */
export enum AuditorUserGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  FIRST_NAME = "FIRST_NAME",
  INTERNAL_ONLY = "INTERNAL_ONLY",
  LAST_NAME = "LAST_NAME",
  PROVIDER_ID = "PROVIDER_ID",
  READ_ONLY = "READ_ONLY",
  TITLE = "TITLE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
  USER_TYPE = "USER_TYPE",
}

export type AuditorUserHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuditorUser` aggregates. */
export type AuditorUserHavingInput = {
  AND?: InputMaybe<Array<AuditorUserHavingInput>>;
  OR?: InputMaybe<Array<AuditorUserHavingInput>>;
  average?: InputMaybe<AuditorUserHavingAverageInput>;
  distinctCount?: InputMaybe<AuditorUserHavingDistinctCountInput>;
  max?: InputMaybe<AuditorUserHavingMaxInput>;
  min?: InputMaybe<AuditorUserHavingMinInput>;
  stddevPopulation?: InputMaybe<AuditorUserHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuditorUserHavingStddevSampleInput>;
  sum?: InputMaybe<AuditorUserHavingSumInput>;
  variancePopulation?: InputMaybe<AuditorUserHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuditorUserHavingVarianceSampleInput>;
};

export type AuditorUserHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuditorUserHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `AuditorUser` */
export type AuditorUserInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuditorUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingJob` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: AuditFindingJobPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReasonCode` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: AuditFindingReasonCodePatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReview` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReview` being updated. */
    patch: AuditFindingReviewPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucketAssignee` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
    patch: AuditorBucketAssigneePatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: AuditorBucketPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: AuditorBucketPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `provider` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `provider` being updated. */
  patch: ProviderPatch;
};

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorUserForAuditorUserProviderIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnAuditorUserForAuditorUserProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimAssignee` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
    patch: BatchClaimAssigneePatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchClaim` being updated. */
  patch: BatchClaimPatch;
};

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemAudit` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: BatchClaimLineItemAuditPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemNnIbLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: BatchClaimLineItemNnIbLinePatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnBatchFileForBatchFileAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchFile` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchFile` being updated. */
  patch: BatchFilePatch;
};

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchFileForBatchFileAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchFileForBatchFileAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnBatchFileForBatchFileAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnBatchFileForBatchFileAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `disputedFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: DisputedFindingPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: DocumentUploadCreatedPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurerUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurerUser` being updated. */
    patch: InsurerUserPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageSent` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: MessageSentPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `nnIbLine` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `nnIbLine` being updated. */
  patch: NnIbLinePatch;
};

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `providerUserFacility` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: ProviderUserFacilityPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnQueueForQueueAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `queue` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `queue` being updated. */
  patch: QueuePatch;
};

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnQueueForQueueAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnQueueForQueueAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnQueueForQueueAuthorIdFkeyUsingAuditorUserPkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: updateAuditorUserOnQueueForQueueAuthorIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `reviewedAuditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
    patch: ReviewedAuditFindingPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userInviteCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `userInviteCreated` being updated. */
    patch: UserInviteCreatedPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate =
  {
    email: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyPatch;
  };

/** The fields on `auditorUser` to look up the row to update. */
export type AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingAuditorUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: updateAuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyPatch;
  };

/** Represents an update to a `AuditorUser`. Fields that are set will be updated. */
export type AuditorUserPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** The `auditorUser` to be created by this mutation. */
export type AuditorUserProviderIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `provider` in the `AuditorUserInput` mutation. */
export type AuditorUserProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnAuditorUserForAuditorUserProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorUser` in the `ProviderInput` mutation. */
export type AuditorUserProviderIdFkeyInverseInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<
    Array<AuditorUserAuditorUserEmailUniqueKeyConnect>
  >;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditorUserAuditorUserPkeyConnect>>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorUserNodeIdConnect>>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<AuditorUserProviderIdFkeyAuditorUserCreateInput>>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<
    Array<AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorUserOnAuditorUserForAuditorUserProviderIdFkeyUsingAuditorUserPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnAuditorUserForAuditorUserProviderIdFkeyNodeIdUpdate>
  >;
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A filter to be used against many `AuditFindingJob` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditFindingJobFilter = {
  /** Aggregates across related `AuditFindingJob` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingJobAggregatesFilter>;
  /** Every related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingJobFilter>;
  /** No related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingJobFilter>;
  /** Some related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingJobFilter>;
};

/** A filter to be used against many `AuditFindingReasonCode` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditFindingReasonCodeFilter = {
  /** Aggregates across related `AuditFindingReasonCode` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReasonCodeAggregatesFilter>;
  /** Every related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** No related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** Some related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReasonCodeFilter>;
};

/** A filter to be used against many `AuditFindingReport` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditFindingReportFilter = {
  /** Aggregates across related `AuditFindingReport` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReportAggregatesFilter>;
  /** Every related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReportFilter>;
  /** No related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReportFilter>;
  /** Some related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReportFilter>;
};

/** A filter to be used against many `AuditFindingReview` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditFindingReviewFilter = {
  /** Aggregates across related `AuditFindingReview` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReviewAggregatesFilter>;
  /** Every related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReviewFilter>;
  /** No related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReviewFilter>;
  /** Some related `AuditFindingReview` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReviewFilter>;
};

/** A filter to be used against many `AuditorBucketAssignee` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditorBucketAssigneeFilter = {
  /** Aggregates across related `AuditorBucketAssignee` match the filter criteria. */
  aggregates?: InputMaybe<AuditorBucketAssigneeAggregatesFilter>;
  /** Every related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorBucketAssigneeFilter>;
  /** No related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorBucketAssigneeFilter>;
  /** Some related `AuditorBucketAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorBucketAssigneeFilter>;
};

/** A filter to be used against many `AuditorBucket` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyAuditorBucketFilter = {
  /** Aggregates across related `AuditorBucket` match the filter criteria. */
  aggregates?: InputMaybe<AuditorBucketAggregatesFilter>;
  /** Every related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorBucketFilter>;
  /** No related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorBucketFilter>;
  /** Some related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorBucketFilter>;
};

/** A filter to be used against many `BatchClaimAssignee` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchClaimAssigneeFilter = {
  /** Aggregates across related `BatchClaimAssignee` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAssigneeAggregatesFilter>;
  /** Every related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimAssigneeFilter>;
  /** No related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimAssigneeFilter>;
  /** Some related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimAssigneeFilter>;
};

/** A filter to be used against many `BatchClaim` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchClaimFilter = {
  /** Aggregates across related `BatchClaim` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAggregatesFilter>;
  /** Every related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimFilter>;
  /** No related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimFilter>;
  /** Some related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimFilter>;
};

/** A filter to be used against many `BatchClaimLineItemAudit` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchClaimLineItemAuditFilter = {
  /** Aggregates across related `BatchClaimLineItemAudit` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemAuditAggregatesFilter>;
  /** Every related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** No related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** Some related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemAuditFilter>;
};

/** A filter to be used against many `BatchClaimLineItem` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchClaimLineItemFilter = {
  /** Aggregates across related `BatchClaimLineItem` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemAggregatesFilter>;
  /** Every related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemFilter>;
  /** No related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemFilter>;
  /** Some related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemFilter>;
};

/** A filter to be used against many `BatchClaimLineItemNnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchClaimLineItemNnIbLineFilter = {
  /** Aggregates across related `BatchClaimLineItemNnIbLine` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemNnIbLineAggregatesFilter>;
  /** Every related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** No related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** Some related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
};

/** A filter to be used against many `BatchFile` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyBatchFileFilter = {
  /** Aggregates across related `BatchFile` match the filter criteria. */
  aggregates?: InputMaybe<BatchFileAggregatesFilter>;
  /** Every related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchFileFilter>;
  /** No related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchFileFilter>;
  /** Some related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchFileFilter>;
};

/** A filter to be used against many `DisputedFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyDisputedFindingFilter = {
  /** Aggregates across related `DisputedFinding` match the filter criteria. */
  aggregates?: InputMaybe<DisputedFindingAggregatesFilter>;
  /** Every related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DisputedFindingFilter>;
  /** No related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DisputedFindingFilter>;
  /** Some related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DisputedFindingFilter>;
};

/** A filter to be used against many `DocumentUploadCreated` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyDocumentUploadCreatedFilter = {
  /** Aggregates across related `DocumentUploadCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentUploadCreatedAggregatesFilter>;
  /** Every related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentUploadCreatedFilter>;
  /** No related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentUploadCreatedFilter>;
  /** Some related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentUploadCreatedFilter>;
};

/** A filter to be used against many `InsurerUser` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyInsurerUserFilter = {
  /** Aggregates across related `InsurerUser` match the filter criteria. */
  aggregates?: InputMaybe<InsurerUserAggregatesFilter>;
  /** Every related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<InsurerUserFilter>;
  /** No related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<InsurerUserFilter>;
  /** Some related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<InsurerUserFilter>;
};

/** A filter to be used against many `MessageCreated` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyMessageCreatedFilter = {
  /** Aggregates across related `MessageCreated` match the filter criteria. */
  aggregates?: InputMaybe<MessageCreatedAggregatesFilter>;
  /** Every related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageCreatedFilter>;
  /** No related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageCreatedFilter>;
  /** Some related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageCreatedFilter>;
};

/** A filter to be used against many `MessageSent` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyMessageSentFilter = {
  /** Aggregates across related `MessageSent` match the filter criteria. */
  aggregates?: InputMaybe<MessageSentAggregatesFilter>;
  /** Every related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageSentFilter>;
  /** No related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageSentFilter>;
  /** Some related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageSentFilter>;
};

/** A filter to be used against many `NnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyNnIbLineFilter = {
  /** Aggregates across related `NnIbLine` match the filter criteria. */
  aggregates?: InputMaybe<NnIbLineAggregatesFilter>;
  /** Every related `NnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NnIbLineFilter>;
  /** No related `NnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NnIbLineFilter>;
  /** Some related `NnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NnIbLineFilter>;
};

/** A filter to be used against many `ProviderUserFacility` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyProviderUserFacilityFilter = {
  /** Aggregates across related `ProviderUserFacility` match the filter criteria. */
  aggregates?: InputMaybe<ProviderUserFacilityAggregatesFilter>;
  /** Every related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProviderUserFacilityFilter>;
  /** No related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProviderUserFacilityFilter>;
  /** Some related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProviderUserFacilityFilter>;
};

/** A filter to be used against many `Queue` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyQueueFilter = {
  /** Aggregates across related `Queue` match the filter criteria. */
  aggregates?: InputMaybe<QueueAggregatesFilter>;
  /** Every related `Queue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<QueueFilter>;
  /** No related `Queue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<QueueFilter>;
  /** Some related `Queue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<QueueFilter>;
};

/** A filter to be used against many `ReviewedAuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyReviewedAuditFindingFilter = {
  /** Aggregates across related `ReviewedAuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<ReviewedAuditFindingAggregatesFilter>;
  /** Every related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReviewedAuditFindingFilter>;
  /** No related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReviewedAuditFindingFilter>;
  /** Some related `ReviewedAuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReviewedAuditFindingFilter>;
};

/** A filter to be used against many `UserInviteCreated` object types. All fields are combined with a logical ‘and.’ */
export type AuditorUserToManyUserInviteCreatedFilter = {
  /** Aggregates across related `UserInviteCreated` match the filter criteria. */
  aggregates?: InputMaybe<UserInviteCreatedAggregatesFilter>;
  /** Every related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserInviteCreatedFilter>;
  /** No related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserInviteCreatedFilter>;
  /** Some related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserInviteCreatedFilter>;
};

/** A connection to a list of `AuditorUser` values. */
export type AuditorUsersConnection = {
  __typename?: "AuditorUsersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuditorUserAggregates>;
  /** A list of edges which contains the `AuditorUser` and cursor to aid in pagination. */
  edges: Array<AuditorUsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuditorUserAggregates>>;
  /** A list of `AuditorUser` objects. */
  nodes: Array<Maybe<AuditorUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuditorUser` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `AuditorUser` values. */
export type AuditorUsersConnectiongroupedAggregatesArgs = {
  groupBy: Array<AuditorUserGroupBy>;
  having?: InputMaybe<AuditorUserHavingInput>;
};

/** A `AuditorUser` edge in the connection. */
export type AuditorUsersEdge = {
  __typename?: "AuditorUsersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `AuditorUser` at the end of the edge. */
  node?: Maybe<AuditorUser>;
};

/** Methods to use when ordering `AuditorUser`. */
export enum AuditorUsersOrderBy {
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_COUNT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_COUNT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_COUNT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_COUNT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MAX_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_MIN_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_SUM_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID__COUNT_ASC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID__COUNT_ASC",
  AUDITOR_BUCKETS_BY_ASSIGNEE_ID__COUNT_DESC = "AUDITOR_BUCKETS_BY_ASSIGNEE_ID__COUNT_DESC",
  AUDITOR_BUCKETS_BY_AUTHOR_ID__COUNT_ASC = "AUDITOR_BUCKETS_BY_AUTHOR_ID__COUNT_ASC",
  AUDITOR_BUCKETS_BY_AUTHOR_ID__COUNT_DESC = "AUDITOR_BUCKETS_BY_AUTHOR_ID__COUNT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_ASC = "AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_DESC = "AUDITOR_BUCKET_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_COUNT_ASC = "AUDITOR_BUCKET_ASSIGNEES_COUNT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_COUNT_DESC = "AUDITOR_BUCKET_ASSIGNEES_COUNT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MAX_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_MIN_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_SUM_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_BUCKET_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKET_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_ASC = "AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_ASC",
  AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_DESC = "AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_DESC",
  AUDIT_FINDING_JOBS_BY_AUTHOR_ID__COUNT_ASC = "AUDIT_FINDING_JOBS_BY_AUTHOR_ID__COUNT_ASC",
  AUDIT_FINDING_JOBS_BY_AUTHOR_ID__COUNT_DESC = "AUDIT_FINDING_JOBS_BY_AUTHOR_ID__COUNT_DESC",
  AUDIT_FINDING_REASON_CODES_BY_AUTHOR_ID__COUNT_ASC = "AUDIT_FINDING_REASON_CODES_BY_AUTHOR_ID__COUNT_ASC",
  AUDIT_FINDING_REASON_CODES_BY_AUTHOR_ID__COUNT_DESC = "AUDIT_FINDING_REASON_CODES_BY_AUTHOR_ID__COUNT_DESC",
  AUDIT_FINDING_REPORTS_BY_AUTHOR_ID__COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_AUTHOR_ID__COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_AUTHOR_ID__COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_AUTHOR_ID__COUNT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_AVERAGE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_COUNT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_DISTINCT_COUNT_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MAX_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_MIN_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_STDDEV_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_SUM_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID_VARIANCE_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID__COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID__COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID__COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_PRIMARY_PIA_ID__COUNT_DESC",
  AUDIT_FINDING_REVIEWS_BY_AUTHOR_ID__COUNT_ASC = "AUDIT_FINDING_REVIEWS_BY_AUTHOR_ID__COUNT_ASC",
  AUDIT_FINDING_REVIEWS_BY_AUTHOR_ID__COUNT_DESC = "AUDIT_FINDING_REVIEWS_BY_AUTHOR_ID__COUNT_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_COUNT_ASC = "AUTHORED_AUDITOR_BUCKETS_COUNT_ASC",
  AUTHORED_AUDITOR_BUCKETS_COUNT_DESC = "AUTHORED_AUDITOR_BUCKETS_COUNT_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_COUNT_ASC = "AUTHORED_AUDIT_FINDINGS_COUNT_ASC",
  AUTHORED_AUDIT_FINDINGS_COUNT_DESC = "AUTHORED_AUDIT_FINDINGS_COUNT_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_COUNT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_COUNT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_COUNT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_COUNT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_COUNT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_COUNT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_COUNT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_COUNT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_COUNT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_COUNT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_COUNT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_COUNT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_COUNT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_COUNT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_COUNT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_COUNT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MAX_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MAX_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_MIN_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_MIN_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_SUM_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_SUM_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ORIGINAL_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ORIGINAL_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ORIGINAL_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_ORIGINAL_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_REVIEW_ID_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_REVIEW_ID_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_REVIEW_ID_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_REVIEW_ID_DESC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_AUDIT_FINDING_REVIEWS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_AVERAGE_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_COUNT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_COUNT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_COUNT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_COUNT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_DISTINCT_COUNT_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MAX_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MAX_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_MIN_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_MIN_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_POPULATION_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_STDDEV_SAMPLE_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_SUM_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_SUM_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_POPULATION_UDPATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_INPUT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_INPUT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_INPUT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_INPUT_DESC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_UDPATED_AT_ASC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_UDPATED_AT_ASC",
  AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_UDPATED_AT_DESC = "AUTHORED_AUDIT_SELECTION_JOBS_VARIANCE_SAMPLE_UDPATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ICN_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ICN_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_COUNT_ASC = "AUTHORED_BATCH_CLAIMS_COUNT_ASC",
  AUTHORED_BATCH_CLAIMS_COUNT_DESC = "AUTHORED_BATCH_CLAIMS_COUNT_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_MAX_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_MAX_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_ICN_ASC = "AUTHORED_BATCH_CLAIMS_MAX_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_ICN_DESC = "AUTHORED_BATCH_CLAIMS_MAX_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MAX_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MAX_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MAX_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_MAX_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_MAX_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_MAX_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_MIN_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_MIN_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_ICN_ASC = "AUTHORED_BATCH_CLAIMS_MIN_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_ICN_DESC = "AUTHORED_BATCH_CLAIMS_MIN_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_MIN_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_MIN_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_MIN_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_MIN_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_MIN_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_MIN_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_SUM_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_SUM_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_ICN_ASC = "AUTHORED_BATCH_CLAIMS_SUM_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_ICN_DESC = "AUTHORED_BATCH_CLAIMS_SUM_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_SUM_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_SUM_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_SUM_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_SUM_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_SUM_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_SUM_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_COUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_COUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_COUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_COUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_AVERAGE_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_ID_ASC = "AUTHORED_BATCH_FILES_AVERAGE_ID_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_ID_DESC = "AUTHORED_BATCH_FILES_AVERAGE_ID_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_AVERAGE_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_AVERAGE_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_AVERAGE_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_AVERAGE_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_COUNT_ASC = "AUTHORED_BATCH_FILES_COUNT_ASC",
  AUTHORED_BATCH_FILES_COUNT_DESC = "AUTHORED_BATCH_FILES_COUNT_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_ID_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_ID_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_MAX_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_MAX_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_MAX_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_MAX_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_MAX_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_MAX_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_MAX_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_MAX_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_MAX_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_MAX_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_MAX_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_MAX_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_MAX_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_MAX_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_MAX_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_MAX_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_MAX_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_MAX_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_MAX_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_MAX_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_MAX_ID_ASC = "AUTHORED_BATCH_FILES_MAX_ID_ASC",
  AUTHORED_BATCH_FILES_MAX_ID_DESC = "AUTHORED_BATCH_FILES_MAX_ID_DESC",
  AUTHORED_BATCH_FILES_MAX_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_MAX_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_MAX_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_MAX_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_MAX_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_MAX_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_MAX_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_MAX_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_MAX_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_MAX_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_MAX_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_MAX_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_MIN_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_MIN_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_MIN_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_MIN_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_MIN_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_MIN_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_MIN_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_MIN_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_MIN_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_MIN_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_MIN_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_MIN_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_MIN_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_MIN_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_MIN_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_MIN_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_MIN_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_MIN_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_MIN_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_MIN_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_MIN_ID_ASC = "AUTHORED_BATCH_FILES_MIN_ID_ASC",
  AUTHORED_BATCH_FILES_MIN_ID_DESC = "AUTHORED_BATCH_FILES_MIN_ID_DESC",
  AUTHORED_BATCH_FILES_MIN_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_MIN_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_MIN_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_MIN_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_MIN_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_MIN_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_MIN_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_MIN_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_MIN_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_MIN_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_MIN_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_MIN_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_SUM_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_SUM_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_SUM_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_SUM_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_SUM_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_SUM_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_SUM_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_SUM_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_SUM_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_SUM_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_SUM_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_SUM_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_SUM_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_SUM_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_SUM_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_SUM_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_SUM_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_SUM_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_SUM_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_SUM_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_SUM_ID_ASC = "AUTHORED_BATCH_FILES_SUM_ID_ASC",
  AUTHORED_BATCH_FILES_SUM_ID_DESC = "AUTHORED_BATCH_FILES_SUM_ID_DESC",
  AUTHORED_BATCH_FILES_SUM_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_SUM_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_SUM_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_SUM_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_SUM_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_SUM_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_SUM_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_SUM_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_SUM_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_SUM_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_SUM_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_SUM_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_DESC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_ID_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_ID_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_ID_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_ID_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_AVERAGE_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_COUNT_ASC = "AUTHORED_CLAIM_CASES_COUNT_ASC",
  AUTHORED_CLAIM_CASES_COUNT_DESC = "AUTHORED_CLAIM_CASES_COUNT_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_ID_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_ID_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_DISTINCT_COUNT_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_MAX_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_MAX_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_MAX_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_MAX_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_MAX_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_MAX_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_MAX_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_MAX_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_MAX_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_MAX_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_MAX_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_MAX_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_MAX_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_MAX_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_MAX_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_MAX_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_MAX_ID_ASC = "AUTHORED_CLAIM_CASES_MAX_ID_ASC",
  AUTHORED_CLAIM_CASES_MAX_ID_DESC = "AUTHORED_CLAIM_CASES_MAX_ID_DESC",
  AUTHORED_CLAIM_CASES_MAX_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_MAX_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_MAX_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_MAX_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_MAX_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_MAX_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_MAX_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_MAX_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_MIN_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_MIN_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_MIN_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_MIN_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_MIN_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_MIN_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_MIN_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_MIN_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_MIN_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_MIN_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_MIN_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_MIN_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_MIN_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_MIN_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_MIN_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_MIN_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_MIN_ID_ASC = "AUTHORED_CLAIM_CASES_MIN_ID_ASC",
  AUTHORED_CLAIM_CASES_MIN_ID_DESC = "AUTHORED_CLAIM_CASES_MIN_ID_DESC",
  AUTHORED_CLAIM_CASES_MIN_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_MIN_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_MIN_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_MIN_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_MIN_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_MIN_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_MIN_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_MIN_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_STDDEV_POPULATION_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_STDDEV_SAMPLE_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_SUM_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_SUM_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_SUM_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_SUM_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_SUM_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_SUM_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_SUM_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_SUM_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_SUM_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_SUM_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_SUM_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_SUM_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_SUM_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_SUM_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_SUM_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_SUM_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_SUM_ID_ASC = "AUTHORED_CLAIM_CASES_SUM_ID_ASC",
  AUTHORED_CLAIM_CASES_SUM_ID_DESC = "AUTHORED_CLAIM_CASES_SUM_ID_DESC",
  AUTHORED_CLAIM_CASES_SUM_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_SUM_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_SUM_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_SUM_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_SUM_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_SUM_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_SUM_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_SUM_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_POPULATION_UPDATED_BY_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CASE_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CASE_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CASE_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CASE_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CLAIM_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CLAIM_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CLAIM_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CLAIM_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_BY_ASC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_BY_ASC",
  AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_BY_DESC = "AUTHORED_CLAIM_CASES_VARIANCE_SAMPLE_UPDATED_BY_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_COUNT_ASC = "AUTHORED_DISPUTED_FINDINGS_COUNT_ASC",
  AUTHORED_DISPUTED_FINDINGS_COUNT_DESC = "AUTHORED_DISPUTED_FINDINGS_COUNT_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUTHORED_DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_ID_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_ID_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_ID_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_ID_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_COUNT_ASC = "AUTHORED_NN_IB_LINES_COUNT_ASC",
  AUTHORED_NN_IB_LINES_COUNT_DESC = "AUTHORED_NN_IB_LINES_COUNT_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_ID_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_ID_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_MAX_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_MAX_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_MAX_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_MAX_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_MAX_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_MAX_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_MAX_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_MAX_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_MAX_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_MAX_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_MAX_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_MAX_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_MAX_ID_ASC = "AUTHORED_NN_IB_LINES_MAX_ID_ASC",
  AUTHORED_NN_IB_LINES_MAX_ID_DESC = "AUTHORED_NN_IB_LINES_MAX_ID_DESC",
  AUTHORED_NN_IB_LINES_MAX_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_MAX_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_MAX_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_MAX_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_MAX_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_MAX_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_MAX_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_MAX_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_MIN_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_MIN_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_MIN_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_MIN_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_MIN_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_MIN_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_MIN_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_MIN_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_MIN_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_MIN_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_MIN_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_MIN_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_MIN_ID_ASC = "AUTHORED_NN_IB_LINES_MIN_ID_ASC",
  AUTHORED_NN_IB_LINES_MIN_ID_DESC = "AUTHORED_NN_IB_LINES_MIN_ID_DESC",
  AUTHORED_NN_IB_LINES_MIN_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_MIN_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_MIN_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_MIN_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_MIN_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_MIN_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_MIN_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_MIN_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_ID_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_ID_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_SUM_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_SUM_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_SUM_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_SUM_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_SUM_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_SUM_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_SUM_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_SUM_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_SUM_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_SUM_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_SUM_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_SUM_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_SUM_ID_ASC = "AUTHORED_NN_IB_LINES_SUM_ID_ASC",
  AUTHORED_NN_IB_LINES_SUM_ID_DESC = "AUTHORED_NN_IB_LINES_SUM_ID_DESC",
  AUTHORED_NN_IB_LINES_SUM_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_SUM_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_SUM_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_SUM_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_SUM_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_SUM_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_SUM_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_SUM_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_FINGERPRINT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_FINGERPRINT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_FINGERPRINT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_FINGERPRINT_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_S3_KEY_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_S3_KEY_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_S3_KEY_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_S3_KEY_DESC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_QUEUES_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_QUEUES_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_QUEUES_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_AVERAGE_CREATED_AT_ASC = "AUTHORED_QUEUES_AVERAGE_CREATED_AT_ASC",
  AUTHORED_QUEUES_AVERAGE_CREATED_AT_DESC = "AUTHORED_QUEUES_AVERAGE_CREATED_AT_DESC",
  AUTHORED_QUEUES_AVERAGE_DELETED_ASC = "AUTHORED_QUEUES_AVERAGE_DELETED_ASC",
  AUTHORED_QUEUES_AVERAGE_DELETED_DESC = "AUTHORED_QUEUES_AVERAGE_DELETED_DESC",
  AUTHORED_QUEUES_AVERAGE_DESCRIPTION_ASC = "AUTHORED_QUEUES_AVERAGE_DESCRIPTION_ASC",
  AUTHORED_QUEUES_AVERAGE_DESCRIPTION_DESC = "AUTHORED_QUEUES_AVERAGE_DESCRIPTION_DESC",
  AUTHORED_QUEUES_AVERAGE_FILTER_ASC = "AUTHORED_QUEUES_AVERAGE_FILTER_ASC",
  AUTHORED_QUEUES_AVERAGE_FILTER_DESC = "AUTHORED_QUEUES_AVERAGE_FILTER_DESC",
  AUTHORED_QUEUES_AVERAGE_ID_ASC = "AUTHORED_QUEUES_AVERAGE_ID_ASC",
  AUTHORED_QUEUES_AVERAGE_ID_DESC = "AUTHORED_QUEUES_AVERAGE_ID_DESC",
  AUTHORED_QUEUES_AVERAGE_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_AVERAGE_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_AVERAGE_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_AVERAGE_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_AVERAGE_NAME_ASC = "AUTHORED_QUEUES_AVERAGE_NAME_ASC",
  AUTHORED_QUEUES_AVERAGE_NAME_DESC = "AUTHORED_QUEUES_AVERAGE_NAME_DESC",
  AUTHORED_QUEUES_AVERAGE_UPDATED_AT_ASC = "AUTHORED_QUEUES_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_QUEUES_AVERAGE_UPDATED_AT_DESC = "AUTHORED_QUEUES_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_QUEUES_COUNT_ASC = "AUTHORED_QUEUES_COUNT_ASC",
  AUTHORED_QUEUES_COUNT_DESC = "AUTHORED_QUEUES_COUNT_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_DELETED_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_DELETED_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_DELETED_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_DELETED_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_DESCRIPTION_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_DESCRIPTION_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_DESCRIPTION_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_DESCRIPTION_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_FILTER_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_FILTER_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_FILTER_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_FILTER_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_ID_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_ID_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_ID_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_ID_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_NAME_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_NAME_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_NAME_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_NAME_DESC",
  AUTHORED_QUEUES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_QUEUES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_QUEUES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_QUEUES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_QUEUES_MAX_AUTHOR_ID_ASC = "AUTHORED_QUEUES_MAX_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_MAX_AUTHOR_ID_DESC = "AUTHORED_QUEUES_MAX_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_MAX_CREATED_AT_ASC = "AUTHORED_QUEUES_MAX_CREATED_AT_ASC",
  AUTHORED_QUEUES_MAX_CREATED_AT_DESC = "AUTHORED_QUEUES_MAX_CREATED_AT_DESC",
  AUTHORED_QUEUES_MAX_DELETED_ASC = "AUTHORED_QUEUES_MAX_DELETED_ASC",
  AUTHORED_QUEUES_MAX_DELETED_DESC = "AUTHORED_QUEUES_MAX_DELETED_DESC",
  AUTHORED_QUEUES_MAX_DESCRIPTION_ASC = "AUTHORED_QUEUES_MAX_DESCRIPTION_ASC",
  AUTHORED_QUEUES_MAX_DESCRIPTION_DESC = "AUTHORED_QUEUES_MAX_DESCRIPTION_DESC",
  AUTHORED_QUEUES_MAX_FILTER_ASC = "AUTHORED_QUEUES_MAX_FILTER_ASC",
  AUTHORED_QUEUES_MAX_FILTER_DESC = "AUTHORED_QUEUES_MAX_FILTER_DESC",
  AUTHORED_QUEUES_MAX_ID_ASC = "AUTHORED_QUEUES_MAX_ID_ASC",
  AUTHORED_QUEUES_MAX_ID_DESC = "AUTHORED_QUEUES_MAX_ID_DESC",
  AUTHORED_QUEUES_MAX_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_MAX_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_MAX_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_MAX_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_MAX_NAME_ASC = "AUTHORED_QUEUES_MAX_NAME_ASC",
  AUTHORED_QUEUES_MAX_NAME_DESC = "AUTHORED_QUEUES_MAX_NAME_DESC",
  AUTHORED_QUEUES_MAX_UPDATED_AT_ASC = "AUTHORED_QUEUES_MAX_UPDATED_AT_ASC",
  AUTHORED_QUEUES_MAX_UPDATED_AT_DESC = "AUTHORED_QUEUES_MAX_UPDATED_AT_DESC",
  AUTHORED_QUEUES_MIN_AUTHOR_ID_ASC = "AUTHORED_QUEUES_MIN_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_MIN_AUTHOR_ID_DESC = "AUTHORED_QUEUES_MIN_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_MIN_CREATED_AT_ASC = "AUTHORED_QUEUES_MIN_CREATED_AT_ASC",
  AUTHORED_QUEUES_MIN_CREATED_AT_DESC = "AUTHORED_QUEUES_MIN_CREATED_AT_DESC",
  AUTHORED_QUEUES_MIN_DELETED_ASC = "AUTHORED_QUEUES_MIN_DELETED_ASC",
  AUTHORED_QUEUES_MIN_DELETED_DESC = "AUTHORED_QUEUES_MIN_DELETED_DESC",
  AUTHORED_QUEUES_MIN_DESCRIPTION_ASC = "AUTHORED_QUEUES_MIN_DESCRIPTION_ASC",
  AUTHORED_QUEUES_MIN_DESCRIPTION_DESC = "AUTHORED_QUEUES_MIN_DESCRIPTION_DESC",
  AUTHORED_QUEUES_MIN_FILTER_ASC = "AUTHORED_QUEUES_MIN_FILTER_ASC",
  AUTHORED_QUEUES_MIN_FILTER_DESC = "AUTHORED_QUEUES_MIN_FILTER_DESC",
  AUTHORED_QUEUES_MIN_ID_ASC = "AUTHORED_QUEUES_MIN_ID_ASC",
  AUTHORED_QUEUES_MIN_ID_DESC = "AUTHORED_QUEUES_MIN_ID_DESC",
  AUTHORED_QUEUES_MIN_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_MIN_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_MIN_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_MIN_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_MIN_NAME_ASC = "AUTHORED_QUEUES_MIN_NAME_ASC",
  AUTHORED_QUEUES_MIN_NAME_DESC = "AUTHORED_QUEUES_MIN_NAME_DESC",
  AUTHORED_QUEUES_MIN_UPDATED_AT_ASC = "AUTHORED_QUEUES_MIN_UPDATED_AT_ASC",
  AUTHORED_QUEUES_MIN_UPDATED_AT_DESC = "AUTHORED_QUEUES_MIN_UPDATED_AT_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_DELETED_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_DELETED_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_DELETED_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_DELETED_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_DESCRIPTION_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_DESCRIPTION_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_DESCRIPTION_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_DESCRIPTION_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_FILTER_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_FILTER_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_FILTER_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_FILTER_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_ID_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_ID_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_ID_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_ID_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_NAME_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_NAME_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_NAME_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_NAME_DESC",
  AUTHORED_QUEUES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_QUEUES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_QUEUES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_QUEUES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_DELETED_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_DELETED_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_DELETED_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_DELETED_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_DESCRIPTION_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_DESCRIPTION_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_DESCRIPTION_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_DESCRIPTION_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_FILTER_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_FILTER_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_FILTER_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_FILTER_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_ID_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_ID_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_NAME_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_NAME_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_NAME_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_NAME_DESC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_QUEUES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_QUEUES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_QUEUES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_QUEUES_SUM_AUTHOR_ID_ASC = "AUTHORED_QUEUES_SUM_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_SUM_AUTHOR_ID_DESC = "AUTHORED_QUEUES_SUM_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_SUM_CREATED_AT_ASC = "AUTHORED_QUEUES_SUM_CREATED_AT_ASC",
  AUTHORED_QUEUES_SUM_CREATED_AT_DESC = "AUTHORED_QUEUES_SUM_CREATED_AT_DESC",
  AUTHORED_QUEUES_SUM_DELETED_ASC = "AUTHORED_QUEUES_SUM_DELETED_ASC",
  AUTHORED_QUEUES_SUM_DELETED_DESC = "AUTHORED_QUEUES_SUM_DELETED_DESC",
  AUTHORED_QUEUES_SUM_DESCRIPTION_ASC = "AUTHORED_QUEUES_SUM_DESCRIPTION_ASC",
  AUTHORED_QUEUES_SUM_DESCRIPTION_DESC = "AUTHORED_QUEUES_SUM_DESCRIPTION_DESC",
  AUTHORED_QUEUES_SUM_FILTER_ASC = "AUTHORED_QUEUES_SUM_FILTER_ASC",
  AUTHORED_QUEUES_SUM_FILTER_DESC = "AUTHORED_QUEUES_SUM_FILTER_DESC",
  AUTHORED_QUEUES_SUM_ID_ASC = "AUTHORED_QUEUES_SUM_ID_ASC",
  AUTHORED_QUEUES_SUM_ID_DESC = "AUTHORED_QUEUES_SUM_ID_DESC",
  AUTHORED_QUEUES_SUM_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_SUM_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_SUM_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_SUM_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_SUM_NAME_ASC = "AUTHORED_QUEUES_SUM_NAME_ASC",
  AUTHORED_QUEUES_SUM_NAME_DESC = "AUTHORED_QUEUES_SUM_NAME_DESC",
  AUTHORED_QUEUES_SUM_UPDATED_AT_ASC = "AUTHORED_QUEUES_SUM_UPDATED_AT_ASC",
  AUTHORED_QUEUES_SUM_UPDATED_AT_DESC = "AUTHORED_QUEUES_SUM_UPDATED_AT_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_DELETED_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_DELETED_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_DELETED_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_DELETED_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_DESCRIPTION_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_DESCRIPTION_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_DESCRIPTION_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_DESCRIPTION_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_FILTER_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_FILTER_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_FILTER_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_FILTER_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_ID_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_ID_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_NAME_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_NAME_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_NAME_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_NAME_DESC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_QUEUES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_QUEUES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_QUEUES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_DELETED_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_DELETED_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_DELETED_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_DELETED_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_DESCRIPTION_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_DESCRIPTION_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_FILTER_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_FILTER_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_FILTER_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_FILTER_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_INDEXED_FILTER_TAGS_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_INDEXED_FILTER_TAGS_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_INDEXED_FILTER_TAGS_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_INDEXED_FILTER_TAGS_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_NAME_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_NAME_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_NAME_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_NAME_DESC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_QUEUES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_QUEUES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_COUNT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_COUNT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_COUNT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_COUNT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ACCEPTED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_RATIONALE_DESC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUTHORED_REVIEWED_AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIMS_BY_AUTHOR_ID__COUNT_ASC = "BATCH_CLAIMS_BY_AUTHOR_ID__COUNT_ASC",
  BATCH_CLAIMS_BY_AUTHOR_ID__COUNT_DESC = "BATCH_CLAIMS_BY_AUTHOR_ID__COUNT_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_ASC = "BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_ASC",
  BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_DESC = "BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_ID__COUNT_DESC",
  BATCH_CLAIM_ASSIGNEES_COUNT_ASC = "BATCH_CLAIM_ASSIGNEES_COUNT_ASC",
  BATCH_CLAIM_ASSIGNEES_COUNT_DESC = "BATCH_CLAIM_ASSIGNEES_COUNT_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_BY_AUTHOR_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_BY_AUTHOR_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_BY_AUTHOR_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_BY_AUTHOR_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_BY_AUTHOR_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_BY_AUTHOR_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_BY_AUTHOR_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_BY_AUTHOR_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_AUTHOR_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_AUTHOR_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_AUTHOR_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_AUTHOR_ID__COUNT_DESC",
  BATCH_FILES_BY_AUTHOR_ID__COUNT_ASC = "BATCH_FILES_BY_AUTHOR_ID__COUNT_ASC",
  BATCH_FILES_BY_AUTHOR_ID__COUNT_DESC = "BATCH_FILES_BY_AUTHOR_ID__COUNT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_AVERAGE_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_COUNT_ASC = "CLAIM_CASES_BY_UPDATED_BY_COUNT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_COUNT_DESC = "CLAIM_CASES_BY_UPDATED_BY_COUNT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_DISTINCT_COUNT_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_MAX_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_MIN_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_POPULATION_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_STDDEV_SAMPLE_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_SUM_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_POPULATION_UPDATED_BY_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CASE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CASE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CASE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CASE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CLAIM_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CLAIM_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_ID_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_ID_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_AT_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_AT_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_BY_ASC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_BY_DESC = "CLAIM_CASES_BY_UPDATED_BY_VARIANCE_SAMPLE_UPDATED_BY_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DISPUTED_FINDINGS_BY_AUTHOR_ID__COUNT_ASC = "DISPUTED_FINDINGS_BY_AUTHOR_ID__COUNT_ASC",
  DISPUTED_FINDINGS_BY_AUTHOR_ID__COUNT_DESC = "DISPUTED_FINDINGS_BY_AUTHOR_ID__COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_AUTHOR_ID__COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_AUTHOR_ID__COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_AUTHOR_ID__COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_AUTHOR_ID__COUNT_DESC",
  EMAIL_ASC = "EMAIL_ASC",
  EMAIL_DESC = "EMAIL_DESC",
  FIRST_NAME_ASC = "FIRST_NAME_ASC",
  FIRST_NAME_DESC = "FIRST_NAME_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_USERS_AVERAGE_AUDITOR_USER_ID_ASC = "INSURER_USERS_AVERAGE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_AVERAGE_AUDITOR_USER_ID_DESC = "INSURER_USERS_AVERAGE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_AVERAGE_CREATED_AT_ASC = "INSURER_USERS_AVERAGE_CREATED_AT_ASC",
  INSURER_USERS_AVERAGE_CREATED_AT_DESC = "INSURER_USERS_AVERAGE_CREATED_AT_DESC",
  INSURER_USERS_AVERAGE_ID_ASC = "INSURER_USERS_AVERAGE_ID_ASC",
  INSURER_USERS_AVERAGE_ID_DESC = "INSURER_USERS_AVERAGE_ID_DESC",
  INSURER_USERS_AVERAGE_INSURER_ID_ASC = "INSURER_USERS_AVERAGE_INSURER_ID_ASC",
  INSURER_USERS_AVERAGE_INSURER_ID_DESC = "INSURER_USERS_AVERAGE_INSURER_ID_DESC",
  INSURER_USERS_AVERAGE_UPDATED_AT_ASC = "INSURER_USERS_AVERAGE_UPDATED_AT_ASC",
  INSURER_USERS_AVERAGE_UPDATED_AT_DESC = "INSURER_USERS_AVERAGE_UPDATED_AT_DESC",
  INSURER_USERS_BY_AUDITOR_USER_ID__COUNT_ASC = "INSURER_USERS_BY_AUDITOR_USER_ID__COUNT_ASC",
  INSURER_USERS_BY_AUDITOR_USER_ID__COUNT_DESC = "INSURER_USERS_BY_AUDITOR_USER_ID__COUNT_DESC",
  INSURER_USERS_COUNT_ASC = "INSURER_USERS_COUNT_ASC",
  INSURER_USERS_COUNT_DESC = "INSURER_USERS_COUNT_DESC",
  INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_CREATED_AT_ASC = "INSURER_USERS_DISTINCT_COUNT_CREATED_AT_ASC",
  INSURER_USERS_DISTINCT_COUNT_CREATED_AT_DESC = "INSURER_USERS_DISTINCT_COUNT_CREATED_AT_DESC",
  INSURER_USERS_DISTINCT_COUNT_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_INSURER_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_INSURER_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_INSURER_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_INSURER_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_ASC = "INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_ASC",
  INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_DESC = "INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_DESC",
  INSURER_USERS_MAX_AUDITOR_USER_ID_ASC = "INSURER_USERS_MAX_AUDITOR_USER_ID_ASC",
  INSURER_USERS_MAX_AUDITOR_USER_ID_DESC = "INSURER_USERS_MAX_AUDITOR_USER_ID_DESC",
  INSURER_USERS_MAX_CREATED_AT_ASC = "INSURER_USERS_MAX_CREATED_AT_ASC",
  INSURER_USERS_MAX_CREATED_AT_DESC = "INSURER_USERS_MAX_CREATED_AT_DESC",
  INSURER_USERS_MAX_ID_ASC = "INSURER_USERS_MAX_ID_ASC",
  INSURER_USERS_MAX_ID_DESC = "INSURER_USERS_MAX_ID_DESC",
  INSURER_USERS_MAX_INSURER_ID_ASC = "INSURER_USERS_MAX_INSURER_ID_ASC",
  INSURER_USERS_MAX_INSURER_ID_DESC = "INSURER_USERS_MAX_INSURER_ID_DESC",
  INSURER_USERS_MAX_UPDATED_AT_ASC = "INSURER_USERS_MAX_UPDATED_AT_ASC",
  INSURER_USERS_MAX_UPDATED_AT_DESC = "INSURER_USERS_MAX_UPDATED_AT_DESC",
  INSURER_USERS_MIN_AUDITOR_USER_ID_ASC = "INSURER_USERS_MIN_AUDITOR_USER_ID_ASC",
  INSURER_USERS_MIN_AUDITOR_USER_ID_DESC = "INSURER_USERS_MIN_AUDITOR_USER_ID_DESC",
  INSURER_USERS_MIN_CREATED_AT_ASC = "INSURER_USERS_MIN_CREATED_AT_ASC",
  INSURER_USERS_MIN_CREATED_AT_DESC = "INSURER_USERS_MIN_CREATED_AT_DESC",
  INSURER_USERS_MIN_ID_ASC = "INSURER_USERS_MIN_ID_ASC",
  INSURER_USERS_MIN_ID_DESC = "INSURER_USERS_MIN_ID_DESC",
  INSURER_USERS_MIN_INSURER_ID_ASC = "INSURER_USERS_MIN_INSURER_ID_ASC",
  INSURER_USERS_MIN_INSURER_ID_DESC = "INSURER_USERS_MIN_INSURER_ID_DESC",
  INSURER_USERS_MIN_UPDATED_AT_ASC = "INSURER_USERS_MIN_UPDATED_AT_ASC",
  INSURER_USERS_MIN_UPDATED_AT_DESC = "INSURER_USERS_MIN_UPDATED_AT_DESC",
  INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_CREATED_AT_ASC = "INSURER_USERS_STDDEV_POPULATION_CREATED_AT_ASC",
  INSURER_USERS_STDDEV_POPULATION_CREATED_AT_DESC = "INSURER_USERS_STDDEV_POPULATION_CREATED_AT_DESC",
  INSURER_USERS_STDDEV_POPULATION_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_INSURER_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_INSURER_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_INSURER_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_INSURER_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_ASC = "INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_ASC",
  INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_DESC = "INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_DESC",
  INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_ASC = "INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_ASC",
  INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_DESC = "INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_DESC",
  INSURER_USERS_STDDEV_SAMPLE_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC = "INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC = "INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  INSURER_USERS_SUM_AUDITOR_USER_ID_ASC = "INSURER_USERS_SUM_AUDITOR_USER_ID_ASC",
  INSURER_USERS_SUM_AUDITOR_USER_ID_DESC = "INSURER_USERS_SUM_AUDITOR_USER_ID_DESC",
  INSURER_USERS_SUM_CREATED_AT_ASC = "INSURER_USERS_SUM_CREATED_AT_ASC",
  INSURER_USERS_SUM_CREATED_AT_DESC = "INSURER_USERS_SUM_CREATED_AT_DESC",
  INSURER_USERS_SUM_ID_ASC = "INSURER_USERS_SUM_ID_ASC",
  INSURER_USERS_SUM_ID_DESC = "INSURER_USERS_SUM_ID_DESC",
  INSURER_USERS_SUM_INSURER_ID_ASC = "INSURER_USERS_SUM_INSURER_ID_ASC",
  INSURER_USERS_SUM_INSURER_ID_DESC = "INSURER_USERS_SUM_INSURER_ID_DESC",
  INSURER_USERS_SUM_UPDATED_AT_ASC = "INSURER_USERS_SUM_UPDATED_AT_ASC",
  INSURER_USERS_SUM_UPDATED_AT_DESC = "INSURER_USERS_SUM_UPDATED_AT_DESC",
  INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_ASC = "INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_ASC",
  INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_DESC = "INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_DESC",
  INSURER_USERS_VARIANCE_POPULATION_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC = "INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC = "INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC = "INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC = "INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  INTERNAL_ONLY_ASC = "INTERNAL_ONLY_ASC",
  INTERNAL_ONLY_DESC = "INTERNAL_ONLY_DESC",
  LAST_NAME_ASC = "LAST_NAME_ASC",
  LAST_NAME_DESC = "LAST_NAME_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_AVERAGE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_COUNT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_COUNT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_COUNT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_COUNT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_DISTINCT_COUNT_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MAX_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_MIN_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_STDDEV_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_SUM_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID_VARIANCE_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID__COUNT_ASC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID__COUNT_ASC",
  MESSAGE_CREATEDS_BY_SENDER_USER_ID__COUNT_DESC = "MESSAGE_CREATEDS_BY_SENDER_USER_ID__COUNT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_COUNT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_COUNT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_COUNT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_COUNT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MAX_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_MIN_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_SUM_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID__COUNT_ASC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID__COUNT_ASC",
  MESSAGE_SENTS_BY_RECIPIENT_USER_ID__COUNT_DESC = "MESSAGE_SENTS_BY_RECIPIENT_USER_ID__COUNT_DESC",
  NATURAL = "NATURAL",
  NN_IB_LINES_BY_AUTHOR_ID__COUNT_ASC = "NN_IB_LINES_BY_AUTHOR_ID__COUNT_ASC",
  NN_IB_LINES_BY_AUTHOR_ID__COUNT_DESC = "NN_IB_LINES_BY_AUTHOR_ID__COUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_BY_AUDITOR_USER_ID__COUNT_ASC = "PROVIDER_USER_FACILITIES_BY_AUDITOR_USER_ID__COUNT_ASC",
  PROVIDER_USER_FACILITIES_BY_AUDITOR_USER_ID__COUNT_DESC = "PROVIDER_USER_FACILITIES_BY_AUDITOR_USER_ID__COUNT_DESC",
  PROVIDER_USER_FACILITIES_COUNT_ASC = "PROVIDER_USER_FACILITIES_COUNT_ASC",
  PROVIDER_USER_FACILITIES_COUNT_DESC = "PROVIDER_USER_FACILITIES_COUNT_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_MAX_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MAX_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_MAX_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MAX_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_MIN_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MIN_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_MIN_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MIN_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_SUM_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_SUM_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_SUM_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_SUM_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  QUEUES_BY_AUTHOR_ID__COUNT_ASC = "QUEUES_BY_AUTHOR_ID__COUNT_ASC",
  QUEUES_BY_AUTHOR_ID__COUNT_DESC = "QUEUES_BY_AUTHOR_ID__COUNT_DESC",
  READ_ONLY_ASC = "READ_ONLY_ASC",
  READ_ONLY_DESC = "READ_ONLY_DESC",
  REVIEWED_AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_ASC = "REVIEWED_AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_ASC",
  REVIEWED_AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_DESC = "REVIEWED_AUDIT_FINDINGS_BY_AUTHOR_ID__COUNT_DESC",
  TITLE_ASC = "TITLE_ASC",
  TITLE_DESC = "TITLE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_AVERAGE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_AVERAGE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_AVERAGE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_AVERAGE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_AVERAGE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_AVERAGE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_AVERAGE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_AVERAGE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_AVERAGE_ID_ASC = "USER_INVITE_CREATEDS_AVERAGE_ID_ASC",
  USER_INVITE_CREATEDS_AVERAGE_ID_DESC = "USER_INVITE_CREATEDS_AVERAGE_ID_DESC",
  USER_INVITE_CREATEDS_AVERAGE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_AVERAGE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_AVERAGE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_AVERAGE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_AVERAGE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_AVERAGE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_AVERAGE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_AVERAGE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_AVERAGE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_AVERAGE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_AVERAGE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_AVERAGE_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_AUDITOR_USER_ID__COUNT_ASC = "USER_INVITE_CREATEDS_BY_AUDITOR_USER_ID__COUNT_ASC",
  USER_INVITE_CREATEDS_BY_AUDITOR_USER_ID__COUNT_DESC = "USER_INVITE_CREATEDS_BY_AUDITOR_USER_ID__COUNT_DESC",
  USER_INVITE_CREATEDS_COUNT_ASC = "USER_INVITE_CREATEDS_COUNT_ASC",
  USER_INVITE_CREATEDS_COUNT_DESC = "USER_INVITE_CREATEDS_COUNT_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_ID_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_ID_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_ID_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_ID_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_DISTINCT_COUNT_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_DISTINCT_COUNT_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_MAX_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_MAX_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_MAX_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_MAX_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_MAX_CREATED_AT_ASC = "USER_INVITE_CREATEDS_MAX_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_MAX_CREATED_AT_DESC = "USER_INVITE_CREATEDS_MAX_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_MAX_ID_ASC = "USER_INVITE_CREATEDS_MAX_ID_ASC",
  USER_INVITE_CREATEDS_MAX_ID_DESC = "USER_INVITE_CREATEDS_MAX_ID_DESC",
  USER_INVITE_CREATEDS_MAX_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_MAX_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_MAX_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_MAX_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_MAX_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_MAX_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_MAX_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_MAX_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_MAX_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_MAX_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_MAX_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_MAX_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_MIN_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_MIN_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_MIN_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_MIN_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_MIN_CREATED_AT_ASC = "USER_INVITE_CREATEDS_MIN_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_MIN_CREATED_AT_DESC = "USER_INVITE_CREATEDS_MIN_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_MIN_ID_ASC = "USER_INVITE_CREATEDS_MIN_ID_ASC",
  USER_INVITE_CREATEDS_MIN_ID_DESC = "USER_INVITE_CREATEDS_MIN_ID_DESC",
  USER_INVITE_CREATEDS_MIN_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_MIN_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_MIN_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_MIN_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_MIN_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_MIN_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_MIN_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_MIN_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_MIN_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_MIN_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_MIN_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_MIN_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_STDDEV_POPULATION_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_STDDEV_POPULATION_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_STDDEV_SAMPLE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_SUM_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_SUM_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_SUM_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_SUM_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_SUM_CREATED_AT_ASC = "USER_INVITE_CREATEDS_SUM_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_SUM_CREATED_AT_DESC = "USER_INVITE_CREATEDS_SUM_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_SUM_ID_ASC = "USER_INVITE_CREATEDS_SUM_ID_ASC",
  USER_INVITE_CREATEDS_SUM_ID_DESC = "USER_INVITE_CREATEDS_SUM_ID_DESC",
  USER_INVITE_CREATEDS_SUM_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_SUM_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_SUM_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_SUM_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_SUM_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_SUM_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_SUM_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_SUM_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_SUM_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_SUM_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_SUM_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_SUM_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_VARIANCE_POPULATION_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  USER_TYPE_ASC = "USER_TYPE_ASC",
  USER_TYPE_DESC = "USER_TYPE_DESC",
}

export type Autodor = {
  __typename?: "Autodor";
  autodorFindings: Array<AutodorFinding>;
  claimId: Scalars["UUID"]["output"];
  sinks: Array<AutodorSink>;
};

export enum AutodorBillType {
  IB = "IB",
  UB = "UB",
}

export type AutodorFinding = {
  __typename?: "AutodorFinding";
  auditType: Scalars["String"]["output"];
  autodorMetadata: AutodorMetadata;
  claimId: Scalars["UUID"]["output"];
  createdAt: Scalars["String"]["output"];
  denialCode: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  ibLineId?: Maybe<Scalars["UUID"]["output"]>;
  ibLineVersionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["UUID"]["output"];
  improperCharge: Scalars["Float"]["output"];
  improperUnits: Scalars["Int"]["output"];
  s3Key: Scalars["String"]["output"];
  seedType: SeedType;
  stale: Scalars["Boolean"]["output"];
  ubLineId?: Maybe<Scalars["UUID"]["output"]>;
};

export type AutodorMetadata = {
  __typename?: "AutodorMetadata";
  auditType: Scalars["String"]["output"];
  billType: AutodorBillType;
  dateOfServiceStart: Scalars["Date"]["output"];
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  procedureCode: Scalars["String"]["output"];
  procedureCodeModifier?: Maybe<Scalars["String"]["output"]>;
  quarter: Scalars["String"]["output"];
  rule: AuditRule;
  unitCharge: Scalars["Float"]["output"];
  units: Scalars["Int"]["output"];
  versionId: Scalars["UUID"]["output"];
  year: Scalars["String"]["output"];
};

export type AutodorSink = {
  __typename?: "AutodorSink";
  s3Key: Scalars["String"]["output"];
  timestamp: Scalars["String"]["output"];
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaim = Node & {
  __typename?: "BatchClaim";
  /** Reads and enables pagination through a set of `AuditorUser`. */
  activeAssignees: AuditorUsersConnection;
  admType?: Maybe<Scalars["String"]["output"]>;
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  auditDocumentation: Array<AuditDocumentation>;
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindings: AuditFindingsConnection;
  /** Reads a single `AuditorUser` that is related to this `BatchClaim`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `BatchClaimAssignee`. */
  batchClaimAssignees: BatchClaimAssigneesConnection;
  /** Reads and enables pagination through a set of `BatchClaimDueDate`. */
  batchClaimDueDates: BatchClaimDueDatesConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItem`. */
  batchClaimLineItems: BatchClaimLineItemsConnection;
  /** Reads and enables pagination through a set of `BatchClaimLine`. */
  batchClaimLines: BatchClaimLinesConnection;
  /** Reads a single `BatchClaimState` that is related to this `BatchClaim`. */
  batchClaimStateById?: Maybe<BatchClaimState>;
  /**
   * Reads and enables pagination through a set of `BatchClaimState`.
   * @deprecated Please use batchClaimStateById instead
   */
  batchClaimStatesById: BatchClaimStatesConnection;
  /** Reads a single `BatchFile` that is related to this `BatchClaim`. */
  batchFile?: Maybe<BatchFile>;
  batchFileId: Scalars["UUID"]["output"];
  billType?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `BucketClaim` that is related to this `BatchClaim`. */
  bucketClaim?: Maybe<BucketClaim>;
  /**
   * Reads and enables pagination through a set of `BucketClaim`.
   * @deprecated Please use bucketClaim instead
   */
  bucketClaims: BucketClaimsConnection;
  claimType?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `ClientSignOffClaimState`. */
  clientSignOffClaimStates: ClientSignOffClaimStatesConnection;
  computationStrategy: ClaimComputationStrategyKind;
  createdAt: Scalars["Datetime"]["output"];
  dateAdmit?: Maybe<Scalars["Datetime"]["output"]>;
  dateDischarge?: Maybe<Scalars["Datetime"]["output"]>;
  dateOfServiceEnd: Scalars["Datetime"]["output"];
  dateOfServiceStart: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `DisputedFinding`. */
  disputedFindings: DisputedFindingsConnection;
  /** Reads and enables pagination through a set of `DocumentRequestCreated`. */
  documentRequestCreateds: DocumentRequestCreatedsConnection;
  /** Reads and enables pagination through a set of `DocumentUploadCreated`. */
  documentUploadCreateds: DocumentUploadCreatedsConnection;
  eventHistoryTimeline: HistoryTimelineEvents;
  icn: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `Insurer` that is related to this `BatchClaim`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `InvoiceClaimState`. */
  invoiceClaimStates: InvoiceClaimStatesConnection;
  isAssigned?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * ### Overview
   * Determines if the querying user is assigned to a particular claim. Used on the auditor role for the claimIsAssignedToUserFilter filter in the UI
   */
  isAssignedToQueryingUser?: Maybe<Scalars["Boolean"]["output"]>;
  itemizedBill?: Maybe<ItemizedBill>;
  negotiationClaimState?: Maybe<NegotiationClaimStateEnum>;
  /** Reads and enables pagination through a set of `NegotiationClaimState`. */
  negotiationClaimStates: NegotiationClaimStatesConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /**
   * ### Overview
   * Indicates if the given batch claim is on hold or not.
   */
  onHoldClaimState?: Maybe<OnHoldClaimStateEnum>;
  /** Reads and enables pagination through a set of `OnHoldClaimState`. */
  onHoldClaimStates: OnHoldClaimStatesConnection;
  ownerId?: Maybe<Scalars["UUID"]["output"]>;
  paidDate?: Maybe<Scalars["Datetime"]["output"]>;
  patientDob?: Maybe<Scalars["Datetime"]["output"]>;
  patientId?: Maybe<Scalars["UUID"]["output"]>;
  /**
   * # Overview
   * Calculates the payment rate at the batch claim level.
   * The `amount_reimbursed` at the batch claim level reflects the actual amount the payer pays on the claim bill.
   * The `amount_charged` at the batch claim level is the full amount billed on the claim bill.
   * The ratio of these two things is the fraction of the total bill the payer actually pays, also known as the payment rate.Note: today it is impossible for us to have the equivalent concept of payment rate at the batch claim line level or at the batch claim line item level, though accurate calculations for either would improve the accuracy of our own calculations
   */
  paymentRate: Scalars["Float"]["output"];
  permittedInsurerUsers?: Maybe<Array<Maybe<Scalars["UUID"]["output"]>>>;
  /** Reads a single `Provider` that is related to this `BatchClaim`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ProviderSignOffClaimState`. */
  providerSignOffClaimStates: ProviderSignOffClaimStatesConnection;
  /**
   * ### Overview
   * Identifies all the different audit finding reports that contain a specific batch claim.
   * This is the same as returning the report family that contains the given batch claim.
   */
  reportVersions: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `ReportedClaim`. */
  reportedClaimsByClaimId: ReportedClaimsConnection;
  /** Reads and enables pagination through a set of `S3AuditDocumentation`. */
  s3AuditDocumentations: S3AuditDocumentationsConnection;
  supportingDocumentation: SupportingDocumentation;
  updatedAt: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `WorkflowClaimState`. */
  workflowClaimStates: WorkflowClaimStatesConnection;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimactiveAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<AuditorUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimauditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbatchClaimAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimAssigneeCondition>;
  filter?: InputMaybe<BatchClaimAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimAssigneesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbatchClaimDueDatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimDueDateCondition>;
  filter?: InputMaybe<BatchClaimDueDateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimDueDatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbatchClaimLineItemsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemCondition>;
  filter?: InputMaybe<BatchClaimLineItemFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbatchClaimLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineCondition>;
  filter?: InputMaybe<BatchClaimLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLinesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbatchClaimStatesByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimStateCondition>;
  filter?: InputMaybe<BatchClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimbucketClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BucketClaimCondition>;
  filter?: InputMaybe<BucketClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BucketClaimsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimclientSignOffClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ClientSignOffClaimStateCondition>;
  filter?: InputMaybe<ClientSignOffClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientSignOffClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimdisputedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DisputedFindingCondition>;
  filter?: InputMaybe<DisputedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DisputedFindingsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimdocumentRequestCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentRequestCreatedCondition>;
  filter?: InputMaybe<DocumentRequestCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentRequestCreatedsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimdocumentUploadCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentUploadCreatedCondition>;
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimeventHistoryTimelineArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaiminvoiceClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InvoiceClaimStateCondition>;
  filter?: InputMaybe<InvoiceClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimnegotiationClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NegotiationClaimStateCondition>;
  filter?: InputMaybe<NegotiationClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NegotiationClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimonHoldClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OnHoldClaimStateCondition>;
  filter?: InputMaybe<OnHoldClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnHoldClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimproviderSignOffClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderSignOffClaimStateCondition>;
  filter?: InputMaybe<ProviderSignOffClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderSignOffClaimStatesOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimreportVersionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimreportedClaimsByClaimIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedClaimCondition>;
  filter?: InputMaybe<ReportedClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedClaimsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaims3AuditDocumentationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<S3AuditDocumentationCondition>;
  filter?: InputMaybe<S3AuditDocumentationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<S3AuditDocumentationsOrderBy>>;
};

/**
 * ### Overview
 * Holds metadata about the medical claims that we store in our platform.
 * Ingestion and ETL on audit documentation upstream leads to creation of entries into this table.
 * Our auditors identify audit findings during their audits, which each end up on either the batch_claim_line or batch_claim_line_item table and link back to a specific claim in this table.
 */
export type BatchClaimworkflowClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowClaimStateCondition>;
  filter?: InputMaybe<WorkflowClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowClaimStatesOrderBy>>;
};

export type BatchClaimAggregates = {
  __typename?: "BatchClaimAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `BatchClaim` object types. */
export type BatchClaimAggregatesFilter = {
  /** Mean average aggregate over matching `BatchClaim` objects. */
  average?: InputMaybe<BatchClaimAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BatchClaim` objects. */
  distinctCount?: InputMaybe<BatchClaimDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaim` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimFilter>;
  /** Maximum aggregate over matching `BatchClaim` objects. */
  max?: InputMaybe<BatchClaimMaxAggregateFilter>;
  /** Minimum aggregate over matching `BatchClaim` objects. */
  min?: InputMaybe<BatchClaimMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BatchClaim` objects. */
  stddevPopulation?: InputMaybe<BatchClaimStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BatchClaim` objects. */
  stddevSample?: InputMaybe<BatchClaimStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BatchClaim` objects. */
  sum?: InputMaybe<BatchClaimSumAggregateFilter>;
  /** Population variance aggregate over matching `BatchClaim` objects. */
  variancePopulation?: InputMaybe<BatchClaimVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BatchClaim` objects. */
  varianceSample?: InputMaybe<BatchClaimVarianceSampleAggregateFilter>;
};

export type BatchClaimAssignee = Node & {
  __typename?: "BatchClaimAssignee";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimAssignee`. */
  auditorUser?: Maybe<AuditorUser>;
  auditorUserId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaim` that is related to this `BatchClaimAssignee`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  unassigned: Scalars["Boolean"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type BatchClaimAssigneeAggregates = {
  __typename?: "BatchClaimAssigneeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimAssigneeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `BatchClaimAssignee` object types. */
export type BatchClaimAssigneeAggregatesFilter = {
  /** Distinct count aggregate over matching `BatchClaimAssignee` objects. */
  distinctCount?: InputMaybe<BatchClaimAssigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimAssignee` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimAssigneeFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type BatchClaimAssigneeAuditorUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `batchClaimAssignee` to be created by this mutation. */
export type BatchClaimAssigneeAuditorUserIdFkeyBatchClaimAssigneeCreateInput = {
  auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditorUser` in the `BatchClaimAssigneeInput` mutation. */
export type BatchClaimAssigneeAuditorUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimAssignee` in the `AuditorUserInput` mutation. */
export type BatchClaimAssigneeAuditorUserIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimAssignee` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimAssigneeBatchClaimAssigneePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimAssignee` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimAssigneeNodeIdConnect>>;
  /** A `BatchClaimAssigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimAssigneeAuditorUserIdFkeyBatchClaimAssigneeCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimAssignee` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingBatchClaimAssigneePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimAssignee` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `batchClaimAssignee` to look up the row to connect. */
export type BatchClaimAssigneeBatchClaimAssigneePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `batchClaimAssignee` to be created by this mutation. */
export type BatchClaimAssigneeBatchClaimIdFkeyBatchClaimAssigneeCreateInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchClaimAssigneeInput` mutation. */
export type BatchClaimAssigneeBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimAssignee` in the `BatchClaimInput` mutation. */
export type BatchClaimAssigneeBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimAssignee` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimAssigneeBatchClaimAssigneePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimAssignee` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimAssigneeNodeIdConnect>>;
  /** A `BatchClaimAssigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimAssigneeBatchClaimIdFkeyBatchClaimAssigneeCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimAssignee` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimAssigneePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimAssignee` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `BatchClaimAssignee` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimAssigneeCondition = {
  /** Checks for equality with the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `unassigned` field. */
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimAssigneeDistinctCountAggregateFilter = {
  auditorUserId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  unassigned?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchClaimAssigneeDistinctCountAggregates = {
  __typename?: "BatchClaimAssigneeDistinctCountAggregates";
  /** Distinct count of auditorUserId across the matching connection */
  auditorUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unassigned across the matching connection */
  unassigned?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimAssignee` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimAssigneeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimAssigneeFilter>>;
  /** Filter by the object’s `auditorUser` relation. */
  auditorUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimAssigneeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimAssigneeFilter>>;
  /** Filter by the object’s `unassigned` field. */
  unassigned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BatchClaimAssignee` for usage during aggregation. */
export enum BatchClaimAssigneeGroupBy {
  AUDITOR_USER_ID = "AUDITOR_USER_ID",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UNASSIGNED = "UNASSIGNED",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchClaimAssigneeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimAssignee` aggregates. */
export type BatchClaimAssigneeHavingInput = {
  AND?: InputMaybe<Array<BatchClaimAssigneeHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimAssigneeHavingInput>>;
  average?: InputMaybe<BatchClaimAssigneeHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimAssigneeHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimAssigneeHavingMaxInput>;
  min?: InputMaybe<BatchClaimAssigneeHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimAssigneeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimAssigneeHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimAssigneeHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimAssigneeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimAssigneeHavingVarianceSampleInput>;
};

export type BatchClaimAssigneeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimAssigneeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimAssignee` */
export type BatchClaimAssigneeInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimAssigneeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimAssignee` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `batchClaimAssignee` to look up the row to update. */
export type BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyUsingBatchClaimAssigneePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
    patch: updateBatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `batchClaimAssignee` to look up the row to update. */
export type BatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimAssigneePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
    patch: updateBatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimAssignee`. Fields that are set will be updated. */
export type BatchClaimAssigneePatch = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
};

/** A connection to a list of `BatchClaimAssignee` values. */
export type BatchClaimAssigneesConnection = {
  __typename?: "BatchClaimAssigneesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimAssigneeAggregates>;
  /** A list of edges which contains the `BatchClaimAssignee` and cursor to aid in pagination. */
  edges: Array<BatchClaimAssigneesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimAssigneeAggregates>>;
  /** A list of `BatchClaimAssignee` objects. */
  nodes: Array<Maybe<BatchClaimAssignee>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimAssignee` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimAssignee` values. */
export type BatchClaimAssigneesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimAssigneeGroupBy>;
  having?: InputMaybe<BatchClaimAssigneeHavingInput>;
};

/** A `BatchClaimAssignee` edge in the connection. */
export type BatchClaimAssigneesEdge = {
  __typename?: "BatchClaimAssigneesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimAssignee` at the end of the edge. */
  node?: Maybe<BatchClaimAssignee>;
};

/** Methods to use when ordering `BatchClaimAssignee`. */
export enum BatchClaimAssigneesOrderBy {
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC",
  AUDITOR_USER_ID_ASC = "AUDITOR_USER_ID_ASC",
  AUDITOR_USER_ID_DESC = "AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UNASSIGNED_ASC = "UNASSIGNED_ASC",
  UNASSIGNED_DESC = "UNASSIGNED_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** Input for the nested mutation of `auditorUser` in the `BatchClaimInput` mutation. */
export type BatchClaimAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaim` in the `AuditorUserInput` mutation. */
export type BatchClaimAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<Array<BatchClaimBatchClaimIcnKeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchClaimBatchClaimPkeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyUsingBatchClaimIcnKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyUsingBatchClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyNodeIdUpdate>
  >;
};

export type BatchClaimAverageAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimAverageAggregates = {
  __typename?: "BatchClaimAverageAggregates";
  /** Mean average of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimAverageAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The fields on `batchClaim` to look up the row to connect. */
export type BatchClaimBatchClaimIcnKeyConnect = {
  icn: Scalars["String"]["input"];
};

/** The fields on `batchClaim` to look up the row to connect. */
export type BatchClaimBatchClaimPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `batchFile` in the `BatchClaimInput` mutation. */
export type BatchClaimBatchFileIdFkeyInput = {
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectById?: InputMaybe<BatchFileBatchFilePkeyConnect>;
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchFileNodeIdConnect>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateById?: InputMaybe<BatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchFilePkeyUpdate>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchFileInput` mutation. */
export type BatchClaimBatchFileIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<Array<BatchClaimBatchClaimIcnKeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchClaimBatchClaimPkeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchClaimIcnKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `BatchClaim` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BatchClaimCondition = {
  /** Checks for equality with the object’s `admType` field. */
  admType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `amountCharged` field. */
  amountCharged?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `amountReimbursed` field. */
  amountReimbursed?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchFileId` field. */
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `billType` field. */
  billType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `claimType` field. */
  claimType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `computationStrategy` field. */
  computationStrategy?: InputMaybe<ClaimComputationStrategyKind>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateAdmit` field. */
  dateAdmit?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateDischarge` field. */
  dateDischarge?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfServiceEnd` field. */
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfServiceStart` field. */
  dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `icn` field. */
  icn?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `paidDate` field. */
  paidDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `patientDob` field. */
  patientDob?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `patientId` field. */
  patientId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `paymentRate` field. */
  paymentRate?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `permittedInsurerUsers` field. */
  permittedInsurerUsers?: InputMaybe<
    Array<InputMaybe<Scalars["UUID"]["input"]>>
  >;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimDistinctCountAggregateFilter = {
  admType?: InputMaybe<BigIntFilter>;
  amountCharged?: InputMaybe<BigIntFilter>;
  amountReimbursed?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  batchFileId?: InputMaybe<BigIntFilter>;
  billType?: InputMaybe<BigIntFilter>;
  claimType?: InputMaybe<BigIntFilter>;
  computationStrategy?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  dateAdmit?: InputMaybe<BigIntFilter>;
  dateDischarge?: InputMaybe<BigIntFilter>;
  dateOfServiceEnd?: InputMaybe<BigIntFilter>;
  dateOfServiceStart?: InputMaybe<BigIntFilter>;
  icn?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  insurerId?: InputMaybe<BigIntFilter>;
  isAssigned?: InputMaybe<BooleanFilter>;
  isAssignedToQueryingUser?: InputMaybe<BooleanFilter>;
  negotiationClaimState?: InputMaybe<NegotiationClaimStateEnumFilter>;
  onHoldClaimState?: InputMaybe<OnHoldClaimStateEnumFilter>;
  ownerId?: InputMaybe<BigIntFilter>;
  paidDate?: InputMaybe<BigIntFilter>;
  patientDob?: InputMaybe<BigIntFilter>;
  patientId?: InputMaybe<BigIntFilter>;
  paymentRate?: InputMaybe<BigIntFilter>;
  permittedInsurerUsers?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchClaimDistinctCountAggregates = {
  __typename?: "BatchClaimDistinctCountAggregates";
  /** Distinct count of admType across the matching connection */
  admType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchFileId across the matching connection */
  batchFileId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of billType across the matching connection */
  billType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of claimType across the matching connection */
  claimType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of computationStrategy across the matching connection */
  computationStrategy?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateAdmit across the matching connection */
  dateAdmit?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateDischarge across the matching connection */
  dateDischarge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateOfServiceEnd across the matching connection */
  dateOfServiceEnd?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateOfServiceStart across the matching connection */
  dateOfServiceStart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of icn across the matching connection */
  icn?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  isAssigned?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Determines if the querying user is assigned to a particular claim. Used on the auditor role for the claimIsAssignedToUserFilter filter in the UI
   */
  isAssignedToQueryingUser?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of this field across the matching connection. */
  negotiationClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Indicates if the given batch claim is on hold or not.
   */
  onHoldClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ownerId across the matching connection */
  ownerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of paidDate across the matching connection */
  paidDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of patientDob across the matching connection */
  patientDob?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of patientId across the matching connection */
  patientId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of permittedInsurerUsers across the matching connection */
  permittedInsurerUsers?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

export type BatchClaimDistinctCountAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimDueDate = Node & {
  __typename?: "BatchClaimDueDate";
  /** Reads a single `BatchClaim` that is related to this `BatchClaimDueDate`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  dueDate: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type BatchClaimDueDateAggregates = {
  __typename?: "BatchClaimDueDateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimDueDateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `BatchClaimDueDate` object types. */
export type BatchClaimDueDateAggregatesFilter = {
  /** Distinct count aggregate over matching `BatchClaimDueDate` objects. */
  distinctCount?: InputMaybe<BatchClaimDueDateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimDueDate` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimDueDateFilter>;
};

/** The fields on `batchClaimDueDate` to look up the row to connect. */
export type BatchClaimDueDateBatchClaimDueDatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `batchClaimDueDate` to be created by this mutation. */
export type BatchClaimDueDateBatchClaimIdFkBatchClaimDueDateCreateInput = {
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  dueDate: Scalars["Datetime"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchClaimDueDateInput` mutation. */
export type BatchClaimDueDateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimDueDate` in the `BatchClaimInput` mutation. */
export type BatchClaimDueDateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `batchClaimDueDate` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimDueDateBatchClaimDueDatePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimDueDate` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimDueDateNodeIdConnect>>;
  /** A `BatchClaimDueDateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimDueDateBatchClaimIdFkBatchClaimDueDateCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimDueDate` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimDueDatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimDueDate` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `BatchClaimDueDate` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimDueDateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimDueDateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  dueDate?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
};

export type BatchClaimDueDateDistinctCountAggregates = {
  __typename?: "BatchClaimDueDateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dueDate across the matching connection */
  dueDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimDueDate` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimDueDateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimDueDateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimDueDateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimDueDateFilter>>;
};

/** Grouping methods for `BatchClaimDueDate` for usage during aggregation. */
export enum BatchClaimDueDateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DELETED = "DELETED",
  DUE_DATE = "DUE_DATE",
  DUE_DATE_TRUNCATED_TO_DAY = "DUE_DATE_TRUNCATED_TO_DAY",
  DUE_DATE_TRUNCATED_TO_HOUR = "DUE_DATE_TRUNCATED_TO_HOUR",
}

export type BatchClaimDueDateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimDueDate` aggregates. */
export type BatchClaimDueDateHavingInput = {
  AND?: InputMaybe<Array<BatchClaimDueDateHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimDueDateHavingInput>>;
  average?: InputMaybe<BatchClaimDueDateHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimDueDateHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimDueDateHavingMaxInput>;
  min?: InputMaybe<BatchClaimDueDateHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimDueDateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimDueDateHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimDueDateHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimDueDateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimDueDateHavingVarianceSampleInput>;
};

export type BatchClaimDueDateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimDueDateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimDueDate` */
export type BatchClaimDueDateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  dueDate: Scalars["Datetime"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimDueDateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimDueDate` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `batchClaimDueDate` to look up the row to update. */
export type BatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimDueDatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimDueDate` being updated. */
    patch: updateBatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkPatch;
  };

/** Represents an update to a `BatchClaimDueDate`. Fields that are set will be updated. */
export type BatchClaimDueDatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInput>;
};

/** A connection to a list of `BatchClaimDueDate` values. */
export type BatchClaimDueDatesConnection = {
  __typename?: "BatchClaimDueDatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimDueDateAggregates>;
  /** A list of edges which contains the `BatchClaimDueDate` and cursor to aid in pagination. */
  edges: Array<BatchClaimDueDatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimDueDateAggregates>>;
  /** A list of `BatchClaimDueDate` objects. */
  nodes: Array<Maybe<BatchClaimDueDate>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimDueDate` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimDueDate` values. */
export type BatchClaimDueDatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimDueDateGroupBy>;
  having?: InputMaybe<BatchClaimDueDateHavingInput>;
};

/** A `BatchClaimDueDate` edge in the connection. */
export type BatchClaimDueDatesEdge = {
  __typename?: "BatchClaimDueDatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimDueDate` at the end of the edge. */
  node?: Maybe<BatchClaimDueDate>;
};

/** Methods to use when ordering `BatchClaimDueDate`. */
export enum BatchClaimDueDatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DELETED_ASC = "DELETED_ASC",
  DELETED_DESC = "DELETED_DESC",
  DUE_DATE_ASC = "DUE_DATE_ASC",
  DUE_DATE_DESC = "DUE_DATE_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

/** A filter to be used against `BatchClaim` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimFilter = {
  /** Filter by the object’s `admType` field. */
  admType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `amountCharged` field. */
  amountCharged?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `amountReimbursed` field. */
  amountReimbursed?: InputMaybe<FloatFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimFilter>>;
  /** Filter by the object’s `auditFindings` relation. */
  auditFindings?: InputMaybe<BatchClaimToManyAuditFindingFilter>;
  /** Some related `auditFindings` exist. */
  auditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimAssignees` relation. */
  batchClaimAssignees?: InputMaybe<BatchClaimToManyBatchClaimAssigneeFilter>;
  /** Some related `batchClaimAssignees` exist. */
  batchClaimAssigneesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimDueDates` relation. */
  batchClaimDueDates?: InputMaybe<BatchClaimToManyBatchClaimDueDateFilter>;
  /** Some related `batchClaimDueDates` exist. */
  batchClaimDueDatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimLineItems` relation. */
  batchClaimLineItems?: InputMaybe<BatchClaimToManyBatchClaimLineItemFilter>;
  /** Some related `batchClaimLineItems` exist. */
  batchClaimLineItemsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimLines` relation. */
  batchClaimLines?: InputMaybe<BatchClaimToManyBatchClaimLineFilter>;
  /** Some related `batchClaimLines` exist. */
  batchClaimLinesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimStateById` relation. */
  batchClaimStateById?: InputMaybe<BatchClaimStateFilter>;
  /** A related `batchClaimStateById` exists. */
  batchClaimStateByIdExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchFile` relation. */
  batchFile?: InputMaybe<BatchFileFilter>;
  /** Filter by the object’s `batchFileId` field. */
  batchFileId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `billType` field. */
  billType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bucketClaim` relation. */
  bucketClaim?: InputMaybe<BucketClaimFilter>;
  /** A related `bucketClaim` exists. */
  bucketClaimExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `claimType` field. */
  claimType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientSignOffClaimStates` relation. */
  clientSignOffClaimStates?: InputMaybe<BatchClaimToManyClientSignOffClaimStateFilter>;
  /** Some related `clientSignOffClaimStates` exist. */
  clientSignOffClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `computationStrategy` field. */
  computationStrategy?: InputMaybe<ClaimComputationStrategyKindFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateAdmit` field. */
  dateAdmit?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateDischarge` field. */
  dateDischarge?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfServiceEnd` field. */
  dateOfServiceEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfServiceStart` field. */
  dateOfServiceStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `disputedFindings` relation. */
  disputedFindings?: InputMaybe<BatchClaimToManyDisputedFindingFilter>;
  /** Some related `disputedFindings` exist. */
  disputedFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentRequestCreateds` relation. */
  documentRequestCreateds?: InputMaybe<BatchClaimToManyDocumentRequestCreatedFilter>;
  /** Some related `documentRequestCreateds` exist. */
  documentRequestCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentUploadCreateds` relation. */
  documentUploadCreateds?: InputMaybe<BatchClaimToManyDocumentUploadCreatedFilter>;
  /** Some related `documentUploadCreateds` exist. */
  documentUploadCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `icn` field. */
  icn?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `improperPaymentCost` field. */
  improperPaymentCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `invoiceClaimStates` relation. */
  invoiceClaimStates?: InputMaybe<BatchClaimToManyInvoiceClaimStateFilter>;
  /** Some related `invoiceClaimStates` exist. */
  invoiceClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `isAssigned` field. */
  isAssigned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isAssignedToQueryingUser` field. */
  isAssignedToQueryingUser?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `negotiationClaimState` field. */
  negotiationClaimState?: InputMaybe<NegotiationClaimStateEnumFilter>;
  /** Filter by the object’s `negotiationClaimStates` relation. */
  negotiationClaimStates?: InputMaybe<BatchClaimToManyNegotiationClaimStateFilter>;
  /** Some related `negotiationClaimStates` exist. */
  negotiationClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `onHoldClaimState` field. */
  onHoldClaimState?: InputMaybe<OnHoldClaimStateEnumFilter>;
  /** Filter by the object’s `onHoldClaimStates` relation. */
  onHoldClaimStates?: InputMaybe<BatchClaimToManyOnHoldClaimStateFilter>;
  /** Some related `onHoldClaimStates` exist. */
  onHoldClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `paidDate` field. */
  paidDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `patientDob` field. */
  patientDob?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `patientId` field. */
  patientId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `paymentRate` field. */
  paymentRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `permittedInsurerUsers` field. */
  permittedInsurerUsers?: InputMaybe<UUIDListFilter>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerSignOffClaimStates` relation. */
  providerSignOffClaimStates?: InputMaybe<BatchClaimToManyProviderSignOffClaimStateFilter>;
  /** Some related `providerSignOffClaimStates` exist. */
  providerSignOffClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `reportedClaimsByClaimId` relation. */
  reportedClaimsByClaimId?: InputMaybe<BatchClaimToManyReportedClaimFilter>;
  /** Some related `reportedClaimsByClaimId` exist. */
  reportedClaimsByClaimIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `s3AuditDocumentations` relation. */
  s3AuditDocumentations?: InputMaybe<BatchClaimToManyS3AuditDocumentationFilter>;
  /** Some related `s3AuditDocumentations` exist. */
  s3AuditDocumentationsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `workflowClaimStates` relation. */
  workflowClaimStates?: InputMaybe<BatchClaimToManyWorkflowClaimStateFilter>;
  /** Some related `workflowClaimStates` exist. */
  workflowClaimStatesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Grouping methods for `BatchClaim` for usage during aggregation. */
export enum BatchClaimGroupBy {
  ADM_TYPE = "ADM_TYPE",
  AMOUNT_CHARGED = "AMOUNT_CHARGED",
  AMOUNT_REIMBURSED = "AMOUNT_REIMBURSED",
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_FILE_ID = "BATCH_FILE_ID",
  BILL_TYPE = "BILL_TYPE",
  CLAIM_TYPE = "CLAIM_TYPE",
  COMPUTATION_STRATEGY = "COMPUTATION_STRATEGY",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DATE_ADMIT = "DATE_ADMIT",
  DATE_ADMIT_TRUNCATED_TO_DAY = "DATE_ADMIT_TRUNCATED_TO_DAY",
  DATE_ADMIT_TRUNCATED_TO_HOUR = "DATE_ADMIT_TRUNCATED_TO_HOUR",
  DATE_DISCHARGE = "DATE_DISCHARGE",
  DATE_DISCHARGE_TRUNCATED_TO_DAY = "DATE_DISCHARGE_TRUNCATED_TO_DAY",
  DATE_DISCHARGE_TRUNCATED_TO_HOUR = "DATE_DISCHARGE_TRUNCATED_TO_HOUR",
  DATE_OF_SERVICE_END = "DATE_OF_SERVICE_END",
  DATE_OF_SERVICE_END_TRUNCATED_TO_DAY = "DATE_OF_SERVICE_END_TRUNCATED_TO_DAY",
  DATE_OF_SERVICE_END_TRUNCATED_TO_HOUR = "DATE_OF_SERVICE_END_TRUNCATED_TO_HOUR",
  DATE_OF_SERVICE_START = "DATE_OF_SERVICE_START",
  DATE_OF_SERVICE_START_TRUNCATED_TO_DAY = "DATE_OF_SERVICE_START_TRUNCATED_TO_DAY",
  DATE_OF_SERVICE_START_TRUNCATED_TO_HOUR = "DATE_OF_SERVICE_START_TRUNCATED_TO_HOUR",
  INSURER_ID = "INSURER_ID",
  OWNER_ID = "OWNER_ID",
  PAID_DATE = "PAID_DATE",
  PAID_DATE_TRUNCATED_TO_DAY = "PAID_DATE_TRUNCATED_TO_DAY",
  PAID_DATE_TRUNCATED_TO_HOUR = "PAID_DATE_TRUNCATED_TO_HOUR",
  PATIENT_DOB = "PATIENT_DOB",
  PATIENT_DOB_TRUNCATED_TO_DAY = "PATIENT_DOB_TRUNCATED_TO_DAY",
  PATIENT_DOB_TRUNCATED_TO_HOUR = "PATIENT_DOB_TRUNCATED_TO_HOUR",
  PATIENT_ID = "PATIENT_ID",
  PAYMENT_RATE = "PAYMENT_RATE",
  PERMITTED_INSURER_USERS = "PERMITTED_INSURER_USERS",
  PROVIDER_ID = "PROVIDER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchClaimHavingAverageBatchClaimImproperPaymentCostArgsInput = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimHavingAverageBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingAverageBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingAverageInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingAverageBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingDistinctCountBatchClaimImproperPaymentCostArgsInput =
  {
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchClaimHavingDistinctCountBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingDistinctCountBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingDistinctCountInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingDistinctCountBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaim` aggregates. */
export type BatchClaimHavingInput = {
  AND?: InputMaybe<Array<BatchClaimHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimHavingInput>>;
  average?: InputMaybe<BatchClaimHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimHavingMaxInput>;
  min?: InputMaybe<BatchClaimHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimHavingVarianceSampleInput>;
};

export type BatchClaimHavingMaxBatchClaimImproperPaymentCostArgsInput = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimHavingMaxBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingMaxBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingMaxInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingMaxBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingMinBatchClaimImproperPaymentCostArgsInput = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimHavingMinBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingMinBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingMinInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingMinBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingStddevPopulationBatchClaimImproperPaymentCostArgsInput =
  {
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchClaimHavingStddevPopulationBatchClaimImproperPaymentCostInput =
  {
    args?: InputMaybe<BatchClaimHavingStddevPopulationBatchClaimImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type BatchClaimHavingStddevPopulationInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingStddevPopulationBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingStddevSampleBatchClaimImproperPaymentCostArgsInput =
  {
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchClaimHavingStddevSampleBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingStddevSampleBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingStddevSampleInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingStddevSampleBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingSumBatchClaimImproperPaymentCostArgsInput = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimHavingSumBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingSumBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingSumInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingSumBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingVariancePopulationBatchClaimImproperPaymentCostArgsInput =
  {
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchClaimHavingVariancePopulationBatchClaimImproperPaymentCostInput =
  {
    args?: InputMaybe<BatchClaimHavingVariancePopulationBatchClaimImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type BatchClaimHavingVariancePopulationInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingVariancePopulationBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimHavingVarianceSampleBatchClaimImproperPaymentCostArgsInput =
  {
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchClaimHavingVarianceSampleBatchClaimImproperPaymentCostInput = {
  args?: InputMaybe<BatchClaimHavingVarianceSampleBatchClaimImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimHavingVarianceSampleInput = {
  amountCharged?: InputMaybe<HavingFloatFilter>;
  amountReimbursed?: InputMaybe<HavingFloatFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateAdmit?: InputMaybe<HavingDatetimeFilter>;
  dateDischarge?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchClaimHavingVarianceSampleBatchClaimImproperPaymentCostInput>;
  paidDate?: InputMaybe<HavingDatetimeFilter>;
  patientDob?: InputMaybe<HavingDatetimeFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `insurer` in the `BatchClaimInput` mutation. */
export type BatchClaimInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaim` in the `InsurerInput` mutation. */
export type BatchClaimInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<Array<BatchClaimBatchClaimIcnKeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchClaimBatchClaimPkeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyUsingBatchClaimIcnKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyUsingBatchClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnBatchClaimForBatchClaimInsurerIdFkeyNodeIdUpdate>
  >;
};

export type BatchClaimLine = Node & {
  __typename?: "BatchClaimLine";
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindings: AuditFindingsConnection;
  /** Reads a single `BatchClaim` that is related to this `BatchClaimLine`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaimLineComputedValue` that is related to this `BatchClaimLine`. */
  batchClaimLineComputedValueById?: Maybe<BatchClaimLineComputedValue>;
  /**
   * Reads and enables pagination through a set of `BatchClaimLineComputedValue`.
   * @deprecated Please use batchClaimLineComputedValueById instead
   */
  batchClaimLineComputedValuesById: BatchClaimLineComputedValuesConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItem`. */
  batchClaimLineItems: BatchClaimLineItemsConnection;
  createdAt: Scalars["Datetime"]["output"];
  diag1?: Maybe<Scalars["String"]["output"]>;
  diag1Description?: Maybe<Scalars["String"]["output"]>;
  diag2?: Maybe<Scalars["String"]["output"]>;
  diag2Description?: Maybe<Scalars["String"]["output"]>;
  diag3?: Maybe<Scalars["String"]["output"]>;
  diag3Description?: Maybe<Scalars["String"]["output"]>;
  diag4?: Maybe<Scalars["String"]["output"]>;
  diag4Description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** Captures the order of the batch_claim_line row from the source input.  Conceptually maps back to the original UBO4/CMS1500 line number. */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  nonCoveredAmount: Scalars["Float"]["output"];
  paymentRate: Scalars["Float"]["output"];
  procedureCode?: Maybe<Scalars["String"]["output"]>;
  procedureDescription?: Maybe<Scalars["String"]["output"]>;
  procedureMod?: Maybe<Scalars["String"]["output"]>;
  revCode: Scalars["String"]["output"];
  revCodeSrc?: Maybe<Scalars["String"]["output"]>;
  revDescription?: Maybe<Scalars["String"]["output"]>;
  /** Captures the UB row specific ServDate if available. (Example: Box 45 of UB04) */
  serviceDate?: Maybe<Scalars["Datetime"]["output"]>;
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  units?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
};

export type BatchClaimLineauditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

export type BatchClaimLinebatchClaimLineComputedValuesByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineComputedValueCondition>;
  filter?: InputMaybe<BatchClaimLineComputedValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineComputedValuesOrderBy>>;
};

export type BatchClaimLinebatchClaimLineItemsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemCondition>;
  filter?: InputMaybe<BatchClaimLineItemFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

export type BatchClaimLineAggregates = {
  __typename?: "BatchClaimLineAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimLineAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimLineMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimLineMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimLineStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimLineStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimLineSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimLineVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimLineVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `BatchClaimLine` object types. */
export type BatchClaimLineAggregatesFilter = {
  /** Mean average aggregate over matching `BatchClaimLine` objects. */
  average?: InputMaybe<BatchClaimLineAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BatchClaimLine` objects. */
  distinctCount?: InputMaybe<BatchClaimLineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimLine` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimLineFilter>;
  /** Maximum aggregate over matching `BatchClaimLine` objects. */
  max?: InputMaybe<BatchClaimLineMaxAggregateFilter>;
  /** Minimum aggregate over matching `BatchClaimLine` objects. */
  min?: InputMaybe<BatchClaimLineMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BatchClaimLine` objects. */
  stddevPopulation?: InputMaybe<BatchClaimLineStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BatchClaimLine` objects. */
  stddevSample?: InputMaybe<BatchClaimLineStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BatchClaimLine` objects. */
  sum?: InputMaybe<BatchClaimLineSumAggregateFilter>;
  /** Population variance aggregate over matching `BatchClaimLine` objects. */
  variancePopulation?: InputMaybe<BatchClaimLineVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BatchClaimLine` objects. */
  varianceSample?: InputMaybe<BatchClaimLineVarianceSampleAggregateFilter>;
};

export type BatchClaimLineAverageAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineAverageAggregates = {
  __typename?: "BatchClaimLineAverageAggregates";
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchClaimLineInput` mutation. */
export type BatchClaimLineBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLine` in the `BatchClaimInput` mutation. */
export type BatchClaimLineBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchClaimLineBatchClaimLinePkeyConnect>>;
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `batchClaimLine` to look up the row to connect. */
export type BatchClaimLineBatchClaimLinePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * ### Overview
 * Computes calculations over audit findings for a given service (revenue code) on the unified bill, for use both in reporting and in the frontend.
 */
export type BatchClaimLineComputedValue = Node & {
  __typename?: "BatchClaimLineComputedValue";
  /** Reads a single `BatchClaimLine` that is related to this `BatchClaimLineComputedValue`. */
  batchClaimLineById?: Maybe<BatchClaimLine>;
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  determinedDiscrepantAmount: Scalars["Float"]["output"];
  determinedSavings: Scalars["Float"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  potentialDiscrepantAmount: Scalars["Float"]["output"];
  potentialSavings: Scalars["Float"]["output"];
};

export type BatchClaimLineComputedValueAggregates = {
  __typename?: "BatchClaimLineComputedValueAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimLineComputedValueAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineComputedValueDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimLineComputedValueMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimLineComputedValueMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimLineComputedValueStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimLineComputedValueStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimLineComputedValueSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimLineComputedValueVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimLineComputedValueVarianceSampleAggregates>;
};

export type BatchClaimLineComputedValueAverageAggregates = {
  __typename?: "BatchClaimLineComputedValueAverageAggregates";
  /** Mean average of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The fields on `batchClaimLineComputedValue` to look up the row to connect. */
export type BatchClaimLineComputedValueBatchClaimLineComputedValuesPkeyConnect =
  {
    id: Scalars["UUID"]["input"];
  };

/**
 * A condition to be used against `BatchClaimLineComputedValue` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineComputedValueCondition = {
  /** Checks for equality with the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedAdjustedBilledAmount` field. */
  determinedAdjustedBilledAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedSavings` field. */
  determinedSavings?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialAdjustedBilledAmount` field. */
  potentialAdjustedBilledAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialSavings` field. */
  potentialSavings?: InputMaybe<Scalars["Float"]["input"]>;
};

export type BatchClaimLineComputedValueDistinctCountAggregates = {
  __typename?: "BatchClaimLineComputedValueDistinctCountAggregates";
  /** Distinct count of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLineComputedValue` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineComputedValueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineComputedValueFilter>>;
  /** Filter by the object’s `batchClaimLineById` relation. */
  batchClaimLineById?: InputMaybe<BatchClaimLineFilter>;
  /** Filter by the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedAdjustedBilledAmount` field. */
  determinedAdjustedBilledAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedSavings` field. */
  determinedSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineComputedValueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineComputedValueFilter>>;
  /** Filter by the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialAdjustedBilledAmount` field. */
  potentialAdjustedBilledAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialSavings` field. */
  potentialSavings?: InputMaybe<FloatFilter>;
};

export type BatchClaimLineComputedValueMaxAggregates = {
  __typename?: "BatchClaimLineComputedValueMaxAggregates";
  /** Maximum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineComputedValueMinAggregates = {
  __typename?: "BatchClaimLineComputedValueMinAggregates";
  /** Minimum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineComputedValueNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLineComputedValue` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: BatchClaimLinePatch;
  };

/** The fields on `batchClaimLineComputedValue` to look up the row to update. */
export type BatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyUsingBatchClaimLineComputedValuesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineComputedValue` being updated. */
    patch: updateBatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLineComputedValue`. Fields that are set will be updated. */
export type BatchClaimLineComputedValuePatch = {
  batchClaimLineToId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimLineComputedValueStddevPopulationAggregates = {
  __typename?: "BatchClaimLineComputedValueStddevPopulationAggregates";
  /** Population standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineComputedValueStddevSampleAggregates = {
  __typename?: "BatchClaimLineComputedValueStddevSampleAggregates";
  /** Sample standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineComputedValueSumAggregates = {
  __typename?: "BatchClaimLineComputedValueSumAggregates";
  /** Sum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount: Scalars["Float"]["output"];
  /** Sum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount: Scalars["Float"]["output"];
  /** Sum of determinedSavings across the matching connection */
  determinedSavings: Scalars["Float"]["output"];
  /** Sum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount: Scalars["Float"]["output"];
  /** Sum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount: Scalars["Float"]["output"];
  /** Sum of potentialSavings across the matching connection */
  potentialSavings: Scalars["Float"]["output"];
};

export type BatchClaimLineComputedValueVariancePopulationAggregates = {
  __typename?: "BatchClaimLineComputedValueVariancePopulationAggregates";
  /** Population variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineComputedValueVarianceSampleAggregates = {
  __typename?: "BatchClaimLineComputedValueVarianceSampleAggregates";
  /** Sample variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `BatchClaimLineComputedValue` values. */
export type BatchClaimLineComputedValuesConnection = {
  __typename?: "BatchClaimLineComputedValuesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineComputedValueAggregates>;
  /** A list of edges which contains the `BatchClaimLineComputedValue` and cursor to aid in pagination. */
  edges: Array<BatchClaimLineComputedValuesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineComputedValueAggregates>>;
  /** A list of `BatchClaimLineComputedValue` objects. */
  nodes: Array<Maybe<BatchClaimLineComputedValue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLineComputedValue` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLineComputedValue` values. */
export type BatchClaimLineComputedValuesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineComputedValuesGroupBy>;
  having?: InputMaybe<BatchClaimLineComputedValuesHavingInput>;
};

/** A `BatchClaimLineComputedValue` edge in the connection. */
export type BatchClaimLineComputedValuesEdge = {
  __typename?: "BatchClaimLineComputedValuesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLineComputedValue` at the end of the edge. */
  node?: Maybe<BatchClaimLineComputedValue>;
};

/** Grouping methods for `BatchClaimLineComputedValue` for usage during aggregation. */
export enum BatchClaimLineComputedValuesGroupBy {
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT",
  DETERMINED_ADJUSTED_BILLED_AMOUNT = "DETERMINED_ADJUSTED_BILLED_AMOUNT",
  DETERMINED_DISCREPANT_AMOUNT = "DETERMINED_DISCREPANT_AMOUNT",
  DETERMINED_SAVINGS = "DETERMINED_SAVINGS",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT = "POTENTIAL_ADJUSTED_BILLED_AMOUNT",
  POTENTIAL_DISCREPANT_AMOUNT = "POTENTIAL_DISCREPANT_AMOUNT",
  POTENTIAL_SAVINGS = "POTENTIAL_SAVINGS",
}

export type BatchClaimLineComputedValuesHavingAverageInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingDistinctCountInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `BatchClaimLineComputedValue` aggregates. */
export type BatchClaimLineComputedValuesHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineComputedValuesHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineComputedValuesHavingInput>>;
  average?: InputMaybe<BatchClaimLineComputedValuesHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineComputedValuesHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineComputedValuesHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineComputedValuesHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineComputedValuesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineComputedValuesHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineComputedValuesHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineComputedValuesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineComputedValuesHavingVarianceSampleInput>;
};

export type BatchClaimLineComputedValuesHavingMaxInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingMinInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingStddevPopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingStddevSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingSumInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingVariancePopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineComputedValuesHavingVarianceSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

/** Input for the nested mutation of `batchClaimLine` in the `BatchClaimLineComputedValueInput` mutation. */
export type BatchClaimLineComputedValuesIdFkeyInput = {
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineBatchClaimLinePkeyConnect>;
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyUsingBatchClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineComputedValue` in the `BatchClaimLineInput` mutation. */
export type BatchClaimLineComputedValuesIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineComputedValue` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineComputedValueBatchClaimLineComputedValuesPkeyConnect>;
  /** The primary key(s) for `batchClaimLineComputedValue` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineComputedValueNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLineComputedValue` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyUsingBatchClaimLineComputedValuesPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineComputedValue` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyNodeIdUpdate>;
};

/** Methods to use when ordering `BatchClaimLineComputedValue`. */
export enum BatchClaimLineComputedValuesOrderBy {
  BATCH_CLAIM_LINE_BY_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_BY_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_BY_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_BY_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_BY_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_BY_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_BY_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_1_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_1_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_2_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_2_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_3_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_3_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_4_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_4_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__ID_ASC = "BATCH_CLAIM_LINE_BY_ID__ID_ASC",
  BATCH_CLAIM_LINE_BY_ID__ID_DESC = "BATCH_CLAIM_LINE_BY_ID__ID_DESC",
  BATCH_CLAIM_LINE_BY_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_BY_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_BY_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_BY_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_BY_ID__NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINE_BY_ID__NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_BY_ID__NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINE_BY_ID__NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_BY_ID__NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_ID__NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_ID__NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_ID__NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_LINE_BY_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINE_BY_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_LINE_BY_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINE_BY_ID__PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINE_BY_ID__PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINE_BY_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_BY_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_BY_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_BY_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_BY_ID__REV_CODE_SRC_ASC = "BATCH_CLAIM_LINE_BY_ID__REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINE_BY_ID__REV_CODE_SRC_DESC = "BATCH_CLAIM_LINE_BY_ID__REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINE_BY_ID__REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_ID__REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_ID__REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_ID__REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_ID__SERVICE_DATE_ASC = "BATCH_CLAIM_LINE_BY_ID__SERVICE_DATE_ASC",
  BATCH_CLAIM_LINE_BY_ID__SERVICE_DATE_DESC = "BATCH_CLAIM_LINE_BY_ID__SERVICE_DATE_DESC",
  BATCH_CLAIM_LINE_BY_ID__UNITS_ASC = "BATCH_CLAIM_LINE_BY_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_BY_ID__UNITS_DESC = "BATCH_CLAIM_LINE_BY_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_BY_ID__UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_ID__UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_ID__UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_ID__UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_BY_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_BY_ID__UPDATED_AT_DESC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC = "DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC",
  DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC = "DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC",
  DETERMINED_DISCREPANT_AMOUNT_ASC = "DETERMINED_DISCREPANT_AMOUNT_ASC",
  DETERMINED_DISCREPANT_AMOUNT_DESC = "DETERMINED_DISCREPANT_AMOUNT_DESC",
  DETERMINED_SAVINGS_ASC = "DETERMINED_SAVINGS_ASC",
  DETERMINED_SAVINGS_DESC = "DETERMINED_SAVINGS_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC = "POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC = "POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC",
  POTENTIAL_DISCREPANT_AMOUNT_ASC = "POTENTIAL_DISCREPANT_AMOUNT_ASC",
  POTENTIAL_DISCREPANT_AMOUNT_DESC = "POTENTIAL_DISCREPANT_AMOUNT_DESC",
  POTENTIAL_SAVINGS_ASC = "POTENTIAL_SAVINGS_ASC",
  POTENTIAL_SAVINGS_DESC = "POTENTIAL_SAVINGS_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

/**
 * A condition to be used against `BatchClaimLine` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `diag1` field. */
  diag1?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag1Description` field. */
  diag1Description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag2` field. */
  diag2?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag2Description` field. */
  diag2Description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag3` field. */
  diag3?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag3Description` field. */
  diag3Description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag4` field. */
  diag4?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `diag4Description` field. */
  diag4Description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `nonCovCharge` field. */
  nonCovCharge?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `nonCoveredAmount` field. */
  nonCoveredAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `paymentRate` field. */
  paymentRate?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `procedureDescription` field. */
  procedureDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `procedureMod` field. */
  procedureMod?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revCode` field. */
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revCodeSrc` field. */
  revCodeSrc?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revDescription` field. */
  revDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `serviceDate` field. */
  serviceDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `unitAllowableCharge` field. */
  unitAllowableCharge?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `unitCharge` field. */
  unitCharge?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `units` field. */
  units?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimLineDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  diag1?: InputMaybe<BigIntFilter>;
  diag1Description?: InputMaybe<BigIntFilter>;
  diag2?: InputMaybe<BigIntFilter>;
  diag2Description?: InputMaybe<BigIntFilter>;
  diag3?: InputMaybe<BigIntFilter>;
  diag3Description?: InputMaybe<BigIntFilter>;
  diag4?: InputMaybe<BigIntFilter>;
  diag4Description?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lineNumber?: InputMaybe<BigIntFilter>;
  nonCovCharge?: InputMaybe<BigIntFilter>;
  nonCoveredAmount?: InputMaybe<BigIntFilter>;
  paymentRate?: InputMaybe<BigIntFilter>;
  procedureCode?: InputMaybe<BigIntFilter>;
  procedureDescription?: InputMaybe<BigIntFilter>;
  procedureMod?: InputMaybe<BigIntFilter>;
  revCode?: InputMaybe<BigIntFilter>;
  revCodeSrc?: InputMaybe<BigIntFilter>;
  revDescription?: InputMaybe<BigIntFilter>;
  serviceDate?: InputMaybe<BigIntFilter>;
  unitAllowableCharge?: InputMaybe<BigIntFilter>;
  unitCharge?: InputMaybe<BigIntFilter>;
  units?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineDistinctCountAggregates = {
  __typename?: "BatchClaimLineDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag1 across the matching connection */
  diag1?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag1Description across the matching connection */
  diag1Description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag2 across the matching connection */
  diag2?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag2Description across the matching connection */
  diag2Description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag3 across the matching connection */
  diag3?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag3Description across the matching connection */
  diag3Description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag4 across the matching connection */
  diag4?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of diag4Description across the matching connection */
  diag4Description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureCode across the matching connection */
  procedureCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureDescription across the matching connection */
  procedureDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureMod across the matching connection */
  procedureMod?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revCode across the matching connection */
  revCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revCodeSrc across the matching connection */
  revCodeSrc?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revDescription across the matching connection */
  revDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of serviceDate across the matching connection */
  serviceDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of units across the matching connection */
  units?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLine` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineFilter>>;
  /** Filter by the object’s `auditFindings` relation. */
  auditFindings?: InputMaybe<BatchClaimLineToManyAuditFindingFilter>;
  /** Some related `auditFindings` exist. */
  auditFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineComputedValueById` relation. */
  batchClaimLineComputedValueById?: InputMaybe<BatchClaimLineComputedValueFilter>;
  /** A related `batchClaimLineComputedValueById` exists. */
  batchClaimLineComputedValueByIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `batchClaimLineItems` relation. */
  batchClaimLineItems?: InputMaybe<BatchClaimLineToManyBatchClaimLineItemFilter>;
  /** Some related `batchClaimLineItems` exist. */
  batchClaimLineItemsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `diag1` field. */
  diag1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag1Description` field. */
  diag1Description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag2` field. */
  diag2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag2Description` field. */
  diag2Description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag3` field. */
  diag3?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag3Description` field. */
  diag3Description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag4` field. */
  diag4?: InputMaybe<StringFilter>;
  /** Filter by the object’s `diag4Description` field. */
  diag4Description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `nonCovCharge` field. */
  nonCovCharge?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `nonCoveredAmount` field. */
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineFilter>>;
  /** Filter by the object’s `paymentRate` field. */
  paymentRate?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `procedureDescription` field. */
  procedureDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `procedureMod` field. */
  procedureMod?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revCode` field. */
  revCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revCodeSrc` field. */
  revCodeSrc?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revDescription` field. */
  revDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `serviceDate` field. */
  serviceDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `unitAllowableCharge` field. */
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `unitCharge` field. */
  unitCharge?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `units` field. */
  units?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BatchClaimLine` for usage during aggregation. */
export enum BatchClaimLineGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DIAG_1 = "DIAG_1",
  DIAG_1_DESCRIPTION = "DIAG_1_DESCRIPTION",
  DIAG_2 = "DIAG_2",
  DIAG_2_DESCRIPTION = "DIAG_2_DESCRIPTION",
  DIAG_3 = "DIAG_3",
  DIAG_3_DESCRIPTION = "DIAG_3_DESCRIPTION",
  DIAG_4 = "DIAG_4",
  DIAG_4_DESCRIPTION = "DIAG_4_DESCRIPTION",
  LINE_NUMBER = "LINE_NUMBER",
  NON_COVERED_AMOUNT = "NON_COVERED_AMOUNT",
  NON_COV_CHARGE = "NON_COV_CHARGE",
  PAYMENT_RATE = "PAYMENT_RATE",
  PROCEDURE_CODE = "PROCEDURE_CODE",
  PROCEDURE_DESCRIPTION = "PROCEDURE_DESCRIPTION",
  PROCEDURE_MOD = "PROCEDURE_MOD",
  REV_CODE = "REV_CODE",
  REV_CODE_SRC = "REV_CODE_SRC",
  REV_DESCRIPTION = "REV_DESCRIPTION",
  SERVICE_DATE = "SERVICE_DATE",
  SERVICE_DATE_TRUNCATED_TO_DAY = "SERVICE_DATE_TRUNCATED_TO_DAY",
  SERVICE_DATE_TRUNCATED_TO_HOUR = "SERVICE_DATE_TRUNCATED_TO_HOUR",
  UNITS = "UNITS",
  UNIT_ALLOWABLE_CHARGE = "UNIT_ALLOWABLE_CHARGE",
  UNIT_CHARGE = "UNIT_CHARGE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchClaimLineHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimLine` aggregates. */
export type BatchClaimLineHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineHavingInput>>;
  average?: InputMaybe<BatchClaimLineHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineHavingVarianceSampleInput>;
};

export type BatchClaimLineHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  nonCovCharge?: InputMaybe<HavingFloatFilter>;
  nonCoveredAmount?: InputMaybe<HavingFloatFilter>;
  paymentRate?: InputMaybe<HavingFloatFilter>;
  serviceDate?: InputMaybe<HavingDatetimeFilter>;
  unitAllowableCharge?: InputMaybe<HavingFloatFilter>;
  unitCharge?: InputMaybe<HavingFloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimLine` */
export type BatchClaimLineInput = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  diag1?: InputMaybe<Scalars["String"]["input"]>;
  diag1Description?: InputMaybe<Scalars["String"]["input"]>;
  diag2?: InputMaybe<Scalars["String"]["input"]>;
  diag2Description?: InputMaybe<Scalars["String"]["input"]>;
  diag3?: InputMaybe<Scalars["String"]["input"]>;
  diag3Description?: InputMaybe<Scalars["String"]["input"]>;
  diag4?: InputMaybe<Scalars["String"]["input"]>;
  diag4Description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Captures the order of the batch_claim_line row from the source input.  Conceptually maps back to the original UBO4/CMS1500 line number. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  nonCovCharge?: InputMaybe<Scalars["Float"]["input"]>;
  nonCoveredAmount?: InputMaybe<Scalars["Float"]["input"]>;
  paymentRate?: InputMaybe<Scalars["Float"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureDescription?: InputMaybe<Scalars["String"]["input"]>;
  procedureMod?: InputMaybe<Scalars["String"]["input"]>;
  revCode: Scalars["String"]["input"];
  revCodeSrc?: InputMaybe<Scalars["String"]["input"]>;
  revDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Captures the UB row specific ServDate if available. (Example: Box 45 of UB04) */
  serviceDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  unitAllowableCharge?: InputMaybe<Scalars["Float"]["input"]>;
  unitCharge?: InputMaybe<Scalars["Float"]["input"]>;
  units?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimLineItem = Node & {
  __typename?: "BatchClaimLineItem";
  /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
  alaRowId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItem`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchClaim` that is related to this `BatchClaimLineItem`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchClaimLine` that is related to this `BatchClaimLineItem`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  batchClaimLineId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `BatchClaimLineItemAudit`. */
  batchClaimLineItemAudits: BatchClaimLineItemAuditsConnection;
  /** Reads a single `BatchClaimLineItemComputedValue` that is related to this `BatchClaimLineItem`. */
  batchClaimLineItemComputedValueById?: Maybe<BatchClaimLineItemComputedValue>;
  /**
   * Reads and enables pagination through a set of `BatchClaimLineItemComputedValue`.
   * @deprecated Please use batchClaimLineItemComputedValueById instead
   */
  batchClaimLineItemComputedValuesById: BatchClaimLineItemComputedValuesConnection;
  /** Reads and enables pagination through a set of `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItemNnIbLines: BatchClaimLineItemNnIbLinesConnection;
  createdAt: Scalars["Datetime"]["output"];
  dateOfServiceEnd?: Maybe<Scalars["Datetime"]["output"]>;
  dateOfServiceStart: Scalars["Datetime"]["output"];
  description: Scalars["String"]["output"];
  ibLineId?: Maybe<Scalars["UUID"]["output"]>;
  ibLineVersionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
  id: Scalars["UUID"]["output"];
  itemNumber?: Maybe<Scalars["String"]["output"]>;
  /** # Represents the order of the IB line in the source CSV input. */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /**
   * ### original_version_id
   *  Original version id, used in versioning
   */
  originalVersionId?: Maybe<Scalars["UUID"]["output"]>;
  /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  /**
   * ### previous_version_id
   *  Previous version id, used in versioning
   */
  previousVersionId?: Maybe<Scalars["UUID"]["output"]>;
  procedureCode?: Maybe<Scalars["String"]["output"]>;
  procedureCodeModifier?: Maybe<Scalars["String"]["output"]>;
  revCode: Scalars["String"]["output"];
  /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
  s3InputSource?: Maybe<Scalars["String"]["output"]>;
  /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  unitCharge: Scalars["BigFloat"]["output"];
  units: Scalars["Int"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
  /**
   * ### version_fingerprint
   *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
   */
  versionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
};

export type BatchClaimLineItembatchClaimLineItemAuditsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemAuditCondition>;
  filter?: InputMaybe<BatchClaimLineItemAuditFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemAuditsOrderBy>>;
};

export type BatchClaimLineItembatchClaimLineItemComputedValuesByIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemComputedValueCondition>;
  filter?: InputMaybe<BatchClaimLineItemComputedValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemComputedValuesOrderBy>>;
};

export type BatchClaimLineItembatchClaimLineItemNnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemNnIbLineCondition>;
  filter?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
};

export type BatchClaimLineItemAggregates = {
  __typename?: "BatchClaimLineItemAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimLineItemAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineItemDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimLineItemMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimLineItemMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimLineItemStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimLineItemStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimLineItemSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimLineItemVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimLineItemVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `BatchClaimLineItem` object types. */
export type BatchClaimLineItemAggregatesFilter = {
  /** Mean average aggregate over matching `BatchClaimLineItem` objects. */
  average?: InputMaybe<BatchClaimLineItemAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BatchClaimLineItem` objects. */
  distinctCount?: InputMaybe<BatchClaimLineItemDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimLineItem` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimLineItemFilter>;
  /** Maximum aggregate over matching `BatchClaimLineItem` objects. */
  max?: InputMaybe<BatchClaimLineItemMaxAggregateFilter>;
  /** Minimum aggregate over matching `BatchClaimLineItem` objects. */
  min?: InputMaybe<BatchClaimLineItemMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BatchClaimLineItem` objects. */
  stddevPopulation?: InputMaybe<BatchClaimLineItemStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BatchClaimLineItem` objects. */
  stddevSample?: InputMaybe<BatchClaimLineItemStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BatchClaimLineItem` objects. */
  sum?: InputMaybe<BatchClaimLineItemSumAggregateFilter>;
  /** Population variance aggregate over matching `BatchClaimLineItem` objects. */
  variancePopulation?: InputMaybe<BatchClaimLineItemVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BatchClaimLineItem` objects. */
  varianceSample?: InputMaybe<BatchClaimLineItemVarianceSampleAggregateFilter>;
};

export type BatchClaimLineItemAudit = Node & {
  __typename?: "BatchClaimLineItemAudit";
  /** Reads a single `AuditFinding` that is related to this `BatchClaimLineItemAudit`. */
  auditFinding?: Maybe<AuditFinding>;
  auditFindingId: Scalars["UUID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItemAudit`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemAudit`. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  batchClaimLineItemId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type BatchClaimLineItemAuditAggregates = {
  __typename?: "BatchClaimLineItemAuditAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineItemAuditDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `BatchClaimLineItemAudit` object types. */
export type BatchClaimLineItemAuditAggregatesFilter = {
  /** Distinct count aggregate over matching `BatchClaimLineItemAudit` objects. */
  distinctCount?: InputMaybe<BatchClaimLineItemAuditDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimLineItemAudit` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimLineItemAuditFilter>;
};

/** The `auditFinding` to be created by this mutation. */
export type BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyAuditFindingCreateInput =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType: AuditFindingSeedType;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id: Scalars["UUID"]["input"];
    improperPaymentCost: Scalars["Float"]["input"];
    improperPaymentReason: Scalars["String"]["input"];
    improperPaymentUnitsCharged: Scalars["Int"]["input"];
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview: Scalars["Boolean"]["input"];
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `batchClaimLineItemAudit` to be created by this mutation. */
export type BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyBatchClaimLineItemAuditCreateInput =
  {
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditFinding` in the `BatchClaimLineItemAuditInput` mutation. */
export type BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemAudit` in the `AuditFindingInput` mutation. */
export type BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByAuditFindingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemAuditAuditFindingIdUniqueConnect>;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemAuditPkeyConnect>;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineItemAuditNodeIdConnect>;
  /** A `BatchClaimLineItemAuditInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyBatchClaimLineItemAuditCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByAuditFindingId?: InputMaybe<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyNodeIdUpdate>;
};

/** The `auditorUser` to be created by this mutation. */
export type BatchClaimLineItemAuditAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `batchClaimLineItemAudit` to be created by this mutation. */
export type BatchClaimLineItemAuditAuthorIdFkeyBatchClaimLineItemAuditCreateInput =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditorUser` in the `BatchClaimLineItemAuditInput` mutation. */
export type BatchClaimLineItemAuditAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemAudit` in the `AuditorUserInput` mutation. */
export type BatchClaimLineItemAuditAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByAuditFindingId?: InputMaybe<
    Array<BatchClaimLineItemAuditBatchClaimLineItemAuditAuditFindingIdUniqueConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemAuditBatchClaimLineItemAuditPkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemAuditNodeIdConnect>>;
  /** A `BatchClaimLineItemAuditInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemAuditAuthorIdFkeyBatchClaimLineItemAuditCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByAuditFindingId?: InputMaybe<
    Array<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `batchClaimLineItemAudit` to look up the row to connect. */
export type BatchClaimLineItemAuditBatchClaimLineItemAuditAuditFindingIdUniqueConnect =
  {
    auditFindingId: Scalars["UUID"]["input"];
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to connect. */
export type BatchClaimLineItemAuditBatchClaimLineItemAuditPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The `batchClaimLineItemAudit` to be created by this mutation. */
export type BatchClaimLineItemAuditBatchClaimLineItemIdFkeyBatchClaimLineItemAuditCreateInput =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `batchClaimLineItem` to be created by this mutation. */
export type BatchClaimLineItemAuditBatchClaimLineItemIdFkeyBatchClaimLineItemCreateInput =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart: Scalars["Datetime"]["input"];
    description: Scalars["String"]["input"];
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode: Scalars["String"]["input"];
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge: Scalars["BigFloat"]["input"];
    units: Scalars["Int"]["input"];
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `batchClaimLineItem` in the `BatchClaimLineItemAuditInput` mutation. */
export type BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineItemBatchClaimLineItemPkeyConnect>;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineItemNodeIdConnect>;
  /** A `BatchClaimLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyBatchClaimLineItemCreateInput>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemAudit` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByAuditFindingId?: InputMaybe<
    Array<BatchClaimLineItemAuditBatchClaimLineItemAuditAuditFindingIdUniqueConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemAuditBatchClaimLineItemAuditPkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemAudit` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemAuditNodeIdConnect>>;
  /** A `BatchClaimLineItemAuditInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyBatchClaimLineItemAuditCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByAuditFindingId?: InputMaybe<
    Array<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemAudit` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `BatchClaimLineItemAudit` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineItemAuditCondition = {
  /** Checks for equality with the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimLineItemId` field. */
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimLineItemAuditDistinctCountAggregateFilter = {
  auditFindingId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  batchClaimLineItemId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineItemAuditDistinctCountAggregates = {
  __typename?: "BatchClaimLineItemAuditDistinctCountAggregates";
  /** Distinct count of auditFindingId across the matching connection */
  auditFindingId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimLineItemId across the matching connection */
  batchClaimLineItemId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLineItemAudit` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemAuditFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineItemAuditFilter>>;
  /** Filter by the object’s `auditFinding` relation. */
  auditFinding?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineItem` relation. */
  batchClaimLineItem?: InputMaybe<BatchClaimLineItemFilter>;
  /** Filter by the object’s `batchClaimLineItemId` field. */
  batchClaimLineItemId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineItemAuditFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BatchClaimLineItemAudit` for usage during aggregation. */
export enum BatchClaimLineItemAuditGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_CLAIM_LINE_ITEM_ID = "BATCH_CLAIM_LINE_ITEM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchClaimLineItemAuditHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimLineItemAudit` aggregates. */
export type BatchClaimLineItemAuditHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineItemAuditHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineItemAuditHavingInput>>;
  average?: InputMaybe<BatchClaimLineItemAuditHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineItemAuditHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineItemAuditHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineItemAuditHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineItemAuditHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineItemAuditHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineItemAuditHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineItemAuditHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineItemAuditHavingVarianceSampleInput>;
};

export type BatchClaimLineItemAuditHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemAuditHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimLineItemAudit` */
export type BatchClaimLineItemAuditInput = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineItemAuditNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLineItemAudit` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate =
  {
    auditFindingId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate =
  {
    auditFindingId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemAuditAuditFindingIdUniqueUpdate =
  {
    auditFindingId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemAudit` to look up the row to update. */
export type BatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemAuditPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLineItemAudit`. Fields that are set will be updated. */
export type BatchClaimLineItemAuditPatch = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
};

/** A connection to a list of `BatchClaimLineItemAudit` values. */
export type BatchClaimLineItemAuditsConnection = {
  __typename?: "BatchClaimLineItemAuditsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineItemAuditAggregates>;
  /** A list of edges which contains the `BatchClaimLineItemAudit` and cursor to aid in pagination. */
  edges: Array<BatchClaimLineItemAuditsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineItemAuditAggregates>>;
  /** A list of `BatchClaimLineItemAudit` objects. */
  nodes: Array<Maybe<BatchClaimLineItemAudit>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLineItemAudit` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLineItemAudit` values. */
export type BatchClaimLineItemAuditsConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineItemAuditGroupBy>;
  having?: InputMaybe<BatchClaimLineItemAuditHavingInput>;
};

/** A `BatchClaimLineItemAudit` edge in the connection. */
export type BatchClaimLineItemAuditsEdge = {
  __typename?: "BatchClaimLineItemAuditsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLineItemAudit` at the end of the edge. */
  node?: Maybe<BatchClaimLineItemAudit>;
};

/** Methods to use when ordering `BatchClaimLineItemAudit`. */
export enum BatchClaimLineItemAuditsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_ID_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** The `auditorUser` to be created by this mutation. */
export type BatchClaimLineItemAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `batchClaimLineItem` to be created by this mutation. */
export type BatchClaimLineItemAuthorIdFkeyBatchClaimLineItemCreateInput = {
  /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart: Scalars["Datetime"]["input"];
  description: Scalars["String"]["input"];
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the order of the IB line in the source CSV input. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /**
   * ### original_version_id
   *  Original version id, used in versioning
   */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * ### previous_version_id
   *  Previous version id, used in versioning
   */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode: Scalars["String"]["input"];
  /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
  s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  unitCharge: Scalars["BigFloat"]["input"];
  units: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /**
   * ### version_fingerprint
   *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
   */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `auditorUser` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItem` in the `AuditorUserInput` mutation. */
export type BatchClaimLineItemAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemBatchClaimLineItemPkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNodeIdConnect>>;
  /** A `BatchClaimLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemAuthorIdFkeyBatchClaimLineItemCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingBatchClaimLineItemPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyNodeIdUpdate>
  >;
};

export type BatchClaimLineItemAverageAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigFloatFilter>;
  tableNumber?: InputMaybe<BigFloatFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineItemAverageAggregates = {
  __typename?: "BatchClaimLineItemAverageAggregates";
  /** Mean average of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** The `batchClaimLineItem` to be created by this mutation. */
export type BatchClaimLineItemBatchClaimIdFkeyBatchClaimLineItemCreateInput = {
  /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart: Scalars["Datetime"]["input"];
  description: Scalars["String"]["input"];
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the order of the IB line in the source CSV input. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /**
   * ### original_version_id
   *  Original version id, used in versioning
   */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * ### previous_version_id
   *  Previous version id, used in versioning
   */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode: Scalars["String"]["input"];
  /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
  s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  unitCharge: Scalars["BigFloat"]["input"];
  units: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /**
   * ### version_fingerprint
   *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
   */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItem` in the `BatchClaimInput` mutation. */
export type BatchClaimLineItemBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemBatchClaimLineItemPkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNodeIdConnect>>;
  /** A `BatchClaimLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemBatchClaimIdFkeyBatchClaimLineItemCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimLineItemPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyNodeIdUpdate>
  >;
};

/** The `batchClaimLineItem` to be created by this mutation. */
export type BatchClaimLineItemBatchClaimLineIdFkeyBatchClaimLineItemCreateInput =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart: Scalars["Datetime"]["input"];
    description: Scalars["String"]["input"];
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode: Scalars["String"]["input"];
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge: Scalars["BigFloat"]["input"];
    units: Scalars["Int"]["input"];
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `batchClaimLine` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemBatchClaimLineIdFkeyInput = {
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineBatchClaimLinePkeyConnect>;
  /** The primary key(s) for `batchClaimLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyUsingBatchClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItem` in the `BatchClaimLineInput` mutation. */
export type BatchClaimLineItemBatchClaimLineIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemBatchClaimLineItemPkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNodeIdConnect>>;
  /** A `BatchClaimLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemBatchClaimLineIdFkeyBatchClaimLineItemCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyUsingBatchClaimLineItemPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `batchClaimLineItem` to look up the row to connect. */
export type BatchClaimLineItemBatchClaimLineItemPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * ### Overview
 * Computes calculations over the audit findings on an itemized bill for a given service, for use both in reporting and in the frontend.
 */
export type BatchClaimLineItemComputedValue = Node & {
  __typename?: "BatchClaimLineItemComputedValue";
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemComputedValue`. */
  batchClaimLineItemById?: Maybe<BatchClaimLineItem>;
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  determinedDiscrepantAmount: Scalars["Float"]["output"];
  determinedSavings: Scalars["Float"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  potentialDiscrepantAmount: Scalars["Float"]["output"];
  potentialSavings: Scalars["Float"]["output"];
};

export type BatchClaimLineItemComputedValueAggregates = {
  __typename?: "BatchClaimLineItemComputedValueAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimLineItemComputedValueAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineItemComputedValueDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimLineItemComputedValueMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimLineItemComputedValueMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimLineItemComputedValueStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimLineItemComputedValueStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimLineItemComputedValueSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimLineItemComputedValueVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimLineItemComputedValueVarianceSampleAggregates>;
};

export type BatchClaimLineItemComputedValueAverageAggregates = {
  __typename?: "BatchClaimLineItemComputedValueAverageAggregates";
  /** Mean average of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The fields on `batchClaimLineItemComputedValue` to look up the row to connect. */
export type BatchClaimLineItemComputedValueBatchClaimLineItemComputedValuesPkeyConnect =
  {
    id: Scalars["UUID"]["input"];
  };

/**
 * A condition to be used against `BatchClaimLineItemComputedValue` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineItemComputedValueCondition = {
  /** Checks for equality with the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedAdjustedBilledAmount` field. */
  determinedAdjustedBilledAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedSavings` field. */
  determinedSavings?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialAdjustedBilledAmount` field. */
  potentialAdjustedBilledAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialSavings` field. */
  potentialSavings?: InputMaybe<Scalars["Float"]["input"]>;
};

export type BatchClaimLineItemComputedValueDistinctCountAggregates = {
  __typename?: "BatchClaimLineItemComputedValueDistinctCountAggregates";
  /** Distinct count of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLineItemComputedValue` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemComputedValueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineItemComputedValueFilter>>;
  /** Filter by the object’s `batchClaimLineItemById` relation. */
  batchClaimLineItemById?: InputMaybe<BatchClaimLineItemFilter>;
  /** Filter by the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedAdjustedBilledAmount` field. */
  determinedAdjustedBilledAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedSavings` field. */
  determinedSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineItemComputedValueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineItemComputedValueFilter>>;
  /** Filter by the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialAdjustedBilledAmount` field. */
  potentialAdjustedBilledAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialSavings` field. */
  potentialSavings?: InputMaybe<FloatFilter>;
};

export type BatchClaimLineItemComputedValueMaxAggregates = {
  __typename?: "BatchClaimLineItemComputedValueMaxAggregates";
  /** Maximum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineItemComputedValueMinAggregates = {
  __typename?: "BatchClaimLineItemComputedValueMinAggregates";
  /** Minimum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineItemComputedValueNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLineItemComputedValue` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `batchClaimLineItemComputedValue` to look up the row to update. */
export type BatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyUsingBatchClaimLineItemComputedValuesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemComputedValue` being updated. */
    patch: updateBatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLineItemComputedValue`. Fields that are set will be updated. */
export type BatchClaimLineItemComputedValuePatch = {
  batchClaimLineItemToId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimLineItemComputedValueStddevPopulationAggregates = {
  __typename?: "BatchClaimLineItemComputedValueStddevPopulationAggregates";
  /** Population standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineItemComputedValueStddevSampleAggregates = {
  __typename?: "BatchClaimLineItemComputedValueStddevSampleAggregates";
  /** Sample standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineItemComputedValueSumAggregates = {
  __typename?: "BatchClaimLineItemComputedValueSumAggregates";
  /** Sum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount: Scalars["Float"]["output"];
  /** Sum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount: Scalars["Float"]["output"];
  /** Sum of determinedSavings across the matching connection */
  determinedSavings: Scalars["Float"]["output"];
  /** Sum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount: Scalars["Float"]["output"];
  /** Sum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount: Scalars["Float"]["output"];
  /** Sum of potentialSavings across the matching connection */
  potentialSavings: Scalars["Float"]["output"];
};

export type BatchClaimLineItemComputedValueVariancePopulationAggregates = {
  __typename?: "BatchClaimLineItemComputedValueVariancePopulationAggregates";
  /** Population variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimLineItemComputedValueVarianceSampleAggregates = {
  __typename?: "BatchClaimLineItemComputedValueVarianceSampleAggregates";
  /** Sample variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedAdjustedBilledAmount across the matching connection */
  determinedAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedSavings across the matching connection */
  determinedSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialAdjustedBilledAmount across the matching connection */
  potentialAdjustedBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialSavings across the matching connection */
  potentialSavings?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `BatchClaimLineItemComputedValue` values. */
export type BatchClaimLineItemComputedValuesConnection = {
  __typename?: "BatchClaimLineItemComputedValuesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineItemComputedValueAggregates>;
  /** A list of edges which contains the `BatchClaimLineItemComputedValue` and cursor to aid in pagination. */
  edges: Array<BatchClaimLineItemComputedValuesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineItemComputedValueAggregates>>;
  /** A list of `BatchClaimLineItemComputedValue` objects. */
  nodes: Array<Maybe<BatchClaimLineItemComputedValue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLineItemComputedValue` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLineItemComputedValue` values. */
export type BatchClaimLineItemComputedValuesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineItemComputedValuesGroupBy>;
  having?: InputMaybe<BatchClaimLineItemComputedValuesHavingInput>;
};

/** A `BatchClaimLineItemComputedValue` edge in the connection. */
export type BatchClaimLineItemComputedValuesEdge = {
  __typename?: "BatchClaimLineItemComputedValuesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLineItemComputedValue` at the end of the edge. */
  node?: Maybe<BatchClaimLineItemComputedValue>;
};

/** Grouping methods for `BatchClaimLineItemComputedValue` for usage during aggregation. */
export enum BatchClaimLineItemComputedValuesGroupBy {
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT",
  DETERMINED_ADJUSTED_BILLED_AMOUNT = "DETERMINED_ADJUSTED_BILLED_AMOUNT",
  DETERMINED_DISCREPANT_AMOUNT = "DETERMINED_DISCREPANT_AMOUNT",
  DETERMINED_SAVINGS = "DETERMINED_SAVINGS",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT = "POTENTIAL_ADJUSTED_BILLED_AMOUNT",
  POTENTIAL_DISCREPANT_AMOUNT = "POTENTIAL_DISCREPANT_AMOUNT",
  POTENTIAL_SAVINGS = "POTENTIAL_SAVINGS",
}

export type BatchClaimLineItemComputedValuesHavingAverageInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingDistinctCountInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `BatchClaimLineItemComputedValue` aggregates. */
export type BatchClaimLineItemComputedValuesHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineItemComputedValuesHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineItemComputedValuesHavingInput>>;
  average?: InputMaybe<BatchClaimLineItemComputedValuesHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineItemComputedValuesHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineItemComputedValuesHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineItemComputedValuesHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineItemComputedValuesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineItemComputedValuesHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineItemComputedValuesHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineItemComputedValuesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineItemComputedValuesHavingVarianceSampleInput>;
};

export type BatchClaimLineItemComputedValuesHavingMaxInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingMinInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingStddevPopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingStddevSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingSumInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingVariancePopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimLineItemComputedValuesHavingVarianceSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  determinedSavings?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialAdjustedBilledAmount?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  potentialSavings?: InputMaybe<HavingFloatFilter>;
};

/** Input for the nested mutation of `batchClaimLineItem` in the `BatchClaimLineItemComputedValueInput` mutation. */
export type BatchClaimLineItemComputedValuesIdFkeyInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineItemBatchClaimLineItemPkeyConnect>;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineItemNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyUsingBatchClaimLineItemPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemComputedValue` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemComputedValuesIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemComputedValue` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineItemComputedValueBatchClaimLineItemComputedValuesPkeyConnect>;
  /** The primary key(s) for `batchClaimLineItemComputedValue` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineItemComputedValueNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimLineItemComputedValue` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyUsingBatchClaimLineItemComputedValuesPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItemComputedValue` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyNodeIdUpdate>;
};

/** Methods to use when ordering `BatchClaimLineItemComputedValue`. */
export enum BatchClaimLineItemComputedValuesOrderBy {
  BATCH_CLAIM_LINE_ITEM_BY_ID__ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UNITS_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UNITS_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_ID__VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_ID__VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_ID__VERSION_FINGERPRINT_DESC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC = "DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC",
  DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC = "DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC",
  DETERMINED_DISCREPANT_AMOUNT_ASC = "DETERMINED_DISCREPANT_AMOUNT_ASC",
  DETERMINED_DISCREPANT_AMOUNT_DESC = "DETERMINED_DISCREPANT_AMOUNT_DESC",
  DETERMINED_SAVINGS_ASC = "DETERMINED_SAVINGS_ASC",
  DETERMINED_SAVINGS_DESC = "DETERMINED_SAVINGS_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC = "POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC",
  POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC = "POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC",
  POTENTIAL_DISCREPANT_AMOUNT_ASC = "POTENTIAL_DISCREPANT_AMOUNT_ASC",
  POTENTIAL_DISCREPANT_AMOUNT_DESC = "POTENTIAL_DISCREPANT_AMOUNT_DESC",
  POTENTIAL_SAVINGS_ASC = "POTENTIAL_SAVINGS_ASC",
  POTENTIAL_SAVINGS_DESC = "POTENTIAL_SAVINGS_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

/**
 * A condition to be used against `BatchClaimLineItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineItemCondition = {
  /** Checks for equality with the object’s `alaRowId` field. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimLineId` field. */
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfServiceEnd` field. */
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateOfServiceStart` field. */
  dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `ibLineId` field. */
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `ibLineVersionFingerprint` field. */
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `itemNumber` field. */
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `originalBilledAmount` field. */
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `originalVersionId` field. */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `previousVersionId` field. */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `procedureCodeModifier` field. */
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `revCode` field. */
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3InputSource` field. */
  s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tableNumber` field. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `unitCharge` field. */
  unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Checks for equality with the object’s `units` field. */
  units?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `versionFingerprint` field. */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimLineItemDistinctCountAggregateFilter = {
  alaRowId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  batchClaimLineId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  dateOfServiceEnd?: InputMaybe<BigIntFilter>;
  dateOfServiceStart?: InputMaybe<BigIntFilter>;
  description?: InputMaybe<BigIntFilter>;
  ibLineId?: InputMaybe<BigIntFilter>;
  ibLineVersionFingerprint?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  itemNumber?: InputMaybe<BigIntFilter>;
  lineNumber?: InputMaybe<BigIntFilter>;
  originalBilledAmount?: InputMaybe<BigIntFilter>;
  originalVersionId?: InputMaybe<BigIntFilter>;
  pageNumber?: InputMaybe<BigIntFilter>;
  previousVersionId?: InputMaybe<BigIntFilter>;
  procedureCode?: InputMaybe<BigIntFilter>;
  procedureCodeModifier?: InputMaybe<BigIntFilter>;
  revCode?: InputMaybe<BigIntFilter>;
  s3InputSource?: InputMaybe<BigIntFilter>;
  tableNumber?: InputMaybe<BigIntFilter>;
  unitCharge?: InputMaybe<BigIntFilter>;
  units?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  versionFingerprint?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineItemDistinctCountAggregates = {
  __typename?: "BatchClaimLineItemDistinctCountAggregates";
  /** Distinct count of alaRowId across the matching connection */
  alaRowId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimLineId across the matching connection */
  batchClaimLineId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateOfServiceEnd across the matching connection */
  dateOfServiceEnd?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateOfServiceStart across the matching connection */
  dateOfServiceStart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ibLineId across the matching connection */
  ibLineId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ibLineVersionFingerprint across the matching connection */
  ibLineVersionFingerprint?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of itemNumber across the matching connection */
  itemNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of originalVersionId across the matching connection */
  originalVersionId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of previousVersionId across the matching connection */
  previousVersionId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureCode across the matching connection */
  procedureCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of procedureCodeModifier across the matching connection */
  procedureCodeModifier?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of revCode across the matching connection */
  revCode?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3InputSource across the matching connection */
  s3InputSource?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of units across the matching connection */
  units?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of versionFingerprint across the matching connection */
  versionFingerprint?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLineItem` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemFilter = {
  /** Filter by the object’s `alaRowId` field. */
  alaRowId?: InputMaybe<UUIDFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineItemFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** A related `batchClaim` exists. */
  batchClaimExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLine` relation. */
  batchClaimLine?: InputMaybe<BatchClaimLineFilter>;
  /** A related `batchClaimLine` exists. */
  batchClaimLineExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimLineId` field. */
  batchClaimLineId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineItemAudits` relation. */
  batchClaimLineItemAudits?: InputMaybe<BatchClaimLineItemToManyBatchClaimLineItemAuditFilter>;
  /** Some related `batchClaimLineItemAudits` exist. */
  batchClaimLineItemAuditsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaimLineItemComputedValueById` relation. */
  batchClaimLineItemComputedValueById?: InputMaybe<BatchClaimLineItemComputedValueFilter>;
  /** A related `batchClaimLineItemComputedValueById` exists. */
  batchClaimLineItemComputedValueByIdExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `batchClaimLineItemNnIbLines` relation. */
  batchClaimLineItemNnIbLines?: InputMaybe<BatchClaimLineItemToManyBatchClaimLineItemNnIbLineFilter>;
  /** Some related `batchClaimLineItemNnIbLines` exist. */
  batchClaimLineItemNnIbLinesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfServiceEnd` field. */
  dateOfServiceEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateOfServiceStart` field. */
  dateOfServiceStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `ibLineId` field. */
  ibLineId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `ibLineVersionFingerprint` field. */
  ibLineVersionFingerprint?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `itemNumber` field. */
  itemNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lineNumber` field. */
  lineNumber?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineItemFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineItemFilter>>;
  /** Filter by the object’s `originalBilledAmount` field. */
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `originalVersionId` field. */
  originalVersionId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `pageNumber` field. */
  pageNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `previousVersionId` field. */
  previousVersionId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `procedureCode` field. */
  procedureCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `procedureCodeModifier` field. */
  procedureCodeModifier?: InputMaybe<StringFilter>;
  /** Filter by the object’s `revCode` field. */
  revCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3InputSource` field. */
  s3InputSource?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tableNumber` field. */
  tableNumber?: InputMaybe<IntFilter>;
  /** Filter by the object’s `unitCharge` field. */
  unitCharge?: InputMaybe<BigFloatFilter>;
  /** Filter by the object’s `units` field. */
  units?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `versionFingerprint` field. */
  versionFingerprint?: InputMaybe<UUIDFilter>;
};

/** Grouping methods for `BatchClaimLineItem` for usage during aggregation. */
export enum BatchClaimLineItemGroupBy {
  ALA_ROW_ID = "ALA_ROW_ID",
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  BATCH_CLAIM_LINE_ID = "BATCH_CLAIM_LINE_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DATE_OF_SERVICE_END = "DATE_OF_SERVICE_END",
  DATE_OF_SERVICE_END_TRUNCATED_TO_DAY = "DATE_OF_SERVICE_END_TRUNCATED_TO_DAY",
  DATE_OF_SERVICE_END_TRUNCATED_TO_HOUR = "DATE_OF_SERVICE_END_TRUNCATED_TO_HOUR",
  DATE_OF_SERVICE_START = "DATE_OF_SERVICE_START",
  DATE_OF_SERVICE_START_TRUNCATED_TO_DAY = "DATE_OF_SERVICE_START_TRUNCATED_TO_DAY",
  DATE_OF_SERVICE_START_TRUNCATED_TO_HOUR = "DATE_OF_SERVICE_START_TRUNCATED_TO_HOUR",
  DESCRIPTION = "DESCRIPTION",
  IB_LINE_ID = "IB_LINE_ID",
  IB_LINE_VERSION_FINGERPRINT = "IB_LINE_VERSION_FINGERPRINT",
  ITEM_NUMBER = "ITEM_NUMBER",
  LINE_NUMBER = "LINE_NUMBER",
  ORIGINAL_BILLED_AMOUNT = "ORIGINAL_BILLED_AMOUNT",
  ORIGINAL_VERSION_ID = "ORIGINAL_VERSION_ID",
  PAGE_NUMBER = "PAGE_NUMBER",
  PREVIOUS_VERSION_ID = "PREVIOUS_VERSION_ID",
  PROCEDURE_CODE = "PROCEDURE_CODE",
  PROCEDURE_CODE_MODIFIER = "PROCEDURE_CODE_MODIFIER",
  REV_CODE = "REV_CODE",
  S3_INPUT_SOURCE = "S3_INPUT_SOURCE",
  TABLE_NUMBER = "TABLE_NUMBER",
  UNITS = "UNITS",
  UNIT_CHARGE = "UNIT_CHARGE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
  VERSION_FINGERPRINT = "VERSION_FINGERPRINT",
}

export type BatchClaimLineItemHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimLineItem` aggregates. */
export type BatchClaimLineItemHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineItemHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineItemHavingInput>>;
  average?: InputMaybe<BatchClaimLineItemHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineItemHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineItemHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineItemHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineItemHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineItemHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineItemHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineItemHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineItemHavingVarianceSampleInput>;
};

export type BatchClaimLineItemHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceEnd?: InputMaybe<HavingDatetimeFilter>;
  dateOfServiceStart?: InputMaybe<HavingDatetimeFilter>;
  lineNumber?: InputMaybe<HavingIntFilter>;
  originalBilledAmount?: InputMaybe<HavingBigfloatFilter>;
  pageNumber?: InputMaybe<HavingIntFilter>;
  tableNumber?: InputMaybe<HavingIntFilter>;
  unitCharge?: InputMaybe<HavingBigfloatFilter>;
  units?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimLineItem` */
export type BatchClaimLineItemInput = {
  /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart: Scalars["Datetime"]["input"];
  description: Scalars["String"]["input"];
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the order of the IB line in the source CSV input. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /**
   * ### original_version_id
   *  Original version id, used in versioning
   */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * ### previous_version_id
   *  Previous version id, used in versioning
   */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode: Scalars["String"]["input"];
  /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
  s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  unitCharge: Scalars["BigFloat"]["input"];
  units: Scalars["Int"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /**
   * ### version_fingerprint
   *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
   */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimLineItemMaxAggregateFilter = {
  lineNumber?: InputMaybe<IntFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<IntFilter>;
  tableNumber?: InputMaybe<IntFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<IntFilter>;
};

export type BatchClaimLineItemMaxAggregates = {
  __typename?: "BatchClaimLineItemMaxAggregates";
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Maximum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

export type BatchClaimLineItemMinAggregateFilter = {
  lineNumber?: InputMaybe<IntFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<IntFilter>;
  tableNumber?: InputMaybe<IntFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<IntFilter>;
};

export type BatchClaimLineItemMinAggregates = {
  __typename?: "BatchClaimLineItemMinAggregates";
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Minimum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

export type BatchClaimLineItemNnIbLine = Node & {
  __typename?: "BatchClaimLineItemNnIbLine";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItemNnIbLine`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  batchClaimLineItemId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `NnIbLine` that is related to this `BatchClaimLineItemNnIbLine`. */
  nnIbLine?: Maybe<NnIbLine>;
  nnIbLineId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type BatchClaimLineItemNnIbLineAggregates = {
  __typename?: "BatchClaimLineItemNnIbLineAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimLineItemNnIbLineDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `BatchClaimLineItemNnIbLine` object types. */
export type BatchClaimLineItemNnIbLineAggregatesFilter = {
  /** Distinct count aggregate over matching `BatchClaimLineItemNnIbLine` objects. */
  distinctCount?: InputMaybe<BatchClaimLineItemNnIbLineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchClaimLineItemNnIbLine` object to be included within the aggregate. */
  filter?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `batchClaimLineItemNnIbLine` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineAuthorIdFkeyBatchClaimLineItemNnIbLineCreateInput =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `auditorUser` in the `BatchClaimLineItemNnIbLineInput` mutation. */
export type BatchClaimLineItemNnIbLineAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemNnIbLine` in the `AuditorUserInput` mutation. */
export type BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLinePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNnIbLineNodeIdConnect>>;
  /** A `BatchClaimLineItemNnIbLineInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineAuthorIdFkeyBatchClaimLineItemNnIbLineCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `batchClaimLineItem` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyBatchClaimLineItemCreateInput =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart: Scalars["Datetime"]["input"];
    description: Scalars["String"]["input"];
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode: Scalars["String"]["input"];
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge: Scalars["BigFloat"]["input"];
    units: Scalars["Int"]["input"];
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** The `batchClaimLineItemNnIbLine` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyBatchClaimLineItemNnIbLineCreateInput =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `batchClaimLineItem` in the `BatchClaimLineItemNnIbLineInput` mutation. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput = {
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimLineItemBatchClaimLineItemPkeyConnect>;
  /** The primary key(s) for `batchClaimLineItem` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimLineItemNodeIdConnect>;
  /** A `BatchClaimLineItemInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyBatchClaimLineItemCreateInput>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimLineItem` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemNnIbLine` in the `BatchClaimLineItemInput` mutation. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLinePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNnIbLineNodeIdConnect>>;
  /** A `BatchClaimLineItemNnIbLineInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyBatchClaimLineItemNnIbLineCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to connect. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyConnect =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    nnIbLineId: Scalars["UUID"]["input"];
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to connect. */
export type BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLinePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `BatchClaimLineItemNnIbLine` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimLineItemNnIbLineCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimLineItemId` field. */
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `nnIbLineId` field. */
  nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchClaimLineItemNnIbLineDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  batchClaimLineItemId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  nnIbLineId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineItemNnIbLineDistinctCountAggregates = {
  __typename?: "BatchClaimLineItemNnIbLineDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimLineItemId across the matching connection */
  batchClaimLineItemId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of nnIbLineId across the matching connection */
  nnIbLineId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimLineItemNnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemNnIbLineFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimLineItemNnIbLineFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineItem` relation. */
  batchClaimLineItem?: InputMaybe<BatchClaimLineItemFilter>;
  /** Filter by the object’s `batchClaimLineItemId` field. */
  batchClaimLineItemId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `nnIbLine` relation. */
  nnIbLine?: InputMaybe<NnIbLineFilter>;
  /** Filter by the object’s `nnIbLineId` field. */
  nnIbLineId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimLineItemNnIbLineFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BatchClaimLineItemNnIbLine` for usage during aggregation. */
export enum BatchClaimLineItemNnIbLineGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_CLAIM_LINE_ITEM_ID = "BATCH_CLAIM_LINE_ITEM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  NN_IB_LINE_ID = "NN_IB_LINE_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchClaimLineItemNnIbLineHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchClaimLineItemNnIbLine` aggregates. */
export type BatchClaimLineItemNnIbLineHavingInput = {
  AND?: InputMaybe<Array<BatchClaimLineItemNnIbLineHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimLineItemNnIbLineHavingInput>>;
  average?: InputMaybe<BatchClaimLineItemNnIbLineHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimLineItemNnIbLineHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimLineItemNnIbLineHavingMaxInput>;
  min?: InputMaybe<BatchClaimLineItemNnIbLineHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimLineItemNnIbLineHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimLineItemNnIbLineHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimLineItemNnIbLineHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimLineItemNnIbLineHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimLineItemNnIbLineHavingVarianceSampleInput>;
};

export type BatchClaimLineItemNnIbLineHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchClaimLineItemNnIbLineHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BatchClaimLineItemNnIbLine` */
export type BatchClaimLineItemNnIbLineInput = {
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `batchClaimLineItemNnIbLine` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineNnIbLineIdFkeyBatchClaimLineItemNnIbLineCreateInput =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** Input for the nested mutation of `nnIbLine` in the `BatchClaimLineItemNnIbLineInput` mutation. */
export type BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput = {
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectByFingerprintAndS3Key?: InputMaybe<NnIbLineNnIbLineFingerprintS3KeyKeyConnect>;
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectById?: InputMaybe<NnIbLineNnIbLinePkeyConnect>;
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<NnIbLineNodeIdConnect>;
  /** A `NnIbLineInput` object that will be created and connected to this object. */
  create?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyNnIbLineCreateInput>;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateByFingerprintAndS3Key?: InputMaybe<NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingNnIbLineFingerprintS3KeyKeyUpdate>;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateById?: InputMaybe<NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingNnIbLinePkeyUpdate>;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimLineItemNnIbLine` in the `NnIbLineInput` mutation. */
export type BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineBatchClaimLineItemNnIbLinePkeyConnect>
  >;
  /** The primary key(s) for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimLineItemNnIbLineNodeIdConnect>>;
  /** A `BatchClaimLineItemNnIbLineInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineNnIbLineIdFkeyBatchClaimLineItemNnIbLineCreateInput>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByBatchClaimLineItemIdAndNnIbLineId?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaimLineItemNnIbLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyNodeIdUpdate>
  >;
};

/** The `nnIbLine` to be created by this mutation. */
export type BatchClaimLineItemNnIbLineNnIbLineIdFkeyNnIbLineCreateInput = {
  auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fingerprint: Scalars["UUID"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  s3Key: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineItemNnIbLineNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLineItemNnIbLine` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    nnIbLineId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    nnIbLineId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `nnIbLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `nnIbLine` being updated. */
    patch: NnIbLinePatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingBatchClaimLineItemNnIbLBatchClaimLineItemIdNnIKeyUpdate =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    nnIbLineId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch;
  };

/** The fields on `batchClaimLineItemNnIbLine` to look up the row to update. */
export type BatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingBatchClaimLineItemNnIbLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLineItemNnIbLine`. Fields that are set will be updated. */
export type BatchClaimLineItemNnIbLinePatch = {
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `BatchClaimLineItemNnIbLine` values. */
export type BatchClaimLineItemNnIbLinesConnection = {
  __typename?: "BatchClaimLineItemNnIbLinesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineItemNnIbLineAggregates>;
  /** A list of edges which contains the `BatchClaimLineItemNnIbLine` and cursor to aid in pagination. */
  edges: Array<BatchClaimLineItemNnIbLinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineItemNnIbLineAggregates>>;
  /** A list of `BatchClaimLineItemNnIbLine` objects. */
  nodes: Array<Maybe<BatchClaimLineItemNnIbLine>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLineItemNnIbLine` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLineItemNnIbLine` values. */
export type BatchClaimLineItemNnIbLinesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineItemNnIbLineGroupBy>;
  having?: InputMaybe<BatchClaimLineItemNnIbLineHavingInput>;
};

/** A `BatchClaimLineItemNnIbLine` edge in the connection. */
export type BatchClaimLineItemNnIbLinesEdge = {
  __typename?: "BatchClaimLineItemNnIbLinesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLineItemNnIbLine` at the end of the edge. */
  node?: Maybe<BatchClaimLineItemNnIbLine>;
};

/** Methods to use when ordering `BatchClaimLineItemNnIbLine`. */
export enum BatchClaimLineItemNnIbLinesOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEM_BY_BATCH_CLAIM_LINE_ITEM_ID__VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  NN_IB_LINE_BY_NN_IB_LINE_ID__AUTHOR_ID_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__AUTHOR_ID_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__AUTHOR_ID_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__AUTHOR_ID_DESC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__CREATED_AT_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__CREATED_AT_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__CREATED_AT_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__CREATED_AT_DESC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__FINGERPRINT_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__FINGERPRINT_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__FINGERPRINT_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__FINGERPRINT_DESC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__ID_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__ID_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__ID_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__ID_DESC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__S3_KEY_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__S3_KEY_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__S3_KEY_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__S3_KEY_DESC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__UPDATED_AT_ASC = "NN_IB_LINE_BY_NN_IB_LINE_ID__UPDATED_AT_ASC",
  NN_IB_LINE_BY_NN_IB_LINE_ID__UPDATED_AT_DESC = "NN_IB_LINE_BY_NN_IB_LINE_ID__UPDATED_AT_DESC",
  NN_IB_LINE_ID_ASC = "NN_IB_LINE_ID_ASC",
  NN_IB_LINE_ID_DESC = "NN_IB_LINE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineItemNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemAudit` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
    patch: BatchClaimLineItemAuditPatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemComputedValue` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemComputedValue` being updated. */
    patch: BatchClaimLineItemComputedValuePatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: BatchClaimLinePatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemNnIbLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: BatchClaimLineItemNnIbLinePatch;
  };

/** The fields on `batchClaimLineItem` to look up the row to update. */
export type BatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyUsingBatchClaimLineItemPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: updateBatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLineItem`. Fields that are set will be updated. */
export type BatchClaimLineItemPatch = {
  /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the order of the IB line in the source CSV input. */
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /**
   * ### original_version_id
   *  Original version id, used in versioning
   */
  originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
  pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
  /**
   * ### previous_version_id
   *  Previous version id, used in versioning
   */
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
  s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
  /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
  tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
  unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
  units?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /**
   * ### version_fingerprint
   *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
   */
  versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimLineItemStddevPopulationAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigFloatFilter>;
  tableNumber?: InputMaybe<BigFloatFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineItemStddevPopulationAggregates = {
  __typename?: "BatchClaimLineItemStddevPopulationAggregates";
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineItemStddevSampleAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigFloatFilter>;
  tableNumber?: InputMaybe<BigFloatFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineItemStddevSampleAggregates = {
  __typename?: "BatchClaimLineItemStddevSampleAggregates";
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineItemSumAggregateFilter = {
  lineNumber?: InputMaybe<BigIntFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigIntFilter>;
  tableNumber?: InputMaybe<BigIntFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineItemSumAggregates = {
  __typename?: "BatchClaimLineItemSumAggregates";
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars["BigInt"]["output"];
  /** Sum of originalBilledAmount across the matching connection */
  originalBilledAmount: Scalars["BigFloat"]["output"];
  /** Sum of pageNumber across the matching connection */
  pageNumber: Scalars["BigInt"]["output"];
  /** Sum of tableNumber across the matching connection */
  tableNumber: Scalars["BigInt"]["output"];
  /** Sum of unitCharge across the matching connection */
  unitCharge: Scalars["BigFloat"]["output"];
  /** Sum of units across the matching connection */
  units: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `BatchClaimLineItemAudit` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemToManyBatchClaimLineItemAuditFilter = {
  /** Aggregates across related `BatchClaimLineItemAudit` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemAuditAggregatesFilter>;
  /** Every related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** No related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemAuditFilter>;
  /** Some related `BatchClaimLineItemAudit` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemAuditFilter>;
};

/** A filter to be used against many `BatchClaimLineItemNnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineItemToManyBatchClaimLineItemNnIbLineFilter = {
  /** Aggregates across related `BatchClaimLineItemNnIbLine` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemNnIbLineAggregatesFilter>;
  /** Every related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** No related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** Some related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
};

export type BatchClaimLineItemVariancePopulationAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigFloatFilter>;
  tableNumber?: InputMaybe<BigFloatFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineItemVariancePopulationAggregates = {
  __typename?: "BatchClaimLineItemVariancePopulationAggregates";
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineItemVarianceSampleAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  originalBilledAmount?: InputMaybe<BigFloatFilter>;
  pageNumber?: InputMaybe<BigFloatFilter>;
  tableNumber?: InputMaybe<BigFloatFilter>;
  unitCharge?: InputMaybe<BigFloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineItemVarianceSampleAggregates = {
  __typename?: "BatchClaimLineItemVarianceSampleAggregates";
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of originalBilledAmount across the matching connection */
  originalBilledAmount?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of pageNumber across the matching connection */
  pageNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of tableNumber across the matching connection */
  tableNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `BatchClaimLineItem` values. */
export type BatchClaimLineItemsConnection = {
  __typename?: "BatchClaimLineItemsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineItemAggregates>;
  /** A list of edges which contains the `BatchClaimLineItem` and cursor to aid in pagination. */
  edges: Array<BatchClaimLineItemsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineItemAggregates>>;
  /** A list of `BatchClaimLineItem` objects. */
  nodes: Array<Maybe<BatchClaimLineItem>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLineItem` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLineItem` values. */
export type BatchClaimLineItemsConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineItemGroupBy>;
  having?: InputMaybe<BatchClaimLineItemHavingInput>;
};

/** A `BatchClaimLineItem` edge in the connection. */
export type BatchClaimLineItemsEdge = {
  __typename?: "BatchClaimLineItemsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLineItem` at the end of the edge. */
  node?: Maybe<BatchClaimLineItem>;
};

/** Methods to use when ordering `BatchClaimLineItem`. */
export enum BatchClaimLineItemsOrderBy {
  ALA_ROW_ID_ASC = "ALA_ROW_ID_ASC",
  ALA_ROW_ID_DESC = "ALA_ROW_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__CREATED_AT_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__ID_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__SERVICE_DATE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNITS_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_ASC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_DESC = "BATCH_CLAIM_LINE_BY_BATCH_CLAIM_LINE_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_AUDITS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__ID_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__ID_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__ID_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__ID_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_ASC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_ASC",
  BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_DESC = "BATCH_CLAIM_LINE_ITEM_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_BATCH_CLAIM_LINE_ITEM_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DATE_OF_SERVICE_END_ASC = "DATE_OF_SERVICE_END_ASC",
  DATE_OF_SERVICE_END_DESC = "DATE_OF_SERVICE_END_DESC",
  DATE_OF_SERVICE_START_ASC = "DATE_OF_SERVICE_START_ASC",
  DATE_OF_SERVICE_START_DESC = "DATE_OF_SERVICE_START_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  IB_LINE_ID_ASC = "IB_LINE_ID_ASC",
  IB_LINE_ID_DESC = "IB_LINE_ID_DESC",
  IB_LINE_VERSION_FINGERPRINT_ASC = "IB_LINE_VERSION_FINGERPRINT_ASC",
  IB_LINE_VERSION_FINGERPRINT_DESC = "IB_LINE_VERSION_FINGERPRINT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  ITEM_NUMBER_ASC = "ITEM_NUMBER_ASC",
  ITEM_NUMBER_DESC = "ITEM_NUMBER_DESC",
  LINE_NUMBER_ASC = "LINE_NUMBER_ASC",
  LINE_NUMBER_DESC = "LINE_NUMBER_DESC",
  NATURAL = "NATURAL",
  ORIGINAL_BILLED_AMOUNT_ASC = "ORIGINAL_BILLED_AMOUNT_ASC",
  ORIGINAL_BILLED_AMOUNT_DESC = "ORIGINAL_BILLED_AMOUNT_DESC",
  ORIGINAL_VERSION_ID_ASC = "ORIGINAL_VERSION_ID_ASC",
  ORIGINAL_VERSION_ID_DESC = "ORIGINAL_VERSION_ID_DESC",
  PAGE_NUMBER_ASC = "PAGE_NUMBER_ASC",
  PAGE_NUMBER_DESC = "PAGE_NUMBER_DESC",
  PREVIOUS_VERSION_ID_ASC = "PREVIOUS_VERSION_ID_ASC",
  PREVIOUS_VERSION_ID_DESC = "PREVIOUS_VERSION_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROCEDURE_CODE_ASC = "PROCEDURE_CODE_ASC",
  PROCEDURE_CODE_DESC = "PROCEDURE_CODE_DESC",
  PROCEDURE_CODE_MODIFIER_ASC = "PROCEDURE_CODE_MODIFIER_ASC",
  PROCEDURE_CODE_MODIFIER_DESC = "PROCEDURE_CODE_MODIFIER_DESC",
  REV_CODE_ASC = "REV_CODE_ASC",
  REV_CODE_DESC = "REV_CODE_DESC",
  S3_INPUT_SOURCE_ASC = "S3_INPUT_SOURCE_ASC",
  S3_INPUT_SOURCE_DESC = "S3_INPUT_SOURCE_DESC",
  TABLE_NUMBER_ASC = "TABLE_NUMBER_ASC",
  TABLE_NUMBER_DESC = "TABLE_NUMBER_DESC",
  UNITS_ASC = "UNITS_ASC",
  UNITS_DESC = "UNITS_DESC",
  UNIT_CHARGE_ASC = "UNIT_CHARGE_ASC",
  UNIT_CHARGE_DESC = "UNIT_CHARGE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  VERSION_FINGERPRINT_ASC = "VERSION_FINGERPRINT_ASC",
  VERSION_FINGERPRINT_DESC = "VERSION_FINGERPRINT_DESC",
}

export type BatchClaimLineMaxAggregateFilter = {
  lineNumber?: InputMaybe<IntFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<IntFilter>;
};

export type BatchClaimLineMaxAggregates = {
  __typename?: "BatchClaimLineMaxAggregates";
  /** Maximum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

export type BatchClaimLineMinAggregateFilter = {
  lineNumber?: InputMaybe<IntFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<IntFilter>;
};

export type BatchClaimLineMinAggregates = {
  __typename?: "BatchClaimLineMinAggregates";
  /** Minimum of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of units across the matching connection */
  units?: Maybe<Scalars["Int"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimLineNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimLine` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `batchClaimLine` to look up the row to update. */
export type BatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyUsingBatchClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: updateBatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineComputedValue` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineComputedValue` being updated. */
    patch: BatchClaimLineComputedValuePatch;
  };

/** The fields on `batchClaimLine` to look up the row to update. */
export type BatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyUsingBatchClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: updateBatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `batchClaimLine` to look up the row to update. */
export type BatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: updateBatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `batchClaimLine` to look up the row to update. */
export type BatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyUsingBatchClaimLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: updateBatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimLine`. Fields that are set will be updated. */
export type BatchClaimLinePatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
  batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
};

export type BatchClaimLineStddevPopulationAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineStddevPopulationAggregates = {
  __typename?: "BatchClaimLineStddevPopulationAggregates";
  /** Population standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineStddevSampleAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineStddevSampleAggregates = {
  __typename?: "BatchClaimLineStddevSampleAggregates";
  /** Sample standard deviation of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineSumAggregateFilter = {
  lineNumber?: InputMaybe<BigIntFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigIntFilter>;
};

export type BatchClaimLineSumAggregates = {
  __typename?: "BatchClaimLineSumAggregates";
  /** Sum of lineNumber across the matching connection */
  lineNumber: Scalars["BigInt"]["output"];
  /** Sum of nonCovCharge across the matching connection */
  nonCovCharge: Scalars["Float"]["output"];
  /** Sum of nonCoveredAmount across the matching connection */
  nonCoveredAmount: Scalars["Float"]["output"];
  /** Sum of paymentRate across the matching connection */
  paymentRate: Scalars["Float"]["output"];
  /** Sum of unitAllowableCharge across the matching connection */
  unitAllowableCharge: Scalars["Float"]["output"];
  /** Sum of unitCharge across the matching connection */
  unitCharge: Scalars["Float"]["output"];
  /** Sum of units across the matching connection */
  units: Scalars["BigInt"]["output"];
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A filter to be used against many `BatchClaimLineItem` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimLineToManyBatchClaimLineItemFilter = {
  /** Aggregates across related `BatchClaimLineItem` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemAggregatesFilter>;
  /** Every related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemFilter>;
  /** No related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemFilter>;
  /** Some related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemFilter>;
};

export type BatchClaimLineVariancePopulationAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineVariancePopulationAggregates = {
  __typename?: "BatchClaimLineVariancePopulationAggregates";
  /** Population variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type BatchClaimLineVarianceSampleAggregateFilter = {
  lineNumber?: InputMaybe<BigFloatFilter>;
  nonCovCharge?: InputMaybe<FloatFilter>;
  nonCoveredAmount?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
  unitAllowableCharge?: InputMaybe<FloatFilter>;
  unitCharge?: InputMaybe<FloatFilter>;
  units?: InputMaybe<BigFloatFilter>;
};

export type BatchClaimLineVarianceSampleAggregates = {
  __typename?: "BatchClaimLineVarianceSampleAggregates";
  /** Sample variance of lineNumber across the matching connection */
  lineNumber?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of nonCovCharge across the matching connection */
  nonCovCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of nonCoveredAmount across the matching connection */
  nonCoveredAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of unitAllowableCharge across the matching connection */
  unitAllowableCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of unitCharge across the matching connection */
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of units across the matching connection */
  units?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `BatchClaimLine` values. */
export type BatchClaimLinesConnection = {
  __typename?: "BatchClaimLinesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimLineAggregates>;
  /** A list of edges which contains the `BatchClaimLine` and cursor to aid in pagination. */
  edges: Array<BatchClaimLinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimLineAggregates>>;
  /** A list of `BatchClaimLine` objects. */
  nodes: Array<Maybe<BatchClaimLine>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimLine` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimLine` values. */
export type BatchClaimLinesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimLineGroupBy>;
  having?: InputMaybe<BatchClaimLineHavingInput>;
};

/** A `BatchClaimLine` edge in the connection. */
export type BatchClaimLinesEdge = {
  __typename?: "BatchClaimLinesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimLine` at the end of the edge. */
  node?: Maybe<BatchClaimLine>;
};

/** Methods to use when ordering `BatchClaimLine`. */
export enum BatchClaimLinesOrderBy {
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDINGS_AVERAGE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_BATCH_CLAIM_LINE_ID__COUNT_ASC = "AUDIT_FINDINGS_BY_BATCH_CLAIM_LINE_ID__COUNT_ASC",
  AUDIT_FINDINGS_BY_BATCH_CLAIM_LINE_ID__COUNT_DESC = "AUDIT_FINDINGS_BY_BATCH_CLAIM_LINE_ID__COUNT_DESC",
  AUDIT_FINDINGS_COUNT_ASC = "AUDIT_FINDINGS_COUNT_ASC",
  AUDIT_FINDINGS_COUNT_DESC = "AUDIT_FINDINGS_COUNT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_ASC = "AUDIT_FINDINGS_MAX_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_DESC = "AUDIT_FINDINGS_MAX_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  AUDIT_FINDINGS_MAX_ID_ASC = "AUDIT_FINDINGS_MAX_ID_ASC",
  AUDIT_FINDINGS_MAX_ID_DESC = "AUDIT_FINDINGS_MAX_ID_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_ASC = "AUDIT_FINDINGS_MIN_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_DESC = "AUDIT_FINDINGS_MIN_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  AUDIT_FINDINGS_MIN_ID_ASC = "AUDIT_FINDINGS_MIN_ID_ASC",
  AUDIT_FINDINGS_MIN_ID_DESC = "AUDIT_FINDINGS_MIN_ID_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_ASC = "AUDIT_FINDINGS_SUM_CONFIDENCE_ASC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_DESC = "AUDIT_FINDINGS_SUM_CONFIDENCE_DESC",
  AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  AUDIT_FINDINGS_SUM_ID_ASC = "AUDIT_FINDINGS_SUM_ID_ASC",
  AUDIT_FINDINGS_SUM_ID_DESC = "AUDIT_FINDINGS_SUM_ID_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_COUNT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_NEEDS_REVIEW_IS_WRONG_DESC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_CLAIM_LINE_ACCURACIES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_ADJUSTED_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__DETERMINED_SAVINGS_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__ID_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__ID_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__ID_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__ID_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_ADJUSTED_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_ASC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_ASC",
  BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_DESC = "BATCH_CLAIM_LINE_COMPUTED_VALUE_BY_ID__POTENTIAL_SAVINGS_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_LINE_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_LINE_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_LINE_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_LINE_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_COUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_COUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_COUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_COUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DIAG_1_ASC = "DIAG_1_ASC",
  DIAG_1_DESC = "DIAG_1_DESC",
  DIAG_1_DESCRIPTION_ASC = "DIAG_1_DESCRIPTION_ASC",
  DIAG_1_DESCRIPTION_DESC = "DIAG_1_DESCRIPTION_DESC",
  DIAG_2_ASC = "DIAG_2_ASC",
  DIAG_2_DESC = "DIAG_2_DESC",
  DIAG_2_DESCRIPTION_ASC = "DIAG_2_DESCRIPTION_ASC",
  DIAG_2_DESCRIPTION_DESC = "DIAG_2_DESCRIPTION_DESC",
  DIAG_3_ASC = "DIAG_3_ASC",
  DIAG_3_DESC = "DIAG_3_DESC",
  DIAG_3_DESCRIPTION_ASC = "DIAG_3_DESCRIPTION_ASC",
  DIAG_3_DESCRIPTION_DESC = "DIAG_3_DESCRIPTION_DESC",
  DIAG_4_ASC = "DIAG_4_ASC",
  DIAG_4_DESC = "DIAG_4_DESC",
  DIAG_4_DESCRIPTION_ASC = "DIAG_4_DESCRIPTION_ASC",
  DIAG_4_DESCRIPTION_DESC = "DIAG_4_DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LINE_NUMBER_ASC = "LINE_NUMBER_ASC",
  LINE_NUMBER_DESC = "LINE_NUMBER_DESC",
  NATURAL = "NATURAL",
  NON_COVERED_AMOUNT_ASC = "NON_COVERED_AMOUNT_ASC",
  NON_COVERED_AMOUNT_DESC = "NON_COVERED_AMOUNT_DESC",
  NON_COV_CHARGE_ASC = "NON_COV_CHARGE_ASC",
  NON_COV_CHARGE_DESC = "NON_COV_CHARGE_DESC",
  PAYMENT_RATE_ASC = "PAYMENT_RATE_ASC",
  PAYMENT_RATE_DESC = "PAYMENT_RATE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROCEDURE_CODE_ASC = "PROCEDURE_CODE_ASC",
  PROCEDURE_CODE_DESC = "PROCEDURE_CODE_DESC",
  PROCEDURE_DESCRIPTION_ASC = "PROCEDURE_DESCRIPTION_ASC",
  PROCEDURE_DESCRIPTION_DESC = "PROCEDURE_DESCRIPTION_DESC",
  PROCEDURE_MOD_ASC = "PROCEDURE_MOD_ASC",
  PROCEDURE_MOD_DESC = "PROCEDURE_MOD_DESC",
  REV_CODE_ASC = "REV_CODE_ASC",
  REV_CODE_DESC = "REV_CODE_DESC",
  REV_CODE_SRC_ASC = "REV_CODE_SRC_ASC",
  REV_CODE_SRC_DESC = "REV_CODE_SRC_DESC",
  REV_DESCRIPTION_ASC = "REV_DESCRIPTION_ASC",
  REV_DESCRIPTION_DESC = "REV_DESCRIPTION_DESC",
  SERVICE_DATE_ASC = "SERVICE_DATE_ASC",
  SERVICE_DATE_DESC = "SERVICE_DATE_DESC",
  UNITS_ASC = "UNITS_ASC",
  UNITS_DESC = "UNITS_DESC",
  UNIT_ALLOWABLE_CHARGE_ASC = "UNIT_ALLOWABLE_CHARGE_ASC",
  UNIT_ALLOWABLE_CHARGE_DESC = "UNIT_ALLOWABLE_CHARGE_DESC",
  UNIT_CHARGE_ASC = "UNIT_CHARGE_ASC",
  UNIT_CHARGE_DESC = "UNIT_CHARGE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type BatchClaimMaxAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimMaxAggregates = {
  __typename?: "BatchClaimMaxAggregates";
  /** Maximum of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimMaxAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimMinAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimMinAggregates = {
  __typename?: "BatchClaimMinAggregates";
  /** Minimum of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimMinAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimAssignee` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
    patch: BatchClaimAssigneePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimDueDate` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimDueDate` being updated. */
    patch: BatchClaimDueDatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchFile` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchFile` being updated. */
  patch: BatchFilePatch;
};

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insurer` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `insurer` being updated. */
  patch: InsurerPatch;
};

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `provider` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `provider` being updated. */
  patch: ProviderPatch;
};

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimProviderIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimForBatchClaimProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLine` being updated. */
    patch: BatchClaimLinePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItem` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
    patch: BatchClaimLineItemPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimState` being updated. */
    patch: BatchClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `bucketClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: BucketClaimPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `clientSignOffClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `clientSignOffClaimState` being updated. */
    patch: ClientSignOffClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `disputedFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: DisputedFindingPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentRequestCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: DocumentRequestCreatedPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: DocumentUploadCreatedPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `invoiceClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `invoiceClaimState` being updated. */
    patch: InvoiceClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `negotiationClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `negotiationClaimState` being updated. */
    patch: NegotiationClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `onHoldClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `onHoldClaimState` being updated. */
    patch: OnHoldClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `providerSignOffClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `providerSignOffClaimState` being updated. */
    patch: ProviderSignOffClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `reportedClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `reportedClaim` being updated. */
  patch: ReportedClaimPatch;
};

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnReportedClaimForReportedClaimClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnReportedClaimForReportedClaimClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `s3AuditDocumentation` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
    patch: S3AuditDocumentationPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `workflowClaimState` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `workflowClaimState` being updated. */
    patch: WorkflowClaimStatePatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate =
  {
    icn: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkPatch;
  };

/** The fields on `batchClaim` to look up the row to update. */
export type BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: updateBatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `BatchClaim`. Fields that are set will be updated. */
export type BatchClaimPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** Input for the nested mutation of `provider` in the `BatchClaimInput` mutation. */
export type BatchClaimProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnBatchClaimForBatchClaimProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaim` in the `ProviderInput` mutation. */
export type BatchClaimProviderIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<Array<BatchClaimBatchClaimIcnKeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchClaimBatchClaimPkeyConnect>>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchClaimNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyUsingBatchClaimIcnKeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchClaimOnBatchClaimForBatchClaimProviderIdFkeyUsingBatchClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnBatchClaimForBatchClaimProviderIdFkeyNodeIdUpdate>
  >;
};

/**
 * ### Overview
 * Computed fields related to all state associated with a claim. States include workflow state, document request state, is provider active state, report state disputes state, provider & client sign off state, invoice state, on hold state, negotiation state, det & pot adj allowed amount, det & pot client savings, det & pot discrepant amount, realized savings.
 */
export type BatchClaimState = Node & {
  __typename?: "BatchClaimState";
  /** Reads a single `BatchClaim` that is related to this `BatchClaimState`. */
  batchClaimById?: Maybe<BatchClaim>;
  clientSignOffClaimState?: Maybe<ClientSignOffClaimStateEnum>;
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  disputedFindingClaimState?: Maybe<DisputedFindingClaimStateEnum>;
  documentationClaimState: DocumentationClaimStateEnum;
  dueDate?: Maybe<Scalars["Datetime"]["output"]>;
  id: Scalars["UUID"]["output"];
  invoiceClaimState?: Maybe<InvoiceClaimStateEnum>;
  isProviderActive?: Maybe<Scalars["Boolean"]["output"]>;
  isRealized?: Maybe<Scalars["Boolean"]["output"]>;
  negotiationClaimState?: Maybe<NegotiationClaimStateEnum>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  onHoldClaimState?: Maybe<OnHoldClaimStateEnum>;
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  providerSignOffClaimState?: Maybe<ProviderSignOffClaimStateEnum>;
  reported?: Maybe<Scalars["Boolean"]["output"]>;
  workflowClaimState?: Maybe<WorkflowClaimStateEnum>;
};

export type BatchClaimStateAggregates = {
  __typename?: "BatchClaimStateAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchClaimStateAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchClaimStateMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchClaimStateMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchClaimStateStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchClaimStateStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchClaimStateSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchClaimStateVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchClaimStateVarianceSampleAggregates>;
};

export type BatchClaimStateAverageAggregates = {
  __typename?: "BatchClaimStateAverageAggregates";
  /** Mean average of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Mean average of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BatchClaimStateInput` mutation. */
export type BatchClaimStateBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchClaimState` in the `BatchClaimInput` mutation. */
export type BatchClaimStateBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `batchClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimStateCacheBatchClaimStatePkeyConnect>;
  /** The primary key(s) for `batchClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimStateNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingCacheBatchClaimStatePkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyNodeIdUpdate>;
};

/** The fields on `batchClaimState` to look up the row to connect. */
export type BatchClaimStateCacheBatchClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `BatchClaimState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type BatchClaimStateCondition = {
  /** Checks for equality with the object’s `clientSignOffClaimState` field. */
  clientSignOffClaimState?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Checks for equality with the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedClientSavings` field. */
  determinedClientSavings?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `disputedFindingClaimState` field. */
  disputedFindingClaimState?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Checks for equality with the object’s `documentationClaimState` field. */
  documentationClaimState?: InputMaybe<DocumentationClaimStateEnum>;
  /** Checks for equality with the object’s `dueDate` field. */
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `invoiceClaimState` field. */
  invoiceClaimState?: InputMaybe<InvoiceClaimStateEnum>;
  /** Checks for equality with the object’s `isProviderActive` field. */
  isProviderActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `isRealized` field. */
  isRealized?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `negotiationClaimState` field. */
  negotiationClaimState?: InputMaybe<NegotiationClaimStateEnum>;
  /** Checks for equality with the object’s `onHoldClaimState` field. */
  onHoldClaimState?: InputMaybe<OnHoldClaimStateEnum>;
  /** Checks for equality with the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialClientSavings` field. */
  potentialClientSavings?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<Scalars["Float"]["input"]>;
  /** Checks for equality with the object’s `providerSignOffClaimState` field. */
  providerSignOffClaimState?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Checks for equality with the object’s `reported` field. */
  reported?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `workflowClaimState` field. */
  workflowClaimState?: InputMaybe<WorkflowClaimStateEnum>;
};

export type BatchClaimStateDistinctCountAggregates = {
  __typename?: "BatchClaimStateDistinctCountAggregates";
  /** Distinct count of clientSignOffClaimState across the matching connection */
  clientSignOffClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of disputedFindingClaimState across the matching connection */
  disputedFindingClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of documentationClaimState across the matching connection */
  documentationClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dueDate across the matching connection */
  dueDate?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of invoiceClaimState across the matching connection */
  invoiceClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isProviderActive across the matching connection */
  isProviderActive?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isRealized across the matching connection */
  isRealized?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of negotiationClaimState across the matching connection */
  negotiationClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of onHoldClaimState across the matching connection */
  onHoldClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerSignOffClaimState across the matching connection */
  providerSignOffClaimState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of reported across the matching connection */
  reported?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of workflowClaimState across the matching connection */
  workflowClaimState?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BatchClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchClaimStateFilter>>;
  /** Filter by the object’s `batchClaimById` relation. */
  batchClaimById?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `clientSignOffClaimState` field. */
  clientSignOffClaimState?: InputMaybe<ClientSignOffClaimStateEnumFilter>;
  /** Filter by the object’s `determinedAdjustedAllowedAmount` field. */
  determinedAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedClientSavings` field. */
  determinedClientSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `determinedDiscrepantAmount` field. */
  determinedDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `disputedFindingClaimState` field. */
  disputedFindingClaimState?: InputMaybe<DisputedFindingClaimStateEnumFilter>;
  /** Filter by the object’s `documentationClaimState` field. */
  documentationClaimState?: InputMaybe<DocumentationClaimStateEnumFilter>;
  /** Filter by the object’s `dueDate` field. */
  dueDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `invoiceClaimState` field. */
  invoiceClaimState?: InputMaybe<InvoiceClaimStateEnumFilter>;
  /** Filter by the object’s `isProviderActive` field. */
  isProviderActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isRealized` field. */
  isRealized?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `negotiationClaimState` field. */
  negotiationClaimState?: InputMaybe<NegotiationClaimStateEnumFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchClaimStateFilter>;
  /** Filter by the object’s `onHoldClaimState` field. */
  onHoldClaimState?: InputMaybe<OnHoldClaimStateEnumFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchClaimStateFilter>>;
  /** Filter by the object’s `potentialAdjustedAllowedAmount` field. */
  potentialAdjustedAllowedAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialClientSavings` field. */
  potentialClientSavings?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `potentialDiscrepantAmount` field. */
  potentialDiscrepantAmount?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `providerSignOffClaimState` field. */
  providerSignOffClaimState?: InputMaybe<ProviderSignOffClaimStateEnumFilter>;
  /** Filter by the object’s `reported` field. */
  reported?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `workflowClaimState` field. */
  workflowClaimState?: InputMaybe<WorkflowClaimStateEnumFilter>;
};

/** Grouping methods for `BatchClaimState` for usage during aggregation. */
export enum BatchClaimStateGroupBy {
  CLIENT_SIGN_OFF_CLAIM_STATE = "CLIENT_SIGN_OFF_CLAIM_STATE",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT",
  DETERMINED_CLIENT_SAVINGS = "DETERMINED_CLIENT_SAVINGS",
  DETERMINED_DISCREPANT_AMOUNT = "DETERMINED_DISCREPANT_AMOUNT",
  DISPUTED_FINDING_CLAIM_STATE = "DISPUTED_FINDING_CLAIM_STATE",
  DOCUMENTATION_CLAIM_STATE = "DOCUMENTATION_CLAIM_STATE",
  DUE_DATE = "DUE_DATE",
  DUE_DATE_TRUNCATED_TO_DAY = "DUE_DATE_TRUNCATED_TO_DAY",
  DUE_DATE_TRUNCATED_TO_HOUR = "DUE_DATE_TRUNCATED_TO_HOUR",
  INVOICE_CLAIM_STATE = "INVOICE_CLAIM_STATE",
  IS_PROVIDER_ACTIVE = "IS_PROVIDER_ACTIVE",
  IS_REALIZED = "IS_REALIZED",
  NEGOTIATION_CLAIM_STATE = "NEGOTIATION_CLAIM_STATE",
  ON_HOLD_CLAIM_STATE = "ON_HOLD_CLAIM_STATE",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT",
  POTENTIAL_CLIENT_SAVINGS = "POTENTIAL_CLIENT_SAVINGS",
  POTENTIAL_DISCREPANT_AMOUNT = "POTENTIAL_DISCREPANT_AMOUNT",
  PROVIDER_SIGN_OFF_CLAIM_STATE = "PROVIDER_SIGN_OFF_CLAIM_STATE",
  REPORTED = "REPORTED",
  WORKFLOW_CLAIM_STATE = "WORKFLOW_CLAIM_STATE",
}

export type BatchClaimStateHavingAverageInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingDistinctCountInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

/** Conditions for `BatchClaimState` aggregates. */
export type BatchClaimStateHavingInput = {
  AND?: InputMaybe<Array<BatchClaimStateHavingInput>>;
  OR?: InputMaybe<Array<BatchClaimStateHavingInput>>;
  average?: InputMaybe<BatchClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<BatchClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<BatchClaimStateHavingMaxInput>;
  min?: InputMaybe<BatchClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<BatchClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<BatchClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchClaimStateHavingVarianceSampleInput>;
};

export type BatchClaimStateHavingMaxInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingMinInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingStddevPopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingStddevSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingSumInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingVariancePopulationInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateHavingVarianceSampleInput = {
  determinedAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  determinedClientSavings?: InputMaybe<HavingFloatFilter>;
  determinedDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
  dueDate?: InputMaybe<HavingDatetimeFilter>;
  potentialAdjustedAllowedAmount?: InputMaybe<HavingFloatFilter>;
  potentialClientSavings?: InputMaybe<HavingFloatFilter>;
  potentialDiscrepantAmount?: InputMaybe<HavingFloatFilter>;
};

export type BatchClaimStateMaxAggregates = {
  __typename?: "BatchClaimStateMaxAggregates";
  /** Maximum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Maximum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStateMinAggregates = {
  __typename?: "BatchClaimStateMinAggregates";
  /** Minimum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Minimum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `batchClaimState` to look up the row to update. */
export type BatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyUsingCacheBatchClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimState` being updated. */
    patch: updateBatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyPatch;
  };

/** Represents an update to a `BatchClaimState`. Fields that are set will be updated. */
export type BatchClaimStatePatch = {
  batchClaimToId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type BatchClaimStateStddevPopulationAggregates = {
  __typename?: "BatchClaimStateStddevPopulationAggregates";
  /** Population standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStateStddevSampleAggregates = {
  __typename?: "BatchClaimStateStddevSampleAggregates";
  /** Sample standard deviation of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStateSumAggregates = {
  __typename?: "BatchClaimStateSumAggregates";
  /** Sum of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of determinedClientSavings across the matching connection */
  determinedClientSavings: Scalars["Float"]["output"];
  /** Sum of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount: Scalars["Float"]["output"];
  /** Sum of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount: Scalars["Float"]["output"];
  /** Sum of potentialClientSavings across the matching connection */
  potentialClientSavings: Scalars["Float"]["output"];
  /** Sum of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount: Scalars["Float"]["output"];
};

export type BatchClaimStateVariancePopulationAggregates = {
  __typename?: "BatchClaimStateVariancePopulationAggregates";
  /** Population variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStateVarianceSampleAggregates = {
  __typename?: "BatchClaimStateVarianceSampleAggregates";
  /** Sample variance of determinedAdjustedAllowedAmount across the matching connection */
  determinedAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedClientSavings across the matching connection */
  determinedClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of determinedDiscrepantAmount across the matching connection */
  determinedDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialAdjustedAllowedAmount across the matching connection */
  potentialAdjustedAllowedAmount?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialClientSavings across the matching connection */
  potentialClientSavings?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of potentialDiscrepantAmount across the matching connection */
  potentialDiscrepantAmount?: Maybe<Scalars["Float"]["output"]>;
};

/** A connection to a list of `BatchClaimState` values. */
export type BatchClaimStatesConnection = {
  __typename?: "BatchClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimStateAggregates>;
  /** A list of edges which contains the `BatchClaimState` and cursor to aid in pagination. */
  edges: Array<BatchClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimStateAggregates>>;
  /** A list of `BatchClaimState` objects. */
  nodes: Array<Maybe<BatchClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaimState` values. */
export type BatchClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimStateGroupBy>;
  having?: InputMaybe<BatchClaimStateHavingInput>;
};

/** A `BatchClaimState` edge in the connection. */
export type BatchClaimStatesEdge = {
  __typename?: "BatchClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaimState` at the end of the edge. */
  node?: Maybe<BatchClaimState>;
};

/** Methods to use when ordering `BatchClaimState`. */
export enum BatchClaimStatesOrderBy {
  BATCH_CLAIM_BY_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_ID__ICN_ASC = "BATCH_CLAIM_BY_ID__ICN_ASC",
  BATCH_CLAIM_BY_ID__ICN_DESC = "BATCH_CLAIM_BY_ID__ICN_DESC",
  BATCH_CLAIM_BY_ID__ID_ASC = "BATCH_CLAIM_BY_ID__ID_ASC",
  BATCH_CLAIM_BY_ID__ID_DESC = "BATCH_CLAIM_BY_ID__ID_DESC",
  BATCH_CLAIM_BY_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_ID__UPDATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATE_DESC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  DETERMINED_CLIENT_SAVINGS_ASC = "DETERMINED_CLIENT_SAVINGS_ASC",
  DETERMINED_CLIENT_SAVINGS_DESC = "DETERMINED_CLIENT_SAVINGS_DESC",
  DETERMINED_DISCREPANT_AMOUNT_ASC = "DETERMINED_DISCREPANT_AMOUNT_ASC",
  DETERMINED_DISCREPANT_AMOUNT_DESC = "DETERMINED_DISCREPANT_AMOUNT_DESC",
  DISPUTED_FINDING_CLAIM_STATE_ASC = "DISPUTED_FINDING_CLAIM_STATE_ASC",
  DISPUTED_FINDING_CLAIM_STATE_DESC = "DISPUTED_FINDING_CLAIM_STATE_DESC",
  DOCUMENTATION_CLAIM_STATE_ASC = "DOCUMENTATION_CLAIM_STATE_ASC",
  DOCUMENTATION_CLAIM_STATE_DESC = "DOCUMENTATION_CLAIM_STATE_DESC",
  DUE_DATE_ASC = "DUE_DATE_ASC",
  DUE_DATE_DESC = "DUE_DATE_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_CLAIM_STATE_ASC = "INVOICE_CLAIM_STATE_ASC",
  INVOICE_CLAIM_STATE_DESC = "INVOICE_CLAIM_STATE_DESC",
  IS_PROVIDER_ACTIVE_ASC = "IS_PROVIDER_ACTIVE_ASC",
  IS_PROVIDER_ACTIVE_DESC = "IS_PROVIDER_ACTIVE_DESC",
  IS_REALIZED_ASC = "IS_REALIZED_ASC",
  IS_REALIZED_DESC = "IS_REALIZED_DESC",
  NATURAL = "NATURAL",
  NEGOTIATION_CLAIM_STATE_ASC = "NEGOTIATION_CLAIM_STATE_ASC",
  NEGOTIATION_CLAIM_STATE_DESC = "NEGOTIATION_CLAIM_STATE_DESC",
  ON_HOLD_CLAIM_STATE_ASC = "ON_HOLD_CLAIM_STATE_ASC",
  ON_HOLD_CLAIM_STATE_DESC = "ON_HOLD_CLAIM_STATE_DESC",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  POTENTIAL_CLIENT_SAVINGS_ASC = "POTENTIAL_CLIENT_SAVINGS_ASC",
  POTENTIAL_CLIENT_SAVINGS_DESC = "POTENTIAL_CLIENT_SAVINGS_DESC",
  POTENTIAL_DISCREPANT_AMOUNT_ASC = "POTENTIAL_DISCREPANT_AMOUNT_ASC",
  POTENTIAL_DISCREPANT_AMOUNT_DESC = "POTENTIAL_DISCREPANT_AMOUNT_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATE_DESC",
  REPORTED_ASC = "REPORTED_ASC",
  REPORTED_DESC = "REPORTED_DESC",
  WORKFLOW_CLAIM_STATE_ASC = "WORKFLOW_CLAIM_STATE_ASC",
  WORKFLOW_CLAIM_STATE_DESC = "WORKFLOW_CLAIM_STATE_DESC",
}

export type BatchClaimStddevPopulationAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimStddevPopulationAggregates = {
  __typename?: "BatchClaimStddevPopulationAggregates";
  /** Population standard deviation of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Population standard deviation of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStddevPopulationAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimStddevSampleAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimStddevSampleAggregates = {
  __typename?: "BatchClaimStddevSampleAggregates";
  /** Sample standard deviation of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Sample standard deviation of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimStddevSampleAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimSumAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimSumAggregates = {
  __typename?: "BatchClaimSumAggregates";
  /** Sum of amountCharged across the matching connection */
  amountCharged: Scalars["Float"]["output"];
  /** Sum of amountReimbursed across the matching connection */
  amountReimbursed: Scalars["Float"]["output"];
  /** Sum of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Sum of paymentRate across the matching connection */
  paymentRate: Scalars["Float"]["output"];
};

export type BatchClaimSumAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against many `AuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyAuditFindingFilter = {
  /** Aggregates across related `AuditFinding` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingAggregatesFilter>;
  /** Every related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingFilter>;
  /** No related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingFilter>;
  /** Some related `AuditFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingFilter>;
};

/** A filter to be used against many `BatchClaimAssignee` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyBatchClaimAssigneeFilter = {
  /** Aggregates across related `BatchClaimAssignee` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAssigneeAggregatesFilter>;
  /** Every related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimAssigneeFilter>;
  /** No related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimAssigneeFilter>;
  /** Some related `BatchClaimAssignee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimAssigneeFilter>;
};

/** A filter to be used against many `BatchClaimDueDate` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyBatchClaimDueDateFilter = {
  /** Aggregates across related `BatchClaimDueDate` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimDueDateAggregatesFilter>;
  /** Every related `BatchClaimDueDate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimDueDateFilter>;
  /** No related `BatchClaimDueDate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimDueDateFilter>;
  /** Some related `BatchClaimDueDate` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimDueDateFilter>;
};

/** A filter to be used against many `BatchClaimLine` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyBatchClaimLineFilter = {
  /** Aggregates across related `BatchClaimLine` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineAggregatesFilter>;
  /** Every related `BatchClaimLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineFilter>;
  /** No related `BatchClaimLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineFilter>;
  /** Some related `BatchClaimLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineFilter>;
};

/** A filter to be used against many `BatchClaimLineItem` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyBatchClaimLineItemFilter = {
  /** Aggregates across related `BatchClaimLineItem` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemAggregatesFilter>;
  /** Every related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemFilter>;
  /** No related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemFilter>;
  /** Some related `BatchClaimLineItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemFilter>;
};

/** A filter to be used against many `ClientSignOffClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyClientSignOffClaimStateFilter = {
  /** Aggregates across related `ClientSignOffClaimState` match the filter criteria. */
  aggregates?: InputMaybe<ClientSignOffClaimStateAggregatesFilter>;
  /** Every related `ClientSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ClientSignOffClaimStateFilter>;
  /** No related `ClientSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ClientSignOffClaimStateFilter>;
  /** Some related `ClientSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ClientSignOffClaimStateFilter>;
};

/** A filter to be used against many `DisputedFinding` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyDisputedFindingFilter = {
  /** Aggregates across related `DisputedFinding` match the filter criteria. */
  aggregates?: InputMaybe<DisputedFindingAggregatesFilter>;
  /** Every related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DisputedFindingFilter>;
  /** No related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DisputedFindingFilter>;
  /** Some related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DisputedFindingFilter>;
};

/** A filter to be used against many `DocumentRequestCreated` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyDocumentRequestCreatedFilter = {
  /** Aggregates across related `DocumentRequestCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentRequestCreatedAggregatesFilter>;
  /** Every related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentRequestCreatedFilter>;
  /** No related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentRequestCreatedFilter>;
  /** Some related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentRequestCreatedFilter>;
};

/** A filter to be used against many `DocumentUploadCreated` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyDocumentUploadCreatedFilter = {
  /** Aggregates across related `DocumentUploadCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentUploadCreatedAggregatesFilter>;
  /** Every related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentUploadCreatedFilter>;
  /** No related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentUploadCreatedFilter>;
  /** Some related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentUploadCreatedFilter>;
};

/** A filter to be used against many `InvoiceClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyInvoiceClaimStateFilter = {
  /** Aggregates across related `InvoiceClaimState` match the filter criteria. */
  aggregates?: InputMaybe<InvoiceClaimStateAggregatesFilter>;
  /** Every related `InvoiceClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<InvoiceClaimStateFilter>;
  /** No related `InvoiceClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<InvoiceClaimStateFilter>;
  /** Some related `InvoiceClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<InvoiceClaimStateFilter>;
};

/** A filter to be used against many `NegotiationClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyNegotiationClaimStateFilter = {
  /** Aggregates across related `NegotiationClaimState` match the filter criteria. */
  aggregates?: InputMaybe<NegotiationClaimStateAggregatesFilter>;
  /** Every related `NegotiationClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<NegotiationClaimStateFilter>;
  /** No related `NegotiationClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<NegotiationClaimStateFilter>;
  /** Some related `NegotiationClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<NegotiationClaimStateFilter>;
};

/** A filter to be used against many `OnHoldClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyOnHoldClaimStateFilter = {
  /** Aggregates across related `OnHoldClaimState` match the filter criteria. */
  aggregates?: InputMaybe<OnHoldClaimStateAggregatesFilter>;
  /** Every related `OnHoldClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OnHoldClaimStateFilter>;
  /** No related `OnHoldClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OnHoldClaimStateFilter>;
  /** Some related `OnHoldClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OnHoldClaimStateFilter>;
};

/** A filter to be used against many `ProviderSignOffClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyProviderSignOffClaimStateFilter = {
  /** Aggregates across related `ProviderSignOffClaimState` match the filter criteria. */
  aggregates?: InputMaybe<ProviderSignOffClaimStateAggregatesFilter>;
  /** Every related `ProviderSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProviderSignOffClaimStateFilter>;
  /** No related `ProviderSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProviderSignOffClaimStateFilter>;
  /** Some related `ProviderSignOffClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProviderSignOffClaimStateFilter>;
};

/** A filter to be used against many `ReportedClaim` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyReportedClaimFilter = {
  /** Aggregates across related `ReportedClaim` match the filter criteria. */
  aggregates?: InputMaybe<ReportedClaimAggregatesFilter>;
  /** Every related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ReportedClaimFilter>;
  /** No related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ReportedClaimFilter>;
  /** Some related `ReportedClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ReportedClaimFilter>;
};

/** A filter to be used against many `S3AuditDocumentation` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyS3AuditDocumentationFilter = {
  /** Aggregates across related `S3AuditDocumentation` match the filter criteria. */
  aggregates?: InputMaybe<S3AuditDocumentationAggregatesFilter>;
  /** Every related `S3AuditDocumentation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<S3AuditDocumentationFilter>;
  /** No related `S3AuditDocumentation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<S3AuditDocumentationFilter>;
  /** Some related `S3AuditDocumentation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<S3AuditDocumentationFilter>;
};

/** A filter to be used against many `WorkflowClaimState` object types. All fields are combined with a logical ‘and.’ */
export type BatchClaimToManyWorkflowClaimStateFilter = {
  /** Aggregates across related `WorkflowClaimState` match the filter criteria. */
  aggregates?: InputMaybe<WorkflowClaimStateAggregatesFilter>;
  /** Every related `WorkflowClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<WorkflowClaimStateFilter>;
  /** No related `WorkflowClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<WorkflowClaimStateFilter>;
  /** Some related `WorkflowClaimState` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<WorkflowClaimStateFilter>;
};

export type BatchClaimVariancePopulationAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimVariancePopulationAggregates = {
  __typename?: "BatchClaimVariancePopulationAggregates";
  /** Population variance of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Population variance of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimVariancePopulationAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchClaimVarianceSampleAggregateFilter = {
  amountCharged?: InputMaybe<FloatFilter>;
  amountReimbursed?: InputMaybe<FloatFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  paymentRate?: InputMaybe<FloatFilter>;
};

export type BatchClaimVarianceSampleAggregates = {
  __typename?: "BatchClaimVarianceSampleAggregates";
  /** Sample variance of amountCharged across the matching connection */
  amountCharged?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of amountReimbursed across the matching connection */
  amountReimbursed?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of this field across the matching connection. */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Sample variance of paymentRate across the matching connection */
  paymentRate?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchClaimVarianceSampleAggregatesimproperPaymentCostArgs = {
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `BatchClaim` values. */
export type BatchClaimsConnection = {
  __typename?: "BatchClaimsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchClaimAggregates>;
  /** A list of edges which contains the `BatchClaim` and cursor to aid in pagination. */
  edges: Array<BatchClaimsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchClaimAggregates>>;
  /** A list of `BatchClaim` objects. */
  nodes: Array<Maybe<BatchClaim>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchClaim` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchClaim` values. */
export type BatchClaimsConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchClaimGroupBy>;
  having?: InputMaybe<BatchClaimHavingInput>;
};

/** A `BatchClaim` edge in the connection. */
export type BatchClaimsEdge = {
  __typename?: "BatchClaimsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchClaim` at the end of the edge. */
  node?: Maybe<BatchClaim>;
};

/** Methods to use when ordering `BatchClaim`. */
export enum BatchClaimsOrderBy {
  ADM_TYPE_ASC = "ADM_TYPE_ASC",
  ADM_TYPE_DESC = "ADM_TYPE_DESC",
  AMOUNT_CHARGED_ASC = "AMOUNT_CHARGED_ASC",
  AMOUNT_CHARGED_DESC = "AMOUNT_CHARGED_DESC",
  AMOUNT_REIMBURSED_ASC = "AMOUNT_REIMBURSED_ASC",
  AMOUNT_REIMBURSED_DESC = "AMOUNT_REIMBURSED_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_AVERAGE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_AVERAGE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_AVERAGE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC = "AUDIT_FINDINGS_AVERAGE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDINGS_AVERAGE_ID_ASC = "AUDIT_FINDINGS_AVERAGE_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_ID_DESC = "AUDIT_FINDINGS_AVERAGE_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_AVERAGE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_AVERAGE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_AVERAGE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_ASC = "AUDIT_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  AUDIT_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_DESC = "AUDIT_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  AUDIT_FINDINGS_COUNT_ASC = "AUDIT_FINDINGS_COUNT_ASC",
  AUDIT_FINDINGS_COUNT_DESC = "AUDIT_FINDINGS_COUNT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CONFIDENCE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MAX_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MAX_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MAX_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_ASC = "AUDIT_FINDINGS_MAX_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MAX_CONFIDENCE_DESC = "AUDIT_FINDINGS_MAX_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MAX_CREATED_AT_ASC = "AUDIT_FINDINGS_MAX_CREATED_AT_ASC",
  AUDIT_FINDINGS_MAX_CREATED_AT_DESC = "AUDIT_FINDINGS_MAX_CREATED_AT_DESC",
  AUDIT_FINDINGS_MAX_ID_ASC = "AUDIT_FINDINGS_MAX_ID_ASC",
  AUDIT_FINDINGS_MAX_ID_DESC = "AUDIT_FINDINGS_MAX_ID_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MAX_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MAX_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MAX_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MAX_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MAX_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MAX_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MAX_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_ASC = "AUDIT_FINDINGS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MAX_UPDATED_AT_DESC = "AUDIT_FINDINGS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_MIN_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_MIN_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDINGS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_MIN_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_ASC = "AUDIT_FINDINGS_MIN_CONFIDENCE_ASC",
  AUDIT_FINDINGS_MIN_CONFIDENCE_DESC = "AUDIT_FINDINGS_MIN_CONFIDENCE_DESC",
  AUDIT_FINDINGS_MIN_CREATED_AT_ASC = "AUDIT_FINDINGS_MIN_CREATED_AT_ASC",
  AUDIT_FINDINGS_MIN_CREATED_AT_DESC = "AUDIT_FINDINGS_MIN_CREATED_AT_DESC",
  AUDIT_FINDINGS_MIN_ID_ASC = "AUDIT_FINDINGS_MIN_ID_ASC",
  AUDIT_FINDINGS_MIN_ID_DESC = "AUDIT_FINDINGS_MIN_ID_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_MIN_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_MIN_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_MIN_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_MIN_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_MIN_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_MIN_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_MIN_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_ASC = "AUDIT_FINDINGS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDINGS_MIN_UPDATED_AT_DESC = "AUDIT_FINDINGS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_SUM_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_SUM_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDINGS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_SUM_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_ASC = "AUDIT_FINDINGS_SUM_CONFIDENCE_ASC",
  AUDIT_FINDINGS_SUM_CONFIDENCE_DESC = "AUDIT_FINDINGS_SUM_CONFIDENCE_DESC",
  AUDIT_FINDINGS_SUM_CREATED_AT_ASC = "AUDIT_FINDINGS_SUM_CREATED_AT_ASC",
  AUDIT_FINDINGS_SUM_CREATED_AT_DESC = "AUDIT_FINDINGS_SUM_CREATED_AT_DESC",
  AUDIT_FINDINGS_SUM_ID_ASC = "AUDIT_FINDINGS_SUM_ID_ASC",
  AUDIT_FINDINGS_SUM_ID_DESC = "AUDIT_FINDINGS_SUM_ID_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_SUM_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_SUM_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_SUM_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_SUM_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_SUM_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_SUM_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_SUM_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_ASC = "AUDIT_FINDINGS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDINGS_SUM_UPDATED_AT_DESC = "AUDIT_FINDINGS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_COMMENT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDITOR_REVIEWED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUDIT_TYPE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CONFIDENCE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_NEEDS_REVIEW_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_METHOD_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_METHOD_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_COUNT_ASC = "AUDIT_SELECTION_METHODS_COUNT_ASC",
  AUDIT_SELECTION_METHODS_COUNT_DESC = "AUDIT_SELECTION_METHODS_COUNT_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_MAX_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MAX_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_MAX_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MAX_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_METHOD_ASC = "AUDIT_SELECTION_METHODS_MAX_METHOD_ASC",
  AUDIT_SELECTION_METHODS_MAX_METHOD_DESC = "AUDIT_SELECTION_METHODS_MAX_METHOD_DESC",
  AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_MIN_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MIN_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_MIN_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MIN_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_METHOD_ASC = "AUDIT_SELECTION_METHODS_MIN_METHOD_ASC",
  AUDIT_SELECTION_METHODS_MIN_METHOD_DESC = "AUDIT_SELECTION_METHODS_MIN_METHOD_DESC",
  AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_SUM_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_SUM_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_SUM_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_SUM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_METHOD_ASC = "AUDIT_SELECTION_METHODS_SUM_METHOD_ASC",
  AUDIT_SELECTION_METHODS_SUM_METHOD_DESC = "AUDIT_SELECTION_METHODS_SUM_METHOD_DESC",
  AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_NAME_ASC = "BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_NAME_ASC",
  BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_NAME_DESC = "BATCH_CLAIM_ASSIGNEES_BY_AUDITOR_USER_NAME_DESC",
  BATCH_CLAIM_ASSIGNEES_BY_BATCH_CLAIM_ID__COUNT_ASC = "BATCH_CLAIM_ASSIGNEES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  BATCH_CLAIM_ASSIGNEES_BY_BATCH_CLAIM_ID__COUNT_DESC = "BATCH_CLAIM_ASSIGNEES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  BATCH_CLAIM_ASSIGNEES_COUNT_ASC = "BATCH_CLAIM_ASSIGNEES_COUNT_ASC",
  BATCH_CLAIM_ASSIGNEES_COUNT_DESC = "BATCH_CLAIM_ASSIGNEES_COUNT_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_ID_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_ID_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UNASSIGNED_DESC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_ASSIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_AVERAGE_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_AVERAGE_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_AVERAGE_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_AVERAGE_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_ID_ASC = "BATCH_CLAIM_DUE_DATES_AVERAGE_ID_ASC",
  BATCH_CLAIM_DUE_DATES_AVERAGE_ID_DESC = "BATCH_CLAIM_DUE_DATES_AVERAGE_ID_DESC",
  BATCH_CLAIM_DUE_DATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "BATCH_CLAIM_DUE_DATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  BATCH_CLAIM_DUE_DATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "BATCH_CLAIM_DUE_DATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  BATCH_CLAIM_DUE_DATES_COUNT_ASC = "BATCH_CLAIM_DUE_DATES_COUNT_ASC",
  BATCH_CLAIM_DUE_DATES_COUNT_DESC = "BATCH_CLAIM_DUE_DATES_COUNT_DESC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_DUE_DATES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_DUE_DATES_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_MAX_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_MAX_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_MAX_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_MAX_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_MAX_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_MAX_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_MAX_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_MAX_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_MAX_ID_ASC = "BATCH_CLAIM_DUE_DATES_MAX_ID_ASC",
  BATCH_CLAIM_DUE_DATES_MAX_ID_DESC = "BATCH_CLAIM_DUE_DATES_MAX_ID_DESC",
  BATCH_CLAIM_DUE_DATES_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_MIN_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_MIN_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_MIN_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_MIN_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_MIN_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_MIN_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_MIN_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_MIN_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_MIN_ID_ASC = "BATCH_CLAIM_DUE_DATES_MIN_ID_ASC",
  BATCH_CLAIM_DUE_DATES_MIN_ID_DESC = "BATCH_CLAIM_DUE_DATES_MIN_ID_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_DUE_DATES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_DUE_DATES_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_SUM_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_SUM_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_SUM_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_SUM_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_SUM_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_SUM_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_SUM_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_SUM_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_SUM_ID_ASC = "BATCH_CLAIM_DUE_DATES_SUM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_SUM_ID_DESC = "BATCH_CLAIM_DUE_DATES_SUM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DELETED_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DELETED_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DELETED_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DELETED_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DUE_DATE_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DUE_DATE_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DUE_DATE_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_DUE_DATE_DESC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_DUE_DATES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINES_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_1_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_1_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_2_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_2_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_3_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_3_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_4_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_4_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_ID_ASC = "BATCH_CLAIM_LINES_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINES_AVERAGE_ID_DESC = "BATCH_CLAIM_LINES_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINES_AVERAGE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_AVERAGE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_AVERAGE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_AVERAGE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_AVERAGE_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_AVERAGE_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_AVERAGE_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_AVERAGE_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_AVERAGE_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_AVERAGE_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_AVERAGE_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_AVERAGE_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_AVERAGE_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_AVERAGE_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_AVERAGE_REV_CODE_ASC = "BATCH_CLAIM_LINES_AVERAGE_REV_CODE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_REV_CODE_DESC = "BATCH_CLAIM_LINES_AVERAGE_REV_CODE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_AVERAGE_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_AVERAGE_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_AVERAGE_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_AVERAGE_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_AVERAGE_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_AVERAGE_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_AVERAGE_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_AVERAGE_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_AVERAGE_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_AVERAGE_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_UNITS_ASC = "BATCH_CLAIM_LINES_AVERAGE_UNITS_ASC",
  BATCH_CLAIM_LINES_AVERAGE_UNITS_DESC = "BATCH_CLAIM_LINES_AVERAGE_UNITS_DESC",
  BATCH_CLAIM_LINES_AVERAGE_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_AVERAGE_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_AVERAGE_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_AVERAGE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_AVERAGE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_AVERAGE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_BY_BATCH_CLAIM_ID__COUNT_ASC = "BATCH_CLAIM_LINES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  BATCH_CLAIM_LINES_BY_BATCH_CLAIM_ID__COUNT_DESC = "BATCH_CLAIM_LINES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  BATCH_CLAIM_LINES_COUNT_ASC = "BATCH_CLAIM_LINES_COUNT_ASC",
  BATCH_CLAIM_LINES_COUNT_DESC = "BATCH_CLAIM_LINES_COUNT_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNITS_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNITS_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNITS_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNITS_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_1_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_1_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_1_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_1_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_2_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_2_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_2_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_2_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_3_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_3_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_3_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_3_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_4_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_4_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_4_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_4_DESC",
  BATCH_CLAIM_LINES_MAX_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_ID_ASC = "BATCH_CLAIM_LINES_MAX_ID_ASC",
  BATCH_CLAIM_LINES_MAX_ID_DESC = "BATCH_CLAIM_LINES_MAX_ID_DESC",
  BATCH_CLAIM_LINES_MAX_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_MAX_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_MAX_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_MAX_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_MAX_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_MAX_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_MAX_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_MAX_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_MAX_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_MAX_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_MAX_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_MAX_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_MAX_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_MAX_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_MAX_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_MAX_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_MAX_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_MAX_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_MAX_REV_CODE_ASC = "BATCH_CLAIM_LINES_MAX_REV_CODE_ASC",
  BATCH_CLAIM_LINES_MAX_REV_CODE_DESC = "BATCH_CLAIM_LINES_MAX_REV_CODE_DESC",
  BATCH_CLAIM_LINES_MAX_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_MAX_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_MAX_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_MAX_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_MAX_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MAX_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MAX_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MAX_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MAX_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_MAX_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_MAX_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_MAX_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_MAX_UNITS_ASC = "BATCH_CLAIM_LINES_MAX_UNITS_ASC",
  BATCH_CLAIM_LINES_MAX_UNITS_DESC = "BATCH_CLAIM_LINES_MAX_UNITS_DESC",
  BATCH_CLAIM_LINES_MAX_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_MAX_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_MAX_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_MAX_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_MAX_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_MAX_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_MAX_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_MAX_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_1_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_1_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_1_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_1_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_2_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_2_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_2_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_2_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_3_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_3_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_3_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_3_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_4_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_4_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_4_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_4_DESC",
  BATCH_CLAIM_LINES_MIN_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_ID_ASC = "BATCH_CLAIM_LINES_MIN_ID_ASC",
  BATCH_CLAIM_LINES_MIN_ID_DESC = "BATCH_CLAIM_LINES_MIN_ID_DESC",
  BATCH_CLAIM_LINES_MIN_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_MIN_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_MIN_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_MIN_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_MIN_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_MIN_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_MIN_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_MIN_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_MIN_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_MIN_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_MIN_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_MIN_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_MIN_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_MIN_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_MIN_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_MIN_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_MIN_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_MIN_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_MIN_REV_CODE_ASC = "BATCH_CLAIM_LINES_MIN_REV_CODE_ASC",
  BATCH_CLAIM_LINES_MIN_REV_CODE_DESC = "BATCH_CLAIM_LINES_MIN_REV_CODE_DESC",
  BATCH_CLAIM_LINES_MIN_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_MIN_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_MIN_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_MIN_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_MIN_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_MIN_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_MIN_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_MIN_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_MIN_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_MIN_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_MIN_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_MIN_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_MIN_UNITS_ASC = "BATCH_CLAIM_LINES_MIN_UNITS_ASC",
  BATCH_CLAIM_LINES_MIN_UNITS_DESC = "BATCH_CLAIM_LINES_MIN_UNITS_DESC",
  BATCH_CLAIM_LINES_MIN_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_MIN_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_MIN_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_MIN_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_MIN_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_MIN_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_MIN_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_MIN_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_1_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_1_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_1_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_1_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_2_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_2_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_2_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_2_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_3_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_3_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_3_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_3_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_4_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_4_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_4_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_4_DESC",
  BATCH_CLAIM_LINES_SUM_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_ID_ASC = "BATCH_CLAIM_LINES_SUM_ID_ASC",
  BATCH_CLAIM_LINES_SUM_ID_DESC = "BATCH_CLAIM_LINES_SUM_ID_DESC",
  BATCH_CLAIM_LINES_SUM_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_SUM_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_SUM_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_SUM_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_SUM_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_SUM_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_SUM_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_SUM_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_SUM_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_SUM_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_SUM_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_SUM_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_SUM_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_SUM_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_SUM_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_SUM_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_SUM_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_SUM_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_SUM_REV_CODE_ASC = "BATCH_CLAIM_LINES_SUM_REV_CODE_ASC",
  BATCH_CLAIM_LINES_SUM_REV_CODE_DESC = "BATCH_CLAIM_LINES_SUM_REV_CODE_DESC",
  BATCH_CLAIM_LINES_SUM_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_SUM_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_SUM_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_SUM_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_SUM_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_SUM_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_SUM_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_SUM_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_SUM_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_SUM_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_SUM_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_SUM_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_SUM_UNITS_ASC = "BATCH_CLAIM_LINES_SUM_UNITS_ASC",
  BATCH_CLAIM_LINES_SUM_UNITS_DESC = "BATCH_CLAIM_LINES_SUM_UNITS_DESC",
  BATCH_CLAIM_LINES_SUM_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_SUM_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_SUM_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_SUM_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_SUM_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_SUM_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_SUM_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_SUM_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_1_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_2_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_3_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_DIAG_4_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COVERED_AMOUNT_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COVERED_AMOUNT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COVERED_AMOUNT_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COVERED_AMOUNT_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COV_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COV_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COV_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_NON_COV_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_MOD_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_MOD_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_MOD_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_PROCEDURE_MOD_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_SRC_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_SRC_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_SRC_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_CODE_SRC_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_DESCRIPTION_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_DESCRIPTION_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_DESCRIPTION_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_REV_DESCRIPTION_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_SERVICE_DATE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_SERVICE_DATE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_SERVICE_DATE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_SERVICE_DATE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_ALLOWABLE_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_ALLOWABLE_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_ALLOWABLE_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_ALLOWABLE_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_AVERAGE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_COUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_COUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_COUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_COUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_DISTINCT_COUNT_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MAX_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_MIN_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_POPULATION_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_STDDEV_SAMPLE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_SUM_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_POPULATION_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ALA_ROW_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_IB_LINE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ITEM_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_LINE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_BILLED_AMOUNT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PAGE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PREVIOUS_VERSION_ID_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_PROCEDURE_CODE_MODIFIER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_REV_CODE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_S3_INPUT_SOURCE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_TABLE_NUMBER_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNITS_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UNIT_CHARGE_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_ASC",
  BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC = "BATCH_CLAIM_LINE_ITEMS_VARIANCE_SAMPLE_VERSION_FINGERPRINT_DESC",
  BATCH_CLAIM_STATE_BY_ID__CLIENT_SIGN_OFF_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__CLIENT_SIGN_OFF_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__CLIENT_SIGN_OFF_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__CLIENT_SIGN_OFF_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS_ASC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS_ASC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS_DESC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_CLIENT_SAVINGS_DESC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_STATE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_STATE_BY_ID__DETERMINED_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_STATE_BY_ID__DISPUTED_FINDING_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__DISPUTED_FINDING_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__DISPUTED_FINDING_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__DISPUTED_FINDING_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__DOCUMENTATION_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__DOCUMENTATION_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__DOCUMENTATION_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__DOCUMENTATION_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__DUE_DATE_ASC = "BATCH_CLAIM_STATE_BY_ID__DUE_DATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__DUE_DATE_DESC = "BATCH_CLAIM_STATE_BY_ID__DUE_DATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__ID_ASC = "BATCH_CLAIM_STATE_BY_ID__ID_ASC",
  BATCH_CLAIM_STATE_BY_ID__ID_DESC = "BATCH_CLAIM_STATE_BY_ID__ID_DESC",
  BATCH_CLAIM_STATE_BY_ID__INVOICE_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__INVOICE_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__INVOICE_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__INVOICE_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__IS_PROVIDER_ACTIVE_ASC = "BATCH_CLAIM_STATE_BY_ID__IS_PROVIDER_ACTIVE_ASC",
  BATCH_CLAIM_STATE_BY_ID__IS_PROVIDER_ACTIVE_DESC = "BATCH_CLAIM_STATE_BY_ID__IS_PROVIDER_ACTIVE_DESC",
  BATCH_CLAIM_STATE_BY_ID__IS_REALIZED_ASC = "BATCH_CLAIM_STATE_BY_ID__IS_REALIZED_ASC",
  BATCH_CLAIM_STATE_BY_ID__IS_REALIZED_DESC = "BATCH_CLAIM_STATE_BY_ID__IS_REALIZED_DESC",
  BATCH_CLAIM_STATE_BY_ID__NEGOTIATION_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__NEGOTIATION_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__NEGOTIATION_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__NEGOTIATION_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__ON_HOLD_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__ON_HOLD_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__ON_HOLD_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__ON_HOLD_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_ASC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_ADJUSTED_ALLOWED_AMOUNT_DESC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_CLIENT_SAVINGS_ASC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_CLIENT_SAVINGS_ASC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_CLIENT_SAVINGS_DESC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_CLIENT_SAVINGS_DESC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_ASC",
  BATCH_CLAIM_STATE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC = "BATCH_CLAIM_STATE_BY_ID__POTENTIAL_DISCREPANT_AMOUNT_DESC",
  BATCH_CLAIM_STATE_BY_ID__PROVIDER_SIGN_OFF_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__PROVIDER_SIGN_OFF_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__PROVIDER_SIGN_OFF_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__PROVIDER_SIGN_OFF_CLAIM_STATE_DESC",
  BATCH_CLAIM_STATE_BY_ID__REPORTED_ASC = "BATCH_CLAIM_STATE_BY_ID__REPORTED_ASC",
  BATCH_CLAIM_STATE_BY_ID__REPORTED_DESC = "BATCH_CLAIM_STATE_BY_ID__REPORTED_DESC",
  BATCH_CLAIM_STATE_BY_ID__WORKFLOW_CLAIM_STATE_ASC = "BATCH_CLAIM_STATE_BY_ID__WORKFLOW_CLAIM_STATE_ASC",
  BATCH_CLAIM_STATE_BY_ID__WORKFLOW_CLAIM_STATE_DESC = "BATCH_CLAIM_STATE_BY_ID__WORKFLOW_CLAIM_STATE_DESC",
  BATCH_CLAIM_VALID_UPLOADED_AUDIT_DOCUMENTATION_COUNT_ASC = "BATCH_CLAIM_VALID_UPLOADED_AUDIT_DOCUMENTATION_COUNT_ASC",
  BATCH_CLAIM_VALID_UPLOADED_AUDIT_DOCUMENTATION_COUNT_DESC = "BATCH_CLAIM_VALID_UPLOADED_AUDIT_DOCUMENTATION_COUNT_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__AUTHOR_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_NAME_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_FILE_URL_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__BATCH_NAME_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__CREATED_AT_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__INSURER_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__OWNER_ID_DESC",
  BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_ASC = "BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_ASC",
  BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_DESC = "BATCH_FILE_BY_BATCH_FILE_ID__UPDATED_AT_DESC",
  BATCH_FILE_ID_ASC = "BATCH_FILE_ID_ASC",
  BATCH_FILE_ID_DESC = "BATCH_FILE_ID_DESC",
  BILL_TYPE_ASC = "BILL_TYPE_ASC",
  BILL_TYPE_DESC = "BILL_TYPE_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__AUDITOR_BUCKET_ID_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__AUDITOR_BUCKET_ID_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__AUDITOR_BUCKET_ID_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__AUDITOR_BUCKET_ID_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__BATCH_CLAIM_ID_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__BATCH_CLAIM_ID_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__BATCH_CLAIM_ID_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__BATCH_CLAIM_ID_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__SELECTION_METHOD_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__SELECTION_METHOD_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__SELECTION_METHOD_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__SELECTION_METHOD_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__STATUS_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__STATUS_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__STATUS_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__STATUS_DESC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BUCKET_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BUCKET_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_AVERAGE_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_COUNT_ASC = "CLAIM_CASES_BY_CLAIM_ID_COUNT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_COUNT_DESC = "CLAIM_CASES_BY_CLAIM_ID_COUNT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_DISTINCT_COUNT_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_MAX_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_MIN_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_POPULATION_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_STDDEV_SAMPLE_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_SUM_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_POPULATION_UPDATED_BY_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CASE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CASE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CASE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CASE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_BY_ASC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_BY_ASC",
  CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_BY_DESC = "CLAIM_CASES_BY_CLAIM_ID_VARIANCE_SAMPLE_UPDATED_BY_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_TYPE_ASC = "CLAIM_TYPE_ASC",
  CLAIM_TYPE_DESC = "CLAIM_TYPE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_COUNT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_COUNT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_COUNT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_COUNT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MAX_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MAX_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_MIN_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_MIN_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_SUM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_SUM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CLIENT_SIGN_OFF_STATE_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "CLIENT_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  COMPUTATION_STRATEGY_ASC = "COMPUTATION_STRATEGY_ASC",
  COMPUTATION_STRATEGY_DESC = "COMPUTATION_STRATEGY_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DATE_ADMIT_ASC = "DATE_ADMIT_ASC",
  DATE_ADMIT_DESC = "DATE_ADMIT_DESC",
  DATE_DISCHARGE_ASC = "DATE_DISCHARGE_ASC",
  DATE_DISCHARGE_DESC = "DATE_DISCHARGE_DESC",
  DATE_OF_SERVICE_END_ASC = "DATE_OF_SERVICE_END_ASC",
  DATE_OF_SERVICE_END_DESC = "DATE_OF_SERVICE_END_DESC",
  DATE_OF_SERVICE_START_ASC = "DATE_OF_SERVICE_START_ASC",
  DATE_OF_SERVICE_START_DESC = "DATE_OF_SERVICE_START_DESC",
  DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC = "DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC = "DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_AVERAGE_DELETED_ASC = "DISPUTED_FINDINGS_AVERAGE_DELETED_ASC",
  DISPUTED_FINDINGS_AVERAGE_DELETED_DESC = "DISPUTED_FINDINGS_AVERAGE_DELETED_DESC",
  DISPUTED_FINDINGS_AVERAGE_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_ASC = "DISPUTED_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  DISPUTED_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_DESC = "DISPUTED_FINDINGS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  DISPUTED_FINDINGS_COUNT_ASC = "DISPUTED_FINDINGS_COUNT_ASC",
  DISPUTED_FINDINGS_COUNT_DESC = "DISPUTED_FINDINGS_COUNT_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_MAX_CREATED_AT_ASC = "DISPUTED_FINDINGS_MAX_CREATED_AT_ASC",
  DISPUTED_FINDINGS_MAX_CREATED_AT_DESC = "DISPUTED_FINDINGS_MAX_CREATED_AT_DESC",
  DISPUTED_FINDINGS_MAX_DELETED_ASC = "DISPUTED_FINDINGS_MAX_DELETED_ASC",
  DISPUTED_FINDINGS_MAX_DELETED_DESC = "DISPUTED_FINDINGS_MAX_DELETED_DESC",
  DISPUTED_FINDINGS_MAX_ID_ASC = "DISPUTED_FINDINGS_MAX_ID_ASC",
  DISPUTED_FINDINGS_MAX_ID_DESC = "DISPUTED_FINDINGS_MAX_ID_DESC",
  DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC = "DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC = "DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_MIN_CREATED_AT_ASC = "DISPUTED_FINDINGS_MIN_CREATED_AT_ASC",
  DISPUTED_FINDINGS_MIN_CREATED_AT_DESC = "DISPUTED_FINDINGS_MIN_CREATED_AT_DESC",
  DISPUTED_FINDINGS_MIN_DELETED_ASC = "DISPUTED_FINDINGS_MIN_DELETED_ASC",
  DISPUTED_FINDINGS_MIN_DELETED_DESC = "DISPUTED_FINDINGS_MIN_DELETED_DESC",
  DISPUTED_FINDINGS_MIN_ID_ASC = "DISPUTED_FINDINGS_MIN_ID_ASC",
  DISPUTED_FINDINGS_MIN_ID_DESC = "DISPUTED_FINDINGS_MIN_ID_DESC",
  DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC = "DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC = "DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_SUM_CREATED_AT_ASC = "DISPUTED_FINDINGS_SUM_CREATED_AT_ASC",
  DISPUTED_FINDINGS_SUM_CREATED_AT_DESC = "DISPUTED_FINDINGS_SUM_CREATED_AT_DESC",
  DISPUTED_FINDINGS_SUM_DELETED_ASC = "DISPUTED_FINDINGS_SUM_DELETED_ASC",
  DISPUTED_FINDINGS_SUM_DELETED_DESC = "DISPUTED_FINDINGS_SUM_DELETED_DESC",
  DISPUTED_FINDINGS_SUM_ID_ASC = "DISPUTED_FINDINGS_SUM_ID_ASC",
  DISPUTED_FINDINGS_SUM_ID_DESC = "DISPUTED_FINDINGS_SUM_ID_DESC",
  DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC = "DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC = "DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_AVERAGE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_AVERAGE_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  DOCUMENT_REQUEST_CREATEDS_COUNT_ASC = "DOCUMENT_REQUEST_CREATEDS_COUNT_ASC",
  DOCUMENT_REQUEST_CREATEDS_COUNT_DESC = "DOCUMENT_REQUEST_CREATEDS_COUNT_DESC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MAX_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_MAX_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_MAX_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_MAX_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_MAX_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_MAX_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_MAX_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_MAX_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_MAX_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MAX_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MAX_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MAX_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MAX_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MAX_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MAX_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MAX_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MIN_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_MIN_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_MIN_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_MIN_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_MIN_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_MIN_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_MIN_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_MIN_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_MIN_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MIN_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MIN_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MIN_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_MIN_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_MIN_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_MIN_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_MIN_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_SUM_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_SUM_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_SUM_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_SUM_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_SUM_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_SUM_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_SUM_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_SUM_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_SUM_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_SUM_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_SUM_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_SUM_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_SUM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_SUM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_SUM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_SUM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  ICN_ASC = "ICN_ASC",
  ICN_DESC = "ICN_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  INVOICE_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_AVERAGE_ID_ASC = "INVOICE_CLAIM_STATES_AVERAGE_ID_ASC",
  INVOICE_CLAIM_STATES_AVERAGE_ID_DESC = "INVOICE_CLAIM_STATES_AVERAGE_ID_DESC",
  INVOICE_CLAIM_STATES_AVERAGE_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_AVERAGE_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_AVERAGE_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_AVERAGE_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "INVOICE_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  INVOICE_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "INVOICE_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  INVOICE_CLAIM_STATES_COUNT_ASC = "INVOICE_CLAIM_STATES_COUNT_ASC",
  INVOICE_CLAIM_STATES_COUNT_DESC = "INVOICE_CLAIM_STATES_COUNT_DESC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_DISTINCT_COUNT_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_DISTINCT_COUNT_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_MAX_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_MAX_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_MAX_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_MAX_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_MAX_ID_ASC = "INVOICE_CLAIM_STATES_MAX_ID_ASC",
  INVOICE_CLAIM_STATES_MAX_ID_DESC = "INVOICE_CLAIM_STATES_MAX_ID_DESC",
  INVOICE_CLAIM_STATES_MAX_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_MAX_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_MAX_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_MAX_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_MIN_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_MIN_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_MIN_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_MIN_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_MIN_ID_ASC = "INVOICE_CLAIM_STATES_MIN_ID_ASC",
  INVOICE_CLAIM_STATES_MIN_ID_DESC = "INVOICE_CLAIM_STATES_MIN_ID_DESC",
  INVOICE_CLAIM_STATES_MIN_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_MIN_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_MIN_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_MIN_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_STDDEV_POPULATION_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_STDDEV_POPULATION_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_STDDEV_SAMPLE_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_STDDEV_SAMPLE_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_SUM_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_SUM_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_SUM_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_SUM_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_SUM_ID_ASC = "INVOICE_CLAIM_STATES_SUM_ID_ASC",
  INVOICE_CLAIM_STATES_SUM_ID_DESC = "INVOICE_CLAIM_STATES_SUM_ID_DESC",
  INVOICE_CLAIM_STATES_SUM_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_SUM_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_SUM_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_SUM_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_POPULATION_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_VARIANCE_POPULATION_INVOICE_STATE_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_INVOICE_STATE_ASC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_INVOICE_STATE_ASC",
  INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_INVOICE_STATE_DESC = "INVOICE_CLAIM_STATES_VARIANCE_SAMPLE_INVOICE_STATE_DESC",
  NATURAL = "NATURAL",
  NEGOTIATION_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_ID_ASC = "NEGOTIATION_CLAIM_STATES_AVERAGE_ID_ASC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_ID_DESC = "NEGOTIATION_CLAIM_STATES_AVERAGE_ID_DESC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_AVERAGE_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_AVERAGE_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_AVERAGE_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "NEGOTIATION_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  NEGOTIATION_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "NEGOTIATION_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  NEGOTIATION_CLAIM_STATES_COUNT_ASC = "NEGOTIATION_CLAIM_STATES_COUNT_ASC",
  NEGOTIATION_CLAIM_STATES_COUNT_DESC = "NEGOTIATION_CLAIM_STATES_COUNT_DESC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_DISTINCT_COUNT_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_MAX_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_MAX_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_MAX_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_MAX_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_MAX_ID_ASC = "NEGOTIATION_CLAIM_STATES_MAX_ID_ASC",
  NEGOTIATION_CLAIM_STATES_MAX_ID_DESC = "NEGOTIATION_CLAIM_STATES_MAX_ID_DESC",
  NEGOTIATION_CLAIM_STATES_MAX_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_MAX_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_MAX_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_MAX_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_MIN_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_MIN_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_MIN_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_MIN_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_MIN_ID_ASC = "NEGOTIATION_CLAIM_STATES_MIN_ID_ASC",
  NEGOTIATION_CLAIM_STATES_MIN_ID_DESC = "NEGOTIATION_CLAIM_STATES_MIN_ID_DESC",
  NEGOTIATION_CLAIM_STATES_MIN_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_MIN_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_MIN_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_MIN_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_POPULATION_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_STDDEV_SAMPLE_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_SUM_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_SUM_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_SUM_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_SUM_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_SUM_ID_ASC = "NEGOTIATION_CLAIM_STATES_SUM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_SUM_ID_DESC = "NEGOTIATION_CLAIM_STATES_SUM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_SUM_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_SUM_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_SUM_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_SUM_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_POPULATION_NEGOTIATION_STATE_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_NEGOTIATION_STATE_ASC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_NEGOTIATION_STATE_ASC",
  NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_NEGOTIATION_STATE_DESC = "NEGOTIATION_CLAIM_STATES_VARIANCE_SAMPLE_NEGOTIATION_STATE_DESC",
  ON_HOLD_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_AVERAGE_ID_ASC = "ON_HOLD_CLAIM_STATES_AVERAGE_ID_ASC",
  ON_HOLD_CLAIM_STATES_AVERAGE_ID_DESC = "ON_HOLD_CLAIM_STATES_AVERAGE_ID_DESC",
  ON_HOLD_CLAIM_STATES_AVERAGE_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_AVERAGE_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_AVERAGE_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_AVERAGE_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "ON_HOLD_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  ON_HOLD_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "ON_HOLD_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  ON_HOLD_CLAIM_STATES_COUNT_ASC = "ON_HOLD_CLAIM_STATES_COUNT_ASC",
  ON_HOLD_CLAIM_STATES_COUNT_DESC = "ON_HOLD_CLAIM_STATES_COUNT_DESC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_DISTINCT_COUNT_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_MAX_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_MAX_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_MAX_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_MAX_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_MAX_ID_ASC = "ON_HOLD_CLAIM_STATES_MAX_ID_ASC",
  ON_HOLD_CLAIM_STATES_MAX_ID_DESC = "ON_HOLD_CLAIM_STATES_MAX_ID_DESC",
  ON_HOLD_CLAIM_STATES_MAX_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_MAX_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_MAX_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_MAX_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_MIN_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_MIN_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_MIN_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_MIN_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_MIN_ID_ASC = "ON_HOLD_CLAIM_STATES_MIN_ID_ASC",
  ON_HOLD_CLAIM_STATES_MIN_ID_DESC = "ON_HOLD_CLAIM_STATES_MIN_ID_DESC",
  ON_HOLD_CLAIM_STATES_MIN_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_MIN_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_MIN_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_MIN_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_POPULATION_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_STDDEV_SAMPLE_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_SUM_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_SUM_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_SUM_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_SUM_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_SUM_ID_ASC = "ON_HOLD_CLAIM_STATES_SUM_ID_ASC",
  ON_HOLD_CLAIM_STATES_SUM_ID_DESC = "ON_HOLD_CLAIM_STATES_SUM_ID_DESC",
  ON_HOLD_CLAIM_STATES_SUM_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_SUM_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_SUM_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_SUM_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_POPULATION_ON_HOLD_STATE_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ON_HOLD_STATE_ASC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ON_HOLD_STATE_ASC",
  ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ON_HOLD_STATE_DESC = "ON_HOLD_CLAIM_STATES_VARIANCE_SAMPLE_ON_HOLD_STATE_DESC",
  OWNER_ID_ASC = "OWNER_ID_ASC",
  OWNER_ID_DESC = "OWNER_ID_DESC",
  PAID_DATE_ASC = "PAID_DATE_ASC",
  PAID_DATE_DESC = "PAID_DATE_DESC",
  PATIENT_DOB_ASC = "PATIENT_DOB_ASC",
  PATIENT_DOB_DESC = "PATIENT_DOB_DESC",
  PATIENT_ID_ASC = "PATIENT_ID_ASC",
  PATIENT_ID_DESC = "PATIENT_ID_DESC",
  PAYMENT_RATE_ASC = "PAYMENT_RATE_ASC",
  PAYMENT_RATE_DESC = "PAYMENT_RATE_DESC",
  PERMITTED_INSURER_USERS_ASC = "PERMITTED_INSURER_USERS_ASC",
  PERMITTED_INSURER_USERS_DESC = "PERMITTED_INSURER_USERS_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_AVERAGE_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_COUNT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_COUNT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_COUNT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_COUNT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_DISTINCT_COUNT_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MAX_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_MIN_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_POPULATION_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_STDDEV_SAMPLE_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_SUM_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_POPULATION_PROVIDER_SIGN_OFF_STATE_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_CLAIM_STATES_VARIANCE_SAMPLE_PROVIDER_SIGN_OFF_STATE_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_AVERAGE_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_COUNT_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_COUNT_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_COUNT_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_COUNT_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_DISTINCT_COUNT_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MAX_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MAX_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_MIN_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_MIN_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_POPULATION_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_STDDEV_SAMPLE_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_SUM_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_SUM_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_POPULATION_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_CLAIM_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_ID_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_STRATEGY_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_STRATEGY_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_STRATEGY_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID_VARIANCE_SAMPLE_STRATEGY_DESC",
  REPORTED_CLAIMS_BY_CLAIM_ID__COUNT_ASC = "REPORTED_CLAIMS_BY_CLAIM_ID__COUNT_ASC",
  REPORTED_CLAIMS_BY_CLAIM_ID__COUNT_DESC = "REPORTED_CLAIMS_BY_CLAIM_ID__COUNT_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_AVERAGE_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_AVERAGE_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_BY_BATCH_CLAIM_ID__COUNT_ASC = "S3_AUDIT_DOCUMENTATIONS_BY_BATCH_CLAIM_ID__COUNT_ASC",
  S3_AUDIT_DOCUMENTATIONS_BY_BATCH_CLAIM_ID__COUNT_DESC = "S3_AUDIT_DOCUMENTATIONS_BY_BATCH_CLAIM_ID__COUNT_DESC",
  S3_AUDIT_DOCUMENTATIONS_COUNT_ASC = "S3_AUDIT_DOCUMENTATIONS_COUNT_ASC",
  S3_AUDIT_DOCUMENTATIONS_COUNT_DESC = "S3_AUDIT_DOCUMENTATIONS_COUNT_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_DISTINCT_COUNT_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MAX_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_MAX_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MAX_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_MAX_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_MIN_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_MIN_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_MIN_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_MIN_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_POPULATION_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_STDDEV_SAMPLE_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_SUM_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_SUM_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_SUM_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_SUM_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_POPULATION_TAGS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ETAG_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ETAG_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ETAG_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ETAG_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_FILENAME_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_FILENAME_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_FILENAME_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_FILENAME_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ICN_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ICN_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ICN_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ICN_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_S3_KEY_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_S3_KEY_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SIZE_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SIZE_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SIZE_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SIZE_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_TAGS_ASC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_TAGS_ASC",
  S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_TAGS_DESC = "S3_AUDIT_DOCUMENTATIONS_VARIANCE_SAMPLE_TAGS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_AVERAGE_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_AVERAGE_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_AVERAGE_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_AVERAGE_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_AVERAGE_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_AVERAGE_ID_ASC = "WORKFLOW_CLAIM_STATES_AVERAGE_ID_ASC",
  WORKFLOW_CLAIM_STATES_AVERAGE_ID_DESC = "WORKFLOW_CLAIM_STATES_AVERAGE_ID_DESC",
  WORKFLOW_CLAIM_STATES_AVERAGE_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_AVERAGE_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_AVERAGE_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_AVERAGE_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC = "WORKFLOW_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_ASC",
  WORKFLOW_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC = "WORKFLOW_CLAIM_STATES_BY_BATCH_CLAIM_ID__COUNT_DESC",
  WORKFLOW_CLAIM_STATES_COUNT_ASC = "WORKFLOW_CLAIM_STATES_COUNT_ASC",
  WORKFLOW_CLAIM_STATES_COUNT_DESC = "WORKFLOW_CLAIM_STATES_COUNT_DESC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_ID_ASC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_ID_ASC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_ID_DESC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_ID_DESC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_DISTINCT_COUNT_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_MAX_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_MAX_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_MAX_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_MAX_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_MAX_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_MAX_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_MAX_ID_ASC = "WORKFLOW_CLAIM_STATES_MAX_ID_ASC",
  WORKFLOW_CLAIM_STATES_MAX_ID_DESC = "WORKFLOW_CLAIM_STATES_MAX_ID_DESC",
  WORKFLOW_CLAIM_STATES_MAX_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_MAX_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_MAX_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_MAX_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_MIN_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_MIN_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_MIN_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_MIN_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_MIN_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_MIN_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_MIN_ID_ASC = "WORKFLOW_CLAIM_STATES_MIN_ID_ASC",
  WORKFLOW_CLAIM_STATES_MIN_ID_DESC = "WORKFLOW_CLAIM_STATES_MIN_ID_DESC",
  WORKFLOW_CLAIM_STATES_MIN_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_MIN_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_MIN_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_MIN_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_ID_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_ID_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_ID_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_ID_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_POPULATION_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_ID_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_ID_DESC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_STDDEV_SAMPLE_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_SUM_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_SUM_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_SUM_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_SUM_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_SUM_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_SUM_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_SUM_ID_ASC = "WORKFLOW_CLAIM_STATES_SUM_ID_ASC",
  WORKFLOW_CLAIM_STATES_SUM_ID_DESC = "WORKFLOW_CLAIM_STATES_SUM_ID_DESC",
  WORKFLOW_CLAIM_STATES_SUM_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_SUM_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_SUM_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_SUM_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_ID_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_ID_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_POPULATION_WORKFLOW_STATE_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_ID_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_ID_DESC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_WORKFLOW_STATE_ASC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_WORKFLOW_STATE_ASC",
  WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_WORKFLOW_STATE_DESC = "WORKFLOW_CLAIM_STATES_VARIANCE_SAMPLE_WORKFLOW_STATE_DESC",
}

export type BatchFile = Node & {
  __typename?: "BatchFile";
  /** Reads and enables pagination through a set of `AuditFindingJob`. */
  auditFindingJobs: AuditFindingJobsConnection;
  /** Reads a single `AuditorUser` that is related to this `BatchFile`. */
  author?: Maybe<AuditorUser>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims: BatchClaimsConnection;
  batchFileName: Scalars["String"]["output"];
  batchFileUrl?: Maybe<Scalars["String"]["output"]>;
  batchName: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /**
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  /** Reads a single `Insurer` that is related to this `BatchFile`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  ownerId?: Maybe<Scalars["UUID"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
};

export type BatchFileauditFindingJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingJobCondition>;
  filter?: InputMaybe<AuditFindingJobFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingJobsOrderBy>>;
};

export type BatchFilebatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimCondition>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

export type BatchFileimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileAggregates = {
  __typename?: "BatchFileAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<BatchFileAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BatchFileDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<BatchFileMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<BatchFileMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<BatchFileStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<BatchFileStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<BatchFileSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<BatchFileVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<BatchFileVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `BatchFile` object types. */
export type BatchFileAggregatesFilter = {
  /** Mean average aggregate over matching `BatchFile` objects. */
  average?: InputMaybe<BatchFileAverageAggregateFilter>;
  /** Distinct count aggregate over matching `BatchFile` objects. */
  distinctCount?: InputMaybe<BatchFileDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BatchFile` object to be included within the aggregate. */
  filter?: InputMaybe<BatchFileFilter>;
  /** Maximum aggregate over matching `BatchFile` objects. */
  max?: InputMaybe<BatchFileMaxAggregateFilter>;
  /** Minimum aggregate over matching `BatchFile` objects. */
  min?: InputMaybe<BatchFileMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `BatchFile` objects. */
  stddevPopulation?: InputMaybe<BatchFileStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `BatchFile` objects. */
  stddevSample?: InputMaybe<BatchFileStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `BatchFile` objects. */
  sum?: InputMaybe<BatchFileSumAggregateFilter>;
  /** Population variance aggregate over matching `BatchFile` objects. */
  variancePopulation?: InputMaybe<BatchFileVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `BatchFile` objects. */
  varianceSample?: InputMaybe<BatchFileVarianceSampleAggregateFilter>;
};

/** Input for the nested mutation of `auditorUser` in the `BatchFileInput` mutation. */
export type BatchFileAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnBatchFileForBatchFileAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnBatchFileForBatchFileAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchFileOnBatchFileForBatchFileAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchFile` in the `AuditorUserInput` mutation. */
export type BatchFileAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchFileBatchFilePkeyConnect>>;
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchFileNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchFileOnBatchFileForBatchFileAuthorIdFkeyUsingBatchFilePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnBatchFileForBatchFileAuthorIdFkeyNodeIdUpdate>
  >;
};

export type BatchFileAverageAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileAverageAggregates = {
  __typename?: "BatchFileAverageAggregates";
  /**
   * Mean average of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileAverageAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The fields on `batchFile` to look up the row to connect. */
export type BatchFileBatchFilePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `BatchFile` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BatchFileCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchFileName` field. */
  batchFileName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `batchFileUrl` field. */
  batchFileUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `batchName` field. */
  batchName?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BatchFileDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  batchFileName?: InputMaybe<BigIntFilter>;
  batchFileUrl?: InputMaybe<BigIntFilter>;
  batchName?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  improperPaymentCost?: InputMaybe<FloatFilter>;
  insurerId?: InputMaybe<BigIntFilter>;
  ownerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BatchFileDistinctCountAggregates = {
  __typename?: "BatchFileDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchFileName across the matching connection */
  batchFileName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchFileUrl across the matching connection */
  batchFileUrl?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchName across the matching connection */
  batchName?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of ownerId across the matching connection */
  ownerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

export type BatchFileDistinctCountAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against `BatchFile` object types. All fields are combined with a logical ‘and.’ */
export type BatchFileFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BatchFileFilter>>;
  /** Filter by the object’s `auditFindingJobs` relation. */
  auditFindingJobs?: InputMaybe<BatchFileToManyAuditFindingJobFilter>;
  /** Some related `auditFindingJobs` exist. */
  auditFindingJobsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** A related `author` exists. */
  authorExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaims` relation. */
  batchClaims?: InputMaybe<BatchFileToManyBatchClaimFilter>;
  /** Some related `batchClaims` exist. */
  batchClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchFileName` field. */
  batchFileName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `batchFileUrl` field. */
  batchFileUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `batchName` field. */
  batchName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `improperPaymentCost` field. */
  improperPaymentCost?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BatchFileFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BatchFileFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BatchFile` for usage during aggregation. */
export enum BatchFileGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_FILE_NAME = "BATCH_FILE_NAME",
  BATCH_FILE_URL = "BATCH_FILE_URL",
  BATCH_NAME = "BATCH_NAME",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  INSURER_ID = "INSURER_ID",
  OWNER_ID = "OWNER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BatchFileHavingAverageBatchFileImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileHavingAverageBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingAverageBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingAverageBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingDistinctCountBatchFileImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchFileHavingDistinctCountBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingDistinctCountBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingDistinctCountBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BatchFile` aggregates. */
export type BatchFileHavingInput = {
  AND?: InputMaybe<Array<BatchFileHavingInput>>;
  OR?: InputMaybe<Array<BatchFileHavingInput>>;
  average?: InputMaybe<BatchFileHavingAverageInput>;
  distinctCount?: InputMaybe<BatchFileHavingDistinctCountInput>;
  max?: InputMaybe<BatchFileHavingMaxInput>;
  min?: InputMaybe<BatchFileHavingMinInput>;
  stddevPopulation?: InputMaybe<BatchFileHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BatchFileHavingStddevSampleInput>;
  sum?: InputMaybe<BatchFileHavingSumInput>;
  variancePopulation?: InputMaybe<BatchFileHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BatchFileHavingVarianceSampleInput>;
};

export type BatchFileHavingMaxBatchFileImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileHavingMaxBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingMaxBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingMaxBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingMinBatchFileImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileHavingMinBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingMinBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingMinBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingStddevPopulationBatchFileImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchFileHavingStddevPopulationBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingStddevPopulationBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingStddevPopulationBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingStddevSampleBatchFileImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileHavingStddevSampleBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingStddevSampleBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingStddevSampleBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingSumBatchFileImproperPaymentCostArgsInput = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileHavingSumBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingSumBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingSumBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingVariancePopulationBatchFileImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchFileHavingVariancePopulationBatchFileImproperPaymentCostInput =
  {
    args?: InputMaybe<BatchFileHavingVariancePopulationBatchFileImproperPaymentCostArgsInput>;
    filter?: InputMaybe<HavingFloatFilter>;
  };

export type BatchFileHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingVariancePopulationBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BatchFileHavingVarianceSampleBatchFileImproperPaymentCostArgsInput =
  {
    autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  };

export type BatchFileHavingVarianceSampleBatchFileImproperPaymentCostInput = {
  args?: InputMaybe<BatchFileHavingVarianceSampleBatchFileImproperPaymentCostArgsInput>;
  filter?: InputMaybe<HavingFloatFilter>;
};

export type BatchFileHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  improperPaymentCost?: InputMaybe<BatchFileHavingVarianceSampleBatchFileImproperPaymentCostInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Input for the nested mutation of `insurer` in the `BatchFileInput` mutation. */
export type BatchFileInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchFileOnBatchFileForBatchFileInsurerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `batchFile` in the `InsurerInput` mutation. */
export type BatchFileInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BatchFileBatchFilePkeyConnect>>;
  /** The primary key(s) for `batchFile` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BatchFileNodeIdConnect>>;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BatchFileOnBatchFileForBatchFileInsurerIdFkeyUsingBatchFilePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `batchFile` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnBatchFileForBatchFileInsurerIdFkeyNodeIdUpdate>
  >;
};

export type BatchFileMaxAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileMaxAggregates = {
  __typename?: "BatchFileMaxAggregates";
  /**
   * Maximum of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileMaxAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileMinAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileMinAggregates = {
  __typename?: "BatchFileMinAggregates";
  /**
   * Minimum of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileMinAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BatchFileNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `batchFile` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingJob` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingJob` being updated. */
    patch: AuditFindingJobPatch;
  };

/** The fields on `batchFile` to look up the row to update. */
export type BatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyUsingBatchFilePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchFile` being updated. */
    patch: updateBatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchClaim` being updated. */
  patch: BatchClaimPatch;
};

/** The fields on `batchFile` to look up the row to update. */
export type BatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyUsingBatchFilePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchFile` being updated. */
    patch: updateBatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchFileOnBatchFileForBatchFileAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The fields on `batchFile` to look up the row to update. */
export type BatchFileOnBatchFileForBatchFileAuthorIdFkeyUsingBatchFilePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchFile` being updated. */
    patch: updateBatchFileOnBatchFileForBatchFileAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BatchFileOnBatchFileForBatchFileInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insurer` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `insurer` being updated. */
  patch: InsurerPatch;
};

/** The fields on `batchFile` to look up the row to update. */
export type BatchFileOnBatchFileForBatchFileInsurerIdFkeyUsingBatchFilePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `batchFile` being updated. */
    patch: updateBatchFileOnBatchFileForBatchFileInsurerIdFkeyPatch;
  };

/** Represents an update to a `BatchFile`. Fields that are set will be updated. */
export type BatchFilePatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchFileAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimsUsingId?: InputMaybe<BatchClaimBatchFileIdFkeyInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchFileInsurerIdFkeyInput>;
};

export type BatchFileStddevPopulationAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileStddevPopulationAggregates = {
  __typename?: "BatchFileStddevPopulationAggregates";
  /**
   * Population standard deviation of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileStddevPopulationAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileStddevSampleAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileStddevSampleAggregates = {
  __typename?: "BatchFileStddevSampleAggregates";
  /**
   * Sample standard deviation of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileStddevSampleAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileSumAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileSumAggregates = {
  __typename?: "BatchFileSumAggregates";
  /**
   * Sum of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileSumAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against many `AuditFindingJob` object types. All fields are combined with a logical ‘and.’ */
export type BatchFileToManyAuditFindingJobFilter = {
  /** Aggregates across related `AuditFindingJob` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingJobAggregatesFilter>;
  /** Every related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingJobFilter>;
  /** No related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingJobFilter>;
  /** Some related `AuditFindingJob` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingJobFilter>;
};

/** A filter to be used against many `BatchClaim` object types. All fields are combined with a logical ‘and.’ */
export type BatchFileToManyBatchClaimFilter = {
  /** Aggregates across related `BatchClaim` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAggregatesFilter>;
  /** Every related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimFilter>;
  /** No related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimFilter>;
  /** Some related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimFilter>;
};

export type BatchFileVariancePopulationAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileVariancePopulationAggregates = {
  __typename?: "BatchFileVariancePopulationAggregates";
  /**
   * Population variance of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileVariancePopulationAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type BatchFileVarianceSampleAggregateFilter = {
  improperPaymentCost?: InputMaybe<FloatFilter>;
};

export type BatchFileVarianceSampleAggregates = {
  __typename?: "BatchFileVarianceSampleAggregates";
  /**
   * Sample variance of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per batch file using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
};

export type BatchFileVarianceSampleAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A connection to a list of `BatchFile` values. */
export type BatchFilesConnection = {
  __typename?: "BatchFilesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BatchFileAggregates>;
  /** A list of edges which contains the `BatchFile` and cursor to aid in pagination. */
  edges: Array<BatchFilesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BatchFileAggregates>>;
  /** A list of `BatchFile` objects. */
  nodes: Array<Maybe<BatchFile>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BatchFile` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BatchFile` values. */
export type BatchFilesConnectiongroupedAggregatesArgs = {
  groupBy: Array<BatchFileGroupBy>;
  having?: InputMaybe<BatchFileHavingInput>;
};

/** A `BatchFile` edge in the connection. */
export type BatchFilesEdge = {
  __typename?: "BatchFilesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BatchFile` at the end of the edge. */
  node?: Maybe<BatchFile>;
};

/** Methods to use when ordering `BatchFile`. */
export enum BatchFilesOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_AVERAGE_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_AVERAGE_ID_ASC = "AUDIT_FINDING_JOBS_AVERAGE_ID_ASC",
  AUDIT_FINDING_JOBS_AVERAGE_ID_DESC = "AUDIT_FINDING_JOBS_AVERAGE_ID_DESC",
  AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_BY_BATCH_FILE_ID__COUNT_ASC = "AUDIT_FINDING_JOBS_BY_BATCH_FILE_ID__COUNT_ASC",
  AUDIT_FINDING_JOBS_BY_BATCH_FILE_ID__COUNT_DESC = "AUDIT_FINDING_JOBS_BY_BATCH_FILE_ID__COUNT_DESC",
  AUDIT_FINDING_JOBS_COUNT_ASC = "AUDIT_FINDING_JOBS_COUNT_ASC",
  AUDIT_FINDING_JOBS_COUNT_DESC = "AUDIT_FINDING_JOBS_COUNT_DESC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_MAX_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_MAX_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_MAX_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_MAX_ID_ASC = "AUDIT_FINDING_JOBS_MAX_ID_ASC",
  AUDIT_FINDING_JOBS_MAX_ID_DESC = "AUDIT_FINDING_JOBS_MAX_ID_DESC",
  AUDIT_FINDING_JOBS_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_MIN_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_MIN_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_MIN_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_MIN_ID_ASC = "AUDIT_FINDING_JOBS_MIN_ID_ASC",
  AUDIT_FINDING_JOBS_MIN_ID_DESC = "AUDIT_FINDING_JOBS_MIN_ID_DESC",
  AUDIT_FINDING_JOBS_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_SUM_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_SUM_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_SUM_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_SUM_ID_ASC = "AUDIT_FINDING_JOBS_SUM_ID_ASC",
  AUDIT_FINDING_JOBS_SUM_ID_DESC = "AUDIT_FINDING_JOBS_SUM_ID_DESC",
  AUDIT_FINDING_JOBS_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_JOBS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_AVERAGE_ICN_ASC = "BATCH_CLAIMS_AVERAGE_ICN_ASC",
  BATCH_CLAIMS_AVERAGE_ICN_DESC = "BATCH_CLAIMS_AVERAGE_ICN_DESC",
  BATCH_CLAIMS_AVERAGE_ID_ASC = "BATCH_CLAIMS_AVERAGE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_ID_DESC = "BATCH_CLAIMS_AVERAGE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIMS_BY_BATCH_FILE_ID__COUNT_ASC = "BATCH_CLAIMS_BY_BATCH_FILE_ID__COUNT_ASC",
  BATCH_CLAIMS_BY_BATCH_FILE_ID__COUNT_DESC = "BATCH_CLAIMS_BY_BATCH_FILE_ID__COUNT_DESC",
  BATCH_CLAIMS_COUNT_ASC = "BATCH_CLAIMS_COUNT_ASC",
  BATCH_CLAIMS_COUNT_DESC = "BATCH_CLAIMS_COUNT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIMS_MAX_ADM_TYPE_ASC = "BATCH_CLAIMS_MAX_ADM_TYPE_ASC",
  BATCH_CLAIMS_MAX_ADM_TYPE_DESC = "BATCH_CLAIMS_MAX_ADM_TYPE_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIMS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIMS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MAX_BILL_TYPE_ASC = "BATCH_CLAIMS_MAX_BILL_TYPE_ASC",
  BATCH_CLAIMS_MAX_BILL_TYPE_DESC = "BATCH_CLAIMS_MAX_BILL_TYPE_DESC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MAX_CREATED_AT_ASC = "BATCH_CLAIMS_MAX_CREATED_AT_ASC",
  BATCH_CLAIMS_MAX_CREATED_AT_DESC = "BATCH_CLAIMS_MAX_CREATED_AT_DESC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_ASC = "BATCH_CLAIMS_MAX_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_DESC = "BATCH_CLAIMS_MAX_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MAX_ICN_ASC = "BATCH_CLAIMS_MAX_ICN_ASC",
  BATCH_CLAIMS_MAX_ICN_DESC = "BATCH_CLAIMS_MAX_ICN_DESC",
  BATCH_CLAIMS_MAX_ID_ASC = "BATCH_CLAIMS_MAX_ID_ASC",
  BATCH_CLAIMS_MAX_ID_DESC = "BATCH_CLAIMS_MAX_ID_DESC",
  BATCH_CLAIMS_MAX_INSURER_ID_ASC = "BATCH_CLAIMS_MAX_INSURER_ID_ASC",
  BATCH_CLAIMS_MAX_INSURER_ID_DESC = "BATCH_CLAIMS_MAX_INSURER_ID_DESC",
  BATCH_CLAIMS_MAX_OWNER_ID_ASC = "BATCH_CLAIMS_MAX_OWNER_ID_ASC",
  BATCH_CLAIMS_MAX_OWNER_ID_DESC = "BATCH_CLAIMS_MAX_OWNER_ID_DESC",
  BATCH_CLAIMS_MAX_PAID_DATE_ASC = "BATCH_CLAIMS_MAX_PAID_DATE_ASC",
  BATCH_CLAIMS_MAX_PAID_DATE_DESC = "BATCH_CLAIMS_MAX_PAID_DATE_DESC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_ASC = "BATCH_CLAIMS_MAX_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_DESC = "BATCH_CLAIMS_MAX_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MAX_PATIENT_ID_ASC = "BATCH_CLAIMS_MAX_PATIENT_ID_ASC",
  BATCH_CLAIMS_MAX_PATIENT_ID_DESC = "BATCH_CLAIMS_MAX_PATIENT_ID_DESC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_ASC = "BATCH_CLAIMS_MAX_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_DESC = "BATCH_CLAIMS_MAX_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MAX_UPDATED_AT_ASC = "BATCH_CLAIMS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIMS_MAX_UPDATED_AT_DESC = "BATCH_CLAIMS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIMS_MIN_ADM_TYPE_ASC = "BATCH_CLAIMS_MIN_ADM_TYPE_ASC",
  BATCH_CLAIMS_MIN_ADM_TYPE_DESC = "BATCH_CLAIMS_MIN_ADM_TYPE_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIMS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIMS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MIN_BILL_TYPE_ASC = "BATCH_CLAIMS_MIN_BILL_TYPE_ASC",
  BATCH_CLAIMS_MIN_BILL_TYPE_DESC = "BATCH_CLAIMS_MIN_BILL_TYPE_DESC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MIN_CREATED_AT_ASC = "BATCH_CLAIMS_MIN_CREATED_AT_ASC",
  BATCH_CLAIMS_MIN_CREATED_AT_DESC = "BATCH_CLAIMS_MIN_CREATED_AT_DESC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_ASC = "BATCH_CLAIMS_MIN_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_DESC = "BATCH_CLAIMS_MIN_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MIN_ICN_ASC = "BATCH_CLAIMS_MIN_ICN_ASC",
  BATCH_CLAIMS_MIN_ICN_DESC = "BATCH_CLAIMS_MIN_ICN_DESC",
  BATCH_CLAIMS_MIN_ID_ASC = "BATCH_CLAIMS_MIN_ID_ASC",
  BATCH_CLAIMS_MIN_ID_DESC = "BATCH_CLAIMS_MIN_ID_DESC",
  BATCH_CLAIMS_MIN_INSURER_ID_ASC = "BATCH_CLAIMS_MIN_INSURER_ID_ASC",
  BATCH_CLAIMS_MIN_INSURER_ID_DESC = "BATCH_CLAIMS_MIN_INSURER_ID_DESC",
  BATCH_CLAIMS_MIN_OWNER_ID_ASC = "BATCH_CLAIMS_MIN_OWNER_ID_ASC",
  BATCH_CLAIMS_MIN_OWNER_ID_DESC = "BATCH_CLAIMS_MIN_OWNER_ID_DESC",
  BATCH_CLAIMS_MIN_PAID_DATE_ASC = "BATCH_CLAIMS_MIN_PAID_DATE_ASC",
  BATCH_CLAIMS_MIN_PAID_DATE_DESC = "BATCH_CLAIMS_MIN_PAID_DATE_DESC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_ASC = "BATCH_CLAIMS_MIN_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_DESC = "BATCH_CLAIMS_MIN_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MIN_PATIENT_ID_ASC = "BATCH_CLAIMS_MIN_PATIENT_ID_ASC",
  BATCH_CLAIMS_MIN_PATIENT_ID_DESC = "BATCH_CLAIMS_MIN_PATIENT_ID_DESC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_ASC = "BATCH_CLAIMS_MIN_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_DESC = "BATCH_CLAIMS_MIN_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MIN_UPDATED_AT_ASC = "BATCH_CLAIMS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIMS_MIN_UPDATED_AT_DESC = "BATCH_CLAIMS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIMS_SUM_ADM_TYPE_ASC = "BATCH_CLAIMS_SUM_ADM_TYPE_ASC",
  BATCH_CLAIMS_SUM_ADM_TYPE_DESC = "BATCH_CLAIMS_SUM_ADM_TYPE_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIMS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIMS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_SUM_BILL_TYPE_ASC = "BATCH_CLAIMS_SUM_BILL_TYPE_ASC",
  BATCH_CLAIMS_SUM_BILL_TYPE_DESC = "BATCH_CLAIMS_SUM_BILL_TYPE_DESC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_SUM_CREATED_AT_ASC = "BATCH_CLAIMS_SUM_CREATED_AT_ASC",
  BATCH_CLAIMS_SUM_CREATED_AT_DESC = "BATCH_CLAIMS_SUM_CREATED_AT_DESC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_ASC = "BATCH_CLAIMS_SUM_DATE_ADMIT_ASC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_DESC = "BATCH_CLAIMS_SUM_DATE_ADMIT_DESC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_SUM_ICN_ASC = "BATCH_CLAIMS_SUM_ICN_ASC",
  BATCH_CLAIMS_SUM_ICN_DESC = "BATCH_CLAIMS_SUM_ICN_DESC",
  BATCH_CLAIMS_SUM_ID_ASC = "BATCH_CLAIMS_SUM_ID_ASC",
  BATCH_CLAIMS_SUM_ID_DESC = "BATCH_CLAIMS_SUM_ID_DESC",
  BATCH_CLAIMS_SUM_INSURER_ID_ASC = "BATCH_CLAIMS_SUM_INSURER_ID_ASC",
  BATCH_CLAIMS_SUM_INSURER_ID_DESC = "BATCH_CLAIMS_SUM_INSURER_ID_DESC",
  BATCH_CLAIMS_SUM_OWNER_ID_ASC = "BATCH_CLAIMS_SUM_OWNER_ID_ASC",
  BATCH_CLAIMS_SUM_OWNER_ID_DESC = "BATCH_CLAIMS_SUM_OWNER_ID_DESC",
  BATCH_CLAIMS_SUM_PAID_DATE_ASC = "BATCH_CLAIMS_SUM_PAID_DATE_ASC",
  BATCH_CLAIMS_SUM_PAID_DATE_DESC = "BATCH_CLAIMS_SUM_PAID_DATE_DESC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_ASC = "BATCH_CLAIMS_SUM_PATIENT_DOB_ASC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_DESC = "BATCH_CLAIMS_SUM_PATIENT_DOB_DESC",
  BATCH_CLAIMS_SUM_PATIENT_ID_ASC = "BATCH_CLAIMS_SUM_PATIENT_ID_ASC",
  BATCH_CLAIMS_SUM_PATIENT_ID_DESC = "BATCH_CLAIMS_SUM_PATIENT_ID_DESC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_ASC = "BATCH_CLAIMS_SUM_PROVIDER_ID_ASC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_DESC = "BATCH_CLAIMS_SUM_PROVIDER_ID_DESC",
  BATCH_CLAIMS_SUM_UPDATED_AT_ASC = "BATCH_CLAIMS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIMS_SUM_UPDATED_AT_DESC = "BATCH_CLAIMS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_FILE_NAME_ASC = "BATCH_FILE_NAME_ASC",
  BATCH_FILE_NAME_DESC = "BATCH_FILE_NAME_DESC",
  BATCH_FILE_URL_ASC = "BATCH_FILE_URL_ASC",
  BATCH_FILE_URL_DESC = "BATCH_FILE_URL_DESC",
  BATCH_NAME_ASC = "BATCH_NAME_ASC",
  BATCH_NAME_DESC = "BATCH_NAME_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NATURAL = "NATURAL",
  OWNER_ID_ASC = "OWNER_ID_ASC",
  OWNER_ID_DESC = "OWNER_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]["input"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]["input"]>>;
};

export type BucketClaim = Node & {
  __typename?: "BucketClaim";
  /** Reads a single `AuditorBucket` that is related to this `BucketClaim`. */
  auditorBucket?: Maybe<AuditorBucket>;
  auditorBucketId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaim` that is related to this `BucketClaim`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  selectionMethod?: Maybe<Scalars["JSON"]["output"]>;
  status: AuditFindingClaimStatus;
  updatedAt: Scalars["Datetime"]["output"];
};

export type BucketClaimAggregates = {
  __typename?: "BucketClaimAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<BucketClaimDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `BucketClaim` object types. */
export type BucketClaimAggregatesFilter = {
  /** Distinct count aggregate over matching `BucketClaim` objects. */
  distinctCount?: InputMaybe<BucketClaimDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `BucketClaim` object to be included within the aggregate. */
  filter?: InputMaybe<BucketClaimFilter>;
};

/** The `auditorBucket` to be created by this mutation. */
export type BucketClaimAuditorBucketIdFkeyAuditorBucketCreateInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The `bucketClaim` to be created by this mutation. */
export type BucketClaimAuditorBucketIdFkeyBucketClaimCreateInput = {
  auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
  status?: InputMaybe<AuditFindingClaimStatus>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditorBucket` in the `BucketClaimInput` mutation. */
export type BucketClaimAuditorBucketIdFkeyInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorBucketAuditorBucketPkeyConnect>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorBucketNodeIdConnect>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<BucketClaimAuditorBucketIdFkeyAuditorBucketCreateInput>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<AuditorBucketAuditorBucketPkeyDelete>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<AuditorBucketNodeIdDelete>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingAuditorBucketPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `bucketClaim` in the `AuditorBucketInput` mutation. */
export type BucketClaimAuditorBucketIdFkeyInverseInput = {
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByBatchClaimId?: InputMaybe<
    Array<BucketClaimBucketClaimBatchClaimIdKeyConnect>
  >;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByBatchClaimIdAndAuditorBucketId?: InputMaybe<
    Array<BucketClaimBucketClaimBatchClaimIdAuditorBucketIdKeyConnect>
  >;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<BucketClaimBucketClaimPkeyConnect>>;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<BucketClaimNodeIdConnect>>;
  /** A `BucketClaimInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<BucketClaimAuditorBucketIdFkeyBucketClaimCreateInput>
  >;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByBatchClaimId?: InputMaybe<
    Array<BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimBatchClaimIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByBatchClaimIdAndAuditorBucketId?: InputMaybe<
    Array<BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimBatchClaimIdAuditorBucketIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyNodeIdUpdate>
  >;
};

/** The `bucketClaim` to be created by this mutation. */
export type BucketClaimBatchClaimIdFkeyBucketClaimCreateInput = {
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
  status?: InputMaybe<AuditFindingClaimStatus>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `BucketClaimInput` mutation. */
export type BucketClaimBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `bucketClaim` in the `BatchClaimInput` mutation. */
export type BucketClaimBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByBatchClaimId?: InputMaybe<BucketClaimBucketClaimBatchClaimIdKeyConnect>;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByBatchClaimIdAndAuditorBucketId?: InputMaybe<BucketClaimBucketClaimBatchClaimIdAuditorBucketIdKeyConnect>;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BucketClaimBucketClaimPkeyConnect>;
  /** The primary key(s) for `bucketClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BucketClaimNodeIdConnect>;
  /** A `BucketClaimInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<BucketClaimBatchClaimIdFkeyBucketClaimCreateInput>>;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByBatchClaimId?: InputMaybe<BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimBatchClaimIdKeyUpdate>;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByBatchClaimIdAndAuditorBucketId?: InputMaybe<BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimBatchClaimIdAuditorBucketIdKeyUpdate>;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `bucketClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<BatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyNodeIdUpdate>;
};

/** The fields on `bucketClaim` to look up the row to connect. */
export type BucketClaimBucketClaimBatchClaimIdAuditorBucketIdKeyConnect = {
  auditorBucketId: Scalars["UUID"]["input"];
  batchClaimId: Scalars["UUID"]["input"];
};

/** The fields on `bucketClaim` to look up the row to connect. */
export type BucketClaimBucketClaimBatchClaimIdKeyConnect = {
  batchClaimId: Scalars["UUID"]["input"];
};

/** The fields on `bucketClaim` to look up the row to connect. */
export type BucketClaimBucketClaimPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `BucketClaim` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type BucketClaimCondition = {
  /** Checks for equality with the object’s `auditorBucketId` field. */
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `selectionMethod` field. */
  selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<AuditFindingClaimStatus>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type BucketClaimDistinctCountAggregateFilter = {
  auditorBucketId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  selectionMethod?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type BucketClaimDistinctCountAggregates = {
  __typename?: "BucketClaimDistinctCountAggregates";
  /** Distinct count of auditorBucketId across the matching connection */
  auditorBucketId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of selectionMethod across the matching connection */
  selectionMethod?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `BucketClaim` object types. All fields are combined with a logical ‘and.’ */
export type BucketClaimFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<BucketClaimFilter>>;
  /** Filter by the object’s `auditorBucket` relation. */
  auditorBucket?: InputMaybe<AuditorBucketFilter>;
  /** Filter by the object’s `auditorBucketId` field. */
  auditorBucketId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<BucketClaimFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<BucketClaimFilter>>;
  /** Filter by the object’s `selectionMethod` field. */
  selectionMethod?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<AuditFindingClaimStatusFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `BucketClaim` for usage during aggregation. */
export enum BucketClaimGroupBy {
  AUDITOR_BUCKET_ID = "AUDITOR_BUCKET_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  SELECTION_METHOD = "SELECTION_METHOD",
  STATUS = "STATUS",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type BucketClaimHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `BucketClaim` aggregates. */
export type BucketClaimHavingInput = {
  AND?: InputMaybe<Array<BucketClaimHavingInput>>;
  OR?: InputMaybe<Array<BucketClaimHavingInput>>;
  average?: InputMaybe<BucketClaimHavingAverageInput>;
  distinctCount?: InputMaybe<BucketClaimHavingDistinctCountInput>;
  max?: InputMaybe<BucketClaimHavingMaxInput>;
  min?: InputMaybe<BucketClaimHavingMinInput>;
  stddevPopulation?: InputMaybe<BucketClaimHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<BucketClaimHavingStddevSampleInput>;
  sum?: InputMaybe<BucketClaimHavingSumInput>;
  variancePopulation?: InputMaybe<BucketClaimHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<BucketClaimHavingVarianceSampleInput>;
};

export type BucketClaimHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type BucketClaimHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `BucketClaim` */
export type BucketClaimInput = {
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
  status?: InputMaybe<AuditFindingClaimStatus>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type BucketClaimNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `bucketClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: AuditorBucketPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimBatchClaimIdAuditorBucketIdKeyUpdate =
  {
    auditorBucketId: Scalars["UUID"]["input"];
    batchClaimId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimBatchClaimIdKeyUpdate =
  {
    batchClaimId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyUsingBucketClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimBatchClaimIdAuditorBucketIdKeyUpdate =
  {
    auditorBucketId: Scalars["UUID"]["input"];
    batchClaimId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimBatchClaimIdKeyUpdate =
  {
    batchClaimId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch;
  };

/** The fields on `bucketClaim` to look up the row to update. */
export type BucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyUsingBucketClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `bucketClaim` being updated. */
    patch: updateBucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch;
  };

/** Represents an update to a `BucketClaim`. Fields that are set will be updated. */
export type BucketClaimPatch = {
  auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
  status?: InputMaybe<AuditFindingClaimStatus>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A connection to a list of `BucketClaim` values. */
export type BucketClaimsConnection = {
  __typename?: "BucketClaimsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<BucketClaimAggregates>;
  /** A list of edges which contains the `BucketClaim` and cursor to aid in pagination. */
  edges: Array<BucketClaimsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<BucketClaimAggregates>>;
  /** A list of `BucketClaim` objects. */
  nodes: Array<Maybe<BucketClaim>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BucketClaim` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `BucketClaim` values. */
export type BucketClaimsConnectiongroupedAggregatesArgs = {
  groupBy: Array<BucketClaimGroupBy>;
  having?: InputMaybe<BucketClaimHavingInput>;
};

/** A `BucketClaim` edge in the connection. */
export type BucketClaimsEdge = {
  __typename?: "BucketClaimsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `BucketClaim` at the end of the edge. */
  node?: Maybe<BucketClaim>;
};

/** Methods to use when ordering `BucketClaim`. */
export enum BucketClaimsOrderBy {
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ASSIGNEE_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__AUTHOR_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__CREATED_AT_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__DUE_DATE_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__FILTER_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__INSURER_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__NAME_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__PROVIDER_ID_DESC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_ASC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_ASC",
  AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_DESC = "AUDITOR_BUCKET_BY_AUDITOR_BUCKET_ID__UPDATED_AT_DESC",
  AUDITOR_BUCKET_ID_ASC = "AUDITOR_BUCKET_ID_ASC",
  AUDITOR_BUCKET_ID_DESC = "AUDITOR_BUCKET_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_ID_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_ID_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_ID_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_ID_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_METHOD_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_METHOD_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_COUNT_ASC = "AUDIT_SELECTION_METHODS_COUNT_ASC",
  AUDIT_SELECTION_METHODS_COUNT_DESC = "AUDIT_SELECTION_METHODS_COUNT_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_ID_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_METHOD_DESC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_MAX_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MAX_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_MAX_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MAX_ID_ASC = "AUDIT_SELECTION_METHODS_MAX_ID_ASC",
  AUDIT_SELECTION_METHODS_MAX_ID_DESC = "AUDIT_SELECTION_METHODS_MAX_ID_DESC",
  AUDIT_SELECTION_METHODS_MAX_METHOD_ASC = "AUDIT_SELECTION_METHODS_MAX_METHOD_ASC",
  AUDIT_SELECTION_METHODS_MAX_METHOD_DESC = "AUDIT_SELECTION_METHODS_MAX_METHOD_DESC",
  AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_MAX_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_MIN_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MIN_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_MIN_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_MIN_ID_ASC = "AUDIT_SELECTION_METHODS_MIN_ID_ASC",
  AUDIT_SELECTION_METHODS_MIN_ID_DESC = "AUDIT_SELECTION_METHODS_MIN_ID_DESC",
  AUDIT_SELECTION_METHODS_MIN_METHOD_ASC = "AUDIT_SELECTION_METHODS_MIN_METHOD_ASC",
  AUDIT_SELECTION_METHODS_MIN_METHOD_DESC = "AUDIT_SELECTION_METHODS_MIN_METHOD_DESC",
  AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_MIN_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_METHOD_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_SUM_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_SUM_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_SUM_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_SUM_ID_ASC = "AUDIT_SELECTION_METHODS_SUM_ID_ASC",
  AUDIT_SELECTION_METHODS_SUM_ID_DESC = "AUDIT_SELECTION_METHODS_SUM_ID_DESC",
  AUDIT_SELECTION_METHODS_SUM_METHOD_ASC = "AUDIT_SELECTION_METHODS_SUM_METHOD_ASC",
  AUDIT_SELECTION_METHODS_SUM_METHOD_DESC = "AUDIT_SELECTION_METHODS_SUM_METHOD_DESC",
  AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_SUM_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_METHOD_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_AUDIT_SELECTION_JOB_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_BUCKET_CLAIM_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_METHOD_DESC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_SELECTION_METHODS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SELECTION_METHOD_ASC = "SELECTION_METHOD_ASC",
  SELECTION_METHOD_DESC = "SELECTION_METHOD_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** All input for the `bucketClaimsUpdateStatus` mutation. */
export type BucketClaimsUpdateStatusInput = {
  bucketClaims?: InputMaybe<Array<InputMaybe<BucketClaimInput>>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `bucketClaimsUpdateStatus` mutation. */
export type BucketClaimsUpdateStatusPayload = {
  __typename?: "BucketClaimsUpdateStatusPayload";
  boolean?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Case = {
  __typename?: "Case";
  completed?: Maybe<Scalars["Boolean"]["output"]>;
  createdAt: Scalars["String"]["output"];
  files?: Maybe<Array<File>>;
  id: Scalars["UUID"]["output"];
  latestQuestion?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  queue?: Maybe<AskAutodorQueue>;
};

export type CasesInput = {
  queueId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Claim = {
  __typename?: "Claim";
  autodor?: Maybe<Autodor>;
  findings: Array<Finding>;
  icn: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  itemizedBillLines: Array<ItemizedBillLine>;
};

export enum ClaimComputationStrategyKind {
  CLAIM_LEVEL_PAYMENT_RATE = "CLAIM_LEVEL_PAYMENT_RATE",
  CLAIM_LINE_LEVEL_PAYMENT_RATE = "CLAIM_LINE_LEVEL_PAYMENT_RATE",
}

/** A filter to be used against ClaimComputationStrategyKind fields. All fields are combined with a logical ‘and.’ */
export type ClaimComputationStrategyKindFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ClaimComputationStrategyKind>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ClaimComputationStrategyKind>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ClaimComputationStrategyKind>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ClaimComputationStrategyKind>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ClaimComputationStrategyKind>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ClaimComputationStrategyKind>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ClaimComputationStrategyKind>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ClaimComputationStrategyKind>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ClaimComputationStrategyKind>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ClaimComputationStrategyKind>>;
};

export type ClaimIntakeInput = {
  claimNumber: Scalars["String"]["input"];
  documentUploadKind: DocumentUploadKind;
  fileName: Scalars["String"]["input"];
  fileType: Scalars["String"]["input"];
  insurerId: Scalars["UUID"]["input"];
};

export type ClaimIntakeResponse = {
  __typename?: "ClaimIntakeResponse";
  tags: Scalars["String"]["output"];
  url: Scalars["String"]["output"];
};

export type ClientSignOffClaimState = Node & {
  __typename?: "ClientSignOffClaimState";
  /** Reads a single `BatchClaim` that is related to this `ClientSignOffClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  clientSignOffState: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type ClientSignOffClaimStateAggregates = {
  __typename?: "ClientSignOffClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ClientSignOffClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ClientSignOffClaimState` object types. */
export type ClientSignOffClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `ClientSignOffClaimState` objects. */
  distinctCount?: InputMaybe<ClientSignOffClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ClientSignOffClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<ClientSignOffClaimStateFilter>;
};

/** The `clientSignOffClaimState` to be created by this mutation. */
export type ClientSignOffClaimStateBatchClaimIdFkClientSignOffClaimStateCreateInput =
  {
    batchClaimToBatchClaimId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInput>;
    clientSignOffState?: InputMaybe<Scalars["String"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** Input for the nested mutation of `batchClaim` in the `ClientSignOffClaimStateInput` mutation. */
export type ClientSignOffClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `clientSignOffClaimState` in the `BatchClaimInput` mutation. */
export type ClientSignOffClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `clientSignOffClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ClientSignOffClaimStateClientSignOffClaimStatePkeyConnect>
  >;
  /** The primary key(s) for `clientSignOffClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ClientSignOffClaimStateNodeIdConnect>>;
  /** A `ClientSignOffClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ClientSignOffClaimStateBatchClaimIdFkClientSignOffClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `clientSignOffClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingClientSignOffClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `clientSignOffClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The fields on `clientSignOffClaimState` to look up the row to connect. */
export type ClientSignOffClaimStateClientSignOffClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/**
 * A condition to be used against `ClientSignOffClaimState` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ClientSignOffClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `clientSignOffState` field. */
  clientSignOffState?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type ClientSignOffClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  clientSignOffState?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
};

export type ClientSignOffClaimStateDistinctCountAggregates = {
  __typename?: "ClientSignOffClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of clientSignOffState across the matching connection */
  clientSignOffState?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum ClientSignOffClaimStateEnum {
  CLIENT_NO_SIGN_OFF = "CLIENT_NO_SIGN_OFF",
  CLIENT_SIGN_OFF = "CLIENT_SIGN_OFF",
}

/** A filter to be used against ClientSignOffClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ClientSignOffClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ClientSignOffClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ClientSignOffClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ClientSignOffClaimStateEnum>>;
};

/** A filter to be used against `ClientSignOffClaimState` object types. All fields are combined with a logical ‘and.’ */
export type ClientSignOffClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ClientSignOffClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `clientSignOffState` field. */
  clientSignOffState?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ClientSignOffClaimStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ClientSignOffClaimStateFilter>>;
};

/** Grouping methods for `ClientSignOffClaimState` for usage during aggregation. */
export enum ClientSignOffClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CLIENT_SIGN_OFF_STATE = "CLIENT_SIGN_OFF_STATE",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
}

export type ClientSignOffClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ClientSignOffClaimState` aggregates. */
export type ClientSignOffClaimStateHavingInput = {
  AND?: InputMaybe<Array<ClientSignOffClaimStateHavingInput>>;
  OR?: InputMaybe<Array<ClientSignOffClaimStateHavingInput>>;
  average?: InputMaybe<ClientSignOffClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<ClientSignOffClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<ClientSignOffClaimStateHavingMaxInput>;
  min?: InputMaybe<ClientSignOffClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<ClientSignOffClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ClientSignOffClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<ClientSignOffClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<ClientSignOffClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ClientSignOffClaimStateHavingVarianceSampleInput>;
};

export type ClientSignOffClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ClientSignOffClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ClientSignOffClaimState` */
export type ClientSignOffClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInput>;
  clientSignOffState?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ClientSignOffClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `clientSignOffClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `clientSignOffClaimState` to look up the row to update. */
export type ClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkUsingClientSignOffClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `clientSignOffClaimState` being updated. */
    patch: updateClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `ClientSignOffClaimState`. Fields that are set will be updated. */
export type ClientSignOffClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInput>;
};

/** A connection to a list of `ClientSignOffClaimState` values. */
export type ClientSignOffClaimStatesConnection = {
  __typename?: "ClientSignOffClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ClientSignOffClaimStateAggregates>;
  /** A list of edges which contains the `ClientSignOffClaimState` and cursor to aid in pagination. */
  edges: Array<ClientSignOffClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ClientSignOffClaimStateAggregates>>;
  /** A list of `ClientSignOffClaimState` objects. */
  nodes: Array<Maybe<ClientSignOffClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ClientSignOffClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ClientSignOffClaimState` values. */
export type ClientSignOffClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ClientSignOffClaimStateGroupBy>;
  having?: InputMaybe<ClientSignOffClaimStateHavingInput>;
};

/** A `ClientSignOffClaimState` edge in the connection. */
export type ClientSignOffClaimStatesEdge = {
  __typename?: "ClientSignOffClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ClientSignOffClaimState` at the end of the edge. */
  node?: Maybe<ClientSignOffClaimState>;
};

/** Methods to use when ordering `ClientSignOffClaimState`. */
export enum ClientSignOffClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CLIENT_SIGN_OFF_STATE_ASC = "CLIENT_SIGN_OFF_STATE_ASC",
  CLIENT_SIGN_OFF_STATE_DESC = "CLIENT_SIGN_OFF_STATE_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

export type ConfirmFileUploadSuccessInput = {
  fileId: Scalars["UUID"]["input"];
  fileUploadId: Scalars["UUID"]["input"];
};

export type CreateAskAutodorQueueInput = {
  name: Scalars["String"]["input"];
};

/** All input for the create `AuditFinding` mutation. */
export type CreateAuditFindingInput = {
  /** The `AuditFinding` to be created by this mutation. */
  auditFinding: AuditFindingInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditFinding` mutation. */
export type CreateAuditFindingPayload = {
  __typename?: "CreateAuditFindingPayload";
  /** The `AuditFinding` that was created by this mutation. */
  auditFinding?: Maybe<AuditFinding>;
  /** An edge for our `AuditFinding`. May be used by Relay 1. */
  auditFindingEdge?: Maybe<AuditFindingsEdge>;
  /** Reads a single `AuditFindingJob` that is related to this `AuditFinding`. */
  auditFindingJob?: Maybe<AuditFindingJob>;
  /** Reads a single `AuditFindingRuleType` that is related to this `AuditFinding`. */
  auditFindingRuleTypeByAuditFindingRuleType?: Maybe<AuditFindingRuleType>;
  /** Reads a single `AuditorUser` that is related to this `AuditFinding`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `AuditFinding`. */
  batchClaim?: Maybe<BatchClaim>;
  /** Reads a single `BatchClaimLine` that is related to this `AuditFinding`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `AuditFinding` that is related to this `AuditFinding`. */
  previousVersion?: Maybe<AuditFinding>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AuditFinding` mutation. */
export type CreateAuditFindingPayloadauditFindingEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

/** All input for the create `AuditFindingReasonCode` mutation. */
export type CreateAuditFindingReasonCodeInput = {
  /** The `AuditFindingReasonCode` to be created by this mutation. */
  auditFindingReasonCode: AuditFindingReasonCodeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditFindingReasonCode` mutation. */
export type CreateAuditFindingReasonCodePayload = {
  __typename?: "CreateAuditFindingReasonCodePayload";
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReasonCode`. */
  auditFinding?: Maybe<AuditFinding>;
  /** The `AuditFindingReasonCode` that was created by this mutation. */
  auditFindingReasonCode?: Maybe<AuditFindingReasonCode>;
  /** An edge for our `AuditFindingReasonCode`. May be used by Relay 1. */
  auditFindingReasonCodeEdge?: Maybe<AuditFindingReasonCodesEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReasonCode`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReasonCode` that is related to this `AuditFindingReasonCode`. */
  reasonCode?: Maybe<ReasonCode>;
};

/** The output of our create `AuditFindingReasonCode` mutation. */
export type CreateAuditFindingReasonCodePayloadauditFindingReasonCodeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
  };

/** All input for the create `AuditFindingReview` mutation. */
export type CreateAuditFindingReviewInput = {
  /** The `AuditFindingReview` to be created by this mutation. */
  auditFindingReview: AuditFindingReviewInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditFindingReview` mutation. */
export type CreateAuditFindingReviewPayload = {
  __typename?: "CreateAuditFindingReviewPayload";
  /** The `AuditFindingReview` that was created by this mutation. */
  auditFindingReview?: Maybe<AuditFindingReview>;
  /** An edge for our `AuditFindingReview`. May be used by Relay 1. */
  auditFindingReviewEdge?: Maybe<AuditFindingReviewsEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReview`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReview`. */
  original?: Maybe<AuditFinding>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReview`. */
  review?: Maybe<AuditFinding>;
};

/** The output of our create `AuditFindingReview` mutation. */
export type CreateAuditFindingReviewPayloadauditFindingReviewEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditFindingReviewsOrderBy>>;
};

export type CreateAuditFindingValuesInput = {
  auditFindingRuleType: Scalars["String"]["input"];
  autodorFindingId?: InputMaybe<Scalars["String"]["input"]>;
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  confidence: Scalars["BigFloat"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CreateAuditFindingsPayload = {
  __typename?: "CreateAuditFindingsPayload";
  auditFindings: Array<AuditFinding>;
};

/** All input for the create `AuditorBucketAssignee` mutation. */
export type CreateAuditorBucketAssigneeInput = {
  /** The `AuditorBucketAssignee` to be created by this mutation. */
  auditorBucketAssignee: AuditorBucketAssigneeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditorBucketAssignee` mutation. */
export type CreateAuditorBucketAssigneePayload = {
  __typename?: "CreateAuditorBucketAssigneePayload";
  /** Reads a single `AuditorBucket` that is related to this `AuditorBucketAssignee`. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** The `AuditorBucketAssignee` that was created by this mutation. */
  auditorBucketAssignee?: Maybe<AuditorBucketAssignee>;
  /** An edge for our `AuditorBucketAssignee`. May be used by Relay 1. */
  auditorBucketAssigneeEdge?: Maybe<AuditorBucketAssigneesEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditorBucketAssignee`. */
  auditorUser?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AuditorBucketAssignee` mutation. */
export type CreateAuditorBucketAssigneePayloadauditorBucketAssigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorBucketAssigneesOrderBy>>;
};

/** All input for the create `AuditorBucket` mutation. */
export type CreateAuditorBucketInput = {
  /** The `AuditorBucket` to be created by this mutation. */
  auditorBucket: AuditorBucketInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditorBucket` mutation. */
export type CreateAuditorBucketPayload = {
  __typename?: "CreateAuditorBucketPayload";
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  assignee?: Maybe<AuditorUser>;
  /** The `AuditorBucket` that was created by this mutation. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** An edge for our `AuditorBucket`. May be used by Relay 1. */
  auditorBucketEdge?: Maybe<AuditorBucketsEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Insurer` that is related to this `AuditorBucket`. */
  insurer?: Maybe<Insurer>;
  /** Reads a single `Provider` that is related to this `AuditorBucket`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AuditorBucket` mutation. */
export type CreateAuditorBucketPayloadauditorBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

/** All input for the create `AuditorUser` mutation. */
export type CreateAuditorUserInput = {
  /** The `AuditorUser` to be created by this mutation. */
  auditorUser: AuditorUserInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `AuditorUser` mutation. */
export type CreateAuditorUserPayload = {
  __typename?: "CreateAuditorUserPayload";
  /** The `AuditorUser` that was created by this mutation. */
  auditorUser?: Maybe<AuditorUser>;
  /** An edge for our `AuditorUser`. May be used by Relay 1. */
  auditorUserEdge?: Maybe<AuditorUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Provider` that is related to this `AuditorUser`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AuditorUser` mutation. */
export type CreateAuditorUserPayloadauditorUserEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorUsersOrderBy>>;
};

/** All input for the create `BatchClaimAssignee` mutation. */
export type CreateBatchClaimAssigneeInput = {
  /** The `BatchClaimAssignee` to be created by this mutation. */
  batchClaimAssignee: BatchClaimAssigneeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `BatchClaimAssignee` mutation. */
export type CreateBatchClaimAssigneePayload = {
  __typename?: "CreateBatchClaimAssigneePayload";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimAssignee`. */
  auditorUser?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `BatchClaimAssignee`. */
  batchClaim?: Maybe<BatchClaim>;
  /** The `BatchClaimAssignee` that was created by this mutation. */
  batchClaimAssignee?: Maybe<BatchClaimAssignee>;
  /** An edge for our `BatchClaimAssignee`. May be used by Relay 1. */
  batchClaimAssigneeEdge?: Maybe<BatchClaimAssigneesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BatchClaimAssignee` mutation. */
export type CreateBatchClaimAssigneePayloadbatchClaimAssigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<BatchClaimAssigneesOrderBy>>;
};

/** All input for the create `BatchClaimDueDate` mutation. */
export type CreateBatchClaimDueDateInput = {
  /** The `BatchClaimDueDate` to be created by this mutation. */
  batchClaimDueDate: BatchClaimDueDateInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `BatchClaimDueDate` mutation. */
export type CreateBatchClaimDueDatePayload = {
  __typename?: "CreateBatchClaimDueDatePayload";
  /** Reads a single `BatchClaim` that is related to this `BatchClaimDueDate`. */
  batchClaim?: Maybe<BatchClaim>;
  /** The `BatchClaimDueDate` that was created by this mutation. */
  batchClaimDueDate?: Maybe<BatchClaimDueDate>;
  /** An edge for our `BatchClaimDueDate`. May be used by Relay 1. */
  batchClaimDueDateEdge?: Maybe<BatchClaimDueDatesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BatchClaimDueDate` mutation. */
export type CreateBatchClaimDueDatePayloadbatchClaimDueDateEdgeArgs = {
  orderBy?: InputMaybe<Array<BatchClaimDueDatesOrderBy>>;
};

/** All input for the create `BatchClaimLineItemAudit` mutation. */
export type CreateBatchClaimLineItemAuditInput = {
  /** The `BatchClaimLineItemAudit` to be created by this mutation. */
  batchClaimLineItemAudit: BatchClaimLineItemAuditInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `BatchClaimLineItemAudit` mutation. */
export type CreateBatchClaimLineItemAuditPayload = {
  __typename?: "CreateBatchClaimLineItemAuditPayload";
  /** Reads a single `AuditFinding` that is related to this `BatchClaimLineItemAudit`. */
  auditFinding?: Maybe<AuditFinding>;
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItemAudit`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemAudit`. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  /** The `BatchClaimLineItemAudit` that was created by this mutation. */
  batchClaimLineItemAudit?: Maybe<BatchClaimLineItemAudit>;
  /** An edge for our `BatchClaimLineItemAudit`. May be used by Relay 1. */
  batchClaimLineItemAuditEdge?: Maybe<BatchClaimLineItemAuditsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BatchClaimLineItemAudit` mutation. */
export type CreateBatchClaimLineItemAuditPayloadbatchClaimLineItemAuditEdgeArgs =
  {
    orderBy?: InputMaybe<Array<BatchClaimLineItemAuditsOrderBy>>;
  };

/** All input for the create `BatchClaimLineItem` mutation. */
export type CreateBatchClaimLineItemInput = {
  /** The `BatchClaimLineItem` to be created by this mutation. */
  batchClaimLineItem: BatchClaimLineItemInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the create `BatchClaimLineItemNnIbLine` mutation. */
export type CreateBatchClaimLineItemNnIbLineInput = {
  /** The `BatchClaimLineItemNnIbLine` to be created by this mutation. */
  batchClaimLineItemNnIbLine: BatchClaimLineItemNnIbLineInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `BatchClaimLineItemNnIbLine` mutation. */
export type CreateBatchClaimLineItemNnIbLinePayload = {
  __typename?: "CreateBatchClaimLineItemNnIbLinePayload";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItemNnIbLine`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  /** The `BatchClaimLineItemNnIbLine` that was created by this mutation. */
  batchClaimLineItemNnIbLine?: Maybe<BatchClaimLineItemNnIbLine>;
  /** An edge for our `BatchClaimLineItemNnIbLine`. May be used by Relay 1. */
  batchClaimLineItemNnIbLineEdge?: Maybe<BatchClaimLineItemNnIbLinesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `NnIbLine` that is related to this `BatchClaimLineItemNnIbLine`. */
  nnIbLine?: Maybe<NnIbLine>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BatchClaimLineItemNnIbLine` mutation. */
export type CreateBatchClaimLineItemNnIbLinePayloadbatchClaimLineItemNnIbLineEdgeArgs =
  {
    orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
  };

/** The output of our create `BatchClaimLineItem` mutation. */
export type CreateBatchClaimLineItemPayload = {
  __typename?: "CreateBatchClaimLineItemPayload";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItem`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `BatchClaimLineItem`. */
  batchClaim?: Maybe<BatchClaim>;
  /** Reads a single `BatchClaimLine` that is related to this `BatchClaimLineItem`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  /** The `BatchClaimLineItem` that was created by this mutation. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  /** An edge for our `BatchClaimLineItem`. May be used by Relay 1. */
  batchClaimLineItemEdge?: Maybe<BatchClaimLineItemsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BatchClaimLineItem` mutation. */
export type CreateBatchClaimLineItemPayloadbatchClaimLineItemEdgeArgs = {
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

export type CreateBatchClaimLineItemValuesInput = {
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart: Scalars["Datetime"]["input"];
  description: Scalars["String"]["input"];
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  nnIbLineValues?: InputMaybe<Array<CreateIbLineValueInput>>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  unitCharge: Scalars["BigFloat"]["input"];
  units: Scalars["Int"]["input"];
};

/** All input for the create `BucketClaim` mutation. */
export type CreateBucketClaimInput = {
  /** The `BucketClaim` to be created by this mutation. */
  bucketClaim: BucketClaimInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our create `BucketClaim` mutation. */
export type CreateBucketClaimPayload = {
  __typename?: "CreateBucketClaimPayload";
  /** Reads a single `AuditorBucket` that is related to this `BucketClaim`. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** Reads a single `BatchClaim` that is related to this `BucketClaim`. */
  batchClaim?: Maybe<BatchClaim>;
  /** The `BucketClaim` that was created by this mutation. */
  bucketClaim?: Maybe<BucketClaim>;
  /** An edge for our `BucketClaim`. May be used by Relay 1. */
  bucketClaimEdge?: Maybe<BucketClaimsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `BucketClaim` mutation. */
export type CreateBucketClaimPayloadbucketClaimEdgeArgs = {
  orderBy?: InputMaybe<Array<BucketClaimsOrderBy>>;
};

export type CreateCaseInput = {
  fileIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  files?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name: Scalars["String"]["input"];
  queueId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** All input for the create `ClientSignOffClaimState` mutation. */
export type CreateClientSignOffClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ClientSignOffClaimState` to be created by this mutation. */
  clientSignOffClaimState: ClientSignOffClaimStateInput;
};

/** The output of our create `ClientSignOffClaimState` mutation. */
export type CreateClientSignOffClaimStatePayload = {
  __typename?: "CreateClientSignOffClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `ClientSignOffClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `ClientSignOffClaimState` that was created by this mutation. */
  clientSignOffClaimState?: Maybe<ClientSignOffClaimState>;
  /** An edge for our `ClientSignOffClaimState`. May be used by Relay 1. */
  clientSignOffClaimStateEdge?: Maybe<ClientSignOffClaimStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ClientSignOffClaimState` mutation. */
export type CreateClientSignOffClaimStatePayloadclientSignOffClaimStateEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ClientSignOffClaimStatesOrderBy>>;
  };

export type CreateDirectoryInput = {
  name: Scalars["String"]["input"];
  parentDirectoryId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** All input for the create `DisputedFinding` mutation. */
export type CreateDisputedFindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DisputedFinding` to be created by this mutation. */
  disputedFinding: DisputedFindingInput;
};

/** The output of our create `DisputedFinding` mutation. */
export type CreateDisputedFindingPayload = {
  __typename?: "CreateDisputedFindingPayload";
  /** Reads a single `AuditorUser` that is related to this `DisputedFinding`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `DisputedFinding`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `DisputedFinding` that was created by this mutation. */
  disputedFinding?: Maybe<DisputedFinding>;
  /** An edge for our `DisputedFinding`. May be used by Relay 1. */
  disputedFindingEdge?: Maybe<DisputedFindingsEdge>;
  /** Reads a single `Provider` that is related to this `DisputedFinding`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DisputedFinding` mutation. */
export type CreateDisputedFindingPayloaddisputedFindingEdgeArgs = {
  orderBy?: InputMaybe<Array<DisputedFindingsOrderBy>>;
};

/** All input for the `createDisputedFindings` mutation. */
export type CreateDisputedFindingsInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The output of our `createDisputedFindings` mutation. */
export type CreateDisputedFindingsPayload = {
  __typename?: "CreateDisputedFindingsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  disputedFindings?: Maybe<Array<Maybe<DisputedFinding>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `DocumentRequestCreated` mutation. */
export type CreateDocumentRequestCreatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DocumentRequestCreated` to be created by this mutation. */
  documentRequestCreated: DocumentRequestCreatedInput;
};

/** The output of our create `DocumentRequestCreated` mutation. */
export type CreateDocumentRequestCreatedPayload = {
  __typename?: "CreateDocumentRequestCreatedPayload";
  /** Reads a single `MessageCreated` that is related to this `DocumentRequestCreated`. */
  _deprecatedMessageCreated?: Maybe<MessageCreated>;
  /** Reads a single `BatchClaim` that is related to this `DocumentRequestCreated`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `DocumentRequestCreated` that was created by this mutation. */
  documentRequestCreated?: Maybe<DocumentRequestCreated>;
  /** An edge for our `DocumentRequestCreated`. May be used by Relay 1. */
  documentRequestCreatedEdge?: Maybe<DocumentRequestCreatedsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DocumentRequestCreated` mutation. */
export type CreateDocumentRequestCreatedPayloaddocumentRequestCreatedEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DocumentRequestCreatedsOrderBy>>;
  };

/** All input for the create `DocumentUploadCreated` mutation. */
export type CreateDocumentUploadCreatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DocumentUploadCreated` to be created by this mutation. */
  documentUploadCreated: DocumentUploadCreatedInput;
};

/** The output of our create `DocumentUploadCreated` mutation. */
export type CreateDocumentUploadCreatedPayload = {
  __typename?: "CreateDocumentUploadCreatedPayload";
  /** Reads a single `MessageCreated` that is related to this `DocumentUploadCreated`. */
  _deprecatedMessage?: Maybe<MessageCreated>;
  /** Reads a single `AuditorUser` that is related to this `DocumentUploadCreated`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `DocumentUploadCreated`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `DocumentUploadCreated` that was created by this mutation. */
  documentUploadCreated?: Maybe<DocumentUploadCreated>;
  /** An edge for our `DocumentUploadCreated`. May be used by Relay 1. */
  documentUploadCreatedEdge?: Maybe<DocumentUploadCreatedsEdge>;
  /** Reads a single `Provider` that is related to this `DocumentUploadCreated`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DocumentUploadCreated` mutation. */
export type CreateDocumentUploadCreatedPayloaddocumentUploadCreatedEdgeArgs = {
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

/** All input for the `createDocumentationRequests` mutation. */
export type CreateDocumentationRequestsInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `createDocumentationRequests` mutation. */
export type CreateDocumentationRequestsPayload = {
  __typename?: "CreateDocumentationRequestsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  documentRequestCreateds?: Maybe<Array<Maybe<DocumentRequestCreated>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `DocumentationSelectionSet` mutation. */
export type CreateDocumentationSelectionSetInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `DocumentationSelectionSet` to be created by this mutation. */
  documentationSelectionSet: DocumentationSelectionSetInput;
};

/** The output of our create `DocumentationSelectionSet` mutation. */
export type CreateDocumentationSelectionSetPayload = {
  __typename?: "CreateDocumentationSelectionSetPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `DocumentationSelectionSet` that was created by this mutation. */
  documentationSelectionSet?: Maybe<DocumentationSelectionSet>;
  /** An edge for our `DocumentationSelectionSet`. May be used by Relay 1. */
  documentationSelectionSetEdge?: Maybe<DocumentationSelectionSetsEdge>;
  /** Reads a single `Provider` that is related to this `DocumentationSelectionSet`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DocumentationSelectionSet` mutation. */
export type CreateDocumentationSelectionSetPayloaddocumentationSelectionSetEdgeArgs =
  {
    orderBy?: InputMaybe<Array<DocumentationSelectionSetsOrderBy>>;
  };

export type CreateIbLineFindingsInput = {
  authorId: Scalars["UUID"]["input"];
  batchClaimId: Scalars["UUID"]["input"];
  batchClaimLineId: Scalars["UUID"]["input"];
  values: Array<CreateIbLineFindingsInputValue>;
};

export type CreateIbLineFindingsInputValue = {
  auditFindingValues: CreateAuditFindingValuesInput;
  batchClaimLineItemValues: CreateBatchClaimLineItemValuesInput;
};

export type CreateIbLineValueInput = {
  fingerprint: Scalars["UUID"]["input"];
  s3Key: Scalars["String"]["input"];
};

/** All input for the create `InsurerUser` mutation. */
export type CreateInsurerUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `InsurerUser` to be created by this mutation. */
  insurerUser: InsurerUserInput;
};

/** The output of our create `InsurerUser` mutation. */
export type CreateInsurerUserPayload = {
  __typename?: "CreateInsurerUserPayload";
  /** Reads a single `AuditorUser` that is related to this `InsurerUser`. */
  auditorUser?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Insurer` that is related to this `InsurerUser`. */
  insurer?: Maybe<Insurer>;
  /** The `InsurerUser` that was created by this mutation. */
  insurerUser?: Maybe<InsurerUser>;
  /** An edge for our `InsurerUser`. May be used by Relay 1. */
  insurerUserEdge?: Maybe<InsurerUsersEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `InsurerUser` mutation. */
export type CreateInsurerUserPayloadinsurerUserEdgeArgs = {
  orderBy?: InputMaybe<Array<InsurerUsersOrderBy>>;
};

/** All input for the create `InvoiceClaimState` mutation. */
export type CreateInvoiceClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `InvoiceClaimState` to be created by this mutation. */
  invoiceClaimState: InvoiceClaimStateInput;
};

/** The output of our create `InvoiceClaimState` mutation. */
export type CreateInvoiceClaimStatePayload = {
  __typename?: "CreateInvoiceClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `InvoiceClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `InvoiceClaimState` that was created by this mutation. */
  invoiceClaimState?: Maybe<InvoiceClaimState>;
  /** An edge for our `InvoiceClaimState`. May be used by Relay 1. */
  invoiceClaimStateEdge?: Maybe<InvoiceClaimStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `InvoiceClaimState` mutation. */
export type CreateInvoiceClaimStatePayloadinvoiceClaimStateEdgeArgs = {
  orderBy?: InputMaybe<Array<InvoiceClaimStatesOrderBy>>;
};

/** All input for the create `MessageCreated` mutation. */
export type CreateMessageCreatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `MessageCreated` to be created by this mutation. */
  messageCreated: MessageCreatedInput;
};

/** The output of our create `MessageCreated` mutation. */
export type CreateMessageCreatedPayload = {
  __typename?: "CreateMessageCreatedPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `DocumentRequestCreated` that is related to this `MessageCreated`. */
  documentRequestCreated?: Maybe<DocumentRequestCreated>;
  /** Reads a single `DocumentUploadCreated` that is related to this `MessageCreated`. */
  documentUploadCreated?: Maybe<DocumentUploadCreated>;
  /** The `MessageCreated` that was created by this mutation. */
  messageCreated?: Maybe<MessageCreated>;
  /** An edge for our `MessageCreated`. May be used by Relay 1. */
  messageCreatedEdge?: Maybe<MessageCreatedsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuditorUser` that is related to this `MessageCreated`. */
  senderUser?: Maybe<AuditorUser>;
};

/** The output of our create `MessageCreated` mutation. */
export type CreateMessageCreatedPayloadmessageCreatedEdgeArgs = {
  orderBy?: InputMaybe<Array<MessageCreatedsOrderBy>>;
};

/** All input for the create `MessageSent` mutation. */
export type CreateMessageSentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `MessageSent` to be created by this mutation. */
  messageSent: MessageSentInput;
};

/** The output of our create `MessageSent` mutation. */
export type CreateMessageSentPayload = {
  __typename?: "CreateMessageSentPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `MessageCreated` that is related to this `MessageSent`. */
  message?: Maybe<MessageCreated>;
  /** The `MessageSent` that was created by this mutation. */
  messageSent?: Maybe<MessageSent>;
  /** An edge for our `MessageSent`. May be used by Relay 1. */
  messageSentEdge?: Maybe<MessageSentsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `AuditorUser` that is related to this `MessageSent`. */
  recipientUser?: Maybe<AuditorUser>;
  /** Reads a single `MessageSent` that is related to this `MessageSent`. */
  responseToMessageSent?: Maybe<MessageSent>;
  /** Reads a single `MessageSent` that is related to this `MessageSent`. */
  seedMessageSent?: Maybe<MessageSent>;
};

/** The output of our create `MessageSent` mutation. */
export type CreateMessageSentPayloadmessageSentEdgeArgs = {
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

/** All input for the create `NegotiationClaimState` mutation. */
export type CreateNegotiationClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `NegotiationClaimState` to be created by this mutation. */
  negotiationClaimState: NegotiationClaimStateInput;
};

/** The output of our create `NegotiationClaimState` mutation. */
export type CreateNegotiationClaimStatePayload = {
  __typename?: "CreateNegotiationClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `NegotiationClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `NegotiationClaimState` that was created by this mutation. */
  negotiationClaimState?: Maybe<NegotiationClaimState>;
  /** An edge for our `NegotiationClaimState`. May be used by Relay 1. */
  negotiationClaimStateEdge?: Maybe<NegotiationClaimStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `NegotiationClaimState` mutation. */
export type CreateNegotiationClaimStatePayloadnegotiationClaimStateEdgeArgs = {
  orderBy?: InputMaybe<Array<NegotiationClaimStatesOrderBy>>;
};

/** All input for the create `NnIbLine` mutation. */
export type CreateNnIbLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `NnIbLine` to be created by this mutation. */
  nnIbLine: NnIbLineInput;
};

/** The output of our create `NnIbLine` mutation. */
export type CreateNnIbLinePayload = {
  __typename?: "CreateNnIbLinePayload";
  /** Reads a single `AuditorUser` that is related to this `NnIbLine`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `NnIbLine` that was created by this mutation. */
  nnIbLine?: Maybe<NnIbLine>;
  /** An edge for our `NnIbLine`. May be used by Relay 1. */
  nnIbLineEdge?: Maybe<NnIbLinesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `NnIbLine` mutation. */
export type CreateNnIbLinePayloadnnIbLineEdgeArgs = {
  orderBy?: InputMaybe<Array<NnIbLinesOrderBy>>;
};

/** All input for the create `OnHoldClaimState` mutation. */
export type CreateOnHoldClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `OnHoldClaimState` to be created by this mutation. */
  onHoldClaimState: OnHoldClaimStateInput;
};

/** The output of our create `OnHoldClaimState` mutation. */
export type CreateOnHoldClaimStatePayload = {
  __typename?: "CreateOnHoldClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `OnHoldClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `OnHoldClaimState` that was created by this mutation. */
  onHoldClaimState?: Maybe<OnHoldClaimState>;
  /** An edge for our `OnHoldClaimState`. May be used by Relay 1. */
  onHoldClaimStateEdge?: Maybe<OnHoldClaimStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `OnHoldClaimState` mutation. */
export type CreateOnHoldClaimStatePayloadonHoldClaimStateEdgeArgs = {
  orderBy?: InputMaybe<Array<OnHoldClaimStatesOrderBy>>;
};

/** All input for the create `ProviderSignOffClaimState` mutation. */
export type CreateProviderSignOffClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ProviderSignOffClaimState` to be created by this mutation. */
  providerSignOffClaimState: ProviderSignOffClaimStateInput;
};

/** The output of our create `ProviderSignOffClaimState` mutation. */
export type CreateProviderSignOffClaimStatePayload = {
  __typename?: "CreateProviderSignOffClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `ProviderSignOffClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `ProviderSignOffClaimState` that was created by this mutation. */
  providerSignOffClaimState?: Maybe<ProviderSignOffClaimState>;
  /** An edge for our `ProviderSignOffClaimState`. May be used by Relay 1. */
  providerSignOffClaimStateEdge?: Maybe<ProviderSignOffClaimStatesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ProviderSignOffClaimState` mutation. */
export type CreateProviderSignOffClaimStatePayloadproviderSignOffClaimStateEdgeArgs =
  {
    orderBy?: InputMaybe<Array<ProviderSignOffClaimStatesOrderBy>>;
  };

/** All input for the create `ProviderUserFacility` mutation. */
export type CreateProviderUserFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ProviderUserFacility` to be created by this mutation. */
  providerUserFacility: ProviderUserFacilityInput;
};

/** The output of our create `ProviderUserFacility` mutation. */
export type CreateProviderUserFacilityPayload = {
  __typename?: "CreateProviderUserFacilityPayload";
  /** Reads a single `AuditorUser` that is related to this `ProviderUserFacility`. */
  auditorUser?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Provider` that is related to this `ProviderUserFacility`. */
  provider?: Maybe<Provider>;
  /** The `ProviderUserFacility` that was created by this mutation. */
  providerUserFacility?: Maybe<ProviderUserFacility>;
  /** An edge for our `ProviderUserFacility`. May be used by Relay 1. */
  providerUserFacilityEdge?: Maybe<ProviderUserFacilitiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ProviderUserFacility` mutation. */
export type CreateProviderUserFacilityPayloadproviderUserFacilityEdgeArgs = {
  orderBy?: InputMaybe<Array<ProviderUserFacilitiesOrderBy>>;
};

/** All input for the create `Queue` mutation. */
export type CreateQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `Queue` to be created by this mutation. */
  queue: QueueInput;
};

/** The output of our create `Queue` mutation. */
export type CreateQueuePayload = {
  __typename?: "CreateQueuePayload";
  /** Reads a single `AuditorUser` that is related to this `Queue`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Queue` that was created by this mutation. */
  queue?: Maybe<Queue>;
  /** An edge for our `Queue`. May be used by Relay 1. */
  queueEdge?: Maybe<QueuesEdge>;
};

/** The output of our create `Queue` mutation. */
export type CreateQueuePayloadqueueEdgeArgs = {
  orderBy?: InputMaybe<Array<QueuesOrderBy>>;
};

/** All input for the `createReportedClaims` mutation. */
export type CreateReportedClaimsInput = {
  claims?: InputMaybe<Array<InputMaybe<ReportedClaimInput>>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `createReportedClaims` mutation. */
export type CreateReportedClaimsPayload = {
  __typename?: "CreateReportedClaimsPayload";
  boolean?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createReportedFindings` mutation. */
export type CreateReportedFindingsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  findings?: InputMaybe<Array<InputMaybe<ReportedFindingInput>>>;
};

/** The output of our `createReportedFindings` mutation. */
export type CreateReportedFindingsPayload = {
  __typename?: "CreateReportedFindingsPayload";
  boolean?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `ReviewedAuditFinding` mutation. */
export type CreateReviewedAuditFindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `ReviewedAuditFinding` to be created by this mutation. */
  reviewedAuditFinding: ReviewedAuditFindingInput;
};

/** The output of our create `ReviewedAuditFinding` mutation. */
export type CreateReviewedAuditFindingPayload = {
  __typename?: "CreateReviewedAuditFindingPayload";
  /** Reads a single `AuditFinding` that is related to this `ReviewedAuditFinding`. */
  auditFinding?: Maybe<AuditFinding>;
  /** Reads a single `AuditorUser` that is related to this `ReviewedAuditFinding`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReviewedAuditFinding` that was created by this mutation. */
  reviewedAuditFinding?: Maybe<ReviewedAuditFinding>;
  /** An edge for our `ReviewedAuditFinding`. May be used by Relay 1. */
  reviewedAuditFindingEdge?: Maybe<ReviewedAuditFindingsEdge>;
};

/** The output of our create `ReviewedAuditFinding` mutation. */
export type CreateReviewedAuditFindingPayloadreviewedAuditFindingEdgeArgs = {
  orderBy?: InputMaybe<Array<ReviewedAuditFindingsOrderBy>>;
};

/** All input for the create `S3AuditDocumentation` mutation. */
export type CreateS3AuditDocumentationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `S3AuditDocumentation` to be created by this mutation. */
  s3AuditDocumentation: S3AuditDocumentationInput;
};

/** The output of our create `S3AuditDocumentation` mutation. */
export type CreateS3AuditDocumentationPayload = {
  __typename?: "CreateS3AuditDocumentationPayload";
  /** Reads a single `BatchClaim` that is related to this `S3AuditDocumentation`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `S3AuditDocumentation` that was created by this mutation. */
  s3AuditDocumentation?: Maybe<S3AuditDocumentation>;
  /** An edge for our `S3AuditDocumentation`. May be used by Relay 1. */
  s3AuditDocumentationEdge?: Maybe<S3AuditDocumentationsEdge>;
};

/** The output of our create `S3AuditDocumentation` mutation. */
export type CreateS3AuditDocumentationPayloads3AuditDocumentationEdgeArgs = {
  orderBy?: InputMaybe<Array<S3AuditDocumentationsOrderBy>>;
};

export type CreateUbClaimFindingsInput = {
  auditFindingValues: CreateAuditFindingValuesInput;
  authorId: Scalars["UUID"]["input"];
  batchClaimIds: Array<Scalars["UUID"]["input"]>;
};

export type CreateUbClaimLineFindingsInput = {
  auditFindingValues: CreateAuditFindingValuesInput;
  authorId: Scalars["UUID"]["input"];
  batchClaimId: Scalars["UUID"]["input"];
  batchClaimLineIds: Array<Scalars["UUID"]["input"]>;
};

export type CreateUserInput = {
  email: Scalars["String"]["input"];
  facilities?: InputMaybe<Array<Scalars["String"]["input"]>>;
  firstName: Scalars["String"]["input"];
  insurers?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  phoneNumber: Scalars["String"]["input"];
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  userType: UserTypeEnum;
  username: Scalars["String"]["input"];
};

/** All input for the create `UserInviteCreated` mutation. */
export type CreateUserInviteCreatedInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `UserInviteCreated` to be created by this mutation. */
  userInviteCreated: UserInviteCreatedInput;
};

/** The output of our create `UserInviteCreated` mutation. */
export type CreateUserInviteCreatedPayload = {
  __typename?: "CreateUserInviteCreatedPayload";
  /** Reads a single `AuditorUser` that is related to this `UserInviteCreated`. */
  auditorUser?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `MessageCreated` that is related to this `UserInviteCreated`. */
  message?: Maybe<MessageCreated>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserInviteCreated` that was created by this mutation. */
  userInviteCreated?: Maybe<UserInviteCreated>;
  /** An edge for our `UserInviteCreated`. May be used by Relay 1. */
  userInviteCreatedEdge?: Maybe<UserInviteCreatedsEdge>;
};

/** The output of our create `UserInviteCreated` mutation. */
export type CreateUserInviteCreatedPayloaduserInviteCreatedEdgeArgs = {
  orderBy?: InputMaybe<Array<UserInviteCreatedsOrderBy>>;
};

export type CreateUserOutput = {
  __typename?: "CreateUserOutput";
  user: AuditorUser;
};

/** All input for the create `WorkflowClaimState` mutation. */
export type CreateWorkflowClaimStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The `WorkflowClaimState` to be created by this mutation. */
  workflowClaimState: WorkflowClaimStateInput;
};

/** The output of our create `WorkflowClaimState` mutation. */
export type CreateWorkflowClaimStatePayload = {
  __typename?: "CreateWorkflowClaimStatePayload";
  /** Reads a single `BatchClaim` that is related to this `WorkflowClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `WorkflowClaimState` that was created by this mutation. */
  workflowClaimState?: Maybe<WorkflowClaimState>;
  /** An edge for our `WorkflowClaimState`. May be used by Relay 1. */
  workflowClaimStateEdge?: Maybe<WorkflowClaimStatesEdge>;
};

/** The output of our create `WorkflowClaimState` mutation. */
export type CreateWorkflowClaimStatePayloadworkflowClaimStateEdgeArgs = {
  orderBy?: InputMaybe<Array<WorkflowClaimStatesOrderBy>>;
};

export type Csv = {
  __typename?: "Csv";
  header?: Maybe<CsvRow>;
  id: Scalars["UUID"]["output"];
  rows: Array<Scalars["JSON"]["output"]>;
  s3Key: Scalars["String"]["output"];
};

export type CsvRow = {
  __typename?: "CsvRow";
  values: Array<Maybe<Scalars["String"]["output"]>>;
};

/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Date"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Date"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Date"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Date"]["input"]>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]["input"]>>;
};

/** A filter to be used against Datetime List fields. All fields are combined with a logical ‘and.’ */
export type DatetimeListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars["Datetime"]["input"]>>
  >;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars["Datetime"]["input"]>>
  >;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
};

/**
 * # Overview
 * Maps the default strategy to use for a given batch claim based the insurer for the claim.
 * This table represents the beginning of a data model based around user settings, where the user (in this case the payer), could indicate something like their preferred claim computation strategy.
 */
export type DefaultStrategyByInsurer = Node & {
  __typename?: "DefaultStrategyByInsurer";
  id: Scalars["UUID"]["output"];
  /** Reads a single `Insurer` that is related to this `DefaultStrategyByInsurer`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  strategy: ClaimComputationStrategyKind;
};

export type DefaultStrategyByInsurerAggregates = {
  __typename?: "DefaultStrategyByInsurerAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DefaultStrategyByInsurerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `DefaultStrategyByInsurer` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DefaultStrategyByInsurerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `strategy` field. */
  strategy?: InputMaybe<ClaimComputationStrategyKind>;
};

/** The fields on `defaultStrategyByInsurer` to look up the row to connect. */
export type DefaultStrategyByInsurerDefaultStrategyByInsurerInsurerIdKeyConnect =
  {
    insurerId: Scalars["UUID"]["input"];
  };

/** The fields on `defaultStrategyByInsurer` to look up the row to connect. */
export type DefaultStrategyByInsurerDefaultStrategyByInsurerPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

export type DefaultStrategyByInsurerDistinctCountAggregates = {
  __typename?: "DefaultStrategyByInsurerDistinctCountAggregates";
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of strategy across the matching connection */
  strategy?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DefaultStrategyByInsurer` object types. All fields are combined with a logical ‘and.’ */
export type DefaultStrategyByInsurerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DefaultStrategyByInsurerFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DefaultStrategyByInsurerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DefaultStrategyByInsurerFilter>>;
  /** Filter by the object’s `strategy` field. */
  strategy?: InputMaybe<ClaimComputationStrategyKindFilter>;
};

/** Grouping methods for `DefaultStrategyByInsurer` for usage during aggregation. */
export enum DefaultStrategyByInsurerGroupBy {
  STRATEGY = "STRATEGY",
}

/** Conditions for `DefaultStrategyByInsurer` aggregates. */
export type DefaultStrategyByInsurerHavingInput = {
  AND?: InputMaybe<Array<DefaultStrategyByInsurerHavingInput>>;
  OR?: InputMaybe<Array<DefaultStrategyByInsurerHavingInput>>;
};

/** Input for the nested mutation of `insurer` in the `DefaultStrategyByInsurerInput` mutation. */
export type DefaultStrategyByInsurerInsurerFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `defaultStrategyByInsurer` in the `InsurerInput` mutation. */
export type DefaultStrategyByInsurerInsurerFkeyInverseInput = {
  /** The primary key(s) for `defaultStrategyByInsurer` for the far side of the relationship. */
  connectById?: InputMaybe<DefaultStrategyByInsurerDefaultStrategyByInsurerPkeyConnect>;
  /** The primary key(s) for `defaultStrategyByInsurer` for the far side of the relationship. */
  connectByInsurerId?: InputMaybe<DefaultStrategyByInsurerDefaultStrategyByInsurerInsurerIdKeyConnect>;
  /** The primary key(s) for `defaultStrategyByInsurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<DefaultStrategyByInsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `defaultStrategyByInsurer` for the far side of the relationship. */
  updateById?: InputMaybe<DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingDefaultStrategyByInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `defaultStrategyByInsurer` for the far side of the relationship. */
  updateByInsurerId?: InputMaybe<DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingDefaultStrategyByInsurerInsurerIdKeyUpdate>;
  /** The primary key(s) and patch data for `defaultStrategyByInsurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyNodeIdUpdate>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DefaultStrategyByInsurerNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `defaultStrategyByInsurer` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `defaultStrategyByInsurer` to look up the row to update. */
export type DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingDefaultStrategyByInsurerInsurerIdKeyUpdate =
  {
    insurerId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `defaultStrategyByInsurer` being updated. */
    patch: updateDefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch;
  };

/** The fields on `defaultStrategyByInsurer` to look up the row to update. */
export type DefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingDefaultStrategyByInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `defaultStrategyByInsurer` being updated. */
    patch: updateDefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch;
  };

/** Represents an update to a `DefaultStrategyByInsurer`. Fields that are set will be updated. */
export type DefaultStrategyByInsurerPatch = {
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInput>;
};

/** A connection to a list of `DefaultStrategyByInsurer` values. */
export type DefaultStrategyByInsurersConnection = {
  __typename?: "DefaultStrategyByInsurersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DefaultStrategyByInsurerAggregates>;
  /** A list of edges which contains the `DefaultStrategyByInsurer` and cursor to aid in pagination. */
  edges: Array<DefaultStrategyByInsurersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DefaultStrategyByInsurerAggregates>>;
  /** A list of `DefaultStrategyByInsurer` objects. */
  nodes: Array<Maybe<DefaultStrategyByInsurer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DefaultStrategyByInsurer` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DefaultStrategyByInsurer` values. */
export type DefaultStrategyByInsurersConnectiongroupedAggregatesArgs = {
  groupBy: Array<DefaultStrategyByInsurerGroupBy>;
  having?: InputMaybe<DefaultStrategyByInsurerHavingInput>;
};

/** A `DefaultStrategyByInsurer` edge in the connection. */
export type DefaultStrategyByInsurersEdge = {
  __typename?: "DefaultStrategyByInsurersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DefaultStrategyByInsurer` at the end of the edge. */
  node?: Maybe<DefaultStrategyByInsurer>;
};

/** Methods to use when ordering `DefaultStrategyByInsurer`. */
export enum DefaultStrategyByInsurersOrderBy {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STRATEGY_ASC = "STRATEGY_ASC",
  STRATEGY_DESC = "STRATEGY_DESC",
}

/** All input for the `deleteAuditorBucketByNodeId` mutation. */
export type DeleteAuditorBucketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditorBucket` to be deleted. */
  nodeId: Scalars["ID"]["input"];
};

/** All input for the `deleteAuditorBucket` mutation. */
export type DeleteAuditorBucketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
};

/** The output of our delete `AuditorBucket` mutation. */
export type DeleteAuditorBucketPayload = {
  __typename?: "DeleteAuditorBucketPayload";
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  assignee?: Maybe<AuditorUser>;
  /** The `AuditorBucket` that was deleted by this mutation. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** An edge for our `AuditorBucket`. May be used by Relay 1. */
  auditorBucketEdge?: Maybe<AuditorBucketsEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  deletedAuditorBucketNodeId?: Maybe<Scalars["ID"]["output"]>;
  /** Reads a single `Insurer` that is related to this `AuditorBucket`. */
  insurer?: Maybe<Insurer>;
  /** Reads a single `Provider` that is related to this `AuditorBucket`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `AuditorBucket` mutation. */
export type DeleteAuditorBucketPayloadauditorBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

/** All input for the `deleteDocumentationRequests` mutation. */
export type DeleteDocumentationRequestsInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `deleteDocumentationRequests` mutation. */
export type DeleteDocumentationRequestsPayload = {
  __typename?: "DeleteDocumentationRequestsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  documentRequestCreateds?: Maybe<Array<Maybe<DocumentRequestCreated>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteKeysFromS3Cache` mutation. */
export type DeleteKeysFromS3CacheInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  s3Keys?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

/** The output of our `deleteKeysFromS3Cache` mutation. */
export type DeleteKeysFromS3CachePayload = {
  __typename?: "DeleteKeysFromS3CachePayload";
  boolean?: Maybe<Scalars["Boolean"]["output"]>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Directory = {
  __typename?: "Directory";
  archived: Scalars["Boolean"]["output"];
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  items: Array<FileOrDirectory>;
  modifiedAt: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  parentDirectory?: Maybe<Directory>;
  path: Array<DirectoryPathNode>;
};

export type DirectoryitemsArgs = {
  input?: InputMaybe<DirectoryItemsInput>;
};

export type DirectoryItemsInput = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DirectoryPathNode = {
  __typename?: "DirectoryPathNode";
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
};

export type DisputedFinding = Node & {
  __typename?: "DisputedFinding";
  /** Reads a single `AuditorUser` that is related to this `DisputedFinding`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaim` that is related to this `DisputedFinding`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `DisputedFinding`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type DisputedFindingAggregates = {
  __typename?: "DisputedFindingAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DisputedFindingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `DisputedFinding` object types. */
export type DisputedFindingAggregatesFilter = {
  /** Distinct count aggregate over matching `DisputedFinding` objects. */
  distinctCount?: InputMaybe<DisputedFindingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DisputedFinding` object to be included within the aggregate. */
  filter?: InputMaybe<DisputedFindingFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type DisputedFindingAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `disputedFinding` to be created by this mutation. */
export type DisputedFindingAuthorIdFkeyDisputedFindingCreateInput = {
  auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditorUser` in the `DisputedFindingInput` mutation. */
export type DisputedFindingAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<DisputedFindingAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `disputedFinding` in the `AuditorUserInput` mutation. */
export type DisputedFindingAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DisputedFindingDisputedFindingPkeyConnect>>;
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DisputedFindingNodeIdConnect>>;
  /** A `DisputedFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DisputedFindingAuthorIdFkeyDisputedFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingDisputedFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `disputedFinding` to be created by this mutation. */
export type DisputedFindingBatchClaimIdFkeyDisputedFindingCreateInput = {
  auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `batchClaim` in the `DisputedFindingInput` mutation. */
export type DisputedFindingBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `disputedFinding` in the `BatchClaimInput` mutation. */
export type DisputedFindingBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DisputedFindingDisputedFindingPkeyConnect>>;
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DisputedFindingNodeIdConnect>>;
  /** A `DisputedFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DisputedFindingBatchClaimIdFkeyDisputedFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingDisputedFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyNodeIdUpdate>
  >;
};

export enum DisputedFindingClaimStateEnum {
  DISPUTED = "DISPUTED",
  NOT_DISPUTED = "NOT_DISPUTED",
}

/** A filter to be used against DisputedFindingClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type DisputedFindingClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DisputedFindingClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DisputedFindingClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DisputedFindingClaimStateEnum>>;
};

/**
 * A condition to be used against `DisputedFinding` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type DisputedFindingCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `disputedFinding` to look up the row to connect. */
export type DisputedFindingDisputedFindingPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

export type DisputedFindingDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type DisputedFindingDistinctCountAggregates = {
  __typename?: "DisputedFindingDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `DisputedFinding` object types. All fields are combined with a logical ‘and.’ */
export type DisputedFindingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DisputedFindingFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DisputedFindingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DisputedFindingFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `DisputedFinding` for usage during aggregation. */
export enum DisputedFindingGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DELETED = "DELETED",
  PROVIDER_ID = "PROVIDER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type DisputedFindingHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DisputedFinding` aggregates. */
export type DisputedFindingHavingInput = {
  AND?: InputMaybe<Array<DisputedFindingHavingInput>>;
  OR?: InputMaybe<Array<DisputedFindingHavingInput>>;
  average?: InputMaybe<DisputedFindingHavingAverageInput>;
  distinctCount?: InputMaybe<DisputedFindingHavingDistinctCountInput>;
  max?: InputMaybe<DisputedFindingHavingMaxInput>;
  min?: InputMaybe<DisputedFindingHavingMinInput>;
  stddevPopulation?: InputMaybe<DisputedFindingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DisputedFindingHavingStddevSampleInput>;
  sum?: InputMaybe<DisputedFindingHavingSumInput>;
  variancePopulation?: InputMaybe<DisputedFindingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DisputedFindingHavingVarianceSampleInput>;
};

export type DisputedFindingHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DisputedFindingHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DisputedFinding` */
export type DisputedFindingInput = {
  auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DisputedFindingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `disputedFinding` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `disputedFinding` to look up the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyUsingDisputedFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: updateDisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `disputedFinding` to look up the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyUsingDisputedFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: updateDisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `disputedFinding` to look up the row to update. */
export type DisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyUsingDisputedFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: updateDisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyPatch;
  };

/** Represents an update to a `DisputedFinding`. Fields that are set will be updated. */
export type DisputedFindingPatch = {
  auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
};

/** The `disputedFinding` to be created by this mutation. */
export type DisputedFindingProviderIdFkeyDisputedFindingCreateInput = {
  auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `provider` in the `DisputedFindingInput` mutation. */
export type DisputedFindingProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnDisputedFindingForDisputedFindingProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `disputedFinding` in the `ProviderInput` mutation. */
export type DisputedFindingProviderIdFkeyInverseInput = {
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DisputedFindingDisputedFindingPkeyConnect>>;
  /** The primary key(s) for `disputedFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DisputedFindingNodeIdConnect>>;
  /** A `DisputedFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DisputedFindingProviderIdFkeyDisputedFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyUsingDisputedFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `disputedFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnDisputedFindingForDisputedFindingProviderIdFkeyNodeIdUpdate>
  >;
};

/** A connection to a list of `DisputedFinding` values. */
export type DisputedFindingsConnection = {
  __typename?: "DisputedFindingsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DisputedFindingAggregates>;
  /** A list of edges which contains the `DisputedFinding` and cursor to aid in pagination. */
  edges: Array<DisputedFindingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DisputedFindingAggregates>>;
  /** A list of `DisputedFinding` objects. */
  nodes: Array<Maybe<DisputedFinding>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DisputedFinding` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DisputedFinding` values. */
export type DisputedFindingsConnectiongroupedAggregatesArgs = {
  groupBy: Array<DisputedFindingGroupBy>;
  having?: InputMaybe<DisputedFindingHavingInput>;
};

/** A `DisputedFinding` edge in the connection. */
export type DisputedFindingsEdge = {
  __typename?: "DisputedFindingsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DisputedFinding` at the end of the edge. */
  node?: Maybe<DisputedFinding>;
};

/** Methods to use when ordering `DisputedFinding`. */
export enum DisputedFindingsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DELETED_ASC = "DELETED_ASC",
  DELETED_DESC = "DELETED_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/**
 * ### Overview
 * Stores a record of a documentation requests.
 * Feeds the cache.batch_claim_documentation_claim_state function to output to --> batch_claim_state.documentation_claim_state
 *  Has a _deleted_ boolean to specify the removal of a request.
 * This is an append only table, a documentation request is deleted by inserting a new row with deleted=true
 */
export type DocumentRequestCreated = Node & {
  __typename?: "DocumentRequestCreated";
  /** Reads a single `MessageCreated` that is related to this `DocumentRequestCreated`. */
  _deprecatedMessageCreated?: Maybe<MessageCreated>;
  _deprecatedMessageCreatedId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `BatchClaim` that is related to this `DocumentRequestCreated`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `MessageCreated`. */
  messageCreateds: MessageCreatedsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

/**
 * ### Overview
 * Stores a record of a documentation requests.
 * Feeds the cache.batch_claim_documentation_claim_state function to output to --> batch_claim_state.documentation_claim_state
 *  Has a _deleted_ boolean to specify the removal of a request.
 * This is an append only table, a documentation request is deleted by inserting a new row with deleted=true
 */
export type DocumentRequestCreatedmessageCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCreatedCondition>;
  filter?: InputMaybe<MessageCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageCreatedsOrderBy>>;
};

export type DocumentRequestCreatedAggregates = {
  __typename?: "DocumentRequestCreatedAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DocumentRequestCreatedDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `DocumentRequestCreated` object types. */
export type DocumentRequestCreatedAggregatesFilter = {
  /** Distinct count aggregate over matching `DocumentRequestCreated` objects. */
  distinctCount?: InputMaybe<DocumentRequestCreatedDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DocumentRequestCreated` object to be included within the aggregate. */
  filter?: InputMaybe<DocumentRequestCreatedFilter>;
};

/** The `documentRequestCreated` to be created by this mutation. */
export type DocumentRequestCreatedBatchClaimIdFkDocumentRequestCreatedCreateInput =
  {
    _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** Input for the nested mutation of `batchClaim` in the `DocumentRequestCreatedInput` mutation. */
export type DocumentRequestCreatedBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `documentRequestCreated` in the `BatchClaimInput` mutation. */
export type DocumentRequestCreatedBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentRequestCreatedDocumentRequestCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentRequestCreatedNodeIdConnect>>;
  /** A `DocumentRequestCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentRequestCreatedBatchClaimIdFkDocumentRequestCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingDocumentRequestCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `DocumentRequestCreated` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DocumentRequestCreatedCondition = {
  /** Checks for equality with the object’s `_deprecatedMessageCreatedId` field. */
  _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type DocumentRequestCreatedDistinctCountAggregateFilter = {
  _deprecatedMessageCreatedId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
};

export type DocumentRequestCreatedDistinctCountAggregates = {
  __typename?: "DocumentRequestCreatedDistinctCountAggregates";
  /** Distinct count of _deprecatedMessageCreatedId across the matching connection */
  _deprecatedMessageCreatedId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
};

/** The fields on `documentRequestCreated` to look up the row to connect. */
export type DocumentRequestCreatedDocumentRequestCreatedPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DocumentRequestCreated` object types. All fields are combined with a logical ‘and.’ */
export type DocumentRequestCreatedFilter = {
  /** Filter by the object’s `_deprecatedMessageCreated` relation. */
  _deprecatedMessageCreated?: InputMaybe<MessageCreatedFilter>;
  /** A related `_deprecatedMessageCreated` exists. */
  _deprecatedMessageCreatedExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `_deprecatedMessageCreatedId` field. */
  _deprecatedMessageCreatedId?: InputMaybe<UUIDFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentRequestCreatedFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `messageCreateds` relation. */
  messageCreateds?: InputMaybe<DocumentRequestCreatedToManyMessageCreatedFilter>;
  /** Some related `messageCreateds` exist. */
  messageCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentRequestCreatedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentRequestCreatedFilter>>;
};

/** Grouping methods for `DocumentRequestCreated` for usage during aggregation. */
export enum DocumentRequestCreatedGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DELETED = "DELETED",
  _DEPRECATED_MESSAGE_CREATED_ID = "_DEPRECATED_MESSAGE_CREATED_ID",
}

export type DocumentRequestCreatedHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DocumentRequestCreated` aggregates. */
export type DocumentRequestCreatedHavingInput = {
  AND?: InputMaybe<Array<DocumentRequestCreatedHavingInput>>;
  OR?: InputMaybe<Array<DocumentRequestCreatedHavingInput>>;
  average?: InputMaybe<DocumentRequestCreatedHavingAverageInput>;
  distinctCount?: InputMaybe<DocumentRequestCreatedHavingDistinctCountInput>;
  max?: InputMaybe<DocumentRequestCreatedHavingMaxInput>;
  min?: InputMaybe<DocumentRequestCreatedHavingMinInput>;
  stddevPopulation?: InputMaybe<DocumentRequestCreatedHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DocumentRequestCreatedHavingStddevSampleInput>;
  sum?: InputMaybe<DocumentRequestCreatedHavingSumInput>;
  variancePopulation?: InputMaybe<DocumentRequestCreatedHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DocumentRequestCreatedHavingVarianceSampleInput>;
};

export type DocumentRequestCreatedHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentRequestCreatedHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DocumentRequestCreated` */
export type DocumentRequestCreatedInput = {
  _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
};

/** The `documentRequestCreated` to be created by this mutation. */
export type DocumentRequestCreatedMessageCreatedIdFkDocumentRequestCreatedCreateInput =
  {
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** Input for the nested mutation of `messageCreated` in the `DocumentRequestCreatedInput` mutation. */
export type DocumentRequestCreatedMessageCreatedIdFkInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<MessageCreatedMessageCreatedPkeyConnect>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageCreatedNodeIdConnect>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkMessageCreatedCreateInput>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<MessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkUsingMessageCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `documentRequestCreated` in the `MessageCreatedInput` mutation. */
export type DocumentRequestCreatedMessageCreatedIdFkInverseInput = {
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentRequestCreatedDocumentRequestCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentRequestCreatedNodeIdConnect>>;
  /** A `DocumentRequestCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentRequestCreatedMessageCreatedIdFkDocumentRequestCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkUsingDocumentRequestCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<MessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type DocumentRequestCreatedMessageCreatedIdFkMessageCreatedCreateInput =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: MessageCreatedKind;
    message?: InputMaybe<Scalars["String"]["input"]>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** The globally unique `ID` look up for the row to connect. */
export type DocumentRequestCreatedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `documentRequestCreated` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `documentRequestCreated` to look up the row to update. */
export type DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkUsingDocumentRequestCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: updateDocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `documentRequestCreated` to look up the row to update. */
export type DocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkUsingDocumentRequestCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: updateDocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `documentRequestCreated` to look up the row to update. */
export type DocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyUsingDocumentRequestCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: updateDocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyPatch;
  };

/** Represents an update to a `DocumentRequestCreated`. Fields that are set will be updated. */
export type DocumentRequestCreatedPatch = {
  _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
  messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
};

/** A filter to be used against many `MessageCreated` object types. All fields are combined with a logical ‘and.’ */
export type DocumentRequestCreatedToManyMessageCreatedFilter = {
  /** Aggregates across related `MessageCreated` match the filter criteria. */
  aggregates?: InputMaybe<MessageCreatedAggregatesFilter>;
  /** Every related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageCreatedFilter>;
  /** No related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageCreatedFilter>;
  /** Some related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageCreatedFilter>;
};

/** A connection to a list of `DocumentRequestCreated` values. */
export type DocumentRequestCreatedsConnection = {
  __typename?: "DocumentRequestCreatedsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DocumentRequestCreatedAggregates>;
  /** A list of edges which contains the `DocumentRequestCreated` and cursor to aid in pagination. */
  edges: Array<DocumentRequestCreatedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DocumentRequestCreatedAggregates>>;
  /** A list of `DocumentRequestCreated` objects. */
  nodes: Array<Maybe<DocumentRequestCreated>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DocumentRequestCreated` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DocumentRequestCreated` values. */
export type DocumentRequestCreatedsConnectiongroupedAggregatesArgs = {
  groupBy: Array<DocumentRequestCreatedGroupBy>;
  having?: InputMaybe<DocumentRequestCreatedHavingInput>;
};

/** A `DocumentRequestCreated` edge in the connection. */
export type DocumentRequestCreatedsEdge = {
  __typename?: "DocumentRequestCreatedsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DocumentRequestCreated` at the end of the edge. */
  node?: Maybe<DocumentRequestCreated>;
};

/** Methods to use when ordering `DocumentRequestCreated`. */
export enum DocumentRequestCreatedsOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DELETED_ASC = "DELETED_ASC",
  DELETED_DESC = "DELETED_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_CREATED_AT_ASC = "MESSAGE_CREATEDS_AVERAGE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_AVERAGE_CREATED_AT_DESC = "MESSAGE_CREATEDS_AVERAGE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_KIND_ASC = "MESSAGE_CREATEDS_AVERAGE_KIND_ASC",
  MESSAGE_CREATEDS_AVERAGE_KIND_DESC = "MESSAGE_CREATEDS_AVERAGE_KIND_DESC",
  MESSAGE_CREATEDS_AVERAGE_MESSAGE_ASC = "MESSAGE_CREATEDS_AVERAGE_MESSAGE_ASC",
  MESSAGE_CREATEDS_AVERAGE_MESSAGE_DESC = "MESSAGE_CREATEDS_AVERAGE_MESSAGE_DESC",
  MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_DOCUMENT_REQUEST_CREATED_ID__COUNT_ASC = "MESSAGE_CREATEDS_BY_DOCUMENT_REQUEST_CREATED_ID__COUNT_ASC",
  MESSAGE_CREATEDS_BY_DOCUMENT_REQUEST_CREATED_ID__COUNT_DESC = "MESSAGE_CREATEDS_BY_DOCUMENT_REQUEST_CREATED_ID__COUNT_DESC",
  MESSAGE_CREATEDS_COUNT_ASC = "MESSAGE_CREATEDS_COUNT_ASC",
  MESSAGE_CREATEDS_COUNT_DESC = "MESSAGE_CREATEDS_COUNT_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_MAX_CREATED_AT_ASC = "MESSAGE_CREATEDS_MAX_CREATED_AT_ASC",
  MESSAGE_CREATEDS_MAX_CREATED_AT_DESC = "MESSAGE_CREATEDS_MAX_CREATED_AT_DESC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MAX_ID_ASC = "MESSAGE_CREATEDS_MAX_ID_ASC",
  MESSAGE_CREATEDS_MAX_ID_DESC = "MESSAGE_CREATEDS_MAX_ID_DESC",
  MESSAGE_CREATEDS_MAX_KIND_ASC = "MESSAGE_CREATEDS_MAX_KIND_ASC",
  MESSAGE_CREATEDS_MAX_KIND_DESC = "MESSAGE_CREATEDS_MAX_KIND_DESC",
  MESSAGE_CREATEDS_MAX_MESSAGE_ASC = "MESSAGE_CREATEDS_MAX_MESSAGE_ASC",
  MESSAGE_CREATEDS_MAX_MESSAGE_DESC = "MESSAGE_CREATEDS_MAX_MESSAGE_DESC",
  MESSAGE_CREATEDS_MAX_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_MAX_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_MAX_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_MAX_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_MIN_CREATED_AT_ASC = "MESSAGE_CREATEDS_MIN_CREATED_AT_ASC",
  MESSAGE_CREATEDS_MIN_CREATED_AT_DESC = "MESSAGE_CREATEDS_MIN_CREATED_AT_DESC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MIN_ID_ASC = "MESSAGE_CREATEDS_MIN_ID_ASC",
  MESSAGE_CREATEDS_MIN_ID_DESC = "MESSAGE_CREATEDS_MIN_ID_DESC",
  MESSAGE_CREATEDS_MIN_KIND_ASC = "MESSAGE_CREATEDS_MIN_KIND_ASC",
  MESSAGE_CREATEDS_MIN_KIND_DESC = "MESSAGE_CREATEDS_MIN_KIND_DESC",
  MESSAGE_CREATEDS_MIN_MESSAGE_ASC = "MESSAGE_CREATEDS_MIN_MESSAGE_ASC",
  MESSAGE_CREATEDS_MIN_MESSAGE_DESC = "MESSAGE_CREATEDS_MIN_MESSAGE_DESC",
  MESSAGE_CREATEDS_MIN_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_MIN_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_MIN_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_MIN_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_SUM_CREATED_AT_ASC = "MESSAGE_CREATEDS_SUM_CREATED_AT_ASC",
  MESSAGE_CREATEDS_SUM_CREATED_AT_DESC = "MESSAGE_CREATEDS_SUM_CREATED_AT_DESC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_SUM_ID_ASC = "MESSAGE_CREATEDS_SUM_ID_ASC",
  MESSAGE_CREATEDS_SUM_ID_DESC = "MESSAGE_CREATEDS_SUM_ID_DESC",
  MESSAGE_CREATEDS_SUM_KIND_ASC = "MESSAGE_CREATEDS_SUM_KIND_ASC",
  MESSAGE_CREATEDS_SUM_KIND_DESC = "MESSAGE_CREATEDS_SUM_KIND_DESC",
  MESSAGE_CREATEDS_SUM_MESSAGE_ASC = "MESSAGE_CREATEDS_SUM_MESSAGE_ASC",
  MESSAGE_CREATEDS_SUM_MESSAGE_DESC = "MESSAGE_CREATEDS_SUM_MESSAGE_DESC",
  MESSAGE_CREATEDS_SUM_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_SUM_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_SUM_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_SUM_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__CREATED_AT_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__CREATED_AT_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__CREATED_AT_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__CREATED_AT_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__KIND_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__KIND_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__KIND_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__KIND_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__MESSAGE_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__MESSAGE_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__MESSAGE_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__MESSAGE_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__SENDER_USER_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__SENDER_USER_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__SENDER_USER_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_CREATED_ID__SENDER_USER_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  _DEPRECATED_MESSAGE_CREATED_ID_ASC = "_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  _DEPRECATED_MESSAGE_CREATED_ID_DESC = "_DEPRECATED_MESSAGE_CREATED_ID_DESC",
}

export enum DocumentStateEnum {
  optimized = "optimized",
  uploaded = "uploaded",
  vectorized = "vectorized",
}

export type DocumentStateInput = {
  documentId: Scalars["UUID"]["input"];
  state: DocumentStateEnum;
};

/**
 * ## Overview
 *  Tracks uploads that are made through the application. Owned by the `Documentation Service` - its not actually feeding any state calculations, just serving as an application log.
 */
export type DocumentUploadCreated = Node & {
  __typename?: "DocumentUploadCreated";
  /** Reads a single `MessageCreated` that is related to this `DocumentUploadCreated`. */
  _deprecatedMessage?: Maybe<MessageCreated>;
  _deprecatedMessageId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `AuditorUser` that is related to this `DocumentUploadCreated`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaim` that is related to this `DocumentUploadCreated`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  claimInquiryRequestId?: Maybe<Scalars["UUID"]["output"]>;
  createdAt: Scalars["Datetime"]["output"];
  fileLocation: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  kind: DocumentUploadKind;
  /** Reads and enables pagination through a set of `MessageCreated`. */
  messageCreateds: MessageCreatedsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `DocumentUploadCreated`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
};

/**
 * ## Overview
 *  Tracks uploads that are made through the application. Owned by the `Documentation Service` - its not actually feeding any state calculations, just serving as an application log.
 */
export type DocumentUploadCreatedmessageCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCreatedCondition>;
  filter?: InputMaybe<MessageCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageCreatedsOrderBy>>;
};

export type DocumentUploadCreatedAggregates = {
  __typename?: "DocumentUploadCreatedAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DocumentUploadCreatedDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `DocumentUploadCreated` object types. */
export type DocumentUploadCreatedAggregatesFilter = {
  /** Distinct count aggregate over matching `DocumentUploadCreated` objects. */
  distinctCount?: InputMaybe<DocumentUploadCreatedDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DocumentUploadCreated` object to be included within the aggregate. */
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type DocumentUploadCreatedAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** The `documentUploadCreated` to be created by this mutation. */
export type DocumentUploadCreatedAuthorIdFkeyDocumentUploadCreatedCreateInput =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileLocation: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: DocumentUploadKind;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** Input for the nested mutation of `auditorUser` in the `DocumentUploadCreatedInput` mutation. */
export type DocumentUploadCreatedAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `documentUploadCreated` in the `AuditorUserInput` mutation. */
export type DocumentUploadCreatedAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentUploadCreatedDocumentUploadCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentUploadCreatedNodeIdConnect>>;
  /** A `DocumentUploadCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentUploadCreatedAuthorIdFkeyDocumentUploadCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingDocumentUploadCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `documentUploadCreated` to be created by this mutation. */
export type DocumentUploadCreatedBatchClaimIdFkDocumentUploadCreatedCreateInput =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileLocation: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: DocumentUploadKind;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** Input for the nested mutation of `batchClaim` in the `DocumentUploadCreatedInput` mutation. */
export type DocumentUploadCreatedBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `documentUploadCreated` in the `BatchClaimInput` mutation. */
export type DocumentUploadCreatedBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentUploadCreatedDocumentUploadCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentUploadCreatedNodeIdConnect>>;
  /** A `DocumentUploadCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentUploadCreatedBatchClaimIdFkDocumentUploadCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingDocumentUploadCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `DocumentUploadCreated` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type DocumentUploadCreatedCondition = {
  /** Checks for equality with the object’s `_deprecatedMessageId` field. */
  _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `claimInquiryRequestId` field. */
  claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fileLocation` field. */
  fileLocation?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `kind` field. */
  kind?: InputMaybe<DocumentUploadKind>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
};

export type DocumentUploadCreatedDistinctCountAggregateFilter = {
  _deprecatedMessageId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  batchClaimId?: InputMaybe<BigIntFilter>;
  claimInquiryRequestId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  fileLocation?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  kind?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
};

export type DocumentUploadCreatedDistinctCountAggregates = {
  __typename?: "DocumentUploadCreatedDistinctCountAggregates";
  /** Distinct count of _deprecatedMessageId across the matching connection */
  _deprecatedMessageId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of claimInquiryRequestId across the matching connection */
  claimInquiryRequestId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fileLocation across the matching connection */
  fileLocation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of kind across the matching connection */
  kind?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** The fields on `documentUploadCreated` to look up the row to connect. */
export type DocumentUploadCreatedDocumentUploadCreatedPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A filter to be used against `DocumentUploadCreated` object types. All fields are combined with a logical ‘and.’ */
export type DocumentUploadCreatedFilter = {
  /** Filter by the object’s `_deprecatedMessage` relation. */
  _deprecatedMessage?: InputMaybe<MessageCreatedFilter>;
  /** A related `_deprecatedMessage` exists. */
  _deprecatedMessageExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `_deprecatedMessageId` field. */
  _deprecatedMessageId?: InputMaybe<UUIDFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentUploadCreatedFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `claimInquiryRequestId` field. */
  claimInquiryRequestId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fileLocation` field. */
  fileLocation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `kind` field. */
  kind?: InputMaybe<DocumentUploadKindFilter>;
  /** Filter by the object’s `messageCreateds` relation. */
  messageCreateds?: InputMaybe<DocumentUploadCreatedToManyMessageCreatedFilter>;
  /** Some related `messageCreateds` exist. */
  messageCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentUploadCreatedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentUploadCreatedFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
};

/** Grouping methods for `DocumentUploadCreated` for usage during aggregation. */
export enum DocumentUploadCreatedGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CLAIM_INQUIRY_REQUEST_ID = "CLAIM_INQUIRY_REQUEST_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  FILE_LOCATION = "FILE_LOCATION",
  KIND = "KIND",
  PROVIDER_ID = "PROVIDER_ID",
  _DEPRECATED_MESSAGE_ID = "_DEPRECATED_MESSAGE_ID",
}

export type DocumentUploadCreatedHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DocumentUploadCreated` aggregates. */
export type DocumentUploadCreatedHavingInput = {
  AND?: InputMaybe<Array<DocumentUploadCreatedHavingInput>>;
  OR?: InputMaybe<Array<DocumentUploadCreatedHavingInput>>;
  average?: InputMaybe<DocumentUploadCreatedHavingAverageInput>;
  distinctCount?: InputMaybe<DocumentUploadCreatedHavingDistinctCountInput>;
  max?: InputMaybe<DocumentUploadCreatedHavingMaxInput>;
  min?: InputMaybe<DocumentUploadCreatedHavingMinInput>;
  stddevPopulation?: InputMaybe<DocumentUploadCreatedHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DocumentUploadCreatedHavingStddevSampleInput>;
  sum?: InputMaybe<DocumentUploadCreatedHavingSumInput>;
  variancePopulation?: InputMaybe<DocumentUploadCreatedHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DocumentUploadCreatedHavingVarianceSampleInput>;
};

export type DocumentUploadCreatedHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DocumentUploadCreatedHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DocumentUploadCreated` */
export type DocumentUploadCreatedInput = {
  _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
  claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fileLocation: Scalars["String"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: DocumentUploadKind;
  messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
};

/** The `documentUploadCreated` to be created by this mutation. */
export type DocumentUploadCreatedMessageIdFkeyDocumentUploadCreatedCreateInput =
  {
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileLocation: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: DocumentUploadKind;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** Input for the nested mutation of `messageCreated` in the `DocumentUploadCreatedInput` mutation. */
export type DocumentUploadCreatedMessageIdFkeyInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<MessageCreatedMessageCreatedPkeyConnect>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageCreatedNodeIdConnect>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<DocumentUploadCreatedMessageIdFkeyMessageCreatedCreateInput>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<MessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyUsingMessageCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `documentUploadCreated` in the `MessageCreatedInput` mutation. */
export type DocumentUploadCreatedMessageIdFkeyInverseInput = {
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentUploadCreatedDocumentUploadCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentUploadCreatedNodeIdConnect>>;
  /** A `DocumentUploadCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentUploadCreatedMessageIdFkeyDocumentUploadCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyUsingDocumentUploadCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<MessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type DocumentUploadCreatedMessageIdFkeyMessageCreatedCreateInput = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: MessageCreatedKind;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DocumentUploadCreatedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `documentUploadCreated` to look up the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyUsingDocumentUploadCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `documentUploadCreated` to look up the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkUsingDocumentUploadCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `documentUploadCreated` to look up the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyUsingDocumentUploadCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `documentUploadCreated` to look up the row to update. */
export type DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyUsingDocumentUploadCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `documentUploadCreated` to look up the row to update. */
export type DocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyUsingDocumentUploadCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: updateDocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyPatch;
  };

/** Represents an update to a `DocumentUploadCreated`. Fields that are set will be updated. */
export type DocumentUploadCreatedPatch = {
  _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
  messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
};

/** The `documentUploadCreated` to be created by this mutation. */
export type DocumentUploadCreatedProviderIdFkeyDocumentUploadCreatedCreateInput =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileLocation: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: DocumentUploadKind;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** Input for the nested mutation of `provider` in the `DocumentUploadCreatedInput` mutation. */
export type DocumentUploadCreatedProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `documentUploadCreated` in the `ProviderInput` mutation. */
export type DocumentUploadCreatedProviderIdFkeyInverseInput = {
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<DocumentUploadCreatedDocumentUploadCreatedPkeyConnect>
  >;
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentUploadCreatedNodeIdConnect>>;
  /** A `DocumentUploadCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentUploadCreatedProviderIdFkeyDocumentUploadCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyUsingDocumentUploadCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyNodeIdUpdate>
  >;
};

/** A filter to be used against many `MessageCreated` object types. All fields are combined with a logical ‘and.’ */
export type DocumentUploadCreatedToManyMessageCreatedFilter = {
  /** Aggregates across related `MessageCreated` match the filter criteria. */
  aggregates?: InputMaybe<MessageCreatedAggregatesFilter>;
  /** Every related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageCreatedFilter>;
  /** No related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageCreatedFilter>;
  /** Some related `MessageCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageCreatedFilter>;
};

/** A connection to a list of `DocumentUploadCreated` values. */
export type DocumentUploadCreatedsConnection = {
  __typename?: "DocumentUploadCreatedsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DocumentUploadCreatedAggregates>;
  /** A list of edges which contains the `DocumentUploadCreated` and cursor to aid in pagination. */
  edges: Array<DocumentUploadCreatedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DocumentUploadCreatedAggregates>>;
  /** A list of `DocumentUploadCreated` objects. */
  nodes: Array<Maybe<DocumentUploadCreated>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DocumentUploadCreated` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DocumentUploadCreated` values. */
export type DocumentUploadCreatedsConnectiongroupedAggregatesArgs = {
  groupBy: Array<DocumentUploadCreatedGroupBy>;
  having?: InputMaybe<DocumentUploadCreatedHavingInput>;
};

/** A `DocumentUploadCreated` edge in the connection. */
export type DocumentUploadCreatedsEdge = {
  __typename?: "DocumentUploadCreatedsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DocumentUploadCreated` at the end of the edge. */
  node?: Maybe<DocumentUploadCreated>;
};

/** Methods to use when ordering `DocumentUploadCreated`. */
export enum DocumentUploadCreatedsOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REQUEST_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  FILE_LOCATION_ASC = "FILE_LOCATION_ASC",
  FILE_LOCATION_DESC = "FILE_LOCATION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  KIND_ASC = "KIND_ASC",
  KIND_DESC = "KIND_DESC",
  MESSAGE_CREATEDS_AVERAGE_CREATED_AT_ASC = "MESSAGE_CREATEDS_AVERAGE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_AVERAGE_CREATED_AT_DESC = "MESSAGE_CREATEDS_AVERAGE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_ID_DESC",
  MESSAGE_CREATEDS_AVERAGE_KIND_ASC = "MESSAGE_CREATEDS_AVERAGE_KIND_ASC",
  MESSAGE_CREATEDS_AVERAGE_KIND_DESC = "MESSAGE_CREATEDS_AVERAGE_KIND_DESC",
  MESSAGE_CREATEDS_AVERAGE_MESSAGE_ASC = "MESSAGE_CREATEDS_AVERAGE_MESSAGE_ASC",
  MESSAGE_CREATEDS_AVERAGE_MESSAGE_DESC = "MESSAGE_CREATEDS_AVERAGE_MESSAGE_DESC",
  MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_AVERAGE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_BY_DOCUMENT_UPLOAD_CREATED_ID__COUNT_ASC = "MESSAGE_CREATEDS_BY_DOCUMENT_UPLOAD_CREATED_ID__COUNT_ASC",
  MESSAGE_CREATEDS_BY_DOCUMENT_UPLOAD_CREATED_ID__COUNT_DESC = "MESSAGE_CREATEDS_BY_DOCUMENT_UPLOAD_CREATED_ID__COUNT_DESC",
  MESSAGE_CREATEDS_COUNT_ASC = "MESSAGE_CREATEDS_COUNT_ASC",
  MESSAGE_CREATEDS_COUNT_DESC = "MESSAGE_CREATEDS_COUNT_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_ID_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_KIND_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_MESSAGE_DESC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_DISTINCT_COUNT_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_MAX_CREATED_AT_ASC = "MESSAGE_CREATEDS_MAX_CREATED_AT_ASC",
  MESSAGE_CREATEDS_MAX_CREATED_AT_DESC = "MESSAGE_CREATEDS_MAX_CREATED_AT_DESC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_MAX_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_MAX_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MAX_ID_ASC = "MESSAGE_CREATEDS_MAX_ID_ASC",
  MESSAGE_CREATEDS_MAX_ID_DESC = "MESSAGE_CREATEDS_MAX_ID_DESC",
  MESSAGE_CREATEDS_MAX_KIND_ASC = "MESSAGE_CREATEDS_MAX_KIND_ASC",
  MESSAGE_CREATEDS_MAX_KIND_DESC = "MESSAGE_CREATEDS_MAX_KIND_DESC",
  MESSAGE_CREATEDS_MAX_MESSAGE_ASC = "MESSAGE_CREATEDS_MAX_MESSAGE_ASC",
  MESSAGE_CREATEDS_MAX_MESSAGE_DESC = "MESSAGE_CREATEDS_MAX_MESSAGE_DESC",
  MESSAGE_CREATEDS_MAX_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_MAX_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_MAX_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_MAX_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_MIN_CREATED_AT_ASC = "MESSAGE_CREATEDS_MIN_CREATED_AT_ASC",
  MESSAGE_CREATEDS_MIN_CREATED_AT_DESC = "MESSAGE_CREATEDS_MIN_CREATED_AT_DESC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_MIN_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_MIN_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_MIN_ID_ASC = "MESSAGE_CREATEDS_MIN_ID_ASC",
  MESSAGE_CREATEDS_MIN_ID_DESC = "MESSAGE_CREATEDS_MIN_ID_DESC",
  MESSAGE_CREATEDS_MIN_KIND_ASC = "MESSAGE_CREATEDS_MIN_KIND_ASC",
  MESSAGE_CREATEDS_MIN_KIND_DESC = "MESSAGE_CREATEDS_MIN_KIND_DESC",
  MESSAGE_CREATEDS_MIN_MESSAGE_ASC = "MESSAGE_CREATEDS_MIN_MESSAGE_ASC",
  MESSAGE_CREATEDS_MIN_MESSAGE_DESC = "MESSAGE_CREATEDS_MIN_MESSAGE_DESC",
  MESSAGE_CREATEDS_MIN_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_MIN_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_MIN_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_MIN_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_STDDEV_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_STDDEV_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_SUM_CREATED_AT_ASC = "MESSAGE_CREATEDS_SUM_CREATED_AT_ASC",
  MESSAGE_CREATEDS_SUM_CREATED_AT_DESC = "MESSAGE_CREATEDS_SUM_CREATED_AT_DESC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_SUM_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_SUM_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_SUM_ID_ASC = "MESSAGE_CREATEDS_SUM_ID_ASC",
  MESSAGE_CREATEDS_SUM_ID_DESC = "MESSAGE_CREATEDS_SUM_ID_DESC",
  MESSAGE_CREATEDS_SUM_KIND_ASC = "MESSAGE_CREATEDS_SUM_KIND_ASC",
  MESSAGE_CREATEDS_SUM_KIND_DESC = "MESSAGE_CREATEDS_SUM_KIND_DESC",
  MESSAGE_CREATEDS_SUM_MESSAGE_ASC = "MESSAGE_CREATEDS_SUM_MESSAGE_ASC",
  MESSAGE_CREATEDS_SUM_MESSAGE_DESC = "MESSAGE_CREATEDS_SUM_MESSAGE_DESC",
  MESSAGE_CREATEDS_SUM_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_SUM_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_SUM_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_SUM_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_KIND_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_MESSAGE_DESC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_POPULATION_SENDER_USER_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_KIND_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_MESSAGE_DESC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_ASC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_ASC",
  MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_DESC = "MESSAGE_CREATEDS_VARIANCE_SAMPLE_SENDER_USER_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__CREATED_AT_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__CREATED_AT_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__CREATED_AT_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__CREATED_AT_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__ID_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__KIND_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__KIND_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__KIND_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__KIND_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__MESSAGE_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__MESSAGE_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__MESSAGE_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__MESSAGE_DESC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__SENDER_USER_ID_ASC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__SENDER_USER_ID_ASC",
  MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__SENDER_USER_ID_DESC = "MESSAGE_CREATED_BY_DEPRECATED_MESSAGE_ID__SENDER_USER_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  _DEPRECATED_MESSAGE_ID_ASC = "_DEPRECATED_MESSAGE_ID_ASC",
  _DEPRECATED_MESSAGE_ID_DESC = "_DEPRECATED_MESSAGE_ID_DESC",
}

export enum DocumentUploadKind {
  BILLING_RECORD = "BILLING_RECORD",
  CONSULTATIONS = "CONSULTATIONS",
  DISCHARGE_SUMMARY = "DISCHARGE_SUMMARY",
  EMERGENCY_ROOM_RECORDS = "EMERGENCY_ROOM_RECORDS",
  FACE_SHEET = "FACE_SHEET",
  FACILITY_CHARGEMASTER = "FACILITY_CHARGEMASTER",
  HISTORY_AND_PHYSICAL = "HISTORY_AND_PHYSICAL",
  ICD10_CM_CODES_SUBMITTED = "ICD10_CM_CODES_SUBMITTED",
  ITEMIZED_BILL = "ITEMIZED_BILL",
  LABORATORY_REPORTS = "LABORATORY_REPORTS",
  MEDICAL_RECORD = "MEDICAL_RECORD",
  NURSING_NOTES = "NURSING_NOTES",
  OPERATIVE_REPORTS = "OPERATIVE_REPORTS",
  OTHER = "OTHER",
  PATHOLOGY_REPORTS = "PATHOLOGY_REPORTS",
  PHYSICIAN_ORDERS = "PHYSICIAN_ORDERS",
  PHYSICIAN_PROGRESS_NOTES = "PHYSICIAN_PROGRESS_NOTES",
  RADIOLOGY_REPORTS = "RADIOLOGY_REPORTS",
  THERAPY_TREATMENT_PLAN_WITH_NOTES = "THERAPY_TREATMENT_PLAN_WITH_NOTES",
  UB04_CLAIM_FORM = "UB04_CLAIM_FORM",
}

/** A filter to be used against DocumentUploadKind fields. All fields are combined with a logical ‘and.’ */
export type DocumentUploadKindFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DocumentUploadKind>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DocumentUploadKind>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DocumentUploadKind>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DocumentUploadKind>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DocumentUploadKind>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DocumentUploadKind>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DocumentUploadKind>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DocumentUploadKind>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DocumentUploadKind>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DocumentUploadKind>>;
};

export enum DocumentationClaimStateEnum {
  AWAITING_UPLOAD = "AWAITING_UPLOAD",
  DOCUMENTS_RECEIVED = "DOCUMENTS_RECEIVED",
  NO_REQUESTS = "NO_REQUESTS",
  REQUEST_DRAFTED = "REQUEST_DRAFTED",
}

/** A filter to be used against DocumentationClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type DocumentationClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<DocumentationClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<DocumentationClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<DocumentationClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<DocumentationClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<DocumentationClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<DocumentationClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<DocumentationClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<DocumentationClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<DocumentationClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<DocumentationClaimStateEnum>>;
};

export type DocumentationSelectionSet = Node & {
  __typename?: "DocumentationSelectionSet";
  createdAt: Scalars["Datetime"]["output"];
  etag: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `DocumentationSelectionSet`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  s3Key: Scalars["String"]["output"];
  selectionSet: Scalars["Int"]["output"];
};

export type DocumentationSelectionSetAggregates = {
  __typename?: "DocumentationSelectionSetAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DocumentationSelectionSetAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DocumentationSelectionSetDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DocumentationSelectionSetMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DocumentationSelectionSetMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DocumentationSelectionSetStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DocumentationSelectionSetStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DocumentationSelectionSetSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DocumentationSelectionSetVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DocumentationSelectionSetVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `DocumentationSelectionSet` object types. */
export type DocumentationSelectionSetAggregatesFilter = {
  /** Mean average aggregate over matching `DocumentationSelectionSet` objects. */
  average?: InputMaybe<DocumentationSelectionSetAverageAggregateFilter>;
  /** Distinct count aggregate over matching `DocumentationSelectionSet` objects. */
  distinctCount?: InputMaybe<DocumentationSelectionSetDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DocumentationSelectionSet` object to be included within the aggregate. */
  filter?: InputMaybe<DocumentationSelectionSetFilter>;
  /** Maximum aggregate over matching `DocumentationSelectionSet` objects. */
  max?: InputMaybe<DocumentationSelectionSetMaxAggregateFilter>;
  /** Minimum aggregate over matching `DocumentationSelectionSet` objects. */
  min?: InputMaybe<DocumentationSelectionSetMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `DocumentationSelectionSet` objects. */
  stddevPopulation?: InputMaybe<DocumentationSelectionSetStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `DocumentationSelectionSet` objects. */
  stddevSample?: InputMaybe<DocumentationSelectionSetStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `DocumentationSelectionSet` objects. */
  sum?: InputMaybe<DocumentationSelectionSetSumAggregateFilter>;
  /** Population variance aggregate over matching `DocumentationSelectionSet` objects. */
  variancePopulation?: InputMaybe<DocumentationSelectionSetVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `DocumentationSelectionSet` objects. */
  varianceSample?: InputMaybe<DocumentationSelectionSetVarianceSampleAggregateFilter>;
};

export type DocumentationSelectionSetAverageAggregateFilter = {
  selectionSet?: InputMaybe<BigFloatFilter>;
};

export type DocumentationSelectionSetAverageAggregates = {
  __typename?: "DocumentationSelectionSetAverageAggregates";
  /** Mean average of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `DocumentationSelectionSet` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type DocumentationSelectionSetCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `etag` field. */
  etag?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `filename` field. */
  filename?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `selectionSet` field. */
  selectionSet?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DocumentationSelectionSetDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  etag?: InputMaybe<BigIntFilter>;
  filename?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  s3Key?: InputMaybe<BigIntFilter>;
  selectionSet?: InputMaybe<BigIntFilter>;
};

export type DocumentationSelectionSetDistinctCountAggregates = {
  __typename?: "DocumentationSelectionSetDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of etag across the matching connection */
  etag?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of filename across the matching connection */
  filename?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3Key across the matching connection */
  s3Key?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigInt"]["output"]>;
};

/** The fields on `documentationSelectionSet` to look up the row to connect. */
export type DocumentationSelectionSetDocumentationSelectionSetPkeyConnect = {
  etag: Scalars["String"]["input"];
};

/** The fields on `documentationSelectionSet` to look up the row to connect. */
export type DocumentationSelectionSetDocumentationSelectionSetS3KeyKeyConnect =
  {
    s3Key: Scalars["String"]["input"];
  };

/** A filter to be used against `DocumentationSelectionSet` object types. All fields are combined with a logical ‘and.’ */
export type DocumentationSelectionSetFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DocumentationSelectionSetFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `etag` field. */
  etag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `filename` field. */
  filename?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DocumentationSelectionSetFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DocumentationSelectionSetFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3Key` field. */
  s3Key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `selectionSet` field. */
  selectionSet?: InputMaybe<IntFilter>;
};

/** Grouping methods for `DocumentationSelectionSet` for usage during aggregation. */
export enum DocumentationSelectionSetGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  FILENAME = "FILENAME",
  PROVIDER_ID = "PROVIDER_ID",
  SELECTION_SET = "SELECTION_SET",
}

export type DocumentationSelectionSetHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `DocumentationSelectionSet` aggregates. */
export type DocumentationSelectionSetHavingInput = {
  AND?: InputMaybe<Array<DocumentationSelectionSetHavingInput>>;
  OR?: InputMaybe<Array<DocumentationSelectionSetHavingInput>>;
  average?: InputMaybe<DocumentationSelectionSetHavingAverageInput>;
  distinctCount?: InputMaybe<DocumentationSelectionSetHavingDistinctCountInput>;
  max?: InputMaybe<DocumentationSelectionSetHavingMaxInput>;
  min?: InputMaybe<DocumentationSelectionSetHavingMinInput>;
  stddevPopulation?: InputMaybe<DocumentationSelectionSetHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DocumentationSelectionSetHavingStddevSampleInput>;
  sum?: InputMaybe<DocumentationSelectionSetHavingSumInput>;
  variancePopulation?: InputMaybe<DocumentationSelectionSetHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DocumentationSelectionSetHavingVarianceSampleInput>;
};

export type DocumentationSelectionSetHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

export type DocumentationSelectionSetHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  selectionSet?: InputMaybe<HavingIntFilter>;
};

/** An input for mutations affecting `DocumentationSelectionSet` */
export type DocumentationSelectionSetInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  etag: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInput>;
  s3Key: Scalars["String"]["input"];
  selectionSet: Scalars["Int"]["input"];
};

export type DocumentationSelectionSetMaxAggregateFilter = {
  selectionSet?: InputMaybe<IntFilter>;
};

export type DocumentationSelectionSetMaxAggregates = {
  __typename?: "DocumentationSelectionSetMaxAggregates";
  /** Maximum of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["Int"]["output"]>;
};

export type DocumentationSelectionSetMinAggregateFilter = {
  selectionSet?: InputMaybe<IntFilter>;
};

export type DocumentationSelectionSetMinAggregates = {
  __typename?: "DocumentationSelectionSetMinAggregates";
  /** Minimum of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["Int"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DocumentationSelectionSetNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `documentationSelectionSet` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `documentationSelectionSet` to look up the row to update. */
export type DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingDocumentationSelectionSetPkeyUpdate =
  {
    etag: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `documentationSelectionSet` being updated. */
    patch: updateDocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyPatch;
  };

/** The fields on `documentationSelectionSet` to look up the row to update. */
export type DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingDocumentationSelectionSetS3KeyKeyUpdate =
  {
    /** An object where the defined keys will be set on the `documentationSelectionSet` being updated. */
    patch: updateDocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyPatch;
    s3Key: Scalars["String"]["input"];
  };

/** Represents an update to a `DocumentationSelectionSet`. Fields that are set will be updated. */
export type DocumentationSelectionSetPatch = {
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInput>;
};

/** The `documentationSelectionSet` to be created by this mutation. */
export type DocumentationSelectionSetProviderIdFkeyDocumentationSelectionSetCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    etag: Scalars["String"]["input"];
    filename: Scalars["String"]["input"];
    providerToProviderId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInput>;
    s3Key: Scalars["String"]["input"];
    selectionSet: Scalars["Int"]["input"];
  };

/** Input for the nested mutation of `provider` in the `DocumentationSelectionSetInput` mutation. */
export type DocumentationSelectionSetProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `documentationSelectionSet` in the `ProviderInput` mutation. */
export type DocumentationSelectionSetProviderIdFkeyInverseInput = {
  /** The primary key(s) for `documentationSelectionSet` for the far side of the relationship. */
  connectByEtag?: InputMaybe<
    Array<DocumentationSelectionSetDocumentationSelectionSetPkeyConnect>
  >;
  /** The primary key(s) for `documentationSelectionSet` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<DocumentationSelectionSetNodeIdConnect>>;
  /** The primary key(s) for `documentationSelectionSet` for the far side of the relationship. */
  connectByS3Key?: InputMaybe<
    Array<DocumentationSelectionSetDocumentationSelectionSetS3KeyKeyConnect>
  >;
  /** A `DocumentationSelectionSetInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<DocumentationSelectionSetProviderIdFkeyDocumentationSelectionSetCreateInput>
  >;
  /** The primary key(s) and patch data for `documentationSelectionSet` for the far side of the relationship. */
  updateByEtag?: InputMaybe<
    Array<DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingDocumentationSelectionSetPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `documentationSelectionSet` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `documentationSelectionSet` for the far side of the relationship. */
  updateByS3Key?: InputMaybe<
    Array<DocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingDocumentationSelectionSetS3KeyKeyUpdate>
  >;
};

export type DocumentationSelectionSetStddevPopulationAggregateFilter = {
  selectionSet?: InputMaybe<BigFloatFilter>;
};

export type DocumentationSelectionSetStddevPopulationAggregates = {
  __typename?: "DocumentationSelectionSetStddevPopulationAggregates";
  /** Population standard deviation of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DocumentationSelectionSetStddevSampleAggregateFilter = {
  selectionSet?: InputMaybe<BigFloatFilter>;
};

export type DocumentationSelectionSetStddevSampleAggregates = {
  __typename?: "DocumentationSelectionSetStddevSampleAggregates";
  /** Sample standard deviation of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DocumentationSelectionSetSumAggregateFilter = {
  selectionSet?: InputMaybe<BigIntFilter>;
};

export type DocumentationSelectionSetSumAggregates = {
  __typename?: "DocumentationSelectionSetSumAggregates";
  /** Sum of selectionSet across the matching connection */
  selectionSet: Scalars["BigInt"]["output"];
};

export type DocumentationSelectionSetVariancePopulationAggregateFilter = {
  selectionSet?: InputMaybe<BigFloatFilter>;
};

export type DocumentationSelectionSetVariancePopulationAggregates = {
  __typename?: "DocumentationSelectionSetVariancePopulationAggregates";
  /** Population variance of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type DocumentationSelectionSetVarianceSampleAggregateFilter = {
  selectionSet?: InputMaybe<BigFloatFilter>;
};

export type DocumentationSelectionSetVarianceSampleAggregates = {
  __typename?: "DocumentationSelectionSetVarianceSampleAggregates";
  /** Sample variance of selectionSet across the matching connection */
  selectionSet?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `DocumentationSelectionSet` values. */
export type DocumentationSelectionSetsConnection = {
  __typename?: "DocumentationSelectionSetsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DocumentationSelectionSetAggregates>;
  /** A list of edges which contains the `DocumentationSelectionSet` and cursor to aid in pagination. */
  edges: Array<DocumentationSelectionSetsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DocumentationSelectionSetAggregates>>;
  /** A list of `DocumentationSelectionSet` objects. */
  nodes: Array<Maybe<DocumentationSelectionSet>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DocumentationSelectionSet` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `DocumentationSelectionSet` values. */
export type DocumentationSelectionSetsConnectiongroupedAggregatesArgs = {
  groupBy: Array<DocumentationSelectionSetGroupBy>;
  having?: InputMaybe<DocumentationSelectionSetHavingInput>;
};

/** A `DocumentationSelectionSet` edge in the connection. */
export type DocumentationSelectionSetsEdge = {
  __typename?: "DocumentationSelectionSetsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `DocumentationSelectionSet` at the end of the edge. */
  node?: Maybe<DocumentationSelectionSet>;
};

/** Methods to use when ordering `DocumentationSelectionSet`. */
export enum DocumentationSelectionSetsOrderBy {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ETAG_ASC = "ETAG_ASC",
  ETAG_DESC = "ETAG_DESC",
  FILENAME_ASC = "FILENAME_ASC",
  FILENAME_DESC = "FILENAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  S3_KEY_ASC = "S3_KEY_ASC",
  S3_KEY_DESC = "S3_KEY_DESC",
  SELECTION_SET_ASC = "SELECTION_SET_ASC",
  SELECTION_SET_DESC = "SELECTION_SET_DESC",
}

export type EditUserInput = {
  auditorUserId: Scalars["UUID"]["input"];
  facilities?: InputMaybe<Array<Scalars["String"]["input"]>>;
  userType: UserTypeEnum;
};

export type EditUserResponse = {
  __typename?: "EditUserResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  updatedFields?: Maybe<EditableUserFields>;
};

export type EditableUserFields = {
  __typename?: "EditableUserFields";
  facilities?: Maybe<Array<Scalars["String"]["output"]>>;
  messageSentIds?: Maybe<Array<Scalars["UUID"]["output"]>>;
};

export type EmailServerResponse = {
  __typename?: "EmailServerResponse";
  rejected?: Maybe<Scalars["String"]["output"]>;
  response?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type File = {
  __typename?: "File";
  archived: Scalars["Boolean"]["output"];
  createdAt: Scalars["String"]["output"];
  deleted: Scalars["Boolean"]["output"];
  documentStates?: Maybe<Array<DocumentStateEnum>>;
  fileName: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  modifiedAt: Scalars["String"]["output"];
  parentDirectory?: Maybe<Directory>;
  readyToSearch: Scalars["Boolean"]["output"];
  signedDownloadUrl?: Maybe<SignedDownloadUrl>;
  size: Scalars["Int"]["output"];
};

export type FileOrDirectory = Directory | File;

export type FilesInput = {
  archived?: InputMaybe<Scalars["Boolean"]["input"]>;
  caseId?: InputMaybe<Scalars["ID"]["input"]>;
  fileIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  parentDirectoryId?: InputMaybe<Scalars["ID"]["input"]>;
  queueId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Finding = {
  __typename?: "Finding";
  accepted?: Maybe<Scalars["Boolean"]["output"]>;
  author: FindingAuthor;
  autodorFindingId?: Maybe<Scalars["UUID"]["output"]>;
  autodorFindingS3Key?: Maybe<Scalars["String"]["output"]>;
  confidence?: Maybe<Scalars["String"]["output"]>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  findingItemizedBillData?: Maybe<FindingItemizedBillData>;
  id: Scalars["UUID"]["output"];
  improperPaymentCost?: Maybe<Scalars["Float"]["output"]>;
  improperPaymentReason?: Maybe<Scalars["String"]["output"]>;
  improperPaymentUnitsCharged?: Maybe<Scalars["Int"]["output"]>;
  isActive?: Maybe<Scalars["Boolean"]["output"]>;
  isOriginal?: Maybe<Scalars["Boolean"]["output"]>;
  latestReview?: Maybe<LatestReview>;
  metaDataAocPrimaryCode?: Maybe<Scalars["String"]["output"]>;
  metaDataEmCode?: Maybe<Scalars["String"]["output"]>;
  metaDataEmCorrectLevel?: Maybe<Scalars["Int"]["output"]>;
  metaDataEmOriginalLevel?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciMueAdjudicationValue?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciMueOhpMueValue?: Maybe<Scalars["Int"]["output"]>;
  metaDataNcciPtpCode?: Maybe<Scalars["String"]["output"]>;
  metaDataNcciPtpModifier?: Maybe<Scalars["Int"]["output"]>;
  metaDataPricingCorrectPrice?: Maybe<Scalars["Float"]["output"]>;
  metaDataUnitsCorrectUnits?: Maybe<Scalars["Int"]["output"]>;
  needsReview: Scalars["Boolean"]["output"];
  ruleType?: Maybe<Scalars["String"]["output"]>;
  seedType?: Maybe<Scalars["String"]["output"]>;
  superseded?: Maybe<Scalars["Boolean"]["output"]>;
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
};

export type FindingAuthor = {
  __typename?: "FindingAuthor";
  firstName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  lastName: Scalars["String"]["output"];
  userType: Scalars["String"]["output"];
};

export type FindingItemizedBillData = {
  __typename?: "FindingItemizedBillData";
  alaRowId?: Maybe<Scalars["UUID"]["output"]>;
  authorId?: Maybe<Scalars["UUID"]["output"]>;
  batchClaimLineId?: Maybe<Scalars["UUID"]["output"]>;
  createdAt?: Maybe<Scalars["Date"]["output"]>;
  dateOfServiceEnd?: Maybe<Scalars["Date"]["output"]>;
  dateOfServiceStart?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  ibLineId?: Maybe<Scalars["UUID"]["output"]>;
  ibLineVersionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
  id?: Maybe<Scalars["UUID"]["output"]>;
  itemNumber?: Maybe<Scalars["String"]["output"]>;
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  originalBilledAmount?: Maybe<Scalars["Float"]["output"]>;
  procedureCode?: Maybe<Scalars["String"]["output"]>;
  procedureCodeModifier?: Maybe<Scalars["String"]["output"]>;
  revCode?: Maybe<Scalars["String"]["output"]>;
  s3InputSource?: Maybe<Scalars["String"]["output"]>;
  stale?: Maybe<Scalars["Boolean"]["output"]>;
  unitCharge?: Maybe<Scalars["Float"]["output"]>;
  units?: Maybe<Scalars["Int"]["output"]>;
  updatedAt?: Maybe<Scalars["Date"]["output"]>;
  versionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
};

/** The `auditorBucket` to be created by this mutation. */
export type FkeyAuditorBucketProviderIdAuditorBucketCreateInput = {
  assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
  auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `provider` in the `AuditorBucketInput` mutation. */
export type FkeyAuditorBucketProviderIdInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnAuditorBucketForFkeyAuditorBucketProviderIdUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdNodeIdUpdate>;
};

/** Input for the nested mutation of `auditorBucket` in the `ProviderInput` mutation. */
export type FkeyAuditorBucketProviderIdInverseInput = {
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyConnect>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditorBucketNodeIdConnect>>;
  /** A `AuditorBucketInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FkeyAuditorBucketProviderIdAuditorBucketCreateInput>
  >;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<AuditorBucketAuditorBucketPkeyDelete>>;
  /** The primary key(s) for `auditorBucket` for the far side of the relationship. */
  deleteByNodeId?: InputMaybe<Array<AuditorBucketNodeIdDelete>>;
  /** Flag indicating whether all other `auditorBucket` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdUsingAuditorBucketPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditorBucket` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnAuditorBucketForFkeyAuditorBucketProviderIdNodeIdUpdate>
  >;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Float"]["input"]>>;
};

export type FloatStat = {
  __typename?: "FloatStat";
  description: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  operationType: OperationTypeEnum;
  units: FloatUnitsEnum;
  value: Scalars["Float"]["output"];
};

export enum FloatUnitsEnum {
  DOLLARS = "DOLLARS",
}

export type FoxitCredentials = {
  __typename?: "FoxitCredentials";
  key: Scalars["String"]["output"];
  sn: Scalars["String"]["output"];
};

export type GenerateReportResponse = {
  __typename?: "GenerateReportResponse";
  auditFindingReportId?: Maybe<Scalars["UUID"]["output"]>;
  auditFindingReportViewId: Scalars["UUID"]["output"];
  reportUrl: Scalars["String"]["output"];
};

export type HavingBigfloatFilter = {
  equalTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  greaterThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  lessThan?: InputMaybe<Scalars["BigFloat"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["BigFloat"]["input"]>;
};

export type HavingBigintFilter = {
  equalTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  greaterThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  lessThan?: InputMaybe<Scalars["BigInt"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  lessThan?: InputMaybe<Scalars["Datetime"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars["Float"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Float"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  lessThan?: InputMaybe<Scalars["Float"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Float"]["input"]>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HistoricStatistics = {
  __typename?: "HistoricStatistics";
  allowedAmount: FloatStat;
  billedAmount: FloatStat;
  claimsAssigned: IntStat;
  claimsCompleted: IntStat;
  claimsInProgress: IntStat;
  claimsReceived: IntStat;
  claimsReviewed: IntStat;
  clientRealizedSavings: FloatStat;
  determinedDiscrepantAmount: FloatStat;
  numberOfLives: IntStat;
  numberOfProviders: IntStat;
  savingsPerLife: FloatStat;
};

export type HistoryTimelineEvent = {
  __typename?: "HistoryTimelineEvent";
  action: Scalars["String"]["output"];
  created_at: Scalars["String"]["output"];
  eventType: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  metaData?: Maybe<Scalars["String"]["output"]>;
};

export type HistoryTimelineEvents = {
  __typename?: "HistoryTimelineEvents";
  events: Array<Maybe<HistoryTimelineEvent>>;
};

export type HumanProcessingRequest = Node & {
  __typename?: "HumanProcessingRequest";
  createdAt?: Maybe<Scalars["Datetime"]["output"]>;
  errorDetails?: Maybe<Scalars["String"]["output"]>;
  extraDetails?: Maybe<Scalars["JSON"]["output"]>;
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  priority: Scalars["Int"]["output"];
  pulledDates?: Maybe<Array<Maybe<Scalars["Datetime"]["output"]>>>;
  requestType: HumanProcessingRequestType;
  requeuedDates?: Maybe<Array<Maybe<Scalars["Datetime"]["output"]>>>;
  runId: Scalars["Int"]["output"];
  /** Reads a single `S3AuditDocumentation` that is related to this `HumanProcessingRequest`. */
  s3AuditDocumentation?: Maybe<S3AuditDocumentation>;
  s3AuditDocumentationId: Scalars["UUID"]["output"];
  status: HumanProcessingRequestStatus;
  successDetails?: Maybe<Scalars["JSON"]["output"]>;
  updatedAt?: Maybe<Scalars["Datetime"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type HumanProcessingRequestAggregates = {
  __typename?: "HumanProcessingRequestAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<HumanProcessingRequestAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<HumanProcessingRequestDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<HumanProcessingRequestMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<HumanProcessingRequestMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<HumanProcessingRequestStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<HumanProcessingRequestStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<HumanProcessingRequestSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<HumanProcessingRequestVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<HumanProcessingRequestVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `HumanProcessingRequest` object types. */
export type HumanProcessingRequestAggregatesFilter = {
  /** Mean average aggregate over matching `HumanProcessingRequest` objects. */
  average?: InputMaybe<HumanProcessingRequestAverageAggregateFilter>;
  /** Distinct count aggregate over matching `HumanProcessingRequest` objects. */
  distinctCount?: InputMaybe<HumanProcessingRequestDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `HumanProcessingRequest` object to be included within the aggregate. */
  filter?: InputMaybe<HumanProcessingRequestFilter>;
  /** Maximum aggregate over matching `HumanProcessingRequest` objects. */
  max?: InputMaybe<HumanProcessingRequestMaxAggregateFilter>;
  /** Minimum aggregate over matching `HumanProcessingRequest` objects. */
  min?: InputMaybe<HumanProcessingRequestMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `HumanProcessingRequest` objects. */
  stddevPopulation?: InputMaybe<HumanProcessingRequestStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `HumanProcessingRequest` objects. */
  stddevSample?: InputMaybe<HumanProcessingRequestStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `HumanProcessingRequest` objects. */
  sum?: InputMaybe<HumanProcessingRequestSumAggregateFilter>;
  /** Population variance aggregate over matching `HumanProcessingRequest` objects. */
  variancePopulation?: InputMaybe<HumanProcessingRequestVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `HumanProcessingRequest` objects. */
  varianceSample?: InputMaybe<HumanProcessingRequestVarianceSampleAggregateFilter>;
};

export type HumanProcessingRequestAverageAggregateFilter = {
  priority?: InputMaybe<BigFloatFilter>;
  runId?: InputMaybe<BigFloatFilter>;
};

export type HumanProcessingRequestAverageAggregates = {
  __typename?: "HumanProcessingRequestAverageAggregates";
  /** Mean average of priority across the matching connection */
  priority?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Mean average of runId across the matching connection */
  runId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/**
 * A condition to be used against `HumanProcessingRequest` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type HumanProcessingRequestCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `errorDetails` field. */
  errorDetails?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `extraDetails` field. */
  extraDetails?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `priority` field. */
  priority?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `pulledDates` field. */
  pulledDates?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Checks for equality with the object’s `requestType` field. */
  requestType?: InputMaybe<HumanProcessingRequestType>;
  /** Checks for equality with the object’s `requeuedDates` field. */
  requeuedDates?: InputMaybe<Array<InputMaybe<Scalars["Datetime"]["input"]>>>;
  /** Checks for equality with the object’s `runId` field. */
  runId?: InputMaybe<Scalars["Int"]["input"]>;
  /** Checks for equality with the object’s `s3AuditDocumentationId` field. */
  s3AuditDocumentationId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<HumanProcessingRequestStatus>;
  /** Checks for equality with the object’s `successDetails` field. */
  successDetails?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `username` field. */
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type HumanProcessingRequestDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  errorDetails?: InputMaybe<BigIntFilter>;
  extraDetails?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  priority?: InputMaybe<BigIntFilter>;
  pulledDates?: InputMaybe<BigIntFilter>;
  requestType?: InputMaybe<BigIntFilter>;
  requeuedDates?: InputMaybe<BigIntFilter>;
  runId?: InputMaybe<BigIntFilter>;
  s3AuditDocumentationId?: InputMaybe<BigIntFilter>;
  status?: InputMaybe<BigIntFilter>;
  successDetails?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  username?: InputMaybe<BigIntFilter>;
};

export type HumanProcessingRequestDistinctCountAggregates = {
  __typename?: "HumanProcessingRequestDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of errorDetails across the matching connection */
  errorDetails?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of extraDetails across the matching connection */
  extraDetails?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of priority across the matching connection */
  priority?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of pulledDates across the matching connection */
  pulledDates?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of requestType across the matching connection */
  requestType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of requeuedDates across the matching connection */
  requeuedDates?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of runId across the matching connection */
  runId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3AuditDocumentationId across the matching connection */
  s3AuditDocumentationId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of successDetails across the matching connection */
  successDetails?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of username across the matching connection */
  username?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `HumanProcessingRequest` object types. All fields are combined with a logical ‘and.’ */
export type HumanProcessingRequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<HumanProcessingRequestFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `errorDetails` field. */
  errorDetails?: InputMaybe<StringFilter>;
  /** Filter by the object’s `extraDetails` field. */
  extraDetails?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<HumanProcessingRequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<HumanProcessingRequestFilter>>;
  /** Filter by the object’s `priority` field. */
  priority?: InputMaybe<IntFilter>;
  /** Filter by the object’s `pulledDates` field. */
  pulledDates?: InputMaybe<DatetimeListFilter>;
  /** Filter by the object’s `requestType` field. */
  requestType?: InputMaybe<HumanProcessingRequestTypeFilter>;
  /** Filter by the object’s `requeuedDates` field. */
  requeuedDates?: InputMaybe<DatetimeListFilter>;
  /** Filter by the object’s `runId` field. */
  runId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `s3AuditDocumentation` relation. */
  s3AuditDocumentation?: InputMaybe<S3AuditDocumentationFilter>;
  /** Filter by the object’s `s3AuditDocumentationId` field. */
  s3AuditDocumentationId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<HumanProcessingRequestStatusFilter>;
  /** Filter by the object’s `successDetails` field. */
  successDetails?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `username` field. */
  username?: InputMaybe<StringFilter>;
};

/** Grouping methods for `HumanProcessingRequest` for usage during aggregation. */
export enum HumanProcessingRequestGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  ERROR_DETAILS = "ERROR_DETAILS",
  EXTRA_DETAILS = "EXTRA_DETAILS",
  PRIORITY = "PRIORITY",
  PULLED_DATES = "PULLED_DATES",
  REQUEST_TYPE = "REQUEST_TYPE",
  REQUEUED_DATES = "REQUEUED_DATES",
  RUN_ID = "RUN_ID",
  S3_AUDIT_DOCUMENTATION_ID = "S3_AUDIT_DOCUMENTATION_ID",
  STATUS = "STATUS",
  SUCCESS_DETAILS = "SUCCESS_DETAILS",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
  USERNAME = "USERNAME",
}

export type HumanProcessingRequestHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `HumanProcessingRequest` aggregates. */
export type HumanProcessingRequestHavingInput = {
  AND?: InputMaybe<Array<HumanProcessingRequestHavingInput>>;
  OR?: InputMaybe<Array<HumanProcessingRequestHavingInput>>;
  average?: InputMaybe<HumanProcessingRequestHavingAverageInput>;
  distinctCount?: InputMaybe<HumanProcessingRequestHavingDistinctCountInput>;
  max?: InputMaybe<HumanProcessingRequestHavingMaxInput>;
  min?: InputMaybe<HumanProcessingRequestHavingMinInput>;
  stddevPopulation?: InputMaybe<HumanProcessingRequestHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<HumanProcessingRequestHavingStddevSampleInput>;
  sum?: InputMaybe<HumanProcessingRequestHavingSumInput>;
  variancePopulation?: InputMaybe<HumanProcessingRequestHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<HumanProcessingRequestHavingVarianceSampleInput>;
};

export type HumanProcessingRequestHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type HumanProcessingRequestHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  priority?: InputMaybe<HavingIntFilter>;
  runId?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The fields on `humanProcessingRequest` to look up the row to connect. */
export type HumanProcessingRequestHumanProcessingRequestPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `humanProcessingRequest` to look up the row to connect. */
export type HumanProcessingRequestHumanProcessingRequestUniqueRunIdAndTypeConnect =
  {
    requestType: HumanProcessingRequestType;
    runId: Scalars["Int"]["input"];
    s3AuditDocumentationId: Scalars["UUID"]["input"];
  };

export type HumanProcessingRequestMaxAggregateFilter = {
  priority?: InputMaybe<IntFilter>;
  runId?: InputMaybe<IntFilter>;
};

export type HumanProcessingRequestMaxAggregates = {
  __typename?: "HumanProcessingRequestMaxAggregates";
  /** Maximum of priority across the matching connection */
  priority?: Maybe<Scalars["Int"]["output"]>;
  /** Maximum of runId across the matching connection */
  runId?: Maybe<Scalars["Int"]["output"]>;
};

export type HumanProcessingRequestMinAggregateFilter = {
  priority?: InputMaybe<IntFilter>;
  runId?: InputMaybe<IntFilter>;
};

export type HumanProcessingRequestMinAggregates = {
  __typename?: "HumanProcessingRequestMinAggregates";
  /** Minimum of priority across the matching connection */
  priority?: Maybe<Scalars["Int"]["output"]>;
  /** Minimum of runId across the matching connection */
  runId?: Maybe<Scalars["Int"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type HumanProcessingRequestNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `humanProcessingRequest` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `s3AuditDocumentation` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
    patch: S3AuditDocumentationPatch;
  };

/** The fields on `humanProcessingRequest` to look up the row to update. */
export type HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingHumanProcessingRequestPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `humanProcessingRequest` being updated. */
    patch: updateHumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyPatch;
  };

/** The fields on `humanProcessingRequest` to look up the row to update. */
export type HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingHumanProcessingRequestUniqueRunIdAndTypeUpdate =
  {
    /** An object where the defined keys will be set on the `humanProcessingRequest` being updated. */
    patch: updateHumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyPatch;
    requestType: HumanProcessingRequestType;
    runId: Scalars["Int"]["input"];
    s3AuditDocumentationId: Scalars["UUID"]["input"];
  };

/** Represents an update to a `HumanProcessingRequest`. Fields that are set will be updated. */
export type HumanProcessingRequestPatch = {
  s3AuditDocumentationId?: InputMaybe<Scalars["UUID"]["input"]>;
  s3AuditDocumentationToS3AuditDocumentationId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInput>;
};

/** Input for the nested mutation of `s3AuditDocumentation` in the `HumanProcessingRequestInput` mutation. */
export type HumanProcessingRequestS3AuditDocumentationIdFkeyInput = {
  /** The primary key(s) for `s3AuditDocumentation` for the far side of the relationship. */
  connectById?: InputMaybe<S3AuditDocumentationS3AuditDocumentationPkeyConnect>;
  /** The primary key(s) for `s3AuditDocumentation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<S3AuditDocumentationNodeIdConnect>;
  /** The primary key(s) and patch data for `s3AuditDocumentation` for the far side of the relationship. */
  updateById?: InputMaybe<S3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingS3AuditDocumentationPkeyUpdate>;
  /** The primary key(s) and patch data for `s3AuditDocumentation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `humanProcessingRequest` in the `S3AuditDocumentationInput` mutation. */
export type HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput = {
  /** The primary key(s) for `humanProcessingRequest` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<HumanProcessingRequestHumanProcessingRequestPkeyConnect>
  >;
  /** The primary key(s) for `humanProcessingRequest` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<HumanProcessingRequestNodeIdConnect>>;
  /** The primary key(s) for `humanProcessingRequest` for the far side of the relationship. */
  connectByRunIdAndS3AuditDocumentationIdAndRequestType?: InputMaybe<
    Array<HumanProcessingRequestHumanProcessingRequestUniqueRunIdAndTypeConnect>
  >;
  /** The primary key(s) and patch data for `humanProcessingRequest` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingHumanProcessingRequestPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `humanProcessingRequest` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<S3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `humanProcessingRequest` for the far side of the relationship. */
  updateByRunIdAndS3AuditDocumentationIdAndRequestType?: InputMaybe<
    Array<HumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingHumanProcessingRequestUniqueRunIdAndTypeUpdate>
  >;
};

export enum HumanProcessingRequestStatus {
  CANCELED = "CANCELED",
  FAILURE = "FAILURE",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
  SUCCESS = "SUCCESS",
}

/** A filter to be used against HumanProcessingRequestStatus fields. All fields are combined with a logical ‘and.’ */
export type HumanProcessingRequestStatusFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<HumanProcessingRequestStatus>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<HumanProcessingRequestStatus>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<HumanProcessingRequestStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<HumanProcessingRequestStatus>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<HumanProcessingRequestStatus>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<HumanProcessingRequestStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<HumanProcessingRequestStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<HumanProcessingRequestStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<HumanProcessingRequestStatus>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<HumanProcessingRequestStatus>>;
};

export type HumanProcessingRequestStddevPopulationAggregateFilter = {
  priority?: InputMaybe<BigFloatFilter>;
  runId?: InputMaybe<BigFloatFilter>;
};

export type HumanProcessingRequestStddevPopulationAggregates = {
  __typename?: "HumanProcessingRequestStddevPopulationAggregates";
  /** Population standard deviation of priority across the matching connection */
  priority?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population standard deviation of runId across the matching connection */
  runId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type HumanProcessingRequestStddevSampleAggregateFilter = {
  priority?: InputMaybe<BigFloatFilter>;
  runId?: InputMaybe<BigFloatFilter>;
};

export type HumanProcessingRequestStddevSampleAggregates = {
  __typename?: "HumanProcessingRequestStddevSampleAggregates";
  /** Sample standard deviation of priority across the matching connection */
  priority?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample standard deviation of runId across the matching connection */
  runId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type HumanProcessingRequestSumAggregateFilter = {
  priority?: InputMaybe<BigIntFilter>;
  runId?: InputMaybe<BigIntFilter>;
};

export type HumanProcessingRequestSumAggregates = {
  __typename?: "HumanProcessingRequestSumAggregates";
  /** Sum of priority across the matching connection */
  priority: Scalars["BigInt"]["output"];
  /** Sum of runId across the matching connection */
  runId: Scalars["BigInt"]["output"];
};

export enum HumanProcessingRequestType {
  ANNOTATE_IB = "ANNOTATE_IB",
  CLAIM_REVIEW = "CLAIM_REVIEW",
  CLASSIFY_DOC = "CLASSIFY_DOC",
  COLA_AND_ISIB = "COLA_AND_ISIB",
  EDIT_PDF = "EDIT_PDF",
  INSPECT_STRUCTURE = "INSPECT_STRUCTURE",
  REVIEW_CSV = "REVIEW_CSV",
}

/** A filter to be used against HumanProcessingRequestType fields. All fields are combined with a logical ‘and.’ */
export type HumanProcessingRequestTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<HumanProcessingRequestType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<HumanProcessingRequestType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<HumanProcessingRequestType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<HumanProcessingRequestType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<HumanProcessingRequestType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<HumanProcessingRequestType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<HumanProcessingRequestType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<HumanProcessingRequestType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<HumanProcessingRequestType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<HumanProcessingRequestType>>;
};

export type HumanProcessingRequestVariancePopulationAggregateFilter = {
  priority?: InputMaybe<BigFloatFilter>;
  runId?: InputMaybe<BigFloatFilter>;
};

export type HumanProcessingRequestVariancePopulationAggregates = {
  __typename?: "HumanProcessingRequestVariancePopulationAggregates";
  /** Population variance of priority across the matching connection */
  priority?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Population variance of runId across the matching connection */
  runId?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type HumanProcessingRequestVarianceSampleAggregateFilter = {
  priority?: InputMaybe<BigFloatFilter>;
  runId?: InputMaybe<BigFloatFilter>;
};

export type HumanProcessingRequestVarianceSampleAggregates = {
  __typename?: "HumanProcessingRequestVarianceSampleAggregates";
  /** Sample variance of priority across the matching connection */
  priority?: Maybe<Scalars["BigFloat"]["output"]>;
  /** Sample variance of runId across the matching connection */
  runId?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `HumanProcessingRequest` values. */
export type HumanProcessingRequestsConnection = {
  __typename?: "HumanProcessingRequestsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<HumanProcessingRequestAggregates>;
  /** A list of edges which contains the `HumanProcessingRequest` and cursor to aid in pagination. */
  edges: Array<HumanProcessingRequestsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<HumanProcessingRequestAggregates>>;
  /** A list of `HumanProcessingRequest` objects. */
  nodes: Array<Maybe<HumanProcessingRequest>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `HumanProcessingRequest` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `HumanProcessingRequest` values. */
export type HumanProcessingRequestsConnectiongroupedAggregatesArgs = {
  groupBy: Array<HumanProcessingRequestGroupBy>;
  having?: InputMaybe<HumanProcessingRequestHavingInput>;
};

/** A `HumanProcessingRequest` edge in the connection. */
export type HumanProcessingRequestsEdge = {
  __typename?: "HumanProcessingRequestsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `HumanProcessingRequest` at the end of the edge. */
  node?: Maybe<HumanProcessingRequest>;
};

/** Methods to use when ordering `HumanProcessingRequest`. */
export enum HumanProcessingRequestsOrderBy {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ERROR_DETAILS_ASC = "ERROR_DETAILS_ASC",
  ERROR_DETAILS_DESC = "ERROR_DETAILS_DESC",
  EXTRA_DETAILS_ASC = "EXTRA_DETAILS_ASC",
  EXTRA_DETAILS_DESC = "EXTRA_DETAILS_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PRIORITY_ASC = "PRIORITY_ASC",
  PRIORITY_DESC = "PRIORITY_DESC",
  PULLED_DATES_ASC = "PULLED_DATES_ASC",
  PULLED_DATES_DESC = "PULLED_DATES_DESC",
  REQUEST_TYPE_ASC = "REQUEST_TYPE_ASC",
  REQUEST_TYPE_DESC = "REQUEST_TYPE_DESC",
  REQUEUED_DATES_ASC = "REQUEUED_DATES_ASC",
  REQUEUED_DATES_DESC = "REQUEUED_DATES_DESC",
  RUN_ID_ASC = "RUN_ID_ASC",
  RUN_ID_DESC = "RUN_ID_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__BATCH_CLAIM_ID_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__BATCH_CLAIM_ID_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__BATCH_CLAIM_ID_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__BATCH_CLAIM_ID_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ETAG_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ETAG_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ETAG_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ETAG_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__FILENAME_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__FILENAME_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__FILENAME_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__FILENAME_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ICN_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ICN_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ICN_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ICN_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ID_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ID_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ID_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ID_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__IS_UPLOADED_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__IS_UPLOADED_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__IS_UPLOADED_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__IS_UPLOADED_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__LAST_MODIFIED_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__LAST_MODIFIED_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__LAST_MODIFIED_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__LAST_MODIFIED_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ORIGINAL_FILE_ID_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ORIGINAL_FILE_ID_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ORIGINAL_FILE_ID_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__ORIGINAL_FILE_ID_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROCESS_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROCESS_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROCESS_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROCESS_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROVIDER_ID_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROVIDER_ID_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROVIDER_ID_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__PROVIDER_ID_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__S3_KEY_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__S3_KEY_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__S3_KEY_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__S3_KEY_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SIZE_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SIZE_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SIZE_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SIZE_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_FILE_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_FILE_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_FILE_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_FILE_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_PROCESS_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_PROCESS_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_PROCESS_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__SUB_PROCESS_DESC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__TAGS_ASC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__TAGS_ASC",
  S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__TAGS_DESC = "S3_AUDIT_DOCUMENTATION_BY_S3_AUDIT_DOCUMENTATION_ID__TAGS_DESC",
  S3_AUDIT_DOCUMENTATION_ID_ASC = "S3_AUDIT_DOCUMENTATION_ID_ASC",
  S3_AUDIT_DOCUMENTATION_ID_DESC = "S3_AUDIT_DOCUMENTATION_ID_DESC",
  STATUS_ASC = "STATUS_ASC",
  STATUS_DESC = "STATUS_DESC",
  SUCCESS_DETAILS_ASC = "SUCCESS_DETAILS_ASC",
  SUCCESS_DETAILS_DESC = "SUCCESS_DETAILS_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
  USERNAME_ASC = "USERNAME_ASC",
  USERNAME_DESC = "USERNAME_DESC",
}

export type Insurer = Node & {
  __typename?: "Insurer";
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  auditFindingReports: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  auditorBuckets: AuditorBucketsConnection;
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims: BatchClaimsConnection;
  /** Reads and enables pagination through a set of `BatchFile`. */
  batchFiles: BatchFilesConnection;
  /**
   * ### Overview
   * Identifies the set of claims for a given insurer that are assigned to the current auditor user.
   * The auditor user is identified using the uuid in the jwt of the active user, so this is meant to be called by a logged in user within the application.
   */
  claimsAssignedToAuditor: BatchClaimsConnection;
  clientAbbreviation: Scalars["String"]["output"];
  clientId?: Maybe<Scalars["UUID"]["output"]>;
  createdAt: Scalars["Datetime"]["output"];
  /** Reads a single `DefaultStrategyByInsurer` that is related to this `Insurer`. */
  defaultStrategyByInsurer?: Maybe<DefaultStrategyByInsurer>;
  /**
   * Reads and enables pagination through a set of `DefaultStrategyByInsurer`.
   * @deprecated Please use defaultStrategyByInsurer instead
   */
  defaultStrategyByInsurers: DefaultStrategyByInsurersConnection;
  id: Scalars["UUID"]["output"];
  /** Reads a single `InsurerClaimIntakeDropboxPermitted` that is related to this `Insurer`. */
  insurerClaimIntakeDropboxPermitted?: Maybe<InsurerClaimIntakeDropboxPermitted>;
  /**
   * Reads and enables pagination through a set of `InsurerClaimIntakeDropboxPermitted`.
   * @deprecated Please use insurerClaimIntakeDropboxPermitted instead
   */
  insurerClaimIntakeDropboxPermitteds: InsurerClaimIntakeDropboxPermittedsConnection;
  /** Reads and enables pagination through a set of `InsurerOrganization`. */
  insurerOrganizationsByMemberId: InsurerOrganizationsConnection;
  /** Reads and enables pagination through a set of `InsurerOrganization`. */
  insurerOrganizationsByOrganizationId: InsurerOrganizationsConnection;
  /** Reads and enables pagination through a set of `InsurerUser`. */
  insurerUsers: InsurerUsersConnection;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type InsurerauditFindingReportsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

export type InsurerauditorBucketsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketCondition>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

export type InsurerbatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimCondition>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

export type InsurerbatchFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchFileCondition>;
  filter?: InputMaybe<BatchFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchFilesOrderBy>>;
};

export type InsurerclaimsAssignedToAuditorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InsurerdefaultStrategyByInsurersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DefaultStrategyByInsurerCondition>;
  filter?: InputMaybe<DefaultStrategyByInsurerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DefaultStrategyByInsurersOrderBy>>;
};

export type InsurerinsurerClaimIntakeDropboxPermittedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerClaimIntakeDropboxPermittedCondition>;
  filter?: InputMaybe<InsurerClaimIntakeDropboxPermittedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedsOrderBy>>;
};

export type InsurerinsurerOrganizationsByMemberIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerOrganizationCondition>;
  filter?: InputMaybe<InsurerOrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerOrganizationsOrderBy>>;
};

export type InsurerinsurerOrganizationsByOrganizationIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerOrganizationCondition>;
  filter?: InputMaybe<InsurerOrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerOrganizationsOrderBy>>;
};

export type InsurerinsurerUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerUserCondition>;
  filter?: InputMaybe<InsurerUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerUsersOrderBy>>;
};

export type InsurerAggregates = {
  __typename?: "InsurerAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InsurerDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * # Overview
 * A join table that defines which insurer_ids are permitted to perform dropbox-style uploads to the alaffia-claim-intake bucket.
 */
export type InsurerClaimIntakeDropboxPermitted = {
  __typename?: "InsurerClaimIntakeDropboxPermitted";
  id?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `Insurer` that is related to this `InsurerClaimIntakeDropboxPermitted`. */
  insurer?: Maybe<Insurer>;
  insurerId?: Maybe<Scalars["UUID"]["output"]>;
};

export type InsurerClaimIntakeDropboxPermittedAggregates = {
  __typename?: "InsurerClaimIntakeDropboxPermittedAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InsurerClaimIntakeDropboxPermittedDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `InsurerClaimIntakeDropboxPermitted` object
 * types. All fields are tested for equality and combined with a logical ‘and.’
 */
export type InsurerClaimIntakeDropboxPermittedCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type InsurerClaimIntakeDropboxPermittedDistinctCountAggregates = {
  __typename?: "InsurerClaimIntakeDropboxPermittedDistinctCountAggregates";
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `InsurerClaimIntakeDropboxPermitted` object types. All fields are combined with a logical ‘and.’ */
export type InsurerClaimIntakeDropboxPermittedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedFilter>>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** A related `insurer` exists. */
  insurerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsurerClaimIntakeDropboxPermittedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedFilter>>;
};

/** Grouping methods for `InsurerClaimIntakeDropboxPermitted` for usage during aggregation. */
export enum InsurerClaimIntakeDropboxPermittedGroupBy {
  ID = "ID",
}

/** Conditions for `InsurerClaimIntakeDropboxPermitted` aggregates. */
export type InsurerClaimIntakeDropboxPermittedHavingInput = {
  AND?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedHavingInput>>;
  OR?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedHavingInput>>;
};

/** The fields on `insurerClaimIntakeDropboxPermitted` to look up the row to connect. */
export type InsurerClaimIntakeDropboxPermittedInsurerClaimIntakeDropboxPermittedInsurerIdKeyConnect =
  {
    insurerId: Scalars["UUID"]["input"];
  };

/** Input for the nested mutation of `insurer` in the `InsurerClaimIntakeDropboxPermittedInput` mutation. */
export type InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `insurerClaimIntakeDropboxPermitted` in the `InsurerInput` mutation. */
export type InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `insurerClaimIntakeDropboxPermitted` for the far side of the relationship. */
  connectByInsurerId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerClaimIntakeDropboxPermittedInsurerIdKeyConnect>;
  /** The primary key(s) and patch data for `insurerClaimIntakeDropboxPermitted` for the far side of the relationship. */
  updateByInsurerId?: InputMaybe<InsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerClaimIntakeDropboxPermittedInsurerIdKeyUpdate>;
};

/** The globally unique `ID` look up for the row to update. */
export type InsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `insurerClaimIntakeDropboxPermitted` to look up the row to update. */
export type InsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerClaimIntakeDropboxPermittedInsurerIdKeyUpdate =
  {
    insurerId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerClaimIntakeDropboxPermitted` being updated. */
    patch: updateInsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch;
  };

/** A connection to a list of `InsurerClaimIntakeDropboxPermitted` values. */
export type InsurerClaimIntakeDropboxPermittedsConnection = {
  __typename?: "InsurerClaimIntakeDropboxPermittedsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InsurerClaimIntakeDropboxPermittedAggregates>;
  /** A list of edges which contains the `InsurerClaimIntakeDropboxPermitted` and cursor to aid in pagination. */
  edges: Array<InsurerClaimIntakeDropboxPermittedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<
    Array<InsurerClaimIntakeDropboxPermittedAggregates>
  >;
  /** A list of `InsurerClaimIntakeDropboxPermitted` objects. */
  nodes: Array<Maybe<InsurerClaimIntakeDropboxPermitted>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsurerClaimIntakeDropboxPermitted` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `InsurerClaimIntakeDropboxPermitted` values. */
export type InsurerClaimIntakeDropboxPermittedsConnectiongroupedAggregatesArgs =
  {
    groupBy: Array<InsurerClaimIntakeDropboxPermittedGroupBy>;
    having?: InputMaybe<InsurerClaimIntakeDropboxPermittedHavingInput>;
  };

/** A `InsurerClaimIntakeDropboxPermitted` edge in the connection. */
export type InsurerClaimIntakeDropboxPermittedsEdge = {
  __typename?: "InsurerClaimIntakeDropboxPermittedsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `InsurerClaimIntakeDropboxPermitted` at the end of the edge. */
  node?: Maybe<InsurerClaimIntakeDropboxPermitted>;
};

/** Methods to use when ordering `InsurerClaimIntakeDropboxPermitted`. */
export enum InsurerClaimIntakeDropboxPermittedsOrderBy {
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NATURAL = "NATURAL",
}

/** A condition to be used against `Insurer` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type InsurerCondition = {
  /** Checks for equality with the object’s `clientAbbreviation` field. */
  clientAbbreviation?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `clientId` field. */
  clientId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type InsurerDistinctCountAggregates = {
  __typename?: "InsurerDistinctCountAggregates";
  /** Distinct count of clientAbbreviation across the matching connection */
  clientAbbreviation?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of clientId across the matching connection */
  clientId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Insurer` object types. All fields are combined with a logical ‘and.’ */
export type InsurerFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsurerFilter>>;
  /** Filter by the object’s `auditFindingReports` relation. */
  auditFindingReports?: InputMaybe<InsurerToManyAuditFindingReportFilter>;
  /** Some related `auditFindingReports` exist. */
  auditFindingReportsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditorBuckets` relation. */
  auditorBuckets?: InputMaybe<InsurerToManyAuditorBucketFilter>;
  /** Some related `auditorBuckets` exist. */
  auditorBucketsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaims` relation. */
  batchClaims?: InputMaybe<InsurerToManyBatchClaimFilter>;
  /** Some related `batchClaims` exist. */
  batchClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchFiles` relation. */
  batchFiles?: InputMaybe<InsurerToManyBatchFileFilter>;
  /** Some related `batchFiles` exist. */
  batchFilesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `clientAbbreviation` field. */
  clientAbbreviation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `clientId` field. */
  clientId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `defaultStrategyByInsurer` relation. */
  defaultStrategyByInsurer?: InputMaybe<DefaultStrategyByInsurerFilter>;
  /** A related `defaultStrategyByInsurer` exists. */
  defaultStrategyByInsurerExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurerClaimIntakeDropboxPermitted` relation. */
  insurerClaimIntakeDropboxPermitted?: InputMaybe<InsurerClaimIntakeDropboxPermittedFilter>;
  /** A related `insurerClaimIntakeDropboxPermitted` exists. */
  insurerClaimIntakeDropboxPermittedExists?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `insurerOrganizationsByMemberId` relation. */
  insurerOrganizationsByMemberId?: InputMaybe<InsurerToManyInsurerOrganizationFilter>;
  /** Some related `insurerOrganizationsByMemberId` exist. */
  insurerOrganizationsByMemberIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `insurerOrganizationsByOrganizationId` relation. */
  insurerOrganizationsByOrganizationId?: InputMaybe<InsurerToManyInsurerOrganizationFilter>;
  /** Some related `insurerOrganizationsByOrganizationId` exist. */
  insurerOrganizationsByOrganizationIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `insurerUsers` relation. */
  insurerUsers?: InputMaybe<InsurerToManyInsurerUserFilter>;
  /** Some related `insurerUsers` exist. */
  insurerUsersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsurerFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsurerFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Insurer` for usage during aggregation. */
export enum InsurerGroupBy {
  CLIENT_ID = "CLIENT_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type InsurerHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Insurer` aggregates. */
export type InsurerHavingInput = {
  AND?: InputMaybe<Array<InsurerHavingInput>>;
  OR?: InputMaybe<Array<InsurerHavingInput>>;
  average?: InputMaybe<InsurerHavingAverageInput>;
  distinctCount?: InputMaybe<InsurerHavingDistinctCountInput>;
  max?: InputMaybe<InsurerHavingMaxInput>;
  min?: InputMaybe<InsurerHavingMinInput>;
  stddevPopulation?: InputMaybe<InsurerHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InsurerHavingStddevSampleInput>;
  sum?: InputMaybe<InsurerHavingSumInput>;
  variancePopulation?: InputMaybe<InsurerHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InsurerHavingVarianceSampleInput>;
};

export type InsurerHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The fields on `insurer` to look up the row to connect. */
export type InsurerInsurerPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The fields on `insurer` to look up the row to connect. */
export type InsurerInsurerUniqueClientAbbreviationConnect = {
  clientAbbreviation: Scalars["String"]["input"];
};

/** The fields on `insurer` to look up the row to connect. */
export type InsurerInsurerUniqueNameConnect = {
  name: Scalars["String"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type InsurerNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `insurer` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorBucket` being updated. */
  patch: AuditorBucketPatch;
};

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnBatchClaimForBatchClaimInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchClaim` being updated. */
  patch: BatchClaimPatch;
};

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchClaimForBatchClaimInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchClaimForBatchClaimInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchClaimForBatchClaimInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchClaimForBatchClaimInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnBatchFileForBatchFileInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchFile` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchFile` being updated. */
  patch: BatchFilePatch;
};

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchFileForBatchFileInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchFileForBatchFileInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnBatchFileForBatchFileInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnBatchFileForBatchFileInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `defaultStrategyByInsurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `defaultStrategyByInsurer` being updated. */
    patch: DefaultStrategyByInsurerPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurerOrganization` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: InsurerOrganizationPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurerOrganization` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: InsurerOrganizationPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOnInsurerUserForInsurerUserInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insurerUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `insurerUser` being updated. */
  patch: InsurerUserPatch;
};

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerUserForInsurerUserInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate =
  {
    clientAbbreviation: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerUserForInsurerUserInsurerIdFkeyPatch;
  };

/** The fields on `insurer` to look up the row to update. */
export type InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUniqueNameUpdate =
  {
    name: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: updateInsurerOnInsurerUserForInsurerUserInsurerIdFkeyPatch;
  };

export type InsurerOrganization = Node & {
  __typename?: "InsurerOrganization";
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Insurer` that is related to this `InsurerOrganization`. */
  member?: Maybe<Insurer>;
  memberId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Insurer` that is related to this `InsurerOrganization`. */
  organization?: Maybe<Insurer>;
  organizationId: Scalars["UUID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type InsurerOrganizationAggregates = {
  __typename?: "InsurerOrganizationAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InsurerOrganizationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `InsurerOrganization` object types. */
export type InsurerOrganizationAggregatesFilter = {
  /** Distinct count aggregate over matching `InsurerOrganization` objects. */
  distinctCount?: InputMaybe<InsurerOrganizationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `InsurerOrganization` object to be included within the aggregate. */
  filter?: InputMaybe<InsurerOrganizationFilter>;
};

/**
 * A condition to be used against `InsurerOrganization` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type InsurerOrganizationCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `memberId` field. */
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type InsurerOrganizationDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  memberId?: InputMaybe<BigIntFilter>;
  organizationId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type InsurerOrganizationDistinctCountAggregates = {
  __typename?: "InsurerOrganizationDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of memberId across the matching connection */
  memberId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `InsurerOrganization` object types. All fields are combined with a logical ‘and.’ */
export type InsurerOrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsurerOrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `member` relation. */
  member?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `memberId` field. */
  memberId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsurerOrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsurerOrganizationFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `InsurerOrganization` for usage during aggregation. */
export enum InsurerOrganizationGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  MEMBER_ID = "MEMBER_ID",
  ORGANIZATION_ID = "ORGANIZATION_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type InsurerOrganizationHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `InsurerOrganization` aggregates. */
export type InsurerOrganizationHavingInput = {
  AND?: InputMaybe<Array<InsurerOrganizationHavingInput>>;
  OR?: InputMaybe<Array<InsurerOrganizationHavingInput>>;
  average?: InputMaybe<InsurerOrganizationHavingAverageInput>;
  distinctCount?: InputMaybe<InsurerOrganizationHavingDistinctCountInput>;
  max?: InputMaybe<InsurerOrganizationHavingMaxInput>;
  min?: InputMaybe<InsurerOrganizationHavingMinInput>;
  stddevPopulation?: InputMaybe<InsurerOrganizationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InsurerOrganizationHavingStddevSampleInput>;
  sum?: InputMaybe<InsurerOrganizationHavingSumInput>;
  variancePopulation?: InputMaybe<InsurerOrganizationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InsurerOrganizationHavingVarianceSampleInput>;
};

export type InsurerOrganizationHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerOrganizationHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The fields on `insurerOrganization` to look up the row to connect. */
export type InsurerOrganizationInsurerOrganizationMemberIdOrganizationIdKeyConnect =
  {
    memberId: Scalars["UUID"]["input"];
    organizationId: Scalars["UUID"]["input"];
  };

/** The fields on `insurerOrganization` to look up the row to connect. */
export type InsurerOrganizationInsurerOrganizationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** Input for the nested mutation of `insurer` in the `InsurerOrganizationInput` mutation. */
export type InsurerOrganizationMemberIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `insurerOrganization` in the `InsurerInput` mutation. */
export type InsurerOrganizationMemberIdFkeyInverseInput = {
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<InsurerOrganizationInsurerOrganizationPkeyConnect>
  >;
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectByMemberIdAndOrganizationId?: InputMaybe<
    Array<InsurerOrganizationInsurerOrganizationMemberIdOrganizationIdKeyConnect>
  >;
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsurerOrganizationNodeIdConnect>>;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerOrganizationPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateByMemberIdAndOrganizationId?: InputMaybe<
    Array<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerOrganizationMemberIdOrganizationIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyNodeIdUpdate>
  >;
};

/** The globally unique `ID` look up for the row to connect. */
export type InsurerOrganizationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `insurerOrganization` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `insurerOrganization` to look up the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerOrganizationMemberIdOrganizationIdKeyUpdate =
  {
    memberId: Scalars["UUID"]["input"];
    organizationId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch;
  };

/** The fields on `insurerOrganization` to look up the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyUsingInsurerOrganizationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `insurer` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `insurer` being updated. */
    patch: InsurerPatch;
  };

/** The fields on `insurerOrganization` to look up the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerOrganizationMemberIdOrganizationIdKeyUpdate =
  {
    memberId: Scalars["UUID"]["input"];
    organizationId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch;
  };

/** The fields on `insurerOrganization` to look up the row to update. */
export type InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerOrganizationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerOrganization` being updated. */
    patch: updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch;
  };

/** Input for the nested mutation of `insurer` in the `InsurerOrganizationInput` mutation. */
export type InsurerOrganizationOrganizationIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `insurerOrganization` in the `InsurerInput` mutation. */
export type InsurerOrganizationOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<InsurerOrganizationInsurerOrganizationPkeyConnect>
  >;
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectByMemberIdAndOrganizationId?: InputMaybe<
    Array<InsurerOrganizationInsurerOrganizationMemberIdOrganizationIdKeyConnect>
  >;
  /** The primary key(s) for `insurerOrganization` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsurerOrganizationNodeIdConnect>>;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerOrganizationPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateByMemberIdAndOrganizationId?: InputMaybe<
    Array<InsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyUsingInsurerOrganizationMemberIdOrganizationIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerOrganization` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyNodeIdUpdate>
  >;
};

/** Represents an update to a `InsurerOrganization`. Fields that are set will be updated. */
export type InsurerOrganizationPatch = {
  insurerToMemberId?: InputMaybe<InsurerOrganizationMemberIdFkeyInput>;
  insurerToOrganizationId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInput>;
  memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  organizationId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** A connection to a list of `InsurerOrganization` values. */
export type InsurerOrganizationsConnection = {
  __typename?: "InsurerOrganizationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InsurerOrganizationAggregates>;
  /** A list of edges which contains the `InsurerOrganization` and cursor to aid in pagination. */
  edges: Array<InsurerOrganizationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InsurerOrganizationAggregates>>;
  /** A list of `InsurerOrganization` objects. */
  nodes: Array<Maybe<InsurerOrganization>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsurerOrganization` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `InsurerOrganization` values. */
export type InsurerOrganizationsConnectiongroupedAggregatesArgs = {
  groupBy: Array<InsurerOrganizationGroupBy>;
  having?: InputMaybe<InsurerOrganizationHavingInput>;
};

/** A `InsurerOrganization` edge in the connection. */
export type InsurerOrganizationsEdge = {
  __typename?: "InsurerOrganizationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `InsurerOrganization` at the end of the edge. */
  node?: Maybe<InsurerOrganization>;
};

/** Methods to use when ordering `InsurerOrganization`. */
export enum InsurerOrganizationsOrderBy {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_MEMBER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_MEMBER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_MEMBER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_MEMBER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_MEMBER_ID__CLIENT_ID_ASC = "INSURER_BY_MEMBER_ID__CLIENT_ID_ASC",
  INSURER_BY_MEMBER_ID__CLIENT_ID_DESC = "INSURER_BY_MEMBER_ID__CLIENT_ID_DESC",
  INSURER_BY_MEMBER_ID__CREATED_AT_ASC = "INSURER_BY_MEMBER_ID__CREATED_AT_ASC",
  INSURER_BY_MEMBER_ID__CREATED_AT_DESC = "INSURER_BY_MEMBER_ID__CREATED_AT_DESC",
  INSURER_BY_MEMBER_ID__ID_ASC = "INSURER_BY_MEMBER_ID__ID_ASC",
  INSURER_BY_MEMBER_ID__ID_DESC = "INSURER_BY_MEMBER_ID__ID_DESC",
  INSURER_BY_MEMBER_ID__NAME_ASC = "INSURER_BY_MEMBER_ID__NAME_ASC",
  INSURER_BY_MEMBER_ID__NAME_DESC = "INSURER_BY_MEMBER_ID__NAME_DESC",
  INSURER_BY_MEMBER_ID__UPDATED_AT_ASC = "INSURER_BY_MEMBER_ID__UPDATED_AT_ASC",
  INSURER_BY_MEMBER_ID__UPDATED_AT_DESC = "INSURER_BY_MEMBER_ID__UPDATED_AT_DESC",
  INSURER_BY_ORGANIZATION_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_ORGANIZATION_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_ORGANIZATION_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_ORGANIZATION_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_ORGANIZATION_ID__CLIENT_ID_ASC = "INSURER_BY_ORGANIZATION_ID__CLIENT_ID_ASC",
  INSURER_BY_ORGANIZATION_ID__CLIENT_ID_DESC = "INSURER_BY_ORGANIZATION_ID__CLIENT_ID_DESC",
  INSURER_BY_ORGANIZATION_ID__CREATED_AT_ASC = "INSURER_BY_ORGANIZATION_ID__CREATED_AT_ASC",
  INSURER_BY_ORGANIZATION_ID__CREATED_AT_DESC = "INSURER_BY_ORGANIZATION_ID__CREATED_AT_DESC",
  INSURER_BY_ORGANIZATION_ID__ID_ASC = "INSURER_BY_ORGANIZATION_ID__ID_ASC",
  INSURER_BY_ORGANIZATION_ID__ID_DESC = "INSURER_BY_ORGANIZATION_ID__ID_DESC",
  INSURER_BY_ORGANIZATION_ID__NAME_ASC = "INSURER_BY_ORGANIZATION_ID__NAME_ASC",
  INSURER_BY_ORGANIZATION_ID__NAME_DESC = "INSURER_BY_ORGANIZATION_ID__NAME_DESC",
  INSURER_BY_ORGANIZATION_ID__UPDATED_AT_ASC = "INSURER_BY_ORGANIZATION_ID__UPDATED_AT_ASC",
  INSURER_BY_ORGANIZATION_ID__UPDATED_AT_DESC = "INSURER_BY_ORGANIZATION_ID__UPDATED_AT_DESC",
  MEMBER_ID_ASC = "MEMBER_ID_ASC",
  MEMBER_ID_DESC = "MEMBER_ID_DESC",
  NATURAL = "NATURAL",
  ORGANIZATION_ID_ASC = "ORGANIZATION_ID_ASC",
  ORGANIZATION_ID_DESC = "ORGANIZATION_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** Represents an update to a `Insurer`. Fields that are set will be updated. */
export type InsurerPatch = {
  auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
  defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
  insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
  insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
  insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
  insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
};

/** A filter to be used against many `AuditFindingReport` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyAuditFindingReportFilter = {
  /** Aggregates across related `AuditFindingReport` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReportAggregatesFilter>;
  /** Every related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReportFilter>;
  /** No related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReportFilter>;
  /** Some related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReportFilter>;
};

/** A filter to be used against many `AuditorBucket` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyAuditorBucketFilter = {
  /** Aggregates across related `AuditorBucket` match the filter criteria. */
  aggregates?: InputMaybe<AuditorBucketAggregatesFilter>;
  /** Every related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorBucketFilter>;
  /** No related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorBucketFilter>;
  /** Some related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorBucketFilter>;
};

/** A filter to be used against many `BatchClaim` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyBatchClaimFilter = {
  /** Aggregates across related `BatchClaim` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAggregatesFilter>;
  /** Every related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimFilter>;
  /** No related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimFilter>;
  /** Some related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimFilter>;
};

/** A filter to be used against many `BatchFile` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyBatchFileFilter = {
  /** Aggregates across related `BatchFile` match the filter criteria. */
  aggregates?: InputMaybe<BatchFileAggregatesFilter>;
  /** Every related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchFileFilter>;
  /** No related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchFileFilter>;
  /** Some related `BatchFile` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchFileFilter>;
};

/** A filter to be used against many `InsurerOrganization` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyInsurerOrganizationFilter = {
  /** Aggregates across related `InsurerOrganization` match the filter criteria. */
  aggregates?: InputMaybe<InsurerOrganizationAggregatesFilter>;
  /** Every related `InsurerOrganization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<InsurerOrganizationFilter>;
  /** No related `InsurerOrganization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<InsurerOrganizationFilter>;
  /** Some related `InsurerOrganization` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<InsurerOrganizationFilter>;
};

/** A filter to be used against many `InsurerUser` object types. All fields are combined with a logical ‘and.’ */
export type InsurerToManyInsurerUserFilter = {
  /** Aggregates across related `InsurerUser` match the filter criteria. */
  aggregates?: InputMaybe<InsurerUserAggregatesFilter>;
  /** Every related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<InsurerUserFilter>;
  /** No related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<InsurerUserFilter>;
  /** Some related `InsurerUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<InsurerUserFilter>;
};

export type InsurerUser = Node & {
  __typename?: "InsurerUser";
  /** Reads a single `AuditorUser` that is related to this `InsurerUser`. */
  auditorUser?: Maybe<AuditorUser>;
  auditorUserId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `Insurer` that is related to this `InsurerUser`. */
  insurer?: Maybe<Insurer>;
  insurerId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type InsurerUserAggregates = {
  __typename?: "InsurerUserAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InsurerUserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `InsurerUser` object types. */
export type InsurerUserAggregatesFilter = {
  /** Distinct count aggregate over matching `InsurerUser` objects. */
  distinctCount?: InputMaybe<InsurerUserDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `InsurerUser` object to be included within the aggregate. */
  filter?: InputMaybe<InsurerUserFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type InsurerUserAuditorUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `InsurerUserInput` mutation. */
export type InsurerUserAuditorUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<InsurerUserAuditorUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyNodeIdUpdate>;
};

/** The `insurerUser` to be created by this mutation. */
export type InsurerUserAuditorUserIdFkeyInsurerUserCreateInput = {
  auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `insurerUser` in the `AuditorUserInput` mutation. */
export type InsurerUserAuditorUserIdFkeyInverseInput = {
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectByAuditorUserIdAndInsurerId?: InputMaybe<
    Array<InsurerUserInsurerUserAuditorUserIdInsurerIdKeyConnect>
  >;
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<InsurerUserInsurerUserPkeyConnect>>;
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsurerUserNodeIdConnect>>;
  /** A `InsurerUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<InsurerUserAuditorUserIdFkeyInsurerUserCreateInput>
  >;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateByAuditorUserIdAndInsurerId?: InputMaybe<
    Array<InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingInsurerUserAuditorUserIdInsurerIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingInsurerUserPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `InsurerUser` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type InsurerUserCondition = {
  /** Checks for equality with the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `insurerId` field. */
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type InsurerUserDistinctCountAggregateFilter = {
  auditorUserId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  insurerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type InsurerUserDistinctCountAggregates = {
  __typename?: "InsurerUserDistinctCountAggregates";
  /** Distinct count of auditorUserId across the matching connection */
  auditorUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of insurerId across the matching connection */
  insurerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `InsurerUser` object types. All fields are combined with a logical ‘and.’ */
export type InsurerUserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InsurerUserFilter>>;
  /** Filter by the object’s `auditorUser` relation. */
  auditorUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `insurer` relation. */
  insurer?: InputMaybe<InsurerFilter>;
  /** Filter by the object’s `insurerId` field. */
  insurerId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InsurerUserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InsurerUserFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `InsurerUser` for usage during aggregation. */
export enum InsurerUserGroupBy {
  AUDITOR_USER_ID = "AUDITOR_USER_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  INSURER_ID = "INSURER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type InsurerUserHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `InsurerUser` aggregates. */
export type InsurerUserHavingInput = {
  AND?: InputMaybe<Array<InsurerUserHavingInput>>;
  OR?: InputMaybe<Array<InsurerUserHavingInput>>;
  average?: InputMaybe<InsurerUserHavingAverageInput>;
  distinctCount?: InputMaybe<InsurerUserHavingDistinctCountInput>;
  max?: InputMaybe<InsurerUserHavingMaxInput>;
  min?: InputMaybe<InsurerUserHavingMinInput>;
  stddevPopulation?: InputMaybe<InsurerUserHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InsurerUserHavingStddevSampleInput>;
  sum?: InputMaybe<InsurerUserHavingSumInput>;
  variancePopulation?: InputMaybe<InsurerUserHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InsurerUserHavingVarianceSampleInput>;
};

export type InsurerUserHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InsurerUserHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `InsurerUser` */
export type InsurerUserInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `insurer` in the `InsurerUserInput` mutation. */
export type InsurerUserInsurerIdFkeyInput = {
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByClientAbbreviation?: InputMaybe<InsurerInsurerUniqueClientAbbreviationConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectById?: InputMaybe<InsurerInsurerPkeyConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByName?: InputMaybe<InsurerInsurerUniqueNameConnect>;
  /** The primary key(s) for `insurer` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<InsurerNodeIdConnect>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByClientAbbreviation?: InputMaybe<InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUniqueClientAbbreviationUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateById?: InputMaybe<InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerPkeyUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByName?: InputMaybe<InsurerOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUniqueNameUpdate>;
  /** The primary key(s) and patch data for `insurer` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyNodeIdUpdate>;
};

/** The `insurerUser` to be created by this mutation. */
export type InsurerUserInsurerIdFkeyInsurerUserCreateInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `insurerUser` in the `InsurerInput` mutation. */
export type InsurerUserInsurerIdFkeyInverseInput = {
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectByAuditorUserIdAndInsurerId?: InputMaybe<
    Array<InsurerUserInsurerUserAuditorUserIdInsurerIdKeyConnect>
  >;
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectById?: InputMaybe<Array<InsurerUserInsurerUserPkeyConnect>>;
  /** The primary key(s) for `insurerUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InsurerUserNodeIdConnect>>;
  /** A `InsurerUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<InsurerUserInsurerIdFkeyInsurerUserCreateInput>>;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateByAuditorUserIdAndInsurerId?: InputMaybe<
    Array<InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUserAuditorUserIdInsurerIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUserPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `insurerUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<InsurerOnInsurerUserForInsurerUserInsurerIdFkeyNodeIdUpdate>
  >;
};

/** The fields on `insurerUser` to look up the row to connect. */
export type InsurerUserInsurerUserAuditorUserIdInsurerIdKeyConnect = {
  auditorUserId: Scalars["UUID"]["input"];
  insurerId: Scalars["UUID"]["input"];
};

/** The fields on `insurerUser` to look up the row to connect. */
export type InsurerUserInsurerUserPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type InsurerUserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `insurerUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `insurerUser` to look up the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingInsurerUserAuditorUserIdInsurerIdKeyUpdate =
  {
    auditorUserId: Scalars["UUID"]["input"];
    insurerId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerUser` being updated. */
    patch: updateInsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch;
  };

/** The fields on `insurerUser` to look up the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyUsingInsurerUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerUser` being updated. */
    patch: updateInsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `insurer` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `insurer` being updated. */
  patch: InsurerPatch;
};

/** The fields on `insurerUser` to look up the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUserAuditorUserIdInsurerIdKeyUpdate =
  {
    auditorUserId: Scalars["UUID"]["input"];
    insurerId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerUser` being updated. */
    patch: updateInsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyPatch;
  };

/** The fields on `insurerUser` to look up the row to update. */
export type InsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyUsingInsurerUserPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `insurerUser` being updated. */
    patch: updateInsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyPatch;
  };

/** Represents an update to a `InsurerUser`. Fields that are set will be updated. */
export type InsurerUserPatch = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
};

/** A connection to a list of `InsurerUser` values. */
export type InsurerUsersConnection = {
  __typename?: "InsurerUsersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InsurerUserAggregates>;
  /** A list of edges which contains the `InsurerUser` and cursor to aid in pagination. */
  edges: Array<InsurerUsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InsurerUserAggregates>>;
  /** A list of `InsurerUser` objects. */
  nodes: Array<Maybe<InsurerUser>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InsurerUser` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `InsurerUser` values. */
export type InsurerUsersConnectiongroupedAggregatesArgs = {
  groupBy: Array<InsurerUserGroupBy>;
  having?: InputMaybe<InsurerUserHavingInput>;
};

/** A `InsurerUser` edge in the connection. */
export type InsurerUsersEdge = {
  __typename?: "InsurerUsersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `InsurerUser` at the end of the edge. */
  node?: Maybe<InsurerUser>;
};

/** Methods to use when ordering `InsurerUser`. */
export enum InsurerUsersOrderBy {
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC",
  AUDITOR_USER_ID_ASC = "AUDITOR_USER_ID_ASC",
  AUDITOR_USER_ID_DESC = "AUDITOR_USER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC = "INSURER_BY_INSURER_ID__CLIENT_ABBREVIATION_DESC",
  INSURER_BY_INSURER_ID__CLIENT_ID_ASC = "INSURER_BY_INSURER_ID__CLIENT_ID_ASC",
  INSURER_BY_INSURER_ID__CLIENT_ID_DESC = "INSURER_BY_INSURER_ID__CLIENT_ID_DESC",
  INSURER_BY_INSURER_ID__CREATED_AT_ASC = "INSURER_BY_INSURER_ID__CREATED_AT_ASC",
  INSURER_BY_INSURER_ID__CREATED_AT_DESC = "INSURER_BY_INSURER_ID__CREATED_AT_DESC",
  INSURER_BY_INSURER_ID__ID_ASC = "INSURER_BY_INSURER_ID__ID_ASC",
  INSURER_BY_INSURER_ID__ID_DESC = "INSURER_BY_INSURER_ID__ID_DESC",
  INSURER_BY_INSURER_ID__NAME_ASC = "INSURER_BY_INSURER_ID__NAME_ASC",
  INSURER_BY_INSURER_ID__NAME_DESC = "INSURER_BY_INSURER_ID__NAME_DESC",
  INSURER_BY_INSURER_ID__UPDATED_AT_ASC = "INSURER_BY_INSURER_ID__UPDATED_AT_ASC",
  INSURER_BY_INSURER_ID__UPDATED_AT_DESC = "INSURER_BY_INSURER_ID__UPDATED_AT_DESC",
  INSURER_ID_ASC = "INSURER_ID_ASC",
  INSURER_ID_DESC = "INSURER_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** A connection to a list of `Insurer` values. */
export type InsurersConnection = {
  __typename?: "InsurersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InsurerAggregates>;
  /** A list of edges which contains the `Insurer` and cursor to aid in pagination. */
  edges: Array<InsurersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InsurerAggregates>>;
  /** A list of `Insurer` objects. */
  nodes: Array<Maybe<Insurer>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Insurer` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Insurer` values. */
export type InsurersConnectiongroupedAggregatesArgs = {
  groupBy: Array<InsurerGroupBy>;
  having?: InputMaybe<InsurerHavingInput>;
};

/** A `Insurer` edge in the connection. */
export type InsurersEdge = {
  __typename?: "InsurersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Insurer` at the end of the edge. */
  node?: Maybe<Insurer>;
};

/** Methods to use when ordering `Insurer`. */
export enum InsurersOrderBy {
  AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC = "AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC = "AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC = "AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC = "AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_AVERAGE_FILTER_ASC = "AUDITOR_BUCKETS_AVERAGE_FILTER_ASC",
  AUDITOR_BUCKETS_AVERAGE_FILTER_DESC = "AUDITOR_BUCKETS_AVERAGE_FILTER_DESC",
  AUDITOR_BUCKETS_AVERAGE_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_NAME_ASC = "AUDITOR_BUCKETS_AVERAGE_NAME_ASC",
  AUDITOR_BUCKETS_AVERAGE_NAME_DESC = "AUDITOR_BUCKETS_AVERAGE_NAME_DESC",
  AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_INSURER_ID__COUNT_ASC = "AUDITOR_BUCKETS_BY_INSURER_ID__COUNT_ASC",
  AUDITOR_BUCKETS_BY_INSURER_ID__COUNT_DESC = "AUDITOR_BUCKETS_BY_INSURER_ID__COUNT_DESC",
  AUDITOR_BUCKETS_COUNT_ASC = "AUDITOR_BUCKETS_COUNT_ASC",
  AUDITOR_BUCKETS_COUNT_DESC = "AUDITOR_BUCKETS_COUNT_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_MAX_CREATED_AT_ASC = "AUDITOR_BUCKETS_MAX_CREATED_AT_ASC",
  AUDITOR_BUCKETS_MAX_CREATED_AT_DESC = "AUDITOR_BUCKETS_MAX_CREATED_AT_DESC",
  AUDITOR_BUCKETS_MAX_DUE_DATE_ASC = "AUDITOR_BUCKETS_MAX_DUE_DATE_ASC",
  AUDITOR_BUCKETS_MAX_DUE_DATE_DESC = "AUDITOR_BUCKETS_MAX_DUE_DATE_DESC",
  AUDITOR_BUCKETS_MAX_FILTER_ASC = "AUDITOR_BUCKETS_MAX_FILTER_ASC",
  AUDITOR_BUCKETS_MAX_FILTER_DESC = "AUDITOR_BUCKETS_MAX_FILTER_DESC",
  AUDITOR_BUCKETS_MAX_ID_ASC = "AUDITOR_BUCKETS_MAX_ID_ASC",
  AUDITOR_BUCKETS_MAX_ID_DESC = "AUDITOR_BUCKETS_MAX_ID_DESC",
  AUDITOR_BUCKETS_MAX_INSURER_ID_ASC = "AUDITOR_BUCKETS_MAX_INSURER_ID_ASC",
  AUDITOR_BUCKETS_MAX_INSURER_ID_DESC = "AUDITOR_BUCKETS_MAX_INSURER_ID_DESC",
  AUDITOR_BUCKETS_MAX_NAME_ASC = "AUDITOR_BUCKETS_MAX_NAME_ASC",
  AUDITOR_BUCKETS_MAX_NAME_DESC = "AUDITOR_BUCKETS_MAX_NAME_DESC",
  AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC = "AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC = "AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_MIN_CREATED_AT_ASC = "AUDITOR_BUCKETS_MIN_CREATED_AT_ASC",
  AUDITOR_BUCKETS_MIN_CREATED_AT_DESC = "AUDITOR_BUCKETS_MIN_CREATED_AT_DESC",
  AUDITOR_BUCKETS_MIN_DUE_DATE_ASC = "AUDITOR_BUCKETS_MIN_DUE_DATE_ASC",
  AUDITOR_BUCKETS_MIN_DUE_DATE_DESC = "AUDITOR_BUCKETS_MIN_DUE_DATE_DESC",
  AUDITOR_BUCKETS_MIN_FILTER_ASC = "AUDITOR_BUCKETS_MIN_FILTER_ASC",
  AUDITOR_BUCKETS_MIN_FILTER_DESC = "AUDITOR_BUCKETS_MIN_FILTER_DESC",
  AUDITOR_BUCKETS_MIN_ID_ASC = "AUDITOR_BUCKETS_MIN_ID_ASC",
  AUDITOR_BUCKETS_MIN_ID_DESC = "AUDITOR_BUCKETS_MIN_ID_DESC",
  AUDITOR_BUCKETS_MIN_INSURER_ID_ASC = "AUDITOR_BUCKETS_MIN_INSURER_ID_ASC",
  AUDITOR_BUCKETS_MIN_INSURER_ID_DESC = "AUDITOR_BUCKETS_MIN_INSURER_ID_DESC",
  AUDITOR_BUCKETS_MIN_NAME_ASC = "AUDITOR_BUCKETS_MIN_NAME_ASC",
  AUDITOR_BUCKETS_MIN_NAME_DESC = "AUDITOR_BUCKETS_MIN_NAME_DESC",
  AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC = "AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC = "AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_SUM_CREATED_AT_ASC = "AUDITOR_BUCKETS_SUM_CREATED_AT_ASC",
  AUDITOR_BUCKETS_SUM_CREATED_AT_DESC = "AUDITOR_BUCKETS_SUM_CREATED_AT_DESC",
  AUDITOR_BUCKETS_SUM_DUE_DATE_ASC = "AUDITOR_BUCKETS_SUM_DUE_DATE_ASC",
  AUDITOR_BUCKETS_SUM_DUE_DATE_DESC = "AUDITOR_BUCKETS_SUM_DUE_DATE_DESC",
  AUDITOR_BUCKETS_SUM_FILTER_ASC = "AUDITOR_BUCKETS_SUM_FILTER_ASC",
  AUDITOR_BUCKETS_SUM_FILTER_DESC = "AUDITOR_BUCKETS_SUM_FILTER_DESC",
  AUDITOR_BUCKETS_SUM_ID_ASC = "AUDITOR_BUCKETS_SUM_ID_ASC",
  AUDITOR_BUCKETS_SUM_ID_DESC = "AUDITOR_BUCKETS_SUM_ID_DESC",
  AUDITOR_BUCKETS_SUM_INSURER_ID_ASC = "AUDITOR_BUCKETS_SUM_INSURER_ID_ASC",
  AUDITOR_BUCKETS_SUM_INSURER_ID_DESC = "AUDITOR_BUCKETS_SUM_INSURER_ID_DESC",
  AUDITOR_BUCKETS_SUM_NAME_ASC = "AUDITOR_BUCKETS_SUM_NAME_ASC",
  AUDITOR_BUCKETS_SUM_NAME_DESC = "AUDITOR_BUCKETS_SUM_NAME_DESC",
  AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC = "AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC = "AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_INSURER_ID__COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_INSURER_ID__COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_INSURER_ID__COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_INSURER_ID__COUNT_DESC",
  AUDIT_FINDING_REPORTS_COUNT_ASC = "AUDIT_FINDING_REPORTS_COUNT_ASC",
  AUDIT_FINDING_REPORTS_COUNT_DESC = "AUDIT_FINDING_REPORTS_COUNT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MAX_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC = "AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC = "AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_MAX_TITLE_ASC = "AUDIT_FINDING_REPORTS_MAX_TITLE_ASC",
  AUDIT_FINDING_REPORTS_MAX_TITLE_DESC = "AUDIT_FINDING_REPORTS_MAX_TITLE_DESC",
  AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MIN_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC = "AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC = "AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_MIN_TITLE_ASC = "AUDIT_FINDING_REPORTS_MIN_TITLE_ASC",
  AUDIT_FINDING_REPORTS_MIN_TITLE_DESC = "AUDIT_FINDING_REPORTS_MIN_TITLE_DESC",
  AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_SUM_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC = "AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC = "AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_SUM_TITLE_ASC = "AUDIT_FINDING_REPORTS_SUM_TITLE_ASC",
  AUDIT_FINDING_REPORTS_SUM_TITLE_DESC = "AUDIT_FINDING_REPORTS_SUM_TITLE_DESC",
  AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_AVERAGE_ICN_ASC = "BATCH_CLAIMS_AVERAGE_ICN_ASC",
  BATCH_CLAIMS_AVERAGE_ICN_DESC = "BATCH_CLAIMS_AVERAGE_ICN_DESC",
  BATCH_CLAIMS_AVERAGE_ID_ASC = "BATCH_CLAIMS_AVERAGE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_ID_DESC = "BATCH_CLAIMS_AVERAGE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIMS_BY_INSURER_ID__COUNT_ASC = "BATCH_CLAIMS_BY_INSURER_ID__COUNT_ASC",
  BATCH_CLAIMS_BY_INSURER_ID__COUNT_DESC = "BATCH_CLAIMS_BY_INSURER_ID__COUNT_DESC",
  BATCH_CLAIMS_COUNT_ASC = "BATCH_CLAIMS_COUNT_ASC",
  BATCH_CLAIMS_COUNT_DESC = "BATCH_CLAIMS_COUNT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIMS_MAX_ADM_TYPE_ASC = "BATCH_CLAIMS_MAX_ADM_TYPE_ASC",
  BATCH_CLAIMS_MAX_ADM_TYPE_DESC = "BATCH_CLAIMS_MAX_ADM_TYPE_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIMS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIMS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MAX_BILL_TYPE_ASC = "BATCH_CLAIMS_MAX_BILL_TYPE_ASC",
  BATCH_CLAIMS_MAX_BILL_TYPE_DESC = "BATCH_CLAIMS_MAX_BILL_TYPE_DESC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MAX_CREATED_AT_ASC = "BATCH_CLAIMS_MAX_CREATED_AT_ASC",
  BATCH_CLAIMS_MAX_CREATED_AT_DESC = "BATCH_CLAIMS_MAX_CREATED_AT_DESC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_ASC = "BATCH_CLAIMS_MAX_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_DESC = "BATCH_CLAIMS_MAX_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MAX_ICN_ASC = "BATCH_CLAIMS_MAX_ICN_ASC",
  BATCH_CLAIMS_MAX_ICN_DESC = "BATCH_CLAIMS_MAX_ICN_DESC",
  BATCH_CLAIMS_MAX_ID_ASC = "BATCH_CLAIMS_MAX_ID_ASC",
  BATCH_CLAIMS_MAX_ID_DESC = "BATCH_CLAIMS_MAX_ID_DESC",
  BATCH_CLAIMS_MAX_INSURER_ID_ASC = "BATCH_CLAIMS_MAX_INSURER_ID_ASC",
  BATCH_CLAIMS_MAX_INSURER_ID_DESC = "BATCH_CLAIMS_MAX_INSURER_ID_DESC",
  BATCH_CLAIMS_MAX_OWNER_ID_ASC = "BATCH_CLAIMS_MAX_OWNER_ID_ASC",
  BATCH_CLAIMS_MAX_OWNER_ID_DESC = "BATCH_CLAIMS_MAX_OWNER_ID_DESC",
  BATCH_CLAIMS_MAX_PAID_DATE_ASC = "BATCH_CLAIMS_MAX_PAID_DATE_ASC",
  BATCH_CLAIMS_MAX_PAID_DATE_DESC = "BATCH_CLAIMS_MAX_PAID_DATE_DESC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_ASC = "BATCH_CLAIMS_MAX_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_DESC = "BATCH_CLAIMS_MAX_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MAX_PATIENT_ID_ASC = "BATCH_CLAIMS_MAX_PATIENT_ID_ASC",
  BATCH_CLAIMS_MAX_PATIENT_ID_DESC = "BATCH_CLAIMS_MAX_PATIENT_ID_DESC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_ASC = "BATCH_CLAIMS_MAX_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_DESC = "BATCH_CLAIMS_MAX_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MAX_UPDATED_AT_ASC = "BATCH_CLAIMS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIMS_MAX_UPDATED_AT_DESC = "BATCH_CLAIMS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIMS_MIN_ADM_TYPE_ASC = "BATCH_CLAIMS_MIN_ADM_TYPE_ASC",
  BATCH_CLAIMS_MIN_ADM_TYPE_DESC = "BATCH_CLAIMS_MIN_ADM_TYPE_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIMS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIMS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MIN_BILL_TYPE_ASC = "BATCH_CLAIMS_MIN_BILL_TYPE_ASC",
  BATCH_CLAIMS_MIN_BILL_TYPE_DESC = "BATCH_CLAIMS_MIN_BILL_TYPE_DESC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MIN_CREATED_AT_ASC = "BATCH_CLAIMS_MIN_CREATED_AT_ASC",
  BATCH_CLAIMS_MIN_CREATED_AT_DESC = "BATCH_CLAIMS_MIN_CREATED_AT_DESC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_ASC = "BATCH_CLAIMS_MIN_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_DESC = "BATCH_CLAIMS_MIN_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MIN_ICN_ASC = "BATCH_CLAIMS_MIN_ICN_ASC",
  BATCH_CLAIMS_MIN_ICN_DESC = "BATCH_CLAIMS_MIN_ICN_DESC",
  BATCH_CLAIMS_MIN_ID_ASC = "BATCH_CLAIMS_MIN_ID_ASC",
  BATCH_CLAIMS_MIN_ID_DESC = "BATCH_CLAIMS_MIN_ID_DESC",
  BATCH_CLAIMS_MIN_INSURER_ID_ASC = "BATCH_CLAIMS_MIN_INSURER_ID_ASC",
  BATCH_CLAIMS_MIN_INSURER_ID_DESC = "BATCH_CLAIMS_MIN_INSURER_ID_DESC",
  BATCH_CLAIMS_MIN_OWNER_ID_ASC = "BATCH_CLAIMS_MIN_OWNER_ID_ASC",
  BATCH_CLAIMS_MIN_OWNER_ID_DESC = "BATCH_CLAIMS_MIN_OWNER_ID_DESC",
  BATCH_CLAIMS_MIN_PAID_DATE_ASC = "BATCH_CLAIMS_MIN_PAID_DATE_ASC",
  BATCH_CLAIMS_MIN_PAID_DATE_DESC = "BATCH_CLAIMS_MIN_PAID_DATE_DESC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_ASC = "BATCH_CLAIMS_MIN_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_DESC = "BATCH_CLAIMS_MIN_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MIN_PATIENT_ID_ASC = "BATCH_CLAIMS_MIN_PATIENT_ID_ASC",
  BATCH_CLAIMS_MIN_PATIENT_ID_DESC = "BATCH_CLAIMS_MIN_PATIENT_ID_DESC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_ASC = "BATCH_CLAIMS_MIN_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_DESC = "BATCH_CLAIMS_MIN_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MIN_UPDATED_AT_ASC = "BATCH_CLAIMS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIMS_MIN_UPDATED_AT_DESC = "BATCH_CLAIMS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIMS_SUM_ADM_TYPE_ASC = "BATCH_CLAIMS_SUM_ADM_TYPE_ASC",
  BATCH_CLAIMS_SUM_ADM_TYPE_DESC = "BATCH_CLAIMS_SUM_ADM_TYPE_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIMS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIMS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_SUM_BILL_TYPE_ASC = "BATCH_CLAIMS_SUM_BILL_TYPE_ASC",
  BATCH_CLAIMS_SUM_BILL_TYPE_DESC = "BATCH_CLAIMS_SUM_BILL_TYPE_DESC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_SUM_CREATED_AT_ASC = "BATCH_CLAIMS_SUM_CREATED_AT_ASC",
  BATCH_CLAIMS_SUM_CREATED_AT_DESC = "BATCH_CLAIMS_SUM_CREATED_AT_DESC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_ASC = "BATCH_CLAIMS_SUM_DATE_ADMIT_ASC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_DESC = "BATCH_CLAIMS_SUM_DATE_ADMIT_DESC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_SUM_ICN_ASC = "BATCH_CLAIMS_SUM_ICN_ASC",
  BATCH_CLAIMS_SUM_ICN_DESC = "BATCH_CLAIMS_SUM_ICN_DESC",
  BATCH_CLAIMS_SUM_ID_ASC = "BATCH_CLAIMS_SUM_ID_ASC",
  BATCH_CLAIMS_SUM_ID_DESC = "BATCH_CLAIMS_SUM_ID_DESC",
  BATCH_CLAIMS_SUM_INSURER_ID_ASC = "BATCH_CLAIMS_SUM_INSURER_ID_ASC",
  BATCH_CLAIMS_SUM_INSURER_ID_DESC = "BATCH_CLAIMS_SUM_INSURER_ID_DESC",
  BATCH_CLAIMS_SUM_OWNER_ID_ASC = "BATCH_CLAIMS_SUM_OWNER_ID_ASC",
  BATCH_CLAIMS_SUM_OWNER_ID_DESC = "BATCH_CLAIMS_SUM_OWNER_ID_DESC",
  BATCH_CLAIMS_SUM_PAID_DATE_ASC = "BATCH_CLAIMS_SUM_PAID_DATE_ASC",
  BATCH_CLAIMS_SUM_PAID_DATE_DESC = "BATCH_CLAIMS_SUM_PAID_DATE_DESC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_ASC = "BATCH_CLAIMS_SUM_PATIENT_DOB_ASC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_DESC = "BATCH_CLAIMS_SUM_PATIENT_DOB_DESC",
  BATCH_CLAIMS_SUM_PATIENT_ID_ASC = "BATCH_CLAIMS_SUM_PATIENT_ID_ASC",
  BATCH_CLAIMS_SUM_PATIENT_ID_DESC = "BATCH_CLAIMS_SUM_PATIENT_ID_DESC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_ASC = "BATCH_CLAIMS_SUM_PROVIDER_ID_ASC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_DESC = "BATCH_CLAIMS_SUM_PROVIDER_ID_DESC",
  BATCH_CLAIMS_SUM_UPDATED_AT_ASC = "BATCH_CLAIMS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIMS_SUM_UPDATED_AT_DESC = "BATCH_CLAIMS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  BATCH_FILES_AVERAGE_AUTHOR_ID_ASC = "BATCH_FILES_AVERAGE_AUTHOR_ID_ASC",
  BATCH_FILES_AVERAGE_AUTHOR_ID_DESC = "BATCH_FILES_AVERAGE_AUTHOR_ID_DESC",
  BATCH_FILES_AVERAGE_BATCH_FILE_NAME_ASC = "BATCH_FILES_AVERAGE_BATCH_FILE_NAME_ASC",
  BATCH_FILES_AVERAGE_BATCH_FILE_NAME_DESC = "BATCH_FILES_AVERAGE_BATCH_FILE_NAME_DESC",
  BATCH_FILES_AVERAGE_BATCH_FILE_URL_ASC = "BATCH_FILES_AVERAGE_BATCH_FILE_URL_ASC",
  BATCH_FILES_AVERAGE_BATCH_FILE_URL_DESC = "BATCH_FILES_AVERAGE_BATCH_FILE_URL_DESC",
  BATCH_FILES_AVERAGE_BATCH_NAME_ASC = "BATCH_FILES_AVERAGE_BATCH_NAME_ASC",
  BATCH_FILES_AVERAGE_BATCH_NAME_DESC = "BATCH_FILES_AVERAGE_BATCH_NAME_DESC",
  BATCH_FILES_AVERAGE_CREATED_AT_ASC = "BATCH_FILES_AVERAGE_CREATED_AT_ASC",
  BATCH_FILES_AVERAGE_CREATED_AT_DESC = "BATCH_FILES_AVERAGE_CREATED_AT_DESC",
  BATCH_FILES_AVERAGE_ID_ASC = "BATCH_FILES_AVERAGE_ID_ASC",
  BATCH_FILES_AVERAGE_ID_DESC = "BATCH_FILES_AVERAGE_ID_DESC",
  BATCH_FILES_AVERAGE_INSURER_ID_ASC = "BATCH_FILES_AVERAGE_INSURER_ID_ASC",
  BATCH_FILES_AVERAGE_INSURER_ID_DESC = "BATCH_FILES_AVERAGE_INSURER_ID_DESC",
  BATCH_FILES_AVERAGE_OWNER_ID_ASC = "BATCH_FILES_AVERAGE_OWNER_ID_ASC",
  BATCH_FILES_AVERAGE_OWNER_ID_DESC = "BATCH_FILES_AVERAGE_OWNER_ID_DESC",
  BATCH_FILES_AVERAGE_UPDATED_AT_ASC = "BATCH_FILES_AVERAGE_UPDATED_AT_ASC",
  BATCH_FILES_AVERAGE_UPDATED_AT_DESC = "BATCH_FILES_AVERAGE_UPDATED_AT_DESC",
  BATCH_FILES_BY_INSURER_ID__COUNT_ASC = "BATCH_FILES_BY_INSURER_ID__COUNT_ASC",
  BATCH_FILES_BY_INSURER_ID__COUNT_DESC = "BATCH_FILES_BY_INSURER_ID__COUNT_DESC",
  BATCH_FILES_COUNT_ASC = "BATCH_FILES_COUNT_ASC",
  BATCH_FILES_COUNT_DESC = "BATCH_FILES_COUNT_DESC",
  BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_FILES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_ASC = "BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_ASC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_DESC = "BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_NAME_DESC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_ASC = "BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_ASC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_DESC = "BATCH_FILES_DISTINCT_COUNT_BATCH_FILE_URL_DESC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_ASC = "BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_ASC",
  BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_DESC = "BATCH_FILES_DISTINCT_COUNT_BATCH_NAME_DESC",
  BATCH_FILES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_FILES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_FILES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_FILES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_FILES_DISTINCT_COUNT_ID_ASC = "BATCH_FILES_DISTINCT_COUNT_ID_ASC",
  BATCH_FILES_DISTINCT_COUNT_ID_DESC = "BATCH_FILES_DISTINCT_COUNT_ID_DESC",
  BATCH_FILES_DISTINCT_COUNT_INSURER_ID_ASC = "BATCH_FILES_DISTINCT_COUNT_INSURER_ID_ASC",
  BATCH_FILES_DISTINCT_COUNT_INSURER_ID_DESC = "BATCH_FILES_DISTINCT_COUNT_INSURER_ID_DESC",
  BATCH_FILES_DISTINCT_COUNT_OWNER_ID_ASC = "BATCH_FILES_DISTINCT_COUNT_OWNER_ID_ASC",
  BATCH_FILES_DISTINCT_COUNT_OWNER_ID_DESC = "BATCH_FILES_DISTINCT_COUNT_OWNER_ID_DESC",
  BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_FILES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_FILES_MAX_AUTHOR_ID_ASC = "BATCH_FILES_MAX_AUTHOR_ID_ASC",
  BATCH_FILES_MAX_AUTHOR_ID_DESC = "BATCH_FILES_MAX_AUTHOR_ID_DESC",
  BATCH_FILES_MAX_BATCH_FILE_NAME_ASC = "BATCH_FILES_MAX_BATCH_FILE_NAME_ASC",
  BATCH_FILES_MAX_BATCH_FILE_NAME_DESC = "BATCH_FILES_MAX_BATCH_FILE_NAME_DESC",
  BATCH_FILES_MAX_BATCH_FILE_URL_ASC = "BATCH_FILES_MAX_BATCH_FILE_URL_ASC",
  BATCH_FILES_MAX_BATCH_FILE_URL_DESC = "BATCH_FILES_MAX_BATCH_FILE_URL_DESC",
  BATCH_FILES_MAX_BATCH_NAME_ASC = "BATCH_FILES_MAX_BATCH_NAME_ASC",
  BATCH_FILES_MAX_BATCH_NAME_DESC = "BATCH_FILES_MAX_BATCH_NAME_DESC",
  BATCH_FILES_MAX_CREATED_AT_ASC = "BATCH_FILES_MAX_CREATED_AT_ASC",
  BATCH_FILES_MAX_CREATED_AT_DESC = "BATCH_FILES_MAX_CREATED_AT_DESC",
  BATCH_FILES_MAX_ID_ASC = "BATCH_FILES_MAX_ID_ASC",
  BATCH_FILES_MAX_ID_DESC = "BATCH_FILES_MAX_ID_DESC",
  BATCH_FILES_MAX_INSURER_ID_ASC = "BATCH_FILES_MAX_INSURER_ID_ASC",
  BATCH_FILES_MAX_INSURER_ID_DESC = "BATCH_FILES_MAX_INSURER_ID_DESC",
  BATCH_FILES_MAX_OWNER_ID_ASC = "BATCH_FILES_MAX_OWNER_ID_ASC",
  BATCH_FILES_MAX_OWNER_ID_DESC = "BATCH_FILES_MAX_OWNER_ID_DESC",
  BATCH_FILES_MAX_UPDATED_AT_ASC = "BATCH_FILES_MAX_UPDATED_AT_ASC",
  BATCH_FILES_MAX_UPDATED_AT_DESC = "BATCH_FILES_MAX_UPDATED_AT_DESC",
  BATCH_FILES_MIN_AUTHOR_ID_ASC = "BATCH_FILES_MIN_AUTHOR_ID_ASC",
  BATCH_FILES_MIN_AUTHOR_ID_DESC = "BATCH_FILES_MIN_AUTHOR_ID_DESC",
  BATCH_FILES_MIN_BATCH_FILE_NAME_ASC = "BATCH_FILES_MIN_BATCH_FILE_NAME_ASC",
  BATCH_FILES_MIN_BATCH_FILE_NAME_DESC = "BATCH_FILES_MIN_BATCH_FILE_NAME_DESC",
  BATCH_FILES_MIN_BATCH_FILE_URL_ASC = "BATCH_FILES_MIN_BATCH_FILE_URL_ASC",
  BATCH_FILES_MIN_BATCH_FILE_URL_DESC = "BATCH_FILES_MIN_BATCH_FILE_URL_DESC",
  BATCH_FILES_MIN_BATCH_NAME_ASC = "BATCH_FILES_MIN_BATCH_NAME_ASC",
  BATCH_FILES_MIN_BATCH_NAME_DESC = "BATCH_FILES_MIN_BATCH_NAME_DESC",
  BATCH_FILES_MIN_CREATED_AT_ASC = "BATCH_FILES_MIN_CREATED_AT_ASC",
  BATCH_FILES_MIN_CREATED_AT_DESC = "BATCH_FILES_MIN_CREATED_AT_DESC",
  BATCH_FILES_MIN_ID_ASC = "BATCH_FILES_MIN_ID_ASC",
  BATCH_FILES_MIN_ID_DESC = "BATCH_FILES_MIN_ID_DESC",
  BATCH_FILES_MIN_INSURER_ID_ASC = "BATCH_FILES_MIN_INSURER_ID_ASC",
  BATCH_FILES_MIN_INSURER_ID_DESC = "BATCH_FILES_MIN_INSURER_ID_DESC",
  BATCH_FILES_MIN_OWNER_ID_ASC = "BATCH_FILES_MIN_OWNER_ID_ASC",
  BATCH_FILES_MIN_OWNER_ID_DESC = "BATCH_FILES_MIN_OWNER_ID_DESC",
  BATCH_FILES_MIN_UPDATED_AT_ASC = "BATCH_FILES_MIN_UPDATED_AT_ASC",
  BATCH_FILES_MIN_UPDATED_AT_DESC = "BATCH_FILES_MIN_UPDATED_AT_DESC",
  BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_FILES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_ASC = "BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_ASC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_DESC = "BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_NAME_DESC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_ASC = "BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_ASC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_DESC = "BATCH_FILES_STDDEV_POPULATION_BATCH_FILE_URL_DESC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_ASC = "BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_ASC",
  BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_DESC = "BATCH_FILES_STDDEV_POPULATION_BATCH_NAME_DESC",
  BATCH_FILES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_FILES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_FILES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_FILES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_FILES_STDDEV_POPULATION_ID_ASC = "BATCH_FILES_STDDEV_POPULATION_ID_ASC",
  BATCH_FILES_STDDEV_POPULATION_ID_DESC = "BATCH_FILES_STDDEV_POPULATION_ID_DESC",
  BATCH_FILES_STDDEV_POPULATION_INSURER_ID_ASC = "BATCH_FILES_STDDEV_POPULATION_INSURER_ID_ASC",
  BATCH_FILES_STDDEV_POPULATION_INSURER_ID_DESC = "BATCH_FILES_STDDEV_POPULATION_INSURER_ID_DESC",
  BATCH_FILES_STDDEV_POPULATION_OWNER_ID_ASC = "BATCH_FILES_STDDEV_POPULATION_OWNER_ID_ASC",
  BATCH_FILES_STDDEV_POPULATION_OWNER_ID_DESC = "BATCH_FILES_STDDEV_POPULATION_OWNER_ID_DESC",
  BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_FILES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_FILES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_ASC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_ASC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_DESC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_NAME_DESC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_ASC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_ASC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_DESC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_FILE_URL_DESC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_ASC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_ASC",
  BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_DESC = "BATCH_FILES_STDDEV_SAMPLE_BATCH_NAME_DESC",
  BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_FILES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_FILES_STDDEV_SAMPLE_ID_ASC = "BATCH_FILES_STDDEV_SAMPLE_ID_ASC",
  BATCH_FILES_STDDEV_SAMPLE_ID_DESC = "BATCH_FILES_STDDEV_SAMPLE_ID_DESC",
  BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_ASC = "BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_ASC",
  BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_DESC = "BATCH_FILES_STDDEV_SAMPLE_INSURER_ID_DESC",
  BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_ASC = "BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_ASC",
  BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_DESC = "BATCH_FILES_STDDEV_SAMPLE_OWNER_ID_DESC",
  BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_FILES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_FILES_SUM_AUTHOR_ID_ASC = "BATCH_FILES_SUM_AUTHOR_ID_ASC",
  BATCH_FILES_SUM_AUTHOR_ID_DESC = "BATCH_FILES_SUM_AUTHOR_ID_DESC",
  BATCH_FILES_SUM_BATCH_FILE_NAME_ASC = "BATCH_FILES_SUM_BATCH_FILE_NAME_ASC",
  BATCH_FILES_SUM_BATCH_FILE_NAME_DESC = "BATCH_FILES_SUM_BATCH_FILE_NAME_DESC",
  BATCH_FILES_SUM_BATCH_FILE_URL_ASC = "BATCH_FILES_SUM_BATCH_FILE_URL_ASC",
  BATCH_FILES_SUM_BATCH_FILE_URL_DESC = "BATCH_FILES_SUM_BATCH_FILE_URL_DESC",
  BATCH_FILES_SUM_BATCH_NAME_ASC = "BATCH_FILES_SUM_BATCH_NAME_ASC",
  BATCH_FILES_SUM_BATCH_NAME_DESC = "BATCH_FILES_SUM_BATCH_NAME_DESC",
  BATCH_FILES_SUM_CREATED_AT_ASC = "BATCH_FILES_SUM_CREATED_AT_ASC",
  BATCH_FILES_SUM_CREATED_AT_DESC = "BATCH_FILES_SUM_CREATED_AT_DESC",
  BATCH_FILES_SUM_ID_ASC = "BATCH_FILES_SUM_ID_ASC",
  BATCH_FILES_SUM_ID_DESC = "BATCH_FILES_SUM_ID_DESC",
  BATCH_FILES_SUM_INSURER_ID_ASC = "BATCH_FILES_SUM_INSURER_ID_ASC",
  BATCH_FILES_SUM_INSURER_ID_DESC = "BATCH_FILES_SUM_INSURER_ID_DESC",
  BATCH_FILES_SUM_OWNER_ID_ASC = "BATCH_FILES_SUM_OWNER_ID_ASC",
  BATCH_FILES_SUM_OWNER_ID_DESC = "BATCH_FILES_SUM_OWNER_ID_DESC",
  BATCH_FILES_SUM_UPDATED_AT_ASC = "BATCH_FILES_SUM_UPDATED_AT_ASC",
  BATCH_FILES_SUM_UPDATED_AT_DESC = "BATCH_FILES_SUM_UPDATED_AT_DESC",
  BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_FILES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_ASC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_ASC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_DESC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_NAME_DESC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_ASC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_ASC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_DESC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_FILE_URL_DESC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_ASC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_ASC",
  BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_DESC = "BATCH_FILES_VARIANCE_POPULATION_BATCH_NAME_DESC",
  BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_FILES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_FILES_VARIANCE_POPULATION_ID_ASC = "BATCH_FILES_VARIANCE_POPULATION_ID_ASC",
  BATCH_FILES_VARIANCE_POPULATION_ID_DESC = "BATCH_FILES_VARIANCE_POPULATION_ID_DESC",
  BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_ASC = "BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_ASC",
  BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_DESC = "BATCH_FILES_VARIANCE_POPULATION_INSURER_ID_DESC",
  BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_ASC = "BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_ASC",
  BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_DESC = "BATCH_FILES_VARIANCE_POPULATION_OWNER_ID_DESC",
  BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_FILES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_FILES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_ASC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_DESC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_NAME_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_ASC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_DESC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_FILE_URL_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_ASC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_DESC = "BATCH_FILES_VARIANCE_SAMPLE_BATCH_NAME_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_FILES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_ID_ASC = "BATCH_FILES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_ID_DESC = "BATCH_FILES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_ASC = "BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_DESC = "BATCH_FILES_VARIANCE_SAMPLE_INSURER_ID_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_ASC = "BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_DESC = "BATCH_FILES_VARIANCE_SAMPLE_OWNER_ID_DESC",
  BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_FILES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CLIENT_ABBREVIATION_ASC = "CLIENT_ABBREVIATION_ASC",
  CLIENT_ABBREVIATION_DESC = "CLIENT_ABBREVIATION_DESC",
  CLIENT_ID_ASC = "CLIENT_ID_ASC",
  CLIENT_ID_DESC = "CLIENT_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__ID_ASC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__ID_ASC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__ID_DESC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__ID_DESC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__INSURER_ID_ASC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__INSURER_ID_ASC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__INSURER_ID_DESC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__INSURER_ID_DESC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__STRATEGY_ASC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__STRATEGY_ASC",
  DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__STRATEGY_DESC = "DEFAULT_STRATEGY_BY_INSURER_BY_INSURER_ID__STRATEGY_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__ID_ASC = "INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__ID_ASC",
  INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__ID_DESC = "INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__ID_DESC",
  INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__INSURER_ID_ASC = "INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__INSURER_ID_ASC",
  INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__INSURER_ID_DESC = "INSURER_CLAIM_INTAKE_DROPBOX_PERMITTED_BY_INSURER_ID__INSURER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_AVERAGE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_COUNT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_COUNT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_COUNT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_COUNT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MAX_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_MIN_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_SUM_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID__COUNT_ASC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID__COUNT_ASC",
  INSURER_ORGANIZATIONS_BY_MEMBER_ID__COUNT_DESC = "INSURER_ORGANIZATIONS_BY_MEMBER_ID__COUNT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_AVERAGE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_COUNT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_COUNT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_COUNT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_COUNT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_SUM_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_MEMBER_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_MEMBER_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID__COUNT_ASC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID__COUNT_ASC",
  INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID__COUNT_DESC = "INSURER_ORGANIZATIONS_BY_ORGANIZATION_ID__COUNT_DESC",
  INSURER_USERS_AVERAGE_AUDITOR_USER_ID_ASC = "INSURER_USERS_AVERAGE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_AVERAGE_AUDITOR_USER_ID_DESC = "INSURER_USERS_AVERAGE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_AVERAGE_CREATED_AT_ASC = "INSURER_USERS_AVERAGE_CREATED_AT_ASC",
  INSURER_USERS_AVERAGE_CREATED_AT_DESC = "INSURER_USERS_AVERAGE_CREATED_AT_DESC",
  INSURER_USERS_AVERAGE_ID_ASC = "INSURER_USERS_AVERAGE_ID_ASC",
  INSURER_USERS_AVERAGE_ID_DESC = "INSURER_USERS_AVERAGE_ID_DESC",
  INSURER_USERS_AVERAGE_INSURER_ID_ASC = "INSURER_USERS_AVERAGE_INSURER_ID_ASC",
  INSURER_USERS_AVERAGE_INSURER_ID_DESC = "INSURER_USERS_AVERAGE_INSURER_ID_DESC",
  INSURER_USERS_AVERAGE_UPDATED_AT_ASC = "INSURER_USERS_AVERAGE_UPDATED_AT_ASC",
  INSURER_USERS_AVERAGE_UPDATED_AT_DESC = "INSURER_USERS_AVERAGE_UPDATED_AT_DESC",
  INSURER_USERS_BY_INSURER_ID__COUNT_ASC = "INSURER_USERS_BY_INSURER_ID__COUNT_ASC",
  INSURER_USERS_BY_INSURER_ID__COUNT_DESC = "INSURER_USERS_BY_INSURER_ID__COUNT_DESC",
  INSURER_USERS_COUNT_ASC = "INSURER_USERS_COUNT_ASC",
  INSURER_USERS_COUNT_DESC = "INSURER_USERS_COUNT_DESC",
  INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_CREATED_AT_ASC = "INSURER_USERS_DISTINCT_COUNT_CREATED_AT_ASC",
  INSURER_USERS_DISTINCT_COUNT_CREATED_AT_DESC = "INSURER_USERS_DISTINCT_COUNT_CREATED_AT_DESC",
  INSURER_USERS_DISTINCT_COUNT_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_INSURER_ID_ASC = "INSURER_USERS_DISTINCT_COUNT_INSURER_ID_ASC",
  INSURER_USERS_DISTINCT_COUNT_INSURER_ID_DESC = "INSURER_USERS_DISTINCT_COUNT_INSURER_ID_DESC",
  INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_ASC = "INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_ASC",
  INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_DESC = "INSURER_USERS_DISTINCT_COUNT_UPDATED_AT_DESC",
  INSURER_USERS_MAX_AUDITOR_USER_ID_ASC = "INSURER_USERS_MAX_AUDITOR_USER_ID_ASC",
  INSURER_USERS_MAX_AUDITOR_USER_ID_DESC = "INSURER_USERS_MAX_AUDITOR_USER_ID_DESC",
  INSURER_USERS_MAX_CREATED_AT_ASC = "INSURER_USERS_MAX_CREATED_AT_ASC",
  INSURER_USERS_MAX_CREATED_AT_DESC = "INSURER_USERS_MAX_CREATED_AT_DESC",
  INSURER_USERS_MAX_ID_ASC = "INSURER_USERS_MAX_ID_ASC",
  INSURER_USERS_MAX_ID_DESC = "INSURER_USERS_MAX_ID_DESC",
  INSURER_USERS_MAX_INSURER_ID_ASC = "INSURER_USERS_MAX_INSURER_ID_ASC",
  INSURER_USERS_MAX_INSURER_ID_DESC = "INSURER_USERS_MAX_INSURER_ID_DESC",
  INSURER_USERS_MAX_UPDATED_AT_ASC = "INSURER_USERS_MAX_UPDATED_AT_ASC",
  INSURER_USERS_MAX_UPDATED_AT_DESC = "INSURER_USERS_MAX_UPDATED_AT_DESC",
  INSURER_USERS_MIN_AUDITOR_USER_ID_ASC = "INSURER_USERS_MIN_AUDITOR_USER_ID_ASC",
  INSURER_USERS_MIN_AUDITOR_USER_ID_DESC = "INSURER_USERS_MIN_AUDITOR_USER_ID_DESC",
  INSURER_USERS_MIN_CREATED_AT_ASC = "INSURER_USERS_MIN_CREATED_AT_ASC",
  INSURER_USERS_MIN_CREATED_AT_DESC = "INSURER_USERS_MIN_CREATED_AT_DESC",
  INSURER_USERS_MIN_ID_ASC = "INSURER_USERS_MIN_ID_ASC",
  INSURER_USERS_MIN_ID_DESC = "INSURER_USERS_MIN_ID_DESC",
  INSURER_USERS_MIN_INSURER_ID_ASC = "INSURER_USERS_MIN_INSURER_ID_ASC",
  INSURER_USERS_MIN_INSURER_ID_DESC = "INSURER_USERS_MIN_INSURER_ID_DESC",
  INSURER_USERS_MIN_UPDATED_AT_ASC = "INSURER_USERS_MIN_UPDATED_AT_ASC",
  INSURER_USERS_MIN_UPDATED_AT_DESC = "INSURER_USERS_MIN_UPDATED_AT_DESC",
  INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_CREATED_AT_ASC = "INSURER_USERS_STDDEV_POPULATION_CREATED_AT_ASC",
  INSURER_USERS_STDDEV_POPULATION_CREATED_AT_DESC = "INSURER_USERS_STDDEV_POPULATION_CREATED_AT_DESC",
  INSURER_USERS_STDDEV_POPULATION_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_INSURER_ID_ASC = "INSURER_USERS_STDDEV_POPULATION_INSURER_ID_ASC",
  INSURER_USERS_STDDEV_POPULATION_INSURER_ID_DESC = "INSURER_USERS_STDDEV_POPULATION_INSURER_ID_DESC",
  INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_ASC = "INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_ASC",
  INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_DESC = "INSURER_USERS_STDDEV_POPULATION_UPDATED_AT_DESC",
  INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_ASC = "INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_ASC",
  INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_DESC = "INSURER_USERS_STDDEV_SAMPLE_CREATED_AT_DESC",
  INSURER_USERS_STDDEV_SAMPLE_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_ASC = "INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_ASC",
  INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_DESC = "INSURER_USERS_STDDEV_SAMPLE_INSURER_ID_DESC",
  INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC = "INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC = "INSURER_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  INSURER_USERS_SUM_AUDITOR_USER_ID_ASC = "INSURER_USERS_SUM_AUDITOR_USER_ID_ASC",
  INSURER_USERS_SUM_AUDITOR_USER_ID_DESC = "INSURER_USERS_SUM_AUDITOR_USER_ID_DESC",
  INSURER_USERS_SUM_CREATED_AT_ASC = "INSURER_USERS_SUM_CREATED_AT_ASC",
  INSURER_USERS_SUM_CREATED_AT_DESC = "INSURER_USERS_SUM_CREATED_AT_DESC",
  INSURER_USERS_SUM_ID_ASC = "INSURER_USERS_SUM_ID_ASC",
  INSURER_USERS_SUM_ID_DESC = "INSURER_USERS_SUM_ID_DESC",
  INSURER_USERS_SUM_INSURER_ID_ASC = "INSURER_USERS_SUM_INSURER_ID_ASC",
  INSURER_USERS_SUM_INSURER_ID_DESC = "INSURER_USERS_SUM_INSURER_ID_DESC",
  INSURER_USERS_SUM_UPDATED_AT_ASC = "INSURER_USERS_SUM_UPDATED_AT_ASC",
  INSURER_USERS_SUM_UPDATED_AT_DESC = "INSURER_USERS_SUM_UPDATED_AT_DESC",
  INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_ASC = "INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_ASC",
  INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_DESC = "INSURER_USERS_VARIANCE_POPULATION_CREATED_AT_DESC",
  INSURER_USERS_VARIANCE_POPULATION_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_ASC = "INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_ASC",
  INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_DESC = "INSURER_USERS_VARIANCE_POPULATION_INSURER_ID_DESC",
  INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC = "INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC = "INSURER_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC = "INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC = "INSURER_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_ASC = "INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_DESC = "INSURER_USERS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "INSURER_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type IntStat = {
  __typename?: "IntStat";
  description: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  operationType: OperationTypeEnum;
  units: IntUnitsEnum;
  value: Scalars["Int"]["output"];
};

export enum IntUnitsEnum {
  CLAIMS = "CLAIMS",
  LIVES = "LIVES",
  PROVIDERS = "PROVIDERS",
}

export type InvoiceClaimState = Node & {
  __typename?: "InvoiceClaimState";
  /** Reads a single `BatchClaim` that is related to this `InvoiceClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  invoiceState: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type InvoiceClaimStateAggregates = {
  __typename?: "InvoiceClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<InvoiceClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `InvoiceClaimState` object types. */
export type InvoiceClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `InvoiceClaimState` objects. */
  distinctCount?: InputMaybe<InvoiceClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `InvoiceClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<InvoiceClaimStateFilter>;
};

/** Input for the nested mutation of `batchClaim` in the `InvoiceClaimStateInput` mutation. */
export type InvoiceClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<InvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `invoiceClaimState` in the `BatchClaimInput` mutation. */
export type InvoiceClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `invoiceClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<InvoiceClaimStateInvoiceClaimStatePkeyConnect>
  >;
  /** The primary key(s) for `invoiceClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<InvoiceClaimStateNodeIdConnect>>;
  /** A `InvoiceClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<InvoiceClaimStateBatchClaimIdFkInvoiceClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `invoiceClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<InvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingInvoiceClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `invoiceClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The `invoiceClaimState` to be created by this mutation. */
export type InvoiceClaimStateBatchClaimIdFkInvoiceClaimStateCreateInput = {
  batchClaimToBatchClaimId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  invoiceState?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * A condition to be used against `InvoiceClaimState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvoiceClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `invoiceState` field. */
  invoiceState?: InputMaybe<Scalars["String"]["input"]>;
};

export type InvoiceClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  invoiceState?: InputMaybe<BigIntFilter>;
};

export type InvoiceClaimStateDistinctCountAggregates = {
  __typename?: "InvoiceClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of invoiceState across the matching connection */
  invoiceState?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum InvoiceClaimStateEnum {
  NOT_PAID = "NOT_PAID",
  PAID = "PAID",
  SENT = "SENT",
}

/** A filter to be used against InvoiceClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type InvoiceClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<InvoiceClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<InvoiceClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<InvoiceClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<InvoiceClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<InvoiceClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<InvoiceClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<InvoiceClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<InvoiceClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<InvoiceClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<InvoiceClaimStateEnum>>;
};

/** A filter to be used against `InvoiceClaimState` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<InvoiceClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `invoiceState` field. */
  invoiceState?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<InvoiceClaimStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<InvoiceClaimStateFilter>>;
};

/** Grouping methods for `InvoiceClaimState` for usage during aggregation. */
export enum InvoiceClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  INVOICE_STATE = "INVOICE_STATE",
}

export type InvoiceClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `InvoiceClaimState` aggregates. */
export type InvoiceClaimStateHavingInput = {
  AND?: InputMaybe<Array<InvoiceClaimStateHavingInput>>;
  OR?: InputMaybe<Array<InvoiceClaimStateHavingInput>>;
  average?: InputMaybe<InvoiceClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<InvoiceClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<InvoiceClaimStateHavingMaxInput>;
  min?: InputMaybe<InvoiceClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<InvoiceClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<InvoiceClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<InvoiceClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<InvoiceClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<InvoiceClaimStateHavingVarianceSampleInput>;
};

export type InvoiceClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type InvoiceClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `InvoiceClaimState` */
export type InvoiceClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  invoiceState?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `invoiceClaimState` to look up the row to connect. */
export type InvoiceClaimStateInvoiceClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type InvoiceClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `invoiceClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type InvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `invoiceClaimState` to look up the row to update. */
export type InvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkUsingInvoiceClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `invoiceClaimState` being updated. */
    patch: updateInvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `InvoiceClaimState`. Fields that are set will be updated. */
export type InvoiceClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInput>;
};

/** A connection to a list of `InvoiceClaimState` values. */
export type InvoiceClaimStatesConnection = {
  __typename?: "InvoiceClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<InvoiceClaimStateAggregates>;
  /** A list of edges which contains the `InvoiceClaimState` and cursor to aid in pagination. */
  edges: Array<InvoiceClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<InvoiceClaimStateAggregates>>;
  /** A list of `InvoiceClaimState` objects. */
  nodes: Array<Maybe<InvoiceClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `InvoiceClaimState` values. */
export type InvoiceClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<InvoiceClaimStateGroupBy>;
  having?: InputMaybe<InvoiceClaimStateHavingInput>;
};

/** A `InvoiceClaimState` edge in the connection. */
export type InvoiceClaimStatesEdge = {
  __typename?: "InvoiceClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `InvoiceClaimState` at the end of the edge. */
  node?: Maybe<InvoiceClaimState>;
};

/** Methods to use when ordering `InvoiceClaimState`. */
export enum InvoiceClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INVOICE_STATE_ASC = "INVOICE_STATE_ASC",
  INVOICE_STATE_DESC = "INVOICE_STATE_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

export type ItemizedBill = {
  __typename?: "ItemizedBill";
  csv: Csv;
  id: Scalars["UUID"]["output"];
};

export type ItemizedBillLine = {
  __typename?: "ItemizedBillLine";
  alaRowId?: Maybe<Scalars["UUID"]["output"]>;
  dateOfService?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  findings: Array<Scalars["UUID"]["output"]>;
  groupCode?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["UUID"]["output"];
  itemNumber?: Maybe<Scalars["String"]["output"]>;
  lineNumber?: Maybe<Scalars["Int"]["output"]>;
  procedureCode?: Maybe<Scalars["String"]["output"]>;
  procedureCodeModifier?: Maybe<Scalars["String"]["output"]>;
  revCode?: Maybe<Scalars["String"]["output"]>;
  s3InputSourceId?: Maybe<Scalars["UUID"]["output"]>;
  totalCharge?: Maybe<Scalars["Float"]["output"]>;
  units?: Maybe<Scalars["Int"]["output"]>;
  versionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JSONFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]["input"]>>;
};

export type LatestReview = {
  __typename?: "LatestReview";
  authorId: Scalars["String"]["output"];
  createdAt: Scalars["Date"]["output"];
  rationale?: Maybe<Scalars["String"]["output"]>;
};

export enum MFAOption {
  SMS_MFA = "SMS_MFA",
  SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA",
}

export type MUEAuditRule = {
  __typename?: "MUEAuditRule";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
  editType: Scalars["String"]["output"];
  mai: Scalars["String"]["output"];
  num: Scalars["Int"]["output"];
  rationale: MUEAuditRuleRationale;
};

export type MUEAuditRuleRationale = {
  __typename?: "MUEAuditRuleRationale";
  enumeration: Scalars["String"]["output"];
  value: Scalars["String"]["output"];
};

export type Message = {
  __typename?: "Message";
  createdAt: Scalars["String"]["output"];
  feedback?: Maybe<Array<PromptFeedback>>;
  id: Scalars["ID"]["output"];
  promptInputState: Scalars["String"]["output"];
  promptText: Scalars["String"]["output"];
  response?: Maybe<PromptResponse>;
  thread: Thread;
};

/**
 * ## Overview
 * Records messages created (aka notifications sent). Owned and written to by the Notification Service.
 *  More message types can be created by adding a nullable foreign key to a table owned by a separate service,
 * as well as a new message_created_kind enum
 */
export type MessageCreated = Node & {
  __typename?: "MessageCreated";
  createdAt: Scalars["Datetime"]["output"];
  /** Reads a single `DocumentRequestCreated` that is related to this `MessageCreated`. */
  documentRequestCreated?: Maybe<DocumentRequestCreated>;
  documentRequestCreatedId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `DocumentRequestCreated`. */
  documentRequestCreatedsByDeprecatedMessageCreatedId: DocumentRequestCreatedsConnection;
  /** Reads a single `DocumentUploadCreated` that is related to this `MessageCreated`. */
  documentUploadCreated?: Maybe<DocumentUploadCreated>;
  documentUploadCreatedId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads and enables pagination through a set of `DocumentUploadCreated`. */
  documentUploadCreatedsByDeprecatedMessageId: DocumentUploadCreatedsConnection;
  id: Scalars["UUID"]["output"];
  kind: MessageCreatedKind;
  message?: Maybe<Scalars["String"]["output"]>;
  /** Reads and enables pagination through a set of `MessageSent`. */
  messageSentsByMessageId: MessageSentsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `MessageCreated`. */
  senderUser?: Maybe<AuditorUser>;
  senderUserId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `UserInviteCreated`. */
  userInviteCreatedsByMessageId: UserInviteCreatedsConnection;
};

/**
 * ## Overview
 * Records messages created (aka notifications sent). Owned and written to by the Notification Service.
 *  More message types can be created by adding a nullable foreign key to a table owned by a separate service,
 * as well as a new message_created_kind enum
 */
export type MessageCreateddocumentRequestCreatedsByDeprecatedMessageCreatedIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]["input"]>;
    before?: InputMaybe<Scalars["Cursor"]["input"]>;
    condition?: InputMaybe<DocumentRequestCreatedCondition>;
    filter?: InputMaybe<DocumentRequestCreatedFilter>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    offset?: InputMaybe<Scalars["Int"]["input"]>;
    orderBy?: InputMaybe<Array<DocumentRequestCreatedsOrderBy>>;
  };

/**
 * ## Overview
 * Records messages created (aka notifications sent). Owned and written to by the Notification Service.
 *  More message types can be created by adding a nullable foreign key to a table owned by a separate service,
 * as well as a new message_created_kind enum
 */
export type MessageCreateddocumentUploadCreatedsByDeprecatedMessageIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentUploadCreatedCondition>;
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

/**
 * ## Overview
 * Records messages created (aka notifications sent). Owned and written to by the Notification Service.
 *  More message types can be created by adding a nullable foreign key to a table owned by a separate service,
 * as well as a new message_created_kind enum
 */
export type MessageCreatedmessageSentsByMessageIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageSentCondition>;
  filter?: InputMaybe<MessageSentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

/**
 * ## Overview
 * Records messages created (aka notifications sent). Owned and written to by the Notification Service.
 *  More message types can be created by adding a nullable foreign key to a table owned by a separate service,
 * as well as a new message_created_kind enum
 */
export type MessageCreateduserInviteCreatedsByMessageIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserInviteCreatedCondition>;
  filter?: InputMaybe<UserInviteCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserInviteCreatedsOrderBy>>;
};

export type MessageCreatedAggregates = {
  __typename?: "MessageCreatedAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MessageCreatedDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `MessageCreated` object types. */
export type MessageCreatedAggregatesFilter = {
  /** Distinct count aggregate over matching `MessageCreated` objects. */
  distinctCount?: InputMaybe<MessageCreatedDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MessageCreated` object to be included within the aggregate. */
  filter?: InputMaybe<MessageCreatedFilter>;
};

/**
 * A condition to be used against `MessageCreated` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MessageCreatedCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `documentRequestCreatedId` field. */
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `documentUploadCreatedId` field. */
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `kind` field. */
  kind?: InputMaybe<MessageCreatedKind>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `senderUserId` field. */
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type MessageCreatedDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  documentRequestCreatedId?: InputMaybe<BigIntFilter>;
  documentUploadCreatedId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  kind?: InputMaybe<BigIntFilter>;
  message?: InputMaybe<BigIntFilter>;
  senderUserId?: InputMaybe<BigIntFilter>;
};

export type MessageCreatedDistinctCountAggregates = {
  __typename?: "MessageCreatedDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of documentRequestCreatedId across the matching connection */
  documentRequestCreatedId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of documentUploadCreatedId across the matching connection */
  documentUploadCreatedId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of kind across the matching connection */
  kind?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of message across the matching connection */
  message?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of senderUserId across the matching connection */
  senderUserId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** The `documentRequestCreated` to be created by this mutation. */
export type MessageCreatedDocumentRequestCreatedIdFkeyDocumentRequestCreatedCreateInput =
  {
    _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** Input for the nested mutation of `documentRequestCreated` in the `MessageCreatedInput` mutation. */
export type MessageCreatedDocumentRequestCreatedIdFkeyInput = {
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectById?: InputMaybe<DocumentRequestCreatedDocumentRequestCreatedPkeyConnect>;
  /** The primary key(s) for `documentRequestCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<DocumentRequestCreatedNodeIdConnect>;
  /** A `DocumentRequestCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyDocumentRequestCreatedCreateInput>;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateById?: InputMaybe<DocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyUsingDocumentRequestCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `documentRequestCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `messageCreated` in the `DocumentRequestCreatedInput` mutation. */
export type MessageCreatedDocumentRequestCreatedIdFkeyInverseInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MessageCreatedMessageCreatedPkeyConnect>>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MessageCreatedNodeIdConnect>>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MessageCreatedDocumentRequestCreatedIdFkeyMessageCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyUsingMessageCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<DocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type MessageCreatedDocumentRequestCreatedIdFkeyMessageCreatedCreateInput =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: MessageCreatedKind;
    message?: InputMaybe<Scalars["String"]["input"]>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** The `documentUploadCreated` to be created by this mutation. */
export type MessageCreatedDocumentUploadCreatedIdFkeyDocumentUploadCreatedCreateInput =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    claimInquiryRequestId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fileLocation: Scalars["String"]["input"];
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: DocumentUploadKind;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** Input for the nested mutation of `documentUploadCreated` in the `MessageCreatedInput` mutation. */
export type MessageCreatedDocumentUploadCreatedIdFkeyInput = {
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectById?: InputMaybe<DocumentUploadCreatedDocumentUploadCreatedPkeyConnect>;
  /** The primary key(s) for `documentUploadCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<DocumentUploadCreatedNodeIdConnect>;
  /** A `DocumentUploadCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyDocumentUploadCreatedCreateInput>;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateById?: InputMaybe<DocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyUsingDocumentUploadCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `documentUploadCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `messageCreated` in the `DocumentUploadCreatedInput` mutation. */
export type MessageCreatedDocumentUploadCreatedIdFkeyInverseInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MessageCreatedMessageCreatedPkeyConnect>>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MessageCreatedNodeIdConnect>>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MessageCreatedDocumentUploadCreatedIdFkeyMessageCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyUsingMessageCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<DocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type MessageCreatedDocumentUploadCreatedIdFkeyMessageCreatedCreateInput =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    kind: MessageCreatedKind;
    message?: InputMaybe<Scalars["String"]["input"]>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** A filter to be used against `MessageCreated` object types. All fields are combined with a logical ‘and.’ */
export type MessageCreatedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageCreatedFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `documentRequestCreated` relation. */
  documentRequestCreated?: InputMaybe<DocumentRequestCreatedFilter>;
  /** A related `documentRequestCreated` exists. */
  documentRequestCreatedExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentRequestCreatedId` field. */
  documentRequestCreatedId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `documentRequestCreatedsByDeprecatedMessageCreatedId` relation. */
  documentRequestCreatedsByDeprecatedMessageCreatedId?: InputMaybe<MessageCreatedToManyDocumentRequestCreatedFilter>;
  /** Some related `documentRequestCreatedsByDeprecatedMessageCreatedId` exist. */
  documentRequestCreatedsByDeprecatedMessageCreatedIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `documentUploadCreated` relation. */
  documentUploadCreated?: InputMaybe<DocumentUploadCreatedFilter>;
  /** A related `documentUploadCreated` exists. */
  documentUploadCreatedExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentUploadCreatedId` field. */
  documentUploadCreatedId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `documentUploadCreatedsByDeprecatedMessageId` relation. */
  documentUploadCreatedsByDeprecatedMessageId?: InputMaybe<MessageCreatedToManyDocumentUploadCreatedFilter>;
  /** Some related `documentUploadCreatedsByDeprecatedMessageId` exist. */
  documentUploadCreatedsByDeprecatedMessageIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `kind` field. */
  kind?: InputMaybe<MessageCreatedKindFilter>;
  /** Filter by the object’s `message` field. */
  message?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageSentsByMessageId` relation. */
  messageSentsByMessageId?: InputMaybe<MessageCreatedToManyMessageSentFilter>;
  /** Some related `messageSentsByMessageId` exist. */
  messageSentsByMessageIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Negates the expression. */
  not?: InputMaybe<MessageCreatedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageCreatedFilter>>;
  /** Filter by the object’s `senderUser` relation. */
  senderUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `senderUserId` field. */
  senderUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `userInviteCreatedsByMessageId` relation. */
  userInviteCreatedsByMessageId?: InputMaybe<MessageCreatedToManyUserInviteCreatedFilter>;
  /** Some related `userInviteCreatedsByMessageId` exist. */
  userInviteCreatedsByMessageIdExist?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Grouping methods for `MessageCreated` for usage during aggregation. */
export enum MessageCreatedGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DOCUMENT_REQUEST_CREATED_ID = "DOCUMENT_REQUEST_CREATED_ID",
  DOCUMENT_UPLOAD_CREATED_ID = "DOCUMENT_UPLOAD_CREATED_ID",
  KIND = "KIND",
  MESSAGE = "MESSAGE",
  SENDER_USER_ID = "SENDER_USER_ID",
}

export type MessageCreatedHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MessageCreated` aggregates. */
export type MessageCreatedHavingInput = {
  AND?: InputMaybe<Array<MessageCreatedHavingInput>>;
  OR?: InputMaybe<Array<MessageCreatedHavingInput>>;
  average?: InputMaybe<MessageCreatedHavingAverageInput>;
  distinctCount?: InputMaybe<MessageCreatedHavingDistinctCountInput>;
  max?: InputMaybe<MessageCreatedHavingMaxInput>;
  min?: InputMaybe<MessageCreatedHavingMinInput>;
  stddevPopulation?: InputMaybe<MessageCreatedHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MessageCreatedHavingStddevSampleInput>;
  sum?: InputMaybe<MessageCreatedHavingSumInput>;
  variancePopulation?: InputMaybe<MessageCreatedHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MessageCreatedHavingVarianceSampleInput>;
};

export type MessageCreatedHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageCreatedHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `MessageCreated` */
export type MessageCreatedInput = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: MessageCreatedKind;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

export enum MessageCreatedKind {
  CLAIM_INQUIRY_REPLY_CREATED = "CLAIM_INQUIRY_REPLY_CREATED",
  CLAIM_INQUIRY_REQUEST_CREATED = "CLAIM_INQUIRY_REQUEST_CREATED",
  DOCUMENT_REQUEST_CREATED = "DOCUMENT_REQUEST_CREATED",
  DOCUMENT_UPLOAD_CREATED = "DOCUMENT_UPLOAD_CREATED",
  FINAL_AUDIT_FINDING_REPORT_REVIEW_REPLY_CREATED = "FINAL_AUDIT_FINDING_REPORT_REVIEW_REPLY_CREATED",
  FINAL_AUDIT_FINDING_REPORT_REVIEW_REQUEST_CREATED = "FINAL_AUDIT_FINDING_REPORT_REVIEW_REQUEST_CREATED",
  PRELIMINARY_AUDIT_REPORT_REVIEW_REPLY_CREATED = "PRELIMINARY_AUDIT_REPORT_REVIEW_REPLY_CREATED",
  PRELIMINARY_AUDIT_REPORT_REVIEW_REQUEST_CREATED = "PRELIMINARY_AUDIT_REPORT_REVIEW_REQUEST_CREATED",
  REPORT_CREATED = "REPORT_CREATED",
  USER_INVITE_CREATED = "USER_INVITE_CREATED",
}

/** A filter to be used against MessageCreatedKind fields. All fields are combined with a logical ‘and.’ */
export type MessageCreatedKindFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<MessageCreatedKind>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<MessageCreatedKind>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<MessageCreatedKind>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<MessageCreatedKind>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<MessageCreatedKind>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<MessageCreatedKind>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<MessageCreatedKind>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<MessageCreatedKind>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<MessageCreatedKind>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<MessageCreatedKind>>;
};

/** The fields on `messageCreated` to look up the row to connect. */
export type MessageCreatedMessageCreatedPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type MessageCreatedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentRequestCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: DocumentRequestCreatedPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: DocumentUploadCreatedPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentRequestCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
    patch: DocumentRequestCreatedPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: DocumentUploadCreatedPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnMessageSentForMessageSentMessageIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageSent` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: MessageSentPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnMessageSentForMessageSentMessageIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnMessageSentForMessageSentMessageIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userInviteCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `userInviteCreated` being updated. */
    patch: UserInviteCreatedPatch;
  };

/** The fields on `messageCreated` to look up the row to update. */
export type MessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyUsingMessageCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: updateMessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyPatch;
  };

/** Represents an update to a `MessageCreated`. Fields that are set will be updated. */
export type MessageCreatedPatch = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

/** The `auditorUser` to be created by this mutation. */
export type MessageCreatedSenderUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `MessageCreatedInput` mutation. */
export type MessageCreatedSenderUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<MessageCreatedSenderUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `messageCreated` in the `AuditorUserInput` mutation. */
export type MessageCreatedSenderUserIdFkeyInverseInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MessageCreatedMessageCreatedPkeyConnect>>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MessageCreatedNodeIdConnect>>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MessageCreatedSenderUserIdFkeyMessageCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyUsingMessageCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type MessageCreatedSenderUserIdFkeyMessageCreatedCreateInput = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: MessageCreatedKind;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

/** A filter to be used against many `DocumentRequestCreated` object types. All fields are combined with a logical ‘and.’ */
export type MessageCreatedToManyDocumentRequestCreatedFilter = {
  /** Aggregates across related `DocumentRequestCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentRequestCreatedAggregatesFilter>;
  /** Every related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentRequestCreatedFilter>;
  /** No related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentRequestCreatedFilter>;
  /** Some related `DocumentRequestCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentRequestCreatedFilter>;
};

/** A filter to be used against many `DocumentUploadCreated` object types. All fields are combined with a logical ‘and.’ */
export type MessageCreatedToManyDocumentUploadCreatedFilter = {
  /** Aggregates across related `DocumentUploadCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentUploadCreatedAggregatesFilter>;
  /** Every related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentUploadCreatedFilter>;
  /** No related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentUploadCreatedFilter>;
  /** Some related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentUploadCreatedFilter>;
};

/** A filter to be used against many `MessageSent` object types. All fields are combined with a logical ‘and.’ */
export type MessageCreatedToManyMessageSentFilter = {
  /** Aggregates across related `MessageSent` match the filter criteria. */
  aggregates?: InputMaybe<MessageSentAggregatesFilter>;
  /** Every related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageSentFilter>;
  /** No related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageSentFilter>;
  /** Some related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageSentFilter>;
};

/** A filter to be used against many `UserInviteCreated` object types. All fields are combined with a logical ‘and.’ */
export type MessageCreatedToManyUserInviteCreatedFilter = {
  /** Aggregates across related `UserInviteCreated` match the filter criteria. */
  aggregates?: InputMaybe<UserInviteCreatedAggregatesFilter>;
  /** Every related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserInviteCreatedFilter>;
  /** No related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserInviteCreatedFilter>;
  /** Some related `UserInviteCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserInviteCreatedFilter>;
};

/** A connection to a list of `MessageCreated` values. */
export type MessageCreatedsConnection = {
  __typename?: "MessageCreatedsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MessageCreatedAggregates>;
  /** A list of edges which contains the `MessageCreated` and cursor to aid in pagination. */
  edges: Array<MessageCreatedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MessageCreatedAggregates>>;
  /** A list of `MessageCreated` objects. */
  nodes: Array<Maybe<MessageCreated>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageCreated` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MessageCreated` values. */
export type MessageCreatedsConnectiongroupedAggregatesArgs = {
  groupBy: Array<MessageCreatedGroupBy>;
  having?: InputMaybe<MessageCreatedHavingInput>;
};

/** A `MessageCreated` edge in the connection. */
export type MessageCreatedsEdge = {
  __typename?: "MessageCreatedsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MessageCreated` at the end of the edge. */
  node?: Maybe<MessageCreated>;
};

/** Methods to use when ordering `MessageCreated`. */
export enum MessageCreatedsOrderBy {
  AUDITOR_USER_BY_SENDER_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__ID_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__ID_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__ID_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__ID_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_SENDER_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_SENDER_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_SENDER_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_SENDER_USER_ID__USER_TYPE_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_COUNT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_COUNT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_COUNT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_COUNT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_COUNT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_COUNT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_COUNT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_COUNT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_AVERAGE_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_COUNT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_COUNT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_COUNT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_COUNT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_DISTINCT_COUNT_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MAX_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_MIN_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_POPULATION_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_STDDEV_SAMPLE_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_SUM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_POPULATION_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DELETED_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DELETED_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DELETED_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DELETED_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_ID_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_ID_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_ID_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID_VARIANCE_SAMPLE_ID_DESC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID__COUNT_ASC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID__COUNT_ASC",
  DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID__COUNT_DESC = "DOCUMENT_REQUEST_CREATEDS_BY_DEPRECATED_MESSAGE_CREATED_ID__COUNT_DESC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__BATCH_CLAIM_ID_ASC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__BATCH_CLAIM_ID_ASC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__BATCH_CLAIM_ID_DESC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__BATCH_CLAIM_ID_DESC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__CREATED_AT_ASC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__CREATED_AT_ASC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__CREATED_AT_DESC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__CREATED_AT_DESC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__DELETED_ASC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__DELETED_ASC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__DELETED_DESC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__DELETED_DESC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__ID_ASC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__ID_ASC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__ID_DESC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID__ID_DESC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID___DEPRECATED_MESSAGE_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID___DEPRECATED_MESSAGE_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID___DEPRECATED_MESSAGE_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATED_BY_DOCUMENT_REQUEST_CREATED_ID___DEPRECATED_MESSAGE_CREATED_ID_DESC",
  DOCUMENT_REQUEST_CREATED_ID_ASC = "DOCUMENT_REQUEST_CREATED_ID_ASC",
  DOCUMENT_REQUEST_CREATED_ID_DESC = "DOCUMENT_REQUEST_CREATED_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_AVERAGE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MAX_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_MIN_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_SUM_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID__COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID__COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID__COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_DEPRECATED_MESSAGE_ID__COUNT_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__ID_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__KIND_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__KIND_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__KIND_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__KIND_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID__PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID___DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID___DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID___DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATED_BY_DOCUMENT_UPLOAD_CREATED_ID___DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATED_ID_ASC = "DOCUMENT_UPLOAD_CREATED_ID_ASC",
  DOCUMENT_UPLOAD_CREATED_ID_DESC = "DOCUMENT_UPLOAD_CREATED_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  KIND_ASC = "KIND_ASC",
  KIND_DESC = "KIND_DESC",
  MESSAGE_ASC = "MESSAGE_ASC",
  MESSAGE_DESC = "MESSAGE_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_COUNT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_COUNT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_COUNT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_COUNT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MAX_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MAX_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_MIN_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_MIN_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_SUM_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_SUM_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_MESSAGE_ID__COUNT_ASC = "MESSAGE_SENTS_BY_MESSAGE_ID__COUNT_ASC",
  MESSAGE_SENTS_BY_MESSAGE_ID__COUNT_DESC = "MESSAGE_SENTS_BY_MESSAGE_ID__COUNT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_AVERAGE_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_COUNT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_COUNT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_COUNT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_COUNT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MAX_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_MIN_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_SUM_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_READ_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_READ_ASC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_READ_ASC",
  MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_READ_DESC = "MESSAGE_UPDATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_READ_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  SENDER_USER_ID_ASC = "SENDER_USER_ID_ASC",
  SENDER_USER_ID_DESC = "SENDER_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_AVERAGE_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_COUNT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_COUNT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_COUNT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_COUNT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_DISTINCT_COUNT_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MAX_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_MIN_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_POPULATION_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_STDDEV_SAMPLE_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_SUM_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_POPULATION_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_TEMPORARY_PASSWORD_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID__COUNT_ASC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID__COUNT_ASC",
  USER_INVITE_CREATEDS_BY_MESSAGE_ID__COUNT_DESC = "USER_INVITE_CREATEDS_BY_MESSAGE_ID__COUNT_DESC",
}

export type MessageSent = Node & {
  __typename?: "MessageSent";
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `MessageCreated` that is related to this `MessageSent`. */
  message?: Maybe<MessageCreated>;
  messageId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `MessageSent`. */
  messageSentsByResponseToMessageSentId: MessageSentsConnection;
  /** Reads and enables pagination through a set of `MessageSent`. */
  messageSentsBySeedMessageSentId: MessageSentsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `MessageSent`. */
  recipientUser?: Maybe<AuditorUser>;
  recipientUserId: Scalars["UUID"]["output"];
  /** Reads a single `MessageSent` that is related to this `MessageSent`. */
  responseToMessageSent?: Maybe<MessageSent>;
  responseToMessageSentId?: Maybe<Scalars["UUID"]["output"]>;
  /** Reads a single `MessageSent` that is related to this `MessageSent`. */
  seedMessageSent?: Maybe<MessageSent>;
  seedMessageSentId?: Maybe<Scalars["UUID"]["output"]>;
};

export type MessageSentmessageSentsByResponseToMessageSentIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageSentCondition>;
  filter?: InputMaybe<MessageSentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

export type MessageSentmessageSentsBySeedMessageSentIdArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageSentCondition>;
  filter?: InputMaybe<MessageSentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

export type MessageSentAggregates = {
  __typename?: "MessageSentAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<MessageSentDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `MessageSent` object types. */
export type MessageSentAggregatesFilter = {
  /** Distinct count aggregate over matching `MessageSent` objects. */
  distinctCount?: InputMaybe<MessageSentDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `MessageSent` object to be included within the aggregate. */
  filter?: InputMaybe<MessageSentFilter>;
};

/**
 * A condition to be used against `MessageSent` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type MessageSentCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `recipientUserId` field. */
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `responseToMessageSentId` field. */
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `seedMessageSentId` field. */
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
};

export type MessageSentDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  messageId?: InputMaybe<BigIntFilter>;
  recipientUserId?: InputMaybe<BigIntFilter>;
  responseToMessageSentId?: InputMaybe<BigIntFilter>;
  seedMessageSentId?: InputMaybe<BigIntFilter>;
};

export type MessageSentDistinctCountAggregates = {
  __typename?: "MessageSentDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of messageId across the matching connection */
  messageId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of recipientUserId across the matching connection */
  recipientUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of responseToMessageSentId across the matching connection */
  responseToMessageSentId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of seedMessageSentId across the matching connection */
  seedMessageSentId?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `MessageSent` object types. All fields are combined with a logical ‘and.’ */
export type MessageSentFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<MessageSentFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `message` relation. */
  message?: InputMaybe<MessageCreatedFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `messageSentsByResponseToMessageSentId` relation. */
  messageSentsByResponseToMessageSentId?: InputMaybe<MessageSentToManyMessageSentFilter>;
  /** Some related `messageSentsByResponseToMessageSentId` exist. */
  messageSentsByResponseToMessageSentIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Filter by the object’s `messageSentsBySeedMessageSentId` relation. */
  messageSentsBySeedMessageSentId?: InputMaybe<MessageSentToManyMessageSentFilter>;
  /** Some related `messageSentsBySeedMessageSentId` exist. */
  messageSentsBySeedMessageSentIdExist?: InputMaybe<
    Scalars["Boolean"]["input"]
  >;
  /** Negates the expression. */
  not?: InputMaybe<MessageSentFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<MessageSentFilter>>;
  /** Filter by the object’s `recipientUser` relation. */
  recipientUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `recipientUserId` field. */
  recipientUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `responseToMessageSent` relation. */
  responseToMessageSent?: InputMaybe<MessageSentFilter>;
  /** A related `responseToMessageSent` exists. */
  responseToMessageSentExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `responseToMessageSentId` field. */
  responseToMessageSentId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `seedMessageSent` relation. */
  seedMessageSent?: InputMaybe<MessageSentFilter>;
  /** A related `seedMessageSent` exists. */
  seedMessageSentExists?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `seedMessageSentId` field. */
  seedMessageSentId?: InputMaybe<UUIDFilter>;
};

/** Grouping methods for `MessageSent` for usage during aggregation. */
export enum MessageSentGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  MESSAGE_ID = "MESSAGE_ID",
  RECIPIENT_USER_ID = "RECIPIENT_USER_ID",
  RESPONSE_TO_MESSAGE_SENT_ID = "RESPONSE_TO_MESSAGE_SENT_ID",
  SEED_MESSAGE_SENT_ID = "SEED_MESSAGE_SENT_ID",
}

export type MessageSentHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `MessageSent` aggregates. */
export type MessageSentHavingInput = {
  AND?: InputMaybe<Array<MessageSentHavingInput>>;
  OR?: InputMaybe<Array<MessageSentHavingInput>>;
  average?: InputMaybe<MessageSentHavingAverageInput>;
  distinctCount?: InputMaybe<MessageSentHavingDistinctCountInput>;
  max?: InputMaybe<MessageSentHavingMaxInput>;
  min?: InputMaybe<MessageSentHavingMinInput>;
  stddevPopulation?: InputMaybe<MessageSentHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<MessageSentHavingStddevSampleInput>;
  sum?: InputMaybe<MessageSentHavingSumInput>;
  variancePopulation?: InputMaybe<MessageSentHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<MessageSentHavingVarianceSampleInput>;
};

export type MessageSentHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type MessageSentHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `MessageSent` */
export type MessageSentInput = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  emailTemplate?: InputMaybe<MessageSentInputEmailTemplateType>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum MessageSentInputEmailTemplateType {
  ONBOARD_COMMERCIAL = "ONBOARD_COMMERCIAL",
  ONBOARD_INTERNAL = "ONBOARD_INTERNAL",
  ONBOARD_MDH = "ONBOARD_MDH",
}

/** Input for the nested mutation of `messageCreated` in the `MessageSentInput` mutation. */
export type MessageSentMessageIdFkeyInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<MessageCreatedMessageCreatedPkeyConnect>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageCreatedNodeIdConnect>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<MessageSentMessageIdFkeyMessageCreatedCreateInput>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<MessageCreatedOnMessageSentForMessageSentMessageIdFkeyUsingMessageCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageSentOnMessageSentForMessageSentMessageIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `messageSent` in the `MessageCreatedInput` mutation. */
export type MessageSentMessageIdFkeyInverseInput = {
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MessageSentMessageSentPkeyConnect>>;
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MessageSentNodeIdConnect>>;
  /** A `MessageSentInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<MessageSentMessageIdFkeyMessageSentCreateInput>>;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MessageSentOnMessageSentForMessageSentMessageIdFkeyUsingMessageSentPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<MessageCreatedOnMessageSentForMessageSentMessageIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type MessageSentMessageIdFkeyMessageCreatedCreateInput = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: MessageCreatedKind;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

/** The `messageSent` to be created by this mutation. */
export type MessageSentMessageIdFkeyMessageSentCreateInput = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  emailTemplate?: InputMaybe<MessageSentInputEmailTemplateType>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The fields on `messageSent` to look up the row to connect. */
export type MessageSentMessageSentPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type MessageSentNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `messageSent` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type MessageSentOnMessageSentForMessageSentMessageIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `messageCreated` being updated. */
  patch: MessageCreatedPatch;
};

/** The fields on `messageSent` to look up the row to update. */
export type MessageSentOnMessageSentForMessageSentMessageIdFkeyUsingMessageSentPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: updateMessageSentOnMessageSentForMessageSentMessageIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageSentOnMessageSentForMessageSentRecipientUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `messageSent` to look up the row to update. */
export type MessageSentOnMessageSentForMessageSentRecipientUserIdFkeyUsingMessageSentPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: updateMessageSentOnMessageSentForMessageSentRecipientUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageSent` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: MessageSentPatch;
  };

/** The fields on `messageSent` to look up the row to update. */
export type MessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkUsingMessageSentPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: updateMessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type MessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageSent` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: MessageSentPatch;
  };

/** The fields on `messageSent` to look up the row to update. */
export type MessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkUsingMessageSentPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `messageSent` being updated. */
    patch: updateMessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkPatch;
  };

/** Represents an update to a `MessageSent`. Fields that are set will be updated. */
export type MessageSentPatch = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The `auditorUser` to be created by this mutation. */
export type MessageSentRecipientUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `MessageSentInput` mutation. */
export type MessageSentRecipientUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<MessageSentRecipientUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageSentOnMessageSentForMessageSentRecipientUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `messageSent` in the `AuditorUserInput` mutation. */
export type MessageSentRecipientUserIdFkeyInverseInput = {
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectById?: InputMaybe<Array<MessageSentMessageSentPkeyConnect>>;
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<MessageSentNodeIdConnect>>;
  /** A `MessageSentInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<MessageSentRecipientUserIdFkeyMessageSentCreateInput>
  >;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<MessageSentOnMessageSentForMessageSentRecipientUserIdFkeyUsingMessageSentPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyNodeIdUpdate>
  >;
};

/** The `messageSent` to be created by this mutation. */
export type MessageSentRecipientUserIdFkeyMessageSentCreateInput = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  emailTemplate?: InputMaybe<MessageSentInputEmailTemplateType>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** A filter to be used against many `MessageSent` object types. All fields are combined with a logical ‘and.’ */
export type MessageSentToManyMessageSentFilter = {
  /** Aggregates across related `MessageSent` match the filter criteria. */
  aggregates?: InputMaybe<MessageSentAggregatesFilter>;
  /** Every related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<MessageSentFilter>;
  /** No related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<MessageSentFilter>;
  /** Some related `MessageSent` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<MessageSentFilter>;
};

/** A connection to a list of `MessageSent` values. */
export type MessageSentsConnection = {
  __typename?: "MessageSentsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<MessageSentAggregates>;
  /** A list of edges which contains the `MessageSent` and cursor to aid in pagination. */
  edges: Array<MessageSentsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<MessageSentAggregates>>;
  /** A list of `MessageSent` objects. */
  nodes: Array<Maybe<MessageSent>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MessageSent` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `MessageSent` values. */
export type MessageSentsConnectiongroupedAggregatesArgs = {
  groupBy: Array<MessageSentGroupBy>;
  having?: InputMaybe<MessageSentHavingInput>;
};

/** A `MessageSent` edge in the connection. */
export type MessageSentsEdge = {
  __typename?: "MessageSentsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `MessageSent` at the end of the edge. */
  node?: Maybe<MessageSent>;
};

/** Methods to use when ordering `MessageSent`. */
export enum MessageSentsOrderBy {
  AUDITOR_USER_BY_RECIPIENT_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__ID_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__ID_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__ID_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__ID_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_RECIPIENT_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_RECIPIENT_USER_ID__USER_TYPE_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__KIND_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__KIND_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__KIND_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__KIND_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_DESC",
  MESSAGE_ID_ASC = "MESSAGE_ID_ASC",
  MESSAGE_ID_DESC = "MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_COUNT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_COUNT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_COUNT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_COUNT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID__COUNT_ASC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID__COUNT_ASC",
  MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID__COUNT_DESC = "MESSAGE_SENTS_BY_RESPONSE_TO_MESSAGE_SENT_ID__COUNT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_AVERAGE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_COUNT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_COUNT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_COUNT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_COUNT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_DISTINCT_COUNT_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MAX_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_MIN_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_STDDEV_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_SUM_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_POPULATION_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_CREATED_AT_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RECIPIENT_USER_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID_VARIANCE_SAMPLE_SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID__COUNT_ASC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID__COUNT_ASC",
  MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID__COUNT_DESC = "MESSAGE_SENTS_BY_SEED_MESSAGE_SENT_ID__COUNT_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__CREATED_AT_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__CREATED_AT_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__CREATED_AT_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__CREATED_AT_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__ID_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__ID_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__ID_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__ID_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__MESSAGE_ID_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__MESSAGE_ID_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__MESSAGE_ID_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__MESSAGE_ID_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RECIPIENT_USER_ID_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RECIPIENT_USER_ID_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RECIPIENT_USER_ID_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RECIPIENT_USER_ID_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENT_BY_RESPONSE_TO_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__CREATED_AT_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__CREATED_AT_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__CREATED_AT_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__CREATED_AT_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__ID_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__ID_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__ID_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__ID_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__MESSAGE_ID_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__MESSAGE_ID_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__MESSAGE_ID_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__MESSAGE_ID_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RECIPIENT_USER_ID_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RECIPIENT_USER_ID_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RECIPIENT_USER_ID_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RECIPIENT_USER_ID_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_ASC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_ASC",
  MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_DESC = "MESSAGE_SENT_BY_SEED_MESSAGE_SENT_ID__SEED_MESSAGE_SENT_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  RECIPIENT_USER_ID_ASC = "RECIPIENT_USER_ID_ASC",
  RECIPIENT_USER_ID_DESC = "RECIPIENT_USER_ID_DESC",
  RESPONSE_TO_MESSAGE_SENT_ID_ASC = "RESPONSE_TO_MESSAGE_SENT_ID_ASC",
  RESPONSE_TO_MESSAGE_SENT_ID_DESC = "RESPONSE_TO_MESSAGE_SENT_ID_DESC",
  SEED_MESSAGE_SENT_ID_ASC = "SEED_MESSAGE_SENT_ID_ASC",
  SEED_MESSAGE_SENT_ID_DESC = "SEED_MESSAGE_SENT_ID_DESC",
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: "Mutation";
  appendDocumentState: DocumentStateEnum;
  archiveDirectory?: Maybe<Directory>;
  archiveFile?: Maybe<File>;
  askAutodorCreateSavedPrompt: AskAutodorCreateSavedPromptResponse;
  askAutodorDeleteSavedPrompt: Scalars["Boolean"]["output"];
  askAutodorRateResponse: AskAutodorRateResponseResponse;
  askAutodorResetSavedPromptTags: Scalars["Boolean"]["output"];
  /**
   * ### Overview
   * DEPRECATED: Updates the status of a set of bucket claims specified.
   * Bucket claims are deprecated, and the application instead uses the workstation view to group sets of claims for application users.
   */
  bucketClaimsUpdateStatus?: Maybe<BucketClaimsUpdateStatusPayload>;
  confirmFileUploadSuccess: File;
  confirmFileUploadSuccessAlaffiaApp: Scalars["Boolean"]["output"];
  createAskAutodorQueue: AskAutodorQueue;
  /** Creates a single `AuditFinding`. */
  createAuditFinding?: Maybe<CreateAuditFindingPayload>;
  /** Creates a single `AuditFindingReasonCode`. */
  createAuditFindingReasonCode?: Maybe<CreateAuditFindingReasonCodePayload>;
  /** Creates a single `AuditFindingReview`. */
  createAuditFindingReview?: Maybe<CreateAuditFindingReviewPayload>;
  /** Creates a single `AuditorBucket`. */
  createAuditorBucket?: Maybe<CreateAuditorBucketPayload>;
  /** Creates a single `AuditorBucketAssignee`. */
  createAuditorBucketAssignee?: Maybe<CreateAuditorBucketAssigneePayload>;
  /** Creates a single `AuditorUser`. */
  createAuditorUser?: Maybe<CreateAuditorUserPayload>;
  /** Creates a single `BatchClaimAssignee`. */
  createBatchClaimAssignee?: Maybe<CreateBatchClaimAssigneePayload>;
  /** Creates a single `BatchClaimDueDate`. */
  createBatchClaimDueDate?: Maybe<CreateBatchClaimDueDatePayload>;
  /** Creates a single `BatchClaimLineItem`. */
  createBatchClaimLineItem?: Maybe<CreateBatchClaimLineItemPayload>;
  /** Creates a single `BatchClaimLineItemAudit`. */
  createBatchClaimLineItemAudit?: Maybe<CreateBatchClaimLineItemAuditPayload>;
  /** Creates a single `BatchClaimLineItemNnIbLine`. */
  createBatchClaimLineItemNnIbLine?: Maybe<CreateBatchClaimLineItemNnIbLinePayload>;
  /** Creates a single `BucketClaim`. */
  createBucketClaim?: Maybe<CreateBucketClaimPayload>;
  createCase: Case;
  /** Creates a single `ClientSignOffClaimState`. */
  createClientSignOffClaimState?: Maybe<CreateClientSignOffClaimStatePayload>;
  createDirectory: Directory;
  /** Creates a single `DisputedFinding`. */
  createDisputedFinding?: Maybe<CreateDisputedFindingPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.disputed_finding for the set of batch_claim_ids passed
   */
  createDisputedFindings?: Maybe<CreateDisputedFindingsPayload>;
  /** Creates a single `DocumentRequestCreated`. */
  createDocumentRequestCreated?: Maybe<CreateDocumentRequestCreatedPayload>;
  /** Creates a single `DocumentUploadCreated`. */
  createDocumentUploadCreated?: Maybe<CreateDocumentUploadCreatedPayload>;
  /**
   * ## Overview
   *
   * Inserts records into document_request_created for an array of batch_claim. Triggers an update of batch_claim_state.documentation_claim_state by calling function t2_update_cache_document_claim_state_on_doc_req
   */
  createDocumentationRequests?: Maybe<CreateDocumentationRequestsPayload>;
  /** Creates a single `DocumentationSelectionSet`. */
  createDocumentationSelectionSet?: Maybe<CreateDocumentationSelectionSetPayload>;
  createIbLineFindings: CreateAuditFindingsPayload;
  /** Creates a single `InsurerUser`. */
  createInsurerUser?: Maybe<CreateInsurerUserPayload>;
  /** Creates a single `InvoiceClaimState`. */
  createInvoiceClaimState?: Maybe<CreateInvoiceClaimStatePayload>;
  /** Creates a single `MessageCreated`. */
  createMessageCreated?: Maybe<CreateMessageCreatedPayload>;
  /** Creates a single `MessageSent`. */
  createMessageSent?: Maybe<CreateMessageSentPayload>;
  /** Creates a single `NegotiationClaimState`. */
  createNegotiationClaimState?: Maybe<CreateNegotiationClaimStatePayload>;
  /** Creates a single `NnIbLine`. */
  createNnIbLine?: Maybe<CreateNnIbLinePayload>;
  /** Creates a single `OnHoldClaimState`. */
  createOnHoldClaimState?: Maybe<CreateOnHoldClaimStatePayload>;
  /** Creates a single `ProviderSignOffClaimState`. */
  createProviderSignOffClaimState?: Maybe<CreateProviderSignOffClaimStatePayload>;
  /** Creates a single `ProviderUserFacility`. */
  createProviderUserFacility?: Maybe<CreateProviderUserFacilityPayload>;
  /** Creates a single `Queue`. */
  createQueue?: Maybe<CreateQueuePayload>;
  /**
   * # Overview
   * Creates the reported claim entries to track reporting of a batch of claims received as input.
   * This routine is called by the report service primarily, mirroring the pattern used for reporting audit findings.
   * Therefore, generally the only caller of this function should be the report service using the reporter role in the database, at report generation time.
   */
  createReportedClaims?: Maybe<CreateReportedClaimsPayload>;
  /**
   * ### Overview
   * Inserts an array of reported findings into the reported findings table.
   */
  createReportedFindings?: Maybe<CreateReportedFindingsPayload>;
  /** Creates a single `ReviewedAuditFinding`. */
  createReviewedAuditFinding?: Maybe<CreateReviewedAuditFindingPayload>;
  /** Creates a single `S3AuditDocumentation`. */
  createS3AuditDocumentation?: Maybe<CreateS3AuditDocumentationPayload>;
  createUbClaimFindings: CreateAuditFindingsPayload;
  createUbClaimLineFindings: CreateAuditFindingsPayload;
  createUser: CreateUserOutput;
  /** Creates a single `UserInviteCreated`. */
  createUserInviteCreated?: Maybe<CreateUserInviteCreatedPayload>;
  /** Creates a single `WorkflowClaimState`. */
  createWorkflowClaimState?: Maybe<CreateWorkflowClaimStatePayload>;
  deleteAskAutodorQueue?: Maybe<AskAutodorQueue>;
  /** Deletes a single `AuditorBucket` using a unique key. */
  deleteAuditorBucket?: Maybe<DeleteAuditorBucketPayload>;
  /** Deletes a single `AuditorBucket` using its globally unique id. */
  deleteAuditorBucketByNodeId?: Maybe<DeleteAuditorBucketPayload>;
  deleteCase?: Maybe<Case>;
  deleteDirectory?: Maybe<Directory>;
  /**
   * # Overview
   * Inserts records into document_upload_created for an array of batch_claim, with deleted=true
   */
  deleteDocumentationRequests?: Maybe<DeleteDocumentationRequestsPayload>;
  deleteFile?: Maybe<File>;
  /**
   * ##Overview
   *
   * Deletes s3_audit_documentation cache records given an array of s3Keys
   */
  deleteKeysFromS3Cache?: Maybe<DeleteKeysFromS3CachePayload>;
  editUser: EditUserResponse;
  generateDocRequestReport?: Maybe<Scalars["Boolean"]["output"]>;
  generateReport: GenerateReportResponse;
  onboardUser: OnboardUserOutput;
  prepareFileForUpload: PrepareFileForUploadResult;
  prompt: Message;
  promptMessage: Message;
  /**
   * ## Overview
   * Refreshes the audit documentation cache table for either a provider id, or a specific claim for that provider.
   * Audit documentation is populated in our s3 schema our foreign data wrapper.
   * The foreign data wrapper re-fetches each row in the table at query time, leading to very slow query times.
   * Our cache is a copy of the s3.audit_documentation table into the v3 schema to enable faster query access patterns there.
   * Certain events, like an application user uploading new audit documentation may automatically trigger or call this function.
   * Pass force_refresh as true in the input arguments if you must guarantee a refresh.
   * Caution: the column ordering matters in the select call, as it dictates the column mapping in the cached table.
   */
  refreshS3AuditDocumentation?: Maybe<RefreshS3AuditDocumentationPayload>;
  reviewAuditFindings: ReviewAuditFindingsPayload;
  s3CreateObjectHandler?: Maybe<S3CreateObjectHandlerResponse>;
  s3DataImport: Scalars["Boolean"]["output"];
  sendDocumentUploadNotification: NotificationResponse;
  sendUserOnboardNotification: NotificationResponse;
  /**
   * ### Overview
   * Bulk Inserts on v3.batch_claim_assignee for the set of batch_claim_ids passed
   */
  setAssignees?: Maybe<SetAssigneesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.client_sign_off_claim_state for the set of batch_claim_ids passed
   */
  setClientSignOffClaimStates?: Maybe<SetClientSignOffClaimStatesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on batch_claim_due_date for the set of batch_claim_ids and dates passed
   */
  setDueDates?: Maybe<SetDueDatesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.invoice_claim_state for the set of batch_claim_ids passed
   */
  setInvoiceClaimStates?: Maybe<SetInvoiceClaimStatesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.negotiation_claim_state for the set of batch_claim_ids passed
   */
  setNegotiationClaimStates?: Maybe<SetNegotiationClaimStatesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.on_hold_claim_state for the set of batch_claim_ids passed
   */
  setOnHoldClaimStates?: Maybe<SetOnHoldClaimStatesPayload>;
  /**
   * ### Overview
   * Bulk Inserts on v3.provider_sign_off_claim_state for the set of batch_claim_ids passed
   */
  setProviderSignOffClaimStates?: Maybe<SetProviderSignOffClaimStatesPayload>;
  setUserMfaPreference: UserMfaPreferenceResponse;
  /**
   * ### Overview
   * Bulk Inserts on v3.workflow_claim_state for the set of batch_claim_ids passed
   */
  setWorkflowClaimStates?: Maybe<SetWorkflowClaimStatesPayload>;
  updateAskAutodorQueue?: Maybe<AskAutodorQueue>;
  /** Updates a single `AuditFinding` using a unique key and a patch. */
  updateAuditFinding?: Maybe<UpdateAuditFindingPayload>;
  /** Updates a single `AuditFinding` using its globally unique id and a patch. */
  updateAuditFindingByNodeId?: Maybe<UpdateAuditFindingPayload>;
  /** Updates a single `AuditFindingReasonCode` using a unique key and a patch. */
  updateAuditFindingReasonCode?: Maybe<UpdateAuditFindingReasonCodePayload>;
  /** Updates a single `AuditFindingReasonCode` using its globally unique id and a patch. */
  updateAuditFindingReasonCodeByNodeId?: Maybe<UpdateAuditFindingReasonCodePayload>;
  /** Updates a single `AuditorBucket` using a unique key and a patch. */
  updateAuditorBucket?: Maybe<UpdateAuditorBucketPayload>;
  /** Updates a single `AuditorBucket` using its globally unique id and a patch. */
  updateAuditorBucketByNodeId?: Maybe<UpdateAuditorBucketPayload>;
  /** Updates a single `AuditorUser` using a unique key and a patch. */
  updateAuditorUser?: Maybe<UpdateAuditorUserPayload>;
  /** Updates a single `AuditorUser` using a unique key and a patch. */
  updateAuditorUserByEmail?: Maybe<UpdateAuditorUserPayload>;
  /** Updates a single `AuditorUser` using its globally unique id and a patch. */
  updateAuditorUserByNodeId?: Maybe<UpdateAuditorUserPayload>;
  /** Updates a single `BatchClaimLineItem` using a unique key and a patch. */
  updateBatchClaimLineItem?: Maybe<UpdateBatchClaimLineItemPayload>;
  /** Updates a single `BatchClaimLineItem` using its globally unique id and a patch. */
  updateBatchClaimLineItemByNodeId?: Maybe<UpdateBatchClaimLineItemPayload>;
  /** Updates a single `BatchClaimLineItemNnIbLine` using a unique key and a patch. */
  updateBatchClaimLineItemNnIbLine?: Maybe<UpdateBatchClaimLineItemNnIbLinePayload>;
  /** Updates a single `BatchClaimLineItemNnIbLine` using a unique key and a patch. */
  updateBatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineId?: Maybe<UpdateBatchClaimLineItemNnIbLinePayload>;
  /** Updates a single `BatchClaimLineItemNnIbLine` using its globally unique id and a patch. */
  updateBatchClaimLineItemNnIbLineByNodeId?: Maybe<UpdateBatchClaimLineItemNnIbLinePayload>;
  /**
   * # Overview
   * Recalculates the computed values for a claim line in response to the strategy used by a batch claim being updated.
   * This function is not directly a trigger function, as the trigger function that calls this function is on the batch claim table.
   */
  updateBclComputationsFromComputationStrategy?: Maybe<UpdateBclComputationsFromComputationStrategyPayload>;
  /**
   * # Overview
   * Recalculates the computed values for a claim line item in response to the strategy used by a batch claim being updated.
   * This function is not directly a trigger function, as the trigger function that calls this function is on the batch claim table.
   */
  updateBcliComputationsFromComputationStrategy?: Maybe<UpdateBcliComputationsFromComputationStrategyPayload>;
  /** Updates a single `BucketClaim` using a unique key and a patch. */
  updateBucketClaim?: Maybe<UpdateBucketClaimPayload>;
  /** Updates a single `BucketClaim` using a unique key and a patch. */
  updateBucketClaimByBatchClaimId?: Maybe<UpdateBucketClaimPayload>;
  /** Updates a single `BucketClaim` using a unique key and a patch. */
  updateBucketClaimByBatchClaimIdAndAuditorBucketId?: Maybe<UpdateBucketClaimPayload>;
  /** Updates a single `BucketClaim` using its globally unique id and a patch. */
  updateBucketClaimByNodeId?: Maybe<UpdateBucketClaimPayload>;
  updateCase: Case;
  updateDirectory?: Maybe<Directory>;
  updateFile?: Maybe<File>;
  /** Updates a single `NnIbLine` using a unique key and a patch. */
  updateNnIbLine?: Maybe<UpdateNnIbLinePayload>;
  /** Updates a single `NnIbLine` using a unique key and a patch. */
  updateNnIbLineByFingerprintAndS3Key?: Maybe<UpdateNnIbLinePayload>;
  /** Updates a single `NnIbLine` using its globally unique id and a patch. */
  updateNnIbLineByNodeId?: Maybe<UpdateNnIbLinePayload>;
  /** Updates a single `ProviderUserFacility` using a unique key and a patch. */
  updateProviderUserFacility?: Maybe<UpdateProviderUserFacilityPayload>;
  /** Updates a single `ProviderUserFacility` using its globally unique id and a patch. */
  updateProviderUserFacilityByNodeId?: Maybe<UpdateProviderUserFacilityPayload>;
  /** Updates a single `ProviderUserFacility` using a unique key and a patch. */
  updateProviderUserFacilityByProviderIdAndAuditorUserId?: Maybe<UpdateProviderUserFacilityPayload>;
  /** Updates a single `Queue` using a unique key and a patch. */
  updateQueue?: Maybe<UpdateQueuePayload>;
  /** Updates a single `Queue` using its globally unique id and a patch. */
  updateQueueByNodeId?: Maybe<UpdateQueuePayload>;
  /** Updates a single `ReviewedAuditFinding` using a unique key and a patch. */
  updateReviewedAuditFinding?: Maybe<UpdateReviewedAuditFindingPayload>;
  /** Updates a single `ReviewedAuditFinding` using its globally unique id and a patch. */
  updateReviewedAuditFindingByNodeId?: Maybe<UpdateReviewedAuditFindingPayload>;
  /** Updates a single `S3AuditDocumentation` using a unique key and a patch. */
  updateS3AuditDocumentation?: Maybe<UpdateS3AuditDocumentationPayload>;
  /** Updates a single `S3AuditDocumentation` using its globally unique id and a patch. */
  updateS3AuditDocumentationByNodeId?: Maybe<UpdateS3AuditDocumentationPayload>;
  updateUserProfile?: Maybe<Scalars["Boolean"]["output"]>;
  versionAuditFindings: CreateAuditFindingsPayload;
  versionReport: GenerateReportResponse;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationappendDocumentStateArgs = {
  input: DocumentStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationarchiveDirectoryArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationarchiveFileArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationaskAutodorCreateSavedPromptArgs = {
  input: AskAutodorCreateSavedPromptInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationaskAutodorDeleteSavedPromptArgs = {
  input: AskAutodorDeleteSavedPromptInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationaskAutodorRateResponseArgs = {
  input: AskAutodorRateResponseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationaskAutodorResetSavedPromptTagsArgs = {
  input?: InputMaybe<AskAutodorSavedPromptsNewTagsInput>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationbucketClaimsUpdateStatusArgs = {
  input: BucketClaimsUpdateStatusInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationconfirmFileUploadSuccessArgs = {
  input: ConfirmFileUploadSuccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationconfirmFileUploadSuccessAlaffiaAppArgs = {
  input: ConfirmFileUploadSuccessInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAskAutodorQueueArgs = {
  input: CreateAskAutodorQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditFindingArgs = {
  input: CreateAuditFindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditFindingReasonCodeArgs = {
  input: CreateAuditFindingReasonCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditFindingReviewArgs = {
  input: CreateAuditFindingReviewInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditorBucketArgs = {
  input: CreateAuditorBucketInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditorBucketAssigneeArgs = {
  input: CreateAuditorBucketAssigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateAuditorUserArgs = {
  input: CreateAuditorUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBatchClaimAssigneeArgs = {
  input: CreateBatchClaimAssigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBatchClaimDueDateArgs = {
  input: CreateBatchClaimDueDateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBatchClaimLineItemArgs = {
  input: CreateBatchClaimLineItemInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBatchClaimLineItemAuditArgs = {
  input: CreateBatchClaimLineItemAuditInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBatchClaimLineItemNnIbLineArgs = {
  input: CreateBatchClaimLineItemNnIbLineInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateBucketClaimArgs = {
  input: CreateBucketClaimInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateCaseArgs = {
  input: CreateCaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateClientSignOffClaimStateArgs = {
  input: CreateClientSignOffClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDirectoryArgs = {
  input: CreateDirectoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDisputedFindingArgs = {
  input: CreateDisputedFindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDisputedFindingsArgs = {
  input: CreateDisputedFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDocumentRequestCreatedArgs = {
  input: CreateDocumentRequestCreatedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDocumentUploadCreatedArgs = {
  input: CreateDocumentUploadCreatedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDocumentationRequestsArgs = {
  input: CreateDocumentationRequestsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateDocumentationSelectionSetArgs = {
  input: CreateDocumentationSelectionSetInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateIbLineFindingsArgs = {
  input: CreateIbLineFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateInsurerUserArgs = {
  input: CreateInsurerUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateInvoiceClaimStateArgs = {
  input: CreateInvoiceClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateMessageCreatedArgs = {
  input: CreateMessageCreatedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateMessageSentArgs = {
  input: CreateMessageSentInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateNegotiationClaimStateArgs = {
  input: CreateNegotiationClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateNnIbLineArgs = {
  input: CreateNnIbLineInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateOnHoldClaimStateArgs = {
  input: CreateOnHoldClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateProviderSignOffClaimStateArgs = {
  input: CreateProviderSignOffClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateProviderUserFacilityArgs = {
  input: CreateProviderUserFacilityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateQueueArgs = {
  input: CreateQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateReportedClaimsArgs = {
  input: CreateReportedClaimsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateReportedFindingsArgs = {
  input: CreateReportedFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateReviewedAuditFindingArgs = {
  input: CreateReviewedAuditFindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateS3AuditDocumentationArgs = {
  input: CreateS3AuditDocumentationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateUbClaimFindingsArgs = {
  input: CreateUbClaimFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateUbClaimLineFindingsArgs = {
  input: CreateUbClaimLineFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateUserInviteCreatedArgs = {
  input: CreateUserInviteCreatedInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationcreateWorkflowClaimStateArgs = {
  input: CreateWorkflowClaimStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteAskAutodorQueueArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteAuditorBucketArgs = {
  input: DeleteAuditorBucketInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteAuditorBucketByNodeIdArgs = {
  input: DeleteAuditorBucketByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteCaseArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteDirectoryArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteDocumentationRequestsArgs = {
  input: DeleteDocumentationRequestsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteFileArgs = {
  id: Scalars["ID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationdeleteKeysFromS3CacheArgs = {
  input: DeleteKeysFromS3CacheInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationeditUserArgs = {
  input: EditUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationgenerateDocRequestReportArgs = {
  providerId: Scalars["String"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationgenerateReportArgs = {
  batchClaimIds: Array<Scalars["UUID"]["input"]>;
  primaryPiaId: Scalars["UUID"]["input"];
  reportSubtype: AuditFindingReportSubtype;
  reportType: AuditFindingReportType;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationonboardUserArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationprepareFileForUploadArgs = {
  input: PrepareFileForUploadInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationpromptArgs = {
  input: PromptInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationpromptMessageArgs = {
  input: PromptInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationrefreshS3AuditDocumentationArgs = {
  input: RefreshS3AuditDocumentationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationreviewAuditFindingsArgs = {
  input: ReviewAuditFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutations3CreateObjectHandlerArgs = {
  s3Key: Scalars["String"]["input"];
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutations3DataImportArgs = {
  input: S3DataImportInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsendDocumentUploadNotificationArgs = {
  input: SendDocumentUploadNoficationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsendUserOnboardNotificationArgs = {
  input: SendUserOnboardNotificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetAssigneesArgs = {
  input: SetAssigneesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetClientSignOffClaimStatesArgs = {
  input: SetClientSignOffClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetDueDatesArgs = {
  input: SetDueDatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetInvoiceClaimStatesArgs = {
  input: SetInvoiceClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetNegotiationClaimStatesArgs = {
  input: SetNegotiationClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetOnHoldClaimStatesArgs = {
  input: SetOnHoldClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetProviderSignOffClaimStatesArgs = {
  input: SetProviderSignOffClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetUserMfaPreferenceArgs = {
  input: UserMfaPreferenceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationsetWorkflowClaimStatesArgs = {
  input: SetWorkflowClaimStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAskAutodorQueueArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateAskAutodorQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditFindingArgs = {
  input: UpdateAuditFindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditFindingByNodeIdArgs = {
  input: UpdateAuditFindingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditFindingReasonCodeArgs = {
  input: UpdateAuditFindingReasonCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditFindingReasonCodeByNodeIdArgs = {
  input: UpdateAuditFindingReasonCodeByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditorBucketArgs = {
  input: UpdateAuditorBucketInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditorBucketByNodeIdArgs = {
  input: UpdateAuditorBucketByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditorUserArgs = {
  input: UpdateAuditorUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditorUserByEmailArgs = {
  input: UpdateAuditorUserByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateAuditorUserByNodeIdArgs = {
  input: UpdateAuditorUserByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBatchClaimLineItemArgs = {
  input: UpdateBatchClaimLineItemInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBatchClaimLineItemByNodeIdArgs = {
  input: UpdateBatchClaimLineItemByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBatchClaimLineItemNnIbLineArgs = {
  input: UpdateBatchClaimLineItemNnIbLineInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineIdArgs =
  {
    input: UpdateBatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBatchClaimLineItemNnIbLineByNodeIdArgs = {
  input: UpdateBatchClaimLineItemNnIbLineByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBclComputationsFromComputationStrategyArgs = {
  input: UpdateBclComputationsFromComputationStrategyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBcliComputationsFromComputationStrategyArgs = {
  input: UpdateBcliComputationsFromComputationStrategyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBucketClaimArgs = {
  input: UpdateBucketClaimInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBucketClaimByBatchClaimIdArgs = {
  input: UpdateBucketClaimByBatchClaimIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBucketClaimByBatchClaimIdAndAuditorBucketIdArgs = {
  input: UpdateBucketClaimByBatchClaimIdAndAuditorBucketIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateBucketClaimByNodeIdArgs = {
  input: UpdateBucketClaimByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateCaseArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateCaseInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateDirectoryArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateDirectoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateFileArgs = {
  id: Scalars["ID"]["input"];
  input: UpdateFileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateNnIbLineArgs = {
  input: UpdateNnIbLineInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateNnIbLineByFingerprintAndS3KeyArgs = {
  input: UpdateNnIbLineByFingerprintAndS3KeyInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateNnIbLineByNodeIdArgs = {
  input: UpdateNnIbLineByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateProviderUserFacilityArgs = {
  input: UpdateProviderUserFacilityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateProviderUserFacilityByNodeIdArgs = {
  input: UpdateProviderUserFacilityByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateProviderUserFacilityByProviderIdAndAuditorUserIdArgs =
  {
    input: UpdateProviderUserFacilityByProviderIdAndAuditorUserIdInput;
  };

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateQueueArgs = {
  input: UpdateQueueInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateQueueByNodeIdArgs = {
  input: UpdateQueueByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateReviewedAuditFindingArgs = {
  input: UpdateReviewedAuditFindingInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateReviewedAuditFindingByNodeIdArgs = {
  input: UpdateReviewedAuditFindingByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateS3AuditDocumentationArgs = {
  input: UpdateS3AuditDocumentationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateS3AuditDocumentationByNodeIdArgs = {
  input: UpdateS3AuditDocumentationByNodeIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationupdateUserProfileArgs = {
  input: UserProfileInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationversionAuditFindingsArgs = {
  input: VersionAuditFindingsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationversionReportArgs = {
  reportId: Scalars["UUID"]["input"];
  reportSubtype: AuditFindingReportSubtype;
  reportType: AuditFindingReportType;
};

export type NegotiationClaimState = Node & {
  __typename?: "NegotiationClaimState";
  /** Reads a single `BatchClaim` that is related to this `NegotiationClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  negotiationState: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type NegotiationClaimStateAggregates = {
  __typename?: "NegotiationClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NegotiationClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NegotiationClaimState` object types. */
export type NegotiationClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `NegotiationClaimState` objects. */
  distinctCount?: InputMaybe<NegotiationClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NegotiationClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<NegotiationClaimStateFilter>;
};

/** Input for the nested mutation of `batchClaim` in the `NegotiationClaimStateInput` mutation. */
export type NegotiationClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<NegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `negotiationClaimState` in the `BatchClaimInput` mutation. */
export type NegotiationClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `negotiationClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<NegotiationClaimStateNegotiationClaimStatePkeyConnect>
  >;
  /** The primary key(s) for `negotiationClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<NegotiationClaimStateNodeIdConnect>>;
  /** A `NegotiationClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<NegotiationClaimStateBatchClaimIdFkNegotiationClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `negotiationClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<NegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingNegotiationClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `negotiationClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The `negotiationClaimState` to be created by this mutation. */
export type NegotiationClaimStateBatchClaimIdFkNegotiationClaimStateCreateInput =
  {
    batchClaimToBatchClaimId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    negotiationState?: InputMaybe<Scalars["String"]["input"]>;
  };

/**
 * A condition to be used against `NegotiationClaimState` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type NegotiationClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `negotiationState` field. */
  negotiationState?: InputMaybe<Scalars["String"]["input"]>;
};

export type NegotiationClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  negotiationState?: InputMaybe<BigIntFilter>;
};

export type NegotiationClaimStateDistinctCountAggregates = {
  __typename?: "NegotiationClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of negotiationState across the matching connection */
  negotiationState?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum NegotiationClaimStateEnum {
  NEGOTIATING = "NEGOTIATING",
  NOT_NEGOTIATING = "NOT_NEGOTIATING",
}

/** A filter to be used against NegotiationClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type NegotiationClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<NegotiationClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<NegotiationClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<NegotiationClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<NegotiationClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<NegotiationClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<NegotiationClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<NegotiationClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<NegotiationClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<NegotiationClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<NegotiationClaimStateEnum>>;
};

/** A filter to be used against `NegotiationClaimState` object types. All fields are combined with a logical ‘and.’ */
export type NegotiationClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NegotiationClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `negotiationState` field. */
  negotiationState?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NegotiationClaimStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NegotiationClaimStateFilter>>;
};

/** Grouping methods for `NegotiationClaimState` for usage during aggregation. */
export enum NegotiationClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  NEGOTIATION_STATE = "NEGOTIATION_STATE",
}

export type NegotiationClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NegotiationClaimState` aggregates. */
export type NegotiationClaimStateHavingInput = {
  AND?: InputMaybe<Array<NegotiationClaimStateHavingInput>>;
  OR?: InputMaybe<Array<NegotiationClaimStateHavingInput>>;
  average?: InputMaybe<NegotiationClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<NegotiationClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<NegotiationClaimStateHavingMaxInput>;
  min?: InputMaybe<NegotiationClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<NegotiationClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NegotiationClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<NegotiationClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<NegotiationClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NegotiationClaimStateHavingVarianceSampleInput>;
};

export type NegotiationClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NegotiationClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `NegotiationClaimState` */
export type NegotiationClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  negotiationState?: InputMaybe<Scalars["String"]["input"]>;
};

/** The fields on `negotiationClaimState` to look up the row to connect. */
export type NegotiationClaimStateNegotiationClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type NegotiationClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `negotiationClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type NegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `negotiationClaimState` to look up the row to update. */
export type NegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkUsingNegotiationClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `negotiationClaimState` being updated. */
    patch: updateNegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `NegotiationClaimState`. Fields that are set will be updated. */
export type NegotiationClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInput>;
};

/** A connection to a list of `NegotiationClaimState` values. */
export type NegotiationClaimStatesConnection = {
  __typename?: "NegotiationClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NegotiationClaimStateAggregates>;
  /** A list of edges which contains the `NegotiationClaimState` and cursor to aid in pagination. */
  edges: Array<NegotiationClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NegotiationClaimStateAggregates>>;
  /** A list of `NegotiationClaimState` objects. */
  nodes: Array<Maybe<NegotiationClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NegotiationClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NegotiationClaimState` values. */
export type NegotiationClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<NegotiationClaimStateGroupBy>;
  having?: InputMaybe<NegotiationClaimStateHavingInput>;
};

/** A `NegotiationClaimState` edge in the connection. */
export type NegotiationClaimStatesEdge = {
  __typename?: "NegotiationClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NegotiationClaimState` at the end of the edge. */
  node?: Maybe<NegotiationClaimState>;
};

/** Methods to use when ordering `NegotiationClaimState`. */
export enum NegotiationClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  NEGOTIATION_STATE_ASC = "NEGOTIATION_STATE_ASC",
  NEGOTIATION_STATE_DESC = "NEGOTIATION_STATE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

export type NnIbLine = Node & {
  __typename?: "NnIbLine";
  /** Reads a single `AuditorUser` that is related to this `NnIbLine`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  /** Reads and enables pagination through a set of `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItemNnIbLines: BatchClaimLineItemNnIbLinesConnection;
  createdAt: Scalars["Datetime"]["output"];
  fingerprint: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  s3Key: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type NnIbLinebatchClaimLineItemNnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemNnIbLineCondition>;
  filter?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
};

export type NnIbLineAggregates = {
  __typename?: "NnIbLineAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<NnIbLineDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `NnIbLine` object types. */
export type NnIbLineAggregatesFilter = {
  /** Distinct count aggregate over matching `NnIbLine` objects. */
  distinctCount?: InputMaybe<NnIbLineDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `NnIbLine` object to be included within the aggregate. */
  filter?: InputMaybe<NnIbLineFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type NnIbLineAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `NnIbLineInput` mutation. */
export type NnIbLineAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<NnIbLineAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `nnIbLine` in the `AuditorUserInput` mutation. */
export type NnIbLineAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectByFingerprintAndS3Key?: InputMaybe<
    Array<NnIbLineNnIbLineFingerprintS3KeyKeyConnect>
  >;
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectById?: InputMaybe<Array<NnIbLineNnIbLinePkeyConnect>>;
  /** The primary key(s) for `nnIbLine` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<NnIbLineNodeIdConnect>>;
  /** A `NnIbLineInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<NnIbLineAuthorIdFkeyNnIbLineCreateInput>>;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateByFingerprintAndS3Key?: InputMaybe<
    Array<NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyUsingNnIbLineFingerprintS3KeyKeyUpdate>
  >;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyUsingNnIbLinePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `nnIbLine` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `nnIbLine` to be created by this mutation. */
export type NnIbLineAuthorIdFkeyNnIbLineCreateInput = {
  auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fingerprint: Scalars["UUID"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  s3Key: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/**
 * A condition to be used against `NnIbLine` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type NnIbLineCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `fingerprint` field. */
  fingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type NnIbLineDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  fingerprint?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  s3Key?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type NnIbLineDistinctCountAggregates = {
  __typename?: "NnIbLineDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of fingerprint across the matching connection */
  fingerprint?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3Key across the matching connection */
  s3Key?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `NnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type NnIbLineFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NnIbLineFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `batchClaimLineItemNnIbLines` relation. */
  batchClaimLineItemNnIbLines?: InputMaybe<NnIbLineToManyBatchClaimLineItemNnIbLineFilter>;
  /** Some related `batchClaimLineItemNnIbLines` exist. */
  batchClaimLineItemNnIbLinesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `fingerprint` field. */
  fingerprint?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<NnIbLineFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NnIbLineFilter>>;
  /** Filter by the object’s `s3Key` field. */
  s3Key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `NnIbLine` for usage during aggregation. */
export enum NnIbLineGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  FINGERPRINT = "FINGERPRINT",
  S3_KEY = "S3_KEY",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type NnIbLineHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `NnIbLine` aggregates. */
export type NnIbLineHavingInput = {
  AND?: InputMaybe<Array<NnIbLineHavingInput>>;
  OR?: InputMaybe<Array<NnIbLineHavingInput>>;
  average?: InputMaybe<NnIbLineHavingAverageInput>;
  distinctCount?: InputMaybe<NnIbLineHavingDistinctCountInput>;
  max?: InputMaybe<NnIbLineHavingMaxInput>;
  min?: InputMaybe<NnIbLineHavingMinInput>;
  stddevPopulation?: InputMaybe<NnIbLineHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<NnIbLineHavingStddevSampleInput>;
  sum?: InputMaybe<NnIbLineHavingSumInput>;
  variancePopulation?: InputMaybe<NnIbLineHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<NnIbLineHavingVarianceSampleInput>;
};

export type NnIbLineHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type NnIbLineHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `NnIbLine` */
export type NnIbLineInput = {
  auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fingerprint: Scalars["UUID"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  s3Key: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `nnIbLine` to look up the row to connect. */
export type NnIbLineNnIbLineFingerprintS3KeyKeyConnect = {
  fingerprint: Scalars["UUID"]["input"];
  s3Key: Scalars["String"]["input"];
};

/** The fields on `nnIbLine` to look up the row to connect. */
export type NnIbLineNnIbLinePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to connect. */
export type NnIbLineNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `nnIbLine` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaimLineItemNnIbLine` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
    patch: BatchClaimLineItemNnIbLinePatch;
  };

/** The fields on `nnIbLine` to look up the row to update. */
export type NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingNnIbLineFingerprintS3KeyKeyUpdate =
  {
    fingerprint: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `nnIbLine` being updated. */
    patch: updateNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch;
    s3Key: Scalars["String"]["input"];
  };

/** The fields on `nnIbLine` to look up the row to update. */
export type NnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyUsingNnIbLinePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `nnIbLine` being updated. */
    patch: updateNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The fields on `nnIbLine` to look up the row to update. */
export type NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyUsingNnIbLineFingerprintS3KeyKeyUpdate =
  {
    fingerprint: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `nnIbLine` being updated. */
    patch: updateNnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyPatch;
    s3Key: Scalars["String"]["input"];
  };

/** The fields on `nnIbLine` to look up the row to update. */
export type NnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyUsingNnIbLinePkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `nnIbLine` being updated. */
  patch: updateNnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyPatch;
};

/** Represents an update to a `NnIbLine`. Fields that are set will be updated. */
export type NnIbLinePatch = {
  auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A filter to be used against many `BatchClaimLineItemNnIbLine` object types. All fields are combined with a logical ‘and.’ */
export type NnIbLineToManyBatchClaimLineItemNnIbLineFilter = {
  /** Aggregates across related `BatchClaimLineItemNnIbLine` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimLineItemNnIbLineAggregatesFilter>;
  /** Every related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** No related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  /** Some related `BatchClaimLineItemNnIbLine` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
};

/** A connection to a list of `NnIbLine` values. */
export type NnIbLinesConnection = {
  __typename?: "NnIbLinesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<NnIbLineAggregates>;
  /** A list of edges which contains the `NnIbLine` and cursor to aid in pagination. */
  edges: Array<NnIbLinesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<NnIbLineAggregates>>;
  /** A list of `NnIbLine` objects. */
  nodes: Array<Maybe<NnIbLine>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NnIbLine` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `NnIbLine` values. */
export type NnIbLinesConnectiongroupedAggregatesArgs = {
  groupBy: Array<NnIbLineGroupBy>;
  having?: InputMaybe<NnIbLineHavingInput>;
};

/** A `NnIbLine` edge in the connection. */
export type NnIbLinesEdge = {
  __typename?: "NnIbLinesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NnIbLine` at the end of the edge. */
  node?: Maybe<NnIbLine>;
};

/** Methods to use when ordering `NnIbLine`. */
export enum NnIbLinesOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_NN_IB_LINE_ID__COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_NN_IB_LINE_ID__COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_NN_IB_LINE_ID__COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_BY_NN_IB_LINE_ID__COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_COUNT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MAX_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_MIN_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_SUM_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ITEM_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_NN_IB_LINE_ID_DESC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIM_LINE_ITEM_NN_IB_LINES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  FINGERPRINT_ASC = "FINGERPRINT_ASC",
  FINGERPRINT_DESC = "FINGERPRINT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  S3_KEY_ASC = "S3_KEY_ASC",
  S3_KEY_DESC = "S3_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
};

export type NonBillableAuditRule = {
  __typename?: "NonBillableAuditRule";
  code: Scalars["String"]["output"];
  description: Scalars["String"]["output"];
};

export type NotificationDigest = {
  __typename?: "NotificationDigest";
  email: EmailServerResponse;
  messageSentId?: Maybe<Scalars["UUID"]["output"]>;
  recipientUserEmail: Scalars["String"]["output"];
  recipientUserId: Scalars["UUID"]["output"];
};

export type NotificationResponse = {
  __typename?: "NotificationResponse";
  messageCreatedId: Scalars["UUID"]["output"];
  notificationKind: Scalars["String"]["output"];
  notifications: Array<Maybe<NotificationDigest>>;
};

export type NpiProvider = {
  __typename?: "NpiProvider";
  npi?: Maybe<Scalars["String"]["output"]>;
  providers?: Maybe<Array<Maybe<Provider>>>;
};

/** A filter to be used against `NpiProvider` object types. All fields are combined with a logical ‘and.’ */
export type NpiProviderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<NpiProviderFilter>>;
  /** Negates the expression. */
  not?: InputMaybe<NpiProviderFilter>;
  /** Filter by the object’s `npi` field. */
  npi?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<NpiProviderFilter>>;
};

/** A connection to a list of `NpiProvider` values. */
export type NpiProvidersConnection = {
  __typename?: "NpiProvidersConnection";
  /** A list of edges which contains the `NpiProvider` and cursor to aid in pagination. */
  edges: Array<NpiProvidersEdge>;
  /** A list of `NpiProvider` objects. */
  nodes: Array<Maybe<NpiProvider>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `NpiProvider` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `NpiProvider` edge in the connection. */
export type NpiProvidersEdge = {
  __typename?: "NpiProvidersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `NpiProvider` at the end of the edge. */
  node?: Maybe<NpiProvider>;
};

export type OnHoldClaimState = Node & {
  __typename?: "OnHoldClaimState";
  /** Reads a single `BatchClaim` that is related to this `OnHoldClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  onHoldState: Scalars["String"]["output"];
};

export type OnHoldClaimStateAggregates = {
  __typename?: "OnHoldClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OnHoldClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `OnHoldClaimState` object types. */
export type OnHoldClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `OnHoldClaimState` objects. */
  distinctCount?: InputMaybe<OnHoldClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `OnHoldClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<OnHoldClaimStateFilter>;
};

/** Input for the nested mutation of `batchClaim` in the `OnHoldClaimStateInput` mutation. */
export type OnHoldClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<OnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `onHoldClaimState` in the `BatchClaimInput` mutation. */
export type OnHoldClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `onHoldClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OnHoldClaimStateOnHoldClaimStatePkeyConnect>>;
  /** The primary key(s) for `onHoldClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<OnHoldClaimStateNodeIdConnect>>;
  /** A `OnHoldClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OnHoldClaimStateBatchClaimIdFkOnHoldClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `onHoldClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingOnHoldClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `onHoldClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The `onHoldClaimState` to be created by this mutation. */
export type OnHoldClaimStateBatchClaimIdFkOnHoldClaimStateCreateInput = {
  batchClaimToBatchClaimId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  onHoldState?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * A condition to be used against `OnHoldClaimState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OnHoldClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `onHoldState` field. */
  onHoldState?: InputMaybe<Scalars["String"]["input"]>;
};

export type OnHoldClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  onHoldState?: InputMaybe<BigIntFilter>;
};

export type OnHoldClaimStateDistinctCountAggregates = {
  __typename?: "OnHoldClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of onHoldState across the matching connection */
  onHoldState?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum OnHoldClaimStateEnum {
  NOT_ON_HOLD = "NOT_ON_HOLD",
  ON_HOLD = "ON_HOLD",
}

/** A filter to be used against OnHoldClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type OnHoldClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<OnHoldClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<OnHoldClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<OnHoldClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<OnHoldClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<OnHoldClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<OnHoldClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<OnHoldClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<OnHoldClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<OnHoldClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<OnHoldClaimStateEnum>>;
};

/** A filter to be used against `OnHoldClaimState` object types. All fields are combined with a logical ‘and.’ */
export type OnHoldClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OnHoldClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OnHoldClaimStateFilter>;
  /** Filter by the object’s `onHoldState` field. */
  onHoldState?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OnHoldClaimStateFilter>>;
};

/** Grouping methods for `OnHoldClaimState` for usage during aggregation. */
export enum OnHoldClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  ON_HOLD_STATE = "ON_HOLD_STATE",
}

export type OnHoldClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OnHoldClaimState` aggregates. */
export type OnHoldClaimStateHavingInput = {
  AND?: InputMaybe<Array<OnHoldClaimStateHavingInput>>;
  OR?: InputMaybe<Array<OnHoldClaimStateHavingInput>>;
  average?: InputMaybe<OnHoldClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<OnHoldClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<OnHoldClaimStateHavingMaxInput>;
  min?: InputMaybe<OnHoldClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<OnHoldClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OnHoldClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<OnHoldClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<OnHoldClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OnHoldClaimStateHavingVarianceSampleInput>;
};

export type OnHoldClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OnHoldClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `OnHoldClaimState` */
export type OnHoldClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  onHoldState?: InputMaybe<Scalars["String"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OnHoldClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `onHoldClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The fields on `onHoldClaimState` to look up the row to connect. */
export type OnHoldClaimStateOnHoldClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type OnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `onHoldClaimState` to look up the row to update. */
export type OnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkUsingOnHoldClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `onHoldClaimState` being updated. */
    patch: updateOnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `OnHoldClaimState`. Fields that are set will be updated. */
export type OnHoldClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInput>;
};

/** A connection to a list of `OnHoldClaimState` values. */
export type OnHoldClaimStatesConnection = {
  __typename?: "OnHoldClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OnHoldClaimStateAggregates>;
  /** A list of edges which contains the `OnHoldClaimState` and cursor to aid in pagination. */
  edges: Array<OnHoldClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OnHoldClaimStateAggregates>>;
  /** A list of `OnHoldClaimState` objects. */
  nodes: Array<Maybe<OnHoldClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OnHoldClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `OnHoldClaimState` values. */
export type OnHoldClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<OnHoldClaimStateGroupBy>;
  having?: InputMaybe<OnHoldClaimStateHavingInput>;
};

/** A `OnHoldClaimState` edge in the connection. */
export type OnHoldClaimStatesEdge = {
  __typename?: "OnHoldClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `OnHoldClaimState` at the end of the edge. */
  node?: Maybe<OnHoldClaimState>;
};

/** Methods to use when ordering `OnHoldClaimState`. */
export enum OnHoldClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  ON_HOLD_STATE_ASC = "ON_HOLD_STATE_ASC",
  ON_HOLD_STATE_DESC = "ON_HOLD_STATE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
}

export type OnboardUserOutput = {
  __typename?: "OnboardUserOutput";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export enum OperationTypeEnum {
  AVERAGE = "AVERAGE",
  COUNT = "COUNT",
  SUM = "SUM",
}

export type PTPAuditRule = {
  __typename?: "PTPAuditRule";
  colOneCode: Scalars["String"]["output"];
  colTwoCode: Scalars["String"]["output"];
  extendedDescription: Scalars["String"]["output"];
  rationale: Scalars["String"]["output"];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]["output"]>;
};

/** Input for the nested mutation of `auditorUser` in the `AuditFindingReportInput` mutation. */
export type PreliminaryAuditFindingReportAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReport` in the `AuditorUserInput` mutation. */
export type PreliminaryAuditFindingReportAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReportNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyNodeIdUpdate>
  >;
};

/** Input for the nested mutation of `provider` in the `AuditFindingReportInput` mutation. */
export type PreliminaryAuditFindingReportProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `auditFindingReport` in the `ProviderInput` mutation. */
export type PreliminaryAuditFindingReportProviderIdFkeyInverseInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>
  >;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<AuditFindingReportNodeIdConnect>>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<AuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyNodeIdUpdate>
  >;
};

export type PrepareFileForUploadInput = {
  caseId?: InputMaybe<Scalars["ID"]["input"]>;
  fileName: Scalars["String"]["input"];
  parentDirectoryId?: InputMaybe<Scalars["ID"]["input"]>;
  queueId?: InputMaybe<Scalars["ID"]["input"]>;
  size: Scalars["Int"]["input"];
};

export type PrepareFileForUploadResult = {
  __typename?: "PrepareFileForUploadResult";
  fileId: Scalars["ID"]["output"];
  fileUploadId: Scalars["UUID"]["output"];
  signedUploadUrl: SignedUploadUrl;
};

export type ProcedureCode = Node & {
  __typename?: "ProcedureCode";
  codeType: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["String"]["output"];
  lastUpdated?: Maybe<Scalars["Datetime"]["output"]>;
  longDescription: Scalars["String"]["output"];
  mediumDescription?: Maybe<Scalars["String"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `ProcedureCodeType` that is related to this `ProcedureCode`. */
  procedureCodeTypeByCodeType?: Maybe<ProcedureCodeType>;
  shortDescription: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type ProcedureCodeAggregates = {
  __typename?: "ProcedureCodeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProcedureCodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ProcedureCode` object types. */
export type ProcedureCodeAggregatesFilter = {
  /** Distinct count aggregate over matching `ProcedureCode` objects. */
  distinctCount?: InputMaybe<ProcedureCodeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProcedureCode` object to be included within the aggregate. */
  filter?: InputMaybe<ProcedureCodeFilter>;
};

/**
 * A condition to be used against `ProcedureCode` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProcedureCodeCondition = {
  /** Checks for equality with the object’s `codeType` field. */
  codeType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `longDescription` field. */
  longDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `mediumDescription` field. */
  mediumDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ProcedureCodeDistinctCountAggregateFilter = {
  codeType?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  lastUpdated?: InputMaybe<BigIntFilter>;
  longDescription?: InputMaybe<BigIntFilter>;
  mediumDescription?: InputMaybe<BigIntFilter>;
  shortDescription?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type ProcedureCodeDistinctCountAggregates = {
  __typename?: "ProcedureCodeDistinctCountAggregates";
  /** Distinct count of codeType across the matching connection */
  codeType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdated across the matching connection */
  lastUpdated?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of longDescription across the matching connection */
  longDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of mediumDescription across the matching connection */
  mediumDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of shortDescription across the matching connection */
  shortDescription?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ProcedureCode` object types. All fields are combined with a logical ‘and.’ */
export type ProcedureCodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProcedureCodeFilter>>;
  /** Filter by the object’s `codeType` field. */
  codeType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `longDescription` field. */
  longDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `mediumDescription` field. */
  mediumDescription?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProcedureCodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProcedureCodeFilter>>;
  /** Filter by the object’s `procedureCodeTypeByCodeType` relation. */
  procedureCodeTypeByCodeType?: InputMaybe<ProcedureCodeTypeFilter>;
  /** Filter by the object’s `shortDescription` field. */
  shortDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ProcedureCode` for usage during aggregation. */
export enum ProcedureCodeGroupBy {
  CODE_TYPE = "CODE_TYPE",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  LAST_UPDATED = "LAST_UPDATED",
  LAST_UPDATED_TRUNCATED_TO_DAY = "LAST_UPDATED_TRUNCATED_TO_DAY",
  LAST_UPDATED_TRUNCATED_TO_HOUR = "LAST_UPDATED_TRUNCATED_TO_HOUR",
  LONG_DESCRIPTION = "LONG_DESCRIPTION",
  MEDIUM_DESCRIPTION = "MEDIUM_DESCRIPTION",
  SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ProcedureCodeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ProcedureCode` aggregates. */
export type ProcedureCodeHavingInput = {
  AND?: InputMaybe<Array<ProcedureCodeHavingInput>>;
  OR?: InputMaybe<Array<ProcedureCodeHavingInput>>;
  average?: InputMaybe<ProcedureCodeHavingAverageInput>;
  distinctCount?: InputMaybe<ProcedureCodeHavingDistinctCountInput>;
  max?: InputMaybe<ProcedureCodeHavingMaxInput>;
  min?: InputMaybe<ProcedureCodeHavingMinInput>;
  stddevPopulation?: InputMaybe<ProcedureCodeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProcedureCodeHavingStddevSampleInput>;
  sum?: InputMaybe<ProcedureCodeHavingSumInput>;
  variancePopulation?: InputMaybe<ProcedureCodeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProcedureCodeHavingVarianceSampleInput>;
};

export type ProcedureCodeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProcedureCodeType = {
  __typename?: "ProcedureCodeType";
  description: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ProcedureCode`. */
  procedureCodesByCodeType: ProcedureCodesConnection;
  type: Scalars["String"]["output"];
};

export type ProcedureCodeTypeprocedureCodesByCodeTypeArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProcedureCodeCondition>;
  filter?: InputMaybe<ProcedureCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProcedureCodesOrderBy>>;
};

export type ProcedureCodeTypeAggregates = {
  __typename?: "ProcedureCodeTypeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProcedureCodeTypeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `ProcedureCodeType` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ProcedureCodeTypeCondition = {
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProcedureCodeTypeDistinctCountAggregates = {
  __typename?: "ProcedureCodeTypeDistinctCountAggregates";
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of type across the matching connection */
  type?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ProcedureCodeType` object types. All fields are combined with a logical ‘and.’ */
export type ProcedureCodeTypeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProcedureCodeTypeFilter>>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProcedureCodeTypeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProcedureCodeTypeFilter>>;
  /** Filter by the object’s `procedureCodesByCodeType` relation. */
  procedureCodesByCodeType?: InputMaybe<ProcedureCodeTypeToManyProcedureCodeFilter>;
  /** Some related `procedureCodesByCodeType` exist. */
  procedureCodesByCodeTypeExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<StringFilter>;
};

/** Grouping methods for `ProcedureCodeType` for usage during aggregation. */
export enum ProcedureCodeTypeGroupBy {
  DESCRIPTION = "DESCRIPTION",
}

/** Conditions for `ProcedureCodeType` aggregates. */
export type ProcedureCodeTypeHavingInput = {
  AND?: InputMaybe<Array<ProcedureCodeTypeHavingInput>>;
  OR?: InputMaybe<Array<ProcedureCodeTypeHavingInput>>;
};

/** A filter to be used against many `ProcedureCode` object types. All fields are combined with a logical ‘and.’ */
export type ProcedureCodeTypeToManyProcedureCodeFilter = {
  /** Aggregates across related `ProcedureCode` match the filter criteria. */
  aggregates?: InputMaybe<ProcedureCodeAggregatesFilter>;
  /** Every related `ProcedureCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProcedureCodeFilter>;
  /** No related `ProcedureCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProcedureCodeFilter>;
  /** Some related `ProcedureCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProcedureCodeFilter>;
};

/** A connection to a list of `ProcedureCodeType` values. */
export type ProcedureCodeTypesConnection = {
  __typename?: "ProcedureCodeTypesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProcedureCodeTypeAggregates>;
  /** A list of edges which contains the `ProcedureCodeType` and cursor to aid in pagination. */
  edges: Array<ProcedureCodeTypesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProcedureCodeTypeAggregates>>;
  /** A list of `ProcedureCodeType` objects. */
  nodes: Array<Maybe<ProcedureCodeType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProcedureCodeType` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ProcedureCodeType` values. */
export type ProcedureCodeTypesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ProcedureCodeTypeGroupBy>;
  having?: InputMaybe<ProcedureCodeTypeHavingInput>;
};

/** A `ProcedureCodeType` edge in the connection. */
export type ProcedureCodeTypesEdge = {
  __typename?: "ProcedureCodeTypesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProcedureCodeType` at the end of the edge. */
  node?: Maybe<ProcedureCodeType>;
};

/** Methods to use when ordering `ProcedureCodeType`. */
export enum ProcedureCodeTypesOrderBy {
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  NATURAL = "NATURAL",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_AVERAGE_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_COUNT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_COUNT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_COUNT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_COUNT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_DISTINCT_COUNT_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MAX_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MAX_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_MIN_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_MIN_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_POPULATION_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_STDDEV_SAMPLE_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_SUM_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_SUM_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_POPULATION_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CODE_TYPE_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CODE_TYPE_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CODE_TYPE_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CODE_TYPE_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CREATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_CREATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_ID_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_ID_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_ID_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_ID_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LAST_UPDATED_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LAST_UPDATED_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LAST_UPDATED_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LAST_UPDATED_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LONG_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LONG_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LONG_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_LONG_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_MEDIUM_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_MEDIUM_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_MEDIUM_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_MEDIUM_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_SHORT_DESCRIPTION_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_SHORT_DESCRIPTION_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_SHORT_DESCRIPTION_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_SHORT_DESCRIPTION_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  PROCEDURE_CODES_BY_CODE_TYPE__COUNT_ASC = "PROCEDURE_CODES_BY_CODE_TYPE__COUNT_ASC",
  PROCEDURE_CODES_BY_CODE_TYPE__COUNT_DESC = "PROCEDURE_CODES_BY_CODE_TYPE__COUNT_DESC",
  TYPE_ASC = "TYPE_ASC",
  TYPE_DESC = "TYPE_DESC",
}

/** A connection to a list of `ProcedureCode` values. */
export type ProcedureCodesConnection = {
  __typename?: "ProcedureCodesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProcedureCodeAggregates>;
  /** A list of edges which contains the `ProcedureCode` and cursor to aid in pagination. */
  edges: Array<ProcedureCodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProcedureCodeAggregates>>;
  /** A list of `ProcedureCode` objects. */
  nodes: Array<Maybe<ProcedureCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProcedureCode` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ProcedureCode` values. */
export type ProcedureCodesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ProcedureCodeGroupBy>;
  having?: InputMaybe<ProcedureCodeHavingInput>;
};

/** A `ProcedureCode` edge in the connection. */
export type ProcedureCodesEdge = {
  __typename?: "ProcedureCodesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProcedureCode` at the end of the edge. */
  node?: Maybe<ProcedureCode>;
};

/** Methods to use when ordering `ProcedureCode`. */
export enum ProcedureCodesOrderBy {
  CODE_TYPE_ASC = "CODE_TYPE_ASC",
  CODE_TYPE_DESC = "CODE_TYPE_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LAST_UPDATED_ASC = "LAST_UPDATED_ASC",
  LAST_UPDATED_DESC = "LAST_UPDATED_DESC",
  LONG_DESCRIPTION_ASC = "LONG_DESCRIPTION_ASC",
  LONG_DESCRIPTION_DESC = "LONG_DESCRIPTION_DESC",
  MEDIUM_DESCRIPTION_ASC = "MEDIUM_DESCRIPTION_ASC",
  MEDIUM_DESCRIPTION_DESC = "MEDIUM_DESCRIPTION_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROCEDURE_CODE_TYPE_BY_CODE_TYPE__DESCRIPTION_ASC = "PROCEDURE_CODE_TYPE_BY_CODE_TYPE__DESCRIPTION_ASC",
  PROCEDURE_CODE_TYPE_BY_CODE_TYPE__DESCRIPTION_DESC = "PROCEDURE_CODE_TYPE_BY_CODE_TYPE__DESCRIPTION_DESC",
  PROCEDURE_CODE_TYPE_BY_CODE_TYPE__TYPE_ASC = "PROCEDURE_CODE_TYPE_BY_CODE_TYPE__TYPE_ASC",
  PROCEDURE_CODE_TYPE_BY_CODE_TYPE__TYPE_DESC = "PROCEDURE_CODE_TYPE_BY_CODE_TYPE__TYPE_DESC",
  SHORT_DESCRIPTION_ASC = "SHORT_DESCRIPTION_ASC",
  SHORT_DESCRIPTION_DESC = "SHORT_DESCRIPTION_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type PromptFeedback = {
  __typename?: "PromptFeedback";
  level: PromptFeedbackLevel;
  message: Scalars["String"]["output"];
};

export enum PromptFeedbackLevel {
  ERROR = "ERROR",
  INFO = "INFO",
  WARNING = "WARNING",
}

export type PromptInput = {
  fileIds: Array<Scalars["ID"]["input"]>;
  inputState: Scalars["String"]["input"];
  promptText: Scalars["String"]["input"];
  searchKeywords?: InputMaybe<Array<Scalars["String"]["input"]>>;
  threadId: Scalars["ID"]["input"];
};

export type PromptResponse = {
  __typename?: "PromptResponse";
  contextFiles: Array<File>;
  createdAt: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  pages: Array<PromptResponsePage>;
  promptText: Scalars["String"]["output"];
  ratings: PromptResponseRating;
  responseText: Scalars["String"]["output"];
  searchKeywords?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type PromptResponsePage = {
  __typename?: "PromptResponsePage";
  confidence?: Maybe<Scalars["Float"]["output"]>;
  file: File;
  fileId: Scalars["UUID"]["output"];
  filePageId?: Maybe<Scalars["UUID"]["output"]>;
  pageNumber: Scalars["Int"]["output"];
  textMatched: Scalars["String"]["output"];
};

export type PromptResponseRating = {
  __typename?: "PromptResponseRating";
  currentUserRating?: Maybe<PromptResponseRatingType>;
  downRatings: Scalars["Int"]["output"];
  upRatings: Scalars["Int"]["output"];
};

export enum PromptResponseRatingType {
  DOWN = "DOWN",
  UP = "UP",
}

export type Provider = Node & {
  __typename?: "Provider";
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  auditFindingReports: AuditFindingReportsConnection;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  auditorBuckets: AuditorBucketsConnection;
  /** Reads and enables pagination through a set of `AuditorUser`. */
  auditorUsers: AuditorUsersConnection;
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims: BatchClaimsConnection;
  city: Scalars["String"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  /** Reads and enables pagination through a set of `DisputedFinding`. */
  disputedFindings: DisputedFindingsConnection;
  /** Reads and enables pagination through a set of `DocumentUploadCreated`. */
  documentUploadCreateds: DocumentUploadCreatedsConnection;
  /** Reads and enables pagination through a set of `DocumentationSelectionSet`. */
  documentationSelectionSets: DocumentationSelectionSetsConnection;
  id: Scalars["String"]["output"];
  /**
   * ### Overview
   * Calculates improper payment cost per provider using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Aggregate>;
  /**
   * ### Overview
   * Calculates improper payment cost per claim using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCostPerClaim?: Maybe<Aggregate>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  npi: Scalars["String"]["output"];
  practiceType: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `ProviderUserFacility`. */
  providerUserFacilities: ProviderUserFacilitiesConnection;
  spec?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  supportingDocCount: Scalars["Int"]["output"];
  uniquePatients: Scalars["Int"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type ProviderauditFindingReportsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

export type ProviderauditorBucketsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketCondition>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

export type ProviderauditorUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorUserCondition>;
  filter?: InputMaybe<AuditorUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorUsersOrderBy>>;
};

export type ProviderbatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimCondition>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

export type ProviderdisputedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DisputedFindingCondition>;
  filter?: InputMaybe<DisputedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DisputedFindingsOrderBy>>;
};

export type ProviderdocumentUploadCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentUploadCreatedCondition>;
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

export type ProviderdocumentationSelectionSetsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentationSelectionSetCondition>;
  filter?: InputMaybe<DocumentationSelectionSetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentationSelectionSetsOrderBy>>;
};

export type ProviderimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProviderimproperPaymentCostPerClaimArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProviderproviderUserFacilitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderUserFacilityCondition>;
  filter?: InputMaybe<ProviderUserFacilityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderUserFacilitiesOrderBy>>;
};

export type ProviderAggregates = {
  __typename?: "ProviderAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProviderDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type ProviderAuditStat = {
  __typename?: "ProviderAuditStat";
  autoImproperPaymentCost: Aggregate;
  id: Scalars["UUID"]["output"];
  name: Scalars["String"]["output"];
  potentialImproperPaymentCost: Aggregate;
};

/**
 * A condition to be used against `Provider` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ProviderCondition = {
  /** Checks for equality with the object’s `city` field. */
  city?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `npi` field. */
  npi?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `practiceType` field. */
  practiceType?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `spec` field. */
  spec?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `state` field. */
  state?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ProviderDistinctCountAggregates = {
  __typename?: "ProviderDistinctCountAggregates";
  /** Distinct count of city across the matching connection */
  city?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per provider using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCost?: Maybe<Scalars["BigInt"]["output"]>;
  /**
   * Distinct count of this field across the matching connection.
   *
   * ---
   *
   * ### Overview
   * Calculates improper payment cost per claim using heuristics to omit certain findings generated by Autodor.
   */
  improperPaymentCostPerClaim?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of npi across the matching connection */
  npi?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of practiceType across the matching connection */
  practiceType?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of spec across the matching connection */
  spec?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of state across the matching connection */
  state?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

export type ProviderDistinctCountAggregatesimproperPaymentCostArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ProviderDistinctCountAggregatesimproperPaymentCostPerClaimArgs = {
  autodorVersionUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** A filter to be used against `Provider` object types. All fields are combined with a logical ‘and.’ */
export type ProviderFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProviderFilter>>;
  /** Filter by the object’s `auditFindingReports` relation. */
  auditFindingReports?: InputMaybe<ProviderToManyAuditFindingReportFilter>;
  /** Some related `auditFindingReports` exist. */
  auditFindingReportsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditorBuckets` relation. */
  auditorBuckets?: InputMaybe<ProviderToManyAuditorBucketFilter>;
  /** Some related `auditorBuckets` exist. */
  auditorBucketsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `auditorUsers` relation. */
  auditorUsers?: InputMaybe<ProviderToManyAuditorUserFilter>;
  /** Some related `auditorUsers` exist. */
  auditorUsersExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `batchClaims` relation. */
  batchClaims?: InputMaybe<ProviderToManyBatchClaimFilter>;
  /** Some related `batchClaims` exist. */
  batchClaimsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `city` field. */
  city?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `disputedFindings` relation. */
  disputedFindings?: InputMaybe<ProviderToManyDisputedFindingFilter>;
  /** Some related `disputedFindings` exist. */
  disputedFindingsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentUploadCreateds` relation. */
  documentUploadCreateds?: InputMaybe<ProviderToManyDocumentUploadCreatedFilter>;
  /** Some related `documentUploadCreateds` exist. */
  documentUploadCreatedsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `documentationSelectionSets` relation. */
  documentationSelectionSets?: InputMaybe<ProviderToManyDocumentationSelectionSetFilter>;
  /** Some related `documentationSelectionSets` exist. */
  documentationSelectionSetsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `npi` field. */
  npi?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProviderFilter>>;
  /** Filter by the object’s `practiceType` field. */
  practiceType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerUserFacilities` relation. */
  providerUserFacilities?: InputMaybe<ProviderToManyProviderUserFacilityFilter>;
  /** Some related `providerUserFacilities` exist. */
  providerUserFacilitiesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `spec` field. */
  spec?: InputMaybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Provider` for usage during aggregation. */
export enum ProviderGroupBy {
  CITY = "CITY",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  NAME = "NAME",
  NPI = "NPI",
  PRACTICE_TYPE = "PRACTICE_TYPE",
  SPEC = "SPEC",
  STATE = "STATE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ProviderHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Provider` aggregates. */
export type ProviderHavingInput = {
  AND?: InputMaybe<Array<ProviderHavingInput>>;
  OR?: InputMaybe<Array<ProviderHavingInput>>;
  average?: InputMaybe<ProviderHavingAverageInput>;
  distinctCount?: InputMaybe<ProviderHavingDistinctCountInput>;
  max?: InputMaybe<ProviderHavingMaxInput>;
  min?: InputMaybe<ProviderHavingMinInput>;
  stddevPopulation?: InputMaybe<ProviderHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProviderHavingStddevSampleInput>;
  sum?: InputMaybe<ProviderHavingSumInput>;
  variancePopulation?: InputMaybe<ProviderHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProviderHavingVarianceSampleInput>;
};

export type ProviderHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProviderNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `provider` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnAuditorBucketForFkeyAuditorBucketProviderIdNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorBucket` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorBucket` being updated. */
    patch: AuditorBucketPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnAuditorBucketForFkeyAuditorBucketProviderIdUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnAuditorBucketForFkeyAuditorBucketProviderIdPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnAuditorUserForAuditorUserProviderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The fields on `provider` to look up the row to update. */
export type ProviderOnAuditorUserForAuditorUserProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnAuditorUserForAuditorUserProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnBatchClaimForBatchClaimProviderIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `batchClaim` being updated. */
  patch: BatchClaimPatch;
};

/** The fields on `provider` to look up the row to update. */
export type ProviderOnBatchClaimForBatchClaimProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnBatchClaimForBatchClaimProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnDisputedFindingForDisputedFindingProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `disputedFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `disputedFinding` being updated. */
    patch: DisputedFindingPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnDisputedFindingForDisputedFindingProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnDisputedFindingForDisputedFindingProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentUploadCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
    patch: DocumentUploadCreatedPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `documentationSelectionSet` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `documentationSelectionSet` being updated. */
    patch: DocumentationSelectionSetPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `providerUserFacility` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: ProviderUserFacilityPatch;
  };

/** The fields on `provider` to look up the row to update. */
export type ProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderPkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: updateProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyPatch;
  };

/** Represents an update to a `Provider`. Fields that are set will be updated. */
export type ProviderPatch = {
  auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
  auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
  documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
};

/** The fields on `provider` to look up the row to connect. */
export type ProviderProviderPkeyConnect = {
  id: Scalars["String"]["input"];
};

export type ProviderSignOffClaimState = Node & {
  __typename?: "ProviderSignOffClaimState";
  /** Reads a single `BatchClaim` that is related to this `ProviderSignOffClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  providerSignOffState: Scalars["String"]["output"];
};

export type ProviderSignOffClaimStateAggregates = {
  __typename?: "ProviderSignOffClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProviderSignOffClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ProviderSignOffClaimState` object types. */
export type ProviderSignOffClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `ProviderSignOffClaimState` objects. */
  distinctCount?: InputMaybe<ProviderSignOffClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProviderSignOffClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<ProviderSignOffClaimStateFilter>;
};

/** Input for the nested mutation of `batchClaim` in the `ProviderSignOffClaimStateInput` mutation. */
export type ProviderSignOffClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `providerSignOffClaimState` in the `BatchClaimInput` mutation. */
export type ProviderSignOffClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `providerSignOffClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProviderSignOffClaimStateProviderSignOffClaimStatePkeyConnect>
  >;
  /** The primary key(s) for `providerSignOffClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProviderSignOffClaimStateNodeIdConnect>>;
  /** A `ProviderSignOffClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ProviderSignOffClaimStateBatchClaimIdFkProviderSignOffClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `providerSignOffClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingProviderSignOffClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `providerSignOffClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The `providerSignOffClaimState` to be created by this mutation. */
export type ProviderSignOffClaimStateBatchClaimIdFkProviderSignOffClaimStateCreateInput =
  {
    batchClaimToBatchClaimId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    providerSignOffState?: InputMaybe<Scalars["String"]["input"]>;
  };

/**
 * A condition to be used against `ProviderSignOffClaimState` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type ProviderSignOffClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `providerSignOffState` field. */
  providerSignOffState?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProviderSignOffClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  providerSignOffState?: InputMaybe<BigIntFilter>;
};

export type ProviderSignOffClaimStateDistinctCountAggregates = {
  __typename?: "ProviderSignOffClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerSignOffState across the matching connection */
  providerSignOffState?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum ProviderSignOffClaimStateEnum {
  PROVIDER_NO_SIGN_OFF = "PROVIDER_NO_SIGN_OFF",
  PROVIDER_SIGN_OFF = "PROVIDER_SIGN_OFF",
}

/** A filter to be used against ProviderSignOffClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type ProviderSignOffClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ProviderSignOffClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ProviderSignOffClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ProviderSignOffClaimStateEnum>>;
};

/** A filter to be used against `ProviderSignOffClaimState` object types. All fields are combined with a logical ‘and.’ */
export type ProviderSignOffClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProviderSignOffClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProviderSignOffClaimStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProviderSignOffClaimStateFilter>>;
  /** Filter by the object’s `providerSignOffState` field. */
  providerSignOffState?: InputMaybe<StringFilter>;
};

/** Grouping methods for `ProviderSignOffClaimState` for usage during aggregation. */
export enum ProviderSignOffClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  PROVIDER_SIGN_OFF_STATE = "PROVIDER_SIGN_OFF_STATE",
}

export type ProviderSignOffClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ProviderSignOffClaimState` aggregates. */
export type ProviderSignOffClaimStateHavingInput = {
  AND?: InputMaybe<Array<ProviderSignOffClaimStateHavingInput>>;
  OR?: InputMaybe<Array<ProviderSignOffClaimStateHavingInput>>;
  average?: InputMaybe<ProviderSignOffClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<ProviderSignOffClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<ProviderSignOffClaimStateHavingMaxInput>;
  min?: InputMaybe<ProviderSignOffClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<ProviderSignOffClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProviderSignOffClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<ProviderSignOffClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<ProviderSignOffClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProviderSignOffClaimStateHavingVarianceSampleInput>;
};

export type ProviderSignOffClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderSignOffClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ProviderSignOffClaimState` */
export type ProviderSignOffClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerSignOffState?: InputMaybe<Scalars["String"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProviderSignOffClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `providerSignOffClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `providerSignOffClaimState` to look up the row to update. */
export type ProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkUsingProviderSignOffClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `providerSignOffClaimState` being updated. */
    patch: updateProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `ProviderSignOffClaimState`. Fields that are set will be updated. */
export type ProviderSignOffClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInput>;
};

/** The fields on `providerSignOffClaimState` to look up the row to connect. */
export type ProviderSignOffClaimStateProviderSignOffClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `ProviderSignOffClaimState` values. */
export type ProviderSignOffClaimStatesConnection = {
  __typename?: "ProviderSignOffClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProviderSignOffClaimStateAggregates>;
  /** A list of edges which contains the `ProviderSignOffClaimState` and cursor to aid in pagination. */
  edges: Array<ProviderSignOffClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProviderSignOffClaimStateAggregates>>;
  /** A list of `ProviderSignOffClaimState` objects. */
  nodes: Array<Maybe<ProviderSignOffClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProviderSignOffClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ProviderSignOffClaimState` values. */
export type ProviderSignOffClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ProviderSignOffClaimStateGroupBy>;
  having?: InputMaybe<ProviderSignOffClaimStateHavingInput>;
};

/** A `ProviderSignOffClaimState` edge in the connection. */
export type ProviderSignOffClaimStatesEdge = {
  __typename?: "ProviderSignOffClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProviderSignOffClaimState` at the end of the edge. */
  node?: Maybe<ProviderSignOffClaimState>;
};

/** Methods to use when ordering `ProviderSignOffClaimState`. */
export enum ProviderSignOffClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_SIGN_OFF_STATE_ASC = "PROVIDER_SIGN_OFF_STATE_ASC",
  PROVIDER_SIGN_OFF_STATE_DESC = "PROVIDER_SIGN_OFF_STATE_DESC",
}

/** A filter to be used against many `AuditFindingReport` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyAuditFindingReportFilter = {
  /** Aggregates across related `AuditFindingReport` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReportAggregatesFilter>;
  /** Every related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReportFilter>;
  /** No related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReportFilter>;
  /** Some related `AuditFindingReport` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReportFilter>;
};

/** A filter to be used against many `AuditorBucket` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyAuditorBucketFilter = {
  /** Aggregates across related `AuditorBucket` match the filter criteria. */
  aggregates?: InputMaybe<AuditorBucketAggregatesFilter>;
  /** Every related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorBucketFilter>;
  /** No related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorBucketFilter>;
  /** Some related `AuditorBucket` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorBucketFilter>;
};

/** A filter to be used against many `AuditorUser` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyAuditorUserFilter = {
  /** Aggregates across related `AuditorUser` match the filter criteria. */
  aggregates?: InputMaybe<AuditorUserAggregatesFilter>;
  /** Every related `AuditorUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditorUserFilter>;
  /** No related `AuditorUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditorUserFilter>;
  /** Some related `AuditorUser` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditorUserFilter>;
};

/** A filter to be used against many `BatchClaim` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyBatchClaimFilter = {
  /** Aggregates across related `BatchClaim` match the filter criteria. */
  aggregates?: InputMaybe<BatchClaimAggregatesFilter>;
  /** Every related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<BatchClaimFilter>;
  /** No related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<BatchClaimFilter>;
  /** Some related `BatchClaim` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<BatchClaimFilter>;
};

/** A filter to be used against many `DisputedFinding` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyDisputedFindingFilter = {
  /** Aggregates across related `DisputedFinding` match the filter criteria. */
  aggregates?: InputMaybe<DisputedFindingAggregatesFilter>;
  /** Every related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DisputedFindingFilter>;
  /** No related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DisputedFindingFilter>;
  /** Some related `DisputedFinding` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DisputedFindingFilter>;
};

/** A filter to be used against many `DocumentUploadCreated` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyDocumentUploadCreatedFilter = {
  /** Aggregates across related `DocumentUploadCreated` match the filter criteria. */
  aggregates?: InputMaybe<DocumentUploadCreatedAggregatesFilter>;
  /** Every related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentUploadCreatedFilter>;
  /** No related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentUploadCreatedFilter>;
  /** Some related `DocumentUploadCreated` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentUploadCreatedFilter>;
};

/** A filter to be used against many `DocumentationSelectionSet` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyDocumentationSelectionSetFilter = {
  /** Aggregates across related `DocumentationSelectionSet` match the filter criteria. */
  aggregates?: InputMaybe<DocumentationSelectionSetAggregatesFilter>;
  /** Every related `DocumentationSelectionSet` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DocumentationSelectionSetFilter>;
  /** No related `DocumentationSelectionSet` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DocumentationSelectionSetFilter>;
  /** Some related `DocumentationSelectionSet` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DocumentationSelectionSetFilter>;
};

/** A filter to be used against many `ProviderUserFacility` object types. All fields are combined with a logical ‘and.’ */
export type ProviderToManyProviderUserFacilityFilter = {
  /** Aggregates across related `ProviderUserFacility` match the filter criteria. */
  aggregates?: InputMaybe<ProviderUserFacilityAggregatesFilter>;
  /** Every related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ProviderUserFacilityFilter>;
  /** No related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ProviderUserFacilityFilter>;
  /** Some related `ProviderUserFacility` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ProviderUserFacilityFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type ProviderUserFacilitiesAuditorUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `ProviderUserFacilityInput` mutation. */
export type ProviderUserFacilitiesAuditorUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `providerUserFacility` in the `AuditorUserInput` mutation. */
export type ProviderUserFacilitiesAuditorUserIdFkeyInverseInput = {
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProviderUserFacilityProviderUserFacilitiesPkeyConnect>
  >;
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProviderUserFacilityNodeIdConnect>>;
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectByProviderIdAndAuditorUserId?: InputMaybe<
    Array<ProviderUserFacilityProviderUserFacilitiesAuditorUserIdUniqueConnect>
  >;
  /** A `ProviderUserFacilityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ProviderUserFacilitiesAuditorUserIdFkeyProviderUserFacilitiesCreateInput>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingProviderUserFacilitiesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateByProviderIdAndAuditorUserId?: InputMaybe<
    Array<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingProviderUserFacilitiesAuditorUserIdUniqueUpdate>
  >;
};

/** The `providerUserFacility` to be created by this mutation. */
export type ProviderUserFacilitiesAuditorUserIdFkeyProviderUserFacilitiesCreateInput =
  {
    auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** A connection to a list of `ProviderUserFacility` values. */
export type ProviderUserFacilitiesConnection = {
  __typename?: "ProviderUserFacilitiesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProviderUserFacilityAggregates>;
  /** A list of edges which contains the `ProviderUserFacility` and cursor to aid in pagination. */
  edges: Array<ProviderUserFacilitiesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProviderUserFacilityAggregates>>;
  /** A list of `ProviderUserFacility` objects. */
  nodes: Array<Maybe<ProviderUserFacility>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ProviderUserFacility` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ProviderUserFacility` values. */
export type ProviderUserFacilitiesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ProviderUserFacilitiesGroupBy>;
  having?: InputMaybe<ProviderUserFacilitiesHavingInput>;
};

/** A `ProviderUserFacility` edge in the connection. */
export type ProviderUserFacilitiesEdge = {
  __typename?: "ProviderUserFacilitiesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ProviderUserFacility` at the end of the edge. */
  node?: Maybe<ProviderUserFacility>;
};

/** Grouping methods for `ProviderUserFacility` for usage during aggregation. */
export enum ProviderUserFacilitiesGroupBy {
  AUDITOR_USER_ID = "AUDITOR_USER_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  PROVIDER_ID = "PROVIDER_ID",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ProviderUserFacilitiesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ProviderUserFacility` aggregates. */
export type ProviderUserFacilitiesHavingInput = {
  AND?: InputMaybe<Array<ProviderUserFacilitiesHavingInput>>;
  OR?: InputMaybe<Array<ProviderUserFacilitiesHavingInput>>;
  average?: InputMaybe<ProviderUserFacilitiesHavingAverageInput>;
  distinctCount?: InputMaybe<ProviderUserFacilitiesHavingDistinctCountInput>;
  max?: InputMaybe<ProviderUserFacilitiesHavingMaxInput>;
  min?: InputMaybe<ProviderUserFacilitiesHavingMinInput>;
  stddevPopulation?: InputMaybe<ProviderUserFacilitiesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ProviderUserFacilitiesHavingStddevSampleInput>;
  sum?: InputMaybe<ProviderUserFacilitiesHavingSumInput>;
  variancePopulation?: InputMaybe<ProviderUserFacilitiesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ProviderUserFacilitiesHavingVarianceSampleInput>;
};

export type ProviderUserFacilitiesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ProviderUserFacilitiesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `ProviderUserFacility`. */
export enum ProviderUserFacilitiesOrderBy {
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC",
  AUDITOR_USER_ID_ASC = "AUDITOR_USER_ID_ASC",
  AUDITOR_USER_ID_DESC = "AUDITOR_USER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_BY_PROVIDER_ID__CITY_ASC = "PROVIDER_BY_PROVIDER_ID__CITY_ASC",
  PROVIDER_BY_PROVIDER_ID__CITY_DESC = "PROVIDER_BY_PROVIDER_ID__CITY_DESC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__CREATED_AT_DESC",
  PROVIDER_BY_PROVIDER_ID__ID_ASC = "PROVIDER_BY_PROVIDER_ID__ID_ASC",
  PROVIDER_BY_PROVIDER_ID__ID_DESC = "PROVIDER_BY_PROVIDER_ID__ID_DESC",
  PROVIDER_BY_PROVIDER_ID__NAME_ASC = "PROVIDER_BY_PROVIDER_ID__NAME_ASC",
  PROVIDER_BY_PROVIDER_ID__NAME_DESC = "PROVIDER_BY_PROVIDER_ID__NAME_DESC",
  PROVIDER_BY_PROVIDER_ID__NPI_ASC = "PROVIDER_BY_PROVIDER_ID__NPI_ASC",
  PROVIDER_BY_PROVIDER_ID__NPI_DESC = "PROVIDER_BY_PROVIDER_ID__NPI_DESC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_ASC",
  PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC = "PROVIDER_BY_PROVIDER_ID__PRACTICE_TYPE_DESC",
  PROVIDER_BY_PROVIDER_ID__SPEC_ASC = "PROVIDER_BY_PROVIDER_ID__SPEC_ASC",
  PROVIDER_BY_PROVIDER_ID__SPEC_DESC = "PROVIDER_BY_PROVIDER_ID__SPEC_DESC",
  PROVIDER_BY_PROVIDER_ID__STATE_ASC = "PROVIDER_BY_PROVIDER_ID__STATE_ASC",
  PROVIDER_BY_PROVIDER_ID__STATE_DESC = "PROVIDER_BY_PROVIDER_ID__STATE_DESC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_ASC",
  PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC = "PROVIDER_BY_PROVIDER_ID__UPDATED_AT_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** Input for the nested mutation of `provider` in the `ProviderUserFacilityInput` mutation. */
export type ProviderUserFacilitiesProviderIdFkeyInput = {
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectById?: InputMaybe<ProviderProviderPkeyConnect>;
  /** The primary key(s) for `provider` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<ProviderNodeIdConnect>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateById?: InputMaybe<ProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderPkeyUpdate>;
  /** The primary key(s) and patch data for `provider` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `providerUserFacility` in the `ProviderInput` mutation. */
export type ProviderUserFacilitiesProviderIdFkeyInverseInput = {
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ProviderUserFacilityProviderUserFacilitiesPkeyConnect>
  >;
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ProviderUserFacilityNodeIdConnect>>;
  /** The primary key(s) for `providerUserFacility` for the far side of the relationship. */
  connectByProviderIdAndAuditorUserId?: InputMaybe<
    Array<ProviderUserFacilityProviderUserFacilitiesAuditorUserIdUniqueConnect>
  >;
  /** A `ProviderUserFacilityInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ProviderUserFacilitiesProviderIdFkeyProviderUserFacilitiesCreateInput>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderUserFacilitiesPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<ProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `providerUserFacility` for the far side of the relationship. */
  updateByProviderIdAndAuditorUserId?: InputMaybe<
    Array<ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderUserFacilitiesAuditorUserIdUniqueUpdate>
  >;
};

/** The `providerUserFacility` to be created by this mutation. */
export type ProviderUserFacilitiesProviderIdFkeyProviderUserFacilitiesCreateInput =
  {
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

export type ProviderUserFacility = Node & {
  __typename?: "ProviderUserFacility";
  /** Reads a single `AuditorUser` that is related to this `ProviderUserFacility`. */
  auditorUser?: Maybe<AuditorUser>;
  auditorUserId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  /** Reads a single `Provider` that is related to this `ProviderUserFacility`. */
  provider?: Maybe<Provider>;
  providerId: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type ProviderUserFacilityAggregates = {
  __typename?: "ProviderUserFacilityAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ProviderUserFacilityDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ProviderUserFacility` object types. */
export type ProviderUserFacilityAggregatesFilter = {
  /** Distinct count aggregate over matching `ProviderUserFacility` objects. */
  distinctCount?: InputMaybe<ProviderUserFacilityDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ProviderUserFacility` object to be included within the aggregate. */
  filter?: InputMaybe<ProviderUserFacilityFilter>;
};

/**
 * A condition to be used against `ProviderUserFacility` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ProviderUserFacilityCondition = {
  /** Checks for equality with the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ProviderUserFacilityDistinctCountAggregateFilter = {
  auditorUserId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type ProviderUserFacilityDistinctCountAggregates = {
  __typename?: "ProviderUserFacilityDistinctCountAggregates";
  /** Distinct count of auditorUserId across the matching connection */
  auditorUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ProviderUserFacility` object types. All fields are combined with a logical ‘and.’ */
export type ProviderUserFacilityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ProviderUserFacilityFilter>>;
  /** Filter by the object’s `auditorUser` relation. */
  auditorUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ProviderUserFacilityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ProviderUserFacilityFilter>>;
  /** Filter by the object’s `provider` relation. */
  provider?: InputMaybe<ProviderFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `ProviderUserFacility` */
export type ProviderUserFacilityInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ProviderUserFacilityNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `providerUserFacility` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `providerUserFacility` to look up the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingProviderUserFacilitiesAuditorUserIdUniqueUpdate =
  {
    auditorUserId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch;
    providerId: Scalars["String"]["input"];
  };

/** The fields on `providerUserFacility` to look up the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyUsingProviderUserFacilitiesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `provider` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `provider` being updated. */
    patch: ProviderPatch;
  };

/** The fields on `providerUserFacility` to look up the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderUserFacilitiesAuditorUserIdUniqueUpdate =
  {
    auditorUserId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyPatch;
    providerId: Scalars["String"]["input"];
  };

/** The fields on `providerUserFacility` to look up the row to update. */
export type ProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyUsingProviderUserFacilitiesPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `providerUserFacility` being updated. */
    patch: updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyPatch;
  };

/** Represents an update to a `ProviderUserFacility`. Fields that are set will be updated. */
export type ProviderUserFacilityPatch = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `providerUserFacility` to look up the row to connect. */
export type ProviderUserFacilityProviderUserFacilitiesAuditorUserIdUniqueConnect =
  {
    auditorUserId: Scalars["UUID"]["input"];
    providerId: Scalars["String"]["input"];
  };

/** The fields on `providerUserFacility` to look up the row to connect. */
export type ProviderUserFacilityProviderUserFacilitiesPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `Provider` values. */
export type ProvidersConnection = {
  __typename?: "ProvidersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ProviderAggregates>;
  /** A list of edges which contains the `Provider` and cursor to aid in pagination. */
  edges: Array<ProvidersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ProviderAggregates>>;
  /** A list of `Provider` objects. */
  nodes: Array<Maybe<Provider>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Provider` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Provider` values. */
export type ProvidersConnectiongroupedAggregatesArgs = {
  groupBy: Array<ProviderGroupBy>;
  having?: InputMaybe<ProviderHavingInput>;
};

/** A `Provider` edge in the connection. */
export type ProvidersEdge = {
  __typename?: "ProvidersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Provider` at the end of the edge. */
  node?: Maybe<Provider>;
};

/** Methods to use when ordering `Provider`. */
export enum ProvidersOrderBy {
  AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC = "AUDITOR_BUCKETS_AVERAGE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC = "AUDITOR_BUCKETS_AVERAGE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC = "AUDITOR_BUCKETS_AVERAGE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC = "AUDITOR_BUCKETS_AVERAGE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_AVERAGE_FILTER_ASC = "AUDITOR_BUCKETS_AVERAGE_FILTER_ASC",
  AUDITOR_BUCKETS_AVERAGE_FILTER_DESC = "AUDITOR_BUCKETS_AVERAGE_FILTER_DESC",
  AUDITOR_BUCKETS_AVERAGE_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_NAME_ASC = "AUDITOR_BUCKETS_AVERAGE_NAME_ASC",
  AUDITOR_BUCKETS_AVERAGE_NAME_DESC = "AUDITOR_BUCKETS_AVERAGE_NAME_DESC",
  AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_AVERAGE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_BY_PROVIDER_ID__COUNT_ASC = "AUDITOR_BUCKETS_BY_PROVIDER_ID__COUNT_ASC",
  AUDITOR_BUCKETS_BY_PROVIDER_ID__COUNT_DESC = "AUDITOR_BUCKETS_BY_PROVIDER_ID__COUNT_DESC",
  AUDITOR_BUCKETS_COUNT_ASC = "AUDITOR_BUCKETS_COUNT_ASC",
  AUDITOR_BUCKETS_COUNT_DESC = "AUDITOR_BUCKETS_COUNT_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_DUE_DATE_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_FILTER_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_NAME_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_BUCKETS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_MAX_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_MAX_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_MAX_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_MAX_CREATED_AT_ASC = "AUDITOR_BUCKETS_MAX_CREATED_AT_ASC",
  AUDITOR_BUCKETS_MAX_CREATED_AT_DESC = "AUDITOR_BUCKETS_MAX_CREATED_AT_DESC",
  AUDITOR_BUCKETS_MAX_DUE_DATE_ASC = "AUDITOR_BUCKETS_MAX_DUE_DATE_ASC",
  AUDITOR_BUCKETS_MAX_DUE_DATE_DESC = "AUDITOR_BUCKETS_MAX_DUE_DATE_DESC",
  AUDITOR_BUCKETS_MAX_FILTER_ASC = "AUDITOR_BUCKETS_MAX_FILTER_ASC",
  AUDITOR_BUCKETS_MAX_FILTER_DESC = "AUDITOR_BUCKETS_MAX_FILTER_DESC",
  AUDITOR_BUCKETS_MAX_ID_ASC = "AUDITOR_BUCKETS_MAX_ID_ASC",
  AUDITOR_BUCKETS_MAX_ID_DESC = "AUDITOR_BUCKETS_MAX_ID_DESC",
  AUDITOR_BUCKETS_MAX_INSURER_ID_ASC = "AUDITOR_BUCKETS_MAX_INSURER_ID_ASC",
  AUDITOR_BUCKETS_MAX_INSURER_ID_DESC = "AUDITOR_BUCKETS_MAX_INSURER_ID_DESC",
  AUDITOR_BUCKETS_MAX_NAME_ASC = "AUDITOR_BUCKETS_MAX_NAME_ASC",
  AUDITOR_BUCKETS_MAX_NAME_DESC = "AUDITOR_BUCKETS_MAX_NAME_DESC",
  AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_MAX_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_MAX_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC = "AUDITOR_BUCKETS_MAX_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC = "AUDITOR_BUCKETS_MAX_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_MIN_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_MIN_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_MIN_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_MIN_CREATED_AT_ASC = "AUDITOR_BUCKETS_MIN_CREATED_AT_ASC",
  AUDITOR_BUCKETS_MIN_CREATED_AT_DESC = "AUDITOR_BUCKETS_MIN_CREATED_AT_DESC",
  AUDITOR_BUCKETS_MIN_DUE_DATE_ASC = "AUDITOR_BUCKETS_MIN_DUE_DATE_ASC",
  AUDITOR_BUCKETS_MIN_DUE_DATE_DESC = "AUDITOR_BUCKETS_MIN_DUE_DATE_DESC",
  AUDITOR_BUCKETS_MIN_FILTER_ASC = "AUDITOR_BUCKETS_MIN_FILTER_ASC",
  AUDITOR_BUCKETS_MIN_FILTER_DESC = "AUDITOR_BUCKETS_MIN_FILTER_DESC",
  AUDITOR_BUCKETS_MIN_ID_ASC = "AUDITOR_BUCKETS_MIN_ID_ASC",
  AUDITOR_BUCKETS_MIN_ID_DESC = "AUDITOR_BUCKETS_MIN_ID_DESC",
  AUDITOR_BUCKETS_MIN_INSURER_ID_ASC = "AUDITOR_BUCKETS_MIN_INSURER_ID_ASC",
  AUDITOR_BUCKETS_MIN_INSURER_ID_DESC = "AUDITOR_BUCKETS_MIN_INSURER_ID_DESC",
  AUDITOR_BUCKETS_MIN_NAME_ASC = "AUDITOR_BUCKETS_MIN_NAME_ASC",
  AUDITOR_BUCKETS_MIN_NAME_DESC = "AUDITOR_BUCKETS_MIN_NAME_DESC",
  AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_MIN_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_MIN_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC = "AUDITOR_BUCKETS_MIN_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC = "AUDITOR_BUCKETS_MIN_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_SUM_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_SUM_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_SUM_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_SUM_CREATED_AT_ASC = "AUDITOR_BUCKETS_SUM_CREATED_AT_ASC",
  AUDITOR_BUCKETS_SUM_CREATED_AT_DESC = "AUDITOR_BUCKETS_SUM_CREATED_AT_DESC",
  AUDITOR_BUCKETS_SUM_DUE_DATE_ASC = "AUDITOR_BUCKETS_SUM_DUE_DATE_ASC",
  AUDITOR_BUCKETS_SUM_DUE_DATE_DESC = "AUDITOR_BUCKETS_SUM_DUE_DATE_DESC",
  AUDITOR_BUCKETS_SUM_FILTER_ASC = "AUDITOR_BUCKETS_SUM_FILTER_ASC",
  AUDITOR_BUCKETS_SUM_FILTER_DESC = "AUDITOR_BUCKETS_SUM_FILTER_DESC",
  AUDITOR_BUCKETS_SUM_ID_ASC = "AUDITOR_BUCKETS_SUM_ID_ASC",
  AUDITOR_BUCKETS_SUM_ID_DESC = "AUDITOR_BUCKETS_SUM_ID_DESC",
  AUDITOR_BUCKETS_SUM_INSURER_ID_ASC = "AUDITOR_BUCKETS_SUM_INSURER_ID_ASC",
  AUDITOR_BUCKETS_SUM_INSURER_ID_DESC = "AUDITOR_BUCKETS_SUM_INSURER_ID_DESC",
  AUDITOR_BUCKETS_SUM_NAME_ASC = "AUDITOR_BUCKETS_SUM_NAME_ASC",
  AUDITOR_BUCKETS_SUM_NAME_DESC = "AUDITOR_BUCKETS_SUM_NAME_DESC",
  AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_SUM_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_SUM_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC = "AUDITOR_BUCKETS_SUM_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC = "AUDITOR_BUCKETS_SUM_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_DUE_DATE_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_FILTER_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_NAME_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ASSIGNEE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_DUE_DATE_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_FILTER_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_NAME_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_BUCKETS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_USERS_AVERAGE_CREATED_AT_ASC = "AUDITOR_USERS_AVERAGE_CREATED_AT_ASC",
  AUDITOR_USERS_AVERAGE_CREATED_AT_DESC = "AUDITOR_USERS_AVERAGE_CREATED_AT_DESC",
  AUDITOR_USERS_AVERAGE_EMAIL_ASC = "AUDITOR_USERS_AVERAGE_EMAIL_ASC",
  AUDITOR_USERS_AVERAGE_EMAIL_DESC = "AUDITOR_USERS_AVERAGE_EMAIL_DESC",
  AUDITOR_USERS_AVERAGE_FIRST_NAME_ASC = "AUDITOR_USERS_AVERAGE_FIRST_NAME_ASC",
  AUDITOR_USERS_AVERAGE_FIRST_NAME_DESC = "AUDITOR_USERS_AVERAGE_FIRST_NAME_DESC",
  AUDITOR_USERS_AVERAGE_ID_ASC = "AUDITOR_USERS_AVERAGE_ID_ASC",
  AUDITOR_USERS_AVERAGE_ID_DESC = "AUDITOR_USERS_AVERAGE_ID_DESC",
  AUDITOR_USERS_AVERAGE_INTERNAL_ONLY_ASC = "AUDITOR_USERS_AVERAGE_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_AVERAGE_INTERNAL_ONLY_DESC = "AUDITOR_USERS_AVERAGE_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_AVERAGE_LAST_NAME_ASC = "AUDITOR_USERS_AVERAGE_LAST_NAME_ASC",
  AUDITOR_USERS_AVERAGE_LAST_NAME_DESC = "AUDITOR_USERS_AVERAGE_LAST_NAME_DESC",
  AUDITOR_USERS_AVERAGE_PROVIDER_ID_ASC = "AUDITOR_USERS_AVERAGE_PROVIDER_ID_ASC",
  AUDITOR_USERS_AVERAGE_PROVIDER_ID_DESC = "AUDITOR_USERS_AVERAGE_PROVIDER_ID_DESC",
  AUDITOR_USERS_AVERAGE_READ_ONLY_ASC = "AUDITOR_USERS_AVERAGE_READ_ONLY_ASC",
  AUDITOR_USERS_AVERAGE_READ_ONLY_DESC = "AUDITOR_USERS_AVERAGE_READ_ONLY_DESC",
  AUDITOR_USERS_AVERAGE_TITLE_ASC = "AUDITOR_USERS_AVERAGE_TITLE_ASC",
  AUDITOR_USERS_AVERAGE_TITLE_DESC = "AUDITOR_USERS_AVERAGE_TITLE_DESC",
  AUDITOR_USERS_AVERAGE_UPDATED_AT_ASC = "AUDITOR_USERS_AVERAGE_UPDATED_AT_ASC",
  AUDITOR_USERS_AVERAGE_UPDATED_AT_DESC = "AUDITOR_USERS_AVERAGE_UPDATED_AT_DESC",
  AUDITOR_USERS_AVERAGE_USER_TYPE_ASC = "AUDITOR_USERS_AVERAGE_USER_TYPE_ASC",
  AUDITOR_USERS_AVERAGE_USER_TYPE_DESC = "AUDITOR_USERS_AVERAGE_USER_TYPE_DESC",
  AUDITOR_USERS_BY_PROVIDER_ID__COUNT_ASC = "AUDITOR_USERS_BY_PROVIDER_ID__COUNT_ASC",
  AUDITOR_USERS_BY_PROVIDER_ID__COUNT_DESC = "AUDITOR_USERS_BY_PROVIDER_ID__COUNT_DESC",
  AUDITOR_USERS_COUNT_ASC = "AUDITOR_USERS_COUNT_ASC",
  AUDITOR_USERS_COUNT_DESC = "AUDITOR_USERS_COUNT_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDITOR_USERS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDITOR_USERS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_EMAIL_ASC = "AUDITOR_USERS_DISTINCT_COUNT_EMAIL_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_EMAIL_DESC = "AUDITOR_USERS_DISTINCT_COUNT_EMAIL_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_FIRST_NAME_ASC = "AUDITOR_USERS_DISTINCT_COUNT_FIRST_NAME_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_FIRST_NAME_DESC = "AUDITOR_USERS_DISTINCT_COUNT_FIRST_NAME_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_ID_ASC = "AUDITOR_USERS_DISTINCT_COUNT_ID_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_ID_DESC = "AUDITOR_USERS_DISTINCT_COUNT_ID_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_INTERNAL_ONLY_ASC = "AUDITOR_USERS_DISTINCT_COUNT_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_INTERNAL_ONLY_DESC = "AUDITOR_USERS_DISTINCT_COUNT_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_LAST_NAME_ASC = "AUDITOR_USERS_DISTINCT_COUNT_LAST_NAME_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_LAST_NAME_DESC = "AUDITOR_USERS_DISTINCT_COUNT_LAST_NAME_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDITOR_USERS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDITOR_USERS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_READ_ONLY_ASC = "AUDITOR_USERS_DISTINCT_COUNT_READ_ONLY_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_READ_ONLY_DESC = "AUDITOR_USERS_DISTINCT_COUNT_READ_ONLY_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_TITLE_ASC = "AUDITOR_USERS_DISTINCT_COUNT_TITLE_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_TITLE_DESC = "AUDITOR_USERS_DISTINCT_COUNT_TITLE_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDITOR_USERS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDITOR_USERS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDITOR_USERS_DISTINCT_COUNT_USER_TYPE_ASC = "AUDITOR_USERS_DISTINCT_COUNT_USER_TYPE_ASC",
  AUDITOR_USERS_DISTINCT_COUNT_USER_TYPE_DESC = "AUDITOR_USERS_DISTINCT_COUNT_USER_TYPE_DESC",
  AUDITOR_USERS_MAX_CREATED_AT_ASC = "AUDITOR_USERS_MAX_CREATED_AT_ASC",
  AUDITOR_USERS_MAX_CREATED_AT_DESC = "AUDITOR_USERS_MAX_CREATED_AT_DESC",
  AUDITOR_USERS_MAX_EMAIL_ASC = "AUDITOR_USERS_MAX_EMAIL_ASC",
  AUDITOR_USERS_MAX_EMAIL_DESC = "AUDITOR_USERS_MAX_EMAIL_DESC",
  AUDITOR_USERS_MAX_FIRST_NAME_ASC = "AUDITOR_USERS_MAX_FIRST_NAME_ASC",
  AUDITOR_USERS_MAX_FIRST_NAME_DESC = "AUDITOR_USERS_MAX_FIRST_NAME_DESC",
  AUDITOR_USERS_MAX_ID_ASC = "AUDITOR_USERS_MAX_ID_ASC",
  AUDITOR_USERS_MAX_ID_DESC = "AUDITOR_USERS_MAX_ID_DESC",
  AUDITOR_USERS_MAX_INTERNAL_ONLY_ASC = "AUDITOR_USERS_MAX_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_MAX_INTERNAL_ONLY_DESC = "AUDITOR_USERS_MAX_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_MAX_LAST_NAME_ASC = "AUDITOR_USERS_MAX_LAST_NAME_ASC",
  AUDITOR_USERS_MAX_LAST_NAME_DESC = "AUDITOR_USERS_MAX_LAST_NAME_DESC",
  AUDITOR_USERS_MAX_PROVIDER_ID_ASC = "AUDITOR_USERS_MAX_PROVIDER_ID_ASC",
  AUDITOR_USERS_MAX_PROVIDER_ID_DESC = "AUDITOR_USERS_MAX_PROVIDER_ID_DESC",
  AUDITOR_USERS_MAX_READ_ONLY_ASC = "AUDITOR_USERS_MAX_READ_ONLY_ASC",
  AUDITOR_USERS_MAX_READ_ONLY_DESC = "AUDITOR_USERS_MAX_READ_ONLY_DESC",
  AUDITOR_USERS_MAX_TITLE_ASC = "AUDITOR_USERS_MAX_TITLE_ASC",
  AUDITOR_USERS_MAX_TITLE_DESC = "AUDITOR_USERS_MAX_TITLE_DESC",
  AUDITOR_USERS_MAX_UPDATED_AT_ASC = "AUDITOR_USERS_MAX_UPDATED_AT_ASC",
  AUDITOR_USERS_MAX_UPDATED_AT_DESC = "AUDITOR_USERS_MAX_UPDATED_AT_DESC",
  AUDITOR_USERS_MAX_USER_TYPE_ASC = "AUDITOR_USERS_MAX_USER_TYPE_ASC",
  AUDITOR_USERS_MAX_USER_TYPE_DESC = "AUDITOR_USERS_MAX_USER_TYPE_DESC",
  AUDITOR_USERS_MIN_CREATED_AT_ASC = "AUDITOR_USERS_MIN_CREATED_AT_ASC",
  AUDITOR_USERS_MIN_CREATED_AT_DESC = "AUDITOR_USERS_MIN_CREATED_AT_DESC",
  AUDITOR_USERS_MIN_EMAIL_ASC = "AUDITOR_USERS_MIN_EMAIL_ASC",
  AUDITOR_USERS_MIN_EMAIL_DESC = "AUDITOR_USERS_MIN_EMAIL_DESC",
  AUDITOR_USERS_MIN_FIRST_NAME_ASC = "AUDITOR_USERS_MIN_FIRST_NAME_ASC",
  AUDITOR_USERS_MIN_FIRST_NAME_DESC = "AUDITOR_USERS_MIN_FIRST_NAME_DESC",
  AUDITOR_USERS_MIN_ID_ASC = "AUDITOR_USERS_MIN_ID_ASC",
  AUDITOR_USERS_MIN_ID_DESC = "AUDITOR_USERS_MIN_ID_DESC",
  AUDITOR_USERS_MIN_INTERNAL_ONLY_ASC = "AUDITOR_USERS_MIN_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_MIN_INTERNAL_ONLY_DESC = "AUDITOR_USERS_MIN_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_MIN_LAST_NAME_ASC = "AUDITOR_USERS_MIN_LAST_NAME_ASC",
  AUDITOR_USERS_MIN_LAST_NAME_DESC = "AUDITOR_USERS_MIN_LAST_NAME_DESC",
  AUDITOR_USERS_MIN_PROVIDER_ID_ASC = "AUDITOR_USERS_MIN_PROVIDER_ID_ASC",
  AUDITOR_USERS_MIN_PROVIDER_ID_DESC = "AUDITOR_USERS_MIN_PROVIDER_ID_DESC",
  AUDITOR_USERS_MIN_READ_ONLY_ASC = "AUDITOR_USERS_MIN_READ_ONLY_ASC",
  AUDITOR_USERS_MIN_READ_ONLY_DESC = "AUDITOR_USERS_MIN_READ_ONLY_DESC",
  AUDITOR_USERS_MIN_TITLE_ASC = "AUDITOR_USERS_MIN_TITLE_ASC",
  AUDITOR_USERS_MIN_TITLE_DESC = "AUDITOR_USERS_MIN_TITLE_DESC",
  AUDITOR_USERS_MIN_UPDATED_AT_ASC = "AUDITOR_USERS_MIN_UPDATED_AT_ASC",
  AUDITOR_USERS_MIN_UPDATED_AT_DESC = "AUDITOR_USERS_MIN_UPDATED_AT_DESC",
  AUDITOR_USERS_MIN_USER_TYPE_ASC = "AUDITOR_USERS_MIN_USER_TYPE_ASC",
  AUDITOR_USERS_MIN_USER_TYPE_DESC = "AUDITOR_USERS_MIN_USER_TYPE_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDITOR_USERS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDITOR_USERS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_EMAIL_ASC = "AUDITOR_USERS_STDDEV_POPULATION_EMAIL_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_EMAIL_DESC = "AUDITOR_USERS_STDDEV_POPULATION_EMAIL_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_FIRST_NAME_ASC = "AUDITOR_USERS_STDDEV_POPULATION_FIRST_NAME_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_FIRST_NAME_DESC = "AUDITOR_USERS_STDDEV_POPULATION_FIRST_NAME_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_ID_ASC = "AUDITOR_USERS_STDDEV_POPULATION_ID_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_ID_DESC = "AUDITOR_USERS_STDDEV_POPULATION_ID_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_INTERNAL_ONLY_ASC = "AUDITOR_USERS_STDDEV_POPULATION_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_INTERNAL_ONLY_DESC = "AUDITOR_USERS_STDDEV_POPULATION_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_LAST_NAME_ASC = "AUDITOR_USERS_STDDEV_POPULATION_LAST_NAME_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_LAST_NAME_DESC = "AUDITOR_USERS_STDDEV_POPULATION_LAST_NAME_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDITOR_USERS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDITOR_USERS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_READ_ONLY_ASC = "AUDITOR_USERS_STDDEV_POPULATION_READ_ONLY_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_READ_ONLY_DESC = "AUDITOR_USERS_STDDEV_POPULATION_READ_ONLY_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_TITLE_ASC = "AUDITOR_USERS_STDDEV_POPULATION_TITLE_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_TITLE_DESC = "AUDITOR_USERS_STDDEV_POPULATION_TITLE_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDITOR_USERS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDITOR_USERS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDITOR_USERS_STDDEV_POPULATION_USER_TYPE_ASC = "AUDITOR_USERS_STDDEV_POPULATION_USER_TYPE_ASC",
  AUDITOR_USERS_STDDEV_POPULATION_USER_TYPE_DESC = "AUDITOR_USERS_STDDEV_POPULATION_USER_TYPE_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_EMAIL_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_EMAIL_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_EMAIL_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_EMAIL_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_FIRST_NAME_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_FIRST_NAME_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_FIRST_NAME_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_FIRST_NAME_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_ID_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_ID_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_ID_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_ID_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_INTERNAL_ONLY_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_INTERNAL_ONLY_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_LAST_NAME_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_LAST_NAME_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_LAST_NAME_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_LAST_NAME_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_READ_ONLY_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_READ_ONLY_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_READ_ONLY_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_READ_ONLY_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_TITLE_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_TITLE_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_TITLE_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_TITLE_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_USERS_STDDEV_SAMPLE_USER_TYPE_ASC = "AUDITOR_USERS_STDDEV_SAMPLE_USER_TYPE_ASC",
  AUDITOR_USERS_STDDEV_SAMPLE_USER_TYPE_DESC = "AUDITOR_USERS_STDDEV_SAMPLE_USER_TYPE_DESC",
  AUDITOR_USERS_SUM_CREATED_AT_ASC = "AUDITOR_USERS_SUM_CREATED_AT_ASC",
  AUDITOR_USERS_SUM_CREATED_AT_DESC = "AUDITOR_USERS_SUM_CREATED_AT_DESC",
  AUDITOR_USERS_SUM_EMAIL_ASC = "AUDITOR_USERS_SUM_EMAIL_ASC",
  AUDITOR_USERS_SUM_EMAIL_DESC = "AUDITOR_USERS_SUM_EMAIL_DESC",
  AUDITOR_USERS_SUM_FIRST_NAME_ASC = "AUDITOR_USERS_SUM_FIRST_NAME_ASC",
  AUDITOR_USERS_SUM_FIRST_NAME_DESC = "AUDITOR_USERS_SUM_FIRST_NAME_DESC",
  AUDITOR_USERS_SUM_ID_ASC = "AUDITOR_USERS_SUM_ID_ASC",
  AUDITOR_USERS_SUM_ID_DESC = "AUDITOR_USERS_SUM_ID_DESC",
  AUDITOR_USERS_SUM_INTERNAL_ONLY_ASC = "AUDITOR_USERS_SUM_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_SUM_INTERNAL_ONLY_DESC = "AUDITOR_USERS_SUM_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_SUM_LAST_NAME_ASC = "AUDITOR_USERS_SUM_LAST_NAME_ASC",
  AUDITOR_USERS_SUM_LAST_NAME_DESC = "AUDITOR_USERS_SUM_LAST_NAME_DESC",
  AUDITOR_USERS_SUM_PROVIDER_ID_ASC = "AUDITOR_USERS_SUM_PROVIDER_ID_ASC",
  AUDITOR_USERS_SUM_PROVIDER_ID_DESC = "AUDITOR_USERS_SUM_PROVIDER_ID_DESC",
  AUDITOR_USERS_SUM_READ_ONLY_ASC = "AUDITOR_USERS_SUM_READ_ONLY_ASC",
  AUDITOR_USERS_SUM_READ_ONLY_DESC = "AUDITOR_USERS_SUM_READ_ONLY_DESC",
  AUDITOR_USERS_SUM_TITLE_ASC = "AUDITOR_USERS_SUM_TITLE_ASC",
  AUDITOR_USERS_SUM_TITLE_DESC = "AUDITOR_USERS_SUM_TITLE_DESC",
  AUDITOR_USERS_SUM_UPDATED_AT_ASC = "AUDITOR_USERS_SUM_UPDATED_AT_ASC",
  AUDITOR_USERS_SUM_UPDATED_AT_DESC = "AUDITOR_USERS_SUM_UPDATED_AT_DESC",
  AUDITOR_USERS_SUM_USER_TYPE_ASC = "AUDITOR_USERS_SUM_USER_TYPE_ASC",
  AUDITOR_USERS_SUM_USER_TYPE_DESC = "AUDITOR_USERS_SUM_USER_TYPE_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_EMAIL_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_EMAIL_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_EMAIL_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_EMAIL_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_FIRST_NAME_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_FIRST_NAME_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_FIRST_NAME_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_FIRST_NAME_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_ID_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_ID_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_ID_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_ID_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_INTERNAL_ONLY_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_INTERNAL_ONLY_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_LAST_NAME_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_LAST_NAME_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_LAST_NAME_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_LAST_NAME_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_READ_ONLY_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_READ_ONLY_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_READ_ONLY_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_READ_ONLY_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_TITLE_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_TITLE_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_TITLE_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_TITLE_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDITOR_USERS_VARIANCE_POPULATION_USER_TYPE_ASC = "AUDITOR_USERS_VARIANCE_POPULATION_USER_TYPE_ASC",
  AUDITOR_USERS_VARIANCE_POPULATION_USER_TYPE_DESC = "AUDITOR_USERS_VARIANCE_POPULATION_USER_TYPE_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_EMAIL_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_EMAIL_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_EMAIL_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_EMAIL_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_FIRST_NAME_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_FIRST_NAME_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_FIRST_NAME_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_FIRST_NAME_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_ID_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_ID_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_ID_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_ID_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_INTERNAL_ONLY_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_INTERNAL_ONLY_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_INTERNAL_ONLY_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_INTERNAL_ONLY_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_LAST_NAME_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_LAST_NAME_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_LAST_NAME_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_LAST_NAME_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_READ_ONLY_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_READ_ONLY_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_READ_ONLY_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_READ_ONLY_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_TITLE_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_TITLE_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_TITLE_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_TITLE_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDITOR_USERS_VARIANCE_SAMPLE_USER_TYPE_ASC = "AUDITOR_USERS_VARIANCE_SAMPLE_USER_TYPE_ASC",
  AUDITOR_USERS_VARIANCE_SAMPLE_USER_TYPE_DESC = "AUDITOR_USERS_VARIANCE_SAMPLE_USER_TYPE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_AVERAGE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_BY_PROVIDER_ID__COUNT_ASC = "AUDIT_FINDING_REPORTS_BY_PROVIDER_ID__COUNT_ASC",
  AUDIT_FINDING_REPORTS_BY_PROVIDER_ID__COUNT_DESC = "AUDIT_FINDING_REPORTS_BY_PROVIDER_ID__COUNT_DESC",
  AUDIT_FINDING_REPORTS_COUNT_ASC = "AUDIT_FINDING_REPORTS_COUNT_ASC",
  AUDIT_FINDING_REPORTS_COUNT_DESC = "AUDIT_FINDING_REPORTS_COUNT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_TITLE_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_DISTINCT_COUNT_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_MAX_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_MAX_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MAX_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC = "AUDIT_FINDING_REPORTS_MAX_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC = "AUDIT_FINDING_REPORTS_MAX_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_MAX_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_MAX_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_MAX_TITLE_ASC = "AUDIT_FINDING_REPORTS_MAX_TITLE_ASC",
  AUDIT_FINDING_REPORTS_MAX_TITLE_DESC = "AUDIT_FINDING_REPORTS_MAX_TITLE_DESC",
  AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_MAX_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_MAX_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_MIN_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_MIN_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MIN_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC = "AUDIT_FINDING_REPORTS_MIN_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC = "AUDIT_FINDING_REPORTS_MIN_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_MIN_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_MIN_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_MIN_TITLE_ASC = "AUDIT_FINDING_REPORTS_MIN_TITLE_ASC",
  AUDIT_FINDING_REPORTS_MIN_TITLE_DESC = "AUDIT_FINDING_REPORTS_MIN_TITLE_DESC",
  AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_MIN_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_MIN_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_STDDEV_SAMPLE_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_SUM_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_SUM_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_SUM_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC = "AUDIT_FINDING_REPORTS_SUM_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC = "AUDIT_FINDING_REPORTS_SUM_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_SUM_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_SUM_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_SUM_TITLE_ASC = "AUDIT_FINDING_REPORTS_SUM_TITLE_ASC",
  AUDIT_FINDING_REPORTS_SUM_TITLE_DESC = "AUDIT_FINDING_REPORTS_SUM_TITLE_DESC",
  AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_SUM_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_SUM_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_TITLE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_POPULATION_VIEW_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_URL_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_S3_VERSION_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_TITLE_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_ASC",
  AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC = "AUDIT_FINDING_REPORTS_VARIANCE_SAMPLE_VIEW_ID_DESC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_ADM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_AVERAGE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC = "BATCH_CLAIMS_AVERAGE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_AVERAGE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_BILL_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_AVERAGE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_AVERAGE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_CREATED_AT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC = "BATCH_CLAIMS_AVERAGE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_AVERAGE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_AVERAGE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_AVERAGE_ICN_ASC = "BATCH_CLAIMS_AVERAGE_ICN_ASC",
  BATCH_CLAIMS_AVERAGE_ICN_DESC = "BATCH_CLAIMS_AVERAGE_ICN_DESC",
  BATCH_CLAIMS_AVERAGE_ID_ASC = "BATCH_CLAIMS_AVERAGE_ID_ASC",
  BATCH_CLAIMS_AVERAGE_ID_DESC = "BATCH_CLAIMS_AVERAGE_ID_DESC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC = "BATCH_CLAIMS_AVERAGE_INSURER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC = "BATCH_CLAIMS_AVERAGE_OWNER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC = "BATCH_CLAIMS_AVERAGE_PAID_DATE_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC = "BATCH_CLAIMS_AVERAGE_PATIENT_ID_DESC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_AVERAGE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_AVERAGE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC = "BATCH_CLAIMS_AVERAGE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_ASC",
  BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC = "BATCH_CLAIMS_AVERAGE_UPDATED_AT_DESC",
  BATCH_CLAIMS_BY_PROVIDER_ID__COUNT_ASC = "BATCH_CLAIMS_BY_PROVIDER_ID__COUNT_ASC",
  BATCH_CLAIMS_BY_PROVIDER_ID__COUNT_DESC = "BATCH_CLAIMS_BY_PROVIDER_ID__COUNT_DESC",
  BATCH_CLAIMS_COUNT_ASC = "BATCH_CLAIMS_COUNT_ASC",
  BATCH_CLAIMS_COUNT_DESC = "BATCH_CLAIMS_COUNT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ADM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_BILL_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_CREATED_AT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_ADMIT_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ICN_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_INSURER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_OWNER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAID_DATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_DOB_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PATIENT_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC = "BATCH_CLAIMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  BATCH_CLAIMS_MAX_ADM_TYPE_ASC = "BATCH_CLAIMS_MAX_ADM_TYPE_ASC",
  BATCH_CLAIMS_MAX_ADM_TYPE_DESC = "BATCH_CLAIMS_MAX_ADM_TYPE_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MAX_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_ASC = "BATCH_CLAIMS_MAX_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MAX_AUTHOR_ID_DESC = "BATCH_CLAIMS_MAX_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MAX_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MAX_BILL_TYPE_ASC = "BATCH_CLAIMS_MAX_BILL_TYPE_ASC",
  BATCH_CLAIMS_MAX_BILL_TYPE_DESC = "BATCH_CLAIMS_MAX_BILL_TYPE_DESC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MAX_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MAX_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MAX_CREATED_AT_ASC = "BATCH_CLAIMS_MAX_CREATED_AT_ASC",
  BATCH_CLAIMS_MAX_CREATED_AT_DESC = "BATCH_CLAIMS_MAX_CREATED_AT_DESC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_ASC = "BATCH_CLAIMS_MAX_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MAX_DATE_ADMIT_DESC = "BATCH_CLAIMS_MAX_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MAX_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MAX_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MAX_ICN_ASC = "BATCH_CLAIMS_MAX_ICN_ASC",
  BATCH_CLAIMS_MAX_ICN_DESC = "BATCH_CLAIMS_MAX_ICN_DESC",
  BATCH_CLAIMS_MAX_ID_ASC = "BATCH_CLAIMS_MAX_ID_ASC",
  BATCH_CLAIMS_MAX_ID_DESC = "BATCH_CLAIMS_MAX_ID_DESC",
  BATCH_CLAIMS_MAX_INSURER_ID_ASC = "BATCH_CLAIMS_MAX_INSURER_ID_ASC",
  BATCH_CLAIMS_MAX_INSURER_ID_DESC = "BATCH_CLAIMS_MAX_INSURER_ID_DESC",
  BATCH_CLAIMS_MAX_OWNER_ID_ASC = "BATCH_CLAIMS_MAX_OWNER_ID_ASC",
  BATCH_CLAIMS_MAX_OWNER_ID_DESC = "BATCH_CLAIMS_MAX_OWNER_ID_DESC",
  BATCH_CLAIMS_MAX_PAID_DATE_ASC = "BATCH_CLAIMS_MAX_PAID_DATE_ASC",
  BATCH_CLAIMS_MAX_PAID_DATE_DESC = "BATCH_CLAIMS_MAX_PAID_DATE_DESC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_ASC = "BATCH_CLAIMS_MAX_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MAX_PATIENT_DOB_DESC = "BATCH_CLAIMS_MAX_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MAX_PATIENT_ID_ASC = "BATCH_CLAIMS_MAX_PATIENT_ID_ASC",
  BATCH_CLAIMS_MAX_PATIENT_ID_DESC = "BATCH_CLAIMS_MAX_PATIENT_ID_DESC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MAX_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MAX_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_ASC = "BATCH_CLAIMS_MAX_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MAX_PROVIDER_ID_DESC = "BATCH_CLAIMS_MAX_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MAX_UPDATED_AT_ASC = "BATCH_CLAIMS_MAX_UPDATED_AT_ASC",
  BATCH_CLAIMS_MAX_UPDATED_AT_DESC = "BATCH_CLAIMS_MAX_UPDATED_AT_DESC",
  BATCH_CLAIMS_MIN_ADM_TYPE_ASC = "BATCH_CLAIMS_MIN_ADM_TYPE_ASC",
  BATCH_CLAIMS_MIN_ADM_TYPE_DESC = "BATCH_CLAIMS_MIN_ADM_TYPE_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_MIN_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_ASC = "BATCH_CLAIMS_MIN_AUTHOR_ID_ASC",
  BATCH_CLAIMS_MIN_AUTHOR_ID_DESC = "BATCH_CLAIMS_MIN_AUTHOR_ID_DESC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_MIN_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_MIN_BILL_TYPE_ASC = "BATCH_CLAIMS_MIN_BILL_TYPE_ASC",
  BATCH_CLAIMS_MIN_BILL_TYPE_DESC = "BATCH_CLAIMS_MIN_BILL_TYPE_DESC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC = "BATCH_CLAIMS_MIN_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_MIN_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_MIN_CREATED_AT_ASC = "BATCH_CLAIMS_MIN_CREATED_AT_ASC",
  BATCH_CLAIMS_MIN_CREATED_AT_DESC = "BATCH_CLAIMS_MIN_CREATED_AT_DESC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_ASC = "BATCH_CLAIMS_MIN_DATE_ADMIT_ASC",
  BATCH_CLAIMS_MIN_DATE_ADMIT_DESC = "BATCH_CLAIMS_MIN_DATE_ADMIT_DESC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_MIN_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_MIN_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_MIN_ICN_ASC = "BATCH_CLAIMS_MIN_ICN_ASC",
  BATCH_CLAIMS_MIN_ICN_DESC = "BATCH_CLAIMS_MIN_ICN_DESC",
  BATCH_CLAIMS_MIN_ID_ASC = "BATCH_CLAIMS_MIN_ID_ASC",
  BATCH_CLAIMS_MIN_ID_DESC = "BATCH_CLAIMS_MIN_ID_DESC",
  BATCH_CLAIMS_MIN_INSURER_ID_ASC = "BATCH_CLAIMS_MIN_INSURER_ID_ASC",
  BATCH_CLAIMS_MIN_INSURER_ID_DESC = "BATCH_CLAIMS_MIN_INSURER_ID_DESC",
  BATCH_CLAIMS_MIN_OWNER_ID_ASC = "BATCH_CLAIMS_MIN_OWNER_ID_ASC",
  BATCH_CLAIMS_MIN_OWNER_ID_DESC = "BATCH_CLAIMS_MIN_OWNER_ID_DESC",
  BATCH_CLAIMS_MIN_PAID_DATE_ASC = "BATCH_CLAIMS_MIN_PAID_DATE_ASC",
  BATCH_CLAIMS_MIN_PAID_DATE_DESC = "BATCH_CLAIMS_MIN_PAID_DATE_DESC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_ASC = "BATCH_CLAIMS_MIN_PATIENT_DOB_ASC",
  BATCH_CLAIMS_MIN_PATIENT_DOB_DESC = "BATCH_CLAIMS_MIN_PATIENT_DOB_DESC",
  BATCH_CLAIMS_MIN_PATIENT_ID_ASC = "BATCH_CLAIMS_MIN_PATIENT_ID_ASC",
  BATCH_CLAIMS_MIN_PATIENT_ID_DESC = "BATCH_CLAIMS_MIN_PATIENT_ID_DESC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC = "BATCH_CLAIMS_MIN_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_MIN_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_ASC = "BATCH_CLAIMS_MIN_PROVIDER_ID_ASC",
  BATCH_CLAIMS_MIN_PROVIDER_ID_DESC = "BATCH_CLAIMS_MIN_PROVIDER_ID_DESC",
  BATCH_CLAIMS_MIN_UPDATED_AT_ASC = "BATCH_CLAIMS_MIN_UPDATED_AT_ASC",
  BATCH_CLAIMS_MIN_UPDATED_AT_DESC = "BATCH_CLAIMS_MIN_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ICN_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  BATCH_CLAIMS_SUM_ADM_TYPE_ASC = "BATCH_CLAIMS_SUM_ADM_TYPE_ASC",
  BATCH_CLAIMS_SUM_ADM_TYPE_DESC = "BATCH_CLAIMS_SUM_ADM_TYPE_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_SUM_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_ASC = "BATCH_CLAIMS_SUM_AUTHOR_ID_ASC",
  BATCH_CLAIMS_SUM_AUTHOR_ID_DESC = "BATCH_CLAIMS_SUM_AUTHOR_ID_DESC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_SUM_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_SUM_BILL_TYPE_ASC = "BATCH_CLAIMS_SUM_BILL_TYPE_ASC",
  BATCH_CLAIMS_SUM_BILL_TYPE_DESC = "BATCH_CLAIMS_SUM_BILL_TYPE_DESC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC = "BATCH_CLAIMS_SUM_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_SUM_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_SUM_CREATED_AT_ASC = "BATCH_CLAIMS_SUM_CREATED_AT_ASC",
  BATCH_CLAIMS_SUM_CREATED_AT_DESC = "BATCH_CLAIMS_SUM_CREATED_AT_DESC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_ASC = "BATCH_CLAIMS_SUM_DATE_ADMIT_ASC",
  BATCH_CLAIMS_SUM_DATE_ADMIT_DESC = "BATCH_CLAIMS_SUM_DATE_ADMIT_DESC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_SUM_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_SUM_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_SUM_ICN_ASC = "BATCH_CLAIMS_SUM_ICN_ASC",
  BATCH_CLAIMS_SUM_ICN_DESC = "BATCH_CLAIMS_SUM_ICN_DESC",
  BATCH_CLAIMS_SUM_ID_ASC = "BATCH_CLAIMS_SUM_ID_ASC",
  BATCH_CLAIMS_SUM_ID_DESC = "BATCH_CLAIMS_SUM_ID_DESC",
  BATCH_CLAIMS_SUM_INSURER_ID_ASC = "BATCH_CLAIMS_SUM_INSURER_ID_ASC",
  BATCH_CLAIMS_SUM_INSURER_ID_DESC = "BATCH_CLAIMS_SUM_INSURER_ID_DESC",
  BATCH_CLAIMS_SUM_OWNER_ID_ASC = "BATCH_CLAIMS_SUM_OWNER_ID_ASC",
  BATCH_CLAIMS_SUM_OWNER_ID_DESC = "BATCH_CLAIMS_SUM_OWNER_ID_DESC",
  BATCH_CLAIMS_SUM_PAID_DATE_ASC = "BATCH_CLAIMS_SUM_PAID_DATE_ASC",
  BATCH_CLAIMS_SUM_PAID_DATE_DESC = "BATCH_CLAIMS_SUM_PAID_DATE_DESC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_ASC = "BATCH_CLAIMS_SUM_PATIENT_DOB_ASC",
  BATCH_CLAIMS_SUM_PATIENT_DOB_DESC = "BATCH_CLAIMS_SUM_PATIENT_DOB_DESC",
  BATCH_CLAIMS_SUM_PATIENT_ID_ASC = "BATCH_CLAIMS_SUM_PATIENT_ID_ASC",
  BATCH_CLAIMS_SUM_PATIENT_ID_DESC = "BATCH_CLAIMS_SUM_PATIENT_ID_DESC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC = "BATCH_CLAIMS_SUM_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_SUM_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_ASC = "BATCH_CLAIMS_SUM_PROVIDER_ID_ASC",
  BATCH_CLAIMS_SUM_PROVIDER_ID_DESC = "BATCH_CLAIMS_SUM_PROVIDER_ID_DESC",
  BATCH_CLAIMS_SUM_UPDATED_AT_ASC = "BATCH_CLAIMS_SUM_UPDATED_AT_ASC",
  BATCH_CLAIMS_SUM_UPDATED_AT_DESC = "BATCH_CLAIMS_SUM_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ADM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_CHARGED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BATCH_FILE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_BILL_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CLAIM_TYPE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_ADMIT_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_DISCHARGE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ICN_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_INSURER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_OWNER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAID_DATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_DOB_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PATIENT_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PAYMENT_RATE_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "BATCH_CLAIMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CITY_ASC = "CITY_ASC",
  CITY_DESC = "CITY_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_COUNT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_COUNT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_COUNT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_COUNT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REPLY_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_COUNT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MAX_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_MIN_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_SUM_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_AUDIT_FINDING_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_LINE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_DOCUMENT_UPLOAD_KIND_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_MESSAGE_ID_DESC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "CLAIM_INQUIRY_REQUEST_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC = "DISPUTED_FINDINGS_AVERAGE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC = "DISPUTED_FINDINGS_AVERAGE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_AVERAGE_DELETED_ASC = "DISPUTED_FINDINGS_AVERAGE_DELETED_ASC",
  DISPUTED_FINDINGS_AVERAGE_DELETED_DESC = "DISPUTED_FINDINGS_AVERAGE_DELETED_DESC",
  DISPUTED_FINDINGS_AVERAGE_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_AVERAGE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_AVERAGE_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_BY_PROVIDER_ID__COUNT_ASC = "DISPUTED_FINDINGS_BY_PROVIDER_ID__COUNT_ASC",
  DISPUTED_FINDINGS_BY_PROVIDER_ID__COUNT_DESC = "DISPUTED_FINDINGS_BY_PROVIDER_ID__COUNT_DESC",
  DISPUTED_FINDINGS_COUNT_ASC = "DISPUTED_FINDINGS_COUNT_ASC",
  DISPUTED_FINDINGS_COUNT_DESC = "DISPUTED_FINDINGS_COUNT_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_CREATED_AT_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_DELETED_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC = "DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC = "DISPUTED_FINDINGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_MAX_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_MAX_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_MAX_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_MAX_CREATED_AT_ASC = "DISPUTED_FINDINGS_MAX_CREATED_AT_ASC",
  DISPUTED_FINDINGS_MAX_CREATED_AT_DESC = "DISPUTED_FINDINGS_MAX_CREATED_AT_DESC",
  DISPUTED_FINDINGS_MAX_DELETED_ASC = "DISPUTED_FINDINGS_MAX_DELETED_ASC",
  DISPUTED_FINDINGS_MAX_DELETED_DESC = "DISPUTED_FINDINGS_MAX_DELETED_DESC",
  DISPUTED_FINDINGS_MAX_ID_ASC = "DISPUTED_FINDINGS_MAX_ID_ASC",
  DISPUTED_FINDINGS_MAX_ID_DESC = "DISPUTED_FINDINGS_MAX_ID_DESC",
  DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_MAX_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_MAX_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC = "DISPUTED_FINDINGS_MAX_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC = "DISPUTED_FINDINGS_MAX_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_MIN_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_MIN_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_MIN_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_MIN_CREATED_AT_ASC = "DISPUTED_FINDINGS_MIN_CREATED_AT_ASC",
  DISPUTED_FINDINGS_MIN_CREATED_AT_DESC = "DISPUTED_FINDINGS_MIN_CREATED_AT_DESC",
  DISPUTED_FINDINGS_MIN_DELETED_ASC = "DISPUTED_FINDINGS_MIN_DELETED_ASC",
  DISPUTED_FINDINGS_MIN_DELETED_DESC = "DISPUTED_FINDINGS_MIN_DELETED_DESC",
  DISPUTED_FINDINGS_MIN_ID_ASC = "DISPUTED_FINDINGS_MIN_ID_ASC",
  DISPUTED_FINDINGS_MIN_ID_DESC = "DISPUTED_FINDINGS_MIN_ID_DESC",
  DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_MIN_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_MIN_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC = "DISPUTED_FINDINGS_MIN_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC = "DISPUTED_FINDINGS_MIN_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_CREATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_DELETED_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_DELETED_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_SUM_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_SUM_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_SUM_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_SUM_CREATED_AT_ASC = "DISPUTED_FINDINGS_SUM_CREATED_AT_ASC",
  DISPUTED_FINDINGS_SUM_CREATED_AT_DESC = "DISPUTED_FINDINGS_SUM_CREATED_AT_DESC",
  DISPUTED_FINDINGS_SUM_DELETED_ASC = "DISPUTED_FINDINGS_SUM_DELETED_ASC",
  DISPUTED_FINDINGS_SUM_DELETED_DESC = "DISPUTED_FINDINGS_SUM_DELETED_DESC",
  DISPUTED_FINDINGS_SUM_ID_ASC = "DISPUTED_FINDINGS_SUM_ID_ASC",
  DISPUTED_FINDINGS_SUM_ID_DESC = "DISPUTED_FINDINGS_SUM_ID_DESC",
  DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_SUM_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_SUM_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC = "DISPUTED_FINDINGS_SUM_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC = "DISPUTED_FINDINGS_SUM_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_DELETED_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_DELETED_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "DISPUTED_FINDINGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_AVERAGE_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_AVERAGE_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_BY_PROVIDER_ID__COUNT_ASC = "DOCUMENTATION_SELECTION_SETS_BY_PROVIDER_ID__COUNT_ASC",
  DOCUMENTATION_SELECTION_SETS_BY_PROVIDER_ID__COUNT_DESC = "DOCUMENTATION_SELECTION_SETS_BY_PROVIDER_ID__COUNT_DESC",
  DOCUMENTATION_SELECTION_SETS_COUNT_ASC = "DOCUMENTATION_SELECTION_SETS_COUNT_ASC",
  DOCUMENTATION_SELECTION_SETS_COUNT_DESC = "DOCUMENTATION_SELECTION_SETS_COUNT_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_DISTINCT_COUNT_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_MAX_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_MAX_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_MAX_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_MAX_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_MIN_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_MIN_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_MIN_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_MIN_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_POPULATION_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_STDDEV_SAMPLE_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_SUM_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_SUM_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_SUM_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_SUM_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_POPULATION_SELECTION_SET_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_ETAG_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_ETAG_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_ETAG_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_ETAG_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_FILENAME_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_FILENAME_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_FILENAME_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_FILENAME_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_S3_KEY_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_S3_KEY_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_S3_KEY_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_S3_KEY_DESC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_SELECTION_SET_ASC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_SELECTION_SET_ASC",
  DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_SELECTION_SET_DESC = "DOCUMENTATION_SELECTION_SETS_VARIANCE_SAMPLE_SELECTION_SET_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_AVERAGE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_BY_PROVIDER_ID__COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_BY_PROVIDER_ID__COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_BY_PROVIDER_ID__COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_BY_PROVIDER_ID__COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC = "DOCUMENT_UPLOAD_CREATEDS_COUNT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC = "DOCUMENT_UPLOAD_CREATEDS_COUNT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_DISTINCT_COUNT_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MAX_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_MIN_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_SUM_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_BATCH_CLAIM_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CLAIM_INQUIRY_REQUEST_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_DEPRECATED_MESSAGE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_FILE_LOCATION_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_ID_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_KIND_DESC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "DOCUMENT_UPLOAD_CREATEDS_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  NPI_ASC = "NPI_ASC",
  NPI_DESC = "NPI_DESC",
  PRACTICE_TYPE_ASC = "PRACTICE_TYPE_ASC",
  PRACTICE_TYPE_DESC = "PRACTICE_TYPE_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_AVERAGE_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_BY_PROVIDER_ID__COUNT_ASC = "PROVIDER_USER_FACILITIES_BY_PROVIDER_ID__COUNT_ASC",
  PROVIDER_USER_FACILITIES_BY_PROVIDER_ID__COUNT_DESC = "PROVIDER_USER_FACILITIES_BY_PROVIDER_ID__COUNT_DESC",
  PROVIDER_USER_FACILITIES_COUNT_ASC = "PROVIDER_USER_FACILITIES_COUNT_ASC",
  PROVIDER_USER_FACILITIES_COUNT_DESC = "PROVIDER_USER_FACILITIES_COUNT_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_DISTINCT_COUNT_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_MAX_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MAX_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_MAX_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MAX_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_MAX_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_MAX_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_MIN_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MIN_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_MIN_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_MIN_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_MIN_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_MIN_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_POPULATION_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_SUM_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_SUM_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_SUM_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_SUM_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_SUM_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_SUM_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_AUDITOR_USER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_PROVIDER_ID_DESC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "PROVIDER_USER_FACILITIES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  SPEC_ASC = "SPEC_ASC",
  SPEC_DESC = "SPEC_DESC",
  STATE_ASC = "STATE_ASC",
  STATE_DESC = "STATE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: "Query";
  /** Reads and enables pagination through a set of `ActiveIbLinesView`. */
  activeIbLinesViews?: Maybe<ActiveIbLinesViewsConnection>;
  activeStatistics: ActiveStatistics;
  askAutodorFile?: Maybe<AskAutodorFileResponse>;
  askAutodorFileVectorsExist?: Maybe<AskAutodorFileVectorsExistResponse>;
  askAutodorGetSavedPrompts: Array<AskAutodorSavedPrompt>;
  askAutodorQueue?: Maybe<AskAutodorQueue>;
  askAutodorQueues: AskAutodorQueuesConnection;
  askAutodorQueuesConnection: AskAutodorQueuesConnection;
  auditFinding?: Maybe<AuditFinding>;
  /** Reads a single `AuditFinding` using its globally unique `ID`. */
  auditFindingByNodeId?: Maybe<AuditFinding>;
  auditFindingJob?: Maybe<AuditFindingJob>;
  /** Reads a single `AuditFindingJob` using its globally unique `ID`. */
  auditFindingJobByNodeId?: Maybe<AuditFindingJob>;
  /** Reads and enables pagination through a set of `AuditFindingJob`. */
  auditFindingJobs?: Maybe<AuditFindingJobsConnection>;
  auditFindingReasonCode?: Maybe<AuditFindingReasonCode>;
  /** Reads a single `AuditFindingReasonCode` using its globally unique `ID`. */
  auditFindingReasonCodeByNodeId?: Maybe<AuditFindingReasonCode>;
  /** Reads and enables pagination through a set of `AuditFindingReasonCode`. */
  auditFindingReasonCodes?: Maybe<AuditFindingReasonCodesConnection>;
  auditFindingReport?: Maybe<AuditFindingReport>;
  /** Reads a single `AuditFindingReport` using its globally unique `ID`. */
  auditFindingReportByNodeId?: Maybe<AuditFindingReport>;
  /** Reads and enables pagination through a set of `AuditFindingReport`. */
  auditFindingReports?: Maybe<AuditFindingReportsConnection>;
  auditFindingReview?: Maybe<AuditFindingReview>;
  /** Reads a single `AuditFindingReview` using its globally unique `ID`. */
  auditFindingReviewByNodeId?: Maybe<AuditFindingReview>;
  auditFindingReviewByReviewId?: Maybe<AuditFindingReview>;
  auditFindingReviewByReviewIdAndOriginalId?: Maybe<AuditFindingReview>;
  /** Reads and enables pagination through a set of `AuditFindingReview`. */
  auditFindingReviews?: Maybe<AuditFindingReviewsConnection>;
  auditFindingRuleType?: Maybe<AuditFindingRuleType>;
  /** Reads a single `AuditFindingRuleType` using its globally unique `ID`. */
  auditFindingRuleTypeByNodeId?: Maybe<AuditFindingRuleType>;
  /** Reads and enables pagination through a set of `AuditFindingRuleType`. */
  auditFindingRuleTypes?: Maybe<AuditFindingRuleTypesConnection>;
  /** Reads and enables pagination through a set of `AuditFinding`. */
  auditFindings?: Maybe<AuditFindingsConnection>;
  auditorBucket?: Maybe<AuditorBucket>;
  auditorBucketAssignee?: Maybe<AuditorBucketAssignee>;
  /** Reads a single `AuditorBucketAssignee` using its globally unique `ID`. */
  auditorBucketAssigneeByNodeId?: Maybe<AuditorBucketAssignee>;
  /** Reads and enables pagination through a set of `AuditorBucketAssignee`. */
  auditorBucketAssignees?: Maybe<AuditorBucketAssigneesConnection>;
  /** Reads a single `AuditorBucket` using its globally unique `ID`. */
  auditorBucketByNodeId?: Maybe<AuditorBucket>;
  /** Reads and enables pagination through a set of `AuditorBucket`. */
  auditorBuckets?: Maybe<AuditorBucketsConnection>;
  auditorUser?: Maybe<AuditorUser>;
  auditorUserByEmail?: Maybe<AuditorUser>;
  /** Reads a single `AuditorUser` using its globally unique `ID`. */
  auditorUserByNodeId?: Maybe<AuditorUser>;
  /** Reads and enables pagination through a set of `AuditorUser`. */
  auditorUsers?: Maybe<AuditorUsersConnection>;
  autodor?: Maybe<Autodor>;
  batchClaim?: Maybe<BatchClaim>;
  batchClaimAssignee?: Maybe<BatchClaimAssignee>;
  /** Reads a single `BatchClaimAssignee` using its globally unique `ID`. */
  batchClaimAssigneeByNodeId?: Maybe<BatchClaimAssignee>;
  /** Reads and enables pagination through a set of `BatchClaimAssignee`. */
  batchClaimAssignees?: Maybe<BatchClaimAssigneesConnection>;
  batchClaimByIcn?: Maybe<BatchClaim>;
  /** Reads a single `BatchClaim` using its globally unique `ID`. */
  batchClaimByNodeId?: Maybe<BatchClaim>;
  batchClaimDueDate?: Maybe<BatchClaimDueDate>;
  /** Reads a single `BatchClaimDueDate` using its globally unique `ID`. */
  batchClaimDueDateByNodeId?: Maybe<BatchClaimDueDate>;
  /** Reads and enables pagination through a set of `BatchClaimDueDate`. */
  batchClaimDueDates?: Maybe<BatchClaimDueDatesConnection>;
  batchClaimLine?: Maybe<BatchClaimLine>;
  /** Reads a single `BatchClaimLine` using its globally unique `ID`. */
  batchClaimLineByNodeId?: Maybe<BatchClaimLine>;
  batchClaimLineComputedValue?: Maybe<BatchClaimLineComputedValue>;
  /** Reads a single `BatchClaimLineComputedValue` using its globally unique `ID`. */
  batchClaimLineComputedValueByNodeId?: Maybe<BatchClaimLineComputedValue>;
  /** Reads and enables pagination through a set of `BatchClaimLineComputedValue`. */
  batchClaimLineComputedValues?: Maybe<BatchClaimLineComputedValuesConnection>;
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  batchClaimLineItemAudit?: Maybe<BatchClaimLineItemAudit>;
  batchClaimLineItemAuditByAuditFindingId?: Maybe<BatchClaimLineItemAudit>;
  /** Reads a single `BatchClaimLineItemAudit` using its globally unique `ID`. */
  batchClaimLineItemAuditByNodeId?: Maybe<BatchClaimLineItemAudit>;
  /** Reads and enables pagination through a set of `BatchClaimLineItemAudit`. */
  batchClaimLineItemAudits?: Maybe<BatchClaimLineItemAuditsConnection>;
  /** Reads a single `BatchClaimLineItem` using its globally unique `ID`. */
  batchClaimLineItemByNodeId?: Maybe<BatchClaimLineItem>;
  batchClaimLineItemComputedValue?: Maybe<BatchClaimLineItemComputedValue>;
  /** Reads a single `BatchClaimLineItemComputedValue` using its globally unique `ID`. */
  batchClaimLineItemComputedValueByNodeId?: Maybe<BatchClaimLineItemComputedValue>;
  /** Reads and enables pagination through a set of `BatchClaimLineItemComputedValue`. */
  batchClaimLineItemComputedValues?: Maybe<BatchClaimLineItemComputedValuesConnection>;
  batchClaimLineItemNnIbLine?: Maybe<BatchClaimLineItemNnIbLine>;
  batchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineId?: Maybe<BatchClaimLineItemNnIbLine>;
  /** Reads a single `BatchClaimLineItemNnIbLine` using its globally unique `ID`. */
  batchClaimLineItemNnIbLineByNodeId?: Maybe<BatchClaimLineItemNnIbLine>;
  /** Reads and enables pagination through a set of `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItemNnIbLines?: Maybe<BatchClaimLineItemNnIbLinesConnection>;
  /** Reads and enables pagination through a set of `BatchClaimLineItem`. */
  batchClaimLineItems?: Maybe<BatchClaimLineItemsConnection>;
  /** Reads and enables pagination through a set of `BatchClaimLine`. */
  batchClaimLines?: Maybe<BatchClaimLinesConnection>;
  batchClaimState?: Maybe<BatchClaimState>;
  /** Reads a single `BatchClaimState` using its globally unique `ID`. */
  batchClaimStateByNodeId?: Maybe<BatchClaimState>;
  /** Reads and enables pagination through a set of `BatchClaimState`. */
  batchClaimStates?: Maybe<BatchClaimStatesConnection>;
  /**
   * ### Overview
   * A performance booster function on the batch_claim_state table that minimizes the number of rows scanned down to only ones where workflow_claim_state != CLAIM_RECEIVED
   */
  batchClaimStatesWithActiveStates?: Maybe<BatchClaimStatesConnection>;
  /** Reads and enables pagination through a set of `BatchClaim`. */
  batchClaims?: Maybe<BatchClaimsConnection>;
  /**
   * ### Overview
   * Returns the claims assigned to the active auditor user as identified by the uuid in the active application user jwt token.
   * The cache schema is queried to identify the active claim assignees.
   */
  batchClaimsAssignedToAuditor?: Maybe<BatchClaimsConnection>;
  batchFile?: Maybe<BatchFile>;
  /** Reads a single `BatchFile` using its globally unique `ID`. */
  batchFileByNodeId?: Maybe<BatchFile>;
  /** Reads and enables pagination through a set of `BatchFile`. */
  batchFiles?: Maybe<BatchFilesConnection>;
  bucketClaim?: Maybe<BucketClaim>;
  bucketClaimByBatchClaimId?: Maybe<BucketClaim>;
  bucketClaimByBatchClaimIdAndAuditorBucketId?: Maybe<BucketClaim>;
  /** Reads a single `BucketClaim` using its globally unique `ID`. */
  bucketClaimByNodeId?: Maybe<BucketClaim>;
  /** Reads and enables pagination through a set of `BucketClaim`. */
  bucketClaims?: Maybe<BucketClaimsConnection>;
  case: Case;
  cases: Array<Case>;
  claim?: Maybe<Claim>;
  claimCases: Array<Case>;
  claimIntake: ClaimIntakeResponse;
  clientSignOffClaimState?: Maybe<ClientSignOffClaimState>;
  /** Reads a single `ClientSignOffClaimState` using its globally unique `ID`. */
  clientSignOffClaimStateByNodeId?: Maybe<ClientSignOffClaimState>;
  /** Reads and enables pagination through a set of `ClientSignOffClaimState`. */
  clientSignOffClaimStates?: Maybe<ClientSignOffClaimStatesConnection>;
  defaultStrategyByInsurer?: Maybe<DefaultStrategyByInsurer>;
  defaultStrategyByInsurerByInsurerId?: Maybe<DefaultStrategyByInsurer>;
  /** Reads a single `DefaultStrategyByInsurer` using its globally unique `ID`. */
  defaultStrategyByInsurerByNodeId?: Maybe<DefaultStrategyByInsurer>;
  /** Reads and enables pagination through a set of `DefaultStrategyByInsurer`. */
  defaultStrategyByInsurers?: Maybe<DefaultStrategyByInsurersConnection>;
  directory?: Maybe<Directory>;
  disputedFinding?: Maybe<DisputedFinding>;
  /** Reads a single `DisputedFinding` using its globally unique `ID`. */
  disputedFindingByNodeId?: Maybe<DisputedFinding>;
  /** Reads and enables pagination through a set of `DisputedFinding`. */
  disputedFindings?: Maybe<DisputedFindingsConnection>;
  documentRequestCreated?: Maybe<DocumentRequestCreated>;
  /** Reads a single `DocumentRequestCreated` using its globally unique `ID`. */
  documentRequestCreatedByNodeId?: Maybe<DocumentRequestCreated>;
  /** Reads and enables pagination through a set of `DocumentRequestCreated`. */
  documentRequestCreateds?: Maybe<DocumentRequestCreatedsConnection>;
  documentUploadCreated?: Maybe<DocumentUploadCreated>;
  /** Reads a single `DocumentUploadCreated` using its globally unique `ID`. */
  documentUploadCreatedByNodeId?: Maybe<DocumentUploadCreated>;
  /** Reads and enables pagination through a set of `DocumentUploadCreated`. */
  documentUploadCreateds?: Maybe<DocumentUploadCreatedsConnection>;
  documentationSelectionSet?: Maybe<DocumentationSelectionSet>;
  /** Reads a single `DocumentationSelectionSet` using its globally unique `ID`. */
  documentationSelectionSetByNodeId?: Maybe<DocumentationSelectionSet>;
  documentationSelectionSetByS3Key?: Maybe<DocumentationSelectionSet>;
  /** Reads and enables pagination through a set of `DocumentationSelectionSet`. */
  documentationSelectionSets?: Maybe<DocumentationSelectionSetsConnection>;
  file?: Maybe<File>;
  fileDownloadUrl: SignedDownloadUrl;
  files: Array<File>;
  finding?: Maybe<Finding>;
  foxitCredentials: FoxitCredentials;
  /**
   * ### Overview
   * DEPRECATED: This function was previously used to calculate the view id for an audit finding report.
   * The view id is a uuid that represents a family of reports over a group of claims.
   * This calculation is now done natively in the report service, so this function is no longer used.
   */
  generateAuditFindingReportViewId?: Maybe<Scalars["UUID"]["output"]>;
  /**
   * ### Overview
   *  Generates fingerprint of values used by generated column batch_claim_line_item.fingerprint -and- app logic to determine if new version is needed
   */
  generateBcliVersionFingerprint?: Maybe<Scalars["UUID"]["output"]>;
  historicStatistics: HistoricStatistics;
  /**
   * ### Overview
   * Calculates total time spent performing human processing review on a document for a given review, specified by id.
   * This aids in metrics and observability over our document review pipelines that our annotators use.
   */
  hprCalculateTimeSpentSecs?: Maybe<Scalars["BigFloat"]["output"]>;
  humanProcessingRequest?: Maybe<HumanProcessingRequest>;
  /** Reads a single `HumanProcessingRequest` using its globally unique `ID`. */
  humanProcessingRequestByNodeId?: Maybe<HumanProcessingRequest>;
  humanProcessingRequestByRunIdAndS3AuditDocumentationIdAndRequestType?: Maybe<HumanProcessingRequest>;
  /** Reads and enables pagination through a set of `HumanProcessingRequest`. */
  humanProcessingRequests?: Maybe<HumanProcessingRequestsConnection>;
  insurer?: Maybe<Insurer>;
  insurerByClientAbbreviation?: Maybe<Insurer>;
  insurerByName?: Maybe<Insurer>;
  /** Reads a single `Insurer` using its globally unique `ID`. */
  insurerByNodeId?: Maybe<Insurer>;
  insurerClaimIntakeDropboxPermittedByInsurerId?: Maybe<InsurerClaimIntakeDropboxPermitted>;
  /** Reads and enables pagination through a set of `InsurerClaimIntakeDropboxPermitted`. */
  insurerClaimIntakeDropboxPermitteds?: Maybe<InsurerClaimIntakeDropboxPermittedsConnection>;
  insurerOrganization?: Maybe<InsurerOrganization>;
  insurerOrganizationByMemberIdAndOrganizationId?: Maybe<InsurerOrganization>;
  /** Reads a single `InsurerOrganization` using its globally unique `ID`. */
  insurerOrganizationByNodeId?: Maybe<InsurerOrganization>;
  /** Reads and enables pagination through a set of `InsurerOrganization`. */
  insurerOrganizations?: Maybe<InsurerOrganizationsConnection>;
  insurerUser?: Maybe<InsurerUser>;
  insurerUserByAuditorUserIdAndInsurerId?: Maybe<InsurerUser>;
  /** Reads a single `InsurerUser` using its globally unique `ID`. */
  insurerUserByNodeId?: Maybe<InsurerUser>;
  /** Reads and enables pagination through a set of `InsurerUser`. */
  insurerUsers?: Maybe<InsurerUsersConnection>;
  /** Reads and enables pagination through a set of `Insurer`. */
  insurers?: Maybe<InsurersConnection>;
  /**
   * ### Overview
   * Identifies the set of insurers where batch claims for those insurers are assigned to the current auditor user.
   * The auditor user is identified using the uuid in the jwt of the active user, so this is meant to be called by a logged in user within the application.
   */
  insurersAssignedToAuditor?: Maybe<InsurersConnection>;
  invoiceClaimState?: Maybe<InvoiceClaimState>;
  /** Reads a single `InvoiceClaimState` using its globally unique `ID`. */
  invoiceClaimStateByNodeId?: Maybe<InvoiceClaimState>;
  /** Reads and enables pagination through a set of `InvoiceClaimState`. */
  invoiceClaimStates?: Maybe<InvoiceClaimStatesConnection>;
  itemizedBillLines?: Maybe<Array<ItemizedBillLine>>;
  /**
   * ### Overview
   * Retrieves the most recent auditor user created by Autodor.
   */
  latestAutodorVersionUserId?: Maybe<Scalars["UUID"]["output"]>;
  messageCreated?: Maybe<MessageCreated>;
  /** Reads a single `MessageCreated` using its globally unique `ID`. */
  messageCreatedByNodeId?: Maybe<MessageCreated>;
  /** Reads and enables pagination through a set of `MessageCreated`. */
  messageCreateds?: Maybe<MessageCreatedsConnection>;
  messageSent?: Maybe<MessageSent>;
  /** Reads a single `MessageSent` using its globally unique `ID`. */
  messageSentByNodeId?: Maybe<MessageSent>;
  /** Reads and enables pagination through a set of `MessageSent`. */
  messageSents?: Maybe<MessageSentsConnection>;
  negotiationClaimState?: Maybe<NegotiationClaimState>;
  /** Reads a single `NegotiationClaimState` using its globally unique `ID`. */
  negotiationClaimStateByNodeId?: Maybe<NegotiationClaimState>;
  /** Reads and enables pagination through a set of `NegotiationClaimState`. */
  negotiationClaimStates?: Maybe<NegotiationClaimStatesConnection>;
  nnIbLine?: Maybe<NnIbLine>;
  nnIbLineByFingerprintAndS3Key?: Maybe<NnIbLine>;
  /** Reads a single `NnIbLine` using its globally unique `ID`. */
  nnIbLineByNodeId?: Maybe<NnIbLine>;
  /** Reads and enables pagination through a set of `NnIbLine`. */
  nnIbLines?: Maybe<NnIbLinesConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars["ID"]["output"];
  onHoldClaimState?: Maybe<OnHoldClaimState>;
  /** Reads a single `OnHoldClaimState` using its globally unique `ID`. */
  onHoldClaimStateByNodeId?: Maybe<OnHoldClaimState>;
  /** Reads and enables pagination through a set of `OnHoldClaimState`. */
  onHoldClaimStates?: Maybe<OnHoldClaimStatesConnection>;
  procedureCode?: Maybe<ProcedureCode>;
  /** Reads a single `ProcedureCode` using its globally unique `ID`. */
  procedureCodeByNodeId?: Maybe<ProcedureCode>;
  procedureCodeTypeByType?: Maybe<ProcedureCodeType>;
  /** Reads and enables pagination through a set of `ProcedureCodeType`. */
  procedureCodeTypes?: Maybe<ProcedureCodeTypesConnection>;
  /** Reads and enables pagination through a set of `ProcedureCode`. */
  procedureCodes?: Maybe<ProcedureCodesConnection>;
  provider?: Maybe<Provider>;
  providerAuditStats: Array<ProviderAuditStat>;
  /** Reads a single `Provider` using its globally unique `ID`. */
  providerByNodeId?: Maybe<Provider>;
  providerSignOffClaimState?: Maybe<ProviderSignOffClaimState>;
  /** Reads a single `ProviderSignOffClaimState` using its globally unique `ID`. */
  providerSignOffClaimStateByNodeId?: Maybe<ProviderSignOffClaimState>;
  /** Reads and enables pagination through a set of `ProviderSignOffClaimState`. */
  providerSignOffClaimStates?: Maybe<ProviderSignOffClaimStatesConnection>;
  /** Reads and enables pagination through a set of `ProviderUserFacility`. */
  providerUserFacilities?: Maybe<ProviderUserFacilitiesConnection>;
  providerUserFacility?: Maybe<ProviderUserFacility>;
  /** Reads a single `ProviderUserFacility` using its globally unique `ID`. */
  providerUserFacilityByNodeId?: Maybe<ProviderUserFacility>;
  providerUserFacilityByProviderIdAndAuditorUserId?: Maybe<ProviderUserFacility>;
  /** Reads and enables pagination through a set of `Provider`. */
  providers?: Maybe<ProvidersConnection>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  queue?: Maybe<Queue>;
  /** Reads a single `Queue` using its globally unique `ID`. */
  queueByNodeId?: Maybe<Queue>;
  /** Reads and enables pagination through a set of `Queue`. */
  queues?: Maybe<QueuesConnection>;
  reasonCode?: Maybe<ReasonCode>;
  /** Reads a single `ReasonCode` using its globally unique `ID`. */
  reasonCodeByNodeId?: Maybe<ReasonCode>;
  /** Reads and enables pagination through a set of `ReasonCode`. */
  reasonCodes?: Maybe<ReasonCodesConnection>;
  reportedClaim?: Maybe<ReportedClaim>;
  /** Reads a single `ReportedClaim` using its globally unique `ID`. */
  reportedClaimByNodeId?: Maybe<ReportedClaim>;
  /** Reads and enables pagination through a set of `ReportedClaim`. */
  reportedClaims?: Maybe<ReportedClaimsConnection>;
  reportedFinding?: Maybe<ReportedFinding>;
  /** Reads a single `ReportedFinding` using its globally unique `ID`. */
  reportedFindingByNodeId?: Maybe<ReportedFinding>;
  /** Reads and enables pagination through a set of `ReportedFinding`. */
  reportedFindings?: Maybe<ReportedFindingsConnection>;
  reviewedAuditFinding?: Maybe<ReviewedAuditFinding>;
  /** Reads a single `ReviewedAuditFinding` using its globally unique `ID`. */
  reviewedAuditFindingByNodeId?: Maybe<ReviewedAuditFinding>;
  /** Reads and enables pagination through a set of `ReviewedAuditFinding`. */
  reviewedAuditFindings?: Maybe<ReviewedAuditFindingsConnection>;
  s3AuditDocumentation?: Maybe<S3AuditDocumentation>;
  /** Reads a single `S3AuditDocumentation` using its globally unique `ID`. */
  s3AuditDocumentationByNodeId?: Maybe<S3AuditDocumentation>;
  /** Reads and enables pagination through a set of `S3AuditDocumentation`. */
  s3AuditDocumentations?: Maybe<S3AuditDocumentationsConnection>;
  searchInFile: Array<SearchResult>;
  threads: Array<Thread>;
  /**
   * ### Overview
   * Retrieves the unique set of health care providers by their unique id called an NPI.
   */
  uniqueNpiProviders?: Maybe<NpiProvidersConnection>;
  /**
   * ### Overview
   * Select the distinct procedure codes at the batch claim line level.
   * Effectively, this is a list of codes that are more detailed about medical procedures done for services present on the unified bill (UB).
   */
  uniqueProcedureCodes?: Maybe<UniqueProcedureCodesConnection>;
  /**
   * ### Overview
   * Select the distinct revenue codes present at the batch claim line level.
   * Effectively, this is a list of services present on a unified bill (UB).
   */
  uniqueRevCodes?: Maybe<UniqueRevCodesConnection>;
  user?: Maybe<User>;
  userInviteCreated?: Maybe<UserInviteCreated>;
  /** Reads a single `UserInviteCreated` using its globally unique `ID`. */
  userInviteCreatedByNodeId?: Maybe<UserInviteCreated>;
  /** Reads and enables pagination through a set of `UserInviteCreated`. */
  userInviteCreateds?: Maybe<UserInviteCreatedsConnection>;
  /**
   * ### Overview
   * Check if a provider user is assigned to a given facility by querying the mapping table `v3.provider_user_facilities`.
   * *Note*: provider users can only interact with claims data for facilities they are associated to
   */
  userIsAssignedToProviderFacility?: Maybe<Scalars["Boolean"]["output"]>;
  workflowClaimState?: Maybe<WorkflowClaimState>;
  /** Reads a single `WorkflowClaimState` using its globally unique `ID`. */
  workflowClaimStateByNodeId?: Maybe<WorkflowClaimState>;
  /** Reads and enables pagination through a set of `WorkflowClaimState`. */
  workflowClaimStates?: Maybe<WorkflowClaimStatesConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryactiveIbLinesViewsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ActiveIbLinesViewCondition>;
  filter?: InputMaybe<ActiveIbLinesViewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ActiveIbLinesViewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorFileArgs = {
  input?: InputMaybe<AskAutodorFileInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorFileVectorsExistArgs = {
  input?: InputMaybe<AskAutodorFileVectorsExistInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorGetSavedPromptsArgs = {
  input?: InputMaybe<AskAutodorGetSavedPromptsInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorQueueArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorQueuesArgs = {
  args?: InputMaybe<AskAutodorQueuesArgs>;
};

/** The root query type which gives access points into the data universe. */
export type QueryaskAutodorQueuesConnectionArgs = {
  args?: InputMaybe<AskAutodorQueuesArgs>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingJobArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingJobByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingJobCondition>;
  filter?: InputMaybe<AuditFindingJobFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingJobsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReasonCodeArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReasonCodeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReasonCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReasonCodeCondition>;
  filter?: InputMaybe<AuditFindingReasonCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReportArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReportByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReportsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReportCondition>;
  filter?: InputMaybe<AuditFindingReportFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReportsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReviewArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReviewByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReviewByReviewIdArgs = {
  reviewId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReviewByReviewIdAndOriginalIdArgs = {
  originalId: Scalars["UUID"]["input"];
  reviewId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingReviewsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReviewCondition>;
  filter?: InputMaybe<AuditFindingReviewFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReviewsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingRuleTypeArgs = {
  type: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingRuleTypeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingRuleTypesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingRuleTypeCondition>;
  filter?: InputMaybe<AuditFindingRuleTypeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingRuleTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingCondition>;
  filter?: InputMaybe<AuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketAssigneeArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketAssigneeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketAssigneeCondition>;
  filter?: InputMaybe<AuditorBucketAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketAssigneesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorBucketsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorBucketCondition>;
  filter?: InputMaybe<AuditorBucketFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorUserArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorUserByEmailArgs = {
  email: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorUserByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryauditorUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditorUserCondition>;
  filter?: InputMaybe<AuditorUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditorUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryautodorArgs = {
  claimId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimAssigneeArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimAssigneeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimAssigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimAssigneeCondition>;
  filter?: InputMaybe<BatchClaimAssigneeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimAssigneesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimByIcnArgs = {
  icn: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimDueDateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimDueDateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimDueDatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimDueDateCondition>;
  filter?: InputMaybe<BatchClaimDueDateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimDueDatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineComputedValueArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineComputedValueByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineComputedValuesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineComputedValueCondition>;
  filter?: InputMaybe<BatchClaimLineComputedValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineComputedValuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemAuditArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemAuditByAuditFindingIdArgs = {
  auditFindingId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemAuditByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemAuditsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemAuditCondition>;
  filter?: InputMaybe<BatchClaimLineItemAuditFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemAuditsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemComputedValueArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemComputedValueByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemComputedValuesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemComputedValueCondition>;
  filter?: InputMaybe<BatchClaimLineItemComputedValueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemComputedValuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemNnIbLineArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineIdArgs =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    nnIbLineId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemNnIbLineByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemNnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemNnIbLineCondition>;
  filter?: InputMaybe<BatchClaimLineItemNnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLineItemsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineItemCondition>;
  filter?: InputMaybe<BatchClaimLineItemFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimLineCondition>;
  filter?: InputMaybe<BatchClaimLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimLinesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimStateCondition>;
  filter?: InputMaybe<BatchClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimStatesWithActiveStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<BatchClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchClaimCondition>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchClaimsAssignedToAuditorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<BatchClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchClaimsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchFileArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchFileByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybatchFilesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BatchFileCondition>;
  filter?: InputMaybe<BatchFileFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BatchFilesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerybucketClaimArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybucketClaimByBatchClaimIdArgs = {
  batchClaimId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybucketClaimByBatchClaimIdAndAuditorBucketIdArgs = {
  auditorBucketId: Scalars["UUID"]["input"];
  batchClaimId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybucketClaimByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerybucketClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<BucketClaimCondition>;
  filter?: InputMaybe<BucketClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<BucketClaimsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerycaseArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerycasesArgs = {
  input?: InputMaybe<CasesInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryclaimArgs = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryclaimCasesArgs = {
  claimId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryclaimIntakeArgs = {
  input: ClaimIntakeInput;
};

/** The root query type which gives access points into the data universe. */
export type QueryclientSignOffClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryclientSignOffClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryclientSignOffClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ClientSignOffClaimStateCondition>;
  filter?: InputMaybe<ClientSignOffClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ClientSignOffClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydefaultStrategyByInsurerArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydefaultStrategyByInsurerByInsurerIdArgs = {
  insurerId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydefaultStrategyByInsurerByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydefaultStrategyByInsurersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DefaultStrategyByInsurerCondition>;
  filter?: InputMaybe<DefaultStrategyByInsurerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DefaultStrategyByInsurersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydirectoryArgs = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydisputedFindingArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydisputedFindingByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydisputedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DisputedFindingCondition>;
  filter?: InputMaybe<DisputedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DisputedFindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentRequestCreatedArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentRequestCreatedByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentRequestCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentRequestCreatedCondition>;
  filter?: InputMaybe<DocumentRequestCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentRequestCreatedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentUploadCreatedArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentUploadCreatedByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentUploadCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentUploadCreatedCondition>;
  filter?: InputMaybe<DocumentUploadCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentUploadCreatedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentationSelectionSetArgs = {
  etag: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentationSelectionSetByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentationSelectionSetByS3KeyArgs = {
  s3Key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerydocumentationSelectionSetsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<DocumentationSelectionSetCondition>;
  filter?: InputMaybe<DocumentationSelectionSetFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<DocumentationSelectionSetsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryfileArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryfileDownloadUrlArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryfilesArgs = {
  input?: InputMaybe<FilesInput>;
};

/** The root query type which gives access points into the data universe. */
export type QueryfindingArgs = {
  id?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerygenerateAuditFindingReportViewIdArgs = {
  claimFilter?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerygenerateBcliVersionFingerprintArgs = {
  alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  itemNumber?: InputMaybe<Scalars["String"]["input"]>;
  lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
  originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
  procedureCode?: InputMaybe<Scalars["String"]["input"]>;
  procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
  revCode?: InputMaybe<Scalars["String"]["input"]>;
  unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
  units?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryhprCalculateTimeSpentSecsArgs = {
  hprId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryhumanProcessingRequestArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryhumanProcessingRequestByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryhumanProcessingRequestByRunIdAndS3AuditDocumentationIdAndRequestTypeArgs =
  {
    requestType: HumanProcessingRequestType;
    runId: Scalars["Int"]["input"];
    s3AuditDocumentationId: Scalars["UUID"]["input"];
  };

/** The root query type which gives access points into the data universe. */
export type QueryhumanProcessingRequestsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<HumanProcessingRequestCondition>;
  filter?: InputMaybe<HumanProcessingRequestFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<HumanProcessingRequestsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerByClientAbbreviationArgs = {
  clientAbbreviation: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerByNameArgs = {
  name: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerClaimIntakeDropboxPermittedByInsurerIdArgs = {
  insurerId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerClaimIntakeDropboxPermittedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerClaimIntakeDropboxPermittedCondition>;
  filter?: InputMaybe<InsurerClaimIntakeDropboxPermittedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerClaimIntakeDropboxPermittedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerOrganizationArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerOrganizationByMemberIdAndOrganizationIdArgs = {
  memberId: Scalars["UUID"]["input"];
  organizationId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerOrganizationByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerOrganizationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerOrganizationCondition>;
  filter?: InputMaybe<InsurerOrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerOrganizationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerUserArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerUserByAuditorUserIdAndInsurerIdArgs = {
  auditorUserId: Scalars["UUID"]["input"];
  insurerId: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerUserByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurerUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerUserCondition>;
  filter?: InputMaybe<InsurerUserFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurerUsersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InsurerCondition>;
  filter?: InputMaybe<InsurerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InsurersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinsurersAssignedToAuditorArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<InsurerFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryinvoiceClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinvoiceClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryinvoiceClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<InvoiceClaimStateCondition>;
  filter?: InputMaybe<InvoiceClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<InvoiceClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryitemizedBillLinesArgs = {
  ids: Array<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageCreatedArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageCreatedByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageCreatedCondition>;
  filter?: InputMaybe<MessageCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageCreatedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageSentArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageSentByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerymessageSentsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<MessageSentCondition>;
  filter?: InputMaybe<MessageSentFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<MessageSentsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerynegotiationClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerynegotiationClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerynegotiationClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NegotiationClaimStateCondition>;
  filter?: InputMaybe<NegotiationClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NegotiationClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerynnIbLineArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerynnIbLineByFingerprintAndS3KeyArgs = {
  fingerprint: Scalars["UUID"]["input"];
  s3Key: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerynnIbLineByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerynnIbLinesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<NnIbLineCondition>;
  filter?: InputMaybe<NnIbLineFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<NnIbLinesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerynodeArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryonHoldClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryonHoldClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryonHoldClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<OnHoldClaimStateCondition>;
  filter?: InputMaybe<OnHoldClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<OnHoldClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryprocedureCodeArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryprocedureCodeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryprocedureCodeTypeByTypeArgs = {
  type: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryprocedureCodeTypesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProcedureCodeTypeCondition>;
  filter?: InputMaybe<ProcedureCodeTypeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProcedureCodeTypesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryprocedureCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProcedureCodeCondition>;
  filter?: InputMaybe<ProcedureCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProcedureCodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderAuditStatsArgs = {
  year?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderSignOffClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderSignOffClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderSignOffClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderSignOffClaimStateCondition>;
  filter?: InputMaybe<ProviderSignOffClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderSignOffClaimStatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderUserFacilitiesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderUserFacilityCondition>;
  filter?: InputMaybe<ProviderUserFacilityFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProviderUserFacilitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderUserFacilityArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderUserFacilityByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryproviderUserFacilityByProviderIdAndAuditorUserIdArgs = {
  auditorUserId: Scalars["UUID"]["input"];
  providerId: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryprovidersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ProviderCondition>;
  filter?: InputMaybe<ProviderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ProvidersOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryqueueArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryqueueByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryqueuesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<QueueCondition>;
  filter?: InputMaybe<QueueFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<QueuesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryreasonCodeArgs = {
  id: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreasonCodeByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreasonCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReasonCodeCondition>;
  filter?: InputMaybe<ReasonCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReasonCodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedClaimArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedClaimByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedClaimsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedClaimCondition>;
  filter?: InputMaybe<ReportedClaimFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedClaimsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedFindingArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedFindingByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreportedFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReportedFindingCondition>;
  filter?: InputMaybe<ReportedFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReportedFindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryreviewedAuditFindingArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreviewedAuditFindingByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryreviewedAuditFindingsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<ReviewedAuditFindingCondition>;
  filter?: InputMaybe<ReviewedAuditFindingFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<ReviewedAuditFindingsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type Querys3AuditDocumentationArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type Querys3AuditDocumentationByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type Querys3AuditDocumentationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<S3AuditDocumentationCondition>;
  filter?: InputMaybe<S3AuditDocumentationFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<S3AuditDocumentationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerysearchInFileArgs = {
  fileId: Scalars["ID"]["input"];
  query: Scalars["String"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QuerythreadsArgs = {
  input: ThreadsInput;
};

/** The root query type which gives access points into the data universe. */
export type QueryuniqueNpiProvidersArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<NpiProviderFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryuniqueProcedureCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryuniqueRevCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  filter?: InputMaybe<StringFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryuserArgs = {
  id: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryuserInviteCreatedArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryuserInviteCreatedByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryuserInviteCreatedsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<UserInviteCreatedCondition>;
  filter?: InputMaybe<UserInviteCreatedFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<UserInviteCreatedsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryuserIsAssignedToProviderFacilityArgs = {
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryworkflowClaimStateArgs = {
  id: Scalars["UUID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryworkflowClaimStateByNodeIdArgs = {
  nodeId: Scalars["ID"]["input"];
};

/** The root query type which gives access points into the data universe. */
export type QueryworkflowClaimStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<WorkflowClaimStateCondition>;
  filter?: InputMaybe<WorkflowClaimStateFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<WorkflowClaimStatesOrderBy>>;
};

export type Queue = Node & {
  __typename?: "Queue";
  /** Reads a single `AuditorUser` that is related to this `Queue`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  filter: Scalars["JSON"]["output"];
  id: Scalars["UUID"]["output"];
  indexedFilterTags?: Maybe<Scalars["JSON"]["output"]>;
  name: Scalars["String"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type QueueAggregates = {
  __typename?: "QueueAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<QueueDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `Queue` object types. */
export type QueueAggregatesFilter = {
  /** Distinct count aggregate over matching `Queue` objects. */
  distinctCount?: InputMaybe<QueueDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Queue` object to be included within the aggregate. */
  filter?: InputMaybe<QueueFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type QueueAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `QueueInput` mutation. */
export type QueueAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<QueueAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnQueueForQueueAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnQueueForQueueAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<QueueOnQueueForQueueAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `queue` in the `AuditorUserInput` mutation. */
export type QueueAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `queue` for the far side of the relationship. */
  connectById?: InputMaybe<Array<QueueQueuePkeyConnect>>;
  /** The primary key(s) for `queue` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<QueueNodeIdConnect>>;
  /** A `QueueInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<QueueAuthorIdFkeyQueueCreateInput>>;
  /** The primary key(s) and patch data for `queue` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<QueueOnQueueForQueueAuthorIdFkeyUsingQueuePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `queue` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnQueueForQueueAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `queue` to be created by this mutation. */
export type QueueAuthorIdFkeyQueueCreateInput = {
  auditorUserToAuthorId?: InputMaybe<QueueAuthorIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  filter: Scalars["JSON"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  indexedFilterTags?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** A condition to be used against `Queue` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type QueueCondition = {
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `filter` field. */
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `indexedFilterTags` field. */
  indexedFilterTags?: InputMaybe<Scalars["JSON"]["input"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type QueueDistinctCountAggregateFilter = {
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  description?: InputMaybe<BigIntFilter>;
  filter?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  indexedFilterTags?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type QueueDistinctCountAggregates = {
  __typename?: "QueueDistinctCountAggregates";
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of filter across the matching connection */
  filter?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of indexedFilterTags across the matching connection */
  indexedFilterTags?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `Queue` object types. All fields are combined with a logical ‘and.’ */
export type QueueFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<QueueFilter>>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `filter` field. */
  filter?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `indexedFilterTags` field. */
  indexedFilterTags?: InputMaybe<JSONFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<QueueFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<QueueFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `Queue` for usage during aggregation. */
export enum QueueGroupBy {
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DELETED = "DELETED",
  DESCRIPTION = "DESCRIPTION",
  FILTER = "FILTER",
  INDEXED_FILTER_TAGS = "INDEXED_FILTER_TAGS",
  NAME = "NAME",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type QueueHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Queue` aggregates. */
export type QueueHavingInput = {
  AND?: InputMaybe<Array<QueueHavingInput>>;
  OR?: InputMaybe<Array<QueueHavingInput>>;
  average?: InputMaybe<QueueHavingAverageInput>;
  distinctCount?: InputMaybe<QueueHavingDistinctCountInput>;
  max?: InputMaybe<QueueHavingMaxInput>;
  min?: InputMaybe<QueueHavingMinInput>;
  stddevPopulation?: InputMaybe<QueueHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<QueueHavingStddevSampleInput>;
  sum?: InputMaybe<QueueHavingSumInput>;
  variancePopulation?: InputMaybe<QueueHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<QueueHavingVarianceSampleInput>;
};

export type QueueHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type QueueHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Queue` */
export type QueueInput = {
  auditorUserToAuthorId?: InputMaybe<QueueAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  filter: Scalars["JSON"]["input"];
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  indexedFilterTags?: InputMaybe<Scalars["JSON"]["input"]>;
  name: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type QueueNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `queue` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type QueueOnQueueForQueueAuthorIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `auditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The fields on `queue` to look up the row to update. */
export type QueueOnQueueForQueueAuthorIdFkeyUsingQueuePkeyUpdate = {
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `queue` being updated. */
  patch: updateQueueOnQueueForQueueAuthorIdFkeyPatch;
};

/** Represents an update to a `Queue`. Fields that are set will be updated. */
export type QueuePatch = {
  auditorUserToAuthorId?: InputMaybe<QueueAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  indexedFilterTags?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `queue` to look up the row to connect. */
export type QueueQueuePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `Queue` values. */
export type QueuesConnection = {
  __typename?: "QueuesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<QueueAggregates>;
  /** A list of edges which contains the `Queue` and cursor to aid in pagination. */
  edges: Array<QueuesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<QueueAggregates>>;
  /** A list of `Queue` objects. */
  nodes: Array<Maybe<Queue>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Queue` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `Queue` values. */
export type QueuesConnectiongroupedAggregatesArgs = {
  groupBy: Array<QueueGroupBy>;
  having?: InputMaybe<QueueHavingInput>;
};

/** A `Queue` edge in the connection. */
export type QueuesEdge = {
  __typename?: "QueuesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `Queue` at the end of the edge. */
  node?: Maybe<Queue>;
};

/** Methods to use when ordering `Queue`. */
export enum QueuesOrderBy {
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DELETED_ASC = "DELETED_ASC",
  DELETED_DESC = "DELETED_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  FILTER_ASC = "FILTER_ASC",
  FILTER_DESC = "FILTER_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  INDEXED_FILTER_TAGS_ASC = "INDEXED_FILTER_TAGS_ASC",
  INDEXED_FILTER_TAGS_DESC = "INDEXED_FILTER_TAGS_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type ReasonCode = Node & {
  __typename?: "ReasonCode";
  /** Reads and enables pagination through a set of `AuditFindingReasonCode`. */
  auditFindingReasonCodes: AuditFindingReasonCodesConnection;
  createdAt: Scalars["Datetime"]["output"];
  dateEffectiveEnd?: Maybe<Scalars["Datetime"]["output"]>;
  dateEffectiveStart?: Maybe<Scalars["Datetime"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["String"]["output"];
  lastUpdated?: Maybe<Scalars["Datetime"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  notes?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
};

export type ReasonCodeauditFindingReasonCodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<AuditFindingReasonCodeCondition>;
  filter?: InputMaybe<AuditFindingReasonCodeFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
};

export type ReasonCodeAggregates = {
  __typename?: "ReasonCodeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReasonCodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/**
 * A condition to be used against `ReasonCode` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ReasonCodeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateEffectiveEnd` field. */
  dateEffectiveEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `dateEffectiveStart` field. */
  dateEffectiveStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `notes` field. */
  notes?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ReasonCodeDistinctCountAggregates = {
  __typename?: "ReasonCodeDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateEffectiveEnd across the matching connection */
  dateEffectiveEnd?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of dateEffectiveStart across the matching connection */
  dateEffectiveStart?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastUpdated across the matching connection */
  lastUpdated?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of notes across the matching connection */
  notes?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ReasonCode` object types. All fields are combined with a logical ‘and.’ */
export type ReasonCodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReasonCodeFilter>>;
  /** Filter by the object’s `auditFindingReasonCodes` relation. */
  auditFindingReasonCodes?: InputMaybe<ReasonCodeToManyAuditFindingReasonCodeFilter>;
  /** Some related `auditFindingReasonCodes` exist. */
  auditFindingReasonCodesExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateEffectiveEnd` field. */
  dateEffectiveEnd?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dateEffectiveStart` field. */
  dateEffectiveStart?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lastUpdated` field. */
  lastUpdated?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReasonCodeFilter>;
  /** Filter by the object’s `notes` field. */
  notes?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReasonCodeFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ReasonCode` for usage during aggregation. */
export enum ReasonCodeGroupBy {
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DATE_EFFECTIVE_END = "DATE_EFFECTIVE_END",
  DATE_EFFECTIVE_END_TRUNCATED_TO_DAY = "DATE_EFFECTIVE_END_TRUNCATED_TO_DAY",
  DATE_EFFECTIVE_END_TRUNCATED_TO_HOUR = "DATE_EFFECTIVE_END_TRUNCATED_TO_HOUR",
  DATE_EFFECTIVE_START = "DATE_EFFECTIVE_START",
  DATE_EFFECTIVE_START_TRUNCATED_TO_DAY = "DATE_EFFECTIVE_START_TRUNCATED_TO_DAY",
  DATE_EFFECTIVE_START_TRUNCATED_TO_HOUR = "DATE_EFFECTIVE_START_TRUNCATED_TO_HOUR",
  DESCRIPTION = "DESCRIPTION",
  LAST_UPDATED = "LAST_UPDATED",
  LAST_UPDATED_TRUNCATED_TO_DAY = "LAST_UPDATED_TRUNCATED_TO_DAY",
  LAST_UPDATED_TRUNCATED_TO_HOUR = "LAST_UPDATED_TRUNCATED_TO_HOUR",
  NOTES = "NOTES",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ReasonCodeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ReasonCode` aggregates. */
export type ReasonCodeHavingInput = {
  AND?: InputMaybe<Array<ReasonCodeHavingInput>>;
  OR?: InputMaybe<Array<ReasonCodeHavingInput>>;
  average?: InputMaybe<ReasonCodeHavingAverageInput>;
  distinctCount?: InputMaybe<ReasonCodeHavingDistinctCountInput>;
  max?: InputMaybe<ReasonCodeHavingMaxInput>;
  min?: InputMaybe<ReasonCodeHavingMinInput>;
  stddevPopulation?: InputMaybe<ReasonCodeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ReasonCodeHavingStddevSampleInput>;
  sum?: InputMaybe<ReasonCodeHavingSumInput>;
  variancePopulation?: InputMaybe<ReasonCodeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ReasonCodeHavingVarianceSampleInput>;
};

export type ReasonCodeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReasonCodeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveEnd?: InputMaybe<HavingDatetimeFilter>;
  dateEffectiveStart?: InputMaybe<HavingDatetimeFilter>;
  lastUpdated?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReasonCodeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reasonCode` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReasonCode` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
    patch: AuditFindingReasonCodePatch;
  };

/** The fields on `reasonCode` to look up the row to update. */
export type ReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyUsingReasonCodePkeyUpdate =
  {
    id: Scalars["String"]["input"];
    /** An object where the defined keys will be set on the `reasonCode` being updated. */
    patch: updateReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyPatch;
  };

/** Represents an update to a `ReasonCode`. Fields that are set will be updated. */
export type ReasonCodePatch = {
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateEffectiveEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
  dateEffectiveStart?: InputMaybe<Scalars["Datetime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Datetime"]["input"]>;
  notes?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `reasonCode` to look up the row to connect. */
export type ReasonCodeReasonCodePkeyConnect = {
  id: Scalars["String"]["input"];
};

/** A filter to be used against many `AuditFindingReasonCode` object types. All fields are combined with a logical ‘and.’ */
export type ReasonCodeToManyAuditFindingReasonCodeFilter = {
  /** Aggregates across related `AuditFindingReasonCode` match the filter criteria. */
  aggregates?: InputMaybe<AuditFindingReasonCodeAggregatesFilter>;
  /** Every related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** No related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<AuditFindingReasonCodeFilter>;
  /** Some related `AuditFindingReasonCode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<AuditFindingReasonCodeFilter>;
};

/** A connection to a list of `ReasonCode` values. */
export type ReasonCodesConnection = {
  __typename?: "ReasonCodesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReasonCodeAggregates>;
  /** A list of edges which contains the `ReasonCode` and cursor to aid in pagination. */
  edges: Array<ReasonCodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReasonCodeAggregates>>;
  /** A list of `ReasonCode` objects. */
  nodes: Array<Maybe<ReasonCode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReasonCode` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ReasonCode` values. */
export type ReasonCodesConnectiongroupedAggregatesArgs = {
  groupBy: Array<ReasonCodeGroupBy>;
  having?: InputMaybe<ReasonCodeHavingInput>;
};

/** A `ReasonCode` edge in the connection. */
export type ReasonCodesEdge = {
  __typename?: "ReasonCodesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReasonCode` at the end of the edge. */
  node?: Maybe<ReasonCode>;
};

/** Methods to use when ordering `ReasonCode`. */
export enum ReasonCodesOrderBy {
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_AVERAGE_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_BY_REASON_CODE_ID__COUNT_ASC = "AUDIT_FINDING_REASON_CODES_BY_REASON_CODE_ID__COUNT_ASC",
  AUDIT_FINDING_REASON_CODES_BY_REASON_CODE_ID__COUNT_DESC = "AUDIT_FINDING_REASON_CODES_BY_REASON_CODE_ID__COUNT_DESC",
  AUDIT_FINDING_REASON_CODES_COUNT_ASC = "AUDIT_FINDING_REASON_CODES_COUNT_ASC",
  AUDIT_FINDING_REASON_CODES_COUNT_DESC = "AUDIT_FINDING_REASON_CODES_COUNT_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_DISTINCT_COUNT_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MAX_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_MAX_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MAX_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MIN_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_MIN_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_MIN_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_SUM_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_SUM_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_SUM_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_AUTHOR_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_REASON_CODE_ID_DESC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC = "AUDIT_FINDING_REASON_CODES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DATE_EFFECTIVE_END_ASC = "DATE_EFFECTIVE_END_ASC",
  DATE_EFFECTIVE_END_DESC = "DATE_EFFECTIVE_END_DESC",
  DATE_EFFECTIVE_START_ASC = "DATE_EFFECTIVE_START_ASC",
  DATE_EFFECTIVE_START_DESC = "DATE_EFFECTIVE_START_DESC",
  DESCRIPTION_ASC = "DESCRIPTION_ASC",
  DESCRIPTION_DESC = "DESCRIPTION_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  LAST_UPDATED_ASC = "LAST_UPDATED_ASC",
  LAST_UPDATED_DESC = "LAST_UPDATED_DESC",
  NATURAL = "NATURAL",
  NOTES_ASC = "NOTES_ASC",
  NOTES_DESC = "NOTES_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** All input for the `refreshS3AuditDocumentation` mutation. */
export type RefreshS3AuditDocumentationInput = {
  batchClaimIcn?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  forceRefresh?: InputMaybe<Scalars["Boolean"]["input"]>;
  pId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `refreshS3AuditDocumentation` mutation. */
export type RefreshS3AuditDocumentationPayload = {
  __typename?: "RefreshS3AuditDocumentationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  uuids?: Maybe<Array<Maybe<Scalars["UUID"]["output"]>>>;
};

export type ReportAggregate = {
  __typename?: "ReportAggregate";
  count?: Maybe<Scalars["Int"]["output"]>;
  endDate?: Maybe<Scalars["Datetime"]["output"]>;
  startDate?: Maybe<Scalars["Datetime"]["output"]>;
  sum?: Maybe<Scalars["Float"]["output"]>;
};

/**
 * Tracks the claims reported in a given audit finding report.
 * This is useful for historical audit purposes, but mainly exists to track the computatio strategy used on each claim at the time that the report was generated.
 * We must track these per report since the uniqueness of the report according to our business rules and the actual calculations in the report itself depend on the strategy selected for each claim.
 */
export type ReportedClaim = Node & {
  __typename?: "ReportedClaim";
  /** Reads a single `AuditFindingReport` that is related to this `ReportedClaim`. */
  auditFindingReport?: Maybe<AuditFindingReport>;
  auditFindingReportId: Scalars["UUID"]["output"];
  /** Reads a single `BatchClaim` that is related to this `ReportedClaim`. */
  claim?: Maybe<BatchClaim>;
  claimId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  strategy: ClaimComputationStrategyKind;
};

export type ReportedClaimAggregates = {
  __typename?: "ReportedClaimAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReportedClaimDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ReportedClaim` object types. */
export type ReportedClaimAggregatesFilter = {
  /** Distinct count aggregate over matching `ReportedClaim` objects. */
  distinctCount?: InputMaybe<ReportedClaimDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ReportedClaim` object to be included within the aggregate. */
  filter?: InputMaybe<ReportedClaimFilter>;
};

/** Input for the nested mutation of `auditFindingReport` in the `ReportedClaimInput` mutation. */
export type ReportedClaimAuditFindingReportIdAuditFindingReportIdInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingReportNodeIdConnect>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdUsingPreliminaryAuditFindingReportPkeyUpdate>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdNodeIdUpdate>;
};

/** Input for the nested mutation of `reportedClaim` in the `AuditFindingReportInput` mutation. */
export type ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput =
  {
    /** The primary key(s) for `reportedClaim` for the far side of the relationship. */
    connectById?: InputMaybe<Array<ReportedClaimReportedClaimPkeyConnect>>;
    /** The primary key(s) for `reportedClaim` for the far side of the relationship. */
    connectByNodeId?: InputMaybe<Array<ReportedClaimNodeIdConnect>>;
    /** The primary key(s) and patch data for `reportedClaim` for the far side of the relationship. */
    updateById?: InputMaybe<
      Array<ReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdUsingReportedClaimPkeyUpdate>
    >;
    /** The primary key(s) and patch data for `reportedClaim` for the far side of the relationship. */
    updateByNodeId?: InputMaybe<
      Array<AuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdNodeIdUpdate>
    >;
  };

/** Input for the nested mutation of `batchClaim` in the `ReportedClaimInput` mutation. */
export type ReportedClaimClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reportedClaim` in the `BatchClaimInput` mutation. */
export type ReportedClaimClaimIdFkeyInverseInput = {
  /** The primary key(s) for `reportedClaim` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ReportedClaimReportedClaimPkeyConnect>>;
  /** The primary key(s) for `reportedClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReportedClaimNodeIdConnect>>;
  /** The primary key(s) and patch data for `reportedClaim` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingReportedClaimPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `reportedClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnReportedClaimForReportedClaimClaimIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `ReportedClaim` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportedClaimCondition = {
  /** Checks for equality with the object’s `auditFindingReportId` field. */
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `claimId` field. */
  claimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `strategy` field. */
  strategy?: InputMaybe<ClaimComputationStrategyKind>;
};

export type ReportedClaimDistinctCountAggregateFilter = {
  auditFindingReportId?: InputMaybe<BigIntFilter>;
  claimId?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  strategy?: InputMaybe<BigIntFilter>;
};

export type ReportedClaimDistinctCountAggregates = {
  __typename?: "ReportedClaimDistinctCountAggregates";
  /** Distinct count of auditFindingReportId across the matching connection */
  auditFindingReportId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of claimId across the matching connection */
  claimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of strategy across the matching connection */
  strategy?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ReportedClaim` object types. All fields are combined with a logical ‘and.’ */
export type ReportedClaimFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReportedClaimFilter>>;
  /** Filter by the object’s `auditFindingReport` relation. */
  auditFindingReport?: InputMaybe<AuditFindingReportFilter>;
  /** Filter by the object’s `auditFindingReportId` field. */
  auditFindingReportId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `claim` relation. */
  claim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `claimId` field. */
  claimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReportedClaimFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReportedClaimFilter>>;
  /** Filter by the object’s `strategy` field. */
  strategy?: InputMaybe<ClaimComputationStrategyKindFilter>;
};

/** Grouping methods for `ReportedClaim` for usage during aggregation. */
export enum ReportedClaimGroupBy {
  AUDIT_FINDING_REPORT_ID = "AUDIT_FINDING_REPORT_ID",
  CLAIM_ID = "CLAIM_ID",
  STRATEGY = "STRATEGY",
}

/** Conditions for `ReportedClaim` aggregates. */
export type ReportedClaimHavingInput = {
  AND?: InputMaybe<Array<ReportedClaimHavingInput>>;
  OR?: InputMaybe<Array<ReportedClaimHavingInput>>;
};

/** An input for mutations affecting `ReportedClaim` */
export type ReportedClaimInput = {
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInput>;
  batchClaimToClaimId?: InputMaybe<ReportedClaimClaimIdFkeyInput>;
  claimId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReportedClaimNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reportedClaim` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `reportedClaim` to look up the row to update. */
export type ReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdUsingReportedClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reportedClaim` being updated. */
    patch: updateReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `reportedClaim` to look up the row to update. */
export type ReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyUsingReportedClaimPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reportedClaim` being updated. */
    patch: updateReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyPatch;
  };

/** Represents an update to a `ReportedClaim`. Fields that are set will be updated. */
export type ReportedClaimPatch = {
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInput>;
  batchClaimToClaimId?: InputMaybe<ReportedClaimClaimIdFkeyInput>;
  claimId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `reportedClaim` to look up the row to connect. */
export type ReportedClaimReportedClaimPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `ReportedClaim` values. */
export type ReportedClaimsConnection = {
  __typename?: "ReportedClaimsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReportedClaimAggregates>;
  /** A list of edges which contains the `ReportedClaim` and cursor to aid in pagination. */
  edges: Array<ReportedClaimsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReportedClaimAggregates>>;
  /** A list of `ReportedClaim` objects. */
  nodes: Array<Maybe<ReportedClaim>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportedClaim` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ReportedClaim` values. */
export type ReportedClaimsConnectiongroupedAggregatesArgs = {
  groupBy: Array<ReportedClaimGroupBy>;
  having?: InputMaybe<ReportedClaimHavingInput>;
};

/** A `ReportedClaim` edge in the connection. */
export type ReportedClaimsEdge = {
  __typename?: "ReportedClaimsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReportedClaim` at the end of the edge. */
  node?: Maybe<ReportedClaim>;
};

/** Methods to use when ordering `ReportedClaim`. */
export enum ReportedClaimsOrderBy {
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_DESC",
  AUDIT_FINDING_REPORT_ID_ASC = "AUDIT_FINDING_REPORT_ID_ASC",
  AUDIT_FINDING_REPORT_ID_DESC = "AUDIT_FINDING_REPORT_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_CLAIM_ID__UPDATED_AT_DESC",
  CLAIM_ID_ASC = "CLAIM_ID_ASC",
  CLAIM_ID_DESC = "CLAIM_ID_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  STRATEGY_ASC = "STRATEGY_ASC",
  STRATEGY_DESC = "STRATEGY_DESC",
}

export type ReportedFinding = Node & {
  __typename?: "ReportedFinding";
  /** Reads a single `AuditFinding` that is related to this `ReportedFinding`. */
  auditFinding?: Maybe<AuditFinding>;
  auditFindingId: Scalars["UUID"]["output"];
  /** Reads a single `AuditFindingReport` that is related to this `ReportedFinding`. */
  auditFindingReport?: Maybe<AuditFindingReport>;
  auditFindingReportId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type ReportedFindingAggregates = {
  __typename?: "ReportedFindingAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReportedFindingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ReportedFinding` object types. */
export type ReportedFindingAggregatesFilter = {
  /** Distinct count aggregate over matching `ReportedFinding` objects. */
  distinctCount?: InputMaybe<ReportedFindingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ReportedFinding` object to be included within the aggregate. */
  filter?: InputMaybe<ReportedFindingFilter>;
};

/** Input for the nested mutation of `auditFinding` in the `ReportedFindingInput` mutation. */
export type ReportedFindingAuditFindingIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reportedFinding` in the `AuditFindingInput` mutation. */
export type ReportedFindingAuditFindingIdFkeyInverseInput = {
  /** The primary key(s) for `reportedFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ReportedFindingReportedFindingPkeyConnect>>;
  /** The primary key(s) for `reportedFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReportedFindingNodeIdConnect>>;
  /** The primary key(s) and patch data for `reportedFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyUsingReportedFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `reportedFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyNodeIdUpdate>
  >;
};

/** Input for the nested mutation of `auditFindingReport` in the `ReportedFindingInput` mutation. */
export type ReportedFindingAuditFindingReportIdFkeyInput = {
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingReportPreliminaryAuditFindingReportPkeyConnect>;
  /** The primary key(s) for `auditFindingReport` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingReportNodeIdConnect>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyUsingPreliminaryAuditFindingReportPkeyUpdate>;
  /** The primary key(s) and patch data for `auditFindingReport` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reportedFinding` in the `AuditFindingReportInput` mutation. */
export type ReportedFindingAuditFindingReportIdFkeyInverseInput = {
  /** The primary key(s) for `reportedFinding` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ReportedFindingReportedFindingPkeyConnect>>;
  /** The primary key(s) for `reportedFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReportedFindingNodeIdConnect>>;
  /** The primary key(s) and patch data for `reportedFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyUsingReportedFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `reportedFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyNodeIdUpdate>
  >;
};

/**
 * A condition to be used against `ReportedFinding` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReportedFindingCondition = {
  /** Checks for equality with the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `auditFindingReportId` field. */
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ReportedFindingDistinctCountAggregateFilter = {
  auditFindingId?: InputMaybe<BigIntFilter>;
  auditFindingReportId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type ReportedFindingDistinctCountAggregates = {
  __typename?: "ReportedFindingDistinctCountAggregates";
  /** Distinct count of auditFindingId across the matching connection */
  auditFindingId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingReportId across the matching connection */
  auditFindingReportId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ReportedFinding` object types. All fields are combined with a logical ‘and.’ */
export type ReportedFindingFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReportedFindingFilter>>;
  /** Filter by the object’s `auditFinding` relation. */
  auditFinding?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `auditFindingReport` relation. */
  auditFindingReport?: InputMaybe<AuditFindingReportFilter>;
  /** Filter by the object’s `auditFindingReportId` field. */
  auditFindingReportId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReportedFindingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReportedFindingFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ReportedFinding` for usage during aggregation. */
export enum ReportedFindingGroupBy {
  AUDIT_FINDING_ID = "AUDIT_FINDING_ID",
  AUDIT_FINDING_REPORT_ID = "AUDIT_FINDING_REPORT_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ReportedFindingHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ReportedFinding` aggregates. */
export type ReportedFindingHavingInput = {
  AND?: InputMaybe<Array<ReportedFindingHavingInput>>;
  OR?: InputMaybe<Array<ReportedFindingHavingInput>>;
  average?: InputMaybe<ReportedFindingHavingAverageInput>;
  distinctCount?: InputMaybe<ReportedFindingHavingDistinctCountInput>;
  max?: InputMaybe<ReportedFindingHavingMaxInput>;
  min?: InputMaybe<ReportedFindingHavingMinInput>;
  stddevPopulation?: InputMaybe<ReportedFindingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ReportedFindingHavingStddevSampleInput>;
  sum?: InputMaybe<ReportedFindingHavingSumInput>;
  variancePopulation?: InputMaybe<ReportedFindingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ReportedFindingHavingVarianceSampleInput>;
};

export type ReportedFindingHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReportedFindingHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ReportedFinding` */
export type ReportedFindingInput = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInput>;
  auditFindingToAuditFindingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReportedFindingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reportedFinding` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `reportedFinding` to look up the row to update. */
export type ReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyUsingReportedFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reportedFinding` being updated. */
    patch: updateReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFindingReport` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFindingReport` being updated. */
    patch: AuditFindingReportPatch;
  };

/** The fields on `reportedFinding` to look up the row to update. */
export type ReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyUsingReportedFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reportedFinding` being updated. */
    patch: updateReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyPatch;
  };

/** Represents an update to a `ReportedFinding`. Fields that are set will be updated. */
export type ReportedFindingPatch = {
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInput>;
  auditFindingToAuditFindingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInput>;
};

/** The fields on `reportedFinding` to look up the row to connect. */
export type ReportedFindingReportedFindingPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `ReportedFinding` values. */
export type ReportedFindingsConnection = {
  __typename?: "ReportedFindingsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReportedFindingAggregates>;
  /** A list of edges which contains the `ReportedFinding` and cursor to aid in pagination. */
  edges: Array<ReportedFindingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReportedFindingAggregates>>;
  /** A list of `ReportedFinding` objects. */
  nodes: Array<Maybe<ReportedFinding>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReportedFinding` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ReportedFinding` values. */
export type ReportedFindingsConnectiongroupedAggregatesArgs = {
  groupBy: Array<ReportedFindingGroupBy>;
  having?: InputMaybe<ReportedFindingHavingInput>;
};

/** A `ReportedFinding` edge in the connection. */
export type ReportedFindingsEdge = {
  __typename?: "ReportedFindingsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReportedFinding` at the end of the edge. */
  node?: Maybe<ReportedFinding>;
};

/** Methods to use when ordering `ReportedFinding`. */
export enum ReportedFindingsOrderBy {
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_SUBTYPE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUDIT_FINDING_REPORT_TYPE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CLAIM_FILTER_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__CREATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__INSURER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__ORIGINAL_VERSION_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PRIMARY_PIA_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__PROVIDER_ID_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_URL_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__S3_VERSION_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__TITLE_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_ASC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_ASC",
  AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_DESC = "AUDIT_FINDING_REPORT_BY_AUDIT_FINDING_REPORT_ID__VIEW_ID_DESC",
  AUDIT_FINDING_REPORT_ID_ASC = "AUDIT_FINDING_REPORT_ID_ASC",
  AUDIT_FINDING_REPORT_ID_DESC = "AUDIT_FINDING_REPORT_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** Input for the nested mutation of `messageSent` in the `MessageSentInput` mutation. */
export type ResponseToMessageSentIdMessageSentIdFkInput = {
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectById?: InputMaybe<MessageSentMessageSentPkeyConnect>;
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageSentNodeIdConnect>;
  /** A `MessageSentInput` object that will be created and connected to this object. */
  create?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkMessageSentCreateInput>;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateById?: InputMaybe<MessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkUsingMessageSentPkeyUpdate>;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkNodeIdUpdate>;
};

/** The `messageSent` to be created by this mutation. */
export type ResponseToMessageSentIdMessageSentIdFkMessageSentCreateInput = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  emailTemplate?: InputMaybe<MessageSentInputEmailTemplateType>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ReviewAuditFindingsInput = {
  auditFindingIds: Array<Scalars["UUID"]["input"]>;
  authorId: Scalars["UUID"]["input"];
  operation: ReviewAuditFindingsOperation;
};

export enum ReviewAuditFindingsOperation {
  ACCEPT = "ACCEPT",
  DECLINE = "DECLINE",
  DELETE = "DELETE",
}

export type ReviewAuditFindingsPayload = {
  __typename?: "ReviewAuditFindingsPayload";
  latestReviews: Array<ReviewedAuditFinding>;
};

export type ReviewedAuditFinding = Node & {
  __typename?: "ReviewedAuditFinding";
  accepted: Scalars["Boolean"]["output"];
  /** Reads a single `AuditFinding` that is related to this `ReviewedAuditFinding`. */
  auditFinding?: Maybe<AuditFinding>;
  auditFindingId: Scalars["UUID"]["output"];
  /** Reads a single `AuditorUser` that is related to this `ReviewedAuditFinding`. */
  author?: Maybe<AuditorUser>;
  authorId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  deleted: Scalars["Boolean"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  rationale?: Maybe<Scalars["String"]["output"]>;
  updatedAt: Scalars["Datetime"]["output"];
};

export type ReviewedAuditFindingAggregates = {
  __typename?: "ReviewedAuditFindingAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ReviewedAuditFindingDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `ReviewedAuditFinding` object types. */
export type ReviewedAuditFindingAggregatesFilter = {
  /** Distinct count aggregate over matching `ReviewedAuditFinding` objects. */
  distinctCount?: InputMaybe<ReviewedAuditFindingDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ReviewedAuditFinding` object to be included within the aggregate. */
  filter?: InputMaybe<ReviewedAuditFindingFilter>;
};

/** The `auditFinding` to be created by this mutation. */
export type ReviewedAuditFindingAuditFindingIdFkeyAuditFindingCreateInput = {
  auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
  auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
  auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
  auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
  auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
  auditFindingSeedType: AuditFindingSeedType;
  auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
  auditType?: InputMaybe<AuditTypeEnum>;
  auditorComment?: InputMaybe<Scalars["String"]["input"]>;
  auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
  autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
  autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
  batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
  batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
  confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id: Scalars["UUID"]["input"];
  improperPaymentCost: Scalars["Float"]["input"];
  improperPaymentReason: Scalars["String"]["input"];
  improperPaymentUnitsCharged: Scalars["Int"]["input"];
  metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
  metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
  metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
  metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
  needsReview: Scalars["Boolean"]["input"];
  previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
  reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `auditFinding` in the `ReviewedAuditFindingInput` mutation. */
export type ReviewedAuditFindingAuditFindingIdFkeyInput = {
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<AuditFindingAuditFindingClaimLinePkeyConnect>;
  /** The primary key(s) for `auditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditFindingNodeIdConnect>;
  /** A `AuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyAuditFindingCreateInput>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<AuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyUsingAuditFindingClaimLinePkeyUpdate>;
  /** The primary key(s) and patch data for `auditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reviewedAuditFinding` in the `AuditFindingInput` mutation. */
export type ReviewedAuditFindingAuditFindingIdFkeyInverseInput = {
  /** The primary key(s) for `reviewedAuditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ReviewedAuditFindingReviewedAuditFindingPkeyConnect>
  >;
  /** The primary key(s) for `reviewedAuditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReviewedAuditFindingNodeIdConnect>>;
  /** A `ReviewedAuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ReviewedAuditFindingAuditFindingIdFkeyReviewedAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `reviewedAuditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyUsingReviewedAuditFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `reviewedAuditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyNodeIdUpdate>
  >;
};

/** The `reviewedAuditFinding` to be created by this mutation. */
export type ReviewedAuditFindingAuditFindingIdFkeyReviewedAuditFindingCreateInput =
  {
    accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    rationale?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** The `auditorUser` to be created by this mutation. */
export type ReviewedAuditFindingAuthorIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `ReviewedAuditFindingInput` mutation. */
export type ReviewedAuditFindingAuthorIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `reviewedAuditFinding` in the `AuditorUserInput` mutation. */
export type ReviewedAuditFindingAuthorIdFkeyInverseInput = {
  /** The primary key(s) for `reviewedAuditFinding` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<ReviewedAuditFindingReviewedAuditFindingPkeyConnect>
  >;
  /** The primary key(s) for `reviewedAuditFinding` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<ReviewedAuditFindingNodeIdConnect>>;
  /** A `ReviewedAuditFindingInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ReviewedAuditFindingAuthorIdFkeyReviewedAuditFindingCreateInput>
  >;
  /** The primary key(s) and patch data for `reviewedAuditFinding` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingReviewedAuditFindingPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `reviewedAuditFinding` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyNodeIdUpdate>
  >;
};

/** The `reviewedAuditFinding` to be created by this mutation. */
export type ReviewedAuditFindingAuthorIdFkeyReviewedAuditFindingCreateInput = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  rationale?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/**
 * A condition to be used against `ReviewedAuditFinding` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ReviewedAuditFindingCondition = {
  /** Checks for equality with the object’s `accepted` field. */
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `authorId` field. */
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `deleted` field. */
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `rationale` field. */
  rationale?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type ReviewedAuditFindingDistinctCountAggregateFilter = {
  accepted?: InputMaybe<BigIntFilter>;
  auditFindingId?: InputMaybe<BigIntFilter>;
  authorId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  deleted?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  rationale?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type ReviewedAuditFindingDistinctCountAggregates = {
  __typename?: "ReviewedAuditFindingDistinctCountAggregates";
  /** Distinct count of accepted across the matching connection */
  accepted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of auditFindingId across the matching connection */
  auditFindingId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of authorId across the matching connection */
  authorId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of deleted across the matching connection */
  deleted?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of rationale across the matching connection */
  rationale?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `ReviewedAuditFinding` object types. All fields are combined with a logical ‘and.’ */
export type ReviewedAuditFindingFilter = {
  /** Filter by the object’s `accepted` field. */
  accepted?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ReviewedAuditFindingFilter>>;
  /** Filter by the object’s `auditFinding` relation. */
  auditFinding?: InputMaybe<AuditFindingFilter>;
  /** Filter by the object’s `auditFindingId` field. */
  auditFindingId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `author` relation. */
  author?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `authorId` field. */
  authorId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleted` field. */
  deleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ReviewedAuditFindingFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ReviewedAuditFindingFilter>>;
  /** Filter by the object’s `rationale` field. */
  rationale?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `ReviewedAuditFinding` for usage during aggregation. */
export enum ReviewedAuditFindingGroupBy {
  ACCEPTED = "ACCEPTED",
  AUDIT_FINDING_ID = "AUDIT_FINDING_ID",
  AUTHOR_ID = "AUTHOR_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  DELETED = "DELETED",
  RATIONALE = "RATIONALE",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ReviewedAuditFindingHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ReviewedAuditFinding` aggregates. */
export type ReviewedAuditFindingHavingInput = {
  AND?: InputMaybe<Array<ReviewedAuditFindingHavingInput>>;
  OR?: InputMaybe<Array<ReviewedAuditFindingHavingInput>>;
  average?: InputMaybe<ReviewedAuditFindingHavingAverageInput>;
  distinctCount?: InputMaybe<ReviewedAuditFindingHavingDistinctCountInput>;
  max?: InputMaybe<ReviewedAuditFindingHavingMaxInput>;
  min?: InputMaybe<ReviewedAuditFindingHavingMinInput>;
  stddevPopulation?: InputMaybe<ReviewedAuditFindingHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ReviewedAuditFindingHavingStddevSampleInput>;
  sum?: InputMaybe<ReviewedAuditFindingHavingSumInput>;
  variancePopulation?: InputMaybe<ReviewedAuditFindingHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ReviewedAuditFindingHavingVarianceSampleInput>;
};

export type ReviewedAuditFindingHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ReviewedAuditFindingHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ReviewedAuditFinding` */
export type ReviewedAuditFindingInput = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  rationale?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ReviewedAuditFindingNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `reviewedAuditFinding` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditFinding` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditFinding` being updated. */
    patch: AuditFindingPatch;
  };

/** The fields on `reviewedAuditFinding` to look up the row to update. */
export type ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyUsingReviewedAuditFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
    patch: updateReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `reviewedAuditFinding` to look up the row to update. */
export type ReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyUsingReviewedAuditFindingPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
    patch: updateReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyPatch;
  };

/** Represents an update to a `ReviewedAuditFinding`. Fields that are set will be updated. */
export type ReviewedAuditFindingPatch = {
  accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
  auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
  auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  rationale?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The fields on `reviewedAuditFinding` to look up the row to connect. */
export type ReviewedAuditFindingReviewedAuditFindingPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `ReviewedAuditFinding` values. */
export type ReviewedAuditFindingsConnection = {
  __typename?: "ReviewedAuditFindingsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ReviewedAuditFindingAggregates>;
  /** A list of edges which contains the `ReviewedAuditFinding` and cursor to aid in pagination. */
  edges: Array<ReviewedAuditFindingsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ReviewedAuditFindingAggregates>>;
  /** A list of `ReviewedAuditFinding` objects. */
  nodes: Array<Maybe<ReviewedAuditFinding>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReviewedAuditFinding` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `ReviewedAuditFinding` values. */
export type ReviewedAuditFindingsConnectiongroupedAggregatesArgs = {
  groupBy: Array<ReviewedAuditFindingGroupBy>;
  having?: InputMaybe<ReviewedAuditFindingHavingInput>;
};

/** A `ReviewedAuditFinding` edge in the connection. */
export type ReviewedAuditFindingsEdge = {
  __typename?: "ReviewedAuditFindingsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `ReviewedAuditFinding` at the end of the edge. */
  node?: Maybe<ReviewedAuditFinding>;
};

/** Methods to use when ordering `ReviewedAuditFinding`. */
export enum ReviewedAuditFindingsOrderBy {
  ACCEPTED_ASC = "ACCEPTED_ASC",
  ACCEPTED_DESC = "ACCEPTED_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUTHOR_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUTHOR_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUTHOR_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUTHOR_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUTHOR_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUTHOR_ID__USER_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_COMMENT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDITOR_REVIEWED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_JOB_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_RULE_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_FINDING_SEED_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUDIT_TYPE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTHOR_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__AUTODOR_FINDING_S3_KEY_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__BATCH_CLAIM_LINE_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CONFIDENCE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__CREATED_AT_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_COST_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_REASON_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__IMPROPER_PAYMENT_UNITS_CHARGED_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_AOC_PRIMARY_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_CORRECT_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_EM_ORIGINAL_LEVEL_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_AJUDICATION_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_MUE_OHP_MUE_VALUE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_CODE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_NCCI_PTP_MODIFIER_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_PRICING_CORRECT_PRICE_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__META_DATA_UNITS_CORRECT_UNITS_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__NEEDS_REVIEW_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__PREVIOUS_VERSION_ID_DESC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_ASC",
  AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC = "AUDIT_FINDING_BY_AUDIT_FINDING_ID__UPDATED_AT_DESC",
  AUDIT_FINDING_ID_ASC = "AUDIT_FINDING_ID_ASC",
  AUDIT_FINDING_ID_DESC = "AUDIT_FINDING_ID_DESC",
  AUTHOR_ID_ASC = "AUTHOR_ID_ASC",
  AUTHOR_ID_DESC = "AUTHOR_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DELETED_ASC = "DELETED_ASC",
  DELETED_DESC = "DELETED_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  RATIONALE_ASC = "RATIONALE_ASC",
  RATIONALE_DESC = "RATIONALE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

/** ##Overview cache output of the objects in alaffia-audit-documentation S3 Bucket./n/n Foreign server table (s3.audit_documentation) reading from pg data wrapper written in steampipe./n/nSteampipe code parses the S3 path convention into the columns in this table */
export type S3AuditDocumentation = Node & {
  __typename?: "S3AuditDocumentation";
  /** Reads a single `BatchClaim` that is related to this `S3AuditDocumentation`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  etag?: Maybe<Scalars["String"]["output"]>;
  filename: Scalars["String"]["output"];
  /** Reads and enables pagination through a set of `HumanProcessingRequest`. */
  humanProcessingRequests: HumanProcessingRequestsConnection;
  icn: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  isUploaded: Scalars["Boolean"]["output"];
  lastModified?: Maybe<Scalars["Datetime"]["output"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  originalFileId?: Maybe<Scalars["UUID"]["output"]>;
  process?: Maybe<Scalars["String"]["output"]>;
  providerId: Scalars["String"]["output"];
  s3Key: Scalars["String"]["output"];
  size: Scalars["BigInt"]["output"];
  subFile?: Maybe<Scalars["String"]["output"]>;
  subProcess?: Maybe<Scalars["String"]["output"]>;
  tags?: Maybe<Scalars["JSON"]["output"]>;
};

/** ##Overview cache output of the objects in alaffia-audit-documentation S3 Bucket./n/n Foreign server table (s3.audit_documentation) reading from pg data wrapper written in steampipe./n/nSteampipe code parses the S3 path convention into the columns in this table */
export type S3AuditDocumentationhumanProcessingRequestsArgs = {
  after?: InputMaybe<Scalars["Cursor"]["input"]>;
  before?: InputMaybe<Scalars["Cursor"]["input"]>;
  condition?: InputMaybe<HumanProcessingRequestCondition>;
  filter?: InputMaybe<HumanProcessingRequestFilter>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  orderBy?: InputMaybe<Array<HumanProcessingRequestsOrderBy>>;
};

export type S3AuditDocumentationAggregates = {
  __typename?: "S3AuditDocumentationAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<S3AuditDocumentationAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<S3AuditDocumentationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<S3AuditDocumentationMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<S3AuditDocumentationMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<S3AuditDocumentationStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<S3AuditDocumentationStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<S3AuditDocumentationSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<S3AuditDocumentationVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<S3AuditDocumentationVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `S3AuditDocumentation` object types. */
export type S3AuditDocumentationAggregatesFilter = {
  /** Mean average aggregate over matching `S3AuditDocumentation` objects. */
  average?: InputMaybe<S3AuditDocumentationAverageAggregateFilter>;
  /** Distinct count aggregate over matching `S3AuditDocumentation` objects. */
  distinctCount?: InputMaybe<S3AuditDocumentationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `S3AuditDocumentation` object to be included within the aggregate. */
  filter?: InputMaybe<S3AuditDocumentationFilter>;
  /** Maximum aggregate over matching `S3AuditDocumentation` objects. */
  max?: InputMaybe<S3AuditDocumentationMaxAggregateFilter>;
  /** Minimum aggregate over matching `S3AuditDocumentation` objects. */
  min?: InputMaybe<S3AuditDocumentationMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `S3AuditDocumentation` objects. */
  stddevPopulation?: InputMaybe<S3AuditDocumentationStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `S3AuditDocumentation` objects. */
  stddevSample?: InputMaybe<S3AuditDocumentationStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `S3AuditDocumentation` objects. */
  sum?: InputMaybe<S3AuditDocumentationSumAggregateFilter>;
  /** Population variance aggregate over matching `S3AuditDocumentation` objects. */
  variancePopulation?: InputMaybe<S3AuditDocumentationVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `S3AuditDocumentation` objects. */
  varianceSample?: InputMaybe<S3AuditDocumentationVarianceSampleAggregateFilter>;
};

export type S3AuditDocumentationAverageAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationAverageAggregates = {
  __typename?: "S3AuditDocumentationAverageAggregates";
  /** Mean average of size across the matching connection */
  size?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** Input for the nested mutation of `batchClaim` in the `S3AuditDocumentationInput` mutation. */
export type S3AuditDocumentationBatchClaimIdFkeyInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<S3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `s3AuditDocumentation` in the `BatchClaimInput` mutation. */
export type S3AuditDocumentationBatchClaimIdFkeyInverseInput = {
  /** The primary key(s) for `s3AuditDocumentation` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<S3AuditDocumentationS3AuditDocumentationPkeyConnect>
  >;
  /** The primary key(s) for `s3AuditDocumentation` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<S3AuditDocumentationNodeIdConnect>>;
  /** A `S3AuditDocumentationInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<S3AuditDocumentationBatchClaimIdFkeyS3AuditDocumentationCreateInput>
  >;
  /** The primary key(s) and patch data for `s3AuditDocumentation` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<S3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingS3AuditDocumentationPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `s3AuditDocumentation` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyNodeIdUpdate>
  >;
};

/** The `s3AuditDocumentation` to be created by this mutation. */
export type S3AuditDocumentationBatchClaimIdFkeyS3AuditDocumentationCreateInput =
  {
    batchClaimToBatchClaimId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInput>;
    etag?: InputMaybe<Scalars["String"]["input"]>;
    filename: Scalars["String"]["input"];
    humanProcessingRequestsUsingId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput>;
    icn: Scalars["String"]["input"];
    id: Scalars["UUID"]["input"];
    isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
    originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    process?: InputMaybe<Scalars["String"]["input"]>;
    providerId: Scalars["String"]["input"];
    s3Key: Scalars["String"]["input"];
    size: Scalars["BigInt"]["input"];
    subFile?: InputMaybe<Scalars["String"]["input"]>;
    subProcess?: InputMaybe<Scalars["String"]["input"]>;
    tags?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/**
 * A condition to be used against `S3AuditDocumentation` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type S3AuditDocumentationCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `etag` field. */
  etag?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `filename` field. */
  filename?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `icn` field. */
  icn?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `isUploaded` field. */
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Checks for equality with the object’s `lastModified` field. */
  lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `originalFileId` field. */
  originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `process` field. */
  process?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `providerId` field. */
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `s3Key` field. */
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `size` field. */
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  /** Checks for equality with the object’s `subFile` field. */
  subFile?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `subProcess` field. */
  subProcess?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `tags` field. */
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type S3AuditDocumentationDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  etag?: InputMaybe<BigIntFilter>;
  filename?: InputMaybe<BigIntFilter>;
  icn?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  isUploaded?: InputMaybe<BigIntFilter>;
  lastModified?: InputMaybe<BigIntFilter>;
  originalFileId?: InputMaybe<BigIntFilter>;
  process?: InputMaybe<BigIntFilter>;
  providerId?: InputMaybe<BigIntFilter>;
  s3Key?: InputMaybe<BigIntFilter>;
  size?: InputMaybe<BigIntFilter>;
  subFile?: InputMaybe<BigIntFilter>;
  subProcess?: InputMaybe<BigIntFilter>;
  tags?: InputMaybe<BigIntFilter>;
};

export type S3AuditDocumentationDistinctCountAggregates = {
  __typename?: "S3AuditDocumentationDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of etag across the matching connection */
  etag?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of filename across the matching connection */
  filename?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of icn across the matching connection */
  icn?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of isUploaded across the matching connection */
  isUploaded?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of lastModified across the matching connection */
  lastModified?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of originalFileId across the matching connection */
  originalFileId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of process across the matching connection */
  process?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of providerId across the matching connection */
  providerId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of s3Key across the matching connection */
  s3Key?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of size across the matching connection */
  size?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subFile across the matching connection */
  subFile?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of subProcess across the matching connection */
  subProcess?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of tags across the matching connection */
  tags?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `S3AuditDocumentation` object types. All fields are combined with a logical ‘and.’ */
export type S3AuditDocumentationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<S3AuditDocumentationFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `etag` field. */
  etag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `filename` field. */
  filename?: InputMaybe<StringFilter>;
  /** Filter by the object’s `humanProcessingRequests` relation. */
  humanProcessingRequests?: InputMaybe<S3AuditDocumentationToManyHumanProcessingRequestFilter>;
  /** Some related `humanProcessingRequests` exist. */
  humanProcessingRequestsExist?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Filter by the object’s `icn` field. */
  icn?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `isUploaded` field. */
  isUploaded?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastModified` field. */
  lastModified?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<S3AuditDocumentationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<S3AuditDocumentationFilter>>;
  /** Filter by the object’s `originalFileId` field. */
  originalFileId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `process` field. */
  process?: InputMaybe<StringFilter>;
  /** Filter by the object’s `providerId` field. */
  providerId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `s3Key` field. */
  s3Key?: InputMaybe<StringFilter>;
  /** Filter by the object’s `size` field. */
  size?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `subFile` field. */
  subFile?: InputMaybe<StringFilter>;
  /** Filter by the object’s `subProcess` field. */
  subProcess?: InputMaybe<StringFilter>;
  /** Filter by the object’s `tags` field. */
  tags?: InputMaybe<JSONFilter>;
};

/** Grouping methods for `S3AuditDocumentation` for usage during aggregation. */
export enum S3AuditDocumentationGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  ETAG = "ETAG",
  FILENAME = "FILENAME",
  ICN = "ICN",
  IS_UPLOADED = "IS_UPLOADED",
  LAST_MODIFIED = "LAST_MODIFIED",
  LAST_MODIFIED_TRUNCATED_TO_DAY = "LAST_MODIFIED_TRUNCATED_TO_DAY",
  LAST_MODIFIED_TRUNCATED_TO_HOUR = "LAST_MODIFIED_TRUNCATED_TO_HOUR",
  ORIGINAL_FILE_ID = "ORIGINAL_FILE_ID",
  PROCESS = "PROCESS",
  PROVIDER_ID = "PROVIDER_ID",
  S3_KEY = "S3_KEY",
  SIZE = "SIZE",
  SUB_FILE = "SUB_FILE",
  SUB_PROCESS = "SUB_PROCESS",
  TAGS = "TAGS",
}

export type S3AuditDocumentationHavingAverageInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingDistinctCountInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

/** Conditions for `S3AuditDocumentation` aggregates. */
export type S3AuditDocumentationHavingInput = {
  AND?: InputMaybe<Array<S3AuditDocumentationHavingInput>>;
  OR?: InputMaybe<Array<S3AuditDocumentationHavingInput>>;
  average?: InputMaybe<S3AuditDocumentationHavingAverageInput>;
  distinctCount?: InputMaybe<S3AuditDocumentationHavingDistinctCountInput>;
  max?: InputMaybe<S3AuditDocumentationHavingMaxInput>;
  min?: InputMaybe<S3AuditDocumentationHavingMinInput>;
  stddevPopulation?: InputMaybe<S3AuditDocumentationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<S3AuditDocumentationHavingStddevSampleInput>;
  sum?: InputMaybe<S3AuditDocumentationHavingSumInput>;
  variancePopulation?: InputMaybe<S3AuditDocumentationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<S3AuditDocumentationHavingVarianceSampleInput>;
};

export type S3AuditDocumentationHavingMaxInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingMinInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingStddevPopulationInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingStddevSampleInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingSumInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingVariancePopulationInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

export type S3AuditDocumentationHavingVarianceSampleInput = {
  lastModified?: InputMaybe<HavingDatetimeFilter>;
  size?: InputMaybe<HavingBigintFilter>;
};

/** An input for mutations affecting `S3AuditDocumentation` */
export type S3AuditDocumentationInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInput>;
  etag?: InputMaybe<Scalars["String"]["input"]>;
  filename: Scalars["String"]["input"];
  humanProcessingRequestsUsingId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput>;
  icn: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
  originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  process?: InputMaybe<Scalars["String"]["input"]>;
  providerId: Scalars["String"]["input"];
  s3Key: Scalars["String"]["input"];
  size: Scalars["BigInt"]["input"];
  subFile?: InputMaybe<Scalars["String"]["input"]>;
  subProcess?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type S3AuditDocumentationMaxAggregateFilter = {
  size?: InputMaybe<BigIntFilter>;
};

export type S3AuditDocumentationMaxAggregates = {
  __typename?: "S3AuditDocumentationMaxAggregates";
  /** Maximum of size across the matching connection */
  size?: Maybe<Scalars["BigInt"]["output"]>;
};

export type S3AuditDocumentationMinAggregateFilter = {
  size?: InputMaybe<BigIntFilter>;
};

export type S3AuditDocumentationMinAggregates = {
  __typename?: "S3AuditDocumentationMinAggregates";
  /** Minimum of size across the matching connection */
  size?: Maybe<Scalars["BigInt"]["output"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type S3AuditDocumentationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `s3AuditDocumentation` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type S3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `humanProcessingRequest` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `humanProcessingRequest` being updated. */
    patch: HumanProcessingRequestPatch;
  };

/** The fields on `s3AuditDocumentation` to look up the row to update. */
export type S3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyUsingS3AuditDocumentationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
    patch: updateS3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type S3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `s3AuditDocumentation` to look up the row to update. */
export type S3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyUsingS3AuditDocumentationPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
    patch: updateS3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyPatch;
  };

/** Represents an update to a `S3AuditDocumentation`. Fields that are set will be updated. */
export type S3AuditDocumentationPatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInput>;
  etag?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  humanProcessingRequestsUsingId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput>;
  icn?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
  originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  process?: InputMaybe<Scalars["String"]["input"]>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["BigInt"]["input"]>;
  subFile?: InputMaybe<Scalars["String"]["input"]>;
  subProcess?: InputMaybe<Scalars["String"]["input"]>;
  tags?: InputMaybe<Scalars["JSON"]["input"]>;
};

/** The fields on `s3AuditDocumentation` to look up the row to connect. */
export type S3AuditDocumentationS3AuditDocumentationPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

export type S3AuditDocumentationStddevPopulationAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationStddevPopulationAggregates = {
  __typename?: "S3AuditDocumentationStddevPopulationAggregates";
  /** Population standard deviation of size across the matching connection */
  size?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type S3AuditDocumentationStddevSampleAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationStddevSampleAggregates = {
  __typename?: "S3AuditDocumentationStddevSampleAggregates";
  /** Sample standard deviation of size across the matching connection */
  size?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type S3AuditDocumentationSumAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationSumAggregates = {
  __typename?: "S3AuditDocumentationSumAggregates";
  /** Sum of size across the matching connection */
  size: Scalars["BigFloat"]["output"];
};

/** A filter to be used against many `HumanProcessingRequest` object types. All fields are combined with a logical ‘and.’ */
export type S3AuditDocumentationToManyHumanProcessingRequestFilter = {
  /** Aggregates across related `HumanProcessingRequest` match the filter criteria. */
  aggregates?: InputMaybe<HumanProcessingRequestAggregatesFilter>;
  /** Every related `HumanProcessingRequest` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<HumanProcessingRequestFilter>;
  /** No related `HumanProcessingRequest` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<HumanProcessingRequestFilter>;
  /** Some related `HumanProcessingRequest` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<HumanProcessingRequestFilter>;
};

export type S3AuditDocumentationVariancePopulationAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationVariancePopulationAggregates = {
  __typename?: "S3AuditDocumentationVariancePopulationAggregates";
  /** Population variance of size across the matching connection */
  size?: Maybe<Scalars["BigFloat"]["output"]>;
};

export type S3AuditDocumentationVarianceSampleAggregateFilter = {
  size?: InputMaybe<BigFloatFilter>;
};

export type S3AuditDocumentationVarianceSampleAggregates = {
  __typename?: "S3AuditDocumentationVarianceSampleAggregates";
  /** Sample variance of size across the matching connection */
  size?: Maybe<Scalars["BigFloat"]["output"]>;
};

/** A connection to a list of `S3AuditDocumentation` values. */
export type S3AuditDocumentationsConnection = {
  __typename?: "S3AuditDocumentationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<S3AuditDocumentationAggregates>;
  /** A list of edges which contains the `S3AuditDocumentation` and cursor to aid in pagination. */
  edges: Array<S3AuditDocumentationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<S3AuditDocumentationAggregates>>;
  /** A list of `S3AuditDocumentation` objects. */
  nodes: Array<Maybe<S3AuditDocumentation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `S3AuditDocumentation` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `S3AuditDocumentation` values. */
export type S3AuditDocumentationsConnectiongroupedAggregatesArgs = {
  groupBy: Array<S3AuditDocumentationGroupBy>;
  having?: InputMaybe<S3AuditDocumentationHavingInput>;
};

/** A `S3AuditDocumentation` edge in the connection. */
export type S3AuditDocumentationsEdge = {
  __typename?: "S3AuditDocumentationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `S3AuditDocumentation` at the end of the edge. */
  node?: Maybe<S3AuditDocumentation>;
};

/** Methods to use when ordering `S3AuditDocumentation`. */
export enum S3AuditDocumentationsOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  ETAG_ASC = "ETAG_ASC",
  ETAG_DESC = "ETAG_DESC",
  FILENAME_ASC = "FILENAME_ASC",
  FILENAME_DESC = "FILENAME_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_ID_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_ID_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_AVERAGE_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_AVERAGE_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_BY_S3_AUDIT_DOCUMENTATION_ID__COUNT_ASC = "HUMAN_PROCESSING_REQUESTS_BY_S3_AUDIT_DOCUMENTATION_ID__COUNT_ASC",
  HUMAN_PROCESSING_REQUESTS_BY_S3_AUDIT_DOCUMENTATION_ID__COUNT_DESC = "HUMAN_PROCESSING_REQUESTS_BY_S3_AUDIT_DOCUMENTATION_ID__COUNT_DESC",
  HUMAN_PROCESSING_REQUESTS_COUNT_ASC = "HUMAN_PROCESSING_REQUESTS_COUNT_ASC",
  HUMAN_PROCESSING_REQUESTS_COUNT_DESC = "HUMAN_PROCESSING_REQUESTS_COUNT_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ID_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ID_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_DISTINCT_COUNT_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_MAX_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_MAX_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_MAX_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_MAX_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_MIN_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_MIN_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_MIN_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_MIN_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_POPULATION_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_STDDEV_SAMPLE_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_ID_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_ID_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_SUM_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_SUM_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_SUM_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_SUM_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_POPULATION_USERNAME_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_CREATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_CREATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ERROR_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ERROR_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ERROR_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ERROR_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_EXTRA_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_EXTRA_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_EXTRA_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_EXTRA_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PRIORITY_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PRIORITY_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PRIORITY_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PRIORITY_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PULLED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PULLED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PULLED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_PULLED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEST_TYPE_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEST_TYPE_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEST_TYPE_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEST_TYPE_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEUED_DATES_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEUED_DATES_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEUED_DATES_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_REQUEUED_DATES_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_RUN_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_RUN_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_RUN_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_RUN_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_S3_AUDIT_DOCUMENTATION_ID_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_STATUS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_STATUS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_STATUS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_STATUS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_SUCCESS_DETAILS_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_SUCCESS_DETAILS_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_SUCCESS_DETAILS_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_SUCCESS_DETAILS_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_UPDATED_AT_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_UPDATED_AT_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_USERNAME_ASC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_USERNAME_ASC",
  HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_USERNAME_DESC = "HUMAN_PROCESSING_REQUESTS_VARIANCE_SAMPLE_USERNAME_DESC",
  ICN_ASC = "ICN_ASC",
  ICN_DESC = "ICN_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  IS_UPLOADED_ASC = "IS_UPLOADED_ASC",
  IS_UPLOADED_DESC = "IS_UPLOADED_DESC",
  LAST_MODIFIED_ASC = "LAST_MODIFIED_ASC",
  LAST_MODIFIED_DESC = "LAST_MODIFIED_DESC",
  NATURAL = "NATURAL",
  ORIGINAL_FILE_ID_ASC = "ORIGINAL_FILE_ID_ASC",
  ORIGINAL_FILE_ID_DESC = "ORIGINAL_FILE_ID_DESC",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  PROCESS_ASC = "PROCESS_ASC",
  PROCESS_DESC = "PROCESS_DESC",
  PROVIDER_ID_ASC = "PROVIDER_ID_ASC",
  PROVIDER_ID_DESC = "PROVIDER_ID_DESC",
  S3_KEY_ASC = "S3_KEY_ASC",
  S3_KEY_DESC = "S3_KEY_DESC",
  SIZE_ASC = "SIZE_ASC",
  SIZE_DESC = "SIZE_DESC",
  SUB_FILE_ASC = "SUB_FILE_ASC",
  SUB_FILE_DESC = "SUB_FILE_DESC",
  SUB_PROCESS_ASC = "SUB_PROCESS_ASC",
  SUB_PROCESS_DESC = "SUB_PROCESS_DESC",
  TAGS_ASC = "TAGS_ASC",
  TAGS_DESC = "TAGS_DESC",
}

export type S3CreateObjectHandlerResponse = {
  __typename?: "S3CreateObjectHandlerResponse";
  documentUploadNotification?: Maybe<NotificationResponse>;
  error?: Maybe<Scalars["String"]["output"]>;
  icn?: Maybe<Scalars["String"]["output"]>;
  providerId?: Maybe<Scalars["String"]["output"]>;
  s3Key: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type S3DataImportInput = {
  columnMap: Scalars["String"]["input"];
  isTest?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind: S3DataImportKind;
  prefix: Scalars["String"]["input"];
};

export enum S3DataImportKind {
  CLAIM = "CLAIM",
  MUE = "MUE",
  PTP = "PTP",
}

export type SearchResult = {
  __typename?: "SearchResult";
  confidence: Scalars["Float"]["output"];
  fileId: Scalars["UUID"]["output"];
  id: Scalars["UUID"]["output"];
  pageNumber: Scalars["Int"]["output"];
  results: Scalars["Int"]["output"];
  textMatched: Scalars["String"]["output"];
};

/** Input for the nested mutation of `messageSent` in the `MessageSentInput` mutation. */
export type SeedMessageSentIdMessageSentIdFkInput = {
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectById?: InputMaybe<MessageSentMessageSentPkeyConnect>;
  /** The primary key(s) for `messageSent` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageSentNodeIdConnect>;
  /** A `MessageSentInput` object that will be created and connected to this object. */
  create?: InputMaybe<SeedMessageSentIdMessageSentIdFkMessageSentCreateInput>;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateById?: InputMaybe<MessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkUsingMessageSentPkeyUpdate>;
  /** The primary key(s) and patch data for `messageSent` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<MessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkNodeIdUpdate>;
};

/** The `messageSent` to be created by this mutation. */
export type SeedMessageSentIdMessageSentIdFkMessageSentCreateInput = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  emailTemplate?: InputMaybe<MessageSentInputEmailTemplateType>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  sendEmail?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum SeedType {
  IB_CLAIM_LINE = "IB_CLAIM_LINE",
  UB_CLAIM_LINE = "UB_CLAIM_LINE",
}

export type SendDocumentUploadNoficationInput = {
  documentUploadCreatedId: Scalars["UUID"]["input"];
  documentUploadKind: DocumentUploadKind;
  senderUserId: Scalars["UUID"]["input"];
};

export type SendUserOnboardNotificationInput = {
  emailTemplate: MessageSentInputEmailTemplateType;
  userId: Scalars["UUID"]["input"];
};

/** All input for the `setAssignees` mutation. */
export type SetAssigneesInput = {
  auditorUserId: Scalars["UUID"]["input"];
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  unassigned?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** The output of our `setAssignees` mutation. */
export type SetAssigneesPayload = {
  __typename?: "SetAssigneesPayload";
  batchClaimAssignees?: Maybe<Array<Maybe<BatchClaimAssignee>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setClientSignOffClaimStates` mutation. */
export type SetClientSignOffClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  clientSignOffState: ClientSignOffClaimStateEnum;
};

/** The output of our `setClientSignOffClaimStates` mutation. */
export type SetClientSignOffClaimStatesPayload = {
  __typename?: "SetClientSignOffClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  clientSignOffClaimStates?: Maybe<Array<Maybe<ClientSignOffClaimState>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setDueDates` mutation. */
export type SetDueDatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  dueDates: Array<InputMaybe<Scalars["Datetime"]["input"]>>;
};

/** The output of our `setDueDates` mutation. */
export type SetDueDatesPayload = {
  __typename?: "SetDueDatesPayload";
  batchClaimDueDates?: Maybe<Array<Maybe<BatchClaimDueDate>>>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setInvoiceClaimStates` mutation. */
export type SetInvoiceClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  invoiceState: InvoiceClaimStateEnum;
};

/** The output of our `setInvoiceClaimStates` mutation. */
export type SetInvoiceClaimStatesPayload = {
  __typename?: "SetInvoiceClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  invoiceClaimStates?: Maybe<Array<Maybe<InvoiceClaimState>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setNegotiationClaimStates` mutation. */
export type SetNegotiationClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  negotiationState: NegotiationClaimStateEnum;
};

/** The output of our `setNegotiationClaimStates` mutation. */
export type SetNegotiationClaimStatesPayload = {
  __typename?: "SetNegotiationClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  negotiationClaimStates?: Maybe<Array<Maybe<NegotiationClaimState>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setOnHoldClaimStates` mutation. */
export type SetOnHoldClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  onHoldState: OnHoldClaimStateEnum;
};

/** The output of our `setOnHoldClaimStates` mutation. */
export type SetOnHoldClaimStatesPayload = {
  __typename?: "SetOnHoldClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  onHoldClaimStates?: Maybe<Array<Maybe<OnHoldClaimState>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setProviderSignOffClaimStates` mutation. */
export type SetProviderSignOffClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffState: ProviderSignOffClaimStateEnum;
};

/** The output of our `setProviderSignOffClaimStates` mutation. */
export type SetProviderSignOffClaimStatesPayload = {
  __typename?: "SetProviderSignOffClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  providerSignOffClaimStates?: Maybe<Array<Maybe<ProviderSignOffClaimState>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setWorkflowClaimStates` mutation. */
export type SetWorkflowClaimStatesInput = {
  batchClaimIds: Array<InputMaybe<Scalars["UUID"]["input"]>>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  workflowState: WorkflowClaimStateEnum;
};

/** The output of our `setWorkflowClaimStates` mutation. */
export type SetWorkflowClaimStatesPayload = {
  __typename?: "SetWorkflowClaimStatesPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  workflowClaimStates?: Maybe<Array<Maybe<WorkflowClaimState>>>;
};

export type SignedDownloadUrl = {
  __typename?: "SignedDownloadUrl";
  fileId: Scalars["ID"]["output"];
  url: Scalars["String"]["output"];
};

export type SignedUploadUrl = {
  __typename?: "SignedUploadUrl";
  fields: Scalars["JSON"]["output"];
  url: Scalars["String"]["output"];
};

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]["input"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]["input"]>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]["input"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]["input"]>;
};

export type SupportingDocumentation = {
  __typename?: "SupportingDocumentation";
  signedUpload: PrepareFileForUploadResult;
};

export type SupportingDocumentationsignedUploadArgs = {
  input: SupportingDocumentationUploadInput;
};

export type SupportingDocumentationUploadInput = {
  documentUploadKind: DocumentUploadKind;
  prepareFileForUploadInput: PrepareFileForUploadInput;
};

export type Thread = {
  __typename?: "Thread";
  case: Case;
  createdAt: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  messages: Array<Message>;
  name: Scalars["String"]["output"];
};

export type ThreadsInput = {
  caseId?: InputMaybe<Scalars["ID"]["input"]>;
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UUIDFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["UUID"]["input"]>>;
};

/** A filter to be used against UUID List fields. All fields are combined with a logical ‘and.’ */
export type UUIDListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<
    Array<InputMaybe<Scalars["UUID"]["input"]>>
  >;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["UUID"]["input"]>>>;
};

/** A `String` edge in the connection. */
export type UniqueProcedureCodeEdge = {
  __typename?: "UniqueProcedureCodeEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `String` at the end of the edge. */
  node?: Maybe<Scalars["String"]["output"]>;
};

/** A connection to a list of `String` values. */
export type UniqueProcedureCodesConnection = {
  __typename?: "UniqueProcedureCodesConnection";
  /** A list of edges which contains the `String` and cursor to aid in pagination. */
  edges: Array<UniqueProcedureCodeEdge>;
  /** A list of `String` objects. */
  nodes: Array<Maybe<Scalars["String"]["output"]>>;
  /** The count of *all* `String` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A `String` edge in the connection. */
export type UniqueRevCodeEdge = {
  __typename?: "UniqueRevCodeEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `String` at the end of the edge. */
  node?: Maybe<Scalars["String"]["output"]>;
};

/** A connection to a list of `String` values. */
export type UniqueRevCodesConnection = {
  __typename?: "UniqueRevCodesConnection";
  /** A list of edges which contains the `String` and cursor to aid in pagination. */
  edges: Array<UniqueRevCodeEdge>;
  /** A list of `String` objects. */
  nodes: Array<Maybe<Scalars["String"]["output"]>>;
  /** The count of *all* `String` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

export type UpdateAskAutodorQueueInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the `updateAuditFindingByNodeId` mutation. */
export type UpdateAuditFindingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditFinding` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `AuditFinding` being updated. */
  patch: AuditFindingPatch;
};

/** All input for the `updateAuditFinding` mutation. */
export type UpdateAuditFindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `AuditFinding` being updated. */
  patch: AuditFindingPatch;
};

/** The output of our update `AuditFinding` mutation. */
export type UpdateAuditFindingPayload = {
  __typename?: "UpdateAuditFindingPayload";
  /** The `AuditFinding` that was updated by this mutation. */
  auditFinding?: Maybe<AuditFinding>;
  /** An edge for our `AuditFinding`. May be used by Relay 1. */
  auditFindingEdge?: Maybe<AuditFindingsEdge>;
  /** Reads a single `AuditFindingJob` that is related to this `AuditFinding`. */
  auditFindingJob?: Maybe<AuditFindingJob>;
  /** Reads a single `AuditFindingRuleType` that is related to this `AuditFinding`. */
  auditFindingRuleTypeByAuditFindingRuleType?: Maybe<AuditFindingRuleType>;
  /** Reads a single `AuditorUser` that is related to this `AuditFinding`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `AuditFinding`. */
  batchClaim?: Maybe<BatchClaim>;
  /** Reads a single `BatchClaimLine` that is related to this `AuditFinding`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `AuditFinding` that is related to this `AuditFinding`. */
  previousVersion?: Maybe<AuditFinding>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AuditFinding` mutation. */
export type UpdateAuditFindingPayloadauditFindingEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditFindingsOrderBy>>;
};

/** All input for the `updateAuditFindingReasonCodeByNodeId` mutation. */
export type UpdateAuditFindingReasonCodeByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditFindingReasonCode` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `AuditFindingReasonCode` being updated. */
  patch: AuditFindingReasonCodePatch;
};

/** All input for the `updateAuditFindingReasonCode` mutation. */
export type UpdateAuditFindingReasonCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `AuditFindingReasonCode` being updated. */
  patch: AuditFindingReasonCodePatch;
};

/** The output of our update `AuditFindingReasonCode` mutation. */
export type UpdateAuditFindingReasonCodePayload = {
  __typename?: "UpdateAuditFindingReasonCodePayload";
  /** Reads a single `AuditFinding` that is related to this `AuditFindingReasonCode`. */
  auditFinding?: Maybe<AuditFinding>;
  /** The `AuditFindingReasonCode` that was updated by this mutation. */
  auditFindingReasonCode?: Maybe<AuditFindingReasonCode>;
  /** An edge for our `AuditFindingReasonCode`. May be used by Relay 1. */
  auditFindingReasonCodeEdge?: Maybe<AuditFindingReasonCodesEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditFindingReasonCode`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReasonCode` that is related to this `AuditFindingReasonCode`. */
  reasonCode?: Maybe<ReasonCode>;
};

/** The output of our update `AuditFindingReasonCode` mutation. */
export type UpdateAuditFindingReasonCodePayloadauditFindingReasonCodeEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuditFindingReasonCodesOrderBy>>;
  };

/** All input for the `updateAuditorBucketByNodeId` mutation. */
export type UpdateAuditorBucketByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditorBucket` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `AuditorBucket` being updated. */
  patch: AuditorBucketPatch;
};

/** All input for the `updateAuditorBucket` mutation. */
export type UpdateAuditorBucketInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `AuditorBucket` being updated. */
  patch: AuditorBucketPatch;
};

/** The output of our update `AuditorBucket` mutation. */
export type UpdateAuditorBucketPayload = {
  __typename?: "UpdateAuditorBucketPayload";
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  assignee?: Maybe<AuditorUser>;
  /** The `AuditorBucket` that was updated by this mutation. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** An edge for our `AuditorBucket`. May be used by Relay 1. */
  auditorBucketEdge?: Maybe<AuditorBucketsEdge>;
  /** Reads a single `AuditorUser` that is related to this `AuditorBucket`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Insurer` that is related to this `AuditorBucket`. */
  insurer?: Maybe<Insurer>;
  /** Reads a single `Provider` that is related to this `AuditorBucket`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AuditorBucket` mutation. */
export type UpdateAuditorBucketPayloadauditorBucketEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorBucketsOrderBy>>;
};

/** All input for the `updateAuditorUserByEmail` mutation. */
export type UpdateAuditorUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  email: Scalars["String"]["input"];
  /** An object where the defined keys will be set on the `AuditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** All input for the `updateAuditorUserByNodeId` mutation. */
export type UpdateAuditorUserByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `AuditorUser` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `AuditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** All input for the `updateAuditorUser` mutation. */
export type UpdateAuditorUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `AuditorUser` being updated. */
  patch: AuditorUserPatch;
};

/** The output of our update `AuditorUser` mutation. */
export type UpdateAuditorUserPayload = {
  __typename?: "UpdateAuditorUserPayload";
  /** The `AuditorUser` that was updated by this mutation. */
  auditorUser?: Maybe<AuditorUser>;
  /** An edge for our `AuditorUser`. May be used by Relay 1. */
  auditorUserEdge?: Maybe<AuditorUsersEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Provider` that is related to this `AuditorUser`. */
  provider?: Maybe<Provider>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AuditorUser` mutation. */
export type UpdateAuditorUserPayloadauditorUserEdgeArgs = {
  orderBy?: InputMaybe<Array<AuditorUsersOrderBy>>;
};

/** All input for the `updateBatchClaimLineItemByNodeId` mutation. */
export type UpdateBatchClaimLineItemByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `BatchClaimLineItem` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `BatchClaimLineItem` being updated. */
  patch: BatchClaimLineItemPatch;
};

/** All input for the `updateBatchClaimLineItem` mutation. */
export type UpdateBatchClaimLineItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `BatchClaimLineItem` being updated. */
  patch: BatchClaimLineItemPatch;
};

/** All input for the `updateBatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineId` mutation. */
export type UpdateBatchClaimLineItemNnIbLineByBatchClaimLineItemIdAndNnIbLineIdInput =
  {
    batchClaimLineItemId: Scalars["UUID"]["input"];
    /**
     * An arbitrary string value with no semantic meaning. Will be included in the
     * payload verbatim. May be used to track mutations by the client.
     */
    clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
    nnIbLineId: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `BatchClaimLineItemNnIbLine` being updated. */
    patch: BatchClaimLineItemNnIbLinePatch;
  };

/** All input for the `updateBatchClaimLineItemNnIbLineByNodeId` mutation. */
export type UpdateBatchClaimLineItemNnIbLineByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `BatchClaimLineItemNnIbLine` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `BatchClaimLineItemNnIbLine` being updated. */
  patch: BatchClaimLineItemNnIbLinePatch;
};

/** All input for the `updateBatchClaimLineItemNnIbLine` mutation. */
export type UpdateBatchClaimLineItemNnIbLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `BatchClaimLineItemNnIbLine` being updated. */
  patch: BatchClaimLineItemNnIbLinePatch;
};

/** The output of our update `BatchClaimLineItemNnIbLine` mutation. */
export type UpdateBatchClaimLineItemNnIbLinePayload = {
  __typename?: "UpdateBatchClaimLineItemNnIbLinePayload";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItemNnIbLine`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaimLineItem` that is related to this `BatchClaimLineItemNnIbLine`. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  /** The `BatchClaimLineItemNnIbLine` that was updated by this mutation. */
  batchClaimLineItemNnIbLine?: Maybe<BatchClaimLineItemNnIbLine>;
  /** An edge for our `BatchClaimLineItemNnIbLine`. May be used by Relay 1. */
  batchClaimLineItemNnIbLineEdge?: Maybe<BatchClaimLineItemNnIbLinesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `NnIbLine` that is related to this `BatchClaimLineItemNnIbLine`. */
  nnIbLine?: Maybe<NnIbLine>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `BatchClaimLineItemNnIbLine` mutation. */
export type UpdateBatchClaimLineItemNnIbLinePayloadbatchClaimLineItemNnIbLineEdgeArgs =
  {
    orderBy?: InputMaybe<Array<BatchClaimLineItemNnIbLinesOrderBy>>;
  };

/** The output of our update `BatchClaimLineItem` mutation. */
export type UpdateBatchClaimLineItemPayload = {
  __typename?: "UpdateBatchClaimLineItemPayload";
  /** Reads a single `AuditorUser` that is related to this `BatchClaimLineItem`. */
  author?: Maybe<AuditorUser>;
  /** Reads a single `BatchClaim` that is related to this `BatchClaimLineItem`. */
  batchClaim?: Maybe<BatchClaim>;
  /** Reads a single `BatchClaimLine` that is related to this `BatchClaimLineItem`. */
  batchClaimLine?: Maybe<BatchClaimLine>;
  /** The `BatchClaimLineItem` that was updated by this mutation. */
  batchClaimLineItem?: Maybe<BatchClaimLineItem>;
  /** An edge for our `BatchClaimLineItem`. May be used by Relay 1. */
  batchClaimLineItemEdge?: Maybe<BatchClaimLineItemsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `BatchClaimLineItem` mutation. */
export type UpdateBatchClaimLineItemPayloadbatchClaimLineItemEdgeArgs = {
  orderBy?: InputMaybe<Array<BatchClaimLineItemsOrderBy>>;
};

/** All input for the `updateBclComputationsFromComputationStrategy` mutation. */
export type UpdateBclComputationsFromComputationStrategyInput = {
  bcl?: InputMaybe<BatchClaimLineInput>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `updateBclComputationsFromComputationStrategy` mutation. */
export type UpdateBclComputationsFromComputationStrategyPayload = {
  __typename?: "UpdateBclComputationsFromComputationStrategyPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBcliComputationsFromComputationStrategy` mutation. */
export type UpdateBcliComputationsFromComputationStrategyInput = {
  bcli?: InputMaybe<BatchClaimLineItemInput>;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
};

/** The output of our `updateBcliComputationsFromComputationStrategy` mutation. */
export type UpdateBcliComputationsFromComputationStrategyPayload = {
  __typename?: "UpdateBcliComputationsFromComputationStrategyPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `updateBucketClaimByBatchClaimIdAndAuditorBucketId` mutation. */
export type UpdateBucketClaimByBatchClaimIdAndAuditorBucketIdInput = {
  auditorBucketId: Scalars["UUID"]["input"];
  batchClaimId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `BucketClaim` being updated. */
  patch: BucketClaimPatch;
};

/** All input for the `updateBucketClaimByBatchClaimId` mutation. */
export type UpdateBucketClaimByBatchClaimIdInput = {
  batchClaimId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `BucketClaim` being updated. */
  patch: BucketClaimPatch;
};

/** All input for the `updateBucketClaimByNodeId` mutation. */
export type UpdateBucketClaimByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `BucketClaim` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `BucketClaim` being updated. */
  patch: BucketClaimPatch;
};

/** All input for the `updateBucketClaim` mutation. */
export type UpdateBucketClaimInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `BucketClaim` being updated. */
  patch: BucketClaimPatch;
};

/** The output of our update `BucketClaim` mutation. */
export type UpdateBucketClaimPayload = {
  __typename?: "UpdateBucketClaimPayload";
  /** Reads a single `AuditorBucket` that is related to this `BucketClaim`. */
  auditorBucket?: Maybe<AuditorBucket>;
  /** Reads a single `BatchClaim` that is related to this `BucketClaim`. */
  batchClaim?: Maybe<BatchClaim>;
  /** The `BucketClaim` that was updated by this mutation. */
  bucketClaim?: Maybe<BucketClaim>;
  /** An edge for our `BucketClaim`. May be used by Relay 1. */
  bucketClaimEdge?: Maybe<BucketClaimsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `BucketClaim` mutation. */
export type UpdateBucketClaimPayloadbucketClaimEdgeArgs = {
  orderBy?: InputMaybe<Array<BucketClaimsOrderBy>>;
};

export type UpdateCaseInput = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  fileIds?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  queueId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateDirectoryInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentDirectoryId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UpdateFileInput = {
  parentDirectoryId?: InputMaybe<Scalars["String"]["input"]>;
};

/** All input for the `updateNnIbLineByFingerprintAndS3Key` mutation. */
export type UpdateNnIbLineByFingerprintAndS3KeyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  fingerprint: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `NnIbLine` being updated. */
  patch: NnIbLinePatch;
  s3Key: Scalars["String"]["input"];
};

/** All input for the `updateNnIbLineByNodeId` mutation. */
export type UpdateNnIbLineByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `NnIbLine` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `NnIbLine` being updated. */
  patch: NnIbLinePatch;
};

/** All input for the `updateNnIbLine` mutation. */
export type UpdateNnIbLineInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `NnIbLine` being updated. */
  patch: NnIbLinePatch;
};

/** The output of our update `NnIbLine` mutation. */
export type UpdateNnIbLinePayload = {
  __typename?: "UpdateNnIbLinePayload";
  /** Reads a single `AuditorUser` that is related to this `NnIbLine`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** The `NnIbLine` that was updated by this mutation. */
  nnIbLine?: Maybe<NnIbLine>;
  /** An edge for our `NnIbLine`. May be used by Relay 1. */
  nnIbLineEdge?: Maybe<NnIbLinesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `NnIbLine` mutation. */
export type UpdateNnIbLinePayloadnnIbLineEdgeArgs = {
  orderBy?: InputMaybe<Array<NnIbLinesOrderBy>>;
};

/** All input for the `updateProviderUserFacilityByNodeId` mutation. */
export type UpdateProviderUserFacilityByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ProviderUserFacility` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `ProviderUserFacility` being updated. */
  patch: ProviderUserFacilityPatch;
};

/** All input for the `updateProviderUserFacilityByProviderIdAndAuditorUserId` mutation. */
export type UpdateProviderUserFacilityByProviderIdAndAuditorUserIdInput = {
  auditorUserId: Scalars["UUID"]["input"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** An object where the defined keys will be set on the `ProviderUserFacility` being updated. */
  patch: ProviderUserFacilityPatch;
  providerId: Scalars["String"]["input"];
};

/** All input for the `updateProviderUserFacility` mutation. */
export type UpdateProviderUserFacilityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `ProviderUserFacility` being updated. */
  patch: ProviderUserFacilityPatch;
};

/** The output of our update `ProviderUserFacility` mutation. */
export type UpdateProviderUserFacilityPayload = {
  __typename?: "UpdateProviderUserFacilityPayload";
  /** Reads a single `AuditorUser` that is related to this `ProviderUserFacility`. */
  auditorUser?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Reads a single `Provider` that is related to this `ProviderUserFacility`. */
  provider?: Maybe<Provider>;
  /** The `ProviderUserFacility` that was updated by this mutation. */
  providerUserFacility?: Maybe<ProviderUserFacility>;
  /** An edge for our `ProviderUserFacility`. May be used by Relay 1. */
  providerUserFacilityEdge?: Maybe<ProviderUserFacilitiesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ProviderUserFacility` mutation. */
export type UpdateProviderUserFacilityPayloadproviderUserFacilityEdgeArgs = {
  orderBy?: InputMaybe<Array<ProviderUserFacilitiesOrderBy>>;
};

/** All input for the `updateQueueByNodeId` mutation. */
export type UpdateQueueByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `Queue` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `Queue` being updated. */
  patch: QueuePatch;
};

/** All input for the `updateQueue` mutation. */
export type UpdateQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `Queue` being updated. */
  patch: QueuePatch;
};

/** The output of our update `Queue` mutation. */
export type UpdateQueuePayload = {
  __typename?: "UpdateQueuePayload";
  /** Reads a single `AuditorUser` that is related to this `Queue`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Queue` that was updated by this mutation. */
  queue?: Maybe<Queue>;
  /** An edge for our `Queue`. May be used by Relay 1. */
  queueEdge?: Maybe<QueuesEdge>;
};

/** The output of our update `Queue` mutation. */
export type UpdateQueuePayloadqueueEdgeArgs = {
  orderBy?: InputMaybe<Array<QueuesOrderBy>>;
};

/** All input for the `updateReviewedAuditFindingByNodeId` mutation. */
export type UpdateReviewedAuditFindingByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `ReviewedAuditFinding` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `ReviewedAuditFinding` being updated. */
  patch: ReviewedAuditFindingPatch;
};

/** All input for the `updateReviewedAuditFinding` mutation. */
export type UpdateReviewedAuditFindingInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `ReviewedAuditFinding` being updated. */
  patch: ReviewedAuditFindingPatch;
};

/** The output of our update `ReviewedAuditFinding` mutation. */
export type UpdateReviewedAuditFindingPayload = {
  __typename?: "UpdateReviewedAuditFindingPayload";
  /** Reads a single `AuditFinding` that is related to this `ReviewedAuditFinding`. */
  auditFinding?: Maybe<AuditFinding>;
  /** Reads a single `AuditorUser` that is related to this `ReviewedAuditFinding`. */
  author?: Maybe<AuditorUser>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `ReviewedAuditFinding` that was updated by this mutation. */
  reviewedAuditFinding?: Maybe<ReviewedAuditFinding>;
  /** An edge for our `ReviewedAuditFinding`. May be used by Relay 1. */
  reviewedAuditFindingEdge?: Maybe<ReviewedAuditFindingsEdge>;
};

/** The output of our update `ReviewedAuditFinding` mutation. */
export type UpdateReviewedAuditFindingPayloadreviewedAuditFindingEdgeArgs = {
  orderBy?: InputMaybe<Array<ReviewedAuditFindingsOrderBy>>;
};

/** All input for the `updateS3AuditDocumentationByNodeId` mutation. */
export type UpdateS3AuditDocumentationByNodeIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  /** The globally unique `ID` which will identify a single `S3AuditDocumentation` to be updated. */
  nodeId: Scalars["ID"]["input"];
  /** An object where the defined keys will be set on the `S3AuditDocumentation` being updated. */
  patch: S3AuditDocumentationPatch;
};

/** All input for the `updateS3AuditDocumentation` mutation. */
export type UpdateS3AuditDocumentationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["UUID"]["input"];
  /** An object where the defined keys will be set on the `S3AuditDocumentation` being updated. */
  patch: S3AuditDocumentationPatch;
};

/** The output of our update `S3AuditDocumentation` mutation. */
export type UpdateS3AuditDocumentationPayload = {
  __typename?: "UpdateS3AuditDocumentationPayload";
  /** Reads a single `BatchClaim` that is related to this `S3AuditDocumentation`. */
  batchClaim?: Maybe<BatchClaim>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]["output"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `S3AuditDocumentation` that was updated by this mutation. */
  s3AuditDocumentation?: Maybe<S3AuditDocumentation>;
  /** An edge for our `S3AuditDocumentation`. May be used by Relay 1. */
  s3AuditDocumentationEdge?: Maybe<S3AuditDocumentationsEdge>;
};

/** The output of our update `S3AuditDocumentation` mutation. */
export type UpdateS3AuditDocumentationPayloads3AuditDocumentationEdgeArgs = {
  orderBy?: InputMaybe<Array<S3AuditDocumentationsOrderBy>>;
};

export type User = {
  __typename?: "User";
  email: Scalars["String"]["output"];
  firstName: Scalars["String"]["output"];
  id: Scalars["UUID"]["output"];
  lastName: Scalars["String"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
  userType: UserType;
};

export type UserCognitoSettings = {
  __typename?: "UserCognitoSettings";
  preferredMfaSetting: MFAOption;
  username: Scalars["String"]["output"];
};

export type UserInviteCreated = Node & {
  __typename?: "UserInviteCreated";
  /** Reads a single `AuditorUser` that is related to this `UserInviteCreated`. */
  auditorUser?: Maybe<AuditorUser>;
  auditorUserId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** Reads a single `MessageCreated` that is related to this `UserInviteCreated`. */
  message?: Maybe<MessageCreated>;
  messageId: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  temporaryPassword: Scalars["String"]["output"];
  updatedAt: Scalars["Datetime"]["output"];
};

export type UserInviteCreatedAggregates = {
  __typename?: "UserInviteCreatedAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserInviteCreatedDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `UserInviteCreated` object types. */
export type UserInviteCreatedAggregatesFilter = {
  /** Distinct count aggregate over matching `UserInviteCreated` objects. */
  distinctCount?: InputMaybe<UserInviteCreatedDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserInviteCreated` object to be included within the aggregate. */
  filter?: InputMaybe<UserInviteCreatedFilter>;
};

/** The `auditorUser` to be created by this mutation. */
export type UserInviteCreatedAuditorUserIdFkeyAuditorUserCreateInput = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email: Scalars["String"]["input"];
  firstName: Scalars["String"]["input"];
  id: Scalars["UUID"]["input"];
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName: Scalars["String"]["input"];
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType: UserTypeEnum;
};

/** Input for the nested mutation of `auditorUser` in the `UserInviteCreatedInput` mutation. */
export type UserInviteCreatedAuditorUserIdFkeyInput = {
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByEmail?: InputMaybe<AuditorUserAuditorUserEmailUniqueKeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectById?: InputMaybe<AuditorUserAuditorUserPkeyConnect>;
  /** The primary key(s) for `auditorUser` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<AuditorUserNodeIdConnect>;
  /** A `AuditorUserInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyAuditorUserCreateInput>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByEmail?: InputMaybe<AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingAuditorUserEmailUniqueKeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateById?: InputMaybe<AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingAuditorUserPkeyUpdate>;
  /** The primary key(s) and patch data for `auditorUser` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `userInviteCreated` in the `AuditorUserInput` mutation. */
export type UserInviteCreatedAuditorUserIdFkeyInverseInput = {
  /** The primary key(s) for `userInviteCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<UserInviteCreatedUserInviteCreatedPkeyConnect>
  >;
  /** The primary key(s) for `userInviteCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<UserInviteCreatedNodeIdConnect>>;
  /** A `UserInviteCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<UserInviteCreatedAuditorUserIdFkeyUserInviteCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `userInviteCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingUserInviteCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `userInviteCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<AuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyNodeIdUpdate>
  >;
};

/** The `userInviteCreated` to be created by this mutation. */
export type UserInviteCreatedAuditorUserIdFkeyUserInviteCreatedCreateInput = {
  auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  temporaryPassword: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/**
 * A condition to be used against `UserInviteCreated` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserInviteCreatedCondition = {
  /** Checks for equality with the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `messageId` field. */
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `temporaryPassword` field. */
  temporaryPassword?: InputMaybe<Scalars["String"]["input"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

export type UserInviteCreatedDistinctCountAggregateFilter = {
  auditorUserId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  messageId?: InputMaybe<BigIntFilter>;
  temporaryPassword?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
};

export type UserInviteCreatedDistinctCountAggregates = {
  __typename?: "UserInviteCreatedDistinctCountAggregates";
  /** Distinct count of auditorUserId across the matching connection */
  auditorUserId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of messageId across the matching connection */
  messageId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of temporaryPassword across the matching connection */
  temporaryPassword?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]["output"]>;
};

/** A filter to be used against `UserInviteCreated` object types. All fields are combined with a logical ‘and.’ */
export type UserInviteCreatedFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserInviteCreatedFilter>>;
  /** Filter by the object’s `auditorUser` relation. */
  auditorUser?: InputMaybe<AuditorUserFilter>;
  /** Filter by the object’s `auditorUserId` field. */
  auditorUserId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `message` relation. */
  message?: InputMaybe<MessageCreatedFilter>;
  /** Filter by the object’s `messageId` field. */
  messageId?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserInviteCreatedFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserInviteCreatedFilter>>;
  /** Filter by the object’s `temporaryPassword` field. */
  temporaryPassword?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** Grouping methods for `UserInviteCreated` for usage during aggregation. */
export enum UserInviteCreatedGroupBy {
  AUDITOR_USER_ID = "AUDITOR_USER_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  MESSAGE_ID = "MESSAGE_ID",
  TEMPORARY_PASSWORD = "TEMPORARY_PASSWORD",
  UPDATED_AT = "UPDATED_AT",
  UPDATED_AT_TRUNCATED_TO_DAY = "UPDATED_AT_TRUNCATED_TO_DAY",
  UPDATED_AT_TRUNCATED_TO_HOUR = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type UserInviteCreatedHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserInviteCreated` aggregates. */
export type UserInviteCreatedHavingInput = {
  AND?: InputMaybe<Array<UserInviteCreatedHavingInput>>;
  OR?: InputMaybe<Array<UserInviteCreatedHavingInput>>;
  average?: InputMaybe<UserInviteCreatedHavingAverageInput>;
  distinctCount?: InputMaybe<UserInviteCreatedHavingDistinctCountInput>;
  max?: InputMaybe<UserInviteCreatedHavingMaxInput>;
  min?: InputMaybe<UserInviteCreatedHavingMinInput>;
  stddevPopulation?: InputMaybe<UserInviteCreatedHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserInviteCreatedHavingStddevSampleInput>;
  sum?: InputMaybe<UserInviteCreatedHavingSumInput>;
  variancePopulation?: InputMaybe<UserInviteCreatedHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserInviteCreatedHavingVarianceSampleInput>;
};

export type UserInviteCreatedHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserInviteCreatedHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `UserInviteCreated` */
export type UserInviteCreatedInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  temporaryPassword: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** Input for the nested mutation of `messageCreated` in the `UserInviteCreatedInput` mutation. */
export type UserInviteCreatedMessageIdFkeyInput = {
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectById?: InputMaybe<MessageCreatedMessageCreatedPkeyConnect>;
  /** The primary key(s) for `messageCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<MessageCreatedNodeIdConnect>;
  /** A `MessageCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserInviteCreatedMessageIdFkeyMessageCreatedCreateInput>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateById?: InputMaybe<MessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyUsingMessageCreatedPkeyUpdate>;
  /** The primary key(s) and patch data for `messageCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `userInviteCreated` in the `MessageCreatedInput` mutation. */
export type UserInviteCreatedMessageIdFkeyInverseInput = {
  /** The primary key(s) for `userInviteCreated` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<UserInviteCreatedUserInviteCreatedPkeyConnect>
  >;
  /** The primary key(s) for `userInviteCreated` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<UserInviteCreatedNodeIdConnect>>;
  /** A `UserInviteCreatedInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<UserInviteCreatedMessageIdFkeyUserInviteCreatedCreateInput>
  >;
  /** The primary key(s) and patch data for `userInviteCreated` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyUsingUserInviteCreatedPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `userInviteCreated` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<MessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyNodeIdUpdate>
  >;
};

/** The `messageCreated` to be created by this mutation. */
export type UserInviteCreatedMessageIdFkeyMessageCreatedCreateInput = {
  auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
  documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
  documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  kind: MessageCreatedKind;
  message?: InputMaybe<Scalars["String"]["input"]>;
  messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
  senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
};

/** The `userInviteCreated` to be created by this mutation. */
export type UserInviteCreatedMessageIdFkeyUserInviteCreatedCreateInput = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
  temporaryPassword: Scalars["String"]["input"];
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserInviteCreatedNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userInviteCreated` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `auditorUser` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `auditorUser` being updated. */
    patch: AuditorUserPatch;
  };

/** The fields on `userInviteCreated` to look up the row to update. */
export type UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyUsingUserInviteCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `userInviteCreated` being updated. */
    patch: updateUserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `messageCreated` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `messageCreated` being updated. */
    patch: MessageCreatedPatch;
  };

/** The fields on `userInviteCreated` to look up the row to update. */
export type UserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyUsingUserInviteCreatedPkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `userInviteCreated` being updated. */
    patch: updateUserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyPatch;
  };

/** Represents an update to a `UserInviteCreated`. Fields that are set will be updated. */
export type UserInviteCreatedPatch = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
  messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
  messageId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** The fields on `userInviteCreated` to look up the row to connect. */
export type UserInviteCreatedUserInviteCreatedPkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `UserInviteCreated` values. */
export type UserInviteCreatedsConnection = {
  __typename?: "UserInviteCreatedsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserInviteCreatedAggregates>;
  /** A list of edges which contains the `UserInviteCreated` and cursor to aid in pagination. */
  edges: Array<UserInviteCreatedsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserInviteCreatedAggregates>>;
  /** A list of `UserInviteCreated` objects. */
  nodes: Array<Maybe<UserInviteCreated>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserInviteCreated` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `UserInviteCreated` values. */
export type UserInviteCreatedsConnectiongroupedAggregatesArgs = {
  groupBy: Array<UserInviteCreatedGroupBy>;
  having?: InputMaybe<UserInviteCreatedHavingInput>;
};

/** A `UserInviteCreated` edge in the connection. */
export type UserInviteCreatedsEdge = {
  __typename?: "UserInviteCreatedsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `UserInviteCreated` at the end of the edge. */
  node?: Maybe<UserInviteCreated>;
};

/** Methods to use when ordering `UserInviteCreated`. */
export enum UserInviteCreatedsOrderBy {
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__CREATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__EMAIL_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__FIRST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__INTERNAL_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__LAST_NAME_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__PROVIDER_ID_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__READ_ONLY_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__TITLE_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__UPDATED_AT_DESC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_ASC",
  AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC = "AUDITOR_USER_BY_AUDITOR_USER_ID__USER_TYPE_DESC",
  AUDITOR_USER_ID_ASC = "AUDITOR_USER_ID_ASC",
  AUDITOR_USER_ID_DESC = "AUDITOR_USER_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__CREATED_AT_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_REQUEST_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__DOCUMENT_UPLOAD_CREATED_ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__ID_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__KIND_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__KIND_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__KIND_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__KIND_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__MESSAGE_DESC",
  MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_ASC = "MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_ASC",
  MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_DESC = "MESSAGE_CREATED_BY_MESSAGE_ID__SENDER_USER_ID_DESC",
  MESSAGE_ID_ASC = "MESSAGE_ID_ASC",
  MESSAGE_ID_DESC = "MESSAGE_ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  TEMPORARY_PASSWORD_ASC = "TEMPORARY_PASSWORD_ASC",
  TEMPORARY_PASSWORD_DESC = "TEMPORARY_PASSWORD_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export type UserMfaPreferenceInput = {
  userCode: Scalars["String"]["input"];
};

export type UserMfaPreferenceResponse = {
  __typename?: "UserMfaPreferenceResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UserProfileInput = {
  addressCity: Scalars["String"]["input"];
  addressState: Scalars["String"]["input"];
  addressStreet: Scalars["String"]["input"];
  addressZipcode: Scalars["String"]["input"];
  avatar: Scalars["String"]["input"];
  company: Scalars["String"]["input"];
  workPhoneNumber: Scalars["String"]["input"];
};

export enum UserType {
  ADMINISTRATOR = "ADMINISTRATOR",
  AUDITOR = "AUDITOR",
  PAYER = "PAYER",
  PROVIDER = "PROVIDER",
}

export enum UserTypeEnum {
  ADMINISTRATOR = "ADMINISTRATOR",
  AUDITOR = "AUDITOR",
  PAYER = "PAYER",
  PROVIDER = "PROVIDER",
  SYS = "SYS",
}

/** A filter to be used against UserTypeEnum fields. All fields are combined with a logical ‘and.’ */
export type UserTypeEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<UserTypeEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<UserTypeEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<UserTypeEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<UserTypeEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<UserTypeEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<UserTypeEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<UserTypeEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<UserTypeEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<UserTypeEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<UserTypeEnum>>;
};

export type VersionAuditFindingsInput = {
  auditFindingReviewValues: Array<AuditFindingReviewValuesInput>;
  authorId: Scalars["UUID"]["input"];
};

export type WorkflowClaimState = Node & {
  __typename?: "WorkflowClaimState";
  /** Reads a single `BatchClaim` that is related to this `WorkflowClaimState`. */
  batchClaim?: Maybe<BatchClaim>;
  batchClaimId: Scalars["UUID"]["output"];
  createdAt: Scalars["Datetime"]["output"];
  id: Scalars["UUID"]["output"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars["ID"]["output"];
  workflowState: Scalars["String"]["output"];
};

export type WorkflowClaimStateAggregates = {
  __typename?: "WorkflowClaimStateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<WorkflowClaimStateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]["output"]>>;
};

/** A filter to be used against aggregates of `WorkflowClaimState` object types. */
export type WorkflowClaimStateAggregatesFilter = {
  /** Distinct count aggregate over matching `WorkflowClaimState` objects. */
  distinctCount?: InputMaybe<WorkflowClaimStateDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `WorkflowClaimState` object to be included within the aggregate. */
  filter?: InputMaybe<WorkflowClaimStateFilter>;
};

/** Input for the nested mutation of `batchClaim` in the `WorkflowClaimStateInput` mutation. */
export type WorkflowClaimStateBatchClaimIdFkInput = {
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByIcn?: InputMaybe<BatchClaimBatchClaimIcnKeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectById?: InputMaybe<BatchClaimBatchClaimPkeyConnect>;
  /** The primary key(s) for `batchClaim` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<BatchClaimNodeIdConnect>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByIcn?: InputMaybe<BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingBatchClaimIcnKeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateById?: InputMaybe<BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingBatchClaimPkeyUpdate>;
  /** The primary key(s) and patch data for `batchClaim` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<WorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkNodeIdUpdate>;
};

/** Input for the nested mutation of `workflowClaimState` in the `BatchClaimInput` mutation. */
export type WorkflowClaimStateBatchClaimIdFkInverseInput = {
  /** The primary key(s) for `workflowClaimState` for the far side of the relationship. */
  connectById?: InputMaybe<
    Array<WorkflowClaimStateWorkflowClaimStatePkeyConnect>
  >;
  /** The primary key(s) for `workflowClaimState` for the far side of the relationship. */
  connectByNodeId?: InputMaybe<Array<WorkflowClaimStateNodeIdConnect>>;
  /** A `WorkflowClaimStateInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<WorkflowClaimStateBatchClaimIdFkWorkflowClaimStateCreateInput>
  >;
  /** The primary key(s) and patch data for `workflowClaimState` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<WorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingWorkflowClaimStatePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `workflowClaimState` for the far side of the relationship. */
  updateByNodeId?: InputMaybe<
    Array<BatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkNodeIdUpdate>
  >;
};

/** The `workflowClaimState` to be created by this mutation. */
export type WorkflowClaimStateBatchClaimIdFkWorkflowClaimStateCreateInput = {
  batchClaimToBatchClaimId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowState?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * A condition to be used against `WorkflowClaimState` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type WorkflowClaimStateCondition = {
  /** Checks for equality with the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  /** Checks for equality with the object’s `workflowState` field. */
  workflowState?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkflowClaimStateDistinctCountAggregateFilter = {
  batchClaimId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  id?: InputMaybe<BigIntFilter>;
  workflowState?: InputMaybe<BigIntFilter>;
};

export type WorkflowClaimStateDistinctCountAggregates = {
  __typename?: "WorkflowClaimStateDistinctCountAggregates";
  /** Distinct count of batchClaimId across the matching connection */
  batchClaimId?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of id across the matching connection */
  id?: Maybe<Scalars["BigInt"]["output"]>;
  /** Distinct count of workflowState across the matching connection */
  workflowState?: Maybe<Scalars["BigInt"]["output"]>;
};

export enum WorkflowClaimStateEnum {
  CLAIM_RECEIVED = "CLAIM_RECEIVED",
  CLOSED = "CLOSED",
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  TO_DO = "TO_DO",
}

/** A filter to be used against WorkflowClaimStateEnum fields. All fields are combined with a logical ‘and.’ */
export type WorkflowClaimStateEnumFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<WorkflowClaimStateEnum>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<WorkflowClaimStateEnum>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<WorkflowClaimStateEnum>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<WorkflowClaimStateEnum>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<WorkflowClaimStateEnum>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<WorkflowClaimStateEnum>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<WorkflowClaimStateEnum>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<WorkflowClaimStateEnum>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<WorkflowClaimStateEnum>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<WorkflowClaimStateEnum>>;
};

/** A filter to be used against `WorkflowClaimState` object types. All fields are combined with a logical ‘and.’ */
export type WorkflowClaimStateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<WorkflowClaimStateFilter>>;
  /** Filter by the object’s `batchClaim` relation. */
  batchClaim?: InputMaybe<BatchClaimFilter>;
  /** Filter by the object’s `batchClaimId` field. */
  batchClaimId?: InputMaybe<UUIDFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<UUIDFilter>;
  /** Negates the expression. */
  not?: InputMaybe<WorkflowClaimStateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<WorkflowClaimStateFilter>>;
  /** Filter by the object’s `workflowState` field. */
  workflowState?: InputMaybe<StringFilter>;
};

/** Grouping methods for `WorkflowClaimState` for usage during aggregation. */
export enum WorkflowClaimStateGroupBy {
  BATCH_CLAIM_ID = "BATCH_CLAIM_ID",
  CREATED_AT = "CREATED_AT",
  CREATED_AT_TRUNCATED_TO_DAY = "CREATED_AT_TRUNCATED_TO_DAY",
  CREATED_AT_TRUNCATED_TO_HOUR = "CREATED_AT_TRUNCATED_TO_HOUR",
  WORKFLOW_STATE = "WORKFLOW_STATE",
}

export type WorkflowClaimStateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `WorkflowClaimState` aggregates. */
export type WorkflowClaimStateHavingInput = {
  AND?: InputMaybe<Array<WorkflowClaimStateHavingInput>>;
  OR?: InputMaybe<Array<WorkflowClaimStateHavingInput>>;
  average?: InputMaybe<WorkflowClaimStateHavingAverageInput>;
  distinctCount?: InputMaybe<WorkflowClaimStateHavingDistinctCountInput>;
  max?: InputMaybe<WorkflowClaimStateHavingMaxInput>;
  min?: InputMaybe<WorkflowClaimStateHavingMinInput>;
  stddevPopulation?: InputMaybe<WorkflowClaimStateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<WorkflowClaimStateHavingStddevSampleInput>;
  sum?: InputMaybe<WorkflowClaimStateHavingSumInput>;
  variancePopulation?: InputMaybe<WorkflowClaimStateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<WorkflowClaimStateHavingVarianceSampleInput>;
};

export type WorkflowClaimStateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

export type WorkflowClaimStateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `WorkflowClaimState` */
export type WorkflowClaimStateInput = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  workflowState?: InputMaybe<Scalars["String"]["input"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type WorkflowClaimStateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `workflowClaimState` to be connected. */
  nodeId: Scalars["ID"]["input"];
};

/** The globally unique `ID` look up for the row to update. */
export type WorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `batchClaim` to be connected. */
    nodeId: Scalars["ID"]["input"];
    /** An object where the defined keys will be set on the `batchClaim` being updated. */
    patch: BatchClaimPatch;
  };

/** The fields on `workflowClaimState` to look up the row to update. */
export type WorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkUsingWorkflowClaimStatePkeyUpdate =
  {
    id: Scalars["UUID"]["input"];
    /** An object where the defined keys will be set on the `workflowClaimState` being updated. */
    patch: updateWorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkPatch;
  };

/** Represents an update to a `WorkflowClaimState`. Fields that are set will be updated. */
export type WorkflowClaimStatePatch = {
  batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimToBatchClaimId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInput>;
};

/** The fields on `workflowClaimState` to look up the row to connect. */
export type WorkflowClaimStateWorkflowClaimStatePkeyConnect = {
  id: Scalars["UUID"]["input"];
};

/** A connection to a list of `WorkflowClaimState` values. */
export type WorkflowClaimStatesConnection = {
  __typename?: "WorkflowClaimStatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<WorkflowClaimStateAggregates>;
  /** A list of edges which contains the `WorkflowClaimState` and cursor to aid in pagination. */
  edges: Array<WorkflowClaimStatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<WorkflowClaimStateAggregates>>;
  /** A list of `WorkflowClaimState` objects. */
  nodes: Array<Maybe<WorkflowClaimState>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `WorkflowClaimState` you could get from the connection. */
  totalCount: Scalars["Int"]["output"];
};

/** A connection to a list of `WorkflowClaimState` values. */
export type WorkflowClaimStatesConnectiongroupedAggregatesArgs = {
  groupBy: Array<WorkflowClaimStateGroupBy>;
  having?: InputMaybe<WorkflowClaimStateHavingInput>;
};

/** A `WorkflowClaimState` edge in the connection. */
export type WorkflowClaimStatesEdge = {
  __typename?: "WorkflowClaimStatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]["output"]>;
  /** The `WorkflowClaimState` at the end of the edge. */
  node?: Maybe<WorkflowClaimState>;
};

/** Methods to use when ordering `WorkflowClaimState`. */
export enum WorkflowClaimStatesOrderBy {
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ADM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_CHARGED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AMOUNT_REIMBURSED_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__AUTHOR_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BATCH_FILE_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__BILL_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CLAIM_TYPE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__COMPUTATION_STRATEGY_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__CREATED_AT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_ADMIT_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_DISCHARGE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_END_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__DATE_OF_SERVICE_START_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ICN_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__INSURER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__OWNER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAID_DATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_DOB_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PATIENT_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PAYMENT_RATE_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PERMITTED_INSURER_USERS_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__PROVIDER_ID_DESC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_ASC",
  BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC = "BATCH_CLAIM_BY_BATCH_CLAIM_ID__UPDATED_AT_DESC",
  BATCH_CLAIM_ID_ASC = "BATCH_CLAIM_ID_ASC",
  BATCH_CLAIM_ID_DESC = "BATCH_CLAIM_ID_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  ID_ASC = "ID_ASC",
  ID_DESC = "ID_DESC",
  NATURAL = "NATURAL",
  PRIMARY_KEY_ASC = "PRIMARY_KEY_ASC",
  PRIMARY_KEY_DESC = "PRIMARY_KEY_DESC",
  WORKFLOW_STATE_ASC = "WORKFLOW_STATE_ASC",
  WORKFLOW_STATE_DESC = "WORKFLOW_STATE_DESC",
}

/** An object where the defined keys will be set on the `auditFindingJob` being updated. */
export type updateAuditFindingJobOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingJobAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `auditFindingJob` being updated. */
export type updateAuditFindingJobOnAuditFindingJobForAuditFindingJobAuthorIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingJobAuthorIdFkeyInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `auditFindingJob` being updated. */
export type updateAuditFindingJobOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingJobAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingClaimLineAuditFindingJobIdFkeyPatch =
  {
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingForAuditFindingClaimLinePreviousVersionIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFinding` being updated. */
export type updateAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyPatch =
  {
    auditFindingJobId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingJobToAuditFindingJobId?: InputMaybe<AuditFindingClaimLineAuditFindingJobIdFkeyInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInverseInput>;
    auditFindingReviewToReviewIdUsingId?: InputMaybe<AuditFindingReviewReviewIdFkeyInverseInput>;
    auditFindingReviewsToOriginalIdUsingId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInverseInput>;
    auditFindingRuleType?: InputMaybe<Scalars["String"]["input"]>;
    auditFindingRuleTypeToAuditFindingRuleType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInput>;
    auditFindingSeedType?: InputMaybe<AuditFindingSeedType>;
    auditFindingToPreviousVersionId?: InputMaybe<AuditFindingClaimLinePreviousVersionIdFkeyInput>;
    auditType?: InputMaybe<AuditTypeEnum>;
    auditorComment?: InputMaybe<Scalars["String"]["input"]>;
    auditorReviewed?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The id of the autodor finding that was used to create this finding. References and ID in an S3 JSON file, will soon be converted to a proper foreign key to autodor"s table of findings */
    autodorFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** The s3 bucket location of the file that contains the autodor finding that was used to create this audit finding. Together with the autodor_finding_id allows one to query the upstream autodor data. This is a temporary measure until there is a proper foreign key to the autodor service tables, or an API endpoint for autodor to serve suggested findings based on the autodor_finding_id */
    autodorFindingS3Key?: InputMaybe<Scalars["String"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditUsingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInput>;
    confidence?: InputMaybe<Scalars["BigFloat"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    improperPaymentCost?: InputMaybe<Scalars["Float"]["input"]>;
    improperPaymentReason?: InputMaybe<Scalars["String"]["input"]>;
    improperPaymentUnitsCharged?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataAocPrimaryCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataEmCorrectLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataEmOriginalLevel?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueAjudicationValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciMueOhpMueValue?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataNcciPtpCode?: InputMaybe<Scalars["String"]["input"]>;
    metaDataNcciPtpModifier?: InputMaybe<Scalars["Int"]["input"]>;
    metaDataPricingCorrectPrice?: InputMaybe<Scalars["BigFloat"]["input"]>;
    metaDataUnitsCorrectUnits?: InputMaybe<Scalars["Int"]["input"]>;
    needsReview?: InputMaybe<Scalars["Boolean"]["input"]>;
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInverseInput>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
export type updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuditFindingIdFkeyPatch =
  {
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
export type updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyPatch =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeId?: InputMaybe<Scalars["String"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReasonCode` being updated. */
export type updateAuditFindingReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyPatch =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<AuditFindingReasonCodeAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    reasonCodeToReasonCodeId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnAuditFindingReportForAuditFindingReportOriginalVersionIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReport` being updated. */
export type updateAuditFindingReportOnReportedFindingForReportedFindingAuditFindingReportIdFkeyPatch =
  {
    auditFindingReportToOriginalVersionId?: InputMaybe<AuditFindingReportOriginalVersionIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInput>;
    auditorUserToPrimaryPiaId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditFindingReportInsurerIdFkeyInput>;
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    primaryPiaId?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInverseInput>;
    reportedFindingsUsingId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditFindingReview` being updated. */
export type updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch =
  {
    auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
    auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
    originalId?: InputMaybe<Scalars["UUID"]["input"]>;
    reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReview` being updated. */
export type updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewOriginalIdFkeyPatch =
  {
    auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
    auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    reviewId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingReview` being updated. */
export type updateAuditFindingReviewOnAuditFindingReviewForAuditFindingReviewReviewIdFkeyPatch =
  {
    auditFindingToOriginalId?: InputMaybe<AuditFindingReviewOriginalIdFkeyInput>;
    auditFindingToReviewId?: InputMaybe<AuditFindingReviewReviewIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    originalId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditFindingRuleType` being updated. */
export type updateAuditFindingRuleTypeOnAuditFindingForAuditFindingAuditFindingRuleTypeFkeyPatch =
  {
    auditFindingsUsingType?: InputMaybe<AuditFindingAuditFindingRuleTypeFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
export type updateAuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyPatch =
  {
    auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `auditorBucketAssignee` being updated. */
export type updateAuditorBucketAssigneeOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyPatch =
  {
    auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketToAuditorBucketId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInput>;
    auditorUserToAuditorUserId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorBucketIdFkeyPatch =
  {
    assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnAuditorBucketForAuditorBucketAssigneeIdFkeyPatch =
  {
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnAuditorBucketForAuditorBucketAuthorIdFkeyPatch =
  {
    assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch =
  {
    assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnAuditorBucketForFkeyAuditorBucketProviderIdPatch =
  {
    assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorBucket` being updated. */
export type updateAuditorBucketOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch =
  {
    assigneeId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorBucketIdFkeyInverseInput>;
    auditorUserToAssigneeId?: InputMaybe<AuditorBucketAssigneeIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<AuditorBucketAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    bucketClaimsUsingId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dueDate?: InputMaybe<Scalars["Datetime"]["input"]>;
    filter?: InputMaybe<Scalars["JSON"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<AuditorBucketInsurerIdFkeyInput>;
    name?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<FkeyAuditorBucketProviderIdInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingForAuditFindingClaimLineAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingJobForAuditFindingJobAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingReasonCodeForAuditFindingReasonCodeAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingReportForAuditFindingReportPrimaryPiaIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingReportForPreliminaryAuditFindingReportAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditFindingReviewForAuditFindingReviewAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditorBucketAssigneeForAuditorBucketAssigneeAuditorUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditorBucketForAuditorBucketAssigneeIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditorBucketForAuditorBucketAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnAuditorUserForAuditorUserProviderIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchClaimForBatchClaimAuthorIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnBatchFileForBatchFileAuthorIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnDisputedFindingForDisputedFindingAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnMessageCreatedForMessageCreatedSenderUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnMessageSentForMessageSentRecipientUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnNnIbLineForNnIbLineAuthorIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnQueueForQueueAuthorIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
  auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
  auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
  auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
  auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
  auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
  auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
  auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
  batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
  internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
  messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
  nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
  queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
  readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
  reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
  userType?: InputMaybe<UserTypeEnum>;
};

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `auditorUser` being updated. */
export type updateAuditorUserOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobAuthorIdFkeyInverseInput>;
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeAuthorIdFkeyInverseInput>;
    auditFindingReportsToAuthorIdUsingId?: InputMaybe<PreliminaryAuditFindingReportAuthorIdFkeyInverseInput>;
    auditFindingReportsToPrimaryPiaIdUsingId?: InputMaybe<AuditFindingReportPrimaryPiaIdFkeyInverseInput>;
    auditFindingReviewsUsingId?: InputMaybe<AuditFindingReviewAuthorIdFkeyInverseInput>;
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineAuthorIdFkeyInverseInput>;
    auditorBucketAssigneesUsingId?: InputMaybe<AuditorBucketAssigneeAuditorUserIdFkeyInverseInput>;
    auditorBucketsToAssigneeIdUsingId?: InputMaybe<AuditorBucketAssigneeIdFkeyInverseInput>;
    auditorBucketsToAuthorIdUsingId?: InputMaybe<AuditorBucketAuthorIdFkeyInverseInput>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInverseInput>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimAuthorIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileAuthorIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingAuthorIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInverseInput>;
    email?: InputMaybe<Scalars["String"]["input"]>;
    firstName?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerUsersUsingId?: InputMaybe<InsurerUserAuditorUserIdFkeyInverseInput>;
    internalOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastName?: InputMaybe<Scalars["String"]["input"]>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedSenderUserIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentRecipientUserIdFkeyInverseInput>;
    nnIbLinesUsingId?: InputMaybe<NnIbLineAuthorIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<AuditorUserProviderIdFkeyInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInverseInput>;
    queuesUsingId?: InputMaybe<QueueAuthorIdFkeyInverseInput>;
    readOnly?: InputMaybe<Scalars["Boolean"]["input"]>;
    reviewedAuditFindingsUsingId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInverseInput>;
    title?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInverseInput>;
    userType?: InputMaybe<UserTypeEnum>;
  };

/** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
export type updateBatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeAuditorUserIdFkeyPatch =
  {
    auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimAssignee` being updated. */
export type updateBatchClaimAssigneeOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyPatch =
  {
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<BatchClaimAssigneeAuditorUserIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimDueDate` being updated. */
export type updateBatchClaimDueDateOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineComputedValue` being updated. */
export type updateBatchClaimLineComputedValueOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyPatch =
  {
    batchClaimLineToId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
export type updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuditFindingClaimLineIdFkeyPatch =
  {
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
export type updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditAuthorIdFkeyPatch =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemAudit` being updated. */
export type updateBatchClaimLineItemAuditOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyPatch =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<BatchClaimLineItemAuditAuditFindingClaimLineIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuditAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemComputedValue` being updated. */
export type updateBatchClaimLineItemComputedValueOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyPatch =
  {
    batchClaimLineItemToId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
export type updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineAuthorIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
export type updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItemNnIbLine` being updated. */
export type updateBatchClaimLineItemNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemNnIbLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemToBatchClaimLineItemId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    nnIbLineToNnIbLineId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemAuditForBatchClaimLineItemAuditBatchClaimLineItemIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemComputedValueForBatchClaimLineItemComputedValuesIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemAuthorIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLineItem` being updated. */
export type updateBatchClaimLineItemOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyPatch =
  {
    /** # The ala_row_id as generated by the IB extraction process, and related to bounding box of text in pdf page.  Relates record back to the given row in s3_input_source bounding box of text on a given page. */
    alaRowId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimLineItemAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemAuditsUsingId?: InputMaybe<BatchClaimLineItemAuditBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineItemComputedValueUsingId?: InputMaybe<BatchClaimLineItemComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineBatchClaimLineItemIdFkeyInverseInput>;
    batchClaimLineToBatchClaimLineId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateOfServiceStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    ibLineId?: InputMaybe<Scalars["UUID"]["input"]>;
    ibLineVersionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    itemNumber?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the order of the IB line in the source CSV input. */
    lineNumber?: InputMaybe<Scalars["Int"]["input"]>;
    originalBilledAmount?: InputMaybe<Scalars["BigFloat"]["input"]>;
    /**
     * ### original_version_id
     *  Original version id, used in versioning
     */
    originalVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    /** # Represents the PDF page number from which the IB line was extracted. Zero-indexed. */
    pageNumber?: InputMaybe<Scalars["Int"]["input"]>;
    /**
     * ### previous_version_id
     *  Previous version id, used in versioning
     */
    previousVersionId?: InputMaybe<Scalars["UUID"]["input"]>;
    procedureCode?: InputMaybe<Scalars["String"]["input"]>;
    procedureCodeModifier?: InputMaybe<Scalars["String"]["input"]>;
    revCode?: InputMaybe<Scalars["String"]["input"]>;
    /** # s3_audit_documentation.id key of input to IBIN.  Using id allows for limiting PII/PHI speard from raw s3 keys. */
    s3InputSource?: InputMaybe<Scalars["String"]["input"]>;
    /** # Represents the table number from which the IB line was extracted, according to Textract. Zero-indexed. */
    tableNumber?: InputMaybe<Scalars["Int"]["input"]>;
    unitCharge?: InputMaybe<Scalars["BigFloat"]["input"]>;
    units?: InputMaybe<Scalars["Int"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    /**
     * ### version_fingerprint
     *  Fingerprint of values used by app logic to determine if new version is needed, generated by function generate_bcli_fingerprint
     */
    versionFingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `batchClaimLine` being updated. */
export type updateBatchClaimLineOnAuditFindingForAuditFindingClaimLineBatchClaimLineIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLine` being updated. */
export type updateBatchClaimLineOnBatchClaimLineComputedValueForBatchClaimLineComputedValuesIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLine` being updated. */
export type updateBatchClaimLineOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
    batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaimLine` being updated. */
export type updateBatchClaimLineOnBatchClaimLineItemForBatchClaimLineItemBatchClaimLineIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimLineIdFkeyInverseInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineComputedValueUsingId?: InputMaybe<BatchClaimLineComputedValuesIdFkeyInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimLineIdFkeyInverseInput>;
    batchClaimToBatchClaimId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnAuditFindingForAuditFindingClaimLineBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimAssigneeForBatchClaimAssigneeBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimDueDateForBatchClaimDueDateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimForBatchClaimAuthorIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimForBatchClaimBatchFileIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimForBatchClaimInsurerIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimForBatchClaimProviderIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimLineForBatchClaimLineBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimLineItemForBatchClaimLineItemBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnDisputedFindingForDisputedFindingBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnReportedClaimForReportedClaimClaimIdFkeyPatch = {
  auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
  batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
  batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
  batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
  batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
  batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
  bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
  clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
  documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
  invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
  negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
  onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
  providerId?: InputMaybe<Scalars["String"]["input"]>;
  providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
  providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
  reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
  s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
  workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
};

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaim` being updated. */
export type updateBatchClaimOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkPatch =
  {
    auditFindingsUsingId?: InputMaybe<AuditFindingClaimLineBatchClaimIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchClaimAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimAssigneesUsingId?: InputMaybe<BatchClaimAssigneeBatchClaimIdFkeyInverseInput>;
    batchClaimDueDatesUsingId?: InputMaybe<BatchClaimDueDateBatchClaimIdFkInverseInput>;
    batchClaimLineItemsUsingId?: InputMaybe<BatchClaimLineItemBatchClaimIdFkeyInverseInput>;
    batchClaimLinesUsingId?: InputMaybe<BatchClaimLineBatchClaimIdFkeyInverseInput>;
    batchClaimStateUsingId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInverseInput>;
    batchFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchFileToBatchFileId?: InputMaybe<BatchClaimBatchFileIdFkeyInput>;
    bucketClaimUsingId?: InputMaybe<BucketClaimBatchClaimIdFkeyInverseInput>;
    clientSignOffClaimStatesUsingId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInverseInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchClaimInsurerIdFkeyInput>;
    invoiceClaimStatesUsingId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInverseInput>;
    negotiationClaimStatesUsingId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInverseInput>;
    onHoldClaimStatesUsingId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerSignOffClaimStatesUsingId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInverseInput>;
    providerToProviderId?: InputMaybe<BatchClaimProviderIdFkeyInput>;
    reportedClaimsUsingId?: InputMaybe<ReportedClaimClaimIdFkeyInverseInput>;
    s3AuditDocumentationsUsingId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInverseInput>;
    workflowClaimStatesUsingId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInverseInput>;
  };

/** An object where the defined keys will be set on the `batchClaimState` being updated. */
export type updateBatchClaimStateOnBatchClaimStateForBatchClaimStateBatchClaimIdFkeyPatch =
  {
    batchClaimToId?: InputMaybe<BatchClaimStateBatchClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchFile` being updated. */
export type updateBatchFileOnAuditFindingJobForAuditFindingJobBatchFileIdFkeyPatch =
  {
    auditFindingJobsUsingId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInverseInput>;
    auditorUserToAuthorId?: InputMaybe<BatchFileAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimsUsingId?: InputMaybe<BatchClaimBatchFileIdFkeyInverseInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<BatchFileInsurerIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `batchFile` being updated. */
export type updateBatchFileOnBatchClaimForBatchClaimBatchFileIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchFileAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimsUsingId?: InputMaybe<BatchClaimBatchFileIdFkeyInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchFileInsurerIdFkeyInput>;
};

/** An object where the defined keys will be set on the `batchFile` being updated. */
export type updateBatchFileOnBatchFileForBatchFileAuthorIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchFileAuthorIdFkeyInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimBatchFileIdFkeyInverseInput>;
  insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
  insurerToInsurerId?: InputMaybe<BatchFileInsurerIdFkeyInput>;
};

/** An object where the defined keys will be set on the `batchFile` being updated. */
export type updateBatchFileOnBatchFileForBatchFileInsurerIdFkeyPatch = {
  auditFindingJobsUsingId?: InputMaybe<AuditFindingJobBatchFileIdFkeyInverseInput>;
  auditorUserToAuthorId?: InputMaybe<BatchFileAuthorIdFkeyInput>;
  authorId?: InputMaybe<Scalars["UUID"]["input"]>;
  batchClaimsUsingId?: InputMaybe<BatchClaimBatchFileIdFkeyInverseInput>;
  insurerToInsurerId?: InputMaybe<BatchFileInsurerIdFkeyInput>;
};

/** An object where the defined keys will be set on the `bucketClaim` being updated. */
export type updateBucketClaimOnBucketClaimForBucketClaimAuditorBucketIdFkeyPatch =
  {
    auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
    status?: InputMaybe<AuditFindingClaimStatus>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `bucketClaim` being updated. */
export type updateBucketClaimOnBucketClaimForBucketClaimBatchClaimIdFkeyPatch =
  {
    auditorBucketId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorBucketToAuditorBucketId?: InputMaybe<BucketClaimAuditorBucketIdFkeyInput>;
    batchClaimToBatchClaimId?: InputMaybe<BucketClaimBatchClaimIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    selectionMethod?: InputMaybe<Scalars["JSON"]["input"]>;
    status?: InputMaybe<AuditFindingClaimStatus>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `clientSignOffClaimState` being updated. */
export type updateClientSignOffClaimStateOnClientSignOffClaimStateForClientSignOffClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<ClientSignOffClaimStateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `defaultStrategyByInsurer` being updated. */
export type updateDefaultStrategyByInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch =
  {
    insurerToInsurerId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInput>;
  };

/** An object where the defined keys will be set on the `disputedFinding` being updated. */
export type updateDisputedFindingOnDisputedFindingForDisputedFindingAuthorIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `disputedFinding` being updated. */
export type updateDisputedFindingOnDisputedFindingForDisputedFindingBatchClaimIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `disputedFinding` being updated. */
export type updateDisputedFindingOnDisputedFindingForDisputedFindingProviderIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<DisputedFindingAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DisputedFindingBatchClaimIdFkeyInput>;
    providerToProviderId?: InputMaybe<DisputedFindingProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
export type updateDocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedBatchClaimIdFkPatch =
  {
    _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
export type updateDocumentRequestCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkPatch =
  {
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `documentRequestCreated` being updated. */
export type updateDocumentRequestCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyPatch =
  {
    _deprecatedMessageCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentRequestCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageCreatedId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
export type updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedAuthorIdFkeyPatch =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
export type updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedBatchClaimIdFkPatch =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
export type updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
export type updateDocumentUploadCreatedOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyPatch =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentUploadCreated` being updated. */
export type updateDocumentUploadCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyPatch =
  {
    _deprecatedMessageId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuthorId?: InputMaybe<DocumentUploadCreatedAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<DocumentUploadCreatedBatchClaimIdFkInput>;
    messageCreatedToDeprecatedMessageId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInput>;
    messageCreatedsUsingId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInverseInput>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `documentationSelectionSet` being updated. */
export type updateDocumentationSelectionSetOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyPatch =
  {
    providerToProviderId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `humanProcessingRequest` being updated. */
export type updateHumanProcessingRequestOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyPatch =
  {
    s3AuditDocumentationToS3AuditDocumentationId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `insurerClaimIntakeDropboxPermitted` being updated. */
export type updateInsurerClaimIntakeDropboxPermittedOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch =
  {
    insurerToInsurerId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnAuditFindingReportForAuditFindingReportInsurerIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
    defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
    insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
    insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
    insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
    insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnAuditorBucketForAuditorBucketInsurerIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
  defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
  insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
  insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
  insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
  insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnBatchClaimForBatchClaimInsurerIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
  defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
  insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
  insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
  insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
  insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnBatchFileForBatchFileInsurerIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
  defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
  insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
  insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
  insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
  insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnDefaultStrategyByInsurerForDefaultStrategyByInsurerInsurerFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
    defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
    insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
    insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
    insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
    insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnInsurerClaimIntakeDropboxPermittedForInsurerClaimIntakeDropboxPermittedInsurerIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
    defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
    insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
    insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
    insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
    insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
    defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
    insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
    insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
    insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
    insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
    batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
    defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
    insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
    insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
    insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
    insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `insurer` being updated. */
export type updateInsurerOnInsurerUserForInsurerUserInsurerIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<AuditFindingReportInsurerIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<AuditorBucketInsurerIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimInsurerIdFkeyInverseInput>;
  batchFilesUsingId?: InputMaybe<BatchFileInsurerIdFkeyInverseInput>;
  defaultStrategyByInsurerUsingId?: InputMaybe<DefaultStrategyByInsurerInsurerFkeyInverseInput>;
  insurerClaimIntakeDropboxPermittedUsingId?: InputMaybe<InsurerClaimIntakeDropboxPermittedInsurerIdFkeyInverseInput>;
  insurerOrganizationsToMemberIdUsingId?: InputMaybe<InsurerOrganizationMemberIdFkeyInverseInput>;
  insurerOrganizationsToOrganizationIdUsingId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInverseInput>;
  insurerUsersUsingId?: InputMaybe<InsurerUserInsurerIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `insurerOrganization` being updated. */
export type updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationMemberIdFkeyPatch =
  {
    insurerToMemberId?: InputMaybe<InsurerOrganizationMemberIdFkeyInput>;
    insurerToOrganizationId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `insurerOrganization` being updated. */
export type updateInsurerOrganizationOnInsurerOrganizationForInsurerOrganizationOrganizationIdFkeyPatch =
  {
    insurerToMemberId?: InputMaybe<InsurerOrganizationMemberIdFkeyInput>;
    insurerToOrganizationId?: InputMaybe<InsurerOrganizationOrganizationIdFkeyInput>;
    memberId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `insurerUser` being updated. */
export type updateInsurerUserOnInsurerUserForInsurerUserAuditorUserIdFkeyPatch =
  {
    auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
    insurerId?: InputMaybe<Scalars["UUID"]["input"]>;
    insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `insurerUser` being updated. */
export type updateInsurerUserOnInsurerUserForInsurerUserInsurerIdFkeyPatch = {
  auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  auditorUserToAuditorUserId?: InputMaybe<InsurerUserAuditorUserIdFkeyInput>;
  insurerToInsurerId?: InputMaybe<InsurerUserInsurerIdFkeyInput>;
};

/** An object where the defined keys will be set on the `invoiceClaimState` being updated. */
export type updateInvoiceClaimStateOnInvoiceClaimStateForInvoiceClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<InvoiceClaimStateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnDocumentRequestCreatedForDocumentRequestCreatedMessageCreatedIdFkPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnDocumentUploadCreatedForDocumentUploadCreatedMessageIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnMessageCreatedForMessageCreatedDocumentRequestCreatedIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnMessageCreatedForMessageCreatedDocumentUploadCreatedIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnMessageCreatedForMessageCreatedSenderUserIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnMessageSentForMessageSentMessageIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageCreated` being updated. */
export type updateMessageCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyPatch =
  {
    auditorUserToSenderUserId?: InputMaybe<MessageCreatedSenderUserIdFkeyInput>;
    documentRequestCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentRequestCreatedToDocumentRequestCreatedId?: InputMaybe<MessageCreatedDocumentRequestCreatedIdFkeyInput>;
    documentRequestCreatedsUsingId?: InputMaybe<DocumentRequestCreatedMessageCreatedIdFkInverseInput>;
    documentUploadCreatedId?: InputMaybe<Scalars["UUID"]["input"]>;
    documentUploadCreatedToDocumentUploadCreatedId?: InputMaybe<MessageCreatedDocumentUploadCreatedIdFkeyInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedMessageIdFkeyInverseInput>;
    messageSentsUsingId?: InputMaybe<MessageSentMessageIdFkeyInverseInput>;
    senderUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    userInviteCreatedsUsingId?: InputMaybe<UserInviteCreatedMessageIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `messageSent` being updated. */
export type updateMessageSentOnMessageSentForMessageSentMessageIdFkeyPatch = {
  auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
  messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
  messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
  messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
  recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
  responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
};

/** An object where the defined keys will be set on the `messageSent` being updated. */
export type updateMessageSentOnMessageSentForMessageSentRecipientUserIdFkeyPatch =
  {
    auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
    messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
    messageId?: InputMaybe<Scalars["UUID"]["input"]>;
    messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
    messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
    responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
    seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `messageSent` being updated. */
export type updateMessageSentOnMessageSentForResponseToMessageSentIdMessageSentIdFkPatch =
  {
    auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
    messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
    messageId?: InputMaybe<Scalars["UUID"]["input"]>;
    messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
    messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
    recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    seedMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `messageSent` being updated. */
export type updateMessageSentOnMessageSentForSeedMessageSentIdMessageSentIdFkPatch =
  {
    auditorUserToRecipientUserId?: InputMaybe<MessageSentRecipientUserIdFkeyInput>;
    messageCreatedToMessageId?: InputMaybe<MessageSentMessageIdFkeyInput>;
    messageId?: InputMaybe<Scalars["UUID"]["input"]>;
    messageSentToResponseToMessageSentId?: InputMaybe<ResponseToMessageSentIdMessageSentIdFkInput>;
    messageSentToSeedMessageSentId?: InputMaybe<SeedMessageSentIdMessageSentIdFkInput>;
    recipientUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    responseToMessageSentId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `negotiationClaimState` being updated. */
export type updateNegotiationClaimStateOnNegotiationClaimStateForNegotiationClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<NegotiationClaimStateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `nnIbLine` being updated. */
export type updateNnIbLineOnBatchClaimLineItemNnIbLineForBatchClaimLineItemNnIbLineNnIbLineIdFkeyPatch =
  {
    auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    fingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    s3Key?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `nnIbLine` being updated. */
export type updateNnIbLineOnNnIbLineForNnIbLineAuthorIdFkeyPatch = {
  auditorUserToAuthorId?: InputMaybe<NnIbLineAuthorIdFkeyInput>;
  batchClaimLineItemNnIbLinesUsingId?: InputMaybe<BatchClaimLineItemNnIbLineNnIbLineIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  fingerprint?: InputMaybe<Scalars["UUID"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  s3Key?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An object where the defined keys will be set on the `onHoldClaimState` being updated. */
export type updateOnHoldClaimStateOnOnHoldClaimStateForOnHoldClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<OnHoldClaimStateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnAuditFindingReportForPreliminaryAuditFindingReportProviderIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
    auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
    documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnAuditorBucketForFkeyAuditorBucketProviderIdPatch = {
  auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
  auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
  documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnAuditorUserForAuditorUserProviderIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
  auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
  documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnBatchClaimForBatchClaimProviderIdFkeyPatch = {
  auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
  auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
  auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
  batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
  disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
  documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
  documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
  providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnDisputedFindingForDisputedFindingProviderIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
    auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
    documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnDocumentUploadCreatedForDocumentUploadCreatedProviderIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
    auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
    documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnDocumentationSelectionSetForDocumentationSelectionSetProviderIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
    auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
    documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `provider` being updated. */
export type updateProviderOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyPatch =
  {
    auditFindingReportsUsingId?: InputMaybe<PreliminaryAuditFindingReportProviderIdFkeyInverseInput>;
    auditorBucketsUsingId?: InputMaybe<FkeyAuditorBucketProviderIdInverseInput>;
    auditorUsersUsingId?: InputMaybe<AuditorUserProviderIdFkeyInverseInput>;
    batchClaimsUsingId?: InputMaybe<BatchClaimProviderIdFkeyInverseInput>;
    disputedFindingsUsingId?: InputMaybe<DisputedFindingProviderIdFkeyInverseInput>;
    documentUploadCreatedsUsingId?: InputMaybe<DocumentUploadCreatedProviderIdFkeyInverseInput>;
    documentationSelectionSetsUsingId?: InputMaybe<DocumentationSelectionSetProviderIdFkeyInverseInput>;
    providerUserFacilitiesUsingId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `providerSignOffClaimState` being updated. */
export type updateProviderSignOffClaimStateOnProviderSignOffClaimStateForProviderSignOffClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<ProviderSignOffClaimStateBatchClaimIdFkInput>;
  };

/** An object where the defined keys will be set on the `providerUserFacility` being updated. */
export type updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesAuditorUserIdFkeyPatch =
  {
    auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `providerUserFacility` being updated. */
export type updateProviderUserFacilityOnProviderUserFacilityForProviderUserFacilitiesProviderIdFkeyPatch =
  {
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<ProviderUserFacilitiesAuditorUserIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    providerToProviderId?: InputMaybe<ProviderUserFacilitiesProviderIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `queue` being updated. */
export type updateQueueOnQueueForQueueAuthorIdFkeyPatch = {
  auditorUserToAuthorId?: InputMaybe<QueueAuthorIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  filter?: InputMaybe<Scalars["JSON"]["input"]>;
  id?: InputMaybe<Scalars["UUID"]["input"]>;
  indexedFilterTags?: InputMaybe<Scalars["JSON"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
};

/** An object where the defined keys will be set on the `reasonCode` being updated. */
export type updateReasonCodeOnAuditFindingReasonCodeForAuditFindingReasonCodeReasonCodeIdFkeyPatch =
  {
    auditFindingReasonCodesUsingId?: InputMaybe<AuditFindingReasonCodeReasonCodeIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateEffectiveEnd?: InputMaybe<Scalars["Datetime"]["input"]>;
    dateEffectiveStart?: InputMaybe<Scalars["Datetime"]["input"]>;
    description?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["String"]["input"]>;
    lastUpdated?: InputMaybe<Scalars["Datetime"]["input"]>;
    notes?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `reportedClaim` being updated. */
export type updateReportedClaimOnReportedClaimForReportedClaimAuditFindingReportIdAuditFindingReportIdPatch =
  {
    auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInput>;
    batchClaimToClaimId?: InputMaybe<ReportedClaimClaimIdFkeyInput>;
    claimId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `reportedClaim` being updated. */
export type updateReportedClaimOnReportedClaimForReportedClaimClaimIdFkeyPatch =
  {
    auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedClaimAuditFindingReportIdAuditFindingReportIdInput>;
    batchClaimToClaimId?: InputMaybe<ReportedClaimClaimIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `reportedFinding` being updated. */
export type updateReportedFindingOnReportedFindingForReportedFindingAuditFindingIdFkeyPatch =
  {
    auditFindingReportId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInput>;
    auditFindingToAuditFindingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `reportedFinding` being updated. */
export type updateReportedFindingOnReportedFindingForReportedFindingAuditFindingReportIdFkeyPatch =
  {
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingReportToAuditFindingReportId?: InputMaybe<ReportedFindingAuditFindingReportIdFkeyInput>;
    auditFindingToAuditFindingId?: InputMaybe<ReportedFindingAuditFindingIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
export type updateReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuditFindingIdFkeyPatch =
  {
    accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
    authorId?: InputMaybe<Scalars["UUID"]["input"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    rationale?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `reviewedAuditFinding` being updated. */
export type updateReviewedAuditFindingOnReviewedAuditFindingForReviewedAuditFindingAuthorIdFkeyPatch =
  {
    accepted?: InputMaybe<Scalars["Boolean"]["input"]>;
    auditFindingId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditFindingToAuditFindingId?: InputMaybe<ReviewedAuditFindingAuditFindingIdFkeyInput>;
    auditorUserToAuthorId?: InputMaybe<ReviewedAuditFindingAuthorIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]["input"]>;
    deleted?: InputMaybe<Scalars["Boolean"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    rationale?: InputMaybe<Scalars["String"]["input"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]["input"]>;
  };

/** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
export type updateS3AuditDocumentationOnHumanProcessingRequestForHumanProcessingRequestS3AuditDocumentationIdFkeyPatch =
  {
    batchClaimId?: InputMaybe<Scalars["UUID"]["input"]>;
    batchClaimToBatchClaimId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInput>;
    etag?: InputMaybe<Scalars["String"]["input"]>;
    filename?: InputMaybe<Scalars["String"]["input"]>;
    humanProcessingRequestsUsingId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput>;
    icn?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
    originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    process?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    s3Key?: InputMaybe<Scalars["String"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    subFile?: InputMaybe<Scalars["String"]["input"]>;
    subProcess?: InputMaybe<Scalars["String"]["input"]>;
    tags?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `s3AuditDocumentation` being updated. */
export type updateS3AuditDocumentationOnS3AuditDocumentationForS3AuditDocumentationBatchClaimIdFkeyPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<S3AuditDocumentationBatchClaimIdFkeyInput>;
    etag?: InputMaybe<Scalars["String"]["input"]>;
    filename?: InputMaybe<Scalars["String"]["input"]>;
    humanProcessingRequestsUsingId?: InputMaybe<HumanProcessingRequestS3AuditDocumentationIdFkeyInverseInput>;
    icn?: InputMaybe<Scalars["String"]["input"]>;
    id?: InputMaybe<Scalars["UUID"]["input"]>;
    isUploaded?: InputMaybe<Scalars["Boolean"]["input"]>;
    lastModified?: InputMaybe<Scalars["Datetime"]["input"]>;
    originalFileId?: InputMaybe<Scalars["UUID"]["input"]>;
    process?: InputMaybe<Scalars["String"]["input"]>;
    providerId?: InputMaybe<Scalars["String"]["input"]>;
    s3Key?: InputMaybe<Scalars["String"]["input"]>;
    size?: InputMaybe<Scalars["BigInt"]["input"]>;
    subFile?: InputMaybe<Scalars["String"]["input"]>;
    subProcess?: InputMaybe<Scalars["String"]["input"]>;
    tags?: InputMaybe<Scalars["JSON"]["input"]>;
  };

/** An object where the defined keys will be set on the `userInviteCreated` being updated. */
export type updateUserInviteCreatedOnUserInviteCreatedForUserInviteCreatedAuditorUserIdFkeyPatch =
  {
    auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
    messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
    messageId?: InputMaybe<Scalars["UUID"]["input"]>;
  };

/** An object where the defined keys will be set on the `userInviteCreated` being updated. */
export type updateUserInviteCreatedOnUserInviteCreatedForUserInviteCreatedMessageIdFkeyPatch =
  {
    auditorUserId?: InputMaybe<Scalars["UUID"]["input"]>;
    auditorUserToAuditorUserId?: InputMaybe<UserInviteCreatedAuditorUserIdFkeyInput>;
    messageCreatedToMessageId?: InputMaybe<UserInviteCreatedMessageIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `workflowClaimState` being updated. */
export type updateWorkflowClaimStateOnWorkflowClaimStateForWorkflowClaimStateBatchClaimIdFkPatch =
  {
    batchClaimToBatchClaimId?: InputMaybe<WorkflowClaimStateBatchClaimIdFkInput>;
  };

export type askAutodorFileVectorsExistQueryQueryVariables = Exact<{
  input?: InputMaybe<AskAutodorFileVectorsExistInput>;
}>;

export type askAutodorFileVectorsExistQueryQuery = {
  __typename?: "Query";
  askAutodorFileVectorsExist?: {
    __typename?: "AskAutodorFileVectorsExistResponse";
    documentIds: Array<any | null>;
  } | null;
};

export type batchClaimLineItemFragmentFragment = {
  __typename?: "BatchClaimLineItem";
  id: any;
  dateOfServiceStart: any;
  dateOfServiceEnd?: any | null;
  revCode: string;
  procedureCode?: string | null;
  itemNumber?: string | null;
  description: string;
  units: number;
  unitCharge: any;
  batchClaimLineId?: any | null;
  authorId?: any | null;
  createdAt: any;
  updatedAt: any;
  alaRowId?: any | null;
  ibLineId?: any | null;
  batchClaimLineItemNnIbLines: {
    __typename?: "BatchClaimLineItemNnIbLinesConnection";
    nodes: Array<{
      __typename?: "BatchClaimLineItemNnIbLine";
      nnIbLineId: any;
      nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
    } | null>;
  };
};

export type reviewedAuditFindingFragmentFragment = {
  __typename?: "ReviewedAuditFinding";
  id: any;
  accepted: boolean;
  deleted: boolean;
  createdAt: any;
  updatedAt: any;
  rationale?: string | null;
};

export type auditFindingFragmentFragment = {
  __typename?: "AuditFinding";
  id: any;
  improperPaymentUnitsCharged: number;
  improperPaymentCost: number;
  improperPaymentReason: string;
  needsReview: boolean;
  auditorReviewed: boolean;
  auditorComment?: string | null;
  auditType?: AuditTypeEnum | null;
  superseded?: boolean | null;
  auditFindingRuleType: string;
  auditFindingSeedType: AuditFindingSeedType;
  confidence: any;
  isOriginal?: boolean | null;
  isActive?: boolean | null;
  accepted?: boolean | null;
  createdAt: any;
  metaDataNcciMueOhpMueValue?: number | null;
  metaDataNcciMueAjudicationValue?: number | null;
  metaDataNcciPtpModifier?: number | null;
  metaDataNcciPtpCode?: string | null;
  metaDataEmCode?: string | null;
  metaDataEmOriginalLevel?: number | null;
  metaDataEmCorrectLevel?: number | null;
  metaDataAocPrimaryCode?: string | null;
  metaDataPricingCorrectPrice?: any | null;
  metaDataUnitsCorrectUnits?: number | null;
  discrepantAmount?: number | null;
  batchClaimLineId?: any | null;
  batchClaimId: any;
  auditFindingRuleTypeByAuditFindingRuleType?: {
    __typename?: "AuditFindingRuleType";
    type: string;
    description?: string | null;
    displayName: string;
  } | null;
  author?: {
    __typename?: "AuditorUser";
    id: any;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  reviewedAuditFinding?: {
    __typename?: "ReviewedAuditFinding";
    id: any;
    accepted: boolean;
    deleted: boolean;
    createdAt: any;
    updatedAt: any;
    rationale?: string | null;
  } | null;
  batchClaimLineItemAudit?: {
    __typename?: "BatchClaimLineItemAudit";
    id: any;
    batchClaimLineItem?: {
      __typename?: "BatchClaimLineItem";
      id: any;
      dateOfServiceStart: any;
      dateOfServiceEnd?: any | null;
      revCode: string;
      procedureCode?: string | null;
      itemNumber?: string | null;
      description: string;
      units: number;
      unitCharge: any;
      batchClaimLineId?: any | null;
      authorId?: any | null;
      createdAt: any;
      updatedAt: any;
      alaRowId?: any | null;
      ibLineId?: any | null;
      batchClaimLineItemNnIbLines: {
        __typename?: "BatchClaimLineItemNnIbLinesConnection";
        nodes: Array<{
          __typename?: "BatchClaimLineItemNnIbLine";
          nnIbLineId: any;
          nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
        } | null>;
      };
    } | null;
  } | null;
  reportedFindings: {
    __typename?: "ReportedFindingsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "ReportedFinding";
      id: any;
      auditFindingReportId: any;
    } | null>;
  };
};

export type auditFindingReviewFragmentFragment = {
  __typename?: "AuditFindingReview";
  id: any;
  createdAt: any;
  reviewId: any;
  originalId: any;
};

export type auditFindingReviewsFragmentFragment = {
  __typename?: "AuditFinding";
  id: any;
  reviews: {
    __typename?: "AuditFindingReviewsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "AuditFindingReview";
      id: any;
      createdAt: any;
      reviewId: any;
      originalId: any;
      auditFinding?: {
        __typename?: "AuditFinding";
        id: any;
        improperPaymentUnitsCharged: number;
        improperPaymentCost: number;
        improperPaymentReason: string;
        needsReview: boolean;
        auditorReviewed: boolean;
        auditorComment?: string | null;
        auditType?: AuditTypeEnum | null;
        superseded?: boolean | null;
        auditFindingRuleType: string;
        auditFindingSeedType: AuditFindingSeedType;
        confidence: any;
        isOriginal?: boolean | null;
        isActive?: boolean | null;
        accepted?: boolean | null;
        createdAt: any;
        metaDataNcciMueOhpMueValue?: number | null;
        metaDataNcciMueAjudicationValue?: number | null;
        metaDataNcciPtpModifier?: number | null;
        metaDataNcciPtpCode?: string | null;
        metaDataEmCode?: string | null;
        metaDataEmOriginalLevel?: number | null;
        metaDataEmCorrectLevel?: number | null;
        metaDataAocPrimaryCode?: string | null;
        metaDataPricingCorrectPrice?: any | null;
        metaDataUnitsCorrectUnits?: number | null;
        discrepantAmount?: number | null;
        batchClaimLineId?: any | null;
        batchClaimId: any;
        auditFindingRuleTypeByAuditFindingRuleType?: {
          __typename?: "AuditFindingRuleType";
          type: string;
          description?: string | null;
          displayName: string;
        } | null;
        author?: {
          __typename?: "AuditorUser";
          id: any;
          firstName: string;
          lastName: string;
          email: string;
        } | null;
        reviewedAuditFinding?: {
          __typename?: "ReviewedAuditFinding";
          id: any;
          accepted: boolean;
          deleted: boolean;
          createdAt: any;
          updatedAt: any;
          rationale?: string | null;
        } | null;
        batchClaimLineItemAudit?: {
          __typename?: "BatchClaimLineItemAudit";
          id: any;
          batchClaimLineItem?: {
            __typename?: "BatchClaimLineItem";
            id: any;
            dateOfServiceStart: any;
            dateOfServiceEnd?: any | null;
            revCode: string;
            procedureCode?: string | null;
            itemNumber?: string | null;
            description: string;
            units: number;
            unitCharge: any;
            batchClaimLineId?: any | null;
            authorId?: any | null;
            createdAt: any;
            updatedAt: any;
            alaRowId?: any | null;
            ibLineId?: any | null;
            batchClaimLineItemNnIbLines: {
              __typename?: "BatchClaimLineItemNnIbLinesConnection";
              nodes: Array<{
                __typename?: "BatchClaimLineItemNnIbLine";
                nnIbLineId: any;
                nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
              } | null>;
            };
          } | null;
        } | null;
        reportedFindings: {
          __typename?: "ReportedFindingsConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "ReportedFinding";
            id: any;
            auditFindingReportId: any;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type auditFindingWithReviewFragmentFragment = {
  __typename?: "AuditFinding";
  id: any;
  improperPaymentUnitsCharged: number;
  improperPaymentCost: number;
  improperPaymentReason: string;
  needsReview: boolean;
  auditorReviewed: boolean;
  auditorComment?: string | null;
  auditType?: AuditTypeEnum | null;
  superseded?: boolean | null;
  auditFindingRuleType: string;
  auditFindingSeedType: AuditFindingSeedType;
  confidence: any;
  isOriginal?: boolean | null;
  isActive?: boolean | null;
  accepted?: boolean | null;
  createdAt: any;
  metaDataNcciMueOhpMueValue?: number | null;
  metaDataNcciMueAjudicationValue?: number | null;
  metaDataNcciPtpModifier?: number | null;
  metaDataNcciPtpCode?: string | null;
  metaDataEmCode?: string | null;
  metaDataEmOriginalLevel?: number | null;
  metaDataEmCorrectLevel?: number | null;
  metaDataAocPrimaryCode?: string | null;
  metaDataPricingCorrectPrice?: any | null;
  metaDataUnitsCorrectUnits?: number | null;
  discrepantAmount?: number | null;
  batchClaimLineId?: any | null;
  batchClaimId: any;
  original?: {
    __typename?: "AuditFindingReview";
    id: any;
    createdAt: any;
    reviewId: any;
    originalId: any;
    auditFinding?: {
      __typename?: "AuditFinding";
      id: any;
      improperPaymentUnitsCharged: number;
      improperPaymentCost: number;
      improperPaymentReason: string;
      needsReview: boolean;
      auditorReviewed: boolean;
      auditorComment?: string | null;
      auditType?: AuditTypeEnum | null;
      superseded?: boolean | null;
      auditFindingRuleType: string;
      auditFindingSeedType: AuditFindingSeedType;
      confidence: any;
      isOriginal?: boolean | null;
      isActive?: boolean | null;
      accepted?: boolean | null;
      createdAt: any;
      metaDataNcciMueOhpMueValue?: number | null;
      metaDataNcciMueAjudicationValue?: number | null;
      metaDataNcciPtpModifier?: number | null;
      metaDataNcciPtpCode?: string | null;
      metaDataEmCode?: string | null;
      metaDataEmOriginalLevel?: number | null;
      metaDataEmCorrectLevel?: number | null;
      metaDataAocPrimaryCode?: string | null;
      metaDataPricingCorrectPrice?: any | null;
      metaDataUnitsCorrectUnits?: number | null;
      discrepantAmount?: number | null;
      batchClaimLineId?: any | null;
      batchClaimId: any;
      auditFindingRuleTypeByAuditFindingRuleType?: {
        __typename?: "AuditFindingRuleType";
        type: string;
        description?: string | null;
        displayName: string;
      } | null;
      author?: {
        __typename?: "AuditorUser";
        id: any;
        firstName: string;
        lastName: string;
        email: string;
      } | null;
      reviewedAuditFinding?: {
        __typename?: "ReviewedAuditFinding";
        id: any;
        accepted: boolean;
        deleted: boolean;
        createdAt: any;
        updatedAt: any;
        rationale?: string | null;
      } | null;
      batchClaimLineItemAudit?: {
        __typename?: "BatchClaimLineItemAudit";
        id: any;
        batchClaimLineItem?: {
          __typename?: "BatchClaimLineItem";
          id: any;
          dateOfServiceStart: any;
          dateOfServiceEnd?: any | null;
          revCode: string;
          procedureCode?: string | null;
          itemNumber?: string | null;
          description: string;
          units: number;
          unitCharge: any;
          batchClaimLineId?: any | null;
          authorId?: any | null;
          createdAt: any;
          updatedAt: any;
          alaRowId?: any | null;
          ibLineId?: any | null;
          batchClaimLineItemNnIbLines: {
            __typename?: "BatchClaimLineItemNnIbLinesConnection";
            nodes: Array<{
              __typename?: "BatchClaimLineItemNnIbLine";
              nnIbLineId: any;
              nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
            } | null>;
          };
        } | null;
      } | null;
      reportedFindings: {
        __typename?: "ReportedFindingsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "ReportedFinding";
          id: any;
          auditFindingReportId: any;
        } | null>;
      };
      reviews: {
        __typename?: "AuditFindingReviewsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "AuditFindingReview";
          id: any;
          createdAt: any;
          reviewId: any;
          originalId: any;
          auditFinding?: {
            __typename?: "AuditFinding";
            id: any;
            improperPaymentUnitsCharged: number;
            improperPaymentCost: number;
            improperPaymentReason: string;
            needsReview: boolean;
            auditorReviewed: boolean;
            auditorComment?: string | null;
            auditType?: AuditTypeEnum | null;
            superseded?: boolean | null;
            auditFindingRuleType: string;
            auditFindingSeedType: AuditFindingSeedType;
            confidence: any;
            isOriginal?: boolean | null;
            isActive?: boolean | null;
            accepted?: boolean | null;
            createdAt: any;
            metaDataNcciMueOhpMueValue?: number | null;
            metaDataNcciMueAjudicationValue?: number | null;
            metaDataNcciPtpModifier?: number | null;
            metaDataNcciPtpCode?: string | null;
            metaDataEmCode?: string | null;
            metaDataEmOriginalLevel?: number | null;
            metaDataEmCorrectLevel?: number | null;
            metaDataAocPrimaryCode?: string | null;
            metaDataPricingCorrectPrice?: any | null;
            metaDataUnitsCorrectUnits?: number | null;
            discrepantAmount?: number | null;
            batchClaimLineId?: any | null;
            batchClaimId: any;
            auditFindingRuleTypeByAuditFindingRuleType?: {
              __typename?: "AuditFindingRuleType";
              type: string;
              description?: string | null;
              displayName: string;
            } | null;
            author?: {
              __typename?: "AuditorUser";
              id: any;
              firstName: string;
              lastName: string;
              email: string;
            } | null;
            reviewedAuditFinding?: {
              __typename?: "ReviewedAuditFinding";
              id: any;
              accepted: boolean;
              deleted: boolean;
              createdAt: any;
              updatedAt: any;
              rationale?: string | null;
            } | null;
            batchClaimLineItemAudit?: {
              __typename?: "BatchClaimLineItemAudit";
              id: any;
              batchClaimLineItem?: {
                __typename?: "BatchClaimLineItem";
                id: any;
                dateOfServiceStart: any;
                dateOfServiceEnd?: any | null;
                revCode: string;
                procedureCode?: string | null;
                itemNumber?: string | null;
                description: string;
                units: number;
                unitCharge: any;
                batchClaimLineId?: any | null;
                authorId?: any | null;
                createdAt: any;
                updatedAt: any;
                alaRowId?: any | null;
                ibLineId?: any | null;
                batchClaimLineItemNnIbLines: {
                  __typename?: "BatchClaimLineItemNnIbLinesConnection";
                  nodes: Array<{
                    __typename?: "BatchClaimLineItemNnIbLine";
                    nnIbLineId: any;
                    nnIbLine?: {
                      __typename?: "NnIbLine";
                      fingerprint: any;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null;
            reportedFindings: {
              __typename?: "ReportedFindingsConnection";
              totalCount: number;
              nodes: Array<{
                __typename?: "ReportedFinding";
                id: any;
                auditFindingReportId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
  auditFindingRuleTypeByAuditFindingRuleType?: {
    __typename?: "AuditFindingRuleType";
    type: string;
    description?: string | null;
    displayName: string;
  } | null;
  author?: {
    __typename?: "AuditorUser";
    id: any;
    firstName: string;
    lastName: string;
    email: string;
  } | null;
  reviewedAuditFinding?: {
    __typename?: "ReviewedAuditFinding";
    id: any;
    accepted: boolean;
    deleted: boolean;
    createdAt: any;
    updatedAt: any;
    rationale?: string | null;
  } | null;
  batchClaimLineItemAudit?: {
    __typename?: "BatchClaimLineItemAudit";
    id: any;
    batchClaimLineItem?: {
      __typename?: "BatchClaimLineItem";
      id: any;
      dateOfServiceStart: any;
      dateOfServiceEnd?: any | null;
      revCode: string;
      procedureCode?: string | null;
      itemNumber?: string | null;
      description: string;
      units: number;
      unitCharge: any;
      batchClaimLineId?: any | null;
      authorId?: any | null;
      createdAt: any;
      updatedAt: any;
      alaRowId?: any | null;
      ibLineId?: any | null;
      batchClaimLineItemNnIbLines: {
        __typename?: "BatchClaimLineItemNnIbLinesConnection";
        nodes: Array<{
          __typename?: "BatchClaimLineItemNnIbLine";
          nnIbLineId: any;
          nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
        } | null>;
      };
    } | null;
  } | null;
  reportedFindings: {
    __typename?: "ReportedFindingsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "ReportedFinding";
      id: any;
      auditFindingReportId: any;
    } | null>;
  };
  reviews: {
    __typename?: "AuditFindingReviewsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "AuditFindingReview";
      id: any;
      createdAt: any;
      reviewId: any;
      originalId: any;
      auditFinding?: {
        __typename?: "AuditFinding";
        id: any;
        improperPaymentUnitsCharged: number;
        improperPaymentCost: number;
        improperPaymentReason: string;
        needsReview: boolean;
        auditorReviewed: boolean;
        auditorComment?: string | null;
        auditType?: AuditTypeEnum | null;
        superseded?: boolean | null;
        auditFindingRuleType: string;
        auditFindingSeedType: AuditFindingSeedType;
        confidence: any;
        isOriginal?: boolean | null;
        isActive?: boolean | null;
        accepted?: boolean | null;
        createdAt: any;
        metaDataNcciMueOhpMueValue?: number | null;
        metaDataNcciMueAjudicationValue?: number | null;
        metaDataNcciPtpModifier?: number | null;
        metaDataNcciPtpCode?: string | null;
        metaDataEmCode?: string | null;
        metaDataEmOriginalLevel?: number | null;
        metaDataEmCorrectLevel?: number | null;
        metaDataAocPrimaryCode?: string | null;
        metaDataPricingCorrectPrice?: any | null;
        metaDataUnitsCorrectUnits?: number | null;
        discrepantAmount?: number | null;
        batchClaimLineId?: any | null;
        batchClaimId: any;
        auditFindingRuleTypeByAuditFindingRuleType?: {
          __typename?: "AuditFindingRuleType";
          type: string;
          description?: string | null;
          displayName: string;
        } | null;
        author?: {
          __typename?: "AuditorUser";
          id: any;
          firstName: string;
          lastName: string;
          email: string;
        } | null;
        reviewedAuditFinding?: {
          __typename?: "ReviewedAuditFinding";
          id: any;
          accepted: boolean;
          deleted: boolean;
          createdAt: any;
          updatedAt: any;
          rationale?: string | null;
        } | null;
        batchClaimLineItemAudit?: {
          __typename?: "BatchClaimLineItemAudit";
          id: any;
          batchClaimLineItem?: {
            __typename?: "BatchClaimLineItem";
            id: any;
            dateOfServiceStart: any;
            dateOfServiceEnd?: any | null;
            revCode: string;
            procedureCode?: string | null;
            itemNumber?: string | null;
            description: string;
            units: number;
            unitCharge: any;
            batchClaimLineId?: any | null;
            authorId?: any | null;
            createdAt: any;
            updatedAt: any;
            alaRowId?: any | null;
            ibLineId?: any | null;
            batchClaimLineItemNnIbLines: {
              __typename?: "BatchClaimLineItemNnIbLinesConnection";
              nodes: Array<{
                __typename?: "BatchClaimLineItemNnIbLine";
                nnIbLineId: any;
                nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
              } | null>;
            };
          } | null;
        } | null;
        reportedFindings: {
          __typename?: "ReportedFindingsConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "ReportedFinding";
            id: any;
            auditFindingReportId: any;
          } | null>;
        };
      } | null;
    } | null>;
  };
};

export type versionAuditFindingFragmentFragment = {
  __typename?: "AuditFinding";
  id: any;
  improperPaymentUnitsCharged: number;
  improperPaymentCost: number;
  improperPaymentReason: string;
  needsReview: boolean;
  auditorReviewed: boolean;
  auditorComment?: string | null;
  auditType?: AuditTypeEnum | null;
  superseded?: boolean | null;
  auditFindingRuleType: string;
  auditFindingSeedType: AuditFindingSeedType;
  confidence: any;
  isOriginal?: boolean | null;
  isActive?: boolean | null;
  accepted?: boolean | null;
  createdAt: any;
  batchClaimLineId?: any | null;
  batchClaimId: any;
  batchClaimLineItemAudit?: {
    __typename?: "BatchClaimLineItemAudit";
    id: any;
    batchClaimLineItem?: {
      __typename?: "BatchClaimLineItem";
      id: any;
      dateOfServiceStart: any;
      dateOfServiceEnd?: any | null;
      revCode: string;
      procedureCode?: string | null;
      itemNumber?: string | null;
      description: string;
      units: number;
      unitCharge: any;
      batchClaimLineId?: any | null;
      authorId?: any | null;
      createdAt: any;
      updatedAt: any;
      alaRowId?: any | null;
      ibLineId?: any | null;
      batchClaimLineItemNnIbLines: {
        __typename?: "BatchClaimLineItemNnIbLinesConnection";
        nodes: Array<{
          __typename?: "BatchClaimLineItemNnIbLine";
          nnIbLineId: any;
          nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type createIbLineFindingsMutationVariables = Exact<{
  input: CreateIbLineFindingsInput;
}>;

export type createIbLineFindingsMutation = {
  __typename?: "Mutation";
  createAuditFindings: {
    __typename?: "CreateAuditFindingsPayload";
    auditFindings: Array<{
      __typename?: "AuditFinding";
      id: any;
      improperPaymentUnitsCharged: number;
      improperPaymentCost: number;
      improperPaymentReason: string;
      needsReview: boolean;
      auditorReviewed: boolean;
      auditorComment?: string | null;
      auditType?: AuditTypeEnum | null;
      superseded?: boolean | null;
      auditFindingRuleType: string;
      auditFindingSeedType: AuditFindingSeedType;
      confidence: any;
      isOriginal?: boolean | null;
      isActive?: boolean | null;
      accepted?: boolean | null;
      createdAt: any;
      metaDataNcciMueOhpMueValue?: number | null;
      metaDataNcciMueAjudicationValue?: number | null;
      metaDataNcciPtpModifier?: number | null;
      metaDataNcciPtpCode?: string | null;
      metaDataEmCode?: string | null;
      metaDataEmOriginalLevel?: number | null;
      metaDataEmCorrectLevel?: number | null;
      metaDataAocPrimaryCode?: string | null;
      metaDataPricingCorrectPrice?: any | null;
      metaDataUnitsCorrectUnits?: number | null;
      discrepantAmount?: number | null;
      batchClaimLineId?: any | null;
      batchClaimId: any;
      original?: {
        __typename?: "AuditFindingReview";
        id: any;
        createdAt: any;
        reviewId: any;
        originalId: any;
        auditFinding?: {
          __typename?: "AuditFinding";
          id: any;
          improperPaymentUnitsCharged: number;
          improperPaymentCost: number;
          improperPaymentReason: string;
          needsReview: boolean;
          auditorReviewed: boolean;
          auditorComment?: string | null;
          auditType?: AuditTypeEnum | null;
          superseded?: boolean | null;
          auditFindingRuleType: string;
          auditFindingSeedType: AuditFindingSeedType;
          confidence: any;
          isOriginal?: boolean | null;
          isActive?: boolean | null;
          accepted?: boolean | null;
          createdAt: any;
          metaDataNcciMueOhpMueValue?: number | null;
          metaDataNcciMueAjudicationValue?: number | null;
          metaDataNcciPtpModifier?: number | null;
          metaDataNcciPtpCode?: string | null;
          metaDataEmCode?: string | null;
          metaDataEmOriginalLevel?: number | null;
          metaDataEmCorrectLevel?: number | null;
          metaDataAocPrimaryCode?: string | null;
          metaDataPricingCorrectPrice?: any | null;
          metaDataUnitsCorrectUnits?: number | null;
          discrepantAmount?: number | null;
          batchClaimLineId?: any | null;
          batchClaimId: any;
          auditFindingRuleTypeByAuditFindingRuleType?: {
            __typename?: "AuditFindingRuleType";
            type: string;
            description?: string | null;
            displayName: string;
          } | null;
          author?: {
            __typename?: "AuditorUser";
            id: any;
            firstName: string;
            lastName: string;
            email: string;
          } | null;
          reviewedAuditFinding?: {
            __typename?: "ReviewedAuditFinding";
            id: any;
            accepted: boolean;
            deleted: boolean;
            createdAt: any;
            updatedAt: any;
            rationale?: string | null;
          } | null;
          batchClaimLineItemAudit?: {
            __typename?: "BatchClaimLineItemAudit";
            id: any;
            batchClaimLineItem?: {
              __typename?: "BatchClaimLineItem";
              id: any;
              dateOfServiceStart: any;
              dateOfServiceEnd?: any | null;
              revCode: string;
              procedureCode?: string | null;
              itemNumber?: string | null;
              description: string;
              units: number;
              unitCharge: any;
              batchClaimLineId?: any | null;
              authorId?: any | null;
              createdAt: any;
              updatedAt: any;
              alaRowId?: any | null;
              ibLineId?: any | null;
              batchClaimLineItemNnIbLines: {
                __typename?: "BatchClaimLineItemNnIbLinesConnection";
                nodes: Array<{
                  __typename?: "BatchClaimLineItemNnIbLine";
                  nnIbLineId: any;
                  nnIbLine?: {
                    __typename?: "NnIbLine";
                    fingerprint: any;
                  } | null;
                } | null>;
              };
            } | null;
          } | null;
          reportedFindings: {
            __typename?: "ReportedFindingsConnection";
            totalCount: number;
            nodes: Array<{
              __typename?: "ReportedFinding";
              id: any;
              auditFindingReportId: any;
            } | null>;
          };
          reviews: {
            __typename?: "AuditFindingReviewsConnection";
            totalCount: number;
            nodes: Array<{
              __typename?: "AuditFindingReview";
              id: any;
              createdAt: any;
              reviewId: any;
              originalId: any;
              auditFinding?: {
                __typename?: "AuditFinding";
                id: any;
                improperPaymentUnitsCharged: number;
                improperPaymentCost: number;
                improperPaymentReason: string;
                needsReview: boolean;
                auditorReviewed: boolean;
                auditorComment?: string | null;
                auditType?: AuditTypeEnum | null;
                superseded?: boolean | null;
                auditFindingRuleType: string;
                auditFindingSeedType: AuditFindingSeedType;
                confidence: any;
                isOriginal?: boolean | null;
                isActive?: boolean | null;
                accepted?: boolean | null;
                createdAt: any;
                metaDataNcciMueOhpMueValue?: number | null;
                metaDataNcciMueAjudicationValue?: number | null;
                metaDataNcciPtpModifier?: number | null;
                metaDataNcciPtpCode?: string | null;
                metaDataEmCode?: string | null;
                metaDataEmOriginalLevel?: number | null;
                metaDataEmCorrectLevel?: number | null;
                metaDataAocPrimaryCode?: string | null;
                metaDataPricingCorrectPrice?: any | null;
                metaDataUnitsCorrectUnits?: number | null;
                discrepantAmount?: number | null;
                batchClaimLineId?: any | null;
                batchClaimId: any;
                auditFindingRuleTypeByAuditFindingRuleType?: {
                  __typename?: "AuditFindingRuleType";
                  type: string;
                  description?: string | null;
                  displayName: string;
                } | null;
                author?: {
                  __typename?: "AuditorUser";
                  id: any;
                  firstName: string;
                  lastName: string;
                  email: string;
                } | null;
                reviewedAuditFinding?: {
                  __typename?: "ReviewedAuditFinding";
                  id: any;
                  accepted: boolean;
                  deleted: boolean;
                  createdAt: any;
                  updatedAt: any;
                  rationale?: string | null;
                } | null;
                batchClaimLineItemAudit?: {
                  __typename?: "BatchClaimLineItemAudit";
                  id: any;
                  batchClaimLineItem?: {
                    __typename?: "BatchClaimLineItem";
                    id: any;
                    dateOfServiceStart: any;
                    dateOfServiceEnd?: any | null;
                    revCode: string;
                    procedureCode?: string | null;
                    itemNumber?: string | null;
                    description: string;
                    units: number;
                    unitCharge: any;
                    batchClaimLineId?: any | null;
                    authorId?: any | null;
                    createdAt: any;
                    updatedAt: any;
                    alaRowId?: any | null;
                    ibLineId?: any | null;
                    batchClaimLineItemNnIbLines: {
                      __typename?: "BatchClaimLineItemNnIbLinesConnection";
                      nodes: Array<{
                        __typename?: "BatchClaimLineItemNnIbLine";
                        nnIbLineId: any;
                        nnIbLine?: {
                          __typename?: "NnIbLine";
                          fingerprint: any;
                        } | null;
                      } | null>;
                    };
                  } | null;
                } | null;
                reportedFindings: {
                  __typename?: "ReportedFindingsConnection";
                  totalCount: number;
                  nodes: Array<{
                    __typename?: "ReportedFinding";
                    id: any;
                    auditFindingReportId: any;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null;
      } | null;
      auditFindingRuleTypeByAuditFindingRuleType?: {
        __typename?: "AuditFindingRuleType";
        type: string;
        description?: string | null;
        displayName: string;
      } | null;
      author?: {
        __typename?: "AuditorUser";
        id: any;
        firstName: string;
        lastName: string;
        email: string;
      } | null;
      reviewedAuditFinding?: {
        __typename?: "ReviewedAuditFinding";
        id: any;
        accepted: boolean;
        deleted: boolean;
        createdAt: any;
        updatedAt: any;
        rationale?: string | null;
      } | null;
      batchClaimLineItemAudit?: {
        __typename?: "BatchClaimLineItemAudit";
        id: any;
        batchClaimLineItem?: {
          __typename?: "BatchClaimLineItem";
          id: any;
          dateOfServiceStart: any;
          dateOfServiceEnd?: any | null;
          revCode: string;
          procedureCode?: string | null;
          itemNumber?: string | null;
          description: string;
          units: number;
          unitCharge: any;
          batchClaimLineId?: any | null;
          authorId?: any | null;
          createdAt: any;
          updatedAt: any;
          alaRowId?: any | null;
          ibLineId?: any | null;
          batchClaimLineItemNnIbLines: {
            __typename?: "BatchClaimLineItemNnIbLinesConnection";
            nodes: Array<{
              __typename?: "BatchClaimLineItemNnIbLine";
              nnIbLineId: any;
              nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
            } | null>;
          };
        } | null;
      } | null;
      reportedFindings: {
        __typename?: "ReportedFindingsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "ReportedFinding";
          id: any;
          auditFindingReportId: any;
        } | null>;
      };
      reviews: {
        __typename?: "AuditFindingReviewsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "AuditFindingReview";
          id: any;
          createdAt: any;
          reviewId: any;
          originalId: any;
          auditFinding?: {
            __typename?: "AuditFinding";
            id: any;
            improperPaymentUnitsCharged: number;
            improperPaymentCost: number;
            improperPaymentReason: string;
            needsReview: boolean;
            auditorReviewed: boolean;
            auditorComment?: string | null;
            auditType?: AuditTypeEnum | null;
            superseded?: boolean | null;
            auditFindingRuleType: string;
            auditFindingSeedType: AuditFindingSeedType;
            confidence: any;
            isOriginal?: boolean | null;
            isActive?: boolean | null;
            accepted?: boolean | null;
            createdAt: any;
            metaDataNcciMueOhpMueValue?: number | null;
            metaDataNcciMueAjudicationValue?: number | null;
            metaDataNcciPtpModifier?: number | null;
            metaDataNcciPtpCode?: string | null;
            metaDataEmCode?: string | null;
            metaDataEmOriginalLevel?: number | null;
            metaDataEmCorrectLevel?: number | null;
            metaDataAocPrimaryCode?: string | null;
            metaDataPricingCorrectPrice?: any | null;
            metaDataUnitsCorrectUnits?: number | null;
            discrepantAmount?: number | null;
            batchClaimLineId?: any | null;
            batchClaimId: any;
            auditFindingRuleTypeByAuditFindingRuleType?: {
              __typename?: "AuditFindingRuleType";
              type: string;
              description?: string | null;
              displayName: string;
            } | null;
            author?: {
              __typename?: "AuditorUser";
              id: any;
              firstName: string;
              lastName: string;
              email: string;
            } | null;
            reviewedAuditFinding?: {
              __typename?: "ReviewedAuditFinding";
              id: any;
              accepted: boolean;
              deleted: boolean;
              createdAt: any;
              updatedAt: any;
              rationale?: string | null;
            } | null;
            batchClaimLineItemAudit?: {
              __typename?: "BatchClaimLineItemAudit";
              id: any;
              batchClaimLineItem?: {
                __typename?: "BatchClaimLineItem";
                id: any;
                dateOfServiceStart: any;
                dateOfServiceEnd?: any | null;
                revCode: string;
                procedureCode?: string | null;
                itemNumber?: string | null;
                description: string;
                units: number;
                unitCharge: any;
                batchClaimLineId?: any | null;
                authorId?: any | null;
                createdAt: any;
                updatedAt: any;
                alaRowId?: any | null;
                ibLineId?: any | null;
                batchClaimLineItemNnIbLines: {
                  __typename?: "BatchClaimLineItemNnIbLinesConnection";
                  nodes: Array<{
                    __typename?: "BatchClaimLineItemNnIbLine";
                    nnIbLineId: any;
                    nnIbLine?: {
                      __typename?: "NnIbLine";
                      fingerprint: any;
                    } | null;
                  } | null>;
                };
              } | null;
            } | null;
            reportedFindings: {
              __typename?: "ReportedFindingsConnection";
              totalCount: number;
              nodes: Array<{
                __typename?: "ReportedFinding";
                id: any;
                auditFindingReportId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    }>;
  };
};

export type versionAuditFindingsMutationVariables = Exact<{
  input: VersionAuditFindingsInput;
}>;

export type versionAuditFindingsMutation = {
  __typename?: "Mutation";
  createAuditFindings: {
    __typename?: "CreateAuditFindingsPayload";
    auditFindings: Array<{
      __typename?: "AuditFinding";
      id: any;
      improperPaymentUnitsCharged: number;
      improperPaymentCost: number;
      improperPaymentReason: string;
      needsReview: boolean;
      auditorReviewed: boolean;
      auditorComment?: string | null;
      auditType?: AuditTypeEnum | null;
      superseded?: boolean | null;
      auditFindingRuleType: string;
      auditFindingSeedType: AuditFindingSeedType;
      confidence: any;
      isOriginal?: boolean | null;
      isActive?: boolean | null;
      accepted?: boolean | null;
      createdAt: any;
      batchClaimLineId?: any | null;
      batchClaimId: any;
      batchClaimLineItemAudit?: {
        __typename?: "BatchClaimLineItemAudit";
        id: any;
        batchClaimLineItem?: {
          __typename?: "BatchClaimLineItem";
          id: any;
          dateOfServiceStart: any;
          dateOfServiceEnd?: any | null;
          revCode: string;
          procedureCode?: string | null;
          itemNumber?: string | null;
          description: string;
          units: number;
          unitCharge: any;
          batchClaimLineId?: any | null;
          authorId?: any | null;
          createdAt: any;
          updatedAt: any;
          alaRowId?: any | null;
          ibLineId?: any | null;
          batchClaimLineItemNnIbLines: {
            __typename?: "BatchClaimLineItemNnIbLinesConnection";
            nodes: Array<{
              __typename?: "BatchClaimLineItemNnIbLine";
              nnIbLineId: any;
              nnIbLine?: { __typename?: "NnIbLine"; fingerprint: any } | null;
            } | null>;
          };
        } | null;
      } | null;
    }>;
  };
};

export type autodorFindingFragmentFragment = {
  __typename: "AutodorFinding";
  auditType: string;
  claimId: any;
  createdAt: string;
  s3Key: string;
  denialCode: string;
  description: string;
  ibLineId?: any | null;
  ibLineVersionFingerprint?: any | null;
  id: any;
  improperCharge: number;
  improperUnits: number;
  seedType: SeedType;
  stale: boolean;
  ubLineId?: any | null;
  autodorMetadata: {
    __typename?: "AutodorMetadata";
    auditType: string;
    billType: AutodorBillType;
    dateOfServiceStart: any;
    lineNumber?: number | null;
    procedureCode: string;
    procedureCodeModifier?: string | null;
    quarter: string;
    unitCharge: number;
    units: number;
    versionId: any;
    year: string;
    rule: {
      __typename: "AuditRule";
      _type: string;
      genericRule: any;
      ptp?: {
        __typename: "PTPAuditRule";
        colOneCode: string;
        colTwoCode: string;
        extendedDescription: string;
        rationale: string;
      } | null;
      mue?: {
        __typename: "MUEAuditRule";
        description: string;
        code: string;
        num: number;
        editType: string;
        mai: string;
        rationale: {
          __typename: "MUEAuditRuleRationale";
          value: string;
          enumeration: string;
        };
      } | null;
      nonBillable?: {
        __typename: "NonBillableAuditRule";
        code: string;
        description: string;
      } | null;
    };
  };
};

export type claimQueryVariables = Exact<{
  id?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type claimQuery = {
  __typename?: "Query";
  claim?: {
    __typename: "Claim";
    id: any;
    icn: string;
    findings: Array<{
      __typename: "Finding";
      autodorFindingId?: any | null;
      autodorFindingS3Key?: string | null;
      accepted?: boolean | null;
      createdAt?: any | null;
      confidence?: string | null;
      id: any;
      improperPaymentCost?: number | null;
      improperPaymentReason?: string | null;
      improperPaymentUnitsCharged?: number | null;
      isOriginal?: boolean | null;
      metaDataAocPrimaryCode?: string | null;
      metaDataEmCode?: string | null;
      metaDataEmCorrectLevel?: number | null;
      metaDataEmOriginalLevel?: number | null;
      metaDataNcciMueAdjudicationValue?: number | null;
      metaDataNcciMueOhpMueValue?: number | null;
      metaDataNcciPtpCode?: string | null;
      metaDataNcciPtpModifier?: number | null;
      metaDataPricingCorrectPrice?: number | null;
      metaDataUnitsCorrectUnits?: number | null;
      needsReview: boolean;
      ruleType?: string | null;
      seedType?: string | null;
      superseded?: boolean | null;
      updatedAt?: any | null;
      findingItemizedBillData?: {
        __typename: "FindingItemizedBillData";
        alaRowId?: any | null;
        authorId?: any | null;
        batchClaimLineId?: any | null;
        createdAt?: any | null;
        dateOfServiceEnd?: any | null;
        dateOfServiceStart?: any | null;
        description?: string | null;
        ibLineId?: any | null;
        id?: any | null;
        itemNumber?: string | null;
        lineNumber?: number | null;
        procedureCode?: string | null;
        procedureCodeModifier?: string | null;
        revCode?: string | null;
        s3InputSource?: string | null;
        stale?: boolean | null;
        unitCharge?: number | null;
        units?: number | null;
        updatedAt?: any | null;
        versionFingerprint?: any | null;
        ibLineVersionFingerprint?: any | null;
        originalBilledAmount?: number | null;
      } | null;
      author: {
        __typename?: "FindingAuthor";
        firstName: string;
        id: any;
        lastName: string;
        userType: string;
      };
      latestReview?: {
        __typename?: "LatestReview";
        authorId: string;
        createdAt: any;
        rationale?: string | null;
      } | null;
    }>;
    autodor?: {
      __typename?: "Autodor";
      claimId: any;
      sinks: Array<{
        __typename?: "AutodorSink";
        s3Key: string;
        timestamp: string;
      }>;
      autodorFindings: Array<{
        __typename: "AutodorFinding";
        auditType: string;
        claimId: any;
        createdAt: string;
        s3Key: string;
        denialCode: string;
        description: string;
        ibLineId?: any | null;
        ibLineVersionFingerprint?: any | null;
        id: any;
        improperCharge: number;
        improperUnits: number;
        seedType: SeedType;
        stale: boolean;
        ubLineId?: any | null;
        autodorMetadata: {
          __typename?: "AutodorMetadata";
          auditType: string;
          billType: AutodorBillType;
          dateOfServiceStart: any;
          lineNumber?: number | null;
          procedureCode: string;
          procedureCodeModifier?: string | null;
          quarter: string;
          unitCharge: number;
          units: number;
          versionId: any;
          year: string;
          rule: {
            __typename: "AuditRule";
            _type: string;
            genericRule: any;
            ptp?: {
              __typename: "PTPAuditRule";
              colOneCode: string;
              colTwoCode: string;
              extendedDescription: string;
              rationale: string;
            } | null;
            mue?: {
              __typename: "MUEAuditRule";
              description: string;
              code: string;
              num: number;
              editType: string;
              mai: string;
              rationale: {
                __typename: "MUEAuditRuleRationale";
                value: string;
                enumeration: string;
              };
            } | null;
            nonBillable?: {
              __typename: "NonBillableAuditRule";
              code: string;
              description: string;
            } | null;
          };
        };
      }>;
    } | null;
    itemizedBillLines: Array<{
      __typename: "ItemizedBillLine";
      alaRowId?: any | null;
      id: any;
      dateOfService?: any | null;
      itemNumber?: string | null;
      revCode?: string | null;
      description?: string | null;
      procedureCode?: string | null;
      procedureCodeModifier?: string | null;
      units?: number | null;
      totalCharge?: number | null;
      lineNumber?: number | null;
      findings: Array<any>;
      s3InputSourceId?: any | null;
      versionFingerprint?: any | null;
      groupCode?: string | null;
    }>;
  } | null;
};

export type providerClaimQueryVariables = Exact<{
  id?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type providerClaimQuery = {
  __typename?: "Query";
  claim?: {
    __typename: "Claim";
    id: any;
    icn: string;
    itemizedBillLines: Array<{
      __typename: "ItemizedBillLine";
      alaRowId?: any | null;
      id: any;
      dateOfService?: any | null;
      itemNumber?: string | null;
      revCode?: string | null;
      description?: string | null;
      procedureCode?: string | null;
      procedureCodeModifier?: string | null;
      units?: number | null;
      totalCharge?: number | null;
      lineNumber?: number | null;
      s3InputSourceId?: any | null;
      versionFingerprint?: any | null;
    }>;
  } | null;
};

export type claimFindingsQueryVariables = Exact<{
  id?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type claimFindingsQuery = {
  __typename?: "Query";
  claim?: {
    __typename: "Claim";
    id: any;
    icn: string;
    findings: Array<{
      __typename: "Finding";
      autodorFindingId?: any | null;
      autodorFindingS3Key?: string | null;
      accepted?: boolean | null;
      createdAt?: any | null;
      confidence?: string | null;
      id: any;
      improperPaymentCost?: number | null;
      improperPaymentReason?: string | null;
      improperPaymentUnitsCharged?: number | null;
      isOriginal?: boolean | null;
      metaDataAocPrimaryCode?: string | null;
      metaDataEmCode?: string | null;
      metaDataEmCorrectLevel?: number | null;
      metaDataEmOriginalLevel?: number | null;
      metaDataNcciMueAdjudicationValue?: number | null;
      metaDataNcciMueOhpMueValue?: number | null;
      metaDataNcciPtpCode?: string | null;
      metaDataNcciPtpModifier?: number | null;
      metaDataPricingCorrectPrice?: number | null;
      metaDataUnitsCorrectUnits?: number | null;
      needsReview: boolean;
      ruleType?: string | null;
      seedType?: string | null;
      superseded?: boolean | null;
      updatedAt?: any | null;
      findingItemizedBillData?: {
        __typename: "FindingItemizedBillData";
        alaRowId?: any | null;
        authorId?: any | null;
        batchClaimLineId?: any | null;
        createdAt?: any | null;
        dateOfServiceEnd?: any | null;
        dateOfServiceStart?: any | null;
        description?: string | null;
        ibLineId?: any | null;
        id?: any | null;
        itemNumber?: string | null;
        lineNumber?: number | null;
        procedureCode?: string | null;
        procedureCodeModifier?: string | null;
        revCode?: string | null;
        s3InputSource?: string | null;
        stale?: boolean | null;
        unitCharge?: number | null;
        units?: number | null;
        updatedAt?: any | null;
        versionFingerprint?: any | null;
        ibLineVersionFingerprint?: any | null;
        originalBilledAmount?: number | null;
      } | null;
      author: {
        __typename?: "FindingAuthor";
        firstName: string;
        id: any;
        lastName: string;
        userType: string;
      };
      latestReview?: {
        __typename?: "LatestReview";
        authorId: string;
        createdAt: any;
        rationale?: string | null;
      } | null;
    }>;
  } | null;
};

export type FindingItemizedBillDataFragmentFragment = {
  __typename: "FindingItemizedBillData";
  alaRowId?: any | null;
  authorId?: any | null;
  batchClaimLineId?: any | null;
  createdAt?: any | null;
  dateOfServiceEnd?: any | null;
  dateOfServiceStart?: any | null;
  description?: string | null;
  ibLineId?: any | null;
  id?: any | null;
  itemNumber?: string | null;
  lineNumber?: number | null;
  procedureCode?: string | null;
  procedureCodeModifier?: string | null;
  revCode?: string | null;
  s3InputSource?: string | null;
  stale?: boolean | null;
  unitCharge?: number | null;
  units?: number | null;
  updatedAt?: any | null;
  versionFingerprint?: any | null;
  ibLineVersionFingerprint?: any | null;
  originalBilledAmount?: number | null;
};

export type FindingFragmentFragment = {
  __typename: "Finding";
  autodorFindingId?: any | null;
  autodorFindingS3Key?: string | null;
  accepted?: boolean | null;
  createdAt?: any | null;
  confidence?: string | null;
  id: any;
  improperPaymentCost?: number | null;
  improperPaymentReason?: string | null;
  improperPaymentUnitsCharged?: number | null;
  isOriginal?: boolean | null;
  metaDataAocPrimaryCode?: string | null;
  metaDataEmCode?: string | null;
  metaDataEmCorrectLevel?: number | null;
  metaDataEmOriginalLevel?: number | null;
  metaDataNcciMueAdjudicationValue?: number | null;
  metaDataNcciMueOhpMueValue?: number | null;
  metaDataNcciPtpCode?: string | null;
  metaDataNcciPtpModifier?: number | null;
  metaDataPricingCorrectPrice?: number | null;
  metaDataUnitsCorrectUnits?: number | null;
  needsReview: boolean;
  ruleType?: string | null;
  seedType?: string | null;
  superseded?: boolean | null;
  updatedAt?: any | null;
  findingItemizedBillData?: {
    __typename: "FindingItemizedBillData";
    alaRowId?: any | null;
    authorId?: any | null;
    batchClaimLineId?: any | null;
    createdAt?: any | null;
    dateOfServiceEnd?: any | null;
    dateOfServiceStart?: any | null;
    description?: string | null;
    ibLineId?: any | null;
    id?: any | null;
    itemNumber?: string | null;
    lineNumber?: number | null;
    procedureCode?: string | null;
    procedureCodeModifier?: string | null;
    revCode?: string | null;
    s3InputSource?: string | null;
    stale?: boolean | null;
    unitCharge?: number | null;
    units?: number | null;
    updatedAt?: any | null;
    versionFingerprint?: any | null;
    ibLineVersionFingerprint?: any | null;
    originalBilledAmount?: number | null;
  } | null;
  author: {
    __typename?: "FindingAuthor";
    firstName: string;
    id: any;
    lastName: string;
    userType: string;
  };
  latestReview?: {
    __typename?: "LatestReview";
    authorId: string;
    createdAt: any;
    rationale?: string | null;
  } | null;
};

export type FindingQueryVariables = Exact<{
  id?: InputMaybe<Scalars["UUID"]["input"]>;
}>;

export type FindingQuery = {
  __typename?: "Query";
  finding?: {
    __typename: "Finding";
    autodorFindingId?: any | null;
    autodorFindingS3Key?: string | null;
    accepted?: boolean | null;
    createdAt?: any | null;
    confidence?: string | null;
    id: any;
    improperPaymentCost?: number | null;
    improperPaymentReason?: string | null;
    improperPaymentUnitsCharged?: number | null;
    isOriginal?: boolean | null;
    metaDataAocPrimaryCode?: string | null;
    metaDataEmCode?: string | null;
    metaDataEmCorrectLevel?: number | null;
    metaDataEmOriginalLevel?: number | null;
    metaDataNcciMueAdjudicationValue?: number | null;
    metaDataNcciMueOhpMueValue?: number | null;
    metaDataNcciPtpCode?: string | null;
    metaDataNcciPtpModifier?: number | null;
    metaDataPricingCorrectPrice?: number | null;
    metaDataUnitsCorrectUnits?: number | null;
    needsReview: boolean;
    ruleType?: string | null;
    seedType?: string | null;
    superseded?: boolean | null;
    updatedAt?: any | null;
    findingItemizedBillData?: {
      __typename: "FindingItemizedBillData";
      alaRowId?: any | null;
      authorId?: any | null;
      batchClaimLineId?: any | null;
      createdAt?: any | null;
      dateOfServiceEnd?: any | null;
      dateOfServiceStart?: any | null;
      description?: string | null;
      ibLineId?: any | null;
      id?: any | null;
      itemNumber?: string | null;
      lineNumber?: number | null;
      procedureCode?: string | null;
      procedureCodeModifier?: string | null;
      revCode?: string | null;
      s3InputSource?: string | null;
      stale?: boolean | null;
      unitCharge?: number | null;
      units?: number | null;
      updatedAt?: any | null;
      versionFingerprint?: any | null;
      ibLineVersionFingerprint?: any | null;
      originalBilledAmount?: number | null;
    } | null;
    author: {
      __typename?: "FindingAuthor";
      firstName: string;
      id: any;
      lastName: string;
      userType: string;
    };
    latestReview?: {
      __typename?: "LatestReview";
      authorId: string;
      createdAt: any;
      rationale?: string | null;
    } | null;
  } | null;
};

export type ItemizedBillLineFragmentFragment = {
  __typename: "ItemizedBillLine";
  alaRowId?: any | null;
  id: any;
  dateOfService?: any | null;
  itemNumber?: string | null;
  revCode?: string | null;
  description?: string | null;
  procedureCode?: string | null;
  procedureCodeModifier?: string | null;
  units?: number | null;
  totalCharge?: number | null;
  lineNumber?: number | null;
  findings: Array<any>;
  s3InputSourceId?: any | null;
  versionFingerprint?: any | null;
  groupCode?: string | null;
};

export type ProviderItemizedBillLineFragmentFragment = {
  __typename: "ItemizedBillLine";
  alaRowId?: any | null;
  id: any;
  dateOfService?: any | null;
  itemNumber?: string | null;
  revCode?: string | null;
  description?: string | null;
  procedureCode?: string | null;
  procedureCodeModifier?: string | null;
  units?: number | null;
  totalCharge?: number | null;
  lineNumber?: number | null;
  s3InputSourceId?: any | null;
  versionFingerprint?: any | null;
};

export type ItemizedBillLinesQueryVariables = Exact<{
  ids: Array<Scalars["UUID"]["input"]> | Scalars["UUID"]["input"];
}>;

export type ItemizedBillLinesQuery = {
  __typename?: "Query";
  itemizedBillLines?: Array<{
    __typename: "ItemizedBillLine";
    alaRowId?: any | null;
    id: any;
    dateOfService?: any | null;
    itemNumber?: string | null;
    revCode?: string | null;
    description?: string | null;
    procedureCode?: string | null;
    procedureCodeModifier?: string | null;
    units?: number | null;
    totalCharge?: number | null;
    lineNumber?: number | null;
    findings: Array<any>;
    s3InputSourceId?: any | null;
    versionFingerprint?: any | null;
    groupCode?: string | null;
  }> | null;
};

export type auditFindingRuleTypesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type auditFindingRuleTypesQuery = {
  __typename?: "Query";
  auditFindingRuleTypes?: {
    __typename?: "AuditFindingRuleTypesConnection";
    nodes: Array<{
      __typename?: "AuditFindingRuleType";
      type: string;
      claimDataType: AuditFindingSeedType;
      deprecated?: boolean | null;
      displayName: string;
      description?: string | null;
      rationaleTemplate?: string | null;
    } | null>;
  } | null;
};

export type batchClaimDocumentationFragmentFragment = {
  __typename?: "BatchClaim";
  id: any;
  auditDocumentation: Array<{
    __typename: "AuditDocumentation";
    id: any;
    subFile?: string | null;
    fileName: string;
    process: AuditDocumentationProcessTypes;
    size: number;
    signedUrl: string;
  }>;
};

export type documentationViewerQueryVariables = Exact<{
  claimId: Scalars["UUID"]["input"];
}>;

export type documentationViewerQuery = {
  __typename?: "Query";
  batchClaim?: {
    __typename?: "BatchClaim";
    id: any;
    auditDocumentation: Array<{
      __typename: "AuditDocumentation";
      id: any;
      subFile?: string | null;
      fileName: string;
      process: AuditDocumentationProcessTypes;
      size: number;
      signedUrl: string;
    }>;
  } | null;
};

export type reportDownloadQueryQueryVariables = Exact<{
  reportId: Scalars["UUID"]["input"];
}>;

export type reportDownloadQueryQuery = {
  __typename?: "Query";
  auditFindingReport?: {
    __typename?: "AuditFindingReport";
    id: any;
    title: string;
    downloadUrl: string;
  } | null;
};

export const reviewedAuditFindingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "reviewedAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewedAuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "rationale" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<reviewedAuditFindingFragmentFragment, unknown>;
export const batchClaimLineItemFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<batchClaimLineItemFragmentFragment, unknown>;
export const auditFindingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auditFindingRuleTypeByAuditFindingRuleType",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "discrepantAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviewedAuditFinding" },
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "reviewedAuditFindingFragment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reportedFindings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "auditFindingReportId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "reviewedAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewedAuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "rationale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<auditFindingFragmentFragment, unknown>;
export const auditFindingReviewFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFindingReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "originalId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<auditFindingReviewFragmentFragment, unknown>;
export const auditFindingReviewsFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviews" },
            name: { kind: "Name", value: "auditFindingReviewsByOriginalId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_AT_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingReviewFragment",
                        },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "auditFinding" },
                        name: { kind: "Name", value: "review" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "auditFindingFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "reviewedAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewedAuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "rationale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFindingReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "originalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auditFindingRuleTypeByAuditFindingRuleType",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "discrepantAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviewedAuditFinding" },
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "reviewedAuditFindingFragment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reportedFindings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "auditFindingReportId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<auditFindingReviewsFragmentFragment, unknown>;
export const auditFindingWithReviewFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingWithReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "auditFindingFragment" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "auditFindingReviewsFragment" },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "original" },
            name: { kind: "Name", value: "auditFindingReviewByReviewId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "auditFindingReviewFragment" },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "auditFinding" },
                  name: { kind: "Name", value: "original" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "auditFindingFragment" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingReviewsFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "reviewedAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewedAuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "rationale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFindingReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "originalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auditFindingRuleTypeByAuditFindingRuleType",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "discrepantAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviewedAuditFinding" },
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "reviewedAuditFindingFragment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reportedFindings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "auditFindingReportId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviews" },
            name: { kind: "Name", value: "auditFindingReviewsByOriginalId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_AT_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingReviewFragment",
                        },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "auditFinding" },
                        name: { kind: "Name", value: "review" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "auditFindingFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<auditFindingWithReviewFragmentFragment, unknown>;
export const versionAuditFindingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "versionAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<versionAuditFindingFragmentFragment, unknown>;
export const autodorFindingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "autodorFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AutodorFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "claimId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "s3Key" } },
          { kind: "Field", name: { kind: "Name", value: "denialCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "improperCharge" } },
          { kind: "Field", name: { kind: "Name", value: "improperUnits" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "ubLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorMetadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "auditType" } },
                { kind: "Field", name: { kind: "Name", value: "billType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateOfServiceStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "procedureCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "procedureCodeModifier" },
                },
                { kind: "Field", name: { kind: "Name", value: "quarter" } },
                { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                { kind: "Field", name: { kind: "Name", value: "versionId" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rule" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "genericRule" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ptp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "colOneCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "colTwoCode" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "extendedDescription",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rationale" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mue" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "num" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rationale" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "enumeration",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mai" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nonBillable" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<autodorFindingFragmentFragment, unknown>;
export const FindingItemizedBillDataFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingItemizedBillDataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FindingItemizedBillData" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSource" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBilledAmount" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindingItemizedBillDataFragmentFragment, unknown>;
export const FindingFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Finding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "autodorFindingId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorFindingS3Key" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "findingItemizedBillData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FindingItemizedBillDataFragment",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "userType" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAdjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "ruleType" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authorId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "rationale" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingItemizedBillDataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FindingItemizedBillData" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSource" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBilledAmount" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindingFragmentFragment, unknown>;
export const ItemizedBillLineFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemizedBillLineFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ItemizedBillLine" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfService" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "totalCharge" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "findings" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSourceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          { kind: "Field", name: { kind: "Name", value: "groupCode" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ItemizedBillLineFragmentFragment, unknown>;
export const ProviderItemizedBillLineFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProviderItemizedBillLineFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ItemizedBillLine" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfService" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "totalCharge" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSourceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProviderItemizedBillLineFragmentFragment, unknown>;
export const batchClaimDocumentationFragmentFragmentDoc = {
  kind: "Document",
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimDocumentationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaim" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditDocumentation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subFile" } },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "process" } },
                { kind: "Field", name: { kind: "Name", value: "size" } },
                { kind: "Field", name: { kind: "Name", value: "signedUrl" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<batchClaimDocumentationFragmentFragment, unknown>;
export const askAutodorFileVectorsExistQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "askAutodorFileVectorsExistQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "AskAutodorFileVectorsExistInput" },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "askAutodorFileVectorsExist" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "documentIds" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  askAutodorFileVectorsExistQueryQuery,
  askAutodorFileVectorsExistQueryQueryVariables
>;
export const createIbLineFindingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "createIbLineFindings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateIbLineFindingsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "createAuditFindings" },
            name: { kind: "Name", value: "createIbLineFindings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "auditFindings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingWithReviewFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "reviewedAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ReviewedAuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "deleted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "rationale" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: {
              kind: "Name",
              value: "auditFindingRuleTypeByAuditFindingRuleType",
            },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "type" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "displayName" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "discrepantAmount" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "email" } },
              ],
            },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviewedAuditFinding" },
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "reviewedAuditFindingFragment" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "reportedFindings" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "auditFindingReportId" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFindingReview" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "reviewId" } },
          { kind: "Field", name: { kind: "Name", value: "originalId" } },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingReviewsFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            alias: { kind: "Name", value: "reviews" },
            name: { kind: "Name", value: "auditFindingReviewsByOriginalId" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: { kind: "EnumValue", value: "CREATED_AT_DESC" },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingReviewFragment",
                        },
                      },
                      {
                        kind: "Field",
                        alias: { kind: "Name", value: "auditFinding" },
                        name: { kind: "Name", value: "review" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "auditFindingFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "auditFindingWithReviewFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "auditFindingFragment" },
          },
          {
            kind: "FragmentSpread",
            name: { kind: "Name", value: "auditFindingReviewsFragment" },
          },
          {
            kind: "Field",
            alias: { kind: "Name", value: "original" },
            name: { kind: "Name", value: "auditFindingReviewByReviewId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "auditFindingReviewFragment" },
                },
                {
                  kind: "Field",
                  alias: { kind: "Name", value: "auditFinding" },
                  name: { kind: "Name", value: "original" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "auditFindingFragment" },
                      },
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "auditFindingReviewsFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  createIbLineFindingsMutation,
  createIbLineFindingsMutationVariables
>;
export const versionAuditFindingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "versionAuditFindings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "VersionAuditFindingsInput" },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            alias: { kind: "Name", value: "createAuditFindings" },
            name: { kind: "Name", value: "versionAuditFindings" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "auditFindings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "versionAuditFindingFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimLineItemFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaimLineItem" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemNnIbLines" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLineId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nnIbLine" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fingerprint" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "versionAuditFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AuditFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "auditorReviewed" } },
          { kind: "Field", name: { kind: "Name", value: "auditorComment" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleType" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingSeedType" },
          },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          { kind: "Field", name: { kind: "Name", value: "isActive" } },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaimLineItemAudit" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "batchClaimLineItem" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "batchClaimLineItemFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  versionAuditFindingsMutation,
  versionAuditFindingsMutationVariables
>;
export const claimDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "claim" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claim" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "findings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FindingFragment" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "autodor" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "claimId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "sinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "s3Key" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "timestamp" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "autodorFindings" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "FragmentSpread",
                              name: {
                                kind: "Name",
                                value: "autodorFindingFragment",
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "itemizedBillLines" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ItemizedBillLineFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingItemizedBillDataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FindingItemizedBillData" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSource" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBilledAmount" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Finding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "autodorFindingId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorFindingS3Key" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "findingItemizedBillData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FindingItemizedBillDataFragment",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "userType" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAdjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "ruleType" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authorId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "rationale" } },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "autodorFindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "AutodorFinding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "auditType" } },
          { kind: "Field", name: { kind: "Name", value: "claimId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "s3Key" } },
          { kind: "Field", name: { kind: "Name", value: "denialCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "improperCharge" } },
          { kind: "Field", name: { kind: "Name", value: "improperUnits" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "ubLineId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorMetadata" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "auditType" } },
                { kind: "Field", name: { kind: "Name", value: "billType" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dateOfServiceStart" },
                },
                { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "procedureCode" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "procedureCodeModifier" },
                },
                { kind: "Field", name: { kind: "Name", value: "quarter" } },
                { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
                { kind: "Field", name: { kind: "Name", value: "units" } },
                { kind: "Field", name: { kind: "Name", value: "versionId" } },
                { kind: "Field", name: { kind: "Name", value: "year" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "rule" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "_type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "__typename" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "genericRule" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ptp" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "colOneCode" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "colTwoCode" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "extendedDescription",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rationale" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mue" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "num" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "rationale" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "value" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "enumeration",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "__typename" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "editType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mai" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nonBillable" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "code" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "__typename" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemizedBillLineFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ItemizedBillLine" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfService" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "totalCharge" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "findings" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSourceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          { kind: "Field", name: { kind: "Name", value: "groupCode" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<claimQuery, claimQueryVariables>;
export const providerClaimDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "providerClaim" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claim" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "itemizedBillLines" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "ProviderItemizedBillLineFragment",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ProviderItemizedBillLineFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ItemizedBillLine" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfService" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "totalCharge" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSourceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<providerClaimQuery, providerClaimQueryVariables>;
export const claimFindingsDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "claimFindings" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claim" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "icn" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "findings" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: { kind: "Name", value: "FindingFragment" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingItemizedBillDataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FindingItemizedBillData" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSource" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBilledAmount" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Finding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "autodorFindingId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorFindingS3Key" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "findingItemizedBillData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FindingItemizedBillDataFragment",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "userType" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAdjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "ruleType" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authorId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "rationale" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<claimFindingsQuery, claimFindingsQueryVariables>;
export const FindingDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "Finding" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "finding" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "FindingFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingItemizedBillDataFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "FindingItemizedBillData" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "authorId" } },
          { kind: "Field", name: { kind: "Name", value: "batchClaimLineId" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfServiceEnd" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "dateOfServiceStart" },
          },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "ibLineId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSource" } },
          { kind: "Field", name: { kind: "Name", value: "stale" } },
          { kind: "Field", name: { kind: "Name", value: "unitCharge" } },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "ibLineVersionFingerprint" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "originalBilledAmount" },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "FindingFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Finding" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "autodorFindingId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "autodorFindingS3Key" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "findingItemizedBillData" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "FindingItemizedBillDataFragment",
                  },
                },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "accepted" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "author" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "firstName" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "lastName" } },
                { kind: "Field", name: { kind: "Name", value: "userType" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "confidence" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentCost" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentReason" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "improperPaymentUnitsCharged" },
          },
          { kind: "Field", name: { kind: "Name", value: "isOriginal" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataAocPrimaryCode" },
          },
          { kind: "Field", name: { kind: "Name", value: "metaDataEmCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmCorrectLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataEmOriginalLevel" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueAdjudicationValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciMueOhpMueValue" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpCode" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataNcciPtpModifier" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataPricingCorrectPrice" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "metaDataUnitsCorrectUnits" },
          },
          { kind: "Field", name: { kind: "Name", value: "needsReview" } },
          { kind: "Field", name: { kind: "Name", value: "ruleType" } },
          { kind: "Field", name: { kind: "Name", value: "seedType" } },
          { kind: "Field", name: { kind: "Name", value: "superseded" } },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "latestReview" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "authorId" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "rationale" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FindingQuery, FindingQueryVariables>;
export const ItemizedBillLinesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "ItemizedBillLines" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "UUID" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "itemizedBillLines" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ids" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "ItemizedBillLineFragment" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ItemizedBillLineFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ItemizedBillLine" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "__typename" } },
          { kind: "Field", name: { kind: "Name", value: "alaRowId" } },
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "dateOfService" } },
          { kind: "Field", name: { kind: "Name", value: "itemNumber" } },
          { kind: "Field", name: { kind: "Name", value: "revCode" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
          { kind: "Field", name: { kind: "Name", value: "procedureCode" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "procedureCodeModifier" },
          },
          { kind: "Field", name: { kind: "Name", value: "units" } },
          { kind: "Field", name: { kind: "Name", value: "totalCharge" } },
          { kind: "Field", name: { kind: "Name", value: "lineNumber" } },
          { kind: "Field", name: { kind: "Name", value: "findings" } },
          { kind: "Field", name: { kind: "Name", value: "s3InputSourceId" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "versionFingerprint" },
          },
          { kind: "Field", name: { kind: "Name", value: "groupCode" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  ItemizedBillLinesQuery,
  ItemizedBillLinesQueryVariables
>;
export const auditFindingRuleTypesDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "auditFindingRuleTypes" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingRuleTypes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "claimDataType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "deprecated" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "displayName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "rationaleTemplate" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  auditFindingRuleTypesQuery,
  auditFindingRuleTypesQueryVariables
>;
export const documentationViewerDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "documentationViewer" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "claimId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "batchClaim" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "claimId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: {
                    kind: "Name",
                    value: "batchClaimDocumentationFragment",
                  },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "batchClaimDocumentationFragment" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "BatchClaim" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "auditDocumentation" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "subFile" } },
                { kind: "Field", name: { kind: "Name", value: "fileName" } },
                { kind: "Field", name: { kind: "Name", value: "process" } },
                { kind: "Field", name: { kind: "Name", value: "size" } },
                { kind: "Field", name: { kind: "Name", value: "signedUrl" } },
                { kind: "Field", name: { kind: "Name", value: "__typename" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  documentationViewerQuery,
  documentationViewerQueryVariables
>;
export const reportDownloadQueryDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "reportDownloadQuery" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "reportId" },
          },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "auditFindingReport" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "reportId" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "downloadUrl" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  reportDownloadQueryQuery,
  reportDownloadQueryQueryVariables
>;
