import * as React from "react";
import type { LexicalEditor } from "lexical";

interface ChatboxContextProps {
  setEditorInput: (content: string) => void;
  editorRef: React.RefObject<LexicalEditor>;
  isEditorEmpty: boolean;
  setIsEditorEmpty: (state: boolean) => void;
}

const ChatboxContext = React.createContext<ChatboxContextProps | null>(null);

type ChatboxProviderProps = React.PropsWithChildren;

export function ChatboxProvider({ children }: ChatboxProviderProps) {
  const editorRef = React.useRef<LexicalEditor>(null);
  const [isEditorEmpty, setIsEditorEmpty] = React.useState(true);

  return (
    <ChatboxContext.Provider
      value={{
        editorRef,
        setEditorInput: (state: string) => {
          const { current } = editorRef;
          if (!current) return;
          const editorState = current.parseEditorState(state);
          current.setEditorState(editorState);
        },
        isEditorEmpty,
        setIsEditorEmpty,
      }}
    >
      {children}
    </ChatboxContext.Provider>
  );
}

export function useChatbox() {
  const context = React.useContext(ChatboxContext);
  if (!context) {
    throw new Error("useChatbox must be used within a ChatboxProvider");
  }
  return context;
}
