/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import * as stateTypes from "../../../claimState/stateTypes";
import { auditProgressStatistics } from "../auditProgressSingleStatisticDefs";

// used to read in the results of an auditProgressQuery and convert it into the correct
// props object needed so that we can render the auditProgress panel to each user's specifiactions
const parseAuditProgressQueryResult = (
  auditProgressData: any,
  permissions: any,
  userType: any,
  showAsStatsArr: any,
) => {
  // the object we will insert all prop objects to
  const progressStateObj: any = {};

  /* ---- STEP 1.  Retrieve and parse all EnumClaimStates from query result ---- */
  // Returns an array of all EnumClaimState stateTypes Objects we expect to retrieve from this query results
  const expectedStateTypePropsArray = Object.entries(stateTypes).filter(
    ([stateType, { auditProgress }]: any) =>
      auditProgress && permissions.claimStatesToShow[stateType].size > 0,
  );

  /* 
    We iterate through these objects and for each we run the parseAuditProgressSubQueryResults which does 2 things:
      1. Determines the statistic values we want to render by parsing the query result and determining the value
      2. access and return all of the react props needed to then render the statistic
          
    We then apply these results onto progressStateObj using the 'stateType' string as the key
  */
  expectedStateTypePropsArray.forEach(
    ([
      stateType,
      {
        auditProgress: { parseAuditProgressSubQueryResults },
        states,
        queryableStates,
        defaultStates,
      },
    ]: any) => {
      progressStateObj[stateType] = parseAuditProgressSubQueryResults({
        stateType,
        data: auditProgressData,
        permissions,
        userType,
        queryableStates,
        defaultStates,
        states,
      });
    },
  );

  /* ---- STEP 2. Retrieve and parse all numeric statistics from query result ---- */

  /* 
    showAsStatsArr is an array of all Statistic stateTypes we expect to retrieve from this query results
    We iterate through these names and use them to index 'auditProgressStatistics' to do 2 things
     1. run the parseQueryResult that will access the query result and return the statistic value we want to render
     2. access and return all of the react props needed to then render this statistic
    
    We then apply these results onto progressStateObj using the 'auditProgressQueryStatName' string as the key
  */
  showAsStatsArr.forEach((auditProgressQueryStatKey: any) => {
    const [_, auditProgressQueryStatName] =
      auditProgressQueryStatKey.split(".");
    if (auditProgressQueryStatName in auditProgressStatistics) {
      progressStateObj[auditProgressQueryStatName] = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        totalCount: auditProgressStatistics[
          auditProgressQueryStatName
        ].parseQueryResult(auditProgressData, auditProgressQueryStatName),
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        ...auditProgressStatistics[auditProgressQueryStatName],
      };
    }
  });

  // We can now use this object to render the auditProgress Panel
  return progressStateObj;
};

export { parseAuditProgressQueryResult };
