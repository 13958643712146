import { graphql } from "../gql";

export const confirmFileUploadSuccessMutationDoc = graphql(`
  mutation confirmFileUploadSuccess($input: ConfirmFileUploadSuccessInput!) {
    confirmFileUploadSuccess(input: $input) {
      ...FileFragment
      signedDownloadUrl {
        ...SignedDownloadUrlFragment
      }
    }
  }
`);
