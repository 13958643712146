/* eslint-disable @typescript-eslint/no-empty-function */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { css } from "@emotion/react";
import type { UploadFile } from "antd";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tabs,
  Typography,
  Upload,
} from "antd";
import type { UploadChangeParam } from "antd/es/upload";

import type { UploadClaimModalUiProps } from "./uploadClaimModal";
import UploadClaimsTable from "./uploadClaimsTable";
import { CLAIM_NUMBER_VALIDATION_REGEX, DOCUMENT_UPLOAD_KINDS } from "./util";

const { Text, Link } = Typography;
const clsSm = css({ minHeight: "0.85em", fontSize: "0.85em" });

const UploadClaimModalUi = (props: UploadClaimModalUiProps) => {
  const {
    open,
    loading,
    uploadList,
    history,
    uploadInProgress,
    insurers,
    insurerName,
    insurerId,
    setInsurerId,
    claimNumber,
    setClaimNumber,
    uploadFilesHandler,
    setUploadKind,
    addFileToUploadList,
    addOrUpdate,
    removeUpload,
    reset,
    close,
  } = props;

  const [activeTab, setActiveTab] = React.useState("currentTab");
  const [helpDrawerOpen, setHelpDrawerOpen] = React.useState(false);

  // watch form and update parent state
  const [form] = Form.useForm();
  const _claimNumber = Form.useWatch("claimNumber", form);
  useEffect(() => {
    setClaimNumber(_claimNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_claimNumber]);
  const _insurerId = Form.useWatch("insurer", form);
  useEffect(() => {
    setInsurerId(_insurerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_insurerId]);

  const onUploadChangeEvent = (info: UploadChangeParam<UploadFile<any>>) => {
    if (activeTab != "currentTab") {
      setActiveTab("currentTab");
    }
    if (insurerName && insurerId && claimNumber) {
      addOrUpdate({
        uploadFile: info.file,
        metadata: { insurerId, insurerName, claimNumber, status: "ready" },
      });
    }
  };

  const documentUploadKindOptions = Object.entries(DOCUMENT_UPLOAD_KINDS).map(
    ([key, value]) => ({
      label: value.label,
      value: key,
    }),
  );

  const notPending = !uploadInProgress;

  const topLevelConfigReady =
    notPending &&
    insurerId &&
    claimNumber &&
    Boolean(CLAIM_NUMBER_VALIDATION_REGEX.test(claimNumber));

  const hasDuplicateNameAndKind = uploadList.some(
    (upload) => upload.metadata?.duplicateNameAndKind,
  );
  const hasDuplicateUB = uploadList.some(
    (upload) => upload.metadata?.duplicateUB,
  );

  const canStartUpload =
    topLevelConfigReady &&
    uploadList.length > 0 &&
    !hasDuplicateUB &&
    !hasDuplicateNameAndKind &&
    uploadList.every((upload) => upload.metadata?.documentUploadKind) &&
    uploadList.some((upload) => upload.metadata.status !== "success");

  const uploadAttempted =
    uploadInProgress ||
    uploadList.some((upload) => upload.metadata.status !== "ready");

  const selectsDisabled = uploadList.length > 0;

  const startButtonLabel =
    uploadAttempted &&
    uploadList.some((upload) => upload.metadata.status !== "success")
      ? "Retry Upload"
      : "Start Upload";
  const resetButtonLabel = uploadAttempted ? "Start a new Upload" : "Reset";

  const uploadFiles = () => {
    setActiveTab("currentTab");
    uploadFilesHandler();
  };

  const uploadDraggerContent = () => {
    return uploadInProgress ? (
      <p className="ant-upload-text">Upload in progress...</p>
    ) : !topLevelConfigReady ? (
      <p className="ant-upload-text">Enter Insurer/Claim Number to add files</p>
    ) : (
      <>
        <p className="ant-upload-text">
          Click or drag file to this area to upload
        </p>
        {uploadList.length === 0 && (
          <>
            <p className="ant-upload-hint">
              Please add the UB for the claim, along with any additional
              documentation, in PDF format.
            </p>
          </>
        )}
        {uploadList.length > 0 && (
          <>
            <p style={{ fontWeight: "bold" }}>
              Click Upload to start uploading
            </p>
          </>
        )}
      </>
    );
  };

  return (
    <Modal
      title={"Upload Claim Documents"}
      onCancel={() => close()}
      okText="Close"
      onOk={() => close()}
      footer={
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={() => close()}
        >
          Close
        </Button>
      }
      confirmLoading={loading}
      maskClosable={false}
      open={open}
      width="85%"
      bodyStyle={{
        height: "calc(70vh)",
        overflowX: "scroll",
        overflowY: "scroll",
        paddingTop: "0",
        marginBottom: 0,
        paddingBottom: 0,
      }}
    >
      <div
        css={css([
          {
            overflowX: "scroll",
            overflow: "hidden",
            position: "relative",
            width: "100%",
            minWidth: "600px",
            minHeight: "100%",
          },
        ])}
      >
        <div
          css={css({
            paddingBottom: "1em",
            "& .grid-container": {
              display: "grid",
              gridAutoFlow: "column",
              gridTemplateColumns: "minmax(min-content, 1fr) 0.75fr",
              columnGap: "2em",
              rowGap: "1.5em",
              "& .info": {
                paddingTop: "2em",
              },
            },
          })}
        >
          <div className="grid-container">
            <div>
              <div css={css({ padding: "1em 0 2em 0" })}>
                Upload new claims to Alaffia by entering the associated insurer,
                claim number, and selecting the files. Click{" "}
                <Link onClick={() => setHelpDrawerOpen(true)}>here</Link> for
                info on file types.
              </div>
              <Form
                form={form}
                name="form-insurer-and-claim"
                labelAlign="left"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                title={
                  !selectsDisabled
                    ? undefined
                    : "Insurer and ICN cannot be changed once files are added to the list below - use Reset (or Start New Upload) button to clear the file list if editing is required"
                }
              >
                <Form.Item
                  name="insurer"
                  label="Insurer"
                  rules={[{ required: true }]}
                >
                  <Select
                    style={{ width: "100%" }}
                    disabled={selectsDisabled || loading}
                    loading={loading}
                    filterOption={true}
                    optionFilterProp={"label"}
                    showSearch={true}
                    options={insurers.map((ins) => ({
                      value: ins.id,
                      label: ins.name,
                    }))}
                  ></Select>
                </Form.Item>
                <Form.Item
                  name="claimNumber"
                  label="Claim Number"
                  rules={[
                    {
                      required: true,
                      pattern: CLAIM_NUMBER_VALIDATION_REGEX,
                      message:
                        "Claim Number must be 6-50 characters, consisting of letters, numbers, underscore, and hyphen",
                    },
                  ]}
                  extra="** Do not include patient names, initials, or other PHI"
                  css={css({
                    "& div.ant-form-item-extra, & div.ant-form-item-explain-error":
                      { fontSize: ".80em" },
                  })}
                >
                  <Input
                    disabled={selectsDisabled}
                    placeholder="Enter claim number"
                  ></Input>
                </Form.Item>
              </Form>
              <Row>
                <Col span={6}> </Col>
                <Col span={18} offset={6}>
                  <Space size="large">
                    <Button
                      size="small"
                      type="primary"
                      disabled={!canStartUpload}
                      loading={uploadInProgress}
                      onClick={uploadFiles}
                    >
                      {startButtonLabel}
                    </Button>

                    <Button
                      size="small"
                      disabled={
                        uploadInProgress ||
                        (uploadList.length === 0 && !selectsDisabled)
                      }
                      onClick={() => reset()}
                    >
                      {resetButtonLabel}
                    </Button>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col span={6}>
                  <div css={clsSm}></div>
                  <div css={clsSm}></div>
                </Col>
                <Col span={18} offset={6}>
                  {hasDuplicateUB && (
                    <div>
                      <Text type="danger" css={clsSm}>
                        Only a single file per claim can be identified as UB
                      </Text>
                    </div>
                  )}
                  {hasDuplicateNameAndKind && (
                    <div>
                      <Text type="danger" css={clsSm}>
                        Files with the same file name and kind are not permitted
                      </Text>
                    </div>
                  )}
                </Col>
              </Row>
            </div>

            <div css={css({ padding: "1em" })}>
              <Upload.Dragger
                name="file"
                id="document-upload"
                accept=".pdf"
                disabled={!topLevelConfigReady}
                multiple
                beforeUpload={(file, _) =>
                  addFileToUploadList({ uploadFile: file, metadata: {} })
                }
                showUploadList={false}
                fileList={uploadList.map((upload) => upload.uploadFile)}
                onChange={(info) => {
                  onUploadChangeEvent(info);
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined
                    style={topLevelConfigReady ? undefined : { color: "gray" }}
                  />
                </p>
                {uploadDraggerContent()}
              </Upload.Dragger>
            </div>
          </div>
        </div>

        <div
          css={css({
            padding: 0,
            height: "100%",
            minHeight: "100%",
          })}
        >
          {/* table tabs current/history */}
          <Tabs
            activeKey={activeTab}
            tabBarStyle={{ justifyContent: "end" }}
            css={css({ "& div.ant-tabs-nav-wrap": { justifyContent: "end" } })}
            items={[
              {
                key: "currentTab",
                label: `Active upload session`,
                children: (
                  <UploadClaimsTable
                    uploadFiles={uploadList}
                    setUploadKind={setUploadKind}
                    documentUploadKindOptions={documentUploadKindOptions}
                    removeUpload={removeUpload}
                  ></UploadClaimsTable>
                ),
              },
              {
                key: "historyTab",
                label: `Upload history`,
                children: (
                  <UploadClaimsTable
                    uploadFiles={history}
                    isShowingHistory={true}
                    setUploadKind={() => {}}
                    documentUploadKindOptions={documentUploadKindOptions}
                  ></UploadClaimsTable>
                ),
              },
            ]}
            onChange={setActiveTab}
          />
        </div>

        {/* help drawer */}
        <Drawer
          title="File Types"
          placement="right"
          style={{ height: "100%", minHeight: "100%", borderBottom: "1px" }}
          height="100%"
          closable={false}
          onClose={() => setHelpDrawerOpen(false)}
          open={helpDrawerOpen}
          getContainer={false}
          maskStyle={{ opacity: "0" }}
        >
          <h2>Types of Claim Documentation</h2>
          {Object.entries(DOCUMENT_UPLOAD_KINDS).map(([_key, value], i) => (
            <section key={i}>
              <h3 style={{ fontSize: "16px" }}>{value.label}</h3>
              <ul>
                {typeof value.contains === "object" ? (
                  Object.values(value.contains).map((v, index) => (
                    <li key={index}>{v}</li>
                  ))
                ) : (
                  <li key={i}>{value.label}</li>
                )}
              </ul>
            </section>
          ))}
        </Drawer>
      </div>
    </Modal>
  );
};

export { UploadClaimModalUi };
