import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { IconCheck } from "@tabler/icons-react";
import { cva } from "class-variance-authority";
import type { VariantProps } from "class-variance-authority";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const checkboxVariants = cva(
  "af-peer af-h-4 af-w-4 af-shrink-0 af-rounded-sm af-border-2 af-p-0 af-ring-offset-background focus-visible:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-ring focus-visible:af-ring-offset-2 disabled:af-cursor-not-allowed disabled:af-opacity-50 af-flex af-items-center af-justify-center",
  {
    variants: {
      variant: {
        primary: `af-border-gray-400 af-border af-text-foreground data-[state=checked]:af-bg-gray-300 data-[state=checked]:af-text-gray-700`,
        info: "data-[state=checked]:af-bg-info-500 af-text-foreground data-[state=checked]:af-text-white af-border-info-500",
      },
    },
    defaultVariants: {
      variant: "primary",
    },
  },
);

interface CheckboxProps
  extends React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    VariantProps<typeof checkboxVariants> {}
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(checkboxVariants({ variant: props.variant }), className)}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn(
        "af-flex af-items-center af-justify-center af-text-current",
        "af-h-full af-w-full",
      )}
    >
      <IconCheck className="af-h-3 af-w-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
