import { forwardRef, useState } from "react";
import { IconDotsVertical } from "@tabler/icons-react";

import type { File } from "@alaffia-technology-solutions/schema";
import {
  Button,
  Checkbox,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@alaffia-technology-solutions/ui";

import { Files } from "../Files";
import { BaseFilesPanel } from "./BaseFilesPanel";
import type { FilesPanelProps } from "./FilesPanel";
import { useFilesPanelControls } from "./useFilesPanelControls";

interface SelectableFilesPanelProps extends FilesPanelProps {
  selectedFileIds?: string[];
  onFileSelection?: (fileId: string, selected: boolean) => void;
  itemActions?: {
    name: string;
    onClick: (id: string) => void;
  }[];
}

export const SelectableFilesPanel = forwardRef<
  HTMLDivElement,
  SelectableFilesPanelProps
>(
  (
    {
      files,
      onFilePreview,
      selectedFileIds,
      onFileSelection,
      itemActions,
      onExpand,
      onCollapse,
      isExpanded: controlledIsExpanded,
      ...props
    },
    ref,
  ) => {
    const { collapse, expand, isExpanded } = useFilesPanelControls({
      isExpanded: controlledIsExpanded,
      onExpand,
      onCollapse,
    });

    const selectedFiles = files?.filter(
      (file) => selectedFileIds?.includes(file.id),
    );
    const unselectedFiles = files?.filter(
      (file) => !selectedFileIds?.includes(file.id),
    );

    return (
      <BaseFilesPanel
        loading={!files}
        numberOfSelectedFiles={selectedFiles?.length ?? 0}
        {...props}
        expand={expand}
        collapse={collapse}
        isExpanded={isExpanded}
        ref={ref}
      >
        <div className="af-grid af-gap-6">
          {(selectedFiles?.length ?? 0) > 0 && (
            <Files.Section title="Selected">
              <Files.List className="af-rounded-lg af-bg-primary-100">
                {selectedFiles?.map((file) => (
                  <SelectableFilesItem
                    key={file.id}
                    file={file}
                    onFilePreview={onFilePreview}
                    itemActions={itemActions}
                    onFileSelection={onFileSelection}
                    selected
                  />
                )) ?? null}
              </Files.List>
            </Files.Section>
          )}

          {(unselectedFiles?.length ?? 0) > 0 && (
            <Files.Section title="All">
              <Files.List>
                {unselectedFiles?.map((file) => (
                  <SelectableFilesItem
                    key={file.id}
                    file={file}
                    onFilePreview={onFilePreview}
                    itemActions={itemActions}
                    onFileSelection={onFileSelection}
                    selected={false}
                  />
                )) ?? null}
              </Files.List>
            </Files.Section>
          )}
        </div>
      </BaseFilesPanel>
    );
  },
);
SelectableFilesPanel.displayName = "SelectableFilesPanel";

type SelectableFilesItemProps = Pick<
  SelectableFilesPanelProps,
  "onFilePreview" | "onFileSelection" | "itemActions"
> & {
  file: File;
  selected?: boolean;
};

function SelectableFilesItem({
  file,
  onFilePreview,
  selected,
  onFileSelection,
  itemActions,
}: SelectableFilesItemProps) {
  const [itemMenuOpen, setItemMenuOpen] = useState(false);
  return (
    <Files.Item
      key={file.id}
      file={file}
      onFilePreview={() => onFilePreview(file)}
      showBefore={selected}
      focused={itemMenuOpen}
      before={
        <Checkbox
          className="af-mr-2"
          disabled={!file.readyToSearch}
          variant="info"
          checked={selected}
          onCheckedChange={(selected) =>
            onFileSelection?.(file.id, selected === true)
          }
        />
      }
      after={
        (itemActions?.length && (
          <ItemMenu
            open={itemMenuOpen}
            onOpenChange={setItemMenuOpen}
            itemActions={itemActions}
            id={file.id}
          />
        )) ??
        null
      }
    />
  );
}

interface ItemMenuProps {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  itemActions: {
    name: string;
    onClick: (id: string) => void;
  }[];
  id: string;
}
const ItemMenu: React.FC<ItemMenuProps> = ({
  open,
  onOpenChange,
  itemActions,
  id,
}) => {
  return (
    <DropdownMenu open={open} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="ghost" className="af-h-auto af-w-auto">
          <IconDotsVertical className="af-h-4 af-w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {itemActions.map(({ name, onClick }) => (
          <DropdownMenuItem key={name} onClick={() => onClick(id)}>
            {name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
