/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useCallback, useMemo, useState } from "react";
import type { FormInstance } from "antd/es/form/hooks/useForm";
import type { Callbacks } from "rc-field-form/lib/interface";

import type {
  FieldChangeHandler,
  FormAdapter,
  ValueChangeHandler,
} from "../viewController/types";

type AntdValuesChangeHandler<Values> = Callbacks<Values>["onValuesChange"];
type AntdFieldsChangeHandler<Values> = Callbacks<Values>["onFieldsChange"];

export const useAntdFormAdapter = <T = any>(form: FormInstance<T>) => {
  const [valueChangeHandler, _setValueChangeHandler] = useState<
    AntdValuesChangeHandler<T> | undefined
  >(() => {});

  const [fieldChangeHandler, _setFieldChangeHandler] = useState<
    AntdFieldsChangeHandler<T> | undefined
  >(undefined);

  const setValues: (valuesMap: Partial<T>) => void = useCallback(
    (valuesMap) => {
      form.setFieldsValue(valuesMap as any); //RecursivePartial<T> ...
    },
    [form],
  );

  const formAdapter: FormAdapter<T> = useMemo(() => {
    return {
      setValueChangeHandler: (changeHandler: ValueChangeHandler<T>) => {
        if (changeHandler) {
          _setValueChangeHandler(() => {
            // returns changeHandler fn from ^^ outer setState fn:
            return (changedValues: Partial<T>, allValues: Partial<T>) => {
              // returns value from calling handler at call time:
              return changeHandler({ changedValues, allValues });
            };
          });
        }
      },
      setFieldChangeHandler: (changeHandler: FieldChangeHandler) =>
        _setFieldChangeHandler(() => changeHandler),
      setValues,
      getAllValues: () => {
        return form.getFieldsValue();
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  return { formAdapter, valueChangeHandler, fieldChangeHandler };
};
