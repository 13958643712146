/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useMemo, useState } from "react";
import { AuditOutlined } from "@ant-design/icons";
import type {
  IStatusPanelParams,
  ModelUpdatedEvent,
  SelectionChangedEvent,
  StatusPanelDef,
} from "ag-grid-community";
import { Button } from "antd";

import { StatusBarRowCount } from "../../../../../common/table/ag/statusbar/statusbarRowCount";
import { AuditFindingSeedType } from "../../../claimWorkspace/createAuditFinding/auditFindingUtil";

const MAX_FINDINGS_PER_CREATE = 40;

const statusBarCreateFindings = <T,>(props: IStatusPanelParams) => {
  const { readOnly, showFindings } =
    props.context?.current?.createFindingFunctions ?? {};
  const findingEditor = props.context?.current?.findingEditor;

  const [selectedRows, setSelectedRows] = useState<T[]>([]);

  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(readOnly);

  const showFindingsButton = useMemo(
    () => showFindings && !readOnlyMode,
    [showFindings, readOnlyMode],
  );

  const selectionChanged = (event: SelectionChangedEvent<T>) => {
    setSelectedRows(() => event.api.getSelectedRows());
  };

  const modelUpdated = (event: ModelUpdatedEvent<T>) => {
    const newCtx = event.context?.current ?? {};
    if (newCtx?.createFindingFunctions?.readOnly !== undefined) {
      setReadOnlyMode(newCtx.createFindingFunctions.readOnly);
    }
  };

  useEffect(() => {
    props.api.addEventListener("selectionChanged", selectionChanged);
    props.api.addEventListener("modelUpdated", modelUpdated);

    return () =>
      props.api.removeEventListener("selectionChanged", selectionChanged);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div tw="af-h-7">
      {showFindingsButton && (
        <>
          {" "}
          <Button
            tw="af-my-1"
            data-test-id="create-ib-audit-finding-button"
            // type="primary"
            size="small"
            icon={<AuditOutlined />}
            disabled={
              readOnly ||
              selectedRows.length < 1 ||
              selectedRows.length > MAX_FINDINGS_PER_CREATE
            }
            onClick={() => {
              findingEditor.showModal({
                operation: {
                  operation: "create",
                  ibData: selectedRows,
                  seedType: AuditFindingSeedType.IB_CLAIM_LINE,
                },
              });
            }}
          >
            <span tw="af-text-xs">
              {selectedRows.length > MAX_FINDINGS_PER_CREATE
                ? `Select <= ${MAX_FINDINGS_PER_CREATE} rows to create findings`
                : selectedRows.length > 0
                ? `Create ${selectedRows.length} findings`
                : "Select rows to create findings"}
            </span>
          </Button>
        </>
      )}
    </div>
  );
};

export const StatusBarCreateFindings = React.memo(statusBarCreateFindings);

export const ClaimLineTableStatusBar: {
  statusPanels: StatusPanelDef[];
} = {
  statusPanels: [
    {
      statusPanel: StatusBarCreateFindings,
      align: "left",
    },
    {
      statusPanel: StatusBarRowCount,
    },
  ],
};
