import { Suspense, useCallback } from "react";

import {
  PaneHeaderSplitView,
  useSplitView,
} from "@alaffia-technology-solutions/components";
import { PDFViewerProvider } from "@alaffia-technology-solutions/pdf-viewer";

import { PdfViewer } from "./PdfViewer";
import { useFileDownloadUrl } from "./useFileDownloadUrl";

export const usePdfViewerSplit = () => {
  const { show, closeLeft, toggleFullscreenLeft, slotsState } = useSplitView();

  const open = useCallback(
    (props: PdfViewerSplitProps) => {
      show({
        left: <PdfViewerSplit {...props} />,
      });
    },
    [show],
  );

  return {
    open,
    close: closeLeft,
    toggleFullscreen: toggleFullscreenLeft,
    isOpen: slotsState.left.state === "open",
  };
};

interface PdfViewerSplitProps {
  fileId: string;
  fileName: string;
  page?: number;
  highlights?: string[];
  searchEnabled?: boolean;
}

const PdfViewerSplit = ({
  fileId,
  fileName,
  page,
  highlights,
  searchEnabled,
}: PdfViewerSplitProps) => {
  const downloadUrl = useFileDownloadUrl({ fileId });
  const { closeLeft, toggleFullscreenLeft } = useSplitView();

  return (
    <div className="autodor-reset af-flex af-h-full af-flex-col af-overflow-hidden">
      <PaneHeaderSplitView
        onClose={closeLeft}
        onFullscreenToggle={toggleFullscreenLeft}
      >
        <h3 className="af-flex af-items-center af-text-base af-font-semibold af-text-gray-900">
          {fileName}
        </h3>
      </PaneHeaderSplitView>
      <Suspense fallback={null}>
        <PDFViewerProvider workerKey="pdf-viewer">
          {downloadUrl.data?.url && (
            <PdfViewer
              fileId={fileId}
              url={downloadUrl.data.url}
              page={page}
              highlights={highlights}
              searchEnabled={searchEnabled}
            />
          )}
        </PDFViewerProvider>
      </Suspense>
    </div>
  );
};
