import type { EditAuditorUserInput } from "../../remote/gql/graphql";
import { editAuditorUserMutationDoc } from "../../remote/mutations/editAuditorUser.gql";
import type { AlaffiaClientModuleArgs } from "./modules";
import { AlaffiaClientModule } from "./modules";

type AuditorUsersModuleArgs = AlaffiaClientModuleArgs;

export class AuditorUsersModule extends AlaffiaClientModule {
  constructor(args: AuditorUsersModuleArgs) {
    super(args);
  }

  editAuditorUser = async (input: {
    id: string;
    editInput: EditAuditorUserInput;
  }) => {
    const result = await this.runGqlMutation(
      editAuditorUserMutationDoc,
      { userId: input.id, input: input.editInput },
      "editAuditorUser",
    );

    return {
      data: result.data?.editAuditorUser ?? null,
      error: result.error ?? null,
    };
  };
}
