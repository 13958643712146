"use client";

import { createContext, useContext, useRef } from "react";
import type { __internal__ } from "@foxitsoftware/foxit-pdf-sdk-for-web-library-full/lib";
import type { StoreApi, UseBoundStore } from "zustand";
import { create } from "zustand";

import type { PDFControllerSlice, PDFViewerSlice } from "./state";
import { usePDFControllerSlice, usePDFViewerSlice } from "./state";

export type CombinedPDFStore = PDFControllerSlice & PDFViewerSlice;

interface PDFStoreApi extends StoreApi<CombinedPDFStore> {
  (callback: (state: CombinedPDFStore) => unknown): unknown;
}

const StoreContext = createContext<PDFStoreApi | null>(null);

interface StoreProviderProps {
  children?: React.ReactNode;
}

/**
 *
 * StoreProvider is a wrapper around Zustand's store. It combines two necessary state parts [slices] into one store:
 *    1. PDFViewerSlice - holds actions responsible for initializing /destroying PDFViewer, once the  {@link [Foxit Controller](../loaders/foxit-pdf-view-ctrl.ts)} is ready and target element is mounted.
 *    2. PDFControllerSlice - state management for PDFViewer controls, such as zoom, page navigation, etc.
 *
 */

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const storeRef =
    useRef<UseBoundStore<StoreApi<PDFViewerSlice & PDFControllerSlice>>>();

  const createPDFViewerSlice = usePDFViewerSlice();
  const createPDFControllerSlice = usePDFControllerSlice();

  if (!storeRef.current) {
    /**
     * Combining slices into one store for modularity and easier state management.
     * https://github.com/pmndrs/zustand/blob/main/docs/guides/typescript.md#slices-pattern
     */

    const store = create<PDFViewerSlice & PDFControllerSlice>()((...s) => ({
      ...createPDFViewerSlice(...s),
      ...createPDFControllerSlice(...s),
    }));

    storeRef.current = store;
  }

  return (
    <StoreContext.Provider value={storeRef.current}>
      {children}
    </StoreContext.Provider>
  );
};

export const usePDFStore = <R,>(selector: (value: CombinedPDFStore) => R) => {
  const store = useContext(StoreContext);
  if (!store) {
    console.error("PDF Viewer store is not initialized yet");
    throw new Error("PDF Viewer store is not initialized yet");
  }

  if (typeof window === "undefined" || !store) {
    return { data: null, isServer: true as const };
  } else {
    return { data: store(selector) as R, isServer: false as const };
  }
};
