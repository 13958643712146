/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FileDoneOutlined } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";

import { formatDateUTC } from "../../../audit/batchClaimOverview/eventHistoryTimeline";

const report = {
  auditProgress: {
    stateTypeLabel: "Reports",
    defaultLabel: "No Reports created",
    tooltipLabel: "Number of reported claim(s)",

    /**
     * Returns a query string that can be converted into a GQL query string
     * --> builds a subQuery that counts the number of reported columns on the batchClaimStates table filtered on a $batchClaimFilter
     */
    buildProgressSubQuery: () => {
      // uses the batchClaimStatesWithActiveStates funtion that filters the batchClaimState table down to all claims NOT in CLAIM_RECEIVED
      const query = `reported: batchClaimStatesWithActiveStates(
          filter: {
            and: [
              { batchClaimById: $batchClaimFilter }
              { reported: { equalTo: true } }
            ]
          }
        ) {
          totalCount
        }`;
      return query;
    },

    /**
     * Returns an Object in the format
     * {
     *    STATE_VALUE_NAME_ENUM (i.e REPORTED): count,
     *    ...
     * }
     */
    parseAuditProgressSubQueryResults: ({
      data: {
        totalClaims: { totalCount },
        reported,
      },
      permissions,
      userType,
      queryableStates,
      states,
    }: any) => {
      const progressStatProps: any = {};
      const formattedQueryResults: any = {
        REPORTED: reported.totalCount || 0,
        NOT_REPORTED: totalCount - reported.totalCount || 0,
      };
      queryableStates.forEach((stateName: string | number) => {
        if (permissions.claimStatesToShow.report.has(stateName)) {
          progressStatProps[stateName] = {
            totalCount: formattedQueryResults[stateName],
            name: states[stateName].name(userType),
            primaryColor: states[stateName].primaryColor,
            strokeColor: states[stateName].strokeColor,
            tagColor: states[stateName].tagColor,
            icon: states[stateName].icon,
            description: states[stateName].description(userType),
          };
        }
      });
      return progressStatProps;
    },
  },
  queryableStates: ["NOT_REPORTED", "REPORTED"],
  defaultStates: ["NOT_REPORTED"],
  dataType: "enum",
  onTableQuery: "batchClaims",
  states: {
    // default state
    NOT_REPORTED: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "Not Reported";
      },
      primaryColor: "lightgray",
      strokeColor: "white",
      tagColor: "lightgray",
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim(s) have not been Reported";
      },
      displayCondition: () => false, // DEFAULT state --> We don't want a tag to be rendered for this
      timelineEventRendering: {
        renderingAllowed: () => false,
      },
    },
    REPORTED: {
      name: () => {
        // different userTypes don't make a difference so we just return
        return "Reported";
      },
      primaryColor: "#52c41a", //#52c41a
      strokeColor: "white",
      tagColor: "green",
      icon: <FileDoneOutlined style={{ color: "#52c41a", fontSize: "120%" }} />,
      description: () => {
        // different userTypes don't make a difference so we just return
        return "Claim(s) have been Reported";
      },
      displayCondition: ({ batchClaimState: { reported } }: any) => reported,
      timelineEventRendering: {
        renderingAllowed: () => true,
        description: () => "Claim was Reported",
        customLabel: ({ createdAt }: any) => {
          return (
            <Tooltip title={formatDateUTC(createdAt)}>
              <strong>Report Version Generated</strong>
            </Tooltip>
          );
        },
        customRendering: ({ metaData: title }: any) => (
          <span>
            {title.length > 25 ? (
              <Tooltip title={title}>
                <Tag>{title.substring(0, 25)}...</Tag>
              </Tooltip>
            ) : (
              <Tag>{title}</Tag>
            )}
          </span>
        ),
      },
    },
  },
  // since a binary selection this will alway come as an array of values of size 1
  filterBuilder: (values: string | string[]) => {
    /* 
      POSSIBLE VALUE = NOT_REPORTED OR REPORTED -- only ONE Values accepted

      filter required for each values:
      NOT_REPORTED
        - reported = false

      REPORTED
        - reported = true 
    */
    return {
      reportedClaimsByClaimIdExist: values.includes("NOT_REPORTED")
        ? false
        : true,
    };
  },
};

export { report };
