import React from "react";
import tw from "twin.macro";

import { TableStyleProvider } from "../../../../common/table/ag/tableStyleProvider";

// alaffia light red: #E16E64
// alaffia red: #C14340
// alaffia light blue: #3860BA
// alaffia blue: #22367C
// alaffia ligth yellow: Hex #FBF559

// UB/IB table row styles -- for use in the ag-grid rowClassRules applied to the tables:
// (note use of '& ...' to scope styles to this container, and provide ag-grid class names within the scope)
const rowStyle = {
  "& .ag-row-focus": {
    backgroundColor: "#00000009",
    // ...tw`af-bg-antd-lt-colorBgBase af-border af-border-solid af-border-antd-lt-colorBorderSecondary dark:af-border-antd-dk-colorBorderSecondary`,
  },
  "& .ala-ag-row-style-accepted-finding": {
    ...tw`af-bg-antd-lt-green-3 dark:af-bg-antd-dk-green-5`,
    "&.ag-row-focus": tw`af-bg-opacity-75`,
  },
  "& .ala-ag-row-style-needs-review-finding": {
    ...tw`af-bg-antd-lt-red-3 dark:af-bg-antd-dk-red-4`,
    "&.ag-row-focus": tw`af-bg-opacity-75`,
  },
  "& .ala-ag-row-style-stale-finding": {
    ...tw`af-bg-antd-lt-colorWarning dark:af-bg-antd-dk-colorWarning`,
    "&.ag-row-focus": tw`af-bg-opacity-75`,
  },
  "& .ala-ag-row-style-autodor-finding": {
    ...tw`af-bg-antd-lt-gold-3 dark:af-bg-antd-dk-gold-6`,
    "&.ag-row-focus": tw`af-bg-opacity-75`,
  },
  "& .ala-ag-row-style-is-group-header": {
    ...tw`af-italic af-text-antd-lt-colorTextLabel dark:af-text-antd-dk-colorTextLabel`,
  },
};

// UB/IB lines table styles -- extends ag-grid theme styling in DesignProvider:
const tableStyle = {
  // main table:
  "& .ag-header-cell-label": tw`af-font-normal`,
  "& .ag-icon": tw`af-font-light af-text-antd-lt-colorIcon dark:af-text-antd-dk-colorIcon`,
  // detail rows (e.g. child findings table nested under main ub/ib lines):
  "& .ag-details-row": {
    // padding and bg of findings table:
    ...tw`af-pb-2.5 af-pt-0 af-px-0 af-bg-antd-lt-colorBgContainerDisabled dark:af-bg-antd-dk-colorBgContainerDisabled`,
    // child findings table wrapper:
    "& .ag-root-wrapper": {
      borderRadius: `0px`,
      ...tw`af-p-0 af-my-0 af-mx-2`,
      // child findings table header:
      "& .ag-header": {
        ...tw`af-bg-antd-lt-colorBgContainerDisabled dark:af-bg-antd-dk-colorBgContainerDisabled`,
        "& .ag-header-cell-label": tw`af-font-light af-bg-transparent`,
        "& .ag-header-icon": tw`af-hidden`, // hide the filter, menu buttons
        "& .ag-header-cell-resize:after": tw`af-h-[80%] af-top-[10%] af-bg-antd-lt-colorBgTextHover dark:af-bg-antd-dk-colorBgTextHover `,
      },
    },
    // set the container size -- otherwise the table will always have a larger min-height than rows, if just a few rows:
    "& .ag-center-cols-container": tw`!af-min-h-[42px]`, // looks like this one  has been replaced by ag-center-cols-viewport ?
    "& .ag-center-cols-viewport": tw`!af-min-h-[42px]`,
    "& .ag-center-cols-clipper": tw`!af-min-h-[42px]`,
  },
};

export interface TableStyleProviderProps {
  width: number;
  height: number;
  children: React.ReactElement;
}

const claimLineTableStyleProvider: React.FC<TableStyleProviderProps> = ({
  width,
  height,
  children,
}) => {
  return (
    <TableStyleProvider {...{ width, height, style: [tableStyle, rowStyle] }}>
      {children}
    </TableStyleProvider>
  );
};

export const ClaimLineTableStyleProvider = React.memo(
  claimLineTableStyleProvider,
);
