import type { Config } from "tailwindcss";
import tailwindAnimatePlugin from "tailwindcss-animate";

const hslColor = (varName: string) => `hsl(var(${varName}) / <alpha-value>)`;

export default {
  content: ["./**/*.{ts,tsx}"],
  darkMode: ["class"],
  prefix: "af-",
  theme: {
    container: {
      center: true,
      padding: "2rem",
      screens: {
        "2xl": "1400px",
      },
    },
    extend: {
      colors: {
        border: hslColor("--alaffia-border"),
        input: hslColor("--alaffia-input"),
        ring: hslColor("--alaffia-ring"),
        background: hslColor("--alaffia-background"),
        foreground: hslColor("--alaffia-foreground"),
        primary: {
          DEFAULT: hslColor("--alaffia-primary"),
          foreground: hslColor("--alaffia-primary-foreground"),
          100: hslColor("--alaffia-primary-100"),
          200: hslColor("--alaffia-primary-200"),
          500: hslColor("--alaffia-primary-500"),
          600: hslColor("--alaffia-primary-600"),
          700: hslColor("--alaffia-primary-700"),
          800: hslColor("--alaffia-primary-800"),
        },
        secondary: {
          DEFAULT: hslColor("--alaffia-secondary"),
          foreground: hslColor("--alaffia-secondary-foreground"),
          500: hslColor("--alaffia-secondary-500"),
          600: hslColor("--alaffia-secondary-600"),
        },
        destructive: {
          DEFAULT: hslColor("--alaffia-destructive"),
          foreground: hslColor("--alaffia-destructive-foreground"),
        },
        muted: {
          DEFAULT: hslColor("--alaffia-muted"),
          foreground: hslColor("--alaffia-muted-foreground"),
        },
        accent: {
          DEFAULT: hslColor("--alaffia-accent"),
          foreground: hslColor("--alaffia-accent-foreground"),
        },
        popover: {
          DEFAULT: hslColor("--alaffia-popover"),
          foreground: hslColor("--alaffia-popover-foreground"),
        },
        card: {
          DEFAULT: hslColor("--alaffia-card"),
          foreground: hslColor("--alaffia-card-foreground"),
        },
        gray: {
          100: hslColor("--alaffia-gray-100"),
          200: hslColor("--alaffia-gray-200"),
          300: hslColor("--alaffia-gray-300"),
          400: hslColor("--alaffia-gray-400"),
          500: hslColor("--alaffia-gray-500"),
          600: hslColor("--alaffia-gray-600"),
          700: hslColor("--alaffia-gray-700"),
          800: hslColor("--alaffia-gray-800"),
          900: hslColor("--alaffia-gray-900"),
        },
        neutral: {
          100: hslColor("--alaffia-neutral-100"),
          300: hslColor("--alaffia-neutral-300"),
          500: hslColor("--alaffia-neutral-500"),
          600: hslColor("--alaffia-neutral-600"),
          700: hslColor("--alaffia-neutral-700"),
          900: hslColor("--alaffia-neutral-900"),
        },
        info: {
          100: hslColor("--alaffia-info-100"),
          200: hslColor("--alaffia-info-200"),
          500: hslColor("--alaffia-info-500"),
          600: hslColor("--alaffia-info-600"),
          700: hslColor("--alaffia-info-700"),
        },
        success: {
          100: hslColor("--alaffia-success-100"),
          200: hslColor("--alaffia-success-200"),
          500: hslColor("--alaffia-success-500"),
          600: hslColor("--alaffia-success-600"),
          700: hslColor("--alaffia-success-700"),
        },
        error: {
          100: hslColor("--alaffia-error-100"),
          500: hslColor("--alaffia-error-500"),
          600: hslColor("--alaffia-error-600"),
          700: hslColor("--alaffia-error-700"),
          900: hslColor("--alaffia-error-900"),
        },
        warning: {
          100: hslColor("--alaffia-warning-100"),
          200: hslColor("--alaffia-warning-200"),
          600: hslColor("--alaffia-warning-600"),
          700: hslColor("--alaffia-warning-700"),
        },
        white: hslColor("--alaffia-white"),
      },
      borderRadius: {
        lg: "var(--alaffia-radius)",
        md: "calc(var(--alaffia-radius) - 2px)",
        sm: "calc(var(--alaffia-radius) - 4px)",
      },
      keyframes: {
        "accordion-down": {
          from: { height: "0" },
          to: { height: "var(--radix-accordion-content-height)" },
        },
        "accordion-up": {
          from: { height: "var(--radix-accordion-content-height)" },
          to: { height: "0" },
        },
      },
      animation: {
        "accordion-down": "accordion-down 0.2s ease-out",
        "accordion-up": "accordion-up 0.2s ease-out",
      },
    },
  },
  corePlugins: {
    preflight: false,
  },
  plugins: [tailwindAnimatePlugin],
} satisfies Config;
