import type { PropsWithChildren } from "react";
import { IconHelpSquareRounded } from "@tabler/icons-react";

import {
  Button,
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@alaffia-technology-solutions/ui";

export const HelpBox = ({ children }: PropsWithChildren) => (
  <HoverCard openDelay={0}>
    <HoverCardTrigger>
      <Button variant="neutral" size="icon">
        <IconHelpSquareRounded className="af-h-4 af-w-4" />
      </Button>
    </HoverCardTrigger>
    <HoverCardContent>{children}</HoverCardContent>
  </HoverCard>
);
