/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import type { FC } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, message, Modal, Select } from "antd";
import { useForm } from "react-hook-form";
import { useQuery } from "urql";
import { z } from "zod";

import type { Provider } from "@alaffia-technology-solutions/client-sdk";
import { apiClient } from "@alaffia-technology-solutions/client-sdk-react-query";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
} from "@alaffia-technology-solutions/ui";

import type { AuditorUser } from "../../gql/graphql";
import { facilitiesQuery } from "./queries";

interface Facilities {
  providers: {
    nodes: Provider[];
  };
}

interface EditUserModalProps {
  user: AuditorUser;
  onClose: () => void;
  refresh: () => void;
}

const UserSchema = z.object({
  firstName: z.string().trim().min(1),
  lastName: z.string().trim().min(1),
  email: z.string().trim().min(1),
  facilities: z
    .array(z.string())
    .min(1, "At least one facility must be selected"),
});

type UserSchemaType = z.infer<typeof UserSchema>;

export const EditUserModal: FC<EditUserModalProps> = ({
  user,
  onClose,
  refresh,
}) => {
  const form = useForm<UserSchemaType>({
    mode: "onChange",
    resolver: zodResolver(UserSchema),
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      facilities: user.providerUserFacilities?.nodes.map(
        (f) => f?.provider?.id,
      ),
    },
  });
  const {
    formState: { isDirty, isLoading, isValid },
  } = form;
  const [{ data: allFacilities }] = useQuery<Facilities>({
    query: facilitiesQuery,
  });
  const editAuditorUser = apiClient.auditorUsers.editAuditorUser.useMutation();

  const onSubmit = async (data: UserSchemaType) => {
    await editAuditorUser.mutateAsync({
      id: user.id,
      editInput: {
        facilities: data.facilities,
      },
    });
    onClose();
    refresh();
    await message.success("User updated successfully");
  };

  return (
    <Modal
      title="Edit User"
      onCancel={onClose}
      open={true}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          htmlType="submit"
          form="editUserForm"
          disabled={!isDirty || isLoading || !isValid}
        >
          Save
        </Button>,
      ]}
    >
      <Form {...form}>
        <form
          id="editUserForm"
          onSubmit={form.handleSubmit(onSubmit)}
          className="af-flex af-flex-col af-gap-2 af-py-5"
        >
          <FormField
            control={form.control}
            name="firstName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>First Name</FormLabel>
                <FormControl>
                  <Input {...field} disabled={true} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="lastName"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Last Name</FormLabel>
                <FormControl>
                  <Input {...field} disabled={true} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="email"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Email</FormLabel>
                <FormControl>
                  <Input {...field} disabled={true} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="facilities"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Facilities</FormLabel>
                <FormControl>
                  <Select
                    {...field}
                    optionFilterProp="label"
                    mode="multiple"
                    className="af-block"
                    options={allFacilities?.providers?.nodes?.map((item) => ({
                      label: item.name,
                      value: item.id,
                    }))}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </form>
      </Form>
    </Modal>
  );
};
