/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from "react";
import { keyBy } from "lodash";
import { useQuery } from "urql";

import type {
  AuditFindingRuleType,
  auditFindingRuleTypesQuery,
} from "../../../../gql/graphql";
import { nonNullArr } from "../../util/array";
import { denialCodesQueryDoc } from "./denialCodes.gql";

const hasData = (data: auditFindingRuleTypesQuery | undefined) =>
  (data?.auditFindingRuleTypes?.nodes?.length ?? 0) > 0;
const getNodes = (data: auditFindingRuleTypesQuery | undefined) =>
  hasData(data) ? data?.auditFindingRuleTypes?.nodes : [];

export type DenialCode = Omit<
  AuditFindingRuleType,
  "__typename" | "nodeId" | "auditFindingsByAuditFindingRuleType"
>;

export interface DenialCodes {
  codes: DenialCode[];
  byKey: Record<string, DenialCode>;
  byType: Record<string, DenialCode[]>;
  fetching: boolean;
  loaded: boolean;
  error?: Error;
}

export const auditFindingRuleTypeResponseNodesToUseDenialCodeResponse = (
  nodes: DenialCode[],
  fetching: boolean,
  error: any,
) => {
  const codes: DenialCode[] = nodes;
  if (codes && codes.length > 0) {
    const byKey = keyBy(codes, "type");

    // denial codes are grouped by type for UI display in dropdowns, deprecated codes are merged in with the
    // non-deprecated codes when an existing finding is edited so that they can be displayed in the dropdown
    const byType = codes.reduce<Record<string, DenialCode[]>>(
      (acc, denialCode) => {
        if (denialCode?.claimDataType) {
          const key =
            denialCode.claimDataType +
            (denialCode.deprecated ? "_DEPRECATED" : "");
          acc[key] = acc[key] ? [...acc[key], denialCode] : [denialCode];
        }
        return acc;
      },
      {},
    );

    return {
      codes,
      byKey,
      byType,
      fetching,
      loaded: true,
      error,
    };
  }
};

export const useDenialCodes: () => DenialCodes = () => {
  const [{ data, fetching, error }] = useQuery({
    query: denialCodesQueryDoc,
  });

  const denialCodes = useMemo<DenialCodes>(() => {
    if (!fetching && data && hasData(data)) {
      const codes = nonNullArr(getNodes(data));
      const result = auditFindingRuleTypeResponseNodesToUseDenialCodeResponse(
        codes,
        fetching,
        error,
      );
      if (result) {
        return result;
      }
    }
    return {
      codes: [],
      byKey: {},
      byType: {},
      fetching: fetching,
      loaded: false,
      error,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return denialCodes;
};
