/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

// external
import { Spin } from "antd";
import { useRouteMatch } from "react-router-dom";
import { css } from "twin.macro";
import { useQuery } from "urql";

// queries
import { queueQuery } from "../../graphql/queries";
import { BatchClaimSearchFilter } from "../search/batchClaimSearchFilter";
import { FilterTags } from "../search/search";
// components and classes
import { ClaimListTable } from "./claimListTable";

const Queue = () => {
  const {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    params: { queueId },
  } = useRouteMatch();

  const [{ data }] = useQuery({
    query: queueQuery,
    variables: { queueId: queueId },
  });

  const batchClaimFilter = new BatchClaimSearchFilter(
    data?.queue.indexedFilterTags,
  );

  return (
    <div tw="af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer">
      {data && batchClaimFilter ? (
        <div>
          <div
            css={css`
              padding-left: 10px;
              padding-top: 15px;
              padding-bottom: 15px;
            `}
          >
            Filter: <FilterTags filter={batchClaimFilter} />
          </div>
          <ClaimListTable
            batchClaimFilter={batchClaimFilter.gqlFilter}
            tableName={data.queue.name}
            tableDescription={data.queue.description}
          />
        </div>
      ) : (
        <div style={{ textAlign: "center", marginTop: "5%" }}>
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export { Queue };
