import { mue, nonBillable, ptp } from "./hbs/reExportHbs";
import type { TemplateDefinitions } from "./ruleDisplayTypes";

// n.b. we could use:
// `import mue from 'bundle-text:./hbs/mueAuditRule.hbs';`
// however, we'd have to provide module mapper/resolver for jest, and it has
// see note at reExportHbs for add'l info about the js file as indirection

export const defaultHbsTemplates: TemplateDefinitions = {
  ptp: {
    template: ptp,
    showExtraProps: false, // ['extendedDescription'],
  },
  mue: {
    template: mue,
    showExtraProps: false,
  },
  nonBillable: {
    template: nonBillable,
    showExtraProps: false,
  },
};
