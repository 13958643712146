import { graphql } from "../gql";

export const createCaseMutationDoc = graphql(`
  mutation createCase($input: CreateCaseInput!) {
    createCase(input: $input) {
      ...CaseFragment
      queue {
        ...AskAutodorQueueFragment
      }
    }
  }
`);
