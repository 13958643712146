/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Fragment } from "react";
import accounting from "accounting-js";
import tw, { css } from "twin.macro";

import type { FindingAdjustments } from "../../util/findingAdjustments";

export interface IbinIbLineAdjustmentsProps {
  ibLineAdjustments: FindingAdjustments[];
  paymentRate: number;
}

const clsPaymentAdjusted = css({
  ...tw`af-pr-0.5 af-text-antd-lt-colorWarningText dark:af-text-antd-dk-colorWarningText`,
});

const isSet = (v: any) => v !== null && v !== undefined;

const isIpcOverride = (x: FindingAdjustments) =>
  isSet(x.overrides.improperPaymentCost) &&
  x.overrides.improperPaymentCost !== x.improperPaymentCost;

export const IbinIbLineAdjustments: React.FC<IbinIbLineAdjustmentsProps> = ({
  ibLineAdjustments,
  paymentRate,
}) => (
  <div
    key="IbinIbLineAdjustments-display"
    tw="af-grid af-gap-0.5 af-grid-cols-12"
  >
    {ibLineAdjustments?.map((x) => (
      <Fragment key={"row" + x.ibLineId}>
        <div
          key={"id" + x.ibLineId}
          tw="af-col-span-2 af-italic af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary"
        >
          Line {x.ibLineNumber}
        </div>
        <div key={"ipuc" + x.ibLineId}>
          {x.improperPaymentUnitsCharged}/{x.unitsBilled}
        </div>
        <div
          key={"at" + x.ibLineId}
          tw="af-italic af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary"
        >
          @
        </div>
        <div key={"da" + x.ibLineId} tw="af-col-span-2">
          <span
            css={
              !!x.overrides.amountCharged &&
              x.overrides.amountCharged !== x.amountCharged &&
              clsPaymentAdjusted
            }
          >
            {accounting.formatMoney(x.discrepantAmount)}
          </span>
        </div>
        <div
          key={"x" + x.ibLineId}
          tw="af-italic af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary"
        >
          {isIpcOverride(x) ? "" : "x"}
        </div>
        <div
          key={"pr" + x.ibLineId}
          tw="af-col-span-2 af-italic af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary"
        >
          {isIpcOverride(x)
            ? "(override)"
            : accounting.toFixed(paymentRate * 100, 1) + "%"}
        </div>
        <div
          key={"eq" + x.ibLineId}
          tw="af-col-span-1 af-italic af-text-antd-lt-colorTextTertiary dark:af-text-antd-dk-colorTextTertiary"
        >
          =
        </div>
        <div
          key={"ipc" + x.ibLineId}
          tw="af-col-span-2 af-text-antd-lt-colorPrimary dark:af-text-antd-dk-colorPrimary"
        >
          <span css={isIpcOverride(x) && clsPaymentAdjusted}>
            {accounting.formatMoney(
              x.overrides.improperPaymentCost ?? x.improperPaymentCost,
            )}
          </span>
        </div>
      </Fragment>
    ))}
  </div>
);
