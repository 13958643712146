/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { datadogRum } from "@datadog/browser-rum";
import { Spin } from "antd";
import { gql, useQuery } from "urql";

import {
  adminAndAuditorClaimListQueryData,
  payerAndProviderClaimListQueryData,
} from "../../fragments";
import {
  claimIsAssignedToUserFilter,
  claimIsProviderActiveFilter,
} from "../../graphql/filters";
import {
  adminAndAuditorWorkstationQuery,
  adminClaimListQuery,
  auditorClaimListQuery,
  payerClaimListQuery,
  payerWorkstationQuery,
  providerClaimListQuery,
  providerWorkstationQuery,
} from "../../graphql/queries";
import {
  AuditsTab,
  CreateAuditOrReport,
  HomeTab,
  ManageUsersTab,
  Queues,
  Reports,
  SearchBarToggle,
  UploadClaims,
  UserLogout,
  UserProfile,
  UserTab,
} from "../app/navigation";

const UserContext = createContext<any>(null);

const extractUser = (userData) => {
  const {
    auditorUser: {
      id,
      firstName,
      lastName,
      title,
      email,
      userType,
      readOnly,
      internalOnly,
      claimIntakeEnabled,
      insurerUsers: { nodes: insurers },
      providerUserFacilities: { nodes: providers },
    },
  } = userData;

  // Tab components in navigation permissions
  // refer to the tabs in the navigation menu
  const permissions = {
    PROVIDER: {
      userDefaultFilter: {
        batchClaimFilter: { ...claimIsProviderActiveFilter },
        auditFindingFilter: {
          batchClaim: {},
          auditFinding: {},
        },
      },
      baseQueryType: {
        batchClaims: "batchClaims",
        auditFindings: "auditFindings",
      },
      allowMultiSelect: false,
      canSearchClaims: false,
      canEnterWorkstation: true,
      canViewPaymentRateStrategy: false,
      claimStatesToShow: {
        workflow: new Set([
          "IN_PROGRESS",
          "COMPLETED",
          "REPORTED_TO_PROVIDER",
          "REPORTED_TO_CLIENT",
        ]),
        documentation: new Set(["AWAITING_UPLOAD", "DOCUMENTS_RECEIVED"]),
        findings: new Set([]),
        signOff: new Set(["PROVIDER_NO_SIGN_OFF", "PROVIDER_SIGN_OFF"]),
        invoice: new Set([]),
        report: new Set(["NOT_REPORTED", "REPORTED"]),
        negotiation: new Set([]),
        dispute: new Set(["NOT_DISPUTED", "DISPUTED"]),
        onHold: new Set([]),
        assignment: new Set([]),
        dueDate: new Set([]),
        providerActive: new Set([]),
      },
      showBatchClaimOverview: false,
      claimActions: {
        workflow: [],
        documentation: ["upload"],
        signOff: [],
        invoice: [],
        negotiation: [],
        dispute: [],
        report: [],
        onHold: [],
        assign: [],
        dueDate: [],
      },
      findingsActions: ["view"],
      viewDocs: true,
      viewDocList: true,
      queues: {
        canCreateOrEditQueue: false,
      },
      // route permissions
      homeRoute: "/home",
      routes: [
        "/",
        "/home",
        // '/auth/login',
        "/workspace/:batchClaimId",
        "/claims/:tableName/:searchFilter",
        "/search",
        "/search/:searchFilter",
        "/user/profile",
      ],
      navigation: componentNames([
        HomeTab,
        UserTab,
        UserLogout,
        SearchBarToggle,
        UserProfile,
      ]),
    },
    PAYER: {
      /*
       * Payers are "admins" for their own claims
       * and their "world of claims" is defined in row level security
       * therefore all queries they make are pre-filtered in the database
       * See Policy: 'read_own_insurer_batch_claim'
       */
      userDefaultFilter: {
        batchClaimFilter: {},
        auditFindingFilter: {
          batchClaim: {},
          auditFinding: {},
        },
      },
      baseQueryType: {
        batchClaims: "batchClaims",
        auditFindings: "auditFindings",
      },
      allowMultiSelect: false,
      canSearchClaims: true,
      canEnterWorkstation: true,
      canViewPaymentRateStrategy: false,
      showBatchClaimOverview: true,
      claimStatesToShow: {
        workflow: new Set([
          "CLAIM_RECEIVED",
          "TO_DO",
          "CLAIM_READY",
          "IN_PROGRESS",
          "COMPLETED",
          "REPORTED_TO_PROVIDER",
          "REPORTED_TO_CLIENT",
          "CLOSED",
        ]),
        documentation: new Set(["AWAITING_UPLOAD", "DOCUMENTS_RECEIVED"]),
        findings: new Set([]),
        signOff: new Set([]),
        invoice: new Set(["NOT_PAID", "SENT", "PAID"]),
        report: new Set(["NOT_REPORTED", "REPORTED"]),
        negotiation: new Set([]),
        dispute: new Set([]),
        onHold: new Set(["NOT_ON_HOLD", "ON_HOLD"]),
        assignment: new Set([]),
        dueDate: new Set([]),
        providerActive: new Set([]),
      },
      claimActions: {
        workflow: [],
        documentation: ["upload"],
        signOff: [],
        invoice: [],
        negotiation: [],
        dispute: [],
        report: [],
        onHold: [],
        assign: [],
        dueDate: [],
      },
      viewDocs: true,
      viewDocList: true,
      findingsActions: ["view"],
      queues: {
        canCreateOrEditQueue: true,
      },
      // route permissions
      homeRoute: "/home",
      routes: [
        "/",
        // '/auth/login',
        "/home",
        "/provider/:providerId",
        "/audits/buckets",
        "/audits/buckets/:auditorBucketId/workspace",
        "/reports",
        "/search",
        "/search/:searchFilter",
        "/queues",
        "/claims/:tableName/:searchFilter",
        "/queue/:queueId",
        "/reports/:auditFindingReportId/workspace",
        "/workspace/:batchClaimId",
        "/user/profile",
      ],
      dashboardColumns: [
        "providerDashboardLink",
        "providerName",
        "providerId",
        "queuedClaims",
        "activeClaims",
        "completedClaims",
        "determinedImproperPayment",
      ],
      navigation: componentNames([
        SearchBarToggle,
        HomeTab,
        AuditsTab,
        Queues,
        Reports,
        UserTab,
        UserLogout,
        UserProfile,
        UploadClaims,
      ]),
    },
    AUDITOR: {
      baseQueryType: {
        batchClaims: "batchClaimsAssignedToAuditor",
        auditFindings: "auditFindings",
      },
      userDefaultFilter: {
        batchClaimFilter: {
          ...claimIsAssignedToUserFilter,
        },
        auditFindingFilter: {
          batchClaim: {
            ...claimIsAssignedToUserFilter,
          },
          auditFinding: {},
        },
      },
      allowMultiSelect: true,
      canSearchClaims: true,
      canEnterWorkstation: true,
      canViewPaymentRateStrategy: true,
      showBatchClaimOverview: true,
      claimStatesToShow: {
        workflow: new Set([
          "CLAIM_RECEIVED",
          "TO_DO",
          "CLAIM_READY",
          "IN_PROGRESS",
          "COMPLETED",
          "REPORTED_TO_PROVIDER",
          "REPORTED_TO_CLIENT",
          "CLOSED",
        ]),
        documentation: new Set([
          "NO_REQUESTS",
          "AWAITING_UPLOAD",
          "DOCUMENTS_RECEIVED",
        ]),
        findings: new Set([
          "FINDINGS_TO_REVIEW",
          "FINDINGS_SUPERSEDED",
          "FINDINGS_REVIEWED_DECLINED",
          "FINDINGS_REVIEWED_ACCEPTED",
        ]),
        signOff: new Set([]),
        invoice: new Set([]),
        report: new Set(["NOT_REPORTED", "REPORTED"]),
        negotiation: new Set(["NOT_NEGOTIATING", "NEGOTIATING"]),
        dispute: new Set(["NOT_DISPUTED", "DISPUTED"]),
        onHold: new Set(["NOT_ON_HOLD", "ON_HOLD"]),
        assignment: new Set(["USER_ASSIGNED", "USER_UNASSIGNED"]),
        dueDate: new Set(["NO_DUE_DATE", "DUE_DATE_SET"]),
        providerActive: new Set(["PROVIDER_ACTIVE", "NOT_PROVIDER_ACTIVE"]),
      },
      claimActions: {
        workflow: [
          "setToDo",
          "setClaimReady",
          "setInProgress",
          "setComplete",
          "setReportedToProvider",
          "setReportedToClient",
        ],
        documentation: ["request", "deleteRequest"],
        signOff: ["setProviderSignOff", "setClientSignOff"],
        invoice: [],
        negotiation: ["setNegotiating", "setNotNegotiating"],
        dispute: [],
        report: [],
        onHold: ["setRemoveHold", "setOnHold"],
        assign: ["assign", "singleUnassign", "unassign"],
        dueDate: [],
      },
      findingsActions: ["create", "decline", "review", "view", "dispute"],
      queues: {
        canCreateOrEditQueue: true,
      },
      viewDocs: true,
      viewDocList: true,
      // route permissions
      homeRoute: "/home",
      routes: [
        "/",
        // '/auth/login',
        "/home",
        "/provider/:providerId",
        "/user/profile",
        "/search",
        "/search/:searchFilter",
        "/queues",
        "/queue/:queueId",
        "/claims/:tableName/:searchFilter",
        "/workspace/:batchClaimId",
        "/reports",
      ],
      dashboardColumns: [
        "providerDashboardLink",
        "providerName",
        "providerId",
        "queuedClaims",
        "activeClaims",
        "completedClaims",
        "determinedImproperPayment",
      ],
      navigation: componentNames([
        SearchBarToggle,
        HomeTab,
        AuditsTab,
        Queues,
        UserTab,
        UserProfile,
        UserLogout,
      ]),
    },
    ADMINISTRATOR: {
      userDefaultFilter: {
        batchClaimFilter: {},
        auditFindingFilter: {
          batchClaim: {},
          auditFinding: {},
        },
      },
      baseQueryType: {
        batchClaims: "batchClaims",
        auditFindings: "auditFindings",
      },
      allowMultiSelect: true,
      canSearchClaims: true,
      canEnterWorkstation: true,
      canViewPaymentRateStrategy: true,
      showBatchClaimOverview: true,
      claimStatesToShow: {
        workflow: new Set([
          "CLAIM_RECEIVED",
          "TO_DO",
          "CLAIM_READY",
          "IN_PROGRESS",
          "COMPLETED",
          "REPORTED_TO_PROVIDER",
          "REPORTED_TO_CLIENT",
          "CLOSED",
        ]),
        documentation: new Set([
          "NO_REQUESTS",
          "AWAITING_UPLOAD",
          "DOCUMENTS_RECEIVED",
        ]),
        findings: new Set([
          "FINDINGS_TO_REVIEW",
          "FINDINGS_SUPERSEDED",
          "FINDINGS_REVIEWED_DECLINED",
          "FINDINGS_REVIEWED_ACCEPTED",
        ]),
        signOff: new Set([
          "PROVIDER_NO_SIGN_OFF",
          "PROVIDER_SIGN_OFF",
          "CLIENT_NO_SIGN_OFF",
          "CLIENT_SIGN_OFF",
          "CLIENT_PROVIDER_SIGN_OFF",
        ]),
        invoice: new Set(["SENT", "PAID", "NOT_PAID"]),
        report: new Set(["NOT_REPORTED", "REPORTED"]),
        negotiation: new Set(["NOT_NEGOTIATING", "NEGOTIATING"]),
        dispute: new Set(["NOT_DISPUTED", "DISPUTED"]),
        onHold: new Set(["NOT_ON_HOLD", "ON_HOLD"]),
        assignment: new Set(["USER_ASSIGNED", "USER_UNASSIGNED"]),
        dueDate: new Set(["NO_DUE_DATE", "DUE_DATE_SET"]),
        providerActive: new Set(["PROVIDER_ACTIVE", "NOT_PROVIDER_ACTIVE"]),
      },
      claimActions: {
        workflow: [
          "setToDo",
          "setClaimReady",
          "setInProgress",
          "setComplete",
          "setClosed",
          "setReportedToProvider",
          "setReportedToClient",
        ],
        documentation: ["request", "upload", "deleteRequest"],
        signOff: ["setProviderSignOff", "setClientSignOff"],
        invoice: ["setInvoiceSent", "setInvoicePaid", "setInvoiceRemoved"],
        negotiation: ["setNegotiating", "setNotNegotiating"],
        dispute: ["setDispute", "setNotDispute"],
        report: ["createReport"],
        onHold: ["setRemoveHold", "setOnHold"],
        assign: ["assign", "singleUnassign", "unassign"],
        dueDate: ["setRemoveDueDate", "setDueDate"],
      },
      findingsActions: ["create", "decline", "review", "view", "dispute"],
      viewDocs: true,
      viewDocList: true,
      queues: {
        canCreateOrEditQueue: true,
      },
      // route permissions
      homeRoute: "/home",
      routes: [
        "/",
        // '/auth/login',
        "/home",
        "/provider/:providerId",
        "/reports",
        "/reports/:auditFindingReportId/workspace",
        "/users",
        "/user/profile",
        "/search",
        "/search/:searchFilter",
        "/queues",
        "/queue/:queueId",
        "/workspace/:batchClaimId",
        "/claims/:tableName/:searchFilter",
      ],
      dashboardColumns: [
        "providerDashboardLink",
        "providerName",
        "providerId",
        "queuedClaims",
        "activeClaims",
        "completedClaims",
        "determinedImproperPayment",
        "generateDocRequestReport",
      ],
      navigation: componentNames([
        SearchBarToggle,
        HomeTab,
        AuditsTab,
        Queues,
        Reports,
        CreateAuditOrReport,
        ManageUsersTab,
        UserTab,
        UserProfile,
        UserLogout,
        UploadClaims,
      ]),
    },
  }[userType];

  // Define the dashboard configuration for
  // different user types
  //    components: [DashboardComponents] defined in /dashboard.js
  //    stats: [Statistics] defined in /statistics.js
  // NOTE: ordering of dashboard components comes from
  // this object
  const dashboard = {
    ADMINISTRATOR: {
      components: ["userInfo", "statsPanel", "queues"],
      stats: {
        active: [
          "claimsUnderReview",
          "numberOfLives",
          "determinedDiscrepantAmount",
          "potentialDiscrepantAmount",
          "allowedAmount",
          "billedAmount",
          "potentialClientSavings",
          "determinedClientSavings",
          "savingsPerLife",
        ],
        historic: [
          "claimsReceived",
          "claimsReviewed",
          "numberOfLives",
          "determinedDiscrepantAmount",
          "allowedAmount",
          "billedAmount",
          "clientRealizedSavings",
          "savingsPerLife",
          "numberOfProviders",
        ],
      },
    },
    AUDITOR: {
      components: ["userInfo", "statsPanel", "auditorClientQueues"],
      stats: {
        active: [
          "claimsAssigned",
          "claimsInProgress",
          "claimsCompleted",
          "allowedAmount",
          "billedAmount",
          "potentialDiscrepantAmount",
          "determinedDiscrepantAmount",
          "potentialClientSavings",
          "determinedClientSavings",
        ],
        historic: [
          "claimsAssigned",
          "claimsInProgress",
          "claimsCompleted",
          "allowedAmount",
          "billedAmount",
          "determinedDiscrepantAmount",
          "clientRealizedSavings",
          "numberOfProviders",
        ],
      },
    },
    PROVIDER: {
      components: ["userInfo", "statsPanel", "providerFacilityQueues"],
      stats: {
        active: ["claimsAwaitingDocumentation", "claimsWithProviderSignOff"],
        historic: [],
      },
    },
    PAYER: {
      components: ["userInfo", "statsPanel", "facilityQueues"],
      // The order of these payer stats are displayed
      // are determined by this ticket https://alaffiahealth.atlassian.net/browse/EN-278
      stats: {
        active: [
          "claimsUnderReview",
          "determinedDiscrepantAmount",
          "savingsPerLife",
          "billedAmount",
          "determinedClientSavings",
          "numberOfLives",
          "allowedAmount",
        ],
        historic: [
          "claimsReceived",
          "billedAmount",
          "determinedDiscrepantAmount",
          "claimsReviewed",
          "allowedAmount",
          "clientRealizedSavings",
          "numberOfLives",
          "numberOfProviders",
          "savingsPerLife",
        ],
      },
    },
  }[userType];

  const claimList = {
    PROVIDER: {
      query: providerClaimListQuery,
      dataFragment: payerAndProviderClaimListQueryData,
      allowColumnFilters: false,
      columns: new Map([
        ["claimNumber", { default: true, disabled: true }],
        ["daysOpen", { default: true }],
        ["dateOfService", { default: true }],
        ["dateAdmitDischarge", { default: true }],
        ["provider", { default: true }], // includes id and npi in tooltip
        ["documents", { default: true }],
        ["claimState", { default: true }],
        ["claimActions", { default: true, disabled: true }],
      ]),
    },
    AUDITOR: {
      query: auditorClaimListQuery,
      dataFragment: adminAndAuditorClaimListQueryData,
      allowColumnFilters: true,
      columns: new Map([
        ["claimNumber", { default: true, disabled: true }],
        ["daysOpen", { default: false }],
        ["dueDate", { default: true }],
        ["assignees", { default: true }],
        ["dateOfService", { default: true }],
        ["dateAdmitDischarge", { default: false }],
        ["provider", { default: true }], // includes id and npi in tooltip
        ["billedAmount", { default: true }],
        ["allowedAmount", { default: true }],
        ["determinedDiscrepantAmount", { default: true }],
        ["determinedAdjustedAllowedAmount", { default: true }],
        ["determinedClientSavings", { default: true }],
        ["realizedClientSavings", { default: true }],
        ["providerActive", { default: true }],
        ["documents", { default: true }],
        ["claimState", { default: true }],
        ["claimActions", { default: true, disabled: true }],
      ]),
    },
    ADMINISTRATOR: {
      query: adminClaimListQuery,
      dataFragment: adminAndAuditorClaimListQueryData,
      allowColumnFilters: true,
      columns: new Map([
        ["claimNumber", { default: true, disabled: true }],
        ["dueDate", { default: true }],
        ["daysOpen", { default: false }],
        ["assignees", { default: true }],
        ["dateOfService", { default: true }],
        ["dateAdmitDischarge", { default: false }],
        ["provider", { default: true }], // includes id and npi in tooltip
        ["billedAmount", { default: true }],
        ["allowedAmount", { default: true }],
        ["determinedDiscrepantAmount", { default: true }],
        ["determinedAdjustedAllowedAmount", { default: true }],
        ["determinedClientSavings", { default: true }],
        ["realizedClientSavings", { default: true }],
        ["documents", { default: true }],
        ["providerActive", { default: true }],
        ["claimState", { default: true }],
        ["claimActions", { default: true, disabled: true }],
      ]),
    },
    PAYER: {
      query: payerClaimListQuery,
      dataFragment: payerAndProviderClaimListQueryData,
      allowColumnFilters: false,
      columns: new Map([
        ["claimNumber", { default: true, disabled: true }],
        ["daysOpen", { default: true }],
        ["dateOfService", { default: true }],
        ["provider", { default: true }], // includes id and npi in tooltip
        ["billedAmount", { default: true }],
        ["allowedAmount", { default: true }],
        ["determinedDiscrepantAmount", { default: true }],
        ["determinedAdjustedAllowedAmount", { default: true }],
        ["realizedClientSavings", { default: true }],
        ["documents", { default: true }],
        ["claimState", { default: true }],
        ["claimActions", { default: true, disabled: true }],
      ]),
    },
  }[userType];

  const auditProgress = {
    /* 
      NOTE 1: 
        showAsStats is used to select the set of stats from the auditProgress
        panel thats are rendered as individual stats instead of being rendered within a progress bar.
        showAsStats can be used to render both things such as statistics (i.e determinedAdjustedAllowedAmount)
        or indiviual EnumClaimState values (i.e show the number of claims that are in the state of ON_HOLD or AWAITING_DOCUMENTATION)
        -- Any stats within showAsStats that are EnumClaimStates  will only render 
           if that user has permissions to accesss that claimState from the `claimStatesToShow` field in the user context
      NOTE 2: 
        The showAsStats object takes a set of keys denoted row<rowNumber> (i.e row2), each of which maps to an array of strings.
        The keys represent the number of rows that the statPanel will render when showing these stats, 
        and the values inside the arrays map to the actual statistics that will be rendered in that row.
      NOTE 3:
        Each string value within the array will have one of 2 structures:
          1.  'state.<stateType>.<stateName>'. These are for for stats that are representing EnumClaimStates and are denoted as such inside the showAsStats row arrays.
              i.e for state.negotiation.NEGOTIATING 'state' denotes that this is an EnumClaimState, 'negotiation' is the stateType, 
              and 'NEGOTIATING' is the stateName for which we intend to render. This is neccesary for the auditProgress component because it needs to know 
              both the stateType and stateName of any given  EnumClaimState in order to correctly render it
          2.  'stat.<stateType>'. These are for for stats representing numeric statistics and are denoted as such inside the showAsStats row arrays.
              i.e for stat.determinedClientSavings 'stat' denotes that this is a numeric statistic and 'determinedClientSavings' is the actual stat we wish to render
              This is neccesary for the auditProgress component because it needs to know the stateType of any given numeric statistic in order to correctly render it
        
          This 'state' vs 'stat' distinction exists so that the render function will know how to treat this when displaying this statistic 
          as numeric statistics and EnumClaimState have slightly different rendering rules
      NOTE 4: 
        for each showAsStats row the stats are rendered
        in THE ORDER that they are set in array
      NOTE 5:
        typically after 3-5 items per row the CSS throws 
        the values onto another row 
        (especially happens for items that have large numbers 
         like determinedClientSavings -- css could be improved here)
    */
    ADMINISTRATOR: {
      showAsStats: {
        row1: [
          "stat.potentialClientSavings",
          "state.negotiation.NEGOTIATING",
          "state.onHold.ON_HOLD",
          "stat.totalClaims",
        ],
        row2: [
          "stat.determinedClientSavings",
          "stat.totalAuditFindings",
          "state.dispute.DISPUTED",
        ],
      },
      progressBarsStateTypesToShow: [
        "workflow",
        "documentation",
        "findings",
        "invoice",
        "report",
        "signOff",
      ],
    },
    AUDITOR: {
      showAsStats: {
        row1: [
          "stat.potentialClientSavings",
          "stat.totalAuditFindings",
          "stat.totalClaims",
        ],
        row2: [
          "stat.determinedClientSavings",
          "state.dispute.DISPUTED",
          "state.onHold.ON_HOLD",
        ],
      },
      progressBarsStateTypesToShow: [
        "workflow",
        "documentation",
        "findings",
        "report",
      ],
    },
    PROVIDER: {
      showAsStats: {
        row1: ["state.dispute.DISPUTED", "stat.totalClaims"],
        row2: [],
      },
      progressBarsStateTypesToShow: [
        "workflow",
        "documentation",
        "signOff",
        "report",
      ],
    },
    PAYER: {
      showAsStats: {
        row1: [
          "stat.totalClaims",
          "state.onHold.ON_HOLD",
          "state.documentation.AWAITING_UPLOAD",
        ],
        row2: [],
      },
      progressBarsStateTypesToShow: ["workflow", "invoice", "report"],
    },
  }[userType];

  const workstation = {
    PROVIDER: {
      tabs: {
        // DRG: {}, // todo who should see what on DRG
        UB: {},
        IBIN: {},
        IB: { table: { hides: { createFindingButton: true, filePath: true } } },
      },
      query: providerWorkstationQuery,
      showOnlyBilledAndAllowedAmount: true,
      hides: { auditFindingComment: {} },
    },
    ADMINISTRATOR: {
      tabs: {
        DRG: {},
        UB: {},
        IBIN: {},
        IB: {},
        Findings: {
          columns: ["*"],
        },
        Reports: {}, // dead?
      },
      query: adminAndAuditorWorkstationQuery,
      showOnlyBilledAndAllowedAmount: false,
      canVersionReports: true,
      hides: { auditFindingComment: {} },
    },
    AUDITOR: {
      tabs: {
        DRG: {},
        UB: {},
        IBIN: {},
        IB: { table: { hides: { filePath: true } } },
        Findings: {
          columns: ["*"],
        },
        Reports: {}, // dead?
      },
      query: adminAndAuditorWorkstationQuery,
      showOnlyBilledAndAllowedAmount: false,
      hides: { auditFindingComment: {} },
    },
    PAYER: {
      tabs: {
        DRG: {}, // todo who should see what on DRG
        UB: {},
        IBIN: {},
        IB: { table: { hides: { createFindingButton: true, filePath: true } } },
        Findings: {
          columns: [
            "source",
            "type",
            "quantity",
            "amount",
            "denialCode",
            "status",
            "createdAt",
          ],
        },
        Reports: {}, // dead?
      },
      query: payerWorkstationQuery,
      showOnlyBilledAndAllowedAmount: false,
      hides: { auditFindingComment: { author: true } },
    },
  }[userType];

  const search = {
    PROVIDER: {
      // This could be more concise meaning we could've just went straight to `providerUserFacilities` BUT
      // The benefit of doing things through batchclaims allows us to greatly generalize our queries
      // enabling us to just pass this object into any searchField filter function
      isValidInformationForFormFilter: {
        batchClaim: {
          provider: {
            providerUserFacilities: {
              some: {
                auditorUserId: {
                  equalTo: id,
                },
              },
            },
          },
        },
      },
      fields: new Set([
        "claimNumber",
        "documentation",
        "providerId",
        "dateOfService",
        "dateAdmitDischarge",
      ]),

      canSearchClaims: true,
    },
    ADMINISTRATOR: {
      // admins can search anything
      fields: new Set(["*"]),
      canSearchClaims: true,
      isValidInformationForFormFilter: {
        batchClaim: {},
      },
    },
    AUDITOR: {
      fields: new Set([
        "claimNumber",
        "workflow",
        "documentation",
        "documentType",
        "reported",
        "dispute",
        "onHold",
        "dueDate",
        "providerActive",
        "npi",
        "providerId",
        "insurerId",
        "amountCharged",
        "amountReimbursed",
        "potentialClientSavings",
        "determinedClientSavings",
        "realizedClientSavings",
        "auditFindingsExist",
        "procedureCode",
        "revCode",
        "claimType",
        "createdAtDate",
        "dateOfService",
        "dateAdmitDischarge",
      ]),
      canSearchClaims: true,
      isValidInformationForFormFilter: {
        batchClaim: {
          ...claimIsAssignedToUserFilter,
        },
      },
    },
    PAYER: {
      fields: new Set([
        "claimNumber",
        "workflow",
        "documentation",
        "reported",
        "onHold",
        "invoice",
        "providerActive",
        "npi",
        "providerId",
        "insurerId",
        "realizedClientSavings",
        "createdAtDate",
        "dateOfService",
        "dateAdmitDischarge",
      ]),
      canSearchClaims: true,
      isValidInformationForFormFilter: {
        batchClaim: {
          insurerId: {
            in: insurers.map(({ insurerId }) => insurerId),
          },
        },
      },
    },
  }[userType];

  const reports = {
    PROVIDER: {
      versionHistory: "none",
      reportFilter: {
        providerId: {
          in: providers.map(({ provider: { id } }) => id),
        },
        isLatestVersion: {
          equalTo: true,
        },
      },
      columns: [
        "title",
        "auditFindingReportType",
        "author",
        "totalBilledAmount",
        "totalAllowedAmount",
        "totalDeterminedImproperPayments",
        "reportedFindingsCount",
        "reportCreatedAt",
        "reportPrimaryPia",
        "numberOfClaims",
        "insurer",
        "provider",
        "claimDateRange",
      ],
    },
    ADMINISTRATOR: {
      versionHistory: "full",
      reportFilter: {
        isLatestVersion: {
          equalTo: true,
        },
      },
      columns: [
        "title",
        "auditFindingReportType",
        "auditFindingReportSubtype",
        "author",
        "totalBilledAmount",
        "totalAllowedAmount",
        "totalDeterminedImproperPayments",
        "reportedFindingsCount",
        "reportCreatedAt",
        "reportPrimaryPia",
        "numberOfClaims",
        "insurer",
        "provider",
        "claimDateRange",
        "reportActions",
      ],
    },
    AUDITOR: {
      versionHistory: "full",
      reportFilter: {
        isLatestVersion: {
          equalTo: true,
        },
      },
      columns: [
        "title",
        "auditFindingReportType",
        "auditFindingReportType",
        "author",
        "totalBilledAmount",
        "totalAllowedAmount",
        "totalDeterminedImproperPayments",
        "reportedFindingsCount",
        "reportCreatedAt",
        "reportPrimaryPia",
        "numberOfClaims",
        "insurer",
        "provider",
        "claimDateRange",
      ],
    },
    PAYER: {
      versionHistory: "latest",
      reportFilter: {
        insurerId: {
          in: insurers.map(({ insurerId }) => insurerId),
        },
        isLatestVersion: {
          equalTo: true,
        },
      },
      columns: [
        "title",
        "totalBilledAmount",
        "totalAllowedAmount",
        "totalDeterminedImproperPayments",
        "reportedFindingsCount",
        "reportCreatedAt",
        "numberOfClaims",
        "insurer",
        "provider",
        "claimDateRange",
      ],
    },
  }[userType];

  return {
    id,
    firstName,
    lastName,
    title,
    email,
    userType,
    insurers,
    providers,
    readOnly,
    internalOnly,
    claimIntakeEnabled,
    permissions,
    claimList,
    auditProgress,
    dashboard,
    workstation,
    search,
    reports,
  };
};

// Takes a list of components and returns a list of strings
// [ComponentOne, ComponentTwo] -> ["ComponentOne", "ComponentTwo"]
const componentNames = (components) => components.map((it) => it.name);

const User = ({ id, children, loggedIn }) => {
  const [{ fetching, data, error }] = useQuery({
    query: gql`
      query getUser($auditorUserId: UUID!) {
        auditorUser(id: $auditorUserId) {
          id
          firstName
          lastName
          title
          email
          userType
          readOnly
          internalOnly
          claimIntakeEnabled
          insurerUsers {
            nodes {
              id
              auditorUserId
              insurerId
              insurer {
                id
                name
              }
            }
          }
          providerUserFacilities {
            nodes {
              id
              provider {
                id
                name
              }
            }
          }
        }
      }
    `,
    variables: { auditorUserId: id },
    pause: !loggedIn,
  });

  useEffect(() => {
    if (
      !!data &&
      !!data.auditorUser &&
      !!data.auditorUser.id &&
      data.auditorUser.id.length > 0
    ) {
      const { auditorUser: au } = data;
      datadogRum.setUser({
        name: `${au.userType.toLowerCase()}@${au.id}`,
        id: au.id,
        userType: au.userType,
        readOnly: au.readOnly,
        claimIntakeEnabled: au.claimIntakeEnabled,
      });
    }
  }, [data]);

  return !loggedIn ? (
    // do nothing if not logged in
    <>{children}</>
  ) : !data ? (
    <Spin
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      style={{ width: "100vw", height: "100vh" }}
      delay={1000}
    />
  ) : (
    <UserContext.Provider value={extractUser(data)}>
      {children}
    </UserContext.Provider>
  );
};

export { User, UserContext, extractUser };
