/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Button, Input, message } from "antd";
import { gql, useQuery } from "urql";

import { AskAutodorButton } from "../../components/audit/askAutodor/askAutodorButton";
import { mapFilesToUiFiles } from "../../components/documentViewer/documentViewerUtil";
import { usePdfViewerDialog } from "../../components/documentViewer/PdfViewerProvider";
import type { UiAuditDocumentation } from "../../components/documentViewer/types";
import { batchClaimDocumentationFragment } from "../../fragments";
import type {
  SignedUrlUpdater,
  SignedUrlUpdaterResponse,
} from "../../sw/swIpc/client";
import { SwClientContext } from "../../sw/swProvider";
import { useLog } from "../log/useLog";

export const AA: React.FC = () => {
  const claimId = "dca27b39-ca00-5521-883d-18511109e8e3";

  const [{ data }] = useQuery({
    query: gql`
      query documentationViewer($batchClaimId: UUID!) {
        batchClaim(id: $batchClaimId) {
          ...batchClaimDocumentationFragment
        }
      }
      ${batchClaimDocumentationFragment}
    `,
    variables: { batchClaimId: claimId },
    requestPolicy: "network-only",
    pause: !claimId,
  });

  const documents: UiAuditDocumentation[] = useMemo(() => {
    return mapFilesToUiFiles(
      data?.batchClaim?.auditDocumentation ?? [],
      claimId,
    );
  }, [data]);

  // SIGNED URL UPDATER REG
  const signedUrlUpdater: SignedUrlUpdater = useCallback<SignedUrlUpdater>(
    async (documentId: string) => {
      // TODO WE NEED TO SWITCH TO A PROMISABLE URLQ FETCH...
      console.log(`Search ${documentId} in `, documents);
      const f = documents.find((f1) => f1.id === documentId);
      if (!f) {
        return Promise.reject(
          `Document id for document ${documentId} not found!`,
        );
      }
      return Promise.resolve<SignedUrlUpdaterResponse>({
        documentId,
        url: f.signedUrl,
        expires: f.expiresMs,
      });
    },
    [documents],
  );
  const swClient = useContext(SwClientContext);
  useEffect(() => {
    if (swClient) {
      swClient.registerSignedUrlUpdater(signedUrlUpdater);
    }
    return () => {
      if (swClient) {
        swClient.removeSignedUrlUpdater();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [swClient, documents]);

  const log = useLog("playground -aa");
  log.css = "background-color: lightpink;";

  // TODO: fix playground
  const [currentDocumentId] = useState<string | null>(null);
  const document = useMemo(
    () => documents.find((d) => d.id === currentDocumentId) ?? null,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentDocumentId],
  );

  const { open } = usePdfViewerDialog();

  const setDocument = useCallback(
    (document: UiAuditDocumentation | null, page = 0) => {
      if (document) {
        open({ claimId, initiallySelectedFile: document, initialPage: page });
      }
    },
    [open],
  );

  const go = useCallback(
    (page: number) => {
      setDocument(document, page);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document],
  );

  const [fooVal, setFooVal] = React.useState<string>("");

  return (
    <div tw="af-w-full af-h-full af-bg-amber-50">
      <div>Ask Autodor -- {fooVal}</div>

      <div tw="af-flex af-content-center af-align-middle af-self-center">
        <div tw="af-flex af-content-center af-align-middle af-self-center">
          <Button
            size="small"
            onClick={() => {
              open({ claimId });
            }}
          >
            Show
          </Button>
        </div>
        {[
          3, 7, 9, 29, 99, 499, 999, 1599, 3999, 8999, 9999, 1000, 1299, 14999,
          15098, 15099, 15100, 15101, 15102,
        ].map((page, index) => (
          <div
            tw="af-flex af-content-center af-align-middle af-self-center af-text-xs"
            key={index}
          >
            <Button size="small" onClick={() => go(page)}>
              {page}
            </Button>
          </div>
        ))}
      </div>

      <AskAutodorButton
        documents={documents}
        claimId={claimId}
      ></AskAutodorButton>

      <div tw="af-py-32">
        <div tw="af-p-16 af-border af-border-solid">
          if ya feel the urge to <span tw="af-text-orange-300">freak</span> do
          the jitterbug
          <div tw="af-p-16 af-border af-border-solid">
            <Button onClick={() => message.info("Yes, you can!")}>
              Can I click it?
            </Button>
            <div tw="af-m-8 af-p-8 af-border af-border-solid">
              <Input
                name={"foo"}
                defaultValue="bar"
                onChange={(val) => setFooVal(val.target.value)}
              ></Input>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
