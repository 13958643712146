/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Form, InputNumber } from "antd";
import tw, { css } from "twin.macro";

import { FormExtra } from "../../../../shared/components/form/formExtra";
import { inputPropsCurrencyFormatter } from "../../../../util";
import { asMoney } from "../../../../util/format";
import { isSet } from "../../../../util/isSet";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

export type ImproperPaymentCostProps = CommonFieldProps & {
  flagged?: boolean;
  addlExtraJsx?: JSX.Element;
  formLayout?: any;
};

export const ImproperPaymentCost: React.FC<ImproperPaymentCostProps> = ({
  dataKey,
  label = "Adjustment Amount",
  initialValues,
  formLayout,
  flagged = false,
  addlExtraJsx = undefined,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<number>(
    dataKey,
    initialValues,
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      css={css({
        ...(flagged
          ? {
              "& .ant-input-number": tw`!af-border !af-border-solid af-border-antd-lt-colorWarningText dark:af-border-antd-dk-colorWarningText`,
            }
          : {}),
      })}
      rules={[{ required: true, message: "Enter the adjustment amount ($)" }]}
      extra={
        <FormExtra visible={isSet(existingFindingValue) || !!addlExtraJsx}>
          <div tw="af-flex">
            <div tw="af-min-w-[3em]">
              {isSet(existingFindingValue) && asMoney(existingFindingValue)}
            </div>
            <div tw="af-pl-0.5">{addlExtraJsx}</div>
          </div>
        </FormExtra>
      }
      initialValue={isSet(defaultValue) && defaultValue}
    >
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-expect-error */}
      <InputNumber style={{ width: "50%" }} {...inputPropsCurrencyFormatter} />
    </Form.Item>
  );
};
