import { BehaviorSubject } from "rxjs";

import type { SWClient } from "./swIpc/client";
import { registerSw } from "./swRegister";

const instanceSubject = new BehaviorSubject<SWClient | undefined>(undefined);
const SwClientInstance = instanceSubject.asObservable();
let isInited = false;
const registerServiceWorker = async () => {
  isInited = true;
  return registerSw().then((swReg) => {
    void swReg.swClient.getVersion().then((v) => {
      console.debug(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        `%cActive sw version: ${v.version}`,
        "color:white; background-color:red",
      );
    });
    instanceSubject.next(swReg.swClient);
  });
};

export const getSwClientObservable = () => {
  if (!isInited) {
    registerServiceWorker().catch((e) => console.error("sw init error", e));
  }
  return SwClientInstance;
};
