import { createContext } from "react";

import { AlaffiaClient } from "@alaffia-technology-solutions/client-sdk";

import { graphCache } from "../graphCache";

let alaffiaClient: AlaffiaClient | null = null;

export const AlaffiaClientContext = createContext<AlaffiaClient>(
  (() => {
    const promptResponseRatingsEnabled =
      process.env.PROMPT_RESPONSE_RATINGS_ENABLED;

    const alaC = new AlaffiaClient({
      apiUrl: `${process.env.REACT_APP_API_URL}`,
      graphqlOptions: {
        cacheExchangeOptions: graphCache,
        includeDevtoolsExchange: true,
      },
      logLevel: "info",
      features: {
        promptResponseRatings:
          promptResponseRatingsEnabled === "true" ||
          promptResponseRatingsEnabled === "1",
      },
    });
    alaffiaClient = alaC;
    return alaC;
  })(),
);

export const getAlaffiaClient = () => {
  return alaffiaClient;
};
