import type { FC } from "react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";

const escapeRegExp = (string: string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

interface SearchResultProps {
  pageNumber: number;
  textMatched: string;
  active: boolean;
  query: string;
  onClick: () => void;
}
export const SearchResult: FC<SearchResultProps> = ({
  pageNumber,
  textMatched,
  active,
  query,
  onClick,
}) => {
  const escapedQuery = escapeRegExp(query);

  const parts: (string | JSX.Element)[] = textMatched.split(
    new RegExp(`(${escapedQuery})`, "gi"),
  );

  for (let i = 1; i < parts.length; i += 2) {
    parts[i] = <strong key={i}>{parts[i]}</strong>;
  }

  return (
    <button
      className={cn(
        "af-flex af-items-center af-gap-5 af-rounded-xl af-p-3 af-text-left af-text-xs focus:af-bg-primary-100",
        {
          "af-bg-primary-100": active,
        },
      )}
      onClick={onClick}
    >
      <span className="af-text-gray-700">{pageNumber}</span>
      <span>{parts}</span>
    </button>
  );
};
