import { createQueryHook } from "../factory";

export const userKeys = {
  all: ["users"] as const,
  current: () => [...userKeys.all, "current"],
  tenantName: () => [...userKeys.current(), "tenantName"],
};

export const users = {
  getUserTenantName: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.users.getUserTenantName,
    queryKeyFn: userKeys.tenantName,
  }),
};
