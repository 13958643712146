import type { MentionTypeaheadOption } from "./plugins/MentionsPlugin";

export function MentionsMenu(props: React.ComponentPropsWithoutRef<"ul">) {
  return (
    <div className="af-m-0 af-mt-6 af-w-[420px] af-border af-border-gray-200 af-bg-white af-shadow af-shadow-gray-200">
      <div className="af-px-4 af-py-3 af-text-xs af-uppercase af-text-gray-500">
        Personal Files
      </div>
      <ul className="af-list-none af-p-0" {...props} />
    </div>
  );
}

export function MentionsMenuItem({
  index,
  isSelected,
  onClick,
  onMouseEnter,
  option,
}: {
  index: number;
  isSelected: boolean;
  onClick: () => void;
  onMouseEnter: () => void;
  option: MentionTypeaheadOption;
}) {
  return (
    <li
      key={option.key}
      tabIndex={-1}
      ref={(el) => option.setRefElement(el)}
      role="option"
      aria-selected={isSelected}
      id={"typeahead-item-" + index}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
    >
      <span
        className={`af-m-0 af-flex af-px-4 af-py-2.5 af-text-sm af-text-gray-700 ${
          isSelected ? "af-bg-gray-100" : "af-bg-white"
        }`}
        data-menu-item-metadata={JSON.stringify(option.metadata)}
      >
        {option.value}
      </span>
    </li>
  );
}
