import { createMutationHook, createQueryHook } from "../factory";

export const fileKeys = {
  all: ["files"] as const,
  lists: () => [...fileKeys.all, "list"] as const,
  list: <T>(filters: T) => [...fileKeys.lists(), filters] as const,
  details: () => [...fileKeys.all, "detail"] as const,
  detail: ({ id }: { id: string }) => [...fileKeys.details(), id] as const,
  downloadUrls: () => [...fileKeys.details(), "downloadUrl"] as const,
  downloadUrl: ({ id }: { id: string }) =>
    [...fileKeys.downloadUrls(), id] as const,
  searches: () => [...fileKeys.details(), "search"] as const,
  search: ({ fileId, query }: { fileId: string; query: string }) =>
    [...fileKeys.searches(), fileId, { query }] as const,
};

export const directoryKeys = {
  all: ["directories"] as const,
  details: () => [...directoryKeys.all, "detail"] as const,
  detail: <T>({ id, filters }: { id: string | null; filters?: T }) =>
    filters
      ? ([...directoryKeys.details(), id, filters] as const)
      : ([...directoryKeys.details(), id] as const),
};

export const files = {
  getAllFiles: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.getAllFiles,
    queryKeyFn: fileKeys.list,
  }),
  getDirectory: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.getDirectory,
    queryKeyFn: directoryKeys.detail,
  }),
  getFile: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.getFile,
    queryKeyFn: fileKeys.detail,
  }),
  getFileDownloadUrl: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.getFileDownloadUrl,
    queryKeyFn: fileKeys.downloadUrl,
  }),
  searchInFile: createQueryHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.searchInFile,
    queryKeyFn: fileKeys.search,
  }),
  prepareFileForUpload: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.prepareFileForUpload,
    mutationKey: ["files", "prepareFileForUpload"],
  }),
  confirmFileUploadSuccess: createMutationHook({
    methodSelector: (alaffiaClient) =>
      alaffiaClient.files.confirmFileUploadSuccess,
    mutationKey: ["files", "confirmFileUploadSuccess"],
  }),
  updateFile: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.updateFile,
    mutationKey: ["files", "updateFile"],
  }),
  archiveFile: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.archiveFile,
    mutationKey: ["files", "archiveFile"],
  }),
  createDirectory: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.createDirectory,
    mutationKey: ["files", "createDirectory"],
  }),
  updateDirectory: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.updateDirectory,
    mutationKey: ["files", "updateDirectory"],
  }),
  archiveDirectory: createMutationHook({
    methodSelector: (alaffiaClient) => alaffiaClient.files.archiveDirectory,
    mutationKey: ["files", "archiveDirectory"],
  }),
};
