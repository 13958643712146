/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { createContext } from "react";
import { Global } from "@emotion/react";
import { App as AntdApp, ConfigProvider } from "antd";
import { useHotkeys } from "react-hotkeys-hook";
import resolveConfig from "tailwindcss/resolveConfig";
import tw, { css, GlobalStyles } from "twin.macro";

import tailwindConfig from "../../../../tailwind.config";
import { BodyBgColorUpdater } from "./BodyBgColorUpdater";
import type { UiOptionActions, UiOptions } from "./useUiOptions";
import {
  Reset,
  SetBorderRadius,
  SetDarkMode,
  SetFontSize,
  SetShowMenu,
  useUiOptions,
} from "./useUiOptions";

export interface IDesignProvider {
  options: UiOptions;
  toggleDarkMode: () => void;
  setDarkMode: (enabled: boolean) => void;
  setBorderRadius: (borderRadius: number) => void;
  setFontSize: (fontSize: number) => void;
  reset: () => void;
}

export const DesignProvider = createContext<IDesignProvider>(
  {} as IDesignProvider,
);

export interface DesignSystemProviderProps {
  children?: React.ReactNode;
}

/**
 * Provides theme management for antd and tailwind -- using the same colors
 * See the tailwind.readme.md in the root for details about how the colors are
 * loaded in the tailwind.config.js; they are read using the tailwind config
 * resolver below, and provided back to the antd config provider to control its theme.
 * @param children
 * @constructor
 */
export const DesignSystemProvider = ({
  children,
}: DesignSystemProviderProps) => {
  const twConfig = React.useMemo(() => resolveConfig(tailwindConfig), []);

  const [uiOptions, _updateUiOption] = useUiOptions();

  const updateUiOption = (opt: UiOptionActions) => {
    _updateUiOption(opt);
  };

  const clsScrollbar = React.useMemo(
    () =>
      css(tw`
    af-scrollbar-thin 
    af-scrollbar-thumb-antd-lt-colorBorder dark:af-scrollbar-thumb-antd-dk-colorBorder
    af-scrollbar-track-antd-lt-colorBorderSecondary dark:af-scrollbar-track-antd-dk-colorBorderSecondary
    af-scrollbar-corner-antd-lt-colorBorderSecondary dark:af-scrollbar-corner-antd-dk-colorBorderSecondary
    af-scrollbar-thumb-rounded-md
    `),
    [],
  );

  const designProvider = React.useMemo(
    () => ({
      options: uiOptions,
      toggleDarkMode: () =>
        updateUiOption(new SetDarkMode(!uiOptions.darkMode)),
      setDarkMode: (enabled: boolean) =>
        updateUiOption(new SetDarkMode(enabled)),
      setBorderRadius: (borderRadius: number) =>
        updateUiOption(new SetBorderRadius(borderRadius)),
      setFontSize: (fontSize: number) =>
        updateUiOption(new SetFontSize(fontSize)),
      reset: () => updateUiOption(new Reset()),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [uiOptions],
  );

  const antdTokens = React.useMemo(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    () => twConfig?.theme?.antd || { light: {}, dark: {} },
    [twConfig],
  );

  const getAgGridAlpineTheme = (theme: "light" | "dark") => ({
    "--ag-cell-horizontal-padding": "0.75rem",
    "--ag-header-height": "36px",
    "--ag-alpine-active-color": antdTokens[theme].colorPrimaryBorder,
    "--ag-background-color": antdTokens[theme].colorBgContainer,
    "--ag-odd-row-background-color": antdTokens[theme].colorBgElevated,
    "--ag-row-hover-color": antdTokens[theme].colorPrimaryBg,
    "--ag-selected-row-background-color": antdTokens[theme].colorInfoBg, // .colorPrimaryBgHover,  //_bg-antd-lt-colorInfoBg
    "--ag-header-background-color": antdTokens[theme].bgColor,
    "--ag-border-color": antdTokens[theme].colorBorderSecondary,
    "--ag-value-change-value-highlight-background-color":
      antdTokens[theme].colorPrimary,
    ".ag-header-icon.ag-header-label-icon.ag-filter-icon": tw`af-text-antd-lt-colorHighlight dark:af-text-antd-dk-colorHighlight`,
    ".ag-sort-indicator-container": {
      "& .ag-sort-order, .ag-sort-ascending-icon, .ag-sort-descending-icon": tw`af-text-antd-lt-colorWarningText dark:af-text-antd-dk-colorWarningText`,
    },
  });

  const agGridThemes = React.useMemo(
    () => ({
      ".ag-root-wrapper": {
        ...tw`af-p-0 af-m-0`,
        borderRadius: `${antdTokens.light.borderRadius}px`,
      },
      ".ag-theme-alpine": getAgGridAlpineTheme("light"),
      ".ag-theme-alpine-dark": getAgGridAlpineTheme("dark"),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [antdTokens],
  );

  useHotkeys("ctrl+shift+alt+m", () =>
    updateUiOption(new SetDarkMode(!uiOptions.darkMode)),
  );
  useHotkeys("ctrl+shift+meta+m", () =>
    updateUiOption(new SetShowMenu(!uiOptions.showMenu)),
  );

  return (
    <DesignProvider.Provider value={designProvider}>
      <GlobalStyles />
      <ConfigProvider
        theme={{
          token: {
            ...(uiOptions.darkMode ? antdTokens.dark : antdTokens.light),
            fontSizeHeading3: 18,
            ...(uiOptions.fontSize &&
            uiOptions.fontSize >= 5 &&
            uiOptions.fontSize !== 14
              ? ((f: number) => ({
                  fontSize: f,
                  fontSizeSM: f - 2,
                  fontSizeLG: f + 2,
                  fontSizeXL: f > 14 ? f + 4 : f + 6,
                  fontSizeHeading1: f * 2 + 8,
                  fontSizeHeading2: f * 2,
                  fontSizeHeading3: f + 8,
                  fontSizeHeading4: f + 4,
                  fontSizeHeading5: f + 2,
                }))(uiOptions.fontSize)
              : {}),
            ...(uiOptions.borderRadius === 2
              ? {
                  borderRadius: 2,
                  borderRadiusXS: 1,
                  borderRadiusSM: 2,
                  borderRadiusLG: 3,
                  borderRadiusOuter: 1,
                }
              : {}),
          },
        }}
      >
        <BodyBgColorUpdater />
        <AntdApp style={{ width: "100%", height: "100%" }}>{children}</AntdApp>
        <Global
          styles={css([
            {
              body: {
                width: "100%",
                height: "100%",
                fontSize: "1rem",
                color: "#595c97",
                ...clsScrollbar,
              },
            },
            clsScrollbar,
            agGridThemes,
          ])}
        />
      </ConfigProvider>
    </DesignProvider.Provider>
  );
};
