/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
// external
import type { SetStateAction } from "react";
import { useContext, useState } from "react";
import { css } from "@emotion/react";
import { formatMoney } from "accounting-js";
// component library
import { Spin, Table, Tag, Tooltip, Typography } from "antd";
import gql from "graphql-tag";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";
import { useQuery } from "urql";

// internal
import { UserContext } from "../context/user";
import TableColumnSelector from "../misc/tableColumnSelector";
import { ColTitle, formatDateRange } from "../queues/util";
import { VersionReportButton } from "../reports/versionReportButton";

const clsReportSubtype = css({
  color: "darkgray",
  fontSize: ".85em",

  "& .ant-tag": {
    minWidth: "44px",
    height: "1.8em",
    lineHeight: "1.8em",
    fontSize: ".9em",
    margin: "1px 2px",
  },
});

const clsReportType = css({
  color: "darkgray",
  display: "flex",
  flexDirection: "column",
  "& .ant-tag": { minWidth: "44px", margin: "2px" },
});

const Reports = () => {
  const history = useHistory();

  const { Title } = Typography;
  const {
    reports: { reportFilter, columns: reportColumns },
  } = useContext(UserContext);

  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });

  const [{ fetching, data }, refreshQuery] = useQuery({
    query: gql`
      query reports(
        $reportFilter: AuditFindingReportFilter
        $first: Int!
        $offset: Int
      ) {
        auditFindingReports(
          filter: $reportFilter
          first: $first
          offset: $offset
          orderBy: CREATED_AT_DESC
        ) {
          totalCount
          nodes {
            id
            title
            createdAt
            auditFindingReportType
            auditFindingReportSubtype
            determinedImproperPayments {
              count
              sum
              startDate
              endDate
            }
            primaryPia {
              id
              firstName
              lastName
            }
            author {
              id
              firstName
              lastName
            }
            # FIXME this needs to sum only for claim line amount charges that have audit findings!!!
            batchClaims {
              totalCount
              nodes {
                id
                icn
              }
              aggregates {
                sum {
                  amountReimbursed
                  amountCharged
                }
              }
            }
            reportedFindings {
              totalCount
            }
            provider {
              name
              id
              npi
            }
            insurer {
              id
              name
            }
          }
        }
      }
    `,
    variables: {
      reportFilter: { ...reportFilter },
      ...paginationArgs,
    },
  });

  const paginationConfig = {
    current: currentPageNumber,
    position: ["bottomLeft"],
    pageSizeOptions: ["10", "25", "30", "50"],
    showSizeChanger: true,
    total: data?.auditFindingReports.totalCount,
    onChange: (nextPage: SetStateAction<number>, pageSize: any) => {
      setPaginationOptions({ pageSize });
      setPaginationArgs({
        first: pageSize,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        offset:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          nextPage > 1 ? (nextPage - 1) * paginationOptions.pageSize : null,
      });
      setCurrentPageNumber(nextPage);
      refreshQuery();
    },
    ...paginationOptions,
  };

  const columns = [
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle tooltipTitle="Report Title" title="Title" id="report-title" />
      ),
      default: true,
      key: "title",
      dataIndex: "title",
      render: (title: any) => {
        return <span data-cy="report-list-title-cell"> {title}</span>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Type of Report"
          title="Report Type"
          id="report-type"
        />
      ),
      default: true,
      key: "auditFindingReportType",
      dataIndex: "auditFindingReportType",
      render: (_: any, record: any) => {
        const { auditFindingReportType, auditFindingReportSubtype } = record;
        // todo extract tag colors -- perhaps both these columns for use in each place
        return (
          <div css={clsReportType}>
            <div>
              <Tag
                color={
                  auditFindingReportType === "EOA"
                    ? "blue"
                    : auditFindingReportType === "IFR"
                    ? "purple"
                    : "yellow"
                }
              >
                {auditFindingReportType}
              </Tag>
            </div>
            <div css={clsReportSubtype}>
              {auditFindingReportType !== "EOA" &&
                auditFindingReportSubtype !== "PROCESSED" && (
                  <Tag color={"lightgray"}>
                    {auditFindingReportType === "EOA" ||
                    auditFindingReportSubtype === "PROCESSED"
                      ? ""
                      : auditFindingReportSubtype?.charAt(0).toUpperCase() +
                        auditFindingReportSubtype?.substring(1).toLowerCase()}
                  </Tag>
                )}
            </div>
          </div>
        );
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Author of Report"
          title="Author"
          id="report-author"
        />
      ),
      default: true,
      key: "author",
      dataIndex: "author",
      render: (author: any) => {
        return <Tag> {`${author.firstName} ${author.lastName}`}</Tag>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Billed Amount in Report"
          title="Total Billed Amount"
          id="total-report-billed-amount"
        />
      ),
      default: true,
      key: "totalBilledAmount",
      dataIndex: "batchClaims",
      render: ({ aggregates }: any) => {
        return formatMoney(aggregates.sum.amountCharged);
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Allowed Amount in Report"
          title="Total Allowed Amount"
          id="total-report-allowed-amount"
        />
      ),
      default: true,
      key: "totalAllowedAmount",
      dataIndex: "batchClaims",
      render: ({ aggregates }: any) => {
        return formatMoney(aggregates.sum.amountReimbursed);
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total Client Savings on Report"
          title="Total Client Savings"
          id="total-adjustment-amount-cost"
        />
      ),
      default: true,
      key: "totalDeterminedImproperPayments",
      dataIndex: "determinedImproperPayments",
      render: (determinedImproperPayments: any) => {
        return formatMoney(determinedImproperPayments.sum);
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Date the latest version of report was created"
          title="Creation Date"
          id="report-created-at"
        />
      ),
      default: true,
      key: "reportCreatedAt",
      dataIndex: "createdAt",
      render: (createdAt: moment.MomentInput) => {
        return <div>{moment(createdAt).utc().format("MM/DD/YYYY")}</div>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Primary PIA for Report"
          title="Primary PIA"
          id="report-primary-pia"
        />
      ),
      default: true,
      key: "reportPrimaryPia",
      dataIndex: "primaryPia",
      render: (primaryPia: any) => {
        return <Tag> {`${primaryPia.firstName} ${primaryPia.lastName}`}</Tag>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total number of findings within the report"
          title="Findings Count"
          id="total-number-of-findings-in-report"
        />
      ),
      default: true,
      key: "reportedFindingsCount",
      dataIndex: "reportedFindings",
      render: ({ totalCount }: any) => {
        return <span> {totalCount}</span>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of Claims in Report"
          title="Claims Count"
          id="number-of-claims"
        />
      ),
      default: true,
      key: "numberOfClaims",
      dataIndex: "batchClaims",
      render: (batchClaims: any) => {
        return <span>{batchClaims.totalCount}</span>;
      },
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Insurer of the Report"
          title="Insurer"
          id="report-insurer"
        />
      ),
      default: true,
      key: "insurer",
      dataIndex: "insurer",
      render: ({ name, id }: any) => (
        <Tooltip title={`ID: ${id}`}>
          <div
            key={`claim-list-provider-name-cell-${name}`}
            id={`claim-list-provider-name-cell-${name}`}
          >
            {name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Provider on Report's name, ID, & NPI number"
          title="Provider"
          id="report-provider"
        />
      ),
      default: true,
      key: "provider",
      dataIndex: "provider",
      render: ({ name, id, npi }: any) => (
        <Tooltip title={`ID: ${id}, NPI: ${npi}`}>
          <div
            key={`claim-list-provider-name-cell-${npi}`}
            id={`claim-list-provider-name-cell-${npi}`}
          >
            {name}
          </div>
        </Tooltip>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="First & last date of the claims in the report"
          title="Claim Date Range"
          id="claim-date-range"
        />
      ),
      default: true,
      key: "claimDateRange",
      dataIndex: "determinedImproperPayments",
      render: ({ startDate, endDate }: any) => (
        <div
          key={`report-date-of-service-cell`}
          id={`report-date-of-service-cell`}
        >
          {formatDateRange(startDate, endDate)}
        </div>
      ),
    },
    {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      title: <ColTitle title="Actions" id="reports-list-col-version-report" />,
      default: true,
      key: "reportActions",
      render: (auditFindingReport: any) => (
        <VersionReportButton
          auditFindingReport={auditFindingReport}
          refreshFunction={() =>
            refreshQuery({ requestPolicy: "network-only" })
          }
        />
      ),
    },
  ].filter(({ key }) => {
    return reportColumns.includes(key);
  });

  // state for the selected columns - filter columns by 'default'
  // to determine whether this column should show when the page loads.
  // This state is an array of column.key values
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter((col) => col.default).map((i) => i.key),
  );

  return (
    <div>
      {fetching ? (
        <Spin />
      ) : (
        <Table
          title={() => (
            <div>
              <span>
                <Title
                  level={4}
                  css={css`
                    margintop: 10px;
                    marginbottom: 10px;
                    float: left;
                  `}
                >
                  Reports
                </Title>
              </span>
              <span
                css={css`
                  padding-left: 1%;
                `}
              >
                <TableColumnSelector
                  columns={columns}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </span>
            </div>
          )}
          dataSource={data.auditFindingReports.nodes.map(
            (node: any, i: number) => {
              node.key = `reported-findings-table-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
              return node;
            },
          )}
          loading={fetching}
          columns={columns.filter(({ key }) => selectedColumns.includes(key))}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          pagination={paginationConfig}
          onRow={(report) => {
            return {
              onDoubleClick: () => {
                history.push(
                  `/claims/${report.title} Report/${JSON.stringify({
                    claimNumber: report.batchClaims.nodes.map(
                      ({ icn }: any) => icn,
                    ),
                  })}`,
                );
              },
            };
          }}
        />
      )}
    </div>
  );
};

export { Reports };
