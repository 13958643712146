import * as React from "react";
import { IconBookmarks, IconSearch } from "@tabler/icons-react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import { Button, Input } from "@alaffia-technology-solutions/ui";

import { CloseButton } from "./CloseButton";

type SavedPromptsProps = React.ComponentPropsWithoutRef<"div">;

function SavedPromptsBase({
  className,
  children,
  ...props
}: SavedPromptsProps) {
  return (
    <div
      className={cn(
        "af-flex af-max-h-96 af-w-[756px] af-max-w-full af-flex-col af-gap-3",
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
}

export const SavedPromptsButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Button>
>(({ className, ...props }, ref) => {
  return (
    <Button
      ref={ref}
      variant="neutralGhost"
      size="sm"
      className={cn("af-overflow-x-hidden", className)}
      {...props}
    >
      <IconBookmarks className="af-mr-2 af-h-4 af-w-4 af-flex-shrink-0" />
      <span className="af-truncate">Saved Prompts</span>
    </Button>
  );
});
SavedPromptsButton.displayName = "SavedPromptsButton";

interface SavedPromptsHeaderProps
  extends React.ComponentPropsWithoutRef<"div"> {
  onClose?: () => void;
}

const Header = React.forwardRef<HTMLDivElement, SavedPromptsHeaderProps>(
  ({ className, onClose, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn("af-flex af-items-center af-justify-between", className)}
        {...props}
      >
        <h3 className="af-font-semibold">Saved prompts</h3>
        <CloseButton onClick={onClose} />
      </div>
    );
  },
);
Header.displayName = "SavedPrompts.Header";

interface SavedPromptsSerachProps
  extends Omit<React.ComponentPropsWithoutRef<typeof Input>, "value"> {
  searchPhrase?: string;
  onSearchPhraseChange?: (value: string) => void;
}

const Search = React.forwardRef<HTMLInputElement, SavedPromptsSerachProps>(
  (
    { searchPhrase, onChange, onSearchPhraseChange: onSearch, ...props },
    ref,
  ) => {
    return (
      <Input
        ref={ref}
        type="search"
        placeholder="Search Prompts..."
        icon={<IconSearch className="af-h-4 af-w-4" />}
        className="af-mb-4"
        value={searchPhrase}
        onChange={(e) => {
          onChange?.(e);
          onSearch?.(e.target.value);
        }}
        {...props}
      />
    );
  },
);
Search.displayName = "SavedPrompts.Search";

const List = React.forwardRef<
  HTMLUListElement,
  React.ComponentPropsWithoutRef<"ul">
>(({ className, ...props }, ref) => {
  return (
    <ul
      ref={ref}
      className={cn(
        "af-flex af-list-none af-flex-col af-gap-1 af-overflow-y-auto af-overflow-x-hidden af-p-0",
        className,
      )}
      {...props}
    />
  );
});
List.displayName = "SavedPrompts.List";

interface SavedPromptsListItemProps
  extends Omit<React.ComponentPropsWithoutRef<"li">, "onClick"> {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const ListItem = React.forwardRef<HTMLLIElement, SavedPromptsListItemProps>(
  ({ className, onClick, children, ...props }, ref) => {
    return (
      <li ref={ref} {...props}>
        <button
          onClick={onClick}
          className={cn(
            "af-w-full af-py-4 af-text-left af-text-sm af-text-gray-600 hover:af-cursor-pointer hover:af-text-gray-800",
            className,
          )}
        >
          {children}
        </button>
      </li>
    );
  },
);
ListItem.displayName = "SavedPrompts.ListItem";

export const SavedPrompts = Object.assign(SavedPromptsBase, {
  Header,
  Search,
  List,
  ListItem,
});
