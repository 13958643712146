/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { Tooltip } from "antd";
import tw, { css } from "twin.macro";

import { UserContext } from "../../context/user";

// this style and the svg below basically replicates the antd icon but with a circle rather than a path since
// the default path is jagged af, and there isn't an easy way with the existing icons to modify fill since that controls
// what would normally be the 'stroke' color in their verison, presumably to make it easy to set from the outside, but
// this is hi-vis in the app so let's improve if we can...
const clsSvg = css({
  // the div wrapper with on click:
  ...tw`af-self-center af-justify-self-center af-cursor-pointer`,

  "& svg": {
    // svg defaults
    strokeWidth: "40",
    ...tw`af-w-4 af-h-4`, // icon **size**
    // stroke and fill -- affects non-overrided below, e.g. the circle
    ...tw`af-stroke-antd-lt-colorPrimary af-fill-antd-lt-colorBgLayout dark:(af-stroke-antd-dk-colorPrimary af-fill-antd-dk-colorBgLayout)`,

    "& circle": {
      strokeWidth: "60",
      shapeRendering: "geometricPrecision",
    },

    "& path": {
      shapeRendering: "crispEdges",
      // for the path (the **i**), doubling-down on the stroke/fill color seems to make it more solid
      ...tw`af-stroke-antd-lt-colorPrimary af-fill-antd-lt-colorPrimary dark:(af-stroke-antd-dk-colorPrimary af-fill-antd-dk-colorPrimary)`,
    },
  },
});

const BatchClaimOverviewTriggerButton = ({
  onClickFunction,
  batchClaim: { icn },
}: any) => {
  const { permissions } = React.useContext(UserContext);
  return (
    <>
      {permissions.showBatchClaimOverview && (
        <div tw="af-flex af-items-center af-justify-center af-pr-1.5">
          <Tooltip
            placement="rightTop"
            key={`claim-list-batch-claim-overview-button-tooltip-${icn}`}
            id={`claim-list-batch-claim-overview-button-tooltip-${icn}`}
            title="Claim Event History Timeline/Claim Overview"
            tw="af-flex af-items-center af-justify-center"
          >
            <div
              key={`claim-list-batch-claim-overview-button-wrapper-${icn}`}
              id={`claim-list-batch-claim-overview-button-wrapper-${icn}`}
              css={clsSvg}
              onClick={onClickFunction}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024">
                <circle cx="512" cy="512" r="475" />
                <path d="M464 336a48 48 0 1096 0 48 48 0 10-96 0zm72 112h-48c-4.4 0-8 3.6-8 8v272c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V456c0-4.4-3.6-8-8-8z"></path>
              </svg>
            </div>
          </Tooltip>
        </div>
      )}
    </>
  );
};

export { BatchClaimOverviewTriggerButton };
