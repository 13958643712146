/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { Form, Radio } from "antd";

import { FormExtra } from "../../../../shared/components/form/formExtra";
import type { AuditFindingSeedType } from "../../../createAuditFinding/auditFindingUtil";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

const displayNameMap = {
  UB_CLAIM: [{ label: "Entire Claim", value: "UB_CLAIM" }],
  UB_CLAIM_LINE: [
    { label: "Rev. Code", value: "UB_CLAIM_LINE" },
    { label: "Itemized", value: "IB_CLAIM_LINE" },
  ],
  IB_CLAIM_LINE: [{ label: "Itemized", value: "IB_CLAIM_LINE" }],
};

export type SeedTypeProps = CommonFieldProps & {
  formLayout?: any;
};

export const SeedType: FC<SeedTypeProps> = ({
  dataKey,
  label = "Type",
  initialValues,
  formLayout = {},
}) => {
  const { defaultValue, existingFindingValue } =
    useInitialValues<AuditFindingSeedType>(dataKey, initialValues);
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      tabIndex={-1}
      extra={<FormExtra visible={false}></FormExtra>}
      rules={[{ required: true, message: "Select a finding type" }]}
      initialValue={existingFindingValue ?? defaultValue}
      {...formLayout}
    >
      <Radio.Group
        options={
          displayNameMap[
            (defaultValue ?? "UB_CLAIM_LINE") as keyof typeof displayNameMap
          ]
        }
        optionType="button"
      />
    </Form.Item>
  );
};
