/* eslint-disable @typescript-eslint/no-unsafe-member-access */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { createContext, useCallback, useContext } from "react";
import { Spin } from "antd";
import { useParams } from "react-router-dom";
import type { OperationContext } from "urql";
import { useQuery } from "urql";

import { UserContext } from "../context/user";
import { AmaVerbiage } from "./amaVerbiage";
import { ClaimWorkspace } from "./claimWorkspace/claimWorkspace";

type RefreshBatchClaimContextValue =
  | ((args?: Partial<OperationContext>) => void)
  | null;
const RefreshBatchClaimContext =
  createContext<RefreshBatchClaimContextValue>(null);

// Renders the ClaimWorkspace
// and the AMA verbiage footer
const AuditWorkspace = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  const { batchClaimId } = useParams();
  const {
    workstation: { query },
  } = useContext(UserContext);

  const [{ fetching, data }, refreshQuery] = useQuery({
    query,
    variables: { batchClaimId, afclFilter: { isActive: { equalTo: true } } },
  });

  const refreshBatchClaimQuery = useCallback(
    (args?: Partial<OperationContext>) => refreshQuery(args),
    [refreshQuery],
  );

  return !data && fetching ? (
    <div style={{ textAlign: "center", marginTop: "5%" }}>
      <Spin size="large" />
    </div>
  ) : (
    <div tw="af-flex af-flex-col af-h-full af-min-h-full af-max-h-full af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer">
      <div tw="af-grow">
        <RefreshBatchClaimContext.Provider value={refreshBatchClaimQuery}>
          <ClaimWorkspace
            {...{
              style: { height: "100%", minHeight: "100%", padding: 0 },
              batchClaim: data.batchClaim,
            }}
          />
        </RefreshBatchClaimContext.Provider>
      </div>
      <div>
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-expect-error */}
        <AmaVerbiage id="auditor_workspace_ama_verbiage" />
      </div>
    </div>
  );
};

const useRefreshBatchClaimQuery = () => {
  const refreshBatchClaimContextValue = useContext(RefreshBatchClaimContext);

  return refreshBatchClaimContextValue;
};

export { AuditWorkspace, RefreshBatchClaimContext, useRefreshBatchClaimQuery };
