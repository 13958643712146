import type { FC } from "react";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useMeasure } from "@react-hookz/web";
import { MutationCache, QueryCache, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { LicenseManager } from "ag-grid-enterprise";
import { Layout, message } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { css } from "twin.macro";
import type { Client as UrqlClient } from "urql";
import { Provider } from "urql";

import {
  ClientSDKReactQueryProvider,
  handleError,
} from "@alaffia-technology-solutions/client-sdk-react-query";

import { AlaffiaLogin } from "../../alaffiaClient/alaffiaLogin";
import { useAlaffiaClient } from "../../alaffiaClient/useAlaffiaClient";
import { useLoginStatus } from "../../alaffiaClient/useLoginStatus";
import { ErrorBoundary } from "../../common/error/errorBoundary";
import { SwClientContext } from "../../sw/swProvider";
import { AutodorVersion } from "../context/autodorVersion";
import { User } from "../context/user";
import { PdfViewerProvider } from "../documentViewer/PdfViewerProvider";
import { DesignSystemProvider } from "./design/designProvider";
import { Header } from "./header";
import { Maintenance } from "./maintenance";
import { Navigation } from "./navigation";
import { Routes } from "./routes";
import { UnhandledRejectionsCatch } from "./unhandled-rejections-catch";

LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-057337}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Alaffia_Health}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{alaffia}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{alaffia}_need_to_be_licensed___{alaffia}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{5_June_2025}____[v3]_[01]_MTc0OTA3ODAwMDAwMA==c4a7bb5f02b681c50e0d75170d3d3372",
);

const maintenanceMessage = process.env.MAINTENANCE_MESSAGE ?? null;
const siteOffline = !!maintenanceMessage; // boolean flag for other (future) 'down/limited' status messages

const baseUrl = window.location.origin;

const App: FC = () => {
  const location = useLocation();
  const history = useHistory();

  const alaffiaClient = useAlaffiaClient();
  const { loggedIn } = useLoginStatus();
  const [queryClient] = useState(
    () =>
      new QueryClient({
        queryCache: new QueryCache({
          onError: (error) =>
            handleError(
              error,
              ({ title, description }) =>
                void message.error(`${title}: ${description}`),
            ),
        }),
        mutationCache: new MutationCache({
          onError: (error) =>
            handleError(
              error,
              ({ title, description }) =>
                void message.error(`${title}: ${description}`),
            ),
        }),
      }),
  );
  const [graphqlClient, setGraphqlClient] = useState<UrqlClient>();

  useEffect(() => {
    if (alaffiaClient) {
      const gqlc = alaffiaClient.api.getGraphqlClient();
      setGraphqlClient(gqlc);
    }
  }, [alaffiaClient, setGraphqlClient]);

  // toggle the search bars visibility at the top
  const [searchBarVisible, setSearchBarVisible] = React.useState(false);

  const [_creatingReport, setCreatingReport] = React.useState(false);

  const lastLoggedIn = useRef(loggedIn);
  const swClient = useContext(SwClientContext);
  if (!loggedIn && loggedIn !== lastLoggedIn.current && swClient) {
    void swClient.clearUserData().then((res) => {
      console.log("Clear user data result", res);
    });
  }
  useEffect(() => {
    lastLoggedIn.current = loggedIn;
  }, [loggedIn]);

  useEffect(() => {
    if (!searchBarVisible && location.pathname.includes("/search/")) {
      setSearchBarVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, history, loggedIn]);

  const [headerMeasurements, headerRef] = useMeasure<HTMLDivElement>();
  const headerHeight = headerMeasurements?.height ?? 103;

  const apiUrl = process.env.REACT_APP_API_URL;

  if (!apiUrl) {
    return <div>No API URL</div>;
  }

  const foxitLicenseKey = process.env.ALAFFIA_FOXIT_KEY;

  if (!foxitLicenseKey) {
    return <div>No Foxit license key</div>;
  }

  const foxitLicenseSN = process.env.ALAFFIA_FOXIT_SN;

  if (!foxitLicenseSN) {
    return <div>No Foxit license SN</div>;
  }

  if (!graphqlClient) {
    return <></>;
  }

  return (
    <ErrorBoundary boundaryDescription="App Root">
      <Provider value={graphqlClient}>
        <UnhandledRejectionsCatch>
          <ClientSDKReactQueryProvider
            alaffiaClient={alaffiaClient}
            queryClient={queryClient}
          >
            <PdfViewerProvider
              baseUrl={baseUrl}
              foxitLicenseSN={foxitLicenseSN}
              foxitLicenseKey={foxitLicenseKey}
            >
              <ReactQueryDevtools initialIsOpen={false} />
              <DesignSystemProvider>
                <User
                  id={(loggedIn && alaffiaClient?.auth.userId) ?? null}
                  loggedIn={loggedIn}
                >
                  <AutodorVersion loggedIn={loggedIn}>
                    <div id="app_layout_top_wrapper" tw="af-w-full af-h-full">
                      <div id="app_layout_header" ref={headerRef}>
                        <Header />
                      </div>
                      <Layout
                        id="app_layout"
                        css={css({
                          height: `calc(100vh - ${headerHeight + 2}px)`,
                          maxHeight: `calc(100vh - ${headerHeight + 2}px)`,
                          scrollbarGutter: "stable both-edges",
                        })}
                      >
                        {!siteOffline && (
                          <Layout.Sider
                            id="app_layout_sider"
                            collapsed={true}
                            tw="!af-bg-transparent"
                          >
                            {loggedIn && (
                              <Navigation
                                {...{
                                  setCreatingReport,
                                  searchBarVisible,
                                  setSearchBarVisible,
                                }}
                              />
                            )}
                          </Layout.Sider>
                        )}
                        <Layout
                          id="app_layout_content_container"
                          tw="af-h-full af-min-h-full af-max-h-full"
                        >
                          <Layout.Content
                            id="app_layout_content"
                            tw="af-h-full af-min-h-full af-max-h-full"
                            style={{ scrollbarGutter: "stable both-edges" }}
                          >
                            <AlaffiaLogin>
                              {siteOffline ? (
                                <Maintenance message={maintenanceMessage} />
                              ) : (
                                <Routes
                                  loggedIn={loggedIn}
                                  // auth={auth}
                                  // setDocumentationViewerProps={
                                  //   setDocumentationViewerProps
                                  // }
                                  searchBarVisible={searchBarVisible}
                                />
                              )}
                            </AlaffiaLogin>
                          </Layout.Content>
                        </Layout>
                      </Layout>
                    </div>
                  </AutodorVersion>
                </User>
              </DesignSystemProvider>
            </PdfViewerProvider>
          </ClientSDKReactQueryProvider>
        </UnhandledRejectionsCatch>
      </Provider>
    </ErrorBoundary>
  );
};

export { App };
