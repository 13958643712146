/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import { Modal, Select, Space } from "antd";
import { gql, useMutation } from "urql";

const onboardUserMutation = gql`
  mutation sendUserOnboardNotification(
    $userId: UUID!
    $emailTemplate: MessageSentInputEmailTemplateType!
  ) {
    sendUserOnboardNotification(
      input: { userId: $userId, emailTemplate: $emailTemplate }
    ) {
      messageCreatedId
      notificationKind
      notifications {
        messageSentId
        email {
          success
          response
          rejected
        }
        recipientUserEmail
        recipientUserId
      }
    }
  }
`;

const OnboardUserModal = ({
  user,
  visible,
  close,
  refresh,
  success,
  error,
}: any) => {
  const { userType } = user || {};
  const userIsExternal = ["PAYER", "PROVIDER"].includes(userType);
  const defaultTemplate = userIsExternal
    ? "ONBOARD_COMMERCIAL"
    : "ONBOARD_INTERNAL";

  const [emailTemplate, setEmailTemplate] = useState(defaultTemplate);

  useEffect(() => {
    // update default if user changes (needed b/c parent renders component always)
    if (emailTemplate !== defaultTemplate) {
      setEmailTemplate(defaultTemplate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const [, onboardUser] = useMutation(onboardUserMutation);

  return (
    <Modal
      title="Onboard User"
      style={{ padding: "15px" }}
      open={visible}
      onOk={async () => {
        const { data, error: onboardUserError } = await onboardUser({
          userId: user.id,
          emailTemplate,
        });

        if (onboardUserError) {
          console.log("User onboard Error", data, error);
          error(`Error During Onboarding! ${onboardUserError}`);
        } else {
          success("User Onboarded, Email Sent!");
          refresh();
          close();
        }
      }}
      onCancel={() => close()}
    >
      <Space
        direction="vertical"
        size="large"
        css={css({
          width: "100%",
        })}
      >
        <div>Send onboarding email to {user?.userType} user:</div>
        <div css={css({ fontWeight: "bold" })}>
          {user?.firstName} {user?.lastName}
        </div>
        <div>
          <Space direction="horizontal" size="large">
            <span>Email Template:</span>
            <Select
              style={{ width: 220 }}
              onChange={setEmailTemplate}
              disabled={false}
              value={emailTemplate}
              options={[
                {
                  label: "Commercial",
                  value: "ONBOARD_COMMERCIAL",
                  disabled: !userIsExternal,
                },
                {
                  label: "MDH",
                  value: "ONBOARD_MDH",
                  disabled: !userIsExternal,
                },
                {
                  label: "Internal - Alaffia",
                  value: "ONBOARD_INTERNAL",
                  disabled: userIsExternal,
                },
                // Improvement:  For Sass installations, Internal vs External is confusing as it might be internal to alaffia or internal to SaaS customer....
                {
                  label: "Internal - Ask Autodor",
                  value: "ONBOARD_AA_INTERNAL",
                  disabled: userIsExternal,
                },
              ]}
            ></Select>
          </Space>
        </div>
      </Space>
    </Modal>
  );
};

export { OnboardUserModal };
