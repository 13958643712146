import type { FilterModel, GridOptions } from "ag-grid-community";
import type { DBSchema } from "idb";
import { openDB } from "idb";

interface AgGridPreferences {
  filterModel: FilterModel | null;
  gridOptions: GridOptions | null;
}

type AgGridPreferencesDatabase<TTableNames extends string> = DBSchema & {
  [table in TTableNames]: {
    value: {
      userId: string;
    } & AgGridPreferences;
  };
};

const DB_NAME = "ag-grid-preferences";
const DB_VERSION = 1;
const AG_GRID_TABLE_NAMES = ["ibin"] as const;

type AgGridTableName = (typeof AG_GRID_TABLE_NAMES)[number];

type FiltersDB = AgGridPreferencesDatabase<AgGridTableName>;

const openAgGridPreferencesDB = () => {
  return openDB<FiltersDB>(DB_NAME, DB_VERSION, {
    upgrade(database) {
      for (const tableName of AG_GRID_TABLE_NAMES) {
        database.createObjectStore(tableName, { keyPath: "userId" });
      }
    },
  });
};

export const getPersistedAgGridPreferences = async (
  tableName: AgGridTableName,
  userId: string,
) => {
  const db = await openAgGridPreferencesDB();

  const userPreferences = await db.get(tableName, userId);

  return userPreferences ?? null;
};

export const setPersistedAgGridPreferences = async (
  tableName: AgGridTableName,
  userId: string,
  preferences: Partial<AgGridPreferences> | null,
) => {
  const db = await openAgGridPreferencesDB();

  if (!preferences) {
    return db.delete(tableName, userId);
  }

  const tx = db.transaction(tableName, "readwrite");
  const currentPreferences = (await tx.store.get(userId)) ?? {
    filterModel: null,
    gridOptions: null,
  };

  const updatedPreferences = {
    ...currentPreferences,
    ...preferences,
    gridOptions: {
      ...currentPreferences.gridOptions,
      ...preferences.gridOptions,
    },
  };

  await Promise.all([tx.store.put({ userId, ...updatedPreferences }), tx.done]);
};
