import type { FC } from "react";
import React from "react";
import {
  IconArrowBarToLeft,
  IconArrowBarToRight,
  IconFiles,
  IconFileTypePdf,
} from "@tabler/icons-react";

import { cn } from "@alaffia-technology-solutions/tailwind-utils";
import {
  Button,
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@alaffia-technology-solutions/ui";

import type { FileEntity } from "./Chatbox/types";
import { FileNotReadyTooltip } from "./FileNotReadyTooltip";

type ItemProps = React.HTMLAttributes<HTMLLIElement> & {
  file: FileEntity;
  onFilePreview: (file: FileEntity) => void;
  before?: React.ReactNode;
  after?: React.ReactNode;
  showBefore?: boolean;
  focused?: boolean;
};

const Item = React.forwardRef<HTMLLIElement, ItemProps>(
  (
    {
      file,
      onFilePreview,
      className,
      showBefore = false,
      before,
      after,
      focused = false,
      ...props
    },
    ref,
  ) => {
    return (
      <li
        className={cn(
          "af-group af-flex af-w-full af-flex-col af-gap-1 af-rounded-lg af-border-[1px] af-border-transparent hover:af-border-gray-300 hover:af-bg-gray-200",
          {
            "af-border-gray-300 af-bg-gray-200": focused,
          },
          className,
        )}
        ref={ref}
        {...props}
      >
        <div
          onClick={() => onFilePreview(file)}
          className="af-w-full af-cursor-pointer af-overflow-x-hidden af-px-[6px] af-py-[6px] af-text-left"
          role="presentation"
        >
          <div className="af-flex af-items-center">
            <div className="af-h-4 af-w-4">
              {before ? (
                <div onClick={(e) => e.stopPropagation()} role="presentation">
                  <div
                    className={cn("af-hidden group-hover:af-block", {
                      "af-block": showBefore || focused,
                    })}
                  >
                    {before}
                  </div>
                  <IconFileTypePdf
                    className={cn(
                      "af-h-4 af-w-4 af-text-gray-500 group-hover:af-hidden",
                      { "af-hidden": showBefore || focused },
                    )}
                  />
                </div>
              ) : (
                <IconFileTypePdf className="af-h-4 af-w-4 af-text-gray-500" />
              )}
            </div>

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="af-border-af-border-b af-flex-1 af-cursor-pointer af-overflow-hidden af-rounded-lg af-border-transparent af-px-2 af-text-left">
                  <div
                    className={cn(
                      "af-line-clamp-3 af-text-wrap af-break-words af-text-xs af-font-medium af-text-neutral-900",
                      {
                        "af-text-gray-600": !file.readyToSearch,
                      },
                    )}
                  >
                    {file.fileName}
                  </div>
                </TooltipTrigger>
                <TooltipContent>{file.fileName}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <div
              className={cn(
                "af-invisible af-flex af-w-0 af-items-center group-hover:af-visible group-hover:af-w-[unset]",
                {
                  "af-visible af-w-[unset]": focused,
                },
              )}
              onClick={(e) => e.stopPropagation()}
              role="presentation"
            >
              {after}
            </div>
          </div>
          {!file.readyToSearch && <FileNotReadyTooltip />}
        </div>
      </li>
    );
  },
);

interface HeaderProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  position: "left" | "right";
}
const Header = React.forwardRef<HTMLDivElement, HeaderProps>(
  ({ children, className, position, ...props }, ref) => {
    const { collapse } = useFilesContext();
    return (
      <h2
        className={cn(
          "af-mb-4 af-flex af-items-center af-gap-2 af-text-base af-font-semibold af-text-neutral-900",
          className,
        )}
        ref={ref}
        {...props}
      >
        {children ?? (
          <span className="af-flex af-w-full af-items-center af-justify-between">
            <span className="af-flex af-items-center af-gap-2">
              <IconFiles className="af-size-4" /> Files
            </span>
            <Button
              size="icon"
              variant="ghost"
              onClick={collapse}
              aria-label="Collapse files list"
            >
              {position === "left" && (
                <IconArrowBarToLeft className="af-size-4" />
              )}
              {position === "right" && (
                <IconArrowBarToRight className="af-size-4" />
              )}
            </Button>
          </span>
        )}
      </h2>
    );
  },
);

type BodyProps = React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>>;
const Body = React.forwardRef<HTMLDivElement, BodyProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <div
        className={cn(
          "af-flex af-min-h-0 af-flex-1 af-flex-col af-justify-between af-gap-4",
          className,
        )}
        ref={ref}
        {...props}
      >
        {children}
      </div>
    );
  },
);

type ContentsProps = React.PropsWithChildren<
  React.HTMLAttributes<HTMLUListElement>
>;
const List = React.forwardRef<HTMLUListElement, ContentsProps>(
  ({ children, className, ...props }, ref) => {
    return (
      <ul
        className={cn(
          "af-flex af-list-none af-flex-col af-overflow-y-auto af-p-0",
          className,
        )}
        ref={ref}
        {...props}
      >
        {children ?? <FilesListEmpty />}
      </ul>
    );
  },
);

function FilesListEmpty() {
  return <div role="alert">No results.</div>;
}

interface SectionProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  title: string;
}
const Section = React.forwardRef<HTMLDivElement, SectionProps>(
  ({ children, title, ...props }, ref) => {
    return (
      <div className="af-grid af-gap-2" ref={ref} {...props}>
        <p className="af-line-16 af-text-xs af-font-medium af-uppercase af-text-gray-600">
          {title}
        </p>
        {children}
      </div>
    );
  },
);

interface FilesContextValue {
  collapse: () => void;
  expand: () => void;
}
const FilesContext = React.createContext<FilesContextValue | null>(null);

function useFilesContext() {
  const context = React.useContext(FilesContext);
  if (!context) {
    throw new Error("useFilesContext must be used within a Files component");
  }
  return context;
}

interface FilesListProps
  extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  isExpanded: boolean;
  numberOfSelectedFiles?: number;
  collapse: () => void;
  expand: () => void;
  position: "left" | "right";
  collapsedActions?: React.ReactNode;
}
export const FilesBase = React.forwardRef<HTMLDivElement, FilesListProps>(
  (
    {
      isExpanded,
      children,
      collapse,
      expand,
      position,
      className,
      numberOfSelectedFiles,
      collapsedActions,
      ...props
    },
    ref,
  ) => {
    const conditionalClassName = isExpanded
      ? "af-p-4 af-pb-6"
      : "af-bg-neutral-100";

    return (
      <FilesContext.Provider value={{ collapse, expand }}>
        <div
          className={cn(
            `${conditionalClassName} af-h-full af-border-border`,
            className,
          )}
          ref={ref}
          {...props}
        >
          {isExpanded ? (
            <div className={cn("af-flex af-h-full af-flex-col")}>
              {children}
            </div>
          ) : (
            <CollapsedContents
              position={position}
              numberOfSelectedFiles={numberOfSelectedFiles}
              actions={collapsedActions}
            />
          )}
        </div>
      </FilesContext.Provider>
    );
  },
);

interface CollapsedContentsProps {
  position: "left" | "right";
  numberOfSelectedFiles?: number;
  actions: React.ReactNode;
}
const CollapsedContents: FC<CollapsedContentsProps> = ({
  position,
  numberOfSelectedFiles,
  actions,
}) => {
  const { expand } = useFilesContext();

  return (
    <div className="af-flex af-h-full af-flex-col af-justify-between">
      <div>
        <Button
          size="icon"
          variant="ghost"
          className="af-h-14 af-w-full af-rounded-none af-border-b af-border-border"
          onClick={expand}
          aria-label="Expand files list"
        >
          {position === "right" && <IconArrowBarToLeft className="af-size-4" />}
          {position === "left" && <IconArrowBarToRight className="af-size-4" />}
        </Button>
        <div className="af-relative af-grid af-items-center af-justify-center af-p-4">
          <IconFiles className="af-size-5 af-text-foreground" />
          {numberOfSelectedFiles !== undefined && (
            <div
              className="af-absolute af-bottom-2 af-right-3 af-flex af-min-h-4 af-min-w-4 af-items-center af-justify-center af-rounded-sm af-bg-info-500 af-text-[10px] af-leading-4 af-text-white"
              aria-label={`Number of selected files: ${numberOfSelectedFiles}`}
            >
              {numberOfSelectedFiles}
            </div>
          )}
        </div>
      </div>
      <div className="af-px-2 af-pb-6">{actions}</div>
    </div>
  );
};

export const Files = Object.assign(FilesBase, {
  Header,
  Body,
  List,
  Section,
  Item,
});
