/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import * as stateTypes from "../../../claimState/stateTypes";
import { auditProgressStatistics } from "../auditProgressSingleStatisticDefs";

// This is used to build the outgoing auditProgress query
const generateAuditProgressQuery = (permissions: any, showAsStatsArr: any) => {
  // Returns an array where every item represents the specific subQuery for every EnumClaimState stateType
  const auditProgressSubQueryArray = Object.entries(stateTypes)
    .filter(
      ([stateType, { auditProgress }]: any) =>
        auditProgress && permissions.claimStatesToShow[stateType].size > 0,
    )
    .map(([_, { auditProgress }]: any) => {
      return auditProgress.buildProgressSubQuery({
        permissions,
      });
    });

  /**
   * In addition to showing data regarding EnumClaimState we also want to show
   * some standard statistics such as determinedClientSavings or total number of auditFindngs
   *  --> for these subQueries we have them defined inside the object 'auditProgressStatistics' file
   *  and based on the user's permissions we select which of these queries we need.
   *  These subQueries are then appended to the 'auditProgressSubQueryArray' so that they get retrieved as well from GQL.
   */
  Object.entries(auditProgressStatistics).forEach(([queryName, { query }]) => {
    if (
      showAsStatsArr.includes("stat." + queryName) ||
      queryName === "totalClaims" // regardless of whether we render totalClaims we must query -- as it is referenced by the claimState parse query results functions
    )
      auditProgressSubQueryArray.push(query);
  });

  // Stitch all the subQueries together to build one cohesive auditProgressQuery
  return `query auditProgressQuery($batchClaimFilter: BatchClaimFilter!){
    ${auditProgressSubQueryArray.join("\n")}
  }`;
};

export { generateAuditProgressQuery };
