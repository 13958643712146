/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContext } from "react";
import { Menu } from "antd";

import { UserContext } from "../../context/user";
import { searchableFields } from "./searchableFields";

const SearchFieldsMenu = ({
  setFilterSelectModalVisible,
  setFilterModal,
}: any) => {
  const {
    search: { fields },
  } = useContext(UserContext);

  return (
    <Menu
      onClick={(args) => {
        const {
          query,
          displayName,
          renderComponent,
          typeDef,
          tag,
          options,
          field,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
        } = args.item.props["data-searchitem"];

        setFilterSelectModalVisible(false);
        setFilterModal({
          displayName,
          visible: true,
          query,
          field,
          typeDef,
          tag,
          renderComponent,
          options,
        });
      }}
    >
      {Object.entries(searchableFields)
        // * is used when a user (i.e. Admin) can search all available fields
        .filter(([field, _]) => fields.has(field) || fields.has("*"))
        .map(([field, { displayName, icon, ...props }], i) => {
          return (
            <Menu.Item
              key={`${field}_${i}`}
              id={`${field}_${i}`}
              icon={icon}
              data-searchitem={{ field, displayName, icon, ...props }}
            >
              {displayName}
            </Menu.Item>
          );
        })}
    </Menu>
  );
};

export { SearchFieldsMenu };
