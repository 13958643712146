/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { UserAddOutlined, UserDeleteOutlined } from "@ant-design/icons";
import { Avatar, Tag, Tooltip } from "antd";

const AssigneeAvatarGroup = ({ activeAssignees }: any) => (
  <Avatar.Group maxCount={3}>
    {activeAssignees.nodes.map(({ firstName, lastName, id }: any) => (
      <Tooltip
        title={`${firstName} ${lastName}`}
        key={`assignee-tooltip-${id}`}
      >
        <Avatar key={`assignee-avatar-${id}`}>
          {firstName[0]}
          {lastName[0]}
        </Avatar>
      </Tooltip>
    ))}
  </Avatar.Group>
);

const assignment = {
  render: ({ activeAssignees }: any) => {
    return activeAssignees.nodes.length > 0 ? (
      <AssigneeAvatarGroup activeAssignees={activeAssignees} />
    ) : (
      "-"
    );
  },
  dataType: "auditorUser",
  onTableQuery: "batchClaims",
  states: {
    USER_ASSIGNED: {
      name: "Assign User",
      icon: <UserAddOutlined />,
      timelineEventRendering: {
        description: () => "User Assigned",
        renderingAllowed: () => true,
        customRendering: ({ metaData: assigneeName }: any) => (
          <span>
            <Tag>{assigneeName}</Tag>
            <strong>assigned</strong>
          </span>
        ),
      },
    },
    USER_UNASSIGNED: {
      name: "Un-assign User",
      icon: <UserDeleteOutlined />,
      timelineEventRendering: {
        description: () => "User Un-assigned",
        renderingAllowed: () => true,
        customRendering: ({ metaData: assigneeName }: any) => (
          <span>
            <Tag>{assigneeName}</Tag>
            <strong>un-assigned</strong>
          </span>
        ),
      },
    },
  },
  filterBuilder: (values: any) => {
    if (values.includes("NO_ASSIGNEES")) {
      return {
        or: [
          { batchClaimAssigneesExist: false }, // DOC (MV): isAssigned is slow for unassigned
          { isAssigned: { equalTo: false } },
        ],
      };
    } else {
      return {
        and: [
          { batchClaimAssigneesExist: true },
          { isAssigned: { equalTo: true } },
          {
            batchClaimAssignees: {
              some: {
                auditorUser: {
                  firstName: {
                    in: values.map((fullName: any) => fullName.split(" ")[0]),
                  },
                  lastName: {
                    in: values.map((fullName: any) => fullName.split(" ")[1]),
                  },
                },
              },
            },
          },
        ],
      };
    }
  },
};

export { assignment };
