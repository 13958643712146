/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/tabindex-no-positive */
/* eslint-disable @typescript-eslint/unbound-method */
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import type { FC } from "react";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { keyframes } from "@emotion/react";
import { Dialog, Transition } from "@headlessui/react";
import { Divider } from "antd";
import tw, { css } from "twin.macro";

import type { LoginResult } from "@alaffia-technology-solutions/client-sdk";

import { tailwindPreflight } from "../components/app/design/tw-preflight";
import { Icon } from "../components/app/icon";
import type { ChallengeArgs } from "./types";

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;
const fadeInSecs = 0.5;
const iconCss = css({
  height: "100%",
  display: "flex",
  alignItems: "center",
  "& img": {
    animation: `${fadeIn} ${fadeInSecs}s ease`,
    flex: "0 0 auto",
    width: "48px",
    height: "auto",
  },
});

const minWidth = "375px";
const maxWidth = "50vw";
// className="af-box af-p-4 af-min-w-80 af-w-full af-max-w-md af-transform af-overflow-hidden af-rounded-2xl af-text-left af-align-middle af-shadow-xl af-transition-all af-bg-antd-lt-colorBgContainer dark:af-bg-antd-lt-colorBgContainer"
const clsPanel = css({
  minWidth,
  maxWidth,
  ...tw`af-w-full af-p-4 af-transform af-overflow-hidden af-rounded-2xl af-text-left af-align-middle af-shadow-xl af-transition-all`,
  ...tw`af-bg-antd-lt-colorBgContainer dark:af-bg-antd-dk-colorBgContainer`,
});

// className="af-block af-text-gray-700 af-text-sm font-bold mb-2"
const clsLabel = css({
  ...tw`af-block af-text-gray-700 af-text-sm af-mb-2`,
});

// className="af-shadow af-appearance-none af-border af-border-red-500 af-rounded af-w-full af-py-2 af-px-3 af-text-gray-700 af-mb-3 af-leading-tight focus:af-outline-none focus:af-shadow-outline"
const clsInput = css({
  ...tw`af-shadow af-appearance-none af-border  af-rounded af-w-full af-py-2 af-px-3 af-mb-3 af-leading-tight`,
  ...tw`af-border-antd-lt-colorPrimaryBorder focus:af-border-antd-lt-colorBorderSecondary af-text-antd-lt-colorText af-placeholder-antd-lt-colorTextPlaceholder`,
  ...tw`dark:(af-border-antd-dk-colorPrimaryBorder focus:af-border-antd-dk-colorBorderSecondary af-text-antd-dk-colorText af-placeholder-antd-dk-colorTextPlaceholder)`,
  ...tw`disabled:(af-border-gray-200 af-shadow-none af-border-solid af-text-gray-400)`,
  "&:placeholder-shown": tw`af-border-antd-lt-colorErrorBorderHover dark:(af-border-antd-dk-colorErrorBorderHover)`,
});

const clsLinkButton = css({
  ...tw`af-bg-inherit af-border-none af-p-0 af-text-antd-lt-colorLink af-text-xs af-underline af-cursor-pointer`,
});

const TermsAndConditions = () => (
  <div tw="af-px-4 af-whitespace-nowrap af-text-xs af-flex af-items-center af-text-antd-lt-colorTextSecondary  dark:af-text-antd-dk-colorTextSecondary ">
    By logging in, you are agreeing to the{" "}
    <a
      tw="af-px-2 af-text-antd-lt-colorLink dark:af-text-antd-dk-colorLink"
      target="_blank"
      href="https://alaffiahealth.com/privacy-policy/"
      rel="noreferrer"
    >
      Alaffia Privacy Policy
    </a>
  </div>
);

export const LoginModal: FC<{
  loginResult: LoginResult | null;
  login({ user, pass }: { user: string; pass: string }): void;
  challenge(args: ChallengeArgs): void;
  forgotPassword(user: string): void;
  isOpen: boolean;
  setIsOpen(b: boolean): void;
  lastError?: string;
}> = ({
  loginResult,
  login,
  challenge,
  forgotPassword,
  isOpen,
  setIsOpen,
  lastError,
}) => {
  function closeModal() {
    setIsOpen(false);
  }

  // 'current' (old) pwd isn't currently needed for our two idp, cognito & kc-dev
  const requireOldPwd = false;

  // if an idp does req, we can have a disabled version on this flag
  const isKcForceChangePwd =
    loginResult?.predicates.isForceChangePassword(loginResult) &&
    loginResult?.idpType === "keycloak-dev-only";

  const [isForgotPassword, setForgotPassword] = useState(false);
  useEffect(() => {
    console.log("effect: loginresult", loginResult);
    if (
      isForgotPassword &&
      loginResult?.predicates.isCredentialsChangeSuccess(loginResult)
    ) {
      console.log("set forgot password false");
      setForgotPassword(false);
    }
  }, [loginResult, lastError, isForgotPassword, setForgotPassword]);

  const signinMessage = isForgotPassword
    ? "Reset Password"
    : !loginResult
    ? "Sign in"
    : loginResult?.predicates?.isConfigureMfa(loginResult)
    ? "Save MFA"
    : loginResult?.predicates?.isLoginChallenge(loginResult)
    ? "Continue"
    : loginResult?.predicates?.isForceChangePassword(loginResult)
    ? "Change Password"
    : "Sign in";

  const handleLogin = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      if (isForgotPassword && !loginResult) {
        const user = data.get("username") as string;
        if (!user) {
          throw new Error("User name is required");
        }
        forgotPassword(user);
      } else if (
        loginResult?.predicates.isLoginChallenge(loginResult) ||
        loginResult?.predicates.isConfigureMfa(loginResult)
      ) {
        challenge({
          code: data.get("code") as string,
        });
      } else if (loginResult?.predicates.isForceChangePassword(loginResult)) {
        challenge({
          oldPassword: !requireOldPwd
            ? "unused-old-password"
            : (data.get("oldPassword") as string),
          newPassword: data.get("newPassword") as string,
          confirmPassword: data.get("confirmPassword") as string,
        });
      } else {
        login({
          user: data.get("username") as string,
          pass: data.get("password") as string,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [login, challenge, loginResult, isForgotPassword],
  );

  // NOTE the {/* @ts-ignore */} suppress error that seems related to using "jsxImportSource": "@emotion/react" tsconfig:
  //    TS2590: Expression produces a union type that is too complex to represent.
  // Switching "jsxImportSource" to just "react" removes the error but then every instance of css={...} gets flagged...
  return (
    <div css={tailwindPreflight}>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="af-vrelative af-z-10" onClose={closeModal}>
          <Transition.Child
            enter="af-ease-out af-duration-900"
            enterFrom="af-opacity-0"
            enterTo="af-opacity-100"
            leave="af-ease-in duration-200"
            leaveFrom="af-opacity-100"
            leaveTo="af-opacity-0"
          >
            <div className="af-fixed af-inset-0 af-bg-black/25" />
          </Transition.Child>

          <div className="af-fixed af-inset-0 af-overflow-y-auto">
            <div className="af-flex af-min-h-full af-items-center af-justify-center af-p-4 af-text-center">
              <Transition.Child
                enter="af-ease-out af-duration-300"
                enterFrom="af-opacity-0 af-scale-95"
                enterTo="af-opacity-100 af-scale-100"
                leave="af-ease-in af-duration-200"
                leaveFrom="af-opacity-100 af-scale-100"
                leaveTo="af-opacity-0 af-scale-95"
              >
                <Dialog.Panel css={clsPanel}>
                  <Dialog.Title
                    as="h3"
                    className="af-text-lg af-font-medium af-leading-6 af-text-gray-600"
                  >
                    <div className="af-flex af-h-16">
                      <div className="af-w-16">
                        <Icon
                          className={""}
                          css={iconCss}
                          data-cy="header-icon"
                        ></Icon>
                      </div>
                      <div className="af-w-full af-place-self-center af-text-center">
                        Login to Alaffia
                      </div>
                      <div className="af-w-16"></div>
                    </div>
                  </Dialog.Title>
                  <div className="af-mt-2 af-text-sm">
                    <div className="w-full max-w-xs">
                      <form
                        onSubmit={handleLogin}
                        className="af-mb-2 af-px-8 af-pb-2 af-pt-6"
                      >
                        {
                          // ** forgot password **

                          isForgotPassword && !loginResult ? (
                            <>
                              <div className="_mb-6">
                                <label css={clsLabel} htmlFor="username">
                                  Enter the email address or username registered
                                  with Alaffia and click continue to send a
                                  reset code to your email
                                </label>
                                <input
                                  css={clsInput}
                                  name="username"
                                  id="username"
                                  placeholder="Enter email address or username"
                                  defaultValue={""}
                                  disabled={false}
                                  autoFocus
                                  tabIndex={1}
                                  type="text"
                                  autoComplete="username"
                                  required
                                  title={""}
                                />
                              </div>
                              <div className="_mb-4 _flex _justify-between">
                                &nbsp;
                              </div>
                            </>
                          ) : // ** MFA **
                          loginResult?.predicates?.isConfigureMfa(
                              loginResult,
                            ) ? (
                            <div className="af-mb-6">
                              <div style={{ paddingTop: "10px" }}>
                                1. Install a compatible app on your mobile
                                device or computer
                              </div>
                              <Divider />
                              <div style={{ paddingTop: "10px" }}>
                                2. Use your virtual MFA app and your devices
                                camera to scan the QR code
                              </div>
                              <div style={{ paddingTop: "10px" }}>
                                <img
                                  alt=""
                                  src={loginResult?.totpQrCode ?? ""}
                                />
                              </div>
                              <div style={{ paddingTop: "5px" }}>
                                Alternatively you can type in the secret key:{" "}
                                {loginResult?.totpSecret ?? ""}
                              </div>
                              <Divider />
                              <div style={{ paddingTop: "10px" }}>
                                3. Type the next MFA code below from your
                                virtual MFA app
                              </div>
                              <div className="af-mb-6">
                                <label css={clsLabel} htmlFor="password">
                                  MFA Code{" "}
                                  {loginResult.challengeKind === "EMAIL_OTP"
                                    ? `( sent to ${loginResult.authParams.codeDeliveryDestination} )`
                                    : ""}
                                </label>
                                <input
                                  css={clsInput}
                                  name="code"
                                  id="code"
                                  type="password"
                                  placeholder="Enter MFA code"
                                  tabIndex={1}
                                  autoFocus
                                  autoComplete={
                                    loginResult.challengeKind ===
                                    "SOFTWARE_TOKEN_MFA"
                                      ? "one-time-code"
                                      : undefined
                                  }
                                  required
                                />
                              </div>
                            </div>
                          ) : loginResult?.predicates?.isLoginChallenge(
                              loginResult,
                            ) ? (
                            <div className="af-mb-6">
                              <label css={clsLabel} htmlFor="password">
                                MFA Code{" "}
                                {loginResult.challengeKind === "EMAIL_OTP"
                                  ? `( sent to ${loginResult.authParams.codeDeliveryDestination} )`
                                  : ""}
                              </label>
                              <input
                                css={clsInput}
                                name="code"
                                id="code"
                                placeholder="Enter MFA Code"
                                type="password"
                                tabIndex={1}
                                autoFocus
                                autoComplete={
                                  loginResult.challengeKind ===
                                  "SOFTWARE_TOKEN_MFA"
                                    ? "one-time-code"
                                    : undefined
                                }
                                required
                              />
                            </div>
                          ) : loginResult?.predicates?.isForceChangePassword(
                              loginResult,
                            ) ? (
                            <>
                              <div className="af-mb-4">
                                <label css={clsLabel} htmlFor="username">
                                  Username
                                </label>
                                <input
                                  css={clsInput}
                                  name="username"
                                  id="username"
                                  placeholder="Enter user name"
                                  defaultValue={
                                    loginResult?.authParams.username ?? ""
                                  }
                                  disabled={!!loginResult?.authParams.username}
                                  autoFocus
                                  tabIndex={1}
                                  type="text"
                                  autoComplete="username"
                                  required
                                  title={
                                    "username: " +
                                    (loginResult?.authParams.username ?? "")
                                  }
                                />
                              </div>
                              {requireOldPwd && (
                                <div className="af-mb-6">
                                  <label css={clsLabel} htmlFor="oldPassword">
                                    Current Password
                                  </label>
                                  {/* keycloak does not support old password! */}
                                  <input
                                    css={clsInput}
                                    name="oldPassword"
                                    id="oldPassword"
                                    placeholder="Enter current password"
                                    disabled={isKcForceChangePwd}
                                    {...(isKcForceChangePwd
                                      ? { value: "Not required for keycloak" }
                                      : {})}
                                    type={
                                      isKcForceChangePwd ? "text" : "password"
                                    }
                                    autoComplete="current-password"
                                    tabIndex={2}
                                  />
                                </div>
                              )}
                              <div className="af-mb-6">
                                <label css={clsLabel} htmlFor="newPassword">
                                  New Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="newPassword"
                                  id="newPassword"
                                  placeholder="Enter new password"
                                  type="password"
                                  autoComplete="new-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>

                              <div className="af-mb-6">
                                <label css={clsLabel} htmlFor="confirmPassword">
                                  Confirm New Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="confirmPassword"
                                  id="confirmPassword"
                                  placeholder="Confirm new password"
                                  type="password"
                                  autoComplete="new-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="af-mb-4">
                                <label css={clsLabel} htmlFor="username">
                                  Username
                                </label>
                                <input
                                  css={clsInput}
                                  name="username"
                                  id="username"
                                  placeholder="Enter user name"
                                  autoFocus
                                  tabIndex={1}
                                  type="text"
                                  autoComplete="username"
                                  required
                                />
                              </div>
                              <div className="af-mb-6">
                                <label css={clsLabel} htmlFor="password">
                                  Password
                                </label>
                                <input
                                  css={clsInput}
                                  name="password"
                                  id="password"
                                  placeholder="Enter password"
                                  type="password"
                                  autoComplete="current-password"
                                  tabIndex={2}
                                  required
                                />
                              </div>
                            </>
                          )
                        }
                        <div className="af-flex af-justify-between">
                          <div className=""></div>
                          <button
                            tabIndex={3}
                            type="submit"
                            className="af-ml-auto af-inline-flex af-justify-center af-rounded-md af-border af-border-transparent af-bg-blue-100 af-px-4 af-py-2 af-text-sm af-font-medium af-text-blue-900 hover:af-bg-blue-200 focus:af-outline-none focus-visible:af-ring-2 focus-visible:af-ring-blue-500 focus-visible:af-ring-offset-2"
                            onClick={closeModal}
                          >
                            {signinMessage}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="af-text-antd-lt-colorError dark:af-text-antd-dk-colorError af-p-2 af-text-xs">
                    {lastError}
                  </div>
                  <div className="af-mb-6 af-flex af-justify-center">
                    {isForgotPassword ? (
                      <button
                        tabIndex={4}
                        onClick={() => setForgotPassword(false)}
                        css={clsLinkButton}
                      >
                        Return to login screen
                      </button>
                    ) : (
                      <button
                        onClick={() => setForgotPassword(true)}
                        css={clsLinkButton}
                        tabIndex={4}
                      >
                        Forgot password?
                      </button>
                    )}
                  </div>
                  <TermsAndConditions />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};
