/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unused-vars */
import type {
  Cache,
  CacheExchangeOpts,
  DataFields,
  FieldArgs,
} from "@urql/exchange-graphcache";

import schema from "../introspection.min.json";
import type { AuditFinding } from "./gql/graphql";

const invalidateQueries = (
  operationName: string,
  cache: Cache,
  invalidates: { field: string; args: FieldArgs }[],
) =>
  invalidates.forEach((invalidate) => {
    console.debug(
      "%c%s: Invaliding query: %s %O",
      "color: chartreuse; font-weight: bold;",
      operationName,
      invalidate.field,
      invalidate.args,
    );
    cache.invalidate("Query", invalidate.field, invalidate.args);
  });

// Idea: introduce a function here to get the graphCache, that supports
// an 'observer' interface/arg that gets notified with read-only props
// when each member of keys/queries/mutations gets called, maybe at the end
// of each function, e.g. containing {request: {result, args, cache, info}, response}
// where request is the inputs, and response is optional, up to the writer (although
// we could introduce utils to wrap/return the values) to provide some detail on the
// update...  Or, we coule introduce classes, e.g. Query, Mutation, Key and internalize
// all updates wrapped with protected members that can be overridden to observe updates
// Purpose here would be primarily testing, but could also allow some interesting things,
// like the ability to colocate the cache function with the code it pertains to...
const graphCache: Partial<CacheExchangeOpts> = {
  schema: schema as CacheExchangeOpts["schema"],
  keys: {
    NpiProvider: (data) => "" + data.npi,
    BatchClaimStateAggregates: (data) => null,
    BatchClaimStateSumAggregates: (data) => null,
    ReportAggregate: (data) => null,
    AuditFindingAggregates: (data) => null,
    AuditFindingSumAggregates: (data) => null,
    BatchClaimAggregates: (data) => null,
    BatchClaimSumAggregates: (data) => null,
    BucketClaimAggregates: (data) => null,
    AuditFindingReviewsConnection: (data) => null,
    BucketClaimsConnection: (data) => null,
    BatchClaimsConnection: (data) => null,
    BatchClaimLinesConnection: (data) => null,
    AuditFindingsConnection: (data) => null,
    ProviderUserFacilitiesConnection: (data) => null,
    SupportingDocumentation: (data) => null,
    AuditFindingRuleType: (data) => {
      return "" + data.type;
    },
    S3SignedUpload: (data) => null,
    FoxitCredentials: (data) => null,
    HistoryTimelineEvents: (data) => null,
    ClaimIntakeResponse: (data) => null,
    CsvRow: (data) => "" + (data.alaRowId ?? data.headerRowId),
    ActiveStatistics: (data) => null,
    HistoricStatistics: (data) => null,
    LatestReview: (data) => null,
    Autodor: (data) => null,
    AutodorFinding: (data) => (data.id as string) ?? null,
    AutodorMetadata: (data) => null,
    MUEAuditRule: (data) => null,
    PTPAuditRule: (data) => null,
    AutodorSink: (data) => null,
    AuditRule: (data) => null,
    MUEAuditRuleRationale: (data) => null,
    NonBillableAuditRule: (data) => null,
    AskAutodorMatchedText: (data) => null,
    AskAutodorFileResponse: (data) => null,
    AskAutodorFileVectorsExistResponse: (data) => null,
    UserCognitoSettings: (data) => null,
    AskAutodorQueueSummary: () => null,
    PrepareFileForUploadResult: () => null,
    PromptResponsePage: () => null,
    PromptFeedback: () => null,
    SignedDownloadUrl: (data) => null,
  },
  resolvers: {
    Query: {
      auditorUser: (parent, args) => ({
        __typename: "AuditorUser",
        id: args.id,
      }),
      auditorBucket: (parent, args) => ({
        __typename: "AuditorBucket",
        id: args.id,
      }),
      auditFindingReview: (parent, args) => ({
        __typename: "AuditFindingReview",
        id: args.id,
      }),
      auditFinding: (parent, args) => ({
        __typename: "AuditFinding",
        id: args.id,
      }),
      finding: (parent, args) => ({
        __typename: "Finding",
        id: args.id,
      }),
      batchClaimLine: (parent, args) => ({
        __typename: "BatchClaimLine",
        id: args.id,
      }),
      batchClaim: (parent, args) => ({ __typename: "BatchClaim", id: args.id }),
      bucketClaim: (parent, args) => ({
        __typename: "BucketClaim",
        id: args.id,
      }),
      auditFindingReport: (parent, args) => ({
        __typename: "AuditFindingReport",
        id: args.id,
      }),
    },
  },
  updates: {
    Mutation: {
      createAuditFindingReview: (result, args, cache, info) => {},
      createAuditorBucket: (result, args, cache, info) => {},

      createIbLineFindings: (result, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createAuditFindings as any).auditFindings[0]
          ?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };

          invalidateQueries(info.fieldName, cache, [
            { field: "batchClaim", args: claimArgs },
          ]);

          return;
        }
      },

      createUbClaimLineFindings: (result, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createAuditFindings as any).auditFindings[0]
          ?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };

          invalidateQueries(info.fieldName, cache, [
            { field: "batchClaim", args: claimArgs },
          ]);

          return;
        }
      },

      createReviewedAuditFinding: (result: DataFields, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimId = (result?.createReviewedAuditFinding as any)
          .reviewedAuditFinding?.auditFinding?.batchClaimId;
        if (claimId) {
          const claimArgs = { id: claimId };

          invalidateQueries(info.fieldName, cache, [
            { field: "batchClaim", args: claimArgs },
          ]);

          return;
        }
      },

      versionAuditFindings: (result: DataFields, args, cache, info) => {
        if (info.error) {
          return;
        }
        const claimIds: string[] = (
          (result?.versionAuditFindings as any).auditFindings ?? []
        )
          .map((af: AuditFinding) => af.batchClaimId)
          .filter((id: string) => !!id);

        if (claimIds.length > 0 && claimIds[0]) {
          const claimArgs = { id: claimIds[0] };

          invalidateQueries(info.fieldName, cache, [
            { field: "batchClaim", args: claimArgs },
          ]);
        }
      },
    },
  },
};

export { graphCache };
