import { forwardRef } from "react";
import type { ComponentProps } from "react";
import { IconUpload } from "@tabler/icons-react";

import { Button } from "@alaffia-technology-solutions/ui";

type ImportFilesButtonProps = ComponentProps<typeof Button>;

export const ImportFilesButton = forwardRef<
  HTMLButtonElement,
  ImportFilesButtonProps
>(({ children, ...props }, ref) => (
  <Button
    ref={ref}
    className="af-flex af-max-w-full af-gap-2 af-border-0 af-bg-primary-200 af-px-4 af-text-center af-text-primary-800"
    size="sm"
    variant="outline"
    {...props}
  >
    <IconUpload size={16} className="af-flex-shrink-0" />
    {children && <div className="af-truncate">{children}</div>}
  </Button>
));
