/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-call */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React from "react";
import { Comment } from "@ant-design/compatible";
import {
  ClockCircleOutlined,
  RobotOutlined,
  UserOutlined,
} from "@ant-design/icons";
import accounting from "accounting-js";
import { Avatar, Descriptions, Space, Tooltip, Typography } from "antd";
import moment from "moment-timezone";

import { UserContext } from "../../../../context/user";
import type { Finding } from "../../../claimWorkspace/findingEdit/types";
import { MetaDataDescription } from "./metaDataDescription";

const { Text } = Typography;

const findingComment = ({ auditFinding }: { auditFinding: Finding }) => {
  const {
    improperPaymentCost,
    improperPaymentUnitsCharged,
    createdAt,
    // auditorComment,
    improperPaymentReason,
    author,
  } = auditFinding;
  const auditorComment = ""; // todo removed with AuditFinding

  const { firstName, lastName, userType } = author ?? {
    firstName: "",
    lastName: "",
    userType: "",
  };

  const {
    workstation: { hides },
  } = React.useContext(UserContext);

  // Check that the review is not deleted, then set reviewed to the reviewedAuditFinding
  // otherwise null
  const reviewedAuditFinding = auditFinding as any;
  const reviewed =
    reviewedAuditFinding.reviewedAuditFinding &&
    !reviewedAuditFinding.reviewedAuditFinding.deleted &&
    reviewedAuditFinding.reviewedAuditFinding;

  const toParagraphs = (rationale: string) =>
    rationale
      ? rationale
          .split("\n")
          .map((text, i) => <Typography key={i}>{text}</Typography>)
      : null;

  const commentProps = {
    ...(hides.auditFindingComment.author
      ? {}
      : {
          author: `${firstName} ${lastName}`,
          avatar: (
            <Avatar
              icon={userType === "SYS" ? <RobotOutlined /> : <UserOutlined />}
            />
          ),
        }),
    content: (
      <div tw="af-flex af-mr-8 af-pt-2">
        <div tw="af-basis-7/12 af-min-w-[300px] af-pl-4">
          <Space direction="vertical" size="small">
            <Descriptions title="" size="small" column={1}>
              <Descriptions.Item
                label="Adjustment Amount"
                data-cy="audit-finding-comment-adjustmentAmount"
                key="audit-finding-comment-adjustmentAmount"
                contentStyle={{ fontWeight: "extrabold" }}
              >
                {accounting.formatMoney(improperPaymentCost)}
              </Descriptions.Item>

              <Descriptions.Item
                label="Discrepant Quantity"
                key="improperPaymentUnitsCharged"
                data-cy="audit-finding-meta-data-description-improperPaymentUnitsCharged"
                contentStyle={{ fontWeight: "extrabold" }}
              >
                {improperPaymentUnitsCharged}
              </Descriptions.Item>
            </Descriptions>

            <div tw="af-whitespace-normal">
              <Text type="secondary">Rationale</Text>
              <span data-cy="audit-finding-comment-rationale">
                {toParagraphs(improperPaymentReason! || auditorComment)}
              </span>
              {reviewed && !reviewed.accepted && (
                <div tw="af-pt-2">
                  <Text type="secondary">Declination</Text>
                  <span data-cy="audit-finding-comment-declination">
                    {toParagraphs(reviewed.rationale)}
                  </span>
                </div>
              )}
            </div>
          </Space>
        </div>

        <div tw="af-basis-5/12  af-min-w-[300px] af-pl-4">
          <Space
            data-cy="audit-finding-comment-meta-desc-container"
            direction="vertical"
            size="small"
          >
            <MetaDataDescription auditFinding={auditFinding} />
          </Space>
        </div>
      </div>
    ),
    datetime: (
      <Tooltip title={moment.utc(createdAt).format("MM-DD-YY HH:mm:ss")}>
        <Space>
          <ClockCircleOutlined />
          <span data-cy="audit-finding-comment-created">
            {moment.utc(createdAt).format("MM-DD-YY")}
          </span>
        </Space>
      </Tooltip>
    ),
  };

  return <Comment {...commentProps} />;
};

export const FindingComment = React.memo(findingComment);
