import { graphql } from "../gql";

export const getFileQueryDoc = graphql(`
  query getFile($id: ID!) {
    file(id: $id) {
      ...FileFragment
      parentDirectory {
        path {
          id
          name
        }
      }
    }
  }
`);
