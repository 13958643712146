/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
// external
import { useState } from "react";
import { css } from "@emotion/react";
import { Button, Spin, Table, Tag } from "antd";
import { useHistory } from "react-router-dom";
import { gql, useQuery } from "urql";

// filters
import {
  claimsAwaitingDocumentationFilter,
  claimsInProgressFilter,
  claimsInvoicePaidFilter,
  claimsInvoicePendingFilter,
  claimsOnHoldFilter,
} from "../../../graphql/filters";
import TableColumnSelector from "../../misc/tableColumnSelector";
// internal
import { QueueTitle } from "../../queues/queues";
import { ColTitle } from "../../queues/util";

// (AT) Deciding to leave this query here
// instead of moving it to the /graphql/queries file
// since it is closely coupled with this component. Will
// move if we decide to use this query elsewhere
const facilityDefaultQueueQuery = gql`
  # query for all providers, security policy read_own_provider
  # restricts the queryable facilities
  query claimsByProviderNpi(
    $claimsAwaitingDocumentationFilter: BatchClaimFilter!
    $claimsInProgressFilter: BatchClaimFilter!
    $claimsOnHoldFilter: BatchClaimFilter!
    $claimsInvoicePendingFilter: BatchClaimFilter!
    $claimsInvoicePaidFilter: BatchClaimFilter!
    $first: Int!
    $offset: Int
  ) {
    uniqueNpiProviders(first: $first, offset: $offset) {
      totalCount
      nodes {
        npi
        providers {
          name
          id
          npi
          totalClaims: batchClaims {
            totalCount
          }
          claimsAwaitingDocumentation: batchClaims(
            filter: $claimsAwaitingDocumentationFilter
          ) {
            totalCount
          }
          claimsUnderReview: batchClaims(filter: $claimsInProgressFilter) {
            totalCount
          }
          claimsOnHold: batchClaims(filter: $claimsOnHoldFilter) {
            totalCount
          }
          claimsInvoicePending: batchClaims(
            filter: $claimsInvoicePendingFilter
          ) {
            totalCount
          }
          claimsInvoicePaid: batchClaims(filter: $claimsInvoicePaidFilter) {
            totalCount
          }
        }
      }
    }
  }
`;

// Takes the output from uniqueNpiProviders and groups
// the providers by their NPIs, while aggregating the statistics
// at the NPI level
const aggregateNpiProviders = ({ uniqueNpiProviders }: any) =>
  uniqueNpiProviders.nodes.map(({ npi, providers }: any) => ({
    npi,
    providers: providers.map(({ name, id }: any) => ({ name, id })),
    ...providers.reduce(
      (sums: any, provider: any) =>
        Object.fromEntries(
          Object.entries(sums).map(([k, v]) => [k, v + provider[k].totalCount]),
        ),
      {
        totalClaims: 0,
        claimsAwaitingDocumentation: 0,
        claimsUnderReview: 0,
        claimsOnHold: 0,
        claimsInvoicePending: 0,
        claimsInvoicePaid: 0,
      },
    ),
  }));

/**
 * The default queues rendered on the <Dashboard />
 * component for certain users, returns a list of
 * provider facilities for the logged in users with the
 * claim counts in certain statuses
 * @returns <Table<Provider>>
 */
const FacilityQueues = () => {
  // pagination state
  const [paginationArgs, setPaginationArgs] = useState({
    first: 10,
    offset: null,
  });
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 10,
  });

  // data state
  const [{ fetching, data }, facilityRefreshQuery] = useQuery({
    query: facilityDefaultQueueQuery,
    variables: {
      ...paginationArgs,
      claimsAwaitingDocumentationFilter,
      claimsInProgressFilter,
      claimsOnHoldFilter,
      claimsInvoicePendingFilter,
      claimsInvoicePaidFilter,
    },
  });

  // browser state
  const history = useHistory();

  // columns need to be inside of the react
  // component so it can use the useHistory() hook
  const columns = [
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Provider's NPI number"
          title="Provider NPI"
          id="provider-npi"
        />
      ),
      dataIndex: "npi",
      key: "providerNpi",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi}/` +
                JSON.stringify({
                  npi: [record.npi],
                }),
            )
          }
        >
          {record.npi}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Provider's name"
          title="Provider Name"
          id="provider-name"
        />
      ),
      key: "providerNames",
      default: false,
      render: (_text: any, record: any) =>
        record.providers.map(({ id, name }: any, index: number) => (
          <Tag
            key={index}
            onClick={() =>
              history.push(
                `/claims/${name}/` + JSON.stringify({ providerId: [id] }),
              )
            }
          >
            {name}
          </Tag>
        )),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Provider's medicaid ID"
          title="Provider ID"
          id="provider-id"
        />
      ),
      key: "medicaidIds",
      default: false,
      render: (_text: any, record: any) =>
        record.providers.map(({ id, name }: any, index: number) => (
          <Tag
            key={index}
            onClick={() =>
              history.push(
                `/claims/${name}/` + JSON.stringify({ providerId: [id] }),
              )
            }
          >
            {id}
          </Tag>
        )),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total number of claims in claim list"
          title="Total Claims"
          id="toal-claims"
        />
      ),
      key: "totalClaims",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - All Claims/` +
                JSON.stringify({
                  npi: [record.npi],
                }),
            )
          }
        >
          {record.totalClaims}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Total number of claims still under review"
          title="Under Review"
          id="under-review"
        />
      ),
      key: "underReview",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - Under Review/` +
                JSON.stringify({
                  npi: [record.npi],
                  workflow: ["IN_PROGRESS"],
                }),
            )
          }
        >
          {record.claimsUnderReview}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of claims awaiting documentation"
          title="Awaiting Documentation"
          id="awaiting-documentation"
        />
      ),
      key: "awaitingDocumentation",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - Awaiting Documentation Upload/` +
                JSON.stringify({
                  npi: [record.npi],
                  documentation: ["AWAITING_UPLOAD"],
                }),
            )
          }
        >
          {record.claimsAwaitingDocumentation}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of claims that are on hold"
          title="On Hold"
          id="on-hold"
        />
      ),
      key: "onHold",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - On Hold/` +
                JSON.stringify({
                  onHold: "ON_HOLD",
                  npi: [record.npi],
                }),
            )
          }
        >
          {record.claimsOnHold}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of claims with a pending invoice"
          title="Invoice Pending"
          id="invoice-pending"
        />
      ),
      key: "invoicePending",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - Invoice Pending/` +
                JSON.stringify({
                  invoice: "SENT",
                  npi: [record.npi],
                }),
            )
          }
        >
          {record.claimsInvoicePending}
        </Button>
      ),
    },
    {
      title: (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        <ColTitle
          tooltipTitle="Number of claims with a paid invoice"
          title="Invoice Paid"
          id="invoice-paid"
        />
      ),
      key: "invoicePaid",
      default: true,
      render: (_text: any, record: any) => (
        <Button
          type="text"
          onClick={() =>
            history.push(
              `/claims/${record.npi} - Invoice Paid/` +
                JSON.stringify({
                  invoice: "PAID",
                  npi: [record.npi],
                }),
            )
          }
        >
          {record.claimsInvoicePaid}
        </Button>
      ),
    },
  ];

  // state for the selected columns - filter columns by 'default'
  // to determine whether this column should show when the page loads.
  // This state is an array of column.key values
  const [selectedColumns, setSelectedColumns] = useState(
    columns.filter((col) => col.default).map((i) => i.key),
  );

  const paginationConfig = {
    current: currentPageNumber,
    position: ["bottomLeft"],
    pageSizeOptions: ["10", "25", "30", "50"],
    showSizeChanger: true,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    pageSize: 10,
    total: data?.uniqueNpiProviders.totalCount,
    onChange: (nextPage: any, pageSize: any) => {
      setPaginationOptions({ pageSize });
      setPaginationArgs({
        first: pageSize,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-errorń
        offset:
          nextPage > 1 ? (nextPage - 1) * paginationOptions.pageSize : null,
      });
      setCurrentPageNumber(nextPage);
      facilityRefreshQuery();
    },
    ...paginationOptions,
  };

  return (
    <div id="facility-default-queues" data-cy="facility-default-queues">
      {!fetching && data ? (
        <Table
          title={() => (
            <>
              <span>
                <QueueTitle title="Facilities" />
              </span>
              <span
                css={css`
                  padding-left: 1%;
                `}
              >
                <TableColumnSelector
                  columns={columns}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </span>
            </>
          )}
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-expect-error
          pagination={paginationConfig}
          // determine the columns to render by filtering the column.key values
          // by the keys present in selectedColumns[]
          columns={columns.filter(({ key }) => selectedColumns.includes(key))}
          // aggregate stats at the NPI level
          dataSource={aggregateNpiProviders({ ...data }).map(
            (node: any, i: number) => {
              node.key = `facilities-queues-row-${i}`; // required for multi-select & each row needs a unique key for react to work best
              return node;
            },
          )}
        />
      ) : (
        <div
          css={css`
            text-align: center;
            margin-top: 5%;
          `}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
};

export { FacilityQueues };
