import { z } from "zod";

export const LoginInput = z.object({
  email: z.string(),
  password: z.string(),
});

export type LoginInput = z.infer<typeof LoginInput>;

const ChallengeKind = z.union([
  z.literal("ADMIN_NO_SRP_AUTH"),
  z.literal("CUSTOM_CHALLENGE"),
  z.literal("DEVICE_PASSWORD_VERIFIER"),
  z.literal("DEVICE_SRP_AUTH"),
  z.literal("MFA_SETUP"),
  z.literal("NEW_PASSWORD_REQUIRED"),
  z.literal("PASSWORD_VERIFIER"),
  z.literal("SELECT_MFA_TYPE"),
  z.literal("SMS_MFA"),
  z.literal("SOFTWARE_TOKEN_MFA"),
]);

export const TwoFactorAuthValidationInput = z.object({
  token: z.string(),
  challenge: z.object({
    type: z.literal("LoginChallengeResult"),
    session: z.string().optional(),
    challengeKind: ChallengeKind.optional(),
    authParams: z.object({
      username: z.string(),
    }),
  }),
});

export type TwoFactorAuthValidationInput = z.infer<
  typeof TwoFactorAuthValidationInput
>;

export const TwoFactorAuthResendInput = z.object({
  login: z.string(),
});

export type TwoFactorAuthResendInput = z.infer<typeof TwoFactorAuthResendInput>;

export const LogoutInput = z
  .union([
    z.object({
      type: z.literal("LogoutEvent"),
      trigger: z.string(),
      payload: z.record(z.any()).optional(),
    }),
    z.object({
      type: z.literal("SessionExpiredEvent"),
      trigger: z.union([z.literal("expiry"), z.literal("fetchFailure")]),
      payload: z.record(z.any()),
    }),
  ])
  .optional();
export type LogoutInput = z.infer<typeof LogoutInput>;
