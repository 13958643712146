import { graphql } from "../gql";

export const promptMutationDoc = graphql(`
  mutation prompt($input: PromptInput!) {
    prompt(input: $input) {
      ...MessageFragment
      response {
        ...PromptResponseFragment
      }
      feedback {
        ...PromptFeedbackFragment
      }
    }
  }
`);

export const promptWithPromptResponseRatingsMutationDoc = graphql(`
  mutation promptWithPromptResponseRatings($input: PromptInput!) {
    prompt(input: $input) {
      ...MessageFragment
      response {
        ...PromptResponseWithRatingsFragment
      }
      feedback {
        ...PromptFeedbackFragment
      }
    }
  }
`);
