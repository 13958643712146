import * as React from "react";

import { useEventCallback } from "./useEventCallback";

const defaultIntersectionThreshold = [0.0, 0.25, 0.5, 0.75, 1.0];

export function useIntersectionObserver(
  elementRef: React.RefObject<Element>,
  {
    threshold = defaultIntersectionThreshold,
    root = null,
    rootMargin = "0px",
  }: IntersectionObserverInit,
  onIntersectionChange?: (entry: IntersectionObserverEntry | undefined) => void,
): IntersectionObserverEntry | undefined {
  const [entry, setEntry] = React.useState<IntersectionObserverEntry>();
  const onIntersectionChangeCallback = useEventCallback(
    (entry: IntersectionObserverEntry | undefined) => {
      onIntersectionChange?.(entry);
    },
  );

  React.useEffect(() => {
    const node = elementRef?.current;
    const hasIOSupport = Boolean(window.IntersectionObserver);

    if (!hasIOSupport || !node) return;

    const observerParams = { threshold, root, rootMargin };

    const updateEntry = ([entry]: IntersectionObserverEntry[]) => {
      setEntry(entry);
      onIntersectionChangeCallback?.(entry);
    };

    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();
  }, [elementRef, threshold, root, rootMargin, onIntersectionChangeCallback]);

  return entry;
}
