/**
 * Foxit PDF SDK for Web Library isn't compatible with server-side rendering.
 * This is because the library uses the `self` object and other browser-specific
 * APIs directly when importing.
 *
 * Loaders folder provides module loading via custom hooks to allow hassle-free usage
 * in Next.Js and other server side React environments.
 */

export { useSuspenseCreateFoxitJrWorker } from "./foxit-jr-worker";
export { useSuspenseFoxitPDFViewCtrlLoader } from "./foxit-pdf-view-ctrl";
