"use client";

import { useQueryClient } from "@tanstack/react-query";

interface QueryProvider {
  children?: React.ReactNode;
}

/**
 * In current implementation, we assume that the QueryClient is already created and provided by the parent application,
 * otherwise useQueryClient will throw an error.
 */

export const WithExistingQueryProvider = ({ children }: QueryProvider) => {
  const existingQueryClient = useQueryClient();

  if (existingQueryClient) {
    return children;
  }

  return null;
};
