/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-redundant-type-constituents */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC } from "react";
import { useCallback, useEffect, useState } from "react";
import { DownloadOutlined } from "@ant-design/icons";
import { Button, Popconfirm, Spin } from "antd";
import { useClient } from "urql";

import * as util from "../documentViewer/documentViewerUtil";
import { reportDownloadQueryDoc } from "./downloadReportsButton.gql";

const ReportDownloadButton: FC<{
  reportTitle: string;
  url: string;
  close: () => void;
}> = ({ reportTitle, url, close }) => {
  const [timedOut, setTimedOut] = useState<boolean>(false);

  // show an error if the user doesn't download before the url is about to expire
  useEffect(() => {
    let timeout: any = null;
    const urlExpiry = util.extractEpochMillisecondsFromSignedUrl(url);
    const expiry = urlExpiry || 0;
    clearTimeout(timeout);
    timeout = null;
    if (expiry) {
      // subtract 30 seconds from the expiry time so that we compensate for clock diffs
      const serverExpire = new Date().getTime() + expiry - 30_000;
      timeout = setTimeout(
        // proactively mark the doc timed out without waiting for error
        () => {
          setTimedOut(true);
        },
        serverExpire - new Date().getTime(),
      );
    }
    return () => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = null;
      }
    };
  }, [url]);

  return timedOut ? (
    <div tw="af-text-antd-lt-colorError dark:af-text-antd-dk-colorError">
      The download has timed out. Reopen the dialog to get a fresh download
      request.
    </div>
  ) : (
    <Button
      onClick={close}
      href={url}
      download={reportTitle}
      target="_blank"
      rel="noreferrer"
    >
      <DownloadOutlined />
      <span>Download</span>
    </Button>
  );
};

export const DownloadReportButton: FC<{ reportId: string }> = ({
  reportId,
}) => {
  const client = useClient();
  const [fetching, setFetching] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [report, setReport] = useState<any | null>(null);
  const [error, setError] = useState<any | null>(null);

  const fetchDocuments = useCallback(async () => {
    if (!reportId) {
      return Promise.reject("useDocuments: claimId is not set");
    }
    setFetching(true);
    try {
      return client
        .query(
          reportDownloadQueryDoc,
          {
            reportId,
          },
          { requestPolicy: "network-only" },
        )
        .toPromise()
        .then((result) => {
          setFetching(false);
          if (result.error) {
            console.error(
              `Error fetching report URL for id ${reportId}`,
              result.error,
            );
            return Promise.reject(result.error);
          }
          return result?.data?.auditFindingReport ?? null;
        })
        .catch((e) => {
          console.error(`Error fetching report URL for id ${reportId}`, e);
          setFetching(false);
          return Promise.reject(e);
        });
    } catch (e) {
      console.error(
        `Unexpected error fetching report URL for id ${reportId}`,
        e,
      );
      setFetching(false);
      return Promise.reject(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId]);

  const openConfirmAndGetSignedUrl = useCallback(() => {
    setOpen(true);
    fetchDocuments()
      .then((report) => {
        if (!report?.downloadUrl) {
          throw new Error("No download URL found in report data");
        }
        setReport(report);
      })
      .catch((e) => {
        setError(e);
      });
  }, [fetchDocuments, setOpen, setError, setReport]);

  const close = useCallback(() => {
    setReport(null);
    setOpen(false);
  }, [setOpen]);

  return (
    <Popconfirm
      title={<div>Download Report?</div>}
      description={
        <div tw="af-w-80 af-h-24">
          {!open ? (
            <></>
          ) : !fetching && error ? (
            <div tw="af-text-antd-lt-colorError dark:af-text-antd-dk-colorError">
              Error fetching report: {error?.message}
            </div>
          ) : fetching || !report ? (
            <Spin></Spin>
          ) : (
            <>
              <div tw="af-pt-2 af-text-antd-lt-colorPrimaryText dark:af-text-antd-dk-colorPrimaryText">
                {report?.title}
              </div>
              <div tw="af-grid af-place-items-center af-h-16">
                <ReportDownloadButton
                  close={close}
                  reportTitle={report?.title}
                  url={report?.downloadUrl}
                ></ReportDownloadButton>
              </div>
            </>
          )}
        </div>
      }
      icon={<DownloadOutlined />}
      open={open}
      onConfirm={close}
      showCancel={false}
      okText={"Close"}
      onCancel={close}
    >
      <Button onClick={openConfirmAndGetSignedUrl}>
        <DownloadOutlined />
      </Button>
    </Popconfirm>
  );
};
