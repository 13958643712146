/* eslint-disable @typescript-eslint/prefer-promise-reject-errors */
import React from "react";
import { Form, InputNumber } from "antd";

import { FormExtra } from "../../../../shared/components/form/formExtra";
import { isSet } from "../../../../util/isSet";
import { orZero } from "../../../../util/orZero";
import { useInitialValues } from "../useInitialValues";
import type { CommonFieldProps } from "../util";
import { formKey } from "../util";

export type ImproperPaymentUnitsProps = CommonFieldProps & {
  maxUnits?: number | null;
};

export const ImproperPaymentUnits: React.FC<ImproperPaymentUnitsProps> = ({
  dataKey,
  label = "Discrepant Quantity",
  initialValues,
  formLayout,
  className,
  maxUnits = null,
}) => {
  const { defaultValue, existingFindingValue } = useInitialValues<number>(
    dataKey,
    initialValues,
  );
  return (
    <Form.Item
      {...formKey(dataKey)}
      label={label}
      {...formLayout}
      className={className}
      rules={[
        {
          required: true,
          validator: async (ruleObject, value) => {
            return new Promise((resolve, reject) => {
              if (
                initialValues.existingFindingValues?.auditFindingValues
                  ?.improperPaymentUnitsCharged === value
              ) {
                resolve("");
              }
              if (value < 1) {
                reject("Enter the improper number of units charged");
              }
              if (!isSet(maxUnits)) {
                resolve("");
              }
              if (value > orZero(maxUnits)) {
                reject(`Exceeds available units (${maxUnits})`);
              }
              // ok:
              resolve("");
            });
          },
        },
      ]}
      extra={
        <FormExtra visible={isSet(existingFindingValue)}>
          {existingFindingValue}
        </FormExtra>
      }
      initialValue={defaultValue}
    >
      <InputNumber style={{ width: "50%" }} precision={0} min={1} />
    </Form.Item>
  );
};
