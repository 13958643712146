import type { FC } from "react";
import { useContext } from "react";

import { DragModal } from "../../../../../common/modal/dragModal";
import { FindingContext } from "../findingProvider";
import { FindingForm } from "./findingForm";

export const FindingModal: FC<{ open: boolean }> = ({ open }) => {
  const { findingEditor } = useContext(FindingContext);
  return (
    <DragModal
      open={open}
      close={findingEditor.hideModal}
      modalProps={{ title: findingEditor.title, footer: null }}
    >
      {findingEditor.isReady() ? (
        <FindingForm
          onCancel={() => findingEditor.hideModal()}
          findingEditor={findingEditor}
        ></FindingForm>
      ) : (
        <div>Finding editor is not configured</div>
      )}
    </DragModal>
  );
};
