import { z } from "zod";

export const Queue = z.object({
  id: z.string(),
  name: z.string(),
  createdAt: z.string(),
});
export type Queue = z.infer<typeof Queue>;

export const QueueCreateInput = z.object({
  name: z.string().trim().min(1),
});
export type QueueCreateInput = z.infer<typeof QueueCreateInput>;

export const QueueCreateFromCaseInput = QueueCreateInput.extend({
  caseId: z.string(),
});
export type QueueCreateFromCaseInput = z.infer<typeof QueueCreateFromCaseInput>;

export const QueueByIdInput = z.object({ id: z.string() });
export type QueueByIdInput = z.infer<typeof QueueByIdInput>;

export const QueueUpdateInput = z.object({
  id: z.string(),
  name: z.string().trim().min(1).optional(),
});
export type QueueUpdateInput = z.infer<typeof QueueUpdateInput>;
