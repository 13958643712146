import { useEffect, useState } from "react";
import type {
  FilterChangedEvent,
  IStatusPanelParams,
  RowDataUpdatedEvent,
  SelectionChangedEvent,
} from "ag-grid-community";

import { LineClampedCell } from "../cell/lineClampedCell";

export const StatusBarRowCount = <T,>(props: IStatusPanelParams) => {
  const [totalCount, setTotalCount] = useState<number>(0);
  const [filteredCount, setFilteredCount] = useState<number>(0);
  const [selectedCount, setSelectedCount] = useState<number>(0);

  const dataChanged = (event: RowDataUpdatedEvent<T>) => {
    setTotalCount(() => event.api.getDisplayedRowCount() ?? 0);
  };

  const selectionChanged = (event: SelectionChangedEvent<T>) => {
    setSelectedCount(() => event.api.getSelectedNodes()?.length ?? 0);
  };

  const filterChanged = (event: FilterChangedEvent) => {
    setFilteredCount(() => event.api.getDisplayedRowCount() ?? 0);
  };

  useEffect(() => {
    props.api.addEventListener("rowDataUpdated", dataChanged);
    props.api.addEventListener("selectionChanged", selectionChanged);
    props.api.addEventListener("filterChanged", filterChanged);
    return () => {
      props.api.removeEventListener("rowDataUpdated", dataChanged);
      props.api.removeEventListener("selectionChanged", selectionChanged);
      props.api.removeEventListener("filterChanged", filterChanged);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LineClampedCell>
      <span tw="af-px-4 af-text-antd-lt-colorPrimary dark:af-text-antd-dk-colorPrimary">
        {selectedCount > 0 ? `${selectedCount} selected` : ""}
      </span>
      <span>
        {filteredCount > 0 ? (
          <>
            <span tw="af-pr-0.5">{filteredCount}</span>/
            <span tw="af-pl-0.5">{totalCount} displayed</span>
          </>
        ) : (
          `${totalCount} rows`
        )}
      </span>
    </LineClampedCell>
  );
};
