import "react-app-polyfill/ie11";

import { datadogRum } from "@datadog/browser-rum";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { version } from "../package.json";
import { LogProvider } from "./common/log/logProvider";
import { App } from "./components/app/app";
import { SwClientProvider } from "./sw/swProvider";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
global.__src = __dirname;

!process.env.DEV_NO_DD &&
  datadogRum.init({
    applicationId: "6ee63b18-40d8-4679-b08a-1fbe4e4ba31c",
    clientToken: "pub5180b6df804df179bc52d4462fbc4d27",
    site: "datadoghq.com",
    service: "alaffia-app",
    env: process.env.REACT_APP_DD_ENV ?? "local",
    version,
    sampleRate: 100,
    trackInteractions: true,
  });

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <LogProvider>
    <SwClientProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SwClientProvider>
  </LogProvider>,
);
