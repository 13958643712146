/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import accounting from "accounting-js";

export const asMoney = (val: number | string | null | undefined) => {
  if (typeof val === "number") {
    return accounting.formatMoney(val);
  }
  if (typeof val === "string") {
    const asNum = parseFloat(val);
    if (isNaN(asNum)) {
      return val;
    }
    return accounting.formatMoney(asNum);
  }
  return accounting.formatMoney(0);
};
