/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { useContext, useState } from "react";
import {
  AuditOutlined,
  FileDoneOutlined,
  FolderOutlined,
  HomeOutlined,
  LogoutOutlined,
  ReconciliationOutlined,
  SearchOutlined,
  TeamOutlined,
  UploadOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Badge, Button, Menu, Tooltip } from "antd";
import { Link } from "react-router-dom";
import tw, { css } from "twin.macro";

import { AlaffiaClientContext } from "../../alaffiaClient/alaffiaClientProvider";
import { UserContext } from "../context/user";
import { UploadClaimModal } from "../uploadClaims/uploadClaimModal";
import { VersionInfo } from "./version.ver";

const Permissioned = ({ components, permissions }: any) => {
  return (
    components
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .filter(([component]) => permissions.navigation.includes(component.name))
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      .map(([component, props]) => component(props))
  );
};

const Queues = () => (
  <Menu.Item
    id="navigationQueues"
    key="NavigationQueues"
    icon={<AuditOutlined />}
  >
    <Link to={`/queues`}>Queues</Link>
  </Menu.Item>
);

const Reports = () => (
  <Menu.Item
    key="NavigationReports"
    id="navigationReportsMenuItem"
    icon={<ReconciliationOutlined />}
  >
    <Link to={"/reports"}>Reports</Link>
  </Menu.Item>
);

const CreateAuditOrReport = ({ setCreatingReport }: any) => (
  <>
    <Menu.Item
      id="navigationCreateReportMenuItem"
      key="NavigationReportsCreate"
    >
      <Button
        icon={<FileDoneOutlined />}
        type="dashed"
        style={{ width: "100%" }}
        onClick={() => setCreatingReport(true)}
      >
        Create Report
      </Button>
    </Menu.Item>
  </>
);

const AuditsTab = ({ permissions }: any) => {
  return (
    <Menu.SubMenu
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="navigationAuditsMenu"
      key="NavigationAuditsMenu"
      icon={<FolderOutlined />}
    >
      {Permissioned({
        components: [[Queues], [Reports]],
        permissions,
      })}
    </Menu.SubMenu>
  );
};

const SearchBarToggle = ({ searchBarVisible, setSearchBarVisible }: any) => (
  <Menu.Item
    id="searchBarToggle"
    key="searchBarToggle"
    icon={<SearchOutlined />}
    onClick={() => {
      setSearchBarVisible(!searchBarVisible);
    }}
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    selectable={"false"}
  >
    Search Claims
  </Menu.Item>
);

const HomeTab = ({ homeRoute }: { homeRoute: string }) => (
  <Menu.Item
    id="navigationDashboard"
    key="NavigationDashboard"
    icon={<HomeOutlined />}
  >
    <Link to={homeRoute}>Home</Link>
  </Menu.Item>
);

const ManageUsersTab = () => (
  <Menu.Item
    id="navigationUserManagement"
    key="NavigationUserManagement"
    icon={<TeamOutlined />}
  >
    <Link to="/users">Manage Users</Link>
  </Menu.Item>
);

const UserProfile = () => (
  <Menu.Item id="navigationProfileMenuItem" key="NavigationUserProfile">
    <Link to="/user/profile">Profile</Link>
  </Menu.Item>
);

const UserLogout = () => {
  const alaffiaClient = useContext(AlaffiaClientContext);
  return (
    <Menu.Item id="navigationLogoutMenuItem" key="NavigationUserLogout">
      <Button
        icon={<LogoutOutlined />}
        style={{ width: "100%", color: "red" }}
        onClick={() => {
          alaffiaClient?.auth.logout({});
          window.location.reload();
        }}
      >
        Sign out
      </Button>
    </Menu.Item>
  );
};

const UserTab = ({ permissions }: any) => {
  return (
    <Menu.SubMenu
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      id="navigationUserMenu"
      key="NavigationUser"
      icon={<UserOutlined />}
      title="User"
    >
      {Permissioned({
        components: [[UserProfile], [UserLogout]],
        permissions,
      })}
    </Menu.SubMenu>
  );
};

const UploadClaims = ({ setUploadClaimModalOpen, uploadClaimPending }: any) => {
  return (
    <Menu.Item
      id="navigationUploadClaims"
      key="NavigationUploadClaims"
      onClick={() => setUploadClaimModalOpen(true)}
      icon={
        <Badge
          size="small"
          dot={true}
          offset={[4, 12]}
          count={uploadClaimPending}
        >
          <UploadOutlined />
        </Badge>
      }
      title="Upload Claims"
    ></Menu.Item>
  );
};

const Navigation = (props: any) => {
  const { setCreatingReport, searchBarVisible, setSearchBarVisible } = props;
  const { permissions, claimIntakeEnabled } = React.useContext(UserContext);
  const [uploadClaimModalOpen, setUploadClaimModalOpen] = useState(false);
  const [uploadClaimPending, setUploadClaimPending] = useState(0);

  return (
    <>
      <div tw="af-h-full af-max-h-full af-flex af-flex-col">
        <div tw="af-grow">
          <Menu
            id="app_navigation"
            key="app_navigation"
            theme="light"
            mode="inline"
            css={css`
              height: 100%;
            `}
          >
            {Permissioned({
              components: [
                [SearchBarToggle, { searchBarVisible, setSearchBarVisible }],
                [HomeTab, { homeRoute: permissions.homeRoute }],
                [AuditsTab, { setCreatingReport, permissions }],
                [ManageUsersTab],
                [UserTab, { permissions }],
                ...(claimIntakeEnabled
                  ? [
                      [
                        UploadClaims,
                        {
                          uploadClaimModalOpen,
                          setUploadClaimModalOpen,
                          setUploadClaimPending,
                          uploadClaimPending,
                        },
                      ],
                    ]
                  : []),
              ],
              permissions,
            })}
          </Menu>
        </div>
        <div
          css={css({
            ...tw` af-text-xs af-flex af-items-center af-justify-center af-w-full af-py-4
            af-bg-antd-lt-colorBgContainer dark:(af-bg-antd-dk-colorBgContainer)`,
            "& div.app-version-label":
              VersionInfo.gitDirty === "D"
                ? tw`af-text-antd-lt-colorErrorActive`
                : VersionInfo.gitTagDistance !== "0"
                ? tw`af-text-antd-lt-colorWarning`
                : tw`af-text-antd-lt-colorTextSecondary dark:(af-text-antd-dk-colorTextSecondary)`,
          })}
        >
          {VersionInfo.gitTagFull.startsWith("%%") ? (
            <div>{/* app version placeholder */}</div>
          ) : (
            <Tooltip
              title={
                <div>
                  <div>Alaffia App v{VersionInfo.gitTag}</div>
                  {(VersionInfo.gitTagDistance ||
                    VersionInfo.gitDirty === "D") && (
                    <div tw="af-text-[0.75em] af-font-light">
                      <div>{VersionInfo.gitTagFull}</div>
                    </div>
                  )}
                </div>
              }
            >
              <div className="app-version-label">v{VersionInfo.gitTag}</div>
            </Tooltip>
          )}
        </div>
      </div>
      <UploadClaimModal
        {...{
          open: uploadClaimModalOpen,
          setOpen: setUploadClaimModalOpen,
          setUploadClaimPending,
        }}
      />
    </>
  );
};

export {
  Navigation,
  HomeTab,
  AuditsTab,
  SearchBarToggle,
  Queues,
  Reports,
  CreateAuditOrReport,
  ManageUsersTab,
  UserTab,
  UserProfile,
  UserLogout,
  UploadClaims,
};
